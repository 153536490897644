import { css, SerializedStyles } from '@emotion/react';

import { getThemeItem, Theme } from '~/styles/themes';

export const menu = css`
	grid-area: action-menu;
`;

export const menuButton = (
	theme: Theme,
	options: {
		menuOpened: boolean;
	}
): SerializedStyles => {
	const { menuOpened } = options;

	const {
		colors: { primary: primaryColor }
	} = theme;

	const activeBorder = css`
		border: 1px solid ${primaryColor} !important;
	`;

	return css`
		width: 21px;
		height: 21px;

		// !important is used to override the MUIs settings
		min-width: unset !important;
		font-size: 1em !important;
		letter-spacing: 0.1em !important;
		border-radius: 0 !important;
		padding: 0 !important;

		// Shows border when focused with the keyboard. But the focus is lost after the click
		&:focus-visible {
			${activeBorder}
		}
		// Shows border after the menu has been opened & focus got lost
		${menuOpened && activeBorder}

		.MuiButton-label {
			// Centers the text content of the button
			margin-top: -0.5em; // Compensates for the line-height
			margin-right: -2px; // Compensates for the borders
		}
	`;
};

export const menuPopper = (theme: Theme): SerializedStyles => {
	const {
		colors: { primary: primaryColor },
		fonts: { app }
	} = theme;

	const appFont = getThemeItem(app, theme);
	return css`
		z-index: 2;

		.MuiPaper-root {
			margin-top: -1px; // Prevent borders stacking
			border-radius: 0;
			border: 1px solid ${primaryColor} !important;
			background: white;
		}

		.MuiList-root {
			padding: 0;
		}
		.MuiListItem-root {
			min-height: auto;
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 6px;
			padding-right: 6px;
			font-family: ${appFont};
			font-size: 0.9em;
			white-space: pre;

			&:not(:last-child) {
				border-bottom: 1px solid ${primaryColor};
			}

			&.Mui-disabled {
				opacity: 1;
				font-style: italic;
			}
		}
	`;
};
