import React, { forwardRef, HTMLAttributes } from 'react';

import { css } from '@emotion/react';

import { mixins } from '~/styles/themes';

interface Props extends HTMLAttributes<HTMLDivElement> {
	placeholder?: string;
	invisible?: boolean;
}

const InputPlaceholder = forwardRef<HTMLDivElement, Props>(
	({ placeholder, invisible, ...rest }, ref) => {
		if (!placeholder) return null;

		const isSampleText = !invisible && getIsPlaceholderSampleText(placeholder);
		return (
			<div
				{...rest}
				ref={ref}
				css={[isSampleText && sampleTextStyles, invisible && invisibleStyles]}
				aria-hidden={!isSampleText}>
				{isSampleText && (
					<span css={mixins.webtextHiddenAccessible}>Temporary Placeholder&nbsp;</span>
				)}
				{placeholder}
			</div>
		);
	}
);

/**
 * "Sample text" is serves the purpose of showing a sample of what a student might write in a way
 * that has learning value as well as confidence-building value.
 * (This is quite different from, and more important than, just being informative)
 *
 * > Meaning that whenever "Type Your" appears the ordinary standard placeholder text would be used
 *
 * @see https://soomo.height.app/T-73614#3adf0a54-800f-45aa-89fe-3228ec66facc
 */
export const getIsPlaceholderSampleText = (placeholder: string | undefined | null): boolean =>
	placeholder ? !placeholder.match(/^type your/i) : false;

const sampleTextStyles = css`
	cursor: text;
`;

// Used for placeholder dimensions tracking even when value is present in the editor
const invisibleStyles = css`
	position: absolute;
	visibility: hidden;
`;

InputPlaceholder.displayName = 'InputPlaceholder';

export default InputPlaceholder;
