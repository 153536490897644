import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

export const deckStyles = css`
	border: 1px solid #c9c9c9;
	border-radius: 0.5rem;

	.prompt-and-pivotar {
		position: relative;
		display: flex;
		width: 100%;
		padding: 1rem 1.5rem;
		align-items: center;
		justify-content: space-between;
		column-gap: 1.5rem;
		font: inherit;
		border: none;
		background: none;
		cursor: pointer;
		text-align: initial;

		@media (max-width: ${breakpoints.small}) {
			padding-right: 1rem;
			column-gap: 0.5rem;
		}

		> svg {
			margin-top: 0.25rem;
			flex-shrink: 0;
			display: inline-block;
		}

		// gray out questions that are complete (either correct or out of attempts)
		.answered-question .question-body {
			color: #6d6d6d !important; // specificity + !important needed to override a Core style
		}

		// QuestionPrompt inner div
		.question-body {
			margin: 0 !important;
		}
	}
`;
