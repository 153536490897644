import range from 'lodash-es/range';

import {
	getChildrenSectionInstancesNumber,
	getToggleSectionInstancesNumber
} from '~/components/Outline/components/SectionView/helpers';
import { getTemplateSections } from '~/components/Outline/helpers/responses';
import {
	OutlineChildrenSection,
	OutlineConfig,
	OutlineHierarchyItemType,
	OutlineResponseInstance,
	OutlineResponseSection,
	OutlineResponsesState,
	OutlineSection
} from '~/components/Outline/types';

/**
 * Maps the retrieved value from `source` to the `hierarchy` of the target Outline.
 * The whole process mimics the rendering flow of the `Outline` itself:
 * 1. Maps sections {@link mapSections} ⤵
 *   2. Maps section {@link mapSection} ⤵
 *     3. Maps instance {@link mapInstance} ➡ 1.
 * Example - https://soomo.slab.com/posts/outline-pi-for-builder-4c0n6ard#hjqnj-import-outline-into-another-outline
 *
 * @param config - configuration of the target Outline
 * @param sourceValue - response value of the source Outline
 */
export const mapSourceResponses = (
	config: OutlineConfig,
	sourceValue: OutlineResponsesState
): OutlineResponsesState => {
	const { hierarchy } = config;
	const { hierarchy: sourceHierarchy, hierarchyResponses: sourceHierarchyResponses } = sourceValue;

	const mappedHierarchyResponses = mapSections(hierarchy, OutlineHierarchyItemType.Children, {
		sections: sourceHierarchy,
		responseSections: sourceHierarchyResponses
	});
	return {
		...sourceValue,
		hierarchyResponses: mappedHierarchyResponses
	};
};

type ResponsesSectionsByTemplate = Record<string, Array<OutlineResponseSection>>;
type SectionsIndicesByTemplate = Record<string, number>;

const mapSections = (
	sections: Array<OutlineSection>,
	sectionsType: OutlineHierarchyItemType,
	source: { sections: Array<OutlineSection>; responseSections: Array<OutlineResponseSection> }
): Array<OutlineResponseSection> => {
	const { sections: sourceSections, responseSections: sourceResponseSections } = source;

	const responsesSection = getSectionsByTemplate(); // Sections indexed by the template name
	const sectionsIndices = getSectionIndicesByTemplate(); // Indices cursors to the sections that have been mapped

	return sections.map((section) => {
		const { template } = section;

		const sourceSection = sourceSections.find(
			({ template: sourceTemplate }) => sourceTemplate === template
		);
		if (!sourceSection) return {};

		const sourceResponseSections = responsesSection[template];
		const sectionIndex = sectionsIndices[template];

		const sourceResponseSection = sourceResponseSections[sectionIndex];
		if (!sourceResponseSection) return {};

		sectionsIndices[template]++; // Shift index to the next section

		return mapSection(section, sectionsType, {
			section: sourceSection,
			responseSection: sourceResponseSection
		});
	});

	function getSectionsByTemplate(): ResponsesSectionsByTemplate {
		return sections.reduce<ResponsesSectionsByTemplate>(
			(responsesByTemplate, { template }) =>
				responsesByTemplate[template]
					? responsesByTemplate
					: {
							...responsesByTemplate,
							[template]: getTemplateSections({
								template,
								hierarchy: sourceSections,
								hierarchyResponses: sourceResponseSections
							})
					  },
			{}
		);
	}

	function getSectionIndicesByTemplate(): SectionsIndicesByTemplate {
		return sections.reduce<SectionsIndicesByTemplate>(
			(indicesByTemplate, { template }) => ({
				...indicesByTemplate,
				[template]: 0
			}),
			{}
		);
	}
};

const mapSection = (
	section: OutlineSection,
	sectionsType: OutlineHierarchyItemType,
	source: { section: OutlineSection; responseSection: OutlineResponseSection }
): OutlineResponseSection => {
	const { section: sourceSection, responseSection: sourceResponseSection } = source;
	const { instances: responseSectionInstances } = sourceResponseSection;

	const { initial: initialInstancesNumber, max: maxInstancesNumber } =
		sectionsType === OutlineHierarchyItemType.Children
			? getChildrenSectionInstancesNumber((section as OutlineChildrenSection).instances)
			: getToggleSectionInstancesNumber();

	const sectionInstances = range(maxInstancesNumber)
		.map((instanceIndex) => {
			const responseSectionInstance = responseSectionInstances[instanceIndex];
			if (responseSectionInstance) return responseSectionInstance;
			if (initialInstancesNumber > instanceIndex) return { value: undefined };
		})
		.filter(Boolean);

	return {
		instances: sectionInstances.map((instance) =>
			mapInstance(section, { section: sourceSection, responseInstance: instance })
		)
	};
};

const mapInstance = (
	section: OutlineSection,
	source: { section: OutlineSection; responseInstance: OutlineResponseInstance }
): OutlineResponseInstance => {
	const { section: sourceSection, responseInstance: sourceResponseInstance } = source;
	return {
		value: sourceResponseInstance.value,
		children: getInstanceSections(OutlineHierarchyItemType.Children),
		toggle: getInstanceSections(OutlineHierarchyItemType.Toggle)
	};

	function getInstanceSections(
		sectionType: OutlineHierarchyItemType
	): Array<OutlineResponseSection> {
		let sections: Array<OutlineResponseSection> = [];
		if (
			sectionType in section &&
			sectionType in sourceSection &&
			sectionType in sourceResponseInstance
		) {
			sections = mapSections(section[sectionType], sectionType, {
				sections: sourceSection[sectionType],
				responseSections: sourceResponseInstance[sectionType]
			});
		}
		return sections;
	}
};
