export enum BrightcovePlayerEvent {
	PLAY = 'play',
	PAUSE = 'pause',
	ENDED = 'ended',
	FIRST_PLAY = 'firstplay',
	LOAD_START = 'loadstart',
	LOADED_DATA = 'loadeddata',
	ERROR = 'error'
}

export interface BrightcovePlayerConfig {
	accountId: string;
	adConfigId?: string;
	applicationId?: string;
	embedId: string;
	embedOptions: { pip?: boolean; responsive: boolean; tagName?: string };
	embedType?: string;
	onEmbedCreated?: (element: HTMLElement) => void;
	onFailure?: (error: Error) => void;
	onSuccess?: (data: object) => void;
	playerId: string;
	refNode: HTMLElement | HTMLDivElement;
	refNodeInsert: 'append' | 'prepend' | 'before' | 'after' | 'replace';
	videoId: string;
}

export interface BrightcovePlayerObject {
	on: (event: BrightcovePlayerEvent, callback: (data?: object) => void) => void;
	currentTime: (amount?: number) => number;
	duration: () => number;
	bufferedPercent: () => number;
	dispose: () => void;
}
