import React, { ReactElement } from 'react';

import { mixins } from '~/styles/themes';
import { convertToCount, convertToPercentageStr, formatPercentageStr } from '~/utils';

export const getColumnCellClass = (i: number): string => (i % 2 !== 1 ? 'shaded-cell' : '');

export const emptyCellContent = '-';
export const emptyCellJsxContent = (
	<>
		<span aria-hidden>{emptyCellContent}</span>
		<span css={mixins.webtextHiddenAccessible}>Data not available</span>
	</>
);

/**
 * Calculate the percentage and response values for the data tab tables,
 * this function is shared between the "Wide"/"Small" table components and CSV generation
 */
export const calculateCellContent = (args: {
	colIdx: number;
	colValue: number;
	dataType: string;
	classTotal: number;
	totalCount: number[];
	roundValuePrecision?: number;
}): { numberCellContent: string; percentageCellContent: string } => {
	const { colIdx, colValue, dataType, classTotal, totalCount, roundValuePrecision = 1 } = args;

	let numberCellContent: string;
	let percentageCellContent: string;

	// 1st columns will always be the "Your class"
	if (colIdx === 0) {
		numberCellContent = colValue.toLocaleString('en-US');
		percentageCellContent = convertToPercentageStr(colValue, classTotal, roundValuePrecision);
	} else {
		// `totalCount` is provided only for the external data, starting from 2nd column (that why -1 needed)
		const columnTotalCount = totalCount[colIdx - 1];

		if (dataType === 'percentage') {
			// colValue is a percentage, convert it to a count
			numberCellContent = columnTotalCount
				? convertToCount(colValue, columnTotalCount).toLocaleString('en-US')
				: emptyCellContent;
			percentageCellContent = formatPercentageStr(colValue, roundValuePrecision);
		} else {
			numberCellContent = colValue.toLocaleString('en-US');
			percentageCellContent = convertToPercentageStr(
				colValue,
				columnTotalCount,
				roundValuePrecision
			);
		}
	}

	return {
		numberCellContent,
		percentageCellContent
	} as const;
};

export const calculateCellJsxContent = (
	...args: Parameters<typeof calculateCellContent>
): {
	numberCellContent: ReactElement | string;
	percentageCellContent: string;
} => {
	const { numberCellContent, percentageCellContent } = calculateCellContent(...args);
	return {
		numberCellContent:
			numberCellContent === emptyCellContent ? emptyCellJsxContent : numberCellContent,
		percentageCellContent
	} as const;
};
