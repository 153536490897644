import * as React from 'react';

function SvgOffline(props) {
	return (
		<svg height="1em" width="1em" viewBox="0 0 100 100" {...props}>
			<path
				fill="currentColor"
				d="M50.048 61.368c4.418 0 8.421 1.798 11.323 4.699l4.691-4.691a22.618 22.618 0 00-12.737-6.404l-6.799 6.8a15.977 15.977 0 013.522-.404zM43.477 70.818l6.571 6.572 6.571-6.572a9.255 9.255 0 00-6.571-2.722 9.266 9.266 0 00-6.571 2.722zM50.048 41.544c-9.882-.001-18.845 4.02-25.336 10.51l4.693 4.691a29.214 29.214 0 0113.872-7.764l7.421-7.422c-.217-.002-.432-.015-.65-.015zM70.692 56.746l4.69-4.691a35.985 35.985 0 00-11.435-7.705l-5.167 5.166a29.235 29.235 0 0111.912 7.23zM50.048 28.319c-13.528 0-25.797 5.503-34.682 14.39l4.692 4.692c7.684-7.684 18.292-12.442 29.991-12.443 2.286 0 4.527.187 6.717.537l5.605-5.606a48.917 48.917 0 00-12.323-1.57zM68.905 39.392a42.697 42.697 0 0111.134 8.006l4.69-4.691a49.35 49.35 0 00-10.911-8.228l-4.913 4.913z"
			/>
			<path
				fill="currentColor"
				d="M50.277 4.35C25.106 4.35 4.627 24.829 4.627 50c0 25.172 20.479 45.65 45.65 45.65 25.172 0 45.65-20.479 45.65-45.65.001-25.171-20.478-45.65-45.65-45.65zm0 6.586c9.419 0 18.069 3.351 24.823 8.923L20.136 74.823C14.564 68.069 11.213 59.419 11.213 50c0-21.54 17.525-39.064 39.064-39.064zm0 78.127c-9.418 0-18.068-3.35-24.821-8.921L80.42 25.179c5.571 6.753 8.921 15.403 8.921 24.821 0 21.54-17.524 39.063-39.064 39.063z"
			/>
		</svg>
	);
}

export default SvgOffline;
