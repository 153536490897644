import React, { VFC } from 'react';
import type { IconBaseProps } from 'react-icons';
import { FaChevronUp } from 'react-icons/fa';

import { expandCollapseIconProps } from './expandCollapseIconProps';
import { iconStyles } from './styles';

const CollapseIcon: VFC<IconBaseProps> = (props) => (
	<FaChevronUp css={iconStyles} {...expandCollapseIconProps} {...props} />
);

export default CollapseIcon;
