import { useMemo } from 'react';

import round from 'lodash-es/round';

import {
	PollChoiceOrdering,
	PollClassData,
	PollClassDataItem,
	PollDataType,
	PollExternalData,
	QuestionChoice
} from '~/types/WebtextManifest';
import { convertToRawPercentage } from '~/utils';

import { calculateCellContent } from './RefreshedPoll/ResultsDataTable/ComparisonTable/utils';

interface UsePollDataHelpersProps {
	shapedDataMode: PollDataType;
	choices: QuestionChoice[];
	classData: PollClassData;
	externalData?: PollExternalData;
	choiceOrdering?: PollChoiceOrdering;
	sourceDatasetIndex?: number;
	dataType?: PollDataType;
	totalCount?: number[];
	roundValuePrecision?: number;
}

export type OrderedClassData = Array<PollClassDataItem & Pick<QuestionChoice, 'shortened_body'>>;

export type ShapedPollData = (string | number)[][];

export const usePollDataState = (props: UsePollDataHelpersProps) => {
	const {
		shapedDataMode,
		choices,
		classData,
		externalData,
		choiceOrdering,
		sourceDatasetIndex,
		dataType,
		totalCount,
		roundValuePrecision = 0
	} = props;

	const classTotal = useMemo(
		() => classData.reduce((acc, curr) => acc + curr.data, 0),
		[classData]
	);

	const orderedClassData = useMemo<OrderedClassData>(() => {
		let processedClassData;

		/**
		 * `fixed` ordering is the default, orderings other than `fixed` do not apply to
		 * comparison polls
		 */
		if (choiceOrdering === 'fixed' || !choiceOrdering) {
			processedClassData = classData;
		} else {
			/**
			 * if `sourceDatasetIndex` is not 0, then we need to sort by a dataset that is not class
			 */
			if (Number(sourceDatasetIndex)) {
				/**
				 * slice to remove header row
				 * sourceDatasetIndex - 1 because `externalData` does not include class data
				 */
				processedClassData = externalData
					.slice(1, externalData.length)
					.map((row, index) => ({
						choiceIndex: index,
						data: Number(row[Number(sourceDatasetIndex) - 1])
					}))
					.sort((a, b) => (choiceOrdering === 'popularity_asc' ? a.data - b.data : b.data - a.data))
					.map((d) => ({
						label: classData[d.choiceIndex].label,
						data: classData[d.choiceIndex].data
					}));
			} else {
				/**
				 * sort by class data
				 */
				processedClassData = [...classData].sort((a, b) =>
					choiceOrdering === 'popularity_asc' ? a.data - b.data : b.data - a.data
				);
			}
		}

		/**
		 * add `shortened_body`
		 */
		return processedClassData.map((d) => ({
			...d,
			shortened_body: choices.find((c) => c.body === d.label)?.shortened_body
		}));
	}, [choiceOrdering, choices, classData, externalData, sourceDatasetIndex]);

	/**
	 * Take class data and optional external data sets and shape them into a uniform format
	 * Not ordered !!!
	 */
	const shapedData = useMemo<ShapedPollData>(() => {
		const isPercentageMode = shapedDataMode === 'percentage';
		const isNumberMode = shapedDataMode === 'number';
		const isPercentageType = dataType === 'percentage';

		if (!externalData) {
			const classValueInMode = classData.map((d) =>
				isPercentageMode
					? [convertToRawPercentage(d.data, classTotal)]
					: [round(d.data, roundValuePrecision)]
			);
			return [['Your Class'], ...classValueInMode];
		}

		return externalData?.map((row, i) => {
			if (i === 0) {
				return ['Your Class', ...row];
			}

			const classValueInMode = isPercentageMode
				? convertToRawPercentage(classData[i - 1].data, classTotal)
				: round(classData[i - 1].data, roundValuePrecision);

			const externalDataValuesInMode = row.map((d, rowIndex) =>
				isNumberMode || isPercentageType
					? round(Number(d), roundValuePrecision)
					: convertToRawPercentage(Number(d), totalCount[rowIndex])
			);

			return [classValueInMode, ...externalDataValuesInMode];
		});
	}, [
		classTotal,
		dataType,
		externalData,
		classData,
		roundValuePrecision,
		shapedDataMode,
		totalCount
	]);

	/**
	 * If choiceOrdering is either 'popularity_asc' or 'popularity_desc'
	 * order shapedData by the sourceDataset.
	 * Used only for Poll chart (see ResultsBars.tsx).
	 * DownloadCSV and DataTable are using non ordered shapedData.
	 */
	const orderedShapedData = useMemo(() => {
		if (!externalData) return shapedData;

		if (choiceOrdering == 'popularity_asc' || choiceOrdering == 'popularity_desc') {
			const index = Number(sourceDatasetIndex);
			const sortedData = shapedData
				.slice(1) // slice to remove header row
				.sort((a, b) =>
					choiceOrdering === 'popularity_asc'
						? Number(a[index]) - Number(b[index])
						: Number(b[index]) - Number(a[index])
				);
			return [shapedData[0], ...sortedData];
		}

		return shapedData;
	}, [choiceOrdering, externalData, shapedData, sourceDatasetIndex]);

	/**
	 * Calculate the total percent for each dataset based on the cell content. Since with rounding
	 * the total may exceed 100%.
	 *
	 * See discussion im https://soomo.height.app/T-94409
	 */
	const totalPercentageSums = useMemo(
		() =>
			shapedData
				.slice(1)
				.reduce((acc, currentRow) => {
					const percentageValues = currentRow.map((colValue, colIdx) => {
						const { percentageCellContent } = calculateCellContent({
							colIdx,
							colValue: colValue as number,
							dataType,
							totalCount,
							classTotal
						});
						return parseFloat(percentageCellContent);
					});
					return acc.map((setValue, setIndex) => setValue + percentageValues[setIndex]);
				}, Array(shapedData[0].length).fill(0))
				.map((sum) => round(sum, 1)),
		[classTotal, dataType, shapedData, totalCount]
	);

	return {
		classTotal,
		orderedClassData,
		shapedData,
		orderedShapedData,
		totalPercentageSums
	} as const;
};
