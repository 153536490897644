import React from 'react';

import { Citations } from '../FillIn/types/ICitations';
import Outline from '../Outline';
import Table from '../Table';
import Text from '../Text';
import { TextOptions } from '../WritingTemplate/types';
import { prepareOutput } from './helpers';
import { paraStyle, viewModeText } from './styles';

export const getText = (
	value: string | Citations,
	key?: number,
	saveOnly?: boolean,
	options?: TextOptions[],
	turabianCounter?: () => string,
	alwaysInline?: boolean
): React.ReactElement<any, any> => {
	const inner = prepareOutput(value, turabianCounter);

	return (
		<span key={key} css={viewModeText}>
			<Text
				options={options}
				value={inner}
				saveOnly={saveOnly || false}
				alwaysInline={alwaysInline}
			/>
		</span>
	);
};

export const getValueOf = (
	value: string | Citations,
	key?: number,
	saveOnly?: boolean
): React.ReactElement<any, any> => {
	const inner = prepareOutput(value);

	return <Text key={`${key}-text`} value={inner} saveOnly={saveOnly || false} />;
};

export const getOutline = (
	variant,
	list,
	key?: number,
	fromView?: boolean
): React.ReactElement<any, any> => (
	<Outline key={key} variant={variant} list={list} fromView={fromView} />
);
export const getTable = (columnWidths, rows, key?: number): React.ReactElement<any, any> => (
	<Table key={key} columnWidths={columnWidths} rows={rows} isOutput />
);
export const getPara = (inner, key?: number): React.ReactElement<any, any> => (
	<p key={`${key}-para`} css={paraStyle}>
		{inner}
	</p>
);

export const listVariants = {
	0: 'upper-roman',
	1: 'upper-alpha',
	2: 'decimal'
};

export const copyToClipboard = (plainText, htmlText?) => {
	const listener = (e) => {
		e.clipboardData.setData('text/html', htmlText || plainText);
		e.clipboardData.setData('text/plain', plainText);
		e.preventDefault();
	};
	document.addEventListener('copy', listener);
	document.execCommand('copy');
	document.removeEventListener('copy', listener);
};
