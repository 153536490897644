import React from 'react';

import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { Props as TextProps } from '../../Text';
import { checkForSpecialTextContent } from '../../utils';
import { getGutterIconKey, withGutterIcon } from '../utils';
import WebtextCalloutStyles from './styles';

interface Props {
	element?: Element;
	textProps: TextProps;
}

const WebtextCallout: React.FC<Props> = (props) => {
	const { element, textProps } = props;
	const { family_id } = textProps.element;

	return (
		<div
			css={(theme) => WebtextCalloutStyles(theme, getGutterIconKey(element.attribs.class) != null)}>
			{domToReact([element], {
				replace: (element: Element) => {
					/**
					 * Don't render using `withGutterIcon` if the callout does not have an icon class
					 * in its class list.
					 */
					if (element.type !== 'tag' || !element.attribs.class || !element.children) {
						return;
					}
					if (getGutterIconKey(element.attribs.class)) {
						const content = domToReact(element.children, {
							replace: (domNode: Element) => checkForSpecialTextContent(domNode, textProps)
						});
						return withGutterIcon({
							element,
							content,
							key: `${family_id}-${element.startIndex}-icon`
						});
					} else {
						return (
							<>
								{domToReact(element.children, {
									replace: (domNode: Element) => {
										return checkForSpecialTextContent(domNode, textProps);
									}
								})}
							</>
						);
					}
				}
			})}
		</div>
	);
};

export default WebtextCallout;
