import * as React from 'react';

export function Compass(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={33.6}
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36 71a35 35 0 1 1 35-35 35 35 0 0 1-35 35Zm0-67.2a32.1 32.1 0 1 0 32.1 32.1A32.14 32.14 0 0 0 36 3.8Z"
				fill="currentColor"
			/>
			<circle cx={36} cy={36} r={29.7} fill="currentColor" />
			<path
				d="M41.7 17.7a4.1 4.1 0 0 0 .7-2.2 3.9 3.9 0 0 0-3.9-3.9h-5.1a3.82 3.82 0 0 0-3.8 3.9 3.6 3.6 0 0 0 .7 2.2 19.4 19.4 0 1 0 11.4 0Zm-8.3-.9a1.3 1.3 0 1 1 0-2.6h5.1a1.32 1.32 0 0 1 1.3 1.3 1.32 1.32 0 0 1-1.3 1.3h-5.1ZM36 53a16.8 16.8 0 1 1 16.8-16.8A17 17 0 0 1 36 53Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36 55.8a19.6 19.6 0 0 1-6.2-38.2 4.55 4.55 0 0 1-.5-2 4.06 4.06 0 0 1 4.1-4.1h5.1a4.06 4.06 0 0 1 4.1 4.1 4.55 4.55 0 0 1-.5 2A19.58 19.58 0 0 1 36 55.8Zm-2.6-43.9a3.65 3.65 0 0 0-3.6 3.6 3.08 3.08 0 0 0 .6 1.9l.2.3-.3.1A19.31 19.31 0 0 0 16.9 36a19.1 19.1 0 1 0 24.8-18.2l-.3-.1.2-.3a3.6 3.6 0 0 0-3-5.6h-5.2ZM36 53.2a17.1 17.1 0 1 1 17.1-17.1A17.16 17.16 0 0 1 36 53.2Zm0-33.5a16.5 16.5 0 1 0 16.5 16.5A16.52 16.52 0 0 0 36 19.7Zm2.5-2.6h-5.1a1.47 1.47 0 0 1-1.5-1.5 1.54 1.54 0 0 1 1.5-1.5h5.1a1.5 1.5 0 0 1 0 3Zm-5.1-2.6a.9.9 0 0 0-1.1.9.9.9 0 0 0 .9 1.1h5.4a.9.9 0 0 0 1.1-.9.9.9 0 0 0-.9-1.1h-5.4Z"
				fill="currentColor"
			/>
			<path
				d="M36 22a14.1 14.1 0 1 0 14.1 14.1A14.08 14.08 0 0 0 36 22ZM24.5 37.4h1.2a1.32 1.32 0 0 0 1.3-1.3 1.32 1.32 0 0 0-1.3-1.3h-1.2a11.68 11.68 0 0 1 3.2-6.8 1.36 1.36 0 0 0-.1 1.5l5.4 9.1c.1.2.3.3.4.4l9.1 5.4a1.37 1.37 0 0 0 1.5-.1 11.41 11.41 0 0 1-6.8 3.2v-1.2a1.32 1.32 0 0 0-1.3-1.3 1.32 1.32 0 0 0-1.3 1.3v1.2a11.29 11.29 0 0 1-10.1-10.1Zm8-4.7 4.4 2.6 2.6 4.4-4.4-2.6Zm11.7 11.5a1.2 1.2 0 0 0 .1-1.5l-5.4-9.1c-.1-.2-.3-.3-.4-.4l-9.1-5.4a1.36 1.36 0 0 0-1.5.1 11.41 11.41 0 0 1 6.8-3.2v1.2a1.32 1.32 0 0 0 1.3 1.3 1.32 1.32 0 0 0 1.3-1.3v-1.2a11.49 11.49 0 0 1 10.2 10.2h-1.2a1.32 1.32 0 0 0-1.3 1.3 1.32 1.32 0 0 0 1.3 1.3h1.2a11.55 11.55 0 0 1-3.3 6.7Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36 50.6a14.4 14.4 0 1 1 14.4-14.4A14.6 14.6 0 0 1 36 50.6Zm0-28.3a13.9 13.9 0 1 0 13.9 13.9A13.87 13.87 0 0 0 36 22.3Zm-1.1 25.6h-.3a11.8 11.8 0 0 1-10.4-10.4v-.3h1.5a1 1 0 1 0 0-2h-1.5v-.3a12.37 12.37 0 0 1 3.3-7l.4.4a1 1 0 0 0-.1 1.2l5.4 9.1c.1.1.2.3.3.3l9.1 5.4a1.06 1.06 0 0 0 1.2-.1l.3.4a12.09 12.09 0 0 1-6.9 3.3h-.3v-1.5a1 1 0 0 0-1-1 1.08 1.08 0 0 0-1 1ZM24.8 37.7a11.24 11.24 0 0 0 9.6 9.6v-.9a1.47 1.47 0 0 1 1.5-1.5 1.54 1.54 0 0 1 1.5 1.5v.9a11.35 11.35 0 0 0 5.5-2.4 1.42 1.42 0 0 1-.6-.2l-9.1-5.4a1.21 1.21 0 0 1-.5-.5l-5.4-9.1a1.42 1.42 0 0 1-.2-.6 11.35 11.35 0 0 0-2.4 5.5h.9a1.56 1.56 0 0 1 1.6 1.5 1.63 1.63 0 0 1-1.5 1.6h-.9Zm19.6 6.7-.4-.3a1 1 0 0 0 .1-1.2l-5.4-9.1-.3-.3-9.1-5.4a1.06 1.06 0 0 0-1.2.1l-.3-.4a12.09 12.09 0 0 1 6.9-3.3h.3V26a1 1 0 0 0 2 0v-1.5h.3a11.8 11.8 0 0 1 10.4 10.4v.3h-1.5a1 1 0 0 0 0 2h1.5v.3a11.5 11.5 0 0 1-3.3 6.9Zm-15.5-17a1.42 1.42 0 0 1 .6.2l9.1 5.4a1.21 1.21 0 0 1 .5.5l5.4 9.1a1.42 1.42 0 0 1 .2.6 11.35 11.35 0 0 0 2.4-5.5h-.9a1.5 1.5 0 0 1 0-3h.9a11.24 11.24 0 0 0-9.6-9.6v.9a1.47 1.47 0 0 1-1.5 1.5 1.54 1.54 0 0 1-1.5-1.5v-.9a10.34 10.34 0 0 0-5.6 2.3Zm11.4 13.1-5.4-3.3-3.2-5.3 5.4 3.3Zm-5-3.7 3.4 2.1-2.1-3.4-3.4-2.1Z"
				fill="currentColor"
			/>
		</svg>
	);
}
