import { css } from '@emotion/react';

import { mixins } from '~/styles/themes';

export const geoMapStyles = (theme) => css`
	position: relative;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQYV2NkQAJdXV3/GWF8EKesrIwRLADjgNiMyByQAADFeQ6iAsPAvQAAAABJRU5ErkJggg==)
		repeat;
	margin-bottom: 20px;

	svg {
		height: 100%;
		width: 100%;
	}

	path {
		stroke: white;
		cursor: pointer;
	}

	a:focus {
		outline: none;
	}

	.tooltip {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
		color: black;
	}

	.tooltip > div {
		padding: 0 5px;
		text-align: left;
		line-height: 1.4;
		max-width: 60%;
	}

	.legend {
		background: rgba(255, 255, 255, 0.7);
		padding: 3px;

		position: absolute;
		bottom: 0;
		right: 0;
		max-width: 40%;
	}

	.legend table {
		border-collapse: separate;
		border-spacing: 2px;
	}

	.legend-color {
		vertical-align: top;
	}

	.legend-name {
		padding-left: 5px;
		font-size: 13px;
		max-width: 300px;
	}

	.visually-hidden {
		${mixins.webtextHiddenAccessible(theme)}
	}

	strong {
		color: inherit;
	}
`;

export const descriptionTableStyles = (theme) => css`
	.webtext-figure-description {
		${mixins.webtextHiddenAccessible(theme)}
	}
`;
