import { css, SerializedStyles } from '@emotion/react';

import '@knight-lab/timelinejs/dist/css/themes/timeline.theme.contrast.css';
import { getThemeItem, Theme } from '~/styles/themes';

export const timelineStyles = (theme: Theme): SerializedStyles => {
	const timelineFontFamily = getThemeItem(theme.timeline.fontFamily, theme);

	const timelineHeadingFontFamily = getThemeItem(theme.timeline.heading.fontFamily, theme);
	const timelineHeadingFontSize = getThemeItem(theme.timeline.heading.fontSize, theme);
	const timelineHeadingLineHeight = getThemeItem(theme.timeline.heading.lineHeight, theme);

	const timelineMediaFontFamily = getThemeItem(theme.timeline.media.fontFamily, theme);

	const timelineSlidenavFontFamily = getThemeItem(theme.timeline.slidenav.fontFamily, theme);

	const timelineDateFontFamily = getThemeItem(theme.timeline.date.fontFamily, theme);

	const timelineSliderFontFamily = getThemeItem(theme.timeline.slider.fontFamily, theme);

	return css`
		height: 650px; // needed for offsetHeight value in TimelineJS
		margin-bottom: 20px; // adds space between Timeline and credits blocks

		.tl-timeline {
			font-family: ${timelineFontFamily} !important;
		}

		.tl-timeline h1,
		.tl-timeline h2,
		.tl-timeline h3,
		.tl-timeline h4,
		.tl-timeline h5,
		.tl-timeline h6 {
			font-family: ${timelineHeadingFontFamily} !important;
		}

		.tl-medium .tl-text h2.tl-headline {
			font-size: ${timelineHeadingFontSize} !important;
			line-height: ${timelineHeadingLineHeight} !important;
		}

		.tl-timeline p {
			font-family: ${timelineFontFamily} !important;
		}

		.tl-timeline ul {
			font-family: ${timelineFontFamily} !important;
		}

		.tl-timeline .tl-media .tl-media-wikipedia h4 a,
		.tl-timeline .tl-media h4,
		.tl-timeline .tl-media h4 a {
			font-family: ${timelineMediaFontFamily} !important;
		}

		.tl-timeline .tl-caption,
		.tl-timeline .tl-credit {
			font-family: ${timelineFontFamily} !important;
		}

		.tl-credit {
			text-align: left !important;
		}

		.tl-caption {
			margin-top: 5px !important;
		}

		.tl-timeline .vcard {
			font-family: ${timelineFontFamily} !important;
		}

		.tl-timeline .tl-slidenav-next,
		.tl-timeline .tl-slidenav-previous,
		.tl-timeline .tl-message,
		.tl-timeline .tl-timegroup-message {
			font-family: ${timelineSlidenavFontFamily} !important;
			word-break: break-word;
			text-transform: none;
		}

		.tl-timeline .tl-headline-date,
		.tl-timeline h3.tl-headline-date {
			font-family: ${timelineDateFontFamily} !important;
		}

		.tl-timeline .tl-timenav-slider {
			font-family: ${timelineSliderFontFamily} !important;
		}

		.tl-timeline .tl-timenav-slider h2.tl-headline {
			font-family: ${timelineSliderFontFamily} !important;
		}

		.tl-timeline .tl-timenav-slider .tl-timeaxis {
			font-family: ${timelineSliderFontFamily} !important;
		}

		.tl-timeline .tl-menubar {
			font-family: ${timelineFontFamily} !important;
		}

		.tl-timeline blockquote,
		.tl-timeline blockquote p {
			font-family: ${timelineFontFamily} !important;
		}

		input[type='text'].editor-headline {
			font-family: ${timelineFontFamily};
		}
	`;
};
