import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export default (theme) => {
	const isUniversalVelvet = theme.name === ThemeName.UNIVERSAL_VELVET;

	return css`
		.offline-unavailable {
			margin-bottom: 0;
			margin-top: 0;
			font-weight: bold;
		}

		.resource-title {
			padding-top: 7px;
		}

		.capitalized {
			text-transform: capitalize;
		}

		.container {
			font-family: sans-serif;
			background-color: ${theme.offline.backgroundColor};
			display: flex;
			align-items: center;
			justify-content: center;
			color: ${theme.colors.text};
			margin: 0 0 1em 0;
			font-weight: normal;
			text-align: left;
			column-gap: 12px;

			&.standalone {
				padding: 1em;
				border-radius: 3px;
			}

			&.addendum {
				/* border-top: 1px solid ${theme.colors['medium-gray']}; */
				background-color: inherit;
				padding: 1em 2em;
				align-items: flex-start;
				justify-content: left;

				@media (max-width: ${breakpoints.small}) {
					margin-top: 20px;
					padding: 0 18px 20px 18px;
				}
			}

			.offline-icon {
				font-size: 64px;
				line-height: 46px;
				color: #757575;
			}
		}

		${isUniversalVelvet &&
		css`
			.offline-unavailable {
				font-size: 1.5em;
				font-family: 'NHaasGroteskTXW01-75Bd', Helvetica, Arial, sans-serif;
				margin-bottom: 7px;
				line-height: 1.2;
			}

			.offline-you-must {
				margin-top: 7px;
				font-family: ${theme.fonts.app};
			}

			.resource-title {
				margin-top: 7px;
				padding: 0;
			}

			.container {
				.offline-icon {
					color: #f72235;
				}

				&.addendum {
					margin: 32px 0 0;
					padding: 0;
				}
			}
		`}
	`;
};
