import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import cn from 'classnames';

import { primarySourceWrapperStyles } from '~/components/pageElements/Text/components/PrimarySource/styles';
import { default as webtextCalloutStyles } from '~/components/pageElements/Text/components/WebtextCallout/styles';
import webtextExampleStyles from '~/components/pageElements/Text/components/WebtextExample/styles';

import { Spacer } from '..';
import PrimarySourceWrapper from './PrimarySourceWrapper';

interface Props {
	familyId: string;
	nestingInstructionClasses: string[];
	nestingInstructionElements: React.ReactElement[];
	primarySourceTitle?: string;
}

const Nesting: React.FC<Props> = (props) => {
	const { familyId, nestingInstructionClasses, nestingInstructionElements, primarySourceTitle } =
		props;

	const isPrimarySource = nestingInstructionClasses.includes('webtext-primary-source');

	const nestedContent = useMemo(
		() =>
			nestingInstructionElements.map((e, index) => [
				{ ...e, key: `${familyId}-element-${index}` },
				<Spacer key={`${familyId}-spacer-${index}`} />
			]),
		[nestingInstructionElements, familyId]
	);

	return (
		<div css={(theme) => styles(theme, nestingInstructionClasses)} nesting="">
			<div
				className={cn({
					'webtext-example-wrapper': nestingInstructionClasses.includes('webtext-example'),
					'webtext-callout-wrapper': nestingInstructionClasses.includes('webtext-callout'),
					'webtext-primary-source-wrapper':
						nestingInstructionClasses.includes('webtext-primary-source')
				})}>
				{isPrimarySource ? (
					<PrimarySourceWrapper
						primarySourceTitle={primarySourceTitle}
						nestingInstructionClasses={nestingInstructionClasses}>
						{nestedContent}
					</PrimarySourceWrapper>
				) : (
					<div className={cn('nested-content', nestingInstructionClasses)}>{nestedContent}</div>
				)}
			</div>
		</div>
	);
};

const styles = (theme: any, containerClasses: string[]) => {
	return css`
		${containerClasses.includes('webtext-example') && webtextExampleStyles(theme)}

		${containerClasses.includes('webtext-callout') &&
		css`
			.webtext-callout {
				${webtextCalloutStyles(theme, false)}
			}
		`}

		${containerClasses.includes('webtext-primary-source') &&
		css`
			.webtext-primary-source {
				${primarySourceWrapperStyles(theme)}
			}
		`}
	`;
};

export default Nesting;
