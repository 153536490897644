export type BatchApplyFn<T extends Record<string, unknown>> = (collection: T) => void;

export class BatchProcessor<T extends Record<string, unknown>> {
	#applyFn: BatchApplyFn<T>;
	#collection: T;

	constructor(applyFn: BatchApplyFn<T>, initialValue?: T) {
		this.#applyFn = applyFn;
		this.#collection = initialValue || ({} as T);
	}

	set applyFn(applyFn: BatchApplyFn<T>) {
		this.#applyFn = applyFn;
	}

	add(item: T): void {
		this.#collection = { ...this.#collection, ...item };
	}

	apply(): void {
		this.#applyFn(this.#collection);
	}

	empty(): void {
		this.#collection = {} as T;
	}
}
