import React, { ReactElement } from 'react';

import { SerializedStyles } from '@emotion/react';

import { shouldSplitText } from '../helpers';
import { elementWrapper } from '../styles';
import { BuilderElementConfig } from '../types';
// eslint-disable-next-line import/no-cycle
import BuilderElement from './BuilderElement';

interface Props {
	builderElement: BuilderElementConfig;
	inlineSelectorIndex?: number;
	isInlineView: boolean;
	parentPresentation: string;
	isTable: boolean;
	listDepth: number;
	multipleInlineSelectors?: boolean;
	priorElement: BuilderElementConfig;
	/** TODO: remove this props */
	extraCSS?: SerializedStyles;
	isNextText?: boolean;
	onParseOldOutline: (component: unknown, listDepth?: number) => ReactElement;
	resetScaleImageFailure?: () => void;
}

const BuilderElementContainer: React.FC<Props> = (props) => {
	const {
		isInlineView,
		extraCSS,
		isNextText,
		builderElement,
		inlineSelectorIndex,
		parentPresentation,
		isTable,
		listDepth,
		multipleInlineSelectors = false,
		priorElement,
		onParseOldOutline,
		resetScaleImageFailure
	} = props;

	const builderComponent = (
		<BuilderElement
			builderElementConfig={builderElement}
			parentPresentation={parentPresentation}
			inlineSelectorIndex={inlineSelectorIndex}
			isTable={isTable}
			listDepth={listDepth}
			multipleInlineSelectors={multipleInlineSelectors}
			priorElement={priorElement}
			onParseOldOutline={onParseOldOutline}
			resetScaleImageFailure={resetScaleImageFailure}
		/>
	);

	return isInlineView ? (
		builderComponent
	) : (
		<div
			css={elementWrapper(
				builderElement,
				shouldSplitText(builderElement, parentPresentation),
				isNextText,
				extraCSS
			)}>
			{builderComponent}
		</div>
	);
};
export default BuilderElementContainer;
