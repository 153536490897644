import { css } from '@emotion/react';

export default (theme) => {
	const voiceRecording = theme.voiceRecording;
	return css`
		.transcript {
			padding-left: 15px;
			border-left: 4px solid ${voiceRecording.colors.primary};
			margin: 20px 0 20px 20px;
			font-size: 14px;
			color: black;
			font-weight: normal;
		}

		.transcript-disclaimer {
			font-size: 14px;
			color: black;
			font-weight: normal;
		}

		.mejs__video {
			display: none;
		}

		.mejs__container {
			margin-left: auto;
			margin-right: auto;
			display: block;
			max-width: 400px;
			min-width: 241px;
			border-radius: 23px;
			.mejs__mediaelement {
				background: inherit;
			}
			.mejs__controls {
				background: inherit !important;
			}
		}

		.notebook-element {
			.mejs__container {
				max-width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	`;
};
