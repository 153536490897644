import * as React from 'react';

export function Quote(): JSX.Element {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 37 30"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg">
			<title>“</title>
			<g id="r" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Header-+-Text-Styles---Desktop"
					transform="translate(-477.000000, -4122.000000)"
					fill="currentColor"
					fillRule="nonzero">
					<g id="Group" transform="translate(477.000000, 4116.000000)">
						<path
							d="M15.3,35.9 L15.3,21 L8.5,21 C8.5,16.6 11.7,13.2 15.6,12 L15.6,6.2 C11.6,6.6 7.4,8.4 4.7,11.2 C2,13.9 0.4,17.6 0.4,22.8 L0.4,35.9 L15.3,35.9 Z M36.3,35.9 L36.3,21 L29.5,21 C29.5,16.6 32.7,13.2 36.6,12 L36.6,6.2 C32.6,6.6 28.4,8.4 25.7,11.2 C23,13.9 21.4,17.6 21.4,22.8 L21.4,35.9 L36.3,35.9 Z"
							id="“"></path>
					</g>
				</g>
			</g>
		</svg>
	);
}
