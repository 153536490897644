import React from 'react';
import { connect } from 'react-redux';
import { Page } from 'Types/index';
import { PageElement } from '@soomo/lib/types/index';

interface ConnectProps {
	chapter: {
		page_ids: string[];
	};
	page: Page;
}

interface Props {
	elementsByType: {
		[key: string]: PageElement[];
	};
	popupQuizzesByType: {
		[key: string]: PageElement[];
	};
}

const QuestionCountStats: React.FC<Props> = (props) => {
	const { elementsByType, popupQuizzesByType } = props;
	if (!elementsByType) {
		return null;
	}

	const mcQuestionCount =
		(elementsByType['mc_question']?.length || 0) +
		(elementsByType['mc_question_pool']?.length || 0);

	const counts = [];

	if (mcQuestionCount > 0) counts.push(['Multiple Choice', mcQuestionCount]);

	if (elementsByType.poll_question?.length > 0)
		counts.push(['Poll', elementsByType.poll_question.length]);

	if (elementsByType.response_board?.length > 0)
		counts.push(['Response Board', elementsByType.response_board.length]);

	if (elementsByType.sa_question?.length > 0)
		counts.push(['Short Answer', elementsByType.sa_question.length]);

	if (elementsByType.interactive_template?.length > 0)
		counts.push(['Template', elementsByType.interactive_template.length]);

	if (popupQuizzesByType) {
		Object.entries(popupQuizzesByType).map(([key, elements]) => {
			counts.push([key, elements.length]);
		});
	}

	if (elementsByType.voice_recording?.length > 0)
		counts.push(['Voice Recording', elementsByType.voice_recording.length]);

	if (counts.length === 0) {
		return null;
	}

	return (
		<>
			<span className="label">Questions:</span>
			<span>
				{counts.map(([type, count], index) => (
					<React.Fragment key={type}>
						{index > 0 ? ', ' : ''}
						<strong>{count}</strong> {type}
					</React.Fragment>
				))}
			</span>
		</>
	);
};

const mapStateToProps = (state: any, props: ConnectProps) => {
	const { chapter, page } = props;
	const page_elements = {};
	const tocConfig = state.entities.toc.config;
	const popupQuizConfigs = {};

	if (chapter) {
		chapter.page_ids.forEach((page_id) => {
			const page = state.entities.pages[page_id];
			page_elements[page.id] = {};

			page.element_ids.forEach((id) => {
				const element = state.entities.elements[id];
				page_elements[page.id][id] = element;

				if (element.type === 'self_assessment') {
					popupQuizConfigs[id] = tocConfig.pages.types[page.page_type]?.elements['PopupQuiz'] || {};
				}
			});
		});
	} else if (page) {
		page_elements[page.id] = {};
		page.element_ids.forEach((id) => {
			const element = state.entities.elements[id];
			page_elements[page.id][id] = element;

			if (element.type === 'self_assessment') {
				popupQuizConfigs[id] = tocConfig.pages.types[page.page_type]?.elements['PopupQuiz'] || {};
			}
		});
	}

	const elementsByType = {};
	Object.values(page_elements).forEach((page_elements) => {
		Object.values(page_elements).forEach((element) => {
			if (!element) return;

			elementsByType[element.type] ||= [];
			elementsByType[element.type].push(element);
		});
	});

	const popupQuizzes = elementsByType['self_assessment'] || [];
	const popupQuizzesByType = popupQuizzes.reduce((acc, puq) => {
		const heading_text = popupQuizConfigs[puq.id].heading_text || 'Popup Quiz';

		acc[heading_text] ||= [];
		acc[heading_text].push(puq);
		return acc;
	}, {});

	return {
		elementsByType,
		popupQuizzesByType
	};
};

export default connect(mapStateToProps)(QuestionCountStats);
