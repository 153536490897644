import React, { useEffect } from 'react';

import { ClassNames } from '@emotion/react';

import { QuestionPrompt } from '~/components/shared/Question';
import {
	selectCollapsed,
	useDeckCollapseSelector as useCollapseSelector
} from '~/components/shared/QuestionDeck/DeckCollapseStateProvider';
import { useAccessibilityFocus } from '~/hooks';
import { getAttemptsRemaining } from '~/utils/policy';

import CorrectIcon from './components/CorrectIcon';
import DeckedMCQuestionPool from './components/DeckedMCQuestionPool';
import IncorrectIcon from './components/IncorrectIcon';
import StudentViewBelowTheFold from './components/StudentViewBelowTheFold';
import { useStudentView } from './hooks/useStudentView';
import SpeedbumpInterventions from './speedbump/SpeedbumpInterventions';
import { promptStyles } from './styles';

import type {
	DeckedMCQuestionPoolInjectedProps,
	DisplayedQuestionElementProps,
	StudentViewElementProps
} from './types';

type Props = StudentViewElementProps &
	DeckedMCQuestionPoolInjectedProps &
	DisplayedQuestionElementProps;

const DeckedStudentView: React.VFC<Props> = (props) => {
	const { attemptsAllowed, deckIndex, onInitialLoadComplete, onVisibilityChange } = props;

	const [buttonRef, setFocusToButton] = useAccessibilityFocus();
	const collapsed = useCollapseSelector((state) => selectCollapsed(state, deckIndex));

	const { speedbumpInterventionsProps, ...useStudentViewProps } = useStudentView(
		props,
		setFocusToButton
	);
	const { activeQuestion, answer, resetCount } = useStudentViewProps;

	useEffect(() => {
		onInitialLoadComplete?.({ familyId: activeQuestion.family_id, collapsed: !collapsed });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const attemptsRemaining = getAttemptsRemaining({
		attemptsAllowed,
		resetCount,
		isAnswered: answer != null
	});

	return (
		<DeckedMCQuestionPool
			{...props}
			ref={buttonRef}
			iconSlot={
				answer != null &&
				(answer.correct ? (
					<CorrectIcon />
				) : (
					<>
						<IncorrectIcon />
						{attemptsRemaining === 0 && (
							<span className="visually-hidden">No attempts remaining. </span>
						)}
					</>
				))
			}
			onVisibilityChange={onVisibilityChange}
			activeQuestion={activeQuestion}
			promptSlot={
				<ClassNames>
					{({ cx }) => (
						<QuestionPrompt
							css={promptStyles}
							className={cx({
								'answered-question':
									collapsed && answer != null && (attemptsRemaining <= 0 || answer.correct)
							})}
							body={activeQuestion.body}
						/>
					)}
				</ClassNames>
			}>
			<StudentViewBelowTheFold {...props} {...useStudentViewProps} />
			<SpeedbumpInterventions {...speedbumpInterventionsProps} />
		</DeckedMCQuestionPool>
	);
};

export default DeckedStudentView;
