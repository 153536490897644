import React, { FC } from 'react';

import { first } from 'lodash-es';

import { ExpandedViewModal, ExpandedViewModalProps } from '.';

const ExpandedImageModal: FC<
	Omit<ExpandedViewModalProps<never>, 'children'> & { payload: string }
> = (props) => {
	const { title, payload, returnFocusRef, onClose } = props;

	return (
		<ExpandedViewModal<HTMLImageElement>
			returnFocusRef={returnFocusRef}
			title={title}
			ariaLabel={'Expanded view of image' + (title ? `: ${title}` : '')}
			onClose={onClose}>
			{({ elementRef }) => (
				<div
					ref={(el) => {
						const image = first(el?.getElementsByTagName('img'));
						if (!image) return;

						image.ondragstart = (event) => event.preventDefault();

						elementRef.current = image;

						const { width, height } = image.getBoundingClientRect();
						el.style.overflow = 'hidden';
						el.style.width = `${width}px`;
						el.style.height = `${height}px`;
					}}
					dangerouslySetInnerHTML={{ __html: payload }}
				/>
			)}
		</ExpandedViewModal>
	);
};

export default ExpandedImageModal;
