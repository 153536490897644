import React, { forwardRef, useCallback } from 'react';

import { css } from '@emotion/react';

import { CollapseIcon, ExpandIcon } from '~/components/shared/QuestionDeck';
import {
	selectCollapsed,
	useDeckCollapseSelector as useCollapseSelector
} from '~/components/shared/QuestionDeck/DeckCollapseStateProvider';
import { deckStyles } from '~/components/shared/QuestionDeck/deckedStyles';
import { breakpoints, mixins } from '~/styles/themes';

import { DeckedMCQuestionPoolInjectedProps, DisplayedQuestionElementProps } from '../types';
import {
	getMCQuestionPoolId,
	getMCQuestionPoolPromptBodyId,
	getMCQuestionPoolExpandingContentId
} from '../utils';

import type { MCQuestionPoolItem, MCQuestionPoolElement } from '~/types/WebtextManifest';

interface Props extends DeckedMCQuestionPoolInjectedProps {
	questionPool: MCQuestionPoolElement;
	iconSlot: React.ReactNode;
	promptSlot: React.ReactNode;
	onVisibilityChange?: DisplayedQuestionElementProps['onVisibilityChange'];
	activeQuestion: MCQuestionPoolItem;
}

/**
 * Container component for a decked multiple-choice question pool.
 *
 * Implements the [WAI-ARIA Disclosure pattern](https://www.w3.org/WAI/ARIA/apg/patterns/disclosure).
 */
const DeckedMCQuestionPool = forwardRef<HTMLButtonElement, React.PropsWithChildren<Props>>(
	(props, ref) => {
		const {
			questionPool,
			iconSlot,
			promptSlot,
			deckIndex,
			children,
			onVisibilityChange,
			activeQuestion
		} = props;

		const containerId = getMCQuestionPoolId(questionPool.family_id);
		const promptId = getMCQuestionPoolPromptBodyId(questionPool.family_id);
		const expandingContentId = getMCQuestionPoolExpandingContentId(questionPool.family_id);

		const collapsed = useCollapseSelector((state) => selectCollapsed(state, deckIndex));
		const toggleCollapse = useCollapseSelector((state) => state.toggleCollapse);
		const handleToggleCollapse = useCallback(() => {
			toggleCollapse(deckIndex);
			onVisibilityChange?.({
				familyId: activeQuestion.family_id,
				scope: 'toggle_item',
				collapsed: !collapsed
			});
		}, [deckIndex, toggleCollapse, onVisibilityChange, activeQuestion, collapsed]);

		return (
			<div id={containerId} css={[deckStyles, mcqpDeckStyleOverrides]}>
				<button
					className="prompt-and-pivotar"
					aria-expanded={!collapsed}
					aria-controls={expandingContentId}
					onClick={handleToggleCollapse}
					ref={ref}>
					<span css={mixins.webtextHiddenAccessible}>Question {deckIndex + 1}.&nbsp;</span>
					<div className="icon-and-prompt">
						<div className="icon-container">{iconSlot}</div>
						<div id={promptId} className="mc-question-pool-prompt">
							{promptSlot}
						</div>
					</div>
					{collapsed ? <ExpandIcon /> : <CollapseIcon />}
				</button>
				<div
					id={expandingContentId}
					hidden={collapsed}
					role="group"
					aria-labelledby={promptId}
					css={belowTheFoldStyles}>
					{children}
				</div>
			</div>
		);
	}
);
DeckedMCQuestionPool.displayName = 'DeckedMCQuestionPool';

export default DeckedMCQuestionPool;

const mcqpDeckStyleOverrides = css`
	.prompt-and-pivotar {
		align-items: flex-start;

		.icon-and-prompt {
			display: flex;
			flex-grow: 1;
			user-select: text;

			.mc-question-pool-prompt {
				flex-grow: 1;
			}
		}

		.icon-container {
			position: absolute;
			left: -13px;
			display: inline-flex;
			margin-right: 0.75rem;
			align-items: baseline;
			font-size: 18px;
			line-height: 30px;
			font-weight: 500;
			font-style: italic;
			column-gap: 0.5rem;

			svg {
				width: 27px;
				height: 27px;
			}
		}
	}
`;

const belowTheFoldStyles = css`
	margin: 0 2rem 1.5rem 1.5rem;

	@media (max-width: ${breakpoints.small}) {
		margin-right: 1.25rem;
	}
`;
