import React from 'react';

import { css } from '@emotion/react';
import axios from 'axios';
import debounce from 'lodash-es/debounce';
import WaveSurfer from 'wavesurfer.js';

import { Theme } from '~/styles/themes';

import { Play, Pause, SkipBackward5s, SkipForward5s } from './Icons';
import Loading from './Loading';
import { formatTimeElapsed, formatTimeRemaining } from './TimeFormatting';

const styles = (theme) => css`
	.waveform-audio-player {
		width: 100%;
		background: ${theme.colors.secondary};

		.audio-controls {
			user-select: none;
			height: 50px;
			width: calc(100%-20px);
			background: ${theme.colors.primary};
			padding: 10px;
		}

		.left {
			float: left;
		}

		.right {
			float: right;
		}

		.audio-button-container {
			width: 60%;
			color: ${theme.colors.white};
			text-align: center;
			cursor: default;
		}

		.audio-button {
			display: inline-block;
			margin: 0 10px;
			border: 1px solid transparent;
			background: none;
		}

		.duration {
			display: inline-block;
			width: 20%;
			color: ${theme.colors.white};
			font-family: 'HelveticaNeueW01-45Ligh', Helvetica, Arial, sans-serif;
			font-size: 14px;
			line-height: 30px;
			min-height: 1px;
		}

		.text-right {
			text-align: right;
		}

		.text-left {
			text-align: left;
		}

		.duration > span {
			font-size: smaller;
		}

		.clear {
			clear: both;
		}

		.waveform-container {
			position: relative;
		}

		.waveform-bottom {
			position: absolute;
			z-index: 0;
			left: 0;
			top: 50%;
			bottom: 0;
			background-color: ${theme.waveformAudioPlayer.colors.background};
			right: 0;
			pointer-events: none;
		}
	}
`;

export interface Props {
	familyId: string;
	theme: Theme;
	src: string;
	waveformUrl: string;
	onPlay: () => void;
	onError?: (error: Error) => void;
	className?: string;
}

interface State {
	wavesurfer: WaveSurfer;
	wavesurferLoading: boolean;
	wavesurferReady: boolean;
	duration: number;
	playing: boolean;
	snippetStart: number;
	snippetEnd: number;
	timeElapsed: number;
}

class WaveformAudioPlayer extends React.Component<Props, State> {
	wavesurferRef: React.RefObject<HTMLDivElement> = React.createRef();

	state: State = {
		wavesurfer: null,
		wavesurferLoading: false,
		wavesurferReady: false,
		duration: null,
		playing: false,
		snippetStart: null,
		snippetEnd: null,
		timeElapsed: 0
	};

	componentDidMount() {
		const { theme, src, waveformUrl } = this.props;

		const options = {
			container: this.wavesurferRef.current,
			waveColor: theme.waveformAudioPlayer.colors.wave,
			progressColor: theme.waveformAudioPlayer.colors.progress,
			cursorColor: theme.waveformAudioPlayer.colors.progress,
			cursorWidth: 2,
			barWidth: 2,
			hideScrollbar: true,
			normalize: true,
			backend: 'MediaElement'
		};

		const wavesurfer = WaveSurfer.create(options);

		const wave = this.wavesurferRef.current.querySelector('wave') as HTMLDivElement;
		wave.style.display = 'none';
		const displayWave = () => {
			wave.style.display = 'block';
		};

		wavesurfer.on('ready', () => {
			const durationInSeconds = this.state.wavesurfer.getDuration();
			if (isNaN(durationInSeconds)) {
				throw new Error('Cannot compute duration.');
			} else {
				this.setState({
					wavesurferLoading: false,
					wavesurferReady: true,
					duration: durationInSeconds * 1000
				});
				if (this.props.waveformUrl != null) {
					displayWave();
				}
			}
		});

		wavesurfer.on('waveform-ready', displayWave);

		wavesurfer.on('audioprocess', (timeElapsedInFractionalSeconds) => {
			this.setState({ timeElapsed: timeElapsedInFractionalSeconds * 1000 });
		});

		wavesurfer.on('error', (errorMessage) => {
			console.error(errorMessage); // WEB-4450. Always log Wavesurfer errors.

			const error = new Error('Wavesurfer error - ' + errorMessage);

			if (errorMessage === 'Error loading media element') {
				if (wavesurfer.backend && wavesurfer.backend.media) {
					error.cause = wavesurfer.backend.media.error;
					console.error(error.cause);
				}
			}

			if (this.props.onError) {
				this.props.onError(error);
			} else {
				throw error;
			}
		});

		wavesurfer.on('play', () => {
			this.setState({ playing: true });
			this.props.onPlay();
		});

		wavesurfer.on('seek', () => {
			this.updateTimeElapsed();
		});

		wavesurfer.on('finish', () => {
			this.state.wavesurfer.seekTo(0);
			this.setState({ playing: false, timeElapsed: 0 });
		});

		wavesurfer.on('pause', () => {
			const { snippetStart, snippetEnd } = this.state;
			if (snippetStart && snippetEnd) {
				const snippetDuration = snippetEnd - snippetStart;
				this.state.wavesurfer.skip(snippetDuration);
				this.updateTimeElapsed();
			}
			this.setState({ playing: false, snippetStart: null, snippetEnd: null });
		});

		this.setState({ wavesurferLoading: true });
		if (waveformUrl != null) {
			axios
				.get(waveformUrl)
				.then((waveform) => {
					if (this.state.wavesurfer) {
						this.state.wavesurfer.load(src, waveform.data.data);
					}
				})
				.catch((error) => {
					if (this.props.onError) {
						const wrappedError = new Error('Failed to fetch waveform data');
						wrappedError.cause = error;
						this.props.onError(wrappedError);
					} else {
						throw error;
					}

					if (this.state.wavesurfer) {
						this.state.wavesurfer.load(src);
					}
				});
		} else {
			wavesurfer.load(src);
		}

		this.setState({ wavesurfer });
	}

	componentDidUpdate(prevProps) {
		const { theme } = this.props;
		const { wavesurfer } = this.state;

		if (prevProps.theme.name !== theme.name) {
			wavesurfer.setCursorColor(theme.waveformAudioPlayer.colors.progress);
			wavesurfer.setProgressColor(theme.waveformAudioPlayer.colors.progress);
			wavesurfer.setWaveColor(theme.waveformAudioPlayer.colors.wave);
		}
	}

	componentWillUnmount() {
		if (this.state.wavesurfer) {
			this.state.wavesurfer.destroy();
			this.setState({ wavesurfer: null });
		}
	}

	/**
	 * Most browsers scroll the page when the spacebar is pressed,
	 * so we must prevent that from happening when we're activating
	 * one of our buttons.
	 */
	onKeyDown: React.KeyboardEventHandler = (event) => {
		if (event.code === 'Space' || event.code === 'Enter') {
			event.preventDefault();
		}
	};

	onKeyUp = (action: VoidFunction): React.KeyboardEventHandler => {
		return function (event) {
			if (event.code === 'Space' || event.code === 'Enter') {
				event.preventDefault();
				action();
			}
		};
	};

	updateTimeElapsed = () => {
		debounce(() => {
			this.setState({ timeElapsed: this.state.wavesurfer.getCurrentTime() * 1000 });
		}, 85);
	};

	pause = () => {
		if (this.state.playing) {
			this.playPause();
		}
	};

	skipBackward = () => {
		this.setState({ snippetStart: null, snippetEnd: null });
		this.state.wavesurfer.skip(-5);
		this.updateTimeElapsed();
	};

	skipForward = () => {
		this.setState({ snippetStart: null, snippetEnd: null });
		this.state.wavesurfer.skip(5);
		this.updateTimeElapsed();
	};

	playPause = () => {
		this.setState({ snippetStart: null, snippetEnd: null });
		this.state.wavesurfer.playPause();
	};

	playSnippet = (start, end) => {
		if (this.state.wavesurfer) {
			this.state.wavesurfer.play(start, end);
		}
	};

	render() {
		const { className } = this.props;
		const { wavesurferReady, playing, duration, timeElapsed } = this.state;
		const timeRemaining = duration - timeElapsed;

		return (
			<div css={styles} className={className}>
				<div className="waveform-audio-player">
					{!wavesurferReady && <Loading />}
					<div className="waveform-container">
						<div ref={this.wavesurferRef}></div>
						{wavesurferReady && <div className="waveform-bottom"></div>}
					</div>
					{wavesurferReady && (
						<div className="audio-controls">
							<div className="duration left text-left">{formatTimeElapsed(timeElapsed)}</div>
							<div className="audio-button-container left">
								<button
									className="audio-button skip-backward-button"
									onClick={this.skipBackward}
									onKeyDown={this.onKeyDown}
									onKeyUp={this.onKeyUp(this.skipBackward)}
									aria-label="skip backward five seconds">
									<SkipBackward5s />
								</button>
								<button
									className="audio-button play-pause-button"
									onClick={this.playPause}
									onKeyDown={this.onKeyDown}
									onKeyUp={this.onKeyUp(this.playPause)}
									aria-label={playing ? 'pause' : 'play'}>
									{playing ? <Pause /> : <Play />}
								</button>
								<button
									className="audio-button skip-forward-button"
									onClick={this.skipForward}
									onKeyDown={this.onKeyDown}
									onKeyUp={this.onKeyUp(this.skipForward)}
									aria-label="skip forward five seconds">
									<SkipForward5s />
								</button>
							</div>
							<div className="duration right text-right">{formatTimeRemaining(timeRemaining)}</div>
							<div className="clear"></div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default WaveformAudioPlayer;
