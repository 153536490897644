import React from 'react';

import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { WebtextPullQuoteStyles } from '.';
import { Props as TextProps } from '../../Text';
import { checkForSpecialCase } from '../../utils';

interface Props {
	element: Element;
	textProps: TextProps;
}

const WebtextPullQuote: React.FC<Props> = (props) => {
	const { element, textProps } = props;
	return (
		<div css={WebtextPullQuoteStyles}>
			{domToReact([element], {
				replace: (domNode: Element) => checkForSpecialCase(domNode, textProps)
			})}
		</div>
	);
};

export default WebtextPullQuote;
