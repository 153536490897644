import React from 'react';

interface Props {
	className?: string;
	disabled: boolean;
	'aria-label': string;
	'aria-disabled': boolean;
	'aria-pressed': boolean;
	onClick: () => void;
}

const ToolbarButton: React.FC<Props> = (props) => {
	const { children, className, ...propsRest } = props;
	return (
		<button className={`toolbar-item ${className}`} {...propsRest} data-ignore="1">
			{children}
		</button>
	);
};

export default ToolbarButton;
