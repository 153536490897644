import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export const simpleSelect = (theme, selected, presentation, parent) => css`
	display: ${presentation};
	font-size: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '18px' : '16px'};
	font-family: ${theme.fonts.app};
	color: ${selected ? '#000' : '#6e6e6e'};
	line-height: 1.3;
	padding: 9px 14px;
	${presentation === 'block' || parent !== 'inline' ? 'width: 100%;' : null}
	max-width: 100%;
	box-sizing: border-box;
	margin: 0.3em 0;
	border: 1px solid #d7d7d7;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
	border-radius: 0.5em;
	background-color: #fcfcfc;
	text-overflow: ellipsis;
	overflow: hidden;

	@media (max-width: ${breakpoints.small}) {
		font-size: 16px;
	}

	&:hover {
		border-color: #888;
		transition: 0.2s;
	}

	&:focus {
		border-color: #888;
		color: #000;
		outline: none;
	}

	option {
		font-weight: normal;
		background-color: #e6e6e6;
		color: #000;
		padding: 1em;
	}
`;
