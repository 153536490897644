import { useCallback, useEffect } from 'react';

import { getOS } from '~/utils';

export const useUnsavedChangesWarning = (isDirty: boolean): void => {
	const isIOS = getOS() === 'ios';

	const unsavedChangesWarning = useCallback((e) => {
		e.preventDefault();
		/**
		 * Chrome needs return value to be set, even if it's not shown;
		 * see https://stackoverflow.com/questions/45088861/whats-the-point-of-beforeunload-returnvalue-if-the-message-does-not-get-set
		 *
		 * Also, the `return ''` doesn't work on Chrome, but works for other browsers
		 * The `return (e.returnValue = '')` works for all of them
		 */
		return (e.returnValue = '');
	}, []);

	/* This is necessary because iOS browsers annoyingly don't emit beforeunload,
	 * so we have to manually recreate the behavior as much as possible by applying
	 * event listeners to every link/navigation control that we create, and using
	 * this confirm
	 */
	const iOSUnsavedChangesConfirmation = useCallback((e) => {
		if (!window.confirm('Your changes have not been saved.')) {
			e.preventDefault();
		}
	}, []);

	useEffect(() => {
		if (isIOS) return;

		if (isDirty) {
			window.addEventListener('beforeunload', unsavedChangesWarning);
			return () => window.removeEventListener('beforeunload', unsavedChangesWarning);
		} else {
			window.removeEventListener('beforeunload', unsavedChangesWarning);
		}
	}, [isDirty, isIOS, unsavedChangesWarning]);

	useEffect(() => {
		if (!isIOS) return;

		getNavLinkElements().forEach((element) => {
			if (isDirty) {
				element.addEventListener('click', iOSUnsavedChangesConfirmation);
			} else {
				element.removeEventListener('click', iOSUnsavedChangesConfirmation);
			}
		});

		function getNavLinkElements(): Array<Element> {
			return [document.querySelectorAll('nav a'), document.querySelectorAll('a.nav-links')].flatMap(
				(nodeList) => Array.from(nodeList).map((element) => element)
			);
		}
	}, [isDirty, isIOS, iOSUnsavedChangesConfirmation]);
};
