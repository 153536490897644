import React from 'react';

import { css } from '@emotion/react';
import cn from 'classnames';
import { scaleOrdinal } from 'd3-scale';

import { patternClasses, strokePatterns } from '../PatternsSvg';

const patternScale: any = scaleOrdinal().range(patternClasses);

interface Props {
	seriesLabels: string[];
	colors: string[];
	monochrome: boolean;
	fill: boolean;
	className?: string;
}

const ChartLegend: React.FC<Props> = (props) => {
	const { seriesLabels, colors, monochrome, fill, className } = props;
	const colorScale = scaleOrdinal<any, any>().range(colors);

	return (
		<div css={styles} className={className}>
			{seriesLabels.map((label, idx) => {
				const {
					stroke_dasharray: strokeDasharray,
					stroke_width: strokeWidth,
					stroke
				} = strokePatterns[idx] || {};

				return (
					<div key={idx} className="legend-item">
						<svg
							className={cn('swatch', monochrome ? 'monochrome' : 'color')}
							height="100%"
							width="100%"
							viewBox={`0 0 40 40`}
							xmlns="http://www.w3.org/2000/svg">
							{!monochrome && <rect height="40" width="40" fill={colorScale(idx)} />}
							{monochrome &&
								(fill ? (
									<rect
										height="40"
										width="40"
										fill={`url('#${patternScale(idx)}')`}
										stroke="black"
									/>
								) : (
									<line
										strokeDasharray={strokeDasharray}
										strokeWidth={strokeWidth}
										x1="0"
										y1="20"
										x2="40"
										y2="20"
										stroke={stroke}
									/>
								))}
						</svg>
						<span className="legend-label">{label}</span>
					</div>
				);
			})}
		</div>
	);
};

const styles = (theme) => css`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;

	${theme.name === 'universal_velvet' &&
	css`
		font-family: ${theme.fonts.app};
	`}

	.legend-item {
		display: flex;
		align-items: center;
		margin-right: 1em;
		margin-bottom: 0.85em;

		.swatch {
			display: inline-block;
			width: 1.1em;
			height: 1.1em;
			flex-shrink: 0;

			&.color {
				border-radius: 15%;
			}
		}

		.legend-label {
			margin-left: 0.5em;
		}
	}
`;

export default ChartLegend;
