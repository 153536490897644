import { isNumeric } from '~/utils/parsing';

import { getFormattedValue } from '../../Components/Cell/utils';
import { CellFormatting, FormatType, FormulaResult } from '../types';

/**
 * function return is [unformatted result, formatted result?]
 */
export const getFormulaValue = (props: {
	cellValue: string;
	formulaResult: FormulaResult;
	cellFormatting?: CellFormatting;
}): string[] => {
	const { formulaResult, cellValue, cellFormatting = {} } = props;
	const { success, result } = formulaResult;

	if (!success) {
		return result ? [`${result}`] : [` ${cellValue}`];
	}

	return [`${result}`, getFormattedValue(result, cellFormatting)];
};

// To separate between the (400) and the -400 when written by the user
export const transformAccounting = (text: string, unary?: boolean): string => {
	const value = text.slice(1, -1);
	return !unary ? `-${value}` : `(${value})`;
};

export const isAccountingNegative = (value: string, cellFormatting?: CellFormatting): boolean =>
	isRawAccNegative(value) && cellFormatting?.type === FormatType.Accounting;

const isRawAccNegative = (value: string): boolean =>
	typeof value === 'string' &&
	value?.startsWith('(') &&
	value?.endsWith(')') &&
	isNumeric(value?.slice(1, -1));
