import { Component, default as React } from 'react';
import { FaCheck as Check } from 'react-icons/fa';

import { type DateTime } from 'luxon';

import { GenericErrorBoundary } from '~/components';
import { Indeterminate } from '~/components/Loaders';
import { JobStatus, type LmsSubmissionProgress } from '~/types';
import { formatTimeFromNow } from '~/utils/formatting';

import { default as lmsSubmissionStyles } from './styles';

interface Props {
	submissionTime: DateTime;
	editSubmission: () => void;
	cancel: (string) => void;
	cancelSubmission: () => void;
	submitFiles: () => void;
	jobStatus: string;
	progress: LmsSubmissionProgress;
}

export class LmsSubmission extends Component<Props> {
	focusRef = null;

	constructor(props) {
		super(props);
		this.focusRef = React.createRef();
	}

	renderEditSubmissionButton() {
		if (this.props.jobStatus !== 'SUCCESS') {
			return null;
		}

		return (
			<div aria-live="polite">
				<p>{this.props.progress.message || 'Your assignment has been submitted!'}</p>
				<p>If you would like to submit a new version, click Edit below.</p>
				<div className="controls">
					<button onClick={() => this.props.editSubmission()}>Edit</button>
				</div>
			</div>
		);
	}

	componentDidUpdate() {
		this.focusRef.current && this.focusRef.current.focus();
	}

	renderCompleted() {
		const formattedRelativeTime = formatTimeFromNow({ time: this.props.submissionTime });
		return (
			<div className="confirmation" tabIndex={-1} ref={this.focusRef}>
				{this.renderEditSubmissionButton()}
				{formattedRelativeTime && (
					<div className="submissionTime">Submitted {formattedRelativeTime}.</div>
				)}
			</div>
		);
	}

	cancelButton() {
		if (this.props.cancelSubmission == null) {
			return null;
		}

		return <button onClick={this.props.cancelSubmission}>Cancel</button>;
	}

	renderStates() {
		const { step: currentStepIdx, states: steps } = this.props.progress;
		if (steps == null) {
			return null;
		}

		return (
			<div>
				{steps.map((step, stepIdx) => (
					<div css={{ display: 'grid', gridTemplateColumns: '20px 1fr' }} key={stepIdx}>
						<span>
							{stepIdx < currentStepIdx ? (
								<Check className="lms-submission-icon" />
							) : stepIdx === currentStepIdx ? (
								<Indeterminate size={16} />
							) : (
								<>&nbsp;</>
							)}
						</span>
						<span>{step}</span>
					</div>
				))}
			</div>
		);
	}

	render() {
		const { cancel, jobStatus, progress } = this.props;

		if (cancel == null) {
			return null;
		}

		let innerContent;

		if (
			(jobStatus === JobStatus.WAITING && (!progress.step || progress.step < 0)) ||
			(jobStatus === JobStatus.RUNNING && progress == null)
		) {
			innerContent = (
				<div className="confirmation">
					<div className="status" aria-live="polite">
						Preparing to submit your assignment
					</div>
					<div className="controls">
						<div className="save-final">{this.cancelButton()}</div>
					</div>
				</div>
			);
		} else if (jobStatus === JobStatus.SUCCESS) {
			innerContent = this.renderCompleted();
		} else if (jobStatus === JobStatus.RUNNING) {
			innerContent = (
				<div className="confirmation">
					<div
						className="status"
						aria-live="polite"
						dangerouslySetInnerHTML={{
							__html: progress.message || 'Submitting your assignment'
						}}
					/>
					<div>{this.renderStates()}</div>
					<div className="controls">
						<div className="save-final">{this.cancelButton()}</div>
					</div>
				</div>
			);
		} else {
			innerContent = (
				<div className="confirmation" tabIndex={-1} ref={this.focusRef}>
					<p className="centered">Are you sure you want us to submit your work for grading?</p>
					<div className="controls">
						<div className="save-final">
							<button onClick={() => cancel(null)}>Cancel</button>
							<button onClick={this.props.submitFiles}>Yes, Submit My Assignment</button>
						</div>
					</div>
				</div>
			);
		}

		return <div css={lmsSubmissionStyles}>{innerContent}</div>;
	}
}

const withErrorBoundary = (Component) => (props) => {
	return (
		<GenericErrorBoundary>
			<Component {...props} />
		</GenericErrorBoundary>
	);
};

export default withErrorBoundary(LmsSubmission);
