import cloneDeep from 'lodash-es/cloneDeep';

import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

const purple = '#634b7d';

theme.colors.primary = purple;
theme.colors.secondary = '#eaeaea';
theme.colors.accent = purple;
theme.colors.link = (theme) => theme.colors.primary;

theme.webtextExample.backgroundColor = '#f7f7f7';
theme.bigLink.icon.fill = '#636363';

theme.webtextPage.title.color = theme.colors.primary;
theme.webtextPage.title.fontSize = '40px';

theme.artifactLink.icon.color = '#636363';

theme.tableOfContents.currentItemColor = (theme) => theme.colors.primary;
// #bcaccd is the result of adding 35% luminance to theme.colors.primary after converting it to HSL
theme.tableOfContents.progressColor = '#bcaccd';

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}1A`;

export default theme;
