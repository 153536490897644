import React, { forwardRef } from 'react';

import { css, ClassNames } from '@emotion/react';

import Text from '~/components/pageElements/Text/Text';
import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';

interface QuestionPromptProps {
	id?: string;
	body: string;
	gutterBottom?: boolean;
	className?: string;
}

/**
 * Renders a QuestionPrompt (also known as a question stem),
 * e.g. "Where is the Table of Contents button located in your webtext?"
 *
 * Appears in the accessibility tree as an h6.
 */
const QuestionPrompt = forwardRef<HTMLDivElement, QuestionPromptProps>((props, ref) => {
	const { id, body, gutterBottom, className } = props;

	return (
		<ClassNames>
			{({ cx }) => (
				<div
					id={id}
					ref={ref}
					role="heading"
					aria-level={6}
					tabIndex={-1}
					css={styles}
					className={cx(className, { ['gutter-bottom']: gutterBottom })}>
					{body && (
						<Text
							element={{ body }}
							css={promptTextStyles}
							// `webtext-question-label` class is a HACK until fonts reside inside of @soomo-libs
							className={cx('question-body', 'webtext-question-label', 'readable')}
							online
						/>
					)}
				</div>
			)}
		</ClassNames>
	);
});
QuestionPrompt.displayName = 'QuestionPrompt';

const styles = (theme) => css`
	padding: 1em 0 0.75em;

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		padding: 0;
	`}
`;

const promptTextStyles = (theme: Theme) => {
	const { questionPrompt } = theme.webtextQuestion;
	return css`
		font-family: ${getThemeItem(questionPrompt.fontFamily, theme)};
		font-weight: ${questionPrompt.fontWeight};
		font-size: ${getThemeItem(questionPrompt.fontSize, theme)};
		line-height: ${getThemeItem(questionPrompt.lineHeight, theme)};
		color: ${getThemeItem(questionPrompt.color, theme)};

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			letter-spacing: 0;
			margin-bottom: 10px;

			@media (max-width: ${breakpoints.small}) {
				font-size: 16px;
				line-height: 26px;
				margin-bottom: 1.5em;
			}

			textarea {
				display: none;
			}
		`}
	`;
};

export default QuestionPrompt;
