import React, { VFC, useEffect, useMemo, useRef } from 'react';

import { max, min, merge, sum, transpose } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale';
import { select } from 'd3-selection';
import { stack, line, curveStepAfter } from 'd3-shape';
import { range } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import { useResizeObserver } from 'usehooks-ts';

import { createTooltipsSingleton } from '~/components/pageElements/PollQuestion/utils';
import { breakpoints, colors } from '~/styles/themes';
import { ChartOrientation } from '~/types';

import ChartDescription from '../ChartDescription';
import {
	Basis,
	getBasis,
	getTicksAmount,
	getValueAxisFormat,
	sanitizeValue,
	scaleValue,
	valueOf
} from '../chartHelpers';
import { detectXAxisLabelsOverlap } from './LineChart';
import { a11yShapes } from './shapesUtils';
import { refreshedChartStyles } from './styles';
import { getYAxisMaxLabelWidth } from './utils';

import type { Props as ChartFigureProps } from './Chart';
import type { ScaleBand, ScaleLinear, ScaleOrdinal } from 'd3-scale';
import type { Selection as D3Selection } from 'd3-selection';
import type { ChartElement } from '~/types/WebtextManifest';

export type BarChart = Pick<
	ChartElement,
	| 'family_id'
	| 'data'
	| 'axis_type'
	| 'x_axis_label'
	| 'y_axis_label'
	| 'show_labels'
	| 'min_bound'
	| 'max_bound'
	| 'orientation'
	| 'series_orientation'
	| 'colors'
	| 'round_to'
	| 'labels_width'
	| 'tippyProps'
>;

export interface Props extends Omit<ChartFigureProps, 'chart'> {
	chart: BarChart;
	isHighContrast?: boolean;
}

/**
 * Global dimensions
 */
const minChartWidth = 300;
const minChartHeight = 400;
const defaultLabelsWidth = 100;
const labelPadding = 8;
const spaceAfter100Percent = 40;
const bottomLabelMargin = 5;
const axisTitleHeight = 14;
const bandPadding = 0.3;
const axisTitleBottomGap = 3;

/**
 * Horizontal space in pixels to reserve for grouped bar chart alternate view markers.
 *
 * n.b. this is not the width of the marker itself, but includes some extra horizontal space.
 */
const groupedHighContrastMarkersWidth = 30;
/**
 * Vertical space in pixels to reserve for grouped bar chart alternate view markers.
 *
 * n.b. this is not the height of the marker itself, but includes some extra vertical space
 */
const groupedHighContrastMarkersHeight = 20;

/**
 * Minimum horizontal space in pixels required in order to show a high contrast marker
 * overlaid atop horizontal bar segment.
 *
 * If the bar segment isn't at least this wide, then we will have to show the marker below the bar segment instead.
 */
const stackedHighContrastMarkerMinWidth = 28;

/**
 * Minimum vertical space in pixels required in order to show a high contrast marker
 * overlaid atop a vertical bar segment.
 *
 * If the bar segment isn't at least this tall,
 * then we will have to show the marker to the right of the bar segment instead.
 */
const stackedHighContrastMarkerMinHeight = 28;

/**
 * Spacing used for repositioned high contrast markers for horizontal stacked bar charts.
 */
const stackedHighContrastMarkerHorizontalSpacing = 8;

/**
 * Spacing used for repositioned high contrast markers for vertical stacked bar charts.
 */
const stackedHighContrastMarkerVerticalSpacing = 20;

/** Amount of horizontal space reserved to contain relocated high contrast markers and connecting lines. */
const stackedHighContrastMarkerReservedWidth = 42;

/**
 * How many pixels to splay apart connecting lines for consecutive repositioned high contrast markers.
 * (Otherwise the lines would overlap, making it hard to tell what symbols belong to which bar segments.)
 */
const splaySize = 4;

/**
 * Minimum width for a single bar in a vertical bar chart. Normally we calculate bar widths automatically based on the
 * available width of the chart, but if the bar width is less than this value then we want to expand the chart width
 * and allow the chart to scroll horizontally instead.
 */
const verticalBarMinWidth = 20;

const verticalBarMinStep = verticalBarMinWidth / (1 - bandPadding);

const margins = {
	[ChartOrientation.horizontal]: {
		top: 0,
		bottom: 16,
		left: defaultLabelsWidth
	},
	[ChartOrientation.vertical]: {
		top: 20,
		bottom: 35,
		left: 0
	}
};

/**
 * Bar dimensions
 */
const barHeight = 24;
const barMarginBottom = 16;

const getMargins = (chart: BarChart, isSmallView: boolean) => {
	const defaultMargins = margins[chart.orientation];

	let leftMargin = defaultMargins.left;

	/**
	 * Take the labels_width for horizontal bar charts if defined.
	 * Adjust the margin for small view.
	 */
	if (chart.orientation === 'horizontal' && Number(chart.labels_width))
		leftMargin = Number(chart.labels_width);
	if (isSmallView) leftMargin = leftMargin / 2;

	/**
	 * If axis labels present add more margin space for them
	 */
	const margin = {
		...defaultMargins,
		left: chart.y_axis_label?.length ? leftMargin + axisTitleHeight * 2 : leftMargin,
		bottom: chart.x_axis_label?.length
			? defaultMargins.bottom + axisTitleHeight * 2
			: defaultMargins.bottom
	};

	return margin;
};

const getHeight = (
	orientation: ChartOrientation,
	margin: ReturnType<typeof getMargins>,
	groupsTotalHeight: number
) => {
	return (
		(orientation === 'horizontal' ? groupsTotalHeight : minChartHeight) + margin.top + margin.bottom
	);
};

type StackedData = ReturnType<typeof getStackedData>;

/**
 * Returns stacked data. Transpose the data for easier usage:
 * so we can add a border to the grouped bar.
 */
const getStackedData = (data: string[][], seriesLabels: string[]) => {
	const result = data.map(
		(row) =>
			seriesLabels.reduce((acc, curr, i) => {
				const sanitizedValue = sanitizeValue(row[i]) || 0;
				return { ...acc, [curr]: sanitizedValue };
			}, {}) as Record<string, number>
	);
	(result as any)['columns'] ||= seriesLabels;

	return transpose(stack().keys(seriesLabels)(result));
};

type GroupedData = ReturnType<typeof valueOf>[][];

/**
 * Converts data value into an object { type: string; value: number; label: any; }
 */
const getGroupedData = (data: string[][]) => {
	return data.map((row) => row.map((value) => valueOf(value)));
};

const getMinGroupedValue = (data: string[][]) => {
	const minValue = min(merge(data), (item) => sanitizeValue(item as string));
	return minValue > 0 ? 0 : minValue;
};

type CategoriesScale = ScaleBand<string> | ScaleOrdinal<string, number>;

const BarChart: VFC<Props> = (props) => {
	const { chart, isHighContrast } = props;

	const {
		data,
		valueType,
		valuesData,
		valuesLowerBound,
		valuesUpperBound,
		basis,
		categoriesLabels,
		seriesLabels,
		initialTicksAmount,
		tickFormat
	} = useMemo(() => {
		const chartData = cloneDeep<string[][]>(chart.data);
		const seriesLabels = chartData.shift().slice(1); // note the shift(), we want this removed for future calculations
		const categoriesLabels = chartData.map((row) => row[0]);
		const valuesData = chartData.map((row) => row.slice(1));
		const valueType = valueOf(valuesData[0][0]).type;
		const maxValue =
			chart.series_orientation === 'grouped'
				? max(merge(valuesData), (item) => sanitizeValue(item as string))
				: max(valuesData.map((row) => sum(row.map((item) => sanitizeValue(item)))));
		const minValue = chart.series_orientation === 'grouped' ? getMinGroupedValue(valuesData) : 0;
		const valuesLowerBound = sanitizeValue(chart.min_bound) || minValue;
		const valuesUpperBound = sanitizeValue(chart.max_bound) || maxValue;
		const basis = getBasis(valuesLowerBound, valuesUpperBound, valueType);
		const data =
			chart.series_orientation === 'grouped'
				? getGroupedData(valuesData)
				: getStackedData(valuesData, seriesLabels);
		const initialTicksAmount = getTicksAmount(
			valuesLowerBound,
			valuesUpperBound,
			valueType === 'percent'
		);
		const tickFormat = getValueAxisFormat(chart.round_to, valueType);
		return {
			valueType,
			valuesLowerBound,
			valuesUpperBound,
			basis: basis as Basis,
			valuesData,
			seriesLabels,
			categoriesLabels,
			data,
			initialTicksAmount,
			tickFormat
		};
	}, [chart.data, chart.max_bound, chart.min_bound, chart.round_to, chart.series_orientation]);
	const showHighContrastMarkers = useMemo(
		() => isHighContrast && seriesLabels.length > 1,
		[isHighContrast, seriesLabels.length]
	);
	/**
	 * This also determines if `categoriesScale` is an ordinal scale rather than a band scale.
	 */
	const isStackedBarAlternateView = useMemo(
		() => showHighContrastMarkers && chart.series_orientation === 'stacked',
		[chart.series_orientation, showHighContrastMarkers]
	);

	/**
	 * Handle resize events
	 */
	const chartContainerRef = useRef<HTMLDivElement | null>(null);
	const { width: chartContainerWidth = 0 } = useResizeObserver({
		ref: chartContainerRef,
		box: 'border-box'
	});
	const isSmallView = useMemo(
		() => chartContainerWidth < parseFloat(breakpoints.small),
		[chartContainerWidth]
	);

	/**
	 * Calculated dimensions
	 */
	const margin = useMemo(() => getMargins(chart, isSmallView), [chart, isSmallView]);
	/**
	 * Calculate height and total height of groups. We also return valuesScale here because for vertical charts,
	 * we need to calculate valuesScale.range before we can determine the height.
	 */
	const { height, groupsTotalHeight, valuesScale, canFitShapeInsideBarSegment } = useMemo(() => {
		let height = 0;
		let groupsTotalHeight = 0;
		const valuesScale = scaleLinear();
		/**
		 * Determine which bar segments will require their symbols to be repositioned and save this info for later.
		 * (If we're not showing a stacked bar alternate view, then we can skip this calculation.)
		 */
		let canFitShapeInsideBarSegment: boolean[][] = [];

		if (!chartContainerRef.current || chartContainerWidth === 0) {
			return {
				height,
				groupsTotalHeight,
				valuesScale,
				canFitShapeInsideBarSegment
			};
		}

		const valuesScaleLowerBound = scaleValue(valuesLowerBound, basis);
		const valuesScaleUpperBound = scaleValue(valuesUpperBound, basis);

		valuesScale.domain([valuesScaleLowerBound, valuesScaleUpperBound]);

		const getGroupsTotalHeight = (canFitShape?: boolean[][]) => {
			const numOfGroups = categoriesLabels.length;
			const barsPerGroup = chart.series_orientation === 'grouped' ? seriesLabels.length : 1;
			const groupHeight = barHeight * barsPerGroup;

			const totalSpacing = barMarginBottom * (numOfGroups - 1) + 2 * barMarginBottom;
			let groupsTotalHeight = totalSpacing;
			if (isStackedBarAlternateView && chart.orientation === 'horizontal') {
				canFitShape.forEach((_, barIndex) => {
					if (canFitShape![barIndex].every(Boolean)) {
						groupsTotalHeight += groupHeight;
					} else {
						groupsTotalHeight += groupHeight * 2;
					}
				});
			} else {
				groupsTotalHeight += groupHeight * numOfGroups;
			}

			return groupsTotalHeight;
		};

		const getCanFitShapeInsideBarSegment = (scale: ScaleLinear<number, number>) => {
			const canFit: boolean[][] = [];
			categoriesLabels.forEach((_, barIndex) => {
				canFit[barIndex] = Array(data[barIndex].length);
				(data as StackedData)[barIndex].forEach((barSegment, barSegmentIndex) => {
					const segmentLength =
						chart.orientation === 'horizontal'
							? scale((barSegment[1] - barSegment[0]) / basis)
							: scale(barSegment[0] / basis) - scale(barSegment[1] / basis);
					if (segmentLength === 0) {
						canFit[barIndex][barSegmentIndex] = true;
						return;
					}

					const minLength =
						chart.orientation === 'horizontal'
							? stackedHighContrastMarkerMinWidth
							: stackedHighContrastMarkerMinHeight;
					canFit[barIndex][barSegmentIndex] = segmentLength >= minLength;
				});
			});
			return canFit;
		};

		/**
		 * Set value scale ranges
		 */
		switch (chart.orientation) {
			case 'horizontal':
				/**
				 * 1. Set valuesScale.range
				 * 2. Determine which bar segments will require their symbols to be repositioned
				 *    (getCanFitShapeInsideBarSegment)
				 * 3. Calculate groupsTotalHeight and height
				 */
				valuesScale.range([
					0,
					Math.max(minChartWidth, chartContainerWidth) -
						margin.left -
						spaceAfter100Percent -
						(showHighContrastMarkers && chart.series_orientation === 'grouped'
							? groupedHighContrastMarkersWidth
							: 0)
				]);
				canFitShapeInsideBarSegment = getCanFitShapeInsideBarSegment(valuesScale);
				groupsTotalHeight = getGroupsTotalHeight(canFitShapeInsideBarSegment);
				height = getHeight(chart.orientation, margin, groupsTotalHeight);
				break;

			case 'vertical':
				/**
				 * 1. Determine groupsTotalHeight and height
				 *    (for vertical charts, the height doesn't change even if symbols require relocation,
				 *     so we can calculate heights before calling getCanFitShapeInsideBarSegment)
				 * 2. Set valuesScale.range
				 * 3. Call getCanFitShapeInsideBarSegment and save it for later
				 *    (we don't need it for anything else in this memo function, but we will need it later on
				 *     in the bar chart drawing process)
				 */
				groupsTotalHeight = getGroupsTotalHeight();
				height = getHeight(chart.orientation, margin, groupsTotalHeight);
				valuesScale.range([
					height -
						margin.bottom -
						margin.top -
						(showHighContrastMarkers && chart.series_orientation === 'grouped'
							? groupedHighContrastMarkersHeight
							: 0),
					0
				]);
				canFitShapeInsideBarSegment = getCanFitShapeInsideBarSegment(valuesScale);
				break;

			default:
				break;
		}

		return {
			height,
			groupsTotalHeight,
			valuesScale,
			canFitShapeInsideBarSegment
		};
	}, [
		basis,
		categoriesLabels,
		chart.orientation,
		chart.series_orientation,
		chartContainerWidth,
		data,
		isStackedBarAlternateView,
		margin,
		seriesLabels.length,
		showHighContrastMarkers,
		valuesLowerBound,
		valuesUpperBound
	]);
	/**
	 * Calculate width. We set a minimum width for vertical charts to avoid squishing bars and symbols too closely.
	 * We also return categoriesScale, categoryBandwidth, and chartXOffset here because for vertical charts we need to
	 * calculate those values to determine what the minimum width should be.
	 */
	const { width, categoriesScale, categoryBandwidth, chartXOffset } = useMemo(() => {
		/**
		 * Scale for categories (e.g. `["Ch 1", "Ch 2", ...]` or `[1980, 1990, 2000, ...]`)
		 *
		 * For grouped charts or stacked charts not in alternate view, we can use a band scale.
		 * But for stacked charts alternate view, we have to use an ordinal scale because band sizes may be unequal
		 * (namely if a bar segment is too short to allow overlaying an `a11yShape` inside of it,
		 * necessitating a wider band for that specific bar).
		 */
		const categoriesScale: CategoriesScale = isStackedBarAlternateView
			? scaleOrdinal<number>().domain(categoriesLabels)
			: scaleBand().domain(categoriesLabels);
		let baseStepSize = 0;
		let chartXOffset = 0;

		if (!chartContainerRef.current || chartContainerWidth === 0) {
			return {
				width: 0,
				categoriesScale,
				categoryBandwidth: 0,
				chartXOffset: 0
			};
		}

		const baseWidth = Math.max(minChartWidth, chartContainerWidth);
		let width = baseWidth;

		/**
		 * Set category ranges
		 */
		switch (chart.orientation) {
			case 'horizontal':
				if (isStackedBarAlternateView) {
					const categoriesStepSizes = Array(categoriesLabels.length).fill(0);
					const categoriesRange = Array(categoriesLabels.length).fill(0);
					categoriesLabels.forEach((_, i) => {
						const step = canFitShapeInsideBarSegment[i].every(Boolean) // can the entire bar fit its symbols?
							? barHeight
							: 2 * barHeight;
						categoriesStepSizes[i] = step;
						categoriesRange[i] =
							barMarginBottom + (i > 0 ? categoriesRange[i - 1] + categoriesStepSizes[i - 1] : 0);
					});

					(categoriesScale as ScaleOrdinal<string, number>).range(categoriesRange);
				} else {
					categoriesScale.range([0, groupsTotalHeight]);
					(categoriesScale as ScaleBand<string>).padding(
						barMarginBottom / (categoriesScale as ScaleBand<string>).step()
					);
				}
				break;

			case 'vertical': {
				const yAxisMaxLabelWidth = measureYAxisMaxLabelWidth({
					chartContainer: chartContainerRef.current,
					ticksAmount: initialTicksAmount,
					tickFormat,
					valuesScale
				});
				chartXOffset = margin.left + yAxisMaxLabelWidth + labelPadding;
				const availableWidth = baseWidth - chartXOffset;

				if (isStackedBarAlternateView) {
					const categoriesStepSizes = Array(categoriesLabels.length).fill(0);
					const categoriesRange = Array(categoriesLabels.length).fill(0);

					const numBarsRequiringRelocatedSymbols = canFitShapeInsideBarSegment.filter((bar) =>
						bar.some((x) => !x)
					).length;

					const minWidth =
						categoriesLabels.length * verticalBarMinStep - // n * minStep
						verticalBarMinStep * bandPadding + // take away 1 extra inner padding
						verticalBarMinStep * bandPadding * 2 + // add 2 outer paddings
						numBarsRequiringRelocatedSymbols * stackedHighContrastMarkerReservedWidth; // extra space for relocated symbols
					const chartWidth = Math.max(availableWidth, minWidth);

					// calculate step with the width after we take away reserved symbol space.
					baseStepSize =
						(chartWidth -
							numBarsRequiringRelocatedSymbols * stackedHighContrastMarkerReservedWidth) /
						(categoriesLabels.length - bandPadding + 2 * bandPadding);

					categoriesLabels.forEach((_, i) => {
						const step = canFitShapeInsideBarSegment[i].every(Boolean)
							? baseStepSize
							: baseStepSize + stackedHighContrastMarkerReservedWidth;
						categoriesStepSizes[i] = step;
						if (i === 0) {
							categoriesRange[i] = baseStepSize * bandPadding; // left-edge outer padding
						} else {
							categoriesRange[i] = categoriesRange[i - 1] + categoriesStepSizes[i - 1];
						}
					});
					categoriesScale.range(categoriesRange);
					width = Math.max(width, chartWidth + chartXOffset);
				} else {
					(categoriesScale as ScaleBand<string>).range([0, availableWidth]).padding(bandPadding);
				}
				break;
			}

			default:
				break;
		}

		let categoryBandwidth = 0;
		if (!isStackedBarAlternateView) {
			categoryBandwidth = (categoriesScale as ScaleBand<string>).bandwidth();
		} else {
			categoryBandwidth =
				chart.orientation === 'horizontal' ? barHeight : baseStepSize * (1 - bandPadding);
		}

		return {
			categoryBandwidth,
			categoriesScale,
			width,
			chartXOffset
		};
	}, [
		canFitShapeInsideBarSegment,
		categoriesLabels,
		chart.orientation,
		chartContainerWidth,
		groupsTotalHeight,
		initialTicksAmount,
		isStackedBarAlternateView,
		margin.left,
		tickFormat,
		valuesScale
	]);

	useEffect(() => {
		const { current: chartContainer } = chartContainerRef;

		/**
		 * Don't try to draw until we know the size of the window
		 */
		if (!chartContainer || chartContainerWidth === 0) return;

		const labelWidth = chart.labels_width || defaultLabelsWidth;

		const color = scaleOrdinal<number, string>(chart.colors);

		let xLabelTooltips: ReturnType<typeof drawLabelTooltips>;
		let yLabelTooltips: ReturnType<typeof drawLabelTooltips>;

		const draw = () => {
			/**
			 * Chart container
			 */
			const svgContainer = select(chartContainer)
				.append('svg')
				.attr('class', 'chart-svg')
				.attr('width', width)
				.attr('height', height)
				.attr('aria-hidden', true);

			const svg = svgContainer
				.append('g')
				.attr(
					'transform',
					`translate(${chart.orientation === 'vertical' ? chartXOffset : margin.left}, ${
						margin.top
					})`
				);

			const seriesScale = scaleBand().domain(seriesLabels); // subgroup scale for multigroup chart

			drawValueAxis(svg, valuesScale);

			drawCategoryAxis(svg, categoriesScale, seriesScale);

			if (chart.series_orientation === 'grouped') {
				drawGroupedBars(svg, valuesScale, categoriesScale as ScaleBand<string>, seriesScale);
			}

			if (chart.series_orientation === 'stacked') {
				drawStackedBars(svg, valuesScale, categoriesScale, canFitShapeInsideBarSegment);
			}

			drawAxisTitles(svgContainer, chart.orientation);
		};

		const drawValueAxis = (
			svg: D3Selection<SVGElement, unknown, null, undefined>,
			valuesScale: ScaleLinear<number, number, never>
		) => {
			switch (chart.orientation) {
				case 'horizontal':
					{
						let xAxisGroup: D3Selection<SVGGElement, unknown, null, undefined> | undefined;
						let ticksAmount = initialTicksAmount;
						const drawXValueAxis = (ticksAmount: number) => {
							const xAxis = axisBottom(valuesScale)
								.ticks(ticksAmount)
								.tickFormat(tickFormat)
								.tickSizeInner(-groupsTotalHeight)
								.tickSizeOuter(0)
								.tickPadding(5);

							xAxisGroup = svg
								.append('g')
								.attr(
									'transform',
									`translate(${
										showHighContrastMarkers && chart.series_orientation == 'grouped'
											? groupedHighContrastMarkersWidth
											: 0
									}, ${groupsTotalHeight})`
								)
								.call(xAxis)
								.attr('class', 'xAxis');
						};

						drawXValueAxis(ticksAmount);

						let xAxisLabelsOverlap = detectXAxisLabelsOverlap(svg);

						/**
						 * While x axis labels overlap decrease the number of ticks and redraw the axis
						 */
						while (xAxisLabelsOverlap) {
							svg.selectAll('.xAxis').remove();

							ticksAmount = ticksAmount / 2;
							drawXValueAxis(ticksAmount);

							xAxisLabelsOverlap = detectXAxisLabelsOverlap(svg);
						}

						xAxisGroup
							.selectAll('text')
							.attr('class', 'chart-value-axis-tick-text')
							.attr('aria-hidden', true);

						/**
						 * Make the x-axis line take the whole width
						 */
						xAxisGroup
							.append('line')
							.attr('class', 'divider-line')
							.attr(
								'x1',
								-margin.left -
									(showHighContrastMarkers && chart.series_orientation === 'grouped'
										? groupedHighContrastMarkersWidth
										: 0)
							)
							.attr('y1', 0)
							.attr('x2', width)
							.attr('y2', 0)
							.attr('stroke-width', 2);
					}
					break;

				case 'vertical':
					drawYValueAxis({
						svg,
						ticksAmount: initialTicksAmount,
						valuesScale,
						tickFormat,
						width
					});
					break;

				default:
					break;
			}
		};

		const drawCategoryAxis = (
			svg: D3Selection<SVGElement, unknown, null, undefined>,
			categoriesScale: CategoriesScale,
			seriesScale: ScaleBand<string>
		) => {
			switch (chart.orientation) {
				case 'horizontal':
					{
						const yAxis = axisLeft(categoriesScale)
							.tickSize(0)
							.tickFormat(() => ''); // Tick text will be displayed within the `foreignObject`
						const yLabelHeight = categoryBandwidth;

						/**
						 * Draw y-axis labels
						 */
						const yLabels = svg
							.append('g')
							// Moves labels to the left, creates gap between labels and bars
							// for ordinal scales we need to move the label to the y-center of the bar rather than the y-top of it
							.attr(
								'transform',
								`translate(-3, ${isStackedBarAlternateView ? yLabelHeight / 2 : 0})`
							)
							.call(yAxis)
							.attr('class', 'yAxis')
							.selectAll('g')
							.append('foreignObject')
							.attr('width', isSmallView ? labelWidth / 2 : labelWidth)
							.attr('height', yLabelHeight)
							.attr('x', -(isSmallView ? labelWidth / 2 : labelWidth) + 4)
							.attr('y', yLabelHeight / -2)
							.attr('class', 'chart-category-axis-tick')
							.append('xhtml:div')
							.attr('class', 'chart-category-axis-tick-container horizontal')
							.append('xhtml:div')
							.attr('class', 'chart-category-axis-tick-label horizontal')
							.html((tick) => `<div class="tick">${tick}</div>`)
							.attr('aria-hidden', true);

						yLabelTooltips = drawLabelTooltips(yLabels, chart.tippyProps);
						svg.selectAll('.yAxis path').attr('stroke-width', 0);

						seriesScale.rangeRound([0, categoryBandwidth]);
					}
					break;

				case 'vertical':
					{
						const xAxis = axisBottom(categoriesScale)
							.tickSize(0)
							.tickFormat(() => ''); // Tick text will be displayed within the `foreignObject`
						/**
						 * Calculate width of category labels on the x-axis. We'll give them a bit of extra space by letting them
						 * run halfway into the left and right padding of a given bar.
						 */
						const xLabelWidth = categoryBandwidth / (1 - bandPadding);

						/**
						 * Draw x-axis labels
						 */
						const xLabels = svg
							.append('g')
							.attr(
								'transform',
								// for ordinal scales we need to move the label to the x-center of the bar rather than the x-left of it
								`translate(${isStackedBarAlternateView ? categoryBandwidth / 2 : 0}, ${
									height - margin.top - margin.bottom
								})`
							)
							.call(xAxis)
							.attr('class', 'xAxis')
							.selectAll('g')
							.append('foreignObject')
							.attr('width', xLabelWidth)
							.attr('x', -xLabelWidth / 2)
							.attr('y', bottomLabelMargin)
							.attr('class', 'chart-category-axis-tick')
							.append('xhtml:div')
							.attr('class', 'chart-category-axis-tick-container vertical')
							.append('xhtml:div')
							.attr('class', 'chart-category-axis-tick-label vertical')
							.html((tick) => `<div class="tick">${tick}</div>`)
							.attr('aria-hidden', true);

						xLabelTooltips = drawLabelTooltips(xLabels, chart.tippyProps);
						svg.selectAll('.xAxis path').attr('stroke-width', 0);

						seriesScale.rangeRound([0, categoryBandwidth]);
					}
					break;

				default:
					break;
			}
		};

		const drawGroupedBars = (
			svg: D3Selection<SVGElement, unknown, null, undefined>,
			valuesScale: ScaleLinear<number, number, never>,
			categoriesScale: ScaleBand<string>,
			seriesScale: ScaleBand<string>
		) => {
			const group = svg
				.append('g')
				.selectAll('bars')
				.data(data as GroupedData)
				.enter()
				.append('g')
				.attr('transform', (_, i) =>
					chart.orientation === 'horizontal'
						? `translate(${
								showHighContrastMarkers ? groupedHighContrastMarkersWidth : 0
						  }, ${categoriesScale(categoriesLabels[i])})`
						: `translate(${categoriesScale(categoriesLabels[i])}, 0)`
				);

			const rects = group
				.selectAll('rect')
				.data((d) => d)
				.enter()
				.append('rect')
				.attr('fill', (_, i) => (isHighContrast ? colors.beige : color(i)))
				.attr('stroke', isHighContrast ? colors.brown : 'black')
				.attr('stroke-width', '1px')
				.attr('rx', 3)
				.attr('shape-rendering', 'geometricPrecision');

			const valuesScaleLowerBound = valuesScale.domain()[0];
			const lowerBoundScaleValue =
				valuesScaleLowerBound < 0 ? valuesScale(0) : valuesScale(valuesScaleLowerBound);

			if (chart.orientation === 'horizontal') {
				rects
					.attr('x', (d) => (d.value < 0 ? valuesScale(d.value) : lowerBoundScaleValue))
					.attr('y', (_, i) => seriesScale(seriesLabels[i]))
					.attr('width', (d) => valuesScale(Math.abs(d.value)) - lowerBoundScaleValue)
					.attr('height', seriesScale.bandwidth());
			} else {
				rects
					.attr('x', (_, i) => seriesScale(seriesLabels[i]))
					.attr('y', (d) => (d.value > 0 ? valuesScale(d.value) : valuesScale(0)))
					.attr('width', seriesScale.bandwidth())
					.attr('height', (d) => lowerBoundScaleValue - valuesScale(Math.abs(d.value)));
			}

			/**
			 * Draw a11y markers
			 */
			if (showHighContrastMarkers) {
				const shapeScale = scaleOrdinal(range(a11yShapes.length), a11yShapes);

				const markers = group
					.selectAll('barsMarkers')
					.data((d) => d)
					.enter()
					.append('g')
					.attr('fill', colors.brown)
					.attr('stroke', colors.brown)
					.html((_, i) => shapeScale(i));

				if (chart.orientation === 'horizontal') {
					markers.attr(
						'transform',
						(_, i) =>
							`translate(${-groupedHighContrastMarkersWidth / 2}, ${
								seriesScale(seriesLabels[i]) + seriesScale.bandwidth() / 2
							})`
					);
				} else {
					markers.attr(
						'transform',
						(_, i) =>
							`translate(${seriesScale(seriesLabels[i]) + seriesScale.bandwidth() / 2}, ${
								height - margin.top - margin.bottom - groupedHighContrastMarkersHeight / 2
							})`
					);
				}
			}

			/**
			 * Draw a bar label for each bar
			 */
			if (chart.show_labels) {
				const labels = group
					.selectAll('barsLabels')
					.data((d) => d)
					.enter()
					.append('text')
					.text((d) => tickFormat(d.value))
					.attr('class', 'chart-bar-label')
					.attr('aria-hidden', true);

				if (chart.orientation === 'horizontal') {
					labels
						.attr('x', function (d) {
							if (d.value >= 0) return valuesScale(d.value) + 5;

							const labelWidth = this.getBBox().width;
							return valuesScale(d.value) - labelWidth - 5;
						})
						.attr('y', (_, i) => seriesScale(seriesLabels[i]))
						.attr('dy', seriesScale.bandwidth() / 1.5);
				} else {
					labels
						.attr('x', (_, i) => seriesScale(seriesLabels[i]))
						.attr('y', (d) => valuesScale(d.value) + (d.value >= 0 ? -5 : 15))
						.attr('dx', seriesScale.bandwidth() / 2)
						.attr('text-anchor', 'middle');
				}
			}
		};

		const drawStackedBars = (
			svg: D3Selection<SVGElement, unknown, null, undefined>,
			valuesScale: ScaleLinear<number, number, never>,
			categoriesScale: CategoriesScale,
			canFitShapeInsideBarSegment: boolean[][]
		) => {
			const group = svg
				.append('g')
				.selectAll('bars')
				.data(data as StackedData)
				.enter()
				.append('g')
				.attr('transform', (_, i) =>
					chart.orientation === 'horizontal'
						? `translate(0, ${categoriesScale(categoriesLabels[i])})`
						: `translate(${categoriesScale(categoriesLabels[i])}, 0)`
				);

			const rectsGroup = group.append('g');
			const rects = rectsGroup
				.selectAll('rect')
				.data((d) => d)
				.enter()
				.append('rect')
				.attr('fill', (_, i) => (isHighContrast ? colors.beige : color(i)));

			if (chart.orientation === 'horizontal') {
				rects
					.attr('x', (d) => valuesScale(d[0] / basis))
					.attr('y', 0)
					.attr('width', (d) => valuesScale((d[1] - d[0]) / basis))
					.attr('height', categoryBandwidth);
			} else if (chart.orientation === 'vertical') {
				rects
					.attr('x', 0)
					.attr('y', (d) => valuesScale(d[1] / basis))
					.attr('width', categoryBandwidth)
					.attr('height', (d) => valuesScale(d[0] / basis) - valuesScale(d[1] / basis));
			}

			if (showHighContrastMarkers) {
				// add border between bar segments, since otherwise we can't tell where a bar segment begins and ends
				// (as they are all the same color when `showHighContrastMarkers` is true)
				rects.attr('stroke', 'black').attr('stroke-width', '1px');

				const shapeScale = scaleOrdinal(range(a11yShapes.length), a11yShapes);
				const symbolPositions: number[][] = Array(data.length);

				group.each((d, rowIndex, nodes) => {
					symbolPositions[rowIndex] = Array(d.length).fill(
						chart.orientation === 'horizontal' ? 0 : height
					);
					select(nodes[rowIndex])
						.append('g')
						.selectAll('barsMarkers')
						.data(d)
						.enter()
						.append('g')
						.attr('fill', colors.brown)
						.attr('stroke', colors.brown)
						.html((d, i) => (d[1] - d[0] > 0 ? shapeScale(i) : null))
						.attr('transform', (d, i) => {
							let x = 0;
							let y = 0;
							if (chart.orientation === 'horizontal') {
								if (canFitShapeInsideBarSegment[rowIndex][i]) {
									x = groupedHighContrastMarkersWidth / 2 + valuesScale(d[0] / basis);
									y = categoryBandwidth / 2 - 1;
								} else {
									x = Math.max(
										i > 0
											? symbolPositions[rowIndex][i - 1] + stackedHighContrastMarkerMinWidth
											: 0,
										valuesScale((d[0] + (d[1] - d[0]) / 2) / basis)
									);
									y =
										categoryBandwidth +
										stackedHighContrastMarkerMinHeight / 2 +
										stackedHighContrastMarkerHorizontalSpacing;
									symbolPositions[rowIndex][i] = x;
								}
								return `translate(${x}, ${y})`;
							} else {
								if (canFitShapeInsideBarSegment[rowIndex][i]) {
									x = categoryBandwidth / 2;
									y = groupedHighContrastMarkersHeight / 2 + valuesScale(d[1] / basis);
								} else {
									x =
										categoryBandwidth +
										stackedHighContrastMarkerMinWidth / 2 +
										stackedHighContrastMarkerVerticalSpacing;
									y = Math.min(
										i > 0
											? symbolPositions[rowIndex][i - 1] - stackedHighContrastMarkerMinHeight
											: height,
										valuesScale((d[0] + (d[1] - d[0]) / 2) / basis)
									);
									symbolPositions[rowIndex][i] = y;
								}
							}
							return `translate(${x}, ${y})`;
						});
				});

				group.each((d, rowIndex, nodes) => {
					let consecutiveRelocatedSymbolCount = 0;
					select(nodes[rowIndex])
						.append('g')
						.selectAll('markerLines')
						.data(d)
						.enter()
						.append('path')
						.attr('stroke', 'black')
						.attr('fill', 'none')
						.attr('d', (barSegment, i) => {
							if (canFitShapeInsideBarSegment[rowIndex][i]) {
								consecutiveRelocatedSymbolCount = 0;
								return '';
							}

							let result: string;
							if (chart.orientation === 'horizontal') {
								const x1 = valuesScale(
									(barSegment[0] + (barSegment[1] - barSegment[0]) / 2) / basis
								);
								const y1 = categoryBandwidth;
								const x2 = symbolPositions[rowIndex][i];
								const y2 = categoryBandwidth + stackedHighContrastMarkerHorizontalSpacing * 1.5;
								result = line(
									(d) => d[0],
									(d) => d[1]
								).curve(curveStepAfter)([
									[x1, y1],
									[x1, y2 - consecutiveRelocatedSymbolCount * splaySize],
									[x2, y2]
								]);
							} else {
								const x1 = categoryBandwidth;
								const y1 = valuesScale(
									(barSegment[0] + (barSegment[1] - barSegment[0]) / 2) / basis
								);
								const x2 = x1 + stackedHighContrastMarkerVerticalSpacing;
								const y2 = symbolPositions[rowIndex][i];
								result = line(
									(d) => d[0],
									(d) => d[1]
								).curve(curveStepAfter)([
									[x1, y1],
									[x2 - consecutiveRelocatedSymbolCount * splaySize, y2],
									[x2 + splaySize, y2]
								]);
							}

							consecutiveRelocatedSymbolCount++;
							return result;
						});
				});
			}

			/**
			 * Add border to the stacked bar group.
			 */
			const borders = svg
				.append('g')
				.selectAll('rectBorder')
				.data(data as StackedData)
				.enter()
				.append('rect')
				.attr('rx', 3)
				.attr('shape-rendering', 'geometricPrecision')
				.attr('stroke', 'black')
				.attr('stroke-width', 1)
				.attr('fill', 'none');

			if (chart.orientation === 'horizontal') {
				borders
					.attr('x', 0)
					.attr('y', (_, i) => categoriesScale(categoriesLabels[i]))
					.attr('height', categoryBandwidth)
					.attr('width', (d) => valuesScale(d[seriesLabels.length - 1][1] / basis));
			} else if (chart.orientation === 'vertical') {
				borders
					.attr('x', (_, i) => categoriesScale(categoriesLabels[i]))
					.attr('y', (d) => valuesScale(d[seriesLabels.length - 1][1] / basis))
					.attr(
						'height',
						(d) => valuesScale(0) - valuesScale(d[seriesLabels.length - 1][1] / basis)
					)
					.attr('width', categoryBandwidth);
			}

			/**
			 * Add clipPath for each stacked bar group to make the border radius.
			 * That's a workaround as we can't apply the border only to the side rects.
			 */
			const clipPaths = svg
				.append('defs')
				.selectAll('clipPath')
				.data(data as StackedData)
				.enter()
				.append('clipPath')
				.attr('id', (_, i) => `clip-${i}-${chart.orientation}-${chart.family_id}`)
				.append('rect')
				.attr('rx', 3)
				.attr('shape-rendering', 'geometricPrecision');

			if (chart.orientation === 'horizontal') {
				clipPaths
					.attr('height', categoryBandwidth)
					.attr('width', (d) => valuesScale(d[seriesLabels.length - 1][1] / basis));
			} else if (chart.orientation === 'vertical') {
				clipPaths
					.attr('y', (d) => valuesScale(d[seriesLabels.length - 1][1] / basis))
					.attr(
						'height',
						(d) => valuesScale(0) - valuesScale(d[seriesLabels.length - 1][1] / basis)
					)
					.attr('width', categoryBandwidth);
			}

			rectsGroup.attr(
				'clip-path',
				(_, i) => `url(#clip-${i}-${chart.orientation}-${chart.family_id})`
			);

			/**
			 * Draw a bar label for each bar
			 */
			if (chart.show_labels) {
				const labels = svg
					.append('g')
					.selectAll('barsLabels')
					.data(data as StackedData)
					.enter()
					.append('text')
					.text((d) => tickFormat(d[seriesLabels.length - 1][1] / basis))
					.attr('class', 'chart-bar-label')
					.attr('aria-hidden', true);

				if (chart.orientation === 'horizontal') {
					labels
						.attr('x', (d) => valuesScale(d[seriesLabels.length - 1][1] / basis) + 5)
						.attr('y', (_, i) => categoriesScale(categoriesLabels[i]))
						.attr('dy', categoryBandwidth / 1.5);
				} else if (chart.orientation === 'vertical') {
					labels
						.attr('x', (_, i) => categoriesScale(categoriesLabels[i]))
						.attr('y', (d) => valuesScale(d[seriesLabels.length - 1][1] / basis) - 5)
						.attr('dx', categoryBandwidth / 2)
						.attr('text-anchor', 'middle');
				}
			}
		};

		const drawAxisTitles = (
			svgContainer: D3Selection<SVGElement, unknown, null, undefined>,
			orientation: 'horizontal' | 'vertical'
		) => {
			if (chart.x_axis_label?.length > 0) {
				svgContainer
					.append('text')
					.attr('class', 'chart-x-axis-title')
					.attr('text-anchor', 'middle')
					.attr('transform', `translate(${width / 2}, ${height - axisTitleBottomGap})`)
					.text(chart.x_axis_label);
			}

			if (chart.y_axis_label?.length > 0) {
				svgContainer
					.append('text')
					.attr('class', 'chart-y-axis-title')
					.attr('text-anchor', 'middle')
					.attr(
						'transform',
						`translate(${axisTitleHeight}, ${
							orientation === 'horizontal' ? (height - margin.bottom) / 2 : height / 2
						}) rotate(-90)`
					)
					.text(chart.y_axis_label);
			}
		};

		/**
		 * Remove all SVG elements and tooltip instances before redrawing
		 */
		select(chartContainer).selectAll('*').remove();
		xLabelTooltips?.destroy();
		yLabelTooltips?.destroy();

		draw();
	}, [
		basis,
		canFitShapeInsideBarSegment,
		categoriesLabels,
		categoriesScale,
		categoryBandwidth,
		chart.colors,
		chart.family_id,
		chart.labels_width,
		chart.orientation,
		chart.round_to,
		chart.series_orientation,
		chart.show_labels,
		chart.tippyProps,
		chart.x_axis_label,
		chart.y_axis_label,
		chartContainerWidth,
		chartXOffset,
		data,
		groupsTotalHeight,
		height,
		initialTicksAmount,
		isHighContrast,
		isSmallView,
		isStackedBarAlternateView,
		margin.bottom,
		margin.left,
		margin.top,
		seriesLabels,
		showHighContrastMarkers,
		tickFormat,
		valueType,
		valuesData,
		valuesLowerBound,
		valuesScale,
		valuesUpperBound,
		width
	]);

	return (
		<figure
			css={(theme) => refreshedChartStyles(theme, { isSmallView })}
			className="refreshed-chart refreshed-bar-chart">
			<div
				className="refreshed-chart-container"
				style={{ minWidth: minChartWidth }}
				ref={chartContainerRef}
			/>
			<ChartDescription chart={chart as ChartElement} />
		</figure>
	);
};

function drawLabelTooltips(labels: any, tippyProps?: ChartElement['tippyProps']) {
	const tooltips: Array<TippyInstance> = [];

	labels.each(function () {
		const labelElement = this as HTMLElement;
		const label = select(this);

		// The `scrollHeight` is higher than `clientHeight` when some text is overflown and hidden
		const isOverflownLabel = labelElement.scrollHeight > labelElement.clientHeight;

		if (isOverflownLabel) {
			const content = labelElement.innerText;
			tooltips.push(tippy(labelElement, { content }));

			label.attr('tabindex', 0);
			label.attr('role', 'button');
			label.attr('aria-label', 'Show longer bar chart label');
			label.attr('aria-hidden', false);
			label.classed('label-underline', true);
		}
	});

	const tooltipsSingleton = createTooltipsSingleton(tooltips, tippyProps);
	return {
		tooltips,
		tooltipsSingleton,
		destroy: () => {
			tooltips.forEach((tooltip) => tooltip.destroy());
			tooltipsSingleton.destroy();
		}
	};
}

function drawYValueAxis({
	svg,
	valuesScale,
	ticksAmount,
	tickFormat,
	width
}: {
	svg: D3Selection<SVGElement, unknown, null, undefined>;
	valuesScale: ScaleLinear<number, number>;
	ticksAmount: number;
	tickFormat: (domainValue: number, index: number) => string;
	width?: number;
}) {
	const yAxis = axisLeft(valuesScale)
		.ticks(ticksAmount)
		.tickSizeOuter(0)
		.tickPadding(5)
		.tickFormat(tickFormat);
	if (width != null) {
		yAxis.tickSizeInner(-width);
	}

	svg
		.append('g')
		.call(yAxis)
		.attr('class', 'yAxis')
		.selectAll('text')
		.attr('class', 'chart-value-axis-tick-text')
		.attr('aria-hidden', true);
}

/**
 * Returns the width of the widest y-axis label for a bar chart.
 *
 * Temporarily draws the axis labels into a 0x0 svg element in order to measure them.
 */
function measureYAxisMaxLabelWidth({
	chartContainer,
	ticksAmount,
	tickFormat,
	valuesScale
}: {
	chartContainer: HTMLElement;
	ticksAmount: number;
	tickFormat: (domainValue: number, index: number) => string;
	valuesScale: ScaleLinear<number, number>;
}): number {
	const tempSvg = select(chartContainer).append('svg').attr('width', 0).attr('height', 0);
	drawYValueAxis({ svg: tempSvg, ticksAmount, tickFormat, valuesScale });
	const maxLabelWidth = getYAxisMaxLabelWidth(tempSvg);
	tempSvg.remove();
	return maxLabelWidth;
}

export default BarChart;
