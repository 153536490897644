import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, Theme, ThemeName } from '~/styles/themes';

export const tableSheetStyles = (
	theme: Theme,
	options: { hideAddressHeaders: boolean }
): SerializedStyles => css`
	position: relative;
	font-size: 16px;
	border-collapse: separate !important;

	// Because of differences with border stuff we have to provide some space, otherwise we can get unexpected scroller
	${options.hideAddressHeaders && 'padding: 1px 0.5px;'}

	&,
	* {
		box-sizing: border-box;
		line-height: 1.6 !important;
		font-size: 16px !important;
	}

	&:focus {
		outline: none;
	}

	tr:last-of-type td:last-of-type {
		div.cell {
			border-width: 2px !important;
		}
	}

	@media (max-width: ${breakpoints.small}) {
		td,
		td.header {
			border-width: 1px !important;
		}

		td:not(.header) {
			--min-uncollapse-width: 175px;
			min-width: var(--min-uncollapse-width) !important;

			div.cell {
				/* 
					We need to set a min-width on the cell to prevent the cell from shrinking too much on narrow screen.
					This is important because the cell width when set in % calculates the width based on the builder width. 
					It may make cell too narrow on narrow screen.
				 */
				min-width: var(--min-uncollapse-width) !important;

				span.placeholder-content {
					/**
						This is the same to previous, but it also takes into account the padding of the cell.
				 	*/
					--min-uncollapse-width-wo-padding: calc(var(--min-uncollapse-width) - 20px);
					min-width: var(--min-uncollapse-width-wo-padding) !important;
				}
			}
		}
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		font-family: ${theme.fonts['haas-grotesk']};
	`}
`;

export const tableWrapper = (): SerializedStyles => css`
	position: relative;
	overflow-y: auto;
`;

/**
 * Set explicitly to rewrite global styles
 */
export const tableRowStyle = css`
	vertical-align: middle;
`;
