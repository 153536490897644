import React, { FC } from 'react';

import { ChartType } from '~/types';

import { BarChart } from './BarChart';
import { LineChart } from './LineChart';
import { PieChart } from './PieChart';

import type { ChartElement } from '~/types/WebtextManifest';

const chartComponents = {
	[ChartType.pie]: PieChart,
	[ChartType.bar]: BarChart,
	[ChartType.line]: LineChart
};

export interface Props {
	chart: ChartElement;
	monochrome?: boolean;
	zoomed?: boolean;
}

const Chart: FC<Props> = (props) => {
	const { chart, monochrome, zoomed } = props;
	const ChartComponent = chartComponents[chart.chart_type];
	if (!ChartComponent) {
		console.warn(`Unrecognized chart type = '${chart.chart_type}'`);
		return null;
	}
	return <ChartComponent chart={chart} monochrome={monochrome} zoomed={zoomed} />;
};

export default Chart;
