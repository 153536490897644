import * as React from 'react';

export function FemaleIcon(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={34.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.9330497566215197px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={30.5} fill="currentColor" />
			<circle
				cx={36}
				cy={28.29}
				r={12.86}
				style={{
					fill: 'none',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.9330497566215197px'
				}}
			/>
			<path
				style={{
					fill: 'none',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.9330497566215197px'
				}}
				d="M36 58.25V41.71M27.12 49.98h17.75"
			/>
		</svg>
	);
}
