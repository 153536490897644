import React, { InputHTMLAttributes } from 'react';

import { styles, checkboxInputStyles } from './styles';

const WebtextCheckbox: React.FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
	const { children, ...rest } = props;
	return (
		<label css={styles}>
			<input type="checkbox" {...rest} css={checkboxInputStyles} />
			{children}
		</label>
	);
};

export default WebtextCheckbox;
