import { getCellCoordinates, getStartCell } from './selection';

export const getCellAddress = (rowIndex: number, columnIndex: number, origin = 'A1'): string => {
	const { horizontal: horizontalShift, vertical: verticalShift } = getCellCoordinates(origin);
	return `${String.fromCharCode(columnIndex + horizontalShift)}${rowIndex + verticalShift}`;
};

export const getOriginAddressShift = (origin: string): [number, number] => {
	if (!origin) return [0, 0];

	const originCell = getStartCell([origin]);
	if (originCell) {
		const { horizontal, vertical } = originCell;
		return [horizontal - 65, vertical - 1];
	}
};
