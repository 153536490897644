import { css } from '@emotion/react';
import { darken } from 'polished';

import { breakpoints, mixins, ThemeName } from '~/styles/themes';

export const containedStyles = (theme) => css`
	${mixins.webtextQuestionButtons(theme)}
	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		margin-right: 0;
		padding: 14px 24px;
		background: ${theme.colors.primary} !important;
		border-radius: 6px;
		color: white !important;
		font-weight: 500 !important;
		font-size: 18px;
		line-height: 1.2;

		&:disabled {
			opacity: unset;
			padding: 13px 23px;
			border: 2px solid rgb(88, 88, 88) !important;
			background: none !important;
			color: rgb(88, 88, 88) !important;
			border-radius: 6px !important;
		}

		@media (max-width: ${breakpoints.small}) {
			box-sizing: border-box;
			max-width: 100%;
			min-width: initial;
			display: block;
			width: 100%;
		}
	`}
`;

export const textStyles = (theme) => css`
	${mixins.webtextQuestionButtons(theme)}

	color: ${theme.colors.primary};
	text-decoration: underline;
	background-color: transparent;
	line-height: 1.25em;

	&:hover {
		background: transparent;
		color: ${darken(0.1, theme.colors.primary)};
	}

	&:focus {
		box-shadow: none;
		background: transparent;
	}

	&[disabled],
	&.snoozed {
		&:hover {
			background: transparent;
		}
	}

	&:last-of-type {
		padding-right: 0;
	}

	&:first-of-type {
		padding-left: 0;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		&:disabled {
			opacity: unset;
		}
	`}
`;

export const underlinedStyles = (theme) => css`
	${mixins.webtextQuestionButtons(theme)}

	box-shadow: inset 0 0px 0 white, inset 0 -3px 0 ${theme.colors.primary};
	background-color: transparent;

	padding: 0.5em 0;
	margin: 0em 1em;
	line-height: 1.25em;
	border-radius: 0;

	&:hover {
		background: transparent;
		box-shadow:
			inset 0 0px 0 white,
			inset 0 -3px 0 ${darken(0.05, theme.colors.primary)};
	}

	&:focus {
		border-width: 0px !important;
		background: transparent;
	}

	&[disabled],
	&.snoozed {
		&:hover {
			background: transparent;
		}
	}

	&:last-of-type {
		padding-right: 0;
	}

	&:first-of-type {
		padding-left: 0;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		padding: 15px 0;
		border: 0;
		font-family: ${theme.fonts.app};
		color: ${theme.colors.primary};

		&:disabled {
			opacity: unset;
		}

		@media (min-width: ${breakpoints.small}) {
			margin-right: 30px;
		}

		@media (max-width: ${breakpoints.small}) {
			font-size: 16px;
			display: block;
			width: 100%;
		}
	`}
`;
