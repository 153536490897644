import { type ComponentType, type ReactElement } from 'react';

import { type CitationResponse, type CitationsConfig } from '../../../types';
import CitationMetaProvider from '../providers/CitationMetaProvider';

function withCitationsProvider<T>(Component: ComponentType<T>) {
	return function LexicalComposerWrapper(
		props: T & {
			storedCitations?: CitationResponse[];
			citationsConfig?: CitationsConfig;
			withCitations?: boolean;
		}
	): ReactElement {
		return (
			<CitationMetaProvider
				storedCitations={props.storedCitations}
				citationsConfig={props.citationsConfig}
				withCitations={props.withCitations}>
				<Component {...(props as any)}></Component>
			</CitationMetaProvider>
		);
	};
}

export default withCitationsProvider;
