import { createContext } from 'react';

export const ErrorMessages = {
	LAUNCH_FAILED: 'Couldn’t connect to AI service. Please refresh the page to try again.',
	SEND_FAILED: 'Failed to send message. Please refresh the page to try again.',
	WEBSOCKET_RECONNECT_ATTEMPTS_EXHAUSTED:
		'Couldn’t connect to AI service. Please refresh the page to try again.',
	BAD_CONFIG_ASSISTANT_NOT_FOUND: (model: string): string =>
		`Configuration error: specified model "${model}" not found`,
	BAD_CONFIG_NO_ASSISTANTS: 'Configuration error: no assistants specified',
	BAD_CONFIG_NO_CONTEXT_PROVIDER: 'Configuration error: missing HostedGPTContext provider'
};

export interface ChatboxMessage {
	id: number;
	role: 'user' | 'assistant';
	index: number;
	version: number;
	conversationId: number;
	htmlContent: string;
	markdownContent: string;
	stopped: boolean;
	isOptimistic?: boolean;
}

export interface Assistant {
	id: number;
	/** Human-readable name, e.g. "Claude 3 Opus" */
	name: string;
	/** Model string ID, e.g. "claude-3-opus-20240229" */
	language_model_name: string;
}

export interface IHostedGPTContext {
	status:
		| 'launching' // `POST /launch` pending
		| 'ready' // launch successful, waiting on input
		| 'sending-message' // `POST /messages` (send message) pending
		| 'streaming-response' // waiting for/receiving streaming updates
		| 'error';
	errorMessage?: string;

	assistantId: Assistant['id'] | null;
	availableAssistants: Assistant[];
	messages: ChatboxMessage[];

	onSend: (message: string) => void | Promise<void>;
	onStop: () => void | Promise<void>;
	onChangeAssistant: (assistantId: Assistant['id']) => void;
	onClear: () => void;
}

export const HostedGPTContext = createContext<IHostedGPTContext>({
	status: 'error',
	assistantId: null,
	availableAssistants: [],
	messages: [],
	errorMessage: ErrorMessages.BAD_CONFIG_NO_CONTEXT_PROVIDER,

	onSend: () => undefined,
	onStop: () => undefined,
	onChangeAssistant: () => undefined,
	onClear: () => undefined
});
