import { SerializedStyles, css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

import { TextOptions } from '../WritingTemplate/types';

export default (
	{ colors, webtextWritingTemplate },
	options: TextOptions[],
	alwaysInline: boolean
): SerializedStyles => {
	const { fontSize } = webtextWritingTemplate || {};
	return css`
		background: transparent;
		color: ${colors.text};
		${fontSize && `font-size: ${fontSize} !important`};
		word-break: break-word;
		@media (max-width: ${breakpoints.small}) {
			font-size: 16px;
			font-weight: 500;
		}
		${options
			.map((option) => {
				switch (option) {
					case TextOptions.Bold:
						return 'font-weight: bold !important;';
					case TextOptions.Allcaps:
						return 'text-transform: uppercase !important;';
					case TextOptions.Capitalize:
						return 'text-transform: capitalize !important;';
					case TextOptions.Italic:
						return 'font-style: italic !important;';
				}
			})
			.join('\n')}

		p {
			margin-bottom: 1em !important;
			min-height: 1px;
			font-size: unset !important;
			${alwaysInline && 'display: inline;'};

			span * {
				font-size: unset !important;
			}
		}

		ul {
			margin: 12px 0 !important;
		}

		p:last-of-type {
			margin-bottom: 0 !important;
		}

		hr {
			border: none;
			color: rgb(204, 204, 204);
			background-color: rgb(204, 204, 204);
			height: 1px;
			margin: 2em 0px 1.5em;
		}
	`;
};
