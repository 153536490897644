import { DateTime } from 'luxon';

import { formatDateTime } from '~/formatters';
import {
	NotebookChapterSummary,
	NotebookPageSummary,
	SummaryData,
	UserDecisions
} from '~/notebook/types';
import { User } from '~/types';

import type { BasePageElement, Chapter, CoursePolicy, Manifest } from '~/types/WebtextManifest';

export function getPageIdsForManifest(manifest) {
	return manifest.toc.chapter_ids.flatMap((cid) => manifest.chapters[cid].page_ids);
}

export function getElementsForPage(
	manifest: any,
	pageId: string,
	userDecisions: UserDecisions = {}
): BasePageElement[] {
	const page = manifest.pages[pageId];
	/**
	 * Modify element content based on book-wide user decisions
	 * Decisions are tracked as a QuizResponse with a respondable of the Book's TOC
	 */
	return page.element_ids.map((id) => {
		const element = manifest.elements[id];
		if (!Array.isArray(element.depends_on) || !element.depends_on.length) {
			return element;
		}

		const updatedElement = { ...element };
		const decisionName = element.depends_on[0];
		const userDecision = userDecisions[decisionName];
		if (userDecision) {
			Object.keys(element.dictionary).forEach((key) => {
				updatedElement[key] = element.dictionary[key][userDecision];
			});
		}
		if (element.choices) {
			element.choices.forEach((choice, choiceIdx) => {
				if (!Array.isArray(choice.depends_on) || !choice.depends_on.length) return;

				const updatedChoice = { ...choice };
				const decisionName = choice.depends_on[0];
				const userDecision = userDecisions[decisionName];
				if (userDecision) {
					Object.keys(choice.dictionary).forEach((key) => {
						updatedChoice[key] = choice.dictionary[key][userDecision];
					});
				}
				updatedElement.choices[choiceIdx] = updatedChoice;
			});
		}
		return updatedElement;
	});
}

export function getUserName(user: User) {
	return `${user.first_name} ${user.last_name}`;
}

export function notebookLinkForPage(pageId) {
	return `page_view?page_id=${encodeURIComponent(pageId)}`;
}

export function chapterNumberAndName(chapter: Chapter) {
	const { chapter_number, chapter_name } = chapter;
	const hasNumber = !isNaN(parseInt(chapter_number));
	return `${hasNumber ? chapter_number + '. ' : ''}${chapter_name}`;
}

export function pageNumberAndName(pageId: string, manifest: Manifest) {
	const page = manifest.pages[pageId];
	if (!page) return 'no page found';
	const pageConfig = manifest.toc.config.pages.types[page.page_type];
	const pageTypeLabel = pageConfig.nav_bar_label ? pageConfig.nav_bar_label + ': ' : '';
	return `${page.page_number ? page.page_number : ''} ${pageTypeLabel} ${page.page_name}`;
}

export function pageSummaryLabel(page: NotebookPageSummary) {
	const { pageTypeLabel } = page;
	return `${page.pageNumber ? page.pageNumber : ''} ${pageTypeLabel ? pageTypeLabel + ': ' : ''} ${
		page.name
	}`;
}

export function chapterLabel(chapter: NotebookChapterSummary) {
	const { chapterNumber, name } = chapter;
	const hasNumber = !isNaN(parseInt(chapterNumber));
	return hasNumber ? `Chapter ${chapterNumber}` : name;
}

/**
 * @param summary
 * @return score fraction number ex .5
 */
export function scoreFraction(summary: SummaryData) {
	if (summary.questionsScored === 0) return 1;
	return summary.questionsCorrect / summary.questionsScored;
}

export function completionFraction(summary: SummaryData) {
	if (summary.questionsPossible === 0) return 1;
	return summary.questionsCompleted / summary.questionsPossible;
}

/**
 * @param summary
 * @return score percentage string ex. "50%"
 */
export function scorePercentage(summary: SummaryData) {
	return `${Math.floor(scoreFraction(summary) * 100)}%`;
}

export interface URLData {
	view: string;
	page_id: string;
}

export function getQueryStringParams(query = window.location.search) {
	return query
		? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
				const [key, value] = param.split('=');
				params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
				return params;
		  }, {})
		: {};
}

export function getParamsFromURL(): URLData {
	const params = getQueryStringParams();
	return {
		view: params['view'],
		page_id: params['page_id']
	};
}

export function getLimitedResetInfo(page: NotebookPageSummary) {
	if (!page.limitedAttempts || page.resetsRemaining > 0) {
		return null;
	}
	return 'No resets left on this page.';
}

export function getDueDateInfo(summary: SummaryData) {
	if (!summary.dueAt) {
		return null;
	}

	const dueAt = DateTime.fromISO(summary.dueAt);
	const penaltyPeriodEndsAt = DateTime.fromISO(summary.penaltyPeriodEndsAt);
	const now = DateTime.local();

	if (dueAt <= now) {
		return `Originally due on ${formatDateTime(dueAt, "MMM dd, yyyy, 'at' hh:mm a ZZZZ")}`;
	} else {
		return `Due on ${formatDateTime(dueAt, "MMM dd, yyyy, 'at' hh:mm a ZZZZ")} (${dueAt
			.toRelative()
			.replace('in ', '')} from now)`;
	}
}

export function getPenaltyPeriodInfo(summary: SummaryData) {
	if (!summary.dueAt) return null;
	if (summary.dueAt === summary.penaltyPeriodEndsAt) return null;
	if (summary.pastDuePenaltyFactor === 0) return null;

	const dueAt = DateTime.fromISO(summary.dueAt);
	const penaltyPeriodEndsAt = DateTime.fromISO(summary.penaltyPeriodEndsAt);
	const now = DateTime.local();

	if (now < dueAt) return null;

	if (now < penaltyPeriodEndsAt) {
		return `Reduced credit period ends on ${formatDateTime(
			penaltyPeriodEndsAt,
			"MMM dd, yyyy, 'at' hh:mm a ZZZZ"
		)}.`;
	} else {
		return `Reduced credit period ended on ${formatDateTime(
			penaltyPeriodEndsAt,
			"MMM dd, yyyy, 'at' hh:mm a ZZZZ"
		)}.`;
	}
}

export function courseHasLimitedAttempts(coursePolicy: CoursePolicy) {
	const defaultLimited =
		Object.keys(coursePolicy.defaults).filter((respondableType) => {
			const policyKey = coursePolicy.defaults[respondableType];
			const policy = coursePolicy.policies[policyKey];
			return policy.attempts_allowed > 0;
		}).length > 0;
	if (defaultLimited) return true;

	const instancesLimited =
		Object.keys(coursePolicy.instances).filter((key) => {
			const policyKey = coursePolicy.instances[key];
			const policy = coursePolicy.policies[policyKey];
			return policy.attempts_allowed > 0;
		}).length > 0;

	return instancesLimited;
}

/**
 * @see requirement T-113684
 */
export function roundGradeNumerator(value: number, lmsGradebookPrecision: number): string {
	return value.toFixed(lmsGradebookPrecision);
}

/**
 * @see requirement T-113684
 */
export function roundGradeDenominator(value: number, lmsGradebookPrecision: number): string {
	return Math.trunc(value) === value ? `${value}` : value.toFixed(lmsGradebookPrecision);
}
