/**
 * Ported from core
 *
 * @param timestamp - like 00:00
 * @returns time in seconds
 */
export const getTimeFromTimestamp = (timestamp: string): number => {
	const [seconds, minutes] = timestamp.split(':').reverse();
	let timeInSeconds = parseInt(seconds);
	if (minutes != null) {
		timeInSeconds = timeInSeconds + parseInt(minutes) * 60;
	}
	return timeInSeconds;
};

/**
 * Extracts the filename from the `Content-Disposition` header
 *
 * @param header - `Content-Disposition` header sent from server alongside the file
 * @returns {String} name of the sent file
 *
 * @example
 * const header = 'attachment; filename="D190_Unit_2_Case_Study.docx"; filename*=UTF-8''D190_Unit_2_Case_Study.docx';
 * const filename = getContentDispositionFilename(header);
 * console.log(filename); // 'D190_Unit_2_Case_Study.docx'
 */
export const getContentDispositionFilename = (header: string): string | null =>
	/filename="(?<filename>.*)"/.exec(header)?.groups?.filename ?? null;

/**
 * https://stackoverflow.com/a/9001644/10963661
 *
 * @example
 * https://playcode.io/907195
 */
export const parseFilename = (filename: string): { file: string; extension: string } => {
	const regex = /^([^\\]*)\.(\w+)$/;
	const matches = filename.match(regex);
	return {
		file: matches[1],
		extension: matches[2]
	};
};

export const isNumeric = (value: string | number): boolean => {
	if (typeof value === 'string' && !value.length) return false;
	return isFinite(+value);
};

/**
 * Calculates the number of world in the sentence using the rules:
 * - if the "word" doesn't contain any alphanumeric characters (i.e. it's all symbols), we don't count it
 * - if the "word" is one character long, and isn't either i or a, we don't count it
 * - if the only "word" in the sentence is i or a, we don't count it
 * @example
 * https://playcode.io/966227
 */
export const getWordsNumber = (sentence: string): number => {
	const words = sentence.trim().split(' ');

	// One-letter sentence. Don't count words there
	if (words.length === 1 && words[0].length === 1) {
		return 0;
	}

	const alphanumericRegex = /^[0-9a-z]+$/i;
	const oneLetterWordsRegex = /[ia]/i;
	return sentence
		.split(' ')
		.filter((word) => alphanumericRegex.test(word))
		.filter((word) => word.length > 1 || oneLetterWordsRegex.test(word)).length;
};

/**
 * Checks whether string includes HTML markup
 */
export const includesMarkup = (description: string): RegExpMatchArray =>
	description?.match(/<[^>]+>/g);

export const trimTags = (str: string): string => str.replace(/<\/?\w+>/g, '');

/**
 * E.g. "Library of Congress | <a target="_blank" href="remote_link" >View larger image</a>" -> "Library of Congress"
 * https://regexr.com/6joij
 */
export const removeEnlargeLink = (payload: string) => {
	const viewLargerImageRegex = /(\|\s)?<a(.+)href=(.*)View larger image(.+)a>(\s\|)?/;
	return payload.replace(viewLargerImageRegex, '').trim();
};
