import shuffle from '~/utils/shuffle';

import type { MCQuestionPoolItem, MCQuestionPoolElement } from '~/types/WebtextManifest';

export interface GetCurrentPoolItemArgs {
	element: MCQuestionPoolElement;
	userId: number | string;
	numResets: number;
}

export const getCurrentPoolItem = ({
	element,
	userId,
	numResets
}: GetCurrentPoolItemArgs): MCQuestionPoolItem => {
	const shuffledPoolItems = shuffle(element.questions, `${element.family_id}${userId}`);
	return shuffledPoolItems[numResets % shuffledPoolItems.length];
};

export const getMCQuestionPoolId = (familyId: string): string => `mc-question-pool-${familyId}`;

export const getMCQuestionPoolPromptBodyId = (familyId: string): string =>
	`${getMCQuestionPoolId(familyId)}-body`;

export const getMCQuestionPoolExpandingContentId = (familyId: string): string =>
	`${getMCQuestionPoolId(familyId)}-content`;
