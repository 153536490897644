import * as React from 'react';

export function Quill(): JSX.Element {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 72 72">
			<g>
				<g transform="translate(2 2)">
					<path
						fill="white"
						stroke="currentColor"
						strokeWidth="2.88"
						strokeMiterlimit="2.88"
						d="M67.8,34c0,18.7-15.2,33.9-33.9,33.9C15.2,67.9,0,52.7,0,34C0,15.3,15.2,0.1,33.9,0.1
						C52.6,0.1,67.8,15.3,67.8,34z"
					/>
					<path
						d="M63.9,34c0,16.5-13.4,30-30,30C17.4,64,4,50.5,4,34c0-16.5,13.4-30,30-30C50.5,4,63.9,17.5,63.9,34"
						fill="currentColor"
					/>
					<path
						fill="white"
						d="M50,17.2C38,17.2,28,25.8,25.7,37.2h-6.8c-0.5,0-1,0.4-1,1s0.4,1,1,1h0.3v2c-1.1,0.2-2,1.1-2,2.3v5.4
					c0,1.3,1,2.3,2.3,2.3h13.3c1.3,0,2.3-1,2.3-2.3v-5.4c0-1.2-0.9-2.1-2-2.3v-2h0.3c0.5,0,1-0.4,1-1s-0.4-1-1-1h-5.8
					c0.1-0.7,0.3-1.3,0.5-2c6.1,0,8.5,0.3,12.2-1.2c0.4-0.2,0.6-0.5,0.6-0.9v-3l2.3,1.5c0.4,0.2,0.8,0.2,1.2,0c4-3.1,6.5-8,6.5-13.3
					C51,17.7,50.6,17.2,50,17.2L50,17.2z M25.3,39.1c-0.1,0.7-0.1,1.3-0.2,2h-4v-2H25.3z M33.1,48.9c0,0.2-0.1,0.3-0.3,0.3H19.5
					c-0.2,0-0.3-0.1-0.3-0.3v-5.4c0-0.2,0.1-0.3,0.3-0.3h13.3c0.2,0,0.3,0.1,0.3,0.3V48.9z M31.1,39.1v2h-4c0-0.7,0.1-1.3,0.2-2
					H31.1z M43.9,29.5l-3.2-2.2c-0.7-0.4-1.5,0-1.5,0.8v4.1c-2.9,1-4.7,0.8-10.1,0.8c3.4-8.1,11.3-13.5,20-13.9
					C48.7,23.2,46.9,26.9,43.9,29.5L43.9,29.5z M43.9,29.5"
					/>
				</g>
			</g>
		</svg>
	);
}
