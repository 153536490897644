import React, {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';

import isEmpty from 'lodash-es/isEmpty';

import { mapSourceResponses } from '~/components/Outline/helpers/sourceMapper';
import { OutlineConfig, OutlineResponsesState } from '~/components/Outline/types';

import { IDictionary, Kinds, SpreadsheetProps } from '../../WritingTemplate/types';
import { getInteractivePromptsParts } from '../helpers';
import { getListRef, isCitationEnable } from '../helpers/citationService';
import { createLabelMap } from '../helpers/labelMapHelper';

import type { IApi } from '../../WritingTemplate/WritingTemplate';

export interface EditModeState {
	userInputs: IDictionary;
	combinedInputs: IDictionary;
	composedOutput: IDictionary;
	labelMap: IDictionary;

	isInitialized: boolean;
	autosaveRequested: boolean;
	isUserUpdate: boolean;
	saveRequested: boolean;
	finalSaveRequested: boolean;
	isPromptsReadOnly: boolean;

	spreadsheetErrors: Record<string, boolean>;
	transferSelection: Record<string, string[]>;
	referenceSelection: Record<string, string[]>;

	spreadsheetProps: SpreadsheetProps;
	tabUpdate: IDictionary;
	inputValues: IDictionary;
	fromConfig: IDictionary;
	schema: IDictionary;
	toc: IDictionary;
	getImageUrl: IApi['getImageUrl'];
	imageUploadUrl: string;
	builderFamilyId: string;

	setAutosaveRequested: Dispatch<SetStateAction<boolean>>;
	setSaveRequested: Dispatch<SetStateAction<boolean>>;
	setFinalSaveRequested: Dispatch<SetStateAction<boolean>>;
	setUserUpdate: Dispatch<SetStateAction<boolean>>;
	setReferenceSelection: Dispatch<SetStateAction<Record<string, string[]>>>;
	handleChange: (dest: string, value: unknown) => void;
	updateTransferSelection: (dest: string, selectedCell: string, selection: string[]) => void;
	updateErrorStatus: (dest: string, isAnyError: boolean) => void;
	getAwsParams: (name: string) => Promise<unknown>;
	updateMapDependentValues: (dest: string, choice: string) => void;
	scrollableContainer: HTMLElement;
}

type Props = Pick<
	EditModeState,
	| 'schema'
	| 'toc'
	| 'tabUpdate'
	| 'inputValues'
	| 'fromConfig'
	| 'getAwsParams'
	| 'getImageUrl'
	| 'imageUploadUrl'
	| 'builderFamilyId'
	| 'spreadsheetProps'
	| 'scrollableContainer'
> & { readOnly: boolean; instructorView?: boolean; isScaling?: boolean };

export const EditModeStateContext = createContext<EditModeState | null>(null);

const EditModeStateProvider: FC<Props> = (props) => {
	const {
		children,
		schema,
		toc,
		fromConfig,
		tabUpdate,
		inputValues,
		getAwsParams,
		getImageUrl,
		imageUploadUrl,
		builderFamilyId = '',
		readOnly,
		instructorView,
		isScaling,
		spreadsheetProps,
		scrollableContainer
	} = props;

	const [isInitialized, setIsInitialized] = useState(false);

	const [userInputs, setUserInputs] = useState<IDictionary>({});
	const [composedOutput, setComposed] = useState<IDictionary>({});

	const [isUserUpdate, setUserUpdate] = useState(false);
	const [autosaveRequested, setAutosaveRequested] = useState(false);
	const [saveRequested, setSaveRequested] = useState(false);
	const [finalSaveRequested, setFinalSaveRequested] = useState(false);

	const [spreadsheetErrors, setErrors] = useState<Record<string, boolean>>(null);

	const [transferSelection, setTransferSelection] = useState<Record<string, string[]>>({});
	const [referenceSelection, setReferenceSelection] = useState<Record<string, string[]>>({});

	const [labelMap] = useState<IDictionary>(createLabelMap(schema?.prompts));

	// We need to cast the value to boolean explicitly because in some cases it can be null/undefined, but we want to treat it as false
	const isPromptsReadOnly = Boolean(
		saveRequested || finalSaveRequested || readOnly || instructorView || isScaling
	);

	const mapDependencies: IDictionary = useMemo(() => {
		if (!schema) return {};

		return getInteractivePromptsParts(schema.prompts)
			?.filter(({ kind }) => kind === Kinds.Map)
			.reduce(
				(acc, { dest, map, source }) => ({
					...acc,
					[dest]: { source, map }
				}),
				{}
			);
	}, [schema]);

	const handleChange = useCallback(
		(dest: string, value: unknown) => setUserInputs((prev) => ({ ...prev, [dest]: value })),
		[]
	);

	const updateErrorStatus = useCallback(
		(dest: string, isAnyError: boolean) => setErrors((prev) => ({ ...prev, [dest]: isAnyError })),
		[]
	);

	const updateTransferSelection = useCallback(
		(dest: string, selectedCell: string, selection: string[]) =>
			setTransferSelection((prev) => ({
				...prev,
				[dest]: selection.length > 0 ? selection : selectedCell ? [selectedCell] : []
			})),
		[]
	);

	const updateMapDependentValues = useCallback(
		(dest: string, choice: string) => {
			const maps = Object.entries(mapDependencies)
				.filter(([_, dependency]) => dependency?.source === dest && !!dependency?.map)
				.map(([dependencyDest, dependency]) => [dependencyDest, dependency.map[choice]]);

			maps.forEach(([dest, value]) => {
				handleChange(dest, value);
				updateMapDependentValues(dest, value);
			});
		},
		[mapDependencies, handleChange]
	);

	const getUserInputs = useCallback(
		(dests: IDictionary) => {
			const prompts = schema.prompts || schema.output.outline;
			if (!prompts) return;

			const userInputsResult = {};

			const promptsParts = getInteractivePromptsParts(prompts);
			promptsParts.forEach(setPromptUserInputs);
			return userInputsResult;

			function setPromptUserInputs(part: IDictionary) {
				const { kind, dest, source, options } = part;

				/**
				 * The `dest` has already been processed on recursive `source` lookup
				 */
				if (userInputsResult[dest]) return;

				if (kind === Kinds.FillIn) {
					const isCitations = isCitationEnable(options, toc);
					const listRef = getListRef(options);

					if (isCitations) {
						const initWith = {
							[Kinds.CitationOutput]: {
								kind: Kinds.FillInWithCitations,
								'source-list-ref': listRef,
								citations: [],
								text: ''
							}
						};

						setComposed((prev) => ({ ...prev, ...initWith }));
					}
				}

				/**
				 * The value is present in the payload of the WT
				 */
				const inputValue = dests[dest];
				if (inputValue) {
					userInputsResult[dest] = inputValue;
					return;
				}

				const remoteSourceValue = dests[source];
				if (remoteSourceValue) {
					userInputsResult[dest] = getMappedSourceValue(part, remoteSourceValue);
					return;
				}

				/**
				 * The `source` part already has the value assigned
				 */
				const localSourceValue = userInputsResult[source];
				if (localSourceValue) {
					userInputsResult[dest] = getMappedSourceValue(part, localSourceValue);
					return;
				}

				/**
				 * Check if the `source` refers to other part in the same WT
				 */
				const sourcePart = promptsParts.find((part) => part.dest === source);
				if (!sourcePart) {
					userInputsResult[dest] = ''; // No `source` part available - using default value
					return;
				}

				/**
				 * Recursively process the `source` part first to populate its dest
				 */
				setPromptUserInputs(sourcePart);

				/**
				 * Now the `source` part should have its dest populated
				 */
				const populatedLocalSourceValue = userInputsResult[source];
				userInputsResult[dest] = getMappedSourceValue(part, populatedLocalSourceValue);
			}

			/**
			 * Processes the source value to conform to the prompt's allowed values
			 *
			 * @example `Map`:
			 * The map converts the received `source` value into another value based on the mapping configuration
			 *
			 *  @example `Outline` imports:
			 * The `Outline` PIs responses format might be different between the source one and the dest one.
			 * Maps the source responses to conform the dest hierarchy
			 */
			function getMappedSourceValue(
				part: IDictionary,
				sourceValue: OutlineResponsesState | string
			) {
				let mappedValue;
				switch (part.kind) {
					case Kinds.Map:
						mappedValue = part.map[sourceValue as string];
						break;
					case Kinds.Chart:
						mappedValue = sourceValue instanceof Object && 'type' in sourceValue ? sourceValue : '';
						break;
					case Kinds.Outline:
						mappedValue = mapSourceResponses(
							part as OutlineConfig,
							sourceValue as OutlineResponsesState
						);
						break;
					default:
						mappedValue = sourceValue;
				}
				return mappedValue ?? '';
			}
		},
		[schema.output.outline, schema.prompts, toc]
	);

	useEffect(() => {
		if (!isEmpty(tabUpdate)) {
			// If we get an update from another tab, treat it just like a clean slate
			setUserInputs(getUserInputs(tabUpdate));
			setAutosaveRequested(null);
		}
	}, [getUserInputs, tabUpdate]);

	useEffect(() => {
		if (isInitialized) return;
		setUserInputs(getUserInputs(inputValues));
		setIsInitialized(true);
	}, [inputValues, isInitialized, getUserInputs]);

	useEffect(() => {
		if (!isEmpty(mapDependencies)) {
			Object.entries(userInputs).forEach((userInput) => updateMapDependentValues(...userInput));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapDependencies]);

	/**
	 * Used to group the static dests and the dests that are imported from other templates
	 * and the latest state of the interactive prompts' dests
	 */
	const combinedInputs = useMemo(
		() => ({ ...inputValues, ...userInputs }),
		[inputValues, userInputs]
	);

	const contextValues = useMemo(
		() => ({
			userInputs,
			composedOutput,
			combinedInputs,
			labelMap,

			isInitialized,
			autosaveRequested,
			isUserUpdate,
			isPromptsReadOnly,
			saveRequested,
			finalSaveRequested,

			spreadsheetErrors,
			transferSelection,
			referenceSelection,

			tabUpdate,
			inputValues,
			fromConfig,
			schema,
			imageUploadUrl,
			toc,
			spreadsheetProps,

			getAwsParams,
			getImageUrl,
			updateMapDependentValues,
			setAutosaveRequested,
			handleChange,
			updateErrorStatus,
			updateTransferSelection,
			setReferenceSelection,
			setUserUpdate,
			builderFamilyId,
			setSaveRequested,
			setFinalSaveRequested,
			scrollableContainer
		}),
		[
			composedOutput,
			userInputs,
			tabUpdate,
			autosaveRequested,
			transferSelection,
			isInitialized,
			spreadsheetErrors,
			combinedInputs,
			referenceSelection,
			inputValues,
			fromConfig,
			schema,
			toc,
			getImageUrl,
			getAwsParams,
			labelMap,
			imageUploadUrl,
			updateMapDependentValues,
			handleChange,
			updateErrorStatus,
			updateTransferSelection,
			isUserUpdate,
			builderFamilyId,
			isPromptsReadOnly,
			saveRequested,
			finalSaveRequested,
			spreadsheetProps,
			scrollableContainer
		]
	);

	return (
		<EditModeStateContext.Provider value={contextValues}>{children}</EditModeStateContext.Provider>
	);
};

export default EditModeStateProvider;
