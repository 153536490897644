import React from 'react';

import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

const styles = () => css`
	margin-bottom: 2em;

	@media (max-width: ${breakpoints.small}) {
		margin-bottom: 1.2em;
	}
`;

export const Spacer: React.FC = () => <div css={styles}></div>;
