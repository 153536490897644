import * as React from 'react';

export function GrassrootsMobilization(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<g id="aa754f08-8bfd-4e06-8388-7033d4cd1bd9" data-name="comput">
				<circle cx="36" cy="36" r="34.53" fill="#fff" />
				<path
					d="M36,72A36,36,0,1,1,72,36,36,36,0,0,1,36,72ZM36,2.94A33.06,33.06,0,1,0,69.06,36,33.1,33.1,0,0,0,36,2.94Z"
					fill="currentColor"
				/>
				<circle cx="36" cy="36" r="30.53" fill="currentColor" />
				<path
					d="M28.44,56.7A4.76,4.76,0,0,1,24,53.61L18.63,39.38l2.57-1,5.35,14.26a2,2,0,0,0,3.91-.7,2.07,2.07,0,0,0-.06-.5l-3-12.23L30,38.58,33.06,50.8a4.75,4.75,0,0,1-3.44,5.77,4.66,4.66,0,0,1-1.18.15Z"
					fill="#fff"
				/>
				<path
					d="M31.84,40.27H18.14a2.74,2.74,0,0,1-2.74-2.74V29.31a2.73,2.73,0,0,1,2.74-2.74h13.7v2.74H18.14v8.22h13.7Z"
					fill="#fff"
				/>
				<path
					d="M49.64,48.63l-19.2-8.89V27.11l19.18-8.89ZM33.21,38l13.7,6.35V22.51l-13.7,6.35Z"
					fill="#fff"
				/>
				<rect x="52.39" y="32.05" width="4.11" height="2.74" fill="#fff" />
				<rect x="52.39" y="26.57" width="4.11" height="2.74" fill="#fff" />
				<rect x="52.39" y="37.53" width="4.11" height="2.74" fill="#fff" />
			</g>
		</svg>
	);
}
