import { css, SerializedStyles } from '@emotion/react';
import { darken } from 'polished';

import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';

export const transcriptLinkStyles = (theme: Theme): SerializedStyles => {
	return css`
		margin: 7px 0 20px;

		a {
			color: ${getThemeItem(theme.artifactLink.transcript.color, theme)};
			font-family: ${getThemeItem(theme.artifactLink.transcript.fontFamily, theme)};
			font-weight: ${theme.artifactLink.transcript.fontWeight};
			font-size: ${getThemeItem(theme.artifactLink.transcript.fontSize, theme)};
			text-decoration: underline;

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				@media (max-width: ${breakpoints.small}) {
					font-size: 14px;
				}
			`}
		}
	`;
};

export const styles = (theme: Theme): SerializedStyles => {
	const iconWidth = theme.name === ThemeName.UNIVERSAL_VELVET ? '45px' : '70px';
	const uvSmallIconWidth = '35px';

	return css`
		display: flex;
		padding: 5px 0;
		align-items: center;
		background-color: ${getThemeItem(theme.artifactLink.backgroundColor, theme)};

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			margin: 28px 12px 30px 13px;
			padding: 26px 30px 30px 32px;
			border: 1px solid ${theme.colors.primary};
			border-radius: 8px;
			column-gap: 20px;

			@media (max-width: ${breakpoints.small}) {
				padding: 20px 18px 24px 20px;
			}
		`}

		.webtext-artifact-icon {
			display: flex;
			width: 75px;
			justify-content: center;
			align-items: center;

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				width: auto;
			`}

			svg {
				margin: -5px 0;
				width: ${iconWidth};

				&.docx-download-icon,
				&.pdf-download-icon,
				&.xlsx-download-icon {
					height: 60px;
				}

				${theme.name === ThemeName.UNIVERSAL_VELVET &&
				css`
					@media (max-width: ${breakpoints.small}) {
						width: ${uvSmallIconWidth};
					}
				`}
			}
		}

		.webtext-artifact-body {
			display: flex;
			padding: 3px 0 5px;
			flex-direction: column;
			row-gap: 3px;
		}

		.webtext-artifact-link-title {
			display: inline-block;
			font-family: ${getThemeItem(theme.artifactLink.title.fontFamily, theme)};
			font-size: ${getThemeItem(theme.artifactLink.title.fontSize, theme)};
			color: ${getThemeItem(theme.artifactLink.title.color, theme)};
			text-decoration: underline;
			text-decoration-color: ${getThemeItem(theme.artifactLink.title.textDecorationColor, theme)};

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				text-decoration-thickness: 1px;

				@media (max-width: ${breakpoints.small}) {
					font-size: 15px;
				}
			`}
		}

		.webtext-artifact-link-credits {
			display: block;
			color: ${getThemeItem(theme.artifactLink.credits.color, theme)};
			font-size: ${getThemeItem(theme.artifactLink.credits.fontSize, theme)};
			font-family: ${getThemeItem(theme.artifactLink.credits.fontFamily, theme)};
			font-style: italic;

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				@media (max-width: ${breakpoints.small}) {
					font-size: 14px;
				}
			`};

			a {
				color: ${getThemeItem(theme.artifactLink.credits.color, theme)};

				&:hover {
					color: ${darken(0.05, getThemeItem(theme.artifactLink.credits.color, theme))};
				}
			}
		}
	`;
};
