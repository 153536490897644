import React, { FC } from 'react';

import { ChartLegend } from '../ChartLegend';
import EnlargeChartWrapper from '../EnlargeChart';
import D3LineChart from './D3LineChart';

import type { Props as ChartFigureProps } from '../Chart';
import type { ChartElement } from '~/types/WebtextManifest';

export type LineChart = Pick<
	ChartElement,
	| 'data'
	| 'axis_type'
	| 'x_axis_label'
	| 'x_axis_type'
	| 'x_ticks'
	| 'y_axis_label'
	| 'show_labels'
	| 'date_format'
	| 'min_bound'
	| 'max_bound'
	| 'colors'
	| 'margins'
	| 'fill_line'
	| 'line_stroke_width'
>;

export interface Props extends Omit<ChartFigureProps, 'chart'> {
	chart: LineChart;
}

const LineChart: FC<Props> = (props) => {
	const { chart, monochrome, zoomed } = props;
	const seriesLabels = chart.data[0].slice(1);

	return (
		<>
			{seriesLabels.length > 1 && (
				<ChartLegend
					seriesLabels={seriesLabels}
					colors={chart.colors}
					monochrome={monochrome}
					fill={chart.fill_line}
				/>
			)}
			<EnlargeChartWrapper zoomed={zoomed}>
				<D3LineChart chart={chart} monochrome={monochrome} />
			</EnlargeChartWrapper>
		</>
	);
};

export default LineChart;
