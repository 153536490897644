import React from 'react';

import { useTheme } from '@emotion/react';

import { Theme } from '~/styles/themes';

import {
	columnWidthStyle,
	rowStyle,
	tableCellStyle,
	tableHeadStyles,
	tableContainerStyle,
	tableStyle
} from './styles';

interface Props {
	dest?: string;
	rows: any[];
	columnWidths?: string[];
	isOutput?: boolean;
}

const Table: React.FC<Props> = (props) => {
	const { dest, rows, columnWidths, isOutput = false } = props;

	const tableHead = rows.slice(0, 1);
	const tableBody = rows.slice(1);
	const theme = useTheme() as Theme;

	const isAllColumnsEqual = !columnWidths?.length;

	/**
	 * Makes the table take `contentColumnWidth`
	 * for the narrow mobile screens.
	 * That way columns will be distributed against
	 * that width and won't be too narrow.
	 *
	 * It's applied only for tables with more than two columns
	 * to prevent redundant scrolling on small screens.
	 */
	const smallTableColumnsNumber = 2;
	const shouldExpandTable = getColumnsNumber(rows, columnWidths) > smallTableColumnsNumber;

	return (
		<div className="wt-table-container" css={tableContainerStyle}>
			<table
				className="wt-table"
				css={tableStyle(theme, {
					isEqual: isAllColumnsEqual,
					expandNarrowTable: shouldExpandTable
				})}>
				{!isAllColumnsEqual && (
					<colgroup>
						{columnWidths.map((width, index) => (
							<col key={index} width={width} />
						))}
					</colgroup>
				)}
				<thead>
					{tableHead.map((headRow, id) => (
						<tr key={`${dest}/${id}`} css={() => tableHeadStyles()}>
							{headRow.map((cell, idx) => (
								<th
									scope={`${cell.colspan ? 'colgroup' : 'col'}`}
									key={`${dest}_${idx}`}
									rowSpan={cell.rowspan}
									colSpan={cell.colspan}
									css={() => columnWidthStyle(theme, cell?.init?.kind, isOutput)}>
									<div
										css={() =>
											tableCellStyle(theme, cell.alignment, cell['vertical-alignment'], isOutput)
										}>
										{cell.value}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{tableBody.map((bodyRow, id) => (
						<tr key={`_${dest}/${id}`} css={rowStyle()}>
							{bodyRow.map((cell, idx) => (
								<td
									scope={idx === 0 ? 'row' : null}
									key={`${dest}_${idx}`}
									rowSpan={cell.rowspan}
									colSpan={cell.colspan}
									css={() => columnWidthStyle(theme, cell?.init?.kind, isOutput)}>
									<div
										css={() =>
											tableCellStyle(theme, cell.alignment, cell['vertical-alignment'], isOutput)
										}>
										{cell.value}
									</div>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getColumnsNumber = (rows: any[], columnWidths?: string[]): number => {
	if (columnWidths?.length > 0) {
		return columnWidths.length;
	}

	const rowsColumnsAmounts = rows.map((row) => row.length);
	return Math.max(...rowsColumnsAmounts);
};

export default Table;
