import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { Figure as FigureFromLibs } from '@soomo/lib/components/pageElements/index';
import updateElement from 'Requests/UpdateElementQuery';

class Figure extends Component {
	static propTypes = {
		course: object.isRequired,
		figure: object.isRequired,
		dispatch: func
	};

	constructor(props) {
		super(props);

		this.state = {
			updatingElement: false
		};
	}

	UNSAFE_componentWillMount() {
		const { dispatch, course, figure } = this.props;

		if (figure.requires_signing) {
			dispatch(updateElement({ courseId: course.id, elementId: figure.id }));
			this.setState({ updatingElement: true });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.figure.payload !== this.props.figure.payload) {
			this.setState({ updatingElement: false });
		}
	}

	render() {
		if (this.state.updatingElement) return null;

		const { figure, course } = this.props;

		const elementContext = {
			courseId: course.id,
			pageFamilyId: figure.page_id
		};

		/**
		 * The views from the instructors should not be counted in the system
		 * as they are not the target audience
		 */
		const notReportingFigure = { ...figure, disableEventReporting: true };
		return (
			<div style={{ marginBottom: 40 }}>
				<FigureFromLibs figureOptions={notReportingFigure} elementContext={elementContext} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	course: state.entities.course
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Figure);
