import React, { forwardRef, ButtonHTMLAttributes } from 'react';

import { textStyles, containedStyles, underlinedStyles } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'contained' | 'text' | 'underlined';
	secondary?: boolean;

	/** @deprecated */
	size?: 'small' | 'large';
}

const WebtextButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
	const { variant, secondary, ...rest } = props;
	if (variant === 'text') {
		return <button {...rest} ref={ref} css={textStyles} />;
	}
	if (variant === 'underlined' || secondary) {
		return <button {...rest} ref={ref} css={underlinedStyles} />;
	}
	return <button {...rest} ref={ref} css={containedStyles} />;
});
WebtextButton.displayName = 'WebtextButton';

export default WebtextButton;
