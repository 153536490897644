import { useRef } from 'react';

import { WaveformAudioPlayerRef } from '~/components/pageElements/WaveformAudioPlayer';
import { getTimeFromTimestamp } from '~/utils/parsing';

/**
 * A hook wrapping functionality for handling AudioFigures via refs.
 */
export function useAudioFigures() {
	const audioRefs = useRef<Record<string, WaveformAudioPlayerRef>>({});

	/**
	 * Clear audio references each time this hook initializes so we aren't holding onto ref's from
	 * other pages
	 */
	audioRefs.current = {};

	const playMediaSnippet = (familyId: string, start: string, end: string) => {
		if (audioRefs.current && audioRefs.current[familyId]) {
			/**
			 * Pause other players
			 */
			Object.keys(audioRefs.current).forEach((fId) => {
				if (fId !== familyId) {
					audioRefs.current[fId].current?.pause();
				}
			});

			audioRefs.current[familyId].current.playSnippet(
				getTimeFromTimestamp(start),
				getTimeFromTimestamp(end)
			);
		}
	};

	return { playMediaSnippet, audioRefs } as const;
}
