import { css } from '@emotion/react';

import { breakpoints, mixins } from '~/styles/themes';

export const refreshedChartStyles = (
	theme,
	options: { isSmallView: boolean } = { isSmallView: false }
) => css`
	margin: 0;

	.xAxis .tick line {
		stroke: ${theme.chart.tickColor};
	}

	.yAxis .tick line {
		stroke: ${theme.chart.tickColor};
	}

	.yAxis path {
		stroke: ${theme.chart.tickColor};
	}

	.xAxis path {
		stroke: ${theme.chart.tickColor};
	}

	.divider-line {
		stroke: ${theme.chart.lineColor};
	}

	.chart-value-axis-tick-text {
		font-size: 14px;
		fill: ${theme.colors['evil-gray']};

		@media (max-width: ${breakpoints.small}) {
			font-size: 10px;
		}
	}

	.chart-category-axis-tick {
		outline: none; // Removes default outline on focus
		font-size: 12px;
		font-weight: 400;
		line-height: 1.3;
		overflow: visible; // Shows the text underline

		&-container {
			display: flex;
			height: 100%;
		}

		&-container.horizontal {
			justify-content: flex-end;
			align-items: center;
		}

		&-container.vertical {
			justify-content: center;
			align-items: flex-start;
		}

		&-label {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			overflow-wrap: break-word;
			hyphens: auto;
			line-height: 1.2;

			.tick {
				a {
					color: ${theme.colors.link};
					text-decoration: underline;

					/**
					 * Override core styles #content a
					 */
					border: none !important;
				}
			}
		}

		&-label.horizontal {
			// Padding is required to display the '...' in Chrome, see https://soomo.height.app/T-86029#ec975898-1ea0-45e3-ae8b-95d12599ddca
			text-align: right;
			padding-right: 1.1em;
		}

		&-label.vertical {
			text-align: center;
		}
	}

	.label-underline {
		text-decoration: underline dotted ${theme.colors.primary};
		text-decoration-thickness: 2px;
	}

	.chart-bar-label {
		font-size: 12px;
		font-weight: 500;
	}

	.chart-x-axis-title,
	.chart-y-axis-title {
		font-size: 14px;
	}

	.pie-chart-label-line {
		stroke: ${theme.colors['evil-gray']};
	}

	.pie-chart-label {
		overflow: visible;
		font-size: 14px;
		line-height: 1.21; // Magically 1.21 makes the underline display properly in Chrome, see https://soomolearning.slack.com/archives/G1R0V6JAW/p1693313340755179?thread_ts=1693312331.723349&cid=G1R0V6JAW

		@media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
			font-size: 13px;
		}

		@media (max-width: ${breakpoints.small}) {
			font-size: 12px;
			line-height: 1.15;
		}

		&-text-container {
			width: fit-content;
			text-align: center;
		}

		&-text {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			overflow-wrap: break-word;
			hyphens: auto;

			.visually-hidden {
				${mixins.webtextHiddenAccessible(theme)}
			}
		}
	}

	.refreshed-chart-container {
		overflow-x: auto;

		&.pie {
			// override core UV _webtext_charts styles
			svg {
				max-width: none;
				max-height: none;
			}
		}
	}
`;

export const chartDataTableStyles = (theme) => css`
	overflow-x: auto;

	table {
		width: 100%;
		font-family: ${theme.fonts.app} !important;
	}

	table,
	th,
	td {
		border: 1px solid #000;
		border-collapse: collapse;
		// TODO: remove the next lines when core UV _webtext_figure.scss styles get removed
		line-height: 24px !important;

		@media (max-width: ${breakpoints.small}) {
			// TODO: remove the next lines when core UV _webtext_figure.scss styles get removed
			line-height: 16px !important;
		}
	}

	thead {
		th {
			padding: 10px 15px;
			font-weight: 700;
			font-size: 16px;
			// TODO: remove the next line when core UV _webtext_figure.scss styles get removed
			font-family: ${theme.fonts.app} !important;

			&:first-of-type {
				width: 100%;
			}

			&:not(:first-of-type) {
				text-align: center !important;
				min-width: 130px;
				overflow-wrap: break-word;
			}

			@media (max-width: ${breakpoints.small}) {
				font-size: 12px;
				padding: 6px 8px;

				&:not(:first-of-type) {
					min-width: unset;
				}
			}
		}
	}

	tbody {
		td,
		th {
			padding: 10px 15px;
			font-size: 16px;

			@media (max-width: ${breakpoints.small}) {
				padding: 6px 8px;
				font-size: 12px;
			}
		}

		td {
			text-align: right;
		}

		th {
			text-align: left;
			font-weight: normal;
			overflow-wrap: break-word;
			hyphens: auto;
		}

		.show-more-rows-container {
			display: flex;
			justify-content: center;

			@media (max-width: ${breakpoints.small}) {
				button {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
`;

export const showMoreButtonStyles = () => css`
	padding: 0;
	font-size: inherit;
	font-weight: normal;
	display: flex;
	align-items: center;

	svg {
		margin-left: 10px;
		// Override core width: 100% for pie svg
		width: auto !important;
	}
`;

export const chartDataTabContainerStyles = (theme) => css`
	margin-top: 30px;

	.download-csv-link {
		display: inline-block;
		margin-top: 20px;
	}
`;
