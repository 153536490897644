import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';
import constants from '~/styles/themes/base/constants';

export default (theme) => css`
	font-family: ${theme.fonts['webtext-body']};

	.webtext-pull-quote {
		width: 40%;
		float: right;
		padding-bottom: 30px;
		margin: 0 0 30px 30px;
		border-bottom: 2px solid rgba(31, 31, 31, 0.11);
		font-size: ${theme.webtextPullQuote.body.fontSize};
		font-family: ${theme.webtextPullQuote.body.fontFamily};
		font-style: italic;
		line-height: 1.3;

		@media (max-width: ${breakpoints.large}) {
			margin-right: 0;
		}

		@media (max-width: ${breakpoints.small}) {
			width: 100%;
			float: none;
			padding-top: 30px;
			margin-left: 0;
			border-top: 2px solid rgba(31, 31, 31, 0.11);
			font-size: ${theme.webtextPullQuote.body.mobileFontSize};
		}

		&.tech-tip {
			border: thin solid ${theme.colors.primary};
			padding-left: 0.75rem;
			padding-right: 0.75rem;
			margin-bottom: 0;
			margin-top: 30px;

			.webtext-pullquote-heading {
				font-family: ${constants.fonts.oswaldRegular};
				text-align: center;
				font-size: 30px;
				align-items: center;
				justify-content: flex-start;
				background-color: ${theme.colors.primary};
				color: ${constants.colors['pure-white']};
				display: inline-flex;
				padding: 2px 16px 2px 8px;
				vertical-align: top;
				white-space: nowrap;
				margin-top: -20px;
				margin-bottom: 20px;
			}

			.icon-tech-tip {
				flex-shrink: 0;
				display: inline-block;
				margin-right: 10px;
				margin-top: 4px;
				color: ${constants.colors['pure-white']};
				fill: ${constants.colors['pure-white']};
				width: 32px;
				height: 32px;
				background-size: 32px;
				background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22white%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2024%2024%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20style%3D%22color%3A%20rgb%2851%2C%2051%2C%2051%29%3B%22%3E%3Cpath%20d%3D%22M21%202H3c-1.1%200-2%20.9-2%202v12c0%201.1.9%202%202%202h7l-2%203v1h8v-1l-2-3h7c1.1%200%202-.9%202-2V4c0-1.1-.9-2-2-2zm0%2012H3V4h18v10z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
				background-repeat: no-repeat;
			}
		}
	}

	span.webtext-pullquote-heading {
		display: block;
		color: ${theme.webtextPullQuote.heading.color};
		text-transform: uppercase;
		margin-bottom: 10px;
		font-size: ${theme.webtextPullQuote.heading.fontSize};
		font-family: ${theme.webtextPullQuote.heading.fontFamily};
		font-weight: ${theme.webtextPullQuote.heading.fontWeight};
		font-style: normal;
	}
`;
