import React, { ReactElement } from 'react';

import { css } from '@emotion/react';

interface Props {
	legendText: ReactElement[];
	inputFields: ReactElement;
	isOutline?: boolean;
}

/**
 * https://ibb.co/9cnD3HT
 * The old outlines are not able to draw marker in correct place when fieldset is used
 * so we will return elements without fieldset
 * This should be save since old outlines will be replaced with new ones
 */
const QuestionFieldset: React.FC<Props> = ({ legendText, inputFields, isOutline }) =>
	isOutline ? (
		<>
			{legendText}
			{inputFields}
		</>
	) : (
		<fieldset css={fieldsetStyles}>
			<legend>{legendText}</legend>
			{inputFields}
		</fieldset>
	);

const fieldsetStyles = css`
	border: 0;
	margin: 0;
	padding: 0;
`;

export default QuestionFieldset;
