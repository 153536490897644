import React, { useEffect, useMemo, useState } from 'react';

import { useEventListener, useWindowSize } from 'usehooks-ts';
import { shallow } from 'zustand/shallow';

import ValidationMessage from '../ValidationMessage';
import { IDictionary } from '../WritingTemplate/types';
import AriaArea from './Components/AriaArea';
import ContextMenu from './Components/ContextMenu';
import ErrorMessage from './Components/ErrorMessage';
import Grid from './Components/Grid';
import InputArea from './Components/InputArea';
import MouseSelection from './Components/MouseSelection';
import Selection from './Components/Selection';
import { ElementsProvider } from './context/ElementsContext';
import {
	getRangeAddresses,
	getFormattingForCell,
	getIsRange,
	getOverflowForCell,
	getStylesForCell,
	createHeightStyle,
	getGlobalWidth,
	getGlobalHeight
} from './helpers';
import { getCellAddress } from './helpers/address';
import { getActiveAreaId, separateInputAreaId } from './helpers/constants';
import {
	Cell,
	CellOnBlurErrors,
	CellsRefs,
	CellType,
	CellValues,
	OnBlurErrors,
	Row,
	Sheet
} from './helpers/types';
import withStoreProvider from './hoc/withStoreProvider';
import { useCallbackOnShowInput } from './hooks/useCallbackOnShowInput';
import useOuterClick from './hooks/useClickOutside';
import { useSpreadsheetValidation } from './hooks/useSpreadsheetValidation';
import { useUpdateCellValuesOnInputs } from './hooks/useUpdateCellValuesOnInputs';
import { useValidationRecheck } from './hooks/useValidationRecheck';
import { useSpreadsheetSelector, useSpreadsheetStore } from './store/provider';
import { selectIsAnyRuntimeError } from './store/selectors';
import styles from './styles';

interface Props {
	dest?: string;
	sheet: Sheet;
	inputs: IDictionary;
	readOnly?: boolean;
	chartSelection?: string[];
	/**
	 * Display only saved cells' values.
	 * Don't dynamically resolve `source` imports.
	 * Used for `ViewMode` where ST should be cemented after submission
	 */
	isolated?: boolean;
	updateErrorStatus?: (isAnyError: boolean) => void;
	updateSelection?: (selectedCell: string, selection: string[]) => void;
	performOuterClickValidation?: () => void;
	onChange?: (values: CellValues) => void;
	onUserUpdate?: () => void;
}

const Spreadsheet: React.FC<Props> = ({
	sheet,
	dest,
	inputs,
	readOnly,
	isolated,
	onChange,
	updateErrorStatus,
	chartSelection,
	updateSelection
}) => {
	const {
		hideAddressHeaders,
		leaveSelectionVisible,
		'on-blur-errors': schemaOnBlurErrors,
		validations
	} = sheet;
	const isCellsExist = sheet?.cells?.length > 0;

	/**
	 * All tries to push this state to store was unsuccessful
	 * Seems refs in state kills devtools and app
	 */
	const [cellsRefs, setCellsRefs] = useState<CellsRefs>({});

	const {
		origin,
		selectedCell,
		cellsRowHeight,
		selectedIndexes,
		hasErrors,
		setEditing,
		setFocus,
		setEnterWithString,
		updateTouchMode,
		isTouchScreen
	} = useSpreadsheetSelector(
		(state) => ({
			origin: state.origin,
			selectedCell: state.selectedCell,
			cellsRowHeight: state.cellsRowHeight,
			selectedIndexes: state.selectedIndexes,
			hasErrors: selectIsAnyRuntimeError(state),
			setEditing: state.setEditingCell,
			setFocus: state.setFocus,
			setEnterWithString: state.setEnterWithString,
			updateTouchMode: state.updateTouchMode,
			isTouchScreen: state.isTouchScreen
		}),
		shallow
	);

	const spreadsheetWrapperRef = useOuterClick(() => {
		setFocus(false);
		setEditing(null);
		setEnterWithString(null);
	}, [separateInputAreaId, getActiveAreaId(dest)]);

	useEffect(() => {
		if (!isTouchScreen) return;

		const { current: spreadsheetWrapper } = spreadsheetWrapperRef;
		const onTouchEnd = () => updateTouchMode('scroll');

		spreadsheetWrapper.addEventListener('touchend', onTouchEnd);
		return () => {
			spreadsheetWrapper.removeEventListener('touchend', onTouchEnd);
		};
	}, [spreadsheetWrapperRef, isTouchScreen, updateTouchMode]);

	const { width: windowSize } = useWindowSize();

	// globalFormatting is used as the `useEffect` dep
	const globalFormatting = useMemo(() => sheet?.formatting || {}, [sheet?.formatting]);

	/**
	 * Mapper with the on-blur errors rules for each cell: { "B1" : { ... }, "B2": { ... } }
	 * Ranges are converted into individual cells and merged with the mapper object
	 */
	const blurErrorsRules = useMemo<OnBlurErrors>(() => {
		if (!schemaOnBlurErrors) return {};

		return Object.entries<CellOnBlurErrors>(schemaOnBlurErrors).reduce<OnBlurErrors>(
			(rules, [address, rule]) => {
				const isRange = getIsRange(address);
				if (!isRange) {
					return { ...rules, [address]: rule };
				}

				const { value: cellsAddresses = [] } = getRangeAddresses(address);
				const rangeCellsRules = cellsAddresses.reduce<OnBlurErrors>(
					(rangeRules, rangeAddress) => ({ ...rangeRules, [rangeAddress]: rule }),
					{}
				);

				return {
					...rules,
					...rangeCellsRules
				};
			},
			{}
		);
	}, [schemaOnBlurErrors]);

	const [validationMessage, setValidationMessage] = useState<string>(null);

	useUpdateCellValuesOnInputs({ inputs, sheet });

	useSpreadsheetValidation({
		isolated,
		validations,
		globalFormatting,
		chartSelection,
		setValidationMessage
	});

	useValidationRecheck();
	useCallbackOnShowInput();

	useEffect(() => {
		updateSelection?.(selectedCell, selectedIndexes);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCell, selectedIndexes]);

	const sheetCells = useMemo<Record<string, Cell>>(() => {
		if (!isCellsExist) return {};

		return sheet.cells.reduce((rowsCellsAcc, row, rowIndex) => {
			const columnsCells = row.reduce((columnsCellsAcc, cell, columnIndex) => {
				const address = getCellAddress(rowIndex, columnIndex, origin);

				const { placeholder, 'placeholder-announcement': placeholderAnnouncement } = cell;

				const cellStyleString = getStylesForCell(address, sheet.styling);
				const cellFormatting = getFormattingForCell(address, sheet.formatting);

				const isOverflow = getOverflowForCell(address, sheet.styling);

				const sheetCell = {
					type: CellType.Common,
					placeholder,
					placeholderAnnouncement,
					address,
					importedStyle: cellStyleString,
					formatting: cellFormatting,
					isOverflow
				};

				return {
					...columnsCellsAcc,
					[address]: sheetCell
				};
			}, {});

			return { ...rowsCellsAcc, ...columnsCells };
		}, {});
	}, [isCellsExist, sheet.cells, sheet.formatting, sheet.styling, origin]);

	const sheetRows = useMemo((): Row[] => {
		if (!isCellsExist) return [];

		return sheet.cells.map((row, rowIndex) => {
			return {
				rowId: rowIndex + 1,
				cells: row.map((cell, columnIndex) => {
					const cellAddress = getCellAddress(rowIndex, columnIndex, origin);
					return sheetCells[cellAddress];
				})
			};
		});
	}, [isCellsExist, origin, sheet.cells, sheetCells]);

	useEffect(() => {
		if (isCellsExist && !readOnly) {
			updateErrorStatus?.(hasErrors);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasErrors, isCellsExist, readOnly]);

	const withHeader = isCellsExist && !hideAddressHeaders;

	const [deviceOrientationAlpha, setOrientation] = useState<number>(null);
	useEventListener('deviceorientation', (event) => setOrientation(event.alpha));

	const spreadsheetStyles = useMemo(() => {
		const styleWidthString = getGlobalWidth(
			sheet,
			spreadsheetWrapperRef.current?.parentElement,
			isTouchScreen
		);
		const styleHeightString = getGlobalHeight(sheet);
		const heightControllerStyles = createHeightStyle(cellsRowHeight);
		return `${styleWidthString} ${styleHeightString} ${heightControllerStyles}`;
		/**
		 * Window size need here as deps, because based on it, we will update styles for whole spreadsheet
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sheet, spreadsheetWrapperRef, cellsRowHeight, deviceOrientationAlpha, isTouchScreen]);

	const spreadsheetStore = useSpreadsheetStore();

	useEffect(() => {
		if (onChange) {
			return spreadsheetStore.subscribe(
				(state) => state.cellValues,
				(onChangeState) => onChange(onChangeState),
				{ fireImmediately: true, equalityFn: shallow }
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spreadsheetStore]);

	return (
		<div ref={spreadsheetWrapperRef} css={styles}>
			<ValidationMessage message={validationMessage} />
			<AriaArea globalFormatting={globalFormatting} sheet={sheet} sheetCells={sheetCells} />
			<MouseSelection dest={dest} aria-hidden={sheetRows.length === 0}>
				{(resetCachedSelection) => (
					<ElementsProvider
						cellsRefs={cellsRefs}
						windowSize={windowSize}
						formatting={globalFormatting}
						blurErrorsRules={blurErrorsRules}
						setReferences={setCellsRefs}
						spreadsheetWrapperRef={spreadsheetWrapperRef}
						resetCachedSelection={resetCachedSelection}>
						<Grid
							withHeader={withHeader}
							rows={sheetRows}
							spreadsheetStyles={spreadsheetStyles}
							hideAddressHeaders={hideAddressHeaders}
						/>
						<Selection
							leaveSelectionVisible={leaveSelectionVisible}
							hideAddressHeaders={hideAddressHeaders}
							cellsRefs={cellsRefs}
							withHeader={withHeader}
						/>
					</ElementsProvider>
				)}
			</MouseSelection>
			<ContextMenu
				cellsRefs={cellsRefs}
				globalFormatting={globalFormatting}
				spreadsheetWrapperRef={spreadsheetWrapperRef}
			/>
			<ErrorMessage cellsRefs={cellsRefs} spreadsheetWrapperRef={spreadsheetWrapperRef} />
			{isTouchScreen && (
				<InputArea
					formatting={globalFormatting}
					cellsRefs={cellsRefs}
					leftParentIndent={spreadsheetWrapperRef.current?.getBoundingClientRect()?.left || 0}
				/>
			)}
		</div>
	);
};

export default withStoreProvider(Spreadsheet);
