import React from 'react';

import { css } from '@emotion/react';

import { getThemeItem, Theme, breakpoints, ThemeName } from '~/styles/themes';

interface Props {
	title?: string;
	body?: string;
	visible?: boolean;
}

export const Footer: React.FC<Props> = ({ title, body, visible = true }) => (
	<>
		{visible && (
			<div css={styles.self}>
				{title && <div className="title">{title}</div>}
				{body && <div className="body">{body}</div>}
			</div>
		)}
	</>
);

const styles = {
	self: (theme: Theme) => css`
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		margin-top: 2em;
		padding-top: 1em;

		border-top: 2px solid;

		text-align: center;
		font-size: 14px;
		font-style: italic;
		color: ${getThemeItem(theme.colors.text, theme)};

		@media (max-width: ${breakpoints.small}) {
			margin-top: 1em;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			border-top: 1px solid #cccccc;
			color: ${theme.colors.black};
		`}
	`
};
