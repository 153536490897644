import React from 'react';

import { SelectionEvent } from '@viselect/react/lib/viselect.esm.es5';
import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';

import { getCellAddress } from '~/components/WritingTemplate/Spreadsheet/helpers/address';

import { SpreadsheetState } from '../store/store';
import { headerColumnWidth, headerRowHeight, regex } from './constants';
import { CellsRefs, Dir, KeyCodes, Result, SelectionState, DragHandlePosition } from './types';

export const getRightIndex = (cellIndexes: RegExpExecArray[]): string[] =>
	cellIndexes.reduce((acc, [, horizontal, vertical]) => {
		if (acc.length === 0) return [horizontal, vertical];

		const [accHorizontal] = acc;

		if (horizontal.charCodeAt(0) > accHorizontal.charCodeAt(0)) {
			return [horizontal, vertical];
		}

		return acc;
	}, []);

export const getLeftIndex = (cellIndexes: RegExpExecArray[]): string[] =>
	cellIndexes.reduce((acc, [, horizontal, vertical]) => {
		if (acc.length === 0) return [horizontal, vertical];

		const [accHorizontal] = acc;

		if (horizontal.charCodeAt(0) < accHorizontal.charCodeAt(0)) {
			return [horizontal, vertical];
		}

		return acc;
	}, []);

export const getTopIndex = (cellIndexes: RegExpExecArray[]): string[] =>
	cellIndexes.reduce((acc, [, horizontal, vertical]) => {
		if (acc.length === 0) return [horizontal, vertical];

		const [, accVertical] = acc;

		if (+vertical < +accVertical) {
			return [horizontal, vertical];
		}

		return acc;
	}, []);

export const getBottomIndex = (cellIndexes: RegExpExecArray[]): string[] =>
	cellIndexes.reduce((acc, [, horizontal, vertical]) => {
		if (acc.length === 0) return [horizontal, vertical];

		const [, accVertical] = acc;

		if (+vertical > +accVertical) {
			return [horizontal, vertical];
		}

		return acc;
	}, []);

export const sideExpand = (
	dir: Dir,
	cell: string[],
	cellIndexes: RegExpExecArray[],
	elements: CellsRefs,
	indexes: string[]
): { result: Result; key?: string; keys?: string[]; message?: string } => {
	const [horizontal, vertical] = cell;
	const charCode = horizontal.charCodeAt(0);
	const updateHorizontal =
		dir === Dir.Left ? String.fromCharCode(charCode - 1) : String.fromCharCode(charCode + 1);
	const key = `${updateHorizontal}${vertical}`;
	const keys = uniq(cellIndexes.map(([, , vertical]) => `${updateHorizontal}${vertical}`));

	if (Object.keys(elements).includes(key)) {
		return {
			result: Result.Accept,
			keys: indexes.concat(keys).sort()
		};
	}

	return {
		result: Result.Decline,
		key: updateHorizontal,
		message: ''
	};
};

export const upDownExpand = (
	dir: Dir,
	cell: string[],
	cellIndexes: RegExpExecArray[],
	elements: CellsRefs,
	indexes: string[]
): { result: Result; key?: string; keys?: string[]; message?: string } => {
	const [horizontal, vertical] = cell;
	const updateVertical = dir === Dir.Up ? +vertical - 1 : +vertical + 1;
	const key = `${horizontal}${updateVertical}`;
	const keys = uniq(cellIndexes.map(([, horizontal]) => `${horizontal}${updateVertical}`));

	if (Object.keys(elements).includes(key)) {
		return {
			result: Result.Accept,
			keys: indexes.concat(keys).sort()
		};
	}

	return {
		result: Result.Decline,
		key: `${updateVertical}`,
		message: ''
	};
};

export const sideReduce = (
	cell: string[],
	indexes: string[]
): { result: Result; keys: string[] } => {
	const [horizontal] = cell;
	const keys = indexes.filter((e) => !e.startsWith(horizontal));
	return { result: Result.Accept, keys };
};

export const upDownReduce = (
	cell: string[],
	indexes: string[]
): { result: Result; keys: string[] } => {
	const [, vertical] = cell;
	const keys = indexes.filter((e) => !e.endsWith(vertical));
	return { result: Result.Accept, keys };
};

export const getChildSizes = (
	cellAddress: string,
	elements: { [key: string]: React.MutableRefObject<HTMLTableCellElement> }
): { height: number; width: number } => {
	if (!elements[cellAddress] || !elements[cellAddress]?.current) {
		return { width: 0, height: 0 };
	}

	const element = elements[cellAddress].current;
	const child = elements[cellAddress].current.childNodes[0] as HTMLElement;

	const nodeHeight = element?.offsetHeight;
	/**
	 * For cases with overflow;
	 * In most cases offsetWidth of element (td) is match visual expectations
	 * However when we have overflow element (td) width remains static, but child (div) width become wider than it.
	 */
	const nodeWidth = Math.max(element?.offsetWidth || 0, child?.offsetWidth || 0);

	return {
		height: nodeHeight,
		width: nodeWidth
	};
};

export const getWidthValues = (
	length: number,
	elements: { [key: string]: React.MutableRefObject<HTMLTableCellElement> },
	horizontal = 0,
	vertical = 0
): number => {
	return Array.from({ length })
		.fill(0)
		.map((_, idx) => `${String.fromCharCode(idx + horizontal)}${1 + vertical}`)
		.map((cell) => {
			const { width } = getChildSizes(cell, elements);
			return width;
		})
		.reduce((acc, el) => acc + el, 0);
};

export const getHeightValues = (
	length: number,
	elements: { [key: string]: React.MutableRefObject<HTMLTableCellElement> },
	horizontal = 0,
	vertical = 0
): number => {
	return Array.from({ length })
		.fill(0)
		.map((_, idx) => `${String.fromCharCode(horizontal)}${idx + vertical}`)
		.map((cell) => {
			const { height } = getChildSizes(cell, elements);

			return height;
		})
		.reduce((acc, el) => acc + el, 0);
};

export const getStartPosition = (
	cellIndex: string,
	elements: { [key: string]: React.MutableRefObject<HTMLTableCellElement> },
	withHeader: boolean
): { top: number; left: number } => {
	const border = 1;

	const cellHandleRegex = regex.cellHandle;
	cellHandleRegex.lastIndex = 0;

	const [, horizontal, vertical] = cellHandleRegex.exec(cellIndex);
	const { horizontal: startHorizontal, vertical: startVertical } = getStartCellFromObject(elements);

	const horizontalLength = +horizontal.charCodeAt(0) - startHorizontal;
	const verticalLength = +vertical - +startVertical;

	const leftShift = getWidthValues(horizontalLength, elements, startHorizontal, +vertical - 1);

	const topShift = getHeightValues(
		verticalLength,
		elements,
		+horizontal.charCodeAt(0),
		+startVertical
	);

	const top = topShift + +withHeader * headerRowHeight + border;
	const left = leftShift + +withHeader * headerColumnWidth + border;

	return { top, left };
};

export const getStartCellFromObject = (elements: {
	[key: string]: React.MutableRefObject<HTMLTableCellElement>;
}): { vertical: number; horizontal: number } => {
	return getStartCell(Object.keys(elements));
};

interface CellCoordinates {
	horizontal: number;
	vertical: number;
}

// TODO: Find places where the coordinates are resolved by hand
/**
 * Maps the human-readable cell address to the numeric codes { horizontal, vertical }.
 * Horizontal coordinates are in range [65, 90], from A to Z in ASCII code
 * Vertical coordinates are in range (1, ∞), because rows start at 1
 *
 * @param address - human-readable cell address, e.g. A1
 */
export const getCellCoordinates = (address: string): CellCoordinates => {
	const cellAddressRegex = regex.cellHandle;
	cellAddressRegex.lastIndex = 0;
	const [, horizontal, vertical] = cellAddressRegex.exec(address);
	return { horizontal: horizontal.charCodeAt(0), vertical: +vertical };
};

export const getStartCell = (selectedAddresses: string[]): CellCoordinates => {
	const descendingCoordinates = getSortedAddressesCoordinates(selectedAddresses, 'desc');
	return first(descendingCoordinates);
};

export const getEndCell = (selectedAddresses: string[]): CellCoordinates => {
	const ascendingCoordinates = getSortedAddressesCoordinates(selectedAddresses, 'asc');
	return first(ascendingCoordinates);
};

const getSortedAddressesCoordinates = (
	addresses: string[],
	order: 'asc' | 'desc'
): CellCoordinates[] =>
	addresses.map(getCellCoordinates).sort((coordinatesA, coordinatesB) => {
		const { horizontal: aHorizontal, vertical: aVertical } = coordinatesA;
		const { horizontal: bHorizontal, vertical: bVertical } = coordinatesB;

		if (isEqual(coordinatesA, coordinatesB)) return 0;

		const isABeforeB = aHorizontal < bHorizontal || aVertical < bVertical;
		switch (order) {
			case 'asc':
				return isABeforeB ? 1 : -1;
			case 'desc':
				return isABeforeB ? -1 : 1;
		}
	});

export const getIsCellToTheLeft = (cellAddressA: string, cellAddressB: string): boolean => {
	const cellCoordinatesA = getCellCoordinates(cellAddressA);
	const cellCoordinatesB = getCellCoordinates(cellAddressB);
	return cellCoordinatesB.horizontal < cellCoordinatesA.horizontal;
};

export const getIsCellToTheTop = (cellAddressA: string, cellAddressB: string): boolean => {
	const cellCoordinatesA = getCellCoordinates(cellAddressA);
	const cellCoordinatesB = getCellCoordinates(cellAddressB);
	return cellCoordinatesB.vertical < cellCoordinatesA.vertical;
};

export const getIsCellToTheRight = (cellAddressA: string, cellAddressB: string): boolean => {
	const cellCoordinatesA = getCellCoordinates(cellAddressA);
	const cellCoordinatesB = getCellCoordinates(cellAddressB);

	return cellCoordinatesB.horizontal > cellCoordinatesA.horizontal;
};

export const getIsCellToTheBottom = (cellAddressA: string, cellAddressB: string): boolean => {
	const cellCoordinatesA = getCellCoordinates(cellAddressA);
	const cellCoordinatesB = getCellCoordinates(cellAddressB);

	return cellCoordinatesB.vertical > cellCoordinatesA.vertical;
};

export const getLeftTopCellAddress = (addresses: string[]): string => {
	if (!addresses.length) return null;

	const topLeftCoordinates = addresses
		.map(getCellCoordinates)
		.reduce(
			(coordinates, { horizontal, vertical }) =>
				!coordinates.horizontal ||
				coordinates.horizontal > horizontal ||
				(coordinates.horizontal === horizontal && coordinates.vertical > vertical)
					? { horizontal, vertical }
					: coordinates,
			{ horizontal: null, vertical: null }
		);
	return String.fromCharCode(topLeftCoordinates.horizontal) + topLeftCoordinates.vertical;
};

export const getBottomRightCellAddress = (addresses: string[]): string => {
	if (!addresses.length) return null;

	const bottomRightCoordinates = addresses
		.map(getCellCoordinates)
		.reduce(
			(coordinates, { horizontal, vertical }) =>
				!coordinates.horizontal ||
				coordinates.horizontal < horizontal ||
				(coordinates.horizontal === horizontal && coordinates.vertical < vertical)
					? { horizontal, vertical }
					: coordinates,
			{ horizontal: null, vertical: null }
		);

	return String.fromCharCode(bottomRightCoordinates.horizontal) + bottomRightCoordinates.vertical;
};

export const normalizeRange = (selectedRange: string[]): string[] => {
	const rangeIndexes = selectedRange.map((element) => {
		const re = regex.cellHandle;
		re.lastIndex = 0;
		return re.exec(element);
	});

	const maxHorizontal = getRightIndex(rangeIndexes);
	const minHorizontal = getLeftIndex(rangeIndexes);
	const minVertical = getTopIndex(rangeIndexes);
	const maxVertical = getBottomIndex(rangeIndexes);

	if (
		!isEmpty(maxHorizontal) &&
		!isEmpty(minHorizontal) &&
		!isEmpty(minVertical) &&
		!isEmpty(maxVertical)
	) {
		const horizontalArray = Array.from({
			length: maxHorizontal[0].charCodeAt(0) - minHorizontal[0].charCodeAt(0) + 1
		})
			.fill(0)
			.map((_, idx) => String.fromCharCode(idx + minHorizontal[0].charCodeAt(0)));

		const verticalArray = Array.from({ length: +maxVertical[1] - +minVertical[1] + 1 })
			.fill(0)
			.map((_, idx) => idx + +minVertical[1]);

		const indexesArray = horizontalArray.reduce((acc, e) => {
			return [...acc, ...verticalArray.map((val) => `${e}${val}`)];
		}, []);

		if (!isEqual(selectedRange.sort(), indexesArray.sort())) {
			return indexesArray;
		}
	}

	return selectedRange;
};

export const expandSelection = (
	dir: Dir,
	indexes: string[],
	selectedCell: string,
	elements: CellsRefs
): { result: Result; key?: string; message?: string; keys?: string[] } => {
	if (indexes.length <= 1) {
		const re = regex.cellHandle;
		re.lastIndex = 0;
		const ind = [selectedCell];
		const [, horizontal, vertical] = re.exec(selectedCell);
		const charCode = horizontal.charCodeAt(0);

		const returnOnExist = (key: string) => {
			if (Object.keys(elements).includes(key)) {
				return {
					result: Result.Accept,
					keys: [...ind, key],
					message: ''
				};
			}

			return {
				result: Result.Decline,
				key,
				message: ''
			};
		};

		if (dir === Dir.Left) {
			const updateHorizontal = String.fromCharCode(charCode - 1);
			const key = `${updateHorizontal}${vertical}`;

			return returnOnExist(key);
		} else if (dir === Dir.Right) {
			const updateHorizontal = String.fromCharCode(charCode + 1);
			const key = `${updateHorizontal}${vertical}`;

			return returnOnExist(key);
		} else if (dir === Dir.Down) {
			const updateVertical = +vertical + 1;
			const key = `${horizontal}${updateVertical}`;

			return returnOnExist(key);
		} else if (dir === Dir.Up) {
			const updateVertical = +vertical - 1;
			const key = `${horizontal}${updateVertical}`;

			return returnOnExist(key);
		}

		return {
			result: Result.Decline,
			key: '',
			message: 'Unknown range, please try again'
		};
	} else {
		const { cellHandle: re } = regex;

		const cellIndexes = indexes.map((e) => {
			re.lastIndex = 0;
			return re.exec(e);
		});

		if (dir === Dir.Left) {
			const isSelectedOnLeft = () => {
				re.lastIndex = 0;
				const [, selectedHor] = re.exec(selectedCell);
				const [horizontal] = getRightIndex(cellIndexes);

				return selectedHor < horizontal;
			};

			if (isSelectedOnLeft()) {
				return sideReduce(getRightIndex(cellIndexes), indexes);
			} else {
				return sideExpand(dir, getLeftIndex(cellIndexes), cellIndexes, elements, indexes);
			}
		} else if (dir === Dir.Right) {
			const isSelectionOnRight = () => {
				re.lastIndex = 0;
				const [, selectedHor] = re.exec(selectedCell);
				const [horizontal] = getLeftIndex(cellIndexes);

				return selectedHor > horizontal;
			};

			if (isSelectionOnRight()) {
				return sideReduce(getLeftIndex(cellIndexes), indexes);
			} else {
				return sideExpand(dir, getRightIndex(cellIndexes), cellIndexes, elements, indexes);
			}
		} else if (dir === Dir.Down) {
			const isSelectionOnBottom = () => {
				re.lastIndex = 0;
				const [, , selectedVer] = re.exec(selectedCell);
				const [, vertical] = getTopIndex(cellIndexes);

				return +selectedVer > +vertical;
			};

			if (isSelectionOnBottom()) {
				return upDownReduce(getTopIndex(cellIndexes), indexes);
			} else {
				return upDownExpand(dir, getBottomIndex(cellIndexes), cellIndexes, elements, indexes);
			}
		} else if (dir === Dir.Up) {
			const isSelectionOnTop = () => {
				re.lastIndex = 0;
				const [, , selectedVer] = re.exec(selectedCell);
				const [, vertical] = getBottomIndex(cellIndexes);

				return +selectedVer < +vertical;
			};

			if (isSelectionOnTop()) {
				return upDownReduce(getBottomIndex(cellIndexes), indexes);
			} else {
				return upDownExpand(dir, getTopIndex(cellIndexes), cellIndexes, elements, indexes);
			}
		}
	}

	return {
		result: Result.Decline,
		message: 'Undefined selection',
		key: null
	};
};

export const shiftPosition = (
	dir: Dir,
	position: string,
	elements: CellsRefs
): {
	result: Result;
	key: string;
	message?: string;
} => {
	const re = regex.cellHandle;
	re.lastIndex = 0;
	const [, horizontal, vertical] = re.exec(position);

	const moveVertically = (updateVertical: number) => {
		const key = `${horizontal}${updateVertical}`;

		if (Object.keys(elements).includes(key)) {
			return {
				result: Result.Accept,
				key
			};
		}

		return {
			result: Result.Decline,
			message: 'No more cells',
			key
		};
	};

	const moveHorizontally = (updateHorizontal: string) => {
		const key = `${updateHorizontal}${vertical}`;

		if (Object.keys(elements).includes(key)) {
			return {
				result: Result.Accept,
				key
			};
		}

		return {
			result: Result.Decline,
			message: 'No more cells',
			key
		};
	};

	if (dir === Dir.Down && vertical) {
		const updateVertical = +vertical + 1;
		return moveVertically(updateVertical);
	} else if (dir === Dir.Up && vertical) {
		const updateVertical = +vertical - 1;
		return moveVertically(updateVertical);
	} else if (dir === Dir.Left && horizontal) {
		const charCode = horizontal.charCodeAt(0);
		const updateHorizontal = String.fromCharCode(charCode - 1);
		return moveHorizontally(updateHorizontal);
	} else if (dir === Dir.Right && horizontal) {
		const charCode = horizontal.charCodeAt(0);
		const updateHorizontal = String.fromCharCode(charCode + 1);
		return moveHorizontally(updateHorizontal);
	}

	return {
		result: Result.Decline,
		message: 'Undefined direction',
		key: null
	};
};

export const shiftCursorInsideSelection = (props: {
	dir: Dir;
	selectedCells: string[];
	selectedCell: string;
	elements: CellsRefs;
}): {
	result: Result;
	key: string;
	message?: string;
} => {
	const { dir, selectedCells, selectedCell, elements } = props;

	if (selectedCells.length <= 1) {
		return shiftPosition(dir, selectedCell, elements);
	}

	const selectedCellCoords = getCellCoordinates(selectedCell);
	const topLeftMostCell = getLeftTopCellAddress(selectedCells);
	const topLeftMostCellCoords = getCellCoordinates(topLeftMostCell);

	const nextBottomCellAddress = getCellAddress(
		selectedCellCoords.horizontal,
		selectedCellCoords.vertical + 1
	);
	let nextPossibleCellExists = selectedCells.includes(nextBottomCellAddress);
	if (nextPossibleCellExists)
		return {
			result: Result.Accept,
			key: nextBottomCellAddress
		};

	const nextRightCellAddress = getCellAddress(
		selectedCellCoords.horizontal + 1,
		topLeftMostCellCoords.vertical
	);
	nextPossibleCellExists = selectedCells.includes(nextRightCellAddress);
	if (nextPossibleCellExists)
		return {
			result: Result.Accept,
			key: nextRightCellAddress
		};

	return {
		result: Result.Accept,
		key: topLeftMostCell
	};
};

export const formulaSelections = (
	e: React.KeyboardEvent<HTMLTextAreaElement>,
	formulaCell: string,
	formulaRange: string[],
	elements: CellsRefs
): string[] | string => {
	const onMove = (dir: Dir) => {
		const increased = shiftPosition(dir, formulaCell, elements);
		const { result, key } = increased;

		if (result === Result.Accept && key) {
			return [key];
		} else {
			return 'No more cells';
		}
	};

	const onSelect = (dir: Dir) => {
		const expanded = expandSelection(dir, formulaRange, formulaCell, elements);

		const { result, keys } = expanded;

		if (result === Result.Accept && keys) {
			return keys;
		} else {
			return 'No more cells';
		}
	};

	if (!e.shiftKey) {
		if (e.key === KeyCodes.ArrowDown) {
			return onMove(Dir.Down);
		} else if (e.key === KeyCodes.ArrowUp) {
			return onMove(Dir.Up);
		} else if (e.key === KeyCodes.ArrowLeft) {
			return onMove(Dir.Left);
		} else if (e.key === KeyCodes.ArrowRight) {
			return onMove(Dir.Right);
		}
	} else if (e.shiftKey) {
		if (e.key === KeyCodes.ArrowLeft) {
			return onSelect(Dir.Left);
		} else if (e.key === KeyCodes.ArrowRight) {
			return onSelect(Dir.Right);
		} else if (e.key === KeyCodes.ArrowUp) {
			return onSelect(Dir.Up);
		} else if (e.key === KeyCodes.ArrowDown) {
			return onSelect(Dir.Down);
		}
	}
};

const getSelectionOptions = (
	activeHandle: DragHandlePosition,
	anchorCellAddress: string,
	rightMostCell: string,
	leftMostCell: string
): {
	activeHandle: DragHandlePosition;
	leftMostTopMostCellAddress: string;
	rightMostBottomMostCellAddress: string;
} => {
	/**
	 * isReverseActiveHandle is trying to define if selection crossed the line of the `anchor cell`
	 * Anchor cell is a cell that is opposite to the selection start cell and once we move to the opposite side of this selection
	 * we should revert the activeHandle and selection left and right edge addresses
	 * We need this for correct calculation of the further selection change.
	 */

	if (activeHandle === 'bottom') {
		const isReverseActiveHandle =
			(getIsCellToTheRight(anchorCellAddress, rightMostCell) &&
				!getIsCellToTheTop(anchorCellAddress, rightMostCell)) ||
			(getIsCellToTheBottom(anchorCellAddress, rightMostCell) &&
				!getIsCellToTheLeft(anchorCellAddress, rightMostCell));

		return isReverseActiveHandle
			? {
					activeHandle: 'bottom' as DragHandlePosition,
					rightMostBottomMostCellAddress: rightMostCell,
					leftMostTopMostCellAddress: anchorCellAddress
			  }
			: {
					activeHandle: 'top' as DragHandlePosition,
					leftMostTopMostCellAddress: leftMostCell,
					rightMostBottomMostCellAddress: anchorCellAddress
			  };
	}

	const isReverseActiveHandle =
		(getIsCellToTheLeft(anchorCellAddress, leftMostCell) &&
			!getIsCellToTheBottom(anchorCellAddress, leftMostCell)) ||
		(getIsCellToTheTop(anchorCellAddress, leftMostCell) &&
			!getIsCellToTheRight(anchorCellAddress, leftMostCell));

	return isReverseActiveHandle
		? {
				activeHandle: 'top' as DragHandlePosition,
				leftMostTopMostCellAddress: leftMostCell,
				rightMostBottomMostCellAddress: anchorCellAddress
		  }
		: {
				activeHandle: 'bottom' as DragHandlePosition,
				rightMostBottomMostCellAddress: rightMostCell,
				leftMostTopMostCellAddress: anchorCellAddress
		  };
};

const getIsSelectionColumnOnly = (selection: string[]): boolean =>
	uniqBy(selection.map(getCellCoordinates), 'horizontal').length === 1;

const getIsSelectionRowOnly = (selection: string[]): boolean =>
	uniqBy(selection.map(getCellCoordinates), 'vertical').length === 1;

const resetCurrentSelector = (
	touchEvent: TouchEvent | MouseEvent,
	selectionArea: SelectionEvent['selection']
) => {
	if (!touchEvent) return;

	selectionArea.cancel();
	selectionArea.clearSelection();
	selectionArea.trigger(touchEvent);
};

export const getUpdatedSelectionState = (props: {
	addedCells: string[];
	removedCells: string[];
	selectionState: SelectionState;
	currentEdgeAddress: string;
	oppositeEdgeAddress: string;
	event: SelectionEvent;
}): SelectionState => {
	const {
		event,
		addedCells,
		removedCells,
		selectionState,
		currentEdgeAddress,
		oppositeEdgeAddress
	} = props;

	const { event: touchEvent, selection: selectionArea } = event;

	const {
		leftMostTopMostCellAddress,
		rightMostBottomMostCellAddress,
		activeHandle,
		selection,
		anchorCellAddress
	} = selectionState;

	const getIsCellInsideTheSelectionBox = (cellAddress: string) => {
		return (
			!getIsCellToTheLeft(leftMostTopMostCellAddress, cellAddress) &&
			!getIsCellToTheTop(leftMostTopMostCellAddress, cellAddress) &&
			!getIsCellToTheBottom(rightMostBottomMostCellAddress, cellAddress) &&
			!getIsCellToTheRight(rightMostBottomMostCellAddress, cellAddress)
		);
	};
	const isOnlyOneEdgeInSelection = currentEdgeAddress === oppositeEdgeAddress;

	if (addedCells.includes(anchorCellAddress) && !isOnlyOneEdgeInSelection) {
		resetCurrentSelector(touchEvent, selectionArea);

		/**
		 * Find cells that are selected and outside the box.
		 * In case they present update the selection
		 * otherwise stick selection to anchor cell
		 */
		const cellsOutsideSelectionBox = addedCells.filter(
			(address) => !getIsCellInsideTheSelectionBox(address)
		);
		if (!cellsOutsideSelectionBox.length) {
			return {
				...selectionState,
				leftMostTopMostCellAddress: anchorCellAddress,
				rightMostBottomMostCellAddress: anchorCellAddress,
				selection: new Set([anchorCellAddress])
			};
		}

		return {
			...selectionState,
			...{
				...getSelectionOptions(
					activeHandle,
					anchorCellAddress,
					getBottomRightCellAddress(cellsOutsideSelectionBox),
					getLeftTopCellAddress(cellsOutsideSelectionBox)
				),
				selection: new Set([...cellsOutsideSelectionBox, anchorCellAddress])
			},
			disableResetting: true
		};
	}

	const selectionInsideTheSelectionBox = addedCells.some(getIsCellInsideTheSelectionBox);
	const isRemoveSelection = selectionInsideTheSelectionBox && !isOnlyOneEdgeInSelection;

	if (isRemoveSelection) {
		/**
		 * When added cells includes anchor cell, that means we crossed the horizon and need to restart the selection event
		 */

		if (addedCells.length === 1 && first(addedCells) === currentEdgeAddress) {
			return {
				...selectionState
			};
		}

		const removingSelectionEdge =
			activeHandle === 'top'
				? getBottomRightCellAddress(addedCells)
				: getLeftTopCellAddress(addedCells);
		const removingSelectionEdgeCoordinates = getCellCoordinates(removingSelectionEdge);
		const updatedSelection = [
			...Array.from(selection).filter((address) => {
				const coordinates = getCellCoordinates(address);

				return activeHandle === 'top'
					? !(
							coordinates.horizontal < removingSelectionEdgeCoordinates.horizontal ||
							coordinates.vertical < removingSelectionEdgeCoordinates.vertical
					  )
					: !(
							coordinates.horizontal > removingSelectionEdgeCoordinates.horizontal ||
							coordinates.vertical > removingSelectionEdgeCoordinates.vertical
					  );
			}),
			oppositeEdgeAddress,
			removingSelectionEdge,
			anchorCellAddress
		];

		const isBecomeRowSelection =
			getIsSelectionRowOnly(updatedSelection) && !getIsSelectionRowOnly(Array.from(selection));
		const isBecomeColumnSelection =
			getIsSelectionColumnOnly(updatedSelection) &&
			!getIsSelectionColumnOnly(Array.from(selection));
		const isAllowReset =
			(isBecomeRowSelection || isBecomeColumnSelection) && !selectionState.disableResetting;
		if (isAllowReset) {
			resetCurrentSelector(touchEvent, selectionArea);

			return {
				...selectionState,
				leftMostTopMostCellAddress: getLeftTopCellAddress(updatedSelection),
				rightMostBottomMostCellAddress: getBottomRightCellAddress(updatedSelection),
				selection: new Set(updatedSelection)
			};
		}

		return {
			...selectionState,
			selection: new Set(updatedSelection)
		};
	}

	if (selectionState.isResetSelection && addedCells.length && !isOnlyOneEdgeInSelection) {
		const updatedSelection = [...selection, ...addedCells, anchorCellAddress];
		const isRowSelection = getIsSelectionRowOnly(updatedSelection);
		const isColumnSelection = getIsSelectionColumnOnly(updatedSelection);
		const isAllowReset = (isColumnSelection || isRowSelection) && !selectionState.disableResetting;
		if (isAllowReset) {
			resetCurrentSelector(touchEvent, selectionArea);
			return {
				...selectionState,
				leftMostTopMostCellAddress: getLeftTopCellAddress(updatedSelection),
				rightMostBottomMostCellAddress: getBottomRightCellAddress(updatedSelection),
				selection: new Set(updatedSelection),
				isResetSelection: false
			};
		}

		return {
			...selectionState,
			selection: new Set(updatedSelection)
		};
	}

	const isRestoreRemovedSelection =
		removedCells.some(getIsCellInsideTheSelectionBox) && !isOnlyOneEdgeInSelection;
	if (isRestoreRemovedSelection) {
		const updatedSelection = [...Array.from(selection), ...removedCells];
		const isRowSelection = getIsSelectionRowOnly(updatedSelection);
		const isColumnSelection = getIsSelectionColumnOnly(updatedSelection);
		const isAllowReset = !isRowSelection && !isColumnSelection && !selectionState.disableResetting;

		if (isAllowReset) {
			resetCurrentSelector(touchEvent, selectionArea);
			return {
				...selectionState,
				selection: new Set(updatedSelection),
				isResetSelection: true
			};
		}

		return {
			...selectionState,
			selection: new Set(updatedSelection)
		};
	}

	if (addedCells.length || removedCells.length) {
		if (addedCells.length) {
			const updatedSelection = [
				...Array.from(selectionState.selection),
				...addedCells,
				anchorCellAddress
			];

			const isBecomeColumnSelection =
				!getIsSelectionColumnOnly(updatedSelection) &&
				getIsSelectionColumnOnly(Array.from(selection));
			const isAllowReset =
				isBecomeColumnSelection &&
				!selectionState.disableResetting &&
				!isOnlyOneEdgeInSelection &&
				!addedCells.some((cellAddr) =>
					getIsCellToTheRight(rightMostBottomMostCellAddress, cellAddr)
				);

			if (isAllowReset) {
				resetCurrentSelector(touchEvent, selectionArea);
				return {
					...selectionState,
					leftMostTopMostCellAddress: getLeftTopCellAddress(updatedSelection),
					rightMostBottomMostCellAddress: getBottomRightCellAddress(updatedSelection),
					selection: new Set(updatedSelection)
				};
			}
		}

		const updatedSelection = [
			...Array.from(selection).filter((id) => !removedCells.includes(id)),
			...addedCells,
			oppositeEdgeAddress
		];

		return {
			...selectionState,
			selection: new Set(updatedSelection)
		};
	}

	return selectionState;
};

export const getIsAnyErrorsInSelection = (state: SpreadsheetState): boolean => {
	const { runtimeEditableErrors, selectedCell, selectedIndexes } = state;

	return selectedIndexes.length <= 1
		? runtimeEditableErrors[selectedCell]
		: selectedIndexes.map((address) => runtimeEditableErrors[address]).some(Boolean);
};
