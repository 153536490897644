import type { ReactNode } from 'react';
import React from 'react';

import { supportEmail, supportPhone } from '~/properties/support';

export interface Props {
	message?: string;
	className?: string;
	disabled?: boolean;
	reportError?: (error: Error, errorInfo: React.ErrorInfo) => { uuid: string };
	renderErrorMessage?: (boundaryState: State) => React.ReactNode;
}

export interface State {
	error: Error | null;
	errorInfo?: React.ErrorInfo;
	errorUUID?: string;
}

/**
 * This class is used to show only the errors where the message is explicitly specified by the developer
 * and ignore automatically generated messages
 */
export interface BoundaryErrorOptions {
	// Shows the default message even if the passed error message is not empty
	showDefaultMessage?: boolean;
}

export class BoundaryError extends Error {
	options: BoundaryErrorOptions;

	constructor(message: string, options?: BoundaryErrorOptions) {
		super(message);
		this.options = options;
	}
}

// Much of this code is copied/adapted from `react-error-boundary`:
// https://github.com/bvaughn/react-error-boundary
class GenericErrorBoundary extends React.Component<Props, State> {
	state = {
		error: null,
		errorInfo: null,
		errorUUID: null
	};

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		if (this.props.disabled) return;

		const { reportError } = this.props;

		let errorUUID;
		if (reportError) {
			const result = reportError(error, errorInfo);
			errorUUID = result && result.uuid;
		} else if (window.Rollbar) {
			// if there's a Rollbar on window, use it
			window.Rollbar.error(error);
		}
		// regardless of external reporting, a log message is handy for FullStory
		console.error('Error found in GenericErrorBoundary!', error, errorInfo);

		this.setState({
			error,
			errorInfo,
			errorUUID
		});
	}

	defaultErrorMessage() {
		const message =
			this.state.errorUUID || this.props.message || transformErrorMessage(this.state.error.message);
		return errorMessage(message, this.props.className, this.state.error.options);
	}

	render() {
		return this.state.error
			? this.props.renderErrorMessage
				? this.props.renderErrorMessage(this.state)
				: this.defaultErrorMessage()
			: this.props.children;
	}
}

export default GenericErrorBoundary;

export const RELOAD_MESSAGE =
	'Something went wrong. Refresh the page and try again. If the error persists, contact Soomo Support.';

// Fine tune our message a bit if the error is one of the common ones that we see that we don't
// know how to handle
const transformErrorMessage = (message: string): string => {
	switch (true) {
		case /^Failed to execute 'removeChild' on 'Node'/i.test(message):
			return RELOAD_MESSAGE;
		case /^Lexical RTE has crashed with error/i.test(message):
			return RELOAD_MESSAGE;
		case /^Failed to execute 'insertBefore' on 'Node'/i.test(message):
			return RELOAD_MESSAGE;
		case /^Minified React error/i.test(message):
			return RELOAD_MESSAGE;
		default:
			return message;
	}
};

export const errorMessage = (
	children: ReactNode,
	className: string,
	options: BoundaryErrorOptions = {}
) => {
	const { showDefaultMessage } = options;

	const errorClass = className || 'error';
	const errorMessageElement = (
		<>
			{typeof children === 'string' ? (
				<span dangerouslySetInnerHTML={{ __html: children }} /> // App should be able to provide HTML as the message
			) : (
				children
			)}
			<br />
		</>
	);

	return (
		<p className={errorClass} role="alert">
			{children && !showDefaultMessage
				? errorMessageElement
				: 'An error has occurred. Please try refreshing the page.'}
			<br />
			If the issue persists, contact Soomo Support by using the Soomo Messenger icon in the lower
			right corner of your webtext, calling{' '}
			<a href={`tel:${supportPhone}`} rel="noopener noreferrer">
				{supportPhone}
			</a>
			, or emailing us at{' '}
			<a href={`mailto:${supportEmail}`} target="_blank" rel="noopener noreferrer">
				{supportEmail}
			</a>
		</p>
	);
};

/**
 * For a given component props object of type `P`, if `P` includes a React ref,
 * return the ref's instance type (e.g. `HTMLDivElement`). Otherwise, return `never`.
 *
 * Similar to `React.ElementRef<C>`, but note that `React.ElementRef` accepts the type
 * of a *component* as its type argument, rather than the type of its props.
 */
type RefInstance<P> = 'ref' extends keyof P
	? P['ref'] extends React.Ref<infer InstanceType>
		? InstanceType
		: never
	: never;

export const withErrorBoundary = <P,>(
	Component: React.ComponentType<P>,
	errorBoundaryProps?: Props
): React.ForwardRefExoticComponent<
	React.PropsWithoutRef<P> & React.RefAttributes<RefInstance<P>>
> => {
	const Wrapped = React.forwardRef<RefInstance<P>, P>((props, ref) => {
		return (
			<GenericErrorBoundary {...errorBoundaryProps}>
				<Component {...(props as any)} ref={ref} />
			</GenericErrorBoundary>
		);
	});

	// Format for display in DevTools
	const name = Component.displayName || Component.name || 'Unknown';
	Wrapped.displayName = `withErrorBoundary(${name})`;

	return Wrapped;
};
