import { css } from '@emotion/react';

import { ThemeName } from '~/styles/themes';
import mixins from '~/styles/themes/base/mixins';

export default (theme) => {
	const voiceRecording = theme.voiceRecording;
	return css`
		div:focus {
			outline-color: transparent;
			outline-style: none;
		}

		.voice-recording-element {
			padding: 30px;
			font-size: ${voiceRecording.fontSize};
			font-family: ${voiceRecording.fontFamily};
			background-color: ${theme.webtextQuestion.backgroundColor};
			line-height: 1.6;

			[role='heading'] {
				margin-bottom: 30px;
			}

			p {
				font-size: ${voiceRecording.fontSize};
			}

			p + p {
				margin-top: 30px;
			}

			.recording_error {
				padding-top: 1.2rem;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin: 15px 0;
				color: ${voiceRecording.colors.primary};
				font-family: ${voiceRecording.heading.fontFamily};
				font-weight: ${voiceRecording.heading.fontWeight};
				text-transform: none;
			}

			.controls {
				button {
					${mixins.webtextQuestionButtons(theme)}
				}

				button:focus {
					${mixins.webtextAccessibleFocused(theme)}
					background-color: darken(${voiceRecording.colors.primary}, 10%) !important;
				}
			}

			.element-title {
				${mixins.elementTitle(theme)}
			}

			.voice-phone {
				font-weight: bold;
			}

			.submissionTime {
				padding: 1.2rem 1.2rem 0 1.2rem;
				text-align: right;
			}

			.error {
				color: ${voiceRecording.colors.warning} !important;
				font-weight: bold;
				padding-bottom: 1.2rem;
				text-align: left;
			}

			.controls {
				margin-top: 5px;
				text-align: right;
				padding-top: 2em;

				button {
					align-self: center;
					background: $primary-color;
					border-radius: 2px;
					padding: 0.5em 1em;
					margin-right: 10px;
					color: $white;
					font-family: ${voiceRecording.fontFamily};
					font-size: ${voiceRecording.fontSize};
					outline: 0;

					&:hover {
						cursor: pointer;
						background: darken($primary-color, 5%);
					}

					&[disabled],
					&.snoozed {
						opacity: 0.5;
						cursor: default;

						&:hover {
							cursor: default;
							background: $primary-color;
						}
					}
				}
			}

			.aria-info {
				padding-bottom: 10px;
			}

			hr {
				height: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '1' : '2'}px;
				margin: 30px 0;
				background-color: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '#cccccc' : '#000000'};
				border: none;
			}

			hr + p {
				font-family: ${theme.webtextQuestion.fontFamily} !important;
				font-size: 14px;
				text-align: center;
				margin: 0 10%;
			}
		}
	`;
};
