import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { default as WritingTemplateFromLibs } from '@soomo/lib/components/WritingTemplate/WritingTemplate/index';
import { getElementLabel } from '@soomo/lib/components/WritingTemplate/WritingTemplate/utils';

import interactiveTemplateQuery from 'Requests/InteractiveTemplateQuery';
import { getImageUrl } from 'Components/WebtextBuilder/api';

const WritingTemplate = (props) => {
	const {
		dispatch,
		api,
		writingTemplateData: data,
		user,
		course,
		toc,
		page,
		element,
		hideBuilderSaveProgressButton
	} = props;

	/**
	 * Library WT2 components depend on the TOC, which is embedded from the server and differs from the A2 TOC
	 * Therefore, we need to create a proxied TOC for the libs compatibility.
	 *
	 * In the A2 TOC we're missing `toc.config.referenceStyle`, `toc.userDecisionsLastSavedAt`, and `toc.userDecisions`.
	 * And the first 2 of the properties are not provided from the API request.
	 * Luckily they are not used in the libs code, and we can skip them in the proxied TOC
	 *
	 * TOC example: https://github.com/soomo/soomo-libs/tree/master/src/components/WritingTemplate/staticStoryAssets/project_config
	 * Embedding: https://github.com/soomo/soomo/blob/231e568ffa5bb7b02f76b030229b09f0be89d8fe/app/controllers/webtext/traditional/pages_controller.rb#L54
	 */
	const libraryToc = useMemo(() => {
		const {
			config: { course_decisions: courseDecisions, course_data: courseData, elements: elements }
		} = toc;
		const { decisions: userDecisions } = user;

		return {
			config: {
				courseDecisions,
				courseData,
				elements
			},
			userDecisions
		};
	}, [toc, user]);

	useEffect(() => {
		if (!user || !course || !element) return;

		dispatch(
			interactiveTemplateQuery({
				courseId: course.id,
				userId: user.id,
				interactiveTemplateId: element.id
			})
		);
	}, [dispatch, user, course, element]);

	const wtApi = {
		storeTemplate: async (params: {
			builderId: string;
			body;
			abortController: AbortController;
			version?: number;
			ignoreLocking?: boolean;
		}) => null,
		loadTemplate: async (builderId: string, defaultValues?: any) => {
			const destsForThisElement = 'dests' in data ? data.dests['_draft'] || data.dests : {};
			const destsForThisAndUpstreamElements = { ...(data.sources || {}), ...destsForThisElement };

			const isDraft = data.dests ? '_draft' in data.dests : false;
			const draftTime = isDraft && data.last_saved_at ? data.last_saved_at : null;
			const saveTime = !isDraft && data.last_saved_at ? data.last_saved_at : null;

			const view = !isDraft && Object.keys(destsForThisElement).length ? 'output' : 'edit';

			return {
				dests: isDraft ? {} : destsForThisAndUpstreamElements,
				draftTime,
				saveTime,
				view,
				upstreamBuildersFamilyIds: data.upstream_builders_family_ids,
				downstreamBuildersFamilyIds: data.downstream_builders_family_ids,
				hideSaveProgressButton: hideBuilderSaveProgressButton
			};
		},
		signedImageParameters: (builderId: string, name: string) => Promise.resolve(),
		generateDocument: (builderId: string) => Promise.resolve(),
		generatedDocumentReady: () => Promise.resolve(),
		getImageUrl: (builderId: string, name: string) =>
			Promise.resolve(
				getImageUrl({
					host: api.host,
					builderId,
					name,
					userId: user.id,
					courseId: course.id,
					jwtToken: api.jwt
				})
			)
	};

	const label = getElementLabel(toc.config, page.page_type, element.element_definition_key);

	// Config can be a string if CYO content.
	let schema = element.config;
	if (typeof schema === 'string') {
		schema = JSON.parse(schema);
	}

	return (
		<WritingTemplateFromLibs
			key={`${element.id}-${user.id}`}
			label={label}
			schema={schema}
			toc={libraryToc}
			builderId={element.id}
			api={wtApi}
			generatingDocument={false}
			setGeneratingDocument={() => {}}
			readOnly
		/>
	);
};

const mapStateToProps = (state, props) => ({
	api: state.data.api,
	writingTemplateData: state.entities.webtextBuilders[`${props.user.id}:${props.element.id}`],
	hideBuilderSaveProgressButton: state.entities.featureFlags.hideBuilderSaveProgressButton
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(WritingTemplate);
