import { getRequestAsync, requestAsync, mergeData } from '.';

import type { GradebookColumn, StudentGrade } from '@soomo/lib/notebook/types/index';

export interface StudentGradesResponse {
	gradebook_columns: Array<GradebookColumn>;
	student_grades: {
		[studentId: number]: {
			current_points_sum: number;
			column_grades: Array<StudentGrade | null>;
		};
	};
	points_in_course: number;
	average_points: {
		total: number;
		column_averages: number[];
	};
	lms_gradebook_precision: number;
}

const studentGradesQueryConfig = ({ courseId, force }) => ({
	path: '/api/courses/v1/analytics/student_grades',
	params: { course_id: courseId },
	transform: (payload) => {
		const entities = {
			studentGrades: payload
		};
		return entities;
	},
	update: { studentGrades: mergeData },
	force
});

export const getStudentGradesQuery = ({ courseId, force }) =>
	getRequestAsync(
		studentGradesQueryConfig({
			courseId,
			force
		})
	);

export default ({ courseId, force = false }) =>
	requestAsync(
		studentGradesQueryConfig({
			courseId,
			force
		})
	);
