import React, { useEffect, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';

import { css } from '@emotion/react';
import { useWindowSize } from 'usehooks-ts';
import { shallow } from 'zustand/shallow';

import { CellsRefs } from '../../helpers/types';
import { useCallbackOnScroll } from '../../hooks/useCallbackOnScroll';
import { useSpreadsheetSelector } from '../../store/provider';
import { selectCellEvaluatedValue } from '../../store/selectors';

export interface Props {
	cellsRefs: CellsRefs;
	spreadsheetWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

const messageWidth = 250;

const ErrorMessage: React.FC<Props> = ({ cellsRefs, spreadsheetWrapperRef }) => {
	const { width: windowWidth } = useWindowSize();

	const {
		displayErrorFor,
		customErrorMessage,
		setShowErrorFor,
		cellValue,
		focused,
		selectedCell,
		runtimeEditableErrors
	} = useSpreadsheetSelector(
		(state) => ({
			displayErrorFor: state.displayErrorFor.address,
			setShowErrorFor: state.setShowErrorFor,
			cellValue: selectCellEvaluatedValue(state, state.displayErrorFor.address),
			customErrorMessage: state.displayErrorFor.message,
			focused: state.focused,
			runtimeEditableErrors: state.runtimeEditableErrors,
			selectedCell: state.selectedCell
		}),
		shallow
	);

	const element = cellsRefs[displayErrorFor]?.current;
	const messageDimensions = useMemo(() => {
		if (!element) return;
		const {
			top: elementTop,
			right: elementRight,
			bottom: elementBottom,
			left: elementLeft
		} = element.getBoundingClientRect();

		/**
		 * https://soomo.height.app/T-46640
		 * When an error is displayed for the cell that's too close to the edge of the screen - overflow happens.
		 * To prevent it, we need to decrease the `left` be ≤ than `windowWidth - messageWidth`
		 */
		let messageWidthAvailable = windowWidth - elementRight;
		let isOverflow = messageWidthAvailable < messageWidth;
		if (!isOverflow) {
			return { top: elementTop, left: elementRight }; // Display right next to the cell
		}

		/**
		 * Display message shifted to the right relative to the cell (mimic GSheet)
		 * Example: https://gyazo.com/606ac3541b4c08b4f6f421dd6e6eba20
		 */
		const messageLeftOffset = 17;
		let messageLeft = elementLeft + messageLeftOffset;

		messageWidthAvailable = windowWidth - messageLeft;
		isOverflow = messageWidthAvailable < messageWidth;
		if (!isOverflow) {
			return {
				top: elementBottom,
				left: messageLeft
			};
		}

		/**
		 * Move message into the viewport to prevent its cropping
		 */
		const missingWidth = messageWidth - messageWidthAvailable;
		messageLeft = messageLeft - missingWidth;
		return {
			top: elementBottom,
			left: messageLeft
		};
	}, [element, windowWidth]);

	const hideErrorMessage = useCallback(() => setShowErrorFor(null), [setShowErrorFor]);
	useCallbackOnScroll(hideErrorMessage, spreadsheetWrapperRef);
	useEffect(() => {
		if (!focused || !runtimeEditableErrors[selectedCell]) hideErrorMessage();
	}, [focused, hideErrorMessage, runtimeEditableErrors, selectedCell]);

	if (!displayErrorFor) return null;
	return ReactDOM.createPortal(
		<div css={styles(messageDimensions)}>
			<div className="help">Error</div>
			<div className="text">{cellValue?.error || customErrorMessage || 'Cell contains error'}</div>
		</div>,
		document.body
	);
};

const styles = (messageDimensions?: { top: number; left: number }) => {
	const { top, left } = messageDimensions || { top: 0, left: 0 };
	return css`
		position: absolute;
		min-height: 100px;
		width: ${messageWidth}px;
		background: white;
		border-radius: 5px;
		border-left: 5px solid red;
		padding: 5px 10px;
		font-size: 14px;
		box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.8);
		z-index: 10001;
		top: ${top}px;
		left: ${left}px;

		.help {
			font-weight: bold;
			margin-bottom: 10px;
		}

		.text {
			line-height: 1.4;
		}
	`;
};

export default ErrorMessage;
