import React, { useEffect, useRef, useState } from 'react';

import { useWindowSize } from 'usehooks-ts';

import { useIsUniversalVelvet } from '~/hooks';

import styles, { universalVelvetOuterContainer } from './styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	noBottomMargin?: boolean;
	className?: string;
	wide?: boolean;
}

export const WebtextQuestion: React.FC<Props> = (props) => {
	const { children, className, noBottomMargin, wide, ...rest } = props;

	const windowSize = useWindowSize();
	const isUV = useIsUniversalVelvet();
	const containerRef = useRef<HTMLDivElement>(null);
	const [spacingToNegate, setSpacingToNegate] = useState(0);

	/**
	 * Since it is impossible to determine parent margin / padding that is outside of an element
	 * (using CSS), we must calculate the amount of space to negate based on the elements position
	 * on the screen using JavaScript. Calculating this value is necessary so we can have a left
	 * hand border flush to the screen edge on small devices.
	 *
	 * This effect will re-calculate the amount of space to negate when the viewport size changes
	 * & when the width of the container element changes.
	 */
	useEffect(() => {
		if (containerRef.current) {
			setSpacingToNegate(containerRef.current.getBoundingClientRect().left);
		}
	}, [containerRef, windowSize, containerRef.current?.clientWidth]);

	if (isUV) {
		return (
			<div
				ref={containerRef}
				className="webtext-question-universal-velvet"
				css={universalVelvetOuterContainer(spacingToNegate, noBottomMargin, wide)}>
				<div className={className} css={styles(noBottomMargin, wide)} {...rest}>
					{children}
				</div>
			</div>
		);
	}

	return (
		<div className={className} css={styles(noBottomMargin)} {...rest}>
			{children}
		</div>
	);
};
