import React, { FC } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import { textField } from '~/components/Outline/components/TextField/styles';
import {
	selectResponseValue,
	selectTemplate,
	selectTemplateDisableOutputLabel,
	useOutlineSelector
} from '~/components/Outline/store';
import { OutlineInstanceAddress } from '~/components/Outline/types';

export interface Props {
	address: OutlineInstanceAddress;
}

const ViewTextField: FC<Props> = (props) => {
	const { address } = props;

	const template = useOutlineSelector((state) => selectTemplate(state, address));
	const { label_type: labelType } = template;

	const disableOutputLabel = useOutlineSelector((state) =>
		selectTemplateDisableOutputLabel(state, address)
	);

	const responseValue = useOutlineSelector((state) => selectResponseValue(state, address));

	return (
		<span
			css={readonlyTextField({ labelType, disableOutputLabel })}
			className="outline-instance-view-text-field">
			{responseValue}
		</span>
	);
};

const readonlyTextField = (...args: Parameters<typeof textField>): SerializedStyles => css`
	${textField(...args)}
	line-height: 1.3;
	white-space: pre-wrap; // Preserve the newline symbols in the output
	word-break: break-word;
`;

export default ViewTextField;
