import { type ReactElement, type ComponentType, useMemo } from 'react';

import { type IDictionary } from '../../WritingTemplate/types';
import { evaluateCellValuesSlice } from '../helpers/compute';
import { createInitialState, initializeCellValues } from '../helpers/initialize';
import { type Sheet } from '../helpers/types';
import { SpreadsheetStoreProvider } from '../store/provider';

function withStoreProvider<T>(Component: ComponentType<T>) {
	return function StoreProviderWrapper(
		props: T & {
			dest: string;
			sheet: Sheet;
			readOnly: boolean;
			isolated?: boolean;
			inputs: IDictionary;
			onUserUpdate?: () => void;
		}
	): ReactElement {
		const { dest, sheet, readOnly, isolated, inputs, onUserUpdate } = props;

		/**
		 * Initialize read-only and runtime default values
		 */
		const initialState = useMemo(
			() => createInitialState({ dest, sheet, readOnly, isolated }),
			/**
			 * All other deps not affect the process of initialize,
			 * so we don't need to re-calculate this once they change
			 *
			 * 2023.09.22 rev: We need to re-calculate this when readOnly changes, the new approach with instructor view mode changing the readOnly prop when SPI is already mounted
			 */
			[readOnly]
		);

		/**
		 * Initialize cellValues from sheet.cells and response, update graph
		 */
		const storedValues = inputs[dest]?.cellValues;
		const initialCellValues = useMemo(
			() =>
				initializeCellValues({
					sheet,
					origin: initialState.origin,
					graph: initialState.graph,
					inputs,
					isolated,
					editableCoordinates: initialState.editableRanges,
					storedValues
				}),
			/**
			 * All other deps not affect the process of initialize,
			 * so we don't need to re-calculate this once they change
			 */
			[]
		);

		/**
		 * Evaluate formulas using the graph and cellValues
		 */
		const initialEvaluatedFormulasValues = useMemo(
			() =>
				evaluateCellValuesSlice({
					addressesToEvaluate: Object.keys(initialCellValues),
					cellValues: initialCellValues,
					graph: initialState.graph
				}),
			/**
			 * All other deps not affect the process of initialize,
			 * so we don't need to re-calculate this once they change
			 */
			[]
		);

		return (
			<SpreadsheetStoreProvider
				{...initialState}
				cellValues={initialCellValues}
				evaluatedFormulasValues={initialEvaluatedFormulasValues}
				onUserUpdate={onUserUpdate}>
				<Component {...(props as any)} />
			</SpreadsheetStoreProvider>
		);
	};
}

export default withStoreProvider;
