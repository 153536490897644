import { buildTree } from 'excel-formula-ast';
import { tokenize } from 'excel-formula-tokenizer';
import isNil from 'lodash-es/isNil';

import { getRangeAddresses } from '..';
import { availableFormulaNames, availableFormulas, staticErrorReturns } from '../constants';
import {
	Argument,
	ArgumentType,
	CellValues,
	EvaluatedCells,
	ExpressionArgument,
	FormulaArguments,
	FormulaResult,
	RangeArgument,
	Tree,
	TreeArgumentType
} from '../types';
import { getIsWithUnexpectedCharacters, transformRangeIntoArrayOfValues } from './utils';

export const validateInput = (
	enteredValue: string,
	cellValues: CellValues,
	evaluatedCellValues: EvaluatedCells = {}
): FormulaResult => {
	const inputStringError = validateUnexpectedCharacters(enteredValue);
	if (inputStringError) return inputStringError;
	/**
	 * We need try/catch here, because buildTree can throw an error
	 * This happens in cases when entered value is not a correct formula and in most cases we will return the error anyway
	 */
	try {
		/**
		 * We also need to replace ; with , because tokenizer does not support ;
		 * This operation should be pretty safe, because ; is a rarely used separator, it works a bit different than , in Excel, but spi does not support this
		 * In two words ; is the same to , in spi
		 */
		const tokens = tokenize(enteredValue.replace(/;/g, ','));
		const tree = buildTree(tokens) as Tree;

		return validateTree({ tree, enteredValue, cellValues, evaluatedCellValues });
	} catch {
		return staticErrorReturns.signatureError;
	}
};

/**
 * In this function we traverse the tree and validate each argument
 * There are 5 types of arguments; but we validate only four because numbers does not require any validation
 */
const validateTree = (props: {
	tree: Tree;
	enteredValue: string;
	cellValues: CellValues;
	evaluatedCellValues: EvaluatedCells;
	isPartOfExpression?: boolean;
}): FormulaResult => {
	const { tree, enteredValue, cellValues, evaluatedCellValues, isPartOfExpression } = props;
	const { type } = tree;

	switch (type) {
		case TreeArgumentType.Formula: {
			const formulaValidationError = validateFormula({
				formulaName: tree.name,
				formulaArgs: tree.arguments,
				cellValues,
				evaluatedCellValues
			});
			/**
			 * This is necessary only for top level formulas, because they returns instantly
			 * Arguments error does not provide a visible result, because this result depends on the entered value
			 */
			if (formulaValidationError && !formulaValidationError.result)
				/**
				 * Example of return in this case will be a =SUM() instead of the #ERROR, the space before user input needed for correct render of this value.
				 */
				return { ...formulaValidationError, result: ` ${enteredValue}` };
			/**
			 * Any other error is completed and has static result
			 */
			return formulaValidationError;
		}
		case TreeArgumentType.BinaryExpression:
			return validateBinaryExpression({
				expression: tree as ExpressionArgument,
				cellValues,
				evaluatedCellValues,
				enteredValue
			});
		case ArgumentType.Cell:
			return validateCell({
				cell: tree as Argument,
				cellValues,
				evaluatedCellValues,
				disableSingleValueError: true,
				isPartOfExpression
			});
		case ArgumentType.CellRange:
			return isPartOfExpression ? staticErrorReturns.valueError : staticErrorReturns.signatureError;
		default:
			break;
	}
};

/**
 * This is a wrapper for formula validation
 * it does traverse formula arguments and validates them and also validates formula itself
 */
const validateFormula = (props: {
	formulaName: string;
	formulaArgs: FormulaArguments;
	cellValues: CellValues;
	evaluatedCellValues: EvaluatedCells;
}): FormulaResult => {
	const { formulaName, formulaArgs, cellValues, evaluatedCellValues } = props;

	const formulaValidationError = validateFormulaInvocation({
		formulaName,
		formulaArgs
	});
	if (formulaValidationError) return formulaValidationError;

	const argumentsValidationError = validateArguments({
		formulaArgs,
		cellValues,
		evaluatedCellValues
	});
	if (argumentsValidationError) return argumentsValidationError;
};

/**
 * This function validates each argument of the formula
 * it does not validates values, but it validates the formula arguments for existence and correctness
 * it traverses each argument and validates it, so it possible to validate any deepness of the formula arguments
 */
const validateArguments = (props: {
	formulaArgs: FormulaArguments;
	cellValues: CellValues;
	evaluatedCellValues: EvaluatedCells;
}): FormulaResult => {
	const { formulaArgs, cellValues, evaluatedCellValues } = props;

	if (formulaArgs.length === 0) {
		return staticErrorReturns.argumentsError;
	}

	for (let arg = 0; arg < formulaArgs.length; arg++) {
		const argument = formulaArgs[arg];
		const { type } = argument;

		let error = null;

		switch (type) {
			case TreeArgumentType.Formula: {
				error = validateFormula({
					formulaName: (argument as Tree).name,
					formulaArgs: (argument as Tree).arguments,
					cellValues,
					evaluatedCellValues
				});
				break;
			}
			case TreeArgumentType.BinaryExpression: {
				error = validateBinaryExpression({
					expression: argument as ExpressionArgument,
					cellValues,
					evaluatedCellValues,
					/**
					 * This value will be handled on the top level
					 */
					enteredValue: ''
				});
				break;
			}
			case ArgumentType.Cell: {
				error = validateCell({
					cell: argument as Argument,
					cellValues,
					evaluatedCellValues
				});
				break;
			}
			case ArgumentType.CellRange: {
				error = validateRange({
					cellRange: argument as RangeArgument,
					cellValues,
					evaluatedCellValues
				});
				break;
			}
			default:
				break;
		}

		if (error) return error;
	}
};

/**
 * This functions validates formulas in general
 * it also validates whether formula implemented or correct
 * this happens only after we validated all arguments of the formula
 */
const validateFormulaInvocation = (props: {
	formulaName: string;
	formulaArgs: FormulaArguments;
}) => {
	const { formulaName, formulaArgs } = props;
	const normalizedFormulaName = formulaName.toUpperCase();

	if (!availableFormulaNames.includes(normalizedFormulaName)) return staticErrorReturns.nameError;

	const formula = availableFormulas[formulaName.toLocaleLowerCase()];
	if (!formula?.formula) {
		return {
			...staticErrorReturns.implementationError,
			error: `${staticErrorReturns.implementationError.error} ${formulaName}`
		};
	}

	const { required } = formula;
	/**
	 * Formula correct, but contains not enough/too much arguments
	 */
	if (formulaArgs.length < required.min || formulaArgs.length > required.max) {
		return { ...staticErrorReturns.argumentsError, result: ` ${formulaName}` };
	}

	if (normalizedFormulaName === 'SUM' && formulaArgs.find((arg) => arg.type === ArgumentType.Text))
		return staticErrorReturns.valueError;
};

/**
 * This function validates only range values by transforming them into array of values
 * It does not validate exact value inside the range, only that the range is correct
 */
const validateRange = (props: {
	cellRange: RangeArgument;
	cellValues: CellValues;
	evaluatedCellValues: EvaluatedCells;
}) => {
	const { cellRange, cellValues, evaluatedCellValues } = props;
	const {
		left: { key: leftKey },
		right: { key: rightKey }
	} = cellRange;

	if (!getIsCellAddressSupported(leftKey) || !getIsCellAddressSupported(rightKey))
		return staticErrorReturns.nameError;

	const startAddr = leftKey.replace(/\$/g, '');
	const endAddr = rightKey.replace(/\$/g, '');

	const normalizedArgument = `${startAddr}:${endAddr}`.toUpperCase();
	const rangeAddresses = getRangeAddresses(normalizedArgument);
	if (!rangeAddresses.result) return staticErrorReturns.nameError;
	if (rangeAddresses?.value.some((value) => isNil(cellValues[value])))
		return staticErrorReturns.nameError;

	const rangeArray = transformRangeIntoArrayOfValues({
		argument: normalizedArgument,
		cellValues,
		evaluated: evaluatedCellValues,
		isAllowRecursion: true
	});

	if (!rangeArray) return staticErrorReturns.nameError;
	if ((rangeArray as FormulaResult).error) return rangeArray as FormulaResult;
};

/**
 * This function validates only single cell value for existence and correctness
 * In some cases it also check whether this cell value is a part of expression and it should be a number
 */
const validateCell = (props: {
	cell: Argument;
	cellValues: CellValues;
	evaluatedCellValues: EvaluatedCells;
	disableSingleValueError?: boolean;
	isPartOfExpression?: boolean;
}) => {
	const { cell, cellValues, evaluatedCellValues, disableSingleValueError, isPartOfExpression } =
		props;
	const { key } = cell;

	if (!getIsCellAddressSupported(key)) return staticErrorReturns.nameError;
	const address = key.replace(/\$/g, '').toUpperCase();

	/**
	 * If cell value stored inside evaluated cell values, then it means that this cell has formula
	 * In this case we only need to check if this formula has any errors
	 * And if it has, then return this error
	 * Otherwise move on
	 */
	const evaluatedCellValue = evaluatedCellValues[address];
	if (evaluatedCellValue) {
		if (evaluatedCellValue.success) return;
		return evaluatedCellValue;
	}

	const cellValue = cellValues[address];
	/**
	 * Cell value must exist, otherwise this ref is incorrect
	 */
	if (isNil(cellValue)) return staticErrorReturns.nameError;
	/**
	 * This is custom error that shows up whether we calculating value $60 or 1,000
	 */
	if (!disableSingleValueError && getIsWithUnexpectedCharacters(cellValue))
		return staticErrorReturns.unexpectedCharactersError;

	if (!isPartOfExpression) return;

	const value = getCellValue(address, cellValues, evaluatedCellValues);
	if (isNaN(+value)) return staticErrorReturns.valueError;
};

/**
 * Sometimes we need to validate single input before building the tree
 * Some single values can be considered as errors, or interfere with the construction of the tree
 */
const validateUnexpectedCharacters = (inputValue: string) => {
	/**
	 * These regex are used to check if the input value contains any $50 or 1,000 or 50$
	 * Except of absolute addresses, they are replaced, because their signature is similar to $5 (B$5)
	 */
	const isErrorsInRawInput = [/(\$\d+)(,\d*)?/gi, /(\d+)(,\d*)?(\$)/gi, /=\d+,\d+/i].some((value) =>
		value.test(inputValue.replace(/[A-Za-z]+\$\d+/gi, ''))
	);

	if (isErrorsInRawInput) return staticErrorReturns.unexpectedCharactersError;

	/**
	 * This is a list of characters that are not allowed in the input
	 * It's a kind of the edge case, but it's better to have this check.
	 * We need this because the tree builder totally ignores these characters
	 * and it's possible to build a tree with these characters without any errors
	 */
	const unexpectedCharactersList = ['|', '—', `\``];
	if (unexpectedCharactersList.some((value) => inputValue.includes(value)))
		return staticErrorReturns.unexpectedCharactersError;
};

/**
 * Binary expression in this case can be any mathematical expression
 * In this case this is similar to the tree, basically each side of the expression is a tree
 * So we traversing them recursively until we find an error or reach the end
 */
const validateBinaryExpression = (props: {
	expression: ExpressionArgument;
	cellValues: CellValues;
	evaluatedCellValues: EvaluatedCells;
	enteredValue: string;
}): FormulaResult => {
	const { expression, cellValues, evaluatedCellValues, enteredValue } = props;
	const { left, right, operator } = expression;

	/**
	 * We don't allow to use , in binary expressions
	 * Most likely this is a kind of 1,000 argument and it's an (custom) error
	 */
	if (operator === ',') return staticErrorReturns.unexpectedCharactersError;

	const leftError = validateTree({
		tree: left,
		cellValues,
		evaluatedCellValues,
		enteredValue,
		isPartOfExpression: true
	});
	const rightError = validateTree({
		tree: right,
		cellValues,
		evaluatedCellValues,
		enteredValue,
		isPartOfExpression: true
	});

	if (leftError) return leftError;
	if (rightError) return rightError;
};

/**
 * This is validation specific getter for cell values
 * It returns cell value from evaluated cell values if it exists
 * Otherwise it returns cell value from cell values
 * without any additional checks
 */
const getCellValue = (
	address: string,
	cellValues: CellValues,
	evaluatedCellValues: EvaluatedCells
) => evaluatedCellValues[address]?.result ?? cellValues[address];

const getIsCellAddressSupported = (address: string) =>
	/^\$?[A-Za-z]{1,2}\$?[0-9]{1,2}[^$]*$/i.test(address);
