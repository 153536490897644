/* eslint-disable react/display-name */
import React from 'react';

import { css, withTheme } from '@emotion/react';

import { GenericErrorBoundary } from '~/components';
import { getPolicyForRespondable } from '~/utils';

import Container from './PopupQuizContainer';
import { PossiblyBrokenPopupQuiz } from './types';

const renderErrorMessage = ({ error, errorUUID }) => {
	const errorStyles = css`
		padding-left: 1rem;
		padding-right: 1rem;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		border-left: 7px solid #2ca936;
		p {
			font-family: Helvetica, Arial, sans-serif;
			font-size: 0.85rem !important;
			margin-bottom: 0.25rem !important;
		}
	`;

	return (
		<div css={errorStyles}>
			<div>
				<span style={{ color: 'red' }}>There was an error with this element.</span>
				<br />
				<span>Ref: {errorUUID}</span>
			</div>
		</div>
	);
};

const withErrorBoundary = (Component) => (props) => {
	return (
		<GenericErrorBoundary
			renderErrorMessage={renderErrorMessage}
			disabled={props.disableErrorBoundary}>
			<Component {...props} />
		</GenericErrorBoundary>
	);
};

// Updates the PUQ that comes in. Should only need to update
// it once, but will update it every time just in case. This
// logic belongs in Core, and will get moved their soon enough.
const withFixedPopupQuiz = (Component) => (props) => {
	const { popupQuiz } = props as { popupQuiz: PossiblyBrokenPopupQuiz };

	// ensure `ui.buttons`, `ui.messages`, and `ui.labels` are present.
	const rebuiltPuq = { ...popupQuiz };
	if (popupQuiz.ui) {
		rebuiltPuq.ui = {
			...popupQuiz.ui,
			...(!popupQuiz.ui.buttons && { buttons: {} }),
			...(!popupQuiz.ui.messages && { messages: {} }),
			...(!popupQuiz.ui.labels && { labels: {} }),
			...(!popupQuiz.ui.flags && { flags: {} }),
			...(!popupQuiz.ui.results && { results: {} })
		};
	} else {
		rebuiltPuq.ui = { buttons: {}, messages: {}, labels: {}, flags: {}, results: {} };
	}

	if (rebuiltPuq.ui.labels.element_name != null) {
		rebuiltPuq.headingText = rebuiltPuq.ui.labels.element_name;
	} else if (!rebuiltPuq.headingText) {
		rebuiltPuq.headingText = 'Assessment';
	}

	return <Component {...{ ...props, popupQuiz: rebuiltPuq }} />;
};

const withRespondablePolicy = (Component) => (props) => {
	const policy = getPolicyForRespondable(
		props.coursePolicy,
		'NG::Soomo::Popup::Quiz',
		props.popupQuiz.family_id
	);
	return <Component {...props} policy={policy} />;
};

// TODO: remove unneeded HOCs here and expose PUQ type completion to consumers
// - withTheme is redundant with css``
// - withErrorBoundary should expose types from wrapped component
// - withRespondablePolicy/withFixedPopupQuiz can possibly live inside PopUpQuizContainer.tsx
export default withTheme(withErrorBoundary(withRespondablePolicy(withFixedPopupQuiz(Container))));
