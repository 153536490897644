import * as React from 'react';

export function MobileTip(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={34.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.933050147566838px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={30.5} fill="currentColor" />
			<path
				d="M47.7 16.55H24.33a1.31 1.31 0 0 0-1.33 1.3v36.3a1.29 1.29 0 0 0 1.29 1.29H47.7a1.29 1.29 0 0 0 1.3-1.28V17.85a1.3 1.3 0 0 0-1.3-1.3Zm-22.07 7.78h20.74v20.74H25.63Zm20.74-5.19v2.59H25.63v-2.58ZM25.63 52.87v-5.2h20.74v5.18Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M37.3 50.25A1.3 1.3 0 1 1 36 49a1.3 1.3 0 0 1 1.33 1.29Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
