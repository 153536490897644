import first from 'lodash-es/first';

import { isNumeric } from '~/utils/parsing';

import { ChartMetadata, PieDataType, SpreadsheetState } from '../types';
import { getPercentage, splitRangeIntoRows, switchRowViewIntoColumns } from '../utils';
import { ChartConfig } from './../../EditMode/types';
import { Graph } from './../../Spreadsheet/Graph';
import { evaluateCellValuesSlice } from '../../Spreadsheet/helpers/compute';
import { formatValue, getFormattingForCell, getIsFormula } from './../../Spreadsheet/helpers/index';
import { Formatting, FormatType } from './../../Spreadsheet/helpers/types';

const createTypedDataRanges = (
	columnData: string[]
): Array<{ type: 'number'; value: number } | { type: 'string'; value: string }> =>
	columnData.map((value) =>
		/* when value is string, just return strings, otherwise transform */
		isNumeric(value) ? { type: 'number', value: +value } : { type: 'string', value }
	);

const getIsColumnNumeric = (columnData: string[]) =>
	createTypedDataRanges(columnData).some((value) => value.type === 'number');

const formatValueForTable = (address: string, value: number, formatting: Formatting) => {
	if (!address) return '';

	const cellFormatting = getFormattingForCell(address, formatting);
	const isCurrencyLikeFormatting = [FormatType.Accounting, FormatType.Currency].includes(
		cellFormatting?.type
	);

	if (!isCurrencyLikeFormatting) {
		return `${value}`;
	}

	return formatValue(value, { ...cellFormatting, type: FormatType.Currency });
};

const processSelectedColumns = (columnData: Array<string[]>, formatting: Formatting) => {
	// Update data range with types for next handling
	const dataColumn = createTypedDataRanges(columnData.flatMap((value) => value.slice(1, 2)));

	let startIndex = 0;

	const chartNameFromST = dataColumn.reduce((acc, { type, value }, idx) => {
		/**
		 * Looking for string in the start of the array, they become a name for chart.
		 * After we found any non-string, this process stops.
		 * StartIndex will be used for cutting array exactly after strings end.
		 */
		if (idx > startIndex || type !== 'string') return acc;

		startIndex++;
		return `${acc} ${value}`;
	}, '');

	const columnDataSlice = dataColumn.slice(startIndex);
	const labelSlice = columnData.slice(startIndex).flatMap((value) => value.slice(0, 1));
	const addrSlice = columnData.slice(startIndex).flatMap((value) => value.slice(2, 3));

	const total = columnDataSlice.reduce(
		(acc, { type, value }) => (type === 'string' ? acc : acc + (value as number)),
		0
	);

	const chartData = columnDataSlice.map(({ type, value }, idx) =>
		type === 'string'
			? { label: `${labelSlice[idx]}`, value: 0, percent: 0, formattedValue: '0' }
			: {
					label: `${labelSlice[idx]}`,
					value: +value,
					percent: getPercentage(total, +value),
					formattedValue: formatValueForTable(addrSlice[idx], +value, formatting)
			  }
	);

	return { name: chartNameFromST, data: chartData, startIndex };
};

export const getPieData = (
	chartSource: SpreadsheetState,
	selection: string[],
	config: ChartConfig
): { name: string; data: PieDataType; meta: ChartMetadata } => {
	const { cellValues: rawCellValues } = chartSource;
	const { formatting } = chartSource.sheet;

	const graph = new Graph();
	Object.entries(rawCellValues).forEach(([address, value]) =>
		graph.addVertex(address, String(value))
	);
	const formulaResults = evaluateCellValuesSlice({
		addressesToEvaluate: Object.keys(rawCellValues),
		cellValues: rawCellValues,
		graph
	});
	const cellValues = Object.fromEntries(
		Object.entries(rawCellValues).map(([address, value]) => [
			address,
			getIsFormula(value) ? `${formulaResults[address].result || 0}` : value
		])
	);

	const rows = splitRangeIntoRows(selection);
	const rowsArray = Object.values(rows);

	const formatAddressWithValues = (address: string) => {
		const cellValue = cellValues[address];
		if (!cellValue.endsWith('%')) return cellValue;

		const percentage = parseFloat(cellValue);
		return `${percentage / 100}`;
	};

	/**
	 *
	 * @param startIndex
	 * Chart metadata now created alongside with chart data, bc they are depends on each other
	 */
	const createDataRanges = (startIndex: number) => {
		const nameSelection = rowsArray.slice(0, startIndex);
		const dataSelection = rowsArray.slice(startIndex);

		const nameColumnsRange = switchRowViewIntoColumns(nameSelection);
		const dataColumnRange = switchRowViewIntoColumns(dataSelection);

		return {
			nameRanges: nameColumnsRange || [[config.nameReference]] || [],
			dataRanges: dataColumnRange
		};
	};

	if (first(rowsArray).length !== 1) {
		// Insert in array [label, value, address]
		const columnData = rowsArray.map(([labelAddress, valueAddress]) => [
			...[labelAddress, valueAddress].map(formatAddressWithValues),
			valueAddress
		]);

		const { name, data, startIndex } = processSelectedColumns(columnData, formatting);

		return {
			name,
			data,
			meta: createDataRanges(startIndex)
		};
	}

	/**
	 * Selected only one column
	 */
	const columnData = rowsArray.map((row) => first(row)).map(formatAddressWithValues);

	if (!columnData.length) return { name: '', data: [], meta: { nameRanges: [], dataRanges: [] } };

	const isColumnNumeric = getIsColumnNumeric(columnData);

	if (!isColumnNumeric) {
		// When column is labels add a dummy data column with zeros
		// we do not worried about addresses here, because these values will never be shown
		const injectedDataColum = columnData.map((value) => [value, `${0}`, '']);

		const { name, data, startIndex } = processSelectedColumns(injectedDataColum, formatting);

		return {
			name,
			data,
			meta: createDataRanges(startIndex)
		};
	}

	// When column is data column add a dummy label column
	// inject address from rowArray by index of element
	const injectedLabelColumn = columnData.map((value, idx) => [
		`${idx + 1}`,
		value,
		`${first(rowsArray[idx])}`
	]);
	const { name, data, startIndex } = processSelectedColumns(injectedLabelColumn, formatting);

	//When we cut one or couple of rows from start for name, idx may shift and we restore it.
	return {
		name,
		data: data.map((dataRow, idx) => ({ ...dataRow, label: `${idx + 1}` })),
		meta: createDataRanges(startIndex)
	};
};
