import React from 'react';

import { tabPanelStyle } from './styles';

interface Props {
	index: number;
	value: number;
	unmountOnExit?: boolean;
}

const TabPanel: React.FC<Props> = (props) => {
	const { value, index, unmountOnExit = false, children } = props;
	return (
		<div
			tabIndex={0}
			role="tabpanel"
			css={tabPanelStyle}
			id={`tabpanel-${index}`}
			hidden={value !== index}
			aria-labelledby={`tab-${index}`}>
			{value !== index && unmountOnExit ? null : children}
		</div>
	);
};

export default TabPanel;
