import React, { FC, useEffect, useRef } from 'react';

import { css } from '@emotion/react';
import { transpose } from 'd3-array';
import { scaleOrdinal } from 'd3-scale';
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import cloneDeep from 'lodash-es/cloneDeep';

import {
	getFormat,
	removeLabelsOverlap,
	valueOf
} from '~/components/pageElements/ChartFigure/chartHelpers';
import { patternClasses } from '~/components/pageElements/ChartFigure/PatternsSvg';

import ChartDescription from '../ChartDescription';

import type { Props as PieChartProps } from './PieChart';
import type { ChartElement } from '~/types/WebtextManifest';

const PieChart: FC<PieChartProps> = (props) => {
	const { chart, monochrome } = props;

	const chartData = cloneDeep(chart.data);

	const ref = useRef();

	useEffect(() => {
		const svg = select(ref.current);
		svg.selectAll('*').remove();

		draw();
	}, [chart]);

	const draw = () => {
		const seriesLabels = chartData.shift();
		seriesLabels.shift();

		const categories = transpose(chartData);
		categories.shift();

		const categoriesData = transpose(categories).map((a) => a.map((b) => valueOf(b)))[0];

		const radius = chart.show_labels ? 130 : 170;
		const viewBoxWidth = 400;
		const viewBoxHeight = 400;

		const color = scaleOrdinal().range(chart.colors);
		const patternScale = scaleOrdinal().range(patternClasses);

		const pieArc = arc().outerRadius(radius).innerRadius(0);

		const pieData = pie<any, any>()
			.value((d) => d.value)
			.sort(null)
			.sortValues(null)(categoriesData);

		const group: any = select(ref.current)
			.attr('class', 'chart')
			.attr('viewBox', `0 0 ${viewBoxWidth} ${viewBoxHeight}`)
			.attr('preserveAspectRatio', 'xMidYMid meet')
			.append('g')
			.attr('transform', `translate(${viewBoxWidth / 2}, ${viewBoxHeight / 2})`);

		/**
		 * Example: https://www.d3-graph-gallery.com/graph/pie_basic.html
		 * Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
		 */
		group
			.selectAll('.wedge')
			.data(pieData)
			.enter()
			.append('path')
			.attr('class', 'wedge')
			.attr('d', pieArc)
			.attr('fill', (d) => (monochrome ? `url(#${patternScale(d.index)})` : color(d.index)))
			.attr('stroke', () => (monochrome ? 'black' : 'none'));

		if (!chart.show_labels) {
			return;
		}

		const dataType = categoriesData[0].type;

		const labels = group.selectAll('.label').data(pieData).enter();
		const labelsGroups = labels.append('g').attr('class', 'label');
		const labelRadius = radius + 20;

		const lines = labelsGroups
			.append('line')
			.attr('x1', (d) => pieArc.centroid(d)[0])
			.attr('y1', (d) => pieArc.centroid(d)[1])
			.attr('x2', (d) => {
				const centroid = pieArc.centroid(d);
				const midAngle = Math.atan2(centroid[1], centroid[0]);
				return Math.cos(midAngle) * labelRadius;
			})
			.attr('y2', (d) => {
				const centroid = pieArc.centroid(d);
				const midAngle = Math.atan2(centroid[1], centroid[0]);
				return Math.sin(midAngle) * labelRadius;
			})
			.attr('class', 'label-line')
			.attr('stroke', (d) => (monochrome ? 'black' : color(d.index)));

		const formatValue = getFormat(dataType);
		const textLabels = labelsGroups
			.append('text')
			.attr('x', (d) => {
				const centroid = pieArc.centroid(d);
				const midAngle = Math.atan2(centroid[1], centroid[0]);
				const x = Math.cos(midAngle) * labelRadius;
				const sign = x > 0 ? 1 : -1;
				const xOffset = 4 * sign;
				return x + xOffset;
			})
			.attr('y', (d) => {
				const centroid = pieArc.centroid(d);
				const midAngle = Math.atan2(centroid[1], centroid[0]);
				const y = Math.sin(midAngle) * labelRadius;
				const sign = y > 0 ? 1 : -1;
				const yOffset = 5 * sign;
				return y + yOffset;
			})
			.attr('text-anchor', function (d) {
				const centroid = pieArc.centroid(d);
				const midAngle = Math.atan2(centroid[1], centroid[0]);
				const x = Math.cos(midAngle) * labelRadius;
				return x > 0 ? 'start' : 'end';
			})
			.attr('class', 'label-text')
			.text((d) => formatValue(d.value));

		const onRepeat = () => {
			const textLabelsElements = textLabels.nodes();
			lines.attr('y2', (d, i) => {
				const labelForLine = select(textLabelsElements[i]);
				return labelForLine.attr('y');
			});
		};

		removeLabelsOverlap({
			chartGroup: group,
			textLabels,
			onRepeat
		});
	};

	return (
		<div css={styles} data-pie-chart>
			<svg ref={ref} aria-hidden />
			<ChartDescription chart={chart as ChartElement} />
		</div>
	);
};

const styles = (theme) => css`
	width: 100%;
	max-width: 400px;

	.label {
		font-family: ${theme.fonts.app};
		text-anchor: middle;
	}
`;

export default PieChart;
