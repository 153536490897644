import * as React from 'react';

export function Heartbeat(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36.5} fill="currentColor" />
			<path
				d="M51.62 37.46H47.1l-4-14.6a1.45 1.45 0 0 0-1.46-1A1.39 1.39 0 0 0 40.38 23l-4.67 20.74-5.4-15.19a1.14 1.14 0 0 0-1.31-.87 1.4 1.4 0 0 0-1.31 1l-2.63 8.76h-4.68a1.46 1.46 0 0 0-1.46 1.46 1.38 1.38 0 0 0 1.46 1.46h5.69a1.41 1.41 0 0 0 1.32-1l1.6-5.7 5.55 15.77a1.27 1.27 0 0 0 1.31.88H36a1.38 1.38 0 0 0 1.31-1.17l4.38-20 2.78 10.07a1.39 1.39 0 0 0 1.31 1h5.7a1.46 1.46 0 0 0 1.46-1.46 1.23 1.23 0 0 0-1.32-1.29Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
