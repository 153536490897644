import { AnswerVersion, DecisionVersion } from '~/notebook/types';

const getVersionAuthor = (source: number, studentId: number): string =>
	`by ${!source || source === studentId ? 'student' : 'instructor'}`;

export const getAnswerEventLabel = (version: AnswerVersion, studentId: number): string => {
	switch (version.event) {
		case 'unposted':
			return `${version.event} ${getVersionAuthor(version.source, studentId)}`;
		default:
			return version.event;
	}
};

export const getDecisionEventLabel = (version: DecisionVersion, studentId: number): string => {
	switch (version.event) {
		case 'decision_reset':
			return `decision reset ${getVersionAuthor(version.source, studentId)}`;
		default:
			return version.event;
	}
};
