import React from 'react';
import { connect } from 'react-redux';

import {
	DeckedStudentView as LibsDeckedStudentView,
	StudentViewElementProps
} from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/index';
import LibsMCQuestionPoolQuestionDeck from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/MCQuestionPoolQuestionDeck';
import { MCQuestionPoolElement } from '@soomo/lib/types/WebtextManifest';
import { getPolicyForRespondable } from '@soomo/lib/utils/index';

import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

import {
	getActiveAnswer,
	getActiveQuestion,
	getNumResets
} from '@soomo/lib/notebook/utils/mcQuestionPool';
import { getIsAnswerCorrect } from './MCQuestionPool';
import TextLoadingIndicator from 'Components/TextLoadingIndicator';

interface Props {
	deckFamilyId: string;
	questions: Array<MCQuestionPoolElement>;
	questionPoolsProps: Array<Omit<StudentViewElementProps, 'onSave' | 'onReset'>>;
}

const MCQuestionPoolQuestionDeck: React.FC<Props> = (deckProps) => {
	const { deckFamilyId, questionPoolsProps } = deckProps;

	const answersLoaded = questionPoolsProps.every((q) => q !== null);

	if (!answersLoaded) return <TextLoadingIndicator />;

	return (
		<LibsMCQuestionPoolQuestionDeck
			key={questionPoolsProps[0]?.answer?.question_family_id}
			deckFamilyId={deckFamilyId}
			showInstructorView={false}
			questionPoolsProps={questionPoolsProps}>
			{questionPoolsProps.map((poolProps) => (
				<LibsDeckedStudentView
					{...poolProps}
					key={poolProps.questionPool.family_id + poolProps.answer?.question_family_id}
					showInstructorView={false}
					onSave={() => null}
					onReset={() => null}
					readOnly
				/>
			))}
		</LibsMCQuestionPoolQuestionDeck>
	);
};

const createMCQuestionPoolQuestionState = (state, question) => {
	const { ruby_class, family_id } = question;

	const { student: userId } = state.ui.currentViewParams;
	const policyDocument = state.entities.course.policy_document;
	const elementPolicy = getPolicyForRespondable(policyDocument, ruby_class, family_id);
	const attemptsAllowed = elementPolicy.attempts_allowed;

	const poolQuestion = { userId, attemptsAllowed, questionPool: question };

	const answerIds = state.entities.elementAnswerIds[`${userId}:${question.family_id}`];
	const answers = answerIds?.map((id) => state.entities.answers[id]);

	// if no answers loaded yet
	if (!answers) return null;

	const answer = getActiveAnswer(answers);
	const numResets = getNumResets(answers);
	const activeQuestion = getActiveQuestion({ element: question, answer, userId, numResets });
	const isAnswerCorrect = getIsAnswerCorrect(activeQuestion, answer);
	const answerChoice = activeQuestion.choices.find((choice) => choice.family_id === answer?.body);

	return {
		...poolQuestion,
		answer: answer
			? {
					...answer,
					rejoinder: answerChoice?.rejoinder,
					completed: true,
					correct: isAnswerCorrect
			  }
			: null,
		resetCount: numResets
	};
};

const mapStateToProps = (state, props) => {
	const { questions } = props;

	const questionPoolsProps = questions.map((question) =>
		createMCQuestionPoolQuestionState(state, question)
	);

	return { questionPoolsProps };
};

export default connect(mapStateToProps)(withLibsThemeProvider(MCQuestionPoolQuestionDeck));
