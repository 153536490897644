import React, { ChangeEvent, FC, useEffect, useRef } from 'react';

import { ClassNames } from '@emotion/react';
import { shallow } from 'zustand/shallow';

import AutosizeTextarea, { TextareaHandles } from '~/components/AutosizeTextarea';
import {
	editTextFieldInput,
	textFieldConstraintMessage,
	textFieldValidationMessage
} from '~/components/Outline/components/TextField/EditTextField/styles';
import { textField } from '~/components/Outline/components/TextField/styles';
import {
	applyFieldConstraint,
	formatViolatedValue,
	getViolatedFillInConstraint,
	removeFieldConstraints
} from '~/components/Outline/helpers';
import { useValidationProviderErrorMessage } from '~/components/Outline/hooks';
import {
	selectLabelId,
	selectReadonly,
	selectResponseValue,
	selectResponseViolatedConstraint,
	selectTemplate,
	selectTemplateConstraints,
	selectTemplateKey,
	useOutlineSelector
} from '~/components/Outline/store';
import { OutlineInstanceAddress } from '~/components/Outline/types';
import ValidationMessage, {
	getValidationMessageId
} from '~/components/WritingTemplate/ValidationMessage';

export interface Props {
	address: OutlineInstanceAddress;
}

const EditTextField: FC<Props> = (props) => {
	const { address } = props;

	const textareaRef = useRef<TextareaHandles>(null);

	const labelId = useOutlineSelector((state) => selectLabelId(state, address));

	const validationId = getValidationMessageId(address);
	const constraintId = `${validationId}_constraint`;

	const dest = useOutlineSelector((state) => state.dest);
	const templateKey = useOutlineSelector((state) => selectTemplateKey(state, address));
	const template = useOutlineSelector((state) => selectTemplate(state, address));
	const {
		label_type: labelType,
		text_field: { min_rows: minRows, placeholder }
	} = template;

	const responseValue = useOutlineSelector((state) => selectResponseValue(state, address));

	const templateConstraints = useOutlineSelector((state) =>
		selectTemplateConstraints(state, templateKey)
	);
	const violatedConstraint = useOutlineSelector((state) =>
		selectResponseViolatedConstraint(state, address)
	);
	const constraintViolationMessage = violatedConstraint?.violationMessage;
	const hasConstraintViolated = Boolean(constraintViolationMessage);

	const validationErrorMessage = useValidationProviderErrorMessage(dest, address);
	const hasValidationError = Boolean(validationErrorMessage);

	const readOnly = useOutlineSelector(selectReadonly);

	const { initializeField, updateField } = useOutlineSelector(
		(state) => ({
			initializeField: state.initializeField,
			updateField: state.updateField
		}),
		shallow
	);

	// Needed to initialize field in the respondable state after it was rendered as the "initial" instance
	useEffect(() => initializeField(address), [address, initializeField]);

	// Focuses the text field that violated validation or template constraint
	useEffect(() => {
		if (hasValidationError) {
			textareaRef.current.focus();
		}
	}, [hasValidationError, textareaRef]);

	const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const { target: fieldElement } = event;

		const value = fieldElement.value;
		const violatedConstraint = getViolatedFillInConstraint(templateConstraints?.fill_in, value);

		let formattedValue: string;
		if (violatedConstraint) {
			formattedValue = formatViolatedValue(value, violatedConstraint);
			applyFieldConstraint(fieldElement, violatedConstraint);
		} else {
			formattedValue = value;
			removeFieldConstraints(fieldElement);
		}

		updateField(address, { value: formattedValue, violatedConstraint });
	};

	return (
		<>
			<ValidationMessage
				id={validationId}
				css={textFieldValidationMessage}
				message={validationErrorMessage}
			/>
			<ClassNames>
				{({ css, cx, theme }) => (
					<AutosizeTextarea
						ref={textareaRef}
						containerClassName={css(textField({ labelType }))}
						textareaClassName={css(editTextFieldInput(theme, { invalid: hasValidationError }))}
						disabled={readOnly}
						rowsMin={minRows}
						autoCorrect="on"
						spellCheck
						aria-labelledby={labelId}
						placeholder={placeholder}
						value={responseValue}
						onChange={handleInput}
						// aria-describedby is used instead of aria-errormessage because of the unequal support https://soomo.height.app/T-63084#1859e424-d2d1-4b54-99c7-ffc7da2320f1
						aria-invalid={hasConstraintViolated || hasValidationError}
						aria-describedby={cx({
							[constraintId]: hasConstraintViolated,
							[validationId]: hasValidationError
						})}
					/>
				)}
			</ClassNames>
			<ValidationMessage
				id={constraintId}
				css={(theme) => textFieldConstraintMessage(theme, { labelType })}
				message={violatedConstraint?.violationMessage}
			/>
		</>
	);
};

export default EditTextField;
