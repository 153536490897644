import { useCallback, useState } from 'react';

export function useInstructorViewPoolNavigation(): {
	activePoolIndex: number;
	onPrevious: () => void;
	onNext: () => void;
} {
	const [activePoolIndex, setActivePoolIndex] = useState(0);
	const onPrevious = useCallback(() => {
		setActivePoolIndex((old) => old - 1);
	}, []);

	const onNext = useCallback(() => {
		setActivePoolIndex((old) => old + 1);
	}, []);

	return { activePoolIndex, onPrevious, onNext };
}
