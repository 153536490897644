import React from 'react';

import { css, withTheme } from '@emotion/react';

import { breakpoints, Theme, ThemeName } from '~/styles/themes';

const styles = (theme, controlsReversed: boolean, insideModal: boolean) => {
	return css`
		background: inherit;
		padding: ${insideModal ? '20px' : '0px'};
		user-select: none;
		display: flex;
		flex-wrap: wrap;
		flex-direction: ${controlsReversed ? 'row-reverse' : 'row'};

		@media (max-width: ${breakpoints.small}) {
			flex-direction: column;
		}

		.controls-left,
		.controls-right {
			margin: ${insideModal ? '10px 0 10px 0' : '20px 0 0 0'};
		}

		.controls-left {
			flex: 1 0 40%;

			display: flex;
			align-items: center;
			justify-content: flex-start;
			-ms-flex-preferred-size: calc(40% - 10px);
		}

		.controls-right {
			flex: 1 0 60%;

			display: flex;
			align-items: center;
			justify-content: flex-end;
			-ms-flex-preferred-size: calc(60% - 40px);
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			border-radius: 0 0 8px 0;
			padding: ${insideModal ? '16px' : '0px'};
			padding-right: ${insideModal ? '24px' : 'inherit'};

			${insideModal &&
			css`
				.controls-left {
					flex: 1 0 55%;
				}

				.controls-right {
					flex: 1 0 45%;
				}
			`}

			@media (max-width: ${breakpoints.small}) {
				// Left border has 12px padding left
				padding: ${insideModal ? '8px 20px 8px 6px' : '0px'};

				.controls-left {
					margin: 0;
					align-items: flex-start;
					justify-content: ${insideModal ? 'center' : 'flex-start'};
				}

				.controls-right {
					flex: 1 0 100%;

					&:not(:empty) {
						margin: 20px 0 0 0;
					}
				}
			}
		`}
	`;
};

interface Props {
	theme: Theme;
	children: React.ReactNode;
	insideModal?: boolean;
}

const LeftControls = (props) => <div className="controls-left">{props.children}</div>;
const RightControls = (props) => <div className="controls-right">{props.children}</div>;

const PopupQuizControls = (props: Props) => {
	const { theme, children, insideModal } = props;
	const controls = React.Children.toArray(children);
	let reversed = false;

	if ((controls[0] as React.ReactElement).type === RightControls) {
		reversed = true;
	}

	return (
		<div className="popup-quiz-controls" css={styles(theme, reversed, insideModal)}>
			{controls}
		</div>
	);
};

export default withTheme(PopupQuizControls);
export { LeftControls, RightControls };
