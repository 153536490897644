import { serializeAddress } from '~/components/Outline/helpers/address';
import { OutlineInstanceAddress } from '~/components/Outline/types';

export const getOutlineId = (builderFamilyId: string, dest: string): string =>
	`${builderFamilyId}_${dest}_outline`;

export const getInstanceId = (
	builderFamilyId: string,
	dest: string,
	address: OutlineInstanceAddress
): string => `${builderFamilyId}_${dest}_${serializeAddress(address)}`;

export const getInstanceLabelId = (...instanceIdParams: Parameters<typeof getInstanceId>): string =>
	`${getInstanceId(...instanceIdParams)}_label`;

export const getInstanceMenuButtonId = (
	...instanceIdParams: Parameters<typeof getInstanceId>
): string => `${getInstanceId(...instanceIdParams)}_menu_button`;

/**
 * Focuses on the instance's text field
 * Fallbacks to the label element if the text field isn't available
 */
export const focusInstanceInteractiveElement = (
	...instanceIdParams: Parameters<typeof getInstanceId>
): void => {
	const focusableElement =
		getInstanceTextFieldElement(...instanceIdParams) ||
		getInstanceLabelElement(...instanceIdParams);
	focusableElement?.focus();
};

export const focusInstanceMenuButtonElement = (
	...instanceIdParams: Parameters<typeof getInstanceId>
): void => {
	const focusableElement = getInstanceMenuButtonElement(...instanceIdParams);
	focusableElement?.focus();
};

export const focusOutlineElement = (...outlineIdParams: Parameters<typeof getOutlineId>): void => {
	const outlineId = getOutlineId(...outlineIdParams);
	const outlineElement = document.getElementById(outlineId);
	outlineElement?.focus();
};

const getInstanceLabelElement = (
	...instanceIdParams: Parameters<typeof getInstanceId>
): HTMLElement | undefined => {
	const instanceLabelId = getInstanceLabelId(...instanceIdParams);
	return document.getElementById(instanceLabelId);
};

const getInstanceTextFieldElement = (
	...instanceIdParams: Parameters<typeof getInstanceId>
): HTMLElement | undefined => {
	const instanceLabelId = getInstanceLabelId(...instanceIdParams);
	return document.querySelector<HTMLTextAreaElement>(
		`textarea[aria-labelledby='${instanceLabelId}']`
	);
};

const getInstanceMenuButtonElement = (
	...instanceIdParams: Parameters<typeof getInstanceId>
): HTMLElement | undefined => {
	const instanceMenuButtonId = getInstanceMenuButtonId(...instanceIdParams);
	return document.getElementById(instanceMenuButtonId);
};
