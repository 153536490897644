import React, { FC, forwardRef } from 'react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';

import { Global } from '@emotion/react';

import { ChartType } from '~/types';

import { default as BarChart } from './BarChart';
import { default as Legend } from './Legend';
import { default as LineChart } from './LineChart';
import { default as PieChart } from './PieChart';
import { pollLabelTippyStyles } from '../../PollQuestion/styles';

import type { ChartElement } from '~/types/WebtextManifest';

const chartComponents = {
	[ChartType.pie]: PieChart,
	[ChartType.bar]: BarChart,
	[ChartType.line]: LineChart
};

export interface Props {
	chart: ChartElement;
	isHighContrast?: boolean;
}

const Chart = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const { chart, isHighContrast } = props;

	const ChartComponent = chartComponents[chart.chart_type];

	if (!ChartComponent) {
		console.warn(`Unrecognized chart type = '${chart.chart_type}'`);
		return null;
	}

	const seriesLabels = chart.data[0].slice(1);

	return (
		<div ref={ref}>
			<Global styles={pollLabelTippyStyles} />
			{seriesLabels.length > 1 && chart.chart_type !== 'pie' && (
				<Legend chart={chart} isHighContrast={isHighContrast} />
			)}
			<ChartComponent chart={chart} isHighContrast={isHighContrast} />
		</div>
	);
});

Chart.displayName = 'Chart';

export default Chart;
