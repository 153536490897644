import * as React from 'react';

export function CulturalChange(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<g id="fe85d95a-5780-4c17-b016-5862d2ded16d" data-name="comput">
				<circle cx="36" cy="36" r="34.53" fill="#fff" />
				<path
					d="M36,72A36,36,0,1,1,72,36,36,36,0,0,1,36,72ZM36,2.94A33.06,33.06,0,1,0,69.06,36,33.1,33.1,0,0,0,36,2.94Z"
					fill="currentColor"
				/>
				<circle cx="36" cy="36" r="30.53" fill="currentColor" />
				<path
					d="M51.26,19.91a21.76,21.76,0,1,0,6.38,15.41A21.77,21.77,0,0,0,51.26,19.91ZM37.14,27.59a39.38,39.38,0,0,0,6.76-.73,37.16,37.16,0,0,1,.92,7.21H37.14Zm0-2.55V16.3c1.77.59,3.48,2.37,4.83,5.09a22.78,22.78,0,0,1,1.23,3A38.49,38.49,0,0,1,37.14,25ZM34.6,16.3V25a38.49,38.49,0,0,1-6.06-.67,22.78,22.78,0,0,1,1.23-3C31.13,18.67,32.83,16.89,34.6,16.3Zm0,11.29V34H26.92a36.51,36.51,0,0,1,.93-7.21,38.2,38.2,0,0,0,6.75.73ZM24.37,34H16.7a19,19,0,0,1,3.51-9.87,25.92,25.92,0,0,0,5.14,2.06,38.77,38.77,0,0,0-1,7.81Zm0,2.55a38.76,38.76,0,0,0,1,7.81,25.92,25.92,0,0,0-5.14,2.06,19.11,19.11,0,0,1-3.51-9.87Zm2.55,0H34.6V43a40.17,40.17,0,0,0-6.75.73,36.51,36.51,0,0,1-.93-7.21Zm7.68,9V54.3c-1.77-.58-3.47-2.36-4.83-5.07a23.36,23.36,0,0,1-1.23-3A36.27,36.27,0,0,1,34.6,45.57Zm2.54,8.73V45.57a36.27,36.27,0,0,1,6.06.68,23.36,23.36,0,0,1-1.23,3C40.62,51.94,38.91,53.72,37.14,54.3Zm0-11.28V36.58h7.68a37.26,37.26,0,0,1-.92,7.21,40.42,40.42,0,0,0-6.76-.74Zm10.23-6.44h7.68a19.12,19.12,0,0,1-3.52,9.88,25.44,25.44,0,0,0-5.14-2.06,38.85,38.85,0,0,0,1-7.81Zm0-2.55a38.76,38.76,0,0,0-1-7.81,25.92,25.92,0,0,0,5.14-2.06A19,19,0,0,1,55,34Zm2.51-11.88a24,24,0,0,1-4.2,1.62,25,25,0,0,0-1.42-3.53,18.41,18.41,0,0,0-1.9-3,19.32,19.32,0,0,1,7.1,4.52ZM29.38,17.2a17.72,17.72,0,0,0-1.89,3A25.28,25.28,0,0,0,26,23.77a23.89,23.89,0,0,1-4.19-1.62l.42-.43a19.15,19.15,0,0,1,7.09-4.52ZM21.86,48.46a24.3,24.3,0,0,1,4.2-1.62,25.83,25.83,0,0,0,1.46,3.52,17,17,0,0,0,1.9,3,19,19,0,0,1-7.1-4.5l-.42-.44Zm20.5,4.94a16.93,16.93,0,0,0,1.88-3,24.48,24.48,0,0,0,1.43-3.52,24.2,24.2,0,0,1,4.19,1.62l-.41.44a19.1,19.1,0,0,1-7.1,4.5Z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
