import { css } from '@emotion/react';

import { mixins, ThemeName } from '~/styles/themes';

export default (theme) => {
	const { webtextWritingTemplate } = theme;
	const { title, fontFamily } = webtextWritingTemplate || {};
	const marginBottom =
		theme.name === ThemeName.UNIVERSAL_VELVET ? null : 'margin-bottom: 8px !important';
	return css`
		${mixins.elementTitle(theme)}
		${title}
			${marginBottom};
		display: block;
		${fontFamily ? `font-family: ${fontFamily} !important;` : null}
	`;
};
