import { first } from 'lodash-es';

import type { FigureElement } from '~/types/WebtextManifest';

export const isGifImage = (payload: FigureElement['payload']) =>
	payloadToImgElement(payload)?.src?.toLowerCase().endsWith('.gif');

export const payloadToImgElement = (payload: FigureElement['payload']) => {
	if (typeof payload !== 'string') return null;

	const dp = new DOMParser();
	const doc = dp.parseFromString(payload, 'text/html');
	return first(doc.getElementsByTagName('img'));
};
