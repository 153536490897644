import { SerializedStyles, css } from '@emotion/react';
export default (
	top = 0,
	left = 0,
	width = 10,
	height = 10,
	isEditing: boolean,
	isDisplay: boolean
): SerializedStyles => css`
	border: 2px solid #3579f8;
	position: absolute;
	left: ${left - 1}px;
	top: ${top - 1}px;
	width: ${width}px;
	height: ${height}px;
	pointer-events: none;
	z-index: 3;
	box-sizing: border-box;
	transition: all 0.1s ease-in-out;

	${isEditing && 'border: none;'}
	${!isDisplay && 'opacity: 0;'}
`;
