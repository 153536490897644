import React from 'react';

// https://github.com/mediaelement/mediaelement/blob/master/docs/usage.md
import 'mediaelement';
import mediaElementStyles from './MediaElementStyles';

const MediaElementPlayer = (window as any).MediaElementPlayer;

interface Props {
	src: string;
	onPlay: () => void;
	onError?: (error: Error) => void;
	className?: string;
}

interface State {
	player: any;
}

class MediaElementAudioPlayer extends React.Component<Props, State> {
	audioRef: React.RefObject<HTMLAudioElement>;
	snippetTimeout: number;

	constructor(props) {
		super(props);

		this.audioRef = React.createRef();
	}

	componentDidMount() {
		const player = new MediaElementPlayer(this.audioRef.current, {
			success: (media) => {
				media.addEventListener('play', this.props.onPlay);
				media.addEventListener('error', this.props.onError);
			}
		});
		this.setState({ player });
	}

	componentWillUnmount() {
		this.state.player.remove();
		this.setState({ player: null });
	}

	play = () => {
		this.state.player.play();
	};

	playSnippet = (start, end) => {
		if (this.snippetTimeout) {
			window.clearTimeout(this.snippetTimeout);
		}
		this.state.player.setCurrentTime(start);
		this.state.player.play();
		this.snippetTimeout = window.setTimeout(() => {
			this.snippetTimeout = null;
			this.state.player.pause();
		}, end - start);
	};

	pause = () => {
		this.state.player.pause();
	};

	render() {
		const { src, className } = this.props;

		return (
			<div css={mediaElementStyles} className={className}>
				<audio ref={this.audioRef} preload="none" controls style={{ maxWidth: '100%' }}>
					<source src={src} type="audio/mp3" />
				</audio>
			</div>
		);
	}
}

export default MediaElementAudioPlayer;
