import React, {
	MutableRefObject,
	ReactElement,
	ReactNode,
	useLayoutEffect,
	useRef,
	useState
} from 'react';

import { css } from '@emotion/react';

import { mixins, Theme } from '~/styles/themes';
import { Dimensions } from '~/types/utils';

import { PointerPosition } from './ExpandedViewModal';
import useClickAndDrag from './useClickAndDrag';

export interface Props<T extends HTMLElement> {
	scale: number;
	onScaleChange: (scale: number) => void;
	children: (props: { elementRef: MutableRefObject<T | null> }) => ReactNode;
	pointerPosition: PointerPosition;
}

export const ExpandedViewScaler = <T extends HTMLElement>(
	props: Props<T> & { scrollContainerRef: React.RefObject<HTMLDivElement> }
): ReactElement => {
	const { scale, onScaleChange, scrollContainerRef, pointerPosition, children } = props;

	const { x: pointerX, y: pointerY } = pointerPosition;

	const elementRef = useRef<T | null>(null);

	const [compensatorDimensions, setCompensatorDimensions] = useState<Dimensions>({
		width: 0,
		height: 0
	});
	const [isScrollable, setIsScrollable] = useState(false);

	/**
	 * Calculate the initial scale on the first render.
	 * It shouldn't react to the resize of the scroll container or the element.
	 */
	useLayoutEffect(() => {
		const { current: scrollContainer } = scrollContainerRef;
		const { current: element } = elementRef;
		if (!scrollContainer || !element) return;

		const { offsetWidth: initialElementWidth, offsetHeight: initialElementHeight } = element;
		const { clientWidth: scrollContainerWidth, clientHeight: scrollContainerHeight } =
			scrollContainer;

		if (
			initialElementWidth < scrollContainerWidth &&
			initialElementHeight < scrollContainerHeight
		) {
			onScaleChange(1);
		} else {
			const widthRatio = scrollContainerWidth / initialElementWidth;
			const heightRatio = scrollContainerHeight / initialElementHeight;
			onScaleChange(Math.min(widthRatio, heightRatio));
		}

		/**
		 * Set the initial focus on the scroll container
		 */
		scrollContainer.focus();
	}, [onScaleChange, scrollContainerRef]);

	useLayoutEffect(() => {
		const { current: scrollContainer } = scrollContainerRef;
		const { current: element } = elementRef;
		if (!scrollContainer || !element) return;

		const { width: elementWidth, height: elementHeight } = element.getBoundingClientRect();

		/**
		 * Apply the `scale` to the element
		 * and update the compensator dimensions.
		 */
		element.style.transform = `scale(${scale})`;
		element.style.transformOrigin = 'top left';

		const { width: newElementWidth, height: newElementHeight } = element.getBoundingClientRect();
		setCompensatorDimensions({ width: newElementWidth, height: newElementHeight });

		/**
		 * Update the scroll position, so the chart is
		 * centered against the top-left corner of the parent.
		 *
		 * The user's scroll position should be preserved,
		 * and the scale should be applied relatively to it.
		 * `centerWidthPercent`/`centerHeightPercent` is the percentage of
		 * the user's scroll position against the center current element.
		 *
		 * The scrolling should be deferred to the next frame
		 * to allow the scroll container to react to the children's size change and overflow.
		 * Otherwise, the `scrollLeft`/`scrollTop` would be ignored.
		 */
		requestAnimationFrame(() => {
			const {
				scrollLeft,
				scrollTop,
				scrollWidth,
				scrollHeight,
				offsetWidth: scrollContainerWidth,
				offsetHeight: scrollContainerHeight
			} = scrollContainer;

			const scrollContainerCenterX = pointerX || scrollContainerWidth / 2;
			const scrollContainerCenterY = pointerY || scrollContainerHeight / 2;

			if (newElementWidth > scrollContainerWidth) {
				const centerWidthPercent =
					elementWidth < scrollContainerWidth
						? 0.5
						: (scrollLeft + scrollContainerCenterX) / elementWidth;
				scrollContainerRef.current.scrollLeft =
					centerWidthPercent * newElementWidth - scrollContainerCenterX;
			}

			if (newElementHeight > scrollContainerHeight) {
				const centerHeightPercent =
					elementHeight < scrollContainerHeight
						? 0.5
						: (scrollTop + scrollContainerCenterY) / elementHeight;
				scrollContainerRef.current.scrollTop =
					centerHeightPercent * newElementHeight - scrollContainerCenterY;
			}

			setIsScrollable(scrollWidth > scrollContainerWidth || scrollHeight > scrollContainerHeight);
		});
	}, [scale, scrollContainerRef, pointerX, pointerY]);

	const { isDragging } = useClickAndDrag({ scrollContainerRef, isScrollable });

	return (
		<div
			ref={scrollContainerRef}
			css={(theme) =>
				styles.scrollContainer(theme, { dragging: isDragging, scrollable: isScrollable })
			}
			className="scroll-container"
			aria-label="Scrollable View"
			role="main"
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}>
			<div className="element-compensator" style={compensatorDimensions}>
				{children({ elementRef })}
			</div>
		</div>
	);
};

ExpandedViewScaler.displayName = 'ExpandedViewScaler';

const styles = {
	scrollContainer: (theme: Theme, options: { dragging: boolean; scrollable: boolean }) => {
		const { dragging, scrollable } = options;

		return css`
			display: grid;
			place-items: center;

			width: 100%;
			height: 100%;
			overflow: auto;

			/**
			 * Add transparent border to avoid overflow
			 * when scroll container is focused at 100% scale
			 */
			border: 1px solid transparent;

			&:focus {
				${mixins.webtextAccessibleFocused(theme)}
			}

			/**
			 * Allow the browser handle only pan actions.
			 * (as we manage image/chart pinch-zoom ourselves)
			 */
			touch-action: pan-x pan-y;

			${scrollable &&
			css`
				cursor: grab;
				user-select: none;
			`}

			${dragging &&
			css`
				cursor: grabbing;
				user-select: none;
			`}

			/**
			 * Override ionic img { max-width: 100% }, which breaks 
			 * initial image rendering in the expanded modal.
			 */
			img {
				max-width: unset;
			}

			/**
			 * Disabling pointer events on SVG fixes pinch-zoom in line charts by allowing touch events to propagate.
			 */
			svg {
				pointer-events: none;
			}
		`;
	}
};
