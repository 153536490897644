import isNil from 'lodash-es/isNil';
import { toWords } from 'number-to-words';
import { ObjectTyped } from 'object-typed';
import pluralize from 'pluralize';

import { RequirementsStatus, ScoreSettingsConfig } from '~/components/pageElements/GoReact/types';
import { joinWithOxfordComma } from '~/utils/formatting';

import {
	getFailedRequirementsCount,
	getOnlyRubricStatusPresent,
	getRequirementsMet
} from './requirementsStatus';

type CompletionTemplateMessages = {
	[completionOption in keyof ScoreSettingsConfig['completionOptions']]: RequirementsTemplateMessages;
};

type RequirementsTemplateMessages = {
	[requirement in keyof RequirementsStatus]: string | ((required: number) => string);
};

/**
 * @see https://docs.google.com/spreadsheets/d/1tYR7dQ9TgWnq2sLYZ1RLeEKbrBR0sEveJtYyaFJfxmk
 */

const unmetRequirementsTemplateMessages: RequirementsTemplateMessages = {
	rubricSubmission: 'post the feedback rubric',
	commentsNumber: (required) =>
		`leave at least ${toWords(required)} ${pluralize('comment', required)}`,
	markersNumber: (required) =>
		`leave at least ${toWords(required)} ${pluralize('marker', required)}`
};

const metRequirementsTemplateMessages: RequirementsTemplateMessages = {
	rubricSubmission: 'posted the rubric',
	commentsNumber: (required) =>
		`left at least ${toWords(required)} ${pluralize('comment', required)}`,
	markersNumber: (required) => `left at least ${toWords(required)} ${pluralize('marker', required)}`
};

const completionTemplateMessages: {
	met: CompletionTemplateMessages;
	unmet: CompletionTemplateMessages;
} = {
	unmet: {
		recordingMaking: {
			postedRecording: 'click Post after recording your video'
		},
		selfReview: unmetRequirementsTemplateMessages,
		providedReview: unmetRequirementsTemplateMessages,
		nonSelfReview: unmetRequirementsTemplateMessages
	},
	met: {
		recordingMaking: {
			postedRecording: 'posted your video'
		},
		selfReview: metRequirementsTemplateMessages,
		providedReview: metRequirementsTemplateMessages,
		nonSelfReview: metRequirementsTemplateMessages
	}
};

const requirementsMessagesOrder: Array<keyof RequirementsStatus> = [
	'postedRecording',
	'rubricSubmission',
	'commentsNumber',
	'markersNumber'
];

/**
 * Generates the feedback for the user based on their answer and validation results.
 * The messages list is specified in the spreadsheet:
 * @see https://docs.google.com/spreadsheets/d/1tYR7dQ9TgWnq2sLYZ1RLeEKbrBR0sEveJtYyaFJfxmk
 */
export const getFeedbackMessage = (
	scoringConfig: ScoreSettingsConfig,
	requirementsStatus: RequirementsStatus
): string | null =>
	scoringConfig.scoreOptions === 'noPoints'
		? null
		: `${
				getPrefix(requirementsStatus) +
				getRequirementsMessage(scoringConfig, requirementsStatus) +
				getPostfix(scoringConfig, requirementsStatus)
		  }.`;

const getPrefix = (requirementsStatus: RequirementsStatus): string => {
	const failedRequirementsCount = getFailedRequirementsCount(requirementsStatus);
	if (failedRequirementsCount === 0) return 'You’ve ';

	const prefix = 'Remember to';
	return failedRequirementsCount === 1 ? `${prefix} ` : `This needs a little more work. ${prefix} `;
};

const getRequirementsMessage = (
	scoringConfig: ScoreSettingsConfig,
	requirementsStatus: RequirementsStatus
): string => {
	const sortedRequirementsKeys = ObjectTyped.keys(requirementsStatus).sort(
		(a, b) => requirementsMessagesOrder.indexOf(a) - requirementsMessagesOrder.indexOf(b)
	);
	const templateMessages = getRequirementsMet(requirementsStatus)
		? completionTemplateMessages.met
		: completionTemplateMessages.unmet;

	let feedbackMessages = [];
	switch (scoringConfig.scoreOptions) {
		case 'instructorGrade':
			feedbackMessages = getMessagesByTemplates(templateMessages.recordingMaking); // Instructor grade assignments share feedback with `recordingMaking`
			break;
		case 'pointsForCompletion': {
			const { completionOptions } = scoringConfig;
			if (completionOptions?.recordingMaking?.required) {
				feedbackMessages = getMessagesByTemplates(templateMessages.recordingMaking);
			}
			if (completionOptions?.selfReview?.required) {
				feedbackMessages = getMessagesByTemplates(
					templateMessages.selfReview,
					completionOptions.selfReview
				);
			}
			if (completionOptions?.providedReview?.required) {
				feedbackMessages = getMessagesByTemplates(
					templateMessages.providedReview,
					completionOptions.providedReview
				);
			}
			if (completionOptions?.nonSelfReview?.required) {
				feedbackMessages = getMessagesByTemplates(
					templateMessages.nonSelfReview,
					completionOptions.nonSelfReview
				);
			}
		}
	}
	return joinWithOxfordComma(feedbackMessages);

	function getMessagesByTemplates(
		requirementsTemplateMessages: RequirementsTemplateMessages,
		completionOption?:
			| ScoreSettingsConfig['completionOptions']['selfReview']
			| ScoreSettingsConfig['completionOptions']['nonSelfReview']
	) {
		return sortedRequirementsKeys.map((requirement) => {
			const message = requirementsTemplateMessages[requirement];
			if (typeof message === 'string') return message;

			const requiredNumber = completionOption?.[requirement];
			return !isNil(requiredNumber) ? message(requiredNumber) : '';
		});
	}
};

const getPostfix = (
	scoringConfig: ScoreSettingsConfig,
	requirementsStatus: RequirementsStatus
): string => {
	switch (scoringConfig.scoreOptions) {
		case 'instructorGrade':
			return '';
		case 'pointsForCompletion': {
			const { completionOptions } = scoringConfig;
			if (completionOptions?.recordingMaking?.required) {
				return '';
			}
			if (completionOptions?.selfReview?.required) {
				return getSelfReviewPostfix();
			}
			if (completionOptions?.providedReview?.required) {
				return getProvidedReviewPostfix();
			}
			if (completionOptions?.nonSelfReview?.required) {
				return getPeerReviewPostfix(completionOptions.nonSelfReview);
			}
		}
	}

	function getSelfReviewPostfix(): string {
		const preposition = getOnlyRubricStatusPresent(requirementsStatus) ? 'for' : 'on';
		return ` ${preposition} your video`;
	}

	function getProvidedReviewPostfix(): string {
		const preposition = getOnlyRubricStatusPresent(requirementsStatus) ? 'for' : 'on';
		return ` ${preposition} the video`;
	}

	function getPeerReviewPostfix(
		nonSelfReviewConfig: ScoreSettingsConfig['completionOptions']['nonSelfReview']
	): string {
		const { reviewsNumber: reviewedRecordingsRequired } = nonSelfReviewConfig;
		const requirementsMet = getRequirementsMet(requirementsStatus);

		const preposition = getOnlyRubricStatusPresent(requirementsStatus) ? 'for' : 'on';
		return ` ${preposition} ${reviewedRecordingsRequired === 1 ? 'the' : 'each'} ${
			requirementsMet ? '' : 'peer '
		}video you chose to review`;
	}
};
