import first from 'lodash-es/first';

import { isNumeric } from '~/utils/parsing';

import { findMatches, getIsFormula, getIsRange, getRangeAddresses } from '..';
import { staticErrorReturns } from '../constants';
import { CellValues, EvaluatedCells, FormulaResult } from '../types';
import { formulaParser, getInlineFormulaResult } from './engine';

/**
 * Transform util
 */
export const transformArgumentsForInline = (
	formulaArguments: RegExpExecArray[],
	getInstantResult: (value: string) => string | number | FormulaResult
): unknown[] => {
	let invertNextElement = false;

	return formulaArguments.map((argument, idx) => {
		const [element, argumentValue] = argument;

		const isUnary = element.startsWith('-');
		const elementValue = isUnary ? element.slice(1) : element;
		const argumentNumberValue = isUnary ? argumentValue.slice(1) : argumentValue;
		/**
		 * Remove argumentValue to receive symbol(s)
		 */
		const sign = elementValue.replace(argumentNumberValue, '');

		const { sign: updatedSign, invertNext } = transformSign({ sign });

		const isSignAfterValue = !!updatedSign;
		const isNotRepeatSymbols = updatedSign.length === 1;

		/**
		 * This validation still required
		 * if element is the latest, there are should not be any symbols at the end
		 * Otherwise after each element should be a sign an it must be individual
		 */
		const isValidInput =
			formulaArguments.length === idx + 1
				? !isSignAfterValue
				: isSignAfterValue && isNotRepeatSymbols;

		if (!isValidInput) {
			return staticErrorReturns.signatureError;
		}

		const unwrapArgument = getInstantResultInBrackets(argumentNumberValue, getInstantResult);

		if ((unwrapArgument as FormulaResult).success === false) {
			return unwrapArgument;
		}
		/**
		 * When both unary and invert true/false not add anything
		 * When oneOf true return '-' sign
		 */
		const unarySign = isUnary && invertNextElement ? '' : isUnary || invertNextElement ? '-' : '';
		invertNextElement = invertNext;

		return [`${unarySign}${unwrapArgument}`, updatedSign];
	});
};

const transformSign = (props: { sign: string; invertNext?: boolean }) => {
	const { sign, invertNext } = props;

	if (!sign)
		return {
			invertNext: invertNext ?? false,
			sign: ''
		};

	/**
	 * Transform +- to - recursively
	 */
	const plusMinusRegex = /(\+-)+/g;
	if (plusMinusRegex.test(sign)) {
		const simpleSign = sign.replace(plusMinusRegex, '-');

		if (sign.length === 1) {
			return {
				invertNext: invertNext ?? false,
				sign: simpleSign
			};
		}

		return transformSign({ sign: simpleSign, invertNext: invertNext ?? false });
	}

	/**
	 * Transform -+ to - recursively
	 */
	const minusPlusRegex = /(-\+)+/g;
	if (minusPlusRegex.test(sign)) {
		const simpleSign = sign.replace(minusPlusRegex, '-');
		if (sign.length === 1) {
			return {
				invertNext: invertNext ?? false,
				sign: simpleSign
			};
		}

		return transformSign({ sign: simpleSign, invertNext: invertNext ?? false });
	}

	/**
	 * Transform +{2, } recursively
	 */
	const multiplePlusRegex = /(\+){2,}/g;
	if (multiplePlusRegex.test(sign)) {
		const simpleSign = sign.replace(multiplePlusRegex, '+');
		if (sign.length === 1) {
			return {
				invertNext: invertNext ?? false,
				sign: simpleSign
			};
		}

		return transformSign({ sign: simpleSign, invertNext: invertNext ?? false });
	}

	/**
	 * Transform -{2,} recursively
	 */
	const multipleMinusRegex = /(-){2,}/g;
	if (multipleMinusRegex.test(sign)) {
		const simpleSign = sign.replace(multipleMinusRegex, (match) =>
			match.length && match.length % 2 === 0 ? '+' : '-'
		);

		if (sign.length === 1) {
			return {
				invertNext: invertNext ?? false,
				sign: simpleSign
			};
		}

		return transformSign({ sign: simpleSign, invertNext: invertNext ?? false });
	}
	/**
	 * Transform *- to * recursively and invert next item sign
	 */
	if (sign.endsWith('*-'))
		return {
			invertNext: true,
			sign: '*'
		};
	/**
	 * Transform /- to / recursively and invert next item sign
	 */
	if (sign.endsWith('/-')) {
		return {
			invertNext: true,
			sign: '*'
		};
	}

	/**
	 * Transform *- to * recursively and not invert next item sign
	 */
	if (sign.endsWith('*+'))
		return {
			invertNext: false,
			sign: '*'
		};

	/**
	 * Transform /- to / recursively and not invert next item sign
	 */
	if (sign.endsWith('/+')) {
		return {
			invertNext: false,
			sign: '*'
		};
	}

	/**
	 * We're unable to handle this return as is
	 */

	return {
		invertNext: false,
		sign
	};
};

/**
 * Calculation utils
 */
export const calculateInlineFormula = (
	inlineArguments: string[][],
	cellValues: CellValues,
	evaluated: EvaluatedCells,
	isAllowRecursion: boolean
): unknown => {
	/**
	 * Transform any arguments to the most molecular type
	 */
	const argumentValues = inlineArguments.map(([argument, sign]) => {
		const value = getArgumentValue({
			input: argument,
			cellValues,
			evaluatedCells: evaluated,
			isArgument: true,
			isAllowRecursion
		});

		if ((value as FormulaResult).success !== false) {
			return [value, sign];
		}

		return value;
	});

	if (argumentValues.some(getUnsuccessfulValue)) {
		return first(argumentValues.filter(getUnsuccessfulValue)) as FormulaResult;
	}


	/**
	 * Based on the "context" only values like string or number are able to get to this point, so we can safely make this filter
	 */
	const filteredValues = argumentValues.filter(
    (value): value is [string | number, string] => Array.isArray(value)
	);

	const { result: firstResult, error: firstError } = executeFirstOrderCalculations(
		filteredValues
	);

	if (firstError) return firstError;

	const { result: finalResult, error } = executeSecondOrderCalculations(firstResult);

	if (error) return error;
	return finalResult;
};

/**
 * Calculate first order calculations "*" and "/"
 */
const executeFirstOrderCalculations = (argumentsList: [string | number, string][]) => {
	if (!argumentsList.some(([_, sign]) => sign === '*' || sign === '/'))
		return { result: argumentsList };

	const result: [string | number, string][] = [];
	
	let currentValue = +argumentsList[0][0]; 
	let currentOperator = argumentsList[0][1]; 

	for (let i = 1; i < argumentsList.length; i++) {
		const [nextValue, nextOperator] = argumentsList[i];

		if (currentOperator === '/' && +nextValue === 0) {
			return { result: [], error: staticErrorReturns.div0Error };
		}

		if (currentOperator === '*' || currentOperator === '/') {
			currentValue = currentOperator === '*' ? currentValue * +nextValue : currentValue / +nextValue;
		} else {
			result.push([currentValue, currentOperator]);
			currentValue = +nextValue;
		}

		currentOperator = nextOperator;
	}

	result.push([currentValue, '']);

	return { result };
};

const executeSecondOrderCalculations = (argumentsList: [string | number, string][]) => {
	const errors = new Set<FormulaResult>();
	
	const result = argumentsList.reduce<[number, string]>((acc, [nextValue, nextSign], index) => {
		if (errors.size) return acc;

		if (isInfinite(nextValue)) {
			errors.add(staticErrorReturns.numberError);
			return acc;
		}

		/**
		 * After every value should be a sign, except the last one
		 */
		if (!['+', '-'].includes(nextSign) && argumentsList.length !== index + 1) {
			errors.add(staticErrorReturns.signatureError);
			return acc;
		}

		if (!acc.length) {
			return [+nextValue, nextSign];
		}

		const [currentValue, currentSign] = acc;

		if (isInfinite(currentValue)) {
			errors.add(staticErrorReturns.numberError);
			return acc;
		}

		return currentSign === '+'
			? [currentValue + +nextValue, nextSign]
			: [currentValue - +nextValue, nextSign];
	}, [0, '+']);

	const [resultValue] = result;

	return { result: resultValue, error: errors.values().next().value };
};

export const normalizeInlineValue = (value: string): string => {
	/**
	 * This method used for replacements inside inline input and tried to catch all possible invalid, that not ruin the formula
	 */
	return value.replace(/^=/, '').replace(/ /g, '');
};

export const getArgumentValue = (props: {
	input: string;
	cellValues: CellValues;
	evaluatedCells: EvaluatedCells;
	isArgument?: boolean;
	isAllowRecursion: boolean;
	blockRecall?: boolean;
}): number | string | FormulaResult => {
	const { input, cellValues, evaluatedCells, isArgument, isAllowRecursion, blockRecall } = props;

	/**
	 * Element is a number return as is, unary minus handled by JS
	 */
	if (isNumeric(input)) {
		return +input;
	}

	/**
	 * When number is unary, trim the `-`
	 */
	const isUnary = input.startsWith('-');
	const positiveArgument = isUnary ? input.slice(1) : input;
	/**
	 * When another cell has a formula, it stored inside evaluatedCells object, we just taking this value
	 * We also need to remove $ sign from absolute addresses B$1 for example
	 */
	const evaluatedResult = evaluatedCells?.[removeDollarSign(positiveArgument.toUpperCase())];
	if (evaluatedResult) {
		/**
		 * When it has an error return it as is
		 */
		if (!evaluatedResult.success) return evaluatedResult;
		/**
		 * Otherwise return result of this formula
		 */
		const { result } = evaluatedResult;

		if (isNumeric(result)) {
			return isUnary ? +result * -1 : result;
		}

		return isUnary ? `-${result}` : result;
	}

	/**
	 * If value is a reference and it's not a formula return it's value
	 * We also need to remove $ sign from absolute addresses B$1 for example
	 */
	const cellReferenceResult = cellValues?.[removeDollarSign(positiveArgument.toUpperCase())];
	/**
	 * Empty string is a valid result
	 */
	if (cellReferenceResult != null) {
		/**
		 * Guard statement for cases when cell equal it's address;
		 * Generally we can have:
		 * 1. Formula in a cell, if yes this function will never reach to this code and will be handled in evaluatedResult
		 * 2. It can include it's own or any other cell address and might be considered as a reference, but it's wrong, because we called it just to get a real value w/o any calculations
		 * For the case #2 we have this statement; blockRecall props passed as a param and when it exist we do not allow recall function with value that seems to be a reference, but it just a string with cell address
		 * This case is quite specific, but still possible, so we should handle it.
		 */
		if (blockRecall) return input;
		/**
		 * Transform empty string to 0
		 */
		if (!cellReferenceResult) return 0;

		if (isNumeric(cellReferenceResult)) {
			/**
			 * When stored value is a numeric, return number and add unary only for numbers !== 0
			 */
			const realResult = +cellReferenceResult;
			return isUnary && realResult !== 0 ? -1 * realResult : realResult;
		}

		/**
		 * Cell might contain some specific value, but we need a real value
		 */
		const formattedResult = getArgumentValue({
			input: cellReferenceResult,
			cellValues,
			evaluatedCells,
			isAllowRecursion,
			blockRecall: true
		});

		return isUnary ? `-${formattedResult}` : formattedResult;
	}

	/**
	 * Remove percentages sign and get a number
	 */
	if (positiveArgument.endsWith('%')) {
		const nonPercent = positiveArgument.replace(/%/g, '');

		if (isNumeric(nonPercent)) {
			return parseFloat(nonPercent) / 100;
		}
	}

	const isInlineFormula = getIsInlineFormula(isArgument, positiveArgument);

	/**
	 * When signature for inline formula correct, return calculated formula
	 */
	if (isInlineFormula)
		return getInlineFormulaResult({
			inputValue: input,
			cellValues,
			evaluated: evaluatedCells,
			isAllowRecursion
		});

	/**
	 * This formula will be evaluated only for cells with `evaluateFormula: true`
	 * Because they don't know and don't have any evaluated values during runtime
	 */
	if (isAllowRecursion && getIsFormula(input)) {
		const cellElement = Object.entries(cellValues).find(([_, value]) => value === input);

		if (cellElement?.length) {
			const [address] = cellElement;

			const result = formulaParser({
				inputValue: input,
				cellValues,
				address,
				isAllowRecursion
			});

			return result.success ? result.result : result;
		}
	}

	/**
	 * Ranges are specific and returned as is
	 */
	if (getIsRange(positiveArgument)) {
		return positiveArgument;
	}

	return input;
};

export const getIsWithUnexpectedCharacters = (input: string): boolean =>
	input.includes('$') || (input.includes(',') && isNumeric(input.replace(/,/g, '')));

const removeDollarSign = (argument: string) => argument.replace(new RegExp(`\\$`, 'g'), '');

const getIsInlineFormula = (isArgument: boolean, value: string): boolean =>
	isArgument && ['+', '-', '*', '/'].some((operand) => value.includes(operand));

export const checkIsAddress = (formulaArgument: string): boolean => {
	/**
	 * Testing input, for address(ish) possible value.
	 */
	const testAddressRegex = /^[A-Z]{1,2}[0-9]{1,2}$/i;
	return testAddressRegex.test(formulaArgument);
};

const getInstantResultInBrackets = (
	value: string,
	getInstantResult: (value) => number | string | FormulaResult
) => {
	/**
	 * When we have value in brackets we have to calc it first, because it may be inconsistent on the next steps
	 */

	const simplifyBracketsRegex = /\([^()]*\)/gi;

	/**
	 * When multiple inclusions with brackets it's better calculate them separately and then return simplified function
	 * In this case all inline formulas with multiple brackets will be simplified step by step from the simplifies to the most difficult
	 * example =(1+1)*2*(2*(2+2))
	 * step 1: =2*2*(2*4)
	 * step 2: =2*2*8
	 * step 3: =32
	 */
	if (findMatches(simplifyBracketsRegex, value).length > 1) {
		let tempError = null;

		const calculatedInlineResult = value.replace(simplifyBracketsRegex, (match) => {
			const result = getInstantResult(match.slice(1, -1));

			if ((result as FormulaResult)?.error) {
				tempError = result;
				return '';
			}

			return `${result}`;
		});

		if (tempError) return tempError;

		return calculatedInlineResult;
	}

	if (value.startsWith('(') && value.endsWith(')')) {
		return getInstantResult(value.slice(1, -1));
	}

	return value;
};

export const getUnsuccessfulValue = (argument: unknown): boolean =>
	(argument as FormulaResult)?.success === false;

/**
 * We need this one because infinity is a number error and not value error as undefined or null or NaN
 */
export const isInfinite = (value: string | number): boolean => String(+value).includes('Infinity');

/**
 * Simplified version of arguments getter; specified for named functions only;
 * We're digging params for the most simplest version of function and we no longer need anything but that
 */
export const getArgumentsArray = (argsList: string): string[] =>
	argsList.split(/;|,/).map((arg) => arg.trim());

export const transformRangeIntoArrayOfValues = (props: {
	argument: string;
	cellValues: CellValues;
	evaluated: EvaluatedCells;
	disableNumbering?: boolean;
	isAllowRecursion: boolean;
}): unknown => {
	const { argument, cellValues, evaluated, disableNumbering, isAllowRecursion } = props;

	/**
	 * Normalize argument, to avoid cases where b5:b7 creates a range with non-readable content
	 */
	const normalizedArgument = argument.toUpperCase().replace(/\$/g, '');
	const rangeAddresses = getRangeAddresses(normalizedArgument);

	if (!rangeAddresses.result) {
		return staticErrorReturns.nameError;
	}

	/**
	 * Getting values from addresses
	 */
	const values = rangeAddresses.value.map((address) =>
		getArgumentValue({ input: address, cellValues, evaluatedCells: evaluated, isAllowRecursion })
	);

	/**
	 * Return reference Error
	 */
	if (values.some(getUnsuccessfulValue)) {
		return first(values.filter(getUnsuccessfulValue)) as FormulaResult;
	}

	/**
	 * Transform any strings to 0 or return as is
	 */
	return values.map((value) =>
		typeof value === 'number' ? value : disableNumbering ? value : 0
	) as (string | number)[];
};

export const normalizeElements = (formulaArguments: unknown[]): number[] => {
	const argumentsList = formulaArguments.map((argument) => +argument);

	if (formulaArguments.length === 3) return argumentsList.concat([0, 0]);

	if (formulaArguments.length === 4) return argumentsList.concat([0]);

	return argumentsList;
};

export const checkFormula = (value: string): RegExpExecArray[] => {
	const formulaValues = findMatches(/([A-Z]+)(\()([^).]+)?(\)?)/gi, value);
	return formulaValues;
};
