import React from 'react';

import { css, jsx } from '@emotion/react';
import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { breakpoints } from '~/styles/themes';

import { GutterIconKey, GutterIconKeys, gutterIconMap } from '../icons';

const sizeScalingIcons: GutterIconKey[] = [
	'agility',
	'communication',
	'initiative',
	'innovation',
	'problem-solving',
	'productivity',
	'relationship-building',
	'results-driven',
	'self-and-social-awareness',
	'stop-sign',
	'technology'
];

const borderlessH3GutterIcons: GutterIconKey[] = [
	'map-marker',
	'heart',
	'briefcase',
	'graduation-cap',
	'star',
	'newspaper',
	'shopping-cart',
	'heartbeat',
	'checkbox-in-circle'
];

type GutterIconStylesArgs = { fontSize: string; borderRadius?: string };

const GutterIconStyles =
	({ fontSize, borderRadius }: GutterIconStylesArgs) =>
	(theme) => css`
		display: flex;
		align-items: center;
		align-content: start;

		&.webtext-callout-heading {
			padding-left: 46px;
			line-height: 40px;

			svg {
				font-size: ${fontSize} !important;
			}
		}

		&.webtext-callout {
			align-items: start;
		}

		svg {
			color: ${theme.global.iconColor};
			fill: currentColor;
			position: absolute;
			left: -70px;
			height: 60px;
			width: 60px;
			border-radius: ${borderRadius ? borderRadius : '100%'};
		}

		@media (max-width: ${breakpoints.large}) {
			display: grid;
			padding: 0;
			grid-template-columns: auto 1fr;
			column-gap: 10px;

			&.webtext-callout-heading {
				padding-left: 0;
			}

			svg {
				position: relative;
				height: 38px;
				width: 38px;
				left: 0px;
			}
		}

		@media (min-width: ${breakpoints.large}) {
			&:is(h1.webtext-callout-gutter) {
				${sizeScalingIcons.map((className) => `&.${className}`).join(',')} {
					svg {
						width: 70px;
						height: 70px;
						left: -80px;
					}
				}
			}

			&:is(h3.webtext-callout-gutter) {
				${sizeScalingIcons.map((className) => `&.${className}`).join(',')} {
					svg {
						width: 50px;
						height: 50px;
					}
				}

				// Alignment fixes for just these icons at h3 on desktop
				${borderlessH3GutterIcons.map((className) => `&.${className}`).join(',')} {
					svg {
						width: 45px;
						height: 45px;
						left: -62px;
					}
				}
			}
		}
	`;

export const getGutterIconKey = (classList: string): GutterIconKey => {
	const classes = classList.split(' ');
	for (const c of classes) {
		for (const k of GutterIconKeys) {
			if (c === k) {
				return k as GutterIconKey;
			}
		}
	}
};

type WithGutterIconArgs = {
	element: Element;
	content?: string | JSX.Element | JSX.Element[];
	iconKey?: GutterIconKey;
	iconStylesOverrides?: Partial<GutterIconStylesArgs>;
	key: string;
};

export const withGutterIcon = ({
	element,
	content,
	iconKey,
	iconStylesOverrides,
	key
}: WithGutterIconArgs): JSX.Element => {
	const children = [
		React.cloneElement(gutterIconMap[getGutterIconKey(iconKey || element.attribs.class)], {
			key
		}),
		<div key="content" className="gutter-icon-wrapper">
			{content || domToReact(element.children)}
		</div>
	];

	return jsx(element.tagName, {
		className: element.attribs.class,
		css: GutterIconStyles({
			fontSize: '50px',
			...iconStylesOverrides
		}),
		children
	});
};

export const withGutterIconHeader = (args: {
	element: Element;
	content?: string | JSX.Element | JSX.Element[];
	key: string;
	iconStylesOverrides?: Partial<GutterIconStylesArgs>;
}): JSX.Element => {
	const { element, content, key, iconStylesOverrides } = args;
	const gutterIcon = gutterIconMap[getGutterIconKey(element.attribs.class)];
	let children;
	if (!gutterIcon) {
		children = [content || domToReact(element.children)];
	} else {
		children = [
			React.cloneElement(gutterIcon, {
				key
			}),
			content || domToReact(element.children)
		];
	}

	/**
	 * `jsx` acts as an alternative to `React.createElement` that is provided by
	 * @emotion. This allows us to pass the `css` prop as we would to a standard
	 * React component.
	 *
	 * https://emotion.sh/docs/css-prop#import-the-jsx-function-from-emotionreact
	 */
	return jsx(
		element.tagName,
		{
			className: element.attribs.class,
			css: GutterIconStyles({ fontSize: '50px', ...iconStylesOverrides })
		},
		children
	);
};
