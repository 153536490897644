import React, { useCallback, useMemo, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';

import { css, SerializedStyles } from '@emotion/react';
import { some } from 'lodash-es';

import Modal from '~/components/Modal';
import WebtextButton from '~/components/WebtextButton';
import WebtextCheckbox from '~/components/WebtextCheckbox';
import { Theme, ThemeName } from '~/styles/themes';
import { generateID } from '~/utils';

import { paragraphStyles } from './styles';

import type { InterventionSurveyData } from '../types';

const HELP_CENTER_ARTICLE_URL =
	'https://help.soomolearning.com/en/articles/9884110-how-will-soomo-learning-use-my-feedback';

const presetOptions = [
	'I’m trying to get points before the due date.',
	'I’m having trouble understanding the information; it’s too hard.',
	'I’ve already learned this information; I don’t want to think about it again.',
	'I don’t need to know this material.'
] as const;

interface Props {
	open: boolean;
	onSubmit: (data: InterventionSurveyData) => void;
}

const ThirdInterventionModal: React.VFC<Props> = ({ open, onSubmit }) => {
	const [presetCheckedArr, setPresetCheckedArr] = useState(Array(presetOptions.length).fill(false));
	const [isOtherChecked, setOtherChecked] = useState(false);
	const [otherReason, setOtherReason] = useState('');
	const id = useMemo(() => generateID(), []);

	const canSubmit = useMemo(
		() => some(presetCheckedArr) || (isOtherChecked && otherReason.length > 0),
		[presetCheckedArr, isOtherChecked, otherReason]
	);

	const handleSubmitClick = useCallback(() => {
		const data: InterventionSurveyData = {};
		presetCheckedArr.forEach((checked, i) => {
			if (checked) {
				data[`option${i + 1}`] = {
					body: presetOptions[i],
					value: true
				};
			}
		});
		if (isOtherChecked && otherReason) {
			data.other = {
				body: 'Other:',
				value: otherReason
			};
		}
		onSubmit(data);
	}, [isOtherChecked, onSubmit, otherReason, presetCheckedArr]);

	const handlePresetCheckedChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setPresetCheckedArr((prev) => {
			const index = parseInt(e.target.dataset.index, 10);
			const newPresetCheckedArr = [...prev];
			newPresetCheckedArr[index] = e.target.checked;
			return newPresetCheckedArr;
		});
	}, []);

	const handleOtherCheckedChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setOtherChecked(e.target.checked);
	}, []);

	const handleOtherReasonChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setOtherReason(e.target.value);
	}, []);

	return (
		<Modal
			open={open}
			className="speedbump-intervention-3-modal"
			title="How’s your learning going?"
			actions={[
				<WebtextButton key={0} onClick={handleSubmitClick} disabled={!canSubmit}>
					Send Feedback
				</WebtextButton>
			]}>
			<p css={paragraphStyles}>
				<strong>Tip:</strong> Sometimes reflecting on your learning process can help you find new
				ways to think about the content.{' '}
				<span id={`${id}-label-1`}>
					What’s influencing the way you are answering questions right now?
				</span>
			</p>
			<p css={paragraphStyles} id={`${id}-label-2`}>
				Select all that apply:
			</p>
			<div css={formStyles} role="group" aria-labelledby={`${id}-label-1 ${id}-label-2`}>
				{presetOptions.map((reason, i) => (
					<WebtextCheckbox
						key={i}
						data-index={i}
						onChange={handlePresetCheckedChange}
						checked={presetCheckedArr[i]}
						css={checkboxStyles}>
						{reason}
					</WebtextCheckbox>
				))}
				<div css={otherReasonContainerStyles}>
					<WebtextCheckbox
						css={checkboxStyles}
						onChange={handleOtherCheckedChange}
						checked={isOtherChecked}>
						Other:
					</WebtextCheckbox>
					<input
						disabled={!isOtherChecked}
						onChange={handleOtherReasonChange}
						value={otherReason}
						aria-label="Other reason"
						css={otherReasonInputStyles}
					/>
				</div>
			</div>
			<p css={paragraphStyles}>
				<a href={HELP_CENTER_ARTICLE_URL} target="_blank" rel="noreferrer">
					How we use your feedback
					<FiExternalLink aria-label="(opens in a new tab)" css={iconStyles} />
				</a>
			</p>
		</Modal>
	);
};

export default ThirdInterventionModal;

const formStyles = (theme: Theme): SerializedStyles => css`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	${theme.name !== ThemeName.UNIVERSAL_VELVET &&
	css`
		margin-top: 26px;
	`}

	label {
		display: inline-flex;
		align-items: center;
		font-size: 18px;
		column-gap: 10px;
		line-height: 1;
	}
`;

const checkboxStyles = css`
	width: 20px;
	height: 20px;
	margin-right: 0 !important;
	flex-shrink: 0;
`;

const otherReasonContainerStyles = css`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
`;

const otherReasonInputStyles = css`
	height: 42px;
	flex-grow: 1;
	border: 1px solid #979797;
	border-radius: 2px;
	font: inherit;
	font-size: 18px;
`;

const iconStyles = css`
	margin-left: 2px;
	vertical-align: -2px;
`;
