import * as React from 'react';

export function Star(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="M53.42 31.82a2 2 0 0 0-1.58-1.29l-10.08-.87-4-9.36a1.88 1.88 0 0 0-3.46 0l-4 9.36-10.08.87a1.67 1.67 0 0 0-1.58 1.29 1.86 1.86 0 0 0 .57 2l7.63 6.77-2.3 9.93a1.89 1.89 0 0 0 .72 1.88 1.58 1.58 0 0 0 1.15.28 2.74 2.74 0 0 0 1-.28l8.78-5.19 8.79 5.19a1.74 1.74 0 0 0 2-.15 1.85 1.85 0 0 0 .72-1.87l-2.3-9.94L53 33.7a1.69 1.69 0 0 0 .42-1.88Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
