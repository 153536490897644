import React, { ReactNode } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { css, ClassNames } from '@emotion/react';
import { darken } from 'polished';

import { WebtextButton } from '~/components';
import { useToggle } from '~/hooks';
import { Theme, breakpoints, getThemeItem } from '~/styles/themes';

interface Props {
	title: string;
	sideLabel?: ReactNode;
	collapseDelimiter?: boolean;
	className?: string;
}

const ExpandablePanel: React.FC<Props> = (props) => {
	const { title, sideLabel, collapseDelimiter = true, className, children } = props;

	const [panelOpen, togglePanelOpen] = useToggle(false);

	return (
		<ClassNames>
			{({ cx }) => (
				<div
					css={(theme) =>
						expandablePanelStyles.self(theme, {
							open: panelOpen,
							hasContentUnder: !collapseDelimiter
						})
					}
					className={cx(className, 'expandable-panel')}>
					<div css={expandablePanelStyles.header} className="expandable-panel-header">
						<WebtextButton
							css={expandablePanelStyles.toggle}
							className="expandable-panel-toggle"
							variant="text"
							onClick={togglePanelOpen}>
							{title}
							<span css={expandablePanelStyles.toggleArrow}>
								{panelOpen ? <FaChevronUp /> : <FaChevronDown />}
							</span>
						</WebtextButton>
						{sideLabel && (
							<div css={expandablePanelStyles.sideLabel} className="expandable-panel-side-label">
								{sideLabel}
							</div>
						)}
					</div>
					{panelOpen && !!children && (
						<div css={expandablePanelStyles.container} className="expandable-panel-container">
							<div css={expandablePanelStyles.content} className="expandable-panel-content">
								{children}
							</div>
						</div>
					)}
				</div>
			)}
		</ClassNames>
	);
};

export const expandablePanelStyles = {
	self: (theme: Theme, { open, hasContentUnder }) => css`
		--timestamp-width: 130px;

		font-family: ${getThemeItem(theme.fonts['helvetica-neue-default'], theme)};

		${open &&
		css`
			/*
			* Makes the questions delimiter displayed right after the last question
			* w/o an excessive margin.
			* @example w/o this rule: https://ibb.co/SvyjzZV
			* @example w/ this rule: https://ibb.co/zxkv6KL
			*/
			margin-bottom: -21px;

			.expandable-panel-header {
				margin-bottom: 20px;
			}
		`}

		${hasContentUnder &&
		css`
			margin-bottom: 20px;
		`}
	`,
	header: css`
		margin-top: 20px;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		display: flex;
		align-items: center;
	`,
	toggle: (theme: Theme) => css`
		padding: 0;
		color: ${theme.colors.lavender};
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		text-transform: inherit;
		text-decoration: none;

		&:hover {
			color: ${darken(0.1, theme.colors.lavender)};
		}

		&:focus {
			border-color: transparent !important;
		}

		@media (max-width: ${breakpoints.small}) {
			width: auto;
			margin: 0;
		}
	`,
	toggleArrow: css`
		position: relative;
		top: 2px;
		padding-left: 5px;
	`,
	sideLabel: (theme: Theme) => css`
		margin-left: 10px;
		color: ${theme.colors['evil-gray']};
	`,
	container: css`
		margin-left: -20px;
		padding-left: 20px;
		margin-right: -20px;
		padding-right: 20px;
		background-color: #f5f7ff;
		border-bottom: 1px solid lightgrey;
		border-top: 1px solid lightgrey;
	`,
	content: css`
		max-width: 700px;
	`,
	historyItem: css`
		&:last-of-type {
			margin-bottom: 24px;
		}
	`,
	historyItemBody: (
		theme: Theme,
		options: { variant: 'answer' | 'comment' | 'default' } = { variant: 'default' }
	) => css`
		margin: 0.5em 0 20px var(--timestamp-width);
		font-size: 14px;

		${options.variant !== 'default' &&
		css`
			padding-left: 15px;
			border-left: 4px solid ${theme.colors[options.variant === 'answer' ? 'blue' : 'pleasant-red']};

			p {
				margin-block-start: 0.5em;
			}
		`}
	`
};

export default ExpandablePanel;
