import { css } from '@emotion/react';

import { colors, fonts, breakpoints } from '~/styles/themes';

export default (theme) => {
	const { borderColor, authorColor, quoteTextFont, quoteAuthorFont, quoteSourceFont } =
		theme.bigQuoteEpigraph;
	return css`
		.big-quote-epigraph {
			padding: 40px;
			@media (max-width: ${breakpoints.small}) {
				padding: 40px 4px;
			}

			.backdrop {
				border-style: solid;
				border-width: 4px;
				border-color: ${borderColor};
				@media (max-width: ${breakpoints.small}) {
					border-width: 2px;
				}
			}
			.quoteCard {
				box-shadow: 14px 14px 10px rgba(0, 0, 0, 0.1);
				position: relative;
				left: -20px;
				top: -20px;
				background-color: ${colors.white};
				@media (max-width: ${breakpoints.small}) {
					box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.1);
					left: -10px;
					top: -10px;
				}

				.icon {
					color: ${borderColor};
					font-size: 150px;
					font-weight: bold;
					line-height: 0;
					padding-left: 40px;
					padding-top: 40px;
					font-family: ${fonts['helvetica-neue-bold']};
					@media (max-width: ${breakpoints.small}) {
						padding-left: 4px;
					}
				}
				.text {
					padding: 0 40px 40px 40px;
					font-family: ${quoteTextFont};
					font-size: 31px;
					font-weight: bold;
					font-style: italic;
					color: ${colors.black};
					letter-spacing: -0.64px;
					line-height: 41px;
					em {
						color: inherit;
						font-style: normal;
					}
					@media (max-width: ${breakpoints.small}) {
						font-size: 24px;
						line-height: 28px;
						padding: 0 4px 20px 4px;
					}
				}
				.source {
					background-color: ${colors['dark-white']};
					padding: 15px 40px;

					.author {
						font-family: ${quoteAuthorFont};
						font-size: 16;
						font-weight: 600;
						text-transform: uppercase;
						color: ${authorColor};
						letter-spacing: 2.78px;
						line-height: 27px;
						@media (max-width: ${breakpoints.small}) {
							font-size: 14px;
							line-height: 20px;
						}
					}

					.citation {
						font-family: ${quoteSourceFont};
						font-size: 14px;
						font-weight: normal;
						color: ${colors['black']};
						letter-spacing: 0;
						line-height: 27px;
						@media (max-width: ${breakpoints.small}) {
							font-size: 12px;
							line-height: 18px;
						}
					}
				}
			}
		}
	`;
};
