import React, { FC, useMemo, useState } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createNodeSelection, $setSelection } from 'lexical';

import { CitationExport, CitationResponse, CitationsConfig } from '../../../types';
import CitationMetaContext from '../context/CitationMetaContext';
import { generateCitationId } from '../helpers/citations.helper';
import { INSERT_CITATION_COMMAND } from '../plugins/';

interface Props {
	citationsConfig: CitationsConfig;
	storedCitations: CitationResponse[];
	withCitations: boolean;
}

const CitationMetaProvider: FC<Props> = (props) => {
	const { citationsConfig, storedCitations, withCitations, children } = props;

	const citationStyle = useMemo(
		() => (withCitations ? citationsConfig?.style : null),
		[citationsConfig, withCitations]
	);
	const availableCitations = useMemo(
		() => (withCitations ? citationsConfig?.citations : null),
		[withCitations, citationsConfig]
	);

	const [editor] = useLexicalComposerContext();

	const [inEditorCitations, setInEditorCitations] = useState(storedCitations);
	const [showCitationDialog, setShowDialog] = useState(false);
	const [editingCitation, setEditingCitation] = useState<CitationResponse>(null);

	const openCreateDialog = () => setShowDialog(true);

	const updateCitationsMarkers = (props: { [identifier: string]: string }) =>
		setInEditorCitations((citations) =>
			citations.map(({ identifier, ...rest }) => ({
				identifier,
				...rest,
				markerValue: props[identifier]
			}))
		);

	const contextValue = useMemo(() => {
		const closeDialog = () => {
			setShowDialog(false);
			setEditingCitation(null);
			/**
			 * Throw focus back; required if we want not to lose selection on citation
			 */
			editor.focus();
		};

		const openEditDialog = (id: string) => {
			setShowDialog(true);
			const citation = inEditorCitations.find(({ identifier }) => identifier === id);
			setEditingCitation(citation);
		};

		const addCitation = (citation: CitationExport) => {
			/**
			 * Set citation in a state
			 */
			const identifier = generateCitationId();
			setInEditorCitations((citations) => [...citations, { identifier, ...citation }]);
			/**
			 * Render citation in lexical
			 */
			const { attributes, markerValue, key } = citation;
			editor.dispatchCommand(INSERT_CITATION_COMMAND, {
				decorationText: markerValue,
				identifier,
				key,
				attributes
			});
			/**
			 * Throw focus back
			 */
			editor.focus();
			/**
			 * Close modal after create;
			 */
			setShowDialog(false);
		};

		const addCitationDataOnly = (
			citation: CitationResponse,
			nodeKey: string,
			ignoreSelection?: boolean
		) => {
			setInEditorCitations((citations) => [...citations, citation]);

			if (ignoreSelection) return;

			editor.update(() => {
				/**
				 * Throw selection on newly created node
				 */
				const selection = $createNodeSelection();
				selection.add(nodeKey);
				$setSelection(selection);
			});
		};

		const updateCitation = (citation: CitationResponse) => {
			const { identifier } = citation;
			/**
			 * Find index
			 */
			const index = inEditorCitations.findIndex(({ identifier: idx }) => idx == identifier);
			/**
			 * Replace item in array of current citations
			 */
			setInEditorCitations((citations) => [
				...citations.slice(0, index),
				citation,
				...citations.slice(index + 1)
			]);
			/**
			 * Throw focus back
			 */
			editor.focus();
			/**
			 * Close modal after update;
			 */
			setShowDialog(false);
			/**
			 * Clear editing
			 */
			setEditingCitation(null);
		};

		return {
			availableCitations,
			citationStyle,
			showCitationDialog,
			openCreateDialog,
			openEditDialog,
			closeDialog,
			addCitation,
			updateCitation,
			editingCitation,
			inEditorCitations,
			updateCitationsMarkers,
			addCitationDataOnly
		};
	}, [
		availableCitations,
		citationStyle,
		showCitationDialog,
		editingCitation,
		inEditorCitations,
		editor
	]);

	return (
		<CitationMetaContext.Provider value={contextValue}>{children}</CitationMetaContext.Provider>
	);
};

export default CitationMetaProvider;
