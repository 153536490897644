import React from 'react';

import { getFormattedValue } from '../WritingTemplate/Spreadsheet/Components/Cell/utils';
import { Graph } from '../WritingTemplate/Spreadsheet/Graph';
import { getFormattingForCell, getStylesForCell } from '../WritingTemplate/Spreadsheet/helpers';
import { getCellAddress } from '../WritingTemplate/Spreadsheet/helpers/address';
import { evaluateCellValuesSlice } from '../WritingTemplate/Spreadsheet/helpers/compute';
import { IDictionary } from '../WritingTemplate/WritingTemplate/types';
import styles, { cell, header, hide, sideHeader } from './styles';

interface Props {
	inputs: IDictionary;
	item: IDictionary;
}

const ReadOnlySpreadsheet: React.FC<Props> = ({ inputs, item }) => {
	const cellHandle = /([A-Za-z]+)([0-9]+)/gi;

	const renderBody = () => {
		const input = inputs[item.dest];
		return getRows(input);
	};

	const getRows = (input) => {
		/*
			This function is responsible for rendering the rows of the spreadsheet.
			Each row includes defined amount of cells, based on schema.
			Every cell will get its own styling and formatting and values (format and formulas also supported).
			Attach header functions creates a row with headers (horizontal or vertical) based on cells count.
		*/
		const { cellValues, sheet } = input;
		const { cells, origin, styling, formatting } = sheet;
		const initOrigin = (origin as string) || 'A1';
		const [, horizontal, vertical] = cellHandle.exec(initOrigin) as RegExpExecArray; // eslint-disable-line;

		/**
		 * This approach is pretty similar to the one used in the spreadsheet component.
		 * The main difference is that we don't have to deal with the state.
		 */
		const graph = new Graph();
		Object.entries(cellValues).forEach(([address, value]) => {
			graph.addVertex(address, String(value));
		});
		const evaluatedCellValues = evaluateCellValuesSlice({
			addressesToEvaluate: Object.keys(cellValues),
			cellValues,
			graph
		});

		const rows = cells.map((row, rowIndex) => {
			return (
				<tr key={+vertical + rowIndex}>
					{[
						attachRowStarter(vertical, rowIndex),
						...row.map((_, colIndex) => {
							const cellAddress = getCellAddress(rowIndex, colIndex, initOrigin);
							const cellImport = cellValues[cellAddress];

							const cellFormatting = getFormattingForCell(cellAddress, formatting);
							const style = createStyles(cellAddress, styling);

							const cellValue = cellImport?.startsWith('=')
								? evaluatedCellValues[cellAddress]?.result
								: cellImport;
							const formatted = getFormattedValue(cellValue, cellFormatting);

							const isAccounting = cellFormatting?.type === 'accounting';

							return getCell(formatted, cellAddress, style, isAccounting);
						})
					]}
				</tr>
			);
		});

		return <>{[attachHeaderRow(horizontal, cells[0].length), ...rows]}</>;
	};

	const createStyles = (cellAddress, styling) => {
		/*
			This functions responsable for style composition for cells in the spreadsheet. (table)
			It takes the cell address and the styling object and returns a style object.
			Based on the origin function, that returns styles in string format.

		*/
		// step1: call the origin function to get the styles in string format
		const cellStyles = getStylesForCell(cellAddress, styling)
			// step2: remove unrecognized keys
			.replace(/div|[{}]|\s|!important/g, '')
			// step3: split the string into an array of styles
			.split(';')
			.map((key) =>
				key
					.split(':')
					// step4: split style for key:value
					.map((str, idx) =>
						idx === 0
							? str
									// step5: replace `-` with camelCase
									.split('-')
									.map((str, idx) => (idx === 1 ? str.charAt(0).toUpperCase() + str.slice(1) : str))
									.join('')
							: str
					)
					.join(':')
			)
			// step6: convert the array to an object
			.reduce((acc, style) => {
				if (style?.length > 0) {
					const [key, value] = style.split(':');
					return { ...acc, [key]: value };
				}

				return acc;
			}, {});

		return cellStyles;
	};

	const getCell = (cellValue, cellAddress, style, isAccounting) => {
		return (
			<td style={{ ...style, position: 'relative' }} key={cellAddress}>
				<span style={style} css={cell(isAccounting)}>
					{cellValue}
				</span>
			</td>
		);
	};

	const attachRowStarter = (vertical, index) => {
		return (
			<td css={sideHeader}>
				<span>{+vertical + index}</span>
			</td>
		);
	};

	const attachHeaderRow = (horizontal, maxLen) => {
		const addEmpty = () => {
			return <td css={hide} key={'empty'}></td>;
		};

		return (
			<tr>
				{[
					addEmpty(),
					...Array.from({ length: maxLen }).map((_, index) => {
						return (
							<td key={horizontal + index}>
								<span css={header}>{String.fromCharCode(horizontal.charCodeAt(0) + index)}</span>
							</td>
						);
					})
				]}
			</tr>
		);
	};

	return (
		<table css={styles}>
			<tbody>{renderBody()}</tbody>
		</table>
	);
};

export default ReadOnlySpreadsheet;
