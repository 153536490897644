const state_neighbors = {
	Alabama: {
		u: 'Tennessee',
		r: 'Florida',
		d: null,
		l: 'Mississippi'
	},
	Alaska: {
		u: 'California',
		r: 'Hawaii',
		d: null,
		l: null
	},
	Arizona: {
		u: 'Utah',
		r: 'New Mexico',
		d: null,
		l: 'California'
	},
	Arkansas: {
		u: 'Missouri',
		r: 'Tennessee',
		d: 'Louisiana',
		l: 'Oklahoma'
	},
	California: {
		u: 'Oregon',
		r: 'Nevada',
		d: 'Alaska',
		l: null
	},
	Colorado: {
		u: 'Wyoming',
		r: 'Kansas',
		d: 'New Mexico',
		l: 'Utah'
	},
	Connecticut: {
		u: 'Massachusetts',
		r: 'Rhode Island',
		d: 'New York',
		l: 'New York'
	},
	Delaware: {
		u: 'Pennsylvania',
		r: null,
		d: null,
		l: 'Maryland'
	},
	Florida: {
		u: 'Georgia',
		r: null,
		d: null,
		l: 'Alabama'
	},
	Georgia: {
		u: 'Tennessee',
		r: 'South Carolina',
		d: 'Florida',
		l: 'Alabama'
	},
	Hawaii: {
		u: 'New Mexico',
		r: 'Texas',
		d: null,
		l: 'Alaska'
	},
	Idaho: {
		u: null,
		r: 'Montana',
		d: 'Utah',
		l: 'Washington'
	},
	Illinois: {
		u: 'Wisconsin',
		r: 'Indiana',
		d: 'Kentucky',
		l: 'Iowa'
	},
	Indiana: {
		u: 'Michigan',
		r: 'Ohio',
		d: 'Kentucky',
		l: 'Illinois'
	},
	Iowa: {
		u: 'Minnesota',
		r: 'Illinois',
		d: 'Missouri',
		l: 'Nebraska'
	},
	Kansas: {
		u: 'Nebraska',
		r: 'Missouri',
		d: 'Oklahoma',
		l: 'Colorado'
	},
	Kentucky: {
		u: 'Indiana',
		r: 'Virginia',
		d: 'Tennessee',
		l: 'Missouri'
	},
	Louisiana: {
		u: 'Arkansas',
		r: 'Mississippi',
		d: null,
		l: 'Texas'
	},
	Maine: {
		u: null,
		r: null,
		d: 'New Hampshire',
		l: 'New Hampshire'
	},
	Maryland: {
		u: 'Pennsylvania',
		r: 'Delaware',
		d: 'Virginia',
		l: 'West Virginia'
	},
	Massachusetts: {
		u: 'Vermont',
		r: null,
		d: 'Connecticut',
		l: 'New York'
	},
	Michigan: {
		u: null,
		r: 'New York',
		d: 'Indiana',
		l: 'Wisconsin'
	},
	Minnesota: {
		u: null,
		r: 'Wisconsin',
		d: 'Iowa',
		l: 'North Dakota'
	},
	Mississippi: {
		u: 'Tennessee',
		r: 'Alabama',
		d: null,
		l: 'Louisiana'
	},
	Missouri: {
		u: 'Iowa',
		r: 'Illinois',
		d: 'Arkansas',
		l: 'Kansas'
	},
	Montana: {
		u: null,
		r: 'North Dakota',
		d: 'Wyoming',
		l: 'Idaho'
	},
	Nebraska: {
		u: 'South Dakota',
		r: 'Iowa',
		d: 'Kansas',
		l: 'Colorado'
	},
	Nevada: {
		u: 'Idaho',
		r: 'Utah',
		d: 'Arizona',
		l: 'California'
	},
	'New Hampshire': {
		u: null,
		r: 'Maine',
		d: 'Massachusetts',
		l: 'Vermont'
	},
	'New Jersey': {
		u: 'New York',
		r: null,
		d: 'Delaware',
		l: 'Pennsylvania'
	},
	'New Mexico': {
		u: 'Colorado',
		r: 'Texas',
		d: 'Hawaii',
		l: 'Arizona'
	},
	'New York': {
		u: null,
		r: 'Massachusetts',
		d: 'Pennsylvania',
		l: 'Michigan'
	},
	'North Carolina': {
		u: 'Virginia',
		r: null,
		d: 'South Carolina',
		l: 'Tennessee'
	},
	'North Dakota': {
		u: null,
		r: 'Minnesota',
		d: 'South Dakota',
		l: 'Montana'
	},
	Ohio: {
		u: null,
		r: 'Pennsylvania',
		d: 'Kentucky',
		l: 'Indiana'
	},
	Oklahoma: {
		u: 'Kansas',
		r: 'Arkansas',
		d: 'Texas',
		l: 'New Mexico'
	},
	Oregon: {
		u: 'Washington',
		r: 'Idaho',
		d: 'California',
		l: null
	},
	Pennsylvania: {
		u: 'New York',
		r: 'New Jersey',
		d: 'Maryland',
		l: 'Ohio'
	},
	'Rhode Island': {
		u: 'Massachusetts',
		r: null,
		d: 'New York',
		l: 'Connecticut'
	},
	'South Carolina': {
		u: 'North Carolina',
		r: null,
		d: null,
		l: 'Georgia'
	},
	'South Dakota': {
		u: 'North Dakota',
		r: 'Minnesota',
		d: 'Nebraska',
		l: 'Wyoming'
	},
	Tennessee: {
		u: 'Kentucky',
		r: 'North Carolina',
		d: 'Alabama',
		l: 'Arkansas'
	},
	Texas: {
		u: 'Oklahoma',
		r: 'Louisiana',
		d: 'Hawaii',
		l: 'New Mexico'
	},
	Utah: {
		u: 'Idaho',
		r: 'Colorado',
		d: 'Arizona',
		l: 'Nevada'
	},
	Vermont: {
		u: null,
		r: 'New Hampshire',
		d: 'Massachusetts',
		l: 'New York'
	},
	Virginia: {
		u: 'Maryland',
		r: 'Maryland',
		d: 'North Carolina',
		l: 'West Virginia'
	},
	Washington: {
		u: null,
		r: 'Idaho',
		d: 'Oregon',
		l: null
	},
	'West Virginia': {
		u: 'Pennsylvania',
		r: 'Virginia',
		d: 'Virginia',
		l: 'Kentucky'
	},
	Wisconsin: {
		u: 'Michigan',
		r: 'Michigan',
		d: 'Illinois',
		l: 'Minnesota'
	},
	Wyoming: {
		u: 'Montana',
		r: 'South Dakota',
		d: 'Colorado',
		l: 'Idaho'
	}
};

export default state_neighbors;
