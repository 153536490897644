import React, { createContext, FC, useMemo } from 'react';

import { CellsRefs, Formatting, OnBlurErrors } from '../helpers/types';

export interface IElementContext {
	cellsRefs: CellsRefs;
	windowSize: number;
	formatting?: Formatting;
	blurErrorsRules: OnBlurErrors;
	addReference?: (address: string, reference: React.MutableRefObject<HTMLTableCellElement>) => void;
	spreadsheetWrapperRef?: React.MutableRefObject<HTMLDivElement>;
	resetCachedSelection: (addresses: string[]) => void | null;
}

const ElementsContext = createContext<Partial<IElementContext>>({});
export default ElementsContext;

export const ElementsProvider: FC<
	IElementContext & {
		setReferences: React.Dispatch<React.SetStateAction<CellsRefs>>;
	}
> = (props) => {
	const { children, setReferences, ...providerValue } = props;

	const value = useMemo(() => {
		const addReference = (
			address: string,
			reference: React.MutableRefObject<HTMLTableCellElement>
		) => {
			setReferences((prev) => ({ ...prev, [address]: reference }));
		};
		return { ...providerValue, addReference };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [providerValue]);

	return <ElementsContext.Provider value={value}>{children}</ElementsContext.Provider>;
};
