import { useContext, useEffect } from 'react';

import { EditModeStateContext } from '../../EditMode/providers/EditModeStateProvider';

export function useCallbackOnScroll(
	callBack: () => void,
	spreadsheetWrapperRef: React.MutableRefObject<HTMLDivElement>
): void {
	const editModeState = useContext(EditModeStateContext);
	const scrollablePageElement = editModeState?.scrollableContainer;

	useEffect(() => {
		const scrollableSpreadsheetElement = spreadsheetWrapperRef.current.querySelector('.container');
		scrollableSpreadsheetElement.addEventListener('scroll', callBack);
		scrollablePageElement?.addEventListener('scroll', callBack);

		return () => {
			scrollableSpreadsheetElement.removeEventListener('scroll', callBack);
			scrollablePageElement?.removeEventListener('scroll', callBack);
		};
	}, [spreadsheetWrapperRef, scrollablePageElement, callBack]);
}
