import { css, SerializedStyles } from '@emotion/react';

import { fieldBlockDimensions as textFieldBlockDimensions } from '~/components/Outline/components/TextField/styles';
import { OutlineVariant, TemplateTransform } from '~/components/Outline/types';
import { Theme } from '~/styles/themes';

const deliveryCueTransform = () => css`
	grid-template-areas: 'text-field';
	grid-template-columns: auto;

	.outline-instance-label {
		display: none;
	}

	.outline-instance-view-text-field {
		font-weight: 700;
		${textFieldBlockDimensions}; // Aligns the field without the label

		&::before {
			content: '[';
		}

		&::after {
			content: ']';
		}
	}
`;

export const instanceViewTransform = (options: {
	transform: TemplateTransform;
	variant: OutlineVariant;
	theme: Theme;
}): SerializedStyles => {
	const { transform, variant } = options;
	if (variant !== OutlineVariant.VIEW) return null;

	switch (transform) {
		case TemplateTransform.DeliveryCue:
			return deliveryCueTransform();
	}
};
