import type React from 'react';

import { type TippyProps } from '@tippyjs/react';
import HtmlReactParser, { Element } from 'html-react-parser';

import { type CitationReferences } from '~/types';
import { type BasePageElement, type FamilyId } from '~/types/WebtextManifest';

import { BaseTextStyles } from './styles';
import { addClassToElement, checkForSpecialTextContent } from './utils';

export interface Props {
	element: { family_id?: FamilyId; body: string };
	onCitationClicked?: (clicked: HTMLAnchorElement, footnote: HTMLAnchorElement) => void;
	onLmsCourseRedirect?: (lmsContent: string) => void;
	platform?: 'web' | 'mobile';
	/** @deprecated */
	getElementByFamilyId?: (familyId: string) => BasePageElement;
	citationReferences?: CitationReferences;
	playMediaSnippet?: (familyId: string, start: string, end: string) => void;
	tippyProps?: TippyProps;
	copyToClipboard?: (text: string) => Promise<void>;
	online: boolean;
	containerType?: string;
	className?: string;
	footnotesVisualStyle?: string;
}

const Text: React.FC<Props> = (props) => {
	const { element, containerType, platform, className } = props;

	/**
	 * We want this to reset every render, so the indexes will be the same every render
	 */
	let index = 0;
	let isWebtextExamplesGroupStart = false;
	return (
		<div
			css={(theme) => BaseTextStyles(theme, { containerType, platform })}
			className={className}
			data-webtexts-annotatable-id={`${element.family_id}#body`}>
			<div className="text-component">
				{HtmlReactParser(element.body, {
					replace: (domNode) => {
						/**
						 * @todo When React 18 is available we should re-evaluate this behavior and try
						 * to use `useId`
						 *
						 * See https://github.com/reactwg/react-18/discussions/111
						 *
						 * Using existing DOMNode field to serve our purposes, it is undefined otherwise
						 */
						domNode.startIndex = index;

						if (domNode instanceof Element && domNode.attribs) {
							// Add `readable` class to top-level elements
							const isNestedContent =
								domNode?.attribs && domNode?.attribs?.class?.includes('nested-content');

							if (domNode.parent === null && !isNestedContent) {
								addClassToElement(domNode, 'readable');
							}

							if (domNode.parent === null && isNestedContent) {
								domNode.children
									.filter((n) => n.type === 'tag')
									.forEach((n) => addClassToElement(n as Element, 'readable'));
							}

							const content = checkForSpecialTextContent(domNode, props);

							/**
							 * Add <hr /> before and after webtext-example items
							 */
							if (/webtext(-\w+)?-example/.test(domNode.attribs['class'])) {
								const webtextExample = renderWebtextExample(content, isWebtextExamplesGroupStart);
								isWebtextExamplesGroupStart = true;
								return webtextExample;
							}
							isWebtextExamplesGroupStart = false;

							return content;
						}

						index = index + 1;
					}
				})}
			</div>
		</div>
	);
};

export default Text;

const renderWebtextExample = (content: React.ReactNode | void, isGroupStart: boolean) => {
	return (
		<>
			{!isGroupStart && <hr className="visually-hidden" />}
			{content}
			<hr className="visually-hidden" />
		</>
	);
};
