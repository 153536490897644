import { useEffect, useContext } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';

import CitationMetaContext from '../context/CitationMetaContext';
import { CitationNode } from '../nodes/CitationNode';

export const useCitationsStore = (
	onChange: (_: unknown, editor: LexicalEditor, disableAutosave?: boolean) => void
): void => {
	const [editor] = useLexicalComposerContext();
	const { inEditorCitations } = useContext(CitationMetaContext);

	useEffect(() => {
		if (!editor.hasNodes([CitationNode])) {
			return;
		}

		/**
		 * Citations data stored inside inEditorCitations inside a provider
		 * the are out of sync with editor state, because editor store only info about their existence
		 * that means in some cases onChange can be executed when citationMeta not added yet, but editor already has a mark bubble
		 * that's why on citation update we trigger onChange event
		 * Known cases:
		 * 1) Paste citation and immediately click store
		 * 2) Paste citation and wait auto-save and reload page
		 * Basically when you paste the citation onPaste happens -> Lexical onChange happens -> Builder onChange called -> inEditorCitation update happens
		 */
		editor.update(() => onChange(null, editor, true));
	}, [editor, inEditorCitations]);
};
