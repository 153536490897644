import { useLayoutEffect, useMemo } from 'react';

import { useToggle } from 'usehooks-ts';

import { useIsUniversalVelvet } from '~/hooks';

import type { ChartElement } from '~/types/WebtextManifest';

const useChartZoom = (chart: ChartElement) => {
	const isUV = useIsUniversalVelvet();

	const { id, caption, credit, allow_zoom, tabsUi } = chart;

	const zoomElementId = `enlarge_${id}`;
	const zoomAllowed = (allow_zoom ?? true) && !tabsUi;

	const [isZoomed, toggleZoomed] = useToggle(false);

	const enlargeCaption = useMemo(() => {
		if (!zoomAllowed) return caption;

		const toggleLargeChart = `<a id="${zoomElementId}" href="app://${zoomElementId}" class="large-chart-toggle">${
			isZoomed ? 'Return to smaller chart' : 'View larger chart'
		}</a>`;

		const breakCaption = `<span class='break-caption'>|</span>`;

		if (caption) {
			return `${toggleLargeChart} ${breakCaption} ${caption}`;
		}
		if (isUV && credit) {
			// UV theme figure caption is inline. Prevents clashing the link with the credit
			return `${toggleLargeChart} ${breakCaption} `;
		}
		return toggleLargeChart;
	}, [zoomAllowed, caption, credit, zoomElementId, isZoomed, isUV]);

	useLayoutEffect(() => {
		if (!zoomAllowed) return;

		const handleToggleEnlargeClick = (event: MouseEvent) => {
			event.preventDefault();
			toggleZoomed();
		};

		const zoomElement = document.getElementById(zoomElementId);
		zoomElement?.addEventListener('click', handleToggleEnlargeClick);
		return () => zoomElement?.removeEventListener('click', handleToggleEnlargeClick);
	}, [isZoomed, zoomAllowed, zoomElementId, toggleZoomed]);

	return { isZoomed, enlargeCaption };
};

export default useChartZoom;
