import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Document, DocumentProps, Page, pdfjs } from 'react-pdf';

import { outputImageStyle, pdfPreviewStyle } from '~/components/WritingTemplate/ViewMode/styles';

import type { IApi } from '../WritingTemplate/WritingTemplate';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface Props {
	documentName?: string;
	getDocumentUrl?: IApi['getImageUrl'];
	sourceBuilderId?: string;
	pdfOptions?: DocumentProps;
	onLoadProgress?: () => void;
	onLoadFinish?: () => void;
}

const DocumentsPreview: React.FC<Props> = ({
	documentName,
	getDocumentUrl,
	sourceBuilderId,
	pdfOptions,
	onLoadProgress,
	onLoadFinish
}) => {
	const [documentUrl, setDocumentUrl] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	const handleLoadProgress = useCallback(() => {
		setIsLoading(true);
		onLoadProgress?.();
	}, [onLoadProgress]);

	const handleLoadFinish = useCallback(() => {
		setIsLoading(false);
		onLoadFinish?.();
	}, [onLoadFinish]);

	const reactPdfOptions = useMemo<DocumentProps>(
		() => ({
			onLoadProgress: (data) => {
				/**
				 * It's executed multiple times during the loading and after its completion
				 * If both `onLoadSuccess` & `onLoadProgress` are specified, only the latter will be invoked
				 */
				const { loaded, total } = data;
				if (loaded < total) {
					handleLoadProgress();
				} else {
					handleLoadFinish();
				}
			},
			...pdfOptions
		}),
		[handleLoadProgress, handleLoadFinish, pdfOptions]
	);

	useEffect(() => {
		if (!documentName) return;

		handleLoadProgress();
		getDocumentUrl(sourceBuilderId, documentName)
			.then((url) => {
				/**
				 * The returned URL can either be relative (in Core) or absolute.
				 * With the URL constructor we normalize both of them to absolute.
				 */
				const absoluteUrl = new URL(url, document.location.origin);
				setDocumentUrl(`${absoluteUrl}`);
			})
			.catch(() => setDocumentUrl(null))
			.finally(handleLoadFinish);
	}, [getDocumentUrl, handleLoadFinish, handleLoadProgress, documentName, sourceBuilderId]);

	const onImageLoadError = useCallback(
		(event: SyntheticEvent<HTMLImageElement, Event>) => {
			const error = (event.nativeEvent as ErrorEvent).error;
			(window.Rollbar || console).error(
				'Unable to load preview image for builder',
				{ documentUrl },
				error
			);
			if (documentUrl === null) return;

			// Forces the browser to re-fetch the image
			const url = new URL(documentUrl);
			if (!url.searchParams.has('t')) {
				url.searchParams.set('t', `${new Date().getTime()}`);
				setDocumentUrl(`${url}`);
			}
		},
		[documentUrl]
	);

	if (isLoading) {
		return <span>Loading preview...</span>;
	}
	return !documentName?.endsWith('.pdf') ? (
		<img src={documentUrl ?? 'broken_link'} css={outputImageStyle} onError={onImageLoadError} />
	) : (
		<Document file={documentUrl} loading="Loading preview..." {...reactPdfOptions}>
			<Page width={310} css={pdfPreviewStyle} pageNumber={1} />
		</Document>
	);
};

export default DocumentsPreview;
