import React, { FC } from 'react';
import { PulseLoader } from 'react-spinners';

import { useTheme } from '@emotion/react';
import { LoaderSizeMarginProps } from 'react-spinners/helpers/props';

import { ThemeName } from '~/styles/themes';

export interface Props extends LoaderSizeMarginProps {
	className?: string;
}

// TODO Unify it with Mark's loaders folder + use the same loader in all the places
const Loader: FC<Props> = (props) => {
	const { className, ...spinnerProps } = props;

	const theme = useTheme();
	const {
		name: themeName,
		colors: { white, primary: primaryColor }
	} = theme;

	const defaultColor = themeName === ThemeName.UNIVERSAL_VELVET ? primaryColor : white;

	return (
		<div className={className}>
			<PulseLoader
				size={11}
				color={defaultColor}
				cssOverride={{
					/**
					 * Workaround to make the loader take only the space needed for pulsing dots
					 * w/o an excessive empty space around them
					 */
					lineHeight: 0
				}}
				{...spinnerProps}
			/>
		</div>
	);
};

export default Loader;
