import React, { FC } from 'react';

import { ChartLegend } from '../ChartLegend';
import EnlargeChartWrapper from '../EnlargeChart';
import D3BarChart from './D3BarChart';

import type { Props as ChartFigureProps } from '../Chart';
import type { ChartElement } from '~/types/WebtextManifest';

export type BarChart = Pick<
	ChartElement,
	| 'data'
	| 'axis_type'
	| 'x_axis_label'
	| 'y_axis_label'
	| 'show_labels'
	| 'min_bound'
	| 'max_bound'
	| 'orientation'
	| 'series_orientation'
	| 'colors'
	| 'margins'
>;

export interface Props extends Omit<ChartFigureProps, 'chart'> {
	chart: BarChart;
}

const BarChart: FC<Props> = (props) => {
	const { chart, monochrome, zoomed } = props;
	const seriesLabels = chart.data[0].slice(1);

	return (
		<>
			{seriesLabels.length > 1 && (
				<ChartLegend
					seriesLabels={seriesLabels}
					colors={chart.colors}
					monochrome={monochrome}
					fill
				/>
			)}
			<EnlargeChartWrapper zoomed={zoomed}>
				<D3BarChart chart={chart} monochrome={monochrome} />
			</EnlargeChartWrapper>
		</>
	);
};

export default BarChart;
