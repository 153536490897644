import cloneDeep from 'lodash-es/cloneDeep';

import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

theme.colors.blue = '#1f83a8';
theme.colors['light-blue'] = '#4b9ae5';

theme.colors.primary = '#810505';
theme.colors.secondary = '#f7f7f7';
theme.colors.accent = theme.colors.primary;
theme.colors.link = theme.colors.blue;

theme.webtextPage.title.color = theme.colors.primary;

theme.tableOfContents.progressColor = (theme) => theme.colors['light-blue'];
theme.tableOfContents.currentItemColor = (theme) => theme.colors.primary;

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}0D`;

export default theme;
