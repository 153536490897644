import { useEffect, useRef } from 'react';

/**
 * Returns the value from the previous render cycle.
 * The memoized value can match the current one on newer renders.
 *
 * @see https://www.developerway.com/posts/implementing-advanced-use-previous-hook#part2
 **/
export function usePreviousRender<T>(value: T): T {
	const ref = useRef<T>();
	useEffect(() => void (ref.current = value));
	return ref.current;
}

/**
 * Returns the previous value in comparison to the current one.
 *
 * It's important to run the ref update synchronously, in the body!
 * Otherwise, using `useEffect`, the updated ref would be lagging 1 render behind that can cause undesired behavior.
 *
 * @see https://www.developerway.com/posts/implementing-advanced-use-previous-hook#part3
 */
export function usePrevious<T>(
	value: T,
	comparator = (next: T, current: T) => next === current
): T {
	const previousValueRef = useRef<T>();
	const currentValueRef = useRef<T>();

	if (!comparator(value, currentValueRef.current)) {
		previousValueRef.current = currentValueRef.current;
		currentValueRef.current = value;
	}

	return previousValueRef.current;
}
