import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import { isNil } from 'lodash-es';

import { QuestionChoices, AnswerStatus } from '~/components/shared/Question';
import { Theme, ThemeName } from '~/styles/themes';
import { DueDateProps } from '~/types/dueDateProps';
import shuffle from '~/utils/shuffle';

import { choicesStyles } from '../styles';
import { StudentViewElementProps } from '../types';
import Rejoinder from './Rejoinder';
import ResetButton from './ResetButton';
import SaveButton from './SaveButton';

import type { UseStudentViewProvided } from '../hooks/useStudentView';

type Props = StudentViewElementProps &
	DueDateProps &
	UseStudentViewProvided & {
		saveButtonLabel?: string;
		resetButtonLabel?: string;
	};

/**
 * Renders everything but the question prompt for a multiple-choice question pool in student view.
 *
 * This component's visiblity is toggled by expanding and collapsing an MCQP inside of a question deck,
 * hence the name "below the fold" (with the question prompt being "above the fold").
 */
const StudentViewBelowTheFold: React.VFC<Props> = (props) => {
	const {
		attemptsAllowed,
		gradeHoldEnd,
		gradeHoldStart,
		penaltyCredit,
		originallyDueAt,
		penaltyDueAt,
		questionPool,
		activeQuestion,
		answer,
		resetCount,
		isRequestInProgress,
		rejoinderRef,
		selectedChoiceFamilyId,
		handleReset,
		handleSave,
		handleChangeSelectedChoice,
		correctChoice,
		seed,
		shuffleChoices = true,
		userId,
		readOnly,
		saveButtonLabel,
		resetButtonLabel,
		isStudyStack,
		onStudyStackLinkClick
	} = props;

	const shuffledChoices = useMemo(() => {
		if (!shuffleChoices) {
			return activeQuestion.choices;
		}

		// use the exact same computed seed as Soomo::Shuffler in Core
		const computedSeed = `${seed ?? userId}${activeQuestion.family_id}`;
		return shuffle(activeQuestion.choices, computedSeed);
	}, [activeQuestion.choices, activeQuestion.family_id, seed, shuffleChoices, userId]);

	return (
		<>
			<QuestionChoices
				choices={shuffledChoices}
				disabled={answer != null || readOnly}
				selectedChoiceFamilyId={selectedChoiceFamilyId}
				onChangeSelectedChoice={handleChangeSelectedChoice}
				questionFamilyId={activeQuestion.family_id}
				css={choicesStyles}
			/>
			{answer ? (
				<>
					<div css={rejoinderAndAnswerStatusContainerStyles}>
						<Rejoinder
							ref={rejoinderRef}
							rejoinder={answer.rejoinder}
							correct={answer.correct}
							correctChoice={correctChoice}
						/>
						<AnswerStatus
							suppressAria
							postedAt={''}
							updatedAt={answer.updated_at}
							saving={false}
							posting={false}
							unposting={false}
							css={answerStatusStyles}
						/>
					</div>
					<ResetButton
						questionPoolFamilyId={questionPool.family_id}
						isRequestInProgress={isRequestInProgress}
						onReset={handleReset}
						correct={answer.correct}
						attemptsAllowed={attemptsAllowed}
						resetCount={resetCount}
						poolSize={questionPool.questions.length}
						gradeHoldEnd={gradeHoldEnd}
						gradeHoldStart={gradeHoldStart}
						originallyDueAt={originallyDueAt}
						penaltyCredit={penaltyCredit}
						penaltyDueAt={penaltyDueAt}
						disabled={readOnly}
						resetButtonLabel={resetButtonLabel}
						isStudyStack={isStudyStack}
						onStudyStackLinkClick={onStudyStackLinkClick}
					/>
				</>
			) : (
				<SaveButton
					questionPoolFamilyId={questionPool.family_id}
					attemptsAllowed={attemptsAllowed}
					resetCount={resetCount}
					isRequestInProgress={isRequestInProgress}
					disabled={isNil(selectedChoiceFamilyId) || readOnly}
					gradeHoldEnd={gradeHoldEnd}
					gradeHoldStart={gradeHoldStart}
					originallyDueAt={originallyDueAt}
					penaltyCredit={penaltyCredit}
					penaltyDueAt={penaltyDueAt}
					onClick={handleSave}
					saveButtonLabel={saveButtonLabel}
				/>
			)}
		</>
	);
};

export default StudentViewBelowTheFold;

const rejoinderAndAnswerStatusContainerStyles = (theme: Theme) => css`
	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		background: ${theme.colors.white};

		[data-in-question-deck='false'] & {
			// if standalone, counteract left padding from UniversalVelvetLeftBorder
			margin-left: -50px;
			padding-left: 50px;
		}

		[data-in-question-deck='true'] & {
			margin-left: -1.5rem;
		}

		&:last-child {
			margin-bottom: -1.5rem;
			border-bottom-left-radius: 0.5rem;
		}
	`}
`;

const answerStatusStyles = (theme: Theme) => css`
	margin: 32px 0 0;

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		margin: 0;
		padding-bottom: 1rem;

		[data-in-question-deck='true'] & {
			margin-left: 1.5rem;
		}
	`}
`;
