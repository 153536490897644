/* eslint-disable react/display-name */
import React from 'react';

import { css, ClassNames } from '@emotion/react';

type Props = React.PropsWithChildren<{
	className?: string;
	role?: string;
	ariaLevel?: number;
	tabIndex?: number;
}>;

/**
 * `webtext-question-label` class is a HACK until fonts reside inside of @soomo-libs
 */
const SectionTitle = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
	<ClassNames>
		{({ cx }) => (
			<div
				ref={ref}
				css={styles}
				className={cx('readable webtext-question-label', props.className)}
				role={props.role}
				aria-level={props.ariaLevel}
				tabIndex={props.tabIndex}>
				{props.children}
			</div>
		)}
	</ClassNames>
));

const styles = (theme) => {
	return css`
		font-family: ${theme.webtextQuestion.sectionTitle.fontFamily};
		font-weight: ${theme.webtextQuestion.sectionTitle.fontWeight};
		font-size: ${theme.webtextQuestion.sectionTitle.fontSize};
		line-height: ${theme.webtextQuestion.sectionTitle.lineHeight};
		color: ${theme.webtextQuestion.sectionTitle.color};
		white-space: nowrap;
	`;
};

export default SectionTitle;
