import styles from './SAQuestion.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';

import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import { ShortAnswerQuestion } from '@soomo/lib/components/pageElements/index';
import { VersionHistoryItem } from '@soomo/lib/notebook/components/index';

import * as uiActions from 'Actions/uiActions';
import pageActivityQuery from 'Requests/PageActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';
import AnalyticsLink from 'Components/AnalyticsLink';
import ActivityHeader from 'Components/ActivityHeader';
import ActivityHistory from 'Containers/ActivityHistory';

import AnswerActions from './AnswerActions';

class SAQuestion extends Component {
	state = {
		showVersionsHistory: false,
		isEditingComment: false
	};

	toggleVersionsHistory = () => {
		this.setState({ showVersionsHistory: !this.state.showVersionsHistory });
		return false;
	};

	onUnpostAnswer = () => {
		const { dispatch, course, chapter, page, current_answer: answer } = this.props;
		dispatch(
			pageActivityQuery({
				courseId: course.id,
				userId: answer.user_id,
				pageId: page.id,
				force: true
			})
		);
		dispatch(
			metricsQuery({
				courseId: course.id,
				userIds: [answer.user_id],
				scopeId: chapter.id,
				groupby: 'page',
				force: true
			})
		);
	};

	onViewAllResponses = () => {
		const { uiActions, user, page, question: element } = this.props;

		let elementId = element.id;
		if (element.depends_on && element.depends_on.length && element.dictionary.body) {
			const cyoKey = user.decisions[element.depends_on[0]];

			if (cyoKey) {
				elementId = `${elementId}:${cyoKey}`;
			}
		}

		uiActions.updateCurrentViewParams({
			element: elementId,
			scope: page.id,
			student: null,
			pageViewStudent: user.id
		});
		return false;
	};

	renderInContext = () => {
		const { user, question, current_answer: answer } = this.props;

		const inContextAnswer = !answer
			? {}
			: {
					...answer,
					body: answer.posted_at ? answer.body : ''
			  };

		return (
			<ShortAnswerQuestion
				userId={user.id}
				questionId={question.id}
				body={question.body}
				answer={inContextAnswer}
				updatedAt={answer?.saved_at}
				referenceAnswer={question.reference_answer}
				validationMessage=""
				online
				readOnly
			/>
		);
	};

	renderAnswerState = () => {
		const { current_answer: answer, posted_at, timeZone } = this.props;
		const isDrafted = answer && answer.body && !posted_at;

		if (!posted_at) {
			if (isDrafted) {
				return (
					<ActivityHeader timestamp={answer.saved_at} timeZone={timeZone}>
						LAST SAVE <span style={{ fontStyle: 'italic' }}>(Not yet posted)</span>
					</ActivityHeader>
				);
			}
			return <ActivityHeader>NOT YET POSTED</ActivityHeader>;
		}
		return null;
	};

	render() {
		if (this.props.inContext) return this.renderInContext();

		const {
			question,
			current_answer: answer,
			commentKey,
			comment,
			posted_at,
			timeZone,
			user
		} = this.props;

		const isComplete = answer && answer.body && posted_at;

		const postedEventVersions = answer?.history?.filter((v) => v.event === 'posted');
		const postedEventCount = postedEventVersions?.length || 0;

		const hasReferenceAnswer = Boolean(
			question.reference_answer && question.reference_answer.length > 0
		);

		return (
			<div className={styles.SAQuestion}>
				{posted_at && (
					<div styleName={cn('pageViewStyles.Symbol', 'pageViewStyles.IsCompleteSymbol')} />
				)}
				<div className={styles.ActivitySummary}>
					<span styleName="pageViewStyles.QuestionType">Short-Answer Question</span>
					<span>&nbsp;&mdash;&nbsp;</span>
					<AnalyticsLink variant="text-uppercase" onClick={this.onViewAllResponses}>
						view all responses
					</AnalyticsLink>
				</div>
				<h2
					className={styles.QuestionBody}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: question.body }}
				/>

				{posted_at && (
					<ActivityHeader timestamp={posted_at} timeZone={timeZone}>
						POSTED
					</ActivityHeader>
				)}

				{answer && posted_at && (
					<div
						data-test="answer-body"
						styleName={cn('styles.AnswerBody', {
							'styles.AnswerBodyComplete': isComplete
						})}>
						<p>{answer.body}</p>
					</div>
				)}

				{this.renderAnswerState()}

				<AnswerActions
					answer={answer}
					posting={posted_at}
					question={question}
					user={user}
					onUnpostAnswer={comment && !this.state.isEditingComment ? this.onUnpostAnswer : null}
					hideCommentButton
				/>

				<AnswerActions
					answer={answer}
					commentKey={commentKey}
					comment={comment}
					posting={posted_at}
					question={question}
					user={user}
					commentWithoutAnswer={true}
					commentRequiresPosting={false}
					onEditComment={(isEditingComment) => {
						this.setState({ isEditingComment });
					}}
					onUnpostAnswer={comment ? null : this.onUnpostAnswer}
				/>

				{postedEventCount > 0 && (
					<ActivityHistory attemptCount={postedEventCount} hasContentUnder={hasReferenceAnswer}>
						{answer.history
							.filter((v) => v.event !== 'saved')
							.map((item, index) => (
								<VersionHistoryItem
									key={`${item.event}-${index}`}
									version={item}
									studentId={user.id}
									timeZone={timeZone}
								/>
							))}
					</ActivityHistory>
				)}

				{hasReferenceAnswer && (
					<div className={styles.ReferenceAnswer}>
						<div style={{ fontWeight: 'bold', marginBottom: '3px' }}>Reference Answer:</div>
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: question.reference_answer }}
						/>
					</div>
				)}
			</div>
		);
	}
}

SAQuestion.propTypes = {
	currentUser: object.isRequired,
	course: object.isRequired,
	featureFlags: object,
	chapter: object.isRequired,
	page: object.isRequired,
	question: object.isRequired,
	user: object.isRequired,
	activity: object,
	current_answer: object,
	comment: object,
	posted_at: string,
	uiActions: object.isRequired,
	timeZone: string.isRequired,
	inContext: bool.isRequired,
	dispatch: func
};

const mapStateToProps = (state, props) => {
	const { student: userId } = state.ui.currentViewParams;
	const commentKey = `${userId}:${props.question.id}`;
	const comment = state.entities.webtextComments[commentKey];
	const current_answer = props.activity && props.activity.answers && props.activity.answers[0];
	const posted_at = current_answer ? state.entities.answerPostings[current_answer.id] : null;

	const pageId = props.question.page_id;
	const page = state.entities.pages[pageId];
	const chapter = state.entities.chapters[page.chapter_id];

	return {
		currentUser: state.entities.currentUser,
		course: state.entities.course,
		featureFlags: state.entities.featureFlags,
		chapter,
		page,
		current_answer,
		commentKey,
		comment,
		posted_at,
		timeZone: state.entities.course.time_zone
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withLibsThemeProvider(SAQuestion));
