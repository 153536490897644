import { css } from '@emotion/react';
import { darken } from 'polished';

export const checkboxInputStyles = (theme) => css`
	margin: 0;
	margin-right: 0.5em !important;
	appearance: auto !important;

	&:hover {
		cursor: pointer;
	}
`;

export const styles = (theme) => css`
	font-size: ${theme.webtextCheckbox.fontSize};
	font-family: ${theme.webtextCheckbox.fontFamily};

	&:hover {
		cursor: pointer;
	}

	&:focus {
		background: ${darken(0.1, theme.colors.primary)};
	}
`;
