import { createContext } from 'react';

import {
	AvailableCitation,
	CitationResponse,
	CitationStyle,
	CitationExport
} from './../../../types';

interface CitationContextValue {
	availableCitations: AvailableCitation[];
	citationStyle: CitationStyle;
	showCitationDialog: boolean;
	closeDialog: () => void;
	openCreateDialog: () => void;
	openEditDialog: (id: string) => void;
	addCitation: (citation: CitationExport) => void;
	updateCitation: (citation: CitationResponse) => void;
	editingCitation: CitationResponse;
	inEditorCitations: CitationResponse[];
	updateCitationsMarkers: (props: { [identifier: string]: string }) => void;
	addCitationDataOnly: (
		citation: CitationResponse,
		nodeKey: string,
		ignoreSelection?: boolean
	) => void;
}

const CitationMetaContext = createContext<Partial<CitationContextValue>>({
	availableCitations: [],
	citationStyle: null,
	showCitationDialog: false,
	editingCitation: null,
	inEditorCitations: []
});

export default CitationMetaContext;
