import * as React from 'react';

export function FrameWithEye(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={32.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.762833777925626px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={28.73} fill="currentColor" />
			<path
				d="M45.87 44.56V26a.53.53 0 0 0-.5-.55h-23.2a.54.54 0 0 0-.52.54v18.57a.53.53 0 0 0 .51.54h23.19a.53.53 0 0 0 .51-.54ZM22.68 44V26.59h22.18V44Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.6907084444814064px'
				}}
			/>
			<path
				d="m33.77 48.59 5.1 1.18a.49.49 0 0 0 .24 0l5.1-1.18 5.45 1.06a.54.54 0 0 0 .5-.17.63.63 0 0 0 .15-.54l-.88-4.77 1.08-4.32a.55.55 0 0 0 0-.31l-1.08-4.27L50.51 31a.58.58 0 0 0 0-.32l-1.08-4.29.88-4.76a.41.41 0 0 0 0-.22.56.56 0 0 0-.63-.5L44.21 22l-5.12-1.19h-.23L33.76 22l-5.1-1.18h-.23L23.33 22l-5.45-1.06a.81.81 0 0 0-.22 0 .61.61 0 0 0-.43.7l.87 4.75-1.1 4.27a.86.86 0 0 0 0 .34l1.08 4.27L17 39.52v.31l1.08 4.29-.87 4.76a.41.41 0 0 0 0 .22.56.56 0 0 0 .61.5l5.42-1.05 5.14 1.18a.45.45 0 0 0 .23 0Zm-10.56-1.21-4.73.92.75-4a.62.62 0 0 0 0-.27l-1.08-4.27 1.08-4.27a.58.58 0 0 0 0-.32l-1.09-4.27 1.08-4.26V26.35l-.75-4 4.75.92a.41.41 0 0 0 .22 0L28.56 22l5.1 1.18a.45.45 0 0 0 .23 0L39 22l5.11 1.18h.21l4.76-.93-.75 4a1.22 1.22 0 0 0 0 .27l1.07 4.28-1.07 4.27a.58.58 0 0 0 0 .32l1.07 4.27L48.32 44a.61.61 0 0 0 0 .27l.74 4-4.75-.92h-.21L39 48.57l-5.1-1.18h-.23l-5.11 1.17-5.12-1.17a.41.41 0 0 0-.22 0Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.6907084444814064px'
				}}
			/>
			<path
				d="M59.28 43A20.57 20.57 0 0 0 56 39.79a17.36 17.36 0 0 0-3.6-2.18 10.55 10.55 0 0 0-9 0 17.63 17.63 0 0 0-3.6 2.18A20.57 20.57 0 0 0 36.46 43l-.35.43.35.43a20.57 20.57 0 0 0 3.3 3.14 16.9 16.9 0 0 0 3.6 2.17 10.55 10.55 0 0 0 9 0A16.66 16.66 0 0 0 56 47a20.57 20.57 0 0 0 3.3-3.16l.35-.43Z"
				style={{
					strokeMiterlimit: 10,
					strokeWidth: '4.144250666888439px'
				}}
				fill="currentColor"
				stroke="currentColor"
			/>
			<path
				d="M59.28 43A21.44 21.44 0 0 0 56 39.79a17.7 17.7 0 0 0-3.61-2.18 10.55 10.55 0 0 0-9 0 17.43 17.43 0 0 0-3.61 2.18A21.44 21.44 0 0 0 36.44 43l-.34.43.34.43a21 21 0 0 0 3.3 3.14 17 17 0 0 0 3.61 2.17 10.55 10.55 0 0 0 9 0A17 17 0 0 0 56 47a21.44 21.44 0 0 0 3.3-3.16l.34-.43Zm-4.15 2.9a12.45 12.45 0 0 1-7.24 2.88 12.54 12.54 0 0 1-7.25-2.88 21.66 21.66 0 0 1-2.72-2.52c1.22-1.35 5.26-5.4 10-5.4a12.44 12.44 0 0 1 7.24 2.88 20.94 20.94 0 0 1 2.72 2.52 21.19 21.19 0 0 1-2.78 2.52Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.5180313333610549px'
				}}
			/>
			<path
				d="M47.86 39.56a3.82 3.82 0 1 0 3.82 3.82 3.82 3.82 0 0 0-3.82-3.82Zm0 6.5a2.69 2.69 0 1 1 2.69-2.68 2.69 2.69 0 0 1-2.68 2.69Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.5180313333610549px'
				}}
			/>
		</svg>
	);
}
