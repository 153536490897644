import { Plugin as TippyPlugin } from 'tippy.js';

import { unlinkify } from '~/utils';

// Docs source: https://atomiks.github.io/tippyjs/v6/plugins/#hideonesc
export const hideOnEscPlugin = {
	name: 'hideOnEsc',
	defaultValue: true,
	fn({ hide }) {
		const onKeyDown = (event) => {
			if (event.keyCode === 27) {
				hide();
			}
		};

		return {
			onShow() {
				document.addEventListener('keydown', onKeyDown);
			},
			onHide() {
				document.removeEventListener('keydown', onKeyDown);
			}
		};
	}
};

export const unlinkifyContentPlugin: TippyPlugin = {
	name: 'unlinkifyContent',
	defaultValue: true,
	fn: (instance) => ({
		onShow: () => instance.setContent(unlinkify(instance.props.content))
	})
};
