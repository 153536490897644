import React, { forwardRef } from 'react';

import { css } from '@emotion/react';

import { breakpoints, mixins, ThemeName } from '~/styles/themes';

type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

const QuestionType = forwardRef<HTMLDivElement, Props>(({ children, ...rest }, ref) => (
	<div
		ref={ref}
		css={styles}
		tabIndex={-1}
		role="heading"
		className="readable"
		data-testid="question-type"
		aria-level={5}
		{...rest}>
		{children}
	</div>
));
QuestionType.displayName = 'QuestionType';

export default QuestionType;

const styles = (theme) => {
	const { questionType } = theme.webtextQuestion;
	return css`
		text-transform: uppercase;
		font-size: ${questionType.fontSize};
		line-height: ${questionType.lineHeight};
		color: ${questionType.color};
		${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetOverrides(theme)}

		.visually-hidden {
			${mixins.webtextHiddenAccessible(theme)}
		}
	`;
};

const universalVelvetOverrides = ({ colors, fonts }) => css`
	color: ${colors.primary};
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 2.8px;
	line-height: 24px;
	margin-bottom: 28px;
	font-family: ${fonts.app};
	svg {
		background-repeat: no-repeat;
		position: relative;
		margin-right: 1em;
		display: inline-block;
		margin-bottom: -0.7em;

		&.response-board-icon {
			margin-bottom: -0.6em;
		}

		&.goreact-icon {
			margin-bottom: -0.55em;
		}

		&.poll-deck-icon {
			margin-bottom: 0;
		}
	}

	@media (max-width: ${breakpoints.small}) {
		font-size: 12px;
		margin-bottom: 6px;
		svg {
			display: block;
			margin-bottom: 1em !important;
		}
	}
`;
