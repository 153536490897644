import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { ClassNames } from '@emotion/react';

import { useAriaLiveAnnouncer } from '~/components/AriaLiveAnnouncer';
import { withErrorBoundary } from '~/components/GenericErrorBoundary';
import { UniversalVelvetLeftBorder } from '~/components/pageElements';
import {
	AnswerStatus,
	QuestionChoices,
	QuestionPrompt,
	WebtextQuestion
} from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import { useAccessibilityFocus } from '~/hooks';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { MCQuestionAnswer, MCQuestionCorrectChoice } from '~/types';
import { QuestionChoice } from '~/types/WebtextManifest';
import shuffle from '~/utils/shuffle';

import MultipleChoiceQuestionHeading from './MultipleChoiceQuestionHeading';
import styles from './styles';

interface Props {
	questionFamilyId: string;
	body: string;
	choices: QuestionChoice[];
	onSubmit: (questionFamilyId: string, choiceFamilyId: string) => void | Promise<void>;
	answer?: MCQuestionAnswer;
	seed?: number;
	elementLabel?: string;
	onChangeSelectedChoice?: (questionFamilyId: string, choiceFamilyId: string) => void;
	readOnly?: boolean;
	showInstructorView?: boolean;
	isInQuestionDeck?: boolean;
	/**
	 * If correct answer is supplied as a prop then this was the user's last attempt and we should
	 * display the updated rejoinder.
	 */
	correctChoice?: MCQuestionCorrectChoice;
	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
	/**
	 * Whether to shuffle question choices or not. In Core, we shuffle ahead of time on the server, so in that case
	 * we don't want to shuffle twice and should set this to false.
	 *
	 * Defaults to true.
	 */
	shuffleChoices?: boolean;
	/**
	 * Whether a save network request is in progress. During this time, the student can't attempt to save again.
	 *
	 * Defaults to false.
	 */
	isSaving?: boolean;
}

export interface MultipleChoiceQuestionRef {
	rejoinderContainer: HTMLDivElement | null;
}

const MultipleChoiceQuestion = React.forwardRef<MultipleChoiceQuestionRef, Props>((props, ref) => {
	const {
		questionFamilyId,
		body,
		choices,
		answer,
		onSubmit,
		seed,
		elementLabel,
		onChangeSelectedChoice,
		readOnly,
		showInstructorView,
		isInQuestionDeck,
		correctChoice,
		shuffleChoices = true,
		isSaving = false
	} = props;

	const questionPromptBodyId = `mc_question_body_${questionFamilyId}`;

	const [userActed, setUserActed] = useState(false);
	const [selectedChoice, setSelectedChoice] = useState<string | null>(
		answer?.completed ? answer.body : null
	);

	const [setRejoinderRef, setFocusToRejoinder] = useAccessibilityFocus();
	const { makeAssertiveAnnouncement } = useAriaLiveAnnouncer();

	const rejoinderContainerRef = useRef<HTMLDivElement>(null);
	useImperativeHandle(ref, () => ({ rejoinderContainer: rejoinderContainerRef.current }));

	const shuffledChoices = useMemo(
		() =>
			seed != null && shuffleChoices ? shuffle(choices, `${seed}${questionFamilyId}`) : choices,
		[seed, shuffleChoices, choices, questionFamilyId]
	);

	useEffect(() => {
		if (answer == null) {
			setSelectedChoice(null);
		} else {
			setSelectedChoice(answer.body);
		}
	}, [answer]);

	const isDirty = selectedChoice && selectedChoice !== answer?.body;
	useUnsavedChangesWarning(isDirty);

	useEffect(() => {
		if (userActed && answer?.completed) {
			const announcement = getAnswerSavedAriaLiveMessage({ answer, choices, correctChoice });
			makeAssertiveAnnouncement(announcement);
			setFocusToRejoinder().then(() => {
				setUserActed(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userActed, answer]);

	const handleChangeSelectedChoice = (choiceFamilyId: string) => {
		onChangeSelectedChoice && onChangeSelectedChoice(questionFamilyId, choiceFamilyId);
		setSelectedChoice(choiceFamilyId);
	};

	const inner = (
		<ClassNames>
			{({ cx }) => (
				<>
					{!isInQuestionDeck && <MultipleChoiceQuestionHeading elementLabel={elementLabel} />}
					<div role="group" aria-labelledby={questionPromptBodyId}>
						<QuestionPrompt id={questionPromptBodyId} body={body} />
						<div className={cx('question', answer?.completed && 'answered')}>
							<QuestionChoices
								questionFamilyId={questionFamilyId}
								choices={shuffledChoices}
								disabled={readOnly || answer?.completed}
								selectedChoiceFamilyId={selectedChoice}
								onChangeSelectedChoice={handleChangeSelectedChoice}
							/>
							<div className="rejoinder" ref={rejoinderContainerRef}>
								{answer?.completed && (
									<>
										{/* we focus a dummy element instead of the actual rejoinder to avoid double-reading issues with screen readers;
							some screen readers will read focused elements, but JAWS will not.
							we're already announcing the rejoinder using an aria-live region, so we don't want it to be read twice */}
										<span tabIndex={-1} ref={setRejoinderRef} className="visually-hidden">
											&nbsp;
										</span>
										<span
											dangerouslySetInnerHTML={{ __html: answer.rejoinder }}
											data-testid="rejoinder-text"
										/>
										{correctChoice && !answer?.correct && (
											<div className="correct-answer-rejoinder">
												<div className="correct-answer">
													<span className="correct-info">Correct Answer: </span>
													<span
														dangerouslySetInnerHTML={{
															__html: shuffledChoices.find(
																(c) => c.family_id === correctChoice.family_id
															).body
														}}
													/>
												</div>
												<span
													dangerouslySetInnerHTML={{
														__html: correctChoice.rejoinder.replace('&nbsp;', '')
													}}
												/>
											</div>
										)}
									</>
								)}
							</div>
							{!showInstructorView && (
								<div className={cx('status-and-actions', { separator: !answer?.completed })}>
									{answer?.completed ? (
										<AnswerStatus
											suppressAria={true}
											updatedAt={answer.updated_at}
											saving={false}
											posting={false}
											unposting={false}
										/>
									) : (
										<div className="save-button-container">
											<WebtextButton
												value="Save"
												type="submit"
												onClick={async () => {
													await onSubmit(questionFamilyId, selectedChoice);
													setUserActed(true);
												}}
												disabled={isSaving || readOnly || !selectedChoice}>
												{isSaving ? 'Saving...' : 'Save'}
											</WebtextButton>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</ClassNames>
	);

	return (
		<WebtextQuestion
			noBottomMargin={props.noBottomMargin}
			className="multiple-choice-question-component"
			css={styles}
			{...(!isInQuestionDeck && { 'data-testid': 'mc-question' })}>
			{isInQuestionDeck ? inner : <UniversalVelvetLeftBorder>{inner}</UniversalVelvetLeftBorder>}
		</WebtextQuestion>
	);
});
MultipleChoiceQuestion.displayName = 'MultipleChoiceQuestion';

export default withErrorBoundary(MultipleChoiceQuestion);

function getAnswerSavedAriaLiveMessage({
	answer,
	choices,
	correctChoice
}: {
	answer: MCQuestionAnswer;
	choices: QuestionChoice[];
	correctChoice?: MCQuestionCorrectChoice;
}) {
	const announcementParts = [`Answer saved. ${answer.rejoinder}`];
	if (correctChoice) {
		const { body: rawCorrectChoiceBody } = choices.find(
			(choice) => choice.family_id === correctChoice.family_id
		);
		/**
		 * We should prepend "Correct Answer: " to the body of the correct answer; in addition,
		 * if the correct choice body doesn't end in punctuation, then in an aria-live announcement
		 * the correct choice body will lead directly into the rejoinder with no pause, which is confusing.
		 * Add a period in these cases to separate the two.
		 */
		const correctChoiceBody = `Correct Answer: ${
			rawCorrectChoiceBody.match(/[^.?!:;]$/) ? `${rawCorrectChoiceBody}.` : rawCorrectChoiceBody
		}`;
		announcementParts.push(correctChoiceBody);

		/**
		 * `correctChoice.rejoinder` contains the HTML `<span class=\"correct\">Correct.</span>&nbsp;`
		 * at the start of the string; we need to strip it out, otherwise the aria-live announcement
		 * will read "Correct choice: <choice body>. Correct. <rest of rejoinder text>" which is strange
		 * and redundant.
		 */
		const strippedCorrectChoiceRejoinder = correctChoice.rejoinder.replace(
			/^<span[^>]*>[^<]*<\/span>(&nbsp;)*/,
			''
		);
		announcementParts.push(strippedCorrectChoiceRejoinder);
	}
	return announcementParts.join('\n\n');
}
