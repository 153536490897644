import first from 'lodash-es/first';

import { getIsFormula, getIsRange, findMatches, getRangeAddresses } from './helpers';

interface AdjacencyList {
	[address: string]: string[];
}

export class Graph {
	private adjacencyList: AdjacencyList = {};

	/**
	 * Add should happens only once, otherwise real value, that hasn't been stored yet may be re-written by old value stored inside a cell
	 * Because several calculations happen when you're typing in a cell. This called once for fresh initialized cells
	 */
	public addVertex(address: string, value: string): void {
		if (this.adjacencyList[address]) {
			return;
		}

		this.adjacencyList[address] = this.getEdgesFromValue(value);
	}

	/**
	 * Update should happens after every type in a cell because several calculations happen when you're typing in a cell
	 * This called after each change.
	 */
	public updateVertex(address: string, value: string): void {
		this.adjacencyList[address] = this.getEdgesFromValue(value);
	}

	/**
	 * Collects all dependencies stack be Going through the transposed adjacencyList
	 * Returns all dependencies in topological order.
	 * @see {@link https://soomo.slab.com/posts/developer-notes-spreadsheet-template-data-layer-7digxk36#hr7p0-examples-of-the-topological-sorting-of-cells-during-an-update}
	 */

	public getSortedCellsDeps(addresses: string[]): string[] {
		/**
		 * Transpose adj list and get deps that cell depends on.
		 */
		const adjacencyList = this.transposeAdjList();

		const visited = new Map();
		const sorted = [];

		const availableKeys = Object.keys(adjacencyList);

		for (const vertex of availableKeys) {
			visited.set(vertex, false);
		}

		/**
		 * Visit every cell from addresses prop and make the sorted list
		 */
		for (const vertex of addresses) {
			if (!visited.get(vertex)) {
				for (const node of this.dfs(adjacencyList, visited, vertex)) {
					sorted.unshift(node);
				}
			}
		}

		return sorted;
	}

	private transposeAdjList(): AdjacencyList {
		const transposedAdjList: AdjacencyList = {};

		Object.entries(this.adjacencyList).forEach(([address, deps]) => {
			transposedAdjList[address] ||= [];

			deps.forEach((depAddress) => {
				/**
				 * When not exist, create element with value
				 */
				transposedAdjList[depAddress] ||= [address];
				const addresses = transposedAdjList[depAddress];
				/**
				 * Values should be uniq
				 */
				if (addresses.includes(address)) return;
				transposedAdjList[depAddress] = [...addresses, address];
			});
		});

		return transposedAdjList;
	}

	/**
	 * Using bfs inside this method; Looking a result for multiple including in a graph.
	 */
	public getIsCycleDependency(address: string): boolean {
		const dependenciesAddresses = [...this.bfs(address)];
		return dependenciesAddresses.includes(address);
	}

	private *dfs(
		adjacencyList: { [key: string]: string[] },
		visited: Map<string, boolean>,
		vertex: string
	): Generator<string> {
		visited.set(vertex, true);

		for (const neighbor of adjacencyList[vertex]) {
			if (!visited.get(neighbor)) {
				yield* this.dfs(adjacencyList, visited, neighbor);
			}
		}

		yield vertex;
	}

	private *bfs(startVertex) {
		const queue = [startVertex];
		const visited = {};
		visited[startVertex] = true;

		while (queue.length) {
			const currentVertex = queue.shift();
			const depsList = this.adjacencyList[currentVertex] || [];

			for (const dependency of depsList) {
				yield dependency;

				if (!visited[dependency]) {
					visited[dependency] = true;
					queue.push(dependency);
				}
			}
		}
	}

	private getEdgesFromValue(value: string): string[] {
		if (!getIsFormula(value)) {
			return [];
		}

		/**
		 * Parses arguments from formula =NAME(B1, B2:B3), =B1+B2, etc.
		 * Example - https://regexr.com/71q9v
		 */
		const getArgumentsRegex =
			/([A-Z]{1,2}\$?[0-9]{1,2}:[A-Z]{1,2}[0-9]{1,2})|([A-Z]{1,2}\$?[0-9]{1,2})/gi;

		const result = findMatches(getArgumentsRegex, value)
			.map((found) => first(found))
			.flatMap((element) => {
				if (!getIsRange(element)) return element;

				/**
				 * Helps to avoid inconsistent range like b5:C5 and always format it as B5:C5
				 */
				const normalizedAddressesRange = element.toUpperCase();
				const addressesRange = getRangeAddresses(normalizedAddressesRange);

				/**
				 * This may happen when we're unable to create range for some reasons
				 */
				if (!addressesRange.result) return;

				return addressesRange.value;
			})
			.filter(Boolean)
			/**
			 * Avoid storing lowercase address into the adj list;
			 */
			.map((value) => value.toUpperCase());

		return result;
	}
}
