import * as React from 'react';

export function ArrowInCircle(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<path d="M36 60.69a24.59 24.59 0 0 1-19.22-9.19l1.33-1.07a23 23 0 0 0 40-20.78l1.64-.47A24.72 24.72 0 0 1 36 60.69ZM12.17 42.5A24.73 24.73 0 0 1 36 11.31 24.49 24.49 0 0 1 47.25 14l-.78 1.52A22.74 22.74 0 0 0 36 13a23 23 0 0 0-22.18 29Z" />
			<path d="M36.17 53.73a17.71 17.71 0 0 1-14.51-7.61l1.38-1a16 16 0 0 0 29.18-9.34 16.53 16.53 0 0 0-.63-4.54l1.61-.46a17.92 17.92 0 0 1-17 22.91ZM18.49 37.27v-1.45a17.84 17.84 0 0 1 17.68-17.91 17.58 17.58 0 0 1 12 4.7L47 23.84a15.91 15.91 0 0 0-10.83-4.25 16.16 16.16 0 0 0-16 16.23c0 .44 0 .88.05 1.32l-1.68.13Z" />
			<path d="M11 51.86 13.27 54l18.55-19.85 8.23 7.13 18.54-18.14v5h3V17.3h-10.7v3.11H57L40 37.1l-8.34-7.22L11 51.86Zm2.3 4.52-4.62-4.45 22.79-24.38 8.39 7.26 13-12.72h-3.65v-6.47h14.11v14.24H56.9v-2.72L40.12 43.56l-8.18-7.08-18.6 19.9Z" />
		</svg>
	);
}
