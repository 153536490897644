import React from 'react';

import { hiddenAccessible } from '../AriaArea/styles';
import { style } from './styles';

interface Props {
	value: string;
}

const HeaderCell: React.FC<Props> = ({ value }) => {
	const isHidden = () => !value || value?.length === 0;
	const className = Number.isNaN(+value) ? 'cHeader' : 'header';

	return (
		<td aria-hidden={!isHidden()} className={className} css={style(isHidden())}>
			{!value || value?.length === 0 ? (
				<div css={hiddenAccessible}>Spreadsheet item click Enter to continue</div>
			) : (
				<div>{value}</div>
			)}
		</td>
	);
};

export default HeaderCell;
