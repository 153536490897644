import { useEffect, useState } from 'react';

import { CellsRefs } from './../helpers/types';

export function useElementsResize(
	cellElementsRefs: CellsRefs,
	cellsAddresses: string[] | null | undefined
): [string, number, number] {
	const [triggerIdentifier, setTriggerIdentifier] = useState<string>(null);
	const [cellsTotalHeight, setCellsTotalHeight] = useState<number>(null);
	const [cellsTotalWidth, setCellsTotalWidth] = useState<number>(null);

	useEffect(() => {
		if (!cellsAddresses) return;

		const updateSizes = () => {
			const totalHeight = cellsAddresses.reduce(
				(total, address) => (total += cellElementsRefs[address]?.current.offsetHeight || 0),
				0
			);

			const totalWidth = cellsAddresses.reduce(
				(total, address) => (total += cellElementsRefs[address]?.current.offsetWidth || 0),
				0
			);

			setCellsTotalHeight(totalHeight);
			setCellsTotalWidth(totalWidth);
			setTriggerIdentifier(cellsAddresses.join('/'));
		};

		/**
		 * Wrap with requestAnimationFrame to avoid "ResizeObserver loop limit exceeded" error
		 * took from here https://stackoverflow.com/a/58701523/10963661
		 */
		const resizeObserver = new ResizeObserver((entries) => {
			window.requestAnimationFrame(() => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				updateSizes();
			});
		});

		cellsAddresses
			.filter((address) => cellElementsRefs[address])
			.forEach((address) => resizeObserver.observe(cellElementsRefs[address].current));

		return () => resizeObserver.disconnect();
	}, [cellElementsRefs, cellsAddresses]);

	return [triggerIdentifier, cellsTotalHeight, cellsTotalWidth];
}
