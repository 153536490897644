import { SerializedStyles, css } from '@emotion/react';

import { Theme } from '~/styles/themes';

import { firefoxViewHeaderOffset } from '../../helpers/constants';

export default (theme: Theme, options: { top: number; left: number }): SerializedStyles => css`
	display: block;
	position: fixed;
	top: ${options.top}px;
	left: ${options.left}px;
	z-index: 1000;
	background: white;
	border-radius: 5px;
	box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.8);

	@supports (-moz-appearance: none) {
		top: ${options.top + firefoxViewHeaderOffset}px; // Firefox: header indent doesn't count here
	}

	*:focus {
		background: #eaeaea;
		outline: none;
	}

	button {
		cursor: pointer;
		padding: 8px 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 150px;
		border: none;
		background: white;
		border-radius: 5px;
		color: black !important;
		font-family: ${theme.fonts['helvetica-neue']};

		span {
			font-size: 14px;
		}

		.help {
			font-weight: bold;
			color: #666666;
		}
	}
`;
