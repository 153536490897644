import { css, SerializedStyles } from '@emotion/react';

import {
	fieldBlockDimensions,
	fieldInlineDimensions
} from '~/components/Outline/components/TextField/styles';
import { TemplateLabelType } from '~/components/Outline/types';
import { breakpoints, getThemeItem, Theme } from '~/styles/themes';

export const editTextFieldInput = (
	theme: Theme,
	options: { invalid: boolean }
): SerializedStyles => {
	const {
		colors: { white },
		webtextWritingTemplate: {
			fillIn: { border },
			outline: {
				invalid: { border: invalidBorder, background: invalidBackground }
			}
		},
		outlineTemplate: { interactiveItemsBorderRadius }
	} = theme;
	const fillInBorder = getThemeItem(border, theme);
	const outlineItemsBorderRadius = getThemeItem(interactiveItemsBorderRadius, theme);
	const outlineInvalidBorder = getThemeItem(invalidBorder, theme);
	const outlineInvalidBackground = getThemeItem(invalidBackground, theme);

	return css`
		font: inherit;

		padding: 0.4em;
		resize: none;
		appearance: none;
		background-color: ${white};
		border: ${fillInBorder};
		border-radius: ${outlineItemsBorderRadius};

		${options.invalid &&
		css`
			border: ${outlineInvalidBorder};
			background-color: ${outlineInvalidBackground};
		`}
	`;
};

export const textFieldValidationMessage = css`
	grid-area: validation-message;

	${fieldBlockDimensions};
	min-width: unset;

	font-size: 0.95em;

	> div {
		padding-bottom: 0;
	}

	@media (max-width: ${breakpoints.small}) {
		font-weight: initial; // Resets 'invalidText' bolding of the text
	}
`;

export const textFieldConstraintMessage = (
	theme: Theme,
	options: { labelType: TemplateLabelType }
): SerializedStyles => {
	const {
		colors: { 'less-dark-gray': lessDarkGray }
	} = theme;
	const { labelType } = options;

	return css`
		${textFieldValidationMessage};
		${
			labelType === 'block'
				? fieldBlockDimensions // Needs indentation margin
				: fieldInlineDimensions // Displayed right beneath the input. Doesn't need the indentation margin
		};

		@media (max-width: ${breakpoints.small}) {
			${fieldBlockDimensions}; // On small screens the indentation margin needed
		}

		grid-area: constraint-message;
		color: ${lessDarkGray};
	`;
};
