import dropRight from 'lodash-es/dropRight';
import last from 'lodash-es/last';

import { isNullOrUndefined } from '~/utils';

import type {
	OutlineInstanceAddress,
	OutlineInstanceAddressPoint,
	OutlineSectionAddress,
	OutlineSectionAddressPoint
} from '~/components/Outline/types';

/**
 * Serializes the array-based address to string
 *
 * `JSON.stringify` outperforms the custom serialization based on the `.reduce`
 * @see {@link https://www.measurethat.net/Benchmarks/ShowResult/379534}
 */
export const serializeAddress = (address: OutlineInstanceAddress): string =>
	JSON.stringify(address);

const hierarchyInstanceToSectionAddressPoint = (
	addressPoint: OutlineInstanceAddressPoint
): OutlineSectionAddressPoint | undefined => {
	const { childrenIndex, toggleIndex } = addressPoint;
	if (childrenIndex !== undefined) {
		return { childrenIndex };
	}
	if (toggleIndex !== undefined) {
		return { toggleIndex };
	}
};
/**
 * Returns the schema-friendly address, with only `childrenIndex` & `toggleIndex`
 *
 * @example
 * // returns [[{ c:1 }, { t:1 }]]
 * instanceToSectionAddress([{ c:1 }, { i:2 }, { t:1 }]);
 */
export const instanceToSectionAddress = (
	instanceAddress: OutlineInstanceAddress
): OutlineSectionAddress =>
	instanceAddress.map(hierarchyInstanceToSectionAddressPoint).filter(Boolean);

/**
 * Depth is considered as the number of the children address points in the address.
 * The depth starts from 1
 * @returns [1..∞)
 */
export const getAddressDepth = (address: OutlineInstanceAddress): number =>
	address.filter(({ childrenIndex }) => childrenIndex !== undefined).length;

export const getParentSectionAddress = (
	address: OutlineInstanceAddress
): OutlineInstanceAddress => {
	const instanceIndex = getAddressInstanceIndex(address);
	return isNullOrUndefined(instanceIndex) ? address : dropRight(address, 1); // The penultimate address point refers to the parent hierarchy section
};

export const getSiblingIndex = (
	address: OutlineInstanceAddress,
	direction: 'up' | 'down'
): number => {
	const currentIndex = getLastAddressPointIndex(address);
	return direction === 'up' ? currentIndex - 1 : currentIndex + 1;
};

export const getSiblingAddress = (
	...args: Parameters<typeof getSiblingIndex>
): OutlineInstanceAddress => {
	const [address] = args;
	const newIndex = getSiblingIndex(...args);

	let newAddressPoint: OutlineInstanceAddressPoint;
	const { childrenIndex, toggleIndex, instanceIndex } = last(address);
	if (childrenIndex !== undefined) {
		newAddressPoint = { childrenIndex: newIndex };
	} else if (toggleIndex !== undefined) {
		newAddressPoint = { toggleIndex: newIndex };
	} else if (instanceIndex !== undefined) {
		newAddressPoint = { instanceIndex: newIndex };
	}

	return [...address.slice(0, -1), newAddressPoint];
};

export const getAddressInstanceIndex = (address: OutlineInstanceAddress): number | undefined => {
	const lastAddressPoint = last(address);
	return lastAddressPoint.instanceIndex;
};

export const getLastAddressPointIndex = (address: OutlineInstanceAddress): number | undefined => {
	const { childrenIndex, toggleIndex, instanceIndex } = last(address);
	return childrenIndex ?? toggleIndex ?? instanceIndex;
};
