import React, { FC, useMemo } from 'react';

import { isNil } from 'lodash-es';
import pluralize from 'pluralize';

import { ExpandablePanel } from '~/notebook/components/ExpandablePanel';
import { getHasLimitedAttempts } from '~/utils/policy';

export interface Props {
	attemptsAllowed?: number;
	attemptCount?: number;
	collapseDelimiter?: boolean;
	answered?: boolean;
	className?: string;
}

const ActivityHistory: FC<Props> = (props) => {
	const {
		attemptCount,
		attemptsAllowed,
		collapseDelimiter = true,
		answered = false,
		className,
		children
	} = props;

	const showAttemptsRemaining = !isNil(attemptsAllowed) && getHasLimitedAttempts(attemptsAllowed);
	const attemptsRemaining = useMemo(() => {
		const remaining = Math.max(attemptsAllowed - attemptCount, 0);

		// After attempts exhaustion when instructor resets the question,
		// it gets unlocked and student can leave another answer.
		// See https://soomo.height.app/T-98435
		if (remaining === 0 && !answered) {
			return 1;
		}
		return remaining;
	}, [answered, attemptCount, attemptsAllowed]);

	return (
		<ExpandablePanel
			title="Activity History"
			collapseDelimiter={collapseDelimiter}
			className={className}
			sideLabel={
				<>
					{attemptCount > 0 && (
						<span data-test="activity-history-attempts-number">
							{pluralize('attempt', attemptCount, true)}
						</span>
					)}
					{showAttemptsRemaining && (
						<span data-test="activity-history-attempts-remaining">
							&nbsp;&mdash;&nbsp;
							{pluralize('attempt', attemptsRemaining, true).replace('0', 'NO')} remaining
						</span>
					)}
				</>
			}>
			{children}
		</ExpandablePanel>
	);
};

export default ActivityHistory;
