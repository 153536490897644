import React from 'react';

import { StudentViewLmsSubmission } from '~/components/pageElements/LmsSubmission';

import { BuilderUserBackgroundJob, IDictionary, LmsData } from '../WritingTemplate/types';

interface Props {
	lmsData: LmsData;
	onCancelLMS: (message: string | null) => void;
	generateAndSubmit: () => void;
	outputButtons: IDictionary;
	job?: BuilderUserBackgroundJob;
}

const WritingTemplateLmsSubmission: React.FC<Props> = ({
	lmsData,
	onCancelLMS,
	generateAndSubmit,
	outputButtons,
	job
}) => {
	const { answerId, elementType, elementFamilyId, courseId, chapterId, pageId, Rollbar, events } =
		lmsData || {};

	return (
		<StudentViewLmsSubmission
			answerId={answerId}
			job={job}
			cancel={onCancelLMS}
			editSubmission={() => onCancelLMS(null)}
			elementType={elementType}
			elementFamilyId={elementFamilyId}
			courseId={courseId}
			chapterId={chapterId}
			pageId={pageId}
			Rollbar={Rollbar}
			events={events}
			generateAndSubmit={generateAndSubmit}
		/>
	);
};

export default WritingTemplateLmsSubmission;
