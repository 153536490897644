import React from 'react';

import { css } from '@emotion/react';
import cn from 'classnames';

import { breakpoints, ThemeName } from '~/styles/themes';
import { ResponseBoardPost } from '~/types';
import { formatTimeLong } from '~/utils/formatting';

import SvgPinned from './SvgPinned';

interface Props {
	answer: ResponseBoardPost;
	variant?: 'card';
	hideAttribution?: boolean;
}

const PostedAnswer: React.FC<Props> = (props: Props) => {
	const { answer, variant, hideAttribution } = props;
	return (
		<div css={styles}>
			<div
				className={cn('posted-answers-container', {
					pinned: answer.isPinned,
					'card-variant': variant === 'card'
				})}>
				{answer.isPinned && (
					<div className="pinned-by">
						<SvgPinned />
						<span>{`Pinned by ${answer.pinnedBy}`}</span>
					</div>
				)}
				<div className="body">{answer.body}</div>
				{!hideAttribution && (
					<div className="signature">
						<div className="author">
							&mdash;{' '}
							{answer.author.name && <span className="name">{`${answer.author.name} -`}</span>}{' '}
							<span className="time chromatic-ignore">
								{formatTimeLong({ time: answer.postedAt, timeZone: 'local' })}
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const styles = (theme) => {
	const { responseBoard } = theme;
	return css`
		font-size: ${theme.webtextQuestion.fontSize} !important;

		.posted-answers-container {
			margin-bottom: 2.5em;

			@media (max-width: ${breakpoints.small}) {
				margin-bottom: 16px;
			}
		}

		.body {
			font-size: ${responseBoard.postedAnswer.body.fontSize};
			line-height: ${responseBoard.postedAnswer.body.lineHeight};
			color: ${responseBoard.postedAnswer.body.color};
			white-space: pre-wrap;
			overflow-wrap: break-word;
		}

		.author {
			font-style: ${responseBoard.postedAnswer.author.fontStyle};
			font-size: ${responseBoard.postedAnswer.author.fontSize};
			line-height: ${responseBoard.postedAnswer.author.lineHeight};
			color: ${responseBoard.postedAnswer.author.color};
		}

		.pinned {
			padding: 0.75em 1.25em;
			margin-bottom: 1.5em;
			border: 1px solid ${theme.colors.primary};
		}

		.pinned-by {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 0.3em;

			span {
				font-weight: ${responseBoard.postedAnswer.pinnedBy.fontWeight};
				font-size: ${responseBoard.postedAnswer.pinnedBy.fontSize};
				line-height: ${responseBoard.postedAnswer.pinnedBy.lineHeight};
				color: ${responseBoard.postedAnswer.pinnedBy.color};
				text-transform: uppercase;
				letter-spacing: 0.4px;
				margin-left: 0.5em;
			}
		}

		.card-variant {
			&.posted-answers-container {
				background-color: #404449;
				margin-bottom: 0em;
				padding: 1em;
			}

			.body {
				color: white;
			}

			.author {
				color: white;
			}
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.posted-answers-container {
				.body {
					font-size: 18px;
				}

				.author {
					font-size: 15px;
				}

				.signature {
					margin-top: 8px;
				}
			}

			@media (max-width: ${breakpoints.small}) {
				.posted-answers-container {
					.body {
						font-size: 15px;
					}

					.author {
						font-size: 14px;
					}
				}
			}
		`}
	`;
};

export default PostedAnswer;
