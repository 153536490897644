import React from 'react';

import cn from 'classnames';

interface Props {
	onClick: (evt: React.MouseEvent) => void;
	disabled: boolean;
}

const PrevQuestionButton = ({ onClick, disabled }: Props) => {
	return (
		<button
			aria-label="go back to previous question"
			className={cn('ally-ignore', 'navigation-icon', {
				'navigation-disabled': disabled
			})}
			disabled={disabled}
			onClick={onClick}>
			<svg viewBox="0 0 465 1000" preserveAspectRatio="none">
				<path d="m452 870c17.333 17.333 17.333 33.333 0 48-17.333 17.333-33.333 17.333-48 0 0 0-392-394-392-394-16-16-16-32.667 0-50 0 0 392-394 392-394 14.667-17.333 30.667-17.333 48 0 17.333 14.667 17.333 30.667 0 48 0 0-358 372-358 372s358 370 358 370" />
			</svg>
		</button>
	);
};

export default PrevQuestionButton;
