import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { loaderStyles } from '~/components/Loaders';

interface Props {
	size?: string | number;
	'aria-hidden'?: boolean;
}

const Indeterminate: React.VFC<Props> = (props) => (
	<span css={loaderStyles}>
		<CircularProgress size={props.size} aria-hidden={props['aria-hidden'] ? 'true' : undefined} />
	</span>
);

export default Indeterminate;
