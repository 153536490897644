import React, { FC } from 'react';

import { expandablePanelStyles } from '../../ExpandablePanel/ExpandablePanel';
import ActivityHeader from '../ActivityHeader';
import { getDecisionEventLabel } from './utils';

import type { Props as VersionHistoryItemProps } from './VersionHistoryItem';
import type { DecisionVersion } from '~/notebook/types';

interface Props extends VersionHistoryItemProps {
	version: DecisionVersion;
}

const DecisionVersionHistoryItem: FC<Props> = (props) => {
	const { version, studentId, timeZone } = props;

	const eventLabel = getDecisionEventLabel(version, studentId);

	return (
		<div css={expandablePanelStyles.historyItem}>
			<ActivityHeader timestamp={version.created_at} timeZone={timeZone}>
				{eventLabel.toUpperCase()}
			</ActivityHeader>
		</div>
	);
};

export default DecisionVersionHistoryItem;
