import React, { FC } from 'react';

import { expandablePanelStyles } from '../../ExpandablePanel/ExpandablePanel';
import ActivityHeader from '../ActivityHeader';

import type { Props as VersionHistoryItemProps } from './VersionHistoryItem';
import type { CommentVersion } from '~/notebook/types';

interface Props extends VersionHistoryItemProps {
	version: CommentVersion;
}

const CommentVersionHistoryItem: FC<Props> = (props) => {
	const {
		version: { created_at: createdAt, seen_at: seenAt, body, event },
		showSeenCommentStatus = true,
		concealedCommentContent,
		timeZone
	} = props;

	const isConcealed = !seenAt && concealedCommentContent;
	return (
		<div css={expandablePanelStyles.historyItem}>
			<ActivityHeader timestamp={createdAt} timeZone={timeZone}>
				COMMENT {event.toUpperCase()}
				{showSeenCommentStatus && <i> ({seenAt ? 'Seen' : 'Unseen'})</i>}
			</ActivityHeader>
			<div
				data-test="activity-history-item-body"
				className="activity-history-item-body comment-version"
				css={(theme) =>
					expandablePanelStyles.historyItemBody(theme, {
						variant: isConcealed ? 'default' : 'comment'
					})
				}>
				{isConcealed ? concealedCommentContent : <i>{body}</i>}
			</div>
		</div>
	);
};

export default CommentVersionHistoryItem;
