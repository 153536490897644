import first from 'lodash-es/first';

import { findMatches } from '..';
import { availableFormulaNames } from '../constants';
import { checkFormula } from './utils';
import { getRangeWithCaret, isInsideExtFormula } from '../../Components/Cell/utils';

export const parseFormulaArguments = (
	text: string
): { name: string; elements: { name: string; range: number[]; idx: number }[] } => {
	/**
	 * vText is the content that shows up in the tooltip while the student is entering the function. Adding a matching closing paren in that tooltip value helps guide the student.
	 */
	const vText = text.endsWith(')') ? text : `${text})`;

	const formulas = findMatches(/([A-Z]+)(\()([^).]+)?(\)?)/gi, getValue(vText, true));

	if (formulas.length > 0) {
		const [, name, , elements] = first(formulas);

		const elementsArray = getFormulaBodyElements(elements);

		if (availableFormulaNames.includes(name?.toUpperCase())) {
			if (elementsArray.length === 0) {
				return {
					name,
					elements: [
						{
							name: '',
							idx: 0,
							range: [name.length + 1, name.length + 1]
						}
					]
				};
			}

			let lastIndex = 0;

			const elements = elementsArray.map((e, idx) => {
				const range = getArgsRanges(e, text.slice(lastIndex))[0];

				const rr = range
					? [range.index + lastIndex, range.index + range[0].length + lastIndex]
					: [lastIndex + 1, lastIndex + 1];

				if (range) {
					lastIndex = range.index + e.length + lastIndex;
				}

				return {
					name: e,
					range: rr,
					idx
				};
			});

			return {
				name,
				elements
			};
		}
	}

	return null;
};

/**
 * This method iterates over userInput = remainingFunctionText, and return position of arguments in it
 * example:
 * iteration 1: A1, SUM(A1;A2;A3;A4;A5); - return position;
 * iteration 2: A2, ;A2;A3;A4;A5); - return position;
 * ...
 */
const getArgsRanges = (functionsArgument: string, remainingFunctionText: string) => {
	/**
	 * Multiple insertions like (*** or [[[) might break this regex creator,
	 * that's why we need to replace globally. Was found 20.10.
	 * I'd prefer to use 1 line regex here, but to make it less confusing we're using "chain replace" with splitted regex in it
	 */
	const regex = new RegExp(
		'\\s*' +
			functionsArgument
				?.replace(/\]/g, '\\]')
				?.replace(/\[/g, '\\[')
				?.replace(/\+/g, '\\+')
				?.replace(/-/g, '\\-')
				?.replace(/\|/g, '\\|')
				?.replace(/\*/g, '\\*')
				?.replace(/\//g, '\\/')
				?.replace(/\^/g, '\\^')
				?.replace(/\(/g, '\\(')
				?.replace(/\)/g, '\\)')
				?.replace(/\$/g, '\\$')
				?.replace(/\?/g, '\\?')
				?.replace(/\{/g, '\\{')
				?.replace(/\}/g, '\\}') +
			'\\s*',
		'gi'
	);

	return findMatches(regex, remainingFunctionText);
};

const getValue = (value: string, split?: boolean): string => {
	const comaBr = /\),/gi;
	const semiBr = /\);/gi;
	const doubleBr = /\)\)/gi;

	const result = value.replace(comaBr, '},').replace(semiBr, '};').replace(doubleBr, '})');

	return !split ? result.split(' ').join('') : result;
};

const getFormulaBodyElements = (elementString: string): string[] => {
	if (!elementString) return [];

	const formulasInElements = findMatches(
		/([A-Z]+)(\()(.+?)?(\)|})/gi,
		elementString?.toUpperCase()
	);

	if (formulasInElements.length > 0) {
		const formulaBodies = [];
		let replaceIdx = 0;

		return elementString
			.replace(/([A-Z]+)(\()(.+?)?(\)|})/gi, (formula) => {
				formulaBodies.push(formula);
				return 'replace';
			})
			.split(/;|,/)
			.map((e) => {
				if (e.trim() === 'replace') {
					const body = formulaBodies[replaceIdx].trim();
					replaceIdx += 1;
					return body.replace('}', ')');
				}

				return e.trim();
			});
	}

	return elementString
		.split(/;|,/)
		.map((e) => e?.replace(/\([0-9]+(\.[0-9]+)?\}/gi, (match) => match?.slice(1, -1)))
		.map((e) => e?.trim());
};

export const getTooltipAttributes = (inputValue: string, caretPosition: number) => {
	const formulas = checkFormula(inputValue);
	const formulaRanges = formulas.map((e) => [e.index, e.index + e[0].length]);

	if (!isInsideExtFormula(formulaRanges, caretPosition)) return null;

	const rangeWithCaret = getRangeWithCaret(formulaRanges, caretPosition);
	if (rangeWithCaret.length <= 0) return null;

	const [start, end] = first(rangeWithCaret);
	const parsedFormula = parseFormulaArguments(inputValue.slice(start, end));
	if (!parsedFormula) return null;

	const { name, elements } = parsedFormula;
	const activeElement = elements.find((element) => {
		const { range } = element;

		const [fStart, fEnd] = range;

		return caretPosition >= fStart + start && caretPosition <= fEnd + start;
	});

	const formulaBodyRange = [
		[start + name?.length + 1, inputValue.slice(start, end).endsWith(')') ? end - 1 : end]
	];

	if (!isInsideExtFormula(formulaBodyRange, caretPosition)) return null;

	return {
		name,
		activeElement: activeElement ? activeElement.idx : null,
		elementsNumber: elements?.length
	};
};

interface FormulaAttributes {
	[name: string]: {
		name: string;
		getArgs: (length?: number) => string[];
	};
}

export const formulaAttributes: FormulaAttributes = {
	fv: {
		getArgs: (): string[] => ['rate', 'nper', 'pmt', 'pv'],
		name: 'FV'
	},
	irr: {
		getArgs: (): string[] => ['values', '[guess]'],
		name: 'IRR'
	},
	sum: {
		getArgs: (active: number): string[] => {
			if (active && active > 0) {
				const arr = Array.from({ length: active }).map((_, idx) => {
					if (idx === 0) {
						return `number1`;
					}

					return `[number${idx + 1}]`;
				});

				return arr.concat([`[number${active + 1}], ...`]);
			}

			return ['number1', '[number2], ...'];
		},
		name: 'SUM'
	},
	rate: {
		getArgs: (): string[] => ['nper', 'pmt', 'pv', '[fv]', '[type]', '[guess]'],
		name: 'RATE'
	},
	npv: {
		getArgs: (length: number): string[] => {
			if (length && length > 2) {
				const arr = Array.from({ length })
					.map((_, idx) => {
						if (idx === 0) return 'rate';
						else if (idx === 1) return 'value1';

						return `value${idx}`;
					})
					.concat([`value${length}, ...`]);

				return arr;
			}

			return ['rate', 'value1', 'value2, ...'];
		},
		name: 'NPV'
	},
	pv: {
		getArgs: (): string[] => ['rate', 'nper', 'pmt', '[fv]', '[type]'],
		name: 'PV'
	}
};
