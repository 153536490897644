import debounce from 'lodash-es/debounce';

import { elementVisibleOnScreen } from './';

function isDocumentHidden() {
	const document = window.document as any;
	let hidden = null;
	if (typeof document.hidden !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
	} else if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden';
	} else if (typeof document.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden';
	}
	return document[hidden];
}

function getDocumentVisibilityEventName() {
	if (typeof document['hidden'] !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		return 'visibilitychange';
	} else if (typeof document['msHidden'] !== 'undefined') {
		return 'msvisibilitychange';
	} else if (typeof document['webkitHidden'] !== 'undefined') {
		return 'webkitvisibilitychange';
	} else {
		return null;
	}
}

/**
 * Utility class for performing the same async action repeatedly
 * while pausing while in the background (tab/window not visible)
 *
 * Can optionally use an element's visbility in page to determine
 * when to pause
 */
export default class Polling {
	intervalId = null;
	pollCount = 0;
	listeningForPollingEvents = false;
	poll = null;
	pollInterval = null;
	maxIntervals = null;
	visibilityChange = null;

	// Cancels polling if the HTML node in 'element' is no longer in view.
	stopPollingIfElementNotInView = true;
	element: HTMLElement = null;

	// When stopPollingIfElementNotInView is true, scrollListenerSelector
	// controls what element to listen to scroll events on
	scrollListenerSelector = '#content';

	debouncedPollingSwitch = debounce(() => {
		if (isDocumentHidden()) {
			this.cancelPolling();
			return;
		}

		if (this.stopPollingIfElementNotInView && !elementVisibleOnScreen(this.element)) {
			if (this.element == null) {
				throw new Error('stopPollingIfElementNotInView is enabled, but element is not set');
			}
			this.cancelPolling();
			return;
		}

		this.pollForUpdates();
	}, 500);

	constructor(poll, pollInterval, maxIntervals) {
		this.poll = poll;
		this.pollInterval = pollInterval;
		this.maxIntervals = maxIntervals;
		this.visibilityChange = getDocumentVisibilityEventName();
	}

	resetPollCount() {
		this.pollCount = 0;
	}

	pollForUpdates() {
		if (this.intervalId) {
			return;
		}
		// When we resume polling, we want to immediately perform a request.
		// Mainly so that a return to the tab (visibility change) will result
		// in an immediate refresh.
		if (this.pollCount > 0) {
			this.executePollRequest();
		}
		this.intervalId = setInterval(this.executePollRequest, this.pollInterval);
	}

	executePollRequest = () => {
		this.pollCount += 1;
		if (this.pollCount > this.maxIntervals) {
			// nothing has happened in awhile, so give up. We'll automatically
			// start polling again if they scroll with the component in the viewport
			this.cancelPolling();
		} else {
			this.poll();
		}
	};

	cancelPolling() {
		clearInterval(this.intervalId);
		this.intervalId = null;
		this.pollCount = 0;
	}

	ensurePollingIsActive() {
		if (this.listeningForPollingEvents) {
			return;
		}
		this.listeningForPollingEvents = true;
		this.debouncedPollingSwitch();
		if (this.stopPollingIfElementNotInView) {
			document
				.querySelector(this.scrollListenerSelector)
				.addEventListener('scroll', this.debouncedPollingSwitch);
		}

		if (this.visibilityChange) {
			document.addEventListener(this.visibilityChange, this.debouncedPollingSwitch);
		}
	}

	removePollingEventListeners() {
		if (!this.listeningForPollingEvents) {
			return;
		}
		this.listeningForPollingEvents = false;
		if (this.stopPollingIfElementNotInView) {
			document
				.querySelector(this.scrollListenerSelector)
				.removeEventListener('scroll', this.debouncedPollingSwitch);
		}

		if (this.visibilityChange) {
			document.removeEventListener(this.visibilityChange, this.debouncedPollingSwitch);
		}
	}
}
