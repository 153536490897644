import * as React from 'react';

function HistoricalClaim(): JSX.Element {
	return (
		<svg
			aria-hidden="true"
			focusable="false"
			viewBox="0 0 26 26"
			height="1em"
			width="1em"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fill="currentColor"
				d="M17.1055332,6.34626087 L10.402165,6.34626087 C9.42940439,7.40945863 8.84335354,8.73786385 8.71524845,10.1734176 L14.5146975,10.1734176 C15.0055675,10.1734176 15.4042497,10.5730047 15.4042497,11.0596131 C15.4042497,11.5456294 15.0055675,11.9452166 14.5146975,11.9452166 L8.79007619,11.9452166 C8.899624,12.5632447 9.09537336,13.1581855 9.37133806,13.7170155 L14.5146975,13.7170155 C15.0055675,13.7170155 15.4042497,14.1166026 15.4042497,14.602619 C15.4042497,15.0880433 15.0055675,15.4882225 14.5146975,15.4882225 L10.6434096,15.4882225 C10.6547834,15.4994701 10.6655586,15.5113097 10.6763338,15.5225573 C11.9591806,16.7905805 13.6520834,17.489118 15.4653091,17.489118 C16.7092455,17.489118 17.9256452,17.1599766 18.9295342,16.5449084 L18.9295342,8.14529093 C18.9295342,7.13477948 18.1267822,6.34626087 17.1055332,6.34626087 Z"
				id="Path"></path>
			<path
				fill="currentColor"
				d="M25.1436957,21.4884348 L19.4926522,15.8373913 C20.3828696,14.2807826 20.857087,12.5127826 20.857087,10.6713043 C20.857087,7.88534783 19.773,5.26613043 17.8026522,3.29691304 C15.834,1.32713043 13.2147826,0.242478261 10.4288261,0.242478261 C7.64286957,0.242478261 5.02365217,1.32713043 3.05443478,3.29804348 C1.08465217,5.26669565 0,7.88591304 0,10.6718696 C0,13.4578261 1.08465217,16.0764783 3.05443478,18.0456957 C5.02365217,20.0160435 7.64286957,21.1001304 10.4288261,21.1001304 C12.5116522,21.1001304 14.500087,20.4942174 16.1946087,19.3654783 L21.7297826,24.9012174 C22.2011739,25.3720435 22.8183913,25.6077391 23.4367391,25.6077391 C24.0539565,25.6077391 24.6723043,25.3720435 25.1436957,24.9012174 C26.085913,23.959 26.085913,22.4312174 25.1436957,21.4884348 Z M16.153913,16.3963913 C14.6244348,17.9258696 12.5913478,18.7674783 10.4288261,18.7674783 C8.26630435,18.7674783 6.23321739,17.9258696 4.70430435,16.3963913 C3.17482609,14.8674783 2.33321739,12.8343913 2.33321739,10.6718696 C2.33321739,8.50934783 3.17426087,6.47569565 4.70373913,4.94678261 C6.23265217,3.41786957 8.26630435,2.57569565 10.4282609,2.57569565 C12.5913478,2.57569565 14.6244348,3.41730435 16.1533478,4.94678261 C19.310087,8.10352174 19.310087,13.2402174 16.153913,16.3963913 Z"
				id="Shape"
				transform="translate(12.925179, 12.925109) scale(-1, 1) translate(-12.925179, -12.925109) "></path>
		</svg>
	);
}

export default HistoricalClaim;
