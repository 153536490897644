import { SerializedLexicalNode } from 'lexical';

import { IDictionary } from '../WritingTemplate/types';

export enum EditorType {
	RichText = 'richtext',
	TextArea = 'textarea'
}

export enum ListType {
	UL = 'ul',
	/**
	 * This value is gonna be used in future releases of RTE.
	 */
	OL = 'ol'
}

interface CitationOptions {
	enabled: boolean;
	['source-list-ref']: string;
}

interface CitationsData {
	identifier: string;
	/**
	 * We've changed this property when moved from wt1 -> wt2, but it could be received somewhere in deprecated courses
	 */
	identifer?: string;
	key: string;
	attributes: CitationResponseAttributes;
	markerValue: string;
}

export interface CitationObjectValue {
	kind: string;
	text: string;
	['source-list-ref']: string;
	citations: CitationsData[];
}

interface EditorOptions {
	type: EditorType;
	'allow-paragraphs'?: boolean;
	'allow-lists'?: ListType[];
}

export interface ValidationParams {
	minimum: number;
	maximum: number;
	optimum: number;
}

export interface ValidationOptions {
	'word-count'?: ValidationParams;
	'letter-count'?: ValidationParams;
	'reject-unrecognized-words'?: boolean;
	'reject-excess-repetition'?: boolean;
}

export interface RTESchemaOptions {
	editor: EditorOptions;
	citations?: CitationOptions;
}

export interface StyleOptions {
	presentation: string;
	isTable: boolean;
	isInlineBox: boolean;
}

export interface InputOptions {
	validation: ValidationOptions;
	schemaOptions: RTESchemaOptions;
	styleOptions: StyleOptions;
	readOnly: boolean;
	label?: string;
}

export interface SchemaReferences {
	toc: IDictionary;
	imports: IDictionary;
}

export type CitationLoader = (dest: string, citations: CitationsData[]) => void;

export interface EditorSpecificOptions {
	isAllowMultiParagraph: boolean;
	allowedLists: ListType[];
	readOnly: boolean;
}

export interface CitationResponseAttributes {
	/**
	 * Citation style options selected in a step2; may not exist in citations turabian
	 * Page number can exist in citations with extra input
	 * both are optional
	 */
	style?: string;
	'page-number'?: string;
}

export interface CitationResponse {
	attributes: CitationResponseAttributes;
	identifier: string;
	key: string;
	markerValue: string;
}

export interface AvailableCitation {
	['options-label']: string;
	['options-value']: string;
	['supported-styles']: IDictionary;
}

export interface CitationsConfig {
	citations?: AvailableCitation[];
	style?: CitationStyle;
}

export enum CitationStyle {
	SWS = 'sws',
	APA = 'apa',
	Turabian = 'turabian'
}

export type CitationExport = Omit<CitationResponse, 'identifier'>;

export type CitationMode = 'create' | 'update';

export interface SerializedCitationNode extends SerializedLexicalNode {
	version: number;
	type: string;
	identifier: string;
	decoratorText: string;
	citationKey: string;
	attributes: CitationResponseAttributes;
}
