import React, { FC } from 'react';

import { ShapedPollData } from '~/components/pageElements/PollQuestion/hooks';
import { useMediaBreakpoint } from '~/hooks';

import SmallComparisonTable from './SmallComparisonTable';
import WideComparisonTable from './WideComparisonTable';

import type { Props as RefreshedPollResultsProps } from '~/components/pageElements/PollQuestion/RefreshedPoll/ResultsTabs';

export interface Props
	extends Pick<
		RefreshedPollResultsProps,
		'courseNumber' | 'classData' | 'dataType' | 'totalCount' | 'contextualInfo'
	> {
	shapedData: ShapedPollData;
	totalPercentageSums: number[];
	classTotal: number;
	classmatesRespondedMessage: string;
}

const ComparisonTable: FC<Props> = (props) => {
	const isNarrowScreen = useMediaBreakpoint('small', 'max-width');
	return (
		<div>
			{isNarrowScreen ? <SmallComparisonTable {...props} /> : <WideComparisonTable {...props} />}
		</div>
	);
};

export default ComparisonTable;
