import { css } from '@emotion/react';

import { breakpoints, fonts, ThemeName } from '~/styles/themes';

const commonStyles = (theme) => css`
	width: calc(100% - 30px);
	display: flex;
	align-items: center;
	margin: 30px 0 30px 30px;

	// Bottom margin should be on the root of the component so it can be negated with page rules
	@media (max-width: ${breakpoints.small}) {
		margin: 15px 0;
	}

	.webtext-example-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 30px;
		height: 30px;
		flex-shrink: 0;

		&.right {
			background-color: ${theme.webtextExample.decorationColorRight};
		}

		&.wrong {
			background-color: ${theme.webtextExample.decorationColorWrong};
		}

		&.star {
			background-color: ${theme.colors.primary};
		}

		svg {
			color: white;
		}
	}

	.webtext-example-wrapper {
		max-width: 100%;
		flex: 1 0 95%;

		.webtext-example,
		.webtext-question-example {
			.table-figure-container {
				margin-left: calc(-1 * var(--side-size-left));
				margin-right: calc(-1 * var(--side-size-right));
				padding-left: var(--side-size-left);
				padding-right: var(--side-size-right);

				& > table {
					border-right: none;
				}
			}
		}
	}
`;

const blueVelvetStyles = (theme) => css`
	.webtext-example-wrapper {
		.webtext-example,
		.webtext-question-example {
			--side-size-right: 10px;
			--side-size-left: 30px;

			padding: 0 var(--side-size-right) 15px var(--side-size-left);
			border-left: 5px solid ${theme.webtextExample.borderColor};

			@media (max-width: ${breakpoints.small}) {
				--side-size-left: 15px;
				margin: 0;
			}

			.webtext-example-label {
				background-color: ${theme.colors.primary};
				color: ${theme.colors.secondary};
				margin: 0 -10px 20px -30px;
				padding: 5px 0 3px 30px;
				font-size: 0.9em;
				font-family: ${fonts['helvetica-neue-default']};
			}

			& > :first-child:not(.webtext-example-label) {
				&.webtext-text > :first-child {
					margin-top: 0;
				}

				padding-top: 20px !important;
				margin-top: 0;
			}

			& > :last-child {
				margin-bottom: 5px;
			}

			ul {
				list-style: disc;
				margin-left: 30px;
			}
		}

		.webtext-example {
			background-color: ${theme.webtextExample.backgroundColor};

			&.right {
				border-color: ${theme.webtextExample.decorationColorRight};
				border-left: 5px solid ${theme.webtextExample.decorationColorRight};
			}

			&.wrong {
				border-color: ${theme.webtextExample.decorationColorWrong};
				border-left: 5px solid ${theme.webtextExample.decorationColorWrong};
			}
		}

		.webtext-question-example {
			background-color: transparent;
		}
	}
`;

const universalVelvetStyles = (theme) => css`
	.webtext-example-wrapper {
		.webtext-example,
		.webtext-question-example {
			> :first-child:not(.webtext-example-label) {
				margin-top: 0;
			}

			> :last-child {
				margin-bottom: 0;
			}

			&.nested-content {
				p {
					font-family: ${theme.fonts.webtext};
				}
			}
		}

		.webtext-example {
			--side-size-right: 40px;
			--side-size-left: 34px;
			padding: 40px var(--side-size-right) 40px var(--side-size-left);

			border-left: 6px solid #d7d7d7;
			background: #f5f5f5;

			&.right {
				border-color: ${theme.webtextExample.decorationColorRight};
				border-left: 6px solid ${theme.webtextExample.decorationColorRight};
			}

			&.wrong {
				border-color: ${theme.webtextExample.decorationColorWrong};
				border-left: 6px solid ${theme.webtextExample.decorationColorWrong};
			}

			&.star {
				border-color: ${theme.colors.primary};
				border-left: 6px solid ${theme.colors.primary};
			}

			@media (max-width: ${breakpoints.large}) {
				--side-size-right: 30px;
				--side-size-left: 24px;
				padding-top: 30px;
				padding-bottom: 30px;
			}

			@media (max-width: ${breakpoints.small}) {
				--side-size-right: 20px;
				--side-size-left: 14px;
				padding-top: 20px;
				padding-bottom: 20px;

				margin: 0;

				p {
					margin-bottom: 20px;
				}
			}
		}

		.webtext-question-example {
			border: 1px solid ${theme.webtextExample.borderColor};
			border-radius: 10px;

			> :first-child:not(.webtext-example-label) {
				padding-top: 15px !important;
			}
		}
	}

	@media (max-width: ${breakpoints.small}) {
		p + .webtext-example-wrapper {
			.webtext-example {
				margin: -15px 0 15px;
			}
		}
	}

	.webtext-question-example {
		padding: 22px 27px 24px;
		margin: -6px -30px 0; // Compensate for the margin on the WebtextExample
		font-family: ${fonts['haas-grotesk']};

		@media (max-width: ${breakpoints.small}) {
			margin-top: -2px;
			margin-left: 0;
			padding: 13px 20px;
		}
	}

	.webtext-example,
	.webtext-question-example {
		cite {
			font-size: 16px;
			display: block;
			text-align: right;
			margin-top: 15px;
			padding-right: 10px;
		}
	}
`;

export default (theme) => css`
	${commonStyles(theme)}
	${theme.name !== ThemeName.UNIVERSAL_VELVET && blueVelvetStyles(theme)}
	${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetStyles(theme)}
`;
