import React from 'react';

import { jsx } from '@emotion/react';
import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { Props as TextProps } from '~/components/pageElements/Text/Text';
import { checkForSpecialTextContent } from '~/components/pageElements/Text/utils';

import { PrimarySourceStyles } from '.';

interface Props {
	element?: Element;
	textProps: TextProps;
}

const PrimarySource: React.FC<Props> = (props) => {
	const { element, textProps } = props;

	const elementContent = jsx(element.tagName, {
		className: element.attribs.class,
		children: domToReact(element.children, {
			replace: (domNode: Element) => checkForSpecialTextContent(domNode, textProps, element)
		})
	});

	return <div css={PrimarySourceStyles}>{elementContent}</div>;
};

export default PrimarySource;
