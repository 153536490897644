/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
	CellMeasurer,
	CellMeasurerCache,
	defaultCellRangeRenderer,
	MultiGrid
} from 'react-virtualized';
import cn from 'classnames';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { updateCurrentViewParams } from 'Actions/uiActions';
import { colors } from 'Theme/index';
import filterUserIds from 'Filters/FilterUserIdsBySearchQuery';
import sortUserIdsCompletions from 'Sorts/SortUserIdsCompletions';
import { selectAssignmentTypeFilterUserIds } from 'Store/selectors';

import StudentNameCellHover from './StudentNameCellHover';
import CourseCompletionCell from './CourseCompletionCell';
import CourseScoreCell from './CourseScoreCell';
import CourseTimingCell from './CourseTimingCell';
import CohortCompletionCell from './CohortCompletionCell';
import CohortScoreCell from './CohortScoreCell';
import CohortTimingCell from './CohortTimingCell';
import GradeAverageCell from './GradeAverageCell';

import TippyRollover from '../TippyRollover';

import styles from '../GridView.scss';

import type { MultiGridProps } from 'react-virtualized';
import type { FamilyId } from '@soomo/lib/types/WebtextManifest';

const greyBorderColor = colors.gridViewVerticalLineColor;

const ROW_GROUP_SIZE = 10;

const SORT_OPTIONS = [
	{ label: 'A-Z', primaryText: 'Alphabetical (A-Z)', value: 'user.last_name ASC' },
	{ label: 'Z-A', primaryText: 'Alphabetical (Z-A)', value: 'user.last_name DESC' },
	{ label: 'Most Progress', primaryText: 'Most Progress', value: 'user.course_completion DESC' },
	{ label: 'Least Progress', primaryText: 'Least Progress', value: 'user.course_completion ASC' },
	{ label: 'Highest Score', primaryText: 'Highest Score', value: 'user.course_score DESC' },
	{ label: 'Lowest Score', primaryText: 'Lowest Score', value: 'user.course_score ASC' },
	{ label: 'Most Time Spent', primaryText: 'Most Time Spent', value: 'user.total_time_spent DESC' },
	{ label: 'Least Time Spent', primaryText: 'Least Time Spent', value: 'user.total_time_spent ASC' }
];

const SORT_OPTIONS_BY_VALUE = SORT_OPTIONS.reduce(function (memo, sortOption) {
	memo[sortOption.value] = sortOption;
	return memo;
}, {});

class CustomMultiGrid extends MultiGrid {
	_renderTopLeftGrid(props) {
		// @ts-expect-error undocumented method
		return super._renderTopLeftGrid({
			...props,
			className: styles['top-left-grid']
		});
	}
	_renderTopRightGrid(props) {
		// @ts-expect-error undocumented method
		return super._renderTopRightGrid({
			...props,
			className: styles['top-right-grid']
		});
	}
	_renderBottomLeftGrid(props) {
		// @ts-expect-error undocumented method
		return super._renderBottomLeftGrid({
			...props,
			className: styles['bottom-left-grid']
		});
	}
	_renderBottomRightGrid(props) {
		// @ts-expect-error undocumented method
		return super._renderBottomRightGrid({
			...props,
			className: styles['bottom-right-grid']
		});
	}
	_rowHeightBottomGrid({ index }) {
		const { fixedRowCount, rowHeight } = this.props;

		return typeof rowHeight === 'function'
			? rowHeight({ index: index + fixedRowCount })
			: rowHeight;
	}
}

interface CellRendererProps {
	rowIndex: number;
	rowGroup: number;
	fetchRowGroupUserIds: (args: { rowGroup: number }) => number[];
	columnIndex: number;
	user: any;
	loaded: boolean;
}

type CellRenderer = (props: CellRendererProps) => React.ReactNode;

export interface BaseGridProps
	extends Pick<
		MultiGridProps,
		| 'sortBy'
		| 'columnCount'
		| 'fixedColumnCount'
		| 'fixedRowCount'
		| 'scrollTop'
		| 'scrollToColumn'
		| 'height'
		| 'width'
	> {
	course: any;
	loaded: boolean;
	toc: any;
	userIds: number[];
	usersById: { [userId: string | number]: any };
	scopeTypes: { [familyId: FamilyId]: string };
	assignmentTypeFilter: string;
	searchQuery: string;
	aspect: 'progress' | 'timeSpent' | 'score' | 'gradebookPoints';
	sortBy: string;
	selectedScope: FamilyId;
	scrollTop: number;
	scrollToRequiresActionColumn: MultiGridProps['scrollToColumn'];
	fixedColumnCount: number;
	fixedRowCount: number;
	showAllSectionsRow: boolean;
	sortedFilteredUserIds: number[];
	maximumColumnGroupSize?: number;
	onDataCellClick?: (args: {
		userId: number;
		columnIndex: number;
	}) => React.MouseEventHandler<HTMLDivElement>;
	onCourseAggregateCellClick?: (args: {
		columnIndex: number;
	}) => React.MouseEventHandler<HTMLDivElement>;
	updateCurrentViewParams: any; // type `dispatch(updateCurrentViewParams(...))`
	courseAverageCompletions?: unknown;
	courseAverageTimings?: unknown;
	renderColumnGroupCell?: (
		args: { columnIndex: number },
		extraStyle: CSSProperties
	) => React.ReactNode;
	getScope?: (args: { columnIndex: number }) => any;
	dataCellHoverContent?: (args: {
		user: any;
		columnIndex: number;
		rowIndex: number;
	}) => React.ReactNode;
	renderCompletionCell?: CellRenderer;
	renderTimingCell?: CellRenderer;
	renderScoreCell?: CellRenderer;
	renderGradebookPointsCell?: CellRenderer;
	renderHeaderCell: (args: { columnIndex: number }, extraStyle: CSSProperties) => React.ReactNode;
	headerCellHoverContent?: (args: { columnIndex: number }) => React.ReactNode;
	showOverallClassPerformance: boolean;
	hasDynamicRowHeights?: boolean;
}

interface BaseGridState {
	scrollTop: number;
}

class BaseGrid extends React.Component<BaseGridProps, BaseGridState> {
	private _cache: CellMeasurerCache;

	constructor(props: BaseGridProps) {
		super(props);

		this.state = {
			scrollTop: 0
		};

		this._cache = new CellMeasurerCache({
			defaultWidth: 64,
			defaultHeight: 40,
			fixedWidth: true
		});
	}

	componentDidMount() {
		this.setDefaults();
	}

	componentDidUpdate() {
		this.setDefaults();
	}

	componentWillUnmount() {
		this.props.updateCurrentViewParams({
			gridViewScrollTop: this.state.scrollTop
		});
	}

	setDefaults = () => {
		const defaultParams: any = {};

		if (!this.props.sortBy) {
			defaultParams.gridViewSortBy = SORT_OPTIONS[0].value;
		}

		if (
			!this.props.selectedScope &&
			this.props.toc.chapter_ids &&
			this.props.toc.chapter_ids.length
		) {
			defaultParams.scope = this.props.toc.chapter_ids[0];
		}

		if (Object.keys(defaultParams).length) {
			this.props.updateCurrentViewParams(defaultParams);
		}
	};

	updateSortBy = (event) => {
		this.props.updateCurrentViewParams({ gridViewSortBy: event.target.value });
	};

	onScroll = ({ scrollTop }) => {
		this.setState({ scrollTop });
	};

	getColumnWidth = ({ index }) => {
		if (index === 0) {
			return 200;
		} else if (this.props.aspect === 'gradebookPoints') {
			return 150;
		} else if (index === 1) {
			return 90;
		} else {
			return 64;
		}
	};

	getRowHeight = ({ index }) => {
		// if it's the header row, use dynamically measured row height
		// (because gradebook column labels can be arbitrarily long)
		if (index === 1) {
			return this._cache.rowHeight({ index });
		}

		const columnGroupSize = this.props.renderColumnGroupCell && this.props.maximumColumnGroupSize;

		if (index === 0) {
			if (columnGroupSize) {
				return 40;
			} else {
				return 0;
			}
		} else if (index === 2) {
			if (columnGroupSize) {
				return 41;
			} else {
				return 40;
			}
		} else {
			return 40;
		}
	};

	fetchRowGroupUserIds = ({ rowGroup }) => {
		const startIdx = rowGroup * ROW_GROUP_SIZE;
		const stopIdx = startIdx + ROW_GROUP_SIZE;
		return this.props.sortedFilteredUserIds.slice(startIdx, stopIdx);
	};

	renderStudentNameHeaderCell = () => {
		const menuItems = SORT_OPTIONS.map(function (sortOption, idx) {
			return (
				<MenuItem
					key={idx}
					value={sortOption.value}
					classes={{ selected: styles.SortByMenuItemSelected }}>
					{sortOption.primaryText}
				</MenuItem>
			);
		});

		return (
			<div className={styles.SortByContainer}>
				<span style={{ marginTop: '-1px' }}>Sort by: </span>
				<Select
					value={this.props.sortBy}
					renderValue={(value) => SORT_OPTIONS_BY_VALUE[value as string].label}
					onChange={this.updateSortBy}
					variant="standard"
					disableUnderline={true}
					classes={{
						root: styles.SortBySelectRoot,
						select: styles.SortBySelectMenu,
						icon: styles.SortByIcon
					}}>
					{menuItems}
				</Select>
			</div>
		);
	};

	renderStudentNameCell = ({
		label,
		user,
		isScrolling
	}: {
		label?: React.ReactNode;
		user: any;
		isScrolling: boolean;
	}) => {
		if (label) {
			return <strong>{label}</strong>;
		}

		return (
			<TippyRollover
				content={isScrolling ? null : <StudentNameCellHover userId={user.id} />}
				variant={'pane'}>
				<span
					className={styles.StudentName}
					style={{ display: 'inline-block', paddingLeft: '1em' }}>
					<a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
						{user.first_name} {user.last_name}
					</a>
				</span>
			</TippyRollover>
		);
	};

	renderStudentAggregateHeaderCell = (extraStyle) => {
		extraStyle.borderLeft = `1px solid ${greyBorderColor}`;
		extraStyle.borderRight = `1px solid ${greyBorderColor}`;
		extraStyle.justifyContent = 'center';
		extraStyle.fontSize = '0.75em';
		extraStyle.fontWeight = '300';
		extraStyle.width = this.props.aspect === 'gradebookPoints' ? '148px' : '88px';

		const style = {
			display: 'inline-block',
			padding: '0 8px'
		};

		let content = '';
		switch (this.props.aspect) {
			case 'progress':
				content = 'Answered';
				break;
			case 'timeSpent':
				content = 'Active time in course';
				break;
			case 'score':
				content = 'Total score';
				break;
			case 'gradebookPoints':
				content = 'Total';
				break;
		}
		return <span style={style}>{content}</span>;
	};

	renderCohortAggregateCell = (
		{ user, columnIndex, isScrolling }: { user?: any; columnIndex?: number; isScrolling: boolean },
		extraStyle
	) => {
		extraStyle.justifyContent = 'center';
		extraStyle.borderLeft = `1px solid ${greyBorderColor}`;
		extraStyle.borderRight = `1px solid ${colors.gridViewScrollStopColor}`;

		if (user) {
			extraStyle.width = '88px';
			extraStyle.fontSize = '.7em';
		}

		const scope = columnIndex != null ? this.props.getScope({ columnIndex }) : this.props.toc;

		switch (this.props.aspect) {
			case 'progress':
				return <CohortCompletionCell user={user} scope={scope} isScrolling={isScrolling} />;
			case 'timeSpent':
				return <CohortTimingCell user={user} scope={scope} isScrolling={isScrolling} />;
			case 'score':
				return <CohortScoreCell user={user} scope={scope} isScrolling={isScrolling} />;
		}
	};

	renderCourseAggregateCell = (
		{ user, columnIndex, isScrolling }: { user?: any; columnIndex?: number; isScrolling: boolean },
		extraStyle
	) => {
		extraStyle.justifyContent = 'center';
		extraStyle.borderLeft = `1px solid ${greyBorderColor}`;
		extraStyle.borderRight = `1px solid ${colors.gridViewScrollStopColor}`;

		if (this.props.aspect === 'gradebookPoints') {
			extraStyle.width = '148px';
		}
		if (user) {
			extraStyle.fontSize = '.7em';
			if (this.props.aspect !== 'gradebookPoints') {
				extraStyle.width = '88px';
			}
		}

		const scope =
			columnIndex != null && this.props.getScope ? this.props.getScope({ columnIndex }) : null;
		const scopeType = scope ? this.props.scopeTypes[scope.id] : null;

		switch (this.props.aspect) {
			case 'progress':
				return (
					<CourseCompletionCell
						user={user}
						scope={scope}
						scopeType={scopeType}
						isScrolling={isScrolling}
					/>
				);
			case 'timeSpent':
				return (
					<CourseTimingCell
						user={user}
						scope={scope}
						scopeType={scopeType}
						isScrolling={isScrolling}
					/>
				);
			case 'score':
				return (
					<CourseScoreCell
						user={user}
						scope={scope}
						scopeType={scopeType}
						isScrolling={isScrolling}
					/>
				);
			case 'gradebookPoints':
				return <GradeAverageCell user={user} columnIndex={columnIndex} />;
		}
	};

	renderStudentDataCell = ({ rowIndex, rowGroup, columnIndex, user }) => {
		const { aspect, fixedColumnCount, loaded } = this.props;

		const renderProps: CellRendererProps = {
			rowIndex,
			rowGroup,
			fetchRowGroupUserIds: this.fetchRowGroupUserIds,
			columnIndex: columnIndex - fixedColumnCount,
			user,
			loaded
		};

		let content: React.ReactNode = <span />;
		if (aspect === 'progress' && this.props.renderCompletionCell) {
			content = this.props.renderCompletionCell(renderProps);
		} else if (aspect === 'timeSpent' && this.props.renderTimingCell) {
			content = this.props.renderTimingCell(renderProps);
		} else if (aspect === 'score' && this.props.renderScoreCell) {
			content = this.props.renderScoreCell(renderProps);
		} else if (aspect === 'gradebookPoints' && this.props.renderGradebookPointsCell) {
			content = this.props.renderGradebookPointsCell(renderProps);
		}

		if (this.props.dataCellHoverContent) {
			const hoverContent = this.props.dataCellHoverContent({
				user,
				columnIndex: columnIndex - fixedColumnCount,
				rowIndex
			});

			content = (
				<TippyRollover content={hoverContent} variant={'pane'}>
					{content}
				</TippyRollover>
			);
		}

		return content;
	};

	getOnClickHandler = ({ columnIndex, rowIndex, user }) => {
		const { fixedColumnCount, fixedRowCount, showOverallClassPerformance } = this.props;

		const isDataCell = columnIndex > fixedColumnCount - 1 && rowIndex > fixedRowCount - 1;
		const isPageAverageCell = columnIndex > fixedColumnCount - 1 && rowIndex > fixedRowCount - 2;

		if (isDataCell && this.props.onDataCellClick) {
			return this.props.onDataCellClick({ userId: user.id, columnIndex: columnIndex - 2 });
		}

		if (showOverallClassPerformance && isPageAverageCell && this.props.onCourseAggregateCellClick) {
			return this.props.onCourseAggregateCellClick({ columnIndex: columnIndex - 2 });
		}

		return null;
	};

	renderCell = ({ columnIndex, key, rowIndex, style, isScrolling, parent }) => {
		const { usersById, userIds, showAllSectionsRow, fixedRowCount, sortedFilteredUserIds } =
			this.props;

		let content, userId, user;
		const styleNames = [];
		const rowGroup = Math.floor((rowIndex - fixedRowCount) / ROW_GROUP_SIZE);

		const extraStyle: CSSProperties = { display: 'flex', alignItems: 'center' };

		if (rowIndex > fixedRowCount - 1) {
			userId = sortedFilteredUserIds[rowIndex - fixedRowCount];
			user = usersById[userId];
		}

		if (rowIndex === 0) {
			// column grouping row
			if (columnIndex > 1) {
				if (this.props.renderColumnGroupCell) {
					content = this.props.renderColumnGroupCell({ columnIndex: columnIndex - 2 }, extraStyle);
				}
			}
		} else if (rowIndex === 1) {
			// header row
			styleNames.push('styles.HeaderCell');
			// we must set extraStyle.width here to get the correct dynamic height from CellMeasurer
			// otherwise the cell will simply grow in width to accommodate its text before measuring
			extraStyle.width = `${this.getColumnWidth({ index: columnIndex })}px`;

			if (columnIndex === 0) {
				styleNames.push('styles.TopLeftHeaderCell');
				content = this.renderStudentNameHeaderCell();
			} else if (columnIndex === 1) {
				content = this.renderStudentAggregateHeaderCell(extraStyle);
			} else {
				extraStyle.justifyContent = 'center';
				extraStyle.textAlign = 'center';
				content = this.props.renderHeaderCell({ columnIndex: columnIndex - 2 }, extraStyle);
				if (this.props.headerCellHoverContent) {
					const hoverContent = this.props.headerCellHoverContent({ columnIndex: columnIndex - 2 });
					content = (
						<TippyRollover content={hoverContent} variant={'pane'}>
							{content}
						</TippyRollover>
					);
				}
			}
		} else if (rowIndex === 2 && showAllSectionsRow) {
			// cohort average row
			styleNames.push('styles.CourseAverageHeaderCell');
			extraStyle.background = colors.gridViewAlternateRowBackgroundColor;
			extraStyle.borderBottom = `1px solid ${colors.gridViewScrollStopColor}`;
			extraStyle.height = '39px';
			if (columnIndex === 0) {
				// student name column
				styleNames.push('styles.ClassAverages');
				content = this.renderStudentNameCell({ user, isScrolling, label: 'All Sections' });
			} else if (columnIndex === 1) {
				// student aggregate column
				content = this.renderCohortAggregateCell({ isScrolling }, extraStyle);
			} else {
				if (columnIndex !== 2) {
					extraStyle.borderLeft = `1px solid ${greyBorderColor}`;
				}
				content = this.renderCohortAggregateCell(
					{ columnIndex: columnIndex - 2, isScrolling },
					extraStyle
				);
			}
		} else if ((rowIndex === 2 && !showAllSectionsRow) || (rowIndex === 3 && showAllSectionsRow)) {
			if (userIds.length === 0) {
				return null;
			}

			// course average row
			styleNames.push('styles.CourseAverageHeaderCell');
			extraStyle.background = colors.gridViewAlternateRowBackgroundColor;
			extraStyle.borderBottom = `1px solid ${colors.gridViewScrollStopColor}`;
			extraStyle.height = '39px';
			if (columnIndex === 0) {
				// student name column
				styleNames.push('styles.ClassAverages');
				content = this.renderStudentNameCell({ user, isScrolling, label: 'Section Averages' });
			} else if (columnIndex === 1) {
				// student aggregate column
				content = this.renderCourseAggregateCell({ isScrolling }, extraStyle);
			} else {
				if (this.props.showOverallClassPerformance) styleNames.push('styles.PageCell');
				if (columnIndex !== 2) {
					extraStyle.borderLeft = `1px solid ${greyBorderColor}`;
				}
				content = this.renderCourseAggregateCell(
					{ columnIndex: columnIndex - 2, isScrolling },
					extraStyle
				);
			}
		} else {
			// normal row
			if (rowIndex % 2 === (showAllSectionsRow ? 1 : 0)) {
				extraStyle.background = colors.gridViewAlternateRowBackgroundColor;
			}
			if (columnIndex === 0) {
				// student name column
				content = this.renderStudentNameCell({ user, isScrolling });
			} else if (columnIndex === 1) {
				// student aggregate column
				content = this.renderCourseAggregateCell({ user, isScrolling }, extraStyle);
			} else {
				// page column
				if (this.props.aspect !== 'gradebookPoints') {
					styleNames.push('styles.PageCell');
				}
				extraStyle.justifyContent = 'center';
				extraStyle.textAlign = 'center';
				extraStyle.fontSize = '0.7em';
				if (columnIndex !== 2) {
					extraStyle.borderLeft = `1px solid ${greyBorderColor}`;
				}
				content = this.renderStudentDataCell({
					user,
					rowIndex,
					rowGroup,
					columnIndex
				});
			}
		}

		let cellContent = (
			<div
				styleName={cn(styleNames)}
				onClick={this.getOnClickHandler({ columnIndex, rowIndex, user })}
				style={{ ...style, ...extraStyle }}>
				{content}
			</div>
		);

		if (this.props.hasDynamicRowHeights) {
			cellContent = (
				<CellMeasurer
					key={key}
					cache={this._cache}
					columnIndex={columnIndex}
					rowIndex={rowIndex}
					parent={parent}>
					{cellContent}
				</CellMeasurer>
			);
		}

		return cellContent;
	};

	renderCellRange = () => {
		const { maximumColumnGroupSize } = this.props;

		return function (props) {
			const columnStartIndex = Math.max(0, props.columnStartIndex - maximumColumnGroupSize);
			return defaultCellRangeRenderer({ ...props, columnStartIndex });
		};
	};

	render() {
		const {
			aspect,
			sortBy,
			selectedScope,
			scrollTop,
			scrollToColumn,
			scrollToRequiresActionColumn,
			columnCount,
			maximumColumnGroupSize,
			fixedColumnCount,
			fixedRowCount,
			sortedFilteredUserIds,
			...passthroughProps
		} = this.props;

		const cellRangeRenderer = maximumColumnGroupSize
			? this.renderCellRange()
			: defaultCellRangeRenderer;

		return (
			<CustomMultiGrid
				{...passthroughProps}
				style={{
					borderBottom: '1px solid ' + colors.gridViewScrollStopColor,
					borderRight: '1px solid ' + colors.gridViewScrollStopColor
				}}
				columnCount={fixedColumnCount + columnCount}
				rowCount={fixedRowCount + sortedFilteredUserIds.length}
				fixedColumnCount={fixedColumnCount}
				fixedRowCount={fixedRowCount}
				enableFixedColumnScroll
				enableFixedRowScroll
				deferredMeasurementCache={this.props.hasDynamicRowHeights ? this._cache : undefined}
				rowHeight={this.getRowHeight}
				columnWidth={this.getColumnWidth}
				cellRangeRenderer={cellRangeRenderer}
				cellRenderer={this.renderCell}
				scrollTop={scrollTop}
				scrollToAlignment={scrollToColumn || scrollToRequiresActionColumn ? 'start' : 'auto'}
				scrollToColumn={
					scrollToRequiresActionColumn
						? fixedColumnCount + scrollToRequiresActionColumn
						: fixedColumnCount + scrollToColumn
				}
				scrollToRow={scrollToColumn || scrollToRequiresActionColumn ? fixedRowCount : null}
				onScroll={this.onScroll}
				// if these change, we want to re-render the grid.
				aspect={aspect}
				sortBy={sortBy}
				searchQuery={this.props.searchQuery}
				selectedScope={selectedScope}
				courseAverageCompletions={this.props.courseAverageCompletions}
				courseAverageTimings={this.props.courseAverageTimings}
			/>
		);
	}
}

const selectFilteredUserIds = createSelector(
	(state) => state.entities.studentIds,
	selectAssignmentTypeFilterUserIds,
	(state) => state.entities.users,
	(state) => state.ui.currentViewParams.searchQuery,
	(studentIds, assignmentTypeFilterUserIds, usersById, searchQuery) =>
		Object.keys(usersById).length > 0
			? filterUserIds(
					assignmentTypeFilterUserIds || studentIds, // Filter only students that match the assignment type filter || all students
					usersById,
					searchQuery
			  )
			: []
);

export const selectSortedFilteredUserIds = createSelector(
	selectFilteredUserIds,
	(state) => state.entities.users,
	(state) => state.ui.currentViewParams.gridViewSortBy,
	(filteredUserIds, usersById, sortBy) => sortUserIdsCompletions(filteredUserIds, usersById, sortBy)
);

const mapStateToProps = (state) => {
	const {
		ui: { allSectionsRowEnabled, currentViewParams, gridViewScrollToColumn },
		entities
	} = state;

	const showAllSectionsRow = allSectionsRowEnabled && entities.course.cohort_size > 1;
	return {
		course: entities.course,
		toc: entities.toc,
		userIds: entities.studentIds,
		usersById: entities.users,
		scopeTypes: entities.scopeTypes,

		assignmentTypeFilter: currentViewParams.assignmentTypeFilter,
		searchQuery: currentViewParams.searchQuery,
		aspect: currentViewParams.gridViewAspect,
		sortBy: currentViewParams.gridViewSortBy,
		selectedScope: currentViewParams.scope,
		scrollTop: currentViewParams.gridViewScrollTop,
		scrollToRequiresActionColumn: gridViewScrollToColumn,

		fixedColumnCount: 2,
		fixedRowCount: showAllSectionsRow ? 4 : 3,
		showAllSectionsRow,

		sortedFilteredUserIds: selectSortedFilteredUserIds(state),
		showOverallClassPerformance: entities.featureFlags.showOverallClassPerformance
	};
};

const mapDispatchToProps = (dispatch) => ({
	updateCurrentViewParams: (params) => {
		dispatch(updateCurrentViewParams(params));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseGrid);
