import styles from '../GridView.scss';
import 'rc-tooltip/assets/bootstrap.css';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { selectGradingRequiredPages } from 'Store/selectors';
import CompletionCell from 'Components/GridCells/CompletionCell';
import TimingCell from 'Components/GridCells/TimingCell';
import ScoreCell from 'Components/GridCells/ScoreCell';
import RequiresActionCell from 'Components/GridCells/RequiresActionCell';
import GradeGridLegend from 'Containers/Views/GridView/GradeGridLegend';

class GridLegend extends React.PureComponent {
	static propTypes = {
		userIds: PropTypes.array,
		startIndex: PropTypes.number,
		stopIndex: PropTypes.number,
		columnScopeType: PropTypes.string,
		aspect: PropTypes.string,
		highlightTiming: PropTypes.bool
	};

	render() {
		const {
			userIds,
			startIndex,
			stopIndex,
			columnScopeType,
			aspect,
			highlightTiming,
			gradingRequiredPages
		} = this.props;
		let legend = null;

		const studentCount = userIds.length > 0 && (
			<div className={styles.StudentCount}>
				Showing students {startIndex + 1}&ndash;{stopIndex + 1} of {userIds.length}
			</div>
		);

		if (userIds.length === 0) return null;

		switch (aspect) {
			case 'progress':
				legend = (
					<div className={styles.Legend}>
						{studentCount}
						{gradingRequiredPages.length !== 0 && (
							<div className={styles.LegendItem}>
								<RequiresActionCell />
								<div className={styles.LegendItemLabel}>Requires Action</div>
							</div>
						)}
						{columnScopeType === 'page' && (
							<>
								<div className={styles.LegendItem}>
									<CompletionCell completion={0.0} fullyCompleted={false} opened loaded={true} />
									<div className={styles.LegendItemLabel}>Opened</div>
								</div>
								<div className={styles.LegendItem}>
									<CompletionCell completion={0.5} fullyCompleted={false} opened loaded={true} />
									<div className={styles.LegendItemLabel}>Progress</div>
								</div>
								<Tooltip
									visible={false}
									placement="top"
									align={{
										offset: [-39, -5]
									}}
									overlay={
										<div style={{ width: 280, height: 60, display: 'flex', flexDirection: 'row' }}>
											<i
												className="ss-icon ss-info"
												style={{
													display: 'inline-block',
													width: '30px',
													paddingRight: '10px',
													color: 'orange',
													fontSize: '24px',
													alignSelf: 'center'
												}}
											/>
											<span
												style={{
													fontSize: '0.8em'
												}}>
												{`"Completed" implies the student has taken advantage of all available points. If they have completed the questions but not reset incorrect answers when available, a completed checkmark will <strong>NOT</strong> be displayed.`}
											</span>
										</div>
									}
									overlayClassName={styles.TooltipOverlay}>
									<div className={styles.LegendItem}>
										<CompletionCell
											completion={100.0}
											fullyCompleted
											opened
											loaded
											data-tip=""
											data-for="page-opened-tooltip"
										/>
										<div className={styles.LegendItemLabel}>Answered</div>
									</div>
								</Tooltip>
							</>
						)}
					</div>
				);
				break;
			case 'timeSpent':
				legend = (
					<div className={styles.Legend}>
						{studentCount}
						{highlightTiming && columnScopeType === 'page' && (
							<div className={styles.LegendItem}>
								<div className={styles.LegendItem}>
									<TimingCell lowTime={true} highTime={false} loaded={true} />
									<div className={styles.LegendItemLabel}>Low Time</div>
								</div>
								<div className={styles.LegendItem}>
									<TimingCell lowTime={false} highTime={true} loaded={true} />
									<div className={styles.LegendItemLabel}>High Time</div>
								</div>
							</div>
						)}
					</div>
				);
				break;
			case 'score':
				legend = (
					<div className={styles.Legend}>
						{studentCount}
						{gradingRequiredPages.length !== 0 && (
							<div className={styles.LegendItem}>
								<RequiresActionCell />
								<div className={styles.LegendItemLabel}>Requires Action</div>
							</div>
						)}
						{columnScopeType === 'page' && (
							<div className={styles.LegendItem}>
								<ScoreCell lowScore={true} score={0} loaded={true} />
								<div className={styles.LegendItemLabel}>Low Score</div>
							</div>
						)}
					</div>
				);
				break;
			case 'gradebookPoints':
				legend = <GradeGridLegend />;
				break;
		}

		return legend;
	}
}

const mapStateToProps = (state) => ({
	gradingRequiredPages: selectGradingRequiredPages(state)
});

export default connect(mapStateToProps)(GridLegend);
