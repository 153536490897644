import React from 'react';

export const strokePatterns = [
	{ stroke_dasharray: '10, 4', stroke_width: '1', stroke: 'black' },
	{ stroke_dasharray: '', stroke_width: '0.7', stroke: 'black' },
	{ stroke_dasharray: '2,4', stroke_width: '2', stroke: 'black' },
	{ stroke_dasharray: '20,4', stroke_width: '2', stroke: 'black' },
	{ stroke_dasharray: '', stroke_width: '2', stroke: 'black' },
	{ stroke_dasharray: '10, 4', stroke_width: '1', stroke: '#bbb' },
	{ stroke_dasharray: '', stroke_width: '0.7', stroke: '#bbb' },
	{ stroke_dasharray: '2,4', stroke_width: '2', stroke: '#bbb' },
	{ stroke_dasharray: '20,4', stroke_width: '2', stroke: '#bbb' },
	{ stroke_dasharray: '', stroke_width: '2', stroke: '#bbb' },
	{ stroke_dasharray: '10, 4', stroke_width: '1', stroke: 'black' },
	{ stroke_dasharray: '', stroke_width: '0.7', stroke: 'black' }
];

export const patternClasses = [
	'dots-1',
	'diagonal-stripe-1',
	'horizontal-stripe-1',
	'vertical-stripe-1',
	'dots-3',
	'diagonal-stripe-4',
	'horizontal-stripe-3',
	'vertical-stripe-3',
	'dots-5',
	'diagonal-stripe-6',
	'horizontal-stripe-5',
	'vertical-stripe-5',
	'circles-5',
	'dots-7',
	'horizontal-stripe-7',
	'vertical-stripe-7',
	'dots-9',
	'horizontal-stripe-9',
	'vertical-stripe-9',
	'circles-9',
	'crosshatch',
	'houndstooth',
	'lightstripe',
	'verticalstripe',
	'smalldot',
	'whitecarbon'
];

const PatternsSvg = () => {
	return (
		<svg width="0" height="0" style={{ position: 'absolute' }}>
			<defs>
				<pattern height="10" id="circles-1" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="1" cy="1" fill="black" r="1"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-2" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="1.5" cy="1.5" fill="black" r="1.5"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-3" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="2" cy="2" fill="black" r="2"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-4" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="2.5" cy="2.5" fill="black" r="2.5"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-5" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="3" cy="3" fill="black" r="3"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-6" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="3.5" cy="3.5" fill="black" r="3.5"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-7" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="4" cy="4" fill="black" r="4"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-8" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="4.5" cy="4.5" fill="black" r="4.5"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="circles-9" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<circle cx="5" cy="5" fill="black" r="5"></circle>
					</svg>
				</pattern>
				<pattern height="10" id="diagonal-stripe-1" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<path
							d="M-1,1 l2,-2&#x000A;M0,10 l10,-10&#x000A;M9,11 l2,-2"
							strokeWidth="1"
							stroke="black"></path>
					</svg>
				</pattern>
				<pattern height="10" id="diagonal-stripe-2" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<path
							d="M-1,1 l2,-2&#x000A;M0,10 l10,-10&#x000A;M9,11 l2,-2"
							strokeWidth="2"
							stroke="black"></path>
					</svg>
				</pattern>
				<pattern height="10" id="diagonal-stripe-3" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<path
							d="M-1,1 l2,-2&#x000A;M0,10 l10,-10&#x000A;M9,11 l2,-2"
							strokeWidth="3"
							stroke="black"></path>
					</svg>
				</pattern>
				<pattern height="10" id="diagonal-stripe-4" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="black" height="10" width="10"></rect>
						<path
							d="M-1,1 l2,-2&#x000A;M0,10 l10,-10&#x000A;M9,11 l2,-2"
							strokeWidth="3"
							stroke="white"></path>
					</svg>
				</pattern>
				<pattern height="10" id="diagonal-stripe-5" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="black" height="10" width="10"></rect>
						<path
							d="M-1,1 l2,-2&#x000A;M0,10 l10,-10&#x000A;M9,11 l2,-2"
							strokeWidth="2"
							stroke="white"></path>
					</svg>
				</pattern>
				<pattern height="10" id="diagonal-stripe-6" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="black" height="10" width="10"></rect>
						<path
							d="M-1,1 l2,-2&#x000A;M0,10 l10,-10&#x000A;M9,11 l2,-2"
							strokeWidth="1"
							stroke="white"></path>
					</svg>
				</pattern>
				<pattern height="10" id="dots-1" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="1" width="1" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-2" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="2" width="2" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-3" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="3" width="3" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-4" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="4" width="4" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-5" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="5" width="5" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-6" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="6" width="6" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-7" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="7" width="7" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-8" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="8" width="8" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="dots-9" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="9" width="9" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-1" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="1" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-2" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="2" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-3" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="3" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-4" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="4" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-5" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="5" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-6" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="6" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-7" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="7" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-8" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="8" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="horizontal-stripe-9" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="9" width="10" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-1" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="1" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-2" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="2" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-3" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="3" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-4" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="4" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-5" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="5" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-6" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="6" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-7" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="7" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-8" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="8" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="10" id="vertical-stripe-9" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="10" width="10"></rect>
						<rect fill="black" height="10" width="9" x="0" y="0"></rect>
					</svg>
				</pattern>
				<pattern height="8" id="crosshatch" patternUnits="userSpaceOnUse" width="8">
					<svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
						<rect fill="#fff" height="8" width="8"></rect>
						<path d="M0 0L8 8ZM8 0L0 8Z" strokeWidth="0.5" stroke="#aaa"></path>
					</svg>
				</pattern>
				<pattern height="10" id="houndstooth" patternUnits="userSpaceOnUse" width="10">
					<svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 0L4 4" fill="#aaa" strokeWidth="1" stroke="#aaa"></path>
						<path
							d="M2.5 0L5 2.5L5 5L9 9L5 5L10 5L10 0"
							fill="#aaa"
							strokeWidth="1"
							stroke="#aaa"></path>
						<path d="M5 10L5 7.5L7.5 10" fill="#aaa" strokeWidth="1" stroke="#aaa"></path>
					</svg>
				</pattern>
				<pattern height="5" id="lightstripe" patternUnits="userSpaceOnUse" width="5">
					<svg height="5" width="5" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="5" width="5"></rect>
						<path d="M0 5L5 0ZM6 4L4 6ZM-1 1L1 -1Z" strokeWidth="1" stroke="#888"></path>
					</svg>
				</pattern>
				<pattern height="5" id="smalldot" patternUnits="userSpaceOnUse" width="5">
					<svg height="5" width="5" xmlns="http://www.w3.org/2000/svg">
						<rect fill="#fff" height="5" width="5"></rect>
						<rect fill="#ccc" height="1" width="1"></rect>
					</svg>
				</pattern>
				<pattern height="5" id="subtle-patch" patternUnits="userSpaceOnUse" width="5">
					<svg height="5" width="5" xmlns="http://www.w3.org/2000/svg">
						<rect fill="white" height="5" width="5"></rect>
						<rect fill="black" height="1" width="1" x="2" y="2"></rect>
					</svg>
				</pattern>
				<pattern height="49" id="verticalstripe" patternUnits="userSpaceOnUse" width="6">
					<svg height="49" width="6" xmlns="http://www.w3.org/2000/svg">
						<rect fill="#fff" height="50" width="3"></rect>
						<rect fill="#ccc" height="50" width="1" x="3"></rect>
					</svg>
				</pattern>
				<pattern height="6" id="whitecarbon" patternUnits="userSpaceOnUse" width="6">
					<svg height="6" width="6" xmlns="http://www.w3.org/2000/svg">
						<rect fill="#eeeeee" height="6" width="6"></rect>
						<g id="c">
							<rect fill="#e6e6e6" height="3" width="3"></rect>
							<rect fill="#d8d8d8" height="2" width="3" y="1"></rect>
						</g>
					</svg>
				</pattern>
			</defs>
		</svg>
	);
};

export default PatternsSvg;
