import React, { forwardRef } from 'react';

import MCQuestionIcon from '~/components/icons/MCQuestionIcon';
import { QuestionType } from '~/components/shared/Question';
import { useIsUniversalVelvet } from '~/hooks';

const defaultElementLabel = 'Multiple-Choice Question';

interface Props {
	elementLabel?: string;
}

const MultipleChoiceQuestionHeading = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const { elementLabel } = props;
	const isUniversalVelvet = useIsUniversalVelvet();

	return (
		<QuestionType ref={ref}>
			{isUniversalVelvet && <MCQuestionIcon />}
			{elementLabel ?? defaultElementLabel}
		</QuestionType>
	);
});
MultipleChoiceQuestionHeading.displayName = 'MultipleChoiceQuestionHeading';

export default MultipleChoiceQuestionHeading;
