import isEqual from 'lodash-es/isEqual';

import { getAddressDepth, instanceToSectionAddress } from '~/components/Outline/helpers/address';
import {
	getTemplateAddresses,
	getToggleTemplateParentsAddresses
} from '~/components/Outline/helpers/hierarchy';
import {
	isChildrenResponseInstance,
	isInstancesResponses,
	isSectionsResponses,
	OutlineHierarchy,
	OutlineHierarchyItemType,
	OutlineHierarchyResponses,
	OutlineInstanceAddress,
	OutlineResponse,
	OutlineResponseInstance,
	OutlineResponseSection,
	OutlineSectionAddress,
	OutlineSectionAddressPoint
} from '~/components/Outline/types';

/**
 * Returns the hierarchy response section/instance by the provided address
 *
 * @example Playground
 * https://playcode.io/1014444
 */
export const getResponse = <TResponse extends OutlineResponse>(
	hierarchyResponses: OutlineHierarchyResponses,
	address: OutlineInstanceAddress
): TResponse => {
	let response: OutlineResponse;

	address.some(({ childrenIndex, toggleIndex, instanceIndex }, depth) => {
		if (depth === 0) {
			response = hierarchyResponses[childrenIndex];
			return;
		}
		if (!response) {
			// Can be invoked only on 2+ pass
			return true; // The response hasn't been added yet -> stop the traversal
		}

		if ('instances' in response) {
			response = response.instances?.[instanceIndex];
			return;
		}

		if ('children' in response && childrenIndex !== undefined) {
			response = response.children?.[childrenIndex];
			return;
		}

		if ('toggle' in response && toggleIndex !== undefined) {
			response = response.toggle?.[toggleIndex];
			return;
		}
	});

	return response as TResponse;
};

export const getResponsesNotSubmittable = (responses: Array<OutlineResponse>): boolean => {
	for (const { instance } of traverseResponsesInstances(responses)) {
		if (instance.value === '') return true;
	}
};

type ResponsesTraverseResult = ResponsesTraverseSectionResult | ResponsesTraverseInstanceResult;

interface ResponsesTraverseSectionResult {
	section: OutlineResponseSection;
	address: OutlineInstanceAddress;
}

interface ResponsesTraverseInstanceResult {
	instance: OutlineResponseInstance;
	address: OutlineInstanceAddress;
}

export function* traverseResponsesSections(
	responses: Array<OutlineResponse>,
	depth?: number
): Generator<ResponsesTraverseSectionResult> {
	for (const response of traverseResponses({ responses, depth })) {
		if ('section' in response) {
			const { section, address } = response;
			yield { section, address };
		}
	}
}

export function* traverseResponsesInstances(
	responses: Array<OutlineResponse>,
	depth?: number
): Generator<ResponsesTraverseInstanceResult> {
	for (const response of traverseResponses({ responses, depth })) {
		if ('instance' in response) {
			const { instance, address } = response;
			yield { instance, address };
		}
	}
}

/**
 * Traverses over the hierarchy responses tree, children or toggle ones
 */
export function* traverseResponses(args: {
	responses: Array<OutlineResponse>;
	responsesType?: OutlineHierarchyItemType;
	parentAddress?: OutlineInstanceAddress;
	depth?: number;
}): Generator<ResponsesTraverseResult> {
	const {
		responses,
		responsesType = OutlineHierarchyItemType.Children,
		parentAddress = [],
		depth
	} = args;

	if (depth !== undefined) {
		const parentAddressDepth = getAddressDepth(parentAddress);
		if (parentAddressDepth >= depth) return;
	}

	if (isSectionsResponses(responses, parentAddress)) {
		yield* traverseResponsesSections(responses, responsesType, parentAddress);
	} else if (isInstancesResponses(responses, parentAddress)) {
		yield* traverseResponsesInstances(responses, parentAddress);
	}

	function* traverseResponsesSections(
		sections: Array<OutlineResponseSection>,
		responsesType: OutlineHierarchyItemType,
		parentAddress: OutlineInstanceAddress
	): Generator<ResponsesTraverseResult> {
		for (let index = 0; index < sections.length; index++) {
			const section = sections[index];
			if (!section) continue; // Handles the "holes" in the responses array. See `hierarchyResponses` definition

			const addressPoint: OutlineSectionAddressPoint =
				responsesType === OutlineHierarchyItemType.Children
					? { childrenIndex: index }
					: { toggleIndex: index };
			const sectionAddress = parentAddress.concat(addressPoint);

			yield { section, address: sectionAddress };
			yield* traverseResponses({
				responses: section.instances,
				responsesType,
				parentAddress: sectionAddress,
				depth
			});
		}
	}

	function* traverseResponsesInstances(
		instances: Array<OutlineResponseInstance> | undefined,
		parentAddress: OutlineInstanceAddress
	): Generator<ResponsesTraverseResult> {
		for (let index = 0; index < instances?.length; index++) {
			const instance = instances[index];

			const addressPoint = { instanceIndex: index };
			const instanceAddress = parentAddress.concat(addressPoint);

			yield { instance, address: instanceAddress };

			if (!isChildrenResponseInstance(instance, instanceAddress)) continue; // Only children instances can nest

			const { children, toggle } = instance;
			if (children) {
				yield* traverseResponses({
					responses: children,
					responsesType: OutlineHierarchyItemType.Children,
					parentAddress: instanceAddress,
					depth
				});
			}
			if (toggle) {
				yield* traverseResponses({
					responses: toggle,
					responsesType: OutlineHierarchyItemType.Toggle,
					parentAddress: instanceAddress,
					depth
				});
			}
		}
	}
}

/**
 * Get all the responses sections of the certain template rendered in the Outline
 */
export const getTemplateSections = (args: {
	template: string;
	hierarchy: OutlineHierarchy;
	hierarchyResponses: OutlineHierarchyResponses;
	depth?: number;
}): Array<OutlineResponseSection> => {
	const { template, hierarchy, hierarchyResponses, depth } = args;
	const templateAddresses = getTemplateAddresses(template, hierarchy, depth);
	const instancesResponses = [...traverseResponsesSections(hierarchyResponses, depth)];
	return filterTemplateResponses(instancesResponses, templateAddresses).map(
		({ section }) => section
	);
};

/**
 * Get all the responses instances of the certain template rendered in the Outline
 */
export const getTemplateInstances = (args: {
	template: string;
	hierarchy: OutlineHierarchy;
	hierarchyResponses: OutlineHierarchyResponses;
	depth?: number;
}): Array<OutlineResponseInstance> => {
	const { template, hierarchy, hierarchyResponses, depth } = args;
	const templateAddresses = getTemplateAddresses(template, hierarchy);
	const instancesResponses = [...traverseResponsesInstances(hierarchyResponses, depth)];
	return filterTemplateResponses(instancesResponses, templateAddresses).map(
		({ instance }) => instance
	);
};

/**
 * Get all the parent instances which have the certain template under the `toggle` property
 */
export const getToggleTemplateParentsInstances = (args: {
	template: string;
	hierarchy: OutlineHierarchy;
	hierarchyResponses: OutlineHierarchyResponses;
}): Array<OutlineResponseInstance> => {
	const { template, hierarchy, hierarchyResponses } = args;
	const toggleTemplateParentAddresses = getToggleTemplateParentsAddresses(template, hierarchy);
	const instancesResponses = [...traverseResponsesInstances(hierarchyResponses)];
	return filterTemplateResponses(instancesResponses, toggleTemplateParentAddresses).map(
		({ instance }) => instance
	);
};

/**
 * Get all the responses with the address section address among the template addresses
 */
export const filterTemplateResponses = <T extends ResponsesTraverseResult>(
	responses: Array<T>,
	templateAddresses: Array<OutlineSectionAddress>
): Array<T> =>
	responses.filter(({ address }) =>
		templateAddresses.some((sectionAddress) =>
			isEqual(instanceToSectionAddress(address), sectionAddress)
		)
	);

export const getInstanceHasDescendants = (instance: OutlineResponseInstance): boolean => {
	if ('children' in instance) {
		const descendantInstances = instance.children.flatMap(({ instances }) => instances);
		return descendantInstances.length > 0;
	}
};
