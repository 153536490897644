import first from 'lodash-es/first';

import { getRangeAddresses, getFormattingForCell, formatCurrencyLikeValue, getIsFormula } from '.';
import { StringDictionary } from '../../WritingTemplate/types';
import { getStartCell, getEndCell } from './selection';
import {
	RawRegion,
	RawRegions,
	RegionHeaders,
	Sheet,
	Formatting,
	EvaluatedCells,
	CellFormatting,
	CellValues
} from './types';

const completeValueWithFormula = (props: {
	selectedCell: string;
	cellFormatting: CellFormatting;
	evaluatedCells: EvaluatedCells;
}): string => {
	const { selectedCell, cellFormatting, evaluatedCells } = props;

	const formulaResult = evaluatedCells[selectedCell];
	if (!formulaResult) return '';

	const { result, success, error } = formulaResult;

	if (!success) return `${result} Has formula Has Error ${error}`;

	return `${formatCurrencyLikeValue(`${result}`, cellFormatting)} Has formula`;
};

export const getAnnounceValue = (props: {
	selectedCell: string;
	cellValue: string;
	sheetFormatting?: Formatting;
	evaluatedCells: EvaluatedCells;
	onBlurErrorMessages?: CellValues;
}): string => {
	const { selectedCell, cellValue, sheetFormatting, evaluatedCells, onBlurErrorMessages } = props;

	/**
	 * If cell value is empty, return empty string
	 * We need this because any further transformations is not necessary, and if they happen they convert empty string to 0
	 */
	if (!cellValue) return '';

	const isCellIncludesFormula = getIsFormula(cellValue);

	const cellFormatting = getFormattingForCell(selectedCell, sheetFormatting);

	if (isCellIncludesFormula)
		return completeValueWithFormula({
			selectedCell,
			cellFormatting,
			evaluatedCells
		});

	/**
	 * #ERROR! is static for all on-blur-errors
	 */
	if (onBlurErrorMessages?.[selectedCell])
		return `#ERROR! Has Error ${onBlurErrorMessages?.[selectedCell]}`;

	return formatCurrencyLikeValue(cellValue, cellFormatting);
};

export const isNamedRegions = (sheet: Sheet): boolean => !!sheet?.regions;

const getAvailableRanges = (sheet: Sheet): RawRegions => {
	const regions = sheet.regions;

	const regionRanges = Object.entries(regions)
		.map(([region, headers]) => {
			const { result, value } = getRangeAddresses(region);

			if (!result) return null;

			return [value, headers];
		})
		.filter((e) => e) as [string[], { headers: string[] }][];

	return regionRanges;
};

export const isAnyRegionOnSelection = (regions: RawRegions, selected: string): boolean =>
	regions.some(([range]) => range.includes(selected));

export const getRegionBySelected = (regions: RawRegions, selected: string): RawRegion =>
	regions.find(([range]) => range.includes(selected));

export const isHeadersProvided = (regionHeaders: RegionHeaders): boolean => !!regionHeaders.headers;

export const isHasVerticalHeader = (vAddress: string): boolean =>
	!!vAddress && Number.isNaN(+vAddress);

export const isHasHorizontalHeader = (hAddress: string): boolean =>
	!!hAddress && !Number.isNaN(+hAddress);

export const getCellHeader = (
	horizontal: string,
	vertical: string,
	selected: string,
	cellValues: { [key: string]: string }
): string => {
	const cell = `${horizontal}${vertical}`;
	const text = cellValues[cell];
	return text && cell !== selected ? text : '';
};

export const getNamedRegionText = (
	selected: string,
	currentCell: string,
	isNamedRegions: boolean,
	sheet: Sheet,
	cellValues: { [key: string]: string }
): string => {
	if (isNamedRegions) {
		const regionRanges = getAvailableRanges(sheet);

		if (isAnyRegionOnSelection(regionRanges, selected)) {
			const [regionRange, regionHeader] = getRegionBySelected(regionRanges, selected);

			if (isHeadersProvided(regionHeader)) {
				const { headers } = regionHeader;
				const [primary, secondary] = headers;
				const re = /([A-Za-z]+)([0-9]+)/gi;
				re.lastIndex = 0;
				const [, currentHorizontal, currentVertical] = currentCell
					? re.exec(currentCell)
					: ['', ''];
				re.lastIndex = 0;
				const [, horizontal, vertical] = re.exec(selected);

				if (horizontal === currentHorizontal) {
					if (isHasVerticalHeader(primary)) {
						return getCellHeader(primary, vertical, selected, cellValues);
					} else if (isHasVerticalHeader(secondary)) {
						return getCellHeader(secondary, vertical, selected, cellValues);
					}

					return '';
				} else if (vertical === currentVertical) {
					if (isHasHorizontalHeader(primary)) {
						return getCellHeader(horizontal, primary, selected, cellValues);
					} else if (isHasHorizontalHeader(secondary)) {
						return getCellHeader(horizontal, secondary, selected, cellValues);
					}

					return '';
				}
			}
		}
	}

	return '';
};

export const getStartCellAddress = (selectedIndexes: string[]): string => {
	const { horizontal, vertical } = getStartCell(selectedIndexes);

	return `${String.fromCharCode(horizontal)}${vertical}`;
};

export const getEndCellAddress = (selectedIndexes: string[]): string => {
	const { horizontal, vertical } = getEndCell(selectedIndexes);

	return `${String.fromCharCode(horizontal)}${vertical}`;
};

export const getAnnounceForSelect = (
	range: string[],
	cellValues: StringDictionary,
	formatting: Formatting,
	evaluatedCells: EvaluatedCells
): string => {
	if (!range) return '';

	if (range.length === 1) return `${first(range)} selected`;

	const start = getStartCellAddress(range);
	const end = getEndCellAddress(range);

	const startValue = cellValues[start];
	const endValue = cellValues[end];

	const startText = `${getAnnounceValue({
		selectedCell: start,
		cellValue: startValue,
		sheetFormatting: formatting,
		evaluatedCells
	})} ${start}`;
	const endText = `${getAnnounceValue({
		selectedCell: end,
		cellValue: endValue,
		sheetFormatting: formatting,
		evaluatedCells
	})} ${end}`;

	return `${startText} through ${endText} selected`;
};
