import React, { FC } from 'react';

import { label, labelNumber } from '~/components/Outline/components/Label/styles';
import { getAddressDepth } from '~/components/Outline/helpers';
import {
	selectLabelId,
	selectTemplate,
	selectTemplateDisableOutputLabel,
	selectTemplateLabelNumber,
	selectTemplateLabelText,
	selectVariant,
	useOutlineSelector
} from '~/components/Outline/store';
import { OutlineInstanceAddress } from '~/components/Outline/types';

interface Props {
	address: OutlineInstanceAddress;
}

const Label: FC<Props> = (props) => {
	const { address } = props;

	const variant = useOutlineSelector(selectVariant);
	const depth = getAddressDepth(address);

	const template = useOutlineSelector((state) => selectTemplate(state, address));
	const { label_type: labelType } = template;

	const disableOutputLabel = useOutlineSelector((state) =>
		selectTemplateDisableOutputLabel(state, address)
	);

	const number = useOutlineSelector((state) => selectTemplateLabelNumber(state, address));
	const text = useOutlineSelector((state) => selectTemplateLabelText(state, address));

	const labelId = useOutlineSelector((state) => selectLabelId(state, address));
	return (
		<div
			id={labelId}
			css={label({ labelType, variant, disableOutputLabel })}
			className="outline-instance-label"
			role={depth === 1 ? 'heading' : undefined}
			aria-level={depth === 1 ? 6 : undefined}>
			<span css={labelNumber}>{number}</span>
			<span>{text}</span>
		</div>
	);
};

export default Label;
