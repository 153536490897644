import React from 'react';
import type { IconBaseProps } from 'react-icons';
import { FaChevronDown } from 'react-icons/fa';

import { expandCollapseIconProps } from './expandCollapseIconProps';
import { iconStyles } from './styles';

const ExpandIcon: React.VFC<IconBaseProps> = (props) => {
	return <FaChevronDown css={iconStyles} {...expandCollapseIconProps} {...props} />;
};

export default ExpandIcon;
