import React from 'react';
import { object } from 'prop-types';

import { Text as TextFromLibs } from '@soomo/lib/components/pageElements/index';
import { useAudioFigures } from '@soomo/lib/hooks/index';

const Text = ({ text, citationReferences, containerType }) => {
	const { playMediaSnippet } = useAudioFigures();

	return (
		<TextFromLibs
			online
			className="webtext-text"
			element={{ body: text.body }}
			citationReferences={citationReferences}
			containerType={containerType}
			playMediaSnippet={playMediaSnippet}
			getElementByFamilyId={() => null}
		/>
	);
};

Text.propTypes = {
	text: object.isRequired
};

export default Text;
