import * as React from 'react';

export function Heart(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="M50.11 24.05a9.14 9.14 0 0 0-6.77-2.88A8.26 8.26 0 0 0 38 23a15.71 15.71 0 0 0-2.16 2.16A7.93 7.93 0 0 0 33.7 23a8.92 8.92 0 0 0-5.33-1.87 9.15 9.15 0 0 0-6.77 2.88 10.63 10.63 0 0 0-2.74 7.2 12.28 12.28 0 0 0 3.32 8.21 64.75 64.75 0 0 0 8.35 7.77c1.15 1 2.45 2.16 3.89 3.31a2 2 0 0 0 1.29.44 2 2 0 0 0 1.3-.44c1.29-1.15 2.73-2.3 3.89-3.31a66.11 66.11 0 0 0 8.35-7.77 12.32 12.32 0 0 0 3.31-8.21 8.86 8.86 0 0 0-2.45-7.16Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
