import React from 'react';

import { getOS } from '~/utils';

import { Figure } from '../Figure';
import WebtextTimeline from './Timeline';

import type { TimelineElement } from '~/types/WebtextManifest';

interface Props {
	element: TimelineElement;
}

const TimelineFigure: React.FC<Props> = (props) => {
	const {
		element: {
			title,
			instructions,
			touchInstructions,
			data,
			options,
			onSlideChange,
			caption: tlCaption,
			credits,
			figure_number
		}
	} = props;

	const deviceInstructions = /android|ios/.test(getOS())
		? touchInstructions ?? instructions
		: instructions;

	return (
		<Figure
			figureOptions={{
				title,
				payload: <WebtextTimeline data={data} options={options} onSlideChange={onSlideChange} />,
				caption: deviceInstructions ? `${deviceInstructions}<br />${tlCaption}` : tlCaption,
				credits,
				header_style: 'traditional',
				figure_number
			}}
		/>
	);
};

export default TimelineFigure;
