export const getHasLimitedAttempts = (attemptsAllowed: number): boolean => attemptsAllowed !== -1;

export const getAttemptsRemaining = ({
	attemptsAllowed,
	resetCount,
	isAnswered
}: {
	attemptsAllowed: number;
	resetCount: number;
	isAnswered: boolean;
}): number => {
	if (!getHasLimitedAttempts(attemptsAllowed)) {
		return Infinity;
	}

	return attemptsAllowed - resetCount - (isAnswered ? 1 : 0);
};
