import React, { useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import LibsStandaloneMCQuestionPool from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/StandaloneMCQuestionPool';
import type { MCQuestionPoolElement, MCQuestionPoolItem } from '@soomo/lib/types/WebtextManifest';
import type { MCQuestionPoolAnswer } from '@soomo/lib/notebook/types/index';
import { CorrectnessIcon, MCQPHistoryItem } from '@soomo/lib/notebook/components/index';
import {
	getActiveAnswer,
	getActiveQuestion,
	getAnsweredPoolQuestions,
	getNumResets
} from '@soomo/lib/notebook/utils/mcQuestionPool';
import { getPolicyForPage, getPolicyForRespondable } from '@soomo/lib/utils/index';

import ActivityHistory from 'Containers/ActivityHistory';
import ActivityHeader from 'Components/ActivityHeader';
import ConfirmDialog from 'Components/ConfirmDialog';
import pageActivityQuery from 'Requests/PageActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import resetMCQuestionPoolAnswersMutation from 'Requests/ResetMCQuestionPoolAnswersMutation';
import * as uiActions from 'Actions/uiActions';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

import type { RootState } from 'Store/index';

import styles from './MCQuestionPool.scss';
import mcqStyles from './MCQuestion.scss';
import pageViewStyles from '../PageView.scss';

type MCQuestionPoolActivity = {
	answers: Array<MCQuestionPoolAnswer> | null;
};

interface Props {
	element: MCQuestionPoolElement;
	activity: MCQuestionPoolActivity;
	inContext: boolean;
	timeZone: string;
	policyDocument: any;
	resetMCQuestionPool: (args: {
		course;
		user;
		chapter;
		page;
		questionPoolFamilyId;
	}) => Promise<void>;
	course: any;
	user: any;
	page: any;
	chapter: any;
}

const MCQuestionPool: React.VFC<Props> = ({
	element,
	activity,
	inContext,
	timeZone,
	policyDocument,
	resetMCQuestionPool,
	chapter,
	course,
	page,
	user
}) => {
	const [isResetModalOpen, setResetModalOpen] = useState(false);

	const { answers } = activity;
	if (!answers) return null;

	const answer = getActiveAnswer(answers);
	const numResets = getNumResets(answers);
	const activeQuestion = getActiveQuestion({ element, answer, userId: user.id, numResets });
	const isAnswerCorrect = getIsAnswerCorrect(activeQuestion, answer);
	const answerChoice = activeQuestion.choices.find((choice) => choice.family_id === answer?.body);

	const pagePolicy = getPolicyForPage(policyDocument, page.id);
	const elementLabel = pagePolicy.mc_question_type_label;

	const elementPolicy = getPolicyForRespondable(
		policyDocument,
		element.ruby_class,
		element.family_id
	);
	const attemptsAllowed = elementPolicy.attempts_allowed;

	if (inContext)
		return (
			<LibsStandaloneMCQuestionPool
				showInstructorView={false}
				userId={user.id}
				attemptsAllowed={attemptsAllowed}
				onSave={() => null}
				onReset={() => null}
				questionPool={element}
				answer={
					answer
						? ({
								...answer,
								rejoinder: answerChoice?.rejoinder,
								completed: true,
								correct: isAnswerCorrect
						  } as any)
						: null
				}
				resetCount={numResets}
				readOnly
				chapterFamilyId={chapter.family_id}
				courseId={course.id}
			/>
		);

	return (
		<>
			<div className={mcqStyles.MCQuestion}>
				{answer && (
					<CorrectnessIcon correct={isAnswerCorrect} className={mcqStyles.CurrentIsCorrect} />
				)}
				<div className={mcqStyles.ActivitySummary}>
					<span className={pageViewStyles.QuestionType}>
						{elementLabel || 'Multiple-Choice Question'}
					</span>
				</div>
				<h2
					className={pageViewStyles.QuestionBody}
					dangerouslySetInnerHTML={{ __html: activeQuestion.body }}
				/>
				{answer?.saved_at && (
					<ActivityHeader timestamp={answer.saved_at} timeZone={timeZone}>
						LATEST ATTEMPT
					</ActivityHeader>
				)}
				<ul className={mcqStyles.QuestionChoiceList}>
					{activeQuestion.choices.map((choice) => (
						<li
							key={choice.family_id}
							className={cn(
								mcqStyles.QuestionChoice,
								choice.is_correct && mcqStyles.CorrectChoice
							)}>
							<input
								id={choice.family_id}
								type="radio"
								checked={answer?.body === choice.family_id}
								disabled
							/>
							<label htmlFor={choice.family_id} dangerouslySetInnerHTML={{ __html: choice.body }} />
						</li>
					))}
				</ul>
				{answer && (
					<button className={styles.ResetButton} onClick={() => setResetModalOpen(true)}>
						Reset This Question
					</button>
				)}
			</div>
			{answers.length > 0 && (
				<ActivityHistory
					answered={!!answer}
					attemptCount={answers.length}
					attemptsAllowed={attemptsAllowed}>
					{answers.map((answer) => {
						const question = getAnsweredPoolQuestions(element.questions, answer.question_family_id);
						const isAnswerCorrect = getIsAnswerCorrect(question, answer);
						return (
							<MCQPHistoryItem
								key={answer.id}
								answer={{ ...answer, correct: isAnswerCorrect }}
								question={question}
								timeZone={timeZone}
							/>
						);
					})}
				</ActivityHistory>
			)}
			<ConfirmDialog
				open={isResetModalOpen}
				onConfirm={() => {
					resetMCQuestionPool({
						questionPoolFamilyId: element.family_id,
						chapter,
						course,
						page,
						user
					});
				}}
				onClose={() => setResetModalOpen(false)}>
				<p>
					Resetting will clear the answer for this multiple-choice question and allow the student to
					try again. Students are notified of all question resets. (To reset short-answer or
					response-board questions, use the “Unpost” option beneath the question or from the “View
					all responses” screen.)
				</p>
				<p>Would you like to reset this multiple-choice question for this student?</p>
			</ConfirmDialog>
		</>
	);
};

const getCorrectAnswer = (question: MCQuestionPoolItem) =>
	question.choices.find((ch) => ch.is_correct);

export const getIsAnswerCorrect = (
	activeQuestion: MCQuestionPoolItem,
	answer?: MCQuestionPoolAnswer
): boolean => {
	if (!answer) return false;

	const correctAnswer = getCorrectAnswer(activeQuestion);
	return correctAnswer.family_id === answer.body;
};

const mapStateToProps = (state: RootState) => {
	const { student: userId, scope: pageId } = state.ui.currentViewParams;
	const page = state.entities.pages[pageId] || { id: null };
	return {
		timeZone: state.entities.course.time_zone,
		course: state.entities.course,
		user: state.entities.users[userId],
		page,
		chapter: page.id ? state.entities.chapters[page.chapter_id] : { id: null },
		policyDocument: state.entities.course.policy_document
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch),
	resetMCQuestionPool: ({ course, user, chapter, page, questionPoolFamilyId }) =>
		dispatch(
			resetMCQuestionPoolAnswersMutation({
				courseId: course.id,
				userId: user.id,
				pageId: page.id,
				questionPoolFamilyId
			})
		).then(() => {
			dispatch(
				pageActivityQuery({ courseId: course.id, userId: user.id, pageId: page.id, force: true })
			);
			dispatch(
				metricsQuery({
					courseId: course.id,
					userIds: [user.id],
					scopeId: chapter.id,
					groupby: 'page',
					force: true
				})
			);
		})
});

export default connect(mapStateToProps, mapDispatchToProps)(withLibsThemeProvider(MCQuestionPool));
