import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $isListNode, ListNode } from '@lexical/list';
import { UNORDERED_LIST } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import {
	$getRoot,
	COMMAND_PRIORITY_HIGH,
	EditorState,
	KEY_TAB_COMMAND,
	LexicalEditor,
	ParagraphNode
} from 'lexical';
import { useResizeObserver } from 'usehooks-ts';

import { GenericErrorBoundary } from '~/components';
import InputPlaceholder from '~/components/InputPlaceholder';
import { EditModeStateContext } from '~/components/WritingTemplate/EditMode/providers/EditModeStateProvider';

import { editorId, placeholderId } from '../../helpers';
import { CitationResponse, CitationsConfig, EditorSpecificOptions } from '../../types';
import CitationDialog from './components/CitationDialog';
import CitationMetaContext from './context/CitationMetaContext';
import { formatInEditorCitations, formatOutputString } from './helpers/formatting.helper';
import withCitationsProvider from './hoc/withCitationsProvider';
import withLexicalComposer from './hoc/withLexicalComposer';
import { useCitationsStore } from './hooks/useCitationsStore';
import { useValueInit } from './hooks/useValueInit';
import { $isCitationNode } from './nodes/CitationNode';
import {
	AccessibleAnnouncerPlugin,
	ToolbarPlugin,
	ValidationMessagesPlugin,
	CitationSpacerPlugin,
	CitationsPlugin,
	CitationTurNumberPlugin,
	ClipboardPlugin
} from './plugins';
import { CustomOnChangePlugin } from './plugins/CustomOnChangePlugin';
import { editorStyle } from './styles';

interface Props {
	dest: string;
	value: string;
	placeholder: string;
	editorOptions: EditorSpecificOptions;
	minimalEditorHeight: number;
	validationMessage?: string;
	withCitations?: boolean;
	citationsConfig?: CitationsConfig;
	storedCitations?: CitationResponse[];
	handleChange: (value: string, citationsList?: CitationResponse[]) => void;
	onUserUpdate: () => void;
}

const LexicalRTE: React.FC<Props> = (props) => {
	const {
		dest,
		value,
		minimalEditorHeight: minimalEditorHeightProp,
		editorOptions,
		placeholder,
		handleChange,
		validationMessage,
		onUserUpdate,
		withCitations,
		citationsConfig
	} = props;

	const { builderFamilyId } = useContext(EditModeStateContext);

	const [editor] = useLexicalComposerContext();
	const [editorHasContent, setEditorHasContent] = useState(false);
	const { inEditorCitations } = useContext(CitationMetaContext);

	useValueInit(value, withCitations, citationsConfig?.style);

	/**
	 * The minimal height of the editor is based on the height of the provided placeholder (if present)
	 * Also, on resize, we need to update the min-height of the editor to match the placeholder
	 */
	const placeholderRef = useRef<HTMLDivElement | null>(null);
	const { height: placeholderHeight = 0 } = useResizeObserver({
		ref: placeholderRef,
		box: 'border-box'
	});

	const onChange = (_: EditorState, editor: LexicalEditor, disableAutosave?: boolean) => {
		editor.update(() => {
			const htmlString = $generateHtmlFromNodes(editor);

			/**
			 * Prepare html string by adding or removing attributes and transform marks
			 */
			const formattedString = formatOutputString(
				htmlString,
				editorOptions,
				withCitations,
				citationsConfig?.style,
				inEditorCitations
			);
			/**
			 * Filtering all citations and looking for existing citations
			 * We store inside `inEditorCitations` even removed citations, because user should be able to restore them by Ctrl+Z
			 */
			const existingCitations = formatInEditorCitations(
				withCitations,
				inEditorCitations,
				formattedString
			);
			handleChange(formattedString, existingCitations);
		});

		/**
		 * Draft save trigger
		 */
		if (!disableAutosave) onUserUpdate?.();
	};

	useCitationsStore(onChange);

	useEffect(() => {
		/**
		 * Disable default tab command, that create indents and let it be default browser behavior
		 */
		editor.registerCommand(KEY_TAB_COMMAND, () => true, COMMAND_PRIORITY_HIGH);

		return editor.registerUpdateListener(({ editorState }) => {
			/**
			 * Whether text or other custom or default top level nodes are present in the editor update editorHasText state
			 * We're checking it to show or hide placeholder
			 */
			editorState.read(() => {
				const root = $getRoot();

				const hasText = !!root.getTextContent().length;
				/**
				 * Basic structure looks like this
				 * ParagraphNode or ListNode - top level nodes
				 * - TextNode, CitationNode, ListItemNode  - children of top level nodes
				 */
				const hasCitations =
					withCitations &&
					root
						.getChildren()
						.some((node: ParagraphNode | ListNode) =>
							node.getChildren().some((node) => $isCitationNode(node))
						);
				const hasLists =
					editorOptions.allowedLists?.length &&
					root.getChildren().some((node) => $isListNode(node));

				setEditorHasContent(hasText || hasCitations || hasLists);
			});
		});
	}, [editor, withCitations, editorOptions.allowedLists]);

	useEffect(() => {
		/**
		 * Editor element does not provide ref of attribute for it, so we change this manually and directly
		 */
		const editorElement = document.getElementById(`editor-${dest}`);

		if (!editorElement) return;

		editorElement.setAttribute('aria-invalid', `${Boolean(validationMessage)}`);
	}, [validationMessage, dest]);

	const theme = useTheme();

	const listPlugins = useMemo(() => {
		if (!editorOptions.allowedLists?.length) return;

		/**
		 * As long as we're enable only one transform we can render shortcut plugin with only one argument and only when lists enabled
		 * I believe we'll support only ordered lists in nearest future, so basically all calculations left here.
		 */

		return (
			<>
				<ListPlugin />
				<MarkdownShortcutPlugin transformers={[UNORDERED_LIST]} />
			</>
		);
	}, [editorOptions.allowedLists]);

	const citationsPlugin = useMemo(
		() =>
			withCitations ? (
				<>
					<CitationsPlugin />
					<CitationTurNumberPlugin />
					<CitationSpacerPlugin />
				</>
			) : null,
		[withCitations]
	);

	const editorDescription = useMemo(
		() =>
			validationMessage
				? `rt-validation-message-${dest}`
				: placeholder
					? placeholderId(builderFamilyId, dest)
					: null,
		[validationMessage, dest, placeholder, builderFamilyId]
	);

	return (
		<>
			<div
				id={`rich-${dest}`}
				css={editorStyle(theme, {
					minEditorHeight: Math.max(placeholderHeight, minimalEditorHeightProp),
					invalid: !!validationMessage,
					readOnly: editorOptions.readOnly,
					allowMultiParagraph: editorOptions.isAllowMultiParagraph
				})}>
				<AccessibleAnnouncerPlugin isListsEnabled={Boolean(editorOptions.allowedLists?.length)} />
				<ToolbarPlugin
					allowedLists={editorOptions.allowedLists}
					readOnly={editorOptions.readOnly}
					withCitations={withCitations}
				/>
				<ValidationMessagesPlugin dest={dest} message={validationMessage} />
				<div className="input-wrapper">
					{!editorHasContent && (
						<InputPlaceholder
							id={dest ? placeholderId(builderFamilyId, dest) : undefined}
							className="editor-placeholder"
							placeholder={placeholder}
						/>
					)}
					<RichTextPlugin
						ErrorBoundary={({ onError: _, children }) => (
							// rather than pass onError to GenericErrorBoundary, let Rollbar handle it instead
							<GenericErrorBoundary>{children}</GenericErrorBoundary>
						)}
						placeholder={null} // Isn't used because of the recommendation to put placeholder before the editable area See T-73614
						contentEditable={
							<ContentEditable
								id={editorId(builderFamilyId, dest)}
								className="editor-input"
								ariaLabel="Your Response"
								ariaMultiline
								ariaDescribedBy={editorDescription}
								ariaInvalid={!!validationMessage}
								style={
									!editorOptions.readOnly
										? {
												/**
												 * Fixes a very specific bug in Safari 15.x + v2024 annotations.
												 *
												 * The SPA renderer (which v2024 annotations uses) has an `all: revert-layer` CSS rule to override
												 * Core Sass styles. But in Safari 15.x, that causes `contenteditable`s to revert to
												 * `-webkit-user-modify: read-only`, meaning students can't type in writing templates at all.
												 *
												 * In Safari 16+ and other browsers, even with `all: revert-layer` the `contenteditable` stays as
												 * `read-write`, as expected.
												 *
												 * So, for Safari 15.x users, set `WebkitUserModify` (=> `-webkit-user-modify`) manually.
												 * (And for other browsers, this is already set, so it won't have any effect.)
												 *
												 * See T-175638, T-177726.
												 */
												WebkitUserModify: 'read-write'
											}
										: undefined
								}
							/>
						}
					/>
					{/*Used for placeholder dimensions tracking even when value is present in the editor*/}
					<InputPlaceholder
						ref={placeholderRef}
						className="editor-placeholder"
						placeholder={placeholder}
						invisible
					/>
				</div>

				<ClipboardPlugin />
				<CustomOnChangePlugin onChange={onChange} />
				<HistoryPlugin />
				{citationsPlugin}
				{listPlugins}
			</div>
			<CitationDialog />
		</>
	);
};

export default withLexicalComposer(withCitationsProvider(LexicalRTE));
