import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Dialog, Tooltip } from '@material-ui/core';

import Button from '~/components/WebtextButton';
import Instruction from '~/components/WritingTemplate/Instruction';
import Selector from '~/components/WritingTemplate/Selector';
import Text from '~/components/WritingTemplate/Text';

import CitationHelp from './CitationHelp';
import CloseDialogButton from './CloseDialogButton';
import { styles, useHelperDialogStyles, useStyles } from './styles';
import { CitationMode, CitationStyle } from '../../../types';
import SimpleEditor from '../../SimpleEditor';
import CitationMetaContext from '../context/CitationMetaContext';
import {
	getCitationStyleOptions,
	getIsExtraInput,
	makeCitationExportData,
	notifyError,
	preInitCitation
} from '../helpers/citations.helper';

const CitationDialog: FC = () => {
	const {
		editingCitation,
		showCitationDialog,
		closeDialog,
		availableCitations,
		citationStyle,
		addCitation,
		updateCitation
	} = useContext(CitationMetaContext);

	const [citationSource, setSource] = useState<string>(null);
	const [citationStyleOption, setOption] = useState<string>(null);
	const [extraInputValue, setExtraValue] = useState<string>(null);
	const [mode, setMode] = useState<CitationMode>('create');

	/**
	 * Once modal closed we have to clean any data used for previous create/update
	 */
	useEffect(() => {
		if (!showCitationDialog) {
			/**
			 * When modal close, erase all inputs
			 */
			setSource(null);
			setOption(null);
			setExtraValue(null);
		}
	}, [showCitationDialog]);

	/**
	 * In this useEffect we define what mode is currently active;
	 * create mode means that we do not have any pre-loads and create a new citation;
	 * update mode means that we preload already created citation and make all field already filled;
	 */
	useEffect(() => {
		if (!editingCitation) return setMode('create');

		const values = preInitCitation(citationStyle, editingCitation, availableCitations);
		if (!values) return;

		const { source, option, extraInput } = values;

		setSource(source);
		setOption(option);
		setExtraValue(extraInput);
		setMode('update');
	}, [editingCitation, availableCitations, citationStyle]);

	/**
	 * Citation create process, starts with collecting all available sources from config
	 * They will be the first selector values
	 */
	const citationSources = useMemo(
		() => availableCitations?.map(({ 'options-label': value }) => value),
		[availableCitations]
	);

	/**
	 * Next step is create citation source styles, which available for any source, so based on this value
	 * We create selector #2 values which will allow user to select different options
	 * Note: turabian style ignores this property
	 */
	const citationStyleOptions = useMemo(() => {
		if (!citationSource) return null;

		const options = getCitationStyleOptions(availableCitations, citationStyle, citationSource);
		if (!options) return null;

		return Object.values(options) as string[];
	}, [citationSource, availableCitations, citationStyle]);

	/**
	 * Based on citation source style or citation source (for turabian) we add (or not) extra input field;
	 * This field appears for citation styles that have to have `page-number` property;
	 */
	const isExtraInput = useMemo(
		() => getIsExtraInput(citationStyle, citationSource, citationStyleOption, availableCitations),
		[citationStyleOption, citationSource, availableCitations, citationStyle]
	);

	const helperDialog = useMemo(() => <CitationHelp style={citationStyle} />, [citationStyle]);

	const onChoseSource = (source: string) => {
		setSource(source);
		/**
		 * Whenever source changed we're dropping every else value below;
		 */
		setOption(null);
	};

	const onChoseOption = (source: string) => {
		setOption(source);
	};

	const isHelperOpen = citationSource && citationStyle !== CitationStyle.Turabian;

	/**
	 * Create citation button is disabled until;
	 * 1) citation source not selected
	 * 2) optional: citation style not selected
	 * 3) optional: extra page input not filled
	 */
	const isSubmitDisabled = useMemo(() => {
		/**
		 * When no source; common for all styles
		 */
		if (!citationSource) return true;

		/**
		 * When no options; apa and sws
		 */
		if (citationStyleOptions?.length && !citationStyleOption) return true;

		/**
		 * When extra input active and it's empty
		 */
		if (isExtraInput && !extraInputValue?.length) return true;

		return false;
	}, [citationSource, citationStyleOptions, citationStyleOption, isExtraInput, extraInputValue]);

	/**
	 * Once button clicked; based on selected data we create citation metadata;
	 * example:
	 * { identifier: string (citation uniq id), attributes: {/ diverse citations attributes based on citation style and inputs /}}, key: string (uniq citation data id inside a metadata from configs), markerValue: string (marker displayed everywhere docx, output, input, etc.) }
	 * If we're not able to create a citation we're making error and ignore create;
	 * This can happen when citations wrong configured in TOC;
	 */
	const handleCreateCitation = () => {
		/**
		 * We do not reset this anymore on change source/style, but even when value exist we have to check is it expected
		 */
		const extraInput = isExtraInput ? extraInputValue : null;

		const citationMetadata = makeCitationExportData(
			citationStyle,
			citationSource,
			citationStyleOption,
			availableCitations,
			extraInput
		);

		if (!citationMetadata)
			return notifyError({ citationStyle, citationSource, extraInputValue }, mode);

		if (mode === 'update') {
			/**
			 * Keep the previous marker for turabian, because it shouldn't be generated in place no update, because we don't change the order but turabian marker based on the order of citation inside the editor;
			 */
			const citationDecorator =
				citationStyle === CitationStyle.Turabian
					? editingCitation.markerValue
					: citationMetadata.markerValue;
			updateCitation({
				identifier: editingCitation.identifier,
				...{ ...citationMetadata, markerValue: citationDecorator }
			});
			return;
		}

		addCitation(citationMetadata);
	};

	const dialogHelperClasses = useHelperDialogStyles();

	return (
		<Dialog
			classes={useStyles()}
			open={showCitationDialog}
			onClose={closeDialog}
			maxWidth={'lg'}
			fullWidth
			role="dialog"
			aria-describedby="createInstruction">
			<Instruction
				id="createInstruction"
				value="Build your citation by following the steps below"
				role={'heading'}
				aria-level={2}
			/>
			<div css={styles}>
				<CloseDialogButton handleClick={closeDialog} />
				<div className="pick-answer-container">
					<Selector
						choices={citationSources}
						select={citationSource}
						onChange={onChoseSource}
						placeholder={'Step 1: Select a source for your citation'}
						id="step1-select"
					/>
				</div>
				{citationStyleOptions && (
					<div className="pick-answer-container">
						<Selector
							choices={citationStyleOptions}
							onChange={onChoseOption}
							select={citationStyleOption}
							placeholder={'Step 2: Select a citation option'}
							id="step2-select"
						/>
					</div>
				)}
				{isExtraInput && (
					<div className="extra-input pick-answer-container">
						<Text value={'Enter page number'} />
						<div className="input-wrapper">
							<SimpleEditor
								onChange={setExtraValue}
								value={extraInputValue}
								label={'Enter page number'}
								isAreaMultiLine={false}
							/>
						</div>
					</div>
				)}
				<div className="button-wrapper pick-answer-container">
					<Button data-ignore="1" disabled={isSubmitDisabled} onClick={handleCreateCitation}>
						{mode === 'create' ? 'Create citation' : 'Update citation'}
					</Button>
				</div>
				{isHelperOpen && (
					<Tooltip
						arrow
						classes={dialogHelperClasses}
						title={helperDialog}
						placement="bottom"
						interactive>
						<div
							// this really shouldn't have tabindex, but we need it to be focusable/to hold a tooltip
							// (ideally we'd use something other than a tooltip to display this information, e.g. `<details><summary>`)
							// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
							tabIndex={0}
							className={'citation-hint pick-answer-container'}>
							Help me choose an option
						</div>
					</Tooltip>
				)}
			</div>
		</Dialog>
	);
};

export default CitationDialog;
