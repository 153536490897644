import React, { useEffect, useState } from 'react';

import { ClassNames } from '@emotion/react';

import AutosizeTextarea from '~/components/AutosizeTextarea';
import { groupInvalidMessage } from '~/components/WritingTemplate/EditMode/styles';

import { fromHtmlToRawText } from '../../helpers';
import { textAreaStyles, textAreaWrap, validationMessageStyle } from '../../styles';

interface Props {
	dest?: string;
	label?: string;
	placeholder?: string;
	withinTable?: boolean;
	validationMessage?: string;
	isAreaMultiLine: boolean;
	minimalEditorHeight?: number;
	readOnly?: boolean;
	value?: string;
	onUserUpdate?: () => void;
	onChange: (str: string) => void;
}

const SimpleEditor: React.FC<Props> = ({
	dest,
	label = 'Your Response',
	placeholder,
	withinTable,
	validationMessage,
	isAreaMultiLine,
	minimalEditorHeight,
	readOnly,
	value,
	onUserUpdate,
	onChange
}) => {
	// keep `editorValue` state variable in sync with `value` prop
	const [editorValue, setEditorValue] = useState(value ? fromHtmlToRawText(value) : '');

	useEffect(() => {
		setEditorValue(value ? fromHtmlToRawText(value) : '');
	}, [value]);

	useEffect(() => {
		onChange(editorValue);
	}, [editorValue]);

	const validationMessageId = `simple-validation-message-${dest}`;

	const handleTextareaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
		setEditorValue(e.target.value);
		onUserUpdate?.();
	};

	return (
		<div css={textAreaWrap}>
			{validationMessage && (isAreaMultiLine || withinTable) && (
				<div tabIndex={0} css={validationMessageStyle} id={validationMessageId}>
					<span css={groupInvalidMessage}>{validationMessage}</span>
				</div>
			)}
			<ClassNames>
				{({ css, cx, theme }) => (
					<AutosizeTextarea
						id={dest}
						textareaClassName={css(
							textAreaStyles(theme, { withinTable, invalid: !!validationMessage, readOnly })
						)}
						rowsMax={isAreaMultiLine ? undefined : 1}
						minimalEditorHeight={minimalEditorHeight}
						placeholder={placeholder}
						disabled={readOnly}
						aria-label={fromHtmlToRawText(label)}
						aria-describedby={cx({
							[validationMessageId]: !!validationMessage
						})}
						aria-invalid={!!validationMessage}
						value={editorValue}
						onChange={handleTextareaChange}
					/>
				)}
			</ClassNames>
		</div>
	);
};

export default SimpleEditor;
