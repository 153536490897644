import { useEffect, useMemo, useContext } from 'react';

import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { shallow } from 'zustand/shallow';

import { EditModeStateContext } from '../../EditMode/providers/EditModeStateProvider';
import { TemplateValidationContext } from '../../WritingTemplate/providers/TemplateValidationProvider';
import { getIsRange, getRangeAddresses } from '../helpers';
import { ValidationRules, Validations } from '../helpers/types';
import { validator } from '../helpers/validation';
import {
	SpreadsheetValidationTarget,
	ValidationActionType,
	ValidationItemStatus,
	ValidationStatus
} from './../../WritingTemplate/types';
import { useSpreadsheetSelector } from './../store/provider';

export function useSpreadsheetValidation(props: {
	isolated: boolean;
	/**
	 * TODO: specify these types, once we have the types.
	 */
	validations;
	globalFormatting;
	chartSelection: string[];
	setValidationMessage: React.Dispatch<React.SetStateAction<string>>;
}): void {
	const { isolated, validations, chartSelection, globalFormatting, setValidationMessage } = props;

	const { setInvalidCell, setInvalidRange, dest, cellValues, evaluatedFormulasValues } =
		useSpreadsheetSelector(
			(state) => ({
				setInvalidCell: state.setInvalidCell,
				setInvalidRange: state.setInvalidRange,
				dest: state.dest,
				cellValues: state.cellValues,
				evaluatedFormulasValues: state.evaluatedFormulasValues
			}),
			shallow
		);

	const { validationState, previousValidationState, dispatchValidation } =
		useContext(TemplateValidationContext);

	const validationRules = useMemo<ValidationRules>(() => {
		if (!validations) return [];

		/**
		 * @param validationObject
		 * Function will receive validation object which is { addr1: validation, addr2: validation }
		 * where addr might be or cell address or cell range
		 * Unpack object into array of objects, which will provide `phase` validation, and validation order will be saved
		 */
		const toValidationsArray = (validationObject: Validations) => {
			if (isEmpty(validationObject)) return [];

			return Object.entries(validationObject).flatMap(([address, validation]) => {
				if (!getIsRange(address)) {
					return { [address]: validation };
				}

				const { result, value: addressRange } = getRangeAddresses(address);
				if (!result) return [];

				return addressRange.map((addressKey) => ({ [addressKey]: validation }));
			});
		};

		return Array.isArray(validations)
			? validations.flatMap(toValidationsArray)
			: toValidationsArray(validations);
	}, [validations]);

	const editModeState = useContext(EditModeStateContext);
	const spreadsheetProps = editModeState?.spreadsheetProps;
	useEffect(() => {
		if (isolated) return;

		const { status, validations } = validationState;
		const { validations: prevValidations } = previousValidationState;

		/**
		 * We expect validation happen only on pending status, so any success or failures will not trigger it.
		 */
		if (status !== ValidationStatus.ClientPending) return;

		/**
		 * When SPI don't require any validation, just skip
		 */
		const isValidateSelfResponse = Object.keys(validations).includes(dest);
		if (!isValidateSelfResponse) return;

		const validationResult = validator({
			validationRules,
			cellValues,
			selection: chartSelection,
			globalFormatting,
			evaluatedCells: evaluatedFormulasValues
		});

		let validation = validations[dest];
		if (!validation || validation.status === ValidationItemStatus.Pending) {
			// After reset we need to check what message was displayed previously
			validation = prevValidations[dest] || validation;
		}

		//  !validationResult means no invalid result from validation, in this case we pass this ST
		if (!validationResult) {
			dispatchValidation({
				type: ValidationActionType.Update,
				value: {
					[dest]: {
						type: validation.type,
						status: ValidationItemStatus.Success
					}
				}
			});

			return;
		}

		/**
		 * Otherwise, we need to update validation status to failure and provide some data, that will be used in further render
		 * target: {range, value}, message
		 */
		const { message: displayedMessage, target, type } = validation;
		const firstValidationResult = first(Object.entries(validationResult));
		const [address, { messages, validation: validationRule }] = firstValidationResult;
		/**
		 * In case when we have this message in array of validation messages
		 * This will mean that invalid cell, has the same validation issue
		 */
		const prevMessageIdx = messages.findIndex((message) => displayedMessage === message);

		if (address === 'selection') {
			if (target?.type !== 'range' || prevMessageIdx === -1) {
				/**
				 * Previous selection, was not failed or has `cell` issue, now it changed to range scope
				 * Any case we return new type and first message
				 */
				return dispatchValidation({
					type: ValidationActionType.Update,
					value: {
						[dest]: {
							type,
							status: ValidationItemStatus.Failure,
							target: {
								type: 'range',
								rule: validationRule,
								selections: chartSelection
							},
							message: messages[0]
						}
					}
				});
			}

			/**
			 * Previous validation has the same range issue, moreover messages includes typical message
			 * That means target still in selection.
			 */
			const { selections } = target;
			const isSelectionsEqual = isEqual(selections, chartSelection);

			if (isSelectionsEqual) {
				/**
				 * Nothing has changed, return previous result
				 */
				return dispatchValidation({
					type: ValidationActionType.Update,
					value: {
						[dest]: {
							type,
							status: ValidationItemStatus.Failure,
							target: {
								type: 'range',
								rule: validationRule,
								selections
							},
							message: messages[prevMessageIdx]
						}
					}
				});
			}

			/**
			 * In this case we have the same issue, but different range
			 * return next message
			 */
			const message = messages[(prevMessageIdx + 1) % messages.length];
			return dispatchValidation({
				type: ValidationActionType.Update,
				value: {
					[dest]: {
						type,
						status: ValidationItemStatus.Failure,
						target: {
							type: 'range',
							rule: validationRule,
							selections: chartSelection
						},
						message
					}
				}
			});
		}

		/**
		 * Validation failed on cell
		 */
		const cellValue = cellValues[address];

		if (target?.type !== 'cell' || address !== target?.address) {
			/**
			 * Previous validation has a different type or different cell or even not exist
			 * Return new validation as initial
			 */
			return dispatchValidation({
				type: ValidationActionType.Update,
				value: {
					[dest]: {
						type,
						status: ValidationItemStatus.Failure,
						target: {
							type: 'cell',
							address,
							rule: validationRule,
							value: cellValue
						},
						message: messages[0]
					}
				}
			});
		}

		/**
		 * In this place we need to define message index, for next message
		 * When prev message === -1 or cellValue has changed, that means validation issue has changed
		 * Otherwise, we have the same error and need to return the same message
		 */
		const newMessageIdx =
			prevMessageIdx !== -1 && target?.value !== cellValue
				? (prevMessageIdx + 1) % messages.length
				: Math.max(prevMessageIdx, 0);

		dispatchValidation({
			type: ValidationActionType.Update,
			value: {
				[dest]: {
					type,
					status: ValidationItemStatus.Failure,
					target: {
						type: 'cell',
						address: address,
						rule: validationRule,
						value: cellValue
					},
					message: messages[newMessageIdx]
				}
			}
		});
	}, [
		validationState,
		previousValidationState,
		dest,
		validationRules,
		cellValues,
		chartSelection,
		evaluatedFormulasValues,
		isolated,
		globalFormatting,
		dispatchValidation
	]);

	useEffect(() => {
		/**
		 * Every case when validation global status not failure
		 * or SPI particular validation not failure
		 * We just hide every validation mention
		 */
		const hideAnyValidation = () => {
			setInvalidCell(null);
			setValidationMessage(null);
			setInvalidRange(null);
		};

		if (validationState?.status !== ValidationStatus.Failure || isolated) {
			return hideAnyValidation();
		}

		const validation = validationState?.validations?.[dest];
		if (validation?.status !== ValidationItemStatus.Failure) {
			return hideAnyValidation();
		}

		const { message, target } = validation;
		if (target?.type == 'range') {
			const { selections } = target;

			if (target.selections.length === 1) {
				/**
				 * When length == 1, we will highlight only one cell
				 */
				setInvalidCell(first(selections));
				return;
			}

			setInvalidRange(target.selections);
		} else if (target?.type === 'cell') {
			setInvalidCell(target.address);
		}

		setValidationMessage(message);
	}, [
		validationState?.status,
		validationState,
		dest,
		isolated,
		setInvalidCell,
		setInvalidRange,
		setValidationMessage
	]);

	useEffect(() => {
		if (
			previousValidationState?.status !== ValidationStatus.ClientPending ||
			validationState?.status !== ValidationStatus.Failure
		) {
			return;
		}
		const invalidDests = Object.keys(validationState.validations);
		if (invalidDests.length === 0) {
			return;
		}
		invalidDests.forEach((dest) => {
			const validation = validationState.validations[dest];
			const { message, target } = validation;
			if (target?.type === 'range') {
				spreadsheetProps.spiValidationErrorCallback?.(
					target.selections.join(', '),
					(target as SpreadsheetValidationTarget).rule,
					message,
					target.selections.join(', ')
				);
			} else if (target?.type === 'cell') {
				spreadsheetProps.spiValidationErrorCallback?.(
					target.address,
					(target as SpreadsheetValidationTarget).rule,
					message,
					String(target.value)
				);
			}
		});
	}, [validationState, previousValidationState, spreadsheetProps]);
}
