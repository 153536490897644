import React, { createContext, useContext, useRef } from 'react';

import isEqual from 'lodash-es/isEqual';
import { useStore } from 'zustand';

import {
	SpreadsheetActions,
	SpreadsheetState,
	SpreadsheetStore,
	createSpreadsheetStore
} from './store';

const SpreadsheetStoreContext = createContext<SpreadsheetStore | null>(null);

export const SpreadsheetStoreProvider: React.FC<SpreadsheetState> = ({ children, ...props }) => {
	const storeRef = useRef<SpreadsheetStore>();
	if (!storeRef.current) {
		storeRef.current = createSpreadsheetStore(props);
	}

	/**
	 * We need this assign because in this case store is already initialized
	 * New changes come from readOnly prop trigger which made a new store
	 * Therefore we need to enable/disable readOnly mode for SPI
	 */
	if (!isEqual(storeRef.current?.getState()?.editableRanges, props.editableRanges)) {
		storeRef.current?.setState({ editableRanges: props.editableRanges });
	}

	return (
		<SpreadsheetStoreContext.Provider value={storeRef.current}>
			{children}
		</SpreadsheetStoreContext.Provider>
	);
};

export const useSpreadsheetStore = (): SpreadsheetStore => {
	const store = useContext(SpreadsheetStoreContext);
	if (!store) throw new Error('Missing SpreadsheetStoreContext.Provider in the tree');

	return store;
};

export const useSpreadsheetSelector = <T extends unknown>(
	selector: (state: SpreadsheetState & SpreadsheetActions) => T,
	equalityFn?: (left: T, right: T) => boolean
): T => {
	const store = useSpreadsheetStore();
	return useStore(store, selector, equalityFn);
};
