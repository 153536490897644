import React from 'react';

import ArtifactIcon from './ArtifactIcon';
import { styles, transcriptLinkStyles } from './styles';

export const artifactTypes = [
	'article',
	'video',
	'audio',
	'download',
	'pdfdownload',
	'docxdownload',
	'xlsxdownload'
];

interface Props {
	type: string;
	href: string;
	transcriptHref?: string;
	credits?: string;
	onArtifactClick?: React.MouseEventHandler<HTMLAnchorElement>;
	onTranscriptClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const ArtifactLink: React.FC<Props> = (props) => {
	const { href, transcriptHref, credits, onArtifactClick, onTranscriptClick } = props;

	return (
		<>
			<div className="readable" css={styles}>
				<div className="webtext-artifact-icon">
					<ArtifactIcon type={props.type} />
				</div>
				<div className="webtext-artifact-body">
					<div>
						<a
							className="webtext-artifact-link-title"
							href={href}
							onClick={onArtifactClick}
							target="_blank"
							rel="noopener noreferrer"
							dangerouslySetInnerHTML={{ __html: props.children as string }}
						/>
					</div>
					{credits && (
						<span
							className="webtext-artifact-link-credits"
							dangerouslySetInnerHTML={{ __html: credits }}
						/>
					)}
				</div>
			</div>
			<div css={transcriptLinkStyles}>
				{transcriptHref && (
					<a
						className="webtext-artifact-transcript"
						href={transcriptHref}
						target="_blank"
						onClick={onTranscriptClick}
						rel="noreferrer">
						Read Text Version
					</a>
				)}
			</div>
		</>
	);
};

export default ArtifactLink;
