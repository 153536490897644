import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';

import { Figure } from '@soomo/lib/components/pageElements';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';
import themes from '@soomo/lib/styles/themes';

import ButtonSelect from 'Components/ButtonSelect';

import { updateCurrentViewParams } from '../../actions/uiActions';
import ChaptersGrid from './GridView/ChaptersGrid';
import PageGroupsGrid from './GridView/PageGroupsGrid';
import PagesGrid from './GridView/PagesGrid';
import GradebookPointsGrid from './GridView/GradebookPointsGrid';
import SearchWidget from 'Components/SearchWidget';
import GridLegend from './GridView/GridLegend';
import styles from './GridView.scss';

const COLUMN_SCOPE_TYPE_OPTIONS = [
	['chapter', 'Chapter'],
	['page', 'Page']
];

const ASPECT_OPTIONS = [
	['progress', 'Progress'],
	['score', 'Score'],
	['timeSpent', 'Time']
];

class GridView extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			rowStartIndex: 0,
			rowStopIndex: 0
		};
	}

	onSectionRendered = ({ rowStartIndex, rowStopIndex }) => {
		this.setState({ rowStartIndex, rowStopIndex });
	};

	onColumnScopeTypeSelect = (gridViewColumnScopeType) => {
		this.props.updateCurrentViewParams({ gridViewColumnScopeType });
	};

	onAspectSelect = (gridViewAspect) => {
		this.props.updateCurrentViewParams({ gridViewAspect });
	};

	updateSearchQuery = (searchQuery) => {
		this.props.updateCurrentViewParams({ searchQuery });
	};

	render() {
		const {
			userIds,
			searchQuery,
			columnScopeType,
			columnScopeTypeSelectable,
			hasPageGroups,
			aspect,
			highlightTiming
		} = this.props;
		const { rowStartIndex, rowStopIndex } = this.state;

		const noUsers = !userIds.length;

		let Grid = ChaptersGrid;
		if (aspect === 'gradebookPoints') {
			Grid = GradebookPointsGrid;
		} else if (columnScopeType === 'chapter' && hasPageGroups) {
			Grid = PageGroupsGrid;
		} else if (columnScopeType === 'page' || !columnScopeTypeSelectable) {
			Grid = PagesGrid;
		}

		const aspectIsSelectable = !!ASPECT_OPTIONS.find(([key]) => key === aspect);

		return (
			<div className={cn(styles.GridContainer, { [styles.GridContainerScroll]: noUsers })}>
				<div className={styles.Header}>
					<SearchWidget
						placeholder="Search Students"
						value={searchQuery}
						onChange={this.updateSearchQuery}
					/>
					{columnScopeTypeSelectable && aspect !== 'gradebookPoints' && (
						<ButtonSelect
							options={COLUMN_SCOPE_TYPE_OPTIONS}
							value={columnScopeType}
							onSelect={this.onColumnScopeTypeSelect}
						/>
					)}
					{aspectIsSelectable && (
						<ButtonSelect options={ASPECT_OPTIONS} value={aspect} onSelect={this.onAspectSelect} />
					)}
				</div>
				<div className={styles.TableContainer}>
					<AutoSizer>
						{({ width, height }) => (
							<Grid width={width} height={height} onSectionRendered={this.onSectionRendered} />
						)}
					</AutoSizer>
					{noUsers && (
						<div className={styles.MessageContainer}>
							<div className={styles.Message}>
								<h1>Welcome to Analytics!</h1>
								<p className={styles.MessageHelpText}>
									There are no students enrolled in this course at this time. Once students arrive,
									you’ll be able to use this tool to track their progress. To learn more about how
									Analytics works, check out our{' '}
									<a href="https://instructorhelp.soomolearning.com/en/articles/6680768-how-can-i-use-analytics-to-monitor-students-progress">
										Help Center article
									</a>{' '}
									or watch the video below.
								</p>
								<ThemeProvider theme={themes.universal_velvet}>
									<Figure
										figureOptions={{
											figure_type: 'video',
											figure_subtype: 'vimeo',
											family_id: 'none',
											disableEventReporting: true,
											payload: 'https://vimeo.com/1019579312/9509fd8b83',
											transcript_url: 'https://soomo.co/analyticstranscript'
										}}
									/>
								</ThemeProvider>
							</div>
						</div>
					)}
				</div>
				<GridLegend
					userIds={userIds}
					startIndex={rowStartIndex}
					stopIndex={rowStopIndex}
					columnScopeType={columnScopeType}
					aspect={aspect}
					highlightTiming={highlightTiming}
				/>
			</div>
		);
	}
}

GridView.propTypes = {
	userIds: PropTypes.array.isRequired,
	searchQuery: PropTypes.string.isRequired,
	columnScopeType: PropTypes.string.isRequired,
	columnScopeTypeSelectable: PropTypes.bool.isRequired,
	aspect: PropTypes.string.isRequired,
	hasPageGroups: PropTypes.bool,
	highlightTiming: PropTypes.bool,
	updateCurrentViewParams: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	userIds: state.entities.studentIds,
	searchQuery: state.ui.currentViewParams.searchQuery,
	columnScopeType: state.ui.currentViewParams.gridViewColumnScopeType,
	columnScopeTypeSelectable: state.ui.columnScopeTypeSelectable,
	hasPageGroups: Object.keys(state.entities.page_groups).length > 0,
	aspect: state.ui.currentViewParams.gridViewAspect,
	highlightTiming: state.ui.highlightTiming
});

const mapDispatchToProps = (dispatch) => ({
	updateCurrentViewParams: (params) => {
		dispatch(updateCurrentViewParams(params));
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridView));
