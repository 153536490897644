import React, { MutableRefObject, useEffect, useRef } from 'react';

function useOuterClick<T extends HTMLElement = HTMLDivElement>(
	callback,
	exceptionIds?: string[],
	elementRef?: React.MutableRefObject<T>
): MutableRefObject<T> {
	const callbackRef = useRef(); // initialize mutable ref, which stores callback
	const innerRef = elementRef || useRef(); // returned to client, who marks "border" element

	// update cb on each render, so second useEffect has access to current value
	useEffect(() => {
		callbackRef.current = callback;
	});

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('click', handleClick);
		function handleClick(e: MouseEvent) {
			if (
				innerRef.current &&
				callbackRef.current &&
				!(innerRef.current as HTMLElement).contains(e.target as HTMLElement) &&
				!exceptionIds?.some((id) => id === (e.target as HTMLElement).id)
			)
				(callbackRef as any).current(e);
		}
	}, []); // no dependencies -> stable click listener

	return innerRef; // convenience for client (doesn't need to init ref himself)
}

export default useOuterClick;
