import { useEffect, useState } from 'react';

export function useHover<T extends HTMLElement>(element: T): boolean {
	const [hovered, setHovered] = useState<boolean>(false);

	useEffect(() => {
		if (!element) return;

		const handleMouseOver = (): void => setHovered(true);
		const handleMouseOut = (): void => setHovered(false);

		element.addEventListener('mouseover', handleMouseOver);
		element.addEventListener('mouseout', handleMouseOut);

		return () => {
			element.removeEventListener('mouseover', handleMouseOver);
			element.removeEventListener('mouseout', handleMouseOut);
		};
	}, [element]);

	return hovered;
}
