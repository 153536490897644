import cloneDeep from 'lodash-es/cloneDeep';

import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

theme.colors.blue = '#1f83a8';
theme.colors.primary = '#dd5a31';
theme.colors.link = (theme) => theme.colors.primary;

theme.webtextPage.title.color = (theme) => theme.colors.primary;

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}1A`;

export default theme;
