export const interventions = [1, 2, 3] as const;
export type Intervention = (typeof interventions)[number];

/** How quickly a student must Try Again and then save a new answer to be shown a Speedbump intervention. */
export const SPEEDBUMP_INTERVENTION_THRESHOLD_IN_MILLISECONDS = 2000;

/** How many times a given intervention can be shown per day. Each intervention is counted separately. */
export const MAX_PRESENTATIONS_PER_DAY = 2;
export const MAX_PRESENTATIONS_PER_CHAPTER = 1;
export const MAX_PRESENTATIONS_PER_COURSE = 3;
