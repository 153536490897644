import { Citations } from '../../FillIn/types/ICitations';

const fromCitationToText = (citationData: Citations, turabianCounter?: () => string) => {
	const handlePiece = (piece: Element) => {
		const getValue = (child: Element) => {
			if (child.classList.value.includes('turabian') && turabianCounter) {
				return turabianCounter();
			}

			return child.getAttribute('data-after');
		};

		Array.from(piece.children).forEach((child) => {
			if (child.nodeName === 'MARK') {
				child.innerHTML = getValue(child);
			} else if (child.nodeName === 'P' || child.nodeName === 'STRONG' || child.nodeName === 'EM') {
				handlePiece(child);
			}
		});
	};

	const { text } = citationData;
	const html = document.createElement('div');
	html.innerHTML = text;
	handlePiece(html);
	return html.innerHTML;
};

export const prepareOutput = (
	value: string | Citations,
	turabianCounter?: () => string
): string => {
	if (!value) return '';
	return typeof value === 'string' ? value : fromCitationToText(value, turabianCounter);
};
