import { css } from '@emotion/react';

import { breakpoints, getThemeItem, mixins, Theme, ThemeName } from '~/styles/themes';

export const styles = (theme: Theme) => {
	const { modal } = theme;
	return css`
		font-family: ${getThemeItem(modal.fontFamily, theme)};
		font-size: ${getThemeItem(modal.fontSize, theme)};
		margin: 10vh auto;
		width: 90%;
		max-width: 600px;
		padding: 1em;
		color: ${getThemeItem(modal.colors.text, theme)};
		background-color: ${getThemeItem(modal.colors.background, theme)};
		border: none;
		border-radius: 0;

		::backdrop {
			background-color: rgba(0, 0, 0, 0.75);
		}

		&[data-animated='true'] {
			@media (prefers-reduced-motion: no-preference) {
				animation-fill-mode: forwards;
				animation: appear 0.4s cubic-bezier(0.33, 1, 0.68, 1);
			}
		}

		@keyframes appear {
			from {
				margin-top: 8vh;
				opacity: 0;
			}
			to {
				margin-top: 10vh;
				opacity: 1;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			width: 80%;
		}

		.close-button {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			margin: 0;
			width: 2.5em;
			height: 2.5em;
			background: transparent;
			border: 1px solid transparent;
			color: white;

			&:focus {
				${mixins.webtextAccessibleFocused(theme)}
			}
		}

		.modal-title {
			font-weight: lighter;
			font-size: 1.9em;
			line-height: 1.1;
			color: white;
			margin: 0;
			margin-bottom: 0.25em;
			text-transform: none;

			strong {
				color: ${theme.colors['pure-white']};
			}

			@media (max-width: ${breakpoints.small}) {
				font-size: 1.5em;
			}
		}

		.libs-modal-content {
			margin-bottom: 1em;
			padding: 0.5em 1em;
			color: ${getThemeItem(modal.colors.secondaryText, theme)};
		}

		.modal-actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0.3em 0.7em 0.5em 0.4em;
		}

		.offline-message-container {
			padding: 30px;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			background-color: #ece9e9;
			border-radius: 8px;
			color: black;
			width: 650px;
			max-width: 650px;
			padding: 24px 32px;

			p {
				color: black;
			}

			.libs-modal-content {
				padding: 0;
				margin: 0;
				color: black;
			}

			.dialog-desc {
				margin-top: 18px;
				font-size: 18px;
			}

			.modal-title {
				margin: 0;
				font-family: ${getThemeItem(theme.fonts.app, theme)};
				font-weight: 700;
				color: black;
				font-size: 32px;
				line-height: 34px;
			}

			.card-variant.posted-answers-container {
				background-color: #fbf8f8 !important;

				.body {
					font-size: 16px;
					line-height: 23px;
				}

				.body,
				.signature,
				.author {
					color: black;
					font-family: ${getThemeItem(theme.fonts.app, theme)};
				}

				.author {
					font-size: 14px;
				}

				border-radius: 8px;
			}

			.modal-actions {
				margin-top: 18px;
				padding: 0;

				button {
					font-size: 18px;
				}

				@media (min-width: ${breakpoints.small}) {
					button:last-child {
						min-width: 113px;
					}
				}

				@media (max-width: ${breakpoints.small}) {
					margin-top: 24px;
					flex-direction: column-reverse;

					button:first-child {
						width: initial;
						min-width: 60px;
					}
				}
			}
		`}
	`;
};

export const modalContentStyles = (theme: Theme) => {
	const { modal } = theme;
	return css`
		padding: 0em 1em;
		font-family: ${getThemeItem(modal.fontFamily, theme)};
		font-size: ${getThemeItem(modal.fontSize, theme)};
		line-height: 1.5em;
		margin-bottom: 2em;

		p {
			font-size: ${getThemeItem(modal.fontSize, theme)};
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			display: flex;
			margin: 18px 0 0;
			padding: 0;
			flex-direction: column;
			row-gap: 18px;
		`}
	`;
};
