import {
	type ChangeEventHandler,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
	useContext
} from 'react';
import { BsArrowUpCircleFill, BsXCircle, BsCopy, BsFillTrash3Fill } from 'react-icons/bs';
import { ImSpinner8 } from 'react-icons/im';

import { css } from '@emotion/react';
import { TextareaAutosize } from '@mui/base';
import Tippy from '@tippyjs/react';

import { ShadowRootContext } from '~/components/ShadowDOMRoot';
import { usePrevious } from '~/hooks';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { mixins } from '~/styles/themes';
import { type FamilyId } from '~/types/WebtextManifest';

import { type AiChatboxConfig } from './AiChatbox';
import {
	type Assistant,
	HostedGPTContext,
	type ChatboxMessage,
	ErrorMessages
} from './HostedGPTContext';

export const DEFAULT_AI_CHATBOX_TITLE = 'AI Chatbox';
const SCROLL_UP_THRESHOLD_IN_PIXELS = 5; // accounting for things like pixel rounding
const DISCLAIMER_MESSAGE =
	'AI can make mistakes—always double-check information. This chat will not be used to train LLMs.';

export interface ChatboxProps
	extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id' | 'aria-label' | 'role'> {
	familyId: FamilyId;
	title: string;
	config: AiChatboxConfig;
}

const Chatbox: React.VFC<ChatboxProps> = ({ familyId, title, config, ...rest }) => {
	const startFeatureEnabled = config?.start != null;

	const {
		assistantId,
		availableAssistants,
		messages,
		onChangeAssistant,
		onClear,
		onSend,
		onStop,
		status,
		errorMessage
	} = useContext(HostedGPTContext);
	const { shadowRoot } = useContext(ShadowRootContext);
	const [message, setMessage] = useState('');
	const previousStatus = usePrevious(status);
	const hasScrolledUp = useRef(false);
	const listRef = useRef<HTMLOListElement>(null);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const assistant = useMemo(
		() => (assistantId ? availableAssistants.find((a) => a.id === assistantId) : null),
		[assistantId, availableAssistants]
	);
	const buttonWrapperRef = useRef<HTMLSpanElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	useUnsavedChangesWarning(message.length > 0);

	const handleScroll = useCallback(() => {
		const atBottom =
			listRef.current.scrollHeight - listRef.current.scrollTop - listRef.current.clientHeight <=
			SCROLL_UP_THRESHOLD_IN_PIXELS;
		hasScrolledUp.current = !atBottom;
	}, []);

	useLayoutEffect(() => {
		if (
			previousStatus === 'streaming-response' &&
			status === 'ready' &&
			document.activeElement === document.body
		) {
			// focus has fallen off because the user previously focused the Stop button
			// but it's about to become a disabled Send button. so shift focus to the textarea to avoid dropping it entirely
			textareaRef.current.focus();
		}
	}, [previousStatus, status]);

	/**
	 * When `messages` changes, if the chat log is scrolled to the bottom, then maintain the bottom scroll position
	 * when rendering the new message. But if the user has scrolled up manually (e.g. to back-read something), then
	 * leave the scroll position alone and don't force their scroll position to the bottom.
	 */
	useLayoutEffect(() => {
		if (!hasScrolledUp.current) {
			listRef.current.scrollTop = listRef.current.scrollHeight;
		}
	}, [messages]);

	const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
		setMessage(e.target.value);
	}, []);

	const handleAssistantChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
		(e) => {
			const assistantId = parseInt(e.target.value, 10);
			hasScrolledUp.current = false;
			onChangeAssistant(assistantId);
		},
		[onChangeAssistant]
	);

	const handleSubmit = useCallback(
		async (event) => {
			event.preventDefault();

			if (status === 'ready' || (status === 'streaming-response' && message.trim())) {
				buttonWrapperRef.current.focus();
				await onSend(message);
				setMessage('');
				if ((shadowRoot ?? document).activeElement === buttonWrapperRef.current) {
					textareaRef.current.focus();
				}
			} else if (status === 'streaming-response') {
				onStop();
				textareaRef.current.focus();
			}
		},
		[status, message, onSend, shadowRoot, onStop]
	);

	const handleKeyDown = useCallback(
		(e) => {
			e.stopPropagation();
			if (e.key === 'Enter' && !e.shiftKey) {
				e.preventDefault();

				if (message.trim()) {
					handleSubmit(e);
				}
			}
		},
		[handleSubmit, message]
	);

	const handleCopyChat = useCallback(() => {
		navigator.clipboard.write([
			new ClipboardItem({
				'text/html': new Blob(
					messages.map((message) => messageToClipboardHtml(message, assistant)),
					{
						type: 'text/html'
					}
				),
				'text/plain': new Blob(
					[messages.map((message) => messageToClipboardPlaintext(message, assistant)).join('\n\n')],
					{
						type: 'text/plain'
					}
				)
			})
		]);
	}, [assistant, messages]);

	const handleCopyMessage = useCallback(
		(id: ChatboxMessage['id']) => {
			const message = messages.find((m) => m.id === id);
			if (message) {
				navigator.clipboard.write([
					new ClipboardItem({
						'text/html': new Blob([messageToClipboardHtml(message, assistant)], {
							type: 'text/html'
						}),
						'text/plain': new Blob([messageToClipboardPlaintext(message, assistant)], {
							type: 'text/plain'
						})
					})
				]);
			}
		},
		[assistant, messages]
	);

	const isDisabled = status === 'launching' || status === 'error' || status === 'sending-message';
	const textareaPlaceholder = isDisabled ? '' : `Message ${assistant.name}…`;
	const textareaAriaLabel =
		status === 'launching'
			? // lowercase is intentional to work around a strange Edge + Narrator bug;
				//see https://soomo.height.app/T-142481#activityId=a70cb48d-7508-4dea-b837-216cebbcf721
				`message model, once this component loads you can message the model here.`
			: `message ${assistant?.name}…`;
	const buttonAriaLabel = useMemo(() => {
		switch (status) {
			case 'sending-message':
				return 'Sending…';
			case 'streaming-response':
				return 'Stop';
			default:
				return 'Send';
		}
	}, [status]);
	const regionAriaLabel = useMemo(() => {
		const titleWithDefault = title || DEFAULT_AI_CHATBOX_TITLE;
		return errorMessage ? `${titleWithDefault} - ${errorMessage}` : titleWithDefault;
	}, [title, errorMessage]);
	const id = useMemo(() => `ai_chatbox-${familyId}`, [familyId]);

	const onStart = async () => {
		await onSend(config.start.userMessage);
	};

	return (
		<div css={styles.root} id={id} role="region" aria-label={regionAriaLabel} {...rest}>
			<header css={styles.header}>
				<div css={styles.assistantSelectWrapper}>
					<Tippy content="Choose model" placement="bottom">
						<select
							disabled={isDisabled}
							value={assistant?.id}
							onChange={handleAssistantChange}
							css={styles.assistantSelect}
							aria-label="Choose model">
							{availableAssistants.map(({ id, name }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))}
						</select>
					</Tippy>
				</div>
				<div css={styles.buttons}>
					<Tippy content="Copy chat" placement="bottom">
						<button
							disabled={messages.length === 0}
							css={styles.iconButton}
							onClick={handleCopyChat}
							aria-label="Copy chat">
							<BsCopy size={20} aria-hidden />
						</button>
					</Tippy>
					<Tippy content="Clear chat" placement="bottom">
						<button
							aria-label="Clear chat"
							css={styles.iconButton}
							onClick={onClear}
							disabled={isDisabled}>
							<BsFillTrash3Fill size={20} aria-hidden />
						</button>
					</Tippy>
				</div>
			</header>
			<h1 css={styles.visuallyHidden}>Chat History / LLM Output. {DISCLAIMER_MESSAGE}</h1>
			<ol
				css={styles.list}
				ref={listRef}
				onScroll={handleScroll}
				hidden={
					!assistant || messages.length === 0 || (startFeatureEnabled && messages.length === 1)
				}>
				{assistant &&
					messages.length > 0 &&
					messages.map(({ id, role, htmlContent, stopped }, i) =>
						startFeatureEnabled && i === 0 ? null : (
							<li key={i} css={styles.listItem} data-stopped={stopped}>
								<h2 css={styles.sender}>{roleToLabel(role, assistant)}</h2>
								<div
									css={styles.messageContent}
									dangerouslySetInnerHTML={{ __html: htmlContent }}
								/>
								<Tippy content="Copy message" placement="bottom">
									<button
										css={[styles.iconButton, styles.copyMessageButton]}
										onClick={() => handleCopyMessage(id)}
										aria-label="Copy message">
										<BsCopy size={16} aria-hidden />
									</button>
								</Tippy>
							</li>
						)
					)}
			</ol>
			{messages.length === 0 && (
				<div css={styles.welcome}>
					{status === 'launching' && 'Loading, please wait...'}
					{status === 'ready' &&
						(startFeatureEnabled ? (
							<button css={styles.startButton} onClick={onStart}>
								{config.start.buttonText}
							</button>
						) : (
							'How can I help you today?'
						))}
				</div>
			)}
			<form css={styles.form} onSubmit={handleSubmit}>
				<TextareaAutosize
					ref={textareaRef}
					css={styles.autosizeTextarea}
					disabled={isDisabled || (startFeatureEnabled && messages.length === 0)}
					value={message}
					onChange={handleTextareaChange}
					onKeyDown={handleKeyDown}
					placeholder={startFeatureEnabled && messages.length === 0 ? '' : textareaPlaceholder}
					aria-label={textareaAriaLabel}
					maxRows={12}
				/>
				<Tippy content={buttonAriaLabel} placement="bottom">
					<span tabIndex={-1} ref={buttonWrapperRef}>
						<button
							type="submit"
							ref={buttonRef}
							aria-label={buttonAriaLabel}
							disabled={
								isDisabled ||
								(!message.trim() && status !== 'streaming-response') ||
								(startFeatureEnabled && messages.length === 0)
							}
							css={[styles.iconButton, styles.sendButton]}>
							{status === 'sending-message' && (
								<ImSpinner8 size={24} css={styles.spinner} aria-hidden />
							)}
							{status === 'streaming-response' && !message.trim() && (
								<BsXCircle size={24} aria-hidden />
							)}
							{status === 'ready' && <BsArrowUpCircleFill size={24} aria-hidden />}
						</button>
					</span>
				</Tippy>
			</form>
			<div css={styles.belowMessagesContainer}>
				<span css={styles.errorMessage} role="alert">
					{/* if launching the chatbox fails, that would likely occur across all chatboxes on the page
					    and the aria-live message "Couldn't connect to AI service" would be repeated that many times.
					    we don't want to spam the user with that many alerts at once, and some would be clobbered anyway.
					    so, in this case, just use the default AI chatbox title instead of using element-specific ones.
					    (see T-144142 for more.) */}
					{errorMessage === ErrorMessages.LAUNCH_FAILED && (
						<span css={styles.visuallyHidden}>{DEFAULT_AI_CHATBOX_TITLE} &mdash;&nbsp;</span>
					)}
					{errorMessage}
				</span>
				<div css={styles.disclaimer}>{DISCLAIMER_MESSAGE}</div>
			</div>
		</div>
	);
};

export default Chatbox;

const roleToLabel = (role: ChatboxMessage['role'], assistant: Assistant) =>
	role === 'user' ? 'You' : assistant.name;

const messageToClipboardHtml = (message: ChatboxMessage, assistant: Assistant) => {
	const doc = new DOMParser().parseFromString(message.htmlContent, 'text/html');
	doc.querySelectorAll('.hidden, .clipboard-exclude').forEach((el) => el.remove());
	// rich-text writing templates don't support heading elements, so if we paste them in directly
	// they appear in the writing template without any line breaks.
	// so, replace them with paragraphs to ensure there is whitespace above and below them
	doc.querySelectorAll<HTMLHeadingElement>('h1, h2, h3, h4, h5, h6').forEach((el) => {
		const p = doc.createElement('p');
		p.textContent = el.textContent;
		el.replaceWith(p);
	});
	return `<p><b>${roleToLabel(message.role, assistant)}</b></p>${doc.body.innerHTML}`;
};

const messageToClipboardPlaintext = (message: ChatboxMessage, assistant: Assistant) =>
	`${roleToLabel(message.role, assistant)}\n${message.markdownContent}`;

const styles = {
	root: css`
		display: flex;
		box-sizing: border-box;
		height: 100%;
		flex-direction: column;
		border: 1px solid hsl(0, 0%, 45%);
		color: hsl(0, 0%, 15%);
		font-family: system-ui, sans-serif;

		@keyframes breathe {
			0%,
			100% {
				opacity: 1;
				transform: scale(1);
			}
			50% {
				opacity: 0.5;
				transform: scale(0.9);
			}
		}
	`,
	header: css`
		display: flex;
		margin: 0 0 1rem 0;
		padding: 0.25rem 0.75rem;
		border-bottom: 1px solid hsl(0, 0%, 45%);
		align-items: center;
		justify-content: space-between;
	`,
	assistantSelectWrapper: css`
		position: relative;
		border-radius: 0.5rem;
		background-color: hsl(0, 0%, 95%);

		:hover {
			background-color: hsl(0, 0%, 85%);
		}

		&::after {
			--size: 6px;
			content: '';
			position: absolute;
			width: var(--size);
			height: var(--size);
			border-style: solid;
			border-color: hsl(0, 0%, 45%);
			border-width: 0 2px 2px 0;
			border-radius: 1px;
			transform: rotate(45deg);
			transform-origin: right;
			right: 10px;
			top: calc(50% - var(--size) / 2);
			pointer-events: none;
		}
	`,
	assistantSelect: css`
		appearance: none;
		padding: 0.25rem 2rem 0.25rem 0.75rem;
		margin: 0;
		background: transparent;
		border: none;
		font-size: 1.15rem;
		user-select: none;
		color: black;

		:not(:disabled) {
			cursor: pointer;
		}
	`,
	buttons: css`
		display: flex;
		column-gap: 0.5rem;
	`,
	list: css`
		height: 100%;
		margin: 0 0 0 1.5rem;
		padding: 0 0.5rem 0 0;
		list-style: none;
		scrollbar-gutter: stable;
		overflow-y: auto;
		overscroll-behavior: contain;
		flex-grow: 1;
	`,
	listItem: css`
		display: flex;
		flex-direction: column;
		row-gap: 0.25rem;

		& + & {
			margin-top: 1rem;
		}
	`,
	sender: css`
		margin: 0;
		font-size: 1.1rem;
		font-weight: bold;
	`,
	messageContent: css`
		color: hsl(0, 0%, 30%);
		line-height: 1.4;

		p:first-child {
			margin-top: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}

		.clipboard-exclude {
			display: none;
		}

		// the following are Tailwind classes that HostedGPT sends back along with the model response
		.hidden {
			display: none !important;
		}

		.animate-breathe {
			animation: breathe 2s ease-in-out infinite;
		}

		.w-3 {
			width: 0.75rem; /* 3 / 4 * 16px (standard base unit) */
		}

		.h-3 {
			height: 0.75rem; /* 3 / 4 * 16px (standard base unit) */
		}

		.rounded-full {
			border-radius: 9999px;
		}

		.bg-black {
			background-color: #000000; /* Black */
		}

		.dark .bg-white {
			background-color: #ffffff; /* White */
		}

		.inline-block {
			display: inline-block;
		}

		.ml-1 {
			margin-left: 0.25rem; /* 1 / 4 * 16px (standard base unit) */
		}

		/* if we've marked the message as stopped, then force the in-progress icon to be hidden,
		 * and force the stopped indicators to be visible */
		[data-stopped='true'] & {
			.animate-breathe {
				display: none;
			}

			span.hidden {
				display: inline-block !important;
			}
		}
	`,
	form: css`
		display: grid;
		margin: 1rem 1rem 0;
		padding: 0 6px 0 0;
		border-radius: 1rem;
		border: 1px solid hsl(0, 0%, 45%);
		grid-template-columns: 1fr auto;
		align-items: center;
		column-gap: 0.5rem;

		&:focus-within {
			border: 1px solid blue;
		}
	`,
	autosizeTextarea: css`
		padding: 0.75rem 1rem;
		border: none;
		border-radius: 1rem;
		font: inherit;
		resize: none;

		&:focus {
			outline: none;
		}

		&:disabled {
			background: inherit;
		}
	`,
	startButton: css`
		background: hsl(0, 0%, 95%);
		border: 1px solid rgba(33, 33, 33, 0.1);
		border-radius: 0.5rem;
		padding: 10px 20px;
		font-size: 1.2rem;
		font-weight: 500;
		color: black;

		:hover {
			background: hsl(0, 0%, 85%);
		}
	`,
	iconButton: css`
		width: 32px;
		height: 32px;
		margin: 0;
		padding: 0;
		border: none;
		border-radius: 0.5rem;
		background: none;
		line-height: 0;

		:disabled {
			color: hsl(0, 0%, 70%);
		}

		:not(:disabled) {
			color: black;
			cursor: pointer;
		}

		:not(:disabled):hover {
			background-color: rgba(0, 0, 0, 0.1);
		}
	`,
	copyMessageButton: css`
		width: 28px;
		height: 28px;
		margin-left: 1px;
	`,
	disclaimer: css`
		color: hsl(0, 0%, 45%);
	`,
	welcome: css`
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 1rem 1rem;
		font-size: 1.5rem;
		font-weight: 500;
		flex-grow: 1;
	`,
	belowMessagesContainer: css`
		display: flex;
		padding: 0.5rem 1rem 0.75rem;
		flex-direction: column;
		row-gap: 0.25rem;
		font-size: 0.9rem;
		text-align: center;
	`,
	sendButton: css`
		width: 34px;
		height: 34px;
		border-radius: 50%;
	`,
	errorMessage: css`
		color: red;
	`,
	spinner: css`
		@keyframes rotate {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		animation: rotate 1s linear infinite;
		transform-origin: center;
	`,
	visuallyHidden: mixins.webtextHiddenAccessible
};
