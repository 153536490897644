import React from 'react';

import { PieDataType } from '../../types';

interface Props {
	data: PieDataType;
}

const PieDataTable: React.FC<Props> = ({ data }) => {
	const total = data.reduce((acc, { value }) => acc + value, 0);

	return (
		<>
			<thead>
				<tr>
					<th scope="col">Category</th>
					<th scope="col">Value</th>
					<th scope="col">Percentage</th>
				</tr>
			</thead>
			<tbody>
				{data.map(({ label, percent, formattedValue }, idx) => (
					<tr key={idx}>
						<th scope="row">{label}</th>
						<td>{total === 0 ? ' ' : formattedValue}</td>
						<td>{total === 0 ? ' ' : `${percent}%`}</td>
					</tr>
				))}
			</tbody>
		</>
	);
};

export default PieDataTable;
