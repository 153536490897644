import { css, SerializedStyles } from '@emotion/react';

import {
	OutlineHierarchyItemType,
	OutlineVariant,
	TemplateLabelType
} from '~/components/Outline/types';
import { breakpoints } from '~/styles/themes';

export const instanceView = (options: {
	labelType: TemplateLabelType;
	variant: OutlineVariant;
	sectionType: OutlineHierarchyItemType;
	disableOutputLabel: boolean;
}): SerializedStyles => {
	const { labelType, variant, sectionType, disableOutputLabel } = options;

	const labelBlockLayout = css`
		grid-template-areas:
			'label action-menu'
			'validation-message validation-message'
			'text-field text-field'
			'constraint-message constraint-message';
		grid-template-columns: auto min-content;
	`;

	const labelInlineLayout = css`
		grid-template-areas:
			'. action-menu'
			'validation-message validation-message'
			'label text-field'
			'. constraint-message';
		grid-template-columns: auto 1fr;
		align-items: ${variant === OutlineVariant.VIEW ? 'baseline' : 'center'};
	`;

	return css`
		display: grid;
		font-size: 1rem; // Prevents override of the font-size by the Builder's "ViewMode"
		line-height: 1.6;

		* {
			box-sizing: border-box;
		}

		${disableOutputLabel
			? labelInlineLayout
			: css`
					${labelType === 'block' ? labelBlockLayout : labelInlineLayout};
					@media (max-width: ${breakpoints.small}) {
						${labelBlockLayout}; // On small screens all instances are displayed as block, with indentation margin
					}
					column-gap: 12px;
			  `}

		${variant === OutlineVariant.EDIT &&
		css`
			row-gap: 2px;
		`}
	

		${sectionType === OutlineHierarchyItemType.Children
			? css`
					margin-top: ${variant === OutlineVariant.REVISE ? '8px' : '16px'};
			  `
			: css`
					margin-top: 5px;
			  `}
	`;
};

export const instanceViewDecoration = (options: {
	depth: number;
	variant: OutlineVariant;
	sectionType: OutlineHierarchyItemType;
	childrenNumber: number;
}): SerializedStyles => {
	const { depth, variant, sectionType, childrenNumber } = options;
	if (variant === OutlineVariant.REVISE || sectionType === OutlineHierarchyItemType.Toggle) return;

	if (depth === 1 && childrenNumber > 0) {
		return css`
			padding-bottom: 5px;
			border-bottom: 1px solid #dddddd;
		`;
	}
};
