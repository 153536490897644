import React, { Component } from 'react';
import { type default as ReactPlayer, type ReactPlayerProps } from 'react-player';
import { getSDK } from 'react-player/utils';

import { VzaarStyles } from './VzaarStyles';
import { type Player } from '../types';
import { type IvzPlayer } from './types';

const VZAAR_REGEX = /(\/\/view.vzaar.com\/(.+)\/player(\?[^&?]*?=[^&?]*)*)/;

type VzaarPlayerState = {
	iframeId: string;
	iframeSrc: string;
	currentTime: number;
	currentTimeInterval: number;
	duration: number;
};

export class VzaarPlayer extends Component<ReactPlayerProps, VzaarPlayerState> implements Player {
	static displayName = 'Vzaar';
	static canPlay(url: string) {
		if (VZAAR_REGEX.test(url)) {
			return true;
		}
		return false;
	}

	player: IvzPlayer = null;

	constructor(props: ReactPlayerProps) {
		super(props);
		const videoId = props.url.toString().match(VZAAR_REGEX)[2];

		this.state = {
			iframeId: `vzvd-${videoId}`,
			iframeSrc: `https:${props.url.toString().match(VZAAR_REGEX)[0]}`,
			currentTime: 0,
			currentTimeInterval: null,
			duration: 0
		};
	}

	componentDidMount() {
		this.props.onMount && this.props.onMount(this);
	}

	componentWillUnmount() {
		clearInterval(this.state.currentTimeInterval);
	}

	/**
	 * Vzaar requires the use of an iframe's id
	 */
	load() {
		const { onReady } = this.props;
		const { iframeId } = this.state;

		const SDK_URL = `https://player.vzaar.com/libs/flashtakt/client.js`;
		const SDK_GLOBAL = 'vzPlayer';
		getSDK(SDK_URL, SDK_GLOBAL).then((vzPlayer) => {
			this.player = new vzPlayer(iframeId);
			this.player.ready(() => {
				this.addEventListeners();
				this.player.getTotalTime((duration) => {
					this.setState({
						currentTimeInterval: window.setInterval(() => {
							this.player.getTime((seconds) => this.setState({ currentTime: seconds }));
						}, 1000),
						duration
					});
				});
				onReady(this as unknown as ReactPlayer);
			});
		});
	}

	addEventListeners() {
		const { onPlay, onPause, onEnded, onStart } = this.props;

		this.player.addEventListener('playState', (data) => {
			switch (data) {
				case 'mediaStarted': {
					onStart();
					break;
				}
				case 'mediaPlaying': {
					onPlay();
					break;
				}
				case 'mediaPaused': {
					onPause();
					break;
				}
				case 'mediaEnded': {
					onEnded();
					break;
				}
			}
		});
	}

	seekTo(amount: number) {
		this.player.seekTo(amount);
	}

	getCurrentTime() {
		return this.state.currentTime;
	}

	getDuration() {
		return this.state.duration;
	}

	/**
	 * Vzaar's player API does not support this feature
	 */
	getSecondsLoaded() {
		return 0;
	}

	stop() {
		this.player.pause();
	}

	render() {
		const { iframeId, iframeSrc } = this.state;
		return (
			<div css={VzaarStyles}>
				<iframe
					id={iframeId}
					src={iframeSrc}
					frameBorder={0}
					allowFullScreen={true}
					allow="fullscreen"></iframe>
			</div>
		);
	}
}
