import React from 'react';

import { PopupQuiz, PopupQuizResults } from './types';

interface Props {
	popupQuiz: PopupQuiz;
	results: PopupQuizResults;
}

const TotalScoreInterpretation: React.FC<Props> = (props) => {
	const { popupQuiz, results } = props;

	return (
		<>
			{results.topLineResult.length > 0 && (
				<div
					className={`top-line-${popupQuiz.mode}`}
					style={{ outline: 'none' }}
					tabIndex={-1}
					dangerouslySetInnerHTML={{ __html: results.topLineResult }}
				/>
			)}
			{results.explanation.length > 0 && (
				<div
					className="analysis"
					style={{ outline: 'none' }}
					tabIndex={results.topLineResult ? null : -1}
					dangerouslySetInnerHTML={{ __html: results.explanation }}
				/>
			)}
		</>
	);
};

export default TotalScoreInterpretation;
