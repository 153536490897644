import React, { FC, useEffect, useMemo, useRef } from 'react';

import { ClassNames } from '@emotion/react';
import { scaleOrdinal } from 'd3-scale';
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import cloneDeep from 'lodash-es/cloneDeep';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import { useResizeObserver } from 'usehooks-ts';

import { createTooltipsSingleton } from '~/components/pageElements/PollQuestion/utils';
import { colors } from '~/styles/themes';

import { useChartSizes } from '../../PollQuestion/RefreshedPoll/ResultsPie';
import {
	assignSector,
	positionLabelsBySector,
	removeLabelsOverlap
} from '../../PollQuestion/RefreshedPoll/ResultsPie/utils';
import ChartDescription from '../ChartDescription';
import { getFormat, valueOf } from '../chartHelpers';
import { refreshedChartStyles } from './styles';

import type { Props as ChartFigureProps } from './Chart';
import type { ChartElement } from '~/types/WebtextManifest';

export type PieChart = Pick<ChartElement, 'data' | 'show_labels' | 'colors' | 'tippyProps'>;

export interface Props extends Omit<ChartFigureProps, 'chart'> {
	chart: PieChart;
	isHighContrast?: boolean;
}

/**
 * Global dimensions
 */
const minChartWidth = 300;

const PieChart: FC<Props> = (props) => {
	const { chart, isHighContrast } = props;

	const chartData = useMemo(() => cloneDeep(chart.data), [chart.data]);

	/**
	 * Handle resize events
	 */
	const chartContainerRef = useRef<HTMLDivElement | null>(null);
	const { width = 0 } = useResizeObserver({
		ref: chartContainerRef,
		box: 'border-box'
	});

	const { height, innerRadius, outerRadius, labelRadius, labelWidth } = useChartSizes(false);

	const seriesLabels = useMemo(() => chartData.shift().slice(1), [chartData]);
	const data = useMemo(
		() =>
			chartData[0]
				.slice(1)
				.map((value, index) => ({ ...valueOf(value), label: seriesLabels[index] }))
				.filter((item) => !isNaN(item.value) && item.value !== 0),
		[chartData, seriesLabels]
	);

	const valueType = data[0].type;

	useEffect(() => {
		const { current: chartContainer } = chartContainerRef;

		/**
		 * Don't try to draw until we can received the size of the container
		 */
		if (!chartContainer || width === 0) return;

		const color = scaleOrdinal().range(chart.colors);

		const tickFormat = getFormat(valueType);

		let labelTooltips;

		const draw = () => {
			/**
			 * Pie chart container
			 */
			const svg: any = select(chartContainer)
				.append('svg')
				.attr('width', width)
				.attr('height', height)
				.attr('aria-hidden', true)
				.append('g')
				.attr('transform', `translate(${width / 2}, ${height / 2})`);

			const pieShape = (pie() as any).sort(null).value((d) => d.value);

			const pieData = pieShape(data);

			const pieArc = arc().innerRadius(innerRadius).outerRadius(outerRadius);
			const outerArc = arc().innerRadius(outerRadius).outerRadius(outerRadius);
			const labelArc = arc().innerRadius(labelRadius).outerRadius(labelRadius);

			/**
			 * Draw slices
			 */
			svg
				.selectAll('allSlices')
				.data(pieData)
				.enter()
				.append('path')
				.attr('d', pieArc)
				.attr('fill', (_, i) => (isHighContrast ? colors.beige : color(i)))
				.attr('stroke', () => (isHighContrast ? colors.brown : null));

			/**
			 * Draw lines
			 */
			const lines = svg
				.selectAll('allLines')
				.data(pieData)
				.enter()
				.append('line')
				.attr('fill', 'none')
				.attr('stroke-width', 1)
				.attr('x1', (d) => outerArc.centroid(d)[0]) // line start position
				.attr('y1', (d) => outerArc.centroid(d)[1])
				.attr('x2', (d) => labelArc.centroid(d)[0]) // line end position
				.attr('y2', (d) => labelArc.centroid(d)[1])
				.attr('class', 'pie-chart-label-line');

			/**
			 * Draw labels
			 */
			const labels = svg
				.selectAll('allLabels')
				.data(pieData)
				.enter()
				.append('foreignObject')
				.attr('x', (d) => {
					const x = labelArc.centroid(d)[0];
					const xOffset = 5 * Math.sign(x);
					return x + xOffset;
				})
				.attr('y', (d) => labelArc.centroid(d)[1])
				.attr('width', labelWidth)
				.attr('height', 1) // Firefox requires a height >0 to be set. The number is irrelevant, because `overflow: visible` is applied
				.attr('class', 'pie-chart-label')
				.call(assignSector)
				.append('xhtml:div')
				.attr('class', 'pie-chart-label-text-container')
				.html(
					(d, _i) =>
						`<div class="pie-chart-label-text">${d.data.label}</div> ${tickFormat(d.data.value)}`
				)
				.attr('aria-hidden', true);

			labelTooltips = drawLabelTooltips(labels, chart.tippyProps);

			svg
				.selectAll('.pie-chart-label')
				.call(positionLabelsBySector)
				.call((labels) => removeLabelsOverlap({ chartGroup: svg, labels, lines }));
		};

		/**
		 * Remove all SVG elements and tooltip instances before redrawing
		 */
		select(chartContainer).selectAll('*').remove();
		labelTooltips?.destroy();

		draw();
	}, [
		chartContainerRef,
		width,
		height,
		innerRadius,
		outerRadius,
		labelRadius,
		labelWidth,
		seriesLabels,
		data,
		valueType,
		isHighContrast,
		chart.tippyProps,
		chart.colors
	]);

	return (
		<ClassNames>
			{({ cx }) => (
				<figure
					css={(theme) => refreshedChartStyles(theme)}
					className={cx('refreshed-chart', 'refreshed-pie-chart')}>
					<div
						className="refreshed-chart-container pie"
						style={{ minWidth: minChartWidth }}
						ref={chartContainerRef}
					/>
					<ChartDescription chart={chart as ChartElement} />
				</figure>
			)}
		</ClassNames>
	);
};

export default PieChart;

function drawLabelTooltips(labels: any, tippyProps?: ChartElement['tippyProps']) {
	const tooltips: Array<TippyInstance> = [];

	labels.each(function () {
		const label = select(this);
		const labelElement = this as HTMLElement;

		const labelText = label.select('.pie-chart-label-text');
		const labelTextElement: any = labelText.node();

		// The `scrollHeight` is higher than `clientHeight` when some text is overflown and hidden
		const isOverflownLabel = labelTextElement.scrollHeight > labelTextElement.clientHeight;

		if (isOverflownLabel) {
			const content = labelTextElement.innerText;
			tooltips.push(tippy(labelElement, { content }));

			label.attr('tabindex', 0);
			label.attr('role', 'button');
			label.attr('aria-label', 'Show longer pie chart label');
			label.attr('aria-hidden', false);
			labelText.classed('label-underline', true);
		}
	});

	const tooltipsSingleton = createTooltipsSingleton(tooltips, tippyProps);
	return {
		tooltips,
		tooltipsSingleton,
		destroy: () => {
			tooltips.forEach((tooltip) => tooltip.destroy());
			tooltipsSingleton.destroy();
		}
	};
}
