import * as React from 'react';

export function Camera(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 3334 3334">
			<g fill="currentColor" stroke="currentColor" strokeWidth={800}>
				<path
					d="M1053.3 523c-11.4 4-19.6 12-25.2 24.4-2 4.4-33.1 76.4-69.1 159.8L893.5 859H481.8c-297.4 0-413.2.3-417 1.1-12.1 2.5-24.3 12.2-29.6 23.6l-2.7 5.8v1894l3.3 6.7c5.7 11.6 14.9 19.2 26.8 22.3 5.4 1.3 168.8 1.5 1607.5 1.3l1601.5-.3 7.5-3.7c9.3-4.6 15.8-11.2 20.2-20.6l3.2-6.7v-1892l-2.8-6c-1.5-3.2-3.9-7.4-5.3-9.3-3.8-5-11.7-10.8-18.1-13.4l-5.8-2.3-410.8-.5-410.9-.5-72.3-158.3c-39.8-87.1-73.6-160.1-75.1-162.4-3.7-5.3-9.7-10.1-17-13.5l-5.9-2.8-610-.2c-591.8-.2-610.2-.1-615.2 1.7zm1244.8 208.3 57.6 126.2-684.5.3c-376.5.1-684.7 0-684.9-.2-.2-.2 23.5-55.6 52.8-123.2 29.2-67.6 53.7-124.4 54.4-126.2l1.4-3.2h572.8l572.8.1 57.6 126.2zM3219 1836.5V2730H116V943h3103v893.5z"
					strokeWidth={80}
				/>
				<path
					d="M2925.2 1047.5c-51.5 9.3-91.4 53.5-95 105.3-1.9 26.8 3.7 50.3 17 72.1 7.3 11.9 25.2 29.7 37.2 37 38.2 23.5 86.3 23.5 124.2 0 12.2-7.5 27-21.9 35-34 7.5-11.3 12.3-21.8 16.1-35.5 2.6-9.3 2.8-11.1 2.7-29.9 0-18.4-.2-20.8-2.6-29.8-3.1-11.3-9.7-25.8-16.3-35.7-16.9-25.4-45.9-44.2-76.3-49.5-10.3-1.8-32-1.8-42 0zM1619 1121.1c-117.8 7.8-233.9 45.6-334 108.7-138.2 87.1-243.4 220-296.1 374-32.9 96.2-45 199.5-35.3 301.2 14.2 150.2 74.9 290.6 175.1 405.3 24.3 27.7 63.4 64.8 93.8 88.8 159.2 126.1 363.3 178.1 566 144.3 191.5-32 365.7-144.7 474.3-307.1 60.8-90.8 99.6-192.4 114.6-299.8 5.3-37.7 6-50.7 6.1-100 0-49.2-.8-61.6-6.1-99.8-15.9-114.3-58.8-221.8-126.2-316.5-43.1-60.6-100.4-118.6-160.7-162.7-110.1-80.5-239.5-127.3-377.3-136.5-17.8-1.2-75.9-1.1-94.2.1zm90.8 84c152.8 10.9 291.4 73.1 400.6 179.9 50.8 49.7 91.1 105.1 123.5 170 35.2 70.6 55.4 141.3 63.8 223 2.6 25 2.5 93.1 0 117.5-14.1 133.6-61.7 247-146.3 348.6-14.2 17-53.1 56.6-69.4 70.6-76.5 65.4-162.1 110.4-256.5 134.8-130.4 33.6-269.3 24.7-393.5-25.3-107.6-43.3-202.3-116.1-271.9-209.2-91.1-121.7-134.8-271.6-124-425 9.3-131.1 60.2-256.4 146.5-360.1 16.3-19.7 58.5-61.8 77.9-78 83.3-69.2 178.3-115 282.2-135.9 25.3-5.1 61.8-9.9 86.3-11.3 14-.8 67.6-.6 80.8.4z"
					strokeWidth={80}
				/>
			</g>
		</svg>
	);
}
