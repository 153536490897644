import React from 'react';

import CompletedVoiceRecording from '~/components/pageElements/VoiceRecording/CompletedVoiceRecording';

import { voiceRecordingMediaElementStyles } from '..';

interface Props {
	recordingUrl: string;
	transcript: string;
}
const renderTranscript = (transcript) => {
	return (
		<>
			<p className="transcript-disclaimer">
				The following transcript was generated automatically to serve as a quick textual reference
				for the original audio recording. It may contain errors or omissions.
			</p>
			<div className="transcript">
				<p>{transcript}</p>
			</div>
		</>
	);
};

const AnswerViewVoiceRecording = (props) => {
	if (props.recordingUrl) {
		return (
			<div css={voiceRecordingMediaElementStyles}>
				<div className={props.containerClass}>
					<CompletedVoiceRecording recordingUrl={props.recordingUrl} />
					{props.transcript && renderTranscript(props.transcript)}
				</div>
			</div>
		);
	} else {
		return <>No saved responses</>;
	}
};

export default AnswerViewVoiceRecording;
