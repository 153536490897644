import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';

import { ClassNames } from '@emotion/react';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import { useResizeObserver } from 'usehooks-ts';

import {
	createTooltipsSingleton,
	getFigureCaption,
	getLongDescription
} from '~/components/pageElements/PollQuestion/utils';
import { breakpoints } from '~/styles/themes';
import { includesMarkup } from '~/utils/parsing';

import { usePollDataState } from '../hooks';
import { refreshedPollResultsStyles } from '../styles';

import type { Props as RefreshedPollResultsProps } from './ResultsTabs';
import type { RefreshPollProps } from '~/components/pageElements/PollQuestion';

type Props = Pick<
	RefreshedPollResultsProps,
	| 'questionFamilyId'
	| 'courseNumber'
	| 'classData'
	| 'choices'
	| 'externalData'
	| 'totalCount'
	| 'dataType'
	| 'dataGroupBy'
	| 'choiceOrdering'
	| 'sourceDatasetIndex'
	| 'colorPalette'
	| 'colorGroupBy'
	| 'chartType'
	| 'description'
	| 'tippyProps'
>;

const yourClassLabel = 'Your Class';

/**
 * Global dimensions
 */
const minChartWidth = 300;
const labelWidth = 80;
const spaceBetweenGroupLabels = 28;
const spaceAfter100Percent = 40;
const margin = {
	top: 40,
	bottom: 20,
	left: labelWidth * 2 + spaceBetweenGroupLabels
};

/**
 * Bar dimensions
 */
const barHeight = 25;
const barMarginBottom = 16;

const RefreshedPollResultsBars = forwardRef<HTMLElement, Props>((props, ref) => {
	const {
		courseNumber = '',
		choices,
		classData,
		externalData,
		totalCount,
		dataType,
		dataGroupBy,
		choiceOrdering,
		sourceDatasetIndex,
		colorGroupBy,
		colorPalette,
		description,
		tippyProps
	} = props;

	const isClassOnly = !externalData;
	const groupByResponse = externalData && dataGroupBy === 'response';

	/**
	 * Handle resize events
	 */
	const chartContainerRef = useRef<HTMLDivElement | null>(null);
	const { width: chartContainerWidth = 0 } = useResizeObserver({
		ref: chartContainerRef,
		box: 'border-box'
	});
	const isSmallView = useMemo(
		() => chartContainerWidth < parseFloat(breakpoints.small),
		[chartContainerWidth]
	);

	/**
	 * Supply the hook with what it needs, this is also where we use `shortened_body`
	 * from the choices
	 */
	const { orderedClassData, orderedShapedData } = usePollDataState({
		shapedDataMode: 'percentage',
		choices,
		classData,
		externalData,
		choiceOrdering,
		sourceDatasetIndex,
		dataType,
		totalCount,
		roundValuePrecision: 2
	});

	// accessing this as a string[] enough to justify a memo for it
	const orderedChoices = useMemo(
		() => orderedClassData.map((d) => d.shortened_body || d.label),
		[orderedClassData]
	);

	const getIsChoiceShortenedBody = useCallback(
		(choiceBody: string | number) =>
			choices.some(({ shortened_body }) => shortened_body === choiceBody),
		[choices]
	);

	const getChoiceBody = useCallback(
		(choiceBody: string | number) =>
			choices.find(
				({ body, shortened_body }) => body === choiceBody || shortened_body === choiceBody
			)?.body || '',
		[choices]
	);

	/**
	 * Calculated dimensions
	 */
	const numOfGroups = groupByResponse ? classData.length : orderedShapedData[0].length;
	const barsPerGroup = groupByResponse ? orderedShapedData[0].length : classData.length;
	const groupHeight = (barHeight + barMarginBottom) * barsPerGroup;
	const groupsTotalHeight = groupHeight * numOfGroups;

	/**
	 * Since mobile rendering renders each group in their own chart, we need to use group height
	 * compared to a total height
	 */
	const height =
		(isSmallView ? groupHeight : groupsTotalHeight) - barMarginBottom + margin.top + margin.bottom;

	const getGroupByChoice = useCallback(
		(choice: string | number) => {
			const choiceIndex = orderedChoices.indexOf(String(choice));
			return orderedShapedData[choiceIndex + 1];
		},
		[orderedChoices, orderedShapedData]
	);

	const getGroupByData = useCallback(
		(section: string | number) => {
			const groupIndex = orderedShapedData[0].indexOf(String(section));
			return orderedShapedData.slice(1).map((group) => group[groupIndex]);
		},
		[orderedShapedData]
	);

	const getBarColor = useCallback(
		(dataIndex: number, barIndex: number, chartIndex?: number) => {
			let colorIndex = 0;

			if (groupByResponse) {
				if (isSmallView) {
					// use chart index to color when view is small
					colorIndex = colorGroupBy === 'dataset' ? barIndex : chartIndex;
				} else {
					colorIndex =
						colorGroupBy === 'dataset'
							? barIndex // Bar is dataset, unique color among the group
							: dataIndex; // Bar is response, same color among the group
				}
			} else {
				if (isSmallView) {
					// use chart index to color when view is small
					colorIndex = colorGroupBy === 'dataset' ? chartIndex : barIndex;
				} else {
					colorIndex =
						colorGroupBy === 'dataset'
							? dataIndex // Bar is dataset, same color among the group
							: barIndex; // Bar is response, unique color among the group
				}
			}

			return colorPalette[colorIndex];
		},
		[colorGroupBy, colorPalette, groupByResponse, isSmallView]
	);

	useEffect(() => {
		const { current: chartContainer } = chartContainerRef;

		/**
		 * Don't try to draw until we can received the size of the window
		 */
		if (!chartContainer || chartContainerWidth === 0) return;

		const modeData = groupByResponse ? orderedChoices : orderedShapedData[0];

		let yLabelTooltips;
		let groupLabelTooltips;

		const draw = (modeData: (string | number)[], chartIndex?: number) => {
			const width = Math.max(minChartWidth, chartContainerWidth);

			/**
			 * Calculate an offset for the class chart in mobile view.  This offset is to account for
			 * the second line of text needed to display the course number.
			 */
			const isClassChart = modeData.length === 1 && modeData[0] === yourClassLabel;
			const classChartOffset = isSmallView ? (isClassChart ? 20 : 10) : 0;

			/**
			 * Chart container
			 */
			const svg: any = select(chartContainer)
				.append('svg')
				.attr('class', 'poll-results-svg')
				.attr('aria-hidden', true)
				.attr('width', width)
				.attr('height', height + classChartOffset)
				.append('g')
				.attr(
					'transform',
					`translate(${isSmallView ? labelWidth : margin.left}, ${margin.top + classChartOffset})`
				);

			/**
			 * Draw the shared x-axis
			 */
			const xScaleRange = [
				0,
				isSmallView
					? width - (labelWidth + spaceAfter100Percent)
					: width - margin.left - spaceAfter100Percent
			];

			const xScale = scaleLinear().domain([0, 100]).range(xScaleRange);

			const xAxis = axisBottom(xScale)
				.ticks(5)
				.tickFormat((d) => `${d}%`)
				.tickSizeOuter(0)
				.tickSizeInner(height);

			svg
				.append('g')
				.call(xAxis)
				.attr('class', 'xAxis')
				.selectAll('text')
				.attr('dy', `-${height + 7}px`)
				.attr('class', 'poll-x-axis-tick-text')
				.attr('aria-hidden', true);

			/**
			 * Shared scale that covers the allotted vertical space for the whole chart.  Use to determine
			 * where to draw each group in the chart. On mobile we use the groupHeight because we draw a chart
			 * for each group.
			 */
			const groupScaleY = scaleLinear()
				.range([0, isSmallView ? groupHeight : groupsTotalHeight])
				.domain([0, modeData.length]);

			/**
			 * Draw each group of bars
			 */
			modeData.map((data, dataIndex) => {
				const group = groupByResponse ? getGroupByChoice(data) : getGroupByData(data);
				const topOfGroupElement = groupScaleY(dataIndex);

				/**
				 * Draw the inner/relative y-axis for each group
				 */
				const yScale = scaleBand<any>()
					.domain(groupByResponse ? orderedShapedData[0] : orderedChoices)
					.range([groupScaleY(dataIndex), groupScaleY(dataIndex + 1)])
					.paddingOuter(0.4)
					.paddingInner(0.35);

				const yAxis = axisLeft(yScale)
					.tickSize(0)
					.tickFormat(() => ''); // Tick text will be displayed within the `foreignObject`
				const yLabelHeight = yScale.bandwidth();

				/**
				 * Draw y-axis labels
				 */
				const yLabels = svg
					.append('g')
					.call(yAxis)
					.attr('class', 'yAxis')
					.selectAll('g')
					.append('foreignObject')
					.attr('width', labelWidth)
					.attr('height', yLabelHeight)
					.attr('x', -labelWidth + 4)
					.attr('y', yLabelHeight / -2)
					.attr('class', 'poll-y-axis-tick')
					.append('xhtml:div')
					.attr('class', 'poll-y-axis-tick-container')
					.append('xhtml:div')
					.attr('class', (tick) => {
						let className = 'poll-y-axis-tick-label';
						if (tick === yourClassLabel) {
							className += ` your-class`;
						} else if (getIsChoiceShortenedBody(tick)) {
							className += ` shortened-body`;
						}
						return className;
					})
					.attr('data-choice-body', (tick) => getChoiceBody(tick))
					.html((tick) => {
						let markup = `<div class="tick">${tick}</div>`;
						if (tick === yourClassLabel) {
							markup += `<div class="course-number">${courseNumber}</div>`;
						}
						return markup;
					})
					.attr('aria-hidden', true);
				yLabelTooltips = drawLabelTooltips(yLabels, tippyProps);

				svg.selectAll('.yAxis path').attr('stroke-width', 0);

				/**
				 * Draw the percentage bars for the group
				 */
				svg
					.append('g')
					.selectAll('bars')
					.data(group)
					.enter()
					.append('rect')
					.attr('x', xScale(0))
					.attr('y', (d, i) =>
						yScale(groupByResponse ? orderedShapedData[0][i] : orderedChoices[i])
					)
					.attr('width', (d) => xScale(d > 1 ? d : 0.5) || 1)
					.attr('height', yScale.bandwidth())
					.attr('fill', (_, barIndex) => getBarColor(dataIndex, barIndex, chartIndex))
					.attr('stroke', 'black')
					.attr('stroke-width', '1px')
					.attr('rx', 3);

				/**
				 * Draw the percentage labels for each bar in the group
				 */
				svg
					.append('g')
					.selectAll('percentLabels')
					.data(group)
					.enter()
					.append('text')
					.text((d) => (d > 0 && d < 1 ? '<1%' : `${d.toFixed(0)}%`))
					.attr('x', (d) => xScale(d as number) + 5)
					.attr('y', (d, i) =>
						yScale(groupByResponse ? orderedShapedData[0][i] : orderedChoices[i])
					)
					.attr('dy', yScale.bandwidth() / 1.5)
					.attr('class', 'poll-percent-label')
					.attr('aria-hidden', true);

				/**
				 * Draw a label for each group along the y-axis
				 */
				const yAxisLabelX = isSmallView ? -labelWidth : -margin.left;
				const yAxisLabelY = isSmallView ? -(margin.top + classChartOffset) : topOfGroupElement + 10;
				const groupLabels = svg
					.append('foreignObject')
					.attr('width', isSmallView ? '100%' : labelWidth)
					.attr('height', isSmallView ? 40 : '100%')
					.attr('x', yAxisLabelX)
					.attr('y', yAxisLabelY)
					.append('xhtml:div')
					.attr('class', () => {
						let className = 'poll-group-label';
						if (data === yourClassLabel) {
							className += ` your-class group-${group.length}-items`;
						} else if (getIsChoiceShortenedBody(data)) {
							className += ` shortened-body`;
						}
						return className;
					})
					.attr('data-choice-body', () => getChoiceBody(data))
					.html(() => {
						let markup = `<div class="group">${data}</div>`;
						if (data === yourClassLabel) {
							markup += `<div class="course-number">${courseNumber}</div>`;
						}
						return markup;
					})
					.attr('aria-hidden', true);
				groupLabelTooltips = drawLabelTooltips(groupLabels, tippyProps);

				/**
				 * Draw the divider line between each group
				 */
				svg
					.append('line')
					.style('stroke-width', isSmallView ? 1 : 2)
					.attr('class', 'divider-line')
					.attr('x1', isSmallView ? 0 : margin.left * -1)
					.attr('y1', groupScaleY(dataIndex))
					.attr('x2', width)
					.attr('y2', groupScaleY(dataIndex));
			});
		};

		/**
		 * Remove all SVG elements and tooltip instances before redrawing
		 */
		select(chartContainer).selectAll('*').remove();
		yLabelTooltips?.destroy();
		groupLabelTooltips?.destroy();

		if (isSmallView) {
			modeData.forEach((data, chartIndex) => draw([data], chartIndex));
		} else {
			draw(modeData);
		}
	}, [
		chartContainerRef,
		courseNumber,
		getBarColor,
		getChoiceBody,
		getGroupByChoice,
		getGroupByData,
		getIsChoiceShortenedBody,
		groupByResponse,
		groupHeight,
		groupsTotalHeight,
		height,
		isSmallView,
		orderedChoices,
		orderedShapedData,
		tippyProps,
		chartContainerWidth
	]);

	return (
		<ClassNames>
			{({ cx }) => (
				<figure
					ref={ref}
					css={(theme) => refreshedPollResultsStyles(theme, { isSmallView })}
					className={cx('refreshed-poll-results', 'refreshed-poll-results-bars')}>
					<div
						role="img"
						aria-label={getLongDescription(description, 'bar', isClassOnly)}
						style={{ height: 0 }}>
						&nbsp;
					</div>
					<div
						className="refreshed-poll-results-chart-container"
						style={{ minWidth: minChartWidth }}
						ref={chartContainerRef}
					/>
					{includesMarkup(description) && (
						<figcaption
							className="sr-only"
							dangerouslySetInnerHTML={{ __html: getFigureCaption(description) }}
						/>
					)}
				</figure>
			)}
		</ClassNames>
	);
});

RefreshedPollResultsBars.displayName = 'RefreshedPollResultsBars';

function drawLabelTooltips(labels: any, tippyProps?: RefreshPollProps['tippyProps']) {
	const tooltips: Array<TippyInstance> = [];

	labels.each(function () {
		const labelElement = this as HTMLElement;
		const label = select(this);

		// The tooltip shouldn't be applied to "Your Class" labels due to design. Even if they have ellipsis.
		const isYourClassLabel = labelElement.classList.contains('your-class');
		if (isYourClassLabel) return;

		// The `choice.shortened_body` prop is used. Need to show the full `body`
		const isShortLabel = labelElement.classList.contains('shortened-body');

		// The `scrollHeight` is higher than `clientHeight` when some text is overflown and hidden
		const isOverflownLabel = labelElement.scrollHeight > labelElement.clientHeight;

		if (isShortLabel || isOverflownLabel) {
			const content = labelElement.dataset.choiceBody || labelElement.innerText;
			tooltips.push(tippy(labelElement, { content }));

			label.attr('tabindex', 0);
			label.attr('role', 'button');
			label.attr('aria-label', 'Show longer bar chart label');
			label.attr('aria-hidden', false);
			label.classed('label-underline', true);
		}
	});

	const tooltipsSingleton = createTooltipsSingleton(tooltips, tippyProps);
	return {
		tooltips,
		tooltipsSingleton,
		destroy: () => {
			tooltips.forEach((tooltip) => tooltip.destroy());
			tooltipsSingleton.destroy();
		}
	};
}

export default RefreshedPollResultsBars;
