import { DateTime } from 'luxon';

export const formatTimestamp = (ISODateString: string): string =>
	formatDateTime(DateTime.fromISO(ISODateString), "MM/dd/yy 'at' t ZZZZ");

export const formatTimestampShort = (ISODateString: string): string =>
	formatDateTime(DateTime.fromISO(ISODateString), "MM/dd',' t ZZZZ");

export const formatDateTime = (datetime: DateTime, formatString: string): string =>
	datetime.toFormat(formatString).replace(/ AM /, ' a.m. ').replace(/ PM /, ' p.m. ');

// TODO Rewrite with the "options parameter" pattern. But many applications depends on the plain params interface :(
export function formatTimeSpent(
	duration: number,
	onlyMinutes = false,
	noSeconds = false,
	minuteLabel = 'm'
) {
	if (duration != null) {
		let h, m, timeSpentInWords;

		if (noSeconds && duration < 60) {
			return `<1${minuteLabel}`;
		}

		if (onlyMinutes) {
			h = 0;
			m = Math.ceil(duration / 60).toFixed(0);
		} else {
			let h_int = Math.floor(duration / 3600);
			const m_int = Math.ceil(duration / 60) % 60;

			// `Math.ceil` rounds up to the nearest minute, which
			// may result in rounding up to the next hour. In that
			// scenario, the number of hours should be incremented.
			const rounded_up = duration % 60 !== 0;
			if (rounded_up && m_int === 0) {
				h_int = h_int + 1;
			}

			h = h_int.toFixed(0);
			m = m_int.toFixed(0);
		}

		if (h > 0) {
			timeSpentInWords = `${h}h ${m}${minuteLabel}`;
		} else {
			timeSpentInWords = `${m}${minuteLabel}`;
		}

		return timeSpentInWords;
	} else {
		return null;
	}
}

export let formatAsOrdinalNumber: (number: number) => string;

if ('Intl' in window && 'PluralRules' in window.Intl) {
	const ordinalPluralRules = new Intl.PluralRules('en', { type: 'ordinal' });
	const ordinalSuffixes = new Map([
		['one', 'st'],
		['two', 'nd'],
		['few', 'rd'],
		['other', 'th']
	]);
	formatAsOrdinalNumber = (number) => {
		const rule = ordinalPluralRules.select(number);
		const suffix = ordinalSuffixes.get(rule);
		return `${number}${suffix}`;
	};
} else {
	const ordinalExceptions = { 11: 'th', 12: 'th', 13: 'th' };
	formatAsOrdinalNumber = (number) => {
		const ordinal = number.toString();
		if (ordinalExceptions[number]) return `${ordinal}${ordinalExceptions[number]}`;

		const rem = number % 10;
		if (rem === 1) return ordinal + 'st';
		if (rem === 2) return ordinal + 'nd';
		if (rem === 3) return ordinal + 'rd';
		return ordinal + 'th';
	};
}
