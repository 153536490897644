import { css } from '@emotion/react';

import { mixins, breakpoints, ThemeName, Theme, getThemeItem } from '~/styles/themes';

export const rejoinderRootStyles = (theme: Theme) => css`
	background: ${theme.colors.white};
	font-size: ${getThemeItem(theme.webtextQuestion.fontSize, theme)};
	display: block;
	padding: 10px 10px 15px 15px;
	border-top: 1px solid ${getThemeItem(theme.webtextQuestion.rejoinderSeparator.color, theme)};
`;

export default (theme) => {
	return css`
		.rejoinder {
			${rejoinderRootStyles(theme)}

			&:empty {
				padding: 0;
				border: none;
			}

			a {
				color: ${theme.colors.link};
				text-decoration: underline;
				font-weight: ${theme.webtextPage.a.fontWeight};
			}

			.incorrect {
				color: ${theme.webtextQuestion.rejoinder.incorrect.color};
			}

			.correct {
				color: ${theme.webtextQuestion.rejoinder.correct.color};
			}

			.correct-answer-rejoinder {
				display: block;
				margin-top: 1em;

				.correct {
					display: none;
				}

				.correct-info {
					color: ${theme.webtextQuestion.rejoinder.correctInfo
						? theme.webtextQuestion.rejoinder.correctInfo.color
						: 'inherit'};
				}

				.correct-answer {
					margin-bottom: 1em;
				}
			}
		}

		.incorrect,
		.correct,
		.correct-info {
			font-family: ${theme.fonts['webtext-question-rejoinder-correctness']};
			font-weight: ${theme.webtextQuestion.rejoinder.correctness.fontWeight};
		}

		.visually-hidden {
			${mixins.webtextHiddenAccessible(theme)}
		}

		.status-and-actions {
			margin-top: 2em;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.question.answered .question-choices {
				margin-bottom: 0;
				border-bottom: none;
			}

			.rejoinder,
			.status-and-actions:not(.separator) {
				margin-left: -50px;
				padding-left: 50px;
			}

			.rejoinder {
				padding-top: 1em;
				font-family: ${theme.fonts['webtext-question-rejoinder']};
				font-style: italic;
				font-size: 18px;
				color: #4e4e4e;
				letter-spacing: 0;
				line-height: 30px;

				@media (max-width: ${breakpoints.small}) {
					line-height: 24px;
				}

				& em,
				& i {
					font-style: normal;
				}

				.incorrect,
				.correct,
				.correct-info {
					font-style: italic;
				}
			}

			.rejoinder:not(:empty) + .status-and-actions {
				background: ${theme.colors.white};
				padding-bottom: 1em;
			}

			@media (max-width: ${breakpoints.small}) {
				.rejoinder,
				.status-and-actions {
					font-size: 15px;
					margin-right: 0;
				}

				.rejoinder:not(:last-of-type) {
					padding-bottom: 0;
				}

				.question-choices .question-choice label {
					font-size: 15px;
				}

				.status-and-actions {
					font-size: 12px;
				}

				.save-button-container button {
					width: 100%;
				}
			}
		`}
	`;
};
