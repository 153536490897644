import { CitationsConfig } from '../../FillIn/types';
import { IDictionary } from '../../WritingTemplate/types';
import {
	getCitationFromData,
	getCitationFromSettings,
	getCitationKey,
	getCourseDecisions,
	getDecisionStyleTopic,
	getPathFromImports,
	handleCitationStyles,
	isKeyFromCourse,
	isOneCitationKey
} from './citationService';

export const fromConfigToCitation = (
	toc: IDictionary,
	citationPath: string,
	imports?: IDictionary
): CitationsConfig => {
	const citationImportFrom = getPathFromImports(imports, citationPath);
	const { key, topic } = getDecisionStyleTopic(toc) || {};
	const courseDecisions = getCourseDecisions(toc);

	if (citationImportFrom && key && topic) {
		return getCitationFromSettings(citationImportFrom, toc, key, topic);
	}

	if (citationImportFrom && topic && isKeyFromCourse(courseDecisions)) {
		const citationKey = getCitationKey(courseDecisions);

		return isOneCitationKey(courseDecisions)
			? getCitationFromSettings(citationImportFrom, toc, citationKey, topic)
			: null;
	}

	if (citationPath) {
		const citation = getCitationFromData(toc, citationPath);

		return isOneCitationKey(courseDecisions)
			? handleCitationStyles(citation, getCitationKey(courseDecisions))
			: null;
	}
	return null;
};
