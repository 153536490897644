import { css, SerializedStyles } from '@emotion/react';

import type { Theme } from '~/styles/themes';

export const root = (theme: Theme): SerializedStyles => css`
	display: grid;
	margin-bottom: 15px;
	grid-template-areas:
		'stack'
		'controls';
	border: 1px solid ${theme.colors['border-gray']};
`;

export const imgAndCanvas = css`
	width: 100%;
	height: auto;
	margin: 0 !important; // overrides core figure Sass styles
	padding: 0 !important;
	grid-area: stack;
`;

export const controls = (theme: Theme): SerializedStyles => css`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-area: controls;
	border-top: 1px solid ${theme.colors['border-gray']};
`;

export const playStopToggleButton = css`
	margin: 0;
	border: 0;
	padding: 0;
	cursor: pointer;
	color: inherit;
	text-decoration: underline;
	font-style: normal;
	font-family: inherit;
	font-size: inherit;
	background-color: unset;
`;

export const loading = css`
	display: flex;
	grid-area: stack;
	align-self: center;
	justify-self: center;
	align-items: center;
	column-gap: 1rem;
`;

export const loadFailed = css`
	${loading}
	column-gap: 0.5rem;
`;
