import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export const webtextCalloutWrapperStyles = (theme, gutterIcon: boolean) => css`
	--side-size: ${theme.webtextCallout.padding};
	margin-left: ${gutterIcon && theme.webtextCallout.padding};

	margin-bottom: 30px;
	padding: var(--side-size);

	font-size: 16px;
	border: ${theme.webtextCallout.borderWidth} solid ${theme.colors.primary};
	background-color: ${theme.webtextCallout.backgroundColor};

	overflow-x: auto;

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		border-radius: ${theme.webtextCallout.borderRadius};
		font-family: ${theme.webtextCallout.fontFamily};
	`}

	@media (max-width: ${breakpoints.extraSmall}) {
		--side-size: 22px;
		margin-left: ${gutterIcon && '22px'};
	}

	${gutterIcon &&
	css`
		@media (min-width: ${breakpoints.large}) {
			margin-left: 0;
			div {
				svg {
					position: absolute;
					left: -70px;
					height: 60px;
					width: 60px;
				}
			}
		}
	`}

	&.unstyled {
		border: 0;
		background-color: inherit;
		padding: 0;

		@media (max-width: ${breakpoints.large}) {
			padding-left: 0;
		}
	}

	.table-figure-container {
		margin-left: calc(-1 * var(--side-size));
		margin-right: calc(-1 * var(--side-size));
		padding-left: var(--side-size);
		padding-right: var(--side-size);

		& > table {
			border-right: none;
		}
	}
`;

export default (theme, gutterIcon: boolean) => css`
	${webtextCalloutWrapperStyles(theme, gutterIcon)}

	> :first-child {
		margin-top: 0;

		.text-component,
		.gutter-icon-wrapper {
			> :first-child {
				margin-top: 0;
			}
		}
	}

	> :last-child {
		margin-bottom: 0;

		.text-component,
		.gutter-icon-wrapper {
			> :last-child {
				margin-bottom: 0;
			}
		}
	}

	svg {
		font-size: 60px;
	}

	.marker-icon {
		margin-left: 77px;

		@media (max-width: ${breakpoints.large}) {
			margin-left: 60px;
			padding-left: 30px;
		}
	}

	.blurb,
	.byline,
	.case-appellate,
	.case-date,
	.case-name,
	.case-number,
	.dateline,
	.internal-heading,
	.subtitle,
	.title {
		margin: 0 0 0.67em;
		-webkit-font-smoothing: antialiased;

		font-family: ${theme.fonts.app};
		font-weight: 500;
		color: #333;

		&.bordered {
			&:after {
				content: '';
				width: 0.75em;
				height: 0;
				border-bottom: 5px solid #ffffff;
				display: block;
				margin-top: 0.2em;
			}
		}
	}

	blockquote {
		border-left: 0;
	}

	.case-name,
	.title {
		font-size: 1.8em;
	}

	.subtitle {
		font-size: 1.6em;
	}

	.case-appellate {
		font-size: 1.25em;
	}

	.byline,
	.case-date,
	.case-number {
		font-size: 1.2em;
	}

	.case-date {
		padding-bottom: 20px;
	}

	.dateline {
		font-size: 1.2em;

		// NOTE: These styles are only intended for non-UV
		// themes. For UV, the 'dateline', 'blurb', and
		// 'subtitle' classes are deprecated, and so any
		// styles here must not affect their style in UV.
		//
		// For the others, we are overriding the font-size,
		// but we don't override font-style or padding,
		// which is why this is conditionally included here.
		//
		// See T-1242 for the original request. This can go
		// away if we every recode these webtexts, and we may
		// well do that.
		//
		// - Matt, 16 jun 2021
		${theme.name !== 'universal_velvet' &&
		css`
			font-style: italic;
			padding-bottom: 20px;
		`}
	}

	.internal-heading {
		font-size: 1.4em;
	}

	.blurb {
		font-size: 1.2em;
	}

	// Inheritance from the original css rule
	.text-component p:last-child {
		margin-bottom: 0;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		.dateline, // deprecated, see T-1242
			.blurb, // deprecated, see T-1242
			.byline {
			font-size: 16px;
			font-weight: normal;
			line-height: 28px;
			margin-bottom: 0;

			@media (max-width: ${breakpoints.small}) {
				font-size: 14px;
				line-height: 25px;
			}
		}

		.title {
			font-family: ${theme.fonts.app};
			font-weight: 900;
			font-size: 32px;
			color: #000000;
			letter-spacing: 0.4px;
			line-height: 40px;
			margin-bottom: 15px;

			@media (max-width: ${breakpoints.small}) {
				font-size: 24px;
				line-height: 32px;
			}
		}

		.subtitle, // deprecated, see T-1242
			.internal-heading {
			font-size: 22px;
			font-family: ${theme.fonts.app};
			font-weight: bold;
			line-height: 28px;
			margin-top: 10px;
			margin-bottom: 12px;

			@media (max-width: ${breakpoints.small}) {
				font-size: 18px;
				line-height: 26px;
				margin-bottom: 6px;
			}
		}

		div {
			svg {
				@media (max-width: ${breakpoints.large}) {
					position: absolute;
					margin-bottom: calc(-38px);
				}
			}
		}
	`}
`;
