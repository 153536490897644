import React, { FC, useMemo } from 'react';

import {
	getContextualInfoIcon,
	getContextualInfoId
} from '~/components/pageElements/PollQuestion/utils';

import { calculateCellJsxContent, emptyCellJsxContent, getColumnCellClass } from './utils';

import type { Props as ComparisonTableProps } from './index';

const WideComparisonTable: FC<ComparisonTableProps> = (props) => {
	const {
		courseNumber = '',
		classData,
		dataType,
		classTotal,
		totalCount,
		contextualInfo,
		shapedData,
		totalPercentageSums,
		classmatesRespondedMessage
	} = props;

	const datasets = shapedData[0];

	const header = useMemo(() => {
		const [classDataset, ...externalDatasets] = datasets;
		return (
			<>
				<tr>
					<th scope="col" rowSpan={2}>
						Choice
					</th>
					<th
						scope="col"
						key={classDataset}
						colSpan={2}
						aria-label={`${classDataset} ${courseNumber}`}>
						{classDataset}
						<div className="course-number">{courseNumber}</div>
					</th>
					{externalDatasets.map((dataset: string, index) => {
						const info = contextualInfo?.[index];
						const icon = getContextualInfoIcon(index);
						return (
							<th
								scope="col"
								key={dataset}
								colSpan={2}
								aria-label={dataset}
								aria-describedby={getContextualInfoId(index)}>
								{dataset}
								{info && icon && <sup aria-hidden>{icon}</sup>}
							</th>
						);
					})}
				</tr>
				<tr>
					{datasets.map((_, i) => (
						<React.Fragment key={`dataset-header-${i}`}>
							<th scope="col" className="poll-data-header-column-label">
								Responses
							</th>
							<th scope="col" className="poll-data-header-column-label">
								Percentage
							</th>
						</React.Fragment>
					))}
				</tr>
			</>
		);
	}, [contextualInfo, courseNumber, datasets]);

	const rows = useMemo(
		() =>
			shapedData.slice(1).map((row, rowIndex) => (
				<tr key={`${row[0]}-${rowIndex}`}>
					<th scope="row" dangerouslySetInnerHTML={{ __html: classData[rowIndex].label }} />
					{row.map((colValue, colIdx) => {
						const { numberCellContent, percentageCellContent } = calculateCellJsxContent({
							colIdx,
							colValue: colValue as number,
							dataType,
							totalCount,
							classTotal
						});

						return (
							<React.Fragment key={`${colIdx}-${colValue}-number`}>
								<td className={getColumnCellClass(colIdx)}>{numberCellContent}</td>
								<td className={getColumnCellClass(colIdx)} key={`${colIdx}-${colValue}-percentage`}>
									{percentageCellContent}
								</td>
							</React.Fragment>
						);
					})}
				</tr>
			)),
		[classData, classTotal, dataType, shapedData, totalCount]
	);

	/**
	 * The cell is classed with `i + 1` here because there is an odd number of cells
	 * in-front of the cells that are being classed.
	 */
	const totalRow = useMemo(
		() => (
			<tr>
				<th scope="row" aria-label={'Total responses ' + classmatesRespondedMessage}>
					Total responses
					<div className="poll-data-classmates-message">{classmatesRespondedMessage}</div>
				</th>
				<td className="shaded-cell">{classTotal}</td>
				<td className="shaded-cell total-row-percentage">{totalPercentageSums[0]}%</td>
				{totalCount.map((total, i) => (
					<React.Fragment key={`total-row-${i}`}>
						<td className={getColumnCellClass(i + 1)}>
							{total ? Number(total).toLocaleString('en-US') : emptyCellJsxContent}
						</td>
						<td className={`total-row-percentage ${getColumnCellClass(i + 1)}`}>
							{totalPercentageSums[i + 1]}%
						</td>
					</React.Fragment>
				))}
			</tr>
		),
		[classTotal, classmatesRespondedMessage, totalCount, totalPercentageSums]
	);

	return (
		<div className="poll-data-table-overflow">
			<table>
				<thead>{header}</thead>
				<tbody>
					{rows}
					{totalRow}
				</tbody>
			</table>
		</div>
	);
};

export default WideComparisonTable;
