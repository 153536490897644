import React from 'react';

export default function PdfDownloadIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.79 65.32" className="pdf-download-icon">
			<g fill={props.color}>
				<polygon points="33.39 57.66 33.39 42.92 29.39 42.92 29.39 57.66 22.23 50.51 19.41 53.34 31.39 65.32 43.37 53.34 40.54 50.51 33.39 57.66" />
				<rect x="12.42" y="34.1" width="24.95" height="4" />
				<rect x="12.42" y="26.65" width="24.95" height="4" />
				<path
					d="M21.84,16.12v0A2.06,2.06,0,0,0,19.52,14h-.94v4.26h.94A2.05,2.05,0,0,0,21.84,16.12Z"
					transform="translate(-0.43 -0.12)"
				/>
				<path
					d="M12.94,15v0c0-.72-.51-1.1-1.35-1.1H10.5v2.22h1.1C12.43,16.09,12.94,15.65,12.94,15Z"
					transform="translate(-0.43 -0.12)"
				/>
				<path
					d="M4.43.12V9.39h-4V22.85h4v24.6H23v-4H8.43V22.85H37.8V9.39H8.43V4.12H42.22V47.45h4V.12Zm21,11.79h6.72v2H27.76v1.43h4v1.93h-4v3H25.43Zm-9.18,0h3.24c3,0,4.74,1.73,4.74,4.15v0c0,2.42-1.77,4.22-4.79,4.22H16.25Zm-4.48,0c2.13,0,3.49,1.09,3.49,2.95v0c0,2-1.52,3-3.61,3H10.5v2.4H8.17v-8.4Z"
					transform="translate(-0.43 -0.12)"
				/>
			</g>
		</svg>
	);
}
