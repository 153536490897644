import React, { useEffect } from 'react';

import { QuestionPrompt } from '~/components/shared/Question';
import { useAccessibilityFocus } from '~/hooks';

import { useStudentView } from '../hooks/useStudentView';
import SpeedbumpInterventions from '../speedbump/SpeedbumpInterventions';
import { promptStyles } from '../styles';
import StudentViewBelowTheFold from './StudentViewBelowTheFold';

import type { StudentViewElementProps, DisplayedQuestionElementProps } from '../types';

type Props = StudentViewElementProps &
	DisplayedQuestionElementProps & {
		promptId: string;
		saveButtonLabel?: string;
		resetButtonLabel?: string;
	};

const StandaloneStudentView: React.VFC<Props> = (props) => {
	const { promptId, onInitialLoadComplete } = props;
	const [promptRef, setFocusToPrompt] = useAccessibilityFocus();
	const { speedbumpInterventionsProps, ...useStudentViewProps } = useStudentView(
		props,
		setFocusToPrompt
	);
	const { activeQuestion } = useStudentViewProps;

	useEffect(() => {
		onInitialLoadComplete?.({ familyId: activeQuestion.family_id, collapsed: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<QuestionPrompt id={promptId} ref={promptRef} body={activeQuestion.body} css={promptStyles} />
			<StudentViewBelowTheFold {...props} {...useStudentViewProps} />
			<SpeedbumpInterventions {...speedbumpInterventionsProps} />
		</>
	);
};

export default StandaloneStudentView;
