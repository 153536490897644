import React, { useEffect, useRef, useState } from 'react';
import { MdError } from 'react-icons/md';

import { GifReader } from 'omggif';

import { Indeterminate } from '~/components/Loaders';

import * as styles from './styles';
import { payloadToImgElement } from './utils';

import type { FigureElement } from '~/types/WebtextManifest';

export interface GifImageFigureProps {
	figure: Partial<FigureElement>;
	isPlaying: boolean;
	setAnimated: (newValue: boolean) => void;
}

/**
 * Component to display GIF Figure payloads (either animated or static).
 *
 * For animated GIFs, it allows playing and stopping the animation. The component defaults to autoplay
 * unless (prefers-reduced-motion: reduce) is set. Playing and stopping the animation is accomplished by
 * - creating an `<img>` containing the payload's GIF src
 * - creating a `<canvas>` consisting of the animated GIF's first frame
 * - and varying the z-index of the canvas so that it stacks on top or goes behind the `<img>`
 *
 * It uses `omggif` to decode the GIF and determine whether it is animated or static. Because we use `fetch`,
 * this component **will not work** with images that do not have Access-Control-Allow-Origin configured.
 * (Assets in the Soomo S3 bucket should work fine, however.)
 */
const GifImageFigure: React.VFC<GifImageFigureProps> = ({ figure, isPlaying, setAnimated }) => {
	const { payload } = figure;

	const [imgEl, setImgEl] = useState<HTMLImageElement | null>(null);
	const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement | null>(null);

	const [isLoaded, setLoaded] = useState(false);
	const [loadFailed, setLoadFailed] = useState(false);

	const blobUrl = useRef<string>(null);

	const altText = figure.description
		? figure.description
		: payload
		  ? payloadToImgElement(payload)?.alt
		  : undefined;

	useEffect(() => {
		if (!canvasEl || !imgEl || !payload) {
			return;
		}

		(async () => {
			/**
			 * parse payload and extract a HTMLImageElement from it
			 * (don't actually mount an <img> tag to the DOM yet--we're about to `fetch` its src manually,
			 * and we don't want to fetch it twice)
			 */
			const payloadImg = payloadToImgElement(payload);
			if (!payloadImg) return;

			if (blobUrl.current) {
				URL.revokeObjectURL(blobUrl.current);
			}

			let res;
			try {
				res = await fetch(payloadImg.src);
			} catch (e) {
				// not sending these to Rollbar, though we will see the error in Fullstory
				console.error(e);
				setLoadFailed(true);
			}
			if (!res) return;

			try {
				const blob = await res.blob();
				const buf = await blob.arrayBuffer();
				blobUrl.current = URL.createObjectURL(blob); // we'll use this blobUrl as the <img> src later
				const arr = new Uint8Array(buf);
				const gifReader = new GifReader(arr);
				const firstFrameInfo = gifReader.frameInfo(0);

				if (gifReader.numFrames() > 1) {
					setAnimated(true);

					canvasEl.width = firstFrameInfo.width;
					canvasEl.height = firstFrameInfo.height;
					const ctx = canvasEl.getContext('2d');
					const firstFrameImg = new ImageData(firstFrameInfo.width, firstFrameInfo.height);
					gifReader.decodeAndBlitFrameRGBA(0, firstFrameImg.data);
					ctx.putImageData(firstFrameImg, 0, 0);
					if (!matchMedia('(prefers-reduced-motion: reduce)').matches) {
						canvasEl.style.display = 'none';
					}
				}

				imgEl.addEventListener('load', () => setLoaded(true));

				imgEl.width = firstFrameInfo.width;
				imgEl.height = firstFrameInfo.height;
				imgEl.src = blobUrl.current;
				imgEl.alt = payloadImg.alt;
			} catch (e) {
				(window.Rollbar ?? console).error('Failed to fetch GIF image', e, payload);
				setLoadFailed(true);
			}
		})();
	}, [canvasEl, imgEl, payload, setAnimated]);

	useEffect(() => {
		if (!canvasEl) return;

		if (loadFailed) {
			if (altText) {
				imgEl.src = 'null'; // causes the broken icon and the alt text to be displayed
				canvasEl.style.display = 'none'; // reveals the <img> tag
			} else {
				imgEl.removeAttribute('src');
				canvasEl.style.display = 'block';
			}
		} else if (isPlaying && isLoaded) {
			imgEl.src = blobUrl.current; // force the animated GIF to restart
			canvasEl.style.display = 'none';
		} else {
			canvasEl.style.display = 'block';
		}
	}, [canvasEl, imgEl, isPlaying, loadFailed, altText]);

	return (
		<div css={styles.root}>
			<img
				css={styles.imgAndCanvas}
				ref={setImgEl}
				alt={isLoaded || loadFailed ? altText : undefined}
			/>
			<canvas css={styles.imgAndCanvas} ref={setCanvasEl} />
			{!isLoaded && !loadFailed && (
				<div css={styles.loading}>
					<Indeterminate /> Loading GIF image...
				</div>
			)}
			{loadFailed && !altText && (
				<div css={styles.loadFailed}>
					<MdError size="2rem" color="red" /> Failed to load GIF image
				</div>
			)}
		</div>
	);
};

export default GifImageFigure;
