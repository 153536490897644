import { useCallback } from 'react';
import 'wicg-inert';

/**
 * Hook is required to set the `inert` attribute on an element
 * because React JSX elements doesn't support it natively in React@16
 *
 * https://github.com/WICG/inert/issues/58#issuecomment-458492605
 */
export const useInertRef = <T extends HTMLElement>(
	shouldBeInert: boolean
): ((node: T | null) => void) =>
	useCallback(
		(node) => (shouldBeInert ? node?.setAttribute('inert', '') : node?.removeAttribute('inert')),
		[shouldBeInert]
	);
