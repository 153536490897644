import React from 'react';

import { css, SerializedStyles } from '@emotion/react';
import { shallow } from 'zustand/shallow';

import { dragHandleDataKey } from '../../../helpers/constants';
import { DragHandlePosition } from '../../../helpers/types';
import { useSpreadsheetSelector } from '../../../store/provider';

interface Props {
	position: DragHandlePosition;
}

const DragHandle: React.FC<Props> = ({ position }) => {
	const { touchMode, isTouchScreen } = useSpreadsheetSelector(
		(state) => ({
			touchMode: state.touchMode,
			isTouchScreen: state.isTouchScreen
		}),
		shallow
	);

	const isVisible = touchMode !== 'select' && isTouchScreen;

	return (
		<div
			data-key={dragHandleDataKey}
			id={`${position}-handle`}
			css={styles({ isVisible, position })}
		/>
	);
};

export default DragHandle;

export const styles = (options: {
	isVisible: boolean;
	position: DragHandlePosition;
}): SerializedStyles => css`
	position: absolute;
	background: transparent;
	pointer-events: auto;
	touch-action: none;

	width: 25px;
	height: 25px;

	${setGrabPosition(options.position)}

	&:after {
		content: '';
		position: absolute;
		opacity: ${options.isVisible ? '1' : '0'};
		width: 15px;
		height: 15px;
		background: #3579f8;
		border-radius: 50%;
		border: 2px solid #fff;
		z-index: 500;
		${setShowPosition(options.position)}
	}
`;

const setGrabPosition = (position: DragHandlePosition): SerializedStyles => css`
	${position === 'top'
		? css`
				left: -2px;
				top: -2px;
		  `
		: css`
				right: -2px;
				bottom: -2px;
		  `}
`;

const setShowPosition = (position: DragHandlePosition): SerializedStyles => css`
	${position === 'top'
		? css`
				left: -5px;
				top: -5px;
		  `
		: css`
				right: -5px;
				bottom: -5px;
		  `}
`;
