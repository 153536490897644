import React, { useContext, useMemo } from 'react';

import { shallow } from 'zustand/shallow';

import ElementsContext from '../../context/ElementsContext';
import { getHightLightRanges } from '../../helpers';
import { useSpreadsheetSelector } from '../../store/provider';
import styles from './styles';

interface Props {
	withHeader: boolean;
	totalHeight: number;
	totalWidth: number;
}

const FormulaHighlight: React.FC<Props> = ({ withHeader, totalHeight, totalWidth }) => {
	const { editingCell, highlights } = useSpreadsheetSelector(
		(state) => ({
			editingCell: state.editingCell,
			highlights: state.cellHighlights
		}),
		shallow
	);

	const { cellsRefs } = useContext(ElementsContext);

	const cellHighlights = useMemo(() => {
		if (!editingCell) return null;

		const palette = [
			'#F8A030',
			'#7E3794',
			'#11A9CC',
			'#A61D4C',
			'#659CF6',
			'#F4B400',
			'#65B045',
			'#795548',
			'#9A9A9A',
			'#F2CD46',
			'#536DB2'
		];

		return getHightLightRanges(highlights, cellsRefs, withHeader)
			.filter(({ isDisplay }) => isDisplay)
			.map(({ position }, idx) => {
				const { top, left, width, height } = position;
				const opacity = 0;
				const color = palette[idx];
				return (
					<div key={top + left + color} css={styles(left, top, width, height, color, opacity)}>
						<div></div>
					</div>
				);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingCell, cellsRefs, highlights, withHeader, totalHeight, totalWidth]);

	return <>{cellHighlights}</>;
};

export default FormulaHighlight;
