import React from 'react';

export default function DownloadIcon(props) {
	return (
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 90">
			<g transform="translate(0,90) scale(0.1,-0.1)" fill={props.color}>
				<path
					d="M380 640 c0 -143 -3 -260 -7 -260 -4 0 -71 63 -148 140 l-141 140
-32 -33 -32 -33 203 -201 202 -202 205 198 205 199 -35 36 -34 36 -143 -142
-143 -143 0 263 0 262 -50 0 -50 0 0 -260z"
				/>
				<path d="M0 45 l0 -45 430 0 430 0 0 45 0 45 -430 0 -430 0 0 -45z" />
			</g>
		</svg>
	);
}
