import { MutableRefObject, useEffect, useMemo, useState } from 'react';

export const useIsInViewport = (ref: MutableRefObject<HTMLElement>): boolean => {
	const [isIntersecting, setIsIntersecting] = useState(true);

	const observer = useMemo(
		() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
		[]
	);

	useEffect(() => {
		if (!ref.current) return;

		observer.observe(ref.current);
		return () => observer.disconnect();
	}, [ref, observer]);

	return isIntersecting;
};
