import {
	AcceptOnlyTypes,
	CellOnBlurErrors
} from '~/components/WritingTemplate/Spreadsheet/helpers/types';

const errorMessages: Record<AcceptOnlyTypes, string> = {
	[AcceptOnlyTypes.Number]:
		'The cell should not contain characters such as dollar signs, commas, or percent symbols.',
	[AcceptOnlyTypes.Numeric]:
		'The value entered in this cell must be a number or a formula that results in a number.'
};

interface GetValueErrorArgs {
	value: string;
	cellBlurErrorsRules: CellOnBlurErrors;
	validateType: ValidateType;
}

export type ValueErrorType = 'accept-only' | 'formula';
export type ValueError =
	| { type: ValueErrorType; message: string; violatedOnBlurRule?: CellOnBlurErrors }
	| undefined;

export enum ValidateType {
	Formula = 'formula',
	Value = 'value'
}

/**
 * Validates the current raw cell value against the rules defined in the schema's `on-blur-errors`
 * Return `undefined` if the value is valid
 */
export const getValueError = ({
	value,
	validateType,
	cellBlurErrorsRules
}: GetValueErrorArgs): ValueError => {
	const { 'accept-only': acceptOnly } = cellBlurErrorsRules;
	switch (acceptOnly) {
		case AcceptOnlyTypes.Number:
			return getNumberError(cellBlurErrorsRules);
		case AcceptOnlyTypes.Numeric:
			return getNumericError(cellBlurErrorsRules);
	}

	function getNumberError(cellBlurErrorsRules): ValueError {
		const isInvalidNumber = isNaN(+value) || validateType === ValidateType.Formula;
		if (!isInvalidNumber) return;
		return {
			type: 'accept-only',
			message: errorMessages[AcceptOnlyTypes.Number],
			violatedOnBlurRule: cellBlurErrorsRules
		};
	}

	function getNumericError(cellBlurErrorsRules): ValueError {
		/**
		 * When formula result provided and unformatted result is a number
		 * = formula valid, ignore the number validation
		 * Otherwise check number
		 */
		const isCellInvalid = isNaN(+value);

		if (!isCellInvalid) return;
		return {
			type: 'accept-only',
			message: errorMessages[AcceptOnlyTypes.Numeric],
			violatedOnBlurRule: cellBlurErrorsRules
		};
	}
};
