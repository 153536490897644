import { getAddressDepth } from '~/components/Outline/helpers/address';
import {
	isChildrenSection,
	OutlineHierarchy,
	OutlineHierarchyItemType,
	OutlineSection,
	OutlineSectionAddress,
	OutlineSectionAddressPoint
} from '~/components/Outline/types';

/**
 * Returns the hierarchy section from the schema-defined hierarchy of the outline tool
 *
 * @example nested template retrieval
 * // Returns { template: "transition" }
 *
 * itemAddress: [{ c:0 }, { c:0 }, { c:1 }]
 * hierarchy: [
 * 		{
 * 			"template": "body",
 * 			"children": [
 * 				{
 * 					"template": "main_point",
 * 					"children": [
 * 						{ "template": "support" },
 * 						{ "template": "transition" }
 * 					]
 * 				}
 * 			]
 * 		}
 * ]
 *
 * @example Playground
 * https://playcode.io/1013829
 */
export const getHierarchySection = <TSection extends OutlineSection>(
	hierarchy: OutlineHierarchy,
	address: OutlineSectionAddress
): TSection => {
	let section: OutlineSection;

	address.forEach(({ childrenIndex, toggleIndex }) => {
		if (!section) {
			section = hierarchy[childrenIndex];
			return;
		}

		if ('children' in section && childrenIndex !== undefined) {
			section = section.children[childrenIndex];
		} else if ('toggle' in section && toggleIndex !== undefined) {
			section = section.toggle[toggleIndex];
		}
	});

	return section as TSection;
};

interface HierarchyTraverseYieldResult {
	section: OutlineSection;
	address: OutlineSectionAddress;
}

/**
 * Traverses over the hierarchy tree sections, children or toggle ones
 */
export function* traverseHierarchy(args: {
	sections: Array<OutlineSection>;
	sectionsType?: OutlineHierarchyItemType;
	parentAddress?: OutlineSectionAddress;
	depth?: number;
}): Generator<HierarchyTraverseYieldResult> {
	const {
		sections,
		sectionsType = OutlineHierarchyItemType.Children,
		parentAddress = [],
		depth
	} = args;

	if (depth !== undefined) {
		const parentAddressDepth = getAddressDepth(parentAddress);
		if (parentAddressDepth >= depth) return;
	}

	for (let index = 0; index < sections.length; index++) {
		const section = sections[index];

		const addressPoint: OutlineSectionAddressPoint =
			sectionsType === OutlineHierarchyItemType.Children
				? { childrenIndex: index }
				: { toggleIndex: index };
		const sectionAddress = parentAddress.concat(addressPoint);

		yield { section, address: sectionAddress };

		if (!isChildrenSection(section, sectionAddress)) continue; // Only children sections can nest

		const { children, toggle } = section;
		if (toggle) {
			yield* traverseHierarchy({
				sections: toggle,
				sectionsType: OutlineHierarchyItemType.Toggle,
				parentAddress: sectionAddress,
				depth
			});
		}
		if (children) {
			yield* traverseHierarchy({
				sections: children,
				sectionsType: OutlineHierarchyItemType.Children,
				parentAddress: sectionAddress,
				depth
			});
		}
	}
}

/**
 * Get hierarchy sections addresses where the template is used
 */
export const getTemplateAddresses = (
	template: string,
	hierarchy: OutlineHierarchy,
	depth?: number
): Array<OutlineSectionAddress> =>
	[...traverseHierarchy({ sections: hierarchy, depth })]
		.filter(({ section: { template: sectionTemplate } }) => sectionTemplate === template)
		.map(({ address }) => address);

/**
 * Get hierarchy sections addresses where the template is used under the `toggle` property
 */
export const getToggleTemplateParentsAddresses = (
	template: string,
	hierarchy: OutlineHierarchy,
	depth?: number
): Array<OutlineSectionAddress> =>
	[...traverseHierarchy({ sections: hierarchy, depth })]
		.filter(({ section, address }) =>
			isChildrenSection(section, address)
				? section.toggle?.some(({ template: toggleTemplate }) => toggleTemplate === template)
				: false
		)
		.map(({ address }) => address);
