import React from 'react';

const variantContent = {
	bolt: (
		<polygon points="40.493 30.618 39.979 30.618 35.601 30.618 39.875 23.069 35.836 23.069 31.507 32.804 33.646 32.804 36.333 32.804 31.576 42.431" />
	),
	download: (
		<>
			<rect x="-34.9" y="18.9" width="15.9" height="20.3" />
			<path d="M41,42H30.9c-0.7,0-1.2-0.5-1.2-1.2v-1.7c0-0.7,0.5-1.2,1.2-1.2H41c0.7,0,1.2,0.5,1.2,1.2v1.7C42.3,41.4,41.7,42,41,42z" />
			<path d="m37.4 31.6h-2.8c-0.8 0-1.4-0.6-1.4-1.4v-5.1c0-0.8 0.6-1.4 1.4-1.4h2.8c0.8 0 1.4 0.6 1.4 1.4v5.1c0 0.8-0.7 1.4-1.4 1.4z" />
			<path d="m42 29.6c0-0.1-0.1-0.2-0.2-0.2-0.2-0.2-0.5-0.3-0.8-0.3h-10.1c-0.3 0-0.6 0.1-0.8 0.3l-0.1 0.1c-0.2 0.2-0.3 0.5-0.3 0.8s0.1 0.5 0.2 0.7c0 0.1 0.1 0.1 0.2 0.2l5 5c0.5 0.5 1.3 0.5 1.9 0l5-5c0.1-0.1 0.1-0.2 0.2-0.2 0.1-0.2 0.2-0.4 0.2-0.7-0.1-0.3-0.2-0.5-0.4-0.7z" />
		</>
	),
	headphones: (
		<>
			<path d="m30.814 31.309c-0.22-0.148-0.477-0.225-0.735-0.225-0.166 0-0.334 0.032-0.494 0.097-2.631 1.064-4.332 3.441-4.332 6.057s1.701 4.993 4.332 6.057c0.16 0.065 0.328 0.096 0.494 0.096 0.258 0 0.515-0.076 0.735-0.225 0.363-0.244 0.581-0.653 0.581-1.09v-9.675c0-0.438-0.218-0.847-0.581-1.092" />
			<path d="m42.519 31.181c-0.159-0.065-0.326-0.097-0.493-0.097-0.259 0-0.516 0.076-0.736 0.225-0.363 0.245-0.581 0.654-0.581 1.092v9.675c0 0.437 0.218 0.846 0.581 1.09 0.22 0.149 0.477 0.225 0.736 0.225 0.167 0 0.334-0.031 0.493-0.096 2.631-1.064 4.331-3.441 4.331-6.057s-1.7-4.994-4.331-6.057" />
			<path d="m36 21.799c-7.227 0-13.106 5.942-13.106 13.247 0 0.029 2e-3 2.981 9e-3 3.166 0.019 0.496 0.424 0.885 0.931 0.885 0.524-0.017 0.924-0.433 0.91-0.948-4e-3 -0.174-8e-3 -2.789-8e-3 -3.103 0-6.288 5.053-11.404 11.264-11.404s11.264 5.116 11.264 11.404c0 0.626-0.016 3.313-0.036 3.592-0.04 0.506 0.338 0.951 0.857 0.993l0.042 1e-3c0.487 0 0.898-0.373 0.936-0.848 0.034-0.419 0.043-3.706 0.043-3.738 0-7.305-5.879-13.247-13.106-13.247" />
			<path d="m39.715 37.789l-0.089-0.028-0.044-0.082c-0.098-0.185-0.203-0.55-0.301-0.91-0.261-0.955-0.496-1.631-1.133-1.631-0.639 0-0.873 0.676-1.134 1.631-0.029 0.105-0.057 0.206-0.086 0.309l-0.22 0.756-0.299-1.037c-0.267-0.983-0.502-1.659-1.14-1.659-0.637 0-0.871 0.676-1.132 1.632-0.025 0.088-0.054 0.197-0.086 0.305l-0.221 0.755-0.219-0.755c-0.031-0.109-0.06-0.217-0.083-0.306-0.262-0.955-0.496-1.631-1.134-1.631-0.238 0-0.43 0.193-0.43 0.429 0 0.189 0.121 0.354 0.301 0.41l0.089 0.028 0.044 0.083c0.099 0.184 0.203 0.55 0.3 0.909 0.262 0.955 0.496 1.632 1.134 1.632s0.872-0.677 1.133-1.633l0.302-1.07 0.221 0.764c0.033 0.11 0.062 0.217 0.086 0.307 0.261 0.955 0.495 1.632 1.134 1.632 0.638 0 0.872-0.677 1.134-1.632 0.028-0.106 0.057-0.207 0.086-0.31l0.221-0.751 0.304 1.059c0.261 0.957 0.495 1.634 1.134 1.634 0.236 0 0.43-0.194 0.43-0.43 0-0.188-0.121-0.353-0.302-0.41" />
		</>
	),
	link: (
		<>
			<path d="m40.843 37.316c0.374-0.373 0.373-0.98 0-1.354l-8.333-8.334c-0.181-0.18-0.421-0.28-0.677-0.28s-0.497 0.1-0.678 0.28c-0.18 0.181-0.28 0.421-0.28 0.678 0 0.256 0.1 0.497 0.28 0.678l8.334 8.332c0.361 0.362 0.994 0.361 1.354 0" />
			<path d="m31.784 30.994l-4.537-4.537c-0.277-0.277-0.278-0.746-1e-3 -1.025l1.714-1.711c0.269-0.27 0.753-0.27 1.023-1e-3l4.537 4.538c0.139 0.138 0.21 0.329 0.201 0.534l1.655 1.656c0.824-1.156 0.707-2.73-0.305-3.741l-4.535-4.537c-0.55-0.551-1.284-0.854-2.064-0.854-0.781 0-1.514 0.303-2.064 0.854l-1.712 1.712c-0.551 0.55-0.853 1.282-0.853 2.063 0 0.78 0.303 1.513 0.854 2.064l4.536 4.536c0.545 0.545 1.271 0.845 2.044 0.845 0.609 0 1.204-0.191 1.698-0.541l-1.657-1.656c-0.211 0.012-0.397-0.062-0.534-0.199" />
			<path d="m46.303 36.938l-4.536-4.537c-0.546-0.546-1.272-0.846-2.045-0.846-0.608 0-1.203 0.191-1.697 0.541l1.655 1.656c0.203-0.015 0.397 0.063 0.535 0.199l4.537 4.539c0.135 0.134 0.209 0.314 0.209 0.51 1e-3 0.196-0.074 0.377-0.209 0.512l-1.713 1.713c-0.269 0.269-0.754 0.27-1.024-2e-3l-4.536-4.534c-0.139-0.142-0.21-0.332-0.2-0.536l-1.656-1.655c-0.823 1.155-0.707 2.73 0.305 3.742l4.536 4.536c0.55 0.55 1.283 0.852 2.064 0.853 0.78 0 1.513-0.303 2.063-0.853l1.713-1.712c1.138-1.138 1.137-2.989-1e-3 -4.126" />
		</>
	),
	owl: (
		<>
			<path d="m39.232 33.71c-0.555 0-1.078-0.131-1.547-0.358l-1.684 2.067-1.687-2.068c-0.469 0.228-0.992 0.359-1.547 0.359-1.963 0-3.559-1.597-3.559-3.559 0-1.963 1.596-3.56 3.559-3.56 1.434 0 2.669 0.854 3.233 2.079 0.563-1.225 1.798-2.079 3.232-2.079 1.963 0 3.56 1.597 3.56 3.56 0 1.962-1.597 3.559-3.56 3.559zm4.186-11.285c-4.315 3.567-10.549 3.577-14.875 0.032v11.719c0 4.12 3.336 7.459 7.458 7.459h7.456v-19.21h-0.039z" />
			<path d="m33.215 28.863c-0.948 0-1.722 0.772-1.722 1.723 0 0.948 0.774 1.721 1.722 1.721 0.951 0 1.724-0.773 1.724-1.721 0-0.951-0.773-1.723-1.724-1.723" />
			<path d="m38.772 28.863c-0.951 0-1.724 0.772-1.724 1.723 0 0.948 0.773 1.721 1.724 1.721 0.949 0 1.721-0.773 1.721-1.721 0-0.951-0.772-1.723-1.721-1.723" />
		</>
	),
	play: <polyline points="32.477 25.449 39.778 32.75 32.477 40.052" />,
	'star-ribbon': (
		<>
			<polygon points="36 24.425 37.054 27.668 40.464 27.668 37.705 29.673 38.759 32.916 36 30.912 33.241 32.916 34.295 29.673 31.536 27.668 34.946 27.668" />
			<path d="m40.324 39.081l-0.809 1.863-2.591-4.488c1.123-0.177 2.161-0.61 3.055-1.236l2.363 4.092-2.018-0.231zm-8.648 0l-2.018 0.231 2.237-3.875c0.932 0.581 2.002 0.96 3.149 1.074l-2.559 4.433-0.809-1.863zm-1.883-9.888c0-3.298 2.683-5.98 5.98-5.98s5.98 2.682 5.98 5.98c0 3.297-2.683 5.98-5.98 5.98s-5.98-2.683-5.98-5.98zm11.25 5.121c1.289-1.325 2.084-3.131 2.084-5.121 0-4.055-3.299-7.354-7.354-7.354s-7.354 3.299-7.354 7.354c0 2.135 0.915 4.06 2.374 5.405l-3.69 6.39 3.718-0.426 1.489 3.432 3.69-6.39 3.689 6.39 1.49-3.432 3.717 0.426-3.853-6.674z" />
		</>
	)
};

export default function ComputerIcon(props) {
	return (
		<svg viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<g fill={props.color}>
				<path d="M55.7,44.8c0,0.7-0.6,1.2-1.2,1.2h-37c-0.7,0-1.2-0.6-1.2-1.2V20.1c0-0.7,0.6-1.2,1.2-1.2h37c0.7,0,1.2,0.6,1.2,1.2V44.8z M60,19.2c0-2.7-2.2-4.8-4.8-4.8H16.7c-2.7,0-4.8,2.2-4.8,4.8v31.3H60V19.2z M40.8,53c0,0.7-0.5,1.2-1.2,1.2h-7.2 c-0.7,0-1.2-0.5-1.2-1.2H7.1v2.4c0,1.3,1.1,2.2,2.4,2.2h53c1.3,0,2.4-0.9,2.4-2.2V53H40.8z" />
				{props.variant ? variantContent[props.variant] : null}
			</g>
		</svg>
	);
}
