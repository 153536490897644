import React, { useEffect, useRef, useState } from 'react';

import { css, ClassNames } from '@emotion/react';

import { getRelativeTimePhrase } from '~/hooks';
import { breakpoints, ThemeName } from '~/styles/themes';

const REFRESH_INTERVAL = 60 * 1000; // 1 minute

/**
 * If we receive `offline` as a prop and it is true we can make a fair assumption that we are
 * on a mobile device.
 */
interface Props {
	postedAt?: string;
	updatedAt: string;
	saving: boolean;
	posting: boolean;
	unposting: boolean;
	offline?: boolean;
	className?: string;
	suppressAria?: boolean;
}

export const getAnswerStatusText = (props: Props): string => {
	const { postedAt = '', updatedAt, saving, posting, unposting, offline } = props;

	if (saving) {
		return 'Saving...';
	}

	if (posting) {
		return 'Posting...';
	}

	if (unposting) {
		return 'Unposting...';
	}

	if (postedAt) {
		return `Posted ${getRelativeTimePhrase(postedAt)}.`;
	}

	if (updatedAt) {
		if (offline) {
			return 'Draft saved in app.';
		}

		return `Saved ${getRelativeTimePhrase(updatedAt)}.`;
	}

	return 'No response saved yet.';
};

export const AnswerStatus: React.FC<Props> = (props: Props) => {
	const latestProps = useRef(props);
	const [statusText, setStatusText] = useState(getAnswerStatusText(props));
	const [ariaRole, setAriaRole] = useState<'none' | 'status'>('status');
	const [ariaLive, setAriaLive] = useState<'off' | 'assertive'>('assertive');

	useEffect(() => {
		latestProps.current = props;
		setStatusText(getAnswerStatusText(props));
	});

	/**
	 * This effect needs to react to `props` changes so it can generate the correct answer status
	 * string.  Each time `props` change the return function of this hook will clear the current
	 * interval so there is no over lap.  We need the interval here to have live updating after
	 * the answer status changes.
	 */
	useEffect(() => {
		const interval = setInterval(() => {
			if (!latestProps.current.suppressAria) {
				setAriaRole('none');
				setAriaLive('off');
			}
			setStatusText(getAnswerStatusText(latestProps.current));
		}, REFRESH_INTERVAL);
		return () => clearInterval(interval);
	}, []);

	return (
		<ClassNames>
			{({ cx }) => (
				<div
					data-testid="answer-status"
					className={cx('answer-status', props.className)}
					css={styles}
					{...(!props.suppressAria ? { role: ariaRole, 'aria-live': ariaLive } : {})}>
					{statusText}
				</div>
			)}
		</ClassNames>
	);
};

const styles = (theme) => {
	const { answerStatus } = theme.webtextQuestion;
	return css`
		flex: 1;
		font-size: ${answerStatus.fontSize};
		line-height: ${answerStatus.lineHeight};
		color: ${answerStatus.color};

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			font-size: 16px;
			line-height: 1.6;
			font-family: ${theme.fonts['haas-grotesk-all']};

			@media (max-width: ${breakpoints.small}) {
				font-size: 12px;
			}
		`}
	`;
};
