import { useEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement } from '@lexical/utils';
import {
	$createParagraphNode,
	$insertNodes,
	$isRootOrShadowRoot,
	COMMAND_PRIORITY_EDITOR,
	createCommand,
	LexicalCommand
} from 'lexical';

import { CitationResponseAttributes } from '../../../types';
import { $createCitationNode, CitationNode } from '../nodes/CitationNode';

interface CommandPayload {
	decorationText: string;
	identifier: string;
	key: string;
	attributes: CitationResponseAttributes;
}

export const INSERT_CITATION_COMMAND: LexicalCommand<CommandPayload> = createCommand();

export function CitationsPlugin(): JSX.Element | null {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([CitationNode])) {
			throw new Error('CitationsPlugin: CitationNode not registered on editor');
		}

		return editor.registerCommand<CommandPayload>(
			INSERT_CITATION_COMMAND,
			(payload) => {
				const { decorationText, identifier, key, attributes } = payload;
				const citationNode = $createCitationNode(identifier, decorationText, key, attributes);

				$insertNodes([citationNode]);

				if ($isRootOrShadowRoot(citationNode.getParentOrThrow())) {
					$wrapNodeInElement(citationNode, $createParagraphNode).selectEnd();
				}

				return true;
			},
			COMMAND_PRIORITY_EDITOR
		);
	}, [editor]);

	return null;
}
