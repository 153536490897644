import React from 'react';

import styles from './styles';

interface Props {
	value?: string;
	id?: string;
	role?: string;
}

const Instruction: React.FC<Props> = ({ value, ...rest }) => {
	return value ? (
		<div {...rest} css={styles}>
			{value}
		</div>
	) : (
		<div {...rest} css={styles} />
	);
};

export default Instruction;
