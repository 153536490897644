import React from 'react';

import { css } from '@emotion/react';

interface Props {
	referenceAnswerContent: string;
}

const ReferenceAnswer: React.FC<Props> = (props: { referenceAnswerContent: string }) => (
	<div
		css={styles}
		className="reference-answer"
		dangerouslySetInnerHTML={{ __html: props.referenceAnswerContent }}
	/>
);

const styles = (theme) => {
	return css`
		font-family: 'Helvetica Neue';
		margin: 0.5em 0 0.5em 0;
		font-size: ${theme.shortAnswer.referenceAnswer.fontSize};
		line-height: ${theme.shortAnswer.referenceAnswer.lineHeight};
		color: ${theme.shortAnswer.referenceAnswer.color};
		font-weight: ${theme.shortAnswer.referenceAnswer.fontWeight};
	`;
};

export default ReferenceAnswer;
