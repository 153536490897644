import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIsMounted } from 'usehooks-ts';

import { useAriaLiveAnnouncer } from '~/components/AriaLiveAnnouncer';
import { useAccessibilityFocus, UseAccessibilityFocusReturnType } from '~/hooks';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { getAttemptsRemaining } from '~/utils/policy';

import useSpeedbump from '../speedbump/useSpeedbump';
import { getCurrentPoolItem } from '../utils';

import type { SpeedbumpInterventionsProps } from '../speedbump/SpeedbumpInterventions';
import type { DisplayedQuestionElementProps, StudentViewElementProps } from '../types';
import type { AxiosError } from 'axios';
import type { MCQuestionAnswer, MCQuestionCorrectChoice } from '~/types';
import type { FamilyId, MCQuestionPoolItem } from '~/types/WebtextManifest';

export interface UseStudentViewProvided {
	activeQuestion: MCQuestionPoolItem;
	answer: MCQuestionAnswer | null;
	selectedChoiceFamilyId: FamilyId | null;
	isRequestInProgress: boolean;
	resetCount: number;
	rejoinderRef: UseAccessibilityFocusReturnType[0];
	handleChangeSelectedChoice: (choiceFamilyId: FamilyId) => void;
	handleReset: () => void;
	handleSave: () => void;
	correctChoice?: MCQuestionCorrectChoice;
}

/**
 * Custom hook for both standalone and decked student view MCQPs.
 *
 * Handles things like
 * - determining the active pool item (and updating it after a reset)
 * - tracking selected choice
 * - tracking whether a request is in progress
 * - accessibility behaviors after saving/resetting
 *
 * The `handleReset` and `handleSave` functions returned by this hook are "decorated" versions of the `onReset` and
 * `onSave` functions in `StudentViewElementProps`; they will call `onReset` and `onSave` inside, but also perform
 * some accessibility updates like announcements and focus updating.
 */
export const useStudentView = (
	props: StudentViewElementProps & DisplayedQuestionElementProps,
	setFocusAfterReset: UseAccessibilityFocusReturnType[1]
): UseStudentViewProvided & {
	speedbumpInterventionsProps: SpeedbumpInterventionsProps;
} => {
	const {
		onSave,
		onReset,
		questionPool,
		userId,
		answer,
		resetCount,
		attemptsAllowed,
		onChangeSelectedChoice,
		onVisibilityChange
	} = props;
	const isMounted = useIsMounted();
	const [selectedChoiceFamilyId, setSelectedChoiceFamilyId] = useState<FamilyId | null>(
		answer?.body ?? null
	);
	useEffect(() => {
		setSelectedChoiceFamilyId(answer?.body ?? null);
	}, [answer?.body]);
	const [saveResponseCorrectChoice, setSaveResponseCorrectChoice] =
		useState<MCQuestionCorrectChoice | null>(null);
	const activeQuestion = useMemo(
		() =>
			getCurrentPoolItem({
				element: questionPool,
				userId,
				numResets: resetCount
			}),
		[questionPool, resetCount, userId]
	);
	const [rejoinderRef, setFocusToRejoinder] = useAccessibilityFocus();
	const [isRequestInProgress, setRequestInProgress] = useState(false);
	const { makeAssertiveAnnouncement } = useAriaLiveAnnouncer();
	const isDirty = selectedChoiceFamilyId && selectedChoiceFamilyId !== answer?.body;
	useUnsavedChangesWarning(isDirty);
	const { notifySpeedbumpReset, notifySpeedbumpSaved, ...speedbumpInterventionsProps } =
		useSpeedbump(props);

	const handleReset = useCallback(async () => {
		if (isRequestInProgress || answer == null) {
			return;
		}

		setRequestInProgress(true);
		try {
			await onReset({
				questionPoolFamilyId: questionPool.family_id,
				questionFamilyId: activeQuestion.family_id
			});
			const newQuestion = getCurrentPoolItem({
				element: questionPool,
				userId,
				numResets: resetCount + 1
			});
			onVisibilityChange?.({
				familyId: newQuestion.family_id,
				scope: 'try_again',
				collapsed: false
			});
			setSelectedChoiceFamilyId(null);
			notifySpeedbumpReset();
			await setFocusAfterReset();
		} catch {
			(window.Rollbar ?? console).warn('MCQP reset failed');
		} finally {
			setRequestInProgress(false);
		}
	}, [
		activeQuestion.family_id,
		answer,
		isRequestInProgress,
		notifySpeedbumpReset,
		onReset,
		onVisibilityChange,
		questionPool,
		resetCount,
		setFocusAfterReset,
		userId
	]);

	const handleSave = useCallback(async () => {
		if (isRequestInProgress || answer != null || selectedChoiceFamilyId == null) {
			return;
		}

		let newAnswer: MCQuestionAnswer;
		setRequestInProgress(true);
		try {
			const response = await onSave({
				choiceFamilyId: selectedChoiceFamilyId,
				questionFamilyId: activeQuestion.family_id,
				questionPoolFamilyId: questionPool.family_id
			});
			const { answer: newAnswer, correctChoice: newCorrectChoice } = response;
			setSaveResponseCorrectChoice(newCorrectChoice);
			makeAssertiveAnnouncement(
				`Answer saved. ${newAnswer.correct ? 'Correct.' : 'Incorrect.'} ${newAnswer.rejoinder}`
			);
			setFocusToRejoinder().then(() => {
				notifySpeedbumpSaved(newAnswer.correct);
			});
		} catch (e) {
			if ((e as AxiosError).response?.status === 409) {
				// do nothing, since this is an expected error
			} else {
				(window.Rollbar ?? console).error(e);
			}
		} finally {
			if (isMounted()) {
				setRequestInProgress(false);
			}
		}
		return newAnswer;
	}, [
		activeQuestion.family_id,
		answer,
		isMounted,
		isRequestInProgress,
		makeAssertiveAnnouncement,
		notifySpeedbumpSaved,
		onSave,
		questionPool.family_id,
		selectedChoiceFamilyId,
		setFocusToRejoinder
	]);

	const handleChangeSelectedChoice = useCallback(
		(choiceFamilyId: FamilyId) => {
			setSelectedChoiceFamilyId(choiceFamilyId);
			onChangeSelectedChoice?.(choiceFamilyId);
		},
		[onChangeSelectedChoice]
	);

	const isFinalAttempt =
		getAttemptsRemaining({
			attemptsAllowed,
			resetCount,
			isAnswered: answer != null
		}) === 0;
	const correctChoice = isFinalAttempt
		? activeQuestion.correctChoice ?? saveResponseCorrectChoice
		: null;

	return {
		activeQuestion,
		answer,
		selectedChoiceFamilyId,
		isRequestInProgress,
		resetCount,
		rejoinderRef,
		handleChangeSelectedChoice,
		handleReset,
		handleSave,
		correctChoice,
		speedbumpInterventionsProps
	};
};
