import React, { useContext, useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { GrFormClose } from 'react-icons/gr';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
	$getNodeByKey,
	$getSelection,
	$isNodeSelection,
	NodeKey,
	COMMAND_PRIORITY_HIGH,
	SELECTION_CHANGE_COMMAND,
	KEY_DELETE_COMMAND,
	KEY_SPACE_COMMAND,
	KEY_ENTER_COMMAND
} from 'lexical';

import { CitationResponseAttributes } from '../../../types';
import CitationMetaContext from '../context/CitationMetaContext';
import useSerialTurabianNumber from '../hooks/useSerialTurabianNumber';
import { $isCitationNode } from '../nodes/CitationNode';

interface Props {
	decorationText: string;
	identifier: string;
	ignoreSelection?: true;
	citationKey: string;
	attributes: CitationResponseAttributes;
	nodeKey: NodeKey;
}

const Citation: React.FC<Props> = ({
	decorationText,
	nodeKey,
	identifier,
	attributes,
	citationKey
}) => {
	const [editor] = useLexicalComposerContext();
	const {
		openEditDialog,
		inEditorCitations,
		citationStyle,
		showCitationDialog,
		addCitationDataOnly
	} = useContext(CitationMetaContext);

	const [decorator, setDecorator] = useState(decorationText);
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		const citationData = inEditorCitations.find(({ identifier: idx }) => idx === identifier);

		/**
		 * If citationData is not found it means that we have a new citation copied this instance;
		 */
		if (!citationData) {
			addCitationDataOnly(
				{ identifier, markerValue: decorationText, attributes, key: citationKey },
				nodeKey,
				true
			);
		}
	}, [
		inEditorCitations,
		identifier,
		decorationText,
		nodeKey,
		addCitationDataOnly,
		attributes,
		citationKey
	]);

	const citationTurabianValue = useSerialTurabianNumber(identifier);

	useEffect(() => {
		if (citationTurabianValue && citationTurabianValue !== decorationText)
			setDecorator(citationTurabianValue);
	}, [citationTurabianValue, decorationText]);

	useEffect(() => {
		const citationData = inEditorCitations.find(({ identifier: idx }) => idx === identifier);
		if (!citationData) return;

		const { markerValue } = citationData;
		if (markerValue !== decorationText) setDecorator(markerValue);
	}, [inEditorCitations, decorationText, identifier]);

	useEffect(() => {
		if (showCitationDialog) return;

		if (selected) {
			/**
			 * This is analog of keyPress, but it executes on editor level for node, instead of citation node;
			 * We have this, because keyPress handler may create side effects and ruin user experience;
			 * Moreover it will be easier to handle this for accessibility plugin;
			 */
			return mergeRegister(
				editor.registerCommand(
					SELECTION_CHANGE_COMMAND,
					() => {
						setSelected(false);
						return false;
					},
					COMMAND_PRIORITY_HIGH
				),
				editor.registerCommand(
					KEY_DELETE_COMMAND,
					() => {
						/**
						 * unexpectedly we don't need to exec handleDelete here and citation will be deleted anyway;
						 * I'd like to investigate this on any side effects, but looks good now;
						 * Seems editor able to handle delete by it's own;
						 * More interesting if we delete this citation with "delete" editor will lose a focus;
						 * Looks like a bug, because lexical playground https://playground.lexical.dev/ items has the same behavior;
						 * IMO all of them bugged this way;
						 */
						$getNodeByKey(nodeKey).selectPrevious();
						return true;
					},
					COMMAND_PRIORITY_HIGH
				),
				editor.registerCommand(
					KEY_SPACE_COMMAND,
					() => {
						openEditDialog(identifier);
						return false;
					},
					COMMAND_PRIORITY_HIGH
				),
				editor.registerCommand(
					KEY_ENTER_COMMAND,
					() => {
						/**
						 * Action is identical with KEY_SPACE_COMMAND
						 */
						openEditDialog(identifier);
						return false;
					},
					COMMAND_PRIORITY_HIGH
				)
			);
		}

		return editor.registerUpdateListener(({ editorState }) => {
			const isSelected = editorState.read(() => {
				const selection = $getSelection();
				return (
					$isNodeSelection(selection) && selection.has(nodeKey) && selection.getNodes().length === 1
				);
			});

			if (isSelected) setSelected(true);
		});
	}, [editor, showCitationDialog, nodeKey, selected, identifier, openEditDialog]);

	const handleClick = () => openEditDialog(identifier);

	const handleDelete = () => {
		/**
		 * This also should handle DELETE press! when focus will be set up;
		 */
		editor.update(() => {
			const node = $getNodeByKey(nodeKey);
			if ($isCitationNode(node)) node.remove();
		});
	};

	const bracketsRegex = /\(|\)/g;
	const isCitationWithBrackets = bracketsRegex.test(decorationText);

	/**
	 * We need spacers here to release caret;
	 * Otherwise it will cover the citation visually;
	 * All known RTE (for me) has this bug with custom entities with html elements but text;
	 */
	return (
		<>
			{/**
			 * Android keyboard is affect by this spacer, however it need for desktop browsers, because w/o it
			 * you will be not able to focus input field if the input includes only one citation
			 */}
			{!isAndroid && <span className="spacer"> </span>}
			<span className={`citation ${citationStyle} ${selected ? 'selected' : ''}`} id={identifier}>
				<span className="decorator" role="img" aria-label="begin citation">
					{isCitationWithBrackets ? '(' : ' '}
				</span>
				<span className="decorator" onClick={handleClick}>
					{decorator?.replace(bracketsRegex, '')}
				</span>
				<span className="decorator" role="img" aria-label="end citation">
					{isCitationWithBrackets ? ')' : ' '}
				</span>
				<span
					className="delete-citation"
					role="button"
					aria-label="remove citation"
					onClick={handleDelete}>
					<GrFormClose aria-hidden="true" />
				</span>
			</span>
			{!isAndroid && <span className="spacer"> </span>}
		</>
	);
};

export default Citation;
