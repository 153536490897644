import React, { FC, useMemo } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

import {
	addButton,
	addIcon,
	maxReachedMessage
} from '~/components/Outline/components/SectionView/components/styles';
import { removeDynamicParts } from '~/components/Outline/helpers';
import { OutlineHierarchyItemType } from '~/components/Outline/types';
import WebtextButton from '~/components/WebtextButton/WebtextButton';

interface Props {
	label: string;
	instancesCount: number;
	minInstancesRequired: number;
	maxInstancesAllowed: number;
	sectionType: OutlineHierarchyItemType;
	onAddition: () => void;
}

enum AddButtonVariant {
	/**
	 * Doesn't show the addition button when:
	 * - The `min` === `max` for the template instances definition
	 * - Toggle section is the parent
	 * Example - https://ibb.co/0mBmcr2
	 */
	Hidden,

	/**
	 * Default view when the instances number is lower
	 * than the max instances number for the template
	 * Shows the "+ Add Smth" button
	 * Example - https://ibb.co/1fJw21G
	 */
	AddMore,

	/**
	 * The max instances number defined for the template is reached (`max` should > 1)
	 * Shows "... the maximum num of 'Smth' ..."
	 * Example - https://ibb.co/bW1vy6r
	 */
	MaxInstancesReached
}

const AddButton: FC<Props> = (props) => {
	const {
		label,
		instancesCount,
		sectionType,
		minInstancesRequired,
		maxInstancesAllowed,
		onAddition
	} = props;

	const staticLabel = removeDynamicParts(label);

	const variant = useMemo<AddButtonVariant>(() => {
		if (
			minInstancesRequired === maxInstancesAllowed ||
			sectionType === OutlineHierarchyItemType.Toggle
		) {
			return AddButtonVariant.Hidden;
		}

		return instancesCount < maxInstancesAllowed
			? AddButtonVariant.AddMore
			: AddButtonVariant.MaxInstancesReached;
	}, [instancesCount, maxInstancesAllowed, minInstancesRequired, sectionType]);

	switch (variant) {
		case AddButtonVariant.Hidden:
			return null;
		case AddButtonVariant.AddMore:
			return (
				<WebtextButton css={addButton} onClick={onAddition}>
					<AiOutlinePlus css={addIcon} /> Add {staticLabel}
				</WebtextButton>
			);
		case AddButtonVariant.MaxInstancesReached:
			return (
				<i css={maxReachedMessage}>
					You&rsquo;ve reached the maximum number of &ldquo;{staticLabel}&rdquo; items for this
					section.
				</i>
			);
	}
};

export default AddButton;
