import React from 'react';

import { CitationStyle } from '../../../types';
import { citationHelpStyles } from './styles';

interface Props {
	style: CitationStyle;
}

const CitationHelp: React.FC<Props> = ({ style }) => {
	return (
		<div css={citationHelpStyles}>
			{style === CitationStyle.APA ? <CitationHelpAPA /> : <CitationHelpSWS />}
		</div>
	);
};

const CitationHelpAPA = () => (
	<>
		<h3>
			The standard in-text citation is <strong>(Author, Year)</strong>.
		</h3>
		<table role="table">
			<thead>
				<tr>
					<th scope="col">However, if you included...</th>
					<th scope="col">then use:</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						the <strong>name of the author</strong> in the sentence
					</td>
					<td>(Year)</td>
				</tr>
				<tr>
					<td>
						the <strong>publication year</strong> in the sentence
					</td>
					<td>(Author)</td>
				</tr>
				<tr>
					<td>
						a <strong>direct quote</strong> from a source with page numbers
					</td>
					<td>(Author, Year, p. ___)</td>
				</tr>
				<tr>
					<td>
						the <strong>author and the direct quote</strong> in the sentence
					</td>
					<td>(Year, p. ___)</td>
				</tr>
				<tr>
					<td>
						the <strong>author, year, and the direct quote</strong> in the sentence
					</td>
					<td>(p. ___)</td>
				</tr>
			</tbody>
		</table>
	</>
);

const CitationHelpSWS = () => (
	<p>
		The standard in-text citation is <strong>(Author, Source No.)</strong>. However, if you included
		the name of the author in the sentence, then use <strong>(Source No.)</strong>.
	</p>
);

export default CitationHelp;
