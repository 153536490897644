import { select } from 'd3-selection';

import type { Selection as D3Selection } from 'd3-selection';

export const getYAxisMaxLabelWidth = (
	svg: D3Selection<SVGElement, unknown, null, undefined>
): number => {
	const ticks = svg.selectAll<SVGTextElement, unknown>('.yAxis .tick text');

	let maxLabelWidth = 0;
	ticks.each(function (_) {
		const curr = select(this);
		const currBox = curr.node().getBoundingClientRect();

		if (currBox.width > maxLabelWidth) maxLabelWidth = currBox.width;
	});

	return maxLabelWidth;
};
