import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, Theme, ThemeName } from '~/styles/themes';

export const buttonStyles = css`
	align-items: center;

	@media (max-width: ${breakpoints.small}) {
		margin-bottom: 0;
	}
`;

export const pastDueDateStyles = css`
	width: 100%;
	font-size: 16px;
	font-weight: 400;
	color: #545454;
	text-align: start;
`;

export const promptStyles = css`
	.question-body {
		@media (max-width: ${breakpoints.small}) {
			// !important needed to override some Core-only Sass styles
			font-size: 16px !important;
			line-height: 26px !important;
			margin-bottom: 1.5em !important;
		}
	}

	[data-in-question-deck='true'] & {
		padding: 0;

		.question-body {
			margin-bottom: 0 !important;
		}
	}
`;

export const choicesStyles = (theme: Theme): SerializedStyles => css`
	.question-choices {
		@media (max-width: ${breakpoints.small}) {
			padding-right: 5px;
		}
	}

	[data-in-question-deck='true'] & {
		.question-choices {
			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				padding-top: 0;
			`}
		}
	}
`;
