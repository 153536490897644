import React, { useEffect, useState } from 'react';

import { ClassNames } from '@emotion/react';
import Snackbar from '@material-ui/core/Snackbar';
import cn from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { useIsMounted } from 'usehooks-ts';

import { SAQuestionIcon } from '~/components/icons';
import { Offline } from '~/components/Offline';
import { UniversalVelvetLeftBorder } from '~/components/pageElements';
import {
	QuestionType,
	QuestionPrompt,
	AnswerStatus,
	WebtextQuestion,
	ValidationStatus,
	SectionTitle,
	QuestionApi,
	ReferenceAnswer
} from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import {
	useTabSync,
	SetAnswerDraftArgs,
	useAutoSave,
	SaveValueArgs,
	useIsUniversalVelvet,
	useAccessibilityFocus
} from '~/hooks';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { QuestionAnswer } from '~/types';

import styles from './styles';

const FORCED_WORD_BREAK_REGEX = new RegExp(
	[
		String.raw`([-\/:.$_+!*'()<>,;?@="#%{}|^~\[\]\`]|`, // wbr on symbols
		`[0-9](?=[a-zA-Z])|`, // wbr if number followed by letter
		`[a-zA-Z](?=[0-9])|`, // wbr if letter followed by number
		`[a-z](?=[A-Z])|`, // wbr if lowercase letter followed by uppercase letter
		`(?=[A-Z][a-z]))` // wbr if uppercase letter followed by lowercase letter
	].join(''),
	'g'
);

const addForcedLineBreaks = (answer: string): string =>
	answer
		?.split(' ')
		?.map((word) => {
			if (word.includes('http') || word.length > 34) {
				return word.replace(FORCED_WORD_BREAK_REGEX, '$1\u200B'); // \u200B is the zero-width space
			}
			return word;
		})
		?.join(' ') || '';

export interface SAQProps {
	userId: string;
	questionId: string;
	body: string;
	answer: Partial<QuestionAnswer>;
	referenceAnswer: string;
	api?: QuestionApi;
	saving?: boolean;
	posting?: boolean;
	unposting?: boolean;
	online: boolean;
	showUnpostModal?: () => void;
	// Validation
	hasValidation?: boolean;
	validationMessage: string;
	// Auto-Save / Flexible answer draft handling
	answerDraft?: string;
	setAnswerDraft?: (args: SetAnswerDraftArgs) => void;
	autoSave?: boolean;
	autoSaveDelay?: number;
	// Editability
	editable?: boolean;
	// Tab-Sync
	tabSync?: boolean;
	setTabHasFocus?: (value: boolean) => void;
	mobile?: boolean;
	readOnly?: boolean;
	postFailed?: boolean;
	updatedAt?: string;
	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
}

const ShortAnswerQuestion: React.FC<SAQProps> = (props: SAQProps) => {
	const {
		questionId,
		body,
		answer,
		referenceAnswer,
		api,
		saving,
		posting,
		unposting,
		online,
		showUnpostModal,
		hasValidation,
		validationMessage,
		autoSave,
		autoSaveDelay,
		editable,
		tabSync,
		setTabHasFocus,
		mobile,
		readOnly,
		postFailed,
		updatedAt
	} = props;
	const [snackbarVisible, setSnackbarVisible] = useState(false);
	const hideSnackbar = () => setSnackbarVisible(false);
	const [userActed, setUserActed] = useState(false);
	const [headingRef, setFocusToHeading] = useAccessibilityFocus();
	const [textareaRef, setFocusToTextarea] = useAccessibilityFocus();
	const isUniversalVelvet = useIsUniversalVelvet();
	const [localAnswerDraft, setLocalAnswerDraft] = useState(answer.body);
	const isMounted = useIsMounted();

	const promptId = `sa_question_body_${questionId}`;

	useEffect(() => {
		setSnackbarVisible(postFailed);
	}, [postFailed]);

	useEffect(() => {
		if (isMounted() && userActed && !unposting && answer?.posted_at == null) {
			// the user just unposted, so we should focus the textarea
			setFocusToTextarea();
		}
	}, [answer?.posted_at, isMounted, setFocusToTextarea, unposting, userActed]);

	const save = () => {
		api.onSave(answerDraft()).then(() => setFocusToTextarea());
	};

	const post = () => {
		api.onPost(answerDraft()).then(() => setFocusToHeading());
	};

	const unpost = () => {
		setUserActed(true);
		showUnpostModal();
	};

	const answerDraft = () => {
		if (props.setAnswerDraft) {
			return props.answerDraft;
		} else {
			return localAnswerDraft;
		}
	};

	const setAnswerDraft = (args: SetAnswerDraftArgs) => {
		if (props.setAnswerDraft) {
			return props.setAnswerDraft(args);
		} else {
			return setLocalAnswerDraft(args.value);
		}
	};

	// If we got a new answer from props (e.g., api request has been finished), we want the local draft to reflect the newer answer.
	// Can be used only for simple cases when we use the component just as the visual representation
	useEffect(() => {
		if (!readOnly) return;

		const answerBody = props.answer?.body;
		if (!answerBody || answerBody === localAnswerDraft) return;

		setLocalAnswerDraft(answerBody);
	}, [readOnly, localAnswerDraft, props.answer?.body, setLocalAnswerDraft]);

	const isPosted = Boolean(answer.posted_at);
	// dirty if they aren't equal and one of them has some kind of value
	const isDirty =
		answerDraft() !== answer.body && !(isEmpty(answerDraft()) && isEmpty(answer.body));
	useUnsavedChangesWarning(isDirty);

	const postingAllowed = !hasValidation || online;
	const isPostable =
		!posting && answerDraft() && answerDraft().length > 0 && !isPosted && postingAllowed;

	const renderButtons = () => {
		if (!isPosted) {
			if (autoSave) {
				return (
					<WebtextButton
						id={`saq-${questionId}-post`}
						onClick={() => post()}
						disabled={readOnly || !isPostable || !postingAllowed}>
						Post
					</WebtextButton>
				);
			} else {
				return (
					<span className="button-group">
						<WebtextButton
							secondary={isUniversalVelvet}
							onClick={() => save()}
							disabled={readOnly || saving || answerDraft() === answer.body}>
							Save Draft
						</WebtextButton>
						<WebtextButton
							onClick={() => post()}
							disabled={
								readOnly || posting || (!updatedAt && !answer.updated_at) || !postingAllowed
							}>
							Submit
						</WebtextButton>
					</span>
				);
			}
		}

		if (editable) {
			return (
				<WebtextButton
					key={`saq-${questionId}-unpost`}
					disabled={readOnly || unposting}
					onClick={() => unpost()}>
					Unpost &amp; Edit
				</WebtextButton>
			);
		}
	};

	return (
		<ClassNames>
			{({ cx }) => (
				<>
					<WebtextQuestion noBottomMargin={props.noBottomMargin}>
						<UniversalVelvetLeftBorder>
							<QuestionType ref={headingRef}>
								{isUniversalVelvet && <SAQuestionIcon />}
								Short-answer Question
							</QuestionType>
							<QuestionPrompt body={body} id={promptId} />
							<div className="question" css={styles}>
								{!isPosted && (
									<textarea
										ref={textareaRef}
										key="answer-input"
										aria-labelledby={promptId}
										className={cn({
											answer: true,
											invalid: validationMessage,
											'changed-input': isDirty
										})}
										value={answerDraft()}
										disabled={readOnly || isPosted || posting}
										onChange={(e) => {
											setAnswerDraft({
												value: e.target.value,
												sync: true,
												save: true,
												saveDelay: autoSaveDelay || 5000
											});
										}}
										onFocus={() => {
											if (tabSync) {
												setTabHasFocus(true);
											}
										}}
										onBlur={() => {
											setAnswerDraft({ value: answerDraft(), save: true, saveDelay: 1000 });
											if (tabSync) {
												setTabHasFocus(false);
											}
										}}
										data-testid="sa-question-input"
									/>
								)}
								<div className={cx('answer-posted', { posted: isPosted })}>
									{isPosted && addForcedLineBreaks(answerDraft())}
								</div>
								<ValidationStatus>{validationMessage}</ValidationStatus>
								<div className="status-and-actions">
									<AnswerStatus
										className={!isPosted && 'unposted'}
										postedAt={answer.posted_at}
										updatedAt={updatedAt || answer.updated_at}
										posting={posting}
										saving={saving}
										unposting={unposting}
										offline={!online}
										suppressAria
									/>
									{renderButtons()}
								</div>
								{!postingAllowed && !isPosted && (
									<Offline
										mobile={mobile}
										standalone={false}
										elementName="Posting"
										interactionVerb="post"
									/>
								)}

								<div>
									{isPosted && referenceAnswer ? (
										<>
											<div className="divider" />
											<div className="reference-answer-section">
												<SectionTitle role="heading" ariaLevel={3}>
													Reference Answer
												</SectionTitle>
												<ReferenceAnswer referenceAnswerContent={referenceAnswer} />
											</div>
										</>
									) : null}
								</div>
							</div>
						</UniversalVelvetLeftBorder>
					</WebtextQuestion>
					<Snackbar
						open={snackbarVisible}
						autoHideDuration={6000}
						onClose={hideSnackbar}
						message="An error occurred while sending your answer. Please try again."
						action={<button onClick={hideSnackbar}>close</button>}
					/>
				</>
			)}
		</ClassNames>
	);
};

export const ShortAnswerQuestionSynced: React.FC<SAQProps> = (props) => {
	const { tabSync, autoSave, posting } = props;

	const [tabHasFocus, setTabHasFocus] = useState(false);

	/**
	 * Temporary solution to fix the overriding of the local answer with the props.answer
	 * https://soomo.height.app/T-40308
	 */
	const [answerDraftUpdatedAt, setAnswerDraftUpdatedAt] = useState<number>(-1);

	const [answerDraft, setAutoSaveAnswerDraft] = useAutoSave({
		value: props.answer.body,
		onSave: props.api.onSave,
		posting
	});

	const setAnswerDraft = (saveValue: SaveValueArgs) => {
		setAnswerDraftUpdatedAt(Date.now());
		setAutoSaveAnswerDraft(saveValue);
	};

	// If we got a new answer from elsewhere (e.g., a newer web answer overwrites a stale mobile answer),
	// we want the draft to reflect the newer answer.
	useEffect(() => {
		const isPropsAnswerLater = new Date(props.answer.updated_at).getTime() > answerDraftUpdatedAt;

		if (props.answer.body !== answerDraft && isPropsAnswerLater) {
			setAnswerDraft({ value: props.answer.body, save: false, saveDelay: 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.answer.body]);

	/**
	 * todo: This should probably get refactored into one setState call that happens regardless
	 * of whether tabSync or autoSave are turned on. By setting the state /first/ and then calling
	 * side effects we will reduce bugs that can arise in different combinations of autoSave: on/off and tabSync: on/off
	 */
	const [tabSyncAnswerDraft, setTabSyncAnswerDraft] = useState<any>(null);
	const { questionState } = useTabSync({
		props: props,
		syncedKeys: [
			'answer',
			'referenceAnswer',
			'saving',
			'posting',
			'unposting',
			'validationMessage'
		],
		answerDraft: autoSave ? answerDraft : tabSyncAnswerDraft?.value,
		setAnswerDraft,
		tabHasFocus
	});

	let finalProps = { ...props, setTabHasFocus };

	if (props.autoSave) {
		finalProps = { ...finalProps, answerDraft, setAnswerDraft };
	}

	if (tabSync) {
		finalProps = {
			...finalProps,
			...questionState
		};

		if (!autoSave) {
			finalProps.setAnswerDraft = setTabSyncAnswerDraft;
		}
	}

	return <ShortAnswerQuestion {...finalProps} />;
};

export default ShortAnswerQuestion;
