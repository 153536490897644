import React from 'react';

import FirstInterventionModal from './FirstInterventionModal';
import SecondInterventionModal from './SecondInterventionModal';
import ThirdInterventionModal from './ThirdInterventionModal';

import type { InterventionSurveyData } from '../types';

export interface SpeedbumpInterventionsProps {
	isFirstInterventionOpen: boolean;
	isSecondInterventionOpen: boolean;
	isThirdInterventionOpen: boolean;
	onCloseFirstIntervention: () => void;
	onCloseSecondIntervention: () => void;
	onSubmitThirdIntervention: (data: InterventionSurveyData) => void;
}

const SpeedbumpInterventions: React.VFC<SpeedbumpInterventionsProps> = ({
	isFirstInterventionOpen,
	isSecondInterventionOpen,
	isThirdInterventionOpen,
	onCloseFirstIntervention,
	onCloseSecondIntervention,
	onSubmitThirdIntervention
}) => {
	return (
		<>
			<FirstInterventionModal open={isFirstInterventionOpen} onClose={onCloseFirstIntervention} />
			<SecondInterventionModal
				open={isSecondInterventionOpen}
				onClose={onCloseSecondIntervention}
			/>
			<ThirdInterventionModal open={isThirdInterventionOpen} onSubmit={onSubmitThirdIntervention} />
		</>
	);
};

export default SpeedbumpInterventions;
