import React, { useCallback, useContext, useMemo } from 'react';

import { fromConfigToCitation } from '../EditMode/helpers/citationMapper';
import { getListRef } from '../EditMode/helpers/citationService';
import { TemplateValidationContext } from '../WritingTemplate/providers/TemplateValidationProvider';
import { IDictionary, ValidationItemStatus, ValidationStatus } from '../WritingTemplate/types';
import InlineInput from './components/InlineInput';
import LexicalRTE from './components/LexicalRTE';
import SimpleEditor from './components/SimpleEditor';
import { getMinimalEditorHeight, getWordOptimum } from './helpers';
import { isCitationEnabled } from './helpers/citationsHelper';
import styles from './styles';
import {
	CitationObjectValue,
	CitationResponse,
	EditorSpecificOptions,
	EditorType,
	InputOptions,
	SchemaReferences
} from './types';

interface Props {
	dest: string;
	placeholder: string;
	value: string | CitationObjectValue;
	options: InputOptions;
	schemaReferences: SchemaReferences;
	tabUpdate: IDictionary;
	onChange: (value: string | CitationObjectValue) => void;
	onUserUpdate: () => void;
}

const FillIn: React.FC<Props> = (props) => {
	const { dest, placeholder, value, options, schemaReferences, onChange, onUserUpdate, tabUpdate } =
		props;
	const { validationState } = useContext(TemplateValidationContext);

	const isCitationsEnabled = isCitationEnabled(options.schemaOptions, schemaReferences.toc);
	const isInputAreaFlexible = options.styleOptions.presentation === 'block';
	const isRichText =
		options.schemaOptions?.editor?.type === EditorType.RichText &&
		options.styleOptions.presentation !== 'inline';

	const listRef = useMemo(() => getListRef(options.schemaOptions), [options.schemaOptions]);

	const citationsConfig = useMemo(() => {
		/**
		 * listRef quite important here, to prevent cases when it not yet exist, return
		 */
		if (!isCitationEnabled || !listRef) return;

		return fromConfigToCitation(schemaReferences.toc, listRef, schemaReferences.imports);
	}, [schemaReferences, listRef]);

	const editorOptions = useMemo<EditorSpecificOptions>(
		() => ({
			isAllowMultiParagraph: options.schemaOptions?.editor['allow-paragraphs'],
			allowedLists: options.schemaOptions?.editor['allow-lists'],
			readOnly: options.readOnly
		}),
		[options.schemaOptions?.editor, options.readOnly]
	);

	const minimalEditorHeight = useMemo(() => {
		const wordOptimumValidation = getWordOptimum(options.validation);
		if (!wordOptimumValidation) return 0;

		return getMinimalEditorHeight(wordOptimumValidation);
	}, [options.validation]);

	const validationMessage = useMemo(() => {
		if (validationState?.status !== ValidationStatus.Failure) return;

		const { validations } = validationState;
		const validation = validations[dest];

		if (validation?.status !== ValidationItemStatus.Failure) return;

		const { message } = validation;

		return message;
	}, [validationState, dest]);

	/**
	 * Once we get a citations object where string expected
	 * Transform and return string value.
	 */
	const fromCitationsObjectToString = () => (typeof value === 'string' ? value : value.text || '');

	const fromStringToCitationsObject = () => {
		if (typeof value === 'string') {
			return {
				kind: 'fill-in-with-citations',
				text: value,
				'source-list-ref': listRef,
				citations: []
			};
		}

		return value;
	};

	const updateOldStyleIdentifier = (editorValue: CitationObjectValue) => {
		if (!editorValue?.citations?.length) {
			return editorValue;
		}

		const { citations } = editorValue;

		const citationsUpdated = citations.map(({ identifer, ...rest }) =>
			identifer ? { identifier: identifer, ...rest } : rest
		);

		return {
			...editorValue,
			citations: citationsUpdated
		};
	};

	const renderInlineInput = useCallback(() => {
		const editorValue = fromCitationsObjectToString();
		const isInvalid = Boolean(validationMessage);

		return (
			<InlineInput
				dest={dest}
				value={editorValue}
				isInvalid={isInvalid}
				label={options.label}
				placeholder={placeholder}
				readOnly={options.readOnly}
				onChange={onChange}
				onUserUpdate={onUserUpdate}
			/>
		);
	}, [options.label, options.readOnly, dest, validationMessage, placeholder]);

	const renderSimpleInput = useCallback(() => {
		const editorValue = fromCitationsObjectToString();

		return (
			<SimpleEditor
				dest={dest}
				label={options.label}
				placeholder={placeholder}
				readOnly={options.readOnly}
				minimalEditorHeight={minimalEditorHeight}
				isAreaMultiLine={isInputAreaFlexible}
				withinTable={options.styleOptions?.isTable}
				validationMessage={validationMessage}
				value={editorValue}
				onChange={onChange}
				onUserUpdate={onUserUpdate}
			/>
		);
	}, [
		dest,
		options.label,
		options.readOnly,
		options.styleOptions?.isTable,
		placeholder,
		minimalEditorHeight,
		isInputAreaFlexible,
		validationMessage
	]);

	const renderRTEWithCitations = useCallback(() => {
		const editorValue = fromStringToCitationsObject();
		const modernEditorValue = updateOldStyleIdentifier(editorValue);
		const { text: storedValue, citations: storedCitations } = modernEditorValue;

		const handleChange = (value: string, citationsList?: CitationResponse[]) => {
			onChange({
				citations: citationsList,
				kind: 'fill-in-with-citations',
				['source-list-ref']: listRef,
				text: value
			});
		};

		return (
			<LexicalRTE
				withCitations
				dest={dest}
				value={storedValue}
				handleChange={handleChange}
				editorOptions={editorOptions}
				validationMessage={validationMessage}
				placeholder={placeholder}
				onUserUpdate={onUserUpdate}
				minimalEditorHeight={minimalEditorHeight}
				citationsConfig={citationsConfig}
				storedCitations={storedCitations}
			/>
		);
	}, [
		dest,
		listRef,
		citationsConfig,
		minimalEditorHeight,
		options.readOnly,
		options.schemaOptions,
		validationMessage,
		placeholder
	]);

	const renderSimpleRTE = useCallback(() => {
		const editorValue = fromCitationsObjectToString();
		return (
			<LexicalRTE
				dest={dest}
				value={editorValue}
				minimalEditorHeight={minimalEditorHeight}
				handleChange={onChange}
				editorOptions={editorOptions}
				validationMessage={validationMessage}
				placeholder={placeholder}
				onUserUpdate={onUserUpdate}
			/>
		);
	}, [dest, minimalEditorHeight, validationMessage, editorOptions, placeholder]);

	const component = useMemo(() => {
		if (isRichText) {
			/**
			 * Show old draft js editor when citation turned on
			 */
			if (isCitationsEnabled) {
				return renderRTEWithCitations();
			}
			/**
			 * Show lexical when turned off
			 */
			return renderSimpleRTE();
		}

		if (options.styleOptions.isInlineBox) {
			/**
			 * Inline box makes editor inline.
			 */

			return renderInlineInput();
		}

		return renderSimpleInput();
	}, [
		isRichText,
		isCitationsEnabled,
		/**
		 * We do not allow an editor re-initialize on each render, because this may reduce the performance of the editor
		 * However, we have to re-init the editor on each tab update, which will be received on the tab change.
		 * That's why we have tab updates inside deps.
		 * Actual value is updated already inside EditMode.
		 */
		tabUpdate,
		options.styleOptions.isInlineBox,
		renderInlineInput,
		renderSimpleInput,
		renderRTEWithCitations,
		renderSimpleRTE
	]);

	const wrappedComponent = () => {
		/**
		 * We have this condition because it's possible to define rte inside an inline block (schema)
		 * In this case it might count as inline, but being block element
		 */
		const isBlockRepresentation = isRichText || !options.styleOptions.isTable;

		if (!isBlockRepresentation) {
			return component;
		}

		return <div css={styles}>{component}</div>;
	};

	return wrappedComponent();
};

export default FillIn;
