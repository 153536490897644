import React, { FC, useEffect, useRef } from 'react';

import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';
import { ToolConfiguration } from '~/types';

interface Props extends Pick<ToolConfiguration, 'launchURL' | 'launchParams'> {
	familyId: string;
	className?: string;
}

const GoReactIframe: FC<Props> = (props) => {
	const { familyId, launchURL, launchParams, className } = props;

	/**
	 * Autosubmit form element to setup GoReact iframe via `target` attribute
	 * @see https://mzl.la/3m532XV
	 */
	const formRef = useRef<HTMLFormElement>(null);
	useEffect(() => formRef.current.submit(), []);

	return (
		<div css={styles} className={className}>
			<form
				ref={formRef}
				action={launchURL}
				id={`ltiBasicForm_${familyId}`}
				target={`ltiBasicIframe_${familyId}`}
				method="POST">
				{Object.entries(launchParams).map(([key, value]) => (
					<input key={key} type="hidden" id={key} name={key} value={value} />
				))}
				<iframe
					id={`ltiBasicIframe_${familyId}`}
					name={`ltiBasicIframe_${familyId}`}
					src="about:blank"
					title="Video Management Tool"
					allow="fullscreen; camera *; microphone *; encrypted-media *; autoplay *; clipboard-write *; display-capture *;"
					width="100%"
				/>
			</form>
		</div>
	);
};

GoReactIframe.displayName = 'GoReactIframe';

const styles = css`
	height: 100%;

	form {
		border: 1px #b8b8b8 solid;
		height: 100%;
		width: 100%;
	}

	iframe {
		border: 0;
		height: 100%;
	}

	@media (max-width: ${breakpoints.small}) {
		display: flex;
		justify-content: center;

		form {
			width: 100vw;
		}

		iframe {
			height: 100%;
			width: 100vw;
			margin-bottom: 10px;
			z-index: 1000;
			border: 0;
			border-bottom: 1px #b8b8b8 solid;
		}
	}
`;

export default GoReactIframe;
