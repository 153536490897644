import { css } from '@emotion/react';

import mixins from '~/styles/themes/base/mixins';

export default (theme) => {
	const voiceRecording = theme.voiceRecording;

	return css`
		div:focus {
			outline-color: transparent;
			outline-style: none;
		}

		font-family: ${voiceRecording.fontFamily};

		background: ${voiceRecording.colors.secondary};
		padding: 30px;
		border: 2px solid ${voiceRecording.colors.primary};
		margin: 0 0 2em 0;

		.lms-submission-icon {
			color: ${voiceRecording.colors.primary};
		}

		.MuiCircularProgress-colorPrimary {
			color: ${voiceRecording.colors.primary};
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 15px 0;
			color: ${voiceRecording.colors.primary};
			font-family: ${voiceRecording.heading.fontFamily};
			font-weight: ${voiceRecording.heading.fontWeight};
			text-transform: none;
		}

		.element-title {
			${mixins.elementTitle(theme)}
		}

		.error {
			padding-left: 1rem;
			padding-right: 1rem;
			margin-top: 0.5rem;
			margin-bottom: 1rem;
			border-left: 7px solid #2ca936;
			p {
				margin-bottom: 0.25rem !important;
			}
		}

		.status {
			max-width: 100%;
			margin-bottom: 1.25rem;

			> p {
				padding: 0;
				margin: 0 0 0.5em;
				&:last-child-of-type {
					margin: 0;
				}
			}

			.status-icon {
				float: right;
				position: relative;
			}
		}

		.submissionTime {
			padding: 1.2rem;
			text-align: right;
		}

		.confirmation {
			.controls {
				text-align: right;
				padding: 2em 0 0 0;
				button {
					${mixins.webtextQuestionButtons(theme)}
				}

				button:focus {
					${mixins.webtextAccessibleFocused(theme)}
					background-color: darken(${voiceRecording.colors.primary}, 10%) !important;
				}

				.save-final {
					display: block;
				}
			}
		}

		.centered {
			text-align: center;
		}
	`;
};
