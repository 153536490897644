import { UserBackgroundJob } from '~/types';

import { ValueError } from '../Spreadsheet/Components/Cell/blur-errors.helper';
import { CellValues, RangeValidationRule, ValidationRule } from './../Spreadsheet/helpers/types';

import type { OutlineInstanceAddress } from '~/components/Outline/types';

export interface IDictionary {
	[key: string]: any;
}

export interface ObjectDictionary {
	[key: string]: IDictionary;
}

export interface StringDictionary {
	[key: string]: string;
}

export enum TextOptions {
	Bold = 'bold',
	Capitalize = 'capitalize',
	Italic = 'italic',
	Allcaps = 'allcaps'
}

export interface LmsData {
	enabled: boolean;
	answerId?: number;
	chapterId?: number;
	courseId: number;
	pageId?: number;
	elementFamilyId?: string;
	elementType?: string;
	job?: any;
	Rollbar?: any;
	events?: any;
	familyId?: string;
}

export enum Kinds {
	Map = 'map',
	Select = 'selector',
	Dest = 'dest',
	Gap = 'gap',
	HorizontalRule = 'horizontal-rule',
	Group = 'group',
	CitationOutput = 'output_with_citations',
	ValueOf = 'value-of',
	Text = 'text',
	Table = 'table',
	Para = 'para',
	List = 'list',
	Item = 'item',
	FillIn = 'fill-in',
	Image = 'image',
	Spreadsheet = 'spreadsheet',
	FillInWithCitations = 'fill-in-with-citations',
	Chart = 'chart',
	Outline = 'outline'
}

export interface BuilderUserBackgroundJob extends Omit<UserBackgroundJob, 'status'> {
	status: BuilderJobStatus;
}

// Used for defining the current status of the student's LMS submission progress
export enum BuilderJobStatus {
	REVISED = 'REVISED', // student already stored results
	SUCCESS = 'SUCCESS', // student already sent the stored results to LMS
	FAILURE = 'FAILURE', // LMS submission failed
	CANCELLED = 'CANCELLED' // LMS submission was cancelled
}

export interface ResponseValidationResults {
	[dest: string]: {
		message: string; // Only a single message returned per `dest`
		target?: ValidationResponseItemTarget;
	};
}

export type ValidationResponseItemTarget = OutlineValidationTarget;

export interface HistoricalValidationState {
	// Required to compare the previously shown errors during the validation messages rotation
	previous: ValidationState;
	current: ValidationState;
}

export interface ValidationState {
	validations: ValidationItems;
	status: ValidationStatus;
	trigger?: ValidationTrigger;
}

export type ValidationAction = {
	type: ValidationActionType;
	value?: ValidationItems;
	trigger?: ValidationTrigger;
};

export interface ValidationItems {
	[dest: string]: ValidationItem;
}

export interface ValidationItem {
	type: ValidationItemType;
	status: ValidationItemStatus;
	message?: string;
	target?: ValidationItemTarget;
}

export type ValidationTrigger = 'submit';

export type ValidationItemType = 'inner' | 'received';

type ValidationItemTarget = SpreadsheetValidationTarget | OutlineValidationTarget;

export type SpreadsheetValidationTarget = ValidationCellTarget | ValidationRangeTarget;

export interface ValidationCellTarget {
	type: 'cell';
	address: string;
	value: string | number;
	rule: ValidationRule | RangeValidationRule;
}

export interface ValidationRangeTarget {
	type: 'range';
	selections: string[];
	rule: ValidationRule | RangeValidationRule;
}

type OutlineValidationTarget = OutlineInstanceTarget;

export interface OutlineInstanceTarget {
	type: 'outline-instance';
	address: OutlineInstanceAddress;
	value: string;
}

export const isOutlineInstanceTarget = (
	target: ValidationItemTarget
): target is OutlineInstanceTarget =>
	target.type === 'outline-instance' && Array.isArray(target.address);

export enum ValidationActionType {
	Run = 'validation/run',
	RunOnServer = 'validation/runOnServer',
	Update = 'validation/update',
	Reject = 'validation/reject',
	Success = 'validation/success',
	Reset = 'validation/reset'
}

export enum ValidationItemStatus {
	Pending = 'pending',
	Failure = 'failure',
	Success = 'success'
}

export enum ValidationStatus {
	Uninitialized = 'uninitialized',
	ClientPending = 'clientPending',
	ServerPending = 'serverPending',
	Failure = 'failure',
	Success = 'success'
}

export interface PageScrollApi {
	scrollTo: (options: ScrollToOptions) => void;
	getScrollPosition: () => number;
	scrollableContainer?: HTMLElement;
}

interface StoredValues {
	clipboardValue: string;
	storageValue: CellValues;
}

type StoreValuesFunction = (values: StoredValues, interactionKey: string) => void;

type GetValuesFunction = (interactionKey: string) => Promise<StoredValues>;

export interface SpreadsheetProps {
	hideElementsCallback?: () => void;
	showHiddenElementsCallback?: () => void;
	spiOnBlurErrorCallback?: (address: string, cellError: ValueError, userEntry: string) => void;
	spiValidationErrorCallback?: (
		address: string,
		validationRule: ValidationRule | RangeValidationRule,
		messageShown: string,
		userEntry: string
	) => void;
	spiRuntimeErrorCallback?: (address: string, messageShown: string, userEntry: string) => void;
	mobile?: boolean;
	handleCopy?: StoreValuesFunction;
	handlePaste?: GetValuesFunction;
}
