import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/react';
import { autoUpdate, FloatingPortal, useFloating } from '@floating-ui/react';
import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import last from 'lodash-es/last';
import { shallow } from 'zustand/shallow';

import { EditModeStateContext } from '~/components/WritingTemplate/EditMode/providers/EditModeStateProvider';
import { getBuilderId } from '~/components/WritingTemplate/WritingTemplate/utils';
import { Theme } from '~/styles/themes';

import ElementsContext from '../../context/ElementsContext';
import { getRangeAddresses, getSelectionFromString } from '../../helpers';
import { createFormulaTemporaryValueFromRange } from '../../helpers/compute';
import { getActiveAreaId, separateInputAreaId } from '../../helpers/constants';
import { formulaAttributes, getTooltipAttributes } from '../../helpers/formula/tooltip';
import {
	formulaSelections,
	getLeftTopCellAddress,
	shiftCursorInsideSelection,
	shiftPosition
} from '../../helpers/selection';
import { Dir, KeyCodes, Result } from '../../helpers/types';
import { useElementsResize } from '../../hooks/useElementResize';
import useInteractiveInputElement from '../../hooks/useInteractiveInputElement';
import { useSpreadsheetSelector } from '../../store/provider';
import { selectCellInputValue } from '../../store/selectors';
import { expandArea, getIsOverflow } from '../Cell/utils';

interface Props {
	address: string;
	className: string;
	inputValue: string;
	handleChange: (value: string) => void;
	closeEditAndSave: () => void;
	initialContainerHeight: number;
}

type TooltipAttributes = {
	name: string;
	activeElement: number;
	elementsNumber?: number;
} | null;

type Dimensions = {
	width: number;
	height: number;
} | null;

const CellInput: React.FC<Props> = (props) => {
	const { address, className, inputValue, handleChange, closeEditAndSave, initialContainerHeight } =
		props;

	const {
		value,
		enterWithString,
		formulaInputMethod,
		formulaSelectedValue,
		dest,
		invalidCell,
		selectedCells,
		setFormulaSelectedValueAnchor,
		formulaSelectedValueAnchor
	} = useSpreadsheetSelector(
		(state) => ({
			value: selectCellInputValue(state, address),
			enterWithString: state.enterWithString,
			formulaInputMethod: state.formulaInputMethod,
			formulaSelectedValue: state.formulaSelectedValue,
			dest: state.dest,
			invalidCell: state.invalidCell,
			selectedCells: state.selectedIndexes,
			setFormulaSelectedValueAnchor: state.setFormulaSelectedValueAnchor,
			formulaSelectedValueAnchor: state.formulaSelectedValueAnchor
		}),
		shallow
	);

	const [inputElement, setInputElement] = useState<HTMLTextAreaElement | null>(null);
	const [isOverrideMode, setStopOverrideMode] = useState(Boolean(enterWithString));
	const [tooltipAttributes, setTooltipAttributes] = useState<TooltipAttributes>(null);
	const [expanded, setExpanded] = useState<string[]>([]);
	const [containerDimensions, setContainerDimensions] = useState<Dimensions>(null);
	const [inputDimensions, setInputDimensions] = useState<Dimensions>(null);

	const {
		updateFormulaInputMethod,
		setSelectedCell,
		pushFormulaValue,
		updateAnnouncementSR,
		setActiveAreaId,
		isTouchDevice
	} = useSpreadsheetSelector(
		(state) => ({
			updateFormulaInputMethod: state.updateFormulaInputMethod,
			setSelectedCell: state.setSelectedCell,
			pushFormulaValue: state.pushFormulaSelectedValue,
			updateCellHighlights: state.updateCellHighlights,
			updateAnnouncementSR: state.updateAnnouncementSR,
			setActiveAreaId: state.setActiveAreaId,
			isTouchDevice: state.isTouchScreen
		}),
		shallow
	);

	// This should be pretty safe, because this element is shown only inside the edit mode
	const builderFamilyId = useContext(EditModeStateContext)?.builderFamilyId;
	const { cellsRefs, spreadsheetWrapperRef } = useContext(ElementsContext);

	const spreadsheetIndexes = useMemo(() => Object.keys(cellsRefs), [cellsRefs]);
	const [, totalHeight, totalWidth] = useElementsResize(cellsRefs, spreadsheetIndexes);

	const { refs, floatingStyles } = useFloating({
		placement: 'bottom-start',
		whileElementsMounted: autoUpdate
	});

	useEffect(() => {
		const setDimensions = () => {
			const cell = cellsRefs[address].current;
			if (!cell) return;

			const { width, height } = cell.getBoundingClientRect();
			setContainerDimensions({ width, height });
		};

		setDimensions();

		const scrollable = spreadsheetWrapperRef.current.querySelector('.container');

		if (!scrollable) return;
		scrollable.addEventListener('scroll', setDimensions);

		return () => {
			scrollable.removeEventListener('scroll', setDimensions);
		};
	}, [address, cellsRefs, spreadsheetWrapperRef, invalidCell, totalHeight, totalWidth]);

	useEffect(() => {
		if (!enterWithString) {
			handleChange(value);
			return;
		}

		handleChange(enterWithString);
	}, [value, enterWithString, handleChange]);

	const { caretPosition, setCaretPosition, isFormulaMode } = useInteractiveInputElement(
		inputElement,
		handleChange
	);

	useEffect(() => {
		if (!inputElement || document.activeElement.getAttribute('id') === separateInputAreaId) return;
		inputElement.focus({ preventScroll: true });
	}, [inputElement]);

	useEffect(() => {
		if (getIsOverflow(inputElement)) {
			const expandTo = expandArea(address, expanded, cellsRefs, true);

			/**
			 * This part will run when there are no space to grow to the right
			 */
			if (isEqual(expandTo, expanded)) {
				setInputDimensions((prev) => ({ ...prev, height: inputElement.scrollHeight }));
				return;
			}

			/**
			 * This part is responsible for growing to the right aka expanding the width with overflowing cells
			 */
			setExpanded(expandTo);
			const width = expandTo
				.map((e) => cellsRefs[e].current?.offsetWidth || 0)
				.reduce((acc, v) => acc + v, 0);

			if (!isTouchDevice) {
				setInputDimensions((prev) => ({ ...prev, width }));
			}
		}
	}, [inputValue, expanded, address, cellsRefs, isTouchDevice, inputElement]);

	useEffect(() => {
		setTooltipAttributes(getTooltipAttributes(inputValue, caretPosition));
	}, [caretPosition, inputValue]);

	const getIsFormulaSelectedClick = (event: React.KeyboardEvent<HTMLTextAreaElement>) =>
		[KeyCodes.ArrowDown, KeyCodes.ArrowUp, KeyCodes.ArrowLeft, KeyCodes.ArrowRight].includes(
			event.key as KeyCodes
		) && formulaInputMethod === 'selection';

	const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (!getIsFormulaSelectedClick(event) && event.key !== 'Shift') {
			setCaretPosition(inputElement.selectionStart);
		}
	};

	const handleClick = () => {
		setStopOverrideMode(true);
		setCaretPosition(inputElement.selectionStart ?? 0);
		pushFormulaValue(null);
		setActiveAreaId(getActiveAreaId(dest));
	};

	const handleFocus = () => {
		if (!caretPosition) {
			const caretToEnd = inputValue.length ?? 0;
			inputElement.setSelectionRange(caretToEnd, caretToEnd);
			setCaretPosition(caretToEnd);
			return;
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === KeyCodes.Enter && event.shiftKey) {
			selectNextCell(event, shiftPosition(Dir.Up, address, cellsRefs));
		} else if (event.key === KeyCodes.Enter) {
			selectNextCell(
				event,
				isTouchDevice
					? shiftCursorInsideSelection({
							dir: Dir.Down,
							selectedCells,
							selectedCell: address,
							elements: cellsRefs
					  })
					: shiftPosition(Dir.Down, address, cellsRefs)
			);
		}
	};

	function selectNextCell(
		event: React.KeyboardEvent<HTMLTextAreaElement>,
		nextCell: { result: Result; key: string; message?: string }
	) {
		event.preventDefault();
		updateFormulaInputMethod('input');

		const saveSelectedIndexes = isTouchDevice && selectedCells.length > 1;

		if (nextCell.result === Result.Accept) {
			setSelectedCell(nextCell.key, { saveSelectedIndexes });
			closeEditAndSave();
			return;
		}

		setSelectedCell(address, { saveSelectedIndexes });
		updateAnnouncementSR('No more cells');

		closeEditAndSave();
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (getIsFormulaSelectedClick(event)) {
			event.preventDefault();
			let anchorCell;

			if (event.shiftKey) {
				anchorCell = formulaSelectedValueAnchor
					? formulaSelectedValueAnchor
					: getLeftTopCellAddress(getSelectionFromString(formulaSelectedValue));
				setFormulaSelectedValueAnchor(anchorCell);
			} else {
				anchorCell = formulaSelectedValue ? first(formulaSelectedValue.split(':')) : address;
				setFormulaSelectedValueAnchor(null);
			}

			const newRange = formulaSelections(
				event,
				anchorCell,
				getRangeAddresses(formulaSelectedValue).value ?? [formulaSelectedValue],
				cellsRefs
			);

			if (Array.isArray(newRange) && !isEmpty(newRange)) {
				pushFormulaValue(createFormulaTemporaryValueFromRange(Array.from(newRange)), {
					keepFormulaAnchor: true
				});
			}

			return;
		}

		if (event.key !== KeyCodes.Shift) setCaretPosition(inputElement.selectionStart);

		if (isOverrideMode && !event.shiftKey && !isFormulaMode) {
			if (event.key === KeyCodes.ArrowUp) {
				selectNextCell(event, shiftPosition(Dir.Up, address, cellsRefs));
			} else if (event.key === KeyCodes.ArrowDown) {
				selectNextCell(event, shiftPosition(Dir.Down, address, cellsRefs));
			} else if (event.key === KeyCodes.ArrowLeft) {
				selectNextCell(event, shiftPosition(Dir.Left, address, cellsRefs));
			} else if (event.key === KeyCodes.ArrowRight) {
				selectNextCell(event, shiftPosition(Dir.Right, address, cellsRefs));
			}
		}
	};

	return (
		<>
			<div ref={refs.setReference} css={styles.anchor} />
			<FloatingPortal id={getBuilderId(builderFamilyId)}>
				<div
					css={styles.self({ containerDimensions, minimalHeight: initialContainerHeight })}
					ref={refs.setFloating}
					style={floatingStyles}>
					<textarea
						data-testid={`input-${dest}`}
						id={getActiveAreaId(dest)}
						ref={setInputElement}
						css={(theme) =>
							styles.input(theme, {
								startWithFormula: isFormulaMode,
								isCellInvalid: invalidCell === address,
								inputDimensions
							})
						}
						className={className}
						value={inputValue}
						onClick={handleClick}
						onFocus={handleFocus}
						onKeyPress={handleKeyPress}
						onKeyUp={onKeyUp}
						onKeyDown={handleKeyDown}
						onChange={(e) => handleChange(e.target.value)}
					/>
					<Tooltip attributes={tooltipAttributes} />
				</div>
			</FloatingPortal>
		</>
	);
};

const Tooltip = ({ attributes }: { attributes: TooltipAttributes }) => {
	if (!attributes) return null;

	const { name: enteredFormulaName, activeElement, elementsNumber } = attributes;

	const formula = formulaAttributes[enteredFormulaName.toLowerCase()];
	if (!formula) return null;

	const { name, getArgs } = formula;
	const formulaArguments = getArgs(elementsNumber);

	const getFocused = (elementIdx: number) => (activeElement === elementIdx ? 'bold' : 'normal');

	return (
		<div css={styles.tooltip}>
			{name}(
			{formulaArguments.map((argument, idx) => (
				<span key={name + idx} style={{ fontWeight: getFocused(idx) }}>
					{argument}
					{argument === last(formulaArguments) ? '' : ', '}
				</span>
			))}
			)
		</div>
	);
};

const styles = {
	self: (options: { containerDimensions: Dimensions; minimalHeight: number }) => css`
		min-height: ${options.minimalHeight}px;
		z-index: 3;

		${options.containerDimensions &&
		css`
			height: ${options.containerDimensions.height}px;
			width: ${options.containerDimensions.width}px;
		`}
	`,
	input: (
		theme: Theme,
		options: { startWithFormula: boolean; isCellInvalid: boolean; inputDimensions: Dimensions }
	) => css`
		box-sizing: border-box;
		display: block;

		width: 100%;
		height: 100%;

		background: #edf6ff;
		overflow: hidden;

		padding: 0 10px 3px;

		font-family: ${theme.fonts['helvetica-neue']};
		line-height: 1.6;
		font-size: 16px;

		border: 2px solid #3579f8 !important;
		resize: none;

		text-align: left;
		${options.startWithFormula && 'text-align: left !important;'}
		${options.isCellInvalid && 'background: #fdebe8 !important;'}

		${options.inputDimensions &&
		css`
			height: ${options.inputDimensions.height}px;
			width: ${options.inputDimensions.width}px;
		`}

		&:focus {
			outline: none;
		}
	`,
	tooltip: (theme: Theme) => css`
		display: inline-block;
		color: #444;
		font-size: 12px;
		text-align: center;
		font-weight: normal !important;
		font-family: ${theme.fonts['helvetica-neue']};

		background: #ffffe1;

		border: 1px solid #a2a6ab;
		padding: 5px 10px;
	`,
	anchor: css`
		position: absolute;
		top: 0;
		width: 0;
		height: 0;
	`
};

export default CellInput;
