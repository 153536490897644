import { getCustomEventElement } from '~/utils/emitCustomEvent';

// importing this from '.' or '~/hooks' would produce a dependency cycle
import { useEventListener } from './useEventListener';

export const useCustomEventListener = <T>(
	eventName: string,
	eventHandler: (data: T) => void
): void => {
	const customEventsElement = getCustomEventElement();
	const handleEvent = (event: CustomEvent) => eventHandler(event.detail);
	return useEventListener(eventName, handleEvent, customEventsElement);
};
