import { css } from '@emotion/react';

import { ThemeName, breakpoints, mixins } from '~/styles/themes';

export const figureStyles = (theme) => {
	const { primary, 'pure-white': pureWhite, 'border-gray': borderGray } = theme.colors;

	return css`
		margin: 0;
		border: 2px solid ${borderGray};
		border-style: none none solid;

		&.pull-left,
		&.pull-right {
			width: 35%;

			/**
			 * We need to create a separate stacking context for the figures with the CSS float property
			 * In other cases, we won't be able to select the text or interact with the focusable content
			 */
			position: relative;
			z-index: 1;

			/*
			 * Show a top border whenever this figure is floated to the left or right, and remove it when the figure becomes full width.
			 * We add 1 to avoid showing the border at exactly breakpoints.small width, since at breakpoints.small the figure will become be full-width
			 */
			@media (min-width: calc(${breakpoints.small} + 1px)) {
				&.no-figure-number {
					padding-top: 15px;
					border-style: solid none;
				}
			}

			.webtext-figure-number {
				padding-bottom: 9px;
				margin-bottom: 15px;
				border-style: none none solid;
			}
		}

		&.pull-right {
			float: right;
			margin: 4px 0 25px 30px;
		}

		&.pull-left {
			float: left;
			margin: 4px 30px 25px 0;
		}

		@media (max-width: ${breakpoints.small}) {
			&.pull-right,
			&.pull-left {
				width: 100%;
				float: none;
				margin: 0;
			}
		}

		.figure-number {
			font-family: ${theme.figure.number.fontFamily};
			font-size: ${theme.figure.number.fontSize};
			font-weight: ${theme.figure.number.fontWeight};
			line-height: ${theme.figure.number.lineHeight};
			letter-spacing: ${theme.figure.number.letterSpacing};
			text-transform: ${theme.figure.number.textTransform};
			color: ${theme.figure.number.color};
			margin: ${theme.figure.number.margin};
		}

		.figure-title {
			font-family: ${theme.figure.title.fontFamily};
			font-size: ${theme.figure.title.fontSize};
			font-weight: ${theme.figure.title.fontWeight};
			line-height: ${theme.figure.title.lineHeight};
			color: ${theme.figure.title.color};
			margin-bottom: ${theme.figure.title.marginBottom};
		}

		.figure-instructions {
			font-style: italic;
		}

		.figure-caption {
			clear: both;
			font-family: ${theme.figure.caption.fontFamily};
			font-size: ${theme.figure.caption.fontSize};
			color: ${theme.figure.caption.color};

			margin-bottom: ${theme.figure.caption.marginBottom};
			line-height: ${theme.figure.caption.lineHeight};

			p {
				margin: 0 0 15px;
				font-size: 16px;
			}

			a {
				color: ${primary};
			}

			.figure-caption-prefix {
				font-weight: ${theme.figure.caption.prefix.fontWeight};
			}

			.figure-caption-body {
				margin-bottom: 15px;
				font-family: ${theme.figure.caption.fontFamily};
				font-size: ${theme.figure.caption.body.fontSize};
				line-height: 1.3;

				a {
					text-decoration: underline;
				}
			}

			.figure-caption-credit {
				font-style: italic;
				font-family: ${theme.figure.caption.fontFamily};
				font-size: 14px;
				line-height: 1.3;

				${theme.name === ThemeName.UNIVERSAL_VELVET &&
				css`
					@media (max-width: ${breakpoints.small}) {
						font-size: 13px;
					}
				`}

				strong {
					color: inherit;
				}

				em {
					color: inherit;
					font-style: normal;
				}

				a {
					color: inherit;
					text-decoration: underline;
				}
			}

			.figure-caption-body,
			.figure-caption-credit {
				display: ${theme.figure.caption.body.display};
			}

			.figure-caption-transcript {
				font-size: ${theme.figure.caption.transcript.fontSize};
				margin-top: ${theme.figure.caption.transcript.marginTop};

				a {
					color: inherit;
					text-decoration: underline;
					font-weight: bold;
				}
			}
		}

		.figure-interactive-map-title {
			margin: 10px 0 0;
			padding: 20px;
			background-color: ${theme.figure.interactiveMapTitleBackground};
			color: ${pureWhite};

			.map-title {
				font-size: 20px;
				font-weight: bold;
				color: ${pureWhite} !important;
				font-family: ${theme.fonts.app};
			}
		}

		.figure-payload {
			.webtext-figure-asset {
				width: 100%;
				margin-bottom: 15px;
			}

			.webtext-figure-asset-image {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.hidden {
			display: none;
		}

		.hidden-accessible {
			${mixins.webtextHiddenAccessible(theme)}
		}

		.image-options-block {
			margin-bottom: 15px;
		}
	`;
};
