import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { getSelectionFromString } from '../helpers';
import { getIsAnyErrorsInSelection, getLeftTopCellAddress } from '../helpers/selection';
import { Graph } from './../Graph';
import {
	createFormulaTemporaryValueFromRange,
	evaluateCellValuesSlice
} from './../helpers/compute';
import {
	CellValues,
	EvaluatedCells,
	EditableRanges,
	FormulaInputMethod,
	CellsRowHeight,
	TouchMode,
	ContextMenuParams
} from './../helpers/types';

/**
 * More details can be found here
 * https://soomo.slab.com/posts/developer-notes-spreadsheet-template-data-layer-7digxk36
 */

export interface SpreadsheetState {
	dest: string;
	editableRanges: EditableRanges;
	origin: string;
	copyCacheKey: string;
	onUserUpdate?: () => void;

	cellValues: CellValues;
	evaluatedFormulasValues: EvaluatedCells;
	graph: Graph;

	selectedIndexes: string[];
	selectedCell: string;
	formulaInputMethod: FormulaInputMethod;
	formulaSelectedValue: string;
	focused: boolean;
	editingCell: string;
	enterWithString: string;
	cellsRowHeight: CellsRowHeight;
	displayErrorFor: { address: string; message?: string };
	runtimeEditableErrors: { [address: string]: boolean };
	cellHighlights: string[];
	contextMenuProps: ContextMenuParams;
	invalidCell: string;
	invalidRange: string[];
	announcementSR: string;
	isTouchScreen: boolean;
	touchMode: TouchMode;
	activeInputValue: string;
	activeAreaId: string;
	onBlurErrorMessages?: CellValues;
	formulaSelectedValueAnchor?: string;
}

type SelectionNormalizeFunc = (indexes: string[]) => string[];

export interface SpreadsheetActions {
	updateCellValue: (address: string, value: string) => void;
	updateCellValues: (cellValues: CellValues) => void;
	setEditingCell: (address: string) => void;
	setSelectedCells: (addresses: string[]) => void;
	setSelectedCell: (address: string, options?: { saveSelectedIndexes: boolean }) => void;
	setFocus: (focused: boolean) => void;
	setEnterWithString: (entry: string) => void;
	setShowErrorFor: (address: string, message?: string) => void;
	setInvalidRange: (addressRange: string[]) => void;
	setInvalidCell: (address: string) => void;
	updateFormulaInputMethod: (inputMethod: FormulaInputMethod) => void;
	updateCellHeight: (cellHeight: CellsRowHeight) => void;
	updateSelectedIndexes: (normalize: SelectionNormalizeFunc) => void;
	updateEvaluatedFormulasValues: (updatedCellValues: CellValues) => void;
	updateRuntimeEditableErrors: (address: string, status: boolean) => void;
	updateCellHighlights: (highlights: string[]) => void;
	updateContextProps: (props: ContextMenuParams) => void;
	updateAnnouncementSR: (value: string) => void;
	createSelectionWith: (normalize: SelectionNormalizeFunc, address: string) => void;
	pushFormulaSelectedValue: (value: string, options?: { keepFormulaAnchor: boolean }) => void;
	updateTouchMode: (mode: TouchMode) => void;
	updateActiveInputValue: (value: string) => void;
	setActiveAreaId: (id: string) => void;
	updateOnBlurErrorMessage: (errorMessage: CellValues) => void;
	setFormulaSelectedValueAnchor: (address: string) => void;
}

export type SpreadsheetStore = ReturnType<typeof createSpreadsheetStore>;

export const createSpreadsheetStore = (initialState: SpreadsheetState) =>
	createWithEqualityFn<SpreadsheetState & SpreadsheetActions>()(
		subscribeWithSelector(
			devtools(
				(set, get) => ({
					...initialState,
					updateCellValue(address, value) {
						if (!address) return;

						const { cellValues, graph, onUserUpdate } = get();

						graph.updateVertex(address, value);
						set({ cellValues: { ...cellValues, [address]: value } });

						const updateEvaluatedFormulasValues = get().updateEvaluatedFormulasValues;
						updateEvaluatedFormulasValues({ [address]: value });
						onUserUpdate?.();
					},
					updateCellValues(updatedCellValues) {
						const { cellValues, graph, onUserUpdate } = get();

						Object.entries(updatedCellValues).forEach(([address, value]) =>
							graph.updateVertex(address, value)
						);

						set({ cellValues: { ...cellValues, ...updatedCellValues } });

						const updateEvaluatedFormulasValues = get().updateEvaluatedFormulasValues;
						updateEvaluatedFormulasValues(updatedCellValues);
						onUserUpdate?.();
					},
					setEditingCell(address) {
						set({ editingCell: address });

						if (!address) {
							set({ enterWithString: null });
							set({ activeInputValue: '' });
							set({ activeAreaId: '' });
						}
					},
					setSelectedCell(address, options: { saveSelectedIndexes: boolean }) {
						set({ selectedCell: address });

						const { isTouchScreen } = get();
						if (!options?.saveSelectedIndexes) {
							set(
								isTouchScreen
									? {
											selectedIndexes: [address],
											touchMode: 'scroll'
									  }
									: {
											selectedIndexes: []
									  }
							);
						}
					},
					setSelectedCells(addresses) {
						set({ selectedIndexes: addresses });

						const { isTouchScreen, selectedCell } = get();
						if (isTouchScreen && !addresses.includes(selectedCell)) {
							set({ selectedCell: null });
						}
					},
					setFocus(focused) {
						set({ focused });
					},
					setShowErrorFor(address, message) {
						set({ displayErrorFor: { address, message } });
					},
					setEnterWithString(entry) {
						set({ enterWithString: entry });
					},
					setInvalidCell(address) {
						set({ invalidCell: address });
					},
					setInvalidRange(addressRange) {
						set({ invalidRange: addressRange });
					},
					updateFormulaInputMethod(inputMethod) {
						set({
							formulaInputMethod: inputMethod,
							formulaSelectedValue: null,
							formulaSelectedValueAnchor: null
						});
					},
					updateCellHeight(cellHeight) {
						set({ cellsRowHeight: { ...get().cellsRowHeight, ...cellHeight } });
					},
					updateSelectedIndexes(normalize) {
						const { selectedIndexes, selectedCell } = get();
						const selectedCells = normalize(selectedIndexes);
						set({ selectedIndexes: selectedCells });

						if (!selectedCell) {
							set({ selectedCell: getLeftTopCellAddress(selectedCells) });
						}
					},
					createSelectionWith(normalize, address) {
						const { selectedCell } = get();

						if (!selectedCell) return;

						const { formulaInputMethod, formulaSelectedValue, formulaSelectedValueAnchor } = get();

						if (formulaInputMethod !== 'selection') {
							return set({ selectedIndexes: normalize([address, selectedCell]) });
						}

						if (formulaSelectedValueAnchor)
							return set({
								formulaSelectedValue: createFormulaTemporaryValueFromRange([
									formulaSelectedValueAnchor,
									address
								])
							});

						const initialSelectionCell = getLeftTopCellAddress(
							getSelectionFromString(formulaSelectedValue)
						);

						set({
							formulaSelectedValueAnchor: initialSelectionCell,
							formulaSelectedValue: createFormulaTemporaryValueFromRange([
								initialSelectionCell,
								address
							])
						});
					},
					pushFormulaSelectedValue(value: string, options: { keepFormulaAnchor: boolean }) {
						set({ formulaSelectedValue: value });

						if (!options?.keepFormulaAnchor) {
							set({ formulaSelectedValueAnchor: null });
						}
					},
					setFormulaSelectedValueAnchor(address: string) {
						set({ formulaSelectedValueAnchor: address });
					},
					updateEvaluatedFormulasValues(updatedCellValues: CellValues) {
						const { cellValues: updateCellValues, evaluatedFormulasValues, graph } = get();

						const updatedEvaluatedFormulaValues = evaluateCellValuesSlice({
							addressesToEvaluate: Object.keys(updatedCellValues),
							cellValues: updateCellValues,
							graph,
							evaluatedValues: evaluatedFormulasValues
						});

						set({
							evaluatedFormulasValues: {
								...evaluatedFormulasValues,
								...updatedEvaluatedFormulaValues
							}
						});
					},
					updateRuntimeEditableErrors(address, status) {
						const { runtimeEditableErrors } = get();
						runtimeEditableErrors[address] = status;

						set({ runtimeEditableErrors });
					},
					updateCellHighlights(highlights) {
						set({ cellHighlights: highlights });
					},
					updateContextProps(props) {
						const isAnyErrorInSelection = getIsAnyErrorsInSelection(get());
						if (!isAnyErrorInSelection) {
							set({ contextMenuProps: props });
						}
					},
					updateAnnouncementSR(value) {
						set({ announcementSR: value });
					},
					updateTouchMode(mode) {
						const { isTouchScreen, touchMode } = get();
						if (touchMode === mode) return;

						set({ touchMode: isTouchScreen ? mode : null });
					},
					updateActiveInputValue(value) {
						set({ activeInputValue: value });
					},
					setActiveAreaId(id) {
						set({ activeAreaId: id });
					},
					updateOnBlurErrorMessage(errorMessage: CellValues) {
						set({ onBlurErrorMessages: { ...get().onBlurErrorMessages, ...errorMessage } });
					}
				}),
				{ name: 'SpreadsheetStore', store: initialState.dest }
			)
		)
	);
