import React, { useEffect, useMemo, useRef, useState } from 'react';

import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import { useWindowSize } from 'usehooks-ts';

import { removeLabelsOverlap } from '~/components/pageElements/ChartFigure/chartHelpers';

import { PieDataType } from '../../types';
import { container, hint } from './styles';

interface Props {
	data: PieDataType;
	name: string;
	isNewlyCreated: boolean;
}

const pieColors = [
	'#5F01DF',
	'#48C8B2',
	'#41004C',
	'#E1B2A1',
	'#016E5B',
	'#EADB2F',
	'#F05018',
	'#C8A0FF',
	'#99310D',
	'#F5EE9D',
	'#0062FF',
	'#6F0540',
	'#8AD2C6',
	'#02957C',
	'#153971',
	'#9FB2DE'
];

const radius = 110;

// 590px is optimal width to show on both narrow (scrollable) and wider (not scrollable) view ports
const defaultChartDimensions = { width: 590, height: 320 };

const Pie: React.FC<Props> = ({ data, name, isNewlyCreated }) => {
	const [chartDimensions, setChartDimensions] = useState(defaultChartDimensions);
	const [dataForPie, setPieData] = useState(data.map((element) => element.value));

	const chartSvgRef = useRef<SVGSVGElement>(null);
	const chartScrollableContainerRef = useRef<HTMLDivElement>(null);

	const chartTitleRef = useRef<HTMLDivElement>(null);

	const { width } = useWindowSize();

	useEffect(() => {
		const { current: chartScrollableContainer } = chartScrollableContainerRef;

		if (!chartScrollableContainer) return;

		const { offsetWidth } = chartScrollableContainer;
		setChartDimensions((prev) => ({
			...prev,
			width: Math.max(offsetWidth, defaultChartDimensions.width)
		}));
	}, [width]);

	useEffect(() => {
		const { current: chartTitle } = chartTitleRef;
		const { current: chartScrollableContainer } = chartScrollableContainerRef;
		if (isNewlyCreated) {
			setTimeout(() => {
				// chartScrollableContainer & chartTitle are getting rendered and scrollable only on the next render cycle
				chartScrollableContainer.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				});
				chartTitle.focus({ preventScroll: true });
			});
		}
	}, [isNewlyCreated]);

	useEffect(() => {
		setPieData(data.map((element) => element.value));
	}, [data]);

	useEffect(() => {
		const { current: chartScrollableContainer } = chartScrollableContainerRef;
		if (!chartScrollableContainer) return;

		const { scrollWidth, offsetWidth } = chartScrollableContainer;

		const scrollWidthDifference = scrollWidth - offsetWidth;
		/**
		 * `scrollTo` updates `scrollLeft` prop
		 * To scroll to the container's center we need to set it to (scrollable width - visible width) / 2
		 */
		chartScrollableContainer.scrollTo(scrollWidthDifference / 2, 0);
	}, []);

	const chartDataSum = useMemo(
		() => dataForPie?.length > 0 && dataForPie.reduce((acc, val) => acc + val, 0) === 0,
		[dataForPie]
	);

	useEffect(() => {
		const svg = select(chartSvgRef.current);
		svg.selectAll('*').remove();

		if (!chartDataSum) draw();
	}, [dataForPie]);

	const formatLabelText = (text: string) =>
		// WT container is not able to include text longer than ~17 regular symbols, so we're doing light trim in the end
		text.length > 15 ? `${text.slice(0, 15).trim()}...` : text;

	const draw = () => {
		// Main arc for pie chart
		const pieArc: any = arc().outerRadius(radius).innerRadius(0);

		// Another arc that won't be drawn. Just for labels positioning
		const outerArc = arc()
			.innerRadius(radius)
			.outerRadius(radius + 20);

		const pieData = pie().sort(null)(dataForPie);

		const svg = select(chartSvgRef.current);

		const group = svg
			.attr('class', 'chart')
			.attr('viewBox', `0 0 ${chartDimensions.width} ${chartDimensions.height}`)
			.attr('preserveAspectRatio', 'xMidYMid meet')
			.append('g')
			.attr('transform', `translate(${chartDimensions.width / 2}, ${chartDimensions.height / 2})`);

		group
			.selectAll('.wedge')
			.data(pieData)
			.enter()
			.append('path')
			.attr('class', 'wedge')
			.attr('d', pieArc)
			.attr('fill', (_, idx) => pieColors[idx % pieColors.length])
			.attr('stroke', () => 'white')
			.attr('stroke-width', 0.5);

		const labels = group.selectAll('.label').data(pieData).enter();
		const labelsGroups: any = labels.append('g').attr('class', 'label');

		const getMidAngle = (d) => d.startAngle + (d.endAngle - d.startAngle) / 2;

		const getPositions = (d) => {
			const posA = pieArc.centroid(d); // line insertion in the slice
			const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
			const posC = outerArc.centroid(d); // Label position = almost the same as posB
			const midAngle = getMidAngle(d); // we need the angle to see if the X position will be at the extreme right or extreme left
			posC[0] = radius * (midAngle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
			return [posA, posB, posC];
		};

		const lines = labelsGroups
			.append('polyline')
			.attr('stroke', 'black')
			.style('fill', 'none')
			.attr('stroke-width', 0.5)
			.attr('points', function (d) {
				return getPositions(d);
			});

		const textLabels = labelsGroups
			.append('text')
			.attr('x', (d) => {
				const midAngle = getMidAngle(d);
				return radius * 1.1 * (midAngle < Math.PI ? 1 : -1);
			})
			.attr('y', (d) => {
				return outerArc.centroid(d)[1];
			})
			.attr('text-anchor', function (d) {
				const midAngle = getMidAngle(d);
				return midAngle < Math.PI ? 'start' : 'end';
			})
			.text((_, idx) => `${formatLabelText(data[idx].label)} (${data[idx].percent}%)`)
			.attr('class', 'label-text')
			.attr('fill', '#333');

		const updateLinePoints = () => {
			const textLabelsElements = textLabels.nodes();
			lines.attr('points', (d, i) => {
				const labelForLine = select(textLabelsElements[i]);
				const labelY = labelForLine.attr('y');

				const [posA, posB, posC] = getPositions(d);
				const midAngle = getMidAngle(d);

				posC[0] = radius * 1.05 * (midAngle < Math.PI ? 1 : -1);

				const isIgnoreThirdLine = Math.abs(posB[0]) > Math.abs(posC[0]);
				const points = [posA, [posB[0], labelY]];

				return isIgnoreThirdLine ? points : [...points, [posC[0], labelY]];
			});
		};

		removeLabelsOverlap({
			chartGroup: group,
			textLabels,
			onRepeat: updateLinePoints
		});
	};

	const hintElement = useMemo(() => {
		if (chartDataSum) {
			return (
				<div css={hint}>
					You&rsquo;ve tried to create a chart without any data. Please click &ldquo;Reset
					Chart&rdquo; and select data from the spreadsheet above.
				</div>
			);
		}
	}, [chartDataSum]);

	return (
		<div css={container}>
			<div className="chart-title" role="heading" aria-level={3} ref={chartTitleRef} tabIndex={0}>
				{name}
			</div>
			{hintElement}
			{/* We make scrollable container for chart and name will remain static centred; */}
			<div className="scrollable-container" ref={chartScrollableContainerRef}>
				<svg ref={chartSvgRef} {...chartDimensions} role="img" aria-label="Pie chart" />
			</div>
		</div>
	);
};

export default Pie;
