import React, { useMemo } from 'react';

import { shallow } from 'zustand/shallow';

import { createRangeDimensions } from '../../helpers/compute';
import { getRangeSelectionId } from '../../helpers/constants';
import { CellsRefs } from '../../helpers/types';
import { useSpreadsheetSelector } from '../../store/provider';
import DragHandle from './DragHandle';
import styles from './styles';

interface Props {
	leaveSelectionVisible: boolean;
	hideAddressHeaders: boolean;
	cellsRefs: CellsRefs;
	withHeader: boolean;
	totalHeight: number;
	totalWidth: number;
}

const RangeSelection: React.FC<Props> = ({
	leaveSelectionVisible,
	hideAddressHeaders,
	cellsRefs,
	withHeader,
	totalHeight,
	totalWidth
}) => {
	const { selectedIndexes, dest, focused, invalidCell, isTouchScreen, editingCell } =
		useSpreadsheetSelector(
			(state) => ({
				selectedIndexes: state.selectedIndexes,
				focused: state.focused,
				invalidCell: state.invalidCell,
				isTouchScreen: state.isTouchScreen,
				editingCell: state.editingCell,
				dest: state.dest
			}),
			shallow
		);

	const { isDisplay, position, highlightMode, compensateHeader } = useMemo(() => {
		if (!selectedIndexes.length) {
			return {
				isDisplay: false
			};
		}

		const { top, left, width, height } = createRangeDimensions(
			selectedIndexes,
			cellsRefs,
			withHeader
		);

		/**
		 * We do not need to show this selection when selected range less than 2 (source: Excel).
		 * It's relevant only for active selection
		 */
		const showRegularSelection = isTouchScreen ? focused : focused && selectedIndexes.length > 1;
		/**
		 * Selection must not be shown when covers only one cell and this cell is invalid
		 */
		const isNotCoverInvalidCell = !(
			selectedIndexes.length === 1 && selectedIndexes.includes(invalidCell)
		);
		/**
		 * Selection that visible only when we don't have focus on ST
		 */
		const showShadowSelection = isNotCoverInvalidCell && leaveSelectionVisible && !focused;
		const isDisplay = showRegularSelection || showShadowSelection;

		return {
			isDisplay,
			/**
			 * Every time when we hide headers of ST, it became too wide and we have to add small padding
			 * Otherwise it create unexpected scroll bars.
			 * When we add this padding selection became too wide, that's whe we make it more consistent in styles sections of each selection
			 */
			compensateHeader: !!hideAddressHeaders,
			highlightMode: leaveSelectionVisible && !focused ? 'persisted' : 'active',
			position: {
				top,
				left,
				width,
				height
			}
		};
		// `totalHeight` needed for re-calculation of cursor size
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedIndexes,
		focused,
		invalidCell,
		cellsRefs,
		hideAddressHeaders,
		leaveSelectionVisible,
		totalHeight,
		withHeader,
		isTouchScreen,
		totalWidth
	]);

	const isDisplayForMobile = isTouchScreen
		? editingCell
			? selectedIndexes.length > 1
			: selectedIndexes.length
		: true;

	return isDisplay && isDisplayForMobile ? (
		<div
			aria-hidden
			id={getRangeSelectionId(dest)}
			css={styles({
				left: position.left,
				top: position.top,
				width: position.width,
				height: position.height,
				highlightMode,
				compensateHeader
			})}>
			<DragHandle position="top" />
			<DragHandle position="bottom" />
		</div>
	) : null;
};

export default RangeSelection;
