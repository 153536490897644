import React from 'react';

import styles from './styles';

interface Props {
	variant: 'upper-alpha' | 'lower-alpha' | 'upper-roman' | 'lower-roman' | 'decimal';
	list: any[];
	fromView?: boolean;
}

const Outline: React.FC<Props> = ({ variant, list, fromView }) => {
	return (
		<ol css={(theme) => styles(theme, variant, fromView)}>
			{list.map((el, i) => (
				<li key={`${variant}${i}`}>{el}</li>
			))}
		</ol>
	);
};

export default Outline;
