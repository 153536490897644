import * as React from 'react';

export function Briefcase(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="M51 26.06h-7.51v-3.74a1.3 1.3 0 0 0-1.3-1.3H29.81a1.3 1.3 0 0 0-1.3 1.3v3.74H21a2.43 2.43 0 0 0-2.44 2.45v19.87A2.43 2.43 0 0 0 21 50.83h30a2.42 2.42 0 0 0 2.44-2.45V28.51A2.53 2.53 0 0 0 51 26.06Zm-9.94 0H31.1v-2.44H41Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
