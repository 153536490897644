import React from 'react';

import { useTheme } from '@emotion/react';
import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core';

import { breakpoints } from '~/styles/themes';

import type baseTheme from '../../../styles/themes/blue_velvet';

type Props = Pick<TooltipProps, 'open' | 'title' | 'children'>;

const useStyles = makeStyles({
	popper: {
		top: '-9px !important',
		[`@media (max-width: ${breakpoints.small})`]: {
			top: '7px !important'
		}
	},
	tooltip: {
		boxSizing: 'border-box',
		width: '300px',
		padding: '14px 31px 14px 25px',
		border: '1px solid #979797',
		borderRadius: '8px',
		backgroundColor: '#fff',
		color: 'var(--tooltip-color)',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: 'normal',
		fontFamily: 'var(--tooltip-font-family)',
		[`@media (max-width: ${breakpoints.small})`]: {
			width: '272px',
			padding: '14px 22px 14px 25px'
		}
	},
	arrow: {
		color: '#fff',
		'&::before': {
			border: '1px solid #979797'
		}
	}
});

const NextQuestionReminderTooltip: React.FC<Props> = (props) => {
	const { open, title, children } = props;
	const theme = useTheme();
	const classes = useStyles();

	return (
		/* TODO when we've migrated to MUI v5, add "describeChild" to the Tooltip's props */
		<Tooltip
			open={open}
			arrow
			placement="top"
			title={title}
			TransitionProps={{
				timeout: 0
			}}
			PopperProps={{
				disablePortal: true,
				popperOptions: {
					modifiers: {
						preventOverflow: {
							enabled: false
						},
						hide: {
							enabled: false
						}
					}
				}
			}}
			classes={classes}
			/* TODO when we've migrated to MUI v5, change this to use the sx prop */
			style={
				{
					'--tooltip-font-family': theme.fonts.app,
					'--tooltip-color': theme.colors.text
				} as TooltipProps['style']
			}>
			{children}
		</Tooltip>
	);
};
export default NextQuestionReminderTooltip;
