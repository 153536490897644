import camelCase from 'lodash-es/camelCase';
import isEmpty from 'lodash-es/isEmpty';

import {
	RTESchemaOptions,
	CitationsConfig,
	AvailableCitation,
	CitationStyle
} from '../../FillIn/types';
import { IDictionary } from '../../WritingTemplate/types';

export const getCourseDecisions = (toc: IDictionary): IDictionary => toc.config.courseDecisions;

export const isKeyFromCourse = (courseDecisions: IDictionary): boolean =>
	courseDecisions['citation_style'].options.length > 0;

export const isOneCitationKey = (courseDecisions: IDictionary): boolean =>
	courseDecisions['citation_style'].options.length === 1;

export const getCitationKey = (courseDecisions: IDictionary): string =>
	courseDecisions['citation_style'].options[0]['key'];

export const getCitationsFromConfig = (
	toc: IDictionary,
	from: string,
	get: string
): IDictionary[] => toc.config[from][get].options;

export const getPathFromImports = (
	imports: IDictionary,
	key: string
): {
	from: string;
	get: string;
	child: string;
} => {
	if (!imports) return null;
	const [initPath, endPath] = key.split('.');
	const { [initPath]: citationFrom } = imports;

	if (!citationFrom) return null;
	const [importPath, childPath] = citationFrom.split(':');

	return {
		from: camelCase(importPath) || 'courseDecisions',
		get: childPath || 'topic',
		child: endPath || 'source'
	};
};

export const getDecisionStyleTopic = (toc: IDictionary): IDictionary => {
	const { userDecisions } = toc;
	if (!userDecisions) return null;

	const { topic, citation_style: key } = userDecisions;
	return { topic, key };
};

export const getCitationFromData = (toc: IDictionary, listRef: string): AvailableCitation[] =>
	toc?.config?.courseData[listRef];

export const filterCitationsTopic = (
	citations: IDictionary,
	topic: string,
	source: string
): AvailableCitation[] =>
	citations
		.filter(({ key: citationKey }) => citationKey === topic)
		.reduce((acc: IDictionary[], val) => (val[source] ? [...acc, ...val[source]] : [...acc]), []);

export const handleCitationStyles = (
	citations: AvailableCitation[],
	style: string
): CitationsConfig => {
	const citationsWithStyles = citations?.reduce(
		(acc: AvailableCitation[], { 'supported-styles': styles, ...rest }) =>
			styles[style]
				? [...acc, { ...rest, 'supported-styles': { [style]: styles[style] } }]
				: [...acc],
		[]
	);
	return { citations: citationsWithStyles, style: style as CitationStyle };
};

export const getCitationFromSettings = (
	citationImportFrom: IDictionary,
	toc: IDictionary,
	key: string,
	topic: string
): CitationsConfig => {
	const { from, get, child } = citationImportFrom;
	const citationOptions = getCitationsFromConfig(toc, from, get);

	if (citationOptions) {
		const filteredOptions = filterCitationsTopic(citationOptions, topic, child);
		return handleCitationStyles(filteredOptions, key);
	}

	return null;
};

export const isCitationEnable = (options: RTESchemaOptions, toc: IDictionary): boolean => {
	return options?.citations?.enabled && !!options?.citations['source-list-ref'] && !isEmpty(toc);
};

export const getListRef = (options: RTESchemaOptions): string =>
	options?.citations?.['source-list-ref'];
