import { type FC, useRef, useState, useEffect } from 'react';
import { MdPlayCircle } from 'react-icons/md';
import { type ReactPlayerProps, default as ReactPlayer } from 'react-player';

import { css } from '@emotion/react';

import { postCourseEvent } from '~/api';
import { usePreviousRender } from '~/hooks';
import { type ElementContextData, type FigureElement } from '~/types/WebtextManifest';
import { URL_REGEX } from '~/utils';

import { CastLabsPlayer, Kaltura, BrightcovePlayer, VzaarPlayer } from './players';

/**
 * Attach custom players here.
 * There is a rabbit hole to go down when not ignored by typechecking
 */
ReactPlayer.addCustomPlayer(CastLabsPlayer as unknown as ReactPlayer);
ReactPlayer.addCustomPlayer(Kaltura as unknown as ReactPlayer);
ReactPlayer.addCustomPlayer(BrightcovePlayer as unknown as ReactPlayer);
ReactPlayer.addCustomPlayer(VzaarPlayer as unknown as ReactPlayer);

const TIME_UPDATE_INTERVAL = 30000;

interface VideoFigureProps {
	figure?: Partial<FigureElement>;
	payload?: string; // url or iframe
	trackingConfig?: {
		familyId: string;
		requestParams: any;
	};
	elementContext: ElementContextData;
	videoFigureProps?: Pick<
		ReactPlayerProps,
		'onProgress' | 'onBuffer' | 'onStart' | 'onPlay' | 'onPause' | 'onEnded' | 'onError'
	>;
	mock?: boolean;
}

const VideoFigure: FC<VideoFigureProps> = (props) => {
	const { figure, elementContext, videoFigureProps, mock } = props;
	const { onProgress, onBuffer, onStart, onPlay, onPause, onEnded, onError } =
		videoFigureProps ?? {};

	const familyId = figure?.family_id ? figure.family_id : props.trackingConfig.familyId;
	const player = useRef<ReactPlayer>();
	const [playState, setPlayState] = useState<PlayState>('idle');
	const prevPlayState = usePreviousRender(playState);

	const payload = figure ? String(figure.payload) : props.payload;
	const videoURL = getPayloadVideoUrl(payload);
	const aspectRatio = getAspectRatio(payload);

	const [sendingTimeUpdates, setSendingTimeUpdates] = useState<boolean>();
	const generateEventReportingHeaders = figure ? figure.generateEventReportingHeaders : null;
	const eventReportingUrl = figure ? figure.eventReportingUrl : null;

	useEffect(() => {
		if (figure?.disableEventReporting) return;

		if (playState === 'playing' && !sendingTimeUpdates) {
			setSendingTimeUpdates(true);
		}

		if (playState !== 'playing' && playState !== 'preparing') {
			setSendingTimeUpdates(false);
		}

		if (prevPlayState === 'playing' || playState === 'playing') {
			logEvent(
				eventReportingUrl,
				{
					familyId,
					eventType: 'playstatechange',
					detail: {
						prevPlayState: prevPlayState,
						playState: playState,
						playPosition: player.current.getCurrentTime(),
						videoSrc: videoURL,
						videoLength: player.current.getDuration()
					},
					params: { ...(props.trackingConfig && props.trackingConfig?.requestParams) }
				},
				generateEventReportingHeaders,
				elementContext
			);
		}
	}, [figure?.disableEventReporting, playState, familyId]);

	/**
	 * If the payload has a `frameborder` attribute on its iframe.  Extract that value and apply
	 * it to the iframe generated by `react-player`
	 */
	useEffect(() => {
		if (typeof figure?.payload === 'string') {
			const frameBorderMatch = figure.payload.match(/frameborder="(\d+)"/);
			if (frameBorderMatch) {
				const frameBorder = frameBorderMatch[1];
				const iframe = document.querySelector(`[data-figure-id="${figure.family_id}"] iframe`);

				if (iframe) {
					iframe.setAttribute('frameborder', frameBorder);
					iframe.setAttribute('data-testid', `video-figure-iframe-${figure.family_id}`);
				}
			}
		}
	}, []);

	if (!ReactPlayer.canPlay(videoURL)) {
		// throw `Unsupported video URL: ${videoURL}`;
		return <div dangerouslySetInnerHTML={{ __html: payload }} />;
	}

	if (mock) {
		return (
			<div css={mockStyles}>
				<MdPlayCircle size={32} />
			</div>
		);
	}

	return (
		<div css={styles({ aspectRatio })} data-figure-id={figure?.family_id}>
			<ReactPlayer
				className="react-player"
				ref={player}
				url={
					figure?.figure_subtype === 'castlabs' || figure?.figure_subtype === 'vzaar'
						? payload
						: videoURL
				}
				onProgress={(state) => {
					if (!figure?.disableEventReporting && sendingTimeUpdates) {
						logEvent(
							eventReportingUrl,
							{
								familyId,
								eventType: 'timeupdate',
								detail: {
									playState: playState,
									playPosition: state.playedSeconds,
									videoSrc: videoURL,
									videoLength: player.current.getDuration()
								},
								params: { ...(props.trackingConfig && props.trackingConfig?.requestParams) }
							},
							generateEventReportingHeaders,
							elementContext
						);
						onProgress?.(state);
					}
				}}
				onBuffer={() => {
					setPlayState('preparing');
					onBuffer?.();
				}}
				onPlay={() => {
					setPlayState('playing');
					onPlay?.();
				}}
				onStart={() => {
					setPlayState('playing');
					onStart?.();
				}}
				onPause={() => {
					setPlayState('paused');
					onPause?.();
				}}
				onEnded={() => {
					setPlayState('ended');
					onEnded?.();
				}}
				onError={onError}
				progressInterval={TIME_UPDATE_INTERVAL}
				width="100%"
				height="100%"
				controls
				config={{
					vimeo: {
						playerOptions: {
							pip: true,
							playsinline: true,
							responsive: true
						}
					}
				}}
			/>
		</div>
	);
};

const getPayloadVideoUrl = (payload: string): string => {
	let url = payload.match(URL_REGEX)?.[0];

	if (url?.includes('w.soundcloud.com/player')) {
		/**
		 * Excludes SC player url and gets only the resource url
		 * https://w.soundcloud.com/player/?url=%resource_url%
		 */
		url = new URL(url).searchParams.get('url');
	}

	return url || '';
};

/* Vimeo payloads can include width and height attributes on the
 * iframe itself. If they do, we'll use that info to determine
 * how much padding-top is required on a container element to
 * ensure that the video is rendered correctly. If the attributes
 * aren't specified, the default aspect ratio is 16:9.
 */
const getAspectRatio = (payload: string): number => {
	if (payload.trim().startsWith('<iframe')) {
		const domParser = new DOMParser();
		const iframe = domParser.parseFromString(payload, 'text/html').body.children[0];
		if (iframe.getAttribute('src').includes('vimeo.com')) {
			const iframeWidth = iframe.getAttribute('width');
			const iframeHeight = iframe.getAttribute('height');

			if (iframeWidth && iframeHeight) {
				return parseInt(iframeWidth) / parseInt(iframeHeight);
			}
		}
	}

	return 16 / 9;
};

const logEvent = (
	eventReportingUrl: string,
	event: VideoEventPayload,
	generateEventReportingHeaders: FigureElement['generateEventReportingHeaders'],
	elementContext: ElementContextData
) => {
	const payload = {
		courseId: elementContext.courseId,
		pageFamilyId: elementContext.pageFamilyId,
		objectFamilyId: event.familyId,
		event: {
			type: 'media_player_event',
			subtype: event.eventType,
			data: event.detail
		}
	};

	let headers = null;

	if (generateEventReportingHeaders) {
		headers = generateEventReportingHeaders();
	}

	postCourseEvent(eventReportingUrl || '/api/courses/v1/events', payload, event.params, headers);
};

const styles = ({ aspectRatio }) => css`
	position: relative;
	padding-top: ${aspectRatio ? (1 / aspectRatio) * 100 : 56.25}%;
	margin-bottom: 15px;

	.react-player {
		position: absolute;
		top: 0;
		left: 0;

		iframe {
			border-width: 2px;
		}
	}
`;

const mockStyles = () => css`
	padding-top: 56.25%;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #333;

	svg {
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
	}
`;

type PlayState = 'playing' | 'paused' | 'ended' | 'preparing' | 'idle';

type VideoEventType = 'timeupdate' | 'playstatechange';

interface VideoEventPayload {
	familyId: string;
	eventType: VideoEventType;
	detail: {
		prevPlayState?: PlayState;
		playState: PlayState;
		playPosition: number;
		videoSrc: string;
		videoLength: number;
	};
	params: any;
}

export default VideoFigure;
