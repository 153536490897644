import React from 'react';

import { css } from '@emotion/react';
import { scaleOrdinal } from 'd3-scale';
import { symbol, symbolCircle } from 'd3-shape';

import { colors as baseColors } from '~/styles/themes';

import { a11yShapes } from './shapesUtils';

import type { ChartElement } from '~/types/WebtextManifest';

interface Props {
	chart: ChartElement;
	isHighContrast: boolean;
	className?: string;
}

const Legend: React.FC<Props> = (props) => {
	const { chart, isHighContrast, className } = props;
	const { orientation, chart_type: chartType, colors } = chart;

	const seriesLabels = chart.data[0].slice(1);

	const colorScale = scaleOrdinal<any, any>().range(colors);
	const shapeScale = scaleOrdinal(a11yShapes);

	const defaultMarker = symbol().type(symbolCircle).size(100)();

	return (
		<div
			css={(theme) => styles(theme, { orientation, chartType, isHighContrast })}
			className={className}>
			{seriesLabels.map((label, idx) => {
				return (
					<div key={idx} className="legend-item">
						{chartType === 'line' && !isHighContrast && (
							<svg className="color" xmlns="http://www.w3.org/2000/svg">
								<path d={defaultMarker} transform="translate(12, 12)" fill={colorScale(idx)} />
							</svg>
						)}

						{chartType === 'bar' && !isHighContrast && (
							<span className="color" style={{ background: colorScale(idx) }} />
						)}

						{(chartType === 'bar' || chartType === 'line') && isHighContrast && (
							<svg className="color" xmlns="http://www.w3.org/2000/svg">
								<g
									transform="translate(10, 10)"
									fill={baseColors.brown}
									stroke={baseColors.brown}
									dangerouslySetInnerHTML={{ __html: shapeScale(idx) }}></g>
							</svg>
						)}

						<span className="label">{label}</span>
					</div>
				);
			})}
		</div>
	);
};

const styles = (theme, options) => css`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	row-gap: 0.5em;
	column-gap: 1.5em;
	padding: 15px 0;

	${options.orientation === 'horizontal' &&
	options.chartType === 'bar' &&
	css`
		border-bottom: 2px solid ${theme.chart.lineColor};
	`}

	${theme.name === 'universal_velvet' &&
	css`
		font-family: ${theme.fonts.app};
	`}

	.legend-item {
		display: flex;
		align-items: center;
		gap: 0.5em;

		.color {
			width: 24px;
			height: 24px;

			${options.chartType === 'bar' &&
			!options.isHighContrast &&
			css`
				border-radius: 2px;
				border: 1px solid black;
			`}
		}

		.label {
			font-size: 14px;
		}
	}
`;

export default Legend;
