import { arrayMoveMutable } from 'array-move';
import { applyPatches, Patch } from 'immer';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import {
	getAddressInstanceIndex,
	getDefaultChildrenResponseInstance,
	getDefaultToggleResponseInstance,
	getParentSectionAddress,
	getSiblingIndex,
	updateResponse
} from '~/components/Outline/helpers';
import {
	OutlineConfig,
	OutlineHierarchyItemType,
	OutlineHierarchyResponses,
	OutlineInstanceAddress,
	OutlineResponseInstance,
	OutlineResponseSection,
	OutlineVariant,
	UserAction,
	ViolatedFillInConstraint
} from '~/components/Outline/types';
import { isNullOrUndefined } from '~/utils';

export interface OutlineState extends OutlineConfig {
	builderFamilyId: string;
	dest: string;
	variant: OutlineVariant;
	readOnly: boolean;

	hierarchyResponses: OutlineHierarchyResponses;

	openedMenuId: string;

	userAction?: UserAction;
	showUserActionToast: boolean;

	onUserUpdate?: () => void;
}

export interface OutlineActions {
	/**
	 * Initializes field in the response tree with an empty value
	 * Helps to keep the text fields that were created with the initial instances number
	 */
	initializeField: (address: OutlineInstanceAddress) => void;
	updateField: (
		address: OutlineInstanceAddress,
		payload: { value: string; violatedConstraint?: ViolatedFillInConstraint }
	) => void;

	addInstance: (address: OutlineInstanceAddress, instanceType: OutlineHierarchyItemType) => void;
	moveInstanceUp: (address: OutlineInstanceAddress) => void;
	moveInstanceDown: (address: OutlineInstanceAddress) => void;
	moveInstance: (
		address: OutlineInstanceAddress,
		direction: 'up' | 'down'
	) => { inversePatches: Array<Patch> };
	deleteInstance: (address: OutlineInstanceAddress) => void;

	updateUserAction: (userAction: UserAction) => void;
	undoUserAction: () => void;
	hideUserActionToast: () => void;

	openMenu: (menuId: string) => void;
	closeAllMenus: () => void;
}

export type OutlineStoreProps = OutlineState;

export type OutlineStore = ReturnType<typeof createOutlineStore>;

export const createOutlineStore = (initialState: OutlineStoreProps) =>
	createWithEqualityFn<OutlineState & OutlineActions>()(
		subscribeWithSelector(
			devtools(
				(set, get) => ({
					...initialState,
					initializeField(address) {
						const { hierarchyResponses } = get();

						const [updatedResponses] = updateResponse<OutlineResponseInstance>({
							hierarchyResponses,
							address,
							responseUpdateRecipe: (instance) => {
								/**
								 * It's possible that `initializeField` will get triggered on the existing field
								 * It happens when previous item in the section is getting deleted and fields addresses shift
								 */
								if (isNullOrUndefined(instance.value)) {
									instance.value = '';
								}
							}
						});

						set({ hierarchyResponses: updatedResponses });
					},
					updateField(address, payload) {
						const { hierarchyResponses, onUserUpdate } = get();
						const { value, violatedConstraint } = payload;

						const [updatedResponses] = updateResponse<OutlineResponseInstance>({
							hierarchyResponses,
							address,
							responseUpdateRecipe: (instance) => {
								instance.value = value;
								instance.violatedConstraint = violatedConstraint;
							}
						});

						set({ hierarchyResponses: updatedResponses });
						onUserUpdate();
					},

					addInstance(address, instanceType) {
						const { hierarchyResponses, onUserUpdate } = get();

						let newInstanceAddress: OutlineInstanceAddress;

						const [updatedResponses, inversePatches] = updateResponse<OutlineResponseSection>({
							hierarchyResponses,
							address,
							responseUpdateRecipe: (section) => {
								const defaultInstance =
									instanceType === OutlineHierarchyItemType.Children
										? getDefaultChildrenResponseInstance()
										: getDefaultToggleResponseInstance();
								section.instances.push(defaultInstance);

								const newInstanceIndex = section.instances.indexOf(defaultInstance);
								newInstanceAddress = address.concat({ instanceIndex: newInstanceIndex });
							}
						});

						set({ hierarchyResponses: updatedResponses });
						get().updateUserAction({
							action: 'addInstance',
							targetAddress: newInstanceAddress,
							inversePatches
						});
						onUserUpdate();
					},
					moveInstanceUp(address) {
						const { inversePatches } = get().moveInstance(address, 'up');
						get().updateUserAction({
							action: 'moveInstanceUp',
							targetAddress: address,
							inversePatches
						});
					},
					moveInstanceDown(address) {
						const { inversePatches } = get().moveInstance(address, 'down');
						get().updateUserAction({
							action: 'moveInstanceDown',
							targetAddress: address,
							inversePatches
						});
					},
					moveInstance(address, direction) {
						const { hierarchyResponses, onUserUpdate } = get();

						const parentSectionAddress = getParentSectionAddress(address);
						const currentIndex = getAddressInstanceIndex(address);
						const newIndex = getSiblingIndex(address, direction);

						const [updatedResponses, inversePatches] = updateResponse<OutlineResponseSection>({
							hierarchyResponses,
							address: parentSectionAddress,
							responseUpdateRecipe: (section) =>
								arrayMoveMutable(section.instances, currentIndex, newIndex)
						});

						set({ hierarchyResponses: updatedResponses });
						onUserUpdate();

						return { inversePatches };
					},
					deleteInstance(address) {
						const { hierarchyResponses, onUserUpdate } = get();

						const parentSectionAddress = getParentSectionAddress(address);
						const instanceIndex = getAddressInstanceIndex(address);

						const [updatedResponses, inversePatches] = updateResponse<OutlineResponseSection>({
							hierarchyResponses,
							address: parentSectionAddress,
							responseUpdateRecipe: (section) => section.instances.splice(instanceIndex, 1)
						});

						set({ hierarchyResponses: updatedResponses });
						get().updateUserAction({
							action: 'deleteInstance',
							targetAddress: address,
							inversePatches
						});
						onUserUpdate();
					},

					updateUserAction(userAction) {
						set({ userAction: userAction, showUserActionToast: true });
					},
					undoUserAction() {
						const { userAction, hierarchyResponses } = get();
						if (!userAction) return;

						const previousResponses = applyPatches(hierarchyResponses, userAction.inversePatches);
						set({ hierarchyResponses: previousResponses, userAction: undefined });
						get().hideUserActionToast();
					},
					hideUserActionToast() {
						set({ showUserActionToast: false });
					},

					openMenu(menuId) {
						set({ openedMenuId: menuId });
					},
					closeAllMenus() {
						set({ openedMenuId: null });
					}
				}),
				{ name: 'OutlineStore', store: initialState.dest }
			)
		)
	);
