import { css, SerializedStyles } from '@emotion/react';

import { styles } from '~/components/WritingTemplate/Unfinished/styles';
import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';

import WTStyle from '../EditMode/styles';

export default (theme: Theme): SerializedStyles => css`
	${WTStyle(theme)}
	ol,
	ul {
		padding-left: 24px;
		margin: 0;
	}
	li {
		font-size: 18px;
	}
`;

export const viewModeText = css`
	& * {
		font-size: 18px !important;
		@media (max-width: ${breakpoints.small}) {
			font-size: 16px !important;

			:not(strong, strong *, b, b *) {
				font-weight: 400;
			}
		}
	}
`;

export const footerStyle = css`
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media (max-width: ${breakpoints.small}) {
		margin-top: 8px;
		flex-direction: column;
	}
`;

export const paraStyle = (): SerializedStyles => css`
	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

export const timeStyle = (theme: Theme): SerializedStyles => css`
	color: ${theme.name === ThemeName.UNIVERSAL_VELVET
		? '#545454'
		: getThemeItem(theme.colors.text, theme)};

	flex-basis: 270px;
	@media (max-width: ${breakpoints.small}) {
		flex-basis: unset;
		width: 100%;
		font-size: 12px;
		margin: 5px 0 30px 0;
	}
`;

export const buttonWrap = css`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: flex-end;

	button {
		margin-left: 5px;
		margin-right: 0;
		margin-top: 5px;
	}

	@media (max-width: ${breakpoints.small}) {
		width: 100%;
		button {
			margin-top: 0;
			margin-left: 0;
		}
	}
`;

export const wrapperStyle = (theme) => css`
	max-width: ${theme.layout.contentColumnWidth};
`;

export const lockStyles = (theme) => css`
	${styles(theme)}
	padding: 0;

	.downstream-builder {
		padding-left: 10px;
		margin-bottom: 5px;
	}
`;

export const outputImageStyle = () => css`
	max-width: 100%;
`;

export const userFillsInputsWrapper = () => css`
	display: block;
	margin-top: 10px;
`;

export const pdfPreviewStyle = () => css`
	display: inline-block;
	-webkit-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
	box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
`;

export const spreadsheetWrapper = () => css`
	margin-top: 20px;
`;

export const generatingLoaderStyle = () => css`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 13px;
`;
