import React, { useEffect, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';
import { geoAlbersUsa, geoPath } from 'd3-geo';
import { geoRobinson } from 'd3-geo-projection';
import { select } from 'd3-selection';
import { zoom, zoomIdentity, zoomTransform } from 'd3-zoom';
import * as topojson from 'topojson-client';

import { Theme } from '~/styles/themes';
import { GeoMapType } from '~/types';
import { getColorNameByHex } from '~/utils/hexToColorName';

import { getNeighborDirection, getTooltip } from './helpers';
import { geoMapStyles } from './styles';
// eslint-disable-next-line import/order
import state_neighbors from './state_neighbors';
import world_neighbors from './world_neighbors';

import type { GeoMapElement } from '~/types/WebtextManifest';

interface Props {
	figure: GeoMapElement;
	initialScale?: number;
	getTopology?: (type: GeoMapType) => Promise<Record<string, any>>;
}

const TOPO_JSON_URL_MAP = {
	[GeoMapType.US]: 'https://s3.amazonaws.com/assets.webtexts.com/maps/topojson_USA.min.json',
	[GeoMapType.World]: 'https://s3.amazonaws.com/assets.webtexts.com/maps/topojson_WORLD.min.json'
};

const PROPERTY_NAME = {
	[GeoMapType.US]: 'geojson_USA',
	[GeoMapType.World]: 'geojson_WORLD'
};

const NEIGHBORS_JSON = {
	[GeoMapType.US]: state_neighbors,
	[GeoMapType.World]: world_neighbors
};

const DEFAULT_US_SCALE = 940;
const DEFAULT_WORLD_SCALE = 130;

const FIRST_STATE_FOR_ITERATION = 'Washington';
const FIRST_COUNTRY_FOR_ITERATION = 'United States';

const MIN_SCALE = 1;
const MAX_SCALE = 16;
const KEYBOARD_SCALE_DELTA = 2;

const TRANSITION_DURATION = 500;

const getTopologyDefault = (type: GeoMapType): Promise<Record<string, any>> => {
	return fetch(TOPO_JSON_URL_MAP[type]).then((res) => res.json());
};

const GeoMap: React.FC<Props> = (props) => {
	const { figure, initialScale, getTopology = getTopologyDefault } = props;
	const { data, colors, geomap_type } = figure;

	const [geoJsonData, setGeoJsonData] = useState(null);
	const [activeFeature, setActiveFeature] = useState(null);
	const [featureInFocus, setFeatureInFocus] = useState(null);

	const [centeredItem, setCenteredItem] = useState(null);
	const [isLegendAriaHidden, setLegendAriaHidden] = useState(true);

	const svgRef = useRef<SVGSVGElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const theme: Theme = useTheme();

	useEffect(() => {
		getTopology(geomap_type).then((topology) => {
			const property = PROPERTY_NAME[geomap_type];
			const geojsonFromTopojson = topojson.feature(topology, topology.objects[property]);
			setGeoJsonData(geojsonFromTopojson);
		});
	}, [geomap_type]);

	const margin = {
		top: +figure.margins[0] || 0,
		right: +figure.margins[1] || 0,
		bottom: +figure.margins[2] || 0,
		left: +figure.margins[3] || 0
	};

	const themeContentColumnWidth = parseInt(theme.layout.contentColumnWidth, 10);

	const width = themeContentColumnWidth || 700;
	const height = 480;

	const viewBoxWidth = width - margin.left - margin.right;
	const viewBoxHeight = height - margin.top - margin.bottom;

	const svg: any = select(svgRef.current);
	const g = svg.select('g');

	const onZoom = (event) => {
		const { transform } = event;
		g.attr('transform', transform);
		g.attr('stroke-width', 1 / transform.k);
	};

	const mapZoom = zoom().scaleExtent([MIN_SCALE, MAX_SCALE]).on('zoom', onZoom);
	svg.call(mapZoom);

	const getProjection = () => {
		switch (geomap_type) {
			case GeoMapType.US: {
				return geoAlbersUsa()
					.scale(figure.scale?.value || DEFAULT_US_SCALE)
					.translate([
						(figure.translate && figure.translate?.value[0]) || viewBoxWidth / 2,
						(figure.translate && figure.translate?.value[1]) || viewBoxHeight / 2
					]);
			}

			case GeoMapType.World: {
				return geoRobinson()
					.scale(figure.scale?.value || DEFAULT_WORLD_SCALE)
					.translate([
						(figure.translate && figure.translate?.value[0]) || viewBoxWidth / 2,
						(figure.translate && figure.translate?.value[1]) || viewBoxHeight / 2
					]);
			}

			default:
				console.warn(`Unrecognized GeoMap type = '${geomap_type}'`);
				break;
		}
	};

	const projection = getProjection();
	const path = geoPath().projection(projection);

	const onContainerClick = (event) => {
		if (event.target.nodeName !== 'PATH') {
			setActiveFeature(null);
		}
	};

	const resetZoom = () => {
		svg
			.transition()
			.duration(TRANSITION_DURATION)
			.call(
				mapZoom.transform,
				zoomIdentity.translate(0, -2).scale(initialScale == null ? 1 : initialScale)
			);
	};

	const centerFeatureWithScale = (feature, scale) => {
		const centroid = path.centroid(feature);

		svg
			.transition()
			.duration(TRANSITION_DURATION)
			.call(
				mapZoom.transform,
				zoomIdentity
					.translate(viewBoxWidth / 2, viewBoxHeight / 2)
					.scale(scale)
					.translate(-centroid[0], -centroid[1])
			);
	};

	const getCenteredFeatureScale = (feature) => {
		const [[x0, y0], [x1, y1]] = path.bounds(feature);

		const kValue = 0.8 / Math.max((x1 - x0) / viewBoxWidth, (y1 - y0) / viewBoxHeight);
		const scale = kValue < 1 ? (1 / kValue) * 2 : kValue;

		return Math.min(scale, MAX_SCALE);
	};

	const centerFeature = (feature) => {
		const scale = getCenteredFeatureScale(feature);
		centerFeatureWithScale(feature, scale);
	};

	const onPathClick = (event, feature) => {
		setActiveFeature(feature);

		if (feature && centeredItem !== feature) {
			// Compute the new map center and scale to zoom to
			event.stopPropagation();
			centerFeature(feature);
			setCenteredItem(feature);
		} else {
			resetZoom();
			setCenteredItem(null);
		}
	};

	const focusFeatureById = (featureId) => {
		document.getElementById(`#${featureId}`).focus({ preventScroll: true });
	};

	const onKeyDown = (e, feature) => {
		switch (e.key) {
			// navigate on arrow keys press
			case 'ArrowDown':
			case 'ArrowUp':
			case 'ArrowLeft':
			case 'ArrowRight':
			case 'Down':
			case 'Up':
			case 'Left':
			case 'Right': {
				e.preventDefault();

				const featureName = feature.properties.name;

				const neighborDirection = getNeighborDirection(e.key);
				const neighborId = NEIGHBORS_JSON[geomap_type][featureName][neighborDirection];

				if (neighborId) focusFeatureById(neighborId);
				break;
			}

			// zoom in on '+' press ('=' or 'NumPad+')
			case '=':
			case '+': {
				const scale = zoomTransform(svg.node()).k * KEYBOARD_SCALE_DELTA;
				const centeredFeatureScale = getCenteredFeatureScale(feature);
				if (scale > MAX_SCALE || scale > centeredFeatureScale) return;

				centerFeatureWithScale(feature, scale);
				break;
			}

			// zoom out on '-' press ('-' or 'NumPad-')
			case '-': {
				const scale = zoomTransform(svg.node()).k / KEYBOARD_SCALE_DELTA;
				if (scale <= MIN_SCALE) {
					resetZoom();
					return;
				}

				centerFeatureWithScale(feature, scale);
				break;
			}

			// center on Enter press
			case 'Enter': {
				const isCentered = getCenteredFeatureScale(feature) === zoomTransform(svg.node()).k;
				return isCentered ? resetZoom() : centerFeature(feature);
			}

			// reset zoom on Esc press
			case 'Escape':
			case 'Esc': {
				resetZoom();
				break;
			}

			// announce the legend on 'l' press
			case 'l': {
				setLegendAriaHidden(false);

				setTimeout(() => {
					setLegendAriaHidden(true);
				}, 1000);
				break;
			}

			default:
				break;
		}
	};

	const onFeatureFocus = (feature) => {
		setFeatureInFocus(feature);

		const currentScale = zoomTransform(svg.node()).k;
		if (currentScale <= MIN_SCALE) return;

		const centeredFeatureScale = getCenteredFeatureScale(feature);
		const scale = currentScale > centeredFeatureScale ? centeredFeatureScale : currentScale;

		centerFeatureWithScale(feature, scale);
	};

	const isCurrentFeatureInFocus = (feature) => {
		return featureInFocus && featureInFocus.properties.name === feature.properties.name;
	};

	const getFeatureTabIndex = (feature) => {
		switch (geomap_type) {
			case GeoMapType.US: {
				return feature.properties.name === FIRST_STATE_FOR_ITERATION ? 0 : -1;
			}

			case GeoMapType.World: {
				return feature.properties.name === FIRST_COUNTRY_FOR_ITERATION ? 0 : -1;
			}

			default:
				console.warn(`Unrecognized GeoMap type = '${geomap_type}'`);
				break;
		}
	};

	// Apply aria-hidden=true when focus is NOT in the application (for direct input mode)
	const isAriaHidden = !wrapperRef.current?.contains(document.activeElement);

	return (
		<div
			onClick={onContainerClick}
			css={geoMapStyles}
			role="application"
			ref={wrapperRef}
			aria-hidden={isAriaHidden}
			onKeyDown={(e) => onKeyDown(e, featureInFocus)}
			style={{
				maxWidth: width,
				height,
				padding: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`
			}}>
			<svg
				ref={svgRef}
				viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
				preserveAspectRatio="xMidYMid meet"
				onClick={resetZoom}>
				<g className="features">
					{geoJsonData &&
						geoJsonData.features.map((feature, idx) => {
							const featureName = feature.properties.name;
							const featureData = data[featureName];

							const groupId = featureData ? data[featureName][0] : null;
							const fill =
								featureData && groupId
									? colors.find((group) => group.id === groupId)?.color
									: '#ffffff';

							return (
								<a
									id={`#${feature.properties.name}`}
									key={idx}
									aria-controls="geomap-tooltip"
									tabIndex={getFeatureTabIndex(feature)}
									onFocus={() => onFeatureFocus(feature)}
									onBlur={() => setFeatureInFocus(null)}>
									<path
										style={
											featureInFocus
												? isCurrentFeatureInFocus(feature)
													? { stroke: '#005fcc' }
													: { opacity: '0.5' }
												: {}
										}
										d={path(feature)}
										fill={fill}
										onClick={(e) => onPathClick(e, feature)}
										onMouseOver={() => setActiveFeature(feature)}
										onMouseOut={() => setActiveFeature(null)}
									/>
								</a>
							);
						})}
				</g>
			</svg>

			<div className="tooltip" aria-hidden={isAriaHidden}>
				{(featureInFocus || activeFeature) && (
					<div
						id="geomap-tooltip"
						role="region"
						aria-live="assertive"
						dangerouslySetInnerHTML={{
							__html: getTooltip((featureInFocus || activeFeature).properties.name, figure)
						}}
					/>
				)}
			</div>

			<div
				id="geomap-legend"
				className="legend"
				role="region"
				aria-live="assertive"
				aria-atomic="true" // makes the screen reader always present the live region as a whole, even if only part of the region changes
				aria-hidden={isLegendAriaHidden}>
				<table>
					<tbody>
						{colors.map(({ name, color, id }) => (
							<tr key={id}>
								<td className="legend-color">
									<div style={{ backgroundColor: color, height: '17px', width: '34px' }}>
										<span className="visually-hidden">{getColorNameByHex(color)}</span>
									</div>
								</td>
								<td className="legend-name">
									{name}
									<span className="visually-hidden">,</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default GeoMap;
