import * as React from 'react';

export function PapersMagnifyingGlass(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={32.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.762833777925626px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={28.73} fill="currentColor" />
			<path
				d="M41.29 39.81a6.12 6.12 0 1 0-1.44 1.44l4.92 4.91a1 1 0 0 0 .69.3 1.06 1.06 0 0 0 .69-.3 1 1 0 0 0 0-1.44Zm-9.07-3.55a4.09 4.09 0 1 1 4.09 4.08 4.1 4.1 0 0 1-4.09-4.08Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M45.48 17.9H23a1 1 0 0 0-1 1v28.58a1 1 0 0 0 1 1h3.1v3.2a1 1 0 0 0 1 1h22.47a1 1 0 0 0 1-1V27.93a3 3 0 0 0-.9-2.17l-2.87-2.88a2.44 2.44 0 0 0-.29-.27v-3.69a1 1 0 0 0-1-1ZM24.07 46.46V19.94h20.4V22H27.12a1 1 0 0 0-1 1v23.52Zm4.08 4.22V24h14.28v5.07a1 1 0 0 0 1 1.05h5.16v20.56Zm20.1-23.49a1 1 0 0 1 .3.73v.17h-4.08V24h.18a1 1 0 0 1 .69.3Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
