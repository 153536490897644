import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, getThemeItem, Theme } from '~/styles/themes';

/**
 * Need to do some trickery to negate the global side padding.
 *
 * flex and 100vw seems like a nice solution to me.
 */
export default (theme: Theme): SerializedStyles => {
	const {
		colors: { success, warning },
		fonts: { 'haas-grotesk-all': haasGrotesk }
	} = theme;
	const successColor = getThemeItem(success, theme);
	const warningColor = getThemeItem(warning, theme);

	return css`
		.video-recorder-container {
			height: 705px;
			margin-bottom: 20px;

			@media (max-width: ${breakpoints.small}) {
				height: 500px;
			}
		}

		.bottom-container {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			.attributions {
				.credits {
					font-style: italic;
				}
			}

			.transcript-label {
				margin-top: 15px;

				a {
					color: ${theme.colors.primary};
					text-decoration: underline;
					font-weight: 500;
				}
			}

			.check-work-feedback {
				align-self: flex-end;
				text-align: right;

				.success {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 8px;
					color: ${successColor};
					font-weight: 500;

					svg {
						font-size: 20px;
					}
				}

				.failure {
					color: ${warningColor};
				}

				.instructor-grade-container {
					margin-top: 10px;
					margin-bottom: 10px;

					.grade {
						font-weight: bold;
					}
				}
			}

			.status-and-button {
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				align-self: flex-end;
				gap: 1em;

				.status-label {
					line-height: 1.3;
					font-family: ${haasGrotesk};
					color: #545454;
				}
			}

			@media (max-width: ${breakpoints.small}) {
				.status-and-button {
					flex-direction: column;
					align-items: unset;
					gap: 0.5em;
				}

				button {
					margin-bottom: unset;
				}
			}
		}
	`;
};
