import * as React from 'react';

export function HandsWithPlusSign(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={33.6}
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36 71a35 35 0 1 1 35-35 35 35 0 0 1-35 35Zm0-67.2a32.1 32.1 0 1 0 32.1 32.1A32.14 32.14 0 0 0 36 3.8Z"
				fill="currentColor"
			/>
			<circle cx={36} cy={36} r={29.7} fill="currentColor" />
			<path
				d="m22.7 37.3-.2.1v-6.7a5.93 5.93 0 0 0-6-5.9h-1v17.4l9.8 9.7v4.2h9.4V43.8l-5.4-5.4a6.17 6.17 0 0 0-6.6-1.1Zm9.8 16.8h-5.2v-3l-9.8-9.7V27.1l.2.1a3.75 3.75 0 0 1 2.6 3.6v8.4l-.1.2 6.6 6.6 1.5-1.5-5.1-5.1.2-.1a4 4 0 0 1 4.4.7l4.8 4.8v9.3ZM49.5 30.7v6.7l-.3-.1a5.82 5.82 0 0 0-6.5 1.2l-5.4 5.4v12.3h9.4v-4.3l9.8-9.7V24.8h-1a6.06 6.06 0 0 0-6 5.9Zm5 10.6L44.7 51v3h-5.2v-9.3l4.8-4.8a3.94 3.94 0 0 1 4.4-.7l.2.1-5.1 5.1 1.5 1.5 6.6-6.6-.1-.2v-8.4a3.88 3.88 0 0 1 2.6-3.6l.2-.1v14.3ZM39.8 35.9h-7.6v-5h-5.1v-7.6h5.1v-5.1h7.6v5.1h5.1v7.6h-5.1v5Zm-5.5-2.1h3.3v-5.1h5.1v-3.3h-5.1v-5.1h-3.3v5.1h-5.1v3.3h5.1Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
