import React from 'react';
import { FaClipboardList } from 'react-icons/fa';

import { css, useTheme } from '@emotion/react';

import Loader from '~/components/Loader';
import { UniversalVelvetLeftBorder } from '~/components/pageElements';
import { QuestionType, WebtextQuestion } from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import { useIsUniversalVelvet } from '~/hooks';
import { breakpoints, Theme, ThemeName } from '~/styles/themes';

import InlineQuizInstructions from './InlineQuizInstructions';
import PopupQuizControls, { LeftControls, RightControls } from './PopupQuizControls';
import PopupQuizView from './PopupQuizView';
import ProgressIndicator from './ProgressIndicator';
import QuizResults from './QuizResults';
import { PopupQuiz as PopupQuizType, PopupQuizResults } from './types';

const styles = (theme) => {
	const { popupQuiz } = theme;

	return css`
		max-width: ${theme.layout.contentColumnWidth};
		// TODO: replace these styles with the prefab components we've built since this.
		.popup-quiz-header {
			${popupQuiz.header.fontSize ? `font-size: ${popupQuiz.header.fontSize};` : ''}
			${popupQuiz.header.fontFamily ? `font-family: ${popupQuiz.header.fontFamily};` : ''}
			${popupQuiz.header.fontWeight ? `font-weight: ${popupQuiz.header.fontWeight};` : ''}
			background-color: ${popupQuiz.colors.primary};
			padding: 10px 34px 10px 17px;
			color: ${theme.colors['pure-white']};

			strong {
				// Add !important to override core style '.webtext-content #main-column strong'
				color: ${theme.colors['pure-white']} !important;
			}

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				margin-left: -50px;
			`}
		}

		.popup-quiz-container {
			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				padding-top: 0;
				padding-left: 0;
			`}

			font-family: ${theme.fonts['helvetica']};

			.instructions {
				padding: 10px 0;
				font-weight: bold;
			}
		}

		.offline-message-container {
			padding-bottom: 30px;
		}

		.loading-indicator-container {
			padding-bottom: 50px;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.popup-quiz-prompt {
				font-size: ${popupQuiz.questionPrompt.fontSize};
				font-weight: ${popupQuiz.questionPrompt.fontWeight};
				font-family: ${popupQuiz.questionPrompt.fontFamily} !important;

				@media (max-width: ${breakpoints.small}) {
					font-size: ${popupQuiz.questionPrompt.mobile.fontSize};
				}
			}

			.popup-quiz-divider {
				border: none;
				margin: 32px 0 4px 0;

				@media (max-width: ${breakpoints.small}) {
					margin: 24px 0 4px 0;
				}
			}

			.popup-quiz-controls {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			.uv-header-message {
				font-family: ${theme.fonts.app};
				font-size: 18px;
				margin-top: 16px;
				padding-bottom: 30px;
				border-bottom: 'thin solid ${theme.colors.accent}';
			}

			@media (max-width: ${breakpoints.small}) {
				.controls-right {
					flex-direction: column-reverse;
					width: 100%;
				}
			}
		`}
	`;
};

export interface Props {
	popupQuiz: PopupQuizType;
	unlocked: boolean;
	lockedMessage: string;
	questionCount: number;
	savedAnswerCount: number;
	resetCount: number;
	resultsAvailable: boolean;
	results: PopupQuizResults;
	loaded: boolean;
	quizOpen: boolean;
	resetVisible: boolean;
	resetDisabled: boolean;
	openQuiz: () => void;
	onQuizShowQuestions: () => void;
	onQuizReset: () => void;
	readOnly?: boolean;
	online: boolean;
	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
}

// Represents the element that flows inline with the rest
// of the webtext content.
const InlineQuizView: React.FC<Props> = (props) => {
	const {
		popupQuiz,
		unlocked,
		lockedMessage,
		loaded,
		online,
		questionCount,
		savedAnswerCount,
		resultsAvailable,
		results,
		resetCount,
		resetVisible,
		resetDisabled,
		quizOpen,
		openQuiz,
		onQuizShowQuestions,
		onQuizReset,
		readOnly
	} = props;

	const started = savedAnswerCount > 0;
	const completed = savedAnswerCount === questionCount;

	const questionType = popupQuiz.headingText;

	const headerMessage = completed
		? popupQuiz.ui.messages.completed_header_message
		: (
				popupQuiz.ui.messages.instructions_header_message ||
				'Answer the following <strong>{{totalQuestionsCount()}}</strong> questions to see your results.'
		  ).replace(/\{\{totalQuestionsCount\(\)\}\}/gm, '' + questionCount);

	const isUniversalVelvet = useIsUniversalVelvet();
	const theme = useTheme();

	const renderPopUpQuizContainer = (children: React.ReactNode) => {
		if (!isUniversalVelvet) {
			return <WebtextQuestion noBottomMargin={props.noBottomMargin}>{children}</WebtextQuestion>;
		}

		return children;
	};

	const progressIndicatorDescription = !started
		? `${questionCount} question${questionCount !== 1 ? 's' : ''}`
		: `Question ${savedAnswerCount + 1} of ${questionCount}`;

	return (
		<UniversalVelvetLeftBorder mobilePaddingLeft="12px" mobilePaddingBottom="0px">
			<div css={styles}>
				{headerMessage && !isUniversalVelvet && headerMessage.length && (
					<div className="popup-quiz-header">
						<span dangerouslySetInnerHTML={{ __html: headerMessage }} />
					</div>
				)}

				{renderPopUpQuizContainer(
					<>
						<div className="popup-quiz-container">
							{questionType && questionType.length > 0 && (
								<QuestionType>
									{isUniversalVelvet && <FaClipboardList size="30" aria-hidden />}
									{questionType}
								</QuestionType>
							)}

							{isUniversalVelvet && !completed && (
								<div className="popup-quiz-prompt">{popupQuiz.name}</div>
							)}

							{!online && (
								<div className="offline-message-container">
									Your device must be online in order to continue.
								</div>
							)}

							{!loaded && online && (
								<div
									className="loading-indicator-container"
									style={{ width: '100%', textAlign: 'center' }}>
									<Loader />
									<span>(Loading)</span>
								</div>
							)}

							{loaded && online && (
								<PopupQuizView padding={isUniversalVelvet ? '0' : '20px 0 0'}>
									{!completed && (
										<InlineQuizInstructions
											popupQuiz={popupQuiz}
											unlocked={unlocked}
											lockedMessage={lockedMessage}
											resetCount={resetCount}
										/>
									)}

									{completed && !quizOpen && (
										<QuizResults
											popupQuiz={popupQuiz}
											results={results}
											resultsAvailable={resultsAvailable}
											resultsMessage={results.message}
										/>
									)}
								</PopupQuizView>
							)}
						</div>

						{!completed && isUniversalVelvet && (
							<hr
								aria-hidden
								className="popup-quiz-divider popup-quiz-divider-md"
								style={{ width: '100%' }}
							/>
						)}

						{loaded && online && (
							<PopupQuizControls>
								{!completed && unlocked && (
									<LeftControls>
										<div
											style={{
												paddingLeft: isUniversalVelvet && !completed ? 0 : '10px',
												width: '100%'
											}}>
											<ProgressIndicator
												description={
													isUniversalVelvet
														? progressIndicatorDescription.toUpperCase()
														: progressIndicatorDescription
												}
												completionPercentage={
													questionCount ? Math.floor((savedAnswerCount / questionCount) * 100) : 0
												}
											/>
										</div>
									</LeftControls>
								)}

								<RightControls>
									{completed && !popupQuiz.disable_review && (
										<WebtextButton
											secondary={isUniversalVelvet && resetVisible}
											onClick={onQuizShowQuestions}
											disabled={readOnly}>
											{popupQuiz.ui.buttons.inline_review_assessment_button || 'Edit Responses'}
										</WebtextButton>
									)}
									{completed && resetVisible && (
										<WebtextButton onClick={onQuizReset} disabled={resetDisabled || readOnly}>
											{popupQuiz.ui.buttons.reset_assessment_button || 'Reset Assessment'}
										</WebtextButton>
									)}
									{!started && unlocked && (
										<WebtextButton onClick={openQuiz} disabled={readOnly}>
											{popupQuiz.ui.buttons.begin_assessment_button || 'Begin Assessment'}
										</WebtextButton>
									)}
									{started && !completed && (
										<WebtextButton onClick={openQuiz} disabled={readOnly}>
											{popupQuiz.ui.buttons.resume_assessment_button || 'Complete Assessment'}
										</WebtextButton>
									)}
								</RightControls>
							</PopupQuizControls>
						)}
					</>
				)}
			</div>
		</UniversalVelvetLeftBorder>
	);
};

export default InlineQuizView;
