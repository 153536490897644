import { css } from '@emotion/react';

export const VzaarStyles = () => {
	return css`
		height: 100%;
		width: 100%;
		iframe {
			height: 100%;
			width: 100%;
		}
	`;
};
