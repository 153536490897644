import { format } from 'd3-format';
import { select } from 'd3-selection';
import inRange from 'lodash-es/inRange';

export const valueOf = (value: any) => {
	if (/%$/.test(value))
		return { type: 'percent', value: value.replace(/%$/, '') / 100, label: value };

	if (/^-?\$/.test(value)) {
		return { type: 'money', value: parseFloat(value.replace(/\$/, '')), label: value };
	}

	return { type: 'number', value: parseFloat(value), label: value };
};

export const sanitizeValue = (value: string | number): number => {
	if (!value) return NaN;

	const result = value.toString().replace(/^\$/g, '').replace(/%$/g, '');
	return parseFloat(result) ?? NaN;
};

export const scaleValue = (value: number, basis = 1.0): number =>
	Number.isFinite(value) ? value / basis : NaN;

export const sanitizeAndScaleValue = (value: any, basis) => {
	if (!value) return NaN;

	const result = sanitizeValue(value);
	return scaleValue(result, basis);
};

const moneyFormatter = (moneyValue: number) => {
	const absoluteMoneyValue = Math.abs(moneyValue);
	if (absoluteMoneyValue >= 1e12)
		return format('$~f')(Math.round((moneyValue / 1e12) * 10) / 10) + 'T';

	if (absoluteMoneyValue >= 1e9)
		return format('$~f')(Math.round((moneyValue / 1e9) * 10) / 10) + 'B';

	if (absoluteMoneyValue >= 1e6)
		return format('$~f')(Math.round((moneyValue / 1e6) * 10) / 10) + 'M';

	if (absoluteMoneyValue >= 1e3) return format('$0.0f')(moneyValue / 1e3) + 'K';

	return format('$d')(moneyValue);
};

export const getFormat = (value: string) => {
	switch (value) {
		case 'percent':
			return format('.0%');
		case 'money':
			return moneyFormatter;
		default:
			return format(',');
	}
};

export const getValueAxisFormat = (
	roundToDecimal: number | null | undefined,
	valueType: string
) => {
	const roundTo = roundToDecimal ?? 0; // make default value 0 if roundTo is null or undefined

	switch (valueType) {
		case 'percent':
			return format(`.${roundTo}%`);
		case 'money': {
			const currencyFormat = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'symbol',
				notation: 'compact',
				minimumFractionDigits: roundTo,
				maximumFractionDigits: roundTo
			});

			return (value: number) => currencyFormat.format(value);
		}
		default:
			return format(`,.${roundTo}f`);
	}
};

export type Basis = ReturnType<typeof getBasis>;

export const getBasis = (min: number, max: number, value: string) => {
	const diff = Math.abs(max - min);
	switch (value) {
		case 'percent':
			if (diff <= 1) {
				return 1.0;
			} else {
				return 100.0;
			}
		case 'money':
			return 1.0;
		default:
			return 1.0;
	}
};

export const getTicksAmount = (min: number, max: number, percentage: boolean): number => {
	let diff = Math.abs(max - min);
	if (percentage && diff <= 1) {
		diff = diff * 100;
	}
	// Prevents us from showing the fraction numbers on the smaller ranges
	if (diff <= 5) return diff;

	// Attempts to show percentage as increments of 10%
	if (percentage) return diff / 10;

	return 5;
};

export const parseDate = (date) => {
	date = `${date}`.trim();

	if (/^\d+$/.test(date)) {
		return new Date(+date, 0);
	}

	if (/^(\d{4})-(\d{2})-(\d{2})$/.test(date)) {
		const m = /^(\d{4})-(\d{2})-(\d{2})$/.exec(date);
		return new Date(+m[1], +m[2] - 1, +m[3]); // js months start at 0 hence the -1
	}

	if (/^(\d+)\/(\d+)\/(\d+)$/.test(date)) {
		const m = /^(\d+)\/(\d+)\/(\d+)$/.exec(date);
		return new Date(+m[3], +m[1] - 1, +m[2]); // js months start at 0 hence the -1
	}

	console.warn(
		`The provided date value '${date}' could not be parsed. Please verify this is a correct date.`
	);
	return NaN;
};

interface RelaxLabelsArgs {
	chartGroup: any; // D3 chart group instance
	textLabels: any; // D3 labels instances
	onRepeat: () => void;
	iteration?: number;
}

/**
 * Prevents recursion overflow when there's no space to relax labels further.
 * Usually it takes ~70 iterations to relax 15 labels.
 * But the maximums recursive stack on the usual machine is ~10k+
 */
const maxRelaxLabelsIterations = 2000;

/* eslint-disable @typescript-eslint/no-this-alias */
export const removeLabelsOverlap = (args: RelaxLabelsArgs): void => {
	const { chartGroup, textLabels, onRepeat, iteration = 0 } = args;

	if (textLabels.empty()) return;

	const chart = chartGroup.node().parentNode;
	if (!chart) {
		// The chart component has been re-rendered and the `chartGroup` node doesn't exist in the DOM anymore
		return;
	}

	if (iteration >= maxRelaxLabelsIterations) return;
	const newIteration = iteration + 1;

	const alpha = 2;
	const verticalSpacingBetweenText = 20;

	let repeatRelaxation = false;

	/**
	 * The possible Y range for the text labels is the equal to:
	 * [-(height/2 - textElementHeight); height/2 - textElementHeight]
	 *
	 * Because the textElement position Y is calculated against the center of the chart.
	 * Higher - <0
	 * Lower - >0
	 */
	const { height: chartHeight } = chart.getBoundingClientRect();
	const { height: textElementHeight } = textLabels.nodes()[0].getBoundingClientRect();
	const bottomChartBoundary = chartHeight / 2 - textElementHeight;
	const topChartBoundary = bottomChartBoundary * -1;

	textLabels.each(function (d) {
		const labelElementA = this;
		const labelA = select(labelElementA);
		const labelAy: any = labelA.attr('y');
		const labelATextAnchor = labelA.attr('text-anchor');

		textLabels.each(function (d) {
			const labelElementB = this;
			if (labelElementA === labelElementB) {
				return;
			}

			const labelB: any = select(labelElementB);
			const labelBTextAnchor = labelB.attr('text-anchor');
			if (labelATextAnchor !== labelBTextAnchor) return; // Compare labels only if they on the same side

			const labelBy = labelB.attr('y');
			const deltaY = labelAy - labelBy;
			if (Math.abs(deltaY) > verticalSpacingBetweenText) return; // There's enough space already

			repeatRelaxation = true;

			const sign = deltaY > 0 ? 1 : -1;
			const adjust = sign * alpha;

			const newLabelAy = +labelAy + adjust;
			if (inRange(newLabelAy, topChartBoundary, bottomChartBoundary)) {
				labelA.attr('y', newLabelAy);
			}

			const newLabelBy = +labelBy - adjust;
			if (inRange(newLabelBy, topChartBoundary, bottomChartBoundary)) {
				labelB.attr('y', newLabelBy);
			}
		});
	});

	if (repeatRelaxation) {
		onRepeat();
		removeLabelsOverlap({
			chartGroup,
			textLabels,
			onRepeat,
			iteration: newIteration
		});
	}
};
