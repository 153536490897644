import React from 'react';

const SvgResponseBoard = (props) => (
	<svg className="response-board-icon" width={30} height={28} aria-hidden {...props}>
		<g fill="none" fillRule="evenodd">
			<path fill="#F5F5F5" d="M-384-47h1440v546H-384z" />
			<g fill="#5F01DF" fillRule="nonzero">
				<path d="M23.199 12.613h-2.037c.208.574.321 1.194.321 1.84v7.779c0 .269-.046.528-.13.768h3.366C25.977 23 27 21.966 27 20.695v-4.24c0-2.119-1.705-3.842-3.801-3.842zM5.517 14.453c0-.646.113-1.266.32-1.84H3.802C1.705 12.613 0 14.336 0 16.455v4.24C0 21.966 1.023 23 2.28 23h3.368a2.316 2.316 0 01-.131-.768v-7.779zM15.856 10.387h-4.712c-2.121 0-3.847 1.755-3.847 3.911v7.92c0 .432.345.782.77.782h10.866c.425 0 .77-.35.77-.782v-7.92c0-2.156-1.726-3.91-3.847-3.91zM13.5 0c-2.615 0-4.743 2.163-4.743 4.823a4.84 4.84 0 002.425 4.206 4.654 4.654 0 002.318.616c.841 0 1.632-.224 2.318-.616a4.84 4.84 0 002.425-4.206C18.243 2.163 16.115 0 13.5 0zM4.014 3.71C2.203 3.71.73 5.374.73 7.42c0 2.045 1.473 3.709 3.284 3.709.459 0 .896-.107 1.294-.3.686-.335 1.253-.926 1.603-1.667.246-.52.386-1.113.386-1.743 0-2.045-1.473-3.71-3.283-3.71zM22.257 3.71c-1.811 0-3.284 1.664-3.284 3.71 0 .629.14 1.222.386 1.742.35.74.917 1.332 1.604 1.666.397.194.834.301 1.294.301 1.81 0 3.284-1.664 3.284-3.71 0-2.045-1.474-3.71-3.284-3.71z" />
			</g>
		</g>
	</svg>
);

export default SvgResponseBoard;
