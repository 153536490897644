import React from 'react';

import { css, withTheme } from '@emotion/react';
import cn from 'classnames';

import { ThemeName, ThemeProps } from '~/styles/themes';
import constants from '~/styles/themes/base/constants';

const styles = (theme) => {
	const { popupQuiz } = theme;
	const progressRadius = popupQuiz.progress.indicatorRadius;

	return css`
		flex: 1 0 60%;
		line-height: 0.9;
		min-width: 100px;

		.description {
			display: block;
			font-size: 13px;
			color: ${theme.colors['light-gray']};
			white-space: nowrap;
		}

		.completed {
			display: inline-block;
			height: 6px;
			background-color: ${popupQuiz.progress.completedBackground};
		}

		.pending {
			display: inline-block;
			height: 6px;
			background-color: ${popupQuiz.progress.incompleteBackground};
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.description {
				font-size: ${popupQuiz.progress.description.fontSize};
				font-family: ${popupQuiz.progress.description.fontFamily} !important;
				letter-spacing: 1.45px;
				line-height: 34px;
				color: #545454;

				&.in-question-view {
					line-height: 24px;
					margin-bottom: 4px;
				}

				@media (max-width: ${constants.breakpoints.small}) {
					white-space: break-spaces;
				}
			}

			.completed,
			.pending {
				height: ${popupQuiz.progress.indicatorHeight};
			}

			.completed {
				&.completed-not-0-percent {
					border-radius: ${progressRadius} 0 0 ${progressRadius};
				}

				&.completed-100-percent {
					border-radius: ${progressRadius};
				}
			}

			.pending {
				border-radius: ${progressRadius};

				&.pending-not-0-percent {
					border-radius: 0 ${progressRadius} ${progressRadius} 0;
				}
			}
		`}
	`;
};

interface Props extends ThemeProps {
	description: React.ReactNode;
	completionPercentage: number;
	inQuestionView?: boolean;
}

const ProgressIndicator: React.FunctionComponent<Props> = (props) => {
	const { theme, description, completionPercentage, inQuestionView } = props;

	return (
		<div css={styles(theme)}>
			<span
				className={cn('description', {
					'in-question-view': inQuestionView
				})}>
				{description}
			</span>
			<span
				className={cn('completed', {
					'completed-not-0-percent': completionPercentage !== 0,
					'completed-100-percent': completionPercentage === 100,
					'in-question-view': inQuestionView
				})}
				style={{ width: '' + completionPercentage + '%' }}
			/>
			<span
				className={cn('pending', {
					'pending-not-0-percent': completionPercentage !== 0,
					'in-question-view': inQuestionView
				})}
				style={{ width: '' + (100 - completionPercentage) + '%' }}
			/>
		</div>
	);
};

export default withTheme(ProgressIndicator);
