import { EditorSpecificOptions, CitationResponse, CitationStyle } from './../../../types';

export const formatOutputString = (
	exportString: string,
	options: EditorSpecificOptions,
	withCitations: boolean,
	citationStyle: CitationStyle,
	inEditorCitations: CitationResponse[]
): string => {
	let result: string;

	if (checkEmptyLexicalValue(exportString)) return '';

	/**
	 * Remove spans and codes
	 */
	const spanRemoveRegex = /<\/?span>/gi;
	const codeRemoveRegex = /<\/?code>/gi;
	result = exportString.replace(spanRemoveRegex, '');
	result = exportString.replace(codeRemoveRegex, '');

	/**
	 * Remove paragraphs or paragraphs attributes
	 */
	if (!options.isAllowMultiParagraph) {
		const paragraphRemoveRegex = /<\/?p(.*?)>/gi;
		result = result.replace(paragraphRemoveRegex, '');
	}

	/**
	 * lexical has a different view of text styling intersections
	 * It doesn't save strong -> em, and save strong.italic instead.
	 * Replace it here
	 */
	const italicRegex = /(<strong class="editor-text-bold editor-text-italic">)(.*?)(<\/strong>)/gi;

	/**
	 * Remove editor added attributes
	 */
	const attributeRemoveRegex = /\s?dir="(.*?)"/g;
	const removeClassNamesRegex = /\s?class=".*?"/g;

	result = result
		.replace(italicRegex, '$1<em>$2</em>$3')
		.replace(attributeRemoveRegex, '')
		.replace(removeClassNamesRegex, '')
		/**
		 * For cases where we have added \n new line, by third-party apps
		 */
		.replace(/\n/g, ' ');

	if (withCitations) {
		const markFindRegex = /<mark .*?>.*?<\/mark>/gi;
		result = result.replace(markFindRegex, (mark: string) => {
			const parser = new DOMParser();
			const doc = parser.parseFromString(mark, 'text/html');

			/**
			 * The mark is uniq in this document
			 */
			const markElement = doc.getElementsByTagName('mark')[0];

			/**
			 * Id stored in this attribute
			 */
			const id = markElement.getAttribute('id');
			const markData = inEditorCitations?.find(({ identifier }) => identifier === id);

			/**
			 * if we don't have citation data for this citations return empty
			 */
			if (!markData) return '';

			/** Legacy */
			const deleteSpan = document.createElement('span');
			deleteSpan.classList.add('citation-remove');

			const { markerValue, identifier } = markData;

			const finalMark = document.createElement('mark');
			finalMark.setAttribute('id', identifier);
			finalMark.setAttribute('data-after', markerValue);
			finalMark.innerHTML = deleteSpan.outerHTML;
			finalMark.classList.add(...['redactor-citations', `redactor-citations-${citationStyle}`]);

			return finalMark.outerHTML;
		});
	}

	return result;
};

const getMarksFromHtml = (inputHTML: string): HTMLCollectionOf<HTMLElement> => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(inputHTML, 'text/html');
	return doc.getElementsByTagName('mark');
};

export const formatInEditorCitations = (
	withCitations: boolean,
	inEditorCitations: CitationResponse[],
	formattedString: string
): CitationResponse[] | null => {
	if (!withCitations) return null;
	if (!inEditorCitations || inEditorCitations.length === 0) return [];

	const marks = getMarksFromHtml(formattedString);
	const marksExistIds = Array.from(marks).map((node) => node.getAttribute('id'));

	return inEditorCitations.filter(({ identifier }) => marksExistIds.includes(identifier));
};

/**
 * This function will verify citation by citation style and if style != data, remove not appropriate citations
 */
export const verifyCitationPayload = (
	responseHTML: string,
	citationStyle: CitationStyle
): string => {
	const marks = getMarksFromHtml(responseHTML);
	const inappropriateCitationsIds = Array.from(marks)
		.filter((node) => !node.classList.contains(`redactor-citations-${citationStyle}`))
		.map((node) => node.getAttribute('id'));

	const markFindRegex = /<mark .*?>.*?<\/mark>/gi;
	const result = responseHTML.replace(markFindRegex, (mark) => {
		return inappropriateCitationsIds.some((id) => mark.includes(id)) ? '' : mark;
	});

	return result;
};

const checkEmptyLexicalValue = (lexicalHtmlString: string): boolean => {
	if (!lexicalHtmlString.length) return true;

	/**
	 * On missing value lexical automatically generates string <p><br></p>
	 * This bug is tracked in https://github.com/facebook/lexical/issues/3677
	 */
	const lexicalEmptyValueRegex = /^<p[^>]*><br><\/p>$/;
	return lexicalEmptyValueRegex.test(lexicalHtmlString);
};
