import { css as classCss } from '@emotion/css';
import { css } from '@emotion/react';

import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';

export const tableContainerStyles = (theme: Theme) => {
	const {
		fontSize,
		headerFontSize,
		headerFontWeight,
		rowBackground,
		rowBackgroundOdd,
		strongFontWeight,
		strongFontSize
	} = theme.tableFigure;

	return css`
		flex: 1;
		color: ${getThemeItem(theme.colors.text, theme)};
		font-family: ${getThemeItem(theme.fonts.webtext, theme)};

		* {
			box-sizing: border-box;
		}

		.table-figure-container {
			overflow-x: auto;
		}

		.table-figure-container > table {
			font-size: ${getThemeItem(fontSize, theme)};

			th {
				${headerFontSize && `font-size: ${headerFontSize}`};
				height: ${getThemeItem(theme.global.headerFontSize, theme)};
				font-weight: ${headerFontWeight};
				background: ${getThemeItem(theme.tableFigure.headerBackground, theme)};
				border-right: 2px solid ${theme.colors.white};
				color: ${theme.colors.white};
				padding: 10px;
				text-align: center;

				&:last-child {
					border: none;
				}
			}

			tr {
				background-color: ${getThemeItem(rowBackground, theme)};

				&:nth-of-type(odd) {
					background-color: ${getThemeItem(rowBackgroundOdd, theme)};
				}
			}

			td {
				border-right: 2px solid ${theme.colors.white};
				padding: 10px;

				@media (max-width: ${breakpoints.small}) {
					padding: 2ex;
					vertical-align: top !important;
				}

				&:last-child {
					border: none;
				}

				&.webtext-apa-reference {
					text-indent: -2.5em;
					padding-left: calc(2.5em + 10px);
				}
			}

			ol {
				margin-left: 30px;
				margin-bottom: 30px;
				list-style: decimal;
			}

			ul {
				margin: 0 0 0 30px;
				padding: 0;
				list-style: disc;
			}

			ul li ul {
				margin-top: 8px;
				list-style: circle;
				margin-bottom: 10px;
			}

			li {
				font-size: inherit;
				line-height: inherit;
				margin-top: 5px;
				margin-bottom: 5px;
				padding-left: 5px;
			}

			p {
				font-size: inherit;
			}

			strong {
				${strongFontWeight && `font-weight: ${strongFontWeight}`};
				${strongFontWeight && `font-size: ${strongFontSize}`};
			}
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.table-figure-container > table {
				font-family: ${getThemeItem(theme.fonts.app, theme)};
				font-size: 15px;
				line-height: 18px;

				tr {
					vertical-align: top;
				}

				strong {
					font-family: ${getThemeItem(theme.fonts.app, theme)};
					font-weight: bold;
				}

				thead th {
					text-align: left;
					font-weight: bold;
					height: 52px;
				}

				ul {
					list-style: square;

					li::marker {
						color: ${theme.colors.primary};
						font-size: 1.2em;
					}
				}
			}
		`}
	`;
};

export const tableResponsiveStyles = (
	theme: Theme,
	options: { isEqual: boolean; expandNarrowTable: boolean }
) => classCss`
	table-layout: ${options.isEqual ? 'fixed' : 'auto'};

	${
		options.expandNarrowTable &&
		classCss`
		/**
		*	Forces the table to take the same space as on wide screens.
		* !important is required to override more specific .webtext-figure-asset class
		*/
		@media (max-width: ${breakpoints.small}) {
			width: ${theme.layout.contentColumnWidth || '700px'} !important;
		}
	`
	}
`;
