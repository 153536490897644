import * as React from 'react';

export function Elections(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<g id="b30951e1-088e-40bd-8224-2eea4ffc2d50" data-name="comput">
				<circle cx="36" cy="36" r="34.53" fill="#fff" />
				<path
					d="M36,72A36,36,0,1,1,72,36,36,36,0,0,1,36,72ZM36,2.94A33.06,33.06,0,1,0,69.06,36,33.1,33.1,0,0,0,36,2.94Z"
					fill="currentColor"
				/>
				<circle cx="36" cy="36" r="30.53" fill="currentColor" />
				<path d="M41.42,24l-1.63-1.63L36,26.17,34.53,24.7l-1.66,1.61L36,29.43Z" fill="#fff" />
				<path
					d="M55.68,36.76,50.62,26.64H47l2.43-2.43L38,12.81l-13.08,13,.8.79H21.37L16.32,36.76v6h2.31v9.38H53.37V42.79h2.31ZM38,16.07l8.16,8.15-7,7H33.6l-5.41-5.41ZM22.8,29H28l2.3,2.31H25.62v2.31H46.37V31.26h-4L44.65,29H49.2l3.48,6.93H19.34ZM51.06,49.86H21V42.79H51.1Zm2.31-9.38H18.63v-2.3H53.37Z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
