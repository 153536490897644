import { useContext, useEffect } from 'react';

import { $generateNodesFromSerializedNodes } from '@lexical/clipboard';
import { $generateNodesFromDOM } from '@lexical/html';
import { $isListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes, COMMAND_PRIORITY_LOW, PASTE_COMMAND } from 'lexical';

import { SerializedCitationNode } from '../../../types';
import CitationMetaContext from '../context/CitationMetaContext';
import { getCitationAvailable, getInputValid } from '../helpers/citations.helper';
import { CitationNode } from '../nodes/CitationNode';

export function ClipboardPlugin(): JSX.Element | null {
	const [editor] = useLexicalComposerContext();
	const { inEditorCitations, availableCitations, citationStyle } = useContext(CitationMetaContext);

	useEffect(() => {
		const getAllowedCitationIds = (nodes: SerializedCitationNode[]): string[] =>
			nodes
				?.filter(
					(node) =>
						node.type === 'citation' &&
						getCitationAvailable(node, availableCitations, citationStyle)
				)
				.map((node) => node.identifier);

		return editor.registerCommand(
			PASTE_COMMAND,
			(event) => {
				if (!(event instanceof ClipboardEvent)) {
					return false;
				}

				/**
				 * Code below cover a case when user try to copy citation from one instance to another and the second instance doe not have access to citations state of the first one
				 */
				const lexicalString = event.clipboardData.getData('application/x-lexical-editor');

				if (lexicalString) {
					const payload = JSON.parse(lexicalString);
					const { nodes } = payload;
					const citationNodes: SerializedCitationNode[] = nodes?.filter(
						({ type }) => type === 'citation'
					);

					if (!citationNodes?.length) {
						return false;
					}

					/**
					 * Collect citation that allowed to be pasted in the current instance
					 */
					const allowedCitationIds = getAllowedCitationIds(nodes);

					const editorNodes = $generateNodesFromSerializedNodes(
						payload.nodes.filter(
							(node) => node.type !== 'citation' || allowedCitationIds.includes(node.identifier)
						)
					);

					$insertNodes(editorNodes);
					return true;
				}

				const htmlString = event.clipboardData.getData('text/html');
				const isInputValid = getInputValid(htmlString);

				/**
				 * Catch and disable paste in case if html string is not valid
				 * return true means that paste command was handled and we need to prevent default browser behavior
				 */
				if (!isInputValid) {
					event.preventDefault();
					return true;
				}

				if (htmlString) {
					const parser = new DOMParser();
					const dom = parser.parseFromString(htmlString, 'text/html');

					// Avoid text-align and align attributes on paste that could be grabbed from the source
					dom.querySelectorAll('*').forEach((element: HTMLElement) => {
						element.style.textAlign = '';
						element.removeAttribute('align');
					});

					const nodes = $generateNodesFromDOM(editor, dom);

					// Avoid pasting numbered lists from other sources, they are not supported in builder at least yet
					nodes.forEach((node) => {
						if ($isListNode(node) && node.getListType() === 'number') {
							node.setListType('bullet');
						}
					});

					/**
					 * In case if data stored in a html format we need to create nodes first, then serialize them manually and filter out citations that not allowed to be pasted
					 * In most cases Lexical does this automatically, but mobile devices and some old browsers will require it.
					 */
					const allowedCitationIds = getAllowedCitationIds(
						nodes.map((node) => node.exportJSON() as SerializedCitationNode)
					);

					const allowedNodes = nodes.filter(
						(node) =>
							node.getType() !== 'citation' ||
							allowedCitationIds.includes((node as CitationNode).getIdentifier())
					);
					$insertNodes(allowedNodes);
					return true;
				}

				return false;
			},
			COMMAND_PRIORITY_LOW
		);
	}, [editor, inEditorCitations, availableCitations, citationStyle]);

	return null;
}
