import React, { useCallback } from 'react';

import { css } from 'emotion';
import { useRequest } from 'redux-query-react';

import { GradeDeliveryTooltip } from '@soomo/lib/notebook/components/index';
import GradeCellWithTooltip from './GradeCellWithTooltip';
import BaseGrid, { BaseGridProps, selectSortedFilteredUserIds } from './BaseGrid';
import { useAppSelector } from 'Store/index';
import { selectGradingRequiredPages } from 'Store/selectors';
import { getMetricsQuery } from 'Requests/MetricsQuery';

type Props = Partial<BaseGridProps> & Pick<BaseGridProps, 'height' | 'width' | 'loaded'>;

const GradebookPointsGrid: React.VFC<Props> = (props) => {
	const gradebookColumns = useAppSelector(
		(state) => state.entities.studentGrades.gradebook_columns
	);

	const studentGrades = useAppSelector((state) => state.entities.studentGrades.student_grades);
	const lmsGradebookPrecision = useAppSelector(
		(state) => state.entities.studentGrades.lms_gradebook_precision
	);

	const lmsInstallationId = useAppSelector((state) => state.entities.course.lms_installation_id);
	const timeZone = useAppSelector((state) => state.entities.course.time_zone);

	const headerCellRenderer: BaseGridProps['renderHeaderCell'] = useCallback(
		({ columnIndex }) => (
			<div className={headerCellStyles}>{gradebookColumns[columnIndex].label}</div>
		),
		[gradebookColumns]
	);

	useQueryGradingRequiredChapterMetrics();

	const dataCellRenderer: BaseGridProps['renderGradebookPointsCell'] = useCallback(
		({ columnIndex, user }) => {
			return (
				<GradeCellWithTooltip
					studentId={user.id}
					studentGrade={studentGrades[user.id].column_grades[columnIndex]}
					gradebookColumn={gradebookColumns[columnIndex]}
					lmsGradebookPrecision={lmsGradebookPrecision}
				/>
			);
		},
		[gradebookColumns, lmsGradebookPrecision, studentGrades]
	);

	const dataCellHoverContent: BaseGridProps['dataCellHoverContent'] = useCallback(
		({ user, columnIndex }) => {
			if (user) {
				return (
					<GradeDeliveryTooltip
						possiblePoints={gradebookColumns[columnIndex].possible_points}
						lmsGradebookPrecision={lmsGradebookPrecision}
						timeZone={timeZone}
						studentGrade={studentGrades[user.id].column_grades[columnIndex]}
					/>
				);
			}
		},
		[gradebookColumns, lmsGradebookPrecision, studentGrades, timeZone]
	);

	return (
		<BaseGrid
			{...props}
			columnCount={gradebookColumns.length}
			renderHeaderCell={headerCellRenderer}
			renderGradebookPointsCell={dataCellRenderer}
			dataCellHoverContent={lmsInstallationId ? dataCellHoverContent : undefined}
			hasDynamicRowHeights
		/>
	);
};

/**
 * Query to obtain metrics for the chapters and then match them with the gradebook columns
 * Metrics only needed to display the "Requires action" icon that appears when gradable elements are present
 */
function useQueryGradingRequiredChapterMetrics() {
	const tocId = useAppSelector((state) => state.entities.toc.id);
	const courseId = useAppSelector((state) => state.entities.course.id);
	const gradingRequiredPages = useAppSelector(selectGradingRequiredPages);
	const sortedFilteredUserIds: number[] = useAppSelector(selectSortedFilteredUserIds);

	const [{ isFinished: isMetricsLoaded }] = useRequest(
		gradingRequiredPages.length > 0
			? getMetricsQuery({
					courseId,
					userIds: sortedFilteredUserIds,
					scopeId: tocId,
					groupBy: 'chapter'
			  })
			: null
	);
	return { isMetricsLoaded };
}

export default GradebookPointsGrid;

const headerCellStyles = css`
	padding: 8px;
`;
