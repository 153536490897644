import React, { useCallback, useMemo } from 'react';
import { css } from 'emotion';

import { GradeDeliveryTooltip } from '@soomo/lib/notebook/components/index';
import { roundGradeDenominator, roundGradeNumerator } from '@soomo/lib/notebook/utils/index';
import type { GradebookColumn, StudentGrade } from '@soomo/lib/notebook/types/index';
import { GradebookInteractiveType } from '@soomo/lib/notebook/types/index';

import { updateCurrentViewParams } from 'Actions/uiActions';
import TippyRollover from 'Containers/Views/TippyRollover';
import RequiresActionCell from 'Components/GridCells/RequiresActionCell';
import { useAppDispatch, useAppSelector } from 'Store/index';
import { selectRequiresGradingActionPagesSummaryInChapter } from 'Store/selectors';

interface Props {
	studentId: number;
	studentGrade: StudentGrade | null;
	gradebookColumn: GradebookColumn;
	lmsGradebookPrecision: number;
}

const GradeCellWithTooltip: React.VFC<Props> = ({
	studentId,
	studentGrade,
	gradebookColumn,
	lmsGradebookPrecision
}) => {
	const {
		possible_points: possiblePoints,
		ext: { scope_family_id: scopeFamilyId, interactive_type: interactiveType }
	} = gradebookColumn;

	const lmsInstallationId: number | null = useAppSelector(
		(state) => state.entities.course.lms_installation_id
	);
	const timeZone: string = useAppSelector((state) => state.entities.course.time_zone);

	const requiresAction = useGradeCellRequiresAction(studentId, scopeFamilyId, interactiveType);
	const handleRequiresActionClick = useHandleRequiresActionClick(studentId, scopeFamilyId);

	const cellContent = (
		<div className={cellContentStyles}>
			{roundGradeNumerator(studentGrade?.current_points ?? 0, lmsGradebookPrecision)} /{' '}
			{roundGradeDenominator(possiblePoints, lmsGradebookPrecision)}
			{requiresAction && (
				<RequiresActionCell className="highlight-cell" onClick={handleRequiresActionClick} />
			)}
		</div>
	);

	const tooltipContent = useMemo(
		() => GradeDeliveryTooltip({ studentGrade, possiblePoints, timeZone, lmsGradebookPrecision }),
		[lmsGradebookPrecision, possiblePoints, studentGrade, timeZone]
	);

	return lmsInstallationId ? (
		<TippyRollover variant="none" content={tooltipContent}>
			{cellContent}
		</TippyRollover>
	) : (
		<>{cellContent}</>
	);
};

function useGradeCellRequiresAction(
	studentId: number,
	scopeFamilyId: string,
	interactiveType: string
): boolean {
	const gradingColumnMetrics = useAppSelector(
		(state) => state.entities.metrics[`${studentId}:${scopeFamilyId}`]
	);
	const isGradableColumn = interactiveType === GradebookInteractiveType.GoReact;
	return isGradableColumn && gradingColumnMetrics?.requires_action;
}

function useHandleRequiresActionClick(studentId: number, scopeFamilyId: string) {
	const dispatch = useAppDispatch();
	const requiresActionPageSummary = useAppSelector((state) =>
		selectRequiresGradingActionPagesSummaryInChapter(state, scopeFamilyId)
	)[0];

	return useCallback(() => {
		if (requiresActionPageSummary) {
			dispatch(
				updateCurrentViewParams({
					scope: requiresActionPageSummary.family_id,
					student: studentId
				})
			);
		}
	}, [dispatch, studentId, requiresActionPageSummary]);
}

const cellContentStyles = css`
	display: flex;
	align-items: center;
	gap: 10px;

	.highlight-cell {
		margin: 0;
	}
`;

export default GradeCellWithTooltip;
