import { css, SerializedStyles } from '@emotion/react';

import { OutlineHierarchyItemType, OutlineVariant } from '~/components/Outline/types';
import { breakpoints } from '~/styles/themes';

export const sectionView = (options: {
	depth: number;
	variant: OutlineVariant;
	sectionType: OutlineHierarchyItemType;
}): SerializedStyles => {
	const { depth, variant, sectionType } = options;

	return css`
		--indentation: ${variant === OutlineVariant.REVISE ? '1.3em' : '1.6em'};
		@media (max-width: ${breakpoints.small}) {
			--indentation: 1.3em; // Minimum width to fit 2 digits
		}

		${sectionType === OutlineHierarchyItemType.Children &&
		css`
			/** 
		 * Only the children sections need to have the indentation margin applied
		 * The toggle sections will be rendered within the children sections, inheriting their indentation
		 */
			${getSectionIndentationMargin(depth)};
			${getSectionVerticalMargin(depth, variant)};
		`}
	`;
};

const getSectionIndentationMargin = (depth: number): SerializedStyles => {
	// The first 2 depth levels have the same indentation by design https://www.sketch.com/s/ee08de93-69eb-4314-bedf-5aaa44b1d2d9
	const normalizationOffset = 2;
	const normalizedDepth = Math.max(0, depth - normalizationOffset);
	return css`
		margin-left: calc(var(--indentation) * ${normalizedDepth});
	`;
};

const getSectionVerticalMargin = (depth: number, variant: OutlineVariant): SerializedStyles => css`
	${depth === 1 &&
	variant === OutlineVariant.REVISE &&
	css`
		&:not(:first-of-type) {
			margin-top: 25px;
		}
	`}
`;
