import React, { FC } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import { textField } from '~/components/Outline/components/TextField/styles';
import {
	selectResponseValue,
	selectTemplate,
	useOutlineSelector
} from '~/components/Outline/store';
import { OutlineInstanceAddress } from '~/components/Outline/types';
import { Theme } from '~/styles/themes';

export interface Props {
	address: OutlineInstanceAddress;
}

const ReviseTextField: FC<Props> = (props) => {
	const { address } = props;

	const template = useOutlineSelector((state) => selectTemplate(state, address));
	const { label_type: labelType } = template;

	const responseValue = useOutlineSelector((state) => selectResponseValue(state, address));

	if (!responseValue) return null;
	return (
		<span
			css={(theme) => reviseTextField(theme, { labelType })}
			className="outline-instance-revise-text-field">
			{responseValue}
		</span>
	);
};

const reviseTextField = (
	theme: Theme,
	...textFieldArgs: Parameters<typeof textField>
): SerializedStyles => css`
	${textField(...textFieldArgs)}

	width: fit-content;

	// Applied revise outline for the text field
	border: 1px solid ${theme.colors.lavender};
	padding-left: 5px;
	padding-right: 5px;

	white-space: pre-wrap; // Preserve the newline symbols in the output
	word-break: break-word;
`;

export default ReviseTextField;
