import React, { FC, PropsWithChildren } from 'react';

import {
	ActivityHistory as LibsActivityHistory,
	ActivityHistoryProps
} from '@soomo/lib/notebook/components/index';

import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

import styles from './ActivityHistory.scss';

interface Props extends ActivityHistoryProps {
	hasContentUnder?: boolean;
}

const ActivityHistory: FC<PropsWithChildren<Props>> = (props) => (
	<LibsActivityHistory
		{...props}
		className={styles.ActivityHistoryContainer}
		collapseDelimiter={!props.hasContentUnder}
	/>
);

export default withLibsThemeProvider(ActivityHistory);
