import React from 'react';

export default function DocxDownloadIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.3 65.32" className="docx-download-icon">
			<g fill={props.color}>
				<polygon points="33.9 57.66 33.9 42.92 29.9 42.92 29.9 57.66 22.75 50.51 19.92 53.34 31.9 65.32 43.88 53.34 41.05 50.51 33.9 57.66" />
				<rect x="12.93" y="34.1" width="24.95" height="4" />
				<rect x="12.93" y="26.65" width="24.95" height="4" />
				<path
					d="M15.2,18.54a2,2,0,0,0,2-2.07v0a2,2,0,0,0-2-2.08,2,2,0,0,0-2,2.06v0A2,2,0,0,0,15.2,18.54Z"
					transform="translate(-0.21 -0.13)"
				/>
				<path
					d="M4.72.13V9.51H.21V23H4.72V47.45H23.29v-4H8.72V23H38.09V9.52H8.72V4.13H42.51V47.45h4V.13ZM28,12.59h2.46l1.37,2.17,1.38-2.17h2.4L33,16.36l2.65,3.95H33.21L31.73,18l-1.49,2.33h-2.4l2.65-3.93Zm-7.76,3.86a4,4,0,0,1,4.07-4,3.78,3.78,0,0,1,3.28,1.61l-1.61,1.24a2.07,2.07,0,0,0-1.69-.9,1.91,1.91,0,0,0-1.84,2v0a1.91,1.91,0,0,0,1.84,2.06A2.12,2.12,0,0,0,26,17.58l1.61,1.14a3.89,3.89,0,0,1-3.42,1.75,3.93,3.93,0,0,1-4-4Zm-9.16,0a4,4,0,0,1,2.78-3.78l.36-.11.29-.05a4.42,4.42,0,0,1,.74-.07,4,4,0,0,1,4.15,4v0a4,4,0,0,1-4.17,4,4.42,4.42,0,0,1-.74-.07l-.29-.06-.35-.1A3.94,3.94,0,0,1,11,16.47Zm-.84,0v0a3.61,3.61,0,0,1-1.47,3,4.77,4.77,0,0,1-2.93.88H2.85V12.59h3a4.79,4.79,0,0,1,2.89.84A3.55,3.55,0,0,1,10.19,16.41Z"
					transform="translate(-0.21 -0.13)"
				/>
				<path
					d="M5.86,14.49H5v3.92h.87A1.89,1.89,0,0,0,8,16.46v0A1.9,1.9,0,0,0,5.86,14.49Z"
					transform="translate(-0.21 -0.13)"
				/>
			</g>
		</svg>
	);
}
