import * as React from 'react';

export function Newspaper(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="M51.55 27.65h-6v-7.2a1.13 1.13 0 0 0-1.15-1.15H20.45a1.13 1.13 0 0 0-1.15 1.15V48.1a4.79 4.79 0 0 0 4.75 4.75H48.1a4.79 4.79 0 0 0 4.75-4.75V28.8a1.18 1.18 0 0 0-1.3-1.15ZM28.8 25.2H36a1.15 1.15 0 0 1 0 2.3h-7.2a1.15 1.15 0 0 1 0-2.3ZM39.6 48H25.2a1.15 1.15 0 1 1 0-2.3h14.4a1.13 1.13 0 0 1 1.15 1.15A1.06 1.06 0 0 1 39.6 48Zm0-4.75H25.2a1.14 1.14 0 0 1-1.15-1.15 1.23 1.23 0 0 1 1.15-1.2h14.4a1.15 1.15 0 0 1 0 2.3Zm0-4.75H25.2a1.16 1.16 0 0 1 0-2.31h14.4a1.16 1.16 0 0 1 0 2.31Zm0-4.9H25.2a1.13 1.13 0 0 1-1.15-1.15 1.23 1.23 0 0 1 1.15-1.15h14.4a1.15 1.15 0 0 1 0 2.3ZM50.4 48a2.45 2.45 0 1 1-4.9 0V30h4.9v18Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
