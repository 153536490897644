import React from 'react';

import {
	DeckedPoll as LibsDeckedPoll,
	DeckedPollInjectedProps
} from '@soomo/lib/components/pageElements/PollsDeck/index';
import { PollResultsAliasProps } from '@soomo/lib/components/pageElements/PollQuestion/types';

interface Props extends DeckedPollInjectedProps {
	pollResultsAlias: PollResultsAliasProps;
}

const DeckedPollResultsAlias: React.FC<Props> = (props) => {
	const { pollResultsAlias, ...injectedProps } = props;

	return <LibsDeckedPoll {...injectedProps} {...pollResultsAlias} online readOnly />;
};

export default DeckedPollResultsAlias;
