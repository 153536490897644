import {
	getCurrentPoolItem,
	GetCurrentPoolItemArgs
} from '~/components/pageElements/MultipleChoiceQuestionPool/utils';
import { MCQuestionPoolAnswer } from '~/notebook/types';
import { MCQuestionPoolItem } from '~/types/WebtextManifest';

export const getAnsweredPoolQuestions = (
	questions: Array<MCQuestionPoolItem>,
	answerQuestionFamilyId: string
): MCQuestionPoolItem => questions.find((q) => q.family_id === answerQuestionFamilyId);

/**
 * Chose the question that has been answered
 * or the one that is current in the pool
 */
export const getActiveQuestion = (
	args: GetCurrentPoolItemArgs & { answer?: MCQuestionPoolAnswer }
): MCQuestionPoolItem => {
	const { answer, element } = args;
	return answer
		? getAnsweredPoolQuestions(element.questions, answer.question_family_id)
		: getCurrentPoolItem(args);
};

export const getActiveAnswer = (
	answers: Array<MCQuestionPoolAnswer>
): MCQuestionPoolAnswer | undefined => answers.find((answer) => !answer.reset_at);

export const getNumResets = (answers: Array<MCQuestionPoolAnswer>): number =>
	answers.filter((answer) => answer.reset_at).length;
