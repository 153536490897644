import React from 'react';

import { TextOptions } from '../WritingTemplate/types';
import styles from './styles';

export interface Props {
	value: string;
	saveOnly?: boolean;
	options?: TextOptions[];
	alwaysInline?: boolean;
}

const Text: React.FC<Props> = ({ value, saveOnly, options = [], alwaysInline }) => (
	<>
		{saveOnly ? (
			<span dangerouslySetInnerHTML={{ __html: value }}></span>
		) : (
			<span
				css={(theme) => styles(theme, options, alwaysInline)}
				dangerouslySetInnerHTML={{ __html: value }}></span>
		)}
	</>
);

export default Text;
