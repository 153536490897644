import * as React from 'react';

export function GraduationCap(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="m55.3 25.92-19-6.62a.62.62 0 0 0-.72 0L16.7 25.92a1.14 1.14 0 0 0-.7 1.15 1.28 1.28 0 0 0 .72 1.15l19 6.77h.87l19-6.77a1.12 1.12 0 0 0 .72-1.15 1.6 1.6 0 0 0-1.01-1.15ZM53.28 41.76V31.54L51 32.4v9.36a2.33 2.33 0 0 0 0 4v5.76a1.13 1.13 0 0 0 1.15 1.15 1.24 1.24 0 0 0 1.15-1.15v-5.73a2.29 2.29 0 0 0 1.15-2 2.14 2.14 0 0 0-1.17-2.03Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36 37.3a2.7 2.7 0 0 1-1.15-.15l-10.66-3.74v5.18c0 1.3 1.3 2.31 3.89 3.17a27.85 27.85 0 0 0 15.84 0c2.59-.86 3.89-1.87 3.89-3.17v-5.18l-10.66 3.74a2.7 2.7 0 0 1-1.15.15Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
