import * as React from 'react';

export function BrainHead(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 99 100">
			<g fill="none">
				<path
					d="M96.426 50.001c0 25.988-21.066 47.055-47.055 47.055-25.987 0-47.054-21.067-47.054-47.055 0-25.987 21.067-47.054 47.054-47.054 25.989 0 47.055 21.067 47.055 47.054z"
					stroke="currentColor"
					strokeWidth={4}
				/>
				<path
					d="M90.975 50.001c0 22.978-18.626 41.604-41.604 41.604-22.976 0-41.602-18.626-41.602-41.604 0-22.977 18.626-41.603 41.602-41.603 22.978 0 41.604 18.626 41.604 41.603"
					fill="currentColor"
				/>
				<path
					d="M69.471 52.982h-3.147c-.869 0-1.552.705-1.552 1.573v9.442c0 .867-.728 1.573-1.595 1.573h-9.441c-.869 0-1.552.704-1.552 1.573v4.825H36.428V57.363c0-1.221-.496-2.405-1.398-3.333-4.059-4.176-5.707-9.907-4.521-15.723 1.352-6.629 6.652-11.998 13.191-13.361 5.215-1.087 10.536.184 14.598 3.488a17.378 17.378 0 0 1 6.452 13.534c0 .341.112.672.316.944l5.664 7.552c.779 1.039.042 2.518-1.259 2.518m3.776-4.405-5.356-7.142c-.154-6.018-2.907-11.621-7.608-15.443-4.8-3.904-11.079-5.407-17.224-4.127-7.752 1.616-14.034 7.97-15.634 15.813-1.443 7.079.777 13.843 5.348 18.545.328.337.529.742.529 1.14v16.18c0 .868.684 1.573 1.553 1.573h18.881c.869 0 1.574-.705 1.574-1.573v-4.826h7.867a4.727 4.727 0 0 0 4.721-4.721v-7.867h1.573c3.888 0 6.108-4.443 3.776-7.552"
					fill="#fff"
				/>
				<path
					d="M53.854 48.262c-.138-.004-1.226-.308-1.851.683a1.57 1.57 0 0 0-.004 1.669c.191.308.28.561.28.795a1.575 1.575 0 0 1-3.148 0V32.527a1.575 1.575 0 0 1 3.142-.132c-.172.709.082 1.32.555 1.686a1.573 1.573 0 0 0 1.509.23c2.14-.795 4.236.826 4.236 2.937 0 2.221-2.321 3.768-4.371 2.9a1.574 1.574 0 1 0-1.227 2.899 6.287 6.287 0 0 0 3.535.4c.32.497.49 1.068.49 1.668a3.151 3.151 0 0 1-3.146 3.147m-9.442 4.72a1.576 1.576 0 0 1-1.575-1.573c0-.234.09-.487.282-.795.69-1.111-.204-2.538-1.51-2.394-2.001.221-3.491-1.342-3.491-3.105 0-.6.17-1.171.489-1.668a6.283 6.283 0 0 0 3.534-.4 1.575 1.575 0 0 0-1.225-2.899c-2.101.889-4.373-.723-4.373-2.9 0-2.114 2.099-3.731 4.236-2.937.51.189 1.08.102 1.51-.23.475-.368.725-.98.554-1.686a1.577 1.577 0 0 1 1.569-1.441c.867 0 1.573.706 1.573 1.574v18.881c0 .867-.706 1.573-1.573 1.573m17.307-15.734c0-3.588-3.014-6.453-6.56-6.287a4.729 4.729 0 0 0-4.453-3.154 4.7 4.7 0 0 0-3.148 1.206 4.695 4.695 0 0 0-3.146-1.206 4.73 4.73 0 0 0-4.454 3.154c-3.534-.165-6.561 2.687-6.561 6.287 0 1.915.891 3.699 2.336 4.872a6.204 6.204 0 0 0-.762 2.995 6.305 6.305 0 0 0 4.725 6.096c-.125 2.631 2.003 4.919 4.716 4.919a4.7 4.7 0 0 0 3.146-1.206 4.705 4.705 0 0 0 3.148 1.206c2.712 0 4.84-2.288 4.714-4.919a6.305 6.305 0 0 0 4.726-6.096 6.204 6.204 0 0 0-.762-2.995 6.293 6.293 0 0 0 2.335-4.872"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
