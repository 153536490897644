import React from 'react';

import { css } from '@emotion/react';

import { useIsUniversalVelvet } from '~/hooks';
import { breakpoints } from '~/styles/themes';

interface Props {
	mobilePaddingLeft?: string;
	mobilePaddingBottom?: string;
}

const UniversalVelvetLeftBorder: React.FC<Props> = (props) => {
	const isUV = useIsUniversalVelvet();

	if (isUV) {
		return (
			<div
				css={({ webtextQuestion, colors }) => css`
					background: ${webtextQuestion.backgroundColor as string};
					border-left: 6px solid ${colors.primary};
					padding-left: 50px;
					@media (max-width: ${breakpoints.small}) {
						padding-left: ${props.mobilePaddingLeft ? props.mobilePaddingLeft : '25px'};
						padding-top: 24px;
						padding-bottom: ${props.mobilePaddingBottom ? props.mobilePaddingBottom : '24px'};
						border-width: 0;
					}
				`}>
				{props.children}
			</div>
		);
	} else {
		return <>{props.children}</>;
	}
};

export default UniversalVelvetLeftBorder;
