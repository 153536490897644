import React, { ReactElement, useMemo } from 'react';

import { useTheme } from '@emotion/react';
import useFontFaceObserver, { FontFace } from 'use-font-face-observer';

import { ThemeName } from '~/styles/themes';

interface Props {
	children?: ReactElement | ((props: { themeFontsLoaded: boolean }) => ReactElement);
}

const FontProvider: React.FC<Props> = (props) => {
	const theme = useTheme();

	const themeFonts = useMemo(() => {
		// Initialize with "Velvet Core Theme" font pack
		const fonts = [
			{
				families: [
					'HelveticaNeueW01-45Ligh',
					'HelveticaNeueW01-46Ligh',
					'HelveticaNeueW01-55Roma',
					'HelveticaNeueW01-56It',
					'HelveticaNeueW01-65Medi',
					'HelveticaNeueW01-66Medi',
					'HelveticaNeueW01-75Bold',
					'HelveticaNeueW01-76Bold',
					'Proxima N W15 Bold',
					'Proxima N W15 Light',
					'Neue Haas Grotesk Disp W0595Bl',
					'NeueHaasGroteskText W01',
					'NHaasGroteskTXW01-56It',
					'NHaasGroteskTXW01-65Md',
					'NHaasGroteskTXW01-66MdI',
					'NHaasGroteskTXW01-75Bd',
					'NHaasGroteskTXW01-76BdI',
					'Neue Haas GroteskDispW0196BlkI'
				],
				url: 'https://fast.fonts.net/cssapi/85e28b62-1821-4c6a-ad87-743a5a3f8fc9.css'
			}
		];

		switch (theme.name) {
			case ThemeName.UNIVERSAL_VELVET:
				fonts.push(
					{
						families: ['Publico'],
						url: 'https://s3.amazonaws.com/assets.soomopublishing.com/fonts/Publico/Publico.css'
					},
					{
						families: ['Neue Haas Grotesk'],
						url: 'https://s3.amazonaws.com/assets.soomopublishing.com/fonts/NeueHaasGrotesk/NeueHaasGrotesk.css'
					}
				);
				break;
			case ThemeName.WGU_VELVET:
				fonts.push({
					families: ['FuturaStd'],
					url: 'https://s3.amazonaws.com/assets.soomopublishing.com/fonts/Futura/Futura.css'
				});
				break;
			case ThemeName.STRAYER_VELVET:
			case ThemeName.STRAYER_VELVET_2:
				fonts.push(
					{
						families: ['Roboto'],
						url: 'https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&subset=latin-ext'
					},
					{
						families: ['Oswald'],
						url: 'https://fonts.googleapis.com/css?family=Oswald:700&subset=latin-ext'
					},
					{
						families: ['OswaldRegular'],
						url: 'https://s3.amazonaws.com/assets.soomopublishing.com/fonts/OswaldRegular/OswaldRegular.css'
					}
				);
				break;
			case ThemeName.QUEENS_VELVET:
				fonts.push(
					{
						families: ['Nunito Sans'],
						url: 'https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,600,600i,700,700i&subset=latin-ext'
					},
					{
						families: ['Montserrat'],
						url: 'https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i,700,700i&subset=latin-ext'
					}
				);
				break;
		}

		return fonts;
	}, [theme.name]);

	const fontFaces = useMemo<FontFace[]>(
		() => themeFonts.flatMap(({ families }) => families.map((family) => ({ family }))),
		[themeFonts]
	);

	const themeFontsLoaded = useFontFaceObserver(fontFaces);

	return (
		<>
			{themeFonts.map(({ url: fontUrl }, index) => (
				<link
					key={`theme-font-${fontUrl}-${index}`}
					type="text/css"
					rel="stylesheet"
					href={fontUrl}
				/>
			))}
			{props.children && (
				<>
					{typeof props.children === 'function'
						? props.children({ themeFontsLoaded })
						: props.children}
				</>
			)}
		</>
	);
};

export default FontProvider;
