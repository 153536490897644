import React, { FC, useCallback, useMemo } from 'react';

import { css, ClassNames } from '@emotion/react';

import { PollProps, PollQuestionView } from '~/components/pageElements/PollQuestion';
import pollStyles from '~/components/pageElements/PollQuestion/styles';
import {
	isPollQuestionProps,
	isPollResultsAliasProps,
	PollResultsAliasProps
} from '~/components/pageElements/PollQuestion/types';
import {
	getPollPromptBodyId,
	getPollQuestionContentId,
	getPollQuestionId
} from '~/components/pageElements/PollQuestion/utils';
import { PollResultsAliasView } from '~/components/pageElements/PollResultsAlias';
import { QuestionPrompt } from '~/components/shared/Question';
import { CollapseIcon, ExpandIcon } from '~/components/shared/QuestionDeck';
import {
	selectCollapsed,
	useDeckCollapseSelector as useCollapseSelector
} from '~/components/shared/QuestionDeck/DeckCollapseStateProvider';
import { deckStyles } from '~/components/shared/QuestionDeck/deckedStyles';
import { useAccessibilityFocus, useCustomEventListener } from '~/hooks';
import { breakpoints } from '~/styles/themes';
import { ScrollToEventDetail, ScrollToPageElementCustomEvent } from '~/types/events';

import { DisplayedQuestionElementProps } from '../MultipleChoiceQuestionPool/types';

/**
 * Props injected by the parent `PollsDeck` component
 * @see PollsDeck
 */
export interface DeckedPollInjectedProps {
	deckIndex?: number;
}

type Props = (PollProps | PollResultsAliasProps) &
	DeckedPollInjectedProps & {
		instructorView?: boolean;
		onVisibilityChange?: DisplayedQuestionElementProps['onVisibilityChange'];
	};

const DeckedPoll: FC<Props> = (props) => {
	const { instructorView, deckIndex, questionFamilyId, onVisibilityChange } = props;
	const { body, answer } = isPollResultsAliasProps(props) ? props.targetPollResults : props;
	const collapsed = useCollapseSelector((state) => selectCollapsed(state, deckIndex));
	const toggleCollapse = useCollapseSelector((state) => state.toggleCollapse);

	const handleToggleCollapse = useCallback(() => {
		toggleCollapse(deckIndex);
		onVisibilityChange?.({
			familyId: questionFamilyId,
			scope: 'toggle_item',
			collapsed: !collapsed
		});
	}, [deckIndex, toggleCollapse, onVisibilityChange, questionFamilyId, collapsed]);

	const [setFocusHeadingRef, setFocusToHeading] = useAccessibilityFocus();

	/**
	 * Handler for the custom event dispatched in the Core/Mobile apps
	 * Event is dispatched when a hyperlink is clicked in the app and the target hash matches the element
	 */
	useCustomEventListener<ScrollToEventDetail>(
		ScrollToPageElementCustomEvent.eventName,
		({ elementId }) => {
			elementId === getPollQuestionId(questionFamilyId) && collapsed && handleToggleCollapse();
		}
	);

	const pollElement = useMemo(
		() =>
			/**
			 * `PollResultsAliasProps` props were made optional to support the immediate rendering of the results
			 * for the instructors both for the questions and the alias components.
			 * We know that during the runtime we won't use the alias-specific props for the instructor
			 */
			instructorView || isPollResultsAliasProps(props) ? (
				<PollResultsAliasView
					{...props}
					targetPollResults={isPollResultsAliasProps(props) ? props.targetPollResults : props}
					instructorView={instructorView}
				/>
			) : (
				<PollQuestionView {...props} setFocusToHeading={setFocusToHeading} />
			),
		[instructorView, props, setFocusToHeading]
	);

	const pollQuestionElementId = isPollQuestionProps(props)
		? getPollQuestionId(questionFamilyId)
		: undefined;
	const pollBodyId = getPollPromptBodyId(questionFamilyId);
	const pollContentId = getPollQuestionContentId(questionFamilyId);
	return (
		<ClassNames>
			{({ cx }) => (
				<div css={[deckStyles, pollDeckStyleOverrides]}>
					<button
						id={pollQuestionElementId}
						ref={setFocusHeadingRef}
						className="prompt-and-pivotar"
						aria-expanded={!collapsed}
						aria-controls={pollContentId}
						onClick={handleToggleCollapse}>
						<div className={cx({ 'answered-question': collapsed && !!answer && !instructorView })}>
							<QuestionPrompt id={pollBodyId} body={body} className="decked-poll-prompt" />
						</div>
						{collapsed ? <ExpandIcon /> : <CollapseIcon />}
					</button>
					<div
						className="decked-content"
						id={pollContentId}
						hidden={collapsed}
						role="group"
						aria-labelledby={pollBodyId}>
						{!collapsed && <div css={pollStyles}>{pollElement}</div>}
					</div>
				</div>
			)}
		</ClassNames>
	);
};

export default DeckedPoll;

const pollDeckStyleOverrides = css`
	.prompt-and-pivotar {
		> svg {
			margin-top: 0.25rem;
		}

		.decked-poll-prompt {
			padding: 0;
		}
	}

	.decked-content {
		padding: 0 1.5rem 1.5rem 1.5rem;

		@media (max-width: ${breakpoints.small}) {
			padding-right: 1rem;
		}

		.unfinished-poll-instruction {
			margin-top: 0;
		}
	}
`;
