import first from 'lodash-es/first';

import { findMatches } from '..';
import { Graph } from '../../Graph';
import { availableFormulas, staticErrorReturns } from '../constants';
import { CellValues, EvaluatedCells, FormulaResult } from '../types';
import {
	calculateInlineFormula,
	getArgumentsArray,
	getArgumentValue,
	getUnsuccessfulValue,
	isInfinite,
	normalizeInlineValue,
	transformArgumentsForInline
} from './utils';
import { validateInput } from './validation';

/**
 * Main function
 */
export const formulaParser = (props: {
	inputValue: string;
	address: string;
	cellValues: CellValues;
	evaluatedCells?: EvaluatedCells;
	graph?: Graph;
	isAllowRecursion?: boolean;
}): FormulaResult => {
	const { inputValue, address, cellValues, graph, evaluatedCells, isAllowRecursion } = props;

	/**
	 * Cycle inside a references stack is the most prior issue and should be returned in the first place w/o any calc;
	 */
	if (graph?.getIsCycleDependency(address)) {
		return staticErrorReturns.circularDependencyError;
	}

	/**
	 * In case if something has been returned from the input validation return it instantly any further calculations will be failed
	 */
	const inputValidation = validateInput(inputValue, cellValues, evaluatedCells);
	if (inputValidation) return inputValidation;

	/**
	 * First iterate over named functions and calculate possible stack of functions; when exist
	 * If the user provided input with named formula, then calculateNamedFunctionsStack will return value with calculated formulas and we will pass this to inline formula calculations.
	 * Otherwise calculateNamedFunctionsStack will return FormulaResult and we will return it.
	 */
	const formulaResult = evaluateFunction({
		input: inputValue,
		enteredValue: inputValue,
		cellValues,
		evaluatedCells,
		isAllowRecursion
	});

	/**
	 * There are still possible post-evaluation errors so we still need this check
	 */
	if ((formulaResult as FormulaResult).success === false) {
		return formulaResult as FormulaResult;
	}

	/**
	 * After calculate remaining inline functions and calculate them
	 */
	const simpleFormulaResult = getInlineFormulaResult({
		inputValue: formulaResult as string,
		evaluated: evaluatedCells,
		cellValues,
		isAllowRecursion
	});

	/**
	 * There are still possible post-evaluation errors so we still need this check
	 */
	if ((simpleFormulaResult as FormulaResult).success === false) {
		return simpleFormulaResult as FormulaResult;
	}

	return {
		success: true,
		error: '',
		result: simpleFormulaResult as string | number
	};
};

/**
 * This JS function will calculate all named Excel function insertion
 * It takes values from simplest one to the most complex
 * example: SUM(1, SUM(2, SUM(3,2))) -> calculates SUM(3,2) result = SUM(1, SUM(2,5)), etc.
 */
const evaluateFunction = (props: {
	input: string;
	enteredValue: string;
	cellValues: CellValues;
	evaluatedCells: EvaluatedCells;
	isAllowRecursion: boolean;
}): string | FormulaResult => {
	const { input, cellValues, evaluatedCells, enteredValue, isAllowRecursion } = props;

	/**
	 * Taking the simplest function with this regex
	 */
	const findSmallestFunctionRangeRegex = /[A-Z]+\([^()]*\)/gi;
	const matches = findMatches(findSmallestFunctionRangeRegex, input);

	/**
	 * No functions found return;
	 */
	if (!matches.length) return input;

	/**
	 * Simplifying string, by replacing function insertion with it's result
	 */
	let error = null;

	const simplify = input.replace(findSmallestFunctionRangeRegex, (match) => {
		const functionResult = getNamedFormulaResult({
			input: match,
			cellValues,
			evaluatedCells,
			isAllowRecursion
		});

		if ((functionResult as FormulaResult).success === false) {
			error = functionResult;
			return null;
		}

		return `${functionResult}`;
	});

	if (error) return error as FormulaResult;

	return evaluateFunction({
		input: simplify,
		cellValues,
		evaluatedCells,
		enteredValue,
		isAllowRecursion
	});
};

export const getNamedFormulaResult = (props: {
	input: string;
	cellValues: CellValues;
	evaluatedCells: EvaluatedCells;
	isAllowRecursion: boolean;
}): number | string | FormulaResult => {
	const { input, cellValues, evaluatedCells, isAllowRecursion } = props;

	/**
	 * We're splitting formula and getting name and arguments list
	 */
	const splitFormulaRegex = /([A-Z]+)\(([^()]*)\)/gi;
	const formulaSplit = splitFormulaRegex.exec(input);

	if (!formulaSplit.length) return staticErrorReturns.nameError;

	const [, enteredName, argumentList] = formulaSplit;
	const formulaFunction = availableFormulas[enteredName.toLocaleLowerCase()];
	const argumentsArray = getArgumentsArray(argumentList);

	const evaluatedValues = argumentsArray.map((argument) =>
		getArgumentValue({
			input: argument,
			cellValues,
			evaluatedCells,
			isArgument: true,
			isAllowRecursion
		})
	);

	if (evaluatedValues.some(getUnsuccessfulValue)) {
		return first(evaluatedValues.filter(getUnsuccessfulValue));
	}

	const evaluatedArguments = evaluatedValues as (string | number)[];
	const { formula } = formulaFunction;
	const formulaResult = formula(evaluatedArguments, cellValues, evaluatedCells, isAllowRecursion);

	/**
	 * This error can't be removed in favor of validation because it's based in the result and result will be calculated
	 * so this error is kind of post-validation and does not rely on the validation of the structure
	 */
	if (isInfinite(formulaResult as number)) {
		return staticErrorReturns.numberError;
	}

	return formulaResult;
};

export const getInlineFormulaResult = (props: {
	inputValue: string;
	evaluated: EvaluatedCells;
	cellValues: CellValues;
	isAllowRecursion: boolean;
}): number | string | FormulaResult => {
	const { inputValue, evaluated, cellValues, isAllowRecursion } = props;

	const formulaString = normalizeInlineValue(inputValue);

	const indArgumentRegex = /^-?([A-Z0-9.$%]+)$/gi;
	if (indArgumentRegex.test(formulaString)) {
		/**
		 * Found only one argument w/o symbols, get it's value and return
		 */
		return getArgumentValue({
			input: formulaString,
			cellValues,
			evaluatedCells: evaluated,
			isAllowRecursion
		});
	}

	const splitFormulaArgumentsRegex =
		/((-?[0-9]\.[0-9]+e\+[0-9]+)|(-?\(.*\))|(-?[A-Z0-9%.:$,]*))([-+*/])*/gi;

	const formulaArguments = findMatches(splitFormulaArgumentsRegex, formulaString);

	if (!formulaArguments.length) return staticErrorReturns.signatureError;

	const getInstantResultCallback = (value) =>
		getInlineFormulaResult({
			inputValue: value,
			evaluated,
			cellValues,
			isAllowRecursion: false
		});

	const argumentsWithSign = transformArgumentsForInline(formulaArguments, getInstantResultCallback);

	if (argumentsWithSign.some(getUnsuccessfulValue)) {
		return first(argumentsWithSign.filter(getUnsuccessfulValue) as FormulaResult[]);
	}

	return calculateInlineFormula(
		argumentsWithSign as string[][],
		cellValues,
		evaluated,
		isAllowRecursion
	) as string | number | FormulaResult;
};
