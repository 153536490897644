const world_neighbors = {
	Afghanistan: {
		u: 'Uzbekistan',
		r: 'Tajikistan',
		d: 'Pakistan',
		l: 'Iran'
	},
	Albania: { u: 'Montenegro', r: 'North Macedonia', d: 'Greece', l: 'Italy' },
	Algeria: { u: 'France', r: 'Tunisia', d: 'Mali', l: 'Morocco' },
	'American Samoa': { u: 'Kiribati', r: 'Cook Islands', d: 'Niue', l: 'Samoa' },
	Andorra: { u: 'France', r: 'France', d: 'Spain', l: 'Spain' },
	Angola: {
		u: 'Democratic Republic of the Congo',
		r: 'Zambia',
		d: 'Namibia',
		l: 'Saint Helena'
	},
	Anguilla: {
		u: 'Bermuda',
		r: 'Antigua and Barbuda',
		d: 'Saint Kitts and Nevis',
		l: 'Virgin Islands, British'
	},
	Antarctica: { u: 'South Africa', r: 'Australia', d: '', l: 'Argentina' },
	'Antigua and Barbuda': {
		u: 'Bermuda',
		r: 'Cape Verde',
		d: 'Montserrat',
		l: 'Saint Kitts and Nevis'
	},
	Argentina: { u: 'Paraguay', r: 'Uruguay', d: 'Antarctica', l: 'Chile' },
	Armenia: { u: 'Georgia', r: 'Azerbaijan', d: 'Iran', l: 'Turkey' },
	Aruba: { u: 'Dominican Republic', r: 'Grenada', d: 'Curaçao', l: 'Colombia' },
	Australia: { u: 'East Timor', r: 'New Zealand', d: 'Antarctica', l: 'Madagascar' },
	Austria: { u: 'Czech Republic', r: 'Slovakia', d: 'Slovenia', l: 'Liechtenstein' },
	Azerbaijan: { u: 'Russia', r: 'Turkmenistan', d: 'Iran', l: 'Armenia' },
	Bahamas: { u: 'Bermuda', r: 'Turks and Caicos Islands', d: 'Cuba', l: 'United States' },
	Bahrain: { u: 'Iran', r: 'Qatar', d: 'Saudi Arabia', l: 'Saudi Arabia' },
	Bangladesh: {
		u: 'India',
		r: 'Myanmar (Burma)',
		d: 'French Southern Territories',
		l: 'India'
	},
	Barbados: {
		u: 'Saint Lucia',
		r: 'Cape Verde',
		d: 'Grenada',
		l: 'Saint Vincent and the Grenadines'
	},
	Belarus: { u: 'Lithuania', r: 'Russia', d: 'Ukraine', l: 'Poland' },
	Belgium: { u: 'Netherlands', r: 'Luxembourg', d: 'France', l: 'United Kingdom' },
	Belize: { u: 'Mexico', r: 'Cayman Islands', d: 'Honduras', l: 'Guatemala' },
	Benin: { u: 'Niger', r: 'Nigeria', d: 'Sao Tome and Principe', l: 'Togo' },
	Bermuda: { u: 'Canada', r: 'Morocco', d: 'Bahamas', l: 'United States' },
	Bhutan: { u: 'China', r: 'China', d: 'India', l: 'India' },
	Bolivia: { u: 'Peru', r: 'Brazil', d: 'Paraguay', l: 'Chile' },
	'Bosnia and Herzegovina': { u: 'Croatia', r: 'Serbia', d: 'Montenegro', l: 'Croatia' },
	Botswana: { u: 'Namibia', r: 'Zimbabwe', d: 'South Africa', l: 'Namibia' },
	Brazil: { u: 'French Guiana', r: 'Namibia', d: 'Uruguay', l: 'Paraguay' },
	'British Indian Ocean Territory': {
		u: 'Maldives',
		r: 'Indonesia',
		d: 'French Southern Territories',
		l: 'Seychelles'
	},
	Brunei: { u: 'Vietnam', r: 'Philippines', d: 'Malaysia', l: 'Thailand' },
	Bulgaria: { u: 'Romania', r: 'Georgia', d: 'Greece', l: 'Serbia' },
	'Burkina Faso': { u: 'Mali', r: 'Mali', d: 'Ghana', l: 'Mali' },
	Burundi: {
		u: 'Rwanda',
		r: 'Tanzania',
		d: 'Tanzania',
		l: 'Democratic Republic of the Congo'
	},
	Cambodia: { u: 'Laos', r: 'Vietnam', d: 'Malaysia', l: 'Thailand' },
	Cameroon: {
		u: 'Chad',
		r: 'Central African Republic',
		d: 'Equatorial Guinea',
		l: 'Nigeria'
	},
	Canada: {
		u: 'Greenland',
		r: 'Saint Pierre and Miquelon',
		d: 'United States',
		l: 'Russia'
	},
	'Cape Verde': { u: 'Iceland', r: 'Gambia', d: 'Saint Helena', l: 'Barbados' },
	'Cayman Islands': { u: 'Cuba', r: 'Jamaica', d: 'Panama', l: 'Mexico' },
	'Central African Republic': {
		u: 'Chad',
		r: 'South Sudan',
		d: 'Democratic Republic of the Congo',
		l: 'Cameroon'
	},
	Chad: { u: 'Libya', r: 'Sudan', d: 'Central African Republic', l: 'Niger' },
	Chile: { u: 'Bolivia', r: 'Argentina', d: 'Antarctica', l: 'Pitcairn' },
	China: { u: 'Mongolia', r: 'North Korea', d: 'Vietnam', l: 'Kazakhstan' },
	Colombia: { u: 'Jamaica', r: 'Venezuela', d: 'Ecuador', l: 'Panama' },
	Comoros: { u: 'Somalia', r: 'Seychelles', d: 'Madagascar', l: 'Mozambique' },
	Congo: {
		u: 'Central African Republic',
		r: 'Democratic Republic of the Congo',
		d: 'Angola',
		l: 'Gabon'
	},
	'Cook Islands': { u: 'Kiribati', r: 'French Polynesia', d: 'Antarctica', l: 'Niue' },
	'Costa Rica': { u: 'Nicaragua', r: 'Panama', d: 'Panama', l: 'Nicaragua' },
	Croatia: { u: 'Hungary', r: 'Bosnia and Herzegovina', d: 'Italy', l: 'Slovenia' },
	Cuba: { u: 'Bahamas', r: 'Haiti', d: 'Cayman Islands', l: 'Mexico' },
	Curaçao: { u: 'Aruba', r: 'Trinidad and Tobago', d: 'Venezuela', l: 'Colombia' },
	Cyprus: { u: 'Turkey', r: 'Lebanon', d: 'Egypt', l: 'Greece' },
	'Czech Republic': { u: 'Poland', r: 'Slovakia', d: 'Austria', l: 'Germany' },
	Denmark: { u: 'Norway', r: 'Sweden', d: 'Germany', l: 'United Kingdom' },
	Djibouti: { u: 'Eritrea', r: 'Yemen', d: 'Somalia', l: 'Ethiopia' },
	Dominica: { u: 'Guadeloupe', r: 'Cape Verde', d: 'Martinique', l: 'Nicaragua' },
	'Dominican Republic': {
		u: 'Turks and Caicos Islands',
		r: 'Puerto Rico',
		d: 'Aruba',
		l: 'Haiti'
	},
	'East Timor': { u: 'Indonesia', r: 'Indonesia', d: 'Australia', l: 'Indonesia' },
	Ecuador: { u: 'Colombia', r: 'Peru', d: 'Peru', l: 'Fiji' },
	Egypt: { u: 'Cyprus', r: 'Israel', d: 'Sudan', l: 'Libya' },
	'El Salvador': { u: 'Honduras', r: 'Honduras', d: 'Antarctica', l: 'Guatemala' },
	'Equatorial Guinea': {
		u: 'Cameroon',
		r: 'Gabon',
		d: 'Gabon',
		l: 'Sao Tome and Principe'
	},
	Eritrea: { u: 'Saudi Arabia', r: 'Yemen', d: 'Djibouti', l: 'Sudan' },
	Estonia: { u: 'Finland', r: 'Russia', d: 'Latvia', l: 'Sweden' },
	Ethiopia: { u: 'Eritrea', r: 'Djibouti', d: 'Kenya', l: 'South Sudan' },
	'Falkland Islands': {
		u: 'Uruguay',
		r: 'South Georgia and the South Sandwich Islands',
		d: 'Antarctica',
		l: 'Argentina'
	},
	'Faroe Islands': { u: 'Iceland', r: 'Norway', d: 'United Kingdom', l: 'Iceland' },
	Fiji: { u: 'Wallis and Futuna', r: 'Tonga', d: 'New Zealand', l: 'Vanuatu' },
	Finland: { u: 'Norway', r: 'Russia', d: 'Estonia', l: 'Sweden' },
	France: { u: 'Belgium', r: 'Switzerland', d: 'Monaco', l: 'Andorra' },
	'French Guiana': { u: 'Trinidad and Tobago', r: 'Brazil', d: 'Brazil', l: 'Suriname' },
	'French Polynesia': { u: 'Kiribati', r: 'Pitcairn', d: 'Antarctica', l: 'Cook Islands' },
	'French Southern Territories': {
		u: 'Sri Lanka',
		r: 'Australia',
		d: 'Heard Island and McDonald Islands',
		l: 'Mauritius'
	},
	Gabon: { u: 'Equatorial Guinea', r: 'Congo', d: 'Congo', l: 'Sao Tome and Principe' },
	Gambia: { u: 'Senegal', r: 'Senegal', d: 'Guinea-Bissau', l: 'Cape Verde' },
	Georgia: { u: 'Russia', r: 'Azerbaijan', d: 'Armenia', l: 'Turkey' },
	Germany: { u: 'Denmark', r: 'Czech Republic', d: 'Switzerland', l: 'Luxembourg' },
	Ghana: { u: 'Burkina Faso', r: 'Togo', d: 'Saint Helena', l: 'Ivory Coast' },
	Gibraltar: { u: 'Spain', r: 'Algeria', d: 'Morocco', l: 'Spain' },
	Greece: { u: 'North Macedonia', r: 'Turkey', d: 'Libya', l: 'Albania' },
	Greenland: { u: '', r: 'Iceland', d: 'Canada', l: 'Canada' },
	Grenada: {
		u: 'Saint Vincent and the Grenadines',
		r: 'Barbados',
		d: 'Trinidad and Tobago',
		l: 'Aruba'
	},
	Guadeloupe: {
		u: 'Antigua and Barbuda',
		r: 'Cape Verde',
		d: 'Dominica',
		l: 'Montserrat'
	},
	Guam: {
		u: 'Northern Mariana Islands',
		r: 'Micronesia, Federated States of',
		d: 'Papua New Guinea',
		l: 'Palau'
	},
	Guatemala: { u: 'Belize', r: 'Honduras', d: 'El Salvador', l: 'Mexico' },
	Guernsey: {
		u: 'United Kingdom',
		r: 'France',
		d: 'Jersey',
		l: 'Saint Pierre and Miquelon'
	},
	Guinea: { u: 'Senegal', r: 'Mali', d: 'Sierra Leone', l: 'Guinea-Bissau' },
	'Guinea-Bissau': { u: 'Senegal', r: 'Guinea', d: 'Guinea', l: 'Cape Verde' },
	Guyana: { u: 'Trinidad and Tobago', r: 'Suriname', d: 'Brazil', l: 'Venezuela' },
	Haiti: {
		u: 'Turks and Caicos Islands',
		r: 'Dominican Republic',
		d: 'Colombia',
		l: 'Cuba'
	},
	'Heard Island and McDonald Islands': {
		u: 'French Southern Territories',
		r: 'Australia',
		d: 'Antarctica',
		l: 'French Southern Territories'
	},
	'Holy See (Vatican City State)': { u: 'San Marino', r: 'Italy', d: 'Malta', l: 'Monaco' },
	Honduras: { u: 'Belize', r: 'Nicaragua', d: 'El Salvador', l: 'Guatemala' },
	'Hong Kong': { u: 'China', r: 'Taiwan', d: 'Philippines', l: 'Macao' },
	Hungary: { u: 'Slovakia', r: 'Romania', d: 'Serbia', l: 'Slovenia' },
	Iceland: { u: 'Greenland', r: 'Faroe Islands', d: 'Ireland', l: 'Greenland' },
	India: { u: 'Nepal', r: 'Bangladesh', d: 'Sri Lanka', l: 'Oman' },
	Indonesia: {
		u: 'Singapore',
		r: 'Papua New Guinea',
		d: 'East Timor',
		l: 'British Indian Ocean Territory'
	},
	Iran: { u: 'Turkmenistan', r: 'Afghanistan', d: 'United Arab Emirates', l: 'Iraq' },
	Iraq: { u: 'Turkey', r: 'Iran', d: 'Kuwait', l: 'Syria' },
	Ireland: { u: 'Iceland', r: 'Isle of Man', d: 'Spain', l: 'Canada' },
	'Isle of Man': {
		u: 'United Kingdom',
		r: 'United Kingdom',
		d: 'United Kingdom',
		l: 'Ireland'
	},
	Israel: { u: 'Lebanon', r: 'Palestine', d: 'Egypt', l: 'Cyprus' },
	Italy: { u: 'Switzerland', r: 'Slovenia', d: 'San Marino', l: 'France' },
	'Ivory Coast': { u: 'Mali', r: 'Ghana', d: 'Saint Helena', l: 'Liberia' },
	Jamaica: { u: 'Cuba', r: 'Haiti', d: 'Colombia', l: 'Belize' },
	Japan: {
		u: 'Russia',
		r: 'United States',
		d: 'Northern Mariana Islands',
		l: 'South Korea'
	},
	Jersey: { u: 'Tanzania', r: 'Mozambique', d: 'Mozambique', l: 'Zambia' },
	Jordan: { u: 'Syria', r: 'Iraq', d: 'Saudi Arabia', l: 'Palestine' },
	Kazakhstan: { u: 'Russia', r: 'Mongolia', d: 'Kyrgyzstan', l: 'Russia' },
	'Kashmir, Indian administered': {
		u: 'China',
		r: 'China',
		d: 'India',
		l: 'Kashmir, Pakistani administered'
	},
	'Kashmir, Pakistani administered': {
		u: 'China',
		r: 'China',
		d: 'Pakistan',
		l: 'Pakistan'
	},
	Kenya: { u: 'Ethiopia', r: 'Somalia', d: 'Tanzania', l: 'Uganda' },
	Kiribati: {
		u: 'United States',
		r: 'Ecuador',
		d: 'French Polynesia',
		l: 'Marshall Islands'
	},
	Kosovo: { u: 'Serbia', r: 'Serbia', d: 'North Macedonia', l: 'Montenegro' },
	Kuwait: { u: 'Iraq', r: 'Iran', d: 'Saudi Arabia', l: 'Iraq' },
	Kyrgyzstan: { u: 'Kazakhstan', r: 'China', d: 'Tajikistan', l: 'Uzbekistan' },
	Laos: { u: 'China', r: 'Vietnam', d: 'Cambodia', l: 'Thailand' },
	Latvia: { u: 'Estonia', r: 'Russia', d: 'Lithuania', l: 'Sweden' },
	Lebanon: { u: 'Syria', r: 'Syria', d: 'Israel', l: 'Cyprus' },
	Lesotho: { u: 'South Africa', r: 'Swaziland', d: 'South Africa', l: 'South Africa' },
	Liberia: { u: 'Guinea', r: 'Ivory Coast', d: 'Saint Helena', l: 'Sierra Leone' },
	Libya: { u: 'Malta', r: 'Egypt', d: 'Niger', l: 'Tunisia' },
	Liechtenstein: { u: 'Switzerland', r: 'Austria', d: 'Switzerland', l: 'Switzerland' },
	Lithuania: { u: 'Latvia', r: 'Belarus', d: 'Belarus', l: 'Poland' },
	Luxembourg: { u: 'Germany', r: 'Germany', d: 'France', l: 'Belgium' },
	Macao: { u: 'China', r: 'Hong Kong', d: 'Philippines', l: 'China' },
	'North Macedonia': { u: 'Kosovo', r: 'Bulgaria', d: 'Greece', l: 'Albania' },
	Madagascar: {
		u: 'Seychelles',
		r: 'Mauritius',
		d: 'French Southern Territories',
		l: 'Comoros'
	},
	Malawi: { u: 'Tanzania', r: 'Mozambique', d: 'Mozambique', l: 'Zambia' },
	Malaysia: { u: 'Brunei', r: 'Philippines', d: 'Singapore', l: 'Sri Lanka' },
	Maldives: {
		u: 'Sri Lanka',
		r: 'Indonesia',
		d: 'British Indian Ocean Territory',
		l: 'Seychelles'
	},
	Mali: { u: 'Algeria', r: 'Burkina Faso', d: 'Ivory Coast', l: 'Senegal' },
	Malta: { u: 'Italy', r: 'Greece', d: 'Libya', l: 'Tunisia' },
	'Marshall Islands': {
		u: 'Russia',
		r: 'Kiribati',
		d: 'Nauru',
		l: 'Micronesia, Federated States of'
	},
	Martinique: { u: 'Dominica', r: 'Cape Verde', d: 'Saint Lucia', l: 'Nicaragua' },
	Mauritania: { u: 'Western Sahara', r: 'Mali', d: 'Senegal', l: 'Cape Verde' },
	Mauritius: {
		u: 'Seychelles',
		r: 'Australia',
		d: 'French Southern Territories',
		l: 'Madagascar'
	},
	Mexico: {
		u: 'United States',
		r: 'Cuba',
		d: 'Guatemala',
		l: 'United States Minor Outlying Islands'
	},
	'Micronesia, Federated States of': {
		u: 'Russia',
		r: 'Marshall Islands',
		d: 'Solomon Islands',
		l: 'Guam'
	},
	Moldova: { u: 'Ukraine', r: 'Ukraine', d: 'Romania', l: 'Romania' },
	Monaco: { u: 'France', r: 'Italy', d: 'Algeria', l: 'France' },
	Mongolia: { u: 'Russia', r: 'China', d: 'China', l: 'Kazakhstan' },
	Montenegro: { u: 'Bosnia and Herzegovina', r: 'Kosovo', d: 'Albania', l: 'Italy' },
	Montserrat: {
		u: 'Saint Kitts and Nevis',
		r: 'Antigua and Barbuda',
		d: 'Guadeloupe',
		l: 'Saint Kitts and Nevis'
	},
	Morocco: { u: 'Gibraltar', r: 'Algeria', d: 'Western Sahara', l: 'Bermuda' },
	Mozambique: { u: 'Tanzania', r: 'Comoros', d: 'Swaziland', l: 'Malawi' },
	'Myanmar (Burma)': { u: 'China', r: 'Thailand', d: 'Indonesia', l: 'Bangladesh' },
	Namibia: { u: 'Angola', r: 'Botswana', d: 'South Africa', l: 'Saint Helena' },
	Nauru: { u: 'Marshall Islands', r: 'Tuvalu', d: 'Vanuatu', l: 'Solomon Islands' },
	Nepal: { u: 'China', r: 'China', d: 'India', l: 'India' },
	Netherlands: { u: 'Norway', r: 'Germany', d: 'Belgium', l: 'United Kingdom' },
	'New Caledonia': { u: 'Vanuatu', r: 'Fiji', d: 'Norfolk Island', l: 'Australia' },
	'New Zealand': { u: 'Norfolk Island', r: 'Chile', d: 'Antarctica', l: 'Australia' },
	Nicaragua: { u: 'Honduras', r: 'Jamaica', d: 'Costa Rica', l: 'Kiribati' },
	Niger: { u: 'Libya', r: 'Chad', d: 'Nigeria', l: 'Burkina Faso' },
	Nigeria: { u: 'Niger', r: 'Cameroon', d: 'Sao Tome and Principe', l: 'Benin' },
	Niue: { u: 'American Samoa', r: 'Cook Islands', d: 'Antarctica', l: 'Tonga' },
	'Norfolk Island': { u: 'New Caledonia', r: 'Chile', d: 'New Zealand', l: 'Australia' },
	'North Korea': { u: 'China', r: 'Japan', d: 'South Korea', l: 'China' },
	'Northern Mariana Islands': { u: 'Japan', r: 'United States', d: 'Guam', l: 'Taiwan' },
	Norway: { u: 'Iceland', r: 'Sweden', d: 'Denmark', l: 'Faroe Islands' },
	Oman: { u: 'Iran', r: 'India', d: 'Seychelles', l: 'United Arab Emirates' },
	Pakistan: { u: 'Afghanistan', r: 'India', d: 'India', l: 'Iran' },
	Palau: { u: 'Japan', r: 'Guam', d: 'Indonesia', l: 'Philippines' },
	Palestine: { u: 'Israel', r: 'Jordan', d: 'Israel', l: 'Israel' },
	Panama: { u: 'Jamaica', r: 'Colombia', d: 'Ecuador', l: 'Costa Rica' },
	'Papua New Guinea': { u: 'Guam', r: 'Solomon Islands', d: 'Australia', l: 'Indonesia' },
	Paraguay: { u: 'Bolivia', r: 'Brazil', d: 'Argentina', l: 'Argentina' },
	Peru: { u: 'Ecuador', r: 'Brazil', d: 'Bolivia', l: 'Fiji' },
	Philippines: { u: 'Taiwan', r: 'Palau', d: 'Indonesia', l: 'Vietnam' },
	Pitcairn: { u: 'Canada', r: 'Chile', d: 'Antarctica', l: 'French Polynesia' },
	Poland: { u: 'Sweden', r: 'Belarus', d: 'Slovakia', l: 'Czech Republic' },
	Portugal: { u: 'Spain', r: 'Spain', d: 'Morocco', l: 'United States' },
	'Puerto Rico': {
		u: 'Bermuda',
		r: 'Virgin Islands, U.S.',
		d: 'Venezuela',
		l: 'Dominican Republic'
	},
	Qatar: { u: 'Iran', r: 'United Arab Emirates', d: 'Saudi Arabia', l: 'Bahrain' },
	Romania: { u: 'Ukraine', r: 'Moldova', d: 'Bulgaria', l: 'Hungary' },
	Russia: { u: '', r: 'Canada', d: 'Mongolia', l: 'Ukraine' },
	Rwanda: {
		u: 'Uganda',
		r: 'Tanzania',
		d: 'Burundi',
		l: 'Democratic Republic of the Congo'
	},
	'Saint Helena': { u: 'Ivory Coast', r: 'Angola', d: 'Antarctica', l: 'Brazil' },
	'Saint Kitts and Nevis': {
		u: 'Anguilla',
		r: 'Antigua and Barbuda',
		d: 'Montserrat',
		l: 'Virgin Islands, U.S.'
	},
	'Saint Lucia': {
		u: 'Martinique',
		r: 'Barbados',
		d: 'Saint Vincent and the Grenadines',
		l: 'Curaçao'
	},
	'Saint Pierre and Miquelon': { u: 'Greenland', r: 'Guernsey', d: 'Bermuda', l: 'Canada' },
	'Saint Vincent and the Grenadines': {
		u: 'Saint Lucia',
		r: 'Barbados',
		d: 'Grenada',
		l: 'Curaçao'
	},
	Samoa: { u: 'Kiribati', r: 'French Polynesia', d: 'Niue', l: 'Wallis and Futuna' },
	'San Marino': { u: 'Italy', r: 'Italy', d: 'Holy See (Vatican City State)', l: 'Italy' },
	'Sao Tome and Principe': {
		u: 'Nigeria',
		r: 'Equatorial Guinea',
		d: 'Saint Helena',
		l: 'Brazil'
	},
	'Saudi Arabia': { u: 'Kuwait', r: 'Qatar', d: 'Yemen', l: 'Jordan' },
	Senegal: { u: 'Mauritania', r: 'Mali', d: 'Gambia', l: 'Cape Verde' },
	Serbia: { u: 'Hungary', r: 'Bulgaria', d: 'Kosovo', l: 'Bosnia and Herzegovina' },
	Seychelles: {
		u: 'Oman',
		r: 'British Indian Ocean Territory',
		d: 'Mauritius',
		l: 'Somalia'
	},
	'Sierra Leone': { u: 'Guinea', r: 'Liberia', d: 'Saint Helena', l: 'Cape Verde' },
	Singapore: { u: 'Malaysia', r: 'Malaysia', d: 'Indonesia', l: 'Indonesia' },
	Slovakia: { u: 'Poland', r: 'Ukraine', d: 'Hungary', l: 'Austria' },
	Slovenia: { u: 'Austria', r: 'Hungary', d: 'Croatia', l: 'Italy' },
	'Solomon Islands': {
		u: 'Micronesia, Federated States of',
		r: 'Nauru',
		d: 'Vanuatu',
		l: 'Papua New Guinea'
	},
	Somalia: { u: 'Djibouti', r: 'India', d: 'Kenya', l: 'Ethiopia' },
	'South Africa': { u: 'Lesotho', r: 'Swaziland', d: 'Antarctica', l: 'Saint Helena' },
	'South Georgia and the South Sandwich Islands': {
		u: 'Saint Helena',
		r: 'South Africa',
		d: 'Antarctica',
		l: 'Falkland Islands'
	},
	'South Korea': { u: 'North Korea', r: 'Japan', d: 'Japan', l: 'Taiwan' },
	'South Sudan': { u: 'Sudan', r: 'Ethiopia', d: 'Uganda', l: 'Central African Republic' },
	Spain: { u: 'France', r: 'Andorra', d: 'Gibraltar', l: 'Portugal' },
	'Sri Lanka': { u: 'India', r: 'Malaysia', d: 'Maldives', l: 'India' },
	Sudan: { u: 'Egypt', r: 'Eritrea', d: 'South Sudan', l: 'Chad' },
	Suriname: { u: 'Trinidad and Tobago', r: 'French Guiana', d: 'Brazil', l: 'Guyana' },
	Swaziland: { u: 'Mozambique', r: 'Mozambique', d: 'South Africa', l: 'South Africa' },
	Sweden: { u: 'Norway', r: 'Finland', d: 'Poland', l: 'Denmark' },
	Switzerland: { u: 'Germany', r: 'Liechtenstein', d: 'Italy', l: 'France' },
	Syria: { u: 'Turkey', r: 'Iraq', d: 'Jordan', l: 'Lebanon' },
	Taiwan: { u: 'Japan', r: 'Northern Mariana Islands', d: 'Philippines', l: 'China' },
	Tajikistan: { u: 'Kyrgyzstan', r: 'China', d: 'Afghanistan', l: 'Uzbekistan' },
	Tanzania: { u: 'Uganda', r: 'Kenya', d: 'Mozambique', l: 'Burundi' },
	Thailand: { u: 'Laos', r: 'Cambodia', d: 'Malaysia', l: 'Myanmar (Burma)' },
	'Democratic Republic of the Congo': {
		u: 'Central African Republic',
		r: 'Uganda',
		d: 'Zambia',
		l: 'Congo'
	},
	Togo: { u: 'Burkina Faso', r: 'Benin', d: 'Sao Tome and Principe', l: 'Ghana' },
	Tonga: { u: 'Wallis and Futuna', r: 'Niue', d: 'New Zealand', l: 'Fiji' },
	'Trinidad and Tobago': { u: 'Grenada', r: 'Cape Verde', d: 'Venezuela', l: 'Curaçao' },
	Tunisia: { u: 'Italy', r: 'Malta', d: 'Libya', l: 'Algeria' },
	Turkey: { u: 'Ukraine', r: 'Armenia', d: 'Cyprus', l: 'Greece' },
	Turkmenistan: { u: 'Uzbekistan', r: 'Afghanistan', d: 'Iran', l: 'Azerbaijan' },
	'Turks and Caicos Islands': {
		u: 'Bermuda',
		r: 'Cape Verde',
		d: 'Dominican Republic',
		l: 'Bahamas'
	},
	Tuvalu: { u: 'Nauru', r: 'Chile', d: 'Wallis and Futuna', l: 'Nauru' },
	Uganda: {
		u: 'South Sudan',
		r: 'Kenya',
		d: 'Rwanda',
		l: 'Democratic Republic of the Congo'
	},
	Ukraine: { u: 'Belarus', r: 'Russia', d: 'Moldova', l: 'Slovakia' },
	'United Arab Emirates': { u: 'Iran', r: 'Oman', d: 'Saudi Arabia', l: 'Qatar' },
	'United Kingdom': { u: 'Faroe Islands', r: 'Denmark', d: 'France', l: 'Isle of Man' },
	'United States': {
		u: 'Canada',
		r: 'Portugal',
		d: 'Mexico',
		l: 'United States Minor Outlying Islands'
	},
	'United States Minor Outlying Islands': {
		u: 'Russia',
		r: 'United States',
		d: 'New Zealand',
		l: 'Philippines'
	},
	Uruguay: { u: 'Brazil', r: 'South Africa', d: 'Falkland Islands', l: 'Argentina' },
	Uzbekistan: { u: 'Kazakhstan', r: 'Kyrgyzstan', d: 'Afghanistan', l: 'Turkmenistan' },
	Vanuatu: { u: 'Solomon Islands', r: 'Fiji', d: 'New Caledonia', l: 'Australia' },
	Venezuela: { u: 'Curaçao', r: 'Guyana', d: 'Brazil', l: 'Colombia' },
	Vietnam: { u: 'China', r: 'Philippines', d: 'Brunei', l: 'Laos' },
	'Virgin Islands, British': {
		u: 'Bermuda',
		r: 'Anguilla',
		d: 'Venezuela',
		l: 'Virgin Islands, U.S.'
	},
	'Virgin Islands, U.S.': {
		u: 'Bermuda',
		r: 'Virgin Islands, British',
		d: 'Venezuela',
		l: 'Puerto Rico'
	},
	'Wallis and Futuna': { u: 'Tuvalu', r: 'Samoa', d: 'Tonga', l: 'Fiji' },
	'Western Sahara': { u: 'Morocco', r: 'Algeria', d: 'Mauritania', l: 'Bermuda' },
	Yemen: { u: 'Saudi Arabia', r: 'Oman', d: 'Djibouti', l: 'Eritrea' },
	Zambia: {
		u: 'Democratic Republic of the Congo',
		r: 'Malawi',
		d: 'Zimbabwe',
		l: 'Angola'
	},
	Zimbabwe: { u: 'Zambia', r: 'Mozambique', d: 'South Africa', l: 'Botswana' }
};

export default world_neighbors;
