import React, { forwardRef, MouseEventHandler, useMemo } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import capitalize from 'lodash-es/capitalize';
import { shallow } from 'zustand/shallow';

import { removeDynamicParts } from '~/components/Outline/helpers';
import {
	selectMaxToggleInstancesReached,
	selectResponseValue,
	selectTemplate,
	selectTemplateKey,
	useOutlineSelector
} from '~/components/Outline/store';
import { OutlineHierarchyItemType, OutlineInstanceAddress } from '~/components/Outline/types';
import { isNullOrUndefined } from '~/utils';

interface Props {
	autoFocus: boolean;
	toggleSectionAddress: OutlineInstanceAddress;
	onClick?: MouseEventHandler<HTMLLIElement>;
}

const ToggleItem = forwardRef<HTMLLIElement, Props>((props, ref) => {
	const { autoFocus, toggleSectionAddress, onClick } = props;

	// Only a single instance can be created per toggle section
	const toggleInstanceAddress = useMemo(
		() => toggleSectionAddress.concat({ instanceIndex: 0 }),
		[toggleSectionAddress]
	);

	const template = useOutlineSelector((state) => selectTemplate(state, toggleInstanceAddress));
	const toggleLabel = removeDynamicParts(template.label).toLowerCase();

	const templateKey = useOutlineSelector((state) =>
		selectTemplateKey(state, toggleInstanceAddress)
	);
	const isMaxToggleInstancesReached = useOutlineSelector((state) =>
		selectMaxToggleInstancesReached(state, templateKey)
	);

	const toggleResponseValue = useOutlineSelector((state) =>
		selectResponseValue(state, toggleInstanceAddress)
	);

	const action = isNullOrUndefined(toggleResponseValue) ? 'add' : 'delete';
	const { addInstance, deleteInstance } = useOutlineSelector(
		(state) => ({
			addInstance: state.addInstance,
			deleteInstance: state.deleteInstance
		}),
		shallow
	);

	const message = useMemo(() => {
		if (action === 'add' && isMaxToggleInstancesReached) {
			const capitalizedToggleLabel = capitalize(toggleLabel);
			return `You’ve reached the maximum number\nof “${capitalizedToggleLabel}” items for the outline.`;
		}
		// Possibly new checks will be added here

		const actionLabel = action === 'add' ? 'Add' : 'Delete';
		return `${actionLabel} ${toggleLabel} for this item`;
	}, [action, isMaxToggleInstancesReached, toggleLabel]);

	const disabled = useMemo(() => {
		if (action === 'add' && isMaxToggleInstancesReached) {
			return true;
		}
		// Possibly new checks will be added here

		return false;
	}, [action, isMaxToggleInstancesReached]);

	const handleToggleClick: MouseEventHandler<HTMLLIElement> = (e) => {
		if (isNullOrUndefined(toggleResponseValue)) {
			addInstance(toggleSectionAddress, OutlineHierarchyItemType.Toggle);
		} else {
			deleteInstance(toggleInstanceAddress);
		}
		onClick?.(e);
	};

	return (
		<MenuItem
			autoFocus={autoFocus}
			ref={ref}
			disableRipple
			disabled={disabled}
			onClick={handleToggleClick}>
			{message}
		</MenuItem>
	);
});

ToggleItem.displayName = 'ToggleItem';

export default ToggleItem;
