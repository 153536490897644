import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';

import { ThemeProvider, useTheme } from '@emotion/react';
import isEmpty from 'lodash-es/isEmpty';

import { withErrorBoundary } from '~/components/GenericErrorBoundary';
import {
	useCustomEventListener,
	useEventListener,
	useInertRef,
	useLocalStorageWithBackup,
	usePreviousRender
} from '~/hooks';
import { useIsInViewport } from '~/hooks/useInViewport';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { JobStatus, UserBackgroundJobEvent } from '~/types';
import { generateID } from '~/utils';
import { scrollToElement } from '~/utils/elements';
import { emitCustomEvent } from '~/utils/emitCustomEvent';
import { localStorageSet } from '~/utils/localStorage';
import NetError from '~/utils/netErrors';
import Polling from '~/utils/polling';
import SerialExecutionQueue from '~/utils/SerialExecutionQueue';
import { waitForElementToBeInViewport } from '~/utils/waitForViewport';

import EditMode from '../EditMode';
import OverlayLoader from '../OverlayLoader';
import Title from '../Title';
import Unfinished from '../Unfinished';
import ViewMode from '../ViewMode';
import { getCitationDataFromConf } from './helpers/importFromConf.helper';
import ValidationsProvider from './providers/TemplateValidationProvider';
import { writingTemplateStyles } from './styles';
import {
	BuilderJobStatus,
	BuilderUserBackgroundJob,
	IDictionary,
	Kinds,
	LmsData,
	PageScrollApi,
	SpreadsheetProps
} from './types';
import {
	BuilderApiEndpointKey,
	getDestsMapByKind,
	getSchemaDefinedDests,
	isOutputWithCitation,
	isReviewTemplate,
	mergeCitationOutput,
	pullSchemaPromptProps
} from './utils';

import type { DraftSaveOptions } from '~/components/WritingTemplate/EditMode/types';

export enum Views {
	/**
	 * `EditPreview` has a few subtle diffs from just a plain `Edit`:
	 * - It doesn't show "Saved at" message
	 * - Autosave isn't enabled
	 * - This state is purely visual and doesn't exist on the server
	 * - It doesn't cause the other tabs to change their view to `Edit`
	 */
	Edit = 'edit',
	EditPreview = 'edit-true', // User clicks "Edit" after submission but doesn't change anything
	Output = 'output',
	OutputOnly = 'output-only',
	Dependency = 'dependency'
}

export interface IApi {
	endpoints?: {
		load: string;
		store: string;
		signedResourcePath: string;
		uploadedImagePath: string;
		generatedDocumentPath: string;
		generatedDocumentReadyPath: string;
		generateDocumentPath: string;
	};
	loadTemplate: (
		id: string,
		defaultValues: IDictionary | undefined,
		abortController: AbortController
	) => Promise<IDictionary | null>;
	storeTemplate: (params: {
		builderId: string;
		body: IDictionary;
		abortController: AbortController;
		version?: number;
		ignoreLocking?: boolean;
	}) => Promise<IDictionary | null>;
	signedImageParameters: (builderId: string, name: string) => Promise<any>;
	getImageUrl: (builderId: string, fileName: string) => Promise<string>;
	generateDocument: (builderId: string) => Promise<any>;
	generatedDocumentReady: (key: string, builderId: string) => Promise<any>;
}

export interface Props {
	builderId: string;
	schema: IDictionary;
	toc: IDictionary;
	api?: IApi;
	imageUploadUrl?: string;
	label?: string;
	readOnly?: boolean;
	lmsData?: LmsData;
	onFileExport?: (url: string) => Promise<void>;
	pageScrollApi?: PageScrollApi;
	spreadsheetProps?: SpreadsheetProps;

	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;

	onBuilderLoaded?: () => void;
	scaleImageJobEvent?: UserBackgroundJobEvent;
	dataTestId?: string;
	generatedDocumentUrl?: string;
	generatingDocument: boolean;
	setGeneratingDocument: (x: boolean) => void;
}

const WritingTemplate: React.FC<Props> = ({
	builderId,
	schema,
	toc,
	api,
	imageUploadUrl,
	label,
	readOnly,
	lmsData,
	onFileExport,
	pageScrollApi,
	noBottomMargin,
	onBuilderLoaded,
	scaleImageJobEvent,
	dataTestId,
	spreadsheetProps,
	generatedDocumentUrl,
	generatingDocument,
	setGeneratingDocument
}) => {
	/**
	 * Following RTK Query loading state pattern
	 * {@link https://redux-toolkit.js.org/rtk-query/usage/queries#frequently-used-query-hook-return-values}
	 */
	const [isLoading, setLoading] = useState(true); // Loading for the first time, and has no data yet
	const [isFetching, setFetching] = useState(true); // Fetching, but might have data from an earlier request

	const [dests, setDests] = useState<IDictionary>({});
	const [saveTime, setSaveTime] = useState<Date>(null);
	const [draftTime, setDraftTime] = useState<Date>(null);

	const [view, _setView] = useState<Views>(Views.Edit);
	const previousRenderView = usePreviousRender(view);

	const [validation, setValidation] = useState<IDictionary>(null);
	const [errorMessage, setMessage] = useState('');

	const [citationDataFromConf, setConfData] = useState({});

	const [lockedMessage, setLockedMessage] = useState<string>(null);
	const [builderDependenciesIds, setBuilderDependenciesIds] = useState<{
		upstream: string[];
		downstream: string[];
	}>({ upstream: [], downstream: [] });
	const [isInstructorView, setIsInstructorView] = useState(false);

	const [isTemplateEdited, setIsTemplateEdited] = useState<boolean>(false);
	const [isEditSave, setIsEditSave] = useState<boolean>(false);
	const [netError, setNetError] = useState<NetError<BuilderApiEndpointKey> | null>(null);
	const [tabUpdate, setTabUpdate] = useState({});

	const [lmsSubmissionJob, setLmsSubmissionJob] = useState<BuilderUserBackgroundJob>(null);

	const [scaleImageFailure, setScaleImageFailure] = useState<boolean>(false);
	const [isScaling, setIsScaling] = useState<boolean>(false);

	const [imageLoadUpdate, setImageLoadUpdate] = useState(new Date());

	const [hideSaveProgressButton, setHideSaveProgressButton] = useState(false);

	const loadRequestQueue = useMemo(() => new SerialExecutionQueue(), []);
	const storeRequestQueue = useMemo(() => new SerialExecutionQueue(), []);

	const builderKey = `${builderId}-course${lmsData?.courseId || ''}`;
	const [getVersion, setVersion] = useLocalStorageWithBackup(`${builderKey}-version`, null);

	const isDirty = isTemplateEdited && (view === Views.Edit || view === Views.EditPreview);
	useUnsavedChangesWarning(isDirty);

	const [polling, setPolling] = useState(null);
	const [generatedDocumentUrlFromPolling, setGeneratedDocumentUrlFromPolling] = useState(null);

	const theme = useTheme();

	const hasImages =
		Object.keys(getDestsMapByKind({ schemaPrompts: schema?.prompts, kind: Kinds.Image })).length >
		0;

	/**
	 * We have dedicated top & bottom refs to properly avoid the scroll position jump on the `view` change
	 * If the top portion is not visible while bottom one is - adjust scroll pos. to preserve the pos. of the content beneath the builder
	 * @see https://soomo.height.app/T-85975#e78ca2fc-de59-468e-b0f1-947ff84326c6
	 */
	const builderRef = useRef<HTMLDivElement | null>();
	const prevViewBuilderHeightRef = useRef<number | null>(null);

	const getBuilderHeight = useCallback(() => builderRef.current?.offsetHeight, []);
	const setView = (newView: Views) => {
		prevViewBuilderHeightRef.current = getBuilderHeight();

		if (newView !== Views.Edit && newView !== Views.EditPreview) {
			setIsTemplateEdited(false);
		}
		_setView(newView);
	};

	const builderTopRef = useRef<HTMLDivElement | null>(null);
	const isBuilderTopVisible = useIsInViewport(builderTopRef);

	const setContentInert = useInertRef(isFetching);
	const isNetError = (error: unknown) => {
		if (error instanceof NetError) {
			setNetError(error);
		} else {
			setNetError(null);
		}
	};

	const docGen = schema.buttons?.pptx || schema.buttons?.docx || schema.buttons?.xlsx;

	/**
	 * On initialization, only show the LMS controls if things are configured for LMS Submission
	 * and there's a job that's not cancelled
	 */
	const [showLmsSubmissionWizard, setShowLmsSubmissionWizard] = useState(
		docGen &&
			lmsData?.enabled &&
			lmsSubmissionJob?.status &&
			lmsSubmissionJob?.status !== BuilderJobStatus.CANCELLED
	);
	const previousShowLmsSubmissionWizard = usePreviousRender(showLmsSubmissionWizard);

	const resetScaleImageFailure = () => {
		setScaleImageFailure(false);
		setImageLoadUpdate(new Date());
		setIsScaling(false);
	};

	useEffect(() => apiLoad(), [builderId, api]);

	useEffect(() => {
		if (scaleImageJobEvent == null) return;

		const { jobStatus, updated_at: updatedAt } = scaleImageJobEvent;
		if (imageLoadUpdate == null || new Date(updatedAt) < imageLoadUpdate) return;

		if (isScaling) setIsScaling(false);

		if (jobStatus === JobStatus.FAILURE) {
			setView(Views.Edit);
			if (!scaleImageFailure) {
				apiLoad();
				setScaleImageFailure(true);
			}
			(window.Rollbar || console).error(
				`WritingTemplate - ${builderId}: image scaling job failed`,
				scaleImageJobEvent
			);
		} else if (jobStatus === JobStatus.SUCCESS) {
			resetScaleImageFailure();
			apiLoad();
			emitCustomEvent(Views.Output, { builderId, originatingChangeId: generateID() });
		}
	}, [scaleImageJobEvent, imageLoadUpdate]);

	useEffect(() => {
		if (!previousShowLmsSubmissionWizard && showLmsSubmissionWizard) {
			builderTopRef.current.focus();
		}
	}, [previousShowLmsSubmissionWizard, showLmsSubmissionWizard, builderTopRef]);

	useEffect(() => setConfData(getCitationDataFromConf(toc, schema, dests)), [schema]);

	/**
	 * @see builderRef
	 * @see builderTopRef
	 */
	useEffect(() => {
		if (!pageScrollApi) return;
		if (!previousRenderView || previousRenderView === view) return; // Ignore the same view changes

		const { getScrollPosition, scrollTo } = pageScrollApi;
		const scrollPosition = getScrollPosition();

		// Timeout allows the builder render entirely and capture its full height
		const jumpPreventionScrollTimeout = setTimeout(() => {
			const builderHeight = getBuilderHeight();
			const { current: prevViewBuilderHeight } = prevViewBuilderHeightRef;

			if (isBuilderTopVisible) return; // User either above the builder or sees it entirely
			if (Math.abs(builderHeight - prevViewBuilderHeight) < 1) return; // The builder height practically hasn't changed. `Math.abs<1` used to properly compare floats

			scrollTo({ top: scrollPosition - prevViewBuilderHeight + builderHeight });
		});
		return () => clearTimeout(jumpPreventionScrollTimeout);
	}, [view, previousRenderView, getBuilderHeight, isBuilderTopVisible, pageScrollApi]);

	/**
	 * Another builder's state on the page change.
	 * If it's an upstream/downstream builder for the current, reload the one to get the latest locked messages data
	 * @param changedBuilderId - the state change happened for this builder
	 * @param originatingChangeId - the unique id of the change. Helps to dedupe the processing
	 */
	const [lastOriginatingChangeId, setLastOriginatingChangeId] = useState<string>(null);
	const reloadRelatedBuilderOnChange = async ({
		builderId: changedBuilderId,
		originatingChangeId
	}) => {
		// The change has already been processed by the current builder
		if (originatingChangeId === lastOriginatingChangeId) return;

		/**
		 * Records that the change has already been processed and we don't need to react to it in the future
		 *
		 * The repeated reaction could happen when the other upstream/downstream builder
		 * that shares the `importedBuilderId` emits its own event after apiLoad
		 */
		setLastOriginatingChangeId(originatingChangeId);

		// Don't try to update the latest data a user entered. Reload only when answer has been draft saved/submitted
		if (isTemplateEdited) return;

		const { upstream, downstream } = builderDependenciesIds;
		const isLockedByChanged = upstream.includes(changedBuilderId);
		const isRevisedByChanged = downstream.includes(changedBuilderId);
		if (isLockedByChanged || isRevisedByChanged) {
			apiLoad(originatingChangeId);
		}
	};

	useCustomEventListener(Views.Output, reloadRelatedBuilderOnChange);
	useCustomEventListener(Views.Edit, reloadRelatedBuilderOnChange);
	useCustomEventListener(Views.Dependency, reloadRelatedBuilderOnChange);

	const setEditView = () => {
		emitCustomEvent(Views.Edit, { builderId, originatingChangeId: generateID() });
		setView(Views.Edit);
		apiLoad();
	};

	const setOutputView = () => {
		emitCustomEvent(Views.Output, { builderId, originatingChangeId: generateID() });
		setView(Views.Output);
		apiLoad();
	};

	useEventListener<StorageEvent>('storage', async (event) => {
		if (!event.key?.includes('-course') || event.key?.endsWith('-version')) {
			/**
			 * Weed out notifications about other component's data going into localStorage
			 * and also this component's storing of the version. We only need to react
			 * to builder *data* changes.
			 */
			return;
		}

		if (event.key !== builderKey) {
			/**
			 * Weed out notifications about other builders that didn't trigger the change
			 */
			return;
		}

		const storedData = event.newValue;

		if (!storedData) {
			return;
		}

		// The " at the start is required to match the format of the JSON.stringify
		if (storedData.startsWith(`"saved`)) {
			setOutputView();
		} else {
			setTabUpdate({
				...dests,
				...JSON.parse(storedData)
			});
			setEditView();
		}
	});

	const saveInputsToLocalStorage = (data: { [key: string]: string }) => {
		const stringifyDests = JSON.stringify(data);
		if (stringifyDests !== window.localStorage.getItem(builderKey)) {
			localStorageSet(builderKey, data);
		}
	};

	const getAwsParamsRequest = useCallback(
		async (name: string) => {
			const { data } = await api?.signedImageParameters(builderId, name);
			return data;
		},
		[api, builderId]
	);

	const renderViewToString = (data?) => {
		const viewString = renderToString(
			<ThemeProvider theme={theme}>
				<ViewMode
					fromConfig={citationDataFromConf}
					getImageUrl={api?.getImageUrl}
					output={{ ...schema.output }}
					dests={{ ...dests, ...data }}
					saveOnly
				/>
			</ThemeProvider>
		);

		const handleView = (htmlString: string) => {
			const view = htmlString
				.replace(/\s?(class|style)=".{1,21}"/g, '')
				.replace(/<\/?(span|div)>/g, '');
			return view;
		};

		return handleView(viewString);
	};
	const apiLoad = (originatingChangeId?: string) => {
		const loadData = async (id) => {
			loadRequestQueue.abortAllPendingItems();

			let raiseSave = false;
			let data = {};

			try {
				setFetching(true);

				const loadResponse = await loadRequestQueue.enqueue(async (abortController) => {
					const defaultValues = schema['default-values'];
					const response = await api.loadTemplate(id, defaultValues, abortController);

					/**
					 * Apply the response results right away for the first load
					 *
					 * But wait for the element to be in the viewport for subsequent loads
					 * caused by the upstream/downstream builders changes
					 */
					if (!isLoading) {
						await waitForElementToBeInViewport(builderRef, abortController);
					}

					return response;
				});

				if (loadResponse?.isError) {
					const { message, view } = loadResponse;
					setView(view);
					setMessage(message);
				}
				if (loadResponse) {
					const {
						dests,
						draftTime,
						saveTime,
						view,
						lockedMessageText,
						upstreamBuildersFamilyIds,
						downstreamBuildersFamilyIds,
						version,
						ignoreDownstreamLockForInstructor,
						hideSaveProgressButton
					} = loadResponse;
					setDests(dests || {});
					setLockedMessage(lockedMessageText);
					setVersion(version);
					setBuilderDependenciesIds({
						upstream: upstreamBuildersFamilyIds,
						downstream: downstreamBuildersFamilyIds
					});
					setIsInstructorView(ignoreDownstreamLockForInstructor);

					if (view !== Views.Dependency && isReviewTemplate(schema)) {
						setView(Views.OutputOnly);
					} else if (view) {
						setView(view);
						// This event triggers update checks for chained templates and
						// passes along the ID of the originating change so that it can
						// avoid pointlessly reloading if it already handled that change event.
						if (originatingChangeId) {
							emitCustomEvent(view, { builderId, originatingChangeId });
						}
					}

					setSaveTime(saveTime && new Date(saveTime));
					setDraftTime(draftTime && new Date(draftTime));
					setHideSaveProgressButton(!!hideSaveProgressButton);

					if (
						originatingChangeId &&
						view === Views.Output &&
						lmsData?.enabled &&
						lmsSubmissionJob?.status === BuilderJobStatus.SUCCESS
					) {
						raiseSave = true;
						data = { ...dests };
						setLmsSubmissionJob({ ...lmsSubmissionJob, status: BuilderJobStatus.REVISED });
					}
				}
			} catch (error: unknown) {
				isNetError(error);
			} finally {
				setLoading(false);
				setFetching(false);
				onBuilderLoaded?.();

				if (~window.location.href.indexOf(`#builder_${builderId}`)) {
					builderTopRef.current.focus({ preventScroll: true });
					scrollToElement(builderTopRef.current);
					window.location.href = window.location.href.replace(`builder_${builderId}`, '');
				}

				if (raiseSave) {
					sendSave(data);
				}
			}
		};
		loadData(builderId);
	};

	const onSaveDraft = async (data, options?: DraftSaveOptions) => {
		/**
		 * if a button was pressed, or autosave worked
		 * we cancel pending auto-save and block the button
		 */
		storeRequestQueue.ignoreAllPendingItems();

		if (!isEmpty(data)) {
			setView(Views.Edit); // Replace EditPreview with Edit, required for tab sync enabling

			setDests({
				...dests,
				...data
			});

			try {
				const storeResponse = await storeRequestQueue.enqueue((abortController) =>
					api.storeTemplate({
						builderId,
						body: {
							_draft: isOutputWithCitation(data)
								? { ...mergeCitationOutput(data, renderViewToString(data)) }
								: { ...data }
						},
						abortController,
						version: options?.auto ? getVersion() : null
					})
				);
				if (storeResponse) {
					const { date, version } = storeResponse;
					setDraftTime(date);
					setIsTemplateEdited(false);
					setVersion(version);
					if (hasImages && !options?.auto) {
						setIsScaling(true);
					}
				}
				setNetError(null);
				emitCustomEvent(Views.Output, { builderId, originatingChangeId: generateID() });
			} catch (e) {
				isNetError(e);
			}
		}
	};

	const sendSave = useCallback(
		async (data, ignoreLocking?: boolean) => {
			// Aborts pending save request
			storeRequestQueue.ignoreAllPendingItems();

			try {
				const dataUpdate = isOutputWithCitation(data)
					? { ...mergeCitationOutput(data, renderViewToString(data)) }
					: { ...data };

				const storeResponse = await storeRequestQueue.enqueue((abortController) =>
					api.storeTemplate({
						builderId,
						body: {
							...dataUpdate,
							output: renderViewToString(data)
						},
						abortController,
						ignoreLocking
					})
				);
				setValidation(null);

				if (storeResponse) {
					const { date, validation_results, invalid, version } = storeResponse;

					if (invalid) {
						setValidation(validation_results);
					}
					setVersion(version);
					setSaveTime(date);
					setDraftTime(date); // Required to reset the submission status when validation failed 2+ times in a row

					// If there are images, we don't want to switch the view back just yet,
					// because we want to let the image scaler tell us if there's a failure or not first
					setView(invalid || hasImages ? Views.Edit : Views.Output);
					if (!hasImages) {
						setIsTemplateEdited(false);
						emitCustomEvent(Views.Output, { builderId, originatingChangeId: generateID() });
					} else {
						setIsScaling(true);
					}
				}
				setNetError(null);
			} catch (error) {
				console.error(error);
				isNetError(error);
			}
		},
		[dests]
	);

	const forceSave = async (dests) => {
		try {
			const storeResponse = await storeRequestQueue.enqueue((abortController) =>
				api.storeTemplate({
					builderId,
					body: {
						output: renderViewToString(dests)
					},
					abortController
				})
			);
			if (storeResponse) {
				const { date, version } = storeResponse;
				setSaveTime(date);
				setVersion(version);
				setIsTemplateEdited(false);
			}
			setNetError(null);
		} catch (error) {
			isNetError(error);
		}
	};

	useEffect(() => {
		if (view == Views.OutputOnly && dests && !isEmpty(dests) && !dests?.output) {
			forceSave(dests);
		} else if (view == Views.OutputOnly && isReviewTemplate(schema)) {
			forceSave({});
		}
	}, [view]);

	const onFinalSave = async (data) => {
		if (!isEmpty(data)) {
			setDests({
				...dests,
				...data
			});

			await sendSave(data);
		}
		setIsEditSave(true);

		const savedMessage = `saved-${getVersion()}`;
		localStorageSet(builderKey, savedMessage);
	};

	const handleFileGenerate = async () => {
		if (!api?.generateDocument) return;

		try {
			setNetError(null);
			setGeneratingDocument(true);
			const response = await api.generateDocument(builderId);
			// We don't need to poll if LMS auto-submission is enabled,
			// because StudentViewLmsSubmission polls already for that data.
			if (lmsData?.enabled) return;
			const { key } = response.data;
			// safety net for websocket failure
			const poll = () => {
				api
					.generatedDocumentReady(key, builderId)
					.then((response) => {
						setGeneratedDocumentUrlFromPolling(
							`${api.endpoints.generatedDocumentPath}?${new URLSearchParams({
								key: key,
								builder_id: builderId
							}).toString()}`
						);
					})
					.catch((err) => {
						if (err.response.status == 401) {
							// we've been logged out, so stop polling
							polling.cancelPolling(); // no need to keep polling after unmounting!
							polling.removePollingEventListeners();
						}
					});
			};
			const polling = new Polling(poll, 10000, 7);
			polling.stopPollingIfElementNotInView = false;
			polling.ensurePollingIsActive();
			setPolling(polling);
		} catch (error) {
			isNetError(error);
			setGeneratingDocument(false);
		}
	};

	useEffect(() => {
		if (polling && (generatedDocumentUrl || generatedDocumentUrlFromPolling)) {
			polling.cancelPolling(); // no need to keep polling once we have the document
			polling.removePollingEventListeners();
		}
	}, [generatedDocumentUrl, generatedDocumentUrlFromPolling, polling]);

	const onEdit = () => {
		setView(Views.EditPreview);
		setDraftTime(null);
	};

	const getComponent = () => {
		if (view === Views.Edit || view === Views.EditPreview) {
			return (
				<ValidationsProvider responseValidationResults={validation}>
					<EditMode
						scrollableContainer={pageScrollApi?.scrollableContainer}
						getAwsParams={getAwsParamsRequest}
						saveInputsToLocalStorage={saveInputsToLocalStorage}
						getImageUrl={api?.getImageUrl}
						onSaveDraft={onSaveDraft}
						onFinalSave={onFinalSave}
						schema={schema}
						imageUploadUrl={imageUploadUrl}
						builderFamilyId={builderId}
						toc={toc}
						inputValues={dests}
						timeStamp={draftTime}
						isEditPreview={view === Views.EditPreview}
						fromConfig={citationDataFromConf}
						elementLabel={label}
						readOnly={readOnly}
						onTemplateEdited={() => setIsTemplateEdited(true)}
						tabUpdate={tabUpdate}
						netError={netError}
						scaleImageFailure={scaleImageFailure}
						resetScaleImageFailure={resetScaleImageFailure}
						instructorView={isInstructorView}
						isScaling={isScaling}
						spreadsheetProps={spreadsheetProps}
						hideSaveProgressButton={hideSaveProgressButton}
					/>
				</ValidationsProvider>
			);
		} else if (view === Views.Dependency) {
			return <Unfinished message={errorMessage} elementLabel={label} builderFamilyId={builderId} />;
		}

		const schemaPrompts = schema?.prompts;
		const handleLockingIgnoredSave = (chartData: IDictionary) => {
			setDests({ ...dests, ...chartData });
			sendSave({ ...getSchemaDefinedDests(schemaPrompts, dests), ...chartData }, true);
		};
		return (
			<ViewMode
				builderFamilyId={builderId}
				output={{ ...schema.output, buttons: schema.buttons }}
				dests={dests}
				shouldFocus={isEditSave}
				draftTimeStamp={saveTime}
				onEdit={onEdit}
				getImageUrl={api?.getImageUrl}
				imageDestsMap={getDestsMapByKind({ schemaPrompts, kind: Kinds.Image })}
				spreadsheetDestsMap={getDestsMapByKind({ schemaPrompts, dests, kind: Kinds.Spreadsheet })}
				outlineDestsMap={getDestsMapByKind({ schemaPrompts, dests, kind: Kinds.Outline })}
				fromConfig={citationDataFromConf}
				lockedMessage={lockedMessage}
				isHideBottom={view === Views.OutputOnly}
				elementLabel={label}
				readOnly={readOnly || isInstructorView}
				lmsData={{ ...lmsData, familyId: builderId }}
				refTo={builderTopRef}
				job={lmsSubmissionJob}
				onFileExport={onFileExport}
				setJob={setLmsSubmissionJob}
				generateDocument={handleFileGenerate}
				netError={netError}
				generatedDocumentUrl={generatedDocumentUrl || generatedDocumentUrlFromPolling}
				generatingDocument={generatingDocument}
				setGeneratingDocument={setGeneratingDocument}
				pullSchemaPromptProps={pullSchemaPromptProps(schemaPrompts)}
				onLockingIgnoredSave={handleLockingIgnoredSave}
				setShowLmsSubmissionWizard={setShowLmsSubmissionWizard}
				showLmsSubmissionWizard={showLmsSubmissionWizard}
			/>
		);
	};

	const getTitle = () => {
		if (schema?.title && schema?.ui?.title?.visible !== false)
			return <Title value={schema.title}></Title>;
	};

	useEffect(() => {
		if (lmsData?.enabled && lmsData.job) {
			setLmsSubmissionJob(lmsData.job);
		}
	}, [lmsData]);
	return (
		<>
			<div ref={builderTopRef} aria-hidden="true" tabIndex={-1} />
			<div
				id={`builder_${builderId}`}
				data-testid={dataTestId}
				ref={builderRef}
				tabIndex={-1}
				css={(theme) => writingTemplateStyles(theme, { noBottomMargin })}
				aria-busy={isFetching}>
				{getTitle()}
				{/*Show loader for the first request, as well as subsequent requests*/}
				{(isFetching || isScaling) && (
					<OverlayLoader
						dimmed={!isLoading || isScaling} // Dimmed only for the subsequent requests
					/>
				)}
				{/*Show component after the first request completion*/}
				{!isLoading && <div ref={setContentInert}>{getComponent()}</div>}
			</div>
		</>
	);
};

export default withErrorBoundary(WritingTemplate);
