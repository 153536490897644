import { IDictionary, Kinds } from '~/components/WritingTemplate/WritingTemplate/types';
import { breakpoints } from '~/styles/themes';
import { TocConfig } from '~/types/WebtextManifest';
import NetError from '~/utils/netErrors';

import { BuilderElementConfig } from '../EditMode/types';

declare global {
	interface Window {
		_customEventTargetElement?: HTMLDivElement;
	}
}

export const getFullPageBackgroundStyle = (color) => `
	@media (max-width: ${breakpoints.small}) {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: -9999px;
		right: 0;
		z-index: -1;
		border-left: 9999px solid ${color};
		box-shadow: 9999px 0 0 ${color};
	}
}`;

export const getDestsMapByKind = (args: {
	schemaPrompts?: IDictionary;
	dests?: IDictionary; // User's responses. Imported response end up here as well
	kind: Kinds;
}): Record<string, boolean> => {
	const { schemaPrompts, dests, kind } = args;

	const destsMap = {};

	/**
	 * Obtain dests from schema defined prompts
	 */
	schemaPrompts?.forEach((prompt) => {
		let elements = [];
		if (Array.isArray(prompt)) {
			elements = prompt;
		} else if (prompt.presentation === 'block') {
			elements = prompt.items;
		}
		elements
			.filter(({ kind: elementKind }) => elementKind === kind)
			.forEach((el) => (destsMap[el.dest] = true));
	});

	/**
	 * Obtain imported dests
	 * Each PI has a unique response property on which we can match the kind
	 */
	Object.entries(dests || {})
		.filter(([_, value]) => {
			switch (kind) {
				case Kinds.Spreadsheet:
					return value.sheet;
				case Kinds.Outline:
					return value.hierarchyResponses;
			}
		})
		.forEach(([dest]) => (destsMap[dest] = true));

	return destsMap;
};

export const pullSchemaPromptProps =
	(schemaPrompts: IDictionary) =>
	(dest: string): BuilderElementConfig | undefined =>
		schemaPrompts
			.flatMap((prompt) => (Array.isArray(prompt) ? prompt : prompt.items))
			.find((pi) => 'dest' in pi && pi.dest === dest);

export const getSchemaDefinedDests = (schemaPrompts: IDictionary, dests: IDictionary) => {
	const schemaDests = schemaPrompts
		.flatMap((prompt) => (Array.isArray(prompt) ? prompt : prompt.items))
		.map((pi) => 'dest' in pi && pi.dest)
		.filter(Boolean);

	return Object.fromEntries(
		Object.entries(dests)
			.map(([key, value]) => (schemaDests.includes(key) ? [key, value] : null))
			.filter(Boolean)
	);
};

export const isReviewTemplate = (schema: IDictionary): boolean =>
	(!schema?.prompts && !schema.output?.outline) || schema?.prompts?.length === 0;

export const isOutputWithCitation = (data: IDictionary): boolean => !!data?.output_with_citations;

export const mergeCitationOutput = (data: IDictionary, outputText: string): IDictionary => {
	const { output_with_citations: out } = data;
	const update = { ...data, output_with_citations: { ...out, text: outputText } };
	return update;
};

export const getElementLabel = (
	tocConfig: TocConfig,
	pageType: string,
	definition: string
): string => {
	const tocBuilderLabel = tocConfig.elements[definition]?.human_name;

	const pagesConfig = tocConfig.pages;
	const pageConfig = pagesConfig.types[pageType];
	const pageBuilderLabel = pageConfig.elements[definition]?.human_name;

	return pageBuilderLabel ?? tocBuilderLabel ?? null;
};

/**
 * Helps to narrow down the endpoints keys variety
 * when the network error happens for the Builder requests specifically
 */
export type BuilderApiEndpointKey =
	| 'load'
	| 'store'
	| 'signedResource'
	| 'uploadedImage'
	| 'generateDocument';

// Used in the consuming apps
export class BuilderNetError extends NetError {
	constructor(message: string, status: number, apiEndpointKey?: BuilderApiEndpointKey) {
		super(message, status, apiEndpointKey);
	}
}

export const getBuilderId = (builderFamilyId: string) => `builder_${builderFamilyId}`;
