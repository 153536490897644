import React, { FC, HTMLAttributes } from 'react';

const NewPollQuestionIcon: FC<HTMLAttributes<SVGElement>> = (props) => (
	<svg
		className="poll-icon"
		width={32}
		height={32}
		viewBox="0 0 448 512"
		fill="#5F01DF"
		aria-hidden
		{...props}>
		<path d="M448 32H0V480H448V32zM160 224V384H96V224h64zm32-96h64V384H192V128zM352 288v96H288V288h64z" />
	</svg>
);

export default NewPollQuestionIcon;
