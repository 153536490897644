import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

import { getFullPageBackgroundStyle } from '../WritingTemplate/utils';

export default ({ colors, fonts, webtextWritingTemplate, name }) => {
	const { instructions } = webtextWritingTemplate || { instructions: {} };
	const { color, fontFamily, padding, background, fontSize, lineHeight, fontWeight } = instructions;
	const backgroundColor = background || colors.primary;
	return css`
		color: ${color || colors.secondary} !important;
		font-family: ${fontFamily || fonts.app} !important;
		${setFontUnlessExits(name)}
		font-weight: ${fontWeight || 400} !important;
		padding: ${padding || '25px'};
		background: ${backgroundColor};
		font-size: ${fontSize || '18px'};
		line-height: ${lineHeight || '31px'};
		${getFullPageBackgroundStyle(backgroundColor)}
		@media (max-width: ${breakpoints.small}) {
			font-size: 16px;
			line-height: 26px;
			font-weight: 500;
			padding: 23px 9px;
		}
	`;
};

const setFontUnlessExits = (name: string) => {
	switch (name) {
		case 'strayer_velvet_2':
			return 'font-family: "Roboto", Georgia, Cambria, "Times New Roman", Times, serif !important;';
		case 'queens_velvet':
			return 'font-family: "Nunito Sans", sans-serif !important; font-size: 19px !important;';
		default:
			return '';
	}
};
