import React, { Component } from 'react';

import { withTheme } from '@emotion/react';
import { DateTime } from 'luxon';

import { LmsSubmission } from '~/components/pageElements/LmsSubmission/LmsSubmission';
import { type BuilderUserBackgroundJob } from '~/components/WritingTemplate/WritingTemplate/types';
import { JobStatus, type UserBackgroundJob, type LmsSubmissionProgress } from '~/types';
import Polling from '~/utils/polling';

import {
	cancelLMSSubmission,
	builderAnswer,
	getVoiceRecordingAnswer,
	submitVoiceRecordingToLMS
} from './api';

interface Props {
	answerId: number;
	job: UserBackgroundJob | BuilderUserBackgroundJob;
	cancel: (arg0: string) => void;
	editSubmission: () => void;
	elementType: string;
	generateAndSubmit: () => void;
	resourcesToSubmit?: string[][];
	courseId: number;
	chapterId: number;
	pageId: number;
	elementFamilyId: string;
	events: any;
	Rollbar: any;
}

interface State {
	progress?: LmsSubmissionProgress;
	errorMessage: string;
	jobId: string;
	cancel: (arg0: string) => void;
	editSubmission: () => void;
	generateAndSubmit: () => void;
	submissionTime: DateTime;
	showEditButton: boolean;
	jobStatus: string;
	submitVoiceRecordingToLMS: any;
	answerData: any;
	cancellable: boolean;
}

class UnthemedLmsSubmission extends Component<Props, State> {
	timeoutIds = [];
	nextProgressUpdate = null;
	ref = null;
	polling = null;

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.polling = new Polling(this.poll, 10000, 30);

		const { job, cancel, editSubmission, generateAndSubmit } = props;

		const answerDataMappings = {
			builder: builderAnswer,
			voice_recording: getVoiceRecordingAnswer
		};

		const showEditButton = job && job.status === JobStatus.SUCCESS;

		this.state = {
			submitVoiceRecordingToLMS,
			answerData: answerDataMappings[props.elementType],
			jobId: job ? job.id : null,
			cancel: cancel,
			errorMessage: null,
			editSubmission: editSubmission,
			submissionTime: showEditButton && DateTime.fromISO(job.updated_at),
			showEditButton: showEditButton,
			generateAndSubmit: generateAndSubmit,
			jobStatus: job ? job.status : null,
			progress: job ? job.progress : null,
			cancellable: true
		};
	}

	submitFiles = () => {
		this.polling.ensurePollingIsActive();
		const { courseId, chapterId, pageId, elementFamilyId, resourcesToSubmit } = this.props;
		this.setState({
			progress: { step: -1, states: [], error: false, cancelled: false },
			jobStatus: 'WAITING'
		});
		if (this.props.elementType === 'voice_recording') {
			submitVoiceRecordingToLMS(courseId, chapterId, pageId, elementFamilyId, resourcesToSubmit)
				.then((res) => {
					this.setState({ jobId: res.data.jobId });
				})
				.catch((error) => {
					this.props.Rollbar.error('Failed to submit voice recording to LMS', error);
					this.setState({ errorMessage: 'An error occurred. Please try again.' });
				});
		} else {
			this.state.generateAndSubmit();
		}
	};

	// safety net for websocket failure
	poll = () => {
		const { courseId, chapterId, pageId, elementFamilyId } = this.props;

		this.state
			.answerData(courseId, chapterId, pageId, elementFamilyId)
			.then((response) => {
				const answer = response.data;
				const job = answer ? answer.job : null;

				if (job) {
					if (job.progress.error) {
						this.state.cancel(
							'We weren’t able to submit your work. Please try again. If you continue to have trouble, contact Soomo Support.'
						);
						return;
					} else if (job.progress.cancelled) {
						this.state.cancel('Your submission was cancelled');
						return;
					}
					const stateUpdate = {
						progress: job.progress,
						jobId: job.id,
						jobStatus: job.status
					};

					if (job.status === JobStatus.SUCCESS) {
						stateUpdate['submissionTime'] = DateTime.fromISO(job.updated_at);
					} else if (job.status === JobStatus.CANCELLED) {
						this.state.cancel('Your submission was cancelled');
						return;
					}

					this.setState(stateUpdate);
				}
			})
			.catch((err) => {
				if (err.response.status == 401) {
					// we've been logged out, so stop polling
					this.polling.cancelPolling(); // no need to keep polling after unmounting!
					this.polling.removePollingEventListeners();
				} else if (err.response.status != 404) {
					// 404s are expected for some amount of time until bg processing gets queued
					this.props.Rollbar.error('failed to retrieve answer', err);
				}
			});
	};

	handleWebsocketData = (data) => {
		if (data.progress) {
			if (data.progress.error) {
				this.state.cancel(
					data.progress.message ||
						'We weren’t able to submit your work. Please try again. If you continue to have trouble, contact Soomo Support.'
				);
				return;
			} else if (data.progress.cancelled) {
				this.state.cancel('Your submission was cancelled');
				return;
			} else if (data.jobStatus === JobStatus.SUCCESS) {
				this.setState({ ...data, submissionTime: DateTime.local().minus({ seconds: 1 }) });
				return;
			}
		}

		const updatedState = data;
		if (data.jobStatus && data.jobStatus !== JobStatus.SUCCESS) {
			updatedState.showEditButton = false;
			updatedState.submissionTime = null;
		}

		this.setState(updatedState);
	};

	processWebsocketUpdate = (data) => {
		if (this.nextProgressUpdate && data.progress && !data.progress.error) {
			// wait at least 2 seconds between each update
			const duration = this.nextProgressUpdate.diffNow().milliseconds;
			this.nextProgressUpdate = this.nextProgressUpdate.plus({ seconds: 2 });
			this.timeoutIds.push(setTimeout(() => this.handleWebsocketData(data), duration));
			// the one exception is cancellable, which we need to set to false immediately if they've
			// reached the last step
			if (data.progress.states && data.progress.step >= data.progress.states.length - 1) {
				this.setState({ cancellable: false });
			}
		} else {
			this.nextProgressUpdate = DateTime.local().plus({ seconds: 2 });
			this.handleWebsocketData(data);
		}
	};

	componentDidMount() {
		this.polling.element = this.ref.current;
		this.props.events.subscribe(
			'respondable.update.' + this.props.elementFamilyId,
			this.processWebsocketUpdate
		);
	}

	componentDidUpdate() {
		if (this.state.submissionTime) {
			this.polling.cancelPolling(); // we have their answer, no need to keep polling
			this.polling.removePollingEventListeners();
		} else {
			this.polling.ensurePollingIsActive();
		}
	}

	componentWillUnmount() {
		this.props.events.unsubscribe(
			'respondable.update.' + this.props.elementFamilyId,
			this.processWebsocketUpdate
		);
		this.polling.cancelPolling(); // no need to keep polling after unmounting!
		this.polling.removePollingEventListeners();
		this.timeoutIds.forEach((timeoutId) => {
			clearTimeout(timeoutId);
		});
		this.timeoutIds = [];
	}

	cancelSubmission = () => {
		const { courseId, chapterId, pageId } = this.props;
		cancelLMSSubmission(courseId, chapterId, pageId, this.props.answerId).then(() =>
			this.state.cancel('Your submission was cancelled')
		);
	};

	render() {
		const { progress, cancel, jobStatus, submissionTime } = this.state;
		return (
			<div ref={this.ref}>
				<LmsSubmission
					submissionTime={submissionTime}
					editSubmission={this.state.editSubmission}
					cancel={cancel}
					cancelSubmission={this.cancelSubmission}
					submitFiles={this.submitFiles}
					jobStatus={jobStatus}
					progress={progress}
				/>
			</div>
		);
	}
}

export default withTheme(UnthemedLmsSubmission);
