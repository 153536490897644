import React from 'react';
import { CgClose } from 'react-icons/cg';
import { FaTimes as Close } from 'react-icons/fa';

import { css, withTheme } from '@emotion/react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { GenericErrorBoundary } from '~/components';
import { State as RenderErrorMessageState } from '~/components/GenericErrorBoundary';
import { breakpoints, mixins, Theme, ThemeName } from '~/styles/themes';
import constants from '~/styles/themes/base/constants';

const overlayStyles = (open) =>
	open
		? css`
				position: fixed;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				background-color: rgba(0, 0, 0, 0.75);
				overflow-y: scroll;
				z-index: 200;
		  `
		: null;

const modalStyles = (theme) => {
	const { popupQuiz } = theme;

	return css`
		position: relative;
		margin: 10vh auto;
		width: 90%;
		max-width: 800px;
		background-color: ${popupQuiz.colors.secondary};
		font-family: ${popupQuiz.fontFamily};
		font-size: ${popupQuiz.fontSize};

		@media (min-width: ${breakpoints.small}) {
			width: 80%;
			margin: 15vh auto;
		}

		.close-button {
			position: absolute;
			top: 0;
			right: 0px;
			width: 39px;
			height: 39px;
			padding: 0;
			margin: 0;
			background: transparent;
			border: 1px solid transparent;
			color: white;

			&:focus {
				${mixins.webtextAccessibleFocused(theme)}
			}

			&:disabled {
				cursor: not-allowed;
			}
		}

		.modal-title {
			${popupQuiz.header.fontSize ? `font-size: ${popupQuiz.header.fontSize};` : ''}
			${popupQuiz.header.fontFamily ? `font-family: ${popupQuiz.header.fontFamily};` : ''}
			${popupQuiz.header.fontWeight ? `font-weight: ${popupQuiz.header.fontWeight};` : ''}
			background-color: ${popupQuiz.colors.primary};
			padding: 10px 34px 10px 17px;
			color: ${theme.colors['pure-white']};

			strong {
				color: ${theme.colors['pure-white']} !important;
			}
		}

		.offline-message-container {
			padding: 30px;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			padding-left: 12px;
			border-left: ${theme.colors.primary} 7px solid;
			background-color: ${theme.colors['pure-white']};
			border-radius: 0 8px 8px 0;

			@media (max-width: ${breakpoints.small}) {
				margin: 6vh auto;
				width: 78%;
			}

			.close-button {
				color: ${theme.colors.primary};
				width: 48px;
				height: 48px;
				margin-top: 8px;
				margin-right: 10px;

				@media (max-width: ${breakpoints.small}) {
					margin-top: 4px;
					margin-right: 5px;
				}
			}

			.modal-title {
				background-color: ${theme.colors['pure-white']};
				color: black;
				font-size: 24px;
				font-family: ${constants.fonts['haas-grotesk-medium']} !important;
				font-weight: 600;
				padding: 34px 0 24px 30px;
				border-radius: 0 8px 0 0;

				@media (max-width: ${breakpoints.small}) {
					font-size: 18px;
					// padding-top: 28px;
					// padding-bottom: 0;
					// padding-left: 16px;
					// padding-right: 30px;

					padding: 28px 30px 0 16px;
				}
			}
		`}
	`;
};

const errorStyles = css`
	padding-left: 1rem;
	padding-right: 1rem;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	border-left: 7px solid #2ca936;
	p {
		font-family: Helvetica, Arial, sans-serif;
		font-size: 0.85rem !important;
		margin-bottom: 0.25rem !important;
	}
`;

const renderErrorMessage = ({ errorUUID }: RenderErrorMessageState) => {
	return (
		<div css={errorStyles}>
			<div>
				<span style={{ color: 'red' }}>
					There was an error when trying to display this content.
				</span>
				<br />
				<span>Ref: {errorUUID}</span>
			</div>
		</div>
	);
};

export interface Props {
	theme: Theme;
	open: boolean;
	onClose: (event: React.MouseEvent) => void;
	title: string;
	children: React.ReactNode;
	online: boolean;
	saving: boolean;
}

interface QuizModalWithForwardedRefProps extends Props {
	containerRef: React.RefObject<HTMLDivElement>;
}

class QuizModal extends React.Component<QuizModalWithForwardedRefProps> {
	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.open && !this.props.open) {
			enableBodyScroll(this.props.containerRef.current as HTMLDivElement);
		} else if (!prevProps.open && this.props.open) {
			disableBodyScroll(this.props.containerRef.current as HTMLDivElement);
		}
	}

	render() {
		const { theme, open, onClose, title, online, saving, children } = this.props;

		const isUniversalVelvet = theme.name === ThemeName.UNIVERSAL_VELVET;

		return (
			<div css={overlayStyles(open)} ref={this.props.containerRef}>
				{open && (
					<div className="quiz-modal" css={modalStyles(theme)}>
						{/* we disable the close button while a request is pending,
							because otherwise it's possible to break the internal state of the component:
								- submit a question
								- close the PUQ
								- the response from core comes while the PUQ is closed,
								  so the PUQ component doesn't process it
							see T-32111 for more */}
						<button
							className="close-button"
							onClick={onClose}
							aria-label="close quiz dialog"
							disabled={saving}>
							{!isUniversalVelvet ? <Close size={18} /> : <CgClose size={30} />}
						</button>
						<div
							className="modal-title"
							role="heading"
							aria-level={2}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
						<GenericErrorBoundary renderErrorMessage={renderErrorMessage}>
							{!online && (
								<div className="offline-message-container">
									Your device must be online in order to continue.
								</div>
							)}
							{online && children}
						</GenericErrorBoundary>
					</div>
				)}
			</div>
		);
	}
}

export default withTheme(
	React.forwardRef((props: Props, ref: React.RefObject<HTMLDivElement>) => {
		return <QuizModal {...props} containerRef={ref} />;
	})
);
