import { FC } from 'react';

import { useOutlineActionsFocus, useOutlineActionsHotkeys } from '~/components/Outline/hooks';

/**
 * Separate component for a11y hooks is required
 * to prevent redundant re-renders in the parent `Outline` component
 * @see {@link https://github.com/soomo/soomo-libs/pull/1244/files#r1087847028}
 */
const OutlineA11YHandlersContainer: FC = () => {
	useOutlineActionsFocus();
	useOutlineActionsHotkeys();
	return null;
};

export default OutlineA11YHandlersContainer;
