import React from 'react';
import round from 'lodash.round';
import pluralize from 'pluralize';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { MCQuestionPoolElement } from '@soomo/lib/types/WebtextManifest';
import { ExpandablePanel } from '@soomo/lib/notebook/components/ExpandablePanel/index';

import MCQuestion, { MCQuestionAggregated } from './MCQuestion';
import TippyRollover from '../TippyRollover';

import activityHistoryStyles from '../../ActivityHistory/ActivityHistory.scss';
import pageViewStyles from '../PageView.scss';
import styles from '../AggregatedPageView.scss';
import InfoItem from './InfoItem';

interface MCQuestionPoolAggregated {
	attemptedAtLeastOnce: number;
	avgNumberOfAttempts: number;
	avgInitialAttemptScore: number;
	avgLatestAttemptScore: number;
	aggregatedQuestions: Record<string, Pick<MCQuestionAggregated, 'aggregatedChoices'>>;
}

interface Props {
	element: MCQuestionPoolElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: MCQuestionPoolAggregated;
	totalStudentsCount?: number;
}

const MCQuestionPool: React.FC<Props> = (props) => {
	const { element, isPageActivityLoaded, aggregatedElement, totalStudentsCount } = props;
	const {
		attemptedAtLeastOnce = 0,
		avgNumberOfAttempts = 0,
		avgInitialAttemptScore = 0,
		avgLatestAttemptScore = 0,
		aggregatedQuestions = {}
	} = aggregatedElement || {};

	const questionsCount = element.questions.length;

	return (
		<div>
			<div className={pageViewStyles.ActivitySummary}>
				<span className={pageViewStyles.QuestionType}>Multiple-Choice Question Pool</span>
			</div>
			{/* if MCQP has only 1 question - show regular MCQ view */}
			{questionsCount === 1 && (
				<MCQuestion
					key={element.questions[0].family_id}
					element={element.questions[0] as any}
					isPageActivityLoaded={isPageActivityLoaded}
					aggregatedElement={aggregatedQuestions[element.questions[0].family_id]}
					totalStudentsCount={totalStudentsCount}
					isPoolItem={true}
					className={styles.PoolItem}
				/>
			)}
			{questionsCount > 1 && (
				<h2 className={pageViewStyles.QuestionBody}>
					<span className={styles.HeaderTitle}>{element.questions[0].body}</span>
					<div className={styles.HeaderInfo}>
						<span>
							(and {questionsCount - 1} other pool {pluralize('item', questionsCount - 1)})
						</span>
						<TippyRollover
							trigger={'click'}
							variant="pane"
							followCursor={false}
							offset={[0, 10]}
							width={320}
							content={`
								This question pool is made up of ${element.questions.length} items. 
								The item assigned to each student for their initial attempt is randomized, 
								and students will receive a different item from the pool if they reset. 
								Use the Pool Item Details section to view response data for each item.
							`}>
							<IconButton size="large" classes={{ root: styles.InfoIcon }}>
								<InfoOutlinedIcon />
							</IconButton>
						</TippyRollover>
					</div>
				</h2>
			)}

			<div className={styles.AggregatedQuestionInfo}>
				<InfoItem
					label="Attempted at least once:"
					data={
						isPageActivityLoaded
							? `${attemptedAtLeastOnce}/${totalStudentsCount} students`
							: undefined
					}
				/>
				<InfoItem
					label="Average number of attempts:"
					data={isPageActivityLoaded ? `${round(avgNumberOfAttempts, 2)}` : undefined}
				/>
				<InfoItem
					label="Average initial attempt score:"
					data={isPageActivityLoaded ? `${Math.floor(avgInitialAttemptScore * 100)}%` : undefined}
				/>
				<InfoItem
					label="Average latest attempt score:"
					data={isPageActivityLoaded ? `${Math.floor(avgLatestAttemptScore * 100)}%` : undefined}
				/>
			</div>

			{/* if MCQP has 2+ questions - show expandable panel view */}
			{questionsCount > 1 && (
				<ExpandablePanel
					title="Pool Items Details"
					className={activityHistoryStyles.ActivityHistoryContainer}>
					{element.questions.map((question, index) => (
						<>
							{index !== 0 && <div className={styles.Divider} />}
							<MCQuestion
								key={question.family_id}
								element={question as any}
								isPageActivityLoaded={isPageActivityLoaded}
								aggregatedElement={aggregatedQuestions[question.family_id]}
								totalStudentsCount={totalStudentsCount}
								isPoolItem={true}
								className={styles.PoolItem}
							/>
						</>
					))}
				</ExpandablePanel>
			)}
		</div>
	);
};

export default MCQuestionPool;
