import React, { FC, useEffect, useState } from 'react';

import { first } from 'lodash-es';
import { GifReader } from 'omggif';

import { ExpandedViewModal, ExpandedViewModalProps } from '.';
import { payloadToImgElement } from '../GifImageFigure';
import * as gifImageStyles from '../GifImageFigure/styles';

type GifFrame = { data: Uint8Array; width: number; height: number };

const ExpandedCanvasModal: FC<
	Omit<ExpandedViewModalProps<never>, 'children'> & { payload: string }
> = (props) => {
	const { title, payload, returnFocusRef, onClose } = props;

	const [firstFrame, setFirstFrame] = useState<GifFrame>(null);

	useEffect(() => {
		const payloadImg = payloadToImgElement(payload);
		if (!payloadImg) return;

		const gifUrl = payloadImg.src;

		const abortController = new AbortController();

		// Fetch the GIF data and decode the first frame
		fetch(gifUrl, { signal: abortController.signal })
			.then((response) => response.arrayBuffer())
			.then((arrayBuffer) => {
				const gifReader = new GifReader(new Uint8Array(arrayBuffer));

				const width = gifReader.width;
				const height = gifReader.height;
				const frameData = new Uint8Array(width * height * 4);
				gifReader.decodeAndBlitFrameRGBA(0, frameData);

				setFirstFrame({ data: frameData, width, height });
			})
			.catch((error) => {
				console.error('Failed to load GIF:', error);
			});

		return () => {
			abortController.abort();
		};
	}, [payload]);

	const isLoading = firstFrame === null;

	return (
		<ExpandedViewModal<HTMLCanvasElement>
			isLoading={isLoading}
			returnFocusRef={returnFocusRef}
			title={title}
			onClose={onClose}>
			{({ elementRef }) => (
				<>
					{!isLoading && (
						<div
							css={gifImageStyles.root}
							ref={(el) => {
								const canvas = first(el?.getElementsByTagName('canvas'));
								if (!canvas) return;

								canvas.ondragstart = (event) => event.preventDefault();

								elementRef.current = canvas;

								const { width, height } = canvas.getBoundingClientRect();
								el.style.overflow = 'hidden';
								el.style.width = `${width}px`;
								el.style.height = `${height}px`;
							}}>
							<canvas
								ref={(canvas) => {
									if (canvas) {
										const ctx = canvas.getContext('2d');
										const { data, width, height } = firstFrame;
										const imageData = new ImageData(new Uint8ClampedArray(data), width, height);
										canvas.width = width;
										canvas.height = height;
										ctx.putImageData(imageData, 0, 0);
									}
								}}
							/>
						</div>
					)}
				</>
			)}
		</ExpandedViewModal>
	);
};

export default ExpandedCanvasModal;
