import { ObjectTyped } from 'object-typed';

import { getToggleTemplateParentsInstances } from '~/components/Outline/helpers/responses';
import {
	FillInConstraint,
	FillInConstraints,
	FillInConstraintsKeys,
	OutlineHierarchy,
	OutlineHierarchyResponses,
	ToggleConstraints,
	ViolatedFillInConstraint
} from '~/components/Outline/types';

export const getTemplateMaxInstancesNumber = (args: {
	max: ToggleConstraints['max_instances'];
	template: string;
	hierarchy: OutlineHierarchy;
	hierarchyResponses: OutlineHierarchyResponses;
}): number | null => {
	const { max } = args;

	// e.g. `max: 12`
	if (typeof max === 'number') {
		return Number.isInteger(max) ? max : null;
	}

	// e.g. `max: "24"`
	const numberMax = Number(max);
	if (Number.isInteger(numberMax)) {
		return numberMax;
	}

	// e.g. `max: "36%"`
	if (max.endsWith('%')) {
		const toggleTemplateInstances = getToggleTemplateParentsInstances(args);
		const instancedPercent = parseFloat(max) / 100;
		return Math.ceil(toggleTemplateInstances.length * instancedPercent);
	}

	return null;
};

export const constraintsMessages: Record<FillInConstraintsKeys, string> = {
	max_characters: 'Maximum character limit reached'
};

export const getViolatedFillInConstraint = (
	constraints: FillInConstraints | undefined,
	value: string | undefined
): ViolatedFillInConstraint | undefined => {
	let violatedConstraint: FillInConstraint;

	ObjectTyped.entries(constraints || {}).forEach(([constraint, constraintValue]) => {
		switch (constraint) {
			case 'max_characters': {
				const onlyCharactersValue = value?.replace(/\s/g, '');
				if (onlyCharactersValue?.length >= constraintValue)
					violatedConstraint = { key: constraint, value: constraintValue };
				break;
			}
		}
	});

	return violatedConstraint
		? {
				...violatedConstraint,
				violationMessage: constraintsMessages[violatedConstraint.key]
		  }
		: undefined;
};

/**
 * Counts the maximum length of the string which is needed to fit certain number of characters with spaces
 *
 * @param value - a raw user input with spaces
 * @param maxCharacters - the allowed number of characters in the `value`
 *
 * @example
 * // returns 5
 * getMaxLengthForCharacters('1 2 3', 3)
 *
 * @example
 * // returns 11
 * getMaxLengthForCharacters('hello there', 10)
 *
 * @example
 * // returns 10
 * getMaxLengthForCharacters('bro', 10)
 */
export const getMaxLengthForCharacters = (
	value?: string,
	maxCharacters?: number
): number | undefined => {
	if (maxCharacters === undefined) return;
	if (value === undefined) return maxCharacters;

	const characters = [...value];

	let maxLength = 0;
	let occupiedCharacters = 0;

	for (let index = 0; index < characters.length; index++) {
		if (occupiedCharacters >= maxCharacters) break;

		const char = characters[index];
		if (/\S/.test(char)) {
			occupiedCharacters++;
		}
		maxLength++;
	}

	return Math.max(maxLength, maxCharacters);
};

export const formatViolatedValue = (
	value: string,
	constraint: ViolatedFillInConstraint
): string => {
	switch (constraint.key) {
		case 'max_characters': {
			// Prevents entering/pasting more characters than specified in the config
			const valueMaxLength = getMaxLengthForCharacters(value, constraint.value);
			return value.slice(0, valueMaxLength);
		}
		default:
			return value;
	}
};

export const applyFieldConstraint = (
	fieldElement: HTMLTextAreaElement,
	constraint: ViolatedFillInConstraint
): void => {
	switch (constraint.key) {
		case 'max_characters': {
			fieldElement.setAttribute('maxlength', `${constraint.value}`);
		}
	}
};

export const removeFieldConstraints = (fieldElement: HTMLTextAreaElement): void => {
	fieldElement.removeAttribute('maxlength');
};
