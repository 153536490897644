/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ClassNames } from '@emotion/react';

import { generateID } from '~/utils';

import { modalContentStyles, styles } from './styles';

export type ModalProps = React.PropsWithChildren<{
	open: boolean;
	/**
	 * Whether to animate the modal dialog appearing or not.
	 * Defaults to true.
	 * Will not animate if `prefers-reduced-motion: reduce` is set.
	 */
	animated?: boolean;
	title: React.ReactNode;
	description?: React.ReactNode;
	actions: React.ReactNode;
	className?: string;
}>;

const Modal = React.forwardRef<HTMLDialogElement, ModalProps>((props, forwardedRef) => {
	const { open, animated = true, title, description, children, actions, className } = props;
	const internalRef = useRef<HTMLDialogElement>();
	const titleId = useMemo(() => `modal-title-${generateID()}`, []);
	const descriptionId = useMemo(() => `modal-description-${generateID()}`, []);

	const setRef = useCallback(
		(dialogEl: HTMLDialogElement) => {
			internalRef.current = dialogEl;
			if (typeof forwardedRef === 'function') {
				forwardedRef(dialogEl);
			} else if (forwardedRef) {
				forwardedRef.current = dialogEl;
			}
		},
		[forwardedRef]
	);

	const handleKeyDown = useCallback((e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			e.preventDefault();
		}
	}, []);

	useEffect(() => {
		if (open) {
			internalRef.current.showModal();
			internalRef.current.addEventListener('keydown', handleKeyDown);
		} else {
			internalRef.current.close();
			internalRef.current.removeEventListener('keydown', handleKeyDown);
		}
	}, [handleKeyDown, open]);

	const dialog = (
		<ClassNames>
			{({ cx }) => (
				<dialog
					css={styles}
					className={cx('modal', className)}
					ref={setRef}
					aria-labelledby={titleId}
					aria-describedby={descriptionId}
					data-animated={animated}
					role="alertdialog">
					<div className="libs-modal-content">
						{/* Normally we would focus an interactive element, but for continuity purposes we'll focus the dialog's heading */}
						<h1 className="modal-title" id={titleId} tabIndex={-1} autoFocus>
							{title}
						</h1>
						{description && (
							<div id={descriptionId} className="dialog-desc">
								{description}
							</div>
						)}
					</div>
					<div css={modalContentStyles} className="modal-children">
						{children}
					</div>
					<div className="modal-actions">{actions}</div>
				</dialog>
			)}
		</ClassNames>
	);

	return ReactDOM.createPortal(dialog, document.body);
});

Modal.displayName = 'SoomoModal';

export default Modal;
