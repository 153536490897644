import React from 'react';
import { ArtifactLink as ArtifactLinkFromLibs } from '@soomo/lib/components/pageElements/index';

type Props = {
	artifact: any;
};

const ArtifactLink: React.FC<Props> = ({ artifact }) => (
	<div style={{ margin: '20px 0' }}>
		<ArtifactLinkFromLibs
			type={artifact.css_class}
			href={artifact.artifact_url}
			transcriptHref={artifact.transcript_url}
			credits={artifact.credits}>
			{artifact.title}
		</ArtifactLinkFromLibs>
	</div>
);

export default ArtifactLink;
