import { css } from '@emotion/react';

export const tableStyles = css`
	border-collapse: collapse;
	table-layout: fixed;

	td,
	th {
		text-align: left;
		min-width: 50px;
		padding: 0 10px;
		font-weight: normal;
		font-size: 15px;
		max-height: 30px;
	}

	td,
	tbody th {
		border: 0.75px solid grey;
	}
`;

export const container = css`
	display: flex;
	justify-content: flex-start;
	padding-top: 10px 0;
`;
