import { css } from '@emotion/react';

export const tabPanelStyle = css`
	padding: 20px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid #d8d6d6;
	border-top: none !important;
	position: relative;
	min-height: 400px;

	&:before {
		position: absolute;
		content: ' ';
		height: 1px;
		width: 56%;
		right: 0px;
		top: -1px;
		background: #d8d6d6;
	}
`;
