import { MutableRefObject } from 'react';

export const waitForElementToBeInViewport = (
	ref: MutableRefObject<HTMLElement>,
	abortController?: AbortController
): Promise<void> =>
	new Promise((resolve, reject) => {
		const observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				observer.disconnect();
				resolve();
			}
		});

		abortController?.signal.addEventListener('abort', () => {
			observer.disconnect();
			reject(new DOMException('Waiting for the element was aborted', 'AbortError'));
		});

		observer.observe(ref.current);
	});
