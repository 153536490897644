import React, { useMemo } from 'react';

import { VideoFigure } from '../../VideoFigure';
import { commentBodyToNodes } from '../parsing';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MediaContext: MediaContextType = document?.soomo?.utils?.MediaContext;

type Props = {
	commentBody: string;
	instructor?: boolean;
	trackingConfig?: {
		commentId: number;
		thingId: string;
		pageFamilyId: string;
		courseId: number;
	};
};

const InstructorFeedbackBody: React.FC<Props> = (props) => {
	const { commentBody, instructor, trackingConfig } = props;

	const nodes = useMemo(() => commentBodyToNodes(commentBody), [commentBody]);

	return (
		<div data-preserve-white-space className="comment-body">
			{nodes.map((n, index) =>
				n.type === 'VIDEO_URL' ? (
					<div className="comment-figure" key={`feedback-video-${index}`}>
						<VideoFigure
							figure={{ disableEventReporting: instructor ? true : false, payload: n.content }}
							videoFigureProps={{
								onPlay: () => {
									MediaContext?.videoStarted(`${trackingConfig?.commentId}-${index}`);
								},
								onStart: () => {
									MediaContext?.videoStarted(`${trackingConfig?.commentId}-${index}`);
								},
								onPause: () => {
									MediaContext?.videoPaused(`${trackingConfig?.commentId}-${index}`);
								},
								onEnded: () => {
									MediaContext?.videoStopped(`${trackingConfig?.commentId}-${index}`);
								}
							}}
							trackingConfig={{
								familyId: trackingConfig?.thingId,
								requestParams: {
									comment_id: trackingConfig?.commentId
								}
							}}
							elementContext={{
								pageFamilyId: trackingConfig?.pageFamilyId,
								courseId: trackingConfig?.courseId
							}}
						/>
					</div>
				) : (
					n.content
				)
			)}
		</div>
	);
};

export default InstructorFeedbackBody;
