import { useRef, TouchEvent } from 'react';

export function useDoubleTap(
	callBack: (e: TouchEvent) => void,
	delay = 300
): { onTouchStart?: (e: TouchEvent<any>) => void } {
	const timer = useRef<NodeJS.Timeout>(null);

	const handleDoubleTap = (event: TouchEvent) => {
		if (!timer.current) {
			timer.current = setTimeout(() => {
				timer.current = null;
			}, delay);
		} else {
			clearTimeout(timer.current);
			timer.current = null;
			callBack && callBack(event);
		}
	};

	return callBack ? { onTouchStart: handleDoubleTap } : {};
}
