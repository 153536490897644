import { useCallback, useRef, useState } from 'react';

import {
	Intervention,
	interventions,
	SPEEDBUMP_INTERVENTION_THRESHOLD_IN_MILLISECONDS
} from './constants';
import usePresentationLimits from './usePresentationLimits';

import type { StudentViewElementProps, InterventionSurveyData } from '../types';
import type { SpeedbumpInterventionsProps } from './SpeedbumpInterventions';

export default function useSpeedbump({
	isSpeedbumpEnabled,
	questionPool,
	courseId,
	chapterFamilyId,
	resetCount,
	attemptsAllowed,
	onInterventionShown,
	onInterventionSurveyResponded
}: StudentViewElementProps): SpeedbumpInterventionsProps & {
	notifySpeedbumpReset: () => void;
	notifySpeedbumpSaved: (wasAnswerCorrect: boolean) => {
		willShowIntervention: boolean;
	};
} {
	const [isFirstInterventionOpen, setIsFirstInterventionOpen] = useState(false);
	const [isSecondInterventionOpen, setIsSecondInterventionOpen] = useState(false);
	const [isThirdInterventionOpen, setIsThirdInterventionOpen] = useState(false);
	const lastResetAt = useRef<Date>(null);
	const lastInterventionShown = useRef<null | Intervention>(null);
	const { incrementLimit, isLimitReached } = usePresentationLimits({
		isSpeedbumpEnabled,
		courseId,
		chapterFamilyId
	});
	/**
	 * Number of attempts used at the time we report to Keen.
	 * Note that `answer` will still be null at this time, so we should unconditionally add 1 rather than checking
	 * if `answer` is non-null (since we can only trigger Speedbump after the student has saved).
	 */
	const attemptsUsed = resetCount + 1;
	const attemptsRemaining = attemptsAllowed === -1 ? -1 : attemptsAllowed - attemptsUsed;

	const notifySpeedbumpReset = useCallback(() => {
		if (!isSpeedbumpEnabled) {
			return;
		}

		lastResetAt.current = new Date();
	}, [isSpeedbumpEnabled]);

	const notifySpeedbumpSaved = useCallback(
		(wasAnswerCorrect) => {
			const actualThreshold =
				document.soomo?.env !== 'production'
					? (window as any)._TEST_speedbumpThreshold ??
					  SPEEDBUMP_INTERVENTION_THRESHOLD_IN_MILLISECONDS
					: SPEEDBUMP_INTERVENTION_THRESHOLD_IN_MILLISECONDS;
			if (
				!isSpeedbumpEnabled ||
				!lastResetAt.current ||
				Date.now() - lastResetAt.current.getTime() > actualThreshold ||
				lastInterventionShown.current === 3
			) {
				return { willShowIntervention: false };
			}

			let interventionToShow: Intervention | null = null;
			if (wasAnswerCorrect) {
				// if the answer was correct, then we can **only** show intervention 3,
				// even if interventions 1 and 2 have not been shown yet.
				if (!isLimitReached(3)) {
					interventionToShow = 3;
				}
			} else {
				// if the answer was incorrect, then try to show the next intervention in the sequence
				let nextIntervention = ((lastInterventionShown.current ?? 0) + 1) as Intervention;
				while (nextIntervention <= interventions[interventions.length - 1]) {
					if (!isLimitReached(nextIntervention)) {
						interventionToShow = nextIntervention;
						break;
					} else {
						nextIntervention++;
					}
				}
			}

			if (!interventionToShow) {
				return { willShowIntervention: false };
			}

			if (interventionToShow === 1) {
				setIsFirstInterventionOpen(true);
			} else if (interventionToShow === 2) {
				setIsSecondInterventionOpen(true);
			} else if (interventionToShow === 3) {
				setIsThirdInterventionOpen(true);
			}
			lastInterventionShown.current = interventionToShow;
			incrementLimit(interventionToShow);
			onInterventionShown?.({
				respondable_family_id: questionPool.family_id,
				attempts_used: attemptsUsed,
				attempts_remaining: attemptsRemaining,
				intervention: {
					type: 'speedbump',
					number: interventionToShow
				}
			});
			return { willShowIntervention: true };
		},
		[
			attemptsRemaining,
			attemptsUsed,
			questionPool.family_id,
			isSpeedbumpEnabled,
			incrementLimit,
			isLimitReached,
			onInterventionShown
		]
	);

	const onCloseFirstIntervention = useCallback(() => {
		setIsFirstInterventionOpen(false);
	}, []);

	const onCloseSecondIntervention = useCallback(() => {
		setIsSecondInterventionOpen(false);
	}, []);

	const onSubmitThirdIntervention = useCallback(
		(data: InterventionSurveyData) => {
			setIsThirdInterventionOpen(false);
			onInterventionSurveyResponded?.({
				respondable_family_id: questionPool.family_id,
				attempts_used: attemptsUsed,
				attempts_remaining: attemptsRemaining,
				intervention: {
					type: 'speedbump',
					number: 3
				},
				response: data
			});
		},
		[onInterventionSurveyResponded, attemptsUsed, attemptsRemaining, questionPool.family_id]
	);

	return {
		isFirstInterventionOpen,
		isSecondInterventionOpen,
		isThirdInterventionOpen,
		notifySpeedbumpReset,
		notifySpeedbumpSaved,
		onCloseFirstIntervention,
		onCloseSecondIntervention,
		onSubmitThirdIntervention
	};
}
