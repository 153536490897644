import * as React from 'react';

export function DataAndPieChart(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<path d="M51.84 61.12a14 14 0 0 1-10-4.15 14.21 14.21 0 0 1-2.42-3.27H9.37a3.26 3.26 0 0 1-3.25-3.25V14.13a3.26 3.26 0 0 1 3.25-3.25h44.2a3.25 3.25 0 0 1 3.25 3.25V34a13.93 13.93 0 0 1 4.69 2.95l.28.27a14 14 0 0 1-10 23.93Zm-8.76-5.35a12.35 12.35 0 0 0 16.82.65l-8.67-8.73a1.07 1.07 0 0 1-.15-.22.88.88 0 0 1 0-.15V34.78a12.35 12.35 0 0 0-10.39 17.61.91.91 0 0 1 .09.17 12.36 12.36 0 0 0 2.3 3.21Zm10.8-7.84 7.23 7.29a12.22 12.22 0 0 0 3-7.31ZM9.37 12.59a1.54 1.54 0 0 0-1.54 1.54v36.32A1.54 1.54 0 0 0 9.37 52h29.3a14.05 14.05 0 0 1 13.16-19h.07a13.9 13.9 0 0 1 3.22.38V14.13a1.55 1.55 0 0 0-1.55-1.54Zm51.57 26.17-7.11 7.47h10.31a12.19 12.19 0 0 0-3.2-7.47Zm-8.25-4V45l7-7.37a12.19 12.19 0 0 0-7-2.85ZM15 42.91a4.48 4.48 0 1 1 4.48-4.48A4.49 4.49 0 0 1 15 42.91Zm0-7.25a2.78 2.78 0 1 0 2.77 2.77A2.77 2.77 0 0 0 15 35.66Zm19 5.48a4.48 4.48 0 1 1 4.48-4.48A4.49 4.49 0 0 1 34 41.14Zm0-7.25a2.78 2.78 0 1 0 2.77 2.77A2.77 2.77 0 0 0 34 33.89Zm-8.42 1.52A4.48 4.48 0 1 1 30 30.93a4.49 4.49 0 0 1-4.47 4.48Zm0-7.25a2.77 2.77 0 1 0 2.77 2.77 2.77 2.77 0 0 0-2.82-2.77ZM42.92 32a4.48 4.48 0 1 1 4.48-4.48A4.49 4.49 0 0 1 42.92 32Zm0-7.25a2.77 2.77 0 1 0 2.77 2.77 2.77 2.77 0 0 0-2.77-2.82Z" />
			<path d="m18 36.59-.93-1.44 4.37-2.83.93 1.43Zm12.56-1.3L28 33.77l.88-1.47 2.52 1.53Zm6.89-.29-1.25-1.13 3.88-4.12 1.24 1.17ZM56 21.34H7a.86.86 0 0 1-.85-.86.85.85 0 0 1 .85-.85h49a.85.85 0 0 1 .85.85.86.86 0 0 1-.85.86Z" />
		</svg>
	);
}
