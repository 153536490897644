import React from 'react';

import cn from 'classnames';

interface Props {
	onClick: (evt: React.MouseEvent) => void;
	disabled: boolean;
}

const NextQuestionButton = ({ onClick, disabled }: Props) => {
	return (
		<button
			aria-label="continue on to next question"
			className={cn('ally-ignore', 'navigation-icon', {
				'navigation-disabled': disabled
			})}
			disabled={disabled}
			onClick={onClick}>
			<svg viewBox="0 0 465 1000" preserveAspectRatio="none">
				<g>
					<path d="M13 870c0 0 358 -370 358 -370c0 0 -358 -372 -358 -372c-17.333 -17.333 -17.333 -33.333 0 -48c17.333 -17.333 33.333 -17.333 48 0c0 0 392 394 392 394c16 17.333 16 34 0 50c0 0 -392 394 -392 394c-14.667 17.333 -30.667 17.333 -48 0c-17.333 -14.667 -17.333 -30.667 0 -48c0 0 0 0 0 0" />
				</g>
			</svg>
		</button>
	);
};

export default NextQuestionButton;
