import { useCallback, useState } from 'react';

import { localStorageGet, localStorageSet } from '~/utils/localStorage';

type UseLocalStorageReturnType<T> = readonly [() => T, (newValue: T) => void];

export function useLocalStorageWithBackup<T>(
	key: string,
	initialValue: T
): UseLocalStorageReturnType<T> {
	const [value, setValue] = useState<T>(() => {
		const item = localStorage.getItem(key);
		return item && item !== 'undefined' ? JSON.parse(item) : initialValue;
	});

	const loadStoredValueOrLocal: UseLocalStorageReturnType<T>[0] = useCallback(() => {
		const item = localStorageGet(key);
		return item || value;
	}, [key, value]);

	const setValueAndBackup: UseLocalStorageReturnType<T>[1] = useCallback(
		(newValue) => {
			setValue(newValue);
			localStorageSet(key, newValue);
		},
		[key]
	);

	return [loadStoredValueOrLocal, setValueAndBackup];
}
