import { css, SerializedStyles } from '@emotion/react';

import { mixins, Theme } from '~/styles/themes';

import { CellFormatting, FormatType } from '../../helpers/types';

export const style = (props: {
	isEditable: boolean;
	cellFormatting: CellFormatting;
	importedStyle: string;
	withError: boolean;
	isLeftAlignedError: boolean;
	isInvalid: boolean;
	isSelected: boolean;
	isReplaceLongNumber: boolean;
	isOverflowEnabled: boolean;
	isEditing: boolean;
	cellPositioning: { isLastColumn: boolean; isLastRow: boolean };
	cellOriginWidth: number;
	isSPFocused: boolean;
}): SerializedStyles => {
	const {
		isEditable,
		cellFormatting,
		importedStyle,
		withError,
		isLeftAlignedError,
		isInvalid,
		isOverflowEnabled,
		isSelected,
		isEditing,
		cellPositioning,
		cellOriginWidth,
		isReplaceLongNumber,
		isSPFocused
	} = props;

	return css`
		background: ${isEditable ? '#EDF6FF' : 'white'};
		position: relative;
		box-shadow: 0 0 0 1px #dad4d4;
		font-weight: 500;

		div.cell {
			${cellFormatting.type === FormatType.Accounting &&
			css`
				&::before {
					content: '$';
					position: absolute;
					left: 10px;
					font-weight: normal !important;
					font-style: normal !important;
				}
				padding-left: 30px !important;
			`};

			.selectable {
				display: block;
				position: absolute;

				width: 90%;
				height: 90%;

				z-index: 1;
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}

		div.cell {
			display: flex;
			cursor: default;
			user-select: none;
			padding: 2px 10px;
			align-items: center;
			justify-content: flex-start;
			min-height: 30px;
			overflow-wrap: anywhere; // Breaks the long words. Ex - https://playcode.io/982634

			${isOverflowEnabled &&
			css`
				white-space: nowrap;
			`}

			${isReplaceLongNumber &&
			isOverflowEnabled &&
			css`
				${whenMainHidden()}
				&:before {
					opacity: 1;
					content: '####################################################################';
					left: -25px;
					position: relative;
					letter-spacing: 0.5px;
				}
			`}
		}

		div.cell.overflow {
			display: inline !important;
			width: auto !important;
			visibility: hidden !important;
		}

		${cellPositioning.isLastRow &&
		css`
			.replacer {
				height: 99% !important;
			}
		`}

		${importedStyle}
		${withError && showWithError(isLeftAlignedError, isInvalid)}
		${isInvalid &&
		onValidationFail(isSelected, isEditing, cellPositioning, cellOriginWidth, isSPFocused)}
	`;
};

export const cellElementStyle = (
	theme: Theme,
	isOverflowing: boolean,
	height: number
): SerializedStyles =>
	isOverflowing
		? css`
				display: flex;
				overflow: hidden;
				text-overflow: ellipsis;
				justify-content: flex-start !important;
				opacity: 0;

				height: ${height}px !important;
		  `
		: css`
				overflow: hidden;
				${mixins.placeholderAlignmentAttribute(theme)}
		  `;

export const replaceElementStyle = (width: number, isInvalid?: boolean): SerializedStyles => css`
	position: absolute;
	background: inherit;
	top: 0;
	height: 97%;
	width: ${width - 1}px;
	z-index: 100;
	pointer-events: none;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	padding: 2px 10px;
	user-select: none;
	overflow-wrap: anywhere;

	${isInvalid &&
	css`
		border: 2px solid #e5442e !important;
		background: #fdebe8 !important;
	`}
`;

const whenMainHidden = () => css`
	display: flex;
	overflow: hidden;
	text-overflow: ellipsis;
	justify-content: flex-start !important;
`;

const showWithError = (isLeftAlignedError: boolean, isInvalid: boolean) => css`
	div {
		display: flex !important;
		padding: 2px 15px !important;
	}

	.cell {
		font-weight: normal !important;
		font-style: unset !important;

		${!isLeftAlignedError &&
		css`
			justify-content: center !important;
			text-align: center;
		`}
	}
	&::after {
		content: '';
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-top: 12px solid red;
		position: absolute;
		top: ${!isInvalid ? 0 : '-1px'};
		right: ${!isInvalid ? 0 : '-1px'};
	}
`;

export const onValidationFail = (
	isSelected: boolean,
	isEditing: boolean,
	cellPositioning: { isLastColumn: boolean; isLastRow: boolean },
	cellOriginWidth: number,
	isSPFocused: boolean
): SerializedStyles => css`
	background: #fdebe8 !important;
	color: #000 !important;
	/** Increase cell width when editing cell, this fix jump on mode switch because border is now shown at that time*/
	${cellOriginWidth && isEditing && `min-width:${cellOriginWidth}px !important;`}
	textarea {
		background: #fdebe8 !important;
	}

	border: 2px solid #e5442e;

	${isEditing && 'border: none;'}

	${isSelected && isSPFocused && 'border: 2px solid transparent;'}

	/** These styles below kind of hack to make cell with validation error look consistent, make borders has the same size(visually) and do not break a spreadsheet*/
	${!cellPositioning.isLastRow && 'border-bottom-width: 3px;'}
	${!cellPositioning.isLastColumn && 'border-right-width: 3px;'}
`;
