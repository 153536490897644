import { useRef, useEffect, MutableRefObject } from 'react';

export const useEventListener = <TEvent extends Event, TElement extends HTMLElement = HTMLElement>(
	eventName: string,
	handler: (event: TEvent) => void,
	elementRef: MutableRefObject<TElement> | TElement | Document | Window = window
): void => {
	const savedHandler = useRef(null);
	useEffect(() => void (savedHandler.current = handler), [handler]);

	useEffect(() => {
		const eventListener = (event: TEvent) => savedHandler.current(event);

		const target = 'current' in elementRef ? elementRef.current : elementRef;
		target.addEventListener(eventName, eventListener);
		return () => target.removeEventListener(eventName, eventListener);
	}, [elementRef, eventName]);
};
