import React from 'react';

import { ChartDataType, ChartType } from '../../types';
import Pie from '../Pie';

interface Props {
	type: ChartType;
	isNewlyCreated: boolean;
	name: string;
	data: ChartDataType;
}

const ChartSelection: React.FC<Props> = ({ type, name, data, isNewlyCreated }) => {
	switch (type) {
		case ChartType.Pie:
			return <Pie data={data} name={name} key={name} isNewlyCreated={isNewlyCreated} />;
		default:
			return <>Configuration error: unknown chart type: {type}.</>;
	}
};

export default ChartSelection;
