import { useContext, useEffect } from 'react';

import { shallow } from 'zustand/shallow';

import { TemplateValidationContext } from '../../WritingTemplate/providers/TemplateValidationProvider';
import { ValidationActionType, ValidationItemStatus } from '../../WritingTemplate/types';
import { useSpreadsheetSelector } from './../store/provider';
/**
 * A hook that requests revalidation when:
 * - there is a failed validation, and
 * - the current cell being edited changes (or the user stops editing a cell)
 *
 * The intent is to give immediate feedback when a student has corrected a validation error.
 * See T-60176.
 *
 * Note that this hook currently fires *twice* (and thus requests revalidation twice) when editing a cell
 * that previously failed validation and has a formula in it.
 *
 * This is currently necessary because when `cellEditing` changes, if the previously edited cell
 * contains a formula and its formula was updated, then the new formula hasn't been re-evaluated yet.
 * So we listen to changes to `cellEvaluatedValues`, which will tell us when cells containing formulas have
 * been re-evaluated, and re-request validation when that happens.
 */
export function useValidationRecheck(): void {
	const { dest, editingCell, evaluatedFormulasValues } = useSpreadsheetSelector(
		(state) => ({
			dest: state.dest,
			editingCell: state.editingCell,
			evaluatedFormulasValues: state.evaluatedFormulasValues
		}),
		shallow
	);

	const { validationState, dispatchValidation } = useContext(TemplateValidationContext);

	const spreadsheetValidationState = validationState?.validations[dest];
	useEffect(() => {
		if (
			spreadsheetValidationState &&
			spreadsheetValidationState.status === ValidationItemStatus.Failure &&
			!editingCell
		) {
			dispatchValidation({
				type: ValidationActionType.Run,
				value: {
					[dest]: {
						type: 'inner',
						status: ValidationItemStatus.Pending
					}
				}
			});
		}
		// cellEvaluatedValues is here for a good reason:
		// it ensures that the hook is re-run when a cell containing a formula is re-evaluated with a new value.
		// in other words, if this cell has a formula and previously failed an exact value validation,
		// then we need to re-evaluate the updated formula, wait for that calculation to finish, then re-check
		// the exact value validation against the result of the updated formula.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingCell, evaluatedFormulasValues]);
}
