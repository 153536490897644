import React from 'react';

import pick from 'lodash-es/pick';

import { Theme } from '~/styles/themes';
import { AllyOptions, PollingOptions } from '~/types';
import { Policy } from '~/types/WebtextManifest';
import Polling from '~/utils/polling';

import PopupQuiz from './PopupQuiz';
import {
	PopupQuiz as PopupQuizType,
	PopupQuizData,
	PopupQuizAPILoadResponse,
	PopupQuizAnswers,
	PopupQuizResults,
	PopupQuizQuestion,
	PopupQuizAPI,
	PopupQuizLoadDataStarted
} from './types';

const EMPTY_RESULTS: PopupQuizResults = {
	resultsAvailable: false,
	score: 0,
	maxScore: null,
	scoreAsPercentage: 0,
	questionScoreInterpretations: [],
	dimensionScoreInterpretations: {},
	explanation: '',
	topLineResult: '',
	questionScoreInterpretationsHeading: '',
	footer: '',
	message: null,
	resettable: false
};

interface Props {
	theme: Theme;
	readOnly: boolean;
	popupQuiz: PopupQuizType;
	policy: Policy;
	pollingOptions?: PollingOptions;
	ally?: AllyOptions;
	events?: { subscribe: (string, Function) => void };
	api: PopupQuizAPI;
	online: boolean;
	// If our data is already loaded by an external source
	// (like in mobile v2), it can just pass it in here and
	// not deal with the nonsense of passing in `load` in the api
	// to load a thing it's already loaded.
	popupQuizLoadResponse?: PopupQuizAPILoadResponse;
}

interface State {
	online: boolean;
	loaded: boolean;
	unlocked: boolean;
	lockedMessage: string;
	needsSave: boolean;
	saving: boolean;
	reviewing: boolean;
	resetting: boolean;
	questions: PopupQuizQuestion[];
	questionCount: number;
	savedAnswerCount: number;
	draftAnswers: PopupQuizAnswers;
	savedAnswers: PopupQuizAnswers;
	resetCount: number;
	completed: boolean;
	resultsAvailable: boolean;
	results: PopupQuizResults;
}

class PopupQuizContainer extends React.Component<Props, State> {
	_mounted = false;

	loadPolling;
	loadPollingElementRef;

	constructor(props: Props) {
		super(props);
		const loadResponse = props.popupQuizLoadResponse;
		if (loadResponse) {
			this.state = this.mapLoadResponseToState(loadResponse, true);
		} else {
			this.state = {
				online: props.online ?? window.navigator.onLine,
				loaded: false,
				unlocked: false,
				lockedMessage: '',
				needsSave: false,
				saving: false,
				reviewing: false,
				resetting: false,
				questions: [],
				questionCount: 0,
				resetCount: 0,
				draftAnswers: {},
				savedAnswers: {},
				savedAnswerCount: 0,
				completed: false,
				resultsAvailable: false,
				results: EMPTY_RESULTS
			};
		}

		this.loadPollingElementRef = React.createRef();

		if (props.events) {
			props.events.subscribe('respondable.update.' + this.props.popupQuiz.family_id, () => {
				if (this.state.completed && !this.state.resultsAvailable) {
					this.load();
				}
			});
		}
	}

	componentDidMount() {
		this._mounted = true;
		window.addEventListener('online', this.onNetworkConnect, false);
		window.addEventListener('offline', this.onNetworkDisconnect, false);
		this.setupLoadPolling();
		this.load().then(this.checkLoadPolling);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.online != null && nextProps.online != prevState.online) {
			const online = nextProps.online;
			return online || prevState.saving
				? { online }
				: { online, draftAnswers: prevState.savedAnswers, needsSave: false };
		}
		return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.popupQuiz.family_id !== this.props.popupQuiz.family_id) {
			this.stopLoadPolling();
			this.load().then(this.checkLoadPolling);
		}
		if (prevProps.popupQuizLoadResponse !== this.props.popupQuizLoadResponse) {
			this.setStateFromLoadResponse(this.props.popupQuizLoadResponse, true);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('online', this.onNetworkConnect);
		window.removeEventListener('offline', this.onNetworkDisconnect);
		this.stopLoadPolling();
		this._mounted = false;
	}

	onNetworkConnect = () => {
		this.setState({ online: true });
	};

	onNetworkDisconnect = () => {
		this.setState({ online: false });
		if (!this.state.saving) {
			this.resetDraftAnswer();
		}
	};

	setupLoadPolling = () => {
		const { pollingOptions } = this.props;
		if (!pollingOptions || !pollingOptions.disabled) {
			this.loadPolling = new Polling(this.load, 10000, 30);
			this.loadPolling.element = this.loadPollingElementRef.current;
			if (pollingOptions && pollingOptions.scrollListenerSelector) {
				this.loadPolling.scrollListenerSelector = pollingOptions.scrollListenerSelector;
			}
		}
	};

	checkLoadPolling = () => {
		if (this.state.completed && !this.state.resultsAvailable) {
			this.startLoadPolling();
		} else {
			this.stopLoadPolling();
		}
	};

	startLoadPolling = () => {
		if (this.loadPolling) {
			this.loadPolling.ensurePollingIsActive();
		}
	};

	stopLoadPolling = () => {
		if (this.loadPolling) {
			this.loadPolling.cancelPolling(); // we've been logged out, so stop polling
			this.loadPolling.removePollingEventListeners();
		}
	};

	mapLoadResponseToState: any = (response: PopupQuizAPILoadResponse, loaded: boolean) => {
		const data = pick(response.data, [
			'unlocked',
			'questions',
			'questionCount',
			'resetCount',
			'lockedMessage'
		]) as PopupQuizData;

		const { result, completed, resultsAvailable } = response.data as PopupQuizLoadDataStarted;

		const savedAnswers = result || {};
		const savedAnswerCount = Object.keys(savedAnswers).length;

		/**
		 * Don't pick up the remote state if a user has made a new choice that doesn't exist on the server yet
		 */
		const draftAnswersCount = Object.keys(this.state?.draftAnswers || {}).length;
		const draftAnswers =
			this.state?.needsSave && draftAnswersCount > savedAnswerCount
				? this.state.draftAnswers
				: savedAnswers;

		// whether reviewing or not
		const reviewing = loaded ? (this.state ? this.state.reviewing : completed) : completed;

		const results = resultsAvailable
			? pick(response.data, [
					'score',
					'maxScore',
					'scoreAsPercentage',
					'scores',
					'maxScores',
					'scoresAsPercentages',
					'questionScoreInterpretations',
					'dimensionScoreInterpretations',
					'explanation',
					'topLineResult',
					'questionScoreInterpretationsHeading',
					'footer',
					'message',
					'resettable'
			  ])
			: EMPTY_RESULTS;

		return {
			...data,
			draftAnswers,
			savedAnswers,
			savedAnswerCount,
			completed,
			resultsAvailable,
			results,
			reviewing,
			loaded: true
		};
	};

	setStateFromLoadResponse: (PopupQuizAPILoadResponse, boolean) => void = (response, loaded) => {
		if (this._mounted) {
			// could become unmounted prior to running the load callback
			this.setState(this.mapLoadResponseToState(response, loaded));
		}
	};

	load = (): Promise<unknown> => {
		if (this.props.api.load) {
			return this.props.api
				.load(this.props.popupQuiz.family_id)
				.then((response: PopupQuizAPILoadResponse) =>
					this.setStateFromLoadResponse(response, this.state.loaded)
				)
				.catch((error) => {
					if (error && error.response && error.response.status === 401) {
						this.stopLoadPolling();
					} else {
						throw error;
					}
				});
		} else {
			return Promise.resolve();
		}
	};

	draftAnswer = (questionFamilyId, choiceFamilyId) => {
		this.setState({
			draftAnswers: { ...this.state.draftAnswers, [questionFamilyId]: choiceFamilyId },
			needsSave: true
		});
	};

	resetDraftAnswer = () => {
		this.setState({ draftAnswers: this.state.savedAnswers, needsSave: false });
	};

	saveAnswers = (): Promise<unknown> => {
		if (this.state.saving) {
			throw new Error('Already saving?!');
		}

		const answers = this.state.draftAnswers;
		const answerCount = Object.keys(answers).length;
		const post = answerCount === this.state.questionCount;

		return new Promise((resolve, reject) => {
			this.setState({ saving: true, needsSave: false }, () => {
				this.props.api
					.save(this.props.popupQuiz.family_id, answers, post)
					.then(() => {
						if (post) {
							resolve(
								this.load().then(() => {
									this.setState(
										{
											draftAnswers: this.state.savedAnswers,
											saving: false,
											reviewing: true
										},
										this.checkLoadPolling
									);
								})
							);
						} else {
							// Not a submission, so we won't reload, but instead will
							// just assume that a successful save means we saved our
							// draft answers.
							this.setState(
								{
									savedAnswers: this.state.draftAnswers,
									savedAnswerCount: answerCount,
									saving: false
								},
								resolve as any
							);
						}
					})
					.catch((error) => {
						this.setState({ saving: false, needsSave: true });
						reject(error);
					});
			});
		});
	};

	reset = (): Promise<unknown> => {
		if (this.state.resetting) {
			throw new Error('Already resetting?!');
		}

		return new Promise((resolve) => {
			this.setState({ resetting: true }, () => {
				this.props.api.reset(this.props.popupQuiz.family_id).then(() => {
					resolve(
						this.load().then(() => {
							this.setState({ draftAnswers: {}, reviewing: false, resetting: false });
						})
					);
				});
			});
		});
	};

	render() {
		return (
			<div ref={this.loadPollingElementRef} data-testid="puq">
				<PopupQuiz
					ally={this.props.ally}
					theme={this.props.theme}
					readOnly={this.props.readOnly}
					popupQuiz={this.props.popupQuiz}
					policy={this.props.policy}
					unlocked={this.state.unlocked}
					lockedMessage={this.state.lockedMessage}
					questions={this.state.questions}
					questionCount={this.state.questionCount}
					draftAnswers={this.state.draftAnswers}
					savedAnswers={this.state.savedAnswers}
					savedAnswerCount={this.state.savedAnswerCount}
					resetCount={this.state.resetCount}
					completed={this.state.completed}
					resultsAvailable={this.state.resultsAvailable}
					results={this.state.results}
					loaded={this.state.loaded}
					needsSave={this.state.needsSave}
					saving={this.state.saving}
					reviewing={this.state.reviewing}
					draftAnswer={this.draftAnswer}
					resetDraftAnswer={this.resetDraftAnswer}
					saveAnswers={this.saveAnswers}
					reset={this.reset}
					online={this.state.online}
				/>
			</div>
		);
	}
}

export default PopupQuizContainer;
