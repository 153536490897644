import { useContext, useEffect } from 'react';

import { shallow } from 'zustand/shallow';

import { EditModeStateContext } from '~/components/WritingTemplate/EditMode/providers/EditModeStateProvider';
import { useSpreadsheetSelector } from '~/components/WritingTemplate/Spreadsheet/store/provider';

import { selectEditableSelectedCell } from '../store/selectors';

export function useCallbackOnShowInput(): void {
	const { isTouchDevice, isSelectedCellEditable, focused } = useSpreadsheetSelector(
		(state) => ({
			isTouchDevice: state.isTouchScreen,
			isSelectedCellEditable: selectEditableSelectedCell(state),
			focused: state.focused
		}),
		shallow
	);

	const editModeState = useContext(EditModeStateContext);
	const spreadsheetProps = editModeState?.spreadsheetProps;

	useEffect(() => {
		if (!isTouchDevice || !spreadsheetProps) return;

		if (isSelectedCellEditable && focused) {
			/**
			 * Timeout here is needed for making sure that in stack of calls it will be the last
			 */
			setTimeout(() => spreadsheetProps.hideElementsCallback?.(), 100);
			return;
		}

		spreadsheetProps.showHiddenElementsCallback?.();
	}, [isTouchDevice, spreadsheetProps, isSelectedCellEditable, focused]);
}
