import * as React from 'react';

export function PapersWithEye(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={34}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.89054178847434px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={30.06} fill="currentColor" />
			<path
				d="M46.27 17.78H22.79a1.07 1.07 0 0 0-1.07 1.07v29.88a1.07 1.07 0 0 0 1.07 1.07H26v3.35a1.07 1.07 0 0 0 1.06 1.06h23.48a1.06 1.06 0 0 0 1.05-1.06V28.28a3.15 3.15 0 0 0-.93-2.28l-3-3a2.61 2.61 0 0 0-.31-.27v-3.88a1.07 1.07 0 0 0-1.08-1.07Zm-22.4 29.88V19.92h21.34v2.17H27.07A1.07 1.07 0 0 0 26 23.16v24.57Zm4.26 4.42v-27.9h14.94v5.32a1.06 1.06 0 0 0 1.06 1.06h5.34v21.52Zm21-24.57a1.07 1.07 0 0 1 .31.76v.18h-4.23v-4.27h.18a1.09 1.09 0 0 1 .73.32Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M57.06 40.89a21.06 21.06 0 0 0-3.3-3.17 17.39 17.39 0 0 0-3.61-2.17 10.6 10.6 0 0 0-9 0 17.39 17.39 0 0 0-3.61 2.17 20.7 20.7 0 0 0-3.31 3.17l-.35.43.35.43a21.14 21.14 0 0 0 3.31 3.17 17.84 17.84 0 0 0 3.61 2.17 10.67 10.67 0 0 0 9 0 17.84 17.84 0 0 0 3.61-2.17 21.79 21.79 0 0 0 3.3-3.14l.34-.43Z"
				style={{
					strokeMiterlimit: 10,
					strokeWidth: '4.3358126827115px'
				}}
				stroke="currentColor"
				fill="currentColor"
			/>
			<path
				d="M57.05 40.87a20.93 20.93 0 0 0-3.29-3.16 17 17 0 0 0-3.61-2.17 10.62 10.62 0 0 0-9 0 17 17 0 0 0-3.61 2.17 20.64 20.64 0 0 0-3.31 3.16l-.35.43.35.44a20.64 20.64 0 0 0 3.31 3.16 17 17 0 0 0 3.61 2.17 10.62 10.62 0 0 0 9 0 17 17 0 0 0 3.61-2.17 21.13 21.13 0 0 0 3.32-3.16l.34-.44Zm-4.18 3c-1.72 1.31-4.34 2.89-7.23 2.89a12.55 12.55 0 0 1-7.26-2.89 20.47 20.47 0 0 1-2.73-2.53c1.22-1.35 5.28-5.41 10-5.41a12.34 12.34 0 0 1 7.23 2.89 22.46 22.46 0 0 1 2.72 2.48 21.77 21.77 0 0 1-2.73 2.53Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.541976585338937px'
				}}
			/>
			<path
				d="M45.6 37.45a3.83 3.83 0 1 0 3.83 3.83 3.83 3.83 0 0 0-3.83-3.83Zm0 6.5a2.7 2.7 0 1 1 2.69-2.7A2.69 2.69 0 0 1 45.63 44Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.541976585338937px'
				}}
			/>
		</svg>
	);
}
