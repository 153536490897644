import React from 'react';

const SvgSaQuestion = (props) => (
	<svg className="short-answer-icon" width={30} height={27} aria-hidden {...props}>
		<g fill="none" fillRule="evenodd">
			<path fill="#F5F5F5" d="M-388-45h1443v508H-388z" />
			<g fill="#5F01DF">
				<path d="M0 26l8-2.58L2.58 18zM4 16.242L17.242 3 23 8.758 9.758 22zM25.611 5.833L24.444 7 19 1.556 20.167.389a1.253 1.253 0 011.814 0l3.63 3.63a1.253 1.253 0 010 1.814" />
			</g>
		</g>
	</svg>
);

export default SvgSaQuestion;
