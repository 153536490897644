import React from 'react';

import { css } from '@emotion/react';
import cn from 'classnames';
import { transparentize } from 'polished';

import { breakpoints, ThemeName } from '~/styles/themes';

interface Props {
	label: JSX.Element;
	score: number;
	scoreAsPercentage: number;
	basis: number;
	barOptions: {
		showSeparator?: boolean;
		showFullBar?: boolean;
		showScore?: boolean;
		showBasis?: boolean;
	};
}

const ScoreBar: React.FC<Props> = (props) => {
	const { label, score, scoreAsPercentage, basis, barOptions } = props;
	const { showSeparator, showFullBar, showScore, showBasis } = barOptions;

	const percentWidth = showBasis ? 0.65 : 0.8;
	const filledBarWidth = '' + (showFullBar ? 1 : percentWidth) * scoreAsPercentage + '%';
	const fullBarRemainingWidth = '' + (100 - scoreAsPercentage) + '%';

	const scoreLabel = showScore !== false && (
		<span className="score">
			{!showBasis && <strong>{score}</strong>}
			{showBasis && `${score} out of ${basis}`}
		</span>
	);

	return (
		<div css={styles}>
			<div>{label}</div>
			<div>
				<div className="bar-and-score" data-show-separator={showSeparator !== false}>
					{!showFullBar && <span className="filled-bar" style={{ width: filledBarWidth }} />}
					{showFullBar && (
						<span className="full-bar" data-show-basis={!!showBasis}>
							<span
								className={cn('filled-bar', {
									'filled-not-0-percent': scoreAsPercentage !== 0,
									'filled-100-percent': scoreAsPercentage === 100
								})}
								style={{ width: filledBarWidth }}
							/>
							<span className="remaining-bar" style={{ width: fullBarRemainingWidth }} />
						</span>
					)}
					{scoreLabel}
				</div>
			</div>
		</div>
	);
};

const styles = (theme) => {
	const { popupQuiz } = theme;
	const progressRadius = popupQuiz.progress.indicatorRadius;

	return css`
		.bar-and-score {
			padding-top: 8px;
			white-space: nowrap;

			&[data-show-separator='false'] {
				padding-top: 5px;
			}
		}

		.full-bar {
			display: inline-block;
			width: 50%;

			&[data-show-basis='false'] {
				width: 80%;
			}

			@media (min-width: ${breakpoints.small}) {
				width: 80%;
			}
		}

		.filled-bar {
			display: inline-block;
			height: 1em;
			background: ${popupQuiz.colors.primary};
		}

		.remaining-bar {
			display: inline-block;
			height: 1em;
			background: ${transparentize(0.75, popupQuiz.colors.primary)};
		}

		.score {
			min-width: 3em;
			margin-left: 0.5em;
			position: relative;
			top: -2px;

			strong {
				font-weight: bold;
			}
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			font-size: 18px;

			.full-bar[data-show-basis='false'] {
				width: 100%;
			}

			.full-bar {
				height: 15px;
				background: ${popupQuiz.progress.incompleteBackground};
				border-radius: ${progressRadius};
			}

			.filled-bar {
				height: 15px;
				border-radius: ${progressRadius};

				&.filled-100-percent {
					border-radius: ${progressRadius};
				}
			}

			.remaining-bar {
				height: 15px;
				border-radius: ${progressRadius};
				background: transparent;
			}

			strong {
				font-weight: 500;
			}
		`}
	`;
};

export default ScoreBar;
