import React from 'react';

import Modal from '~/components/Modal';
import WebtextButton from '~/components/WebtextButton';

import { paragraphStyles } from './styles';

interface Props {
	open: boolean;
	onClose: () => void;
}

const FirstInterventionModal: React.VFC<Props> = ({ open, onClose }) => {
	return (
		<Modal
			open={open}
			className="speedbump-intervention-1-modal"
			title="Wrong answers are a great way to learn!"
			actions={[
				<WebtextButton key={0} onClick={onClose}>
					Continue
				</WebtextButton>
			]}>
			<p css={paragraphStyles}>
				<strong>Tip:</strong> Did you notice that you get feedback when you answer a question? It’s
				there to help you understand the chapter concepts. Take a minute to read it before clicking
				Try Again.
			</p>
		</Modal>
	);
};

export default FirstInterventionModal;
