import React, { useContext, useEffect, useMemo } from 'react';

import { useTheme } from '@emotion/react';
import first from 'lodash-es/first';
import { shallow } from 'zustand/shallow';

import { EditModeStateContext } from '~/components/WritingTemplate/EditMode/providers/EditModeStateProvider';
import { selectableContainerId } from '~/components/WritingTemplate/Spreadsheet/helpers/constants';
import { Theme } from '~/styles/themes';

import { onHideContext } from '../../helpers';
import {
	callClipboardInScope,
	clearSelectedRange,
	copyRange,
	getCopyingValues,
	pasteRange
} from '../../helpers/interactions';
import { CellsRefs, Formatting, KeyCodes } from '../../helpers/types';
import { useCallbackOnScroll } from '../../hooks/useCallbackOnScroll';
import useOuterClick from '../../hooks/useClickOutside';
import { useSpreadsheetSelector } from '../../store/provider';
import styles from './styles';

interface Props {
	cellsRefs: CellsRefs;
	globalFormatting: Formatting;
	spreadsheetWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

const ContextMenu: React.FC<Props> = ({ cellsRefs, globalFormatting, spreadsheetWrapperRef }) => {
	const {
		dest,
		copyCacheKey,
		contextMenu,
		editableRanges,
		selectedCell,
		selectedIndexes,
		cellValues,
		isTouchScreen
	} = useSpreadsheetSelector(
		(state) => ({
			dest: state.dest,
			copyCacheKey: state.copyCacheKey,
			contextMenu: state.contextMenuProps,
			editableRanges: state.editableRanges,
			selectedCell: state.selectedCell,
			selectedIndexes: state.selectedIndexes,
			cellValues: state.cellValues,
			isTouchScreen: state.isTouchScreen
		}),
		shallow
	);

	const { setContextMenu, setFocused, updateCellsValues, setSelectedCells, setSelectedCell } =
		useSpreadsheetSelector(
			(state) => ({
				setContextMenu: state.updateContextProps,
				setFocused: state.setFocus,
				updateCellsValues: state.updateCellValues,
				setSelectedCells: state.setSelectedCells,
				setSelectedCell: state.setSelectedCell
			}),
			shallow
		);

	const editModeState = useContext(EditModeStateContext);
	const spreadsheetProps = editModeState?.spreadsheetProps;

	const contextMenuParams = useMemo(() => {
		if (!isTouchScreen || !spreadsheetWrapperRef.current || !contextMenu) return contextMenu;

		/**
		 * Static values
		 */
		const contextMenuSize = { width: 180, height: 100 };
		const inputAreaHeight = 50;
		const pageOffset = spreadsheetProps?.mobile ? -10 : 30;

		const { current: spreadsheetElement } = spreadsheetWrapperRef;
		const { height: visualHeight } = window.visualViewport;
		const { clientWidth } = spreadsheetElement;
		const { width: contextMenuWidth, height: contextMenuHeight } = contextMenuSize;
		const { mouseX, mouseY } = contextMenu;

		/**
		 * Free space (margin) that can be used for showing the context menu
		 */
		const gap = 50;
		/**
		 * In case if contextMenu shows above the touch we need to free up some space for the cell content visibility
		 */
		const bottomSpaceBuffer = spreadsheetProps?.mobile ? 10 : 25;

		return {
			mouseX:
				mouseX + contextMenuWidth > clientWidth + gap
					? clientWidth - contextMenuWidth + gap
					: mouseX,
			mouseY:
				mouseY + contextMenuHeight > visualHeight - inputAreaHeight
					? mouseY - contextMenuHeight - pageOffset - bottomSpaceBuffer
					: mouseY - pageOffset
		};
	}, [isTouchScreen, contextMenu, spreadsheetWrapperRef, spreadsheetProps]);

	useCallbackOnScroll(() => contextMenu && restoreFocusSPI(), spreadsheetWrapperRef);

	const handleCopy = () => {
		const values = callClipboardInScope(selectedCell, selectedIndexes, cellValues);
		copyRange(copyCacheKey, values);
	};

	const handleCut = () => {
		const values = callClipboardInScope(selectedCell, selectedIndexes, cellValues);
		copyRange(copyCacheKey, values);
		clearSelectedRange({
			selectedCell,
			selectedIndexes,
			updateCellsValues,
			editableRanges
		});
	};

	const handlePaste = () => {
		pasteRange({
			dest,
			selectedCell,
			selectedIndexes,
			editableRanges,
			cellsRefs,
			updateCellsValues,
			setSelectedCells,
			copyCacheKey,
			formatting: globalFormatting
		}).then((range) => {
			setSelectedCell(first(range), { saveSelectedIndexes: true });
		});
	};

	const handleCopyMobile = () =>
		spreadsheetProps?.handleCopy(
			getCopyingValues(selectedCell, selectedIndexes, cellValues),
			copyCacheKey
		);

	const handleCutMobile = () => {
		spreadsheetProps?.handleCopy(
			getCopyingValues(selectedCell, selectedIndexes, cellValues),
			copyCacheKey
		);
		clearSelectedRange({
			selectedCell,
			selectedIndexes,
			updateCellsValues,
			editableRanges
		});
	};

	const handlePasteMobile = async () => {
		const { storageValue, clipboardValue } = await spreadsheetProps?.handlePaste(copyCacheKey);
		pasteRange({
			dest,
			selectedCell,
			selectedIndexes,
			editableRanges,
			cellsRefs,
			updateCellsValues,
			setSelectedCells,
			copyCacheKey,
			formatting: globalFormatting,
			storageValue,
			clipboardValue
		}).then((range) => {
			setSelectedCell(first(range), { saveSelectedIndexes: true });
		});
	};

	const handleClick =
		(execFn: () => void, execMobileFn: () => void, restoreFocus = true) =>
		() => {
			if (spreadsheetProps?.mobile) {
				execMobileFn();
				restoreFocus && restoreFocusSPI();
				return;
			}

			execFn();
			restoreFocus && restoreFocusSPI();
		};

	const ref = useOuterClick(() => setContextMenu(null), [isTouchScreen && selectableContainerId]);
	const theme = useTheme();

	/**
	 * In case of calling the menu from the keyboard shortcut ot mouse we allow user to navigate through the menu because focus shits to the context menu
	 * In case of calling the menu from the touch event we don't do this because of 1) focus remains on the SPI component and this do nothing 2) setting SPI.focus() on iOS ignores preventScroll in this case and it make scroll to the top of the SPI
	 */
	useEffect(() => {
		if (!contextMenu || !ref.current || contextMenu.accessBy !== 'keyboard') return;

		const contextMenuElement = ref.current;

		const firstContextElement = contextMenuElement.firstElementChild as HTMLElement;
		firstContextElement.focus();
		let selected = firstContextElement;

		const handleKeyDown = (e: KeyboardEvent) => {
			/**
			 * Preventing only for specific keys; Otherwise it may cause unexpected scrolling/focusing
			 */
			if (
				[
					KeyCodes.ArrowUp,
					KeyCodes.ArrowDown,
					KeyCodes.Tab,
					KeyCodes.Escape,
					KeyCodes.Enter,
					KeyCodes.Space
				].includes(e.key as KeyCodes)
			)
				e.preventDefault();

			/**
			 * When user clicks ArrowUp/ArrowDown shift focus to next/previous(first/last) element
			 */
			const selectNextElement = (nextElement: HTMLElement) => {
				selected = nextElement;
				selected.focus();
			};

			if (e.key === KeyCodes.ArrowDown)
				return selectNextElement(
					(selected.nextSibling || ref.current.firstElementChild) as HTMLElement
				);

			if (e.key === KeyCodes.ArrowUp)
				return selectNextElement(
					(selected.previousSibling || ref.current.lastElementChild) as HTMLElement
				);

			const SPIFocusableElement = spreadsheetWrapperRef.current.querySelector('table');
			const hideContext = () => {
				setContextMenu(null);
				SPIFocusableElement.focus({
					preventScroll: true
				});
			};

			/**
			 * In case of pressing Space of Enter, we need to call `click`, bc we called preventDefault for these key codes;
			 * In case of not calling preventDefault, we can remove click(), but in this case we have scrolling to the contextMenu element
			 * The element itself is mounted below the SPI and we basically have scrolling to the bottom of the SPI in this case. Which is not expected
			 */
			if (e.key === KeyCodes.Space || e.key === KeyCodes.Enter) {
				selected.click();
				hideContext();
			}

			/**
			 * These should only hide context menu
			 */
			if (e.key === KeyCodes.Escape || e.key === KeyCodes.Tab) hideContext();
		};

		/**
		 * Mimic from Chrome context menu, when hover on button select it, and if keep using keyboard, selection will continue from the selected element
		 */
		const handleMouseOver = (e: MouseEvent) => {
			const target = e.target as HTMLElement;

			if (selected.getAttribute('data-button') !== target.getAttribute('data-button')) {
				selected = target;
				selected.focus();
			}
		};

		contextMenuElement.addEventListener('mouseover', handleMouseOver);
		contextMenuElement.addEventListener('keydown', handleKeyDown);

		return () => {
			contextMenuElement.removeEventListener('mouseover', handleMouseOver);
			contextMenuElement.removeEventListener('keydown', handleKeyDown);
		};
	}, [contextMenu, ref, setContextMenu, spreadsheetWrapperRef]);

	const handleMouseDown = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		handler: () => void
	) => (event.button === 2 || event.button === 0) && handler();

	const handleMouseUp = () => restoreFocusSPI();

	function restoreFocusSPI() {
		setContextMenu(null);
		setFocused(true);

		if (contextMenu.accessBy !== 'keyboard') return;

		const SPIFocusableElement = spreadsheetWrapperRef.current.querySelector('table');
		SPIFocusableElement.focus({
			preventScroll: true
		});
	}

	if (!contextMenu) return null;

	return (
		<div
			ref={ref}
			onContextMenu={onHideContext}
			css={styles(theme, {
				top: contextMenuParams.mouseY,
				left: contextMenuParams.mouseX
			})}>
			<button
				data-button="copy"
				onMouseDown={(e) => handleMouseDown(e, handleClick(handleCopy, handleCopyMobile, false))}
				onMouseUp={handleMouseUp}
				onClick={handleClick(handleCopy, handleCopyMobile)}>
				<span>Copy</span>
				<span className="help">Ctrl+C</span>
			</button>
			<button
				data-button="cut"
				onMouseDown={(e) => handleMouseDown(e, handleClick(handleCut, handleCutMobile, false))}
				onMouseUp={handleMouseUp}
				onClick={handleClick(handleCut, handleCutMobile)}>
				<span>Cut</span>
				<span className="help">Ctrl+X</span>
			</button>
			<button
				data-button="paste"
				onMouseDown={(e) => handleMouseDown(e, handleClick(handlePaste, handlePasteMobile, false))}
				onMouseUp={handleMouseUp}
				onClick={handleClick(handlePaste, handlePasteMobile)}>
				<span>Paste</span>
				<span className="help">Ctrl+V</span>
			</button>
		</div>
	);
};

export default ContextMenu;
