import React, { forwardRef } from 'react';
import { CgTemplate as WTIcon } from 'react-icons/cg';

import styles from './styles';

interface Props {
	label: string;
	builderFamilyId: string;
}

const WTPhrase = forwardRef<HTMLSpanElement, Props>(({ label, builderFamilyId }, ref) => (
	<div css={styles}>
		<WTIcon aria-hidden />
		<span
			role="heading"
			id={getBuilderHeaderId(builderFamilyId)}
			aria-level={5}
			tabIndex={-1}
			ref={ref}>
			{label ? label.toUpperCase() : 'WRITING TEMPLATE'}
		</span>
	</div>
));

WTPhrase.displayName = 'WTPhrase';

export const getBuilderHeaderId = (builderFamilyId: string): string =>
	`builder-header-${builderFamilyId}`;

export default WTPhrase;
