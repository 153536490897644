import React from 'react';

import { css, withTheme } from '@emotion/react';

import WebtextButton from '~/components/WebtextButton';
import { useIsUniversalVelvet } from '~/hooks';
import { Theme, ThemeName } from '~/styles/themes';

import PopupQuizControls, { RightControls } from './PopupQuizControls';
import PopupQuizView from './PopupQuizView';
import { PopupQuiz as PopupQuizType } from './types';

const styles = (theme) =>
	theme.name === ThemeName.UNIVERSAL_VELVET
		? css`
				.instructions-message {
					margin-bottom: 24px;
				}

				.popup-quiz-divider {
					border: none;
					margin: 0 0 0 -12px;
				}
		  `
		: '';

interface Props {
	theme: Theme;
	popupQuiz: PopupQuizType;
	questionCount: number;
	onStart: (event: React.MouseEvent) => void;
}

const QuizInstructionsView: React.FunctionComponent<Props> = (props) => {
	const { popupQuiz, questionCount, onStart } = props;
	const isUniversalVelvet = useIsUniversalVelvet();

	const message =
		props.popupQuiz.ui.messages.instructions_header_message ||
		`Answer the following ${questionCount} questions to see your results. \n`;

	return (
		<div css={styles}>
			<PopupQuizView>
				{isUniversalVelvet && (
					<div className="instructions-message" dangerouslySetInnerHTML={{ __html: message }} />
				)}
				<div dangerouslySetInnerHTML={{ __html: popupQuiz.instructions }} />
			</PopupQuizView>

			{isUniversalVelvet && <hr aria-hidden className="popup-quiz-divider" />}

			<PopupQuizControls insideModal>
				<RightControls>
					<WebtextButton onClick={onStart}>
						{popupQuiz.ui.buttons.get_started_button || 'Get Started'}
					</WebtextButton>
				</RightControls>
			</PopupQuizControls>
		</div>
	);
};

export default withTheme(QuizInstructionsView);
