import { useContext, useEffect } from 'react';

import { ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, ParagraphNode } from 'lexical';

import { CitationStyle } from '../../../types';
import CitationMetaContext from '../context/CitationMetaContext';
import { $isCitationNode, CitationNode } from '../nodes/CitationNode';

export function CitationTurNumberPlugin(): JSX.Element | null {
	const [editor] = useLexicalComposerContext();
	const { citationStyle, updateCitationsMarkers } = useContext(CitationMetaContext);

	useEffect(() => {
		if (!editor.hasNodes([CitationNode])) {
			throw new Error('CitationsPlugin: CitationNode not registered on editor');
		}

		/**
		 * Register this plugin listener only for tur style
		 */
		if (citationStyle === CitationStyle.Turabian) {
			/**
			 * We're using this listener here because too many commands should be handled
			 * Create, update, copy, paste, undo, redo, etc..
			 * And on the most cases we need to update this numbers
			 */
			return editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					const root = $getRoot();

					/**
					 * Get all citation nodes
					 */
					const citationNodes = root
						.getChildren<ParagraphNode | ListNode>()
						.flatMap((node) => node?.getChildren())
						.filter((node) => $isCitationNode(node));

					/**
					 * Calculate all citation nodes ids
					 */
					const citationsIds = citationNodes
						.map((citation, idx) => {
							if ($isCitationNode(citation)) {
								return {
									[citation.getIdentifier()]: `${idx + 1}`
								};
							}
						})
						.reduce((acc, value) => (value ? { ...acc, ...value } : acc), {});
					/**
					 * Update citation state with nodes ids
					 */
					updateCitationsMarkers(citationsIds);
				});
			});
		}
	}, [editor, citationStyle, updateCitationsMarkers]);

	return null;
}
