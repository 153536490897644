import { Draft, enablePatches, Patch, produceWithPatches } from 'immer';
import defaultsDeep from 'lodash-es/defaultsDeep';

import {
	getDefaultChildrenResponseInstance,
	getDefaultResponseSection
} from '~/components/Outline/helpers/fixtures';
import {
	OutlineHierarchyResponses,
	OutlineInstanceAddress,
	OutlineResponse
} from '~/components/Outline/types';

enablePatches();

interface UpdateResponseArgs<TResponse extends OutlineResponse> {
	hierarchyResponses: OutlineHierarchyResponses;
	address: OutlineInstanceAddress;
	responseUpdateRecipe: (instanceResponse: Draft<TResponse>) => void;
}

/**
 * Dynamically creates the responses tree to get to the passed address.
 * Then runs the update recipe on the response section/instance on the address.
 *
 * Returns the responses tree to be able to easily set it in the store as the top-level object
 */
export const updateResponse = <TResponse extends OutlineResponse>(
	args: UpdateResponseArgs<TResponse>
): [updatedResponses: OutlineHierarchyResponses, inversePatches: Array<Patch>] => {
	const { hierarchyResponses, address, responseUpdateRecipe } = args;

	const [updatedResponses, _patches, inversePatches] = produceWithPatches(
		hierarchyResponses,
		(draft) => {
			let response: Draft<OutlineResponse>;

			address.forEach(({ childrenIndex, toggleIndex, instanceIndex }, depth) => {
				const defaultSection = getDefaultResponseSection();
				const defaultInstance = getDefaultChildrenResponseInstance();

				if (depth === 0) {
					draft[childrenIndex] = defaultsDeep(draft[childrenIndex], defaultSection);
					response = draft[childrenIndex];
					return;
				}

				if ('children' in response && childrenIndex !== undefined) {
					const { children } = response;
					children[childrenIndex] = defaultsDeep(children[childrenIndex], defaultSection);
					response = children[childrenIndex];
					return;
				}

				if ('toggle' in response && toggleIndex !== undefined) {
					const { toggle } = response;
					toggle[toggleIndex] = defaultsDeep(toggle[toggleIndex], defaultSection);
					response = toggle[toggleIndex];
					return;
				}

				if ('instances' in response && instanceIndex !== undefined) {
					const { instances } = response;
					instances[instanceIndex] = defaultsDeep(instances[instanceIndex], defaultInstance);
					response = instances[instanceIndex];
					return;
				}
			});

			responseUpdateRecipe(response as Draft<TResponse>);
		}
	);
	return [updatedResponses, inversePatches];
};
