import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, fonts, Theme, ThemeName } from '~/styles/themes';

import { Kinds } from '../WritingTemplate/types';
import { getFullPageBackgroundStyle } from '../WritingTemplate/utils';
import { basisMap } from './helpers';
import { BuilderElementConfig } from './types';

export default (theme, additionalStyles?: SerializedStyles): SerializedStyles => {
	const { fontSize } = theme.webtextWritingTemplate || {};
	return css`
		background: ${theme.webtextQuestion.backgroundColor};
		padding: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '2em 3.25em' : '2em'};
		line-height: 1.6;
		font-family: ${theme.webtextQuestion.fontFamily} !important;
		font-size: ${theme.webtextQuestion.fontSize};

		strong {
			color: ${theme.name === 'strayer_velvet_2' ? '#1f1f1f' : '#000'} !important;
		}

		& > * {
			margin: 8px 0;
		}

		p {
			margin: 0;
			word-wrap: break-word;
		}

		ol {
			list-style-type: decimal;
			margin-left: 0;
		}

		ul {
			list-style-type: disc;
		}

		li {
			font-size: ${fontSize || '16px'};
			color: #000000;
		}
		mark.redactor-citations {
			background-color: transparent !important;
		}

		mark.redactor-citations-turabian {
			vertical-align: super;
			font-size: 14px !important;
		}

		@media (max-width: ${breakpoints.small}) {
			padding: 23px 9px;
		}

		${getFullPageBackgroundStyle('#f5f5f5')}
		${additionalStyles}
	`;
};

export const editModeAdditionalStyles = css`
	ul {
		padding-left: 24px;
	}
`;

export const objectStyleWrapper = (presentation: string): SerializedStyles => css`
	display: flex;
	flex-wrap: wrap;

	${presentation === 'inline' ? objectStyleInline() : objectStyleBlock()}

	& > div:last-of-type {
		margin-bottom: 0 !important;
	}

	& > div {
		max-width: 100%;
	}
`;

const objectStyleInline = () => css`
	display: block;

	div {
		display: inline;
	}

	& > div {
		margin-bottom: 8px;
	}

	div > span {
		display: inline;
	}

	* {
		box-sizing: border-box;
	}
`;

export const groupValidationMessageContainer = css`
	display: block;
	/**
	 * Rewriting core styles
	 */
	margin: 0 !important;
`;

const objectStyleBlock = () => css`
	flex-direction: column;
`;

export const wrapperStyle = (theme, animate?: boolean): SerializedStyles => css`
	max-width: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '800px' : '700px'};
	.editable {
		${animate && 'transition: min-width 1s, width 1s linear;'}
	}
`;

export const spaceDivider = css`
	height: 16px;
`;

export const elementWrapper = (
	builderElement: BuilderElementConfig,
	isSplitInline?: boolean,
	isNextText?: boolean,
	additionalStyle?: SerializedStyles
): SerializedStyles => {
	if (builderElement.kind === Kinds.Map)
		return css`
			display: none;
		`;

	if (builderElement.kind === Kinds.FillIn) {
		const { options, presentation, default: placeholder } = builderElement;
		const display = presentation || 'block';

		if (options?.editor?.type === 'richtext')
			return css`
				${isNextText ? 'margin-bottom: 25px !important;' : null}
				display: block;
			`;

		const baseWidth = basisMap[placeholder?.length];

		return css`
			display: ${display};
			flex-basis: ${baseWidth >= 80 ? '100%' : baseWidth + '%'};
			${display === 'inline' && 'margin: 0 !important'};
			${display !== 'inline' && isNextText ? 'margin-bottom: 25px !important' : null}
		`;
	}

	if (builderElement.kind === Kinds.Image)
		return css`
			${isNextText ? 'margin-bottom: 25px !important;' : null}
			display: block;
		`;

	if (builderElement.kind === Kinds.Text || builderElement.kind === Kinds.ValueOf) {
		return css`
			display: inline;

			${isSplitInline &&
			css`
				display: inline-block;
				width: 100%;
				flex-basis: 100%;
			`}
		`;
	}

	if (builderElement.kind === Kinds.Select) {
		const { presentation } = builderElement;
		const display = presentation || 'inline';

		return css`
			display: block;
			width: 100%;
			flex-basis: 100%;
			${isNextText && display !== 'inline' && 'margin-bottom: 25px;'}

			${display === 'inline' &&
			css`
				display: inline-block;
				width: 25%;
				flex-basis: 25%;
				margin: 0;

				select {
					margin: 0;
				}
			`}
		`;
	}

	if (builderElement.kind === Kinds.Table) {
		return css`
			margin-top: 24px;
			${isNextText ? 'margin-bottom: 24px;' : ''}
		`;
	}

	if (builderElement.kind === Kinds.Chart) {
		return css`
			margin-top: 24px;
			${isNextText ? 'margin-bottom: 24px;' : ''}
		`;
	}

	return css`
		display: ${(builderElement as BuilderElementConfig & { presentation?: string }).presentation ||
		'block'};
		${additionalStyle}
	`;
};

export const invalidText = (theme) => css`
	min-width: 100%;
	margin-top: 12px;
	text-align: end;
	font-family: ${theme.fonts.app};
	font-weight: ${theme.webtextQuestion.validationStatus.fontWeight};
	font-size: ${theme.webtextQuestion.validationStatus.fontSize};
	line-height: ${theme.webtextQuestion.validationStatus.lineHeight};
	color: ${theme.webtextQuestion.validationStatus.color};
	white-space: normal;
`;

export const groupInvalidMessage = (theme) => css`
	${invalidText(theme)}
	display: block;
	text-align: start;
	padding-bottom: 12px;
	margin-bottom: 0px;
`;

export const bottomWrapper = css`
	margin-top: 24px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	@media (max-width: ${breakpoints.small}) {
		flex-direction: column;
		margin-top: 8px;
	}
`;

export const buttonWrapper = css`
	display: flex;
	flex-flow: row wrap;
	align-content: flex-end;
	justify-content: flex-end;

	button {
		margin-right: 0;
		margin-left: 10px;
		margin-top: 5px;
	}
	@media (max-width: ${breakpoints.small}) {
		width: 100%;
		button {
			margin-top: 0;
			margin-left: 0;
		}
	}
`;

export const centerStyle = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const separatorStyles = css`
	width: 100%;
	height: 25px;
`;
