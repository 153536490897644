import { useCallback, useRef, MutableRefObject } from 'react';

export type FocusFunction = (options?: FocusOptions) => Promise<void>;

export type UseAccessibilityFocusReturnType<T extends HTMLElement = HTMLElement> = readonly [
	(node: T | null) => void,
	(options?: FocusOptions) => Promise<void>,
	MutableRefObject<T | null>
];

export const useAccessibilityFocus = <
	T extends HTMLElement
>(): UseAccessibilityFocusReturnType<T> => {
	// we *must* use useRef here, not useState, because if the node to be focused
	// is rendered in the same update cycle as when focus() is called,
	// the callback ref will call setState but it will not take effect until the *next* update cycle;
	// as a result, when focus() is called, the state variable will still be null
	const nodeRef: UseAccessibilityFocusReturnType<T>[2] = useRef(null);

	const callbackRef = useCallback<UseAccessibilityFocusReturnType<T>[0]>((node) => {
		if (node != null) {
			nodeRef.current = node;
		}
	}, []);

	const focus = useCallback<UseAccessibilityFocusReturnType<T>[1]>(
		(options) =>
			new Promise<void>((resolve) => {
				if (nodeRef.current === null) {
					resolve();
					return;
				}

				// use setTimeout(..., 0) to wait until the next task to focus the element.
				// this is necessary because we often conditionally render an element
				// and then try to focus it in the same code block. in those cases,
				// immediately focusing that conditionally rendered element will fail inconsistently
				// because the element might not exist yet.
				setTimeout(() => {
					nodeRef.current.focus(options);
					// if we're focusing a <textarea> for accessibility reasons,
					// per Devon's advice, we should always move its cursor to the beginning
					if (nodeRef.current.tagName === 'TEXTAREA') {
						(nodeRef.current as unknown as HTMLTextAreaElement).setSelectionRange(0, 0);
					}
					resolve();
				}, 0);
			}),
		[]
	);

	return [callbackRef, focus, nodeRef] as const;
};
