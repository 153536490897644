import React, { useEffect, useRef } from 'react';

import { ClassNames, css } from '@emotion/react';
import {
	useFloating,
	useRole,
	useInteractions,
	FloatingFocusManager,
	FloatingOverlay,
	FloatingPortal,
	useDismiss
} from '@floating-ui/react';

import { getThemeItem, Theme } from '~/styles/themes';

interface Props {
	title?: any;
	className?: string;
	ariaLabel?: string;
	returnFocusRef?: React.MutableRefObject<HTMLButtonElement>;
	open: boolean;
	onClose: () => void;
}

const ModalFloatingContainer: React.FC<Props> = (props) => {
	const { open, onClose, title, className, ariaLabel, returnFocusRef, children } = props;

	const previousFocusedElement = useRef<HTMLElement>(null);

	const { refs, context } = useFloating({
		open: open,
		onOpenChange: (open) => {
			if (!open) {
				onClose();
			}
		}
	});

	useEffect(() => {
		if (document.activeElement.tagName.toLowerCase() !== 'body') {
			previousFocusedElement.current = document.activeElement as HTMLElement;
		}
	}, []);

	const role = useRole(context);
	const dismiss = useDismiss(context, {
		outsidePress: false,
		referencePress: true,
		referencePressEvent: 'click'
	});

	const { getFloatingProps } = useInteractions([role, dismiss]);

	return (
		<ClassNames>
			{({ cx }) => (
				<FloatingPortal>
					<FloatingOverlay css={styles.overlay} className="floating-modal-overlay" lockScroll>
						<FloatingFocusManager
							context={context}
							returnFocus={returnFocusRef || previousFocusedElement}>
							<div
								ref={refs.setFloating}
								aria-label={ariaLabel}
								css={styles.modal}
								className={cx(className, 'floating-modal-content')}
								{...getFloatingProps(getStopEventsPropagationProps())}>
								<div css={styles.title} className="modal-title" role="banner" tabIndex={-1}>
									{title}
								</div>
								<div css={styles.body} className="modal-body">
									{children}
								</div>
							</div>
						</FloatingFocusManager>
					</FloatingOverlay>
				</FloatingPortal>
			)}
		</ClassNames>
	);
};

export default ModalFloatingContainer;

const getStopEventsPropagationProps = <T extends HTMLElement = HTMLElement>(): Partial<
	React.DOMAttributes<T>
> => ({
	onPointerUp: (event: React.PointerEvent<T>) => event.stopPropagation(),
	onPointerDown: (event: React.PointerEvent<T>) => event.stopPropagation(),
	onMouseDown: (event: React.MouseEvent<T>) => event.stopPropagation(),
	onMouseUp: (event: React.MouseEvent<T>) => event.stopPropagation(),
	onClick: (event: React.MouseEvent<T>) => event.stopPropagation()
});

const styles = {
	overlay: css`
		z-index: 10;
		background-color: rgba(0, 0, 0, 0.75);
	`,
	modal: (theme: Theme) => css`
		position: relative;
		display: flex;
		flex-direction: column;
		width: calc(100% - 30px);
		height: calc(100% - 30px);
		margin: 15px;
		padding: 0;
		background-color: rgb(255, 255, 255);
		font-family: ${getThemeItem(theme.fonts.app, theme)};
		border-radius: 0;
		touch-action: none;
	`,
	title: (theme: Theme) => css`
		height: 50px;
		margin: 0;
		background-color: ${theme.colors['light-grayish-white']};
		font-family: inherit;
		border-bottom: 1px solid ${theme.colors['light-brown']};
	`,
	body: css`
		flex: 1;
		justify-content: space-between;
		margin: 0;
		overflow: hidden;
	`
};
