import * as React from 'react';

export function Gear(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={34.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.93305023223036px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={30.5} fill="currentColor" />
			<path
				d="M36 26.2a9.8 9.8 0 1 0 9.8 9.8 9.8 9.8 0 0 0-9.8-9.8Zm0 17.15A7.34 7.34 0 1 1 43.33 36 7.34 7.34 0 0 1 36 43.35Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="m55.53 31.33-3.16-.68a16.28 16.28 0 0 0-1-2.44l1.75-2.72a1.21 1.21 0 0 0-.18-1.49L48 19.05a1.24 1.24 0 0 0-1.52-.16l-2.72 1.75a16.28 16.28 0 0 0-2.44-1l-.69-3.16a1.21 1.21 0 0 0-1.17-1h-6.91a1.22 1.22 0 0 0-1.19 1l-.69 3.16a16.28 16.28 0 0 0-2.44 1l-2.72-1.75a1.24 1.24 0 0 0-1.52.16L19.08 24a1.24 1.24 0 0 0-.17 1.52l1.75 2.72a16.28 16.28 0 0 0-1 2.44l-3.15.68a1.24 1.24 0 0 0-1 1.2v6.94a1.24 1.24 0 0 0 1 1.19l3.15.69a16.28 16.28 0 0 0 1 2.44l-1.75 2.72a1.24 1.24 0 0 0 .17 1.46L24 52.94a1.21 1.21 0 0 0 1.52.17l2.72-1.75a16.28 16.28 0 0 0 2.44 1l.69 3.16a1.22 1.22 0 0 0 1.19 1h6.94a1.23 1.23 0 0 0 1.2-1l.68-3.16a16.84 16.84 0 0 0 2.45-1l2.71 1.75a1.21 1.21 0 0 0 1.52-.17L53 48a1.21 1.21 0 0 0 .17-1.52l-1.75-2.72a16.32 16.32 0 0 0 1-2.44l3.15-.69a1.22 1.22 0 0 0 1-1.19v-6.91a1.22 1.22 0 0 0-1.04-1.2Zm-1.47 7.15-2.89.63a1.25 1.25 0 0 0-.92.86 14.18 14.18 0 0 1-1.35 3.27 1.25 1.25 0 0 0 0 1.27L50.49 47 47 50.51l-2.49-1.6a1.19 1.19 0 0 0-1.26 0A14.86 14.86 0 0 1 40 50.27a1.2 1.2 0 0 0-.87.92l-.63 2.88h-5l-.61-2.92a1.24 1.24 0 0 0-.86-.92 14.71 14.71 0 0 1-3.28-1.35 1.19 1.19 0 0 0-1.26 0L25 50.47 21.49 47l1.6-2.48a1.21 1.21 0 0 0 0-1.27 14.83 14.83 0 0 1-1.35-3.27 1.25 1.25 0 0 0-.92-.87L18 38.44v-4.93l2.89-.62a1.25 1.25 0 0 0 .92-.87 14.83 14.83 0 0 1 1.35-3.27 1.21 1.21 0 0 0 0-1.26L21.49 25 25 21.49l2.49 1.6a1.24 1.24 0 0 0 1.26 0A14.28 14.28 0 0 1 32 21.73a1.25 1.25 0 0 0 .86-.89l.66-2.84h5l.62 2.88a1.25 1.25 0 0 0 .87.93 14.36 14.36 0 0 1 3.27 1.35 1.19 1.19 0 0 0 1.26 0L47 21.53 50.49 25l-1.6 2.49a1.24 1.24 0 0 0 0 1.26A14.4 14.4 0 0 1 50.25 32a1.22 1.22 0 0 0 .91.87l2.89.62Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
