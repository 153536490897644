import type { Intervention } from './constants';
import type { FamilyId } from '~/types/WebtextManifest';

const MCQP_SPEEDBUMP_KEY_SCOPE = `sm.assignments.mcqp.speedbump`;

/** `course:###:intervention:NUM:presentations` */
export const courseLimitKey = ({
	courseId,
	intervention
}: {
	courseId: number;
	intervention: Intervention;
}): string =>
	`${MCQP_SPEEDBUMP_KEY_SCOPE}.course:${courseId}:intervention:${intervention}:presentations`;

/** `course:###:chapter:FAMILY_ID:intervention:NUM:presentations` */
export const chapterLimitKey = ({
	courseId,
	chapterFamilyId,
	intervention
}: {
	courseId: number;
	chapterFamilyId: FamilyId;
	intervention: Intervention;
}): string =>
	`${MCQP_SPEEDBUMP_KEY_SCOPE}.course:${courseId}:chapter:${chapterFamilyId}:intervention:${intervention}:presentations`;

/** `course:###:day:ISO8601_DATE:intervention:NUM:presentations` */
export const dayLimitKey = ({
	courseId,
	intervention
}: {
	courseId: number;
	intervention: Intervention;
}): string => {
	const iso8601Date = new Date().toISOString().slice(0, 10); // YYYY-MM-DD is 10 characters long
	return `${MCQP_SPEEDBUMP_KEY_SCOPE}.course:${courseId}:day:${iso8601Date}:intervention:${intervention}:presentations`;
};
