import * as React from 'react';

export function Texas(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<path
				d="M36 71a35 35 0 1 1 35-35 35 35 0 0 1-35 35Zm0-67.15A32.15 32.15 0 1 0 68.15 36 32.18 32.18 0 0 0 36 3.85Z"
				fill="currentColor"
			/>
			<circle cx={36} cy={36} r={29.68} fill="currentColor" />
			<path
				d="M55.87 33.88v-7.52a.88.88 0 0 0-.88-.89H43.21l-.06-1.54a.88.88 0 0 0-.89-.88h-4.73v-8a.88.88 0 0 0-.88-.89h-12.2a.89.89 0 0 0-.89.89v17.23H13.34a.88.88 0 0 0-.87.9 1 1 0 0 0 .18.52L17 39.22l.39 3.45a.86.86 0 0 0 .29.57l4.21 3.7a.88.88 0 0 0 1.25-.08l.08-.1L25 44.09l3.41.14 7.35 8.38v3.79a.9.9 0 0 0 .52.81l6.39 2.94a.89.89 0 0 0 1.18-.43 1 1 0 0 0 .08-.38V52l2.42-3.15 2.67-.14a.9.9 0 0 0 .61-.29L58 39.23a.88.88 0 0 0 .13-1Zm-20.11 16-6.27-7.1a.87.87 0 0 0-.63-.31l-4.29-.16a.88.88 0 0 0-.74.4L22.37 45l-3.18-2.8-.38-3.36a.83.83 0 0 0-.19-.45l-3.44-4.33h9.27a.88.88 0 0 0 .89-.88V16.06h10.42Zm1.77-25h3.88l.06 1.51a.89.89 0 0 0 .89.89H54.1v6H37.53Zm11.07 22-2.75.15a.91.91 0 0 0-.66.35l-2.85 3.7a.91.91 0 0 0-.19.54V58l-4.62-2.13V35H54.4l1.82 3.55Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M43.07 60.41a1 1 0 0 1-.45-.1l-6.39-2.94a1.1 1.1 0 0 1-.62-1v-3.7l-7.25-8.27-3.23-.12-1.72 2.58-.1.12a1 1 0 0 1-.73.36 1 1 0 0 1-.77-.26l-4.21-3.7a1.07 1.07 0 0 1-.35-.69l-.39-3.4-4.35-5.48a1.08 1.08 0 0 1 .08-1.38 1 1 0 0 1 .75-.33h10v-17a1.07 1.07 0 0 1 1.11-1.1h12.2a1.07 1.07 0 0 1 1.06 1.07v7.81h4.56a1.07 1.07 0 0 1 1.06 1.06l.06 1.36H55a1.07 1.07 0 0 1 1.06 1.07v7.47l2.23 4.31a1.08 1.08 0 0 1-.16 1.21l-8.33 9.17a1.06 1.06 0 0 1-.73.35l-2.6.12-2.33 3v7.3a1 1 0 0 1-.1.45 1.07 1.07 0 0 1-.97.66Zm-18.13-16.5 3.59.14v.06L36 52.54v3.86a.7.7 0 0 0 .41.64l6.39 3a.71.71 0 0 0 .95-.35.73.73 0 0 0 .06-.3v-7.47l2.51-3.26 2.68-.15a.73.73 0 0 0 .49-.23l8.32-9.17a.72.72 0 0 0 .11-.8l-2.22-4.43v-7.52a.71.71 0 0 0-.71-.71H43v-1.71a.71.71 0 0 0-.7-.72h-4.94v-8.16a.71.71 0 0 0-.71-.71h-12.2a.71.71 0 0 0-.71.71v17.4h-10.4a.71.71 0 0 0-.49.21.71.71 0 0 0-.06.92l4.42 5.56.39 3.5a.74.74 0 0 0 .24.46l4.21 3.7a.71.71 0 0 0 1-.07l.06-.08Zm17.39 14.32-5-2.3V34.81h17.18l1.93 3.75-7.75 8.56-2.83.15a.67.67 0 0 0-.52.28l-2.86 3.7a.68.68 0 0 0-.15.43Zm-4.62-2.52 4.26 2v-6a1.11 1.11 0 0 1 .22-.65l2.86-3.7a1 1 0 0 1 .79-.41l2.68-.15L56 38.51l-1.71-3.35H37.71Zm-1.77-5.33-6.58-7.46a.75.75 0 0 0-.51-.24l-4.28-.17a.71.71 0 0 0-.59.33l-1.57 2.42-3.39-3-.39-3.43a.67.67 0 0 0-.14-.36l-3.68-4.61h9.64a.71.71 0 0 0 .71-.71V15.88h10.78Zm-11.37-8.22 4.3.17a1 1 0 0 1 .75.36l6 6.75v-33.2H25.51v16.93a1.06 1.06 0 0 1-1.06 1.06h-8.9l3.21 4a1 1 0 0 1 .23.54l.37 3.3 3 2.61 1.35-2.07a1 1 0 0 1 .86-.45Zm29.71-8.73H37.36v-8.72h4.22l.07 1.68a.71.71 0 0 0 .71.72h11.92Zm-16.57-.35h16.21v-5.61H42.36a1.06 1.06 0 0 1-1.06-1.07l-.06-1.33h-3.53Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="m30.45 31.91.79 2.44a.11.11 0 0 0 .1.07h2.56a.09.09 0 0 1 .06.17l-2.08 1.51a.09.09 0 0 0 0 .11l.79 2.44a.1.1 0 0 1-.15.11l-2.08-1.51a.11.11 0 0 0-.11 0l-2.08 1.51c-.07.06-.17 0-.14-.11l.79-2.44a.1.1 0 0 0 0-.11l-2.07-1.51a.09.09 0 0 1 0-.17h2.57a.1.1 0 0 0 .09-.07l.8-2.44a.09.09 0 0 1 .16 0Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
