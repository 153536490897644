import React, { Children, cloneElement, isValidElement } from 'react';
import { RiCheckboxMultipleFill } from 'react-icons/ri';

import { css } from '@emotion/react';

import { QuestionType, WebtextQuestion } from '~/components/shared/Question';
import { DeckCollapseProvider } from '~/components/shared/QuestionDeck/DeckCollapseStateProvider';
import { useIsUniversalVelvet } from '~/hooks';
import { breakpoints, Theme, ThemeName } from '~/styles/themes';
import { getAttemptsRemaining } from '~/utils/policy';

import UniversalVelvetLeftBorder from '../UniversalVelvetLeftBorder';

import type {
	DeckedMCQuestionPoolInjectedProps,
	InstructorViewElementProps,
	StudentViewElementProps,
	DisplayedQuestionElementProps
} from './types';
import type { FamilyId } from '~/types/WebtextManifest';

export type StudentViewQuestionPoolPropsType = Array<
	Omit<StudentViewElementProps, 'onSave' | 'onReset'>
>;

export interface StudentViewProps {
	showInstructorView: false;
	questionPoolsProps: StudentViewQuestionPoolPropsType;
}

export interface InstructorViewProps {
	showInstructorView: true;
	questionPoolsProps: InstructorViewElementProps[];
}

type Props = (StudentViewProps | InstructorViewProps) &
	DisplayedQuestionElementProps & {
		deckFamilyId: FamilyId;
	};

const MCQuestionPoolQuestionDeck: React.FC<Props> = (props) => {
	const { deckFamilyId, children } = props;

	const isUV = useIsUniversalVelvet();
	const initialCollapsedMap = getInitialCollapsedMap(props);
	const childrenArray = Children.toArray(children);
	const deckHeadingId = `mcqp-question-deck-${deckFamilyId}`;

	return (
		<WebtextQuestion css={styles} data-in-question-deck="true" data-testid="sqr-question-deck">
			<UniversalVelvetLeftBorder>
				<DeckCollapseProvider
					deckFamilyId={deckFamilyId}
					deckStoreName="mcqp-deck-collapse-store"
					initialCollapsedMap={initialCollapsedMap}>
					<QuestionType id={deckHeadingId}>
						{isUV && <RiCheckboxMultipleFill className="question-deck-icon" aria-hidden="true" />}
						<span>{childrenArray.length} Multiple-Choice Questions</span>
					</QuestionType>
					<div className="questions" role="region" aria-labelledby={deckHeadingId}>
						{Children.map(
							childrenArray,
							(child, i) =>
								isValidElement(child) &&
								cloneElement<DeckedMCQuestionPoolInjectedProps>(child, { deckIndex: i })
						)}
					</div>
				</DeckCollapseProvider>
			</UniversalVelvetLeftBorder>
		</WebtextQuestion>
	);
};

export default MCQuestionPoolQuestionDeck;

function getInitialCollapsedMap(props: Props): boolean[] {
	const collapsedIndexes = Array(props.questionPoolsProps.length).fill(true);

	if (props.showInstructorView === true) {
		// for instructors, start with the first question expanded and the rest collapsed
		collapsedIndexes[0] = false;
		return collapsedIndexes;
	}

	// for students, the first question that can still earn points is expanded:
	// - the first unanswered question, or
	// - the first question which is incorrect *and* still has resets remaining
	// and the rest are collapsed
	const { questionPoolsProps } = props;

	if (questionPoolsProps.length === 0) {
		return collapsedIndexes;
	}

	for (let i = 0; i < questionPoolsProps.length; i++) {
		const { attemptsAllowed, resetCount, answer } = questionPoolsProps[i];
		const attemptsRemaining = getAttemptsRemaining({
			attemptsAllowed,
			resetCount,
			isAnswered: answer != null
		});
		if (answer == null || (!answer.correct && attemptsRemaining > 0)) {
			collapsedIndexes[i] = false;
			break;
		}
	}
	return collapsedIndexes;
}

const styles = (theme: Theme) => css`
	// WebtextQuestion inner div
	.webtext-question-universal-velvet > div {
		@media (max-width: ${breakpoints.small}) {
			padding-right: 0.25rem;
		}
	}

	[role='heading'][aria-level='5'] {
		display: flex;
		align-items: flex-start;
		column-gap: 0.75rem;
		padding-bottom: 16px;

		@media (max-width: ${breakpoints.small}) {
			flex-direction: column;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			padding: 0;
		`}
	}

	.question-deck-icon {
		width: 25px;
		height: 25px;
		margin-right: 0;
	}

	.questions {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;

		@media (max-width: ${breakpoints.small}) {
			margin-top: 1rem;
		}
	}
`;
