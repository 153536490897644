import * as React from 'react';

export function MapMarker(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="M45.94 24.91a11.62 11.62 0 0 0-9.65-5.76h-.58a11.65 11.65 0 0 0-9.79 17.28l8.35 15.27a2.2 2.2 0 0 0 1.73 1 2 2 0 0 0 1.73-1l8.35-15.12a11.64 11.64 0 0 0-.14-11.67ZM36 34.42a4.76 4.76 0 1 1 4.75-4.76A4.8 4.8 0 0 1 36 34.42Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
