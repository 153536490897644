import React, { ReactElement, useCallback, useContext, useMemo } from 'react';

import { isEqual } from 'lodash-es';

import Outline from '~/components/Outline';
import { OutlineConfig, OutlineResponsesState, OutlineVariant } from '~/components/Outline/types';
import { numberToOrdinalWord } from '~/utils/formatting';

import Charts from '../../Charts';
import { ChartState } from '../../Charts/types';
import DocumentsLoader from '../../DocumentsLoader';
import { IRestrictions } from '../../DocumentsLoader/DocumentsLoader';
import FillIn from '../../FillIn';
import { RTESchemaOptions, SchemaReferences, ValidationOptions } from '../../FillIn/types';
import { Citations } from '../../FillIn/types/ICitations';
import Selector from '../../Selector';
import Spreadsheet from '../../Spreadsheet';
import { Sheet } from '../../Spreadsheet/helpers/types';
import Table from '../../Table/Table';
import Text from '../../Text';
import { TemplateValidationContext } from '../../WritingTemplate/providers/TemplateValidationProvider';
import { dividerStyles, gapStyle } from '../../WritingTemplate/styles';
import { Kinds, TextOptions, ValidationActionType } from '../../WritingTemplate/types';
import { getBuilderHeaderId } from '../../WTPhrase/WTPhrase';
import { getChartName, getFromConfig, getNameSource } from '../helpers';
import { isCitationEnable } from '../helpers/citationService';
import { EditModeStateContext } from '../providers/EditModeStateProvider';
import { groupInvalidMessage, groupValidationMessageContainer } from '../styles';
import { BuilderElementConfig, TextElementConfig, ChartConfig, restrictedInput } from '../types';
import BuilderElementsContainer from './BuilderElementsContainer';

interface Props {
	builderElementConfig: BuilderElementConfig;
	inlineSelectorIndex?: number;
	priorElement: BuilderElementConfig;
	parentPresentation: string;
	isTable: boolean;
	listDepth?: number;
	multipleInlineSelectors: boolean;
	onParseOldOutline: (component: unknown, listDepth?: number) => ReactElement;
	resetScaleImageFailure?: () => void;
}

const BuilderElement: React.FC<Props> = (props) => {
	const {
		builderElementConfig,
		inlineSelectorIndex,
		priorElement,
		parentPresentation,
		isTable,
		listDepth,
		multipleInlineSelectors,
		onParseOldOutline: renderOldOutlineElement,
		resetScaleImageFailure
	} = props;

	const { validationState, dispatchValidation } = useContext(TemplateValidationContext);
	const {
		updateErrorStatus,
		setReferenceSelection,
		combinedInputs,
		updateTransferSelection,
		referenceSelection,
		transferSelection,
		userInputs,
		tabUpdate,
		inputValues,
		fromConfig,
		handleChange,
		schema,
		toc,
		getAwsParams,
		getImageUrl,
		labelMap,
		imageUploadUrl,
		updateMapDependentValues,
		setUserUpdate,
		builderFamilyId,
		isPromptsReadOnly
	} = useContext(EditModeStateContext);

	const schemaReferences = useMemo<SchemaReferences>(
		() => ({ toc, imports: schema?.imports }),
		[toc, schema?.imports]
	);

	const handleUserUpdate = () => setUserUpdate(true);

	const showHeadError = useCallback(
		(forDestinations: string[]) => {
			const { validations } = validationState;

			const validationMessage = Object.entries(validations)
				.map(([dest, validation]) =>
					forDestinations.includes(dest) && validation.type === 'received' && validation.message
						? validation.message
						: null
				)
				.filter(Boolean)
				.join(' ');
			if (!validationMessage) return null;

			return (
				<div tabIndex={0} css={groupValidationMessageContainer}>
					<span
						key={forDestinations.join(' ')}
						id={`grouped-validation-message-${forDestinations}`}
						className={forDestinations.join(' ')}
						css={groupInvalidMessage}>
						{validationMessage}
					</span>
				</div>
			);
		},

		[validationState]
	);

	function getBuilderElement(
		element: BuilderElementConfig,
		priorElement: BuilderElementConfig,
		inlineSelectorIndex: number,
		isTable: boolean
	) {
		switch (element.kind) {
			case Kinds.Text: {
				const { value, options } = element;
				return renderTextElement({ value, textOptions: options as TextOptions[] });
			}

			case Kinds.ValueOf: {
				const { source, options } = element;
				return renderValueOf({ dest: source, textOptions: options as TextOptions[] });
			}

			case Kinds.HorizontalRule: {
				const { units } = element;
				return renderHorizontalRule(units);
			}

			case Kinds.Gap: {
				const { units } = element;
				return renderGap(units);
			}

			case Kinds.FillIn: {
				const { dest, options, default: placeholder, presentation, validation } = element;

				return renderFillIn({
					dest,
					options: options as RTESchemaOptions,
					editorValidation: validation,
					placeholder,
					presentation: presentation || parentPresentation,
					isTable,
					inlineBox: parentPresentation === 'inline'
				});
			}
			case Kinds.Select: {
				const { choiceSource, choices, dest, source, presentation } = element;
				const priorElementKind = priorElement?.kind;
				let ariaLabel;
				if (presentation === 'block' && priorElementKind === Kinds.Text) {
					ariaLabel = (priorElement as TextElementConfig).value;
				}
				if ((presentation === 'inline' || isTable) && multipleInlineSelectors) {
					ariaLabel = `${numberToOrdinalWord(inlineSelectorIndex + 1)} selection`;
				}
				return renderSelector({
					choices,
					choiceSource,
					dest,
					source,
					presentation,
					parentPresentation,
					ariaLabel
				});
			}
			case Kinds.Image: {
				const { dest, restrictions, fileRestrictionError } = element;
				return renderDocumentsLoader({ dest, restrictions, fileRestrictionError });
			}

			case Kinds.Group: {
				const { presentation, forDestinations } = element;
				return renderGroupedErrorMessage(presentation, forDestinations);
			}
			case Kinds.Spreadsheet: {
				const { dest, sheet } = element;
				return renderSpreadsheet(dest, sheet);
			}

			case Kinds.Chart: {
				const { dest, source, name, config } = element;
				return renderChart({ dest, source, name, config });
			}

			case Kinds.Outline: {
				const {
					templates: outlineTemplates,
					hierarchy: outlineHierarchy,
					numbered_items_style: outlineNumberedItemsStyle,
					templates_constraints: outlineTemplatesConstraints,
					validations: outlineValidations,
					dest,
					source
				} = element;

				return renderOutline(dest, source, {
					templates: outlineTemplates,
					hierarchy: outlineHierarchy,
					numberedItemsStyle: outlineNumberedItemsStyle,
					templatesConstraints: outlineTemplatesConstraints,
					validations: outlineValidations
				});
			}

			case Kinds.Table: {
				const { rows, ['column-widths']: columnWidths } = element;
				return renderTable(rows, columnWidths);
			}

			case Kinds.List: {
				const { items, kind } = element;
				return renderOldOutlineElement({ kind, items }, listDepth);
			}
			default:
				return null;
		}
	}

	const getChoicesFromSource = (source: string): string[] => schema.sources[source] ?? [];
	const getFromUserInputs = (dest: string) => userInputs?.[dest];
	const getFromTabUpdate = (dest: string) => tabUpdate?.[dest];
	const getFromSourceInputs = (dest: string) =>
		!!inputValues && !!inputValues[dest] ? inputValues[dest] : getFromConfig(dest, fromConfig);

	function renderTextElement(props: {
		value: string | Citations;
		textOptions: TextOptions[];
		isForceInline?: boolean;
	}) {
		const { value, textOptions, isForceInline } = props;
		const textContent = typeof value === 'string' ? value : value?.text;
		return <Text value={textContent} options={textOptions} alwaysInline={isForceInline} />;
	}

	function renderValueOf(props: { dest: string; textOptions: TextOptions[] }) {
		const { dest, textOptions } = props;
		const isForceInline = dest?.endsWith('-output');

		const value = getFromUserInputs(dest)
			? getFromUserInputs(dest)
			: getFromSourceInputs(dest) || '';

		return renderTextElement({ value, textOptions, isForceInline });
	}

	function renderHorizontalRule(units = 0, rest?) {
		return <hr css={(theme) => dividerStyles(theme, Math.max(0, units))} aria-hidden {...rest} />;
	}

	function renderGap(units = 0) {
		return <div css={() => gapStyle(Math.max(0, units))} />;
	}

	function renderFillIn(props: {
		dest: string;
		options?: RTESchemaOptions;
		editorValidation?: ValidationOptions;
		placeholder?: string;
		presentation?: string;
		isTable?: boolean;
		inlineBox?: boolean;
	}) {
		const {
			dest,
			options,
			editorValidation,
			placeholder,
			presentation = 'block',
			isTable,
			inlineBox
		} = props;

		const onChange = (value) => {
			const text = citationsEnabled ? value.text : value;
			if (text && !restrictedInput.includes(text)) {
				handleChange(dest, value);
			} else {
				const saveValue = citationsEnabled ? { ...value, text: '', citations: [] } : '';
				handleChange(dest, saveValue);
			}
		};

		const citationsEnabled = isCitationEnable(options, toc);
		const value = getFromUserInputs(dest) || '';

		const styleOptions = {
			presentation,
			isTable,
			isInlineBox: inlineBox
		};

		const label = labelMap[dest];
		const editorOptions = {
			validation: editorValidation,
			schemaOptions: options,
			styleOptions,
			readOnly: isPromptsReadOnly,
			label
		};

		return (
			<FillIn
				dest={dest}
				value={value}
				placeholder={placeholder}
				schemaReferences={schemaReferences}
				onChange={onChange}
				onUserUpdate={handleUserUpdate}
				options={editorOptions}
				tabUpdate={tabUpdate}
			/>
		);
	}

	function renderSelector(props: {
		choices: string[];
		choiceSource: string;
		dest: string;
		source: string;
		presentation?: string;
		parentPresentation?: string;
		ariaLabel?: string;
	}) {
		const { choices, choiceSource, dest, source, presentation, parentPresentation, ariaLabel } =
			props;

		let finalChoices = choices;
		if (!choices && choiceSource) {
			finalChoices = getChoicesFromSource(choiceSource);
		}

		const selectorDisplay = presentation === 'inline' ? 'inline-block' : presentation;
		const handleSelectorChange = (choice) => {
			handleChange(dest, choice);
			handleUserUpdate();
			updateMapDependentValues(dest, choice);
		};

		const sourceValue = getFromSourceInputs(source);

		if (!getFromUserInputs(dest) && sourceValue) {
			handleSelectorChange(sourceValue);
		}

		const select = getFromUserInputs(dest);
		return (
			<Selector
				choices={finalChoices}
				readOnly={isPromptsReadOnly}
				presentation={selectorDisplay}
				ariaLabel={ariaLabel}
				select={select}
				onChange={handleSelectorChange}
				parent={parentPresentation}
			/>
		);
	}

	function renderDocumentsLoader(props: {
		dest: string;
		restrictions: IRestrictions;
		fileRestrictionError?: string;
	}) {
		const { dest, restrictions, fileRestrictionError } = props;
		const [sourceBuilderId, documentName] = getFromSourceInputs(dest)?.split('/') ?? [];

		return (
			<DocumentsLoader
				documentName={documentName}
				getDocumentUrl={getImageUrl}
				sourceBuilderId={sourceBuilderId}
				endpoint={imageUploadUrl}
				restrictions={restrictions}
				getAwsParams={getAwsParams}
				fileRestrictionError={fileRestrictionError}
				onLoadPicture={(name) => {
					handleUserUpdate();
					handleChange(dest, name);
					resetScaleImageFailure?.();
				}}
			/>
		);
	}

	function renderGroupedErrorMessage(presentation: string, forDestinations: string[]) {
		if ((presentation === 'inline' || parentPresentation === 'inline') && !isTable) {
			return showHeadError(forDestinations);
		}

		return null;
	}

	function renderSpreadsheet(dest: string, sheet: Sheet) {
		const onChange = (cellValues) => handleChange(dest, { sheet, cellValues });
		const updateSpreadsheetErrors = (isAnyError: boolean) => updateErrorStatus(dest, isAnyError);
		const updateSpreadsheetTransferSelection = (selectedCell: string, selection: string[]) =>
			updateTransferSelection(dest, selectedCell, selection);

		return (
			<Spreadsheet
				sheet={sheet}
				dest={dest}
				inputs={combinedInputs}
				onChange={onChange}
				onUserUpdate={handleUserUpdate}
				updateErrorStatus={updateSpreadsheetErrors}
				updateSelection={updateSpreadsheetTransferSelection}
				readOnly={isPromptsReadOnly}
				chartSelection={referenceSelection[dest] || []}
			/>
		);
	}

	function renderChart(props: { dest: string; source: string; name: string; config: ChartConfig }) {
		const { dest, source, name, config } = props;

		const chartValue = getFromUserInputs(dest);
		const chartSource = config?.useReference
			? getFromUserInputs(source)
			: getFromSourceInputs(source) || getFromUserInputs(source) || {};
		const chartName = getChartName(chartValue, getNameSource(name), getFromUserInputs);

		const handleChartChange = (data: ChartState) => {
			handleChange(dest, data);
			handleUserUpdate();
		};

		const handleChartReset = () => {
			handleChartChange({} as ChartState);
			const headerElement = document.getElementById(getBuilderHeaderId(builderFamilyId));

			headerElement.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest'
			});
			headerElement.focus({ preventScroll: true });

			dispatchValidation({ type: ValidationActionType.Reset });
		};

		const handleReferenceSelection = (selection: string[], source: string) => {
			const selectionDiffers = !isEqual(
				selection?.slice().sort(),
				referenceSelection[source]?.slice().sort()
			);

			if (selectionDiffers) {
				setReferenceSelection((prev) => ({
					...prev,
					[source]: selection
				}));
			}
		};

		return (
			<Charts
				dest={dest}
				source={source}
				config={config}
				value={chartValue}
				onReset={handleChartReset}
				chartName={chartName}
				chartSource={chartSource}
				onChange={handleChartChange}
				selection={transferSelection}
				setReferenceSelection={handleReferenceSelection}
			/>
		);
	}

	function renderOutline(dest: string, source: string, config: OutlineConfig) {
		const outlineValue = getFromUserInputs(dest);
		const tabUpdateOutlineValue = getFromTabUpdate(dest);

		const handleOutlineChange = (responses: OutlineResponsesState) => handleChange(dest, responses);

		return (
			<Outline
				dest={dest}
				source={source}
				config={config}
				value={outlineValue}
				variant={OutlineVariant.EDIT}
				readOnly={isPromptsReadOnly}
				tabUpdateValue={tabUpdateOutlineValue}
				onChange={handleOutlineChange}
				onUserUpdate={handleUserUpdate}
				builderFamilyId={builderFamilyId}
			/>
		);
	}

	const renderTable = (rows: BuilderElementConfig[][], columnWidths: string[]) => {
		const tableSelectors = rows.reduce(
			(acc, row) => [...acc, ...row.filter((row) => row.kind === Kinds.Select)],
			[]
		);
		const rowsUpdated = rows.map((row) =>
			row.map((cell) => ({
				...cell,
				value: (
					<BuilderElementsContainer
						elementsArray={[cell]}
						isTable={true}
						tableSelectors={tableSelectors}
						parentPresentation={parentPresentation}
						listDepth={listDepth}
						onParseOldOutline={renderOldOutlineElement}
					/>
				),
				init: cell
			}))
		);
		return <Table rows={rowsUpdated} dest={''} columnWidths={columnWidths} />;
	};

	return getBuilderElement(builderElementConfig, priorElement, inlineSelectorIndex, isTable);
};

export default BuilderElement;
