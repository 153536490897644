import { applyPatches } from 'immer';
import dropRight from 'lodash-es/dropRight';
import range from 'lodash-es/range';

import {
	getChildrenSectionInstancesNumber,
	getToggleSectionInstancesNumber
} from '~/components/Outline/components/SectionView/helpers';
import {
	formatDecimalToNumberedStyle,
	getAddressDepth,
	getAddressInstanceIndex,
	getHierarchySection,
	getInstanceLabelId,
	getInstanceMenuButtonId,
	getOutlineId,
	getParentSectionAddress,
	getResponse,
	getTemplateInstances,
	getTemplateMaxInstancesNumber,
	instanceToSectionAddress,
	replacePlaceholders
} from '~/components/Outline/helpers';
import {
	getHierarchyItemType,
	isChildrenSection,
	NumberedItemStyle,
	OutlineHierarchyItemType,
	OutlineInstanceAddress,
	OutlineResponse,
	OutlineResponseInstance,
	OutlineResponseSection,
	OutlineSection,
	OutlineSectionInstancesNumber,
	OutlineTemplate,
	OutlineVariant,
	ViolatedFillInConstraint
} from '~/components/Outline/types';
import { isNullOrUndefined } from '~/utils';

import type { OutlineState } from '~/components/Outline/store';

export const selectTemplate = (
	state: OutlineState,
	address: OutlineInstanceAddress
): OutlineTemplate => {
	const { templates } = state;
	const templateKey = selectTemplateKey(state, address);
	return templates[templateKey];
};

export const selectTemplateDisableOutputLabel = (
	state: OutlineState,
	address: OutlineInstanceAddress
): boolean => {
	const { variant } = state;
	const {
		output: { disable_label: disableLabel }
	} = selectTemplate(state, address);
	return variant === OutlineVariant.VIEW && disableLabel;
};

export const selectTemplateLabelNumber = (
	state: OutlineState,
	address: OutlineInstanceAddress
): string | null => {
	const { numbered } = selectTemplate(state, address);
	if (!numbered) return null;

	const instanceNumber = selectInstanceOrdinalNumber(state, address);
	const numberStyle = selectLabelNumberStyle(state, address);
	return isNullOrUndefined(instanceNumber) || isNullOrUndefined(numberStyle)
		? null
		: formatDecimalToNumberedStyle(instanceNumber, numberStyle);
};

export const selectTemplateLabelText = (
	state: OutlineState,
	address: OutlineInstanceAddress
): string | null => {
	const disableOutputLabel = selectTemplateDisableOutputLabel(state, address);
	if (disableOutputLabel) return null;

	const { label } = selectTemplate(state, address);
	const sectionInstanceNumber = getAddressInstanceIndex(address) + 1;
	return replacePlaceholders(label, { number: sectionInstanceNumber });
};

export const selectTemplateKey = (state: OutlineState, address: OutlineInstanceAddress): string => {
	const { template: templateKey } = selectHierarchySection(state, address);
	return templateKey;
};

export const selectVariant = (state: OutlineState): OutlineState['variant'] => state.variant;
export const selectReadonly = (state: OutlineState): OutlineState['readOnly'] => state.readOnly;

export const selectHierarchySection = <TSection extends OutlineSection>(
	state: OutlineState,
	address: OutlineInstanceAddress
): TSection => {
	const sectionAddress = instanceToSectionAddress(address);
	return getHierarchySection(state.hierarchy, sectionAddress);
};

export const selectResponse = <TResponse extends OutlineResponse>(
	state: OutlineState,
	address: OutlineInstanceAddress
): TResponse | undefined => getResponse(state.hierarchyResponses, address);

export const selectResponseValue = (
	state: OutlineState,
	address: OutlineInstanceAddress
): string | undefined => {
	const response = selectResponse<OutlineResponseInstance>(state, address);
	return response?.value;
};

export const selectResponseViolatedConstraint = (
	state: OutlineState,
	address: OutlineInstanceAddress
): ViolatedFillInConstraint | undefined => {
	const response = selectResponse<OutlineResponseInstance>(state, address);
	return response?.violatedConstraint;
};

/**
 * Retrieves the number of instances in the section that needs to be rendered in the Outline
 */
export const selectSectionInstancesCount = (
	state: OutlineState,
	address: OutlineInstanceAddress
): number => {
	const sectionAddress = getParentSectionAddress(address);

	const sectionResponse = selectResponse<OutlineResponseSection>(state, sectionAddress);
	const responseInstancesCount = sectionResponse?.instances?.length;
	if (responseInstancesCount !== undefined) {
		return responseInstancesCount;
	}

	return selectConfigSectionInstancesNumber(state, address).initial;
};

export const selectConfigSectionInstancesNumber = (
	state: OutlineState,
	address: OutlineInstanceAddress
): Required<OutlineSectionInstancesNumber> => {
	const parentSectionAddress = getParentSectionAddress(address);
	const section = selectHierarchySection(state, parentSectionAddress);
	return isChildrenSection(section, parentSectionAddress)
		? getChildrenSectionInstancesNumber(section.instances)
		: getToggleSectionInstancesNumber();
};

export const selectMinSectionInstancesRequired = (
	state: OutlineState,
	address: OutlineInstanceAddress
): number => selectConfigSectionInstancesNumber(state, address).min;

export const selectMaxSectionInstancesAllowed = (
	state: OutlineState,
	address: OutlineInstanceAddress
): number => selectConfigSectionInstancesNumber(state, address).max;

/**
 * Retrieves the ordinal number for the instance on the passed address
 *
 * The ordinal number is based on the number of the preceding instances within the same parent + current instance index
 *
 * Preceding instances can be gathered from the different preceding sections aka templates
 * to make the different instance look like it's the part of the same list
 *
 * ❗But when the preceding section is not numbered, we start the numbering from 1 again ❗
 *
 * @example - https://ibb.co/6yDyZVL
 */
export const selectInstanceOrdinalNumber = (
	state: OutlineState,
	address: OutlineInstanceAddress
): number | undefined => {
	// Take 2 last address points: `sectionPoint` & `instancePoint`
	const [{ childrenIndex }, { instanceIndex }] = address.slice(-2);

	if (isNullOrUndefined(childrenIndex)) return; // Toggle instances don't have the number

	const parentInstanceAddress = dropRight(address, 2); // Address of the parent instance that creates the "shared list"

	// The preceding instances count starts from the section after the non-numbered one
	// When no such section is found, we start from the first one
	const nonNumberedSectionIndex = range(childrenIndex).findLastIndex((sectionIndex) => {
		const previousSectionAddress = parentInstanceAddress.concat({ childrenIndex: sectionIndex });
		const previousSectionTemplate = selectTemplate(state, previousSectionAddress);
		return !previousSectionTemplate.numbered;
	});

	const precedingSectionsStartIndex =
		nonNumberedSectionIndex === -1
			? 0 // Start from the first section
			: nonNumberedSectionIndex + 1; // Start from section after the non-numbered one

	// Summarize the number of instances in the preceding sections
	const precedingInstancesNumber = range(precedingSectionsStartIndex, childrenIndex).reduce(
		(instanceNumber, sectionIndex) => {
			const previousSectionAddress = parentInstanceAddress.concat({ childrenIndex: sectionIndex });
			return instanceNumber + selectSectionInstancesCount(state, previousSectionAddress);
		},
		0
	);

	return precedingInstancesNumber + instanceIndex + 1; // +1 needed to map [0..∞) indices range to [1..∞) ordinal one
};

export const selectLabelNumberStyle = (
	state: OutlineState,
	address: OutlineInstanceAddress
): NumberedItemStyle | undefined => {
	const hierarchySectionType = getHierarchyItemType(address);
	if (hierarchySectionType === OutlineHierarchyItemType.Toggle) return; // Toggle instances don't have the number

	const depth = getAddressDepth(address);
	const normalizedDepth = depth - 1; // depth starts at 1

	const { numberedItemsStyle } = state;
	const stylesOptionsNumber = numberedItemsStyle.length;
	const styleIndex = normalizedDepth % stylesOptionsNumber; // Cycle through the styles options
	return numberedItemsStyle[styleIndex];
};

export const selectOutlineId = (state: OutlineState): string =>
	getOutlineId(state.builderFamilyId, state.dest);

export const selectLabelId = (state: OutlineState, address: OutlineInstanceAddress): string =>
	getInstanceLabelId(state.builderFamilyId, state.dest, address);

export const selectMenuButtonId = (state: OutlineState, address: OutlineInstanceAddress): string =>
	getInstanceMenuButtonId(state.builderFamilyId, state.dest, address);

export const selectTemplateInstances = (
	state: OutlineState,
	template: string
): Array<OutlineResponseInstance> => {
	const { hierarchy, hierarchyResponses } = state;
	return getTemplateInstances({ template, hierarchy, hierarchyResponses });
};

export const selectTemplateConstraints = (
	state: OutlineState,
	template: string
): OutlineState['templatesConstraints'][string] => state.templatesConstraints[template];

export const selectMaxToggleInstancesReached = (state: OutlineState, template: string): boolean => {
	const { hierarchy, hierarchyResponses } = state;

	const templateConstraints = selectTemplateConstraints(state, template);
	if (isNullOrUndefined(templateConstraints?.toggle?.max_instances)) return false;

	const templateMaxInstancesNumber = getTemplateMaxInstancesNumber({
		max: templateConstraints.toggle.max_instances,
		template,
		hierarchy,
		hierarchyResponses
	});
	if (templateMaxInstancesNumber === null) return false;

	const templateInstances = selectTemplateInstances(state, template);
	return templateInstances.length >= templateMaxInstancesNumber;
};

export const selectUserAction = (state: OutlineState): OutlineState['userAction'] =>
	state.userAction;

/**
 * Selects the response from the state that was relevant at the time when the last action happened
 */
export const selectUserActionResponse = <TResponse extends OutlineResponse>(
	state: OutlineState,
	address: OutlineInstanceAddress
): TResponse | undefined => {
	const userAction = selectUserAction(state);
	if (!userAction) return;

	const actionResponses = applyPatches(state.hierarchyResponses, userAction.inversePatches);
	return getResponse(actionResponses, address);
};

export const selectOpenedMenuId = (state: OutlineState): OutlineState['openedMenuId'] =>
	state.openedMenuId;
