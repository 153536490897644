import { useEffect, useMemo } from 'react';

import { shallow } from 'zustand/shallow';

import { usePreviousRender } from '~/hooks';

import { getCellAddress } from '../helpers/address';
import { getCellValue } from '../helpers/compute';
import { templateSourceRegex } from '../helpers/constants';
import { useSpreadsheetSelector } from '../store/provider';

interface DependentCells {
	[sourcePromptItem: string]: Array<{
		cellAddress: string;
		cell: {
			source: string;
		};
	}>;
}

/**
 * When Spreadsheet's `props.inputs` change, we need to react to this change by updating Spreadsheet's `cellValues`
 * state variable. It's not sufficient to write something like
 *   ```js
 *   const [cellValues, setCellValues] = useState<StringDictionary>(inputs[dest]?.cellValues || {});
 *   useEffect(() => {
 *     setCellValues(inputs[dest]?.cellValues || {});
 *   }, [inputs, dest]);
 *   ```
 * because `inputs[dest]?.cellValues` will not be updated until the Spreadsheet is saved.
 *
 * So instead, we have to manually call `updateCellValue` (which will, in turn, call `setCellValues`) for cells
 * that depend upon `props.inputs`. If we do this, then the next time the Spreadsheet saves (either via
 * an autosave or a manual submission) then `inputs[dest].cellValues` will be updated with the Spreadsheet's most recent
 * `cellValues` value.
 *
 * If we *don't* do this, then when the Spreadsheet changes to ViewMode upon a final submission, it will source values
 * from `inputs[dest].cellValues`, but those were never updated to reflect the changed `props.inputs`, and that produces
 * stale values which are confusing to the student. See T-62703 for this issue.
 */
export function useUpdateCellValuesOnInputs({ inputs, sheet }): void {
	const previousInputs = usePreviousRender(inputs);

	const { dest, origin, updateCellValue, editableCoordinates } = useSpreadsheetSelector(
		(state) => ({
			dest: state.dest,
			origin: state.origin,
			updateCellValue: state.updateCellValue,
			editableCoordinates: state.editableRanges
		}),
		shallow
	);

	const inputsToDependentCellsMap: DependentCells = useMemo(() => {
		const mapping = {};
		sheet.cells.forEach((row, rowIndex) => {
			row.forEach((cell, columnIndex) => {
				/**
				 * No filters here, because we need to keep the order, otherwise the cell address will be incorrect
				 */
				if (!cell.source) return;
				// There are several types of cell `source`s:
				// 1. `{ source: "some_local_prompt_item" }`,
				// 2. `{ source: "some_imported_builder-some_prompt_item" }`,
				// 3. `{ source: "some_local_prompt_item:A1" }`, where A1 is a cell address
				// 4. `{ source: "some_imported_builder-some_prompt_item:A1" }`
				// 5. `{ source: "{prompt_item_reference} / {other_prompt_item_reference}" }`
				// where a "local" prompt item is one that is in the same builder instance as this spreadsheet,
				// and an "imported" prompt item is one that is in a different builder instance compared to this spreadsheet.
				//
				// cases 1~4 are just `inputs[sourcePromptItem]`, but case 5 requires us to pull out all of the prompt item references
				// (in this example, "prompt_item_reference" and "other_prompt_item_reference")

				const cellAddress = getCellAddress(rowIndex, columnIndex, origin);

				/**
				 * matchAll was used there before
				 * It was undefined in very rare cases that could be traced only to the assumption for the internal initialization
				 * Origin of the idea - {@link https://stackoverflow.com/a/73831177/10963661}
				 */
				const interpolatedPromptItemReferences = (cell.source as string)
					.match(new RegExp(templateSourceRegex, 'g'))
					?.map((value) => value.replace(/\{|\}/g, ''));

				if (interpolatedPromptItemReferences?.length) {
					// handle source type 5
					interpolatedPromptItemReferences.forEach((match) => {
						const sourcePromptItem = match;
						mapping[sourcePromptItem] ||= [];
						mapping[sourcePromptItem].push({ cellAddress, cell });
					});
				} else {
					// handle source types 1-4
					mapping[cell.source] ||= [];
					mapping[cell.source].push({ cellAddress, cell });
				}
			});
		});
		return mapping;
	}, [sheet, origin]);

	useEffect(() => {
		if (!previousInputs) return;

		/**
		 * Out of the templates in `input`, find the ones that
		 * - have changed since the last render, and
		 * - have one or more cells dependent upon their values
		 */
		const sourcePromptItems = Object.keys(inputs).filter(
			(sourcePromptItem) =>
				// we're assuming we can use `===`-based comparisons here even for objects
				inputs[sourcePromptItem] !== previousInputs[sourcePromptItem] &&
				inputsToDependentCellsMap[sourcePromptItem] != null
		);

		sourcePromptItems.forEach((sourcePromptItem) => {
			inputsToDependentCellsMap[sourcePromptItem].forEach(({ cellAddress, cell }) => {
				const updatedCellValue = getCellValue({
					cellConfig: cell,
					address: cellAddress,
					inputs,
					isolated: false,
					editableCoordinates,
					storedValues: inputs[dest]?.cellValues,
					sourcePriority: true
				});

				updateCellValue(cellAddress, updatedCellValue);
			});
		});
	}, [
		inputs,
		dest,
		editableCoordinates,
		inputsToDependentCellsMap,
		previousInputs,
		updateCellValue
	]);
}
