export type CastLabConfig = {
	license: string;
	drmtoday: {
		userId: string;
		sessionId: string;
		merchant: string;
		environment: string;
	};
	sources: Array<{
		src: string;
		url: string; // Version 6 uses url instead of src
		drmProtected: boolean;
	}>;
	subtitles: {
		background: {
			color: string;
			opacity: string;
		};
		sources: Array<{
			src: string;
			url: string; // Version 6 uses url instead of src
			language: string;
			mimeType: string;
			label: string;
		}>;
	};
};

export enum CastLabsPlayerEvents {
	ADS_TIMELINE_CHANGED = 'ads-timeline-changed',
	AD_BREAK_STARTED = 'ad-break-started',
	AD_BREAK_STOPPED = 'ad-break-stopped',
	AD_BUFFERING = 'ad-buffering',
	AD_CLICKED = 'ad-clicked',
	AD_COMPLETED = 'ad-completed',
	AD_FIRST_QUARTILE = 'ad-first-quartile',
	AD_IMPRESSION = 'ad-impression',
	AD_LOADED = 'ad-loaded',
	AD_MIDPOINT = 'ad-midpoint',
	AD_PAUSED = 'ad-paused',
	AD_PROGRESS = 'ad-progress',
	AD_RESUMED = 'ad-resumed',
	AD_SKIPPED = 'ad-skipped',
	AD_STARTED = 'ad-started',
	AD_STOPPED = 'ad-stopped',
	AD_THIRD_QUARTILE = 'ad-third-quartile',
	AUDIO_BUFFER_CHANGED = 'audiobufferchanged',
	AUDIO_TRACK_CHANGED = 'audiotrackchanged',
	AUTOPLAY_NOT_ALLOWED = 'autoplaynotallowed',
	BITRATE_CHANGED = 'bitratechanged',
	BUFFERING_ENDED = 'bufferingended',
	BUFFERING_STARTED = 'bufferingstarted',
	DESTROYED = 'destroyed',
	DESTROYING = 'destroying',
	DOWNLOAD_TRACE = 'downloadtrace',
	DRM_EXPIRATION_UPDATE = 'drmexpirationupdate',
	DRM_RENEWAL_STARTED = 'drmrenewalstarted',
	DRM_SESSION_UPDATE = 'drmsessionupdate',
	ENDED = 'ended',
	ERROR = 'error',
	LOADEDMETADATA = 'loadedmetadata',
	LOAD_START = 'loadstart',
	PLAY = 'play',
	PAUSE = 'pause',
	RELEASED = 'released',
	RELEASING = 'releasing',
	SEEKED = 'seeked',
	SEEKING = 'seeking',
	STATE_CHANGED = 'statechanged',
	TEXT_TRACK_CHANGED = 'texttrackchanged',
	TRACKS_ADDED = 'tracksadded',
	VIDEO_BUFFER_CHANGED = 'videobufferchanged',
	VIDEO_TRACK_CHANGED = 'videotrackchanged'
}
