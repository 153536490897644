import { css } from '@emotion/react';

/**
 * Extracted from Kaltura provided .css file
 */

export const KalturaStyles = () => {
	return css`
		height: 100%;
		width: 100%;
	`;
};
