import React from 'react';

import { css, withTheme } from '@emotion/react';

import { Theme, ThemeName } from '~/styles/themes';

import { PopupQuiz } from './types';

const styles = (theme) => {
	const { popupQuiz } = theme;

	return css`
		font-weight: ${popupQuiz.instructions.fontWeight};

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			margin-top: 4px;
		`}
	`;
};

interface Props {
	theme: Theme;
	popupQuiz: PopupQuiz;
	unlocked: boolean;
	lockedMessage: string;
	resetCount: number;
}

const QuizInstructions: React.FunctionComponent<Props> = (props) => {
	const { theme, popupQuiz, unlocked, lockedMessage, resetCount } = props;
	let instructions = lockedMessage;

	if (unlocked) {
		const introductions = popupQuiz.ui.messages.introductions || [];
		const introduction = introductions[resetCount] || popupQuiz.introduction;

		instructions = introduction != null ? introduction : popupQuiz.instructions;
	}

	return <div css={styles(theme)} dangerouslySetInnerHTML={{ __html: instructions }} />;
};

export default withTheme(QuizInstructions);
