import React, { useMemo } from 'react';

import { ChartState, ChartType } from '../../types';
import PieDataTable from './PieDataTable';
import { container, tableStyles } from './styles';

interface Props {
	chartState: ChartState;
}

const DataTable: React.FC<Props> = ({ chartState }) => {
	const tableBody = useMemo(() => {
		const { type } = chartState;

		switch (type) {
			case ChartType.Pie:
				return <PieDataTable data={chartState.data} />;
			default:
				return <div>Can&apos;t recognize chart type.</div>;
		}
	}, [chartState.type, chartState.data]);

	return (
		<div css={container}>
			<table css={tableStyles}>{tableBody}</table>
		</div>
	);
};

export default DataTable;
