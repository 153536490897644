import { SUM, FV, IRR, PV, NPV, RATE } from '@formulajs/formulajs';
import first from 'lodash-es/first';

import { isNumeric } from '~/utils/parsing';

import { getIsRange } from '..';
import { staticErrorReturns } from '../constants';
import { CellValues, EvaluatedCells, FormulaResult } from '../types';
import { transformRangeIntoArrayOfValues, getUnsuccessfulValue, normalizeElements } from './utils';

export function sum(
	formulaArguments: (number | string)[],
	cellValues: CellValues,
	evaluated: EvaluatedCells,
	isAllowRecursion: boolean
): number | FormulaResult {
	const argumentList = formulaArguments.flatMap((argument) => {
		if (getIsRange(argument as string)) {
			/**
			 * We allow write ranges in this function; source Excel
			 */
			return transformRangeIntoArrayOfValues({
				argument: argument as string,
				cellValues,
				evaluated,
				isAllowRecursion
			});
		}

		/**
		 * Transform any other string arguments to 0
		 */

		return typeof argument === 'number' ? argument : 0;
	});

	if (argumentList.some(getUnsuccessfulValue)) {
		return first(argumentList.filter(getUnsuccessfulValue)) as FormulaResult;
	}

	return SUM(argumentList);
}

export function fv(formulaArguments: (number | string)[]): number | FormulaResult {
	const isAllNumbers = formulaArguments.every(isNumeric);

	if (!isAllNumbers) {
		return staticErrorReturns.valueError;
	}
	const [rate, periods, payment, presentedValue, type] = normalizeElements(formulaArguments);
	const result = FV(rate, periods, payment, presentedValue, type);

	if (typeof result !== 'number') {
		return staticErrorReturns.valueError;
	}

	return result;
}

export function irr(
	formulaArguments: (number | string)[],
	cellValues: CellValues,
	evaluated: EvaluatedCells,
	isAllowRecursion: boolean
): number | FormulaResult {
	const [values, guess] = formulaArguments;

	const createValues = () => {
		if (getIsRange(values as string)) {
			/**
			 * We allow write ranges in this function; source Excel
			 */
			return transformRangeIntoArrayOfValues({
				argument: values as string,
				cellValues,
				evaluated,
				isAllowRecursion
			}) as unknown[];
		}

		return [Number(values)];
	};

	const argumentsList = createValues();

	if (argumentsList.some(getUnsuccessfulValue)) {
		return first(argumentsList.filter(getUnsuccessfulValue)) as FormulaResult;
	}

	const result = IRR(argumentsList, guess);

	if (result?.toString()?.includes('#NUM')) return staticErrorReturns.numberError;

	return result;
}

export function pv(formulaArguments: (number | string)[]): number | FormulaResult {
	const isAllNumbers = formulaArguments.every(isNumeric);

	if (!isAllNumbers) {
		return staticErrorReturns.valueError;
	}
	const [rate, periods, payment, future, type] = normalizeElements(formulaArguments);
	const result = PV(rate, periods, payment, future, type);

	if (typeof result !== 'number') {
		return staticErrorReturns.valueError;
	}

	return result;
}

export function npv(
	formulaArguments: (number | string)[],
	cellValues: CellValues,
	evaluated: EvaluatedCells,
	isAllowRecursion: boolean
): number | FormulaResult {
	const [rate] = formulaArguments;
	const restArguments = formulaArguments.slice(1);
	const argumentsList = restArguments.flatMap((argument) => {
		if (getIsRange(argument as string)) {
			/**
			 * We allow write ranges in this function; source Excel
			 */
			return transformRangeIntoArrayOfValues({
				argument: argument as string,
				cellValues,
				evaluated,
				isAllowRecursion
			});
		}

		return typeof argument === 'number' ? argument : 0;
	});

	const realRate = isNumeric(rate) ? +rate : 0;
	const result = NPV(realRate, argumentsList);

	if (typeof result !== 'number') {
		return staticErrorReturns.valueError;
	}

	return result;
}

export function rate(formulaArguments: (number | string)[]): number | FormulaResult {
	const isAllNumbers = formulaArguments.every(isNumeric);

	if (!isAllNumbers) {
		return staticErrorReturns.valueError;
	}
	const normalizeRateArguments = () => {
		const rateNumberArguments = formulaArguments.map((argument) => +argument);
		/** always returns array.length >= 5 */
		const argsList = normalizeElements(rateNumberArguments);
		if (rateNumberArguments.length < 6) return argsList.concat([0.01]);
		return argsList;
	};

	const rateArgs = normalizeRateArguments();

	const [periods, payment, present, future, type, guess] = rateArgs;

	const result = RATE(periods, payment, present, future, type, guess);

	if (result?.toString()?.includes('#NUM')) return staticErrorReturns.numberError;

	return result;
}
