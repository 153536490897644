import React from 'react';

import { css } from '@emotion/react';

const SvgPinned: React.FC = () => {
	return (
		<svg
			css={css`
				height: 19px;
				width: 19px;
				background-color: 'transparent';
			`}
			aria-hidden>
			<path d="M11.104,0 L10.505,0.599 L10.984,2.519 L5.467,7.237 L1.749,7.357 L0.99,8.116 L4.747,11.874 L0,18.621 L6.747,13.874 L10.505,17.631 L11.264,16.871 L11.384,13.154 L16.102,7.637 L18.021,8.116 L18.621,7.517 L11.104,0 Z" />
		</svg>
	);
};

export default SvgPinned;
