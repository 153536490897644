import { css, SerializedStyles } from '@emotion/react';
import { darken } from 'polished';

import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';

export const addButton = (theme: Theme): SerializedStyles => {
	const {
		outlineTemplate: {
			interactiveItemsBorderRadius,
			addButton: { color, borderColor, backgroundColor, fontWeight }
		}
	} = theme;

	const outlineItemsBorderRadius = getThemeItem(interactiveItemsBorderRadius, theme);

	const addButtonColor = getThemeItem(color, theme);

	const addButtonBorderColor = getThemeItem(borderColor, theme);
	const addButtonActiveBorderColor = darken(0.1, addButtonBorderColor);

	const addButtonBackgroundColor = getThemeItem(backgroundColor, theme);
	const addButtonActiveBackgroundColor = darken(0.1, addButtonBackgroundColor);

	const addButtonFontWeight = fontWeight;
	const addButtonFontSize = '0.9em';

	return css`
		min-width: unset;
		margin-top: 16px;
		padding: 0.55em 10px 0.55em 6px;
		font-size: ${addButtonFontSize};
		color: ${addButtonColor} !important; // !important overrides the UV styles
		font-weight: ${addButtonFontWeight} !important; // !important overrides the UV styles
		background-color: ${addButtonBackgroundColor} !important; // !important overrides the UV styles
		border-color: ${addButtonBorderColor} !important; // !important overrides the Core's styles
		border-radius: ${outlineItemsBorderRadius};
		text-transform: capitalize;

		&:hover {
			background-color: ${addButtonActiveBackgroundColor} !important;
		}

		&:focus {
			background-color: ${addButtonActiveBackgroundColor} !important;
			border-color: ${addButtonActiveBorderColor}; !important; // !important overrides the Core's styles
		}

		@media (max-width: ${breakpoints.small}) {
			display: inline-block;
			width: initial;
			margin-bottom: initial;
			font-size: ${addButtonFontSize};
			font-weight: ${addButtonFontWeight};
		}

		${
			theme.name === ThemeName.UNIVERSAL_VELVET
				? css`
						padding-top: 0.65em; // Compensates for the font height https://github.com/soomo/soomo-libs/pull/1192#issuecomment-1372445875
				  `
				: ''
		}
	`;
};

export const addIcon = (theme: Theme): SerializedStyles => css`
	vertical-align: bottom;
	${theme.name === ThemeName.UNIVERSAL_VELVET
		? css`
				margin-bottom: 1px; // Compensates for the font height https://github.com/soomo/soomo-libs/pull/1192#issuecomment-1372445875
		  `
		: ''}
`;

export const maxReachedMessage = css`
	display: inline-block;
	margin-top: 10px;
`;
