import React, { FC, ReactElement } from 'react';

import DecisionVersionHistoryItem from '~/notebook/components/ActivityHistory/VersionHistoryItem/DecisionVersionHistoryItem';
import ExpandableAnswerVersionHistoryItem from '~/notebook/components/ActivityHistory/VersionHistoryItem/ExpandableAnswerVersionHistoryItem';
import {
	ActivityVersion,
	isAnswerVersion,
	isCommentVersion,
	isDecisionVersion
} from '~/notebook/types';

import AnswerVersionHistoryItem from './AnswerVersionHistoryItem';
import CommentVersionHistoryItem from './CommentVersionHistoryItem';

export interface Props {
	version: ActivityVersion;
	studentId: number;

	// Additional information or advanced version content preview for the answer version
	expandedAnswerContent?: ReactElement;

	// Used by CommentVersionHistoryItem to show whether the comment has been seen
	showSeenCommentStatus?: boolean;
	concealedCommentContent?: ReactElement;

	timeZone?: string;
}

export const VersionHistoryItem: FC<Props> = (props) => {
	const { version, expandedAnswerContent } = props;
	if (isAnswerVersion(version)) {
		return expandedAnswerContent ? (
			<ExpandableAnswerVersionHistoryItem {...props} version={version}>
				{expandedAnswerContent}
			</ExpandableAnswerVersionHistoryItem>
		) : (
			<AnswerVersionHistoryItem {...props} version={version} />
		);
	}
	if (isCommentVersion(version)) {
		return <CommentVersionHistoryItem {...props} version={version} />;
	}
	if (isDecisionVersion(version)) {
		return <DecisionVersionHistoryItem {...props} version={version} />;
	}
	(window.Rollbar || console).error(`Unknown version history item: ${JSON.stringify(version)}`);
	return null;
};

export default VersionHistoryItem;
