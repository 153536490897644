import React from 'react';

import { css } from '@emotion/react';

const SelectorContainer: React.FC = ({ children }) => (
	<div css={extendedSelectorParent}>{children}</div>
);

export default SelectorContainer;

const extendedSelectorParent = css`
	display: inline !important;
	margin: 4px 0;

	& > div:first-child {
		display: inline;
	}

	& > div:last-child {
		display: inline-block;
	}
`;
