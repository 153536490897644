import React, { FC, useEffect, useRef } from 'react';

import { css } from '@emotion/react';
import { extent, max, min, transpose } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleOrdinal, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { area, line } from 'd3-shape';
import { timeMonth, timeYear, timeYears } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import cloneDeep from 'lodash-es/cloneDeep';

import { ChartAxisType } from '~/types';

import ChartDescription from '../ChartDescription';
import {
	getBasis,
	getFormat,
	parseDate,
	sanitizeAndScaleValue,
	sanitizeValue,
	valueOf
} from '../chartHelpers';
import { patternClasses, strokePatterns } from '../PatternsSvg';

import type { Props as LineChartProps } from './LineChart';
import type { ChartElement } from '~/types/WebtextManifest';

const LineChart: FC<LineChartProps> = (props) => {
	const { chart, monochrome } = props;

	const chartData = cloneDeep(chart.data);

	const ref = useRef();

	useEffect(() => {
		const svg = select(ref.current);
		svg.selectAll('*').remove();

		draw();
	}, [chart]);

	// eslint-disable-next-line max-statements
	const draw = () => {
		const seriesLabels = chartData.shift().slice(1);

		let categories: any = transpose(chartData);
		categories = transpose(categories).map((a) => a.map((b: any) => valueOf(b)));

		const margin = {
			top: (+chart.margins[0] || 0) + 33,
			right: (+chart.margins[1] || 0) + 33,
			bottom: (+chart.margins[2] || 0) + 33,
			left: (+chart.margins[3] || 0) + 33
		};

		margin.bottom += chart.x_axis_label?.length > 0 ? 40 : 0;
		margin.left += chart.y_axis_label?.length > 0 ? 40 : 0;

		const viewBoxWidth = 700;
		const viewBoxHeight = 400;

		const width = viewBoxWidth - margin.left - margin.right;
		const height = viewBoxHeight - margin.top - margin.bottom;
		const color: any = scaleOrdinal().range(chart.colors);
		const patternScale = scaleOrdinal().range(patternClasses);
		const strokeScale: any = scaleOrdinal().range(strokePatterns);

		const valuesLowerBound = sanitizeValue(chart.min_bound) || 0;
		const valuesUpperBound = sanitizeValue(chart.max_bound) || 0;

		const basis = getBasis(valuesLowerBound, valuesUpperBound, categories[0][0].type);

		const parseValues = (i) => {
			const parseFunc = chart.x_axis_type === 'linear' ? parseInt : parseDate;

			return chartData.map((row) => ({
				date: parseFunc(row[0]),
				value: sanitizeAndScaleValue(row[i + 1], basis)
			}));
		};

		const data = seriesLabels.map((name, i) => ({
			name: name,
			values: parseValues(i).filter((entry) => !isNaN(entry.value))
		}));

		const longestSeriesIdx = data.reduce(
			(highestIndex, currentDataSet, i) =>
				currentDataSet.values.length > data[highestIndex].values.length ? i : highestIndex,
			0
		);

		const dateRange: any = extent(data[longestSeriesIdx].values, (v: any) => v.date);

		const yDataType = categories[0][1].type;
		const yFormat = (value: number) => {
			if (yDataType === 'percent') {
				/**
				 * The format function used by all the charts expects the percentage
				 * value to be in a decimal format instead of the whole number percentage.
				 */
				return getFormat(yDataType)(value / 100);
			}
			return getFormat(yDataType)(value);
		};

		let min_bound = sanitizeAndScaleValue(chart.min_bound, basis);
		let max_bound = sanitizeAndScaleValue(chart.max_bound, basis);

		min_bound = isNaN(min_bound)
			? min(data, (c: any) => min(c.values, (v: any) => +v.value))
			: min_bound;
		max_bound = isNaN(max_bound)
			? max(data, (c: any) => max(c.values, (v: any) => +v.value))
			: max_bound;

		let x, y, xAxis, yAxis;

		if (chart.x_axis_type === 'linear') {
			x = scaleLinear().domain(dateRange).range([0, width]);
			y = scaleLinear().domain([min_bound, max_bound]).range([height, 0]);

			const chartXTicks = +chart.x_ticks || 1;
			const xTicks = (dateRange[1] - dateRange[0]) / chartXTicks + 1;

			xAxis = axisBottom(x).ticks(xTicks);
			yAxis = axisLeft(y).tickFormat(yFormat);
		}

		if (chart.x_axis_type === 'datetime') {
			const multipleYears = timeYears(dateRange[0], dateRange[1], 2).length > 0;
			const xFormat = timeFormat(chart.date_format || (multipleYears ? '%Y' : '%b'));

			x = scaleTime().domain(dateRange).range([0, width]);
			y = scaleLinear().domain([min_bound, max_bound]).range([height, 0]);

			const chartXTicks = +chart.x_ticks;
			const xTicks = multipleYears ? timeYear.every(chartXTicks) : timeMonth.every(chartXTicks);

			xAxis = axisBottom(x).ticks(xTicks).tickFormat(xFormat);
			yAxis = axisLeft(y).tickFormat(yFormat);
		}

		const valueLine = line()
			.defined((d: any) => !isNaN(d.value))
			.x((d: any) => x(d.date))
			.y((d: any) => y(d.value));

		const lineArea = area()
			.x((d: any) => x(d.date))
			.y0(height)
			.y1((d: any) => y(d.value));

		const svg = select(ref.current)
			.attr('class', 'chart')
			.attr('viewBox', `0 0 ${viewBoxWidth} ${viewBoxHeight}`)
			.attr('preserveAspectRatio', 'xMidYMid meet')
			.append('g')
			.attr('transform', `translate(${margin.left}, ${margin.top})`);

		const lines = svg.selectAll('.lines').data(data).enter().append('g').attr('class', 'lines');

		if (chart.fill_line) {
			lines
				.append('path')
				.attr('class', 'area')
				.attr('d', (d: any) => lineArea(d.values))
				.attr('fill', (d: any) => (monochrome ? `url(#${patternScale(d.name)})` : color(d.name)))
				.attr('fill-opacity', '0.8');
		}

		const linePaths: any = lines
			.append('path')
			.attr('class', 'line')
			.attr('d', (d: any) => valueLine(d.values))
			.attr('fill', 'none');

		if (monochrome) {
			linePaths
				.attr('stroke-dasharray', (d) => strokeScale(d.name).stroke_dasharray)
				.attr('stroke', (d) => strokeScale(d.name).stroke)
				.attr('stroke-width', (d) => strokeScale(d.name).stroke_width);
		} else {
			linePaths
				.attr('stroke', (d: any) => (monochrome ? '#000' : color(d.name)))
				.attr('stroke-width', chart.line_stroke_width);
		}

		const x_labels = svg
			.append('g')
			.attr('class', 'x axis')
			.attr('transform', `translate(0,${height})`)
			.call(xAxis);

		if (chart.axis_type === ChartAxisType.tilted) {
			x_labels
				.selectAll('text')
				.attr('text-anchor', 'end')
				.attr('dx', '-0.4em')
				.attr('dy', '.4em')
				.attr('transform', 'rotate(-45)');
		}

		svg.append('g').attr('class', 'y axis').call(yAxis);

		if (chart.x_axis_label?.length > 0) {
			svg
				.append('text')
				.attr('class', 'x label')
				.attr('text-anchor', 'middle')
				.attr('x', width / 2)
				.attr('y', height + 40)
				.text(chart.x_axis_label);
		}

		if (chart.y_axis_label?.length > 0) {
			svg
				.append('g')
				.attr('transform', 'translate(-40,' + height / 2 + ')')
				.append('text')
				.attr('class', 'y label')
				.attr('text-anchor', 'middle')
				.attr('transform', 'rotate(-90)')
				.text(chart.y_axis_label);
		}
	};

	return (
		<div css={styles} data-line-chart>
			<svg ref={ref} aria-hidden />
			<ChartDescription chart={chart as ChartElement} />
		</div>
	);
};

const styles = css`
	.x,
	.y {
		&.axis {
			font-size: 11px;
		}
	}
`;

export default LineChart;
