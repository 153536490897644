import { Patch } from 'immer';
import last from 'lodash-es/last';
import { XOR } from 'ts-xor';

import { instanceToSectionAddress } from '~/components/Outline/helpers/address';

import type { OutlineActions } from '~/components/Outline/store';

export interface OutlineConfig {
	templates?: OutlineTemplates;
	hierarchy: OutlineHierarchy;
	numberedItemsStyle?: OutlineNumberedItemsStyle;
	templatesConstraints?: OutlineTemplatesConstraints;
	validations?: Record<string, unknown>; // Strong typing is omitted it isn't used on the client
}

/**
 * Reusable snippets that can specify their label, type, visual style, and the output transform
 *
 * @example Attention getter template (https://ibb.co/yg63MYq)
 * "attention_getter": {
 *   "label": "Attention getter:",
 *   "label_type": "block",
 *   "type": "fill_in",
 *   "numbered": true
 * }
 *
 * @example Delivery cue template (https://ibb.co/7W2ss0x)
 * "delivery_cue": {
 *   "label": "Delivery cue:",
 *   "label_type": "inline",
 *   "type": "fill_in",
 *   "transform": "delivery_cue",
 *   "numbered": false
 * }
 */
export interface OutlineTemplates {
	[templateKey: string]: OutlineTemplate;
}

export interface OutlineTemplate {
	label: string;

	/**
	 * Whether the label should be displayed before the input or above it
	 * @default block
	 */
	label_type?: TemplateLabelType;

	/**
	 * Whether display the static text or the input too
	 * @default text
	 */
	type?: TemplateType;

	/**
	 * Whether the number should be displayed before the label
	 * @default true
	 */
	numbered?: boolean;

	/**
	 * Options for the templates' text fields. Applied only when the {@link type} specified as the `fill_in`
	 */
	text_field?: {
		/**
		 * The number of lines in the textarea that always will be displayed. Uses the native `rows` of the `textarea`
		 * @default 1
		 */
		min_rows?: number;

		placeholder?: string;
	};

	/**
	 * Options for the templates' output formatting
	 */
	output?: {
		/**
		 * How the template should be transformed on the output
		 */
		transform?: TemplateTransform;

		/**
		 * Whether the label should be displayed for template instances
		 * in the output mode or generated document
		 */
		disable_label?: boolean;
	};
}

export enum TemplateLabelType {
	Inline = 'inline',
	Block = 'block'
}

export enum TemplateType {
	Text = 'text',
	FillIn = 'fill_in'
}

export enum TemplateTransform {
	DeliveryCue = 'delivery_cue'
}

/**
 * Definition for hierarchical structure of the outline
 * @example Introduction: Children[Attention getter: Toggle[Delivery cue]] (https://ibb.co/s5JVz70)
 * "hierarchy": [
 *   {
 *     "template": "introduction",
 *     "children": [
 *       {
 *         "template": "attention_getter",
 *         "toggle": [{ "template": "delivery_cue" }]
 *       }
 *     ]
 *   }
 * ]
 */
export type OutlineHierarchy = Array<OutlineChildrenSection>;

export interface OutlineChildrenSection {
	template: string;

	/**
	 * Number of instances of the certain template.
	 * If only 1 needed - can be omitted
	 */
	instances?: OutlineSectionInstancesNumber;

	/**
	 * The list of toggleable templates.
	 * They can be created or deleted from the context menu of the parent item.
	 * There can be no more than 1 instance of the template created
	 */
	toggle?: OutlineHierarchyToggle;

	/**
	 * The list of templates.
	 * There can be an arbitrary amount of instances of the templates and the client can add more with the "Add Smth" button
	 */
	children?: OutlineHierarchy;
}

export interface OutlineSectionInstancesNumber {
	/**
	 * Number of automatically created instances for the template
	 * @default 1
	 */
	initial?: number;

	/**
	 * Minimal number of instances per template
	 * @default equal to initial
	 */
	min?: number;

	/**
	 * Maximal number of instances per template
	 * @default equal to initial
	 */
	max?: number;
}

/**
 * An array where the position corresponds to the depth level.
 * Available options:
 * 1. decimal (1 2 3)
 * 2. decimal_leading_zero (01, 02, 03)
 * 3. upper_roman (I II III)
 * 4. lower_roman (i ii iii)
 * 5. upper_alpha (A B C)
 * 6. lower_alpha (a b c)
 *
 * Default:
 * ```
 * "numbered_items_style": [
 *   "upper_roman",
 *   "upper_alpha",
 *   "decimal",
 *   "lower_alpha",
 *   "lower_roman"
 * ]
 * ```
 */
export type OutlineNumberedItemsStyle = Array<NumberedItemStyle>;

export enum NumberedItemStyle {
	Decimal = 'decimal',
	DecimalLeadingZero = 'decimal_leading_zero',
	LowerRoman = 'lower_roman',
	UpperRoman = 'upper_roman',
	LowerAlpha = 'lower_alpha',
	UpperAlpha = 'upper_alpha'
}

export interface OutlineTemplatesConstraints {
	[templateKey: string]: OutlineTemplateConstraints;
}
export interface OutlineTemplateConstraints {
	toggle: ToggleConstraints;
	fill_in: FillInConstraints;
}

export interface ToggleConstraints {
	/**
	 * Per-outline constraints on the number of toggle instances,
	 * e.g. 'No more than 3 Deliver Cues per outline' or
	 * 'No more than 65% of instances can have Deliver Cues'
	 */
	max_instances?: number | string;
}

export interface FillInConstraints {
	/**
	 * Real-time constraint for how many characters can be entered
	 * into the text field of the template
	 */
	max_characters?: number;
}
export type FillInConstraintsKeys = keyof FillInConstraints;

export interface FillInConstraint {
	key: FillInConstraintsKeys;
	value: FillInConstraints[FillInConstraintsKeys];
}

export interface ViolatedFillInConstraint extends FillInConstraint {
	violationMessage: string;
}

export type OutlineHierarchyToggle = Array<OutlineToggleSection>;

export interface OutlineToggleSection {
	template: string;
}

export type OutlineSection = OutlineChildrenSection | OutlineToggleSection;

/**
 * Outline config is included into responses to support document generation
 * and don't pass the whole `Builder` schema into the document generation function
 */
export interface OutlineResponsesState extends OutlineConfig {
	/**
	 * Replicates the hierarchical structure with the embedded responses and the instances
	 * @example
	 * hierarchy_responses: [
	 *   {
	 *     // template: 'introduction',
	 *     instances: [
	 *       {
	 *        // `value` is omitted bc template doesn't have an input
	 *         children: [
	 *           {
	 *             // template: 'attention_getter',
	 *             instances: [
	 *               {
	 *                 value: 'Some value entered by the user',
	 *                 toggle: [
	 *                   {
	 *                     // template: 'delivery_cue',
	 *                     instances: [
	 *                       { value: 'Some delivery cue' }
	 *                     ]
	 *                   },
	 *                 ],
	 *               },
	 *             ],
	 *           },
	 *         ],
	 *       },
	 *     ],
	 *   }
	 * ]
	 *
	 * !!! IMPORTANT !!!
	 * It's possible for the responses array to have "holes" in it.
	 * It can be caused by the fact that instances under a certain "children" don't have any interactive elements.
	 */
	hierarchyResponses: OutlineHierarchyResponses;
}

export type OutlineHierarchyResponses = Array<OutlineChildrenResponseSection>;

export type OutlineResponse = OutlineResponseSection | OutlineResponseInstance;
export type OutlineResponseSection = OutlineChildrenResponseSection | OutlineToggleResponseSection;
export type OutlineResponseInstance =
	| OutlineChildrenResponseInstance
	| OutlineToggleResponseInstance;

export interface OutlineChildrenResponseSection {
	instances?: Array<OutlineChildrenResponseInstance>;
}

export interface OutlineChildrenResponseInstance {
	value?: string;
	violatedConstraint?: ViolatedFillInConstraint;
	toggle?: OutlineToggleResponse;
	children?: OutlineHierarchyResponses;
}

export type OutlineToggleResponse = Array<OutlineToggleResponseSection>;

export interface OutlineToggleResponseSection {
	instances?: Array<OutlineToggleResponseInstance>;
}

export interface OutlineToggleResponseInstance {
	value?: string;
	violatedConstraint?: ViolatedFillInConstraint;
}

export enum OutlineHierarchyItemType {
	Children = 'children',
	Toggle = 'toggle'
}

/**
 * Each template or instance can be addressed using the array with template index and the instance index
 * `childrenIndex` (`c:`) - position of the template in the children array
 * `toggleIndex` (`t:`) - position of the template in the toggle array
 * `instanceIndex` (`i:`) - position of the instance for the template instances array
 *
 * "address" is an array of "address points"
 * Each "address point" can contain exclusively { childrenIndex } | { toggleIndex } | { instanceIndex }
 *
 * @example Schema address navigation
 * [
 * 		{
 * 	    // { c:0 }
 * 			"template": "body",
 * 			"children": [
 * 				{
 * 			    // [{ c:0 }, { c:0 }]
 * 					"template": "main_point",
 * 					"instances": { "max": 2 },
 * 					"children": [
 * 						{
 * 					    // [{ c:0 }, { c:0 }, { c:0 }]
 * 							"template": "support",
 * 							"instances": { "initial": 1, "max": 3 }
 * 						},
 * 						{
 * 					    // [{ c:0 }, { c:0 }, { c: 1 }]
 * 							"template": "transition"
 * 						}
 * 					]
 * 				}
 * 			]
 * 		}
 * ]
 *
 * `transition` template address is [{ c:0 }, { c:0 }, { c: 1 }]
 */
export type OutlineSectionAddress = Array<OutlineSectionAddressPoint>;
export type OutlineSectionAddressPoint = XOR<{ childrenIndex: number }, { toggleIndex: number }>;

/**
 * @example Response object navigation
 * [
 * 	{
 * 	  // { c:0 }
 * 		// template: 'body',
 * 		instances: [
 * 			{
 * 		    // [{ c:0 }, { i:0 }]
 * 				children: [
 * 					{
 * 					  // [{ c:0 }, { i:0 }, { c:0 }]
 * 						// template: 'main_point',
 * 						instances: [
 * 							{
 * 						    // [{ c:0 }, { i:0 }, { c:0 }, { i:0 }]
 * 								children: [
 * 									{
 * 								    // [{ c:0 }, { i:0 }, { c:0 }, { i:0 }, { c:0 }]
 * 										// template: 'support',
 * 										instances: [
 * 											{
 * 										    // [{ c:0 }, { i:0 }, { c:0 }, { i:0 }, { c:0 }, { i:0 }]
 * 												value: 'Some support value',
 * 											},
 * 											{
 * 										     // [{ c:0 }, { i:0 }, { c:0 }, { i:0 }, { c:0 }, { i:1 }]
 * 												value: 'Second support value',
 * 											},
 * 										],
 * 									},
 * 								],
 * 							},
 * 						],
 * 					},
 * 				],
 * 			},
 * 		],
 * 	},
 * ]
 *
 * Second `support` instance address is [{ c:0 }, { i:0 }, { c:0 }, { i:0 }, { c:0 }, { i:1 }]
 */
export type OutlineInstanceAddress = Array<OutlineInstanceAddressPoint>;
export type OutlineInstanceAddressPoint = XOR<
	OutlineSectionAddressPoint,
	{ instanceIndex: number }
>;

export type UserActionType = keyof Pick<
	OutlineActions,
	'addInstance' | 'moveInstanceUp' | 'moveInstanceDown' | 'deleteInstance'
>;

export interface UserAction {
	action: UserActionType;
	targetAddress: OutlineInstanceAddress;
	inversePatches: Array<Patch>;
}

export enum OutlineVariant {
	// Displayed in the `EditMode`, has editable fields
	EDIT = 'edit',
	// Displayed in the `ViewMode`, matches the view in the exported document
	VIEW = 'view',
	// Displayed in the Analytics / My Progress apps for revision
	REVISE = 'revise'
}

export const isChildrenSection = (
	section: OutlineSection,
	address: OutlineInstanceAddress
): section is OutlineChildrenSection => {
	const sectionType = getHierarchyItemType(address);
	return sectionType === OutlineHierarchyItemType.Children;
};

export const isChildrenResponseInstance = (
	instance: OutlineResponseInstance,
	address: OutlineInstanceAddress
): instance is OutlineChildrenResponseInstance => {
	const instanceType = getHierarchyItemType(address);
	return instanceType === OutlineHierarchyItemType.Children;
};

// Top-level responses are considered as sections || Instance can contain sections
export const isSectionsResponses = (
	responses: Array<OutlineResponse>,
	parentAddress: OutlineInstanceAddress
): responses is Array<OutlineResponseSection> =>
	!last(parentAddress) || last(parentAddress).instanceIndex !== undefined;

// Only section can contain instances
export const isInstancesResponses = (
	responses: Array<OutlineResponse>,
	parentAddress: OutlineInstanceAddress
): responses is Array<OutlineResponseInstance> => {
	const lastPoint = last(parentAddress);
	if (!lastPoint) return;

	const { childrenIndex, toggleIndex } = lastPoint;
	return childrenIndex !== undefined || toggleIndex !== undefined;
};

export const getHierarchyItemType = (address: OutlineInstanceAddress): OutlineHierarchyItemType => {
	const sectionAddress = instanceToSectionAddress(address);
	const lastAddressPoint = last(sectionAddress);

	// Top-level items are rendered as the children
	if (!lastAddressPoint) {
		return OutlineHierarchyItemType.Children;
	}
	return lastAddressPoint.childrenIndex !== undefined
		? OutlineHierarchyItemType.Children
		: OutlineHierarchyItemType.Toggle;
};
