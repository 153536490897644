import * as React from 'react';

export function Skills(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 154 154">
			<path
				fill="currentColor"
				d="M0 0v154h154V0H0m60 1-1 1 1-1m35 0-1 1 1-1M53 3l-1 1 1-1m49 0-1 1 1-1m-66 8-1 1 1-1m83 0-1 1 1-1m-86 2-1 1 1-1m89 0-1 1 1-1m-93 3-1 1 1-1m97 0-1 1 1-1M59 117c-4.337 0-9.894.854-13.786-1.457C38.33 111.457 41 97.75 41 91c-13.395-.736-3.765-11.788-1.362-19 1.821-5.468 1.704-11.4 3.038-17 2.28-9.571 8.215-17.792 16.324-23.251 23.51-15.825 56.5-1.68 61.535 26.251 1.504 8.337.081 17.34-3.436 25-1.574 3.429-4.2 6.41-5.397 10-1.814 5.449 1.826 14.935-2.305 19.298-2.6 2.745-7.93 3.546-11.397 4.702-8.37 2.79-16.636 5.869-25 8.667-3.078 1.029-8.503 3.991-11.682 2.212-3.316-1.856-2.317-7.732-2.318-10.879M17 28l-1 1 1-1m121 0-1 1 1-1M14 32l-1 1 1-1m127 0-1 1 1-1M12 35l-1 1 1-1m131 0-1 1 1-1m-35 26c-3.284-.669-6.947-2.036-7.153-6.015-.16-3.129 2.421-5.844-.22-8.652C97.754 43.28 90.067 45.568 86 45c-.128-3.895-2.401-9.61-7.416-5.972-2.43 1.762-2.512 5.13-5.642 6.372-3.67 1.456-7.347-2.307-10.605.937-3.494 3.478.89 7.693-1.303 11.515-2.075 3.62-8.066 3.53-6.697 9.004.833 3.33 4.786 2.915 6.554 5.46 2.807 4.042-2.19 8.712 1.446 12.347 3.324 3.322 6.863-.608 10.604.49 3.982 1.168 3.901 6.925 8.098 7.38 4.676.509 4.658-5.51 8.108-7.157 3.589-1.714 7.193 2.16 10.61-.176 4.32-2.955.18-7.295 1.27-11.141.92-3.25 4.202-3.268 5.946-5.643 1.453-1.98 1.02-5.11 1.027-7.416M4 52l-1 1 1-1m147 0-1 1 1-1m-70 1.63c14.495-.452 15.488 21.74 1 22.716-14.7.99-15.792-22.255-1-22.716M2 59l-1 1 1-1m151 0-1 1 1-1M2 94l-1 1 1-1m151 0-1 1 1-1M4 101l-1 1 1-1m147 0-1 1 1-1M12 118l-1 1 1-1m131 0-1 1 1-1m-129 3-1 1 1-1m127 0-1 1 1-1m-124 4-1 1 1-1m121 0-1 1 1-1M29 137l-1 1 1-1m97 0-1 1 1-1m-93 3-1 1 1-1m89 0-1 1 1-1m-86 2-1 1 1-1m83 0-1 1 1-1m-66 8-1 1 1-1m49 0-1 1 1-1m-42 2-1 1 1-1m35 0-1 1 1-1z"
			/>
		</svg>
	);
}
