import React from 'react';

import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export const ValidationStatus: React.FC = (props) => {
	if (!props.children) {
		return null;
	}

	return (
		<span
			css={styles}
			role="alert"
			aria-live="assertive"
			className="webtext-question-validation-status">
			{props.children}
		</span>
	);
};

const styles = (theme) => {
	return css`
		text-align: right;
		margin-bottom: 1.75em;
		font-weight: ${theme.webtextQuestion.validationStatus.fontWeight};
		font-size: ${theme.webtextQuestion.validationStatus.fontSize};
		line-height: ${theme.webtextQuestion.validationStatus.lineHeight};
		color: ${theme.webtextQuestion.validationStatus.color};

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			@media (max-width: ${breakpoints.small}) {
				margin-bottom: 0;
			}
		`}
	`;
};
