import * as React from 'react';

export function Spreadsheet(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 99 100">
			<g fill="none">
				<path
					d="M96.426 50.001c0 25.988-21.066 47.055-47.055 47.055-25.987 0-47.054-21.067-47.054-47.055 0-25.987 21.067-47.054 47.054-47.054 25.989 0 47.055 21.067 47.055 47.054z"
					stroke="currentColor"
					strokeWidth={4}
				/>
				<path
					d="M90.975 50.001c0 22.978-18.626 41.604-41.604 41.604-22.976 0-41.602-18.626-41.602-41.604 0-22.977 18.626-41.603 41.602-41.603 22.978 0 41.604 18.626 41.604 41.603"
					fill="currentColor"
				/>
				<path
					d="M65.801 70.835h-8.176v-8.503h12.1v4.579a3.928 3.928 0 0 1-3.924 3.924zm-37.935-3.924v-4.579h12.1v8.503H31.79a3.928 3.928 0 0 1-3.924-3.924zm3.924-37.934h34.011a3.927 3.927 0 0 1 3.924 3.923v3.925H27.866V32.9a3.927 3.927 0 0 1 3.924-3.923zM57.625 50.56h12.1v9.157h-12.1V50.56zm-29.759 0h12.1v9.157h-12.1V50.56zm12.1-2.616h-12.1v-8.502h12.1v8.502zm29.759 0h-12.1v-8.502h12.1v8.502zm-14.716 0H42.582v-8.502h12.427v8.502zm0 22.891H42.582v-8.503h12.427v8.503zM42.582 50.56h12.427v9.157H42.582V50.56zm23.219-24.2H31.79a6.547 6.547 0 0 0-6.54 6.54v34.011a6.546 6.546 0 0 0 6.54 6.54h34.011a6.546 6.546 0 0 0 6.54-6.54V32.9a6.547 6.547 0 0 0-6.54-6.54z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
