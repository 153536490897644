import React from 'react';

import { css } from '@emotion/react';

import { QuestionChoices } from '~/components/shared/Question';
import { getThemeItem, Theme, ThemeName } from '~/styles/themes';

import { useInstructorViewPoolNavigation } from '../hooks/useInstructorViewPoolNavigation';
import { choicesStyles } from '../styles';
import InstructorViewPoolNavigation, {
	explanatoryTextStyles
} from './InstructorViewPoolNavigation';
import Rejoinder from './Rejoinder';

import type { InstructorViewElementProps } from '../types';

type Props = Omit<InstructorViewElementProps, 'showInstructorView'> &
	ReturnType<typeof useInstructorViewPoolNavigation>;

/**
 * Renders everything but the question prompt for a multiple-choice question pool in instructor view.
 *
 * This component's visiblity is toggled by expanding and collapsing an MCQP inside of a question deck,
 * hence the name "below the fold" (with the question prompt being "above the fold").
 */
const InstructorViewBelowTheFold: React.VFC<Props> = (props) => {
	const { questionPool, activePoolIndex, onNext, onPrevious, onStudyStackLinkClick } = props;
	const activeQuestion = questionPool.questions[activePoolIndex];
	const activeQuestionCorrectChoice = activeQuestion.choices.find((ch) => ch.is_correct);

	return (
		<>
			<QuestionChoices
				disabled
				choices={activeQuestion.choices}
				onChangeSelectedChoice={() => undefined}
				questionFamilyId={activeQuestion.family_id}
				selectedChoiceFamilyId={activeQuestionCorrectChoice?.family_id}
				css={choicesStyles}
			/>
			<Rejoinder
				rejoinder={activeQuestionCorrectChoice?.rejoinder}
				correct={true}
				correctChoice={null}
				css={rejoinderStyles}
			/>
			<InstructorViewPoolNavigation
				activeIndex={activePoolIndex}
				onNext={onNext}
				onPrevious={onPrevious}
				numQuestions={questionPool.questions.length}
			/>
			{onStudyStackLinkClick && (
				<div css={(theme) => [studyStackLinkStyles(theme), explanatoryTextStyles]}>
					Students can check their understanding of this concept and more with{' '}
					<a href="#" onClick={onStudyStackLinkClick}>
						Study Stack
					</a>
					.
				</div>
			)}
		</>
	);
};

export default InstructorViewBelowTheFold;

const studyStackLinkStyles = (theme: Theme) => css`
	margin-top: 1rem;

	a {
		color: ${getThemeItem(theme.colors.link, theme)};
		text-decoration: underline;
	}
`;

const rejoinderStyles = (theme: Theme) => css`
	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		[data-in-question-deck='true'] & {
			margin-left: -1.5rem;
		}
	`};
`;
