import * as React from 'react';

export function StopSign(): JSX.Element {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 72 72"
			xmlSpace="preserve">
			<path
				style={{
					fill: '#fff',
					stroke: '#000',
					strokeWidth: 0.1479,
					strokeMiterlimit: 10
				}}
				d="M.5 21.3v29.4l20.8 20.8h29.4l20.8-20.8V21.3L50.7.5H21.3L.5 21.3"
				id="polyline14"
			/>
			<path
				fill="#fff"
				d="M18 40.8v-1.2l-.1-.3-.2-.3-.1-.3-.1-.3-.1-.3-.1-.3-.1-.3-.1-.3-.2-.3-.2-.3-.2-.3-.2-.3h-.1l-.2-.3-.2-.3-.2-.3-.4-.1-.3-.3-.3-.3-.3-.2-.2-.2-.3-.2-.3-.2-.4-.2-.4-.4-.4-.3-.4-.2-.4-.2c-1.8-1-2.2-1.7-2.2-2.8 0-1.6 1.2-2.7 2.8-2.7 1.5 0 2.6 1.1 2.7 2.7H18c-.1-3.6-2.6-6-5.8-6-3.4 0-5.9 2.7-5.9 6 0 2.1 1 3.8 2.9 5.1 4.7 3.2 3.5 2.3 3.8 2.8 1.4 1.1 1.9 2.1 1.9 3.4 0 1.9-1.5 3.6-3.3 3.6-2.3 0-3.3-2.2-3.3-4.1H6.1c0 3.9 2.3 7.5 5.9 7.5 3.3 0 6-3.1 6-7z"
				id="path2518"
			/>
			<path
				style={{
					fill: '#c02027'
				}}
				d="M2.3 22 22 2.3h28L69.7 22v28L50 69.7H22L2.3 50z"
				id="polygon16"
			/>
			<path
				fill="#fff"
				d="M11.9 24.7c-3.3.1-5.8 2.9-5.8 6.3 0 2.2 1 3.9 2.9 5.3l4.3 3s1.4 1.4 1.4 2.4c0 1.6-1.2 2.8-2.8 2.8-1.5 0-2.6-1.1-2.7-2.8H6.1c.1 3.6 2.6 6.3 5.8 6.3 3.4 0 5.9-2.8 5.9-6.3 0-2.2-1-3.9-2.9-5.3l-4.3-3c-1-.9-1.3-1.5-1.3-2.5 0-1.6 1.2-2.8 2.8-2.8 1.5 0 2.6 1.1 2.7 2.8H18c-.1-3.6-2.6-6.3-5.8-6.3-.2.1-.3.1-.3.1z"
				id="path18"
			/>
			<path fill="#fff" d="M32.1 27.7v-3H20.3v3h4.3v20.1h3.3V27.7z" id="polygon20" />
			<path
				fill="#fff"
				d="M41.8 23.8c-2.6 0-4.2 1.2-5.4 3.8-1 2.2-1.3 4.5-1.3 8.1 0 3.5.3 5.8 1.3 8.1 1.2 2.8 2.8 3.9 5.5 3.9 2.6 0 4.2-1.2 5.4-3.9 1-2.2 1.3-4.4 1.3-8.1V32.2l-.1-.5-.1-.5-.1-.5-.1-.5-.1-.5-.1-.3-.2-.3-.2-.4-.2-.4-.2-.4C46 25 44.4 24 41.8 24c.1-.2 0-.2 0-.2zm.1 3.2c1.4 0 2.3.9 2.9 3 .5 1.7.7 3.2.7 5.7V39.3l-.1.3-.1.3-.1.3-.1.3-.1.2-.1.6-.1.3c-.6 2-1.5 2.9-2.9 2.9-1.5 0-2.4-.9-3-2.9-.5-1.9-.8-3.4-.8-5.8 0-2.7.1-4.1.8-5.9.6-2 1.5-2.9 3-2.9z"
				id="path22"
			/>
			<path
				fill="#fff"
				d="M53.7 24.7v23.2H57V37.7h3.5c3.8 0 6.1-2.3 6.1-6.5V29.8l-.1-.3-.2-.4-.1-.3-.1-.3-.1-.3v-.3l-.1-.3-.2-.3-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.1-.3-.2-.2-.2-.2-.2-.2-.4-.1-.3-.1H63l-.3-.1-.3-.1-.3-.1-.3-.1-.3-.1H53.7zm3.3 3.1h3.5c1.8 0 3 1.3 3 3.3V32.7l-.1.2-.2.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1h-.1l-.1.1-.1.1-.1.1-.1.1H57v-6.6z"
				id="path26"
			/>
		</svg>
	);
}
