import * as React from 'react';

import { ArrowInCircle } from './ArrowInCircle';
import { ArrowThroughMaze } from './ArrowThroughMaze';
import { BallotBox } from './BallotBox';
import { BrainHead } from './BrainHead';
import { Briefcase } from './Briefcase';
import { BusinessConnections } from './BusinessConnections';
import { BulbIcon, Camera, Global as Global2, News, People } from './calloutHeading';
import { ChatBubbles } from './ChatBubbles';
import { ChatBubblesLarger } from './ChatBubblesLarger';
import { CheckboxInCircle } from './CheckboxInCircle';
import { Checkmark } from './Checkmark';
import { Compass } from './Compass';
import { CulturalChange } from './CulturalChange';
import { DataAndPieChart } from './DataAndPieChart';
import { Document } from './Document';
import { Elections } from './Elections';
import { FemaleIcon } from './FemaleIcon';
import { FrameWithEye } from './FrameWithEye';
import { Gear } from './Gear';
import { GearWithPlayButton } from './GearWithPlayButton';
import { Global } from './Global';
import { Globe } from './Globe';
import { GraduationCap } from './GraduationCap';
import { GrassrootsMobilization } from './GrassrootsMobilization';
import { HandsAndGlobe } from './HandsAndGlobe';
import { HandsWithPlusSign } from './HandsWithPlusSign';
import { HeadWithArrows } from './HeadWithArrows';
import { HeadWithGear } from './HeadWithGear';
import { HeadWithHeartBrain } from './HeadWithHeartBrain';
import { Heart } from './Heart';
import { Heartbeat } from './Heartbeat';
import { Lightbulb } from './Lightbulb';
import { MapMarker } from './MapMarker';
import { Megaphone } from './Megaphone';
import { MobileTip } from './MobileTip';
import { Newspaper } from './Newspaper';
import { PapersMagnifyingGlass } from './PapersMagnifyingGlass';
import { PapersWithEye } from './PapersWithEye';
import { PapersWithPencil } from './PapersWithPencil';
import { PuzzlePieces } from './PuzzlePieces';
import { Pyramid } from './Pyramid';
import { Quill } from './Quill';
import { Quote } from './Quote';
import { ScalesOfJustice } from './ScalesOfJustice';
import { ShakingHands } from './ShakingHands';
import { ShoppingCart } from './ShoppingCart';
import { Skills } from './Skills';
import { SocialConnections } from './SocialConnections';
import { Spreadsheet } from './Spreadsheet';
import { Star } from './Star';
import { StopSign } from './StopSign';
import { Texas } from './Texas';
import { WritingToolbox } from './WritingToolbox';

export const GutterIconKeys = [
	'skills',
	'global',
	'hands-and-globe',
	'brain-head',
	'spreadsheet',
	'chat-bubbles',
	'document',
	'scales-of-justice',
	'quill',
	'business-connections',
	'emotional-intelligence',
	'ethics',
	'self-care',
	'texas',
	'social-connections',
	'frame-with-eye',
	'papers-magnifying-glass',
	'head-with-gear',
	'papers-with-eye',
	'papers-with-pencil',
	'writing-toolbox',
	'map-marker',
	'heart',
	'briefcase',
	'graduation-cap',
	'star',
	'newspaper',
	'shopping-cart',
	'heartbeat',
	'checkbox-in-circle',
	'grassroots-mobilization',
	'elections',
	'cultural-change',
	'mobile-tip',
	'conflict-theory',
	'feminist-theory',
	'functionalism-theory',
	'rational-choice',
	'symbolic-interaction',
	'conflict-theory-38c0c5',
	'feminist-theory-38c0c5',
	'functionalism-theory-38c0c5',
	'rational-choice-38c0c5',
	'symbolic-interaction-38c0c5',
	'agility',
	'communication',
	'initiative',
	'innovation',
	'problem-solving',
	'productivity',
	'relationship-building',
	'results-driven',
	'self-and-social-awareness',
	'technology',
	'stop-sign',
	'webtext-callout-heading-lightbulb',
	'webtext-callout-heading-people',
	'webtext-callout-heading-news',
	'webtext-callout-heading-globe',
	'webtext-callout-heading-camera'
] as const;

export type GutterIconKey = (typeof GutterIconKeys)[number];

export const gutterIconMap: { [key in GutterIconKey]: JSX.Element } = {
	skills: <Skills />,
	global: <Global />,
	'hands-and-globe': <HandsAndGlobe />,
	'brain-head': <BrainHead />,
	spreadsheet: <Spreadsheet />,
	'chat-bubbles': <ChatBubbles />,
	document: <Document />,
	'scales-of-justice': <ScalesOfJustice />,
	quill: <Quill />,
	'business-connections': <BusinessConnections />,
	'emotional-intelligence': <HeadWithHeartBrain />,
	ethics: <Compass />,
	'self-care': <HandsWithPlusSign />,
	texas: <Texas />,
	'social-connections': <SocialConnections />,
	'frame-with-eye': <FrameWithEye />,
	'papers-magnifying-glass': <PapersMagnifyingGlass />,
	'head-with-gear': <HeadWithGear />,
	'papers-with-eye': <PapersWithEye />,
	'papers-with-pencil': <PapersWithPencil />,
	'writing-toolbox': <WritingToolbox />,
	'map-marker': <MapMarker />,
	heart: <Heart />,
	briefcase: <Briefcase />,
	'graduation-cap': <GraduationCap />,
	star: <Star />,
	newspaper: <Newspaper />,
	'shopping-cart': <ShoppingCart />,
	heartbeat: <Heartbeat />,
	'checkbox-in-circle': <CheckboxInCircle />,
	'grassroots-mobilization': <GrassrootsMobilization />,
	elections: <Elections />,
	'cultural-change': <CulturalChange />,
	'mobile-tip': <MobileTip />,
	'conflict-theory': <Pyramid />,
	'feminist-theory': <FemaleIcon />,
	'functionalism-theory': <Gear />,
	'rational-choice': <ScalesOfJustice />,
	'symbolic-interaction': <SocialConnections />,
	'conflict-theory-38c0c5': <Pyramid />,
	'feminist-theory-38c0c5': <FemaleIcon />,
	'functionalism-theory-38c0c5': <Gear />,
	'rational-choice-38c0c5': <ScalesOfJustice />,
	'symbolic-interaction-38c0c5': <SocialConnections />,
	agility: <ArrowThroughMaze />,
	communication: <ChatBubblesLarger />,
	initiative: <ArrowInCircle />,
	innovation: <Lightbulb />,
	'problem-solving': <PuzzlePieces />,
	productivity: <Checkmark />,
	'relationship-building': <ShakingHands />,
	'results-driven': <DataAndPieChart />,
	'self-and-social-awareness': <HeadWithArrows />,
	technology: <GearWithPlayButton />,
	'stop-sign': <StopSign />,
	'webtext-callout-heading-camera': <Camera />,
	'webtext-callout-heading-globe': <Global2 />,
	'webtext-callout-heading-lightbulb': <BulbIcon />,
	'webtext-callout-heading-news': <News />,
	'webtext-callout-heading-people': <People />
};

export {
	BusinessConnections,
	CulturalChange,
	Document,
	Elections,
	GrassrootsMobilization,
	Quill,
	ScalesOfJustice,
	Quote,
	WritingToolbox,
	Gear
};
