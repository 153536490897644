import React, { FC, useEffect, useMemo } from 'react';

import Button from '@material-ui/core/Button';
import { shallow } from 'zustand/shallow';

import { useAriaLiveAnnouncer } from '~/components/AriaLiveAnnouncer';
import { actionToast } from '~/components/Outline/components/ActionToast/styles';
import { getInstanceHasDescendants, removeDynamicParts } from '~/components/Outline/helpers';
import { outlineUndoHumanHotkey } from '~/components/Outline/hooks';
import {
	OutlineState,
	selectResponse,
	selectTemplateLabelText,
	selectUserAction,
	selectUserActionResponse,
	useOutlineSelector
} from '~/components/Outline/store';
import {
	OutlineInstanceAddress,
	OutlineResponseInstance,
	UserActionType
} from '~/components/Outline/types';
import Toast from '~/components/Toast';

const userActionMessages: Record<UserActionType, string> = {
	addInstance: 'added',
	moveInstanceUp: 'moved up',
	moveInstanceDown: 'moved down',
	deleteInstance: 'deleted'
};

const ActionToast: FC = () => {
	const userAction = useOutlineSelector(selectUserAction);
	const { action, targetAddress } = userAction;

	const targetLabelText = useOutlineSelector((state) =>
		selectTemplateLabelText(state, targetAddress)
	);
	const targetResponseInstance = useOutlineSelector((state) =>
		selectActionToastResponse(state, targetAddress)
	);

	const toastMessage = useMemo(() => {
		const staticLabelText = removeDynamicParts(targetLabelText);

		const isSubItemsPresent =
			targetResponseInstance && getInstanceHasDescendants(targetResponseInstance);
		const subItemsSuffix = isSubItemsPresent ? 'and its sub-items' : '';

		const actionMessage = userActionMessages[action];
		return `“${staticLabelText}” ${subItemsSuffix} ${actionMessage}`;
	}, [action, targetLabelText, targetResponseInstance]);

	const { makePoliteAnnouncement } = useAriaLiveAnnouncer();
	useEffect(() => {
		const ariaAnnouncementMessage = `${toastMessage}. To undo press ${outlineUndoHumanHotkey}.`;
		makePoliteAnnouncement(ariaAnnouncementMessage);
	}, [makePoliteAnnouncement, toastMessage]);

	const { undoUserAction, hideUserActionToast } = useOutlineSelector(
		(state) => ({
			undoUserAction: state.undoUserAction,
			hideUserActionToast: state.hideUserActionToast
		}),
		shallow
	);
	useEffect(() => {
		const closeTimeout = setTimeout(() => hideUserActionToast(), 6000);
		return () => clearTimeout(closeTimeout);
	}, [targetAddress, hideUserActionToast]);

	return (
		<Toast
			css={actionToast}
			open
			aria-live="off"
			autoHideDuration={Infinity}
			action={
				// Don't announce the buttons for the screen reader
				<>
					<Button disableRipple onClick={undoUserAction}>
						undo
					</Button>
					<Button disableRipple onClick={hideUserActionToast}>
						close
					</Button>
				</>
			}>
			{toastMessage}
		</Toast>
	);
};

const ActionToastContainer: FC = () => {
	const showUserActionToast = useOutlineSelector((state) => state.showUserActionToast);
	return showUserActionToast ? <ActionToast /> : null;
};

/**
 * Selects the relevant responses state for the action toast
 * When user added/moved/... the instance - use the current responses state
 * When user deleted the instance - use the historical responses state, when the action happened
 */
export const selectActionToastResponse = (
	state: OutlineState,
	address: OutlineInstanceAddress
): OutlineResponseInstance => {
	const userAction = selectUserAction(state);
	if (!userAction) return;

	return userAction.action === 'deleteInstance'
		? selectUserActionResponse(state, address)
		: selectResponse(state, address);
};

export default ActionToastContainer;
