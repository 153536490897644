import { type VFC, useRef, useEffect } from 'react';

interface Props {
	src: string;
}

const ScriptFigure: VFC<Props> = ({ src }) => {
	const ref = useRef(null);

	useEffect(() => {
		if (ref?.current) {
			const script = document.createElement('script');
			script.src = src;
			ref.current.appendChild(script);
		}
	}, [ref, src]);

	return <div ref={ref} />;
};

export default ScriptFigure;
