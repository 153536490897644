import { fv, irr, npv, pv, rate, sum } from './formula/namedFunctions';
import { AvailableFormulas, KeyCodes, Dir } from './types';

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

// TODO Dedupe cellHandle regex throughout the code
export const regex = {
	cellHandle: /([A-Za-z]+)([0-9]+)/gi,
	cellsRange: /([A-Z]+\d+):([A-Z]+\d+)/i // Examples - https://regexr.com/6vf89
};

export const availableFormulaNames = ['SUM', 'FV', 'PV', 'NPV', 'DISC', 'RATE', 'IRR'];

export const headerRowHeight = 30;
export const headerColumnWidth = 30;

export const availableFormulas: AvailableFormulas = {
	sum: {
		formula: sum,
		required: {
			min: 1
		}
	},
	fv: {
		formula: fv,
		required: {
			min: 3,
			max: 5
		}
	},
	irr: {
		formula: irr,
		required: {
			min: 1,
			max: 2
		}
	},
	pv: {
		formula: pv,
		required: {
			min: 3,
			max: 5
		}
	},
	npv: {
		formula: npv,
		required: {
			min: 2
		}
	},
	rate: {
		formula: rate,
		required: {
			min: 3,
			max: 6
		}
	}
};

export const staticErrorReturns = {
	circularDependencyError: {
		success: false,
		error:
			'Circular dependency detected. The formula or function you entered should not include the cell address of the current cell, or a cell that indirectly refers to the current cell.',
		result: '#REF?'
	},
	nameError: {
		success: false,
		error: 'Invalid Name Error',
		result: '#NAME?'
	},
	implementationError: {
		success: false,
		error: 'Formula has not implemented yet: ',
		result: '#IMPLEMENTED?'
	},
	argumentsError: {
		success: false,
		error: 'Wrong number of variables in function.',
		result: ''
	},
	signatureError: {
		success: false,
		error: 'This formula contains unexpected characters.',
		result: '#ERROR!'
	},
	numberError: {
		success: false,
		result: '#NUM!',
		error: 'Number error'
	},
	div0Error: {
		success: false,
		result: '#DIV/0!',
		error: 'Divide By Zero Error'
	},
	valueError: {
		success: false,
		result: '#VALUE!',
		error: 'This function contains unexpected characters.'
	},
	/**
	 * This one is a custom, used for $4 or 1,000
	 */
	unexpectedCharactersError: {
		success: false,
		error: 'This formula cannot be parsed because it contains unexpected characters.',
		result: '#ERROR!'
	}
};

export const currencies = ['$', '€'];

/**
 * `cell-source` - imports dest from another cell
 *
 * To import dest from another cell we use format:
 * `cash_it_out_sheet:B23`
 *
 * Interactive examples - https://regexr.com/6t6j3
 */
export const cellSourceRegex = /\w+:[A-Z]\d+/;

/**
 * `template-source` - imports multiple dests in the template format
 *
 * To import multiple dests with the template formatting we use format:
 * `Some text {destA} more text {destB}`
 * `Dests repetition is allowed, {destA} and {destA} again`
 *
 * Interactive examples - https://regexr.com/6t6tg
 */
export const templateSourceRegex = /{([\w:-]+)}/;

export const directions = {
	[KeyCodes.ArrowUp]: Dir.Up,
	[KeyCodes.ArrowDown]: Dir.Down,
	[KeyCodes.ArrowLeft]: Dir.Left,
	[KeyCodes.ArrowRight]: Dir.Right
};

export const firefoxViewHeaderOffset = 39;

export const separateInputAreaId = 'mobile-input-area';
export const separateInputAreaButtonAccept = 'mobile-input-area-accept';
export const separateInputAreaButtonDiscard = 'mobile-input-area-discard';

export const getActiveAreaId = (dest: string): string => `active-cell-input-${dest}`;
export const selectableContainerId = 'selectable-container';
export const dragHandleDataKey = 'drag-handle';

export const getCursorId = (dest: string): string => `cursor-${dest}`;
export const getRangeSelectionId = (dest: string): string => `range-selection-${dest}`;
