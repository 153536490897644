import React, { ChangeEvent, forwardRef } from 'react';

import { useTheme } from '@emotion/react';

import { fromHtmlToRawText } from '~/components/WritingTemplate/FillIn/helpers';

import { simpleSelect } from './styles';

interface Props {
	id?: string;
	choices: string[] | { name: string; key: string }[];
	select: string;
	presentation?: string;
	placeholder?: string;
	ariaLabel?: string;
	readOnly?: boolean;
	onChange: (str: string) => void;
	parent?: string;
}

const Selector = forwardRef<HTMLSelectElement, Props>((props, ref) => {
	const {
		choices,
		select,
		readOnly,
		presentation = 'block',
		ariaLabel = 'choose one',
		placeholder = 'choose one',
		onChange,
		parent,
		...rest
	} = props;

	const theme = useTheme();

	const isSelected = !!select;
	const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value);
	return (
		<select
			ref={ref}
			disabled={readOnly}
			css={simpleSelect(theme, isSelected, presentation, parent)}
			value={isSelected ? select : placeholder}
			aria-label={ariaLabel}
			onChange={handleSelect}
			{...rest}>
			<option value="" disabled={isSelected} hidden={isSelected}>
				{placeholder}
			</option>
			{choices.map((choice, id) =>
				typeof choice === 'string' ? (
					<option key={id} value={choice}>
						{fromHtmlToRawText(choice)}
					</option>
				) : (
					<option key={choice.key} value={choice.key}>
						{fromHtmlToRawText(choice.name)}
					</option>
				)
			)}
		</select>
	);
});
Selector.displayName = 'Selector';

export default Selector;
