import React, { FC } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { css } from '@emotion/react';
import { darken } from 'polished';

import { WebtextButton } from '~/components';
import { useToggle } from '~/hooks';
import { Theme } from '~/styles/themes';

import { expandablePanelStyles } from '../../ExpandablePanel/ExpandablePanel';
import ActivityHeader from '../ActivityHeader';
import { getAnswerEventLabel } from './utils';

import type { Props as VersionHistoryItemProps } from './VersionHistoryItem';
import type { AnswerVersion } from '~/notebook/types';

interface Props extends VersionHistoryItemProps {
	version: AnswerVersion;
}

const ExpandableAnswerVersionHistoryItem: FC<Props> = (props) => {
	const { version, studentId, timeZone, children } = props;

	const [panelOpen, togglePanelOpen] = useToggle(true);

	const eventLabel = getAnswerEventLabel(version, studentId);

	return (
		<div css={expandablePanelStyles.historyItem}>
			{version.event === 'unposted' && (
				<ActivityHeader timestamp={version.created_at} timeZone={timeZone}>
					{eventLabel.toUpperCase()}
				</ActivityHeader>
			)}

			{version.event === 'posted' && (
				<>
					<ActivityHeader timestamp={version.created_at} timeZone={timeZone}>
						<WebtextButton
							css={expandableVersionItemStyles.toggle}
							className="expandable-panel-toggle"
							variant="text"
							onClick={togglePanelOpen}>
							{eventLabel.toUpperCase()}
							<span css={expandablePanelStyles.toggleArrow}>
								{panelOpen ? <FaChevronUp /> : <FaChevronDown />}
							</span>
						</WebtextButton>
					</ActivityHeader>

					{panelOpen && (
						<div
							data-test="activity-history-item-body"
							className="activity-history-item-body answer-version"
							css={expandablePanelStyles.historyItemBody}>
							{children}
						</div>
					)}
				</>
			)}
		</div>
	);
};

const expandableVersionItemStyles = {
	toggle: (theme: Theme) => css`
		${expandablePanelStyles.toggle(theme)};

		color: ${theme.colors['cobalt-blue']};
		&:hover {
			color: ${darken(0.1, theme.colors['cobalt-blue'])};
		}
	`
};

export default ExpandableAnswerVersionHistoryItem;
