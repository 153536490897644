import { DateTime } from 'luxon';

interface FormatDateOptions {
	date: string;
	timeZone: string;
}

export function formatDate({ date, timeZone }: FormatDateOptions) {
	return date ? DateTime.fromISO(date).setZone(timeZone).toFormat('MM/dd/yy') : null;
}

interface FormatTimeOptions {
	time: string;
	timeZone: string;
	withoutYear?: boolean;
	withoutTimeZone?: boolean;
}

export function formatTime({ time, timeZone, withoutYear, withoutTimeZone }: FormatTimeOptions) {
	if (time) {
		let formatString = "MM/dd/yy 'at' h:mm a ZZZZ";
		if (withoutYear) formatString = formatString.replace('/yy', '');
		if (withoutTimeZone) formatString = formatString.replace(' ZZZZ', '');

		return DateTime.fromISO(time)
			.setZone(timeZone)
			.toFormat(formatString)
			.replace(/ AM( |$)/, ' a.m.$1')
			.replace(/ PM( |$)/, ' p.m.$1');
	} else {
		return null;
	}
}

export function formatTimeLong({ time, timeZone }: FormatTimeOptions) {
	return DateTime.fromISO(time)
		.setZone(timeZone)
		.toFormat("MMMM d, yyyy, 'at' h:mm a ZZZZ")
		.replace(/ AM /, ' a.m. ')
		.replace(/ PM /, ' p.m. ');
}

export function formatTimeShort({ time, timeZone, withoutTimeZone }: FormatTimeOptions) {
	const formatString = 'MM/dd, hh:mm a';
	const z = withoutTimeZone ? '' : ' ' + DateTime.fromISO(time).setZone(timeZone).toFormat('ZZZZ');

	return (
		DateTime.fromISO(time)
			.setZone(timeZone)
			.toFormat(formatString)
			.replace(/ AM$/, ' a.m.')
			.replace(/ PM$/, ' p.m.') + z
	);
}

export function formatTimeFromNow({ time }) {
	return DateTime.fromISO(time).toRelative();
}

export const formatRelativeTime = (datetime: DateTime): string => {
	const { minutes } = datetime.diffNow(['minutes']).toObject();
	return minutes > -1 && minutes <= 0 ? 'moments ago' : datetime.toRelative({ locale: 'en-US' });
};

export function formatSecondsAgo(seconds) {
	try {
		return formatTimeFromNow({ time: DateTime.local().minus({ seconds: seconds }) });
	} catch (e) {
		if (window.Rollbar) {
			// if there's a Rollbar on window, use it
			window.Rollbar.error(e, { seconds: seconds });
		}
		// regardless of external reporting, a log message is handy for FullStory
		console.log('Error calculating relative time string', e, { seconds: seconds });
		return null;
	}
}

/**
 * Formats a list of messages with the Oxford comma style, e.g.
 *     ["a"] => "a"
 *     ["a, b"] => "a and b"
 *     ["a", "b", "c"] => "a, b, and c"
 * @see https://www.oxford-royale.com/articles/oxford-comma/
 *
 * n.b. we cannot use `Intl.ListFormat` for this because Safari 13/14 doesn't support it,
 * and `core-js` does not contain polyfills for `Intl`
 */
export const joinWithOxfordComma = (arr: string[]): string => {
	switch (arr.length) {
		case 0:
			return '';
		case 1:
			return arr[0];
		case 2:
			return arr.join(' and ');
		default:
			return arr.map((item, i) => (i === arr.length - 1 ? `and ${item}` : item)).join(', ');
	}
};

/**
 * Converts a number (e.g. 3) to its ordinal word (e.g. "third").
 *
 * Only supports numbers 1 through 10.
 */
export const numberToOrdinalWord = (i: number): string => {
	switch (i) {
		case 1:
			return 'first';
		case 2:
			return 'second';
		case 3:
			return 'third';
		case 4:
			return 'fourth';
		case 5:
			return 'fifth';
		case 6:
			return 'sixth';
		case 7:
			return 'seventh';
		case 8:
			return 'eighth';
		case 9:
			return 'ninth';
		case 10:
			return 'tenth';
		case 11:
			return 'eleventh';
		case 12:
			return 'twelfth';
		case 13:
			return 'thirteenth';
		case 14:
			return 'fourteenth';
		case 15:
			return 'fifteenth';
		case 16:
			return 'sixteenth';
		case 17:
			return 'seventeenth';
		case 18:
			return 'eighteenth';
		case 19:
			return 'nineteenth';
		case 20:
			return 'twentieth';
		case 21:
			return 'twenty-first';
		case 22:
			return 'twenty-second';
		case 23:
			return 'twenty-third';
		case 24:
			return 'twenty-fourth';
		case 25:
			return 'twenty-fifth';
		case 26:
			return 'twenty-sixth';
		case 27:
			return 'twenty-seventh';
		case 28:
			return 'twenty-eighth';
		case 29:
			return 'twenty-ninth';
		case 30:
			return 'thirtieth';
		default:
			return i.toString();
	}
};

/**
 * Converts a number (e.g. 3) to a word (e.g. "three").
 *
 * Only supports numbers 1 through 10.
 */
export const numberToWord = (i: number) => {
	switch (i) {
		case 1:
			return 'one';
		case 2:
			return 'two';
		case 3:
			return 'three';
		case 4:
			return 'four';
		case 5:
			return 'five';
		case 6:
			return 'six';
		case 7:
			return 'seven';
		case 8:
			return 'eight';
		case 9:
			return 'nine';
		case 10:
			return 'ten';
		default:
			return i;
	}
};
