import { ObjectTyped } from 'object-typed';

import { isNullOrUndefined } from '~/utils';

/**
 * Contains a set of allowed replaces for the label
 * Each key corresponds to the certain regex
 *
 * E.g. `number` key corresponds to the {number} regex
 *
 * So the changes need to applied only to the `Replacers` & `replacersRegexes`
 */
interface Replacers {
	number: number | string | undefined;
}
const replacersRegexes: { [replacer in keyof Replacers]: RegExp } = {
	number: /{number}/g
};

/**
 * Iterates over the specified replacers and replacers the corresponding regexes with the passed value
 * @param value - label text
 * @param replacers - a set of the replacers keys and the values that should be put instead
 */
export const replacePlaceholders = (value: string, replacers: Replacers): string =>
	ObjectTyped.entries(replacers).reduce((replacedValue, replacerEntry) => {
		const [replacerType, replaceValue] = replacerEntry;
		if (isNullOrUndefined(replacedValue)) {
			return replacedValue;
		}

		const replacerRegex = getReplacerRegex(replacerType);
		return replacedValue.replace(replacerRegex, String(replaceValue));
	}, value);

const removePlaceholders = (value: string): string =>
	ObjectTyped.keys(replacersRegexes).reduce((sanitizedValue, replacerType) => {
		const replacerRegex = getReplacerRegex(replacerType);
		return sanitizedValue.replace(replacerRegex, '');
	}, value);

/**
 * Removes all the parts of the label that can be considered as dynamically changing (placeholders)
 * or shows that label contains children (trailing colon)
 * @param value - label text
 * @example Main point label sanitation
 * // returns 'Main point'
 * removeDynamicParts('Main point {number}:');
 */
export const removeDynamicParts = (value: string): string =>
	removeTrailingColon(removePlaceholders(value)).trim();

export const removeTrailingColon = (value: string): string => value.trim().replace(/:$/, '');

const getReplacerRegex = (replacerType: keyof Replacers): RegExp => {
	const regex = replacersRegexes[replacerType];
	regex.lastIndex = 0; // Resets the global regex state
	return regex;
};
