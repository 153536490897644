import { type ComponentType, type ReactElement } from 'react';

import EditModeStateProvider, { type EditModeState } from '../providers/EditModeStateProvider';

function withEditModeState<T>(Component: ComponentType<T>) {
	return function EditModeStateWrapper(
		props: T &
			Partial<
				Pick<
					EditModeState,
					| 'tabUpdate'
					| 'inputValues'
					| 'fromConfig'
					| 'getAwsParams'
					| 'getImageUrl'
					| 'imageUploadUrl'
					| 'builderFamilyId'
					| 'spreadsheetProps'
					| 'scrollableContainer'
				>
			> &
			Required<Pick<EditModeState, 'schema' | 'toc'>> & {
				readOnly?: boolean;
				instructorView?: boolean;
				isScaling?: boolean;
			}
	): ReactElement {
		const {
			toc,
			schema,
			tabUpdate,
			inputValues,
			getAwsParams,
			getImageUrl,
			fromConfig,
			imageUploadUrl,
			builderFamilyId,
			readOnly,
			instructorView,
			isScaling,
			spreadsheetProps,
			scrollableContainer
		} = props;

		return (
			<EditModeStateProvider
				instructorView={instructorView}
				isScaling={isScaling}
				fromConfig={fromConfig}
				getAwsParams={getAwsParams}
				getImageUrl={getImageUrl}
				imageUploadUrl={imageUploadUrl}
				toc={toc}
				readOnly={readOnly}
				builderFamilyId={builderFamilyId}
				schema={schema}
				tabUpdate={tabUpdate}
				inputValues={inputValues}
				spreadsheetProps={spreadsheetProps}
				scrollableContainer={scrollableContainer}>
				<Component {...(props as any)} />
			</EditModeStateProvider>
		);
	};
}

export default withEditModeState;
