import { useState, useEffect } from 'react';

import { DateTime } from 'luxon';

import { formatRelativeTime } from '~/utils/formatting';

type Timestamp = string | number | Date | undefined | null;

/**
 * Returns a relative time phrase based on a timestamp value.
 *
 * @param {Timestamp} [timestamp] The timestamp value to calculate the relative time phrase for
 * @returns {string} A relative time phrase string.
 */
export const getRelativeTimePhrase = (timestamp?: Timestamp): string => {
	let dateTime;
	switch (typeof timestamp) {
		case 'string':
			dateTime = DateTime.fromISO(timestamp);
			break;
		case 'number':
			dateTime = DateTime.fromMillis(timestamp);
			break;
		default:
			dateTime = DateTime.fromJSDate(timestamp || new Date());
	}
	return formatRelativeTime(dateTime);
};

export function useRelativeTimePhrase(timestamp?: Timestamp): string {
	const [relativePhrase, setRelativePhrase] = useState(getRelativeTimePhrase(timestamp));

	useEffect(() => {
		const minuteUpdateTimeout = 61;

		const timer = setInterval(() => {
			const newDraftTime = getRelativeTimePhrase(timestamp);
			if (relativePhrase !== newDraftTime) {
				setRelativePhrase(getRelativeTimePhrase(timestamp));
			}
		}, minuteUpdateTimeout);
		return () => clearInterval(timer);
	}, [relativePhrase, timestamp]);

	return relativePhrase;
}
