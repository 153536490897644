import {
	defaultChildrenInstanceNumber,
	defaultToggleInstanceNumber
} from '~/components/Outline/fixtures';
import { OutlineSectionInstancesNumber } from '~/components/Outline/types';

/**
 * Returns the instances number based on the schema and the default settings
 *
 * @example
 * // returns { initial: 1, min: 1, max: 1 }
 * getChildrenSectionInstancesNumber(undefined)
 *
 * @example
 * // returns { initial: 1, min: 4, max: 4 }
 * getChildrenSectionInstancesNumber({ min: 4 })
 *
 * @example
 * // returns { initial: 2, min: 2, max: 2 }
 * getChildrenSectionInstancesNumber({ initial: 2 })
 *
 * @example
 * // returns { initial: 1, min: 1, max: 4 }
 * getChildrenSectionInstancesNumber({ max: 4 })
 *
 * @example
 * // returns { initial: 4, min: 4, max: 4 }
 * getChildrenSectionInstancesNumber({ initial: 1, min: 4, max: 1 })
 *
 * @example Playground
 * https://codepen.io/OlexanderD/pen/qBKXaMY
 */
export const getChildrenSectionInstancesNumber = (
	instancesNumber?: OutlineSectionInstancesNumber
): Required<OutlineSectionInstancesNumber> => {
	const schemaInstancesNumber = instancesNumber || defaultChildrenInstanceNumber;
	const { initial: schemaInitial, min: schemaMin, max: schemaMax } = schemaInstancesNumber;

	let initial;
	if (schemaInitial === undefined) {
		initial = defaultChildrenInstanceNumber.initial;
	} else {
		initial = Math.max(schemaInitial, 0); // initial ≥ 0
	}

	let min;
	if (schemaMin === undefined) {
		min = initial;
	} else {
		min = Math.max(schemaMin, 0); // min ≥ 0 & min can be less or higher than initial
	}

	let max;
	if (schemaMax === undefined) {
		max = Math.max(min, initial); // max ≥ initial & max ≥ min
	} else {
		max = Math.max(schemaMax, initial); // max ≥ initial
		max = Math.max(max, min); // max ≥ min
		max = Math.max(max, 0); // max ≥ 0
	}

	return { initial, min, max };
};

/**
 * Having this method as the extension point and for the consistency with the `getTemplateChildrenInstancesNumber`
 * Possibly we would have more complicated logic for the toggle instances number
 */
export const getToggleSectionInstancesNumber = (): Required<OutlineSectionInstancesNumber> => {
	return defaultToggleInstanceNumber;
};
