import React, { FC } from 'react';

import { expandablePanelStyles } from '../../ExpandablePanel/ExpandablePanel';
import ActivityHeader from '../ActivityHeader';
import { getAnswerEventLabel } from './utils';

import type { Props as VersionHistoryItemProps } from './VersionHistoryItem';
import type { AnswerVersion } from '~/notebook/types';

interface Props extends VersionHistoryItemProps {
	version: AnswerVersion;
}

const AnswerVersionHistoryItem: FC<Props> = (props) => {
	const { version, studentId, timeZone } = props;

	const eventLabel = getAnswerEventLabel(version, studentId);

	return (
		<div css={expandablePanelStyles.historyItem}>
			<ActivityHeader timestamp={version.created_at} timeZone={timeZone}>
				{eventLabel.toUpperCase()}
			</ActivityHeader>
			{version.event === 'posted' && (
				<div
					data-test="activity-history-item-body"
					className="activity-history-item-body answer-version"
					css={(theme) => expandablePanelStyles.historyItemBody(theme, { variant: 'answer' })}>
					<p>{version.body}</p>
				</div>
			)}
		</div>
	);
};

export default AnswerVersionHistoryItem;
