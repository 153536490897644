import React from 'react';

import {
	DecoratorNode,
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	LexicalNode,
	NodeKey
} from 'lexical';

import {
	CitationResponse,
	CitationResponseAttributes,
	SerializedCitationNode
} from '../../../types';
import Citation from '../components/Citation';
import { generateCitationId } from '../helpers/citations.helper';

/**
 * Wrapper node to insert arbitrary view (component) inside the editor. Decorator node rendering is framework-agnostic and can output components from React, vanilla js or other frameworks.
 * https://lexical.dev/docs/concepts/nodes#decoratornode
 */
export class CitationNode extends DecoratorNode<JSX.Element> {
	private decorationText: string;
	private identifier: string;
	private citationKey: string;
	private attributes: CitationResponseAttributes;

	static getType(): string {
		return 'citation';
	}

	static clone(node: CitationNode): CitationNode {
		return new CitationNode(
			node.identifier,
			node.decorationText,
			node.citationKey,
			node.attributes,
			node.__key
		);
	}

	constructor(
		identifier: string,
		decorationText: string,
		citationKey: string,
		attributes: CitationResponseAttributes,
		key?: NodeKey
	) {
		super(key);
		this.decorationText = decorationText;
		this.identifier = identifier;
		this.attributes = attributes;
		this.citationKey = citationKey;
	}

	/**
	 * This method used for two things:
	 * 1) export json state copy of editor
	 * 2) copy custom entities with context
	 *
	 * We're using only (#2), even though it can be used for exporting in the future;
	 * I assume it should be reconsidered for this purpose (exporting json state copy)
	 */
	exportJSON(): SerializedCitationNode {
		return {
			type: 'citation',
			version: 1,
			identifier: this.identifier,
			decoratorText: this.decorationText,
			citationKey: this.citationKey,
			attributes: this.attributes
		};
	}

	/**
	 * This method is used for two things:
	 * 1) import json state copy of editor
	 * 2) paste custom entities with context
	 *
	 * We're using only (#2), even though it can be used for exporting in the future;
	 * I assume it should be reconsidered for this purpose (importing json state copy)
	 */
	static importJSON(json: SerializedCitationNode): CitationNode {
		const { decoratorText, citationKey, attributes } = json;
		/**
		 * When copy pasted with text context around we don't need to select citation and release the selection position
		 */
		return $createCitationNode(generateCitationId(), decoratorText, citationKey, attributes);
	}

	createDOM(): HTMLElement {
		const element = document.createElement('span');
		element.classList.add('citation-wrapper');
		return element;
	}

	updateDOM(): false {
		return false;
	}

	/**
	 * This method is used for two things:
	 * 1) export html to the state of editor
	 * 2) copy custom entities w/o context
	 */
	exportDOM(): DOMExportOutput {
		const element = document.createElement('mark');

		element.setAttribute('id', this.identifier);
		/**
		 * We're adding this as a custom attribute, whit will be handled for copy/paste/export to response
		 */
		element.setAttribute(
			'data-citation',
			JSON.stringify({
				identifier: this.identifier,
				attributes: this.attributes,
				markerValue: this.decorationText,
				key: this.citationKey
			})
		);

		return { element };
	}

	/**
	 * This method is used for two things:
	 * 1) import html to the state of editor
	 * 2) paste custom entities w/o context
	 */
	static importDOM(): DOMConversionMap | null {
		return {
			mark: () => ({
				conversion: convertCitationElement,
				priority: 0
			})
		};
	}

	getIdentifier(): string {
		return this.identifier;
	}

	decorate(): JSX.Element {
		const nodeKey = this.getKey();
		return (
			<Citation
				nodeKey={nodeKey}
				decorationText={this.decorationText}
				identifier={this.identifier}
				attributes={this.attributes}
				citationKey={this.citationKey}
			/>
		);
	}
}

export function $createCitationNode(
	id: string,
	decorationText: string,
	citationKey: string,
	attributes: CitationResponseAttributes
): CitationNode {
	return new CitationNode(id, decorationText, citationKey, attributes);
}

export function $isCitationNode(node: LexicalNode | null | undefined): node is CitationNode {
	return node instanceof CitationNode;
}

function convertCitationElement(domNode: Node): null | DOMConversionOutput {
	if (domNode instanceof HTMLElement) {
		const { id } = domNode;
		const citationDataValue = domNode.getAttribute('data-citation');
		if (!citationDataValue) return null;

		const citationData: CitationResponse = JSON.parse(citationDataValue);
		if (!citationData || citationData.identifier !== id) return null;
		const { markerValue, attributes, key } = citationData;
		/**
		 * When creates a new node we assign it with a new id
		 */
		const node = $createCitationNode(generateCitationId(), markerValue, key, attributes);

		return { node };
	}
	return null;
}
