import * as React from 'react';

export function CheckboxInCircle(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<path
				d="M49.1 19.15H22.9a3.82 3.82 0 0 0-3.75 3.75V49a3.81 3.81 0 0 0 3.75 3.7H49a3.8 3.8 0 0 0 3.7-3.7V22.9a3.59 3.59 0 0 0-3.6-3.75ZM32.26 45.36 22.9 36l2.59-2.59 6.77 6.77 14.25-14.26 2.59 2.59Z"
				style={{
					fill: '#fff'
				}}
				data-name="check-box"
			/>
		</svg>
	);
}
