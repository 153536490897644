import { AxiosRequestConfig } from 'axios';

import { axios } from '~/utils';

interface PostCourseEventPayload {
	courseId: number;
	pageFamilyId: string;
	objectFamilyId: any;
	event: {
		type: string;
		subtype?: string;
		data?: any;
	};
}

export const postCourseEvent = (
	url = '/api/courses/v1/events',
	payload: PostCourseEventPayload,
	additionalParams: object,
	headers: AxiosRequestConfig['headers'] | null
) => {
	const data = {
		course_id: payload.courseId,
		page_family_id: payload.pageFamilyId,
		object_family_id: payload.objectFamilyId,
		event: payload.event,
		...additionalParams
	};

	if (headers) {
		return axios.post(url, data, { headers });
	}
	return axios.post(url, data);
};
