import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';
import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';

import GenericErrorBoundary from '~/components/GenericErrorBoundary';
import { getResponsesNotSubmittable } from '~/components/Outline/helpers';
import { OutlineResponsesState } from '~/components/Outline/types';
import { Footer } from '~/components/pageElements/Footer';
import Button from '~/components/WebtextButton';
import styles, {
	bottomWrapper,
	buttonWrapper,
	centerStyle,
	editModeAdditionalStyles,
	invalidText,
	objectStyleWrapper,
	spaceDivider
} from '~/components/WritingTemplate/EditMode/styles';
import {
	AllowedFooterButtons,
	BuilderElementConfig,
	DividerType,
	DraftSaveOptions
} from '~/components/WritingTemplate/EditMode/types';
import { BuilderApiEndpointKey } from '~/components/WritingTemplate/WritingTemplate/utils';
import { useRelativeTimePhrase } from '~/hooks';
import { getValueExistsInEnum } from '~/utils';
import NetError from '~/utils/netErrors';
import { trimTags } from '~/utils/parsing';

import Loader from '../../Loader';
import { ChartState, SpreadsheetState } from '../Charts/types';
import Instruction from '../Instruction';
import NetworkError from '../NetworkError';
import { getRangeAddresses } from '../Spreadsheet/helpers';
import { generatingLoaderStyle, timeStyle } from '../ViewMode/styles';
import { getOutline, listVariants } from '../ViewMode/utils';
import { TemplateValidationContext } from '../WritingTemplate/providers/TemplateValidationProvider';
import { dividerStyles } from '../WritingTemplate/styles';
import {
	IDictionary,
	Kinds,
	ValidationActionType,
	ValidationItems,
	ValidationItemStatus,
	ValidationStatus
} from '../WritingTemplate/types';
import WTPhrase from '../WTPhrase';
import BuilderElementsContainer from './components/BuilderElementsContainer';
import { getFooterData, getInteractivePromptsParts } from './helpers';
import { isEditableCellsFilled } from './helpers/spreadsheetHelper';
import withEditModeState from './hoc/withEditModeState';
import { EditModeStateContext } from './providers/EditModeStateProvider';

export interface Props {
	builderFamilyId?: string;
	timeStamp?: Date;
	isEditPreview?: boolean;
	elementLabel?: string;
	netError?: NetError<BuilderApiEndpointKey>;
	saveInputsToLocalStorage?: (data: { [key: string]: string }) => void;
	onTemplateEdited?: () => void;
	onSaveDraft?: (values: IDictionary, options) => void;
	onFinalSave?: (values: IDictionary) => void;
	scaleImageFailure?: boolean;
	resetScaleImageFailure?: () => void;
	isScaling?: boolean;
	hideSaveProgressButton?: boolean;
}

const EditMode: React.FC<Props> = ({
	timeStamp,
	isEditPreview,
	elementLabel,
	netError,
	saveInputsToLocalStorage,
	onTemplateEdited,
	onSaveDraft,
	onFinalSave,
	scaleImageFailure,
	resetScaleImageFailure,
	isScaling,
	hideSaveProgressButton
}) => {
	const draftTimePhrase = useRelativeTimePhrase(timeStamp);

	const [unsaved, setUnsaved] = useState<boolean>(false);
	// necessary for mobile, because there's a gap between when we have
	// state that tells us which kind of loading we're doing and when image
	// scaling is happening, so when we're scaling, this tells us which button
	// to render a loading indicator on
	const [lastButtonClicked, setLastButtonClicked] = useState<string>(null);

	const headingRef = useRef<HTMLElement>(null);

	const { validationState, previousValidationState, dispatchValidation } =
		useContext(TemplateValidationContext);

	const {
		userInputs,
		isInitialized,
		autosaveRequested,
		setAutosaveRequested,
		composedOutput,
		spreadsheetErrors,
		transferSelection,
		schema,
		setUserUpdate,
		isUserUpdate,
		builderFamilyId,
		setFinalSaveRequested,
		setSaveRequested,
		saveRequested,
		finalSaveRequested,
		isPromptsReadOnly
	} = useContext(EditModeStateContext);

	const isPromptsPresent = !!schema?.prompts;
	const isOutlinePresent = !!schema?.output?.outline;

	const resetSaveRequestsStatus = useCallback(() => {
		if (!isScaling) {
			setSaveRequested(false);
			setFinalSaveRequested(false);
		}
	}, [setSaveRequested, setFinalSaveRequested, isScaling]);

	useEffect(() => {
		// if validation changed, that means the submission call returned, and we need
		// to re-enable complete button to allow the user to deal with it.
		const pendingStatuses = [ValidationStatus.ClientPending, ValidationStatus.ServerPending];
		if (!pendingStatuses.includes(validationState?.status) && !isScaling) {
			setFinalSaveRequested(false);
		}
	}, [validationState?.status, setFinalSaveRequested, isScaling]);

	useEffect(() => {
		// if the server sent the submission cancel the loading state on the save button
		if (validationState?.status === ValidationStatus.ServerPending) {
			setSaveRequested(false);
		}
	}, [validationState?.status, setSaveRequested]);

	useEffect(() => {
		if (isEditPreview) {
			headingRef.current.focus({ preventScroll: true });
		}
	}, [isEditPreview]);

	useEffect(() => resetSaveRequestsStatus(), [timeStamp, resetSaveRequestsStatus]); // if the timestamp gets updated our save is completed
	useEffect(() => {
		if (netError) {
			resetSaveRequestsStatus();
		}
	}, [netError, resetSaveRequestsStatus]); // if the request failed our save is considered completed

	const renderSchema = useCallback(
		(schema) => {
			if (!isInitialized || isEmpty(userInputs)) {
				return (
					<div css={centerStyle}>
						<Loader />
					</div>
				);
			}

			if (isPromptsPresent) {
				return schema.prompts
					.reduce((prompts, promptBlock, index) => {
						const divider = index > 0 ? [DividerType.Horizontal] : [];
						return [...prompts, ...divider, promptBlock];
					}, [])
					.map((promptBlock, id) => {
						if (promptBlock === DividerType.Horizontal) {
							return (
								<hr
									css={(theme) => dividerStyles(theme)}
									aria-hidden
									key={`${builderFamilyId}${id}-hr`}
								/>
							);
						}

						if (promptBlock === DividerType.Space) {
							return (
								<div aria-hidden key={`${builderFamilyId}${id}-space`} css={spaceDivider}></div>
							);
						}

						if (Array.isArray(promptBlock)) {
							return parseArrayDefinition({ promptBlock });
						}

						return parseObjectDefinition(promptBlock, id);
					});
			}

			/**
			 * Means old outline is present
			 */
			if (isOutlinePresent) {
				return schema.output.outline.map(onParseOldOutline);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isInitialized, userInputs, transferSelection, validationState?.status, isPromptsReadOnly]
	);

	useEffect(() => {
		if (validationState?.status !== ValidationStatus.Failure) return;

		const { validations } = validationState;
		const invalidElementsList = getInteractivePromptsParts(schema.prompts)
			.map(({ dest }) => dest)
			.filter((dest) => validations[dest]?.message);
		const focusDest = first(invalidElementsList);

		if (!focusDest) return;

		/**
		 * Code below will try to find rte invalid label or textarea invalid label or group message invalid label
		 * Then focus it. Otherwise skip, bc SPI controls focus by itself.
		 */

		const rtElement = document.getElementById(`rt-validation-message-${focusDest}`);
		if (rtElement) {
			rtElement?.focus();
			return;
		}

		const areaElement = document.getElementById(`simple-validation-message-${focusDest}`);
		if (areaElement) {
			areaElement?.focus();
			return;
		}

		const groupMessageElement = first(document.getElementsByClassName(focusDest)) as HTMLElement;
		if (groupMessageElement) {
			groupMessageElement?.parentElement?.focus();
			return;
		}
	}, [validationState, schema.prompts]);

	const parseArrayDefinition = (props: {
		promptBlock: BuilderElementConfig[];
		listDepth?: number;
		parentPresentation?: string;
		isTable?: boolean;
	}) => {
		const { promptBlock, listDepth, parentPresentation, isTable } = props;
		const isBlockWithValidations = (promptBlock: BuilderElementConfig[]) =>
			promptBlock.some((block) => block.kind === Kinds.FillIn && block.validation);

		return (
			<BuilderElementsContainer
				elementsArray={injectGroupValidationMessage(promptBlock)}
				isTable={isTable}
				parentPresentation={parentPresentation}
				listDepth={listDepth}
				onParseOldOutline={onParseOldOutline}
				resetScaleImageFailure={resetScaleImageFailure}
			/>
		);

		function injectGroupValidationMessage(
			promptBlock: BuilderElementConfig[]
		): BuilderElementConfig[] {
			if (!(parentPresentation === 'inline') || !isBlockWithValidations(promptBlock)) {
				return promptBlock;
			}

			const promptDestinations = promptBlock
				.map((prompt) => (prompt as BuilderElementConfig & { dest?: string }).dest)
				.filter(Boolean);
			return [{ kind: Kinds.Group, forDestinations: promptDestinations }, ...promptBlock];
		}
	};

	const parseObjectDefinition = (promptBlock, id: number) => {
		const { presentation, items } = promptBlock;
		return (
			<div
				css={objectStyleWrapper(presentation || 'inline')}
				key={`support-${builderFamilyId}${id}${presentation}`}>
				{parseArrayDefinition({
					promptBlock: items,
					listDepth: null,
					parentPresentation: presentation
				})}
			</div>
		);
	};

	/**
	 * @deprecated
	 * This function is used to parse old outline and will no longer receive any update
	 * Once new outlines replace old ones this function should be removed
	 */
	const onParseOldOutline = (component, listDepth = 0) => {
		switch (component.kind) {
			case Kinds.List: {
				const items = component.items.map((el) => onParseOldOutline(el, listDepth));
				return getOutline(listVariants[listDepth], items);
			}
			case Kinds.Item:
				return parseArrayDefinition({ promptBlock: component.elements, listDepth: listDepth + 1 });
			case Kinds.Text:
			case Kinds.Select:
			case Kinds.ValueOf:
			case Kinds.FillIn:
			case Kinds.Map:
			case Kinds.Table:
			case Kinds.Image:
				return parseArrayDefinition({ promptBlock: [component] });
			default:
				return null;
		}
	};

	useEffect(() => {
		if (unsaved) onTemplateEdited?.();
	}, [unsaved, onTemplateEdited]);

	const updateBeforeSave = useCallback(() => {
		const isOutputWith = composedOutput?.[Kinds.CitationOutput];

		if (!isEmpty(userInputs)) {
			if (isOutputWith) {
				const text = Object.entries(userInputs)
					.filter(([key]) => key !== Kinds.CitationOutput)
					.map(([_, value]) => (value?.kind === Kinds.FillInWithCitations ? value?.text : value))
					.join('');

				const citations = Object.entries(userInputs)
					.filter(
						([key, value]) =>
							key !== Kinds.CitationOutput && value?.kind === Kinds.FillInWithCitations
					)
					.map(([_, value]) => value?.citations)
					.reduce((acc, val) => [...acc, ...val], []);

				const fillsCompleted = {
					[Kinds.CitationOutput]: { ...isOutputWith, text, citations }
				};

				return { ...userInputs, ...fillsCompleted };
			}

			return { ...userInputs };
		}
	}, [composedOutput, userInputs]);

	/**
	 * Collect all SPI which expect some validation before submit
	 */
	const getClientSidePendingValidations = () => {
		const { validations } = validationState;
		const interactivePrompts = getInteractivePromptsParts(schema.prompts);

		const isClientSideValidatable = (prompt: IDictionary): boolean => {
			switch (prompt.kind) {
				case 'spreadsheet':
					return !isEmpty(prompt.sheet.validations);
			}
		};
		const clientSideValidatablePrompts = interactivePrompts.filter(isClientSideValidatable);
		return clientSideValidatablePrompts.reduce<ValidationItems>((pendingItems, prompt) => {
			const previousValidation = validations[prompt.dest] || {};
			return {
				...pendingItems,
				[prompt.dest]: {
					...previousValidation,
					type: 'inner',
					status: ValidationItemStatus.Pending
				}
			};
		}, {});
	};

	const handleDraftSave = useCallback(
		(options?: DraftSaveOptions) => {
			if (!options?.auto) {
				setLastButtonClicked('save');
				setUserUpdate(false);
				setUnsaved(false);
				setSaveRequested(true);
				resetScaleImageFailure?.();
			}

			onSaveDraft(updateBeforeSave(), options);
		},
		[onSaveDraft, updateBeforeSave, setUserUpdate, setSaveRequested, resetScaleImageFailure]
	);

	useEffect(() => {
		if (!isUserUpdate || saveRequested || finalSaveRequested) return;

		setUnsaved(true);
		const timer = setTimeout(() => {
			setUserUpdate(false);
			setAutosaveRequested(true);
		}, 10000);

		return () => clearTimeout(timer);
	}, [finalSaveRequested, isUserUpdate, saveRequested, setAutosaveRequested, setUserUpdate]);

	useEffect(() => {
		/**
		 * This useEffect is required to prevent saving the enclosed obsolete `userInput`
		 * Also it untangles the `userInputs` changes from the useEffect which sets up the timeout
		 */
		if (autosaveRequested) {
			handleDraftSave({ auto: true });
			setAutosaveRequested(false);
			setUnsaved(false);
		}
	}, [autosaveRequested, handleDraftSave, setAutosaveRequested]);

	const handleFinalSave = useCallback(() => {
		setUserUpdate(false);
		setUnsaved(false);
		setFinalSaveRequested(true);
		resetScaleImageFailure?.();

		dispatchValidation({ type: ValidationActionType.RunOnServer });
		onFinalSave(updateBeforeSave());
	}, [
		dispatchValidation,
		onFinalSave,
		updateBeforeSave,
		setUserUpdate,
		setFinalSaveRequested,
		resetScaleImageFailure
	]);

	/**
	 * When the client-side validation got into the "Success" status -> continue default save flow
	 */
	useEffect(() => {
		if (
			validationState.trigger === 'submit' &&
			previousValidationState.status === ValidationStatus.ClientPending &&
			validationState.status === ValidationStatus.Success
		) {
			handleFinalSave();
		}
	}, [
		validationState.status,
		previousValidationState.status,
		validationState.trigger,
		handleFinalSave
	]);

	const handleSubmit = () => {
		const clientSidePendingValidations = getClientSidePendingValidations();
		setLastButtonClicked('finalSave');

		/**
		 * There's nothing to validation on the client side. Start the submission immediately
		 */
		if (isEmpty(clientSidePendingValidations)) {
			return handleFinalSave();
		}

		dispatchValidation({
			type: ValidationActionType.Run,
			value: clientSidePendingValidations,
			trigger: 'submit'
		});
	};

	const renderSchemaButtons = () => {
		if (!schema.buttons) return null;

		return Object.entries(schema.buttons)
			.filter(([name]) => getValueExistsInEnum(AllowedFooterButtons, name))
			.map(([name, label], idx) => {
				const isDisabled = getButtonDisabled(name);
				return (
					<Button
						key={`${name}${idx}${builderFamilyId}`}
						data-ignore="1"
						id={`store-${builderFamilyId}`}
						role="button"
						aria-disabled={isDisabled}
						disabled={isDisabled || isPromptsReadOnly}
						onClick={handleSubmit}
						onMouseEnter={handleSaveInputToStorage}>
						{finalSaveRequested || (isScaling && lastButtonClicked === 'finalSave') ? (
							<div css={generatingLoaderStyle}>
								Submitting <Loader />
							</div>
						) : (
							label
						)}
					</Button>
				);
			});

		function getButtonDisabled(name: string): boolean {
			switch (name) {
				case AllowedFooterButtons.Save:
					return getSaveButtonDisabled();
			}

			function getSaveButtonDisabled(): boolean {
				return Object.values(userInputs).some((inputValue) => {
					if (isEmpty(inputValue)) return true;

					if (inputValue.sheet) {
						return getSpreadsheetNotSubmittable(inputValue);
					}
					if (inputValue.type) {
						return getChartNotSubmittable(inputValue);
					}
					if (inputValue.hierarchyResponses) {
						return getOutlineNotSubmittable(inputValue);
					}
					return getTextFieldNotSubmittable(inputValue);
				});

				function getSpreadsheetNotSubmittable(spreadsheetState: SpreadsheetState): boolean {
					const { cellValues = {}, sheet } = spreadsheetState;
					const { 'editable-ranges': editableRanges } = sheet;

					if (isEmpty(editableRanges)) return false; // Nothing to edit -> nothing to block

					if (!isEmpty(spreadsheetErrors)) {
						const errors = Object.values(spreadsheetErrors).some(Boolean);
						if (errors) return true;
					}

					const editableCoordinates = editableRanges.flatMap(
						(range) => getRangeAddresses(range).value
					);
					return !isEditableCellsFilled(editableCoordinates, cellValues);
				}

				function getChartNotSubmittable(chartState: ChartState): boolean {
					return (
						chartState.data.length === 0 ||
						chartState.data.reduce((acc, { value }) => acc + value, 0) <= 0
					);
				}

				function getOutlineNotSubmittable(value: OutlineResponsesState): boolean {
					const { hierarchyResponses } = value;
					return getResponsesNotSubmittable(hierarchyResponses);
				}

				function getTextFieldNotSubmittable(value: string | { text: string }): boolean {
					const untaggedValue = typeof value === 'string' ? trimTags(value) : trimTags(value.text);
					const trimmedValue = untaggedValue.trim();
					return !trimmedValue?.length;
				}
			}
		}
	};

	const handleSaveInputToStorage = () => {
		if (!isEditPreview) {
			saveInputsToLocalStorage?.({ ...userInputs });
		}
	};

	const theme = useTheme();

	const validationGenericMessage = useMemo(() => {
		if (validationState?.status !== ValidationStatus.Failure) return null;

		return (
			<div css={invalidText}>
				This needs a little more work. Review the suggested edits and try again.
			</div>
		);
	}, [validationState?.status]);

	return (
		<div onMouseLeave={handleSaveInputToStorage}>
			{schema && (
				<div>
					{schema.instructions ? (
						<Instruction id={`${builderFamilyId}-instruction`} value={schema.instructions} />
					) : null}
					<div css={styles(theme, editModeAdditionalStyles)}>
						<WTPhrase ref={headingRef} label={elementLabel} builderFamilyId={builderFamilyId} />
						<GenericErrorBoundary>
							<>
								{renderSchema({ ...schema })}
								{netError && <NetworkError netError={netError} />}
								{scaleImageFailure && (
									<div css={invalidText}>
										Your image file could not be uploaded. Make sure your file is saved as a JPEG,
										PNG, PJPEG, BMP, TIFF, GIF, or SVG and try again. If the error persists, contact
										Soomo Support.
									</div>
								)}
								{validationGenericMessage}
								<div css={bottomWrapper}>
									<div css={timeStyle}>
										{timeStamp && draftTimePhrase && `Saved ${draftTimePhrase}.`}
									</div>
									<div css={buttonWrapper}>
										{!hideSaveProgressButton && (
											<Button
												role="button"
												data-ignore="1"
												onClick={() => handleDraftSave()}
												disabled={isPromptsReadOnly}
												id={`save-${builderFamilyId}`}>
												{saveRequested || (isScaling && lastButtonClicked === 'save') ? (
													<div css={generatingLoaderStyle}>
														Saving <Loader />
													</div>
												) : (
													'Save Progress'
												)}
											</Button>
										)}
										{renderSchemaButtons()}
									</div>
								</div>
								<Footer {...getFooterData(schema.buttons, schema.ui?.footer)} />
							</>
						</GenericErrorBoundary>
					</div>
				</div>
			)}
			{!schema && <div css={styles}>Schema is not supported or not exist!</div>}
		</div>
	);
};

export default withEditModeState(EditMode);
