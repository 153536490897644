import { defaults } from 'lodash-es';

/**
 * Inspired by https://bobbyhadz.com/blog/javascript-check-if-two-elements-overlap
 */
type Offset = { x: number; y: number } | number;
export const elementsOverlap = (
	element1: HTMLElement,
	element2: HTMLElement,
	offset: Offset = 0
): boolean => {
	const rect1 = element1.getBoundingClientRect();
	const rect2 = element2.getBoundingClientRect();

	let offsetX: number, offsetY: number;
	if (typeof offset === 'number') {
		offsetX = offsetY = offset;
	} else {
		offsetX = offset.x;
		offsetY = offset.y;
	}

	return !(
		rect1.right + offsetX < rect2.left ||
		rect1.left - offsetX > rect2.right ||
		rect1.bottom + offsetY < rect2.top ||
		rect1.top - offsetY > rect2.bottom
	);
};

/**
 * Inspired by https://www.30secondsofcode.org/js/s/element-is-visible-in-viewport/
 * @deprecated  replace with `elementIsVisible`
 */
export const elementIsVisibleInViewport = <T extends HTMLElement>(
	element: T,
	partiallyVisible = false
): boolean => elementIsVisible(element, window, partiallyVisible);

export const elementIsVisible = <
	TElement extends HTMLElement,
	TContainer extends HTMLElement | typeof window = typeof window
>(
	element: TElement,
	container: TContainer = window as TContainer,
	partiallyVisible = false
): boolean => {
	const { top, left, bottom, right } = element.getBoundingClientRect();

	let innerWidth: number, innerHeight: number;
	if (container instanceof Window) {
		innerWidth = container.innerWidth;
		innerHeight = container.innerHeight;
	} else {
		const { width, height } = container.getBoundingClientRect();
		innerWidth = width;
		innerHeight = height;
	}

	return partiallyVisible
		? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
				((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
		: top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export interface ScrollToElementOptions extends ScrollIntoViewOptions {
	skipVisible?: boolean;
	focus?: boolean;
}

export const scrollToElement = <T extends HTMLElement>(
	element: T,
	options?: ScrollToElementOptions
): void => {
	const { skipVisible, focus, ...rest } = defaults({}, options, {
		skipVisible: true,
		focus: false,
		behavior: 'smooth',
		block: 'start'
	});

	if (skipVisible) {
		const isVisible = elementIsVisible(element);
		if (isVisible) return;
	}

	focus && element.focus({ preventScroll: true });
	element.scrollIntoView(rest);
};
