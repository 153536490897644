import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, mixins, ThemeName } from '~/styles/themes';

export const editorStyle = (
	theme,
	options: {
		minEditorHeight: number;
		invalid: boolean;
		readOnly: boolean;
		allowMultiParagraph: boolean;
	}
): SerializedStyles => {
	const { minEditorHeight, invalid, readOnly, allowMultiParagraph } = options;

	return css`
		width: 100%;

		display: flex;
		flex-direction: column;

		background-color: #fff;

		.editor-input {
			min-height: ${minEditorHeight}px;
			padding: 0.5em 0.75em;
			width: 100%;
			height: 100%;
			border: 1px solid #cdcdcd;
			border-top: none;
			outline: none;

			${allowMultiParagraph &&
			css`
				p:not(:last-child) {
					margin-bottom: 1em;
				}
			`}
		}

		.input-wrapper {
			position: relative;
			overflow: hidden; // Hides overflown text on resize
		}

		.editor-paragraph {
			font-size: 16px;
		}

		.editor-text-italic {
			font-style: italic;
		}

		.editor-text-bold {
			font-weight: bold;
		}

		.editor-list-ol {
			padding-left: 0px;
		}

		.editor-listitem {
			margin-left: 1.5em;
		}

		.validation-message {
			order: -1;
			border: none;
			background: ${theme.webtextQuestion.backgroundColor};
		}

		.editor-placeholder {
			color: ${theme.colors.placeholderGray};
			overflow: hidden;
			position: absolute;
			text-overflow: ellipsis;
			top: 7px;
			left: 13px;
			user-select: none;
			display: inline-block;
			pointer-events: none;

			// Editor's right/bottom paddings are not applied to the placeholder
			padding-right: 0.75em;
			padding-bottom: calc(0.5em * 2);
		}

		${invalid && validationStyleOverride}
		${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetOverride(invalid)}

	${readOnly &&
		css`
			&,
			.editor-input,
			.toolbar-container {
				background-color: transparent;
			}
		`}

	.citation {
			display: inline-flex;
			background: #d8d8d8;
			border-radius: 15px;
			padding: 4px 6px;
			user-select: none;
			white-space: nowrap;
			position: relative;
			line-height: 0;
			align-items: center;
			justify-content: center;

			.decorator {
				font-size: 16px;
			}

			.delete-citation {
				background: #9e9e9e;
				outline: none;
				border: none;
				width: 18px;
				height: 18px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-left: 8px;
				color: #fff;
				cursor: pointer;

				svg path {
					stroke: currentColor;
				}
			}
		}

		.turabian {
			padding: 0px 5px;
			display: inline-block;
			margin-bottom: 10px;
			vertical-align: bottom;

			.delete-citation {
				margin-left: 3px;
			}
		}

		.spacer {
			letter-spacing: -2px;
		}

		.citation:hover,
		.citation.selected {
			background: #494949;
			color: #fff;
			cursor: pointer;

			.delete-citation {
				background: #fff;
				color: #000;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			.editor-paragraph,
			.editor-listitem {
				font-size: 15px;
			}
			.editor-placeholder {
				font-size: 15px;
				line-height: 1.5;
				left: 12px;
			}

			.citation-wrapper {
				line-height: 2;

				.citation {
					max-width: 100%;
					line-height: 1;
					padding: 3px 6px;

					svg {
						margin: unset !important;
					}

					.decorator {
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: 90%;
						padding-right: 3px;
					}
				}
			}
		}
	`;
};

export const toolbarStyles = (theme): SerializedStyles => css`
	height: 45px;
	width: 100%;

	display: flex;

	background-color: #fff;
	border: 1px solid #cdcdcd;

	.toolbar-item {
		height: 100%;
		width: 35px;
		background: transparent;
		border: none;
		font-size: 13px;

		* {
			display: block;
		}
		color: #000;
	}

	.italic {
		font-style: italic;
	}

	.bold {
		font-weight: bold;
	}

	.toolbar-item:first-of-type {
		border-radius: 6px 0 0 0;
	}

	// Prevents background getting stuck on the button after blur
	@media (hover: hover) and (pointer: fine) {
		.toolbar-item:hover {
			background: ${theme.webtextQuestion.backgroundColor};
		}
	}

	.active {
		color: ${theme.colors.primary};
		background: ${theme.webtextQuestion.backgroundColor};
	}

	.add-citation-button {
		width: unset;
	}

	.list-button div svg {
		transform: translate(30%);
		max-width: 100%;
		max-height: 100%;
	}
`;

export const accessibleAnnouncerStyles = (theme): SerializedStyles => css`
	${mixins.webtextHiddenAccessible(theme)}
`;

export const validationStyleOverride = css`
	.editor-input,
	.toolbar-container {
		border-left: 3px solid #e70000;
		border-right: 3px solid #e70000;
	}

	.editor-input {
		border-bottom: 3px solid #e70000;
		background-color: #fee6e6;
	}

	.toolbar-container {
		border-top: 3px solid #e70000;
	}
`;

const universalVelvetOverride = (isInvalid: boolean) => css`
	.editor-paragraph {
		font-size: 18px;
	}

	border-radius: 6px;

	.editor-input {
		border-radius: 0 0 6px 6px;
	}

	.toolbar-container {
		border-radius: 6px 6px 0 0;
	}

	.editor-placeholder {
		position: absolute;
		text-overflow: ellipsis;
		top: 7px;
		left: 13px;
		user-select: none;
		display: inline-block;
		pointer-events: none;
		font-size: 18px;
	}

	&:focus-within {
		.editor-input,
		.toolbar-container {
			border-color: #5f01df;
		}

		.toolbar-container {
			border-bottom-color: #cdcdcd;
		}
	}

	${isInvalid && validationUVStylesOverride};

	@media (max-width: ${breakpoints.small}) {
		.editor-placeholder {
			left: 10px !important;
		}

		.list-button div svg {
			transform: translate(30%, 45%) !important;
		}
	}
`;

/**
 * Overriding focus color with !important
 */
const validationUVStylesOverride = css`
	.editor-input,
	.toolbar-container {
		border-left: 1px solid #e70000 !important;
		border-right: 1px solid #e70000 !important;
	}

	.editor-input {
		border-bottom: 1px solid #e70000 !important;
		background-color: #fee6e6;
	}

	.toolbar-container {
		border-top: 1px solid #e70000 !important;
	}
`;
