import React, { useEffect, useRef, useState } from 'react';

import GenericErrorBoundary from '~/components/GenericErrorBoundary';
import Loader from '~/components/Loader';
import Outline from '~/components/Outline';
import { OutlineVariant } from '~/components/Outline/types';
import Button from '~/components/WebtextButton';
import DocumentsPreview from '~/components/WritingTemplate/DocumentPreview';
import NetworkError from '~/components/WritingTemplate/NetworkError';
import { BuilderApiEndpointKey } from '~/components/WritingTemplate/WritingTemplate/utils';
import { useRelativeTimePhrase } from '~/hooks';
import { getValueExistsInEnum } from '~/utils';
import NetError from '~/utils/netErrors';

import Charts from '../Charts';
import { ChartState, ChartType } from '../Charts/types';
import { getFromConfig } from '../EditMode/helpers';
import { invalidText } from '../EditMode/styles';
import Spreadsheet from '../Spreadsheet';
import { onShowGap, onShowHorizontalRule } from '../WritingTemplate/components/utils';
import {
	BuilderJobStatus,
	BuilderUserBackgroundJob,
	IDictionary,
	Kinds,
	LmsData
} from '../WritingTemplate/types';
import WritingTemplateLmsSubmission from '../WritingTemplateLmsSubmission/WritingTemplateLmsSubmission';
import WTPhrase from '../WTPhrase';
import LockMessage from './components/LockMessage';
import styles, {
	buttonWrap,
	footerStyle,
	generatingLoaderStyle,
	spreadsheetWrapper,
	timeStyle,
	userFillsInputsWrapper
} from './styles';
import { copyToClipboard, getOutline, getPara, getTable, getText, listVariants } from './utils';
import { BuilderElementConfig } from '../EditMode/types';

import type { IApi } from '../WritingTemplate/WritingTemplate';

export interface Props {
	builderFamilyId?: string;
	output: any;
	dests?: any;
	imageDestsMap?: { [key: string]: boolean };
	spreadsheetDestsMap?: { [key: string]: boolean };
	outlineDestsMap?: { [key: string]: boolean };
	getImageUrl?: IApi['getImageUrl'];
	draftTimeStamp?: Date;
	onEdit?: () => void;
	saveOnly?: boolean;
	fromConfig?: IDictionary;
	lockedMessage?: string;
	isHideBottom?: boolean;
	shouldFocus?: boolean;
	elementLabel?: string;
	readOnly?: boolean;
	lmsData?: LmsData;
	refTo?: any;
	job?: BuilderUserBackgroundJob;
	setJob?: (x: BuilderUserBackgroundJob) => void;
	generateDocument?: () => Promise<any>;
	onFileExport?: (url: string) => Promise<void>;
	netError?: NetError<BuilderApiEndpointKey>;
	generatedDocumentUrl?: string;
	generatingDocument?: boolean;
	setGeneratingDocument?: (x: boolean) => void;
	pullSchemaPromptProps?: (dest: string) => BuilderElementConfig | undefined;
	onLockingIgnoredSave?: (data: IDictionary) => void;
	showLmsSubmissionWizard?: boolean;
	setShowLmsSubmissionWizard?: (x: boolean) => void;
}

const ViewMode: React.FC<Props> = ({
	builderFamilyId = '',
	dests = {},
	output,
	draftTimeStamp,
	saveOnly,
	imageDestsMap = {},
	fromConfig,
	lockedMessage,
	isHideBottom,
	shouldFocus = false,
	elementLabel,
	readOnly,
	spreadsheetDestsMap = {},
	outlineDestsMap = {},
	getImageUrl,
	onEdit,
	lmsData,
	refTo,
	job,
	setJob,
	generateDocument,
	onFileExport,
	netError,
	generatedDocumentUrl,
	generatingDocument,
	setGeneratingDocument,
	pullSchemaPromptProps,
	onLockingIgnoredSave,
	showLmsSubmissionWizard,
	setShowLmsSubmissionWizard
}) => {
	const [lmsSubmissionCancelledMessage, setLmsSubmissionCancelledMessage] = useState(null);

	const headingRef = useRef<HTMLElement>(null);
	const textRef = useRef<HTMLDivElement>(null);
	const saveTimePhrase = useRelativeTimePhrase(draftTimeStamp);
	const citationList = [];

	const onCopy = () => {
		const htmlText = textRef.current.innerHTML;
		const plainText = textRef.current.innerText;
		copyToClipboard(plainText, htmlText);
	};

	let keyProp = 1;
	const docGen = output.buttons?.pptx || output.buttons?.docx || output.buttons?.xlsx;

	useEffect(() => {
		if (shouldFocus) {
			headingRef.current.focus({ preventScroll: true });
		}
	}, [shouldFocus, refTo]);

	useEffect(() => {
		if (generatedDocumentUrl && !lmsData?.enabled) {
			setGeneratingDocument(false);
			if (!onFileExport) {
				// By default, the browser will show the native downloading dialog
				window.location.href = generatedDocumentUrl;
			} else {
				onFileExport(generatedDocumentUrl);
			}
		}
	}, [generatedDocumentUrl, job, lmsData?.enabled, setGeneratingDocument]);

	const turabianCounter = (citation?: Element) => {
		citationList.push(citation);
		return `${citationList.length}`;
	};

	const renderJSON = (component, listDepth = 0) => {
		if (
			(component.kind === Kinds.Text || component.kind === Kinds.ValueOf) &&
			component?.show === 'compose'
		) {
			return null;
		}

		switch (component.kind) {
			case Kinds.ValueOf: {
				const dest = component.source;
				const { options } = component;

				if (
					(imageDestsMap[dest] || spreadsheetDestsMap[dest] || outlineDestsMap[dest]) &&
					!dests[dest]
				) {
					return '';
				}

				if (imageDestsMap[dest]) {
					const [sourceBuilderId, documentName] = dests[dest]?.split('/') ?? [];
					return (
						<DocumentsPreview
							key={keyProp++}
							documentName={documentName}
							getDocumentUrl={getImageUrl}
							sourceBuilderId={sourceBuilderId}
						/>
					);
				}

				if (spreadsheetDestsMap[dest]) {
					return (
						<div key={dest} css={spreadsheetWrapper}>
							<Spreadsheet dest={dest} sheet={dests[dest].sheet} inputs={dests} readOnly isolated />
						</div>
					);
				}

				if (getValueExistsInEnum(ChartType, dests[dest]?.type)) {
					const schemaProps = pullSchemaPromptProps?.(dest);

					if (schemaProps && schemaProps.kind === 'chart') {
						const { config, source } = schemaProps;

						const handleChange = (chartState: ChartState) => {
							onLockingIgnoredSave({ [dest]: chartState });
						};

						return (
							<Charts
								key={`chart-${dest}`}
								config={config}
								value={dests[dest]}
								chartSource={dests[source]}
								onChange={handleChange}
								output
							/>
						);
					}
				}

				if (outlineDestsMap[dest]) {
					const { hierarchy } = dests[dest];
					return (
						<Outline
							builderFamilyId={builderFamilyId}
							dest={dest}
							config={{ hierarchy }}
							value={dests[dest]}
							variant={OutlineVariant.VIEW}
							readOnly={readOnly}
						/>
					);
				}

				return getText(
					dests[dest] || getFromConfig(dest, fromConfig),
					keyProp++,
					saveOnly,
					options,
					turabianCounter,
					dest?.endsWith('-output')
				);
			}
			case Kinds.FillIn: {
				const { dest } = component;
				return (
					<span key={dest} css={userFillsInputsWrapper}>
						{getText(
							dests[dest] || getFromConfig(dest, fromConfig),
							keyProp++,
							saveOnly,
							[],
							turabianCounter
						)}
					</span>
				);
			}
			case Kinds.Text:
				return getText(component.value, keyProp++, saveOnly, [], turabianCounter);

			case Kinds.HorizontalRule: {
				const { units } = component;
				return onShowHorizontalRule(units);
			}
			case Kinds.Gap: {
				const { units } = component;
				return onShowGap(units);
			}
			case Kinds.Para:
				return getPara(
					component.items.map((el, i, components) => {
						const next = components[i + 1];
						if (next && next.kind === Kinds.Text && el.kind === Kinds.Text) {
							return renderJSON({ ...el, value: `${el.value}<br>` }, listDepth);
						}
						return renderJSON(el, listDepth);
					}),
					keyProp++
				);
			case Kinds.List: {
				const items = component.items.map((el) => renderJSON(el, listDepth + 1));
				return getOutline(listVariants[listDepth % 3], items, keyProp++, true);
			}
			case Kinds.Item: {
				return component.elements.map((el) =>
					el.kind === Kinds.Text && el.show === 'compose' ? null : renderJSON(el, listDepth)
				);
			}
			case Kinds.Table: {
				const rows = component.rows.map((row) =>
					row.map((el) => ({ ...el, value: renderJSON(el, listDepth), init: el }))
				);
				return getTable(component['column-widths'], rows, keyProp++);
			}
		}
	};

	const renderBottom = () => (
		<>
			{(docGen && lockedMessage) || !lockedMessage ? (
				<>
					{onShowHorizontalRule()}
					<div css={footerStyle}>
						{saveTimePhrase ? <div css={timeStyle}>Saved {saveTimePhrase}.</div> : <div />}
						<div css={buttonWrap}>
							{output.buttons.edit && !lockedMessage ? (
								<Button data-ignore="1" disabled={readOnly} onClick={onEdit}>
									{output.buttons.edit}
								</Button>
							) : null}
							{output.buttons.copy && !lockedMessage ? (
								<Button data-ignore="1" disabled={readOnly} onClick={onCopy}>
									{output.buttons.copy}
								</Button>
							) : null}
							{docGen ? (
								lmsData?.enabled ? (
									<Button
										data-ignore="1"
										disabled={readOnly}
										onClick={() => setShowLmsSubmissionWizard(true)}
										aria-label={docGen.label}>
										{docGen.label}
									</Button>
								) : (
									<Button
										data-ignore="1"
										disabled={readOnly || generatingDocument}
										onClick={generateDocument}
										aria-label={generatingDocument ? 'Generating a file' : docGen.label}>
										{docGen.label} <br />
										{generatingDocument && (
											<div css={generatingLoaderStyle}>
												(Generating <Loader />)
											</div>
										)}
									</Button>
								)
							) : null}
						</div>
					</div>
				</>
			) : null}
			{lockedMessage ? <LockMessage text={lockedMessage} /> : null}
		</>
	);

	const onCancelLMS = (message) => {
		if (message) {
			setLmsSubmissionCancelledMessage(message);
		}
		setShowLmsSubmissionWizard(false);
		setJob({ ...job, status: BuilderJobStatus.REVISED });
	};

	return (
		<>
			{saveOnly ? (
				<>{(output.outline || output.text).map((el) => renderJSON(el))}</>
			) : (
				<div css={styles}>
					<WTPhrase ref={headingRef} label={elementLabel} builderFamilyId={builderFamilyId} />
					<GenericErrorBoundary>
						{showLmsSubmissionWizard ? (
							<WritingTemplateLmsSubmission
								lmsData={lmsData}
								onCancelLMS={onCancelLMS}
								generateAndSubmit={generateDocument}
								outputButtons={output.buttons}
								job={job}
							/>
						) : (
							<>
								<div ref={textRef} data-testid={`view-mode-${builderFamilyId}`}>
									{(output.outline || output.text).map((el) => renderJSON(el))}
								</div>
								{netError ? (
									<NetworkError netError={netError} />
								) : lmsSubmissionCancelledMessage ? (
									<div css={invalidText}>{lmsSubmissionCancelledMessage}</div>
								) : null}
								{isHideBottom ? <></> : <>{renderBottom()}</>}
							</>
						)}
					</GenericErrorBoundary>
				</div>
			)}
		</>
	);
};

export default ViewMode;
