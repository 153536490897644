import { useMemo } from 'react';

import { shallow } from 'zustand/shallow';

import {
	getBottomRightCellAddress,
	getCellCoordinates,
	getLeftTopCellAddress
} from '../helpers/selection';
import { useSpreadsheetSelector } from '../store/provider';

export function useExpandSelectionArea(): string {
	const { selectedCells, isTouchScreen } = useSpreadsheetSelector(
		(state) => ({
			selectedCells: state.selectedIndexes,
			isTouchScreen: state.isTouchScreen
		}),
		shallow
	);

	const selectableAreasWidthsStyles = useMemo(() => {
		if (!isTouchScreen || !selectedCells.length) return '';

		const selectionLeftColumn = String.fromCharCode(
			getCellCoordinates(getLeftTopCellAddress(selectedCells)).horizontal
		);
		const selectionRightColumn = String.fromCharCode(
			getCellCoordinates(getBottomRightCellAddress(selectedCells)).horizontal
		);

		/**
		 * Selectable range is increased for edge columns
		 * During the *horizontal selection* a user should intentionally slide over an adjacent column with a bit of an offset.
		 * However, it makes a *vertical selection* unintuitive when the user slides over the edge of a diagonally adjacent column. So for them, we allow the selection range to cover the full width of a cell.
		 */
		const getStyleStringForColumn = (column: string) =>
			`.${column} .selectable { width: 100% !important; }`;

		return `${getStyleStringForColumn(selectionLeftColumn)} ${getStyleStringForColumn(
			selectionRightColumn
		)}`;
	}, [selectedCells, isTouchScreen]);

	return selectableAreasWidthsStyles;
}
