import { useCallback } from 'react';

import { emitCustomEvent } from '~/utils/emitCustomEvent';

import {
	MakePoliteAnnouncementEventType,
	MakeAssertiveAnnouncementEventType
} from './AriaLiveAnnouncer';

/**
 * Convenience hook for making aria-live announcements. Returns two functions,
 * one for making a polite announcement and another for making an assertive one.
 *
 * **This hook will not work** until and unless the `AriaLiveAnnouncer` component has been
 * rendered somewhere on the page.
 */
export function useAriaLiveAnnouncer(): {
	makePoliteAnnouncement: (message: string) => void;
	makeAssertiveAnnouncement: (message: string) => void;
} {
	/**
	 * Make an aria-live="polite" announcement.
	 *
	 * @param {string} message The message to announce.
	 * If `message` contains HTML, it will be stripped, leaving just the text content.
	 */
	const makePoliteAnnouncement = useCallback((message: string) => {
		emitCustomEvent(MakePoliteAnnouncementEventType, message);
	}, []);

	/**
	 * Make an aria-live="assertive" announcement.
	 *
	 * @param {string} message The message to announce.
	 * If `message` contains HTML, it will be stripped, leaving just the text content.
	 */
	const makeAssertiveAnnouncement = useCallback((message: string) => {
		emitCustomEvent(MakeAssertiveAnnouncementEventType, message);
	}, []);

	return {
		makePoliteAnnouncement,
		makeAssertiveAnnouncement
	};
}
