import React from 'react';

import { SerializedStyles, css } from '@emotion/react';
import Tab from '@mui/base/Tab';
import TabPanel from '@mui/base/TabPanel';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';

import { breakpoints } from '~/styles/themes';

interface Props {
	chartComponent: React.ReactNode;
	dataComponent: React.ReactNode;
	onTabChange: (tabValue: string) => void;
	ariaLabel?: string;
}

const TabsUIWrapper: React.FC<Props> = (props) => {
	const { chartComponent, dataComponent, onTabChange, ariaLabel } = props;

	return (
		<Tabs
			defaultValue="chart"
			css={tabsUIStyles}
			onChange={(_, value: string) => onTabChange?.(value)}>
			<TabsList aria-label={ariaLabel}>
				<Tab value="chart">Chart</Tab>
				<Tab value="data">Data</Tab>
			</TabsList>
			<TabPanel value="chart">{chartComponent}</TabPanel>
			<TabPanel value="data">{dataComponent}</TabPanel>
		</Tabs>
	);
};

export default TabsUIWrapper;

const tabsUIStyles = (theme): SerializedStyles => css`
	font-family: ${theme.fonts.app};

	.MuiTab-root {
		font-family: ${theme.fonts.app};
		font-size: 18px;
		padding: 9px;
		color: #000000;
		border: none !important;
		background: transparent;
		width: 80px;
		margin-bottom: -2px;

		&.Mui-selected {
			color: ${theme.colors.primary};
			font-weight: 500;
			border-bottom: 2px solid ${theme.colors.primary} !important;
		}
	}

	.MuiTabsList-root {
		border-bottom: 2px solid ${theme.chart.lineColor};
	}

	@media (max-width: ${breakpoints.small}) {
		.MuiTab-root {
			font-size: 16px;
			padding: 6px;
			width: 70px;
		}
	}
`;
