import { css } from '@emotion/react';

export const rowWidth = '864px';
export const sidebarWidth = '148px';
export const contentWidth = `${864 - 148}px`;

export const elementStyles = css`
	display: flex;
	max-width: ${rowWidth};

	.sidebar {
		text-align: center;
		flex-shrink: 0;
		width: ${sidebarWidth};

		&.open-ended {
			font-size: 12px;
			font-style: italic;
		}

		.icon-correct {
		}
		.icon-incorrect {
		}
	}

	.element-content {
		font-size: 14px;
		max-width: ${contentWidth};

		.element-activity-header {
			font-size: 10px;
			letter-spacing: 0.01px;
			margin-bottom: 4px;
			color: #686868;

			.element-type {
				text-transform: uppercase;
				font-weight: 500;
			}
		}

		.element-body {
			font-size: 17px;
		}

		p {
			margin-bottom: 0;

			&.comfortable {
				margin-bottom: 1rem;
			}
		}

		.element-response {
			font-size: 14px;

			&.completed {
				border-left: 4px solid #5980ff;
				padding-left: 15px;
			}

			li.mc-question-choice {
				display: flex;
				align-items: baseline;
				list-style: none none;
				margin-bottom: 7px;
				padding: 0.25rem 0.5rem;
				max-width: 712px;
				input[type='radio'] {
					width: 17px;
					height: 17px;
					padding: 0;
					flex-shrink: 0;
					top: 0.2em;
					position: relative;
				}
				label {
					padding: 0 0.5em;
					margin: 0;
					margin-left: 8px;
				}
				&.correct-answer {
					border: thin solid #2ca935;
				}
			}
		}

		.reference-answer {
			margin-top: 16px;
			border-left: 4px solid #2ca936;
			padding-left: 8px;
		}

		.error {
			color: #c02027;
			border-left: 4px solid #c02027;
			padding-left: 8px;
			font-style: italic;
		}

		.comment-section {
			margin-left: 0.75rem;
			.instructor-comment {
				color: #c02027;
				font-style: italic;
			}
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}

	.no-answer {
		margin-bottom: 0;
		color: #999;
		font-style: italic;
	}

	b {
		font-weight: bold;
	}
`;
