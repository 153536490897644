import { rgba } from 'polished';

const colors = {
	'pure-white': '#fff',
	'dark-white': '#f1f1f1',
	white: '#fefefe',
	'off-white': '#b3b3b1',
	'light-gray': '#949494',
	'darkened-light-gray': '#7f7f7f',
	gray: '#1f1f1f',
	'border-gray': rgba('#1f1f1f', 0.11),
	'dark-gray': '#121212',
	'less-dark-gray': '#333',
	'medium-gray': '#767676',
	'evil-gray': '#666',
	black: '#000',
	blue: '#047da4',
	'cobalt-blue': '#3762f7',
	orange: 'rgb(220, 150, 0)',
	red: '#ff0000',
	'pleasant-red': '#c02027',
	magenta: '#c711b3',
	green: 'rgb(44, 169, 53)',
	lavender: '#5a80ff',
	beige: '#f1e2c9',
	brown: '#4f3315',
	'light-brown': '#a59595',
	'light-grayish-white': '#ece9e9'
};

const fonts = {
	helvetica: 'Helvetica, Arial, sans-serif',
	'helvetica-neue-default': '"Helvetica Neue", Helvetica, Arial, sans-serif',
	'helvetica-neue-light': '"HelveticaNeueW01-45Ligh", Helvetica, Arial, sans-serif',
	'helvetica-neue-light-italic': '"HelveticaNeueW01-46Ligh", Helvetica, Arial, sans-serif',
	'helvetica-neue': '"HelveticaNeueW01-55Roma", Helvetica, Arial, sans-serif',
	'helvetica-neue-italic': '"HelveticaNeueW01-56It", Helvetica, Arial, sans-serif',
	'helvetica-neue-medium': '"HelveticaNeueW01-65Medi", Helvetica, Arial, sans-serif',
	'helvetica-neue-medium-italic': '"HelveticaNeueW01-66Medi", Helvetica, Arial, sans-serif',
	'helvetica-neue-bold': '"HelveticaNeueW01-75Bold", Helvetica, Arial, sans-serif',
	'helvetica-neue-bold-italic': '"HelveticaNeueW01-76Bold", Helvetica, Arial, sans-serif',

	georgia: 'Georgia, Cambria, "Times New Roman", Times, serif',

	'proxima-nova-bold': '"Proxima N W15 Bold", sans-serif',
	'proxima-nova-light': '"Proxima N W15 Light", sans-serif',

	roboto: '"Roboto", Georgia, Cambria, "Times New Roman", Times, serif',
	oswald: '"Oswald", Helvetica, Arial, sans-serif',
	oswaldRegular: '"OswaldRegular", Helvetica, Arial, sans-serif',

	montserrat: '"Montserrat", sans-serif',
	nunito: '"Nunito Sans", sans-serif',

	times: '"Times New Roman", Times, serif',
	publico: '"Publico", Georgia, "Times New Roman", Times, serif',

	/**
	 * This satisfies a contract with core where headers are keyed per weight
	 * https://github.com/soomo/soomo/blob/master/app/assets/stylesheets/webtexts/blue_velvet/base/_variables.scss
	 * https://github.com/soomo/soomo/blob/master/app/assets/stylesheets/webtexts/universal_velvet/base/_fonts.scss
	 *
	 * Those font faces are defined in the "Velvet Core Theme" font pack
	 * @see FontProvider
	 */
	'haas-grotesk-display': `Neue Haas Grotesk Disp W0595Bl, Helvetica, Arial, sans-serif`,
	'haas-grotesk': `'NeueHaasGroteskText W01', Helvetica, Arial, sans-serif`,
	'haas-grotesk-italic': `'NHaasGroteskTXW01-56It', Helvetica, Arial, sans-serif`,
	'haas-grotesk-medium': `'NHaasGroteskTXW01-65Md', Helvetica, Arial, sans-serif`,
	'haas-grotesk-medium-italic': `'NHaasGroteskTXW01-66MdI', Helvetica, Arial, sans-serif`,
	'haas-grotesk-bold': `'NHaasGroteskTXW01-75Bd', Helvetica, Arial, sans-serif`,
	'haas-grotesk-bold-italic': `'NHaasGroteskTXW01-76BdI', Helvetica, Arial, sans-serif`,

	'haas-grotesk-all': `'Neue Haas Grotesk', Helvetica, Arial, sans-serif` // all Haas Grotesk in one
};

const fontWeights = {
	thin: 100,
	light: 300,
	regular: 400,
	medium: 500,
	'semi-bold': 600,
	bold: 700
};

const breakpointValues = {
	extraSmall: 400,
	small: 650,
	medium: 768,
	large: 1230
} as const;

const breakpoints = {
	extraSmall: `${breakpointValues.extraSmall}px`,
	small: `${breakpointValues.small}px`,
	medium: `${breakpointValues.medium}px`,
	large: `${breakpointValues.large}px`
} as const;

export default {
	colors,
	fonts,
	fontWeights,
	breakpoints,
	breakpointValues
};
