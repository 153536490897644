import React from 'react';

import cn from 'classnames';

import offlineStyles from './styles';
import SvgOffline from './SvgOffline';

export interface Props {
	elementName?: string;
	interactionVerb?: string; // e.g. "You must be online to [Stream|View|etc]"
	contentDescription?: string; // e.g. "HQ Principles Post-Test"
	standalone?: boolean; // Controls padding
	/** @deprecated No longer used for anything; left in for now to avoid breaking typechecking in other apps. */
	mobile?: boolean;
}

export const Offline: React.VFC<Props> = (props) => {
	const { interactionVerb, elementName, contentDescription, standalone } = props;

	const text =
		!interactionVerb && !elementName && contentDescription ? (
			<p dangerouslySetInnerHTML={{ __html: contentDescription }} />
		) : (
			<>
				{elementName && (
					<div className="offline-unavailable">
						<span className="capitalized">{elementName}</span> unavailable.
					</div>
				)}
				{interactionVerb && (
					<div className="offline-you-must">You must be online to {interactionVerb}.</div>
				)}
				{contentDescription && (
					<div
						className="resource-title"
						dangerouslySetInnerHTML={{ __html: contentDescription }}
					/>
				)}
			</>
		);

	return (
		<div className="offline-message" css={offlineStyles}>
			<div
				className={cn({
					container: true,
					standalone: standalone == null || standalone === true,
					addendum: standalone === false
				})}>
				<div className="offline-icon">
					<SvgOffline />
				</div>
				<div>{text}</div>
			</div>
		</div>
	);
};

export default Offline;
