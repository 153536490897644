import { css, SerializedStyles } from '@emotion/react';

import { OutlineVariant, TemplateLabelType } from '~/components/Outline/types';
import { breakpoints } from '~/styles/themes';

export const label = (options: {
	labelType: TemplateLabelType;
	variant: OutlineVariant;
	disableOutputLabel: boolean;
}): SerializedStyles => {
	const { labelType, variant, disableOutputLabel } = options;

	const labelBlockLineHeight = css`
		line-height: inherit;
	`;

	const labelInlineLineHeight = css`
		line-height: 1; // Helps to vertically align text field and the label
	`;

	return css`
		grid-area: label;
		font-weight: ${variant === OutlineVariant.REVISE ? 500 : 700};

		${disableOutputLabel
			? labelInlineLineHeight
			: css`
					${labelType === 'block' ? labelBlockLineHeight : labelInlineLineHeight};
					@media (max-width: ${breakpoints.small}) {
						${labelBlockLineHeight}; // On small screens all labels are displayed as block
					}
			  `}
	`;
};

export const labelNumber = css`
	display: inline-block;
	width: var(--indentation);
`;
