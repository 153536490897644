import * as React from 'react';

export function ShoppingCart(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle cx={36} cy={36} r={36} fill="currentColor" />
			<circle
				cx={32.69}
				cy={48.82}
				r={4.03}
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M44.21 44.93a4 4 0 0 0-4 4 3.93 3.93 0 0 0 4 4 4 4 0 0 0 4-4 3.94 3.94 0 0 0-4-4ZM51 40.75H30.38l-.38-2.3h17a1.3 1.3 0 0 0 1.16-.72l4-10.23a1.16 1.16 0 0 0-.72-1.58c-.15 0-.29-.14-.43-.14H27.79l-1-5.62a1.21 1.21 0 0 0-1.15-1H21a1.14 1.14 0 0 0-1.15 1.15A1.25 1.25 0 0 0 21 21.46h3.6l3.46 20.59a1.23 1.23 0 0 0 1.15 1H51a1.14 1.14 0 0 0 1.15-1.16c.12-.56-.45-1.14-1.15-1.14Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
