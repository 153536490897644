import React, { ReactNode, useEffect, useState } from 'react';

import SelectionArea, { SelectionEvent } from '@viselect/react/lib/viselect.esm.es5';
import { first } from 'lodash-es';
import isEqual from 'lodash-es/isEqual';
import { shallow } from 'zustand/shallow';

import { IElementContext } from '../../context/ElementsContext';
import { createFormulaTemporaryValueFromRange } from '../../helpers/compute';
import { dragHandleDataKey } from '../../helpers/constants';
import {
	getBottomRightCellAddress,
	getLeftTopCellAddress,
	getUpdatedSelectionState,
	normalizeRange
} from '../../helpers/selection';
import { DragHandlePosition, SelectionState } from '../../helpers/types';
import { useSpreadsheetSelector } from '../../store/provider';
import { tableWrapper } from '../Grid/styles';

interface Props {
	dest: string;
	children: (resetCachedSelection: IElementContext['resetCachedSelection']) => ReactNode;
}

const MouseSelection: React.FC<Props> = ({ dest, children }) => {
	const [selectionState, setSelectionState] = useState<SelectionState>({
		selection: new Set(),
		leftMostTopMostCellAddress: null,
		rightMostBottomMostCellAddress: null,
		activeHandle: null,
		anchorCellAddress: null
	});

	const {
		selectedIndexes,
		selectedCell,
		formulaInputMethod,
		contextMenu,
		isEditing,
		setSelectedIndexes,
		setSelectedCell,
		updateSelectedIndexes,
		pushFormulaValue,
		isTouchScreen,
		updateTouchMode,
		updateContextProps
	} = useSpreadsheetSelector(
		(state) => ({
			selectedIndexes: state.selectedIndexes,
			selectedCell: state.selectedCell,
			formulaInputMethod: state.formulaInputMethod,
			contextMenu: state.contextMenuProps,
			isEditing: Boolean(state.editingCell),
			setSelectedCell: state.setSelectedCell,
			setSelectedIndexes: state.setSelectedCells,
			updateSelectedIndexes: state.updateSelectedIndexes,
			pushFormulaValue: state.pushFormulaSelectedValue,
			isTouchScreen: state.isTouchScreen,
			updateTouchMode: state.updateTouchMode,
			updateContextProps: state.updateContextProps
		}),
		shallow
	);

	useEffect(() => {
		if (contextMenu || isTouchScreen) return;

		if (!isEditing) {
			updateSelections(Array.from(selectionState.selection));
			return;
		}

		if (formulaInputMethod === 'selection') {
			const selections = Array.from(selectionState.selection);

			if (selections.length > 1) {
				pushFormulaValue(createFormulaTemporaryValueFromRange(selections));
			}
		}

		function updateSelections(value: string[]) {
			if (isEqual(value, selectedIndexes)) return;

			if (!value.includes(selectedCell) && value.length > 0) {
				setSelectedCell(value[0]);
			}
			setSelectedIndexes(value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectionState, pushFormulaValue, setSelectedIndexes, setSelectedCell]);

	useEffect(() => {
		if (isEditing || formulaInputMethod === 'selection' || !isTouchScreen) return;
		if (isEqual(selectedIndexes, Array.from(selectionState.selection))) return;
		setSelectedIndexes(normalizeRange(Array.from(selectionState.selection)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectionState, formulaInputMethod, setSelectedIndexes, isTouchScreen]);

	useEffect(() => {
		const firstSelectedElement = selectedIndexes[0];

		if (selectedIndexes.length === 1 && isTouchScreen && selectedCell === firstSelectedElement) {
			setSelectionState({
				leftMostTopMostCellAddress: firstSelectedElement,
				rightMostBottomMostCellAddress: firstSelectedElement,
				selection: new Set(selectedIndexes),
				activeHandle: null
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTouchScreen, selectedCell]);

	/**
	 * Check whether we have to enable selection or not
	 * On touch screens we have to enable selection only when we click on drag handle
	 * On desktop we have to enable selection only when we click on cursor
	 */
	const handleBeforeStart = ({ event }: SelectionEvent) => {
		if (isTouchScreen) {
			if ((event.target as HTMLElement)?.getAttribute('data-key') === dragHandleDataKey) {
				const elementId = (event.target as HTMLElement)?.getAttribute('id');
				if (!elementId) return false;

				const handlePosition = elementId.split('-')[0] as DragHandlePosition;

				setSelectionState((state) => {
					const leftMostTopMostCellAddress = getLeftTopCellAddress(Array.from(state.selection));
					const rightMostBottomMostCellAddress = getBottomRightCellAddress(
						Array.from(state.selection)
					);

					return {
						...state,
						leftMostTopMostCellAddress,
						rightMostBottomMostCellAddress,
						activeHandle: handlePosition,
						anchorCellAddress:
							handlePosition === 'top' ? rightMostBottomMostCellAddress : leftMostTopMostCellAddress
					};
				});

				return true;
			}
			return false;
		} else {
			// Inspired by https://github.com/simonwep/selection/blob/master/packages/vanilla/recipes.md#preventing-select-from-right-click-middle-mouse-or-left-click-101
			const mouseEvent = event as MouseEvent;
			const isLeftButtonClick = [1].includes(mouseEvent.buttons) && !event.shiftKey;
			isLeftButtonClick && setSelectionState((state) => ({ ...state, selection: new Set() }));
			return !!document?.getElementById(`cursor-${dest}`) && isLeftButtonClick;
		}
	};

	/**
	 * When selection starts we have to clear previous selection, but only on desktop
	 * On touch screens we will calculate selection only when user will stop selection (in different handler)
	 */
	const handleStart = ({ event, selection }: SelectionEvent) => {
		if (isTouchScreen) return;

		if (event.type !== 'mouseup') {
			selection.clearSelection();
			setSelectionState((state) => ({ ...state, selection: new Set() }));
		}
	};

	const extractIds = (els: Element[]): string[] =>
		els.map((v) => v.getAttribute('data-key')).filter(Boolean);

	const handleMove = (event: SelectionEvent) => {
		const {
			store: {
				changed: { added, removed }
			},
			selection: selectionEvent
		} = event;

		if (!selectionEvent) return;
		if (!isTouchScreen) {
			return setSelectionState((state) => {
				const { selection } = state;
				return {
					...state,
					selection: updateSelectionState(selection)
				};
			});
		}

		updateTouchMode('select');
		setSelectionState((state) => {
			const { leftMostTopMostCellAddress, rightMostBottomMostCellAddress, activeHandle } = state;
			const addedCells = extractIds(added);
			const removedCells = extractIds(removed);

			if (!activeHandle || (!addedCells.length && !removedCells.length)) return state;

			return getUpdatedSelectionState({
				addedCells,
				removedCells,
				selectionState: state,
				event,
				...(activeHandle === 'top'
					? {
							currentEdgeAddress: leftMostTopMostCellAddress,
							oppositeEdgeAddress: rightMostBottomMostCellAddress
					  }
					: {
							currentEdgeAddress: rightMostBottomMostCellAddress,
							oppositeEdgeAddress: leftMostTopMostCellAddress
					  })
			});
		});

		function updateSelectionState(prevSelection: Set<string>): Set<string> {
			const next = new Set(prevSelection);
			extractIds(added).forEach((id) => next.add(id));
			extractIds(removed).forEach((id) => next.delete(id));

			return next;
		}
	};

	const handleStop = ({ selection, event }: SelectionEvent) => {
		updateSelectedIndexes(normalizeRange);
		if (isTouchScreen) {
			updateTouchMode('scroll');
			setSelectionState((state) => ({
				...state,
				activeHandle: null,
				disableResetting: null
			}));
			selection.clearSelection();

			const changedTouches = (event as TouchEvent).changedTouches;
			const touch = changedTouches[0];

			if (!touch) return;
			const { clientX, clientY } = touch;
			updateContextProps({ mouseX: clientX, mouseY: clientY, accessBy: 'touch' });
		}
	};

	const resetCachedSelection = (addresses: string[]) =>
		setSelectionState({
			leftMostTopMostCellAddress: first(addresses),
			rightMostBottomMostCellAddress: first(addresses),
			selection: new Set(addresses),
			activeHandle: null
		});

	return (
		<SelectionArea
			onBeforeStart={handleBeforeStart}
			css={tableWrapper}
			className="container mouse-selection-area"
			onStart={handleStart}
			onMove={handleMove}
			onStop={handleStop}
			selectables=".selectable">
			{children(resetCachedSelection)}
		</SelectionArea>
	);
};

export default MouseSelection;
