import { css, SerializedStyles } from '@emotion/react';

export const style = (isEmpty: boolean): SerializedStyles => css`
	background: inherit;
	user-select: none;
	text-align: center;

	${isEmpty && 'background: inherit; border: none! important;'};

	&.header {
		left: -1px;
		position: sticky;
		z-index: 1;
		background: #f7f7f7;
		box-shadow:
			0 0 0 1px #dad4d4,
			inset 1px 0 0 0.1px #dad4d4;
		pointer-events: none;
		touch-action: none;
	}

	&.cHeader {
		box-shadow:
			0 0 0 0.1px #dad4d4,
			inset 0 0px 0 1px #dad4d4;
	}

	${isEmpty && 'background: inherit; box-shadow: unset! important;'};

	div {
		min-width: 30px;
		min-height: 30px;
		// When rendered in inline blocks mode, this div become inline and ruins the layout, so we force it to be block
		display: block !important;
	}
`;
