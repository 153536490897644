import { css, SerializedStyles } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

import { element } from '../../styles';

export const tabButton = (selected: boolean): SerializedStyles => css`
	text-transform: none !important;
	min-width: 140px !important;
	border: 1px solid #d8d6d6 !important;
	color: black !important;
	font-size: 16px !important;
	${selected && 'font-weight: bold !important;'}
	background-color: ${selected ? '#FFF' : '#E0E0E0'} !important;
	border-bottom: ${selected ? 'none' : '#dad4d4'} !important;
	border-radius: 5px 5px 0 0 !important;
	font-family: 'HelveticaNeueW01-55Roma', Helvetica, Arial, sans-serif !important;
	opacity: 1 !important;
`;

export const tabControls = css`
	background: inherit;
	position: relative;
`;

export const chartContainer = css`
	${element}
	&, * {
		box-sizing: border-box;
	}
`;

export const resetButton = css`
	position: absolute;
	top: 0;
	right: 0;
	font-size: 18px;
	z-index: 100;

	@media (max-width: ${breakpoints.small}) {
		width: unset; // Overrides 'webtextQuestionButtons' styles
	}

	@media (max-width: 450px) {
		top: 40px;
		right: 25px;
	}
`;
