import React, { FC } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import Loader, { LoaderProps } from '~/components/Loader';
import { Theme } from '~/styles/themes';

interface Props extends LoaderProps {
	dimmed?: boolean;
}

const OverlayLoader: FC<Props> = (props) => {
	const { dimmed = true, ...loaderProps } = props;

	return <Loader css={(theme) => styles(theme, { dimmed })} {...loaderProps} />;
};

const styles = (theme: Theme, options: { dimmed: boolean }): SerializedStyles => {
	const { dimmed } = options;

	const {
		colors: { 'dark-white': darkWhite }
	} = theme;

	return css`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;

		::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: ${dimmed ? darkWhite : 'transparent'};
			opacity: 0.65;
		}
	`;
};

export default OverlayLoader;
