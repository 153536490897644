import { createContext } from 'react';

import type { UserRegistry } from '~/types';

const missingUserRegistry: UserRegistry = {
	get: () => {
		throw new Error('UserRegistry.get called, but no UserRegistry provided');
	},
	set: () => {
		throw new Error('UserRegistry.set called, but no UserRegistry provided');
	},
	touch: () => {
		throw new Error('UserRegistry.touch called, but no UserRegistry provided');
	},
	increment: () => {
		throw new Error('UserRegistry.increment called, but no UserRegistry provided');
	},
	delete: () => {
		throw new Error('UserRegistry.delete called, but no UserRegistry provided');
	}
};

const UserRegistryContext = createContext(window.Sm?.userRegistry ?? missingUserRegistry);

export default UserRegistryContext;
