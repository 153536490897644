import React from 'react';

import { useTheme } from '@emotion/react';

import { useIsUniversalVelvet } from '~/hooks';
import { Theme } from '~/styles/themes';
import { ArtifactType } from '~/types';

import * as Icons from './icons';

const mapTypeToVariantForBlueVelvetOnly = {
	article: 'link',
	video: 'play',
	audio: 'headphones',
	download: 'download'
};

interface Props {
	type: string;
}

const ArtifactIcon: React.FC<Props> = (props) => {
	const { type } = props;
	const theme = useTheme();
	const isUniversalVelvet = useIsUniversalVelvet();

	let SvgIcon;
	let variant;

	if (isUniversalVelvet) {
		switch (type as ArtifactType) {
			case 'article':
			case 'video':
			case 'audio':
				SvgIcon = Icons.LinkIcon;
				break;
			case 'download':
				SvgIcon = Icons.DownloadIcon;
				break;
			case 'docxdownload':
				SvgIcon = Icons.DocxDownloadIcon;
				break;
			case 'pdfdownload':
				SvgIcon = Icons.PdfDownloadIcon;
				break;
			case 'xlsxdownload':
				SvgIcon = Icons.XlsxDownloadIcon;
				break;
			default:
				throw new Error(`Unknown artifact type = "${type}"`);
		}
	} else {
		switch (type as ArtifactType) {
			case 'article':
			case 'video':
			case 'audio':
			case 'download':
				variant = mapTypeToVariantForBlueVelvetOnly[type as ArtifactType];
				SvgIcon = Icons.ComputerIcon;
				break;
			case 'docxdownload':
				SvgIcon = Icons.DocxDownloadIcon;
				break;
			case 'pdfdownload':
				SvgIcon = Icons.PdfDownloadIcon;
				break;
			case 'xlsxdownload':
				SvgIcon = Icons.XlsxDownloadIcon;
				break;
			default:
				throw new Error(`Unknown artifact type = "${type}"`);
		}
	}

	return <SvgIcon color={theme.artifactLink.icon.color} variant={variant} />;
};

export default ArtifactIcon;
