import React from 'react';

import isNaN from 'lodash-es/isNaN';

import { isNumeric } from '~/utils/parsing';

import { formatValue } from '../../helpers';
import { isAccountingNegative, transformAccounting } from '../../helpers/formula';
import { getStartCell } from '../../helpers/selection';
import {
	CellFormatting,
	FormatType,
	CellValues,
	CellsRowHeight,
	EditableRanges,
	CellsRefs
} from '../../helpers/types';

export const showFormatting = (isEditing: boolean, value?: string): boolean =>
	!!(!isEditing && value && value !== ' ' && value.length);

export const getNeighbor = (address: string): string => {
	const { horizontal, vertical } = getStartCell([address]);
	return `${String.fromCharCode(horizontal + 1)}${vertical}`;
};

export const isFreeNeighbor = (
	address: string,
	elements: { [key: string]: React.MutableRefObject<HTMLTableCellElement> }
): boolean => {
	const neighbor = getNeighbor(address);
	return !!elements[neighbor];
};

export const expandArea = (
	address: string,
	expanded: string[],
	elementsRefs: {
		[key: string]: React.MutableRefObject<HTMLTableCellElement>;
	},
	isArea?: boolean
): string[] => {
	const isNeighborAvailable = (adr: string) => {
		if (isArea && isFreeNeighbor(adr, elementsRefs)) {
			return true;
		}

		if (!isArea && isFreeNeighbor(adr, elementsRefs) && !elementsRefs[getNeighbor(address)]) {
			return true;
		}

		return false;
	};

	if (expanded.length === 0) {
		if (isNeighborAvailable(address)) {
			return [address, getNeighbor(address)];
		}

		return expanded;
	} else {
		const lastExpand = expanded.slice(-1)[0];
		if (isNeighborAvailable(lastExpand)) {
			return [...expanded, getNeighbor(lastExpand)];
		}

		return expanded;
	}
};

export const getIsOverflow = (element: HTMLDivElement | HTMLTextAreaElement): boolean =>
	element &&
	(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);

export const transformFinalInputValue = (text: string, formatting: CellFormatting): string => {
	if (formatting?.type === FormatType.Accounting) {
		if (text.endsWith('$')) {
			const value = text.replace(/\$+$/, '');
			return isNaN(+value) ? text : value;
		}

		if (text.startsWith('$')) {
			const value = text.replace(/^\$+/, '');
			return isNaN(+value) ? text : value;
		}
	}

	if (formatting?.type === FormatType.Percent) {
		const value = +text;
		return !isNaN(value) && (value <= -1 || value >= 1) ? `${text}%` : text;
	}

	return text;
};

export const isInsideExtFormula = (formulaRanges: number[][], caret: number): boolean =>
	formulaRanges.some(([fs, fe]) => caret >= fs && caret <= fe);
export const getRangeWithCaret = (formulaRanges: number[][], caret: number): number[][] =>
	formulaRanges.filter(([fs, fe]) => caret >= fs && caret <= fe);

export const getCaretExceptSpaceLeft = (value: string, caret: number): string => {
	if (caret - 1 < 0) return ' ';

	const caretValue = value[caret - 1];
	if (!caretValue) return ' ';
	if (caretValue === ' ') return getCaretExceptSpaceLeft(value, caret - 1);

	return caretValue;
};

export const isNextAccept = (value: string, caret: number, extraSymbols = []): boolean => {
	if (caret >= value.length) return true;

	const caretValue = value[caret];
	const acceptableSymbols = ['=', '+', '-', '*', '/'].concat(extraSymbols);

	if (caretValue === ' ') return isNextAccept(value, caret + 1);

	return !caretValue || acceptableSymbols.includes(caretValue);
};

// TODO This must be documented
export const getFormattedValue = (value: any, cellFormatting: CellFormatting): string => {
	const { type, accuracy } = cellFormatting;
	if (isNumeric(value) && (type || accuracy)) {
		return formatValue(value, cellFormatting);
	}

	if (isAccountingNegative(value, cellFormatting)) {
		return formatValue(transformAccounting(value), cellFormatting);
	}

	if (typeof value === 'string' && value.endsWith('%') && isNumeric(value.slice(0, -1))) {
		const percent = parseFloat(value) / 100;
		return formatValue(percent, cellFormatting);
	}

	return value;
};

/**
 * __Cell overflow utils__
 */
export const getAvailableNeighbors = (props: {
	address: string;
	cellsRefs: CellsRefs;
	editableRanges: EditableRanges;
	cellValues: CellValues;
	neighbors: string[];
}): string[] => {
	const { address, cellsRefs, editableRanges, neighbors, cellValues } = props;

	const neighborAddress = getNeighbor(address);

	if (!cellsRefs[neighborAddress] || cellValues[neighborAddress]) {
		return neighbors;
	}

	const isEditable = getIsEditable(editableRanges, address);
	const isEditableNeighbor = getIsEditable(editableRanges, neighborAddress);

	if (!isEditable && isEditableNeighbor) {
		return neighbors;
	}

	return getAvailableNeighbors({
		address: neighborAddress,
		cellsRefs,
		editableRanges,
		cellValues,
		neighbors: [...neighbors, neighborAddress]
	});
};

const getIsEditable = (editableRanges: EditableRanges, address: string) =>
	Boolean(editableRanges[address]);

export const getIsWidthOverflow = (
	elem: React.MutableRefObject<HTMLDivElement>,
	parent: React.MutableRefObject<HTMLDivElement>,
	evaluatedValue: string
): boolean => {
	const element = elem.current?.cloneNode() as Element;

	if (element) {
		element.textContent = evaluatedValue;
		element.classList.add('overflow');
		element.removeAttribute('data-placeholder-alignment');
		parent.current.appendChild(element);

		const isOverflow = (element as HTMLDivElement).offsetWidth > elem.current?.offsetWidth;

		element.remove();

		return isOverflow;
	}

	return false;
};

export const getIsExpandOverflow = (
	element: React.MutableRefObject<HTMLDivElement>,
	parent: React.MutableRefObject<HTMLDivElement>,
	evaluatedValue: string,
	width: number
): boolean => {
	const cloneElement = element.current?.cloneNode() as Element;

	if (!element) return false;

	cloneElement.textContent = evaluatedValue;
	cloneElement.classList.add('overflow');
	cloneElement.removeAttribute('data-placeholder-alignment');
	parent.current.appendChild(cloneElement);

	const isOverflowExpand = width < (cloneElement as HTMLDivElement).offsetWidth;

	cloneElement.remove();

	return isOverflowExpand;
};

export const getWidthFromNeighbors = (props: {
	neighbors: string[];
	width: number;
	elements: {
		[key: string]: React.MutableRefObject<HTMLTableCellElement>;
	};
	cellElementRef: React.MutableRefObject<HTMLDivElement>;
	cellContainerRef: React.MutableRefObject<HTMLTableCellElement>;
	evaluatedValue: string;
}): number => {
	const { neighbors, elements, cellElementRef, cellContainerRef, evaluatedValue } = props;
	let { width } = props;

	if (!neighbors.length) return width;

	const [neighbor] = neighbors;
	const neighborElement = elements[neighbor];

	if (!neighborElement.current) return width;

	const { offsetWidth } = neighborElement.current;

	const isExpandOverflow = getIsExpandOverflow(
		cellElementRef,
		cellContainerRef,
		evaluatedValue,
		width + offsetWidth
	);

	return isExpandOverflow
		? getWidthFromNeighbors({
				neighbors: neighbors.slice(1),
				width: (width += offsetWidth),
				elements,
				cellElementRef,
				cellContainerRef,
				evaluatedValue
		  })
		: width + offsetWidth;
};

export const getCellsRowHeight = (props: {
	cellElement: HTMLDivElement;
	cellContainer: HTMLTableCellElement;
	address: string;
	formattedValue: string;
}): CellsRowHeight => {
	const { cellElement, cellContainer, address, formattedValue } = props;

	const element = cellElement?.cloneNode() as HTMLDivElement;

	if (!element) return {};

	const [, , horizontal] = /([A-Za-z]+)([0-9]+)/gi.exec(address);

	element.textContent = String(formattedValue);
	element.style.height = 'unset';
	element.style['white-space'] = 'normal';
	element.classList.remove(`row${horizontal}`);
	cellContainer?.appendChild(element);

	const elemHeight = element.offsetHeight;
	element.remove();

	return {
		[address]: {
			rowName: horizontal,
			height: Math.max(elemHeight, 30)
		}
	};
};
