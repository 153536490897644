import React, { FC, useMemo } from 'react';

import { sum } from 'lodash-es';
import round from 'lodash-es/round';

import { convertToPercentageStr } from '~/utils';

import type { OrderedClassData } from '../../hooks';

interface Props {
	orderedClassData: OrderedClassData;
	classTotal: number;
	classmatesRespondedMessage: string;
}

const ClassOnlyTable: FC<Props> = (props) => {
	const { orderedClassData, classTotal, classmatesRespondedMessage } = props;

	/**
	 * Calculate the sum of the percentages of each choice. Using the convert to string method so the
	 * total lines up with how the percentages are being show in the table.
	 */
	const percentSum = useMemo(() => {
		const choicesPercentages = orderedClassData.map((choice) =>
			parseFloat(convertToPercentageStr(choice.data, classTotal))
		);
		return round(sum(choicesPercentages), 1);
	}, [classTotal, orderedClassData]);

	return (
		<div className="poll-data-table-overflow">
			<table>
				<thead>
					<tr>
						<th scope="col">Choice</th>
						<th scope="col">Responses</th>
						<th scope="col">Percentage</th>
					</tr>
				</thead>
				<tbody>
					{orderedClassData.map((choice) => (
						<tr key={choice.label}>
							<th scope="row" dangerouslySetInnerHTML={{ __html: choice.label }} />
							<td>{choice.data.toLocaleString('en-US')}</td>
							<td>{convertToPercentageStr(choice.data, classTotal)}</td>
						</tr>
					))}
					<tr>
						<th scope="row" aria-label={'Total responses ' + classmatesRespondedMessage}>
							Total responses
							<div className="poll-data-classmates-message">{classmatesRespondedMessage}</div>
						</th>
						<td className="poll-class-only-class-total">{classTotal.toLocaleString('en-US')}</td>
						<td>{percentSum}%</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default ClassOnlyTable;
