import { useContext, useCallback, useRef } from 'react';

import UserRegistryContext from '~/components/UserRegistryContext';
import { useCustomEventListener } from '~/hooks';
import { FamilyId } from '~/types/WebtextManifest';
import { emitCustomEvent } from '~/utils/emitCustomEvent';

import {
	interventions,
	Intervention,
	MAX_PRESENTATIONS_PER_CHAPTER,
	MAX_PRESENTATIONS_PER_COURSE,
	MAX_PRESENTATIONS_PER_DAY
} from './constants';
import { chapterLimitKey, courseLimitKey, dayLimitKey } from './userRegistryKeys';

const PRESENTATION_LIMITS_UPDATED_EVENT_TYPE = 'usePresentationLimits/updated';

interface PresentationLimitsUpdatedEventData {
	courseLimits: Record<Intervention, number>;
	chapterLimits: Record<Intervention, number>;
	dayLimits: Record<Intervention, number>;
}

export default function usePresentationLimits({
	isSpeedbumpEnabled,
	courseId,
	chapterFamilyId
}: {
	isSpeedbumpEnabled: boolean;
	courseId: number;
	chapterFamilyId: FamilyId;
}): {
	incrementLimit: (intervention: Intervention) => void;
	isLimitReached: (intervention: Intervention) => boolean;
} {
	const userRegistry = useContext(UserRegistryContext);

	/**
	 * Gets the initial limit value for the provided presentation limit type.
	 * If Speedbump is disabled, do not fetch from `UserRegistryContext` and instead return zeroes.
	 * This avoids throwing errors in apps like Self-Serve that have no reason to supply a `UserRegistry`.
	 */
	const getInitialLimitValue: (
		keyFn: typeof courseLimitKey | typeof chapterLimitKey | typeof dayLimitKey
	) => Record<Intervention, number> = useCallback(
		(keyFn) => {
			if (!isSpeedbumpEnabled) {
				return Object.fromEntries(interventions.map((i) => [i, 0])) as Record<Intervention, number>;
			}

			return Object.fromEntries(
				interventions.map((i) => {
					const rawKeyValue = userRegistry.get(
						keyFn({ courseId, chapterFamilyId, intervention: i }),
						{
							localOnly: true
						}
					);
					const parsedKeyValue = rawKeyValue ? parseInt(rawKeyValue, 10) : 0;
					return [i, parsedKeyValue];
				})
			) as Record<Intervention, number>;
		},
		[chapterFamilyId, courseId, isSpeedbumpEnabled, userRegistry]
	);

	const coursePresentationLimits = useRef(getInitialLimitValue(courseLimitKey));
	const chapterPresentationLimits = useRef(getInitialLimitValue(chapterLimitKey));
	const dayPresentationLimits = useRef(getInitialLimitValue(dayLimitKey));

	const presentationLimitsUpdatedEventListener = useCallback(
		(data: PresentationLimitsUpdatedEventData) => {
			const { courseLimits, chapterLimits, dayLimits } = data;
			coursePresentationLimits.current = courseLimits;
			chapterPresentationLimits.current = chapterLimits;
			dayPresentationLimits.current = dayLimits;
		},
		[]
	);

	useCustomEventListener<PresentationLimitsUpdatedEventData>(
		PRESENTATION_LIMITS_UPDATED_EVENT_TYPE,
		presentationLimitsUpdatedEventListener
	);

	const isLimitReached = useCallback(
		(intervention: Intervention): boolean => {
			return (
				coursePresentationLimits.current[intervention] >= MAX_PRESENTATIONS_PER_COURSE ||
				chapterPresentationLimits.current[intervention] >= MAX_PRESENTATIONS_PER_CHAPTER ||
				dayPresentationLimits.current[intervention] >= MAX_PRESENTATIONS_PER_DAY
			);
		},
		[chapterPresentationLimits, coursePresentationLimits, dayPresentationLimits]
	);

	const incrementLimit = useCallback(
		(intervention: Intervention): void => {
			coursePresentationLimits.current[intervention] += 1;
			userRegistry.set(
				courseLimitKey({ courseId, intervention }),
				`${coursePresentationLimits.current[intervention]}`
			);
			chapterPresentationLimits.current[intervention] += 1;
			userRegistry.set(
				chapterLimitKey({ courseId, chapterFamilyId, intervention }),
				`${chapterPresentationLimits.current[intervention]}`
			);
			dayPresentationLimits.current[intervention] += 1;
			userRegistry.set(
				dayLimitKey({ courseId, intervention }),
				`${dayPresentationLimits.current[intervention]}`
			);

			emitCustomEvent<PresentationLimitsUpdatedEventData>(PRESENTATION_LIMITS_UPDATED_EVENT_TYPE, {
				courseLimits: coursePresentationLimits.current,
				chapterLimits: chapterPresentationLimits.current,
				dayLimits: dayPresentationLimits.current
			});
		},
		[chapterFamilyId, courseId, userRegistry]
	);

	return { incrementLimit, isLimitReached };
}
