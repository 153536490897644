import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import isEqual from 'lodash-es/isEqual';
import { shallow } from 'zustand/shallow';

import { getOutlineConfigWithDefault } from '~/components/Outline/helpers';
import {
	OutlineProvider,
	OutlineState,
	selectOutlineId,
	useOutlineSelector,
	useOutlineStore
} from '~/components/Outline/store';
import { OutlineConfig, OutlineResponsesState, OutlineVariant } from '~/components/Outline/types';

import ActionToast from './components/ActionToast';
import OutlineA11YHandlersContainer from './components/OutlineA11YHandlersContainer';
import SectionsView from './components/SectionsView';

export interface Props {
	builderFamilyId: string;
	dest: string;
	source?: string;
	config: OutlineConfig;
	value?: OutlineResponsesState;
	tabUpdateValue?: OutlineResponsesState;
	variant: OutlineVariant;
	readOnly?: boolean;
	onChange?: (responses: OutlineResponsesState) => void;
	onUserUpdate?: () => void;
}

const startAddress = [];

const Outline: FC<Props> = (props) => {
	const { variant, readOnly, onChange } = props;

	const outlineStore = useOutlineStore();
	const outlineId = useOutlineSelector(selectOutlineId);

	useEffect(() => outlineStore.setState({ variant, readOnly }), [variant, readOnly, outlineStore]);

	useEffect(() => {
		if (onChange) {
			return outlineStore.subscribe(
				(state) => ({
					templates: state.templates,
					hierarchy: state.hierarchy,
					numberedItemsStyle: state.numberedItemsStyle,
					hierarchyResponses: state.hierarchyResponses,
					templatesConstraints: state.templatesConstraints,
					validations: state.validations
				}),
				(onChangeState) => onChange(onChangeState),
				{ fireImmediately: true, equalityFn: shallow }
			);
		}
	}, [onChange, outlineStore]);

	const ariaLabel = 'Outlining Tool';
	const outlineContent = (
		<>
			<SectionsView address={startAddress} />
			<ActionToast />
			<OutlineA11YHandlersContainer />
		</>
	);

	return variant === OutlineVariant.EDIT ? (
		<form id={outlineId} aria-label={ariaLabel} onSubmit={(event) => event.preventDefault()}>
			{outlineContent}
		</form>
	) : (
		<div id={outlineId} role="complementary" aria-label={ariaLabel}>
			{outlineContent}
		</div>
	);
};

const OutlineContainer: FC<Props> = (props) => {
	const {
		builderFamilyId,
		dest,
		source,
		config,
		value,
		tabUpdateValue,
		variant,
		readOnly,
		onChange,
		onUserUpdate
	} = props;

	const { current: memoizedConfig } = useRef(config);
	const { current: memoizedOnChange } = useRef(onChange);

	/**
	 * The value can be changed when the new tab update is received
	 * It will trigger the global re-render of the outline component with the latest values
	 */
	const [memoizedValue, setMemoizedValue] = useState(value);
	const lastTabUpdateValueRef = useRef(tabUpdateValue);
	useEffect(() => {
		const { current: lastTabUpdateValue } = lastTabUpdateValueRef;
		if (!isEqual(tabUpdateValue, lastTabUpdateValue)) {
			setMemoizedValue(tabUpdateValue);
			lastTabUpdateValueRef.current = tabUpdateValue;
		}
	}, [tabUpdateValue]);

	/**
	 * Prevents rerenders of the `Outline` when the parent `Builder` is getting re-rendered
	 * https://gist.github.com/slikts/e224b924612d53c1b61f359cfb962c06
	 */
	const memoizedOutline = useMemo(
		() => (
			<Outline
				{...props}
				config={memoizedConfig}
				value={memoizedValue}
				onChange={memoizedOnChange}
			/>
		),
		[
			builderFamilyId,
			dest,
			source,
			memoizedConfig,
			memoizedValue,
			memoizedOnChange,
			variant,
			readOnly
		]
	);

	/**
	 * Initializes state before the first render of the Outline
	 * Using `useMemo` to avoid inline object recreation - re-rendering issue
	 */
	const outlineProviderInitialState = useMemo<OutlineState>(() => {
		const defaultedConfig = getOutlineConfigWithDefault(memoizedConfig, memoizedValue);

		return {
			...defaultedConfig,
			builderFamilyId,
			dest,
			hierarchyResponses: memoizedValue.hierarchyResponses || [],
			variant,
			readOnly: readOnly ?? false,
			userActions: [],
			showUserActionToast: false,
			openedMenuId: null,
			onUserUpdate
		};
	}, [builderFamilyId, dest, memoizedConfig, memoizedValue, variant, readOnly]);

	return <OutlineProvider {...outlineProviderInitialState}>{memoizedOutline}</OutlineProvider>;
};

export default OutlineContainer;
