import React from 'react';

const GoReactIcon: React.VFC<React.HTMLAttributes<SVGElement>> = (props) => (
	<svg className="goreact-icon" width={25} height={25} viewBox="0 0 25 25" aria-hidden {...props}>
		<g
			id="Go-React---Check-my-work-plus-feedback"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd">
			<g
				id="Question-Type-Treatment---Width-Control---Expanded---Internal-instructions"
				transform="translate(-276.000000, -1203.000000)"
				fill="#5F01DF"
				fillRule="nonzero">
				<g
					id="material---slideshow_FILL0_wght400_GRAD0_opsz48"
					transform="translate(276.000000, 1203.000000)">
					<path
						d="M9.27083333,18.3680556 L18.3333333,12.5 L9.27083333,6.63194444 L9.27083333,18.3680556 Z M2.08333333,25 C1.52777778,25 1.04166667,24.7916667 0.625,24.375 C0.208333333,23.9583333 0,23.4722222 0,22.9166667 L0,2.08333333 C0,1.52777778 0.208333333,1.04166667 0.625,0.625 C1.04166667,0.208333333 1.52777778,0 2.08333333,0 L22.9166667,0 C23.4722222,0 23.9583333,0.208333333 24.375,0.625 C24.7916667,1.04166667 25,1.52777778 25,2.08333333 L25,22.9166667 C25,23.4722222 24.7916667,23.9583333 24.375,24.375 C23.9583333,24.7916667 23.4722222,25 22.9166667,25 L2.08333333,25 Z M2.08333333,22.9166667 L22.9166667,22.9166667 L22.9166667,2.08333333 L2.08333333,2.08333333 L2.08333333,22.9166667 Z M2.08333333,2.08333333 L2.08333333,22.9166667 L2.08333333,2.08333333 Z"
						id="Shape"></path>
				</g>
			</g>
		</g>
	</svg>
);

export default GoReactIcon;
