import isNil from 'lodash-es/isNil';

import { createCoordinatesFromArray, getIsFormula } from '.';
import { IDictionary } from './../../WritingTemplate/types';
import { Graph } from './../Graph';
import { cellSourceRegex, templateSourceRegex } from './constants';
import { getFormulaValue } from './formula';
import { formulaParser } from './formula/engine';
import {
	getEndCell,
	getHeightValues,
	getStartCell,
	getStartPosition,
	getWidthValues
} from './selection';
import { Box, CellsRefs, CellValues, EditableRanges, EvaluatedCells } from './types';

export const getCellValue = (props: {
	cellConfig: {
		value?: string;
		source?: string;
		evaluateFormula?: boolean; // Whether the imported formula should be executed in context of the imported ST
	};
	address: string;
	inputs: IDictionary;
	isolated: boolean;
	editableCoordinates: EditableRanges;
	storedValues: CellValues;
	sourcePriority?: boolean;
	evaluatedSpreadsheetSourcesCache?: Record<string, EvaluatedCells>;
	updateEvaluatedSourcesCache?: (source: string, evaluatedSources: EvaluatedCells) => void;
}): string => {
	const {
		cellConfig,
		inputs,
		isolated,
		editableCoordinates,
		address,
		storedValues,
		sourcePriority,
		evaluatedSpreadsheetSourcesCache,
		updateEvaluatedSourcesCache
	} = props;
	const { value: cellValue, source: cellSource, evaluateFormula } = cellConfig;

	let localCellValue = storedValues?.[address];

	/**
	 * If the cellValue hasn't been defined yet,
	 * it's either should be imported from the source,
	 * or initialized with the cell's value from the schema
	 *
	 * source priority is used when we want to get live update received from another component
	 */
	if (isNil(localCellValue) || sourcePriority) {
		localCellValue = cellSource ? getSourceValue(cellSource) : cellValue;
		return localCellValue || '';
	}

	/**
	 * In the view mode we show only the already existing cell values. The only
	 * exception is when we need to force-evaluate a formula to ensure we have
	 * the latest value.
	 */
	if (isolated) {
		if (cellSource && cellConfig.evaluateFormula) {
			return getSourceValue(cellSource);
		} else {
			return localCellValue;
		}
	}

	/**
	 * The local cell's value should be overridden by the source value only if it's not editable.
	 * It prevents the lost of the users' custom values.
	 */
	if (cellSource && !editableCoordinates[address]) {
		localCellValue = getSourceValue(cellSource);
		if (localCellValue) return localCellValue;
	}

	/**
	 * The local cell's value should be overridden by the schema value only if it's not editable.
	 * It prevents the lost of the users' custom values.
	 */
	if (cellValue && !editableCoordinates[address]) {
		return cellValue;
	}

	return localCellValue;

	function getSourceValue(source: string): string {
		let sourceValue: string;
		switch (getSourceType()) {
			case 'template-source':
				sourceValue = getTemplateSourceValue();
				break;
			case 'cell-source':
				sourceValue = getCellSourceValue();
				break;
			default:
				sourceValue = inputs[source];
		}
		return sourceValue || '';

		function getSourceType(): 'source' | 'cell-source' | 'template-source' {
			if (templateSourceRegex.test(source)) {
				return 'template-source';
			}
			if (cellSourceRegex.test(source)) {
				return 'cell-source';
			}
			return 'source';
		}

		function getCellSourceValue() {
			const [spreadsheetDest, sourceCellAddress] = source.split(':');

			const spreadsheet = inputs[spreadsheetDest];
			if (!spreadsheet) return '';

			const { cellValues: sourceCellsValues } = spreadsheet;
			const sourceCellValue = sourceCellsValues?.[sourceCellAddress];

			/**
			 * The imported value is another cell, but it doesn't contain a formula
			 * and it can be processed immediately
			 */
			const isFormula = getIsFormula(sourceCellValue);
			if (!isFormula || !evaluateFormula) {
				return sourceCellValue;
			}

			/**
			 * The imported formulas need to be evaluated to prevent copying of the cells to the current ST (T-36489)
			 * We evaluate them here, because on this stage we have access to all the cells of the imported ST
			 */

			const unformattedFormula = getSourceFormulaResult({
				source: spreadsheetDest,
				sourceCellAddress,
				sourceCellsValues,
				evaluatedSpreadsheetSourcesCache,
				updateEvaluatedSourcesCache
			});

			return unformattedFormula;
		}

		function getTemplateSourceValue() {
			const templateSourceGlobalRegex = new RegExp(templateSourceRegex, 'g');
			const sourcesTemplatesMatches = source.matchAll(templateSourceGlobalRegex);

			/**
			 * Creates object `{ "{destA}": destA, "{destB}": destB }`
			 * and also removes the repeated templates
			 */
			const sourcesTemplates = Object.fromEntries(sourcesTemplatesMatches);

			/**
			 * Creates object `{ "{destA}": resolvedDestA, "{destB}": resolvedDestB }`
			 */
			const resolvedSourcesTemplates = Object.entries(sourcesTemplates).reduce(
				(resolvedSources, [template, templateSource]) => ({
					...resolvedSources,
					[template]: getSourceValue(templateSource as string)
				}),
				{}
			);

			const isFormula = getIsFormula(source);
			const isEverySourceResolved = Object.values(resolvedSourcesTemplates).every(Boolean);
			if (isFormula && !isEverySourceResolved) return '';

			/**
			 * Replaces the templates with the resolved sources values
			 */
			return Object.entries(resolvedSourcesTemplates).reduce(
				(templateValue, [template, resolvedSource]) =>
					templateValue.replace(template, resolvedSource as string),
				source
			);
		}
	}
};

export const evaluateCellValuesSlice = (props: {
	addressesToEvaluate: string[];
	cellValues: CellValues;
	graph: Graph;
	evaluatedValues?: EvaluatedCells;
}): EvaluatedCells => {
	const { addressesToEvaluate, cellValues, graph, evaluatedValues } = props;

	const tempEvaluatedValues: EvaluatedCells = {};

	/**
	 * Build dependencies stack
	 */
	const addressesUpdateStack = graph.getSortedCellsDeps(addressesToEvaluate);

	/**
	 * Walk over the sorted list and run formula/rewrite formula result
	 */
	addressesUpdateStack.forEach((address) => {
		const cellValue = cellValues[address];
		if (!getIsFormula(cellValue)) {
			tempEvaluatedValues[address] = null;
			return;
		}

		const formulaResult = formulaParser({
			inputValue: cellValue,
			address: address,
			cellValues: cellValues,
			evaluatedCells: { ...evaluatedValues, ...tempEvaluatedValues },
			graph
		});

		tempEvaluatedValues[address] = formulaResult;
	});

	return tempEvaluatedValues;
};

/**
 * This function builds a value to replace the actual value inside an input with formula
 * When user selects a cell it's [cellAddress], and it's OK for formula argument B1 (for example).
 * When user selects a range it's [cellAddress, cellAddress, cellAddress, cellAddress], and it must be converted into B1:C2 (for example).
 * The value itself is temporary and once formula in the input receives it will be no longer needed.
 */
export const createFormulaTemporaryValueFromRange = (range: string[]): string => {
	if (!range || range.length < 1) return '';

	if (range.length === 1) return range[0];

	const coords = createCoordinatesFromArray(range);
	if (!coords) return '';

	return coords;
};

export const createRangeDimensions = (
	range: string[],
	cellsRefs: CellsRefs,
	withHeader: boolean
): Box => {
	const startCell = getStartCell(range);
	const endCell = getEndCell(range);

	const startPosition = getStartPosition(
		`${String.fromCharCode(startCell.horizontal)}${startCell.vertical}`,
		cellsRefs,
		withHeader
	);

	const width = getWidthValues(
		endCell.horizontal - startCell.horizontal + 1,
		cellsRefs,
		startCell.horizontal,
		startCell.vertical - 1
	);

	const height = getHeightValues(
		endCell.vertical - startCell.vertical + 1,
		cellsRefs,
		startCell.horizontal,
		startCell.vertical
	);

	return {
		top: startPosition.top,
		left: startPosition.left,
		width,
		height
	};
};

/**
 * This function return the stored cache value from previous iteration if exists
 * Otherwise it will evaluate the formula and store it in cache after that return the result
 * In both cases we make a minimal transformation to the result
 */
const getSourceFormulaResult = (props: {
	source: string;
	sourceCellAddress: string;
	sourceCellsValues: CellValues;
	evaluatedSpreadsheetSourcesCache: Record<string, EvaluatedCells>;
	updateEvaluatedSourcesCache: (source: string, evaluatedSources: EvaluatedCells) => void;
}) => {
	const {
		source,
		sourceCellAddress,
		sourceCellsValues,
		evaluatedSpreadsheetSourcesCache,
		updateEvaluatedSourcesCache
	} = props;

	const sourceEvaluatedCells = evaluatedSpreadsheetSourcesCache?.[source];

	/**
	 * If the source has been already evaluated, we can use the cached value
	 */
	if (sourceEvaluatedCells) {
		const [unformattedFormula] = getFormulaValue({
			cellValue: sourceCellsValues[sourceCellAddress],
			formulaResult: sourceEvaluatedCells[sourceCellAddress]
		});

		return unformattedFormula;
	}

	/**
	 * If the source hasn't been evaluated yet, we need to evaluate it
	 * build a graph once
	 */
	const sourceGraph = new Graph();
	Object.entries(sourceCellsValues).forEach(([address, value]) =>
		sourceGraph.addVertex(address, String(value))
	);

	/**
	 * Evaluate all sources in the spreadsheet
	 */
	const evaluatedSourcesFormulas = evaluateCellValuesSlice({
		addressesToEvaluate: Object.keys(sourceCellsValues),
		cellValues: sourceCellsValues,
		graph: sourceGraph
	});

	/**
	 * Update the cache (store the evaluated values)
	 */
	updateEvaluatedSourcesCache?.(source, evaluatedSourcesFormulas);

	/**
	 * Return the evaluated value
	 */
	const [unformattedFormula] = getFormulaValue({
		cellValue: sourceCellsValues[sourceCellAddress],
		formulaResult: evaluatedSourcesFormulas[sourceCellAddress]
	});

	return unformattedFormula;
};
