const grid = `
	<path fill="#ffffff" transform="translate(-5, -5) scale(0.02)" d="M0 0h566.88v566.88H0z"/>
	<path transform="translate(-10, -10) scale(0.038)" d="M120.48 399.24h325.92v47.28H120.48zM120.48 120.36h325.92v47.28H120.48zM120.48 259.8h325.92v47.28H120.48z"/>
	<path transform="translate(-10, -10) scale(0.038)" d="M399.24 120.48h47.28v325.92h-47.28zM120.36 120.48h47.28v325.92h-47.28zM259.8 120.48h47.28v325.92H259.8z"/>
`;

const crescent = `
	<path d="M448.56,80.52c-182.39,0 -330.24,90.85 -330.24,202.92
	s147.85,202.92 330.24,202.92
	C266.17,402.31 229.21,243.32 366,131.25
	c23.47-19.23 51.27-36.31 82.56-50.73"
	transform="translate(-10, -10) scale(0.035)"/>
`;

const curve = `
	<path transform="translate(-10, -10) scale(0.035)" d="M280.12 63.425c135.44 49.3 227.92 136.81 206.57 195.47-21.35 58.66-148.45 66.26-283.89 16.96 151.45 5.3 209.18-67.93 128.96-163.57-13.77-16.41-31.16-32.86-51.65-48.87z"/>
	<path transform="translate(-10, -10) scale(0.035)" d="M286.76 503.46C151.32 454.16 58.835 366.65 80.186 307.99c21.354-58.66 148.45-66.25 283.884-16.96-151.45-5.3-209.18 67.93-128.96 163.57 13.77 16.405 31.16 32.859 51.65 48.865z"/>
`;

const circle = `
	<path d="M5.642,0A5.642,5.642,0,1,1,-5.642,0A5.642,5.642,0,1,1,5.642,0"></path>
`;

const circleEmpty = `
	<path fill="white" stroke-width="4" d="M5.642,0A5.642,5.642,0,1,1,-5.642,0A5.642,5.642,0,1,1,5.642,0"></path>
`;

const cross = `
	<path d="M-6.708,-2.236L-2.236,-2.236L-2.236,-6.708L2.236,-6.708L2.236,-2.236L6.708,-2.236L6.708,2.236L2.236,2.236L2.236,6.708L-2.236,6.708L-2.236,2.236L-6.708,2.236Z"></path>
`;

const diamond = `
	<path transform="scale(0.8)" d="M0,-9.306L5.373,0L0,9.306L-5.373,0Z"></path>
`;

const squareEmpty = `
	<path fill="white" stroke-width="2" d="M-5,-5h10v10h-10Z"></path>
`;

const star = `
	<path transform="scale(0.8)" d="M0,-9.438L2.119,-2.917L8.976,-2.917L3.429,1.114L5.548,7.636L0,3.605L-5.548,7.636L-3.429,1.114L-8.976,-2.917L-2.119,-2.917Z"></path>
`;

const triangleEmpty = `
	<path fill="white" stroke-width="2" transform="scale(0.8)" d="M0,-8.774L7.598,4.387L-7.598,4.387Z"></path>
`;

const wye = `
	<path d="M2.699,1.558L2.699,6.955L-2.699,6.955L-2.699,1.558L-7.373,-1.141L-4.674,-5.815L0,-3.116L4.674,-5.815L7.373,-1.141Z"></path>
`;

const times = `
	<path stroke-width="2" d="M-6.136,-6.136L6.136,6.136M-6.136,6.136L6.136,-6.136"></path>
`;

export const a11yShapes = [
	grid,
	crescent,
	circleEmpty,
	cross,
	circle,
	curve,
	diamond,
	squareEmpty,
	star,
	triangleEmpty,
	wye,
	times
];
