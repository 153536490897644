import React, { FC } from 'react';

import { css } from '@emotion/react';

import PollElementWrapper from '~/components/pageElements/PollQuestion/PollElementWrapper';
import { PollResultsAliasProps } from '~/components/pageElements/PollQuestion/types';
import PollResultsAliasView from '~/components/pageElements/PollResultsAlias/PollResultsAliasView';
import { QuestionPrompt } from '~/components/shared/Question';

const PollResultsAlias: FC<PollResultsAliasProps> = (props) => {
	const { body, answer, noBottomMargin, tabsUi } = props.targetPollResults;

	return (
		<PollElementWrapper tabsUi={tabsUi} noBottomMargin={noBottomMargin}>
			<QuestionPrompt css={questionPromptStyles({ answered: !!answer })} body={body} />
			<div className="question">
				<PollResultsAliasView {...props} />
			</div>
		</PollElementWrapper>
	);
};

const questionPromptStyles = (options: { answered: boolean }) =>
	!options.answered
		? css`
				padding-bottom: 0;
				.question-body {
					margin-bottom: 15px !important; // Required to override Core styles
				}
		  `
		: null;

export default PollResultsAlias;
