import React from 'react';

import { css, withTheme } from '@emotion/react';

import { Theme, Padding, breakpoints, ThemeName } from '~/styles/themes';

const styles = (theme, padding) => {
	const { popupQuiz } = theme;

	return css`
		font-family: ${popupQuiz.view.fontFamily};
		font-size: 16px;
		color: ${theme.colors.text};
		line-height: 1.2em;
		padding: ${padding || '30px'};

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			font-size: 18px;
			line-height: 1.5em;
			padding: ${padding || '8px 30px 30px 30px'};

			@media (max-width: ${breakpoints.small}) {
				font-size: ${popupQuiz.view.fontSize};
				line-height: 1.47;

				padding: ${padding || '20px 16px 16px 16px'};
			}
		`}
	`;
};

interface Props {
	theme: Theme;
	padding?: Padding;
	children: React.ReactNode;
}

const PopupQuizView = (props: Props) => {
	const { theme, padding, children } = props;

	return <div css={styles(theme, padding)}>{children}</div>;
};

export default withTheme(PopupQuizView);
