import { css, SerializedStyles } from '@emotion/react';

export default (props: {
	left: number;
	top: number;
	width: number;
	height: number;
	compensateHeader: boolean;
	highlightMode: string;
}): SerializedStyles => {
	const { left, top, width, height, compensateHeader, highlightMode } = props;

	return css`
		position: absolute;
		box-sizing: border-box;

		left: ${left - 1}px;
		top: ${top - 1}px;
		width: ${width + 1}px;
		// compensate 1px in selection height, when headers are hidden
		height: ${compensateHeader ? height + 1 : height}px;

		z-index: ${highlightMode === 'active' ? 100 : 10};

		background: ${backgroundStyle(highlightMode)};
		border: ${borderStyle(highlightMode)};

		pointer-events: none;
		touch-action: none;

		transition: all 0.1s ease-in-out;
	`;
};

const borderStyle = (highlightMode: string): string => {
	switch (highlightMode) {
		case 'active':
			return '1px solid #3579f8';
		case 'persisted':
			return '2px dashed #3579f8';
		case 'invalid':
			return '2px solid #e5442e';
	}
};

const backgroundStyle = (highlightMode: string): string => {
	switch (highlightMode) {
		case 'active':
			return 'rgba(53, 121, 248, 0.35)';
		case 'invalid':
			return 'rgba(229, 68, 46, 0.15)';
		default:
			return null;
	}
};
