import useMediaQuery from '@material-ui/core/useMediaQuery';

import { breakpoints } from '~/styles/themes';

export const useMediaBreakpoint = (
	breakpoint: keyof typeof breakpoints,
	query: 'min-width' | 'max-width' = 'min-width'
): boolean => {
	const breakpointWidth = breakpoints[breakpoint];
	return useMediaQuery(`(${query}:${breakpointWidth})`);
};
