import * as React from 'react';

export function SocialConnections(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={32.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.762833777925626px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={28.73} fill="currentColor" />
			<path
				d="M53.88 40.1a3.83 3.83 0 0 0-1.47 1.21l-10.82-3.92a5.15 5.15 0 0 0-.09-2.07l5-2.32a3.85 3.85 0 1 0-.91-1.95l-5 2.33a5.35 5.35 0 0 0-1.53-1.47L43 21.17a3.83 3.83 0 1 0-2-.73l-4 10.75a5.34 5.34 0 0 0-2.07.09l-2.33-5a3.84 3.84 0 1 0-2 .91l2.33 5a5.5 5.5 0 0 0-1.38 1.54l-10.79-3.92a3.9 3.9 0 1 0-.76 2l10.83 3.93a5.6 5.6 0 0 0 .09 2.08l-2.12 1a3.84 3.84 0 1 0 .91 2l2.13-1a5.5 5.5 0 0 0 1.54 1.38L29.45 52a3.83 3.83 0 1 0 2 .73l3.95-10.82a5.16 5.16 0 0 0 2.07-.09l1.08 2.3a3.83 3.83 0 1 0 2-.91l-1.08-2.29a5.59 5.59 0 0 0 1.44-1.51l10.82 3.93a3.8 3.8 0 1 0 2.2-3.24Zm-5.24-11.2a1.68 1.68 0 1 1-.8 2.25 1.68 1.68 0 0 1 .81-2.23Zm-22 14a1.68 1.68 0 1 1 .81-2.24 1.68 1.68 0 0 1-.81 2.24Zm15-26.13a1.68 1.68 0 1 1 1 2.16 1.7 1.7 0 0 1-1.06-2.16Zm-13 7.36a1.68 1.68 0 0 1 .81-2.24 1.68 1.68 0 1 1-.81 2.24Zm6.29 9.45a3.29 3.29 0 1 1-1.7 4.42 3.28 3.28 0 0 1 1.58-4.37Zm-18.6-2.42a1.69 1.69 0 1 1 2.16-1 1.7 1.7 0 0 1-2.16 1Zm14.44 25.28a1.68 1.68 0 0 1-3.23-1 1.07 1.07 0 0 1 .07-.19 1.63 1.63 0 0 1 .86-.94 1.67 1.67 0 0 1 2.3 2.07Zm11.85-10.12a1.68 1.68 0 1 1-2.24-.81 1.68 1.68 0 0 1 2.23.8Zm14.47-2.18a1.69 1.69 0 1 1-.09-1.27 1.63 1.63 0 0 1 0 1.28Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
