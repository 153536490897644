import { useState, MutableRefObject, useLayoutEffect } from 'react';

interface UseClickAndDragProps {
	scrollContainerRef: MutableRefObject<HTMLDivElement>;
	isScrollable: boolean;
}

const useClickAndDrag = (props: UseClickAndDragProps) => {
	const { scrollContainerRef, isScrollable } = props;

	const [isDragging, setIsDragging] = useState(false);

	const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });
	const [initialScrollPosition, setInitialScrollPosition] = useState({ top: 0, left: 0 });

	useLayoutEffect(() => {
		const { current: scrollContainer } = scrollContainerRef;
		if (!scrollContainer || !isScrollable) return;

		const handleMouseDown = (e: MouseEvent) => {
			setIsDragging(true);

			const { offsetLeft, offsetTop, scrollLeft, scrollTop } = scrollContainer;
			setDragStartPosition({ x: e.pageX - offsetLeft, y: e.pageY - offsetTop });
			setInitialScrollPosition({ top: scrollTop, left: scrollLeft });
		};

		const handleMouseMove = (e: MouseEvent) => {
			if (!isDragging) return;
			e.preventDefault();

			const { offsetLeft, offsetTop } = scrollContainer;
			const distanceX = e.pageX - offsetLeft - dragStartPosition.x;
			const distanceY = e.pageY - offsetTop - dragStartPosition.y;
			scrollContainer.scrollLeft = initialScrollPosition.left - distanceX;
			scrollContainer.scrollTop = initialScrollPosition.top - distanceY;
		};

		const handleMouseUp = () => setIsDragging(false);

		scrollContainer.addEventListener('mousedown', handleMouseDown);
		scrollContainer.addEventListener('mousemove', handleMouseMove);
		scrollContainer.addEventListener('mouseup', handleMouseUp);

		return () => {
			scrollContainer.removeEventListener('mousedown', handleMouseDown);
			scrollContainer.removeEventListener('mousemove', handleMouseMove);
			scrollContainer.removeEventListener('mouseup', handleMouseUp);
		};
	}, [scrollContainerRef, isDragging, initialScrollPosition, dragStartPosition, isScrollable]);

	return { isDragging };
};

export default useClickAndDrag;
