import { lighten, darken, transparentize } from 'polished';

import constants from '../base/constants';

import type { Theme } from '..';

const colors = {
	...constants.colors,
	primary: constants.colors.blue,
	secondary: '#f7f7f7',
	text: '#333332',
	accent: 'transparent',
	link: constants.colors.blue,
	success: '#227e0c',
	warning: '#e41c0c',
	placeholderGray: '#707070'
};

const layout = {
	contentColumnWidth: '700px',
	contentPadding: '0.25em'
};

const fonts = {
	...constants.fonts,
	app: constants.fonts['helvetica-neue'],

	webtext: constants.fonts.georgia,
	'webtext-question-body': constants.fonts['helvetica-neue-medium'],
	'webtext-question-answer': constants.fonts['helvetica-neue-default'],
	'webtext-question': constants.fonts['helvetica-neue'],
	'webtext-question-buttons': constants.fonts['helvetica-neue-default'],
	'webtext-heading-font-family': constants.fonts['helvetica-neue-light'],
	'webtext-question-rejoinder-correctness': constants.fonts['helvetica-neue-medium'],
	serif: constants.fonts['georgia'],
	'sans-serif': constants.fonts['helvetica-neue']
};

const ally = {
	borderColor: '#1300ff',
	backgroundColor: '#1300ff',
	highlightColor: '#1de5e3'
};

const themeGlobal = {
	headerFontSize: '40px',
	webtextObjective: {
		fontFamily: constants.fonts['helvetica-neue-medium']
	},
	webtextPageInstruction: {
		padding: '10px 20px',
		backgroundColor: (theme) => theme.colors.primary,
		fontFamily: constants.fonts['helvetica-neue-medium'],
		fontWeight: 'initial',
		fontSize: '15.6px',
		lineHeight: undefined
	},
	webtextGlossaryTermLocations: {
		fontFamily: constants.fonts['helvetica-neue-medium']
	},
	webtextInvestigationObjective: {
		color: (theme) => theme.colors.primary,
		fontFamily: (theme) => theme.fonts.app,
		fontSize: '18px'
	},
	webtextArticleSource: {
		fontFamily: (theme) => theme.fonts.app,
		borderLeft: (theme) => `8px solid ${theme.colors.primary}`
	},
	iconColor: (theme) => theme.colors.primary
};

const offline = {
	backgroundColor: (theme) => theme.colors.secondary
};

const webtextPage = {
	title: {
		fontSize: '35px',
		color: (theme) => theme.colors.primary,
		textTransform: 'none'
	},
	headings: {
		fontWeight: 300,
		color: (theme) => theme.colors.primary,
		textTransform: 'none',
		lineHeight: '115%'
	},
	h1: {
		fontFamily: (theme) => theme.fonts['webtext-heading-font-family'],
		fontSize: '40px',
		lineHeight: '115%'
	},
	h2: {
		fontFamily: (theme) => theme.fonts['webtext-heading-font-family'],
		fontSize: '30px',
		lineHeight: '115%'
	},
	h3: {
		fontFamily: (theme) => theme.fonts['webtext-heading-font-family'],
		fontSize: '25px',
		lineHeight: '115%'
	},
	h4: {
		fontFamily: (theme) => theme.fonts['webtext-heading-font-family'],
		fontSize: '22px',
		lineHeight: '115%'
	},
	h5: {
		fontFamily: (theme) => theme.fonts['webtext-heading-font-family'],
		fontSize: '18px',
		lineHeight: '115%'
	},
	p: {
		fontSize: '19px'
	},
	li: {
		fontSize: '19px'
	},
	strong: {
		color: 'inherit',
		fontWeight: 'bold'
	},
	blockquote: {
		fontFamily: (theme) => theme.fonts.webtext,
		fontWeight: 400,
		fontStyle: 'italic',
		fontSize: '18px',
		lineHeight: 1.5
	},
	a: {
		fontWeight: 'initial'
	}
};

const element = {
	title: {
		fontFamily: constants.fonts['helvetica-neue-medium'],
		fontSize: '18px',
		fontWeight: false,
		textTransform: false,
		color: constants.colors['gray'],
		margin: '0 0 20px'
	}
};

const webtextQuestionButtons = {
	fontFamily: (theme) => theme.fonts.app,
	fontSize: '16px'
};

const webtextCheckbox = {
	fontFamily: constants.fonts['helvetica-neue-default'],
	fontSize: '15px'
};

const loader = {
	colors: {
		primary: (theme) => theme.colors.primary
	}
};

const chapterSplash = {
	title: {
		fontFamily: (theme) => theme.fonts['webtext-heading-font-family'],
		fontWeight: 'initial',
		fontSize: 'inherit',
		textTransform: 'none',
		number: {
			fontFamily: (theme) => theme.chapterSplash.title.fontFamily,
			fontWeight: (theme) => theme.chapterSplash.title.fontWeight,
			fontSize: (theme) => theme.chapterSplash.title.fontSize,
			textTransform: (theme) => theme.chapterSplash.title.textTransform,
			letterSpacing: 'normal'
		},
		name: {
			fontFamily: (theme) => theme.chapterSplash.title.fontFamily,
			fontWeight: (theme) => theme.chapterSplash.title.fontWeight,
			fontSize: (theme) => theme.chapterSplash.title.fontSize,
			textTransform: (theme) => theme.chapterSplash.title.textTransform
		}
	},
	credit: {
		color: '#fbfbfb',
		fontStyle: 'italic',
		fontSize: '12px'
	},
	separatorColor: (theme) => theme.colors['pure-white']
};

const voiceRecording = {
	colors: {
		primary: (theme) => theme.colors.primary,
		secondary: (theme) => theme.colors.secondary,
		warning: (theme) => theme.colors.warning
	},
	fontFamily: (theme) => theme.fonts.app,
	fontSize: '16px',
	heading: {
		fontFamily: constants.fonts['helvetica-neue-light'],
		fontWeight: constants.fontWeights.light
	}
};
const lmsSubmission = voiceRecording;
const popupQuiz = {
	colors: {
		primary: (theme) => theme.colors.primary,
		secondary: (theme) => theme.colors.secondary,
		warning: '#ff3020'
	},
	fontFamily: (theme) => theme.fonts.app,
	fontSize: '16px',
	header: {
		fontFamily: false
	},
	view: {
		fontFamily: constants.fonts['helvetica']
	},
	heading: {
		fontFamily: constants.fonts['helvetica-neue-light'],
		fontWeight: constants.fontWeights.light
	},
	instructions: {
		fontWeight: constants.fontWeights.bold
	},
	questionBody: {
		fontWeight: 700
	},
	choice: {
		background: '#cccccc'
	},
	choiceText: {
		fontWeight: 400
	},
	choiceSelected: {
		background: '#d0eaee'
	},
	choiceSpanSelected: {
		background: '#218bb0'
	},
	progress: {
		completedBackground: '#1f83a8',
		incompleteBackground: (theme) => theme.colors['light-gray']
	},
	interpretation: {
		questionNumber: {
			fontWeight: 700
		}
	}
};

const modal = {
	colors: {
		primary: (theme) => theme.colors.primary,
		secondary: (theme) => theme.colors.secondary,
		warning: '#ff3020',
		text: '#c7cacc',
		secondaryText: '#b0b3b5',
		background: '#292c2f'
	},
	fontFamily: (theme) => theme.fonts.app,
	fontSize: '16px',
	header: {
		fontFamily: false
	}
};

const bigQuoteEpigraph = {
	borderColor: (theme) => theme.colors.primary,
	authorColor: (theme) => lighten(0.1, theme.colors.primary),
	quoteTextFont: constants.fonts['times'],
	quoteAuthorFont: constants.fonts['helvetica'],
	quoteSourceFont: constants.fonts['helvetica']
};

const waveformAudioPlayer = {
	colors: {
		background: '#cccccc',
		wave: '#949494',
		progress: (theme) => theme.colors.blue
	}
};

const figure = {
	interactiveMapTitleBackground: (theme) => theme.colors.primary,
	number: {
		fontFamily: constants.fonts['helvetica-neue-medium'],
		fontSize: '18px',
		fontWeight: 500,
		lineHeight: 'normal',
		letterSpacing: 'normal',
		textTransform: 'none',
		color: constants.colors['gray'],
		margin: '0 0 5px'
	},
	title: {
		fontFamily: constants.fonts['helvetica-neue-medium'],
		fontSize: '18px',
		fontWeight: 500,
		color: constants.colors['gray'],
		lineHeight: 'normal',
		marginBottom: '20px'
	},
	caption: {
		fontSize: '16px',
		fontFamily: (theme) => theme.fonts.webtext,
		color: '#6d6e6e',
		marginBottom: '0px',
		lineHeight: 'normal',
		prefix: {
			fontWeight: constants.fontWeights.regular
		},
		body: {
			fontSize: '16px',
			display: 'block'
		},
		transcript: {
			fontSize: '14px',
			marginTop: '0px'
		}
	}
};

const tableFigure = {
	fontSize: '15px',
	headerFontSize: false,
	headerFontWeight: 'bold',
	headerBackground: (theme) => theme.colors.primary,
	rowBackground: (theme) => theme.colors.secondary,
	rowBackgroundOdd: (theme) => darken(0.05, theme.colors.secondary),
	strongFontWeight: false,
	strongFontSize: '17px'
};

const responseBoard = {
	postedAnswer: {
		body: {
			fontSize: '1em',
			lineHeight: '1.5em',
			color: '#545454'
		},
		author: {
			fontSize: '0.875em',
			lineHeight: '1.5em',
			color: '#5F5F5F',
			fontStyle: 'italic'
		},
		pinnedBy: {
			fontSize: '0.75em',
			fontWeight: constants.fontWeights.bold,
			lineHeight: '1em',
			color: '#5F5F5F'
		}
	}
};

const shortAnswer = {
	answerPosted: {
		fontSize: '1em',
		lineHeight: '1.5em',
		color: '#545454'
	},
	referenceAnswer: {
		fontSize: '1em',
		lineHeight: '1.5em',
		color: '#424341'
	}
};

const artifactLink = {
	icon: {
		color: (theme) => theme.colors.primary
	},
	backgroundColor: (theme) => theme.colors.secondary,
	title: {
		fontFamily: fonts.app,
		fontSize: '17px',
		color: (theme) => theme.colors['dark-gray'],
		textDecorationColor: (theme) => theme.colors['light-gray']
	},
	credits: {
		fontFamily: fonts.roboto,
		fontSize: '15px',
		color: (theme) => theme.colors.primary
	},
	transcript: {
		fontFamily: (theme) => theme.fonts['webtext'],
		fontWeight: 400,
		fontSize: '14px',
		color: (theme) => theme.colors.primary
	}
};

const webtextQuestion = {
	fontSize: '16px',
	fontFamily: (theme) => theme.fonts.app,
	backgroundColor: '#f7f7f7',
	divider: {
		height: '1px',
		backgroundColor: '#CFCFCF'
	},
	answerInput: {
		margin: '0 0 2em',
		border: '1px solid #CDCDCD',
		fontSize: '1em',
		lineHeight: '1.5em',
		color: '#424341',
		invalid: {
			border: '3px solid #E41B0C',
			backgroundColor: '#FAE9E7'
		}
	},
	answerStatus: {
		fontSize: '0.875em',
		lineHeight: '1em',
		color: '#3A3A39'
	},
	questionPrompt: {
		fontWeight: constants.fontWeights.medium,
		fontFamily: constants.fonts['helvetica-neue-medium'],
		fontSize: '1em',
		lineHeight: '1.25em',
		color: '#333332'
	},
	questionType: {
		fontSize: '0.75em',
		lineHeight: '0.875em',
		color: '#5f6f7c'
	},
	validationStatus: {
		fontWeight: constants.fontWeights.bold,
		fontSize: '1em',
		lineHeight: '1.25em',
		color: '#ED0000'
	},
	sectionTitle: {
		fontWeight: constants.fontWeights.medium,
		fontFamily: constants.fonts['helvetica-neue-medium'],
		fontSize: '1em',
		lineHeight: '1.25em',
		color: '#333332'
	},
	rejoinderSeparator: {
		color: (theme) => theme.colors.secondary
	},
	rejoinder: {
		correct: {
			color: '#2a742a'
		},
		incorrect: {
			color: '#ad2121'
		},
		correctInfo: {
			color: colors.text
		},
		correctness: {
			fontWeight: 500,
			fontFamily: (theme: Theme) => theme.fonts['webtext-question-body']
		}
	}
};

const bigIdea = {
	title: {
		fontSize: '14px',
		mobileFontSize: '12px',
		fontFamily: constants.fonts['haas-grotesk']
	},
	body: {
		fontSize: '28px',
		mobileFontSize: '20px',
		fontFamily: constants.fonts['haas-grotesk-bold']
	},
	sourceAttribute: {
		fontSize: '14px',
		fontFamily: constants.fonts['haas-grotesk']
	}
};

const bigLink = {
	backgroundColor: (theme) => theme.colors.secondary,
	hoverBackgroundColor: (theme) => theme.colors.secondary,
	icon: {
		fill: (theme) => theme.colors.primary
	},
	title: {
		color: '#121212',
		fontFamily: (theme) => theme.artifactLink.title.fontFamily,
		fontSize: '17px'
	},
	credit: {
		color: (theme) => theme.colors.primary,
		aHoverColor: (theme) => theme.colors.primary,
		fontSize: '15px'
	}
};

const webtextCallout = {
	borderWidth: `2px`,
	borderRadius: 0,
	backgroundColor: (theme) => theme.colors.secondary,
	padding: `50px`
};

const webtextExample = {
	backgroundColor: (theme) => theme.colors.secondary,
	decorationColorRight: '#85c125',
	decorationColorWrong: '#e41c0c',
	backgroundColorRight: '#fae9e7',
	borderColor: (theme) => theme.colors.primary
};

const webtextPullQuote = {
	heading: {
		color: (theme) => theme.colors.primary,
		fontSize: '12px',
		fontFamily: (theme) => theme.fonts.app,
		fontWeight: 'normal'
	},
	body: {
		fontSize: '26px',
		mobileFontSize: '19px',
		fontFamily: (theme) => theme.fonts.webtext
	}
};

const webtextWritingTemplate = {
	instructions: {
		color: 'white',
		fontWeight: 500,
		padding: '10px 20px',
		fontSize: '15.6px',
		fontFamily: constants.fonts['helvetica-neue-medium'],
		lineHeight: '1.6'
	},
	fillIn: {
		border: '1px solid #cdcdcd'
	},
	outline: {
		invalid: {
			border: '1px solid #e70000',
			background: '#fee6e6'
		}
	}
};

const textToSpeech = {
	color: '#bcbec0',
	fontSize: '13px',
	iconButton: {
		color: '#bcbec0',
		fontSize: '13px',
		border: '1px solid #262626'
	},
	selected: {
		border: '2px solid #FFF'
	},
	noHighlightColor: '#FFF'
};

const timeline = {
	fontFamily: fonts.app,
	heading: {
		fontFamily: fonts.app,
		fontSize: '24px',
		lineHeight: '30px'
	},
	media: {
		fontFamily: fonts.app
	},
	slidenav: {
		fontFamily: fonts.app
	},
	date: {
		fontFamily: fonts.app
	},
	slider: {
		fontFamily: fonts.app
	}
};

const endOfChapter = {
	separators: {
		borderColor: (theme) => theme.colors.primary,
		marginTop: '35px',
		borderTop: (theme) => `6px solid ${theme.endOfChapter.separators.borderColor}`,
		marginBottom: '35px',
		borderBottom: (theme) => `6px solid ${theme.endOfChapter.separators.borderColor}`
	},
	message: {
		padding: '20px 0',
		margin: 'initial',
		borderRadius: 'initial',
		fontWeight: 400,
		color: (theme) => theme.colors.primary,
		backgroundColor: 'transparent'
	}
};

const notifications = {
	headerHeight: '40px',
	headerHeightMobile: '40px',

	fontSize: '0.8em',
	fontWeight: '500',
	fontFamily: constants.fonts['helvetica-neue-medium'],

	tab: {
		borderColor: '#979797',
		borderWidthBefore: '14px',
		borderWidthAfter: '93px',

		mobile: {
			borderWidthBefore: '178px',
			borderWidthAfter: '44px'
		}
	},

	button: {
		padding: '3px 6px',
		textColor: '#b9bbbd',
		textTransform: 'uppercase',
		dotBackgroundColor: '#ed0404',

		unread: {
			textColor: '#000000',
			counterColor: '#ffffff',
			backgroundColor: '#ffffff',
			dotBorderColor: '#ffffff'
		},

		hover: {
			textColor: '#ffffff',
			counterColor: '#000000',
			backgroundColor: '#2f2f2f',
			dotBorderColor: '#2f2f2f'
		},

		active: {
			textColor: '#ffffff',
			counterColor: '#000000',
			backgroundColor: '#000000',
			dotBorderColor: '#000000'
		}
	},

	panel: {
		width: '266px',
		backgroundColor: '#000000',
		mainTextColor: '#ffffff',
		secondaryTextColor: '#bcbec0',

		nav: {
			textColor: '#D7D7D7',
			textColorActive: '#ffffff'
		},

		select: {
			textColor: '#ffffff',
			backgroundColor: '#333333'
		},

		element: {
			backgroundColor: '#000000',
			backgroundColorHover: '#333333',
			borderColor: '#979797'
		}
	},

	toc: {
		dotBorderColor: '#ffffff'
	}
};

const comments = {
	instructor: {
		/** descriptions */
		topBorderRadius: '4px',
		bottomBorderRadius: '8px',
		/** standard css */
		fontFamily: (theme) => theme.fonts.app,
		color: (theme) => theme.colors.text,
		borderColor: (theme) => theme.colors.primary,
		backgroundColor: (theme) => `${theme.colors.primary}0D`,
		padding: '17px 24px 16px 17px',
		paddingOpen: '17px 24px 16px 39px',
		header: {
			title: {
				fontSize: '16px',
				fontWeight: 700,
				fontFamily: constants.fonts['helvetica-neue'],
				marginBottom: '2px'
			},
			timestamp: {
				fontSize: '14px',
				fontFamily: constants.fonts['georgia'],
				fontWeight: 400
			},
			reveal: {
				color: (theme) => theme.colors.primary,
				fontSize: '16px',
				fontWeight: 700,
				fontFamily: constants.fonts['helvetica-neue'],
				arrow: {
					top: '0.2em'
				},
				extraSmallPaddingLeft: '28px'
			},
			bellIcon: {
				color: '#ED0404',
				fontSize: '19px'
			}
		},
		comment: {
			lineHeight: '1.813',
			fontSize: '16px',
			fontWeight: 400,
			fontFamily: constants.fonts['georgia']
		}
	}
};

const tableOfContents = {
	progressColor: (theme) => theme.colors.primary,
	currentItemColor: (theme) => transparentize(0.15, theme.colors.primary)
};

const webtextBlockquote = {
	borderLeft: (theme) => `6px solid ${theme.colors.primary}`
};

const outlineTemplate = {
	interactiveItemsBorderRadius: '4px',
	addButton: {
		color: constants.colors.black,
		borderColor: '#c8c8c8',
		backgroundColor: '#d8d8d8',
		fontWeight: 700
	}
};

const toast = {
	backgroundColor: '#323232',
	buttonBackgroundColor: '#3e3e3e'
};

const pollQuestion = {
	tickColor: '#E6E6E6',
	lineColor: '#B3B3B3',
	cellBackgroundColor: `${colors.primary}0D`
};

const chart = {
	tickColor: '#E6E6E6',
	lineColor: '#B3B3B3'
};

const annotations: Theme['annotations'] = {
	highlights: [
		{
			label: 'red',
			color: 'rgb(255, 0, 0)',
			opacity: 0.3,
			opacitySelected: 0.43
		},
		{
			label: 'green',
			color: 'rgb(33, 215, 40)',
			opacity: 0.3,
			opacitySelected: 0.54
		},
		{
			label: 'yellow',
			color: 'rgb(255, 215, 63)',
			opacity: 0.45,
			opacitySelected: 0.63
		}
	],
	notes: {
		ribbon: {
			private: { color: '#efb763' },
			readonly: { color: '#5f5f5f' },
			shared: { color: constants.colors.magenta }
		},
		underline: {
			shared: { color: constants.colors.magenta }
		}
	}
};

const webtextFigureAssetTable = {
	headerBackground: (theme) => theme.colors.primary,
	headerFontSize: '18px',
	fontSize: '15px',
	oddRowBackground: (theme) => theme.colors.secondary,
	rowBackground: (theme) => darken(0.05, theme.colors.secondary)
};

const footnote = {
	color: (theme) => theme.colors.primary
};

const theme = {
	layout,
	colors,
	fonts,
	ally,
	global: themeGlobal,
	offline,
	webtextPage,
	element,
	webtextQuestionButtons,
	webtextCheckbox,
	popupQuiz,
	modal,
	voiceRecording,
	lmsSubmission,
	bigQuoteEpigraph,
	waveformAudioPlayer,
	figure,
	tableFigure,
	webtextFigureAssetTable,
	shortAnswer,
	artifactLink,
	responseBoard,
	webtextQuestion,
	bigIdea,
	bigLink,
	webtextCallout,
	webtextExample,
	webtextPullQuote,
	webtextBlockquote,
	loader,
	chapterSplash,
	webtextWritingTemplate,
	textToSpeech,
	timeline,
	endOfChapter,
	notifications,
	comments,
	tableOfContents,
	outlineTemplate,
	toast,
	pollQuestion,
	chart,
	annotations,
	footnote
};

export default theme;
