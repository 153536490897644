// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './InteractiveTemplate.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import pageViewStyles from '../PageView.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import imageStyles from '../../../components/WebtextBuilder/UploadedImages.scss';

import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import { unlinkify } from '@soomo/lib/utils/index';
import { getElementLabel } from '@soomo/lib/components/WritingTemplate/WritingTemplate/utils';
import { VersionHistoryItem } from '@soomo/lib/notebook/components/ActivityHistory/VersionHistoryItem';
import { isDecisionVersion } from '@soomo/lib/notebook/types/index';

import interactiveTemplateQuery from 'Requests/InteractiveTemplateQuery';
import pageActivityQuery from 'Requests/PageActivityQuery';
import metricsQuery from 'Requests/MetricsQuery';
import * as uiActions from 'Actions/uiActions';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';
import ActivityHeader from 'Components/ActivityHeader';
import WebtextBuilderReview from 'Components/WebtextBuilderReview';
import ActivityHistory from 'Containers/ActivityHistory';

import AnswerActions from './AnswerActions';
import DecisionVersionHistoryItem from '@soomo/lib/notebook/components/ActivityHistory/VersionHistoryItem/DecisionVersionHistoryItem';

class InteractiveTemplateContainer extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate() {
		this.fetchData();
	}

	fetchData = (force) => {
		const { dispatch, course, user, interactiveTemplate } = this.props;
		if (!user || !interactiveTemplate) return;

		dispatch(
			interactiveTemplateQuery({
				courseId: course.id,
				userId: user.id,
				interactiveTemplateId: interactiveTemplate.id,
				force
			})
		);
	};

	onUnpostAnswer = () => {
		const { dispatch, course, chapter, page, current_answer: answer } = this.props;
		this.fetchData(true);
		dispatch(
			pageActivityQuery({
				courseId: course.id,
				userId: answer.user_id,
				pageId: page.id,
				force: true
			})
		);
		dispatch(
			metricsQuery({
				courseId: course.id,
				userIds: [answer.user_id],
				scopeId: chapter.id,
				groupby: 'page',
				force: true
			})
		);
	};

	onViewAllResponses = () => {
		const { uiActions, page, interactiveTemplate: element, user } = this.props;

		// cloned from the SAQuestion onViewAllResponses method
		let elementId = element.id;
		if (element.depends_on && element.depends_on.length && element.dictionary.body) {
			const cyoKey = user.decisions[element.depends_on[0]];

			if (cyoKey) {
				elementId = `${elementId}:${cyoKey}`;
			}
		}

		uiActions.updateCurrentViewParams({
			scope: page.id,
			element: elementId,
			student: null,
			pageViewStudent: user.id
		});

		return false;
	};

	render() {
		const {
			api,
			course,
			toc,
			page,
			interactiveTemplate,
			interactiveTemplateData: data,
			current_answer: answer,
			commentKey,
			comment,
			user,
			jwtToken,
			timeZone
		} = this.props;

		const answerVersionsHistory = answer?.history?.filter((v) => v.event !== 'saved');
		const postedEventCount =
			answerVersionsHistory?.filter((v) => v.event === 'posted')?.length || 0;

		const questionTypeLabel =
			getElementLabel(toc.config, page.page_type, interactiveTemplate.element_definition_key) ||
			'Writing Template';

		const autosubmittable = data?.lms_file_upload_support;
		const isComplete = data && data.dests && data.dests.output && !data.dests._draft;

		const showCompleteCheckmark = isComplete && (autosubmittable ? data.last_autosubmit_at : true);

		const renderActivityHeader = () => {
			if (!data) return null;

			if (data.last_autosubmit_at) {
				return (
					<ActivityHeader timestamp={data.last_autosubmit_at} timeZone={timeZone}>
						AUTO-SUBMITTED
					</ActivityHeader>
				);
			}

			if (data.last_saved_at) {
				return (
					<ActivityHeader timestamp={data.last_saved_at} timeZone={timeZone}>
						{isComplete && !autosubmittable && 'POSTED'}

						{isComplete && autosubmittable && !data.last_autosubmit_at && (
							<>
								ADDED <span style={{ fontStyle: 'italic' }}>(Not yet submitted)</span>
							</>
						)}

						{!isComplete && (
							<>
								LAST SAVE <span style={{ fontStyle: 'italic' }}>(Not yet posted)</span>
							</>
						)}
					</ActivityHeader>
				);
			}
		};

		const renderActivityHistory = () => {
			if (!data) return null;
			if (!answerVersionsHistory?.length) return null;

			/**
			 * Tracks the user's decisions set that should be used for rendering the history items
			 * Gets updated after the historical decision reset event is encountered
			 */
			let userDecisions = user.decisions;
			return (
				<ActivityHistory attemptCount={postedEventCount}>
					{answerVersionsHistory.map((item, index) => {
						if (isDecisionVersion(item)) {
							return (
								<DecisionVersionHistoryItem
									key={`${item.event}-${index}`}
									studentId={user.id}
									timeZone={timeZone}
									version={item}
								/>
							);
						}
						userDecisions = isDecisionVersion(item) ? item.decisions : userDecisions;
						return (
							<VersionHistoryItem
								key={`${item.event}-${index}`}
								version={item}
								studentId={user.id}
								timeZone={timeZone}
								expandedAnswerContent={
									<WebtextBuilderReview
										api={api}
										course={course}
										page={page}
										toc={toc}
										interactiveTemplate={interactiveTemplate}
										interactiveTemplateData={{ ...data, dests: item.body }}
										user={{ ...user, decisions: userDecisions }}
										jwtToken={jwtToken}
									/>
								}
							/>
						);
					})}
				</ActivityHistory>
			);
		};

		return (
			<div styleName="styles.InteractiveTemplate">
				{data && data.last_saved_at && (
					<div
						styleName={cn('pageViewStyles.Symbol', {
							'pageViewStyles.IsCompleteSymbol': showCompleteCheckmark
						})}
					/>
				)}
				<div styleName="styles.ActivitySummary">
					<span styleName="pageViewStyles.QuestionType">{questionTypeLabel}</span>

					{/*Will be activated in the future*/}
					{/*<span>&nbsp;&mdash;&nbsp;</span>*/}
					{/*<AnalyticsLink variant="text" onClick={this.onViewAllResponses}>*/}
					{/*	view all responses*/}
					{/*</AnalyticsLink>*/}
				</div>
				{renderActivityHeader()}
				<div styleName="styles.Body">
					<WebtextBuilderReview
						api={api}
						course={course}
						page={page}
						toc={toc}
						interactiveTemplate={interactiveTemplate}
						interactiveTemplateData={data}
						user={user}
						jwtToken={jwtToken}
					/>
				</div>
				{data && data.locked && (
					<div
						styleName="styles.LockedMessage"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: unlinkify(data.locked_message) }}
					/>
				)}
				<AnswerActions
					user={user}
					question={interactiveTemplate}
					answer={answer}
					comment={comment}
					commentKey={commentKey}
					posting={isComplete}
					onUnpostAnswer={data?.last_saved_at && !data?.locked ? this.onUnpostAnswer : null}
					commentRequiresPosting={false}
					commentWithoutAnswer={true}
					commentLocked={false}
				/>
				{renderActivityHistory()}
			</div>
		);
	}
}

InteractiveTemplateContainer.propTypes = {
	api: object.isRequired,
	course: object.isRequired,
	toc: object.isRequired,
	chapter: object.isRequired,
	page: object.isRequired,
	user: object.isRequired,
	interactiveTemplate: object.isRequired,
	interactiveTemplateData: object,
	current_answer: object,
	comment: object,
	timeZone: string.isRequired,
	dispatch: func.isRequired,
	jwtToken: string,
	uiActions: object.isRequired
};

const mapStateToProps = (state, props) => {
	const { scope: pageId, student: userId } = state.ui.currentViewParams;

	const page = state.entities.pages[pageId];
	const chapter = state.entities.chapters[page.chapter_id];

	const current_answer = props.activity && props.activity.answers && props.activity.answers[0];
	const commentKey = `${userId}:${props.interactiveTemplate.id}`;
	const comment = state.entities.webtextComments[commentKey];

	const webtextBuilder =
		state.entities.webtextBuilders[userId + ':' + props.interactiveTemplate.id];

	return {
		api: state.data.api,
		course: state.entities.course,
		toc: state.entities.toc,
		chapter,
		page,
		user: state.entities.users[userId],
		timeZone: state.entities.course.time_zone,
		interactiveTemplateData: webtextBuilder,
		current_answer,
		commentKey,
		comment,
		jwtToken: state.data.api.jwt
	};
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withLibsThemeProvider(InteractiveTemplateContainer));
