import { useMemo } from 'react';

import jwtDecode, { JwtDecodeOptions, JwtPayload } from 'jwt-decode';

export const useJwtTokenPayload = <T = JwtPayload>(
	token: string,
	options?: JwtDecodeOptions
): T | undefined =>
	useMemo(() => {
		try {
			return jwtDecode<T>(token, options);
		} catch (parsingError) {
			console.error(`Cannot parse JWT token:`, parsingError.message);
			return undefined;
		}
	}, [options, token]);
