import React, { useMemo } from 'react';

import {
	getContextualInfoIcon,
	getContextualInfoId
} from '~/components/pageElements/PollQuestion/utils';

import { usePollDataState } from '../../hooks';
import { pollDataStyles } from '../../styles';
import ClassOnlyTable from './ClassOnlyTable';
import ComparisonTable from './ComparisonTable';

import type { Props as RefreshedPollResultsProps } from '../ResultsTabs';

const sourceComments = [];

type Props = Pick<
	RefreshedPollResultsProps,
	| 'courseNumber'
	| 'choices'
	| 'classData'
	| 'externalData'
	| 'totalCount'
	| 'totalNumberOfStudents'
	| 'dataType'
	| 'choiceOrdering'
	| 'contextualInfo'
	| 'sourceDatasetIndex'
>;

const RefreshedPollResultsDataTable: React.FC<Props> = (props) => {
	const {
		courseNumber,
		choices,
		externalData,
		contextualInfo,
		choiceOrdering,
		sourceDatasetIndex,
		classData,
		dataType,
		totalCount,
		totalNumberOfStudents
	} = props;

	const isClassOnly = !externalData;

	const { classTotal, orderedClassData, shapedData, totalPercentageSums } = usePollDataState({
		choices,
		shapedDataMode: 'number',
		classData,
		externalData,
		choiceOrdering,
		sourceDatasetIndex,
		dataType,
		totalCount,
		roundValuePrecision: 2
	});

	const classmatesRespondedMessage = useMemo(
		() =>
			totalNumberOfStudents - classTotal <= 0
				? null
				: `${totalNumberOfStudents - classTotal} of your classmates ${
						totalNumberOfStudents - classTotal === 1 ? 'has' : 'have'
				  } not responded`,
		[classTotal, totalNumberOfStudents]
	);

	const showContextualInfo = contextualInfo?.some(Boolean);

	return (
		<div className="poll-data-container" css={(theme) => pollDataStyles(theme, isClassOnly)}>
			{isClassOnly ? (
				<ClassOnlyTable
					orderedClassData={orderedClassData}
					classTotal={classTotal}
					classmatesRespondedMessage={classmatesRespondedMessage}
				/>
			) : (
				<ComparisonTable
					courseNumber={courseNumber}
					classData={classData}
					dataType={dataType}
					totalCount={totalCount}
					classTotal={classTotal}
					contextualInfo={contextualInfo}
					shapedData={shapedData}
					totalPercentageSums={totalPercentageSums}
					classmatesRespondedMessage={classmatesRespondedMessage}
				/>
			)}
			{sourceComments.map((comment, index) => (
				<p className="poll-data-comment" key={`${comment}-${index}`}>
					{comment}
				</p>
			))}
			{showContextualInfo && (
				<ul className="poll-data-contextual-info">
					{contextualInfo.map((info, index) => {
						const id = getContextualInfoId(index);
						const icon = getContextualInfoIcon(index);

						return info && icon ? (
							<li id={id} key={id}>
								<span aria-hidden>{icon}</span> <span dangerouslySetInnerHTML={{ __html: info }} />
							</li>
						) : null;
					})}
				</ul>
			)}
		</div>
	);
};

export default RefreshedPollResultsDataTable;
