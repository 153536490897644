/**
 * Not particularly smart, but sufficient for our purposes.
 */
const URL_REGEX = /(https?:\/\/\S+)/g;
const NON_KALTURA_VIDEO_REGEX = /(vimeo|youtube|youtu)/;
const KALTURA_VIDEO_REGEX = /kaltura\.com/;

export function commentHasVideoUrl(commentBody: string): boolean {
	return commentBodyToNodes(commentBody).some((node) => node.type === 'VIDEO_URL');
}

export type CommentSection = {
	type: 'TEXT' | 'VIDEO_URL';
	content: string;
};

function isVideoUrl(url: string): boolean {
	if (NON_KALTURA_VIDEO_REGEX.test(url)) {
		return true;
	}
	if (
		KALTURA_VIDEO_REGEX.test(url) &&
		url.includes('partner_id') &&
		url.includes('uiconf_id') &&
		url.includes('entry_id')
	) {
		return true;
	}
	return false;
}

export function commentBodyToNodes(commentBody: string): CommentSection[] {
	const docFragment = new DOMParser().parseFromString(commentBody, 'text/html');

	const { childNodes } = docFragment.body;
	const asStrings: string[] = [];
	childNodes.forEach((node) => {
		if (node.nodeName === 'IFRAME') {
			asStrings.push((node as HTMLIFrameElement).getAttribute('src'));
		} else {
			asStrings.push(node.textContent);
		}
	});

	const result = asStrings.flatMap((str) => {
		return str.split(URL_REGEX).map((substr) => {
			if (isVideoUrl(substr)) {
				return {
					type: 'VIDEO_URL' as const,
					content: substr
				};
			}
			return {
				type: 'TEXT' as const,
				content: substr
			};
		});
	});
	return result;
}
