import { useCallback, useEffect } from 'react';

import dropRight from 'lodash-es/dropRight';
import isEqual from 'lodash-es/isEqual';

import {
	focusInstanceInteractiveElement,
	focusInstanceMenuButtonElement,
	focusOutlineElement,
	getAddressDepth,
	getInstanceId,
	getResponse,
	getSiblingAddress
} from '~/components/Outline/helpers';
import { selectUserAction, useOutlineSelector } from '~/components/Outline/store';
import {
	getHierarchyItemType,
	OutlineHierarchyItemType,
	OutlineHierarchyResponses,
	OutlineInstanceAddress,
	OutlineResponseSection
} from '~/components/Outline/types';
import { usePreviousRender } from '~/hooks';

export const useOutlineActionsFocus = (): void => {
	const builderFamilyId = useOutlineSelector((state) => state.builderFamilyId);
	const dest = useOutlineSelector((state) => state.dest);

	const userAction = useOutlineSelector(selectUserAction);
	const prevUserAction = usePreviousRender(userAction);

	const hierarchyResponses = useOutlineSelector((state) => state.hierarchyResponses);

	const handleNewUserAction = useCallback(() => {
		const { action: action, targetAddress: targetAddress } = userAction;

		const instanceIdParams: Parameters<typeof getInstanceId> = [
			builderFamilyId,
			dest,
			targetAddress
		];
		switch (action) {
			case 'addInstance':
				return handleAddInstanceFocus(...instanceIdParams);
			case 'moveInstanceUp':
				return handleMoveInstanceFocus('up', ...instanceIdParams);
			case 'moveInstanceDown':
				return handleMoveInstanceFocus('down', ...instanceIdParams);
			case 'deleteInstance':
				return handleDeleteInstanceFocus(hierarchyResponses, ...instanceIdParams);
		}
	}, [builderFamilyId, dest, hierarchyResponses, userAction]);

	const handleUserActionUndo = useCallback(() => {
		const { action: prevAction, targetAddress: prevTargetAddress } = prevUserAction;

		switch (prevAction) {
			case 'addInstance':
				return handleDeleteInstanceFocus(
					hierarchyResponses,
					builderFamilyId,
					dest,
					prevTargetAddress
				);
			case 'moveInstanceUp':
			case 'moveInstanceDown':
				// Focus back on the instance that initiated the action
				return focusInstanceMenuButtonElement(builderFamilyId, dest, prevTargetAddress);
			case 'deleteInstance':
				return handleAddInstanceFocus(builderFamilyId, dest, prevTargetAddress);
		}
	}, [builderFamilyId, dest, hierarchyResponses, prevUserAction]);

	useEffect(() => {
		const newActionArrived = userAction && !isEqual(prevUserAction, userAction);
		const actionUndone = prevUserAction && !userAction; // On Undo the `userAction` is getting cleared

		if (newActionArrived) {
			handleNewUserAction();
		} else if (actionUndone) {
			handleUserActionUndo();
		}
	}, [handleNewUserAction, handleUserActionUndo, prevUserAction, userAction]);
};

/**
 * Focus on the newly created instance
 * @see {@link https://soomo.height.app/T-57047}
 */
const handleAddInstanceFocus = (...instanceIdParams: Parameters<typeof getInstanceId>): void =>
	focusInstanceInteractiveElement(...instanceIdParams);

/**
 * Focus on the menu button of instance above/below the one that initiated the action
 * @see {@link https://soomo.height.app/T-64711}
 */
const handleMoveInstanceFocus = (
	direction: 'up' | 'down',
	builderFamilyId: string,
	dest: string,
	address: OutlineInstanceAddress
): void => {
	const newInstanceAddress = getSiblingAddress(address, direction);
	focusInstanceMenuButtonElement(builderFamilyId, dest, newInstanceAddress);
};

/**
 * When deleting the:
 * 1. toggle instance - focus should be placed on the menu button of the parent instance (T-57049)
 * @see {@link https://soomo.height.app/T-57049}
 *
 * 2. children instance - focus should move to the previous instance in the hierarchy (lookup description - T-64199)
 * @see {@link https://soomo.height.app/T-64199}
 */
const handleDeleteInstanceFocus = (
	hierarchyResponses: OutlineHierarchyResponses,
	builderFamilyId: string,
	dest: string,
	address: OutlineInstanceAddress
) => {
	/**
	 * For the context, the initiator address looks like:
	 * [..., sectionAddressPoint, instanceAddressPoint] AKA [..., { childrenIndex | toggleIndex }, { instanceIndex }]
	 */
	const parentSectionAddress = dropRight(address, 1);
	const parentInstanceAddress = dropRight(parentSectionAddress, 1);

	const deletedInstanceType = getHierarchyItemType(address);
	if (deletedInstanceType === OutlineHierarchyItemType.Toggle) {
		return focusInstanceMenuButtonElement(builderFamilyId, dest, parentInstanceAddress); // Focus on the parent instance of the toggle
	}

	const [{ childrenIndex }, { instanceIndex }] = address.slice(-2);
	if (instanceIndex > 0) {
		const prevInstanceAddress = getSiblingAddress(address, 'up');
		return focusInstanceInteractiveElement(builderFamilyId, dest, prevInstanceAddress); // Focus on the prev sibling instance within the same section
	}
	if (childrenIndex > 0) {
		const prevSectionAddress = getSiblingAddress(parentSectionAddress, 'up');

		const responseSection = getResponse<OutlineResponseSection>(
			hierarchyResponses,
			prevSectionAddress
		);
		const sectionLastInstanceIndex = responseSection.instances.length - 1;

		const prevSectionLastInstanceAddress = prevSectionAddress.concat({
			instanceIndex: sectionLastInstanceIndex
		});
		return focusInstanceInteractiveElement(builderFamilyId, dest, prevSectionLastInstanceAddress); // Focus on the last instance of the prev sibling section
	}

	const depth = getAddressDepth(address);
	if (depth > 1) {
		return focusInstanceInteractiveElement(builderFamilyId, dest, parentInstanceAddress); // Focus on the parent instance
	}

	focusOutlineElement(builderFamilyId, dest); // Focus on the parent Outline PI
};
