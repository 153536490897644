import { css } from '@emotion/react';

export const container = css`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;

	.label-text {
		font-size: 13.5px;
	}

	div:first-of-type {
		margin-bottom: 20px;
	}

	svg {
		width: 590px;
		height: 100%;
	}

	div.chart-title {
		text-align: center;
	}

	div.scrollable-container {
		max-width: 100%;
		overflow: auto;
		justify-self: flex-start;
		align-self: flex-start;
	}
`;

export const hint = css`
	width: 100%;
	text-align: center;
	color: red;
	font-size: 14px;
`;
