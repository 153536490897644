import React, {
	FC,
	MutableRefObject,
	ReactElement,
	useCallback,
	useLayoutEffect,
	useRef,
	useState
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AiOutlineClose } from 'react-icons/ai';
import {
	BsArrowDown,
	BsArrowLeft,
	BsArrowRight,
	BsArrowUp,
	BsDashLg,
	BsPlusLg
} from 'react-icons/bs';

import { ClassNames, css } from '@emotion/react';
import { useMergeRefs } from '@floating-ui/react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { clamp, first, last } from 'lodash-es';

import Loader from '~/components/Loader';
import ModalFloatingContainer from '~/components/Modal/ModalFloatingContainer';
import { useOverflowSides } from '~/hooks';
import { getThemeItem, mixins, Theme } from '~/styles/themes';
import { getOS } from '~/utils';

import { initialScale, relativeScaleValues, smoothScaleStep } from './constants';
import { ExpandedViewScaler, Props as ExpandedViewScalerProps } from './ExpandedViewScaler';

export interface Props<T extends HTMLElement> extends Pick<ExpandedViewScalerProps<T>, 'children'> {
	title?: string;
	ariaLabel?: string;
	isLoading?: boolean;
	returnFocusRef?: React.MutableRefObject<HTMLButtonElement>;
	onClose: () => void;
}

type ArrowButtonDirection = 'up' | 'down' | 'left' | 'right';
export type PointerPosition = { x: number; y: number };

const minScaleValue = first(relativeScaleValues);
const maxScaleValue = last(relativeScaleValues);

export const ExpandedViewModal = <T extends HTMLElement>(props: Props<T>): ReactElement => {
	const { isLoading } = props;

	const [scale, setScale] = useState(initialScale);

	const [pointerPosition, setPointerPosition] = useState<PointerPosition>({ x: 0, y: 0 });

	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	return (
		<ModalFloatingContainer
			open
			onClose={props.onClose}
			ariaLabel={props.ariaLabel}
			returnFocusRef={props.returnFocusRef}
			title={<ModalTitle {...props} />}>
			{isLoading && <Loader css={styles.loader} />}
			{!isLoading && (
				<>
					<ExpandedViewScaler
						scale={scale}
						onScaleChange={setScale}
						scrollContainerRef={scrollContainerRef}
						pointerPosition={pointerPosition}
						{...props}
					/>
					<ModalPanControls scrollContainerRef={scrollContainerRef} />
					<ModalScaleControls
						scale={scale}
						onScaleChange={setScale}
						scrollContainerRef={scrollContainerRef}
						onPointerPositionChange={setPointerPosition}
					/>
				</>
			)}
		</ModalFloatingContainer>
	);
};

const ModalTitle: FC<Omit<Props<never>, 'children'>> = (props) => {
	const { title, onClose } = props;
	return (
		<div css={styles.modalHeader.self}>
			<h2 css={styles.modalHeader.title}>{title}</h2>
			<button css={styles.modalHeader.closeButton} onClick={onClose}>
				<AiOutlineClose aria-hidden /> Close
			</button>
		</div>
	);
};

const ModalPanControls: FC<{ scrollContainerRef: MutableRefObject<HTMLDivElement> }> = (props) => {
	const { scrollContainerRef } = props;

	const overflowSides = useOverflowSides({ ref: scrollContainerRef, includeBounceScrolling: true });

	const handlePanning = useCallback(
		(direction: ArrowButtonDirection) => () => {
			const { current: scrollContainer } = scrollContainerRef;
			if (!scrollContainer) return;

			const verticalOverflow = scrollContainer.scrollHeight - scrollContainer.clientHeight;
			const horizontalOverflow = scrollContainer.scrollWidth - scrollContainer.clientWidth;

			let shorterViewerDimension;
			/**
			 * If one dimension has no overflow of 0,
			 * the code picks the non-zero overflow dimension.
			 * This ensures that the calculation focuses on
			 * the dimension that actually has content to scroll.
			 */
			if (verticalOverflow === 0 || horizontalOverflow === 0) {
				shorterViewerDimension = verticalOverflow || horizontalOverflow;
			} else {
				shorterViewerDimension =
					scrollContainer.clientHeight < scrollContainer.clientWidth
						? verticalOverflow
						: horizontalOverflow;
			}

			const scrollDelta = Math.round(shorterViewerDimension * 0.25);

			switch (direction) {
				case 'up':
					scrollContainer.scrollBy({ top: -scrollDelta });
					break;
				case 'down':
					scrollContainer.scrollBy({ top: scrollDelta });
					break;
				case 'left':
					scrollContainer.scrollBy({ left: -scrollDelta });
					break;
				case 'right':
					scrollContainer.scrollBy({ left: scrollDelta });
					break;
				default:
					break;
			}
		},
		[scrollContainerRef]
	);

	return (
		<ClassNames>
			{({ css, theme }) => (
				<div
					css={styles.panGroup.self}
					role="navigation"
					aria-label="Pan"
					data-testid="pan-controls">
					<ButtonGroup
						variant="contained"
						orientation="vertical"
						disableElevation
						css={styles.panGroup.verticalGroup}
						classes={{
							contained: css(styles.panGroup.buttonGroup(theme)),
							groupedVertical: css(styles.panGroup.grouped(theme))
						}}>
						<button
							css={styles.panGroup.arrowButton('up')}
							aria-label="pan up"
							onClick={handlePanning('up')}
							aria-disabled={!overflowSides.top}
							data-testid="pan-up-button"
							tabIndex={overflowSides.top ? 0 : -1}>
							<BsArrowUp aria-hidden />
						</button>
					</ButtonGroup>
					<ButtonGroup
						variant="contained"
						disableElevation
						classes={{
							contained: css(styles.panGroup.buttonGroup(theme)),
							groupedHorizontal: css(styles.panGroup.grouped(theme))
						}}>
						<button
							aria-label="pan left"
							css={styles.panGroup.arrowButton('left')}
							onClick={handlePanning('left')}
							aria-disabled={!overflowSides.left}
							data-testid="pan-left-button"
							tabIndex={overflowSides.left ? 0 : -1}>
							<BsArrowLeft aria-hidden />
						</button>
						<button
							aria-label="pan down"
							css={styles.panGroup.arrowButton('down')}
							onClick={handlePanning('down')}
							aria-disabled={!overflowSides.bottom}
							data-testid="pan-down-button"
							tabIndex={overflowSides.bottom ? 0 : -1}>
							<BsArrowDown aria-hidden />
						</button>
						<button
							aria-label="pan right"
							css={styles.panGroup.arrowButton('right')}
							onClick={handlePanning('right')}
							aria-disabled={!overflowSides.right}
							data-testid="pan-right-button"
							tabIndex={overflowSides.right ? 0 : -1}>
							<BsArrowRight aria-hidden />
						</button>
					</ButtonGroup>
				</div>
			)}
		</ClassNames>
	);
};

const ModalScaleControls: FC<{
	scale: number;
	onScaleChange: (scale: number) => void;
	scrollContainerRef: MutableRefObject<HTMLDivElement>;
	onPointerPositionChange: (position: PointerPosition) => void;
}> = (props) => {
	const { scale, onScaleChange, scrollContainerRef, onPointerPositionChange } = props;

	const [relativeScale, setRelativeScale] = useState(initialScale);
	const [initialPinchDistance, setInitialPinchDistance] = useState(0);

	const canDecreaseScale = relativeScale > minScaleValue;
	const canIncreaseScale = relativeScale < maxScaleValue;
	const canResetScale = relativeScale !== initialScale;

	const os = getOS();

	const findClosestScaleValue = (target: number, direction: 'increase' | 'decrease') => {
		const scaleValues = relativeScaleValues.filter((value) =>
			direction === 'decrease' ? value < target : value > target
		);
		const closestScale = scaleValues.reduce(
			(closest, value) =>
				closest === value || Math.abs(value - target) < Math.abs(closest - target)
					? value
					: closest,
			first(scaleValues)
		);

		return closestScale;
	};

	const handleScaleChange = useCallback(
		(direction: 'increase' | 'decrease') => () => {
			if (
				(direction === 'increase' && !canIncreaseScale) ||
				(direction === 'decrease' && !canDecreaseScale)
			)
				return;

			const nextRelativeScaleValue = findClosestScaleValue(relativeScale, direction);
			const nextScaleValue = scale * (nextRelativeScaleValue / relativeScale);

			onScaleChange(nextScaleValue);
			setRelativeScale(nextRelativeScaleValue);
		},
		[scale, onScaleChange, relativeScale, canDecreaseScale, canIncreaseScale]
	);

	/**
	 * Normalizes the scroll delta value based on the operating system.
	 * - On Windows: Converts delta to either +1 or -1 for consistent scaling.
	 * - On other systems: Uses the original delta value for scaling.
	 */
	const normalizeDelta = useCallback(
		(delta: number) => (os === 'windows' ? Math.sign(delta) : delta),
		[os]
	);

	/**
	 * Delta < 0 indicates scrolling up - zooming in or pinching out on a touchpad.
	 * Delta > 0 indicates scrolling down - zooming out or pinching in on a touchpad.
	 */
	const handleSmoothScaleChange = useCallback(
		(delta: number) => {
			if (delta === 0) return;
			if ((delta < 0 && !canIncreaseScale) || (delta > 0 && !canDecreaseScale)) return;

			const normalizedDelta = normalizeDelta(delta);

			let newRelativeScaleValue = parseFloat(
				(relativeScale - smoothScaleStep * normalizedDelta).toFixed(2)
			);

			/**
			 * Ensure the new relative scale value is within the allowed range (minScaleValue to maxScaleValue).
			 * If not, set it to the min or max value.
			 * Adjust the new scale value accordingly.
			 */
			newRelativeScaleValue = clamp(newRelativeScaleValue, minScaleValue, maxScaleValue);

			const newScaleValue = scale * (newRelativeScaleValue / relativeScale);

			onScaleChange(newScaleValue);
			setRelativeScale(newRelativeScaleValue);
		},
		[scale, relativeScale, onScaleChange, canDecreaseScale, canIncreaseScale, normalizeDelta]
	);

	const resetScale = useCallback(() => {
		const nextScaleValue = scale * (initialScale / relativeScale);

		onScaleChange(nextScaleValue);
		setRelativeScale(initialScale);
	}, [scale, onScaleChange, relativeScale]);

	const decreaseHotkeyRef = useHotkeys(
		['minus', 'num_subtract', 'Shift+minus'],
		handleScaleChange('decrease'),
		{ enabled: canDecreaseScale },
		[handleScaleChange]
	);
	const increaseHotkeyRef = useHotkeys(
		['equal', 'num_add', 'Shift+equal'],
		handleScaleChange('increase'),
		{ enabled: canIncreaseScale },
		[handleScaleChange]
	);
	const resetHotkeyRef = useHotkeys(['0', 'mod+0'], resetScale, { enabled: canResetScale }, [
		resetScale
	]);
	const scaleHotkeyRef = useMergeRefs([decreaseHotkeyRef, increaseHotkeyRef, resetHotkeyRef]);

	useLayoutEffect(() => {
		const { current: scrollContainer } = scrollContainerRef;
		if (scrollContainer) {
			scaleHotkeyRef(scrollContainer);
		}
	}, [scaleHotkeyRef, scrollContainerRef]);

	useLayoutEffect(() => {
		const { current: scrollContainer } = scrollContainerRef;
		if (!scrollContainer) return;

		const handleWheel = (e: WheelEvent) => {
			if (e.ctrlKey) {
				e.preventDefault();

				/**
				 * Cursor position relative to the viewport
				 */
				const { clientX, clientY } = e;
				const { left, top } = scrollContainer.getBoundingClientRect();

				/**
				 * Calculate cursor position relative to the scroll container
				 */
				onPointerPositionChange({ x: clientX - left, y: clientY - top });

				handleSmoothScaleChange(e.deltaY);
			}
		};

		scrollContainer.addEventListener('wheel', handleWheel, { passive: false });

		return () => {
			/**
			 * Reset the position when the event listener is removed
			 */
			onPointerPositionChange({ x: 0, y: 0 });
			scrollContainer.removeEventListener('wheel', handleWheel);
		};
	}, [scrollContainerRef, handleSmoothScaleChange, onPointerPositionChange]);

	useLayoutEffect(() => {
		const { current: scrollContainer } = scrollContainerRef;
		if (!scrollContainer) return;

		const handleTouchStart = (e: TouchEvent) => {
			if (e.touches.length === 2) {
				const dx = e.touches[0].clientX - e.touches[1].clientX;
				const dy = e.touches[0].clientY - e.touches[1].clientY;
				const distance = Math.sqrt(dx * dx + dy * dy);

				setInitialPinchDistance(distance);
			}
		};

		const handleTouchMove = (e: TouchEvent) => {
			if (e.touches.length === 2) {
				e.preventDefault();

				const { clientX: clientX1, clientY: clientY1 } = e.touches[0];
				const { clientX: clientX2, clientY: clientY2 } = e.touches[1];
				const { left, top } = scrollContainer.getBoundingClientRect();

				const midpointX = (clientX1 + clientX2) / 2;
				const midpointY = (clientY1 + clientY2) / 2;
				onPointerPositionChange({ x: midpointX - left, y: midpointY - top });

				const dx = clientX1 - clientX2;
				const dy = clientY1 - clientY2;
				const currentDistance = Math.sqrt(dx * dx + dy * dy);
				setInitialPinchDistance(currentDistance);

				const deltaY = initialPinchDistance - currentDistance;
				handleSmoothScaleChange(deltaY);
			}
		};

		scrollContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
		scrollContainer.addEventListener('touchmove', handleTouchMove, { passive: false });

		return () => {
			/**
			 * Reset the position when the event listener is removed
			 */
			onPointerPositionChange({ x: 0, y: 0 });
			scrollContainer.removeEventListener('touchstart', handleTouchStart);
			scrollContainer.removeEventListener('touchmove', handleTouchMove);
		};
	}, [initialPinchDistance, scrollContainerRef, handleSmoothScaleChange, onPointerPositionChange]);

	return (
		<ClassNames>
			{({ css, theme }) => (
				<div
					css={styles.scaleGroup.self}
					role="navigation"
					aria-label="Zoom"
					data-testid="scale-controls">
					<ButtonGroup
						variant="contained"
						disableElevation
						classes={{
							contained: css(styles.scaleGroup.buttonGroup(theme)),
							groupedHorizontal: css(styles.scaleGroup.grouped(theme))
						}}>
						<button
							onClick={handleScaleChange('decrease')}
							aria-disabled={!canDecreaseScale}
							aria-label="Zoom out"
							data-testid="zoom-out-button"
							tabIndex={canDecreaseScale ? 0 : -1}>
							<BsDashLg aria-hidden />
						</button>
						<div css={styles.scaleGroup.scaleValue} role="status">
							{relativeScale.toLocaleString(undefined, { style: 'percent' })}
						</div>
						<button
							onClick={handleScaleChange('increase')}
							aria-disabled={!canIncreaseScale}
							aria-label="Zoom in"
							data-testid="zoom-in-button"
							tabIndex={canIncreaseScale ? 0 : -1}>
							<BsPlusLg aria-hidden />
						</button>
					</ButtonGroup>
				</div>
			)}
		</ClassNames>
	);
};

const styles = {
	modalHeader: {
		self: css`
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
		`,
		title: (theme: Theme) => css`
			flex: 1;
			padding: 0 15px;
			font-size: 18px;
			font-family: ${getThemeItem(theme.fonts.app, theme)};
			font-weight: 700;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		`,
		closeButton: (theme: Theme) => css`
			display: flex;
			align-items: center;
			height: 100%;
			padding: 0 15px;
			border: 1px solid transparent;
			border-left: 1px solid ${theme.colors['light-brown']};
			color: #000;
			font-size: 16px;
			font-weight: 500;
			font-family: ${getThemeItem(theme.fonts.app, theme)};
			cursor: pointer;

			svg {
				margin-right: 5px;
			}

			&:focus {
				${mixins.webtextAccessibleFocused(theme)}
			}
		`
	},
	panGroup: {
		self: css`
			position: absolute;
			left: 20px;
			bottom: 70px;
			display: flex;
			flex-direction: column;
			align-items: center;
		`,
		buttonGroup: (theme: Theme) => css`
			background-color: ${theme.colors['light-grayish-white']};
			border: 1px solid ${theme.colors['light-brown']};

			&.MuiButtonGroup-root {
				border-radius: 7px;
			}
		`,
		grouped: (theme: Theme) => css`
			width: 45px;
			height: 45px;
			margin-right: 0;
			padding: 10px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
			font-family: ${getThemeItem(theme.fonts.app, theme)};
			border: none;
			background: transparent;
			cursor: pointer;

			&[aria-disabled='true'] {
				color: rgba(0, 0, 0, 0.25);
				cursor: not-allowed;
			}

			&:not(:last-child) {
				border-color: ${theme.colors['light-brown']};
			}

			&:focus-visible {
				${mixins.webtextAccessibleFocused(theme)}
			}
		`,
		verticalGroup: css`
			border-bottom: none;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		`,
		arrowButton: (direction: ArrowButtonDirection) => css`
			${direction === 'up' &&
			css`
				&:focus-visible {
					border-top-left-radius: 7px !important;
					border-top-right-radius: 7px !important;
				}
			`}

			${direction === 'down' &&
			css`
				border-right: none !important;
				border-top: 1px solid #bdbdbd !important;
				margin-top: -1px;
			`}

			${direction === 'right' &&
			css`
				border-left: 1px solid #bdbdbd !important;

				&:focus-visible {
					border-top-right-radius: 7px !important;
					border-bottom-right-radius: 7px !important;
				}
			`}


			${direction === 'left' &&
			css`
				&:focus-visible {
					border-top-left-radius: 7px !important;
					border-bottom-left-radius: 7px !important;
				}
			`}
		`
	},
	scaleGroup: {
		self: css`
			position: absolute;
			left: 20px;
			bottom: 20px;
		`,
		buttonGroup: (theme: Theme) => css`
			background-color: ${theme.colors['light-grayish-white']};
			border: 1px solid ${theme.colors['light-brown']};

			&.MuiButtonGroup-root {
				border-radius: 7px;
			}
		`,
		grouped: (theme: Theme) => css`
			height: 40px;
			margin-right: 0;
			padding: 10px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
			font-family: ${getThemeItem(theme.fonts.app, theme)};
			background: transparent;
			cursor: pointer;

			&[aria-disabled='true'] {
				color: rgba(0, 0, 0, 0.25);
				cursor: not-allowed;
			}

			&:not(:last-child) {
				border-color: ${theme.colors['light-brown']};
			}

			&:focus-visible {
				${mixins.webtextAccessibleFocused(theme)}

				:first-child {
					border-top-left-radius: 7px !important;
					border-bottom-left-radius: 7px !important;
				}

				:last-child {
					border-top-right-radius: 7px !important;
					border-bottom-right-radius: 7px !important;
				}
			}
		`,
		scaleValue: css`
			width: 55px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 13px !important;
			box-sizing: border-box;

			cursor: auto !important;
		`
	},
	loader: css`
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	`
};
