import first from 'lodash-es/first';
import merge from 'lodash-es/merge';
import omitBy from 'lodash-es/omitBy';
import partition from 'lodash-es/partition';
import pick from 'lodash-es/pick';

import { RequirementsStatus, ScoreSettingsConfig } from '~/components/pageElements/GoReact/types';
import { GoReactAnswerProps, GoReactReviewSummary } from '~/types';

const defaultReviewSummary: GoReactReviewSummary = {
	markerCount: 0,
	freeformCommentCount: 0,
	submittedRubricCount: 0
};

/**
 * Generates requirements completion status for the GoReact assignment
 *
 * It analyzes the answer and based on the scoring config aggregates the object with flags that
 * indicate which requirements were met and which need more work
 *
 * Based on these flags, the feedback message will be generated
 */
export const getRequirementsStatus = (
	scoringConfig: ScoreSettingsConfig,
	answer: GoReactAnswerProps
): RequirementsStatus | null => {
	switch (scoringConfig.scoreOptions) {
		case 'noPoints':
			return null;
		case 'instructorGrade':
			return getRecordingMakingStatus(answer);
		case 'pointsForCompletion': {
			const { completionOptions } = scoringConfig;
			if (completionOptions?.recordingMaking?.required) {
				return getRecordingMakingStatus(answer);
			}
			if (completionOptions?.selfReview?.required) {
				return getSelfReviewFeedback(completionOptions.selfReview, answer);
			}
			if (completionOptions?.providedReview?.required) {
				return getProvidedReviewFeedback(completionOptions.providedReview, answer);
			}
			if (completionOptions?.nonSelfReview?.required) {
				return getPeerReviewFeedback(completionOptions.nonSelfReview, answer);
			}
		}
	}
};

export const getRequirementsMet = (requirementsStatus: RequirementsStatus): boolean =>
	Object.values(requirementsStatus).every(Boolean);

export const getFailedRequirementsCount = (requirementsStatus: RequirementsStatus): number =>
	Object.values(requirementsStatus).filter((status) => !status).length;

export const getOnlyRubricStatusPresent = (requirementsStatus: RequirementsStatus): boolean =>
	'rubricSubmission' in requirementsStatus && Object.keys(requirementsStatus).length === 1;

const getRecordingMakingStatus = (
	answer: GoReactAnswerProps
): ReturnType<typeof getRequirementsStatus> =>
	'postedRecording' in answer ? pick(answer, ['postedRecording']) : null;

const getSelfReviewFeedback = (
	selfReviewConfig: ScoreSettingsConfig['completionOptions']['selfReview'],
	answer: GoReactAnswerProps
): ReturnType<typeof getRequirementsStatus> => {
	if (!('selfReviewSummary' in answer)) return null;

	let recordingsReviews = Object.values(answer.selfReviewSummary);

	/**
	 * Should generate failed feedback if no recordings were reviewed
	 * Default (0) values won't meet the defined requirements
	 */
	if (recordingsReviews.length === 0) {
		recordingsReviews = [defaultReviewSummary];
	}

	const [successStatuses, failedStatuses] = getRecordingsRequirementsStatuses(
		selfReviewConfig,
		recordingsReviews
	);

	// If at least one recording met all the requirements, the feedback is successful
	return successStatuses.length > 0
		? first(successStatuses)
		: aggregateFailedStatus(failedStatuses);
};

const getProvidedReviewFeedback = (
	providedReviewConfig: ScoreSettingsConfig['completionOptions']['providedReview'],
	answer: GoReactAnswerProps
): ReturnType<typeof getRequirementsStatus> => {
	if (!('providedReviewSummary' in answer)) return null;

	let recordingsReviews = Object.values(answer.providedReviewSummary);

	/**
	 * Should generate failed feedback if no recordings were reviewed
	 * Default (0) values won't meet the defined requirements
	 */
	if (recordingsReviews.length === 0) {
		recordingsReviews = [defaultReviewSummary];
	}

	const [successStatuses, failedStatuses] = getRecordingsRequirementsStatuses(
		providedReviewConfig,
		recordingsReviews
	);

	// If at least one recording met all the requirements, the feedback is successful
	return successStatuses.length > 0
		? first(successStatuses)
		: aggregateFailedStatus(failedStatuses);
};

const getPeerReviewFeedback = (
	nonSelfReviewConfig: ScoreSettingsConfig['completionOptions']['nonSelfReview'],
	answer: GoReactAnswerProps
): ReturnType<typeof getRequirementsStatus> => {
	if (!('peerReviewSummary' in answer)) return null;

	const { reviewsNumber: reviewedRecordingsRequired } = nonSelfReviewConfig;

	let recordingsReviews = Object.values(answer.peerReviewSummary);

	/**
	 * Should generate failed feedback if not enough recordings were reviewed
	 * Default (0) values won't meet the defined requirements
	 */
	if (recordingsReviews.length < reviewedRecordingsRequired) {
		recordingsReviews = [defaultReviewSummary];
	}

	const [successStatuses, failedStatuses] = getRecordingsRequirementsStatuses(
		nonSelfReviewConfig,
		recordingsReviews
	);

	// If enough reviewed recordings met all the requirements, the feedback is successful
	return successStatuses.length >= reviewedRecordingsRequired
		? first(successStatuses)
		: aggregateFailedStatus(failedStatuses);
};

const getRecordingsRequirementsStatuses = (
	reviewConfig:
		| ScoreSettingsConfig['completionOptions']['selfReview']
		| ScoreSettingsConfig['completionOptions']['nonSelfReview'],
	recordingsReviews: GoReactReviewSummary[]
): [
	successStatuses: Array<RequirementsStatus>, // All the requirements were met
	failedStatuses: Array<RequirementsStatus> // At least a single requirement wasn't met
] => {
	const {
		rubricSubmission: rubricRequired,
		commentsNumber: commentsRequired,
		markersNumber: markersRequired
	} = reviewConfig;

	const recordingsRequirementsStatuses = recordingsReviews.map((recordingReview) => {
		const { submittedRubricCount, freeformCommentCount, markerCount } = recordingReview;

		const requirementsStatus: RequirementsStatus = {};
		if (rubricRequired) {
			requirementsStatus.rubricSubmission = submittedRubricCount > 0;
		}
		if (commentsRequired > 0) {
			requirementsStatus.commentsNumber = freeformCommentCount >= commentsRequired;
		}
		if (markersRequired > 0) {
			requirementsStatus.markersNumber = markerCount >= markersRequired;
		}
		return requirementsStatus;
	});

	return partition(recordingsRequirementsStatuses, getRequirementsMet);
};

/**
 * Aggregates failed requirements from multiple recordings into a single completion status object
 *
 * @example
 * const failedRecordingsStatuses = [
 *   { commentsNumber: false, markersNumber: true },
 *   { commentsNumber: true, markersNumber: false },
 * ];
 * aggregateFailedStatus(failedRecordingsStatuses); // { commentsNumber: false, markersNumber: false }
 */
const aggregateFailedStatus = (
	failedRecordingsStatuses: RequirementsStatus[]
): RequirementsStatus =>
	failedRecordingsStatuses.reduce((failedStatus, status) => {
		const onlyFailedStatuses = omitBy(status, Boolean);
		return merge(failedStatus, onlyFailedStatuses);
	}, {});
