import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getNodeByKey, TextNode } from 'lexical';

import { CitationNode } from '../nodes/CitationNode';

export function CitationSpacerPlugin(): null {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([CitationNode])) {
			throw new Error('CitationSpacerPlugin: CitationNode not registered on editor');
		}

		/**
		 * Only for IOS devices
		 */
		if (!isIOS) return;

		const addTextNodeAfter = (nodeKey: string) => {
			editor.update(() => {
				const node = $getNodeByKey(nodeKey);
				if (!node) return;

				const nextNode = node.getNextSibling();
				if (nextNode instanceof TextNode) {
					return;
				}

				const textNode = $createTextNode(' ');
				node.insertAfter(textNode);
				/**
				 * Put selection to start to avoid visibility of the text node
				 */
				node.selectNext(0, 0);
			});
		};

		return editor.registerMutationListener(CitationNode, (mutatedNodes) => {
			// mutatedNodes is a Map where each key is the NodeKey, and the value is the state of mutation.
			for (const [nodeKey, mutation] of mutatedNodes) {
				if (mutation !== 'destroyed') {
					return addTextNodeAfter(nodeKey);
				}
			}
		});
	}, [editor]);

	return null;
}
