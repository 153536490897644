import React, { createContext, FC, useContext, useRef } from 'react';

import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';

interface DeckCollapseProps {
	deckFamilyId: string;
	deckStoreName?: string;
	initialCollapsedMap: DeckCollapseState['collapsedMap'];
}

interface DeckCollapseState {
	/**
	 * Maps index of an item to its collapsed state
	 * `true` - collapsed | `false` - expanded
	 */
	collapsedMap: boolean[];

	toggleCollapse: (index: number) => void;
	/**
	 * @returns an array of all the indexes that weren't collapsed but now are
	 */
	collapseAll: () => number[];
	/**
	 * @returns an array of all the indexes that were collapsed but now aren't
	 */
	expandAll: () => number[];
}

type DeckCollapseStore = ReturnType<typeof createDeckCollapseStore>;

const createDeckCollapseStore = (props: DeckCollapseProps) =>
	createWithEqualityFn<DeckCollapseState>()(
		devtools(
			immer((set, get) => ({
				collapsedMap: props.initialCollapsedMap,
				toggleCollapse: (index) =>
					set((state) => void (state.collapsedMap[index] = !state.collapsedMap[index])),
				collapseAll: () => {
					const willCollapseIndices = get()
						.collapsedMap.map((isCollapsed, index) => (!isCollapsed ? index : null))
						.filter((i) => i);
					set({ collapsedMap: Array(get().collapsedMap.length).fill(true) });
					return willCollapseIndices;
				},
				expandAll: () => {
					const willExpandIndices = get()
						.collapsedMap.map((isCollapsed, index) => (isCollapsed ? index : null))
						.filter((i) => i);
					set({ collapsedMap: Array(get().collapsedMap.length).fill(false) });
					return willExpandIndices;
				}
			})),
			{ name: props.deckStoreName || 'deck-collapse-store', store: props.deckFamilyId }
		)
	);

const DeckCollapseContext = createContext<DeckCollapseStore | null>(null);

export const DeckCollapseProvider: FC<DeckCollapseProps> = ({ children, ...props }) => {
	const storeRef = useRef<DeckCollapseStore>();
	if (!storeRef.current) {
		storeRef.current = createDeckCollapseStore(props);
	}
	return (
		<DeckCollapseContext.Provider value={storeRef.current}>{children}</DeckCollapseContext.Provider>
	);
};

export function useDeckCollapseSelector<T>(selector: (state: DeckCollapseState) => T): T {
	const store = useContext(DeckCollapseContext);
	if (!store) throw new Error('Missing DeckCollapseContext.Provider in the tree');

	return useStore(store, selector);
}

export const selectCollapsed = (state: DeckCollapseState, index: number): boolean =>
	state.collapsedMap[index];

export const selectAtLeastOneCollapsed = (state: DeckCollapseState): boolean =>
	state.collapsedMap.some(Boolean);
