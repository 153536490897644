import round from 'lodash-es/round';

import type { CoursePolicy, Policy } from '~/types/WebtextManifest';

export * from './axios';

export const Characters = {
	emdash: '\u2014'
};

export function unlinkify(html) {
	return html.replace(/<\/?a[^>]*>/gim, '');
}

export function clamp(val, min, max) {
	return Math.min(Math.max(val, min), max);
}

export function webtextLinkForPage(
	courseId: string | number,
	chapterId: string | number,
	pageId: string | number,
	host = ''
) {
	return `${host}/courses/${courseId}/traditional_book/chapters/${chapterId}/pages/${pageId}`;
}

export function getPolicyForRespondable(
	coursePolicy: CoursePolicy,
	respondableType: string,
	respondableFamilyId: string
): Policy {
	const instance = coursePolicy.instances[respondableFamilyId];
	let policy = coursePolicy.policies[instance];
	if (!policy) {
		const defaultPolicy = coursePolicy.defaults[respondableFamilyId];
		policy = coursePolicy.policies[defaultPolicy];
	}
	policy || (policy = coursePolicy.policies[coursePolicy.defaults[respondableType]]);
	return policy;
}

export function getPolicyForPage(coursePolicy: CoursePolicy, pageId: string): Policy {
	return getPolicyForRespondable(coursePolicy, 'NG::Traditional::Page', pageId);
}

export function elementVisibleOnScreen(element) {
	if (element == null) {
		return false;
	}

	let hidden;
	if (typeof document.hidden != 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
	} else if (typeof document['msHidden'] != 'undefined') {
		hidden = 'msHidden';
	} else if (typeof document['webkitHidden'] != 'undefined') {
		hidden = 'webkitHidden';
	}

	if (document[hidden]) {
		return false;
	}

	const elemTop = element.getBoundingClientRect().top;
	if (elemTop > 0 && elemTop < window.innerHeight) {
		return true;
	} else {
		// the element scrolled out of the viewport
		return false;
	}
}

export const URL_REGEX =
	/(http|ftp|https|):*\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?;[\]^=%&:/~+#-]*[\w@?;[\]^=%&/~+#-])?/;

/**
 * Random string generator inspired by: {@link https://gist.github.com/6174/6062387}
 */
export const generateID = (): string =>
	Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export { ObjectDebug } from './ObjectDebug';

export const isNullOrUndefined = (value: any): boolean =>
	typeof value === 'undefined' || value === null;

export const renameProp = (oldProp: string, newProp: string, { [oldProp]: old, ...others }) => ({
	[newProp]: old,
	...others
});

export const partitionItems = <T>(list: T[], n: number) => {
	const partitions: T[][] = [];
	const partitionLength = Math.ceil(list.length / n);

	for (let i = 0; i < list.length; i += partitionLength) {
		const partition = list.slice(i, i + partitionLength);
		partitions.push(partition);
	}

	return partitions;
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#rendering_engine
 * n.b. just detecting "Gecko" is insufficient b/c Chrome's UA has "like Gecko" in it;
 * need to match "Gecko/12345678" specifically
 */
export const isGecko = (): boolean => window.navigator.userAgent.match(/Gecko\/\S+/) != null;

/**
 * https://stackoverflow.com/a/70519514/10963661
 */
export const getOS = (): 'macos' | 'ios' | 'windows' | 'android' | 'linux' | 'test' | 'unknown' => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
	const windowsPlatforms = /(win32|win64|windows|wince)/i;
	const iosPlatforms = /(iphone|ipad|ipod)/i;
	const androidPlatforms = /android/i;
	const linuxPlatforms = /linux/i;
	const testEnvironment = /jsdom/i; // This name is defined in the `jest.config.js`

	// as of iOS 13, iPads now return the same user agent string as Macs.
	// in order to differentiate Macs from iPads we have to do a bit more work;
	// see https://stackoverflow.com/questions/56934826/distinguish-between-ipad-and-mac-on-ipad-with-ipados
	const isIPad = macosPlatforms.test(userAgent) && navigator.maxTouchPoints > 2;
	const isIOS = iosPlatforms.test(userAgent) || isIPad;
	const isMacOS = macosPlatforms.test(userAgent) && !isIPad;

	if (isMacOS) {
		return 'macos';
	}
	if (isIOS) {
		return 'ios';
	}
	if (windowsPlatforms.test(userAgent)) {
		return 'windows';
	}
	if (androidPlatforms.test(userAgent)) {
		return 'android';
	}
	if (linuxPlatforms.test(userAgent)) {
		return 'linux';
	}
	if (testEnvironment.test(userAgent)) {
		return 'test';
	}
	return 'unknown';
};

export function getQueryParams(qs) {
	qs = qs.split('+').join(' ');

	const params = {};
	let tokens;
	const re = /[?&]?([^=]+)=([^&]*)/g;

	while ((tokens = re.exec(qs))) {
		params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
	}

	return params;
}

export const getValueExistsInEnum = <T extends Record<string, unknown>>(
	type: T,
	value: unknown
): boolean => Object.values(type)?.includes(value);

/**
 * if <1% return raw value, if not return rounded value
 */
export function convertToRawPercentage(data: number, total: number, precision = 0): number {
	// don't divide by 0
	if (total === 0) return 0;

	const rawPercentage = (data / total) * 100;
	return rawPercentage < 1 ? rawPercentage : convertToPercentage(data, total, precision);
}

export function convertToPercentage(data: number, total: number, precision = 0): number {
	// don't divide by 0
	return total === 0 ? 0 : round((data / total) * 100, precision);
}

export const convertToCount = (data: number, total: number): number =>
	Math.round((data / 100) * total);

export const getIsTouchScreenDevice = (): boolean =>
	window.PointerEvent && navigator.maxTouchPoints > 0;
export const formatPercentageStr = (percent: number, precision = 1): string =>
	`${round(percent, precision)}%`;

export const convertToPercentageStr = (num: number, total: number, precision = 1): string =>
	`${convertToPercentage(num, total, precision)}%`;
