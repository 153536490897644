import React, { FC } from 'react';

import { css } from '@emotion/react';

import { ExpandablePanel } from '~/notebook/components/ExpandablePanel';
import { PopupQuizAnswer } from '~/notebook/types';

import { PopupQuizQuestion } from './types';

interface Props {
	questions: PopupQuizQuestion[];
	answers: PopupQuizAnswer[];
	className?: string;
}

const StudentAnswers: FC<Props> = (props) => {
	const { questions, answers, className } = props;

	const latestAnswer = answers[answers.length - 1];
	const data = latestAnswer && latestAnswer.reset_at == null ? latestAnswer.data : {};

	return (
		<ExpandablePanel title="Student Answers" className={className}>
			<div css={studentAnswersStyles.list} className="student-answers-list">
				{questions.map((question) => (
					<div
						key={question.family_id}
						css={studentAnswersStyles.listItem}
						className="student-answers-list-item">
						<div css={studentAnswersStyles.questionPrompt} className="question-prompt">
							{question.prompt}
						</div>
						<div>
							{question.choices.map((choice) => (
								<div
									key={choice.family_id}
									css={studentAnswersStyles.choicePrompt}
									className="choice-prompt">
									<input
										type="radio"
										id={`choice_${choice.family_id}`}
										checked={choice.family_id === data?.result[question.family_id]}
										disabled={true}
									/>
									<label
										htmlFor={`choice_${choice.family_id}`}
										dangerouslySetInnerHTML={{ __html: choice.prompt }}
									/>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</ExpandablePanel>
	);
};

export const studentAnswersStyles = {
	list: css`
		padding: 20px 0;
	`,
	listItem: css`
		:not(:last-child) {
			margin-bottom: 35px;
		}
	`,
	questionPrompt: css`
		font-weight: 500;
		font-size: 15px;
		margin-bottom: 15px;
	`,
	choicePrompt: css`
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 1.5;
		margin-bottom: 10px;

		input[type='radio'] {
			width: 20px;
			height: 20px;
		}

		label {
			margin-left: 14px;
		}
	`
};

export default StudentAnswers;
