import React from 'react';

export default function XlsxDownloadIcon(props) {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 46.3 65.3"
			className="xlsx-download-icon">
			<g fill={props.color}>
				<polygon points="33.7,57.7 33.7,42.9 29.7,42.9 29.7,57.7 22.6,50.5 19.7,53.3 31.7,65.3 43.7,53.3 40.9,50.5 " />
				<g>
					<rect x="12.5" y="34.1" width="10.5" height="4" />
					<rect x="27" y="34.1" width="10.5" height="4" />
					<rect x="12.5" y="26.7" width="10.5" height="4" />
					<rect x="27" y="26.7" width="10.5" height="4" />
				</g>
				<path
					d="M4.3,0v9.4h-4.5v13.5h4.5v24.5h18.6v-4H8.3V22.9h29.4V9.4H8.3V4h33.8v43.3h4V0H4.3z M26,12h2.4l1.6,2.4l1.6-2.4
	h2.3L31.2,16l2.9,4.1h-2.4l-1.7-2.5l-1.7,2.5h-2.3l2.9-4.1L26,12z M22.2,17c-1.5-0.4-2.7-0.8-2.7-2.5c0-1.5,1.2-2.5,2.9-2.5
	c1.2,0,2.2,0.3,3,1l0.1,0.1l-1.1,1.5l-0.1-0.1c-0.7-0.5-1.4-0.7-2-0.7c-0.5,0-0.9,0.2-0.9,0.5c0,0.4,0.2,0.5,1.6,0.9
	c1.6,0.4,2.6,1,2.6,2.5c0,1.6-1.2,2.6-3.1,2.6c-1.3,0-2.5-0.5-3.5-1.3l-0.1-0.1l1.2-1.4l0.1,0.1c0.8,0.6,1.5,0.9,2.3,0.9
	c0.6,0,1-0.2,1-0.6C23.7,17.5,23.6,17.3,22.2,17z M12.2,12h2v6.2h3.9v1.9h-6V12z M8.9,12h2.3L8.5,16l2.9,4.1H9l-1.7-2.5l-1.7,2.5
	H3.2L6.1,16l-2.7-4h2.4l1.6,2.4L8.9,12z"
				/>
			</g>
		</svg>
	);
}
