import * as React from 'react';

export function Document(): JSX.Element {
	return (
		<svg
			version="1.1"
			viewBox="0 0 99 100"
			width="1em"
			height="1em"
			xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fillRule="evenodd">
				<g transform="translate(2 2)">
					<path
						d="m94.426 48.001c0 25.988-21.066 47.055-47.054 47.055s-47.055-21.067-47.055-47.055c0-25.987 21.067-47.054 47.055-47.054s47.054 21.067 47.054 47.054"
						fill="white"
					/>
					<path
						d="m94.426 48.001c0 25.988-21.066 47.055-47.054 47.055s-47.055-21.067-47.055-47.055c0-25.987 21.067-47.054 47.055-47.054s47.054 21.067 47.054 47.054z"
						stroke="currentColor"
						strokeWidth="4"
					/>
					<path
						d="m88.975 48.001c0 22.978-18.626 41.604-41.603 41.604s-41.604-18.626-41.604-41.604c0-22.977 18.627-41.603 41.604-41.603s41.603 18.626 41.603 41.603"
						fill="currentColor"
					/>
					<path
						d="m64.18 68.459h-22.083c0.453-0.779 0.711-1.686 0.711-2.65h24.02c0 1.461-1.187 2.65-2.648 2.65zm-26.67 0c-1.461 0-2.649-1.188-2.649-2.649v-34.618c0-0.964-0.261-1.869-0.713-2.649h24.733c1.461 0 2.65 1.188 2.65 2.649v31.969h-22.146c0.523 1.584 0.774 1.915 0.774 2.649 0 1.461-1.188 2.649-2.649 2.649zm-5.299-34.617h-2.649c-1.461 0-2.649-1.189-2.649-2.65s1.188-2.649 2.649-2.649 2.649 1.188 2.649 2.649v2.65zm36.654 29.319h-4.685v-31.969c0-2.921-2.377-5.298-5.299-5.298h-29.319c-2.922 0-5.299 2.377-5.299 5.298 0 2.922 2.377 5.299 5.299 5.299h2.649v29.319c0 2.921 2.377 5.298 5.299 5.298h26.67c2.922 0 5.298-2.377 5.298-5.298 0-1.268-0.422-2.011-0.613-2.649z"
						fill="white"
					/>
					<polygon points="42.808 33.842 53.582 33.842 53.582 36.491 42.808 36.491" fill="white" />
					<polygon points="37.51 39.229 58.881 39.229 58.881 41.878 37.51 41.878" fill="white" />
					<polygon points="37.51 44.527 58.881 44.527 58.881 47.176 37.51 47.176" fill="white" />
					<polygon points="37.51 49.826 58.881 49.826 58.881 52.475 37.51 52.475" fill="white" />
					<polygon points="48.107 55.124 58.881 55.124 58.881 57.773 48.107 57.773" fill="white" />
				</g>
			</g>
		</svg>
	);
}
