import React, { FC, useMemo } from 'react';

import isEmpty from 'lodash-es/isEmpty';

import SectionView from '~/components/Outline/components/SectionView';
import { selectHierarchySection, useOutlineSelector } from '~/components/Outline/store';
import {
	OutlineChildrenSection,
	OutlineHierarchyItemType,
	OutlineInstanceAddress
} from '~/components/Outline/types';

interface Props {
	address: OutlineInstanceAddress;
	sectionsType?: OutlineHierarchyItemType;
}

const SectionsView: FC<Props> = (props) => {
	const { address, sectionsType = OutlineHierarchyItemType.Children } = props;

	const hierarchy = useOutlineSelector((state) => state.hierarchy);

	// Only children section can be a parent for the descendants
	const parentSection = useOutlineSelector((state) =>
		selectHierarchySection<OutlineChildrenSection>(state, address)
	);

	const descendantsSections = useMemo(() => {
		if (sectionsType === OutlineHierarchyItemType.Toggle) {
			return parentSection?.toggle;
		}

		return isEmpty(address)
			? hierarchy // Renders top-level hierarchy items templates
			: parentSection?.children;
	}, [address, hierarchy, parentSection?.children, parentSection?.toggle, sectionsType]);

	return (
		<>
			{descendantsSections?.map((item, index) => {
				const key = `${item.template}_${index}`;
				const sectionAddress = address.concat(
					sectionsType === OutlineHierarchyItemType.Children
						? { childrenIndex: index }
						: { toggleIndex: index }
				);
				return <SectionView key={key} address={sectionAddress} />;
			})}
		</>
	);
};

export default SectionsView;
