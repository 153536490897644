import {
	OutlineHierarchy,
	OutlineNumberedItemsStyle,
	OutlineTemplates,
	OutlineTemplatesConstraints
} from '~/components/Outline/types';

import { ChartType } from '../Charts/types';
import { RTESchemaOptions, ValidationOptions } from '../FillIn/types';
import { Citations } from '../FillIn/types/ICitations';
import { Sheet } from '../Spreadsheet/helpers/types';
import { Kinds, TextOptions } from '../WritingTemplate/types';
import { CitationsData } from './../FillIn/types/ICitationData';

import type { IRestrictions } from '../DocumentsLoader/DocumentsLoader';
export const restrictedInput = ['<p><div></div></p>', '<p></p>', '<div></div>'];

export type CitationLoader = (dest: string, citations: CitationsData[]) => void;

export type SaveResults = { [key: string]: Citations | string };

export type CitationState = { [key: string]: CitationsData[] };

export interface ChartConfig {
	useReference?: boolean;
	nameReference?: string;
	type?: ChartType;
}

export type ChartNameSource = { type: 'raw' | 'cell'; value: string; address?: string };

export interface DraftSaveOptions {
	auto?: boolean;
}

export type BuilderElementConfig =
	| OutlineElementConfig
	| TextElementConfig
	| ValueOfElementConfig
	| HRElementConfig
	| GapElementConfig
	| FillInElementConfig
	| SelectorElementConfig
	| SpreadsheetElementConfig
	| ChartElementConfig
	| TableElementConfig
	| DocumentLoaderElementConfig
	| MapElementConfig
	| GroupValidationMessageConfig
	| OldOutlineElementConfig;

interface OutlineElementConfig {
	kind: Kinds.Outline;
	templates: OutlineTemplates;
	hierarchy: OutlineHierarchy;
	numbered_items_style?: OutlineNumberedItemsStyle;
	templates_constraints?: OutlineTemplatesConstraints;
	validations?: Record<string, unknown>;
	dest: string;
	source?: string;
}

export interface TextElementConfig {
	kind: Kinds.Text;
	options?: TextOptions[];
	value: string;
}

interface ValueOfElementConfig {
	kind: Kinds.ValueOf;
	source: string;
	options?: TextOptions[];
}

interface HRElementConfig {
	kind: Kinds.HorizontalRule;
	units?: number;
}

interface GapElementConfig {
	kind: Kinds.Gap;
	units?: number;
}

interface FillInElementConfig {
	kind: Kinds.FillIn;
	dest: string;
	options?: RTESchemaOptions;
	default?: string;
	presentation?: string;
	validation?: ValidationOptions;
}

interface SelectorElementConfig {
	kind: Kinds.Select;
	source?: string;
	choices?: string[];
	choiceSource?: string;
	presentation?: string;
	dest: string;
}

interface SpreadsheetElementConfig {
	kind: Kinds.Spreadsheet;
	dest: string;
	sheet: Sheet;
}

interface ChartElementConfig {
	kind: Kinds.Chart;
	source: string;
	config: ChartConfig;
	dest: string;
	name?: string;
}

interface TableElementConfig {
	kind: Kinds.Table;
	rows: BuilderElementConfig[][];
	['column-widths']?: string[];
}

interface DocumentLoaderElementConfig {
	kind: Kinds.Image;
	dest: string;
	restrictions?: IRestrictions;
	fileRestrictionError?: string;
}

interface MapElementConfig {
	kind: Kinds.Map;
	source: string;
	dest: string;
	map: string[];
}

export interface GroupValidationMessageConfig {
	kind: Kinds.Group;
	presentation?: string;
	forDestinations?: string[];
}

interface OldOutlineElementConfig {
	kind: Kinds.List;
	items: BuilderElementConfig[];
}

export enum AllowedFooterButtons {
	Save = 'save'
}

export enum DividerType {
	Horizontal = 'horizontal',
	Space = 'space'
}
