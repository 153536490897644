import first from 'lodash-es/first';
import round from 'lodash-es/round';

import {
	getIsFormula,
	getRangeAddresses,
	getFormattingForCell,
	getIsRange,
	normalizeCellValue
} from '~/components/WritingTemplate/Spreadsheet/helpers/index';
import { getWordsNumber, isNumeric } from '~/utils/parsing';

import { StringDictionary } from '../../WritingTemplate/types';
import { isAccountingNegative, transformAccounting } from './formula';
import {
	CellFormulaValidations,
	CellNumberValidations,
	CellTextValidations,
	ContainsRangeValidation,
	EvaluatedCells,
	Formatting,
	IntersectsRangeValidation,
	RangeValidationRule,
	ValidationRule,
	ValidationRules,
	ValidationTypes
} from './types';

export const validator = (props: {
	validationRules: ValidationRules;
	cellValues: StringDictionary;
	selection: string[];
	globalFormatting: Formatting;
	evaluatedCells: EvaluatedCells;
}): {
	[address: string]: {
		messages: string[];
		validation: ValidationRule | RangeValidationRule;
	};
} => {
	const { validationRules, cellValues, selection, globalFormatting, evaluatedCells } = props;

	/**
	 * I assume that we don't need to validate each cell, because
	 * We're looking for first failed cell, that's why we're using `for` instead of `any array method`
	 * Iterate array until found first invalid cell
	 * Then stop and return validation result
	 */
	let validationResult: {
		[address: string]: {
			messages: string[];
			validation: ValidationRule | RangeValidationRule;
		};
	};

	for (let ruleIdx = 0; ruleIdx < validationRules.length; ruleIdx++) {
		const rule = validationRules[ruleIdx];

		const [target, validation] = first(Object.entries(rule));

		if (target === 'selection') {
			validationResult = validateSelection(selection, validation);
		} else {
			const cellValue = cellValues[target];
			switch (validation['value-type']) {
				case ValidationTypes.Number: {
					validationResult = validateNumber(target, cellValue, validation, globalFormatting);
					break;
				}
				case ValidationTypes.Formula: {
					validationResult = validateFormula({
						address: target,
						cellValues,
						validationRule: validation,
						evaluatedCells
					});
					break;
				}
				case ValidationTypes.Text: {
					validationResult = validateText(target, cellValue, validation);
					break;
				}
			}
		}

		/**
		 * First validation failed will return and validation process will stop.
		 */
		if (validationResult) return validationResult;
	}
};

/**
 * TODO: Make a reusable ValidatorArgs interface for the arguments
 * TODO: Made a reusable interface for the validators
 */
const validateNumber = (
	address: string,
	value: string,
	validationRule: CellNumberValidations,
	globalFormatting?: Formatting
) => {
	const cellFormatting = getFormattingForCell(address, globalFormatting);

	const isAccounting = isAccountingNegative(value, cellFormatting);
	if (isAccounting) {
		value = transformAccounting(value);
	}

	const { equal, less, greater, messages } = validationRule;

	const validationResult = {
		[address]: {
			messages: messages || [`Cell ${address} should contain a number`],
			validation: validationRule
		}
	};

	const isValueNumeric = isNumeric(value);
	if (!isValueNumeric && !isAccounting) {
		return validationResult;
	}

	if (isNumeric(equal)) {
		/**
		 * Need to normalize values before equals test because of possible rounding inconsistency
		 * E.g. ruleset: `equals: 0`, `accuracy: 0`. But the formula returns `-0.045` => `-0.045` !== 0 => error
		 */
		const normalizedEqual = normalizeCellValue(equal, cellFormatting);
		const normalizedValue = normalizeCellValue(value, cellFormatting);
		if (normalizedEqual !== normalizedValue) {
			return validationResult;
		}
	}

	if (isNumeric(less) && parseFloat(less) <= +value) {
		return validationResult;
	}

	if (isNumeric(greater) && parseFloat(greater) >= +value) {
		return validationResult;
	}

	return null;
};

const validateFormula = (props: {
	address: string;
	cellValues: StringDictionary;
	validationRule: CellFormulaValidations;
	evaluatedCells: EvaluatedCells;
}) => {
	const { address, cellValues, validationRule, evaluatedCells } = props;

	const value = cellValues[address];

	const { formula, result, strict, messages } = validationRule;

	const validationResult = {
		[address]: {
			messages: messages || [`Cell ${address} should contain a formula`],
			validation: validationRule
		}
	};

	const trimmedValue = strict
		? value.replace('=', '')
		: value.replace('=', '').replace(/\s/g, '').toUpperCase();

	const isFormula = getIsFormula(value);
	if (!isFormula) {
		return validationResult;
	}

	if (formula) {
		const { 'one-of': one, equal } = formula;

		if (equal) {
			const trimmedExpect = strict ? equal : equal.replace(/\s/g, '').toUpperCase();

			if (trimmedExpect !== trimmedValue) {
				return validationResult;
			}
		} else if (one) {
			const trimmedList = one.map((f) => (strict ? f : f.replace(/\s/g, '').toUpperCase()));

			if (!trimmedList.includes(trimmedValue)) {
				return validationResult;
			}
		}
	}

	if (result) {
		const formulaResult = evaluatedCells[address];
		if (!formulaResult?.success) {
			return {
				[address]: {
					messages: ['Calculations result includes errors, please check out formula.'],
					validation: validationRule
				}
			};
		}

		const numberCellValidationRules: CellNumberValidations = {
			'value-type': ValidationTypes.Number,
			...result
		};
		const numberValidationResult = validateNumber(
			address,
			`${round(formulaResult.result as number, 2)}`,
			numberCellValidationRules
		);
		if (numberValidationResult) {
			return validationResult;
		}
	}

	return null;
};

const validateText = (address: string, value: string, validationRule: CellTextValidations) => {
	const { 'word-count': wordCount, messages } = validationRule;

	if (wordCount) {
		if (!value) return null; // We cannot validate the number of words if a user has typed nothing yet

		const validationResult = {
			[address]: {
				messages: messages || [`Cell ${address} text words number should be limited`],
				validation: validationRule
			}
		};

		const wordsNumber = getWordsNumber(value);

		const { minimum: minWords, maximum: maxWords } = wordCount;
		const isLowerThanMinWords = isNumeric(minWords) && wordsNumber < minWords;
		const isHigherThanMaxWords = isNumeric(maxWords) && wordsNumber > maxWords;

		if (isLowerThanMinWords || isHigherThanMaxWords) {
			return validationResult;
		}
	}

	return null;
};

const validateSelection = (selection: string[], validation: RangeValidationRule) => {
	if (validation['contains-range']) {
		return validationContaining(selection, validation as ContainsRangeValidation);
	}

	if (validation['intersects-range']) {
		return validateIntersection(selection, validation as IntersectsRangeValidation);
	}

	return null;
};

const createValidationRange = (targetRange: string) => {
	/**
	 * TargetRange might be range or cell, but stored in a string
	 * trying to get array in both cases.
	 */
	return getIsRange(targetRange) ? getRangeAddresses(targetRange).value : [targetRange];
};

const validateIntersection = (selection: string[], validation: IntersectsRangeValidation) => {
	const { 'intersects-range': intersects, messages, negated } = validation;
	/**
	 * @param target
	 * @param array
	 * @returns boolean
	 *
	 * Checking intersections by simple including selection into target array
	 */
	const getIsIntersects = (target: string[], array: string[]) =>
		target.filter((element) => array?.includes(element)).length > 0;

	const intersectsRange = createValidationRange(intersects);
	const isIntersects = getIsIntersects(selection, intersectsRange);

	/**
	 * When negated true, intersection must be empty, bc we expect no intersection
	 * Otherwise, intersection always must include at least one value
	 *
	 * Default message will be shown only when messages array is not presented
	 */
	if ((negated && isIntersects) || (!negated && !isIntersects)) {
		return {
			selection: {
				messages: messages || [
					`Target range (${intersectsRange.join(' ')}) must ${negated && 'not'} intersect selection`
				],
				validation: validation
			}
		};
	}

	return null;
};

const validationContaining = (selection: string[], validation: ContainsRangeValidation) => {
	const { 'contains-range': contains, messages, negated } = validation as ContainsRangeValidation;

	/**
	 * @param target
	 * @param array
	 * @returns boolean
	 *
	 * Checking containing, by looking at every element included in array
	 */
	const getIsContains = (target: string[], array: string[]) =>
		array?.every((element) => target.includes(element));

	const containsRange = createValidationRange(contains);
	const isContains = getIsContains(selection, containsRange);

	/**
	 * When negated true, selection must not contains range
	 * Otherwise, selection must contains it fully
	 *
	 * Default message will be shown only when messages array is not presented
	 */
	if ((negated && isContains) || (!negated && !isContains)) {
		return {
			selection: {
				messages: messages || [
					`Selections range must ${negated && 'not'} contain range ${containsRange?.join(' ')}`
				],
				validation: validation
			}
		};
	}

	return null;
};
