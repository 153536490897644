import React, { FC, useMemo } from 'react';

import ActionMenu from '~/components/Outline/components/ActionMenu';
import {
	instanceView,
	instanceViewDecoration
} from '~/components/Outline/components/InstanceView/styles';
import { instanceViewTransform } from '~/components/Outline/components/InstanceView/transformStyles';
import Label from '~/components/Outline/components/Label';
import SectionsView from '~/components/Outline/components/SectionsView';
import TextField from '~/components/Outline/components/TextField';
import { getAddressDepth } from '~/components/Outline/helpers';
import {
	selectHierarchySection,
	selectReadonly,
	selectTemplate,
	selectTemplateDisableOutputLabel,
	selectVariant,
	useOutlineSelector
} from '~/components/Outline/store';
import {
	getHierarchyItemType,
	OutlineChildrenSection,
	OutlineHierarchyItemType,
	OutlineInstanceAddress,
	OutlineVariant,
	TemplateType
} from '~/components/Outline/types';

interface Props {
	address: OutlineInstanceAddress;
}

const InstanceView: FC<Props> = (props) => {
	const { address } = props;

	const template = useOutlineSelector((state) => selectTemplate(state, address));
	const {
		label_type: labelType,
		type,
		output: { transform }
	} = template;

	const section = useOutlineSelector((state) =>
		selectHierarchySection<OutlineChildrenSection>(state, address)
	);
	const { children: { length: childrenNumber } = [] } = section;

	const disableOutputLabel = useOutlineSelector((state) =>
		selectTemplateDisableOutputLabel(state, address)
	);

	const variant = useOutlineSelector(selectVariant);
	const readOnly = useOutlineSelector(selectReadonly);

	const depth = getAddressDepth(address);
	const sectionType = getHierarchyItemType(address);

	const showActionMenu = variant === OutlineVariant.EDIT && !readOnly;

	const textField = useMemo(() => {
		if (type !== TemplateType.FillIn) return;

		switch (variant) {
			case OutlineVariant.EDIT:
				return <TextField.Edit address={address} />;
			case OutlineVariant.VIEW:
				return <TextField.View address={address} />;
			case OutlineVariant.REVISE:
				return <TextField.Revise address={address} />;
		}
	}, [address, type, variant]);

	return (
		<>
			<div css={instanceViewDecoration({ depth, variant, sectionType, childrenNumber })}>
				<div
					css={(theme) => [
						instanceView({ labelType, variant, sectionType, disableOutputLabel }),
						instanceViewTransform({ transform, variant, theme })
					]}
					className={`outline-instance outline-instance-${sectionType}`}>
					<Label address={address} />
					{showActionMenu && <ActionMenu address={address} />}
					{textField}
				</div>
				<SectionsView sectionsType={OutlineHierarchyItemType.Toggle} address={address} />
			</div>
			<SectionsView sectionsType={OutlineHierarchyItemType.Children} address={address} />
		</>
	);
};

export default InstanceView;
