import * as React from 'react';

export function HeadWithArrows(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<path d="M48.72 63.9h-1.78V48.5a.88.88 0 0 1 .26-.63A15.78 15.78 0 1 0 20.31 36.7v.72a.82.82 0 0 1-.12.48l-4.61 8h2.33a.89.89 0 0 1 .89.89v6.61a3.6 3.6 0 0 0 3.61 3.6h7.11a.89.89 0 0 1 .89.89v6h-1.78v-5.1h-6.22A5.39 5.39 0 0 1 17 53.4v-5.7h-3a.9.9 0 0 1-.77-1.34l5.25-9.13v-.53a17.52 17.52 0 1 1 30.24 12.16v15ZM38.94 13.23l-2.59-2.61-2.6 2.61-1.26-1.26 3.86-3.87 3.85 3.87-1.26 1.26z" />
			<path d="M35.46 25.05V10.23h1.78v14.82h-1.78zM17.59 21.73h-1.78v-5.38h5.35v1.78h-3.57v3.6z" />
			<path d="M27.03 28.85 16.68 18.48l1.26-1.25L28.29 27.6l-1.26 1.25zM10.95 40.64 7.1 36.77l3.85-3.87 1.26 1.25-2.6 2.62 2.6 2.61-1.26 1.26z" />
			<path d="M9.22 37.66v-1.78h14.22v1.78H9.22zM61.05 41.34l-1.26-1.26 2.6-2.61-2.6-2.62 1.26-1.26 3.85 3.88-3.85 3.87z" />
			<path d="M48.56 38.36v-1.78h14.22v1.78H48.56zM54.91 22.22l-.01-3.59-3.57-.01v-1.78l5.35.01v5.37h-1.77z" />
			<path d="m46.03 28.78-1.26-1.26 9.78-9.8 1.26 1.26-9.78 9.8zM36 45a8.78 8.78 0 1 1 8.78-8.77A8.78 8.78 0 0 1 36 45Zm0-15.84a7.07 7.07 0 1 0 7.07 7.07A7.08 7.08 0 0 0 36 29.14Z" />
		</svg>
	);
}
