import React, { FC, useMemo } from 'react';

import {
	getContextualInfoIcon,
	getContextualInfoId
} from '~/components/pageElements/PollQuestion/utils';

import { calculateCellJsxContent, emptyCellJsxContent, getColumnCellClass } from './utils';

import type { Props as ComparisonTableProps } from './index';

const SmallComparisonTable: FC<ComparisonTableProps> = (props) => {
	const {
		courseNumber = '',
		classData,
		dataType,
		classTotal,
		totalCount,
		contextualInfo,
		shapedData,
		totalPercentageSums
	} = props;

	const datasets = shapedData[0];

	const totalRows = useMemo(
		() => (
			<>
				<tr className="poll-data-small-total-row">
					<th scope="row" className="poll-data-total-row-label">
						Total class responses
					</th>
					<td>{classTotal}</td>
					<td className="total-row-percentage">{totalPercentageSums[0]}%</td>
				</tr>
				{totalCount.map((total, totalIndex) => (
					<tr className="poll-data-small-total-row" key={`total-row-${totalIndex}`}>
						<th scope="row" className="poll-data-total-row-label">{`Total ${
							datasets[totalIndex + 1]
						} responses`}</th>
						<td>{total ? Number(total).toLocaleString('en-US') : emptyCellJsxContent}</td>
						<td className="total-row-percentage">{totalPercentageSums[totalIndex + 1]}%</td>
					</tr>
				))}
			</>
		),
		[classTotal, datasets, totalCount, totalPercentageSums]
	);

	const shapedDataWithoutDatasetNames = shapedData.slice(1);

	return (
		<>
			{shapedDataWithoutDatasetNames.map((row, rowIndex) => {
				return (
					<table key={`table-${rowIndex}`}>
						<caption
							className="poll-data-small-choice-header"
							dangerouslySetInnerHTML={{ __html: classData[rowIndex].label }}
						/>
						<thead>
							<tr>
								<th scope="col" className="poll-data-header-column-label">
									Data Set
								</th>
								<th scope="col" className="poll-data-header-column-label">
									Responses
								</th>
								<th scope="col" className="poll-data-header-column-label">
									Percentage
								</th>
							</tr>
						</thead>
						<tbody>
							{row.map((colValue, colIdx) => {
								const info = contextualInfo?.[colIdx - 1];
								const icon = getContextualInfoIcon(colIdx - 1);

								const { numberCellContent, percentageCellContent } = calculateCellJsxContent({
									colIdx,
									colValue: colValue as number,
									dataType,
									totalCount,
									classTotal
								});

								return (
									<tr key={`row-${colIdx}`}>
										{colIdx === 0 ? (
											<th
												scope="row"
												className={getColumnCellClass(colIdx)}
												aria-label={`Your Class ${courseNumber}`}>
												Your Class
												<div className="course-number">{courseNumber}</div>
											</th>
										) : (
											<th
												scope="row"
												className={getColumnCellClass(colIdx)}
												aria-label={datasets[colIdx] as string}
												aria-describedby={getContextualInfoId(colIdx)}>
												{datasets[colIdx]}
												{info && icon && <sup aria-hidden>{icon}</sup>}
											</th>
										)}

										<td className={getColumnCellClass(colIdx)}>{numberCellContent}</td>
										<td className={getColumnCellClass(colIdx)}>{percentageCellContent}</td>
									</tr>
								);
							})}
							{shapedDataWithoutDatasetNames.length - 1 === rowIndex && totalRows}
						</tbody>
					</table>
				);
			})}
		</>
	);
};

export default SmallComparisonTable;
