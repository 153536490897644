import { css, SerializedStyles } from '@emotion/react';

import { TemplateLabelType } from '~/components/Outline/types';
import { breakpoints } from '~/styles/themes';

export const fieldBlockDimensions = css`
	width: calc(100% - var(--indentation));
	margin-left: var(--indentation);
`;

export const fieldInlineDimensions = css`
	width: 100%;
	margin-left: 0;
`;

export const textField = (options: {
	labelType: TemplateLabelType;
	disableOutputLabel?: boolean;
}): SerializedStyles => {
	const { labelType, disableOutputLabel } = options;

	return css`
		grid-area: text-field;

		${disableOutputLabel
			? fieldInlineDimensions
			: css`
					${labelType === 'block' ? fieldBlockDimensions : fieldInlineDimensions};
					@media (max-width: ${breakpoints.small}) {
						${fieldBlockDimensions}; // On small screens all instances are displayed as block
					}
			  `}

		font: inherit;
		font-size: 0.95em;
	`;
};
