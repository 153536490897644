import './_redirects';
import './robots.txt';
import './globals.scss';
import 'react-virtualized/styles.css';
import 'babel-polyfill';

import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Provider as ReduxQueryProvider } from 'redux-query-react';

import { StylesProvider } from '@material-ui/core/styles';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { listenForHistoryChange } from 'redux-location-state';
import { getQueries } from './reducers';
import store from './store';
import ErrorBoundary from 'Components/ErrorBoundary';
import Routing, { history } from './navigation/Routing';

import { soomoMuiTheme, soomoTheme } from 'Theme/theme';

// Answers built here might not have IDs.
// https://github.com/soomo/soomo/blob/staging/app/services/answering/multiple_choice_answering_service.rb
// This is the result of the mobile app coming online and syncing with later answers.
// Therefore, when we consume answer objects in notebook, we use this decrementing syntheticId to assign new UUIDs
// It's a negative value to avoid collisions with any existing data
window.syntheticId = -1;
window.getSyntheticId = () => --window.syntheticId;

/**
 * Inject @emotion styles before custom ones
 * to avoid overriding MUI styles with `!important`
 *
 * @see https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-adjust-css-injection-order
 */
const MuiStylesProvider: FC = (props) => (
	<StyledEngineProvider injectFirst>{props.children}</StyledEngineProvider>
);

/**
 * The `@soomo/lib` package still depends on `@material-ui/core`.
 * By default, when MUI's components are imported,
 * they'll inject global classes at the end of the document head.
 * Unfortunately, this causes specificity conflicts with the newer
 * `@mui/material` classes specificity, overriding custom styles.
 *
 * @see https://github.com/soomo/soomo/pull/10134
 */
const MaterialCoreStylesProvider: FC = (props) => (
	<StylesProvider injectFirst>{props.children}</StylesProvider>
);

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<MuiStylesProvider>
				<MaterialCoreStylesProvider>
					<ThemeProvider theme={soomoMuiTheme}>
						<ThemeProvider theme={soomoTheme}>
							<Provider store={store}>
								<ReduxQueryProvider queriesSelector={getQueries}>
									<Router history={history}>
										<Routing />
									</Router>
								</ReduxQueryProvider>
							</Provider>
						</ThemeProvider>
					</ThemeProvider>
				</MaterialCoreStylesProvider>
			</MuiStylesProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('app')
);

// redux-location-state location tracker
listenForHistoryChange(store, history);
