import React, { FC } from 'react';

import { css, ClassNames } from '@emotion/react';

import { Theme } from '~/styles/themes';
import { formatTimeShort } from '~/utils/formatting';

export interface Props {
	subject?: 'answer' | 'comment';
	timestamp?: string;
	timeZone?: string;
	className?: string;
}

const ActivityHeader: FC<Props> = (props) => {
	const { subject, timestamp, timeZone, className, children } = props;
	return (
		<ClassNames>
			{({ cx }) => (
				<div
					data-test="activity-header"
					data-test-subject={subject || 'answer'}
					css={activityHeaderStyles.self}
					className={cx(className, 'activity-header')}>
					{timestamp && (
						<div
							data-test="activity-header-timestamp"
							css={activityHeaderStyles.timestamp}
							className="activity-header-timestamp">
							{formatTimeShort({
								time: timestamp,
								timeZone,
								withoutTimeZone: true
							})}
						</div>
					)}
					<div
						data-test="activity-header-status"
						css={activityHeaderStyles.status}
						className="activity-header-status">
						{children}
					</div>
				</div>
			)}
		</ClassNames>
	);
};

const activityHeaderStyles = {
	self: css`
		margin-top: 24px;
		display: flex;
		align-items: center;
	`,
	timestamp: (theme: Theme) => css`
		position: absolute;
		width: var(--timestamp-width);
		color: ${theme.colors['evil-gray']};
		font-size: 13px;
	`,
	status: (theme: Theme) => css`
		margin-left: var(--timestamp-width);
		color: ${theme.colors['evil-gray']};
		font-size: 13px;
		letter-spacing: 0.5px;
	`
};

export default ActivityHeader;
