import React, { useEffect, useState, useCallback } from 'react';

import difference from 'lodash-es/difference';
import isEqual from 'lodash-es/isEqual';
import { shallow } from 'zustand/shallow';

import { usePrevious } from '~/hooks';

import {
	getAnnounceForSelect,
	getAnnounceValue,
	getNamedRegionText,
	isNamedRegions
} from '../../helpers/accessibility';
import { Cell, Formatting, Sheet } from '../../helpers/types';
import { useSpreadsheetSelector } from '../../store/provider';
import { hiddenAccessible } from './styles';

interface Props {
	sheetCells: Record<string, Cell>;
	globalFormatting: Formatting;
	sheet: Sheet;
}

const AriaArea: React.FC<Props> = ({ sheetCells, globalFormatting, sheet }) => {
	const [announcement, setAnnouncement] = useState('');

	const {
		announcementSR,
		selectedCell,
		selectedCells,
		cellValues,
		editableRanges,
		invalidCell,
		evaluatedFormulasValues,
		onBlurErrorMessages
	} = useSpreadsheetSelector(
		(state) => ({
			announcementSR: state.announcementSR,
			selectedCell: state.selectedCell,
			selectedCells: state.selectedIndexes,
			cellValues: state.cellValues,
			editableRanges: state.editableRanges,
			invalidCell: state.invalidCell,
			evaluatedFormulasValues: state.evaluatedFormulasValues,
			onBlurErrorMessages: state.onBlurErrorMessages
		}),
		shallow
	);

	const previouslySelectedCell = usePrevious(selectedCell);
	const previouslySelectedCells = usePrevious(selectedCells);

	useEffect(() => {
		setAnnouncement(announcementSR);
	}, [announcementSR]);

	/**
	 * Reset the content of aria-area after the 1s delay and if user doing something inside the SPI don't reset anything
	 */
	useEffect(() => {
		const resetTimeout = setTimeout(() => setAnnouncement(''), 1000);

		return () => {
			clearTimeout(resetTimeout);
		};
	}, [announcementSR, selectedCell, selectedCells]);

	useEffect(() => {
		if (!selectedCell || selectedCell === previouslySelectedCell) return;

		const sheetCell = sheetCells[selectedCell];
		const { placeholderAnnouncement = '', placeholder = '' } = sheetCell;

		const cellValue = cellValues[selectedCell];
		const value = getAnnounceValue({
			selectedCell,
			cellValue,
			sheetFormatting: globalFormatting,
			evaluatedCells: evaluatedFormulasValues,
			onBlurErrorMessages
		});

		const cellText = value || `${placeholderAnnouncement} ${placeholder}`;

		const namedRegionsText = getNamedRegionText(
			selectedCell,
			previouslySelectedCell,
			isNamedRegions(sheet),
			sheet,
			cellValues
		);

		const isCellValid = invalidCell === selectedCell ? 'invalid' : '';
		const isEditable = editableRanges[selectedCell] ? 'editable' : 'not-editable';

		setAnnouncement(`${selectedCell} ${namedRegionsText} ${isCellValid} ${cellText} ${isEditable}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedCell,
		previouslySelectedCell,
		editableRanges,
		globalFormatting,
		sheet,
		sheetCells,
		onBlurErrorMessages
	]);

	useEffect(() => {
		if (selectedCells.length < 2 || isEqual(previouslySelectedCells, selectedCells)) return;

		let announcement;

		const diff = difference(
			selectedCells.filter((e) => e !== selectedCell),
			previouslySelectedCells
		);

		if (diff.length !== 1) {
			announcement = getAnnounceForSelect(
				selectedCells,
				cellValues,
				globalFormatting,
				evaluatedFormulasValues
			);
		} else {
			const addToSelection = diff[0];

			const cellValue = cellValues[addToSelection];

			const value = getAnnounceValue({
				selectedCell: addToSelection,
				cellValue,
				sheetFormatting: globalFormatting,
				evaluatedCells: evaluatedFormulasValues,
				onBlurErrorMessages
			});

			const namedRegionsText = getNamedRegionText(
				addToSelection,
				selectedCell,
				isNamedRegions(sheet),
				sheet,
				cellValues
			);

			const isEditable = editableRanges[addToSelection] ? 'editable' : 'not-editable';

			announcement = `${addToSelection} ${namedRegionsText} ${value} ${isEditable} selected`;
		}

		setAnnouncement(announcement);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		editableRanges,
		globalFormatting,
		previouslySelectedCells,
		selectedCell,
		selectedCells,
		sheet,
		onBlurErrorMessages
	]);

	return (
		<div
			aria-live="assertive"
			role="log"
			aria-hidden="false"
			aria-atomic="true"
			css={hiddenAccessible}>
			{announcement}
		</div>
	);
};

export default AriaArea;
