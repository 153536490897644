import type React from 'react';
import { FaSearch } from 'react-icons/fa';
import { GrStar } from 'react-icons/gr';

import { type Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { HistoricalClaim, Lightbulb } from './icons';
import BigIdeaStyles from './styles';
import { type Props as TextProps } from '../../Text';
import { checkForSpecialCase } from '../../utils';

interface Props {
	element?: Element;
	textProps: TextProps;
}

export type BigIdeaIcon = 'lightbulb' | 'search' | 'historical-claim' | 'central-idea';

const BigIdea: React.FC<Props> = (props) => {
	const { element, textProps } = props;

	const iconMap: { [key in BigIdeaIcon]: JSX.Element } = {
		lightbulb: <Lightbulb />,
		search: <FaSearch />,
		'historical-claim': <HistoricalClaim />,
		'central-idea': <GrStar />
	};

	return (
		<div css={BigIdeaStyles}>
			{domToReact([element], {
				replace: (domNode: Element) => {
					const { attribs } = domNode;
					if (attribs?.class?.match('big-idea-icon-circle')) {
						const iconName = attribs['data-icon'];
						const Icon = iconMap[iconName];
						return <div className="big-idea-icon-circle">{Icon}</div>;
					}

					if (attribs?.class === 'big-idea-title') {
						return (
							<div className="big-idea-title" role="heading" aria-level={4}>
								{domToReact(domNode.children)}
							</div>
						);
					}

					return checkForSpecialCase(domNode, textProps);
				}
			})}
		</div>
	);
};

export default BigIdea;
