import React, { ReactElement } from 'react';

import { dividerStyles, gapStyle } from '../styles';

export const onShowGap = (units = 0): ReactElement => (
	<div css={() => gapStyle(Math.max(0, units))} />
);

export const onShowHorizontalRule = (units = 0): ReactElement => (
	<hr css={(theme) => dividerStyles(theme, Math.max(0, units))} aria-hidden />
);
