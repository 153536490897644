import cloneDeep from 'lodash-es/cloneDeep';
import defaultsDeep from 'lodash-es/defaultsDeep';
import merge from 'lodash-es/merge';
import mergeWith from 'lodash-es/mergeWith';

import {
	defaultChildrenResponseInstance,
	defaultOutlineConfig,
	defaultResponseSection,
	defaultTemplate,
	defaultToggleResponseInstance
} from '~/components/Outline/fixtures';
import {
	OutlineChildrenResponseInstance,
	OutlineConfig,
	OutlineResponseSection,
	OutlineResponsesState,
	OutlineToggleResponseInstance
} from '~/components/Outline/types';

// Prevents mutation of the item response
export const getDefaultResponseSection = (): Required<OutlineResponseSection> =>
	cloneDeep(defaultResponseSection);

// Prevents mutation of the instance response
export const getDefaultChildrenResponseInstance = (): OutlineChildrenResponseInstance =>
	cloneDeep(defaultChildrenResponseInstance);

// Prevents mutation of the toggle response
export const getDefaultToggleResponseInstance = (): OutlineToggleResponseInstance =>
	cloneDeep(defaultToggleResponseInstance);

export const getOutlineConfigWithDefault = (
	config: OutlineConfig,
	responses: OutlineResponsesState
): Required<OutlineConfig> => {
	const {
		templates: configTemplates,
		hierarchy: configHierarchy,
		numberedItemsStyle: configNumberedItemsStyle,
		templatesConstraints: configTemplatesConstraints,
		validations: configValidations
	} = config;

	const {
		templates: responsesTemplates,
		numberedItemsStyle: responsesNumberedItemsStyle,
		templatesConstraints: responsesTemplatesConstraints,
		validations: responsesValidations
	} = responses;

	const {
		numberedItemsStyle: defaultNumberedItemsStyle,
		templatesConstraints: defaultTemplatesConstraints
	} = defaultOutlineConfig;

	const extendedTemplates = getExtendedConfigProp(
		configTemplates,
		responsesTemplates,
		defaultTemplate
	);
	const numberedItemsStyleWithDefault =
		configNumberedItemsStyle || responsesNumberedItemsStyle || defaultNumberedItemsStyle;
	const templatesConstraintsWithDefault =
		configTemplatesConstraints || responsesTemplatesConstraints || defaultTemplatesConstraints;
	const extendedValidations = getExtendedConfigProp(configValidations, responsesValidations);

	return {
		templates: extendedTemplates,
		hierarchy: configHierarchy,
		numberedItemsStyle: numberedItemsStyleWithDefault,
		templatesConstraints: templatesConstraintsWithDefault,
		validations: extendedValidations
	};
};

const getExtendedConfigProp = <T extends Record<string, unknown>>(
	configProp?: T,
	responsesProp?: T,
	defaultValue?: Partial<T[string]>
): T => {
	if (!configProp) {
		return responsesProp || ({} as T);
	}

	return mergeWith(
		responsesProp,
		configProp,
		(responsesPropValue, configPropValue, _key, _obj, _src, stack) => {
			if (stack.size !== 0) return; // Apply `customizer` only to the top-level objects
			if (!configPropValue) return; // Use responses' value if config's value isn't present

			if (responsesPropValue) {
				return merge(responsesPropValue, configPropValue); // Override the responses' value
			}

			// In the mobile app, config's properties can arrive as frozen objects
			const extensibleValue = Object.isExtensible(configPropValue)
				? configPropValue
				: cloneDeep(configPropValue);
			return defaultsDeep(extensibleValue, defaultValue); // Populate config's value with the default
		}
	);
};
