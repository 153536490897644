import React, {
	Children,
	cloneElement,
	isValidElement,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
	VFC
} from 'react';

import { css } from '@emotion/react';

import { WebtextButton } from '~/components';
import { PollDeckIcon } from '~/components/icons';
import { UniversalVelvetLeftBorder } from '~/components/pageElements';
import {
	isPollResultsAliasProps,
	PollProps,
	PollResultDisplayStyle,
	PollResultsAliasProps
} from '~/components/pageElements/PollQuestion/types';
import UnfinishedPolls from '~/components/pageElements/PollResultsAlias/UnfinishedPolls';
import { QuestionType, WebtextQuestion } from '~/components/shared/Question';
import {
	DeckCollapseProvider,
	selectAtLeastOneCollapsed,
	useDeckCollapseSelector as useCollapseSelector
} from '~/components/shared/QuestionDeck/DeckCollapseStateProvider';
import { useIsUniversalVelvet } from '~/hooks';
import { breakpoints } from '~/styles/themes';
import { DisplayedContent } from '~/types';

import { DisplayedQuestionElementProps } from '../MultipleChoiceQuestionPool/types';

import type { DeckedPollInjectedProps } from '~/components/pageElements/PollsDeck/DeckedPoll';

export type Props = {
	familyId: string;
	pollResultDisplayStyle: PollResultDisplayStyle;
	pollElementsProps: Array<PollProps | PollResultsAliasProps>;
	instructorView: boolean;
	children: ReactNode;
	onAllQuestionsLoaded?: (content: DisplayedContent) => void;
	onVisibilityChange: DisplayedQuestionElementProps['onVisibilityChange'];
} & Omit<DisplayedQuestionElementProps, 'onInitialLoadComplete'>;

const PollsDeck: VFC<Props> = (props) => {
	const {
		familyId,
		pollResultDisplayStyle,
		pollElementsProps,
		instructorView,
		children,
		onAllQuestionsLoaded,
		onVisibilityChange
	} = props;

	const pollDeckRef = useRef<HTMLDivElement | null>(null);

	const aliasesProps = useMemo(
		() => pollElementsProps.filter(isPollResultsAliasProps),
		[pollElementsProps]
	);
	const aliasesCount = aliasesProps.length;

	const unfinishedQuestionAliases = useMemo(
		() => aliasesProps.filter((element) => !element.targetPollResults.answer),
		[aliasesProps]
	);

	const showMissedDependencies =
		!instructorView &&
		pollResultDisplayStyle === PollResultDisplayStyle.ALL &&
		unfinishedQuestionAliases.length > 0;
	const showHeaderCollapseControls = aliasesCount > 0 && !showMissedDependencies;

	const initialCollapsedMap = getInitialCollapsedMap(props);
	useEffect(() => {
		if (onAllQuestionsLoaded == null) {
			return;
		}
		const displayedContent = {
			wholeItems: [],
			stems: []
		};
		initialCollapsedMap
			.filter((_, index) => !isPollResultsAliasProps(pollElementsProps[index]))
			.forEach((collapsed, index) => {
				if (collapsed) {
					displayedContent.stems.push(pollElementsProps[index].questionFamilyId);
				} else {
					displayedContent.wholeItems.push(pollElementsProps[index].questionFamilyId);
				}
			});
		onAllQuestionsLoaded(displayedContent);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const headingId = `polls-deck-heading-${familyId}`;
	const childrenArray = Children.toArray(children);
	return (
		<div ref={pollDeckRef} css={styles} role="region" aria-labelledby={headingId}>
			<WebtextQuestion>
				<UniversalVelvetLeftBorder>
					<DeckCollapseProvider
						deckFamilyId={familyId}
						deckStoreName="polls-deck-collapse-store"
						initialCollapsedMap={initialCollapsedMap}>
						<PollsDeckHeader
							id={headingId}
							showCollapseControls={showHeaderCollapseControls}
							pollElementsProps={pollElementsProps}
							onVisibilityChange={onVisibilityChange}
						/>
						<div className="questions">
							{showMissedDependencies ? (
								<UnfinishedPolls missedQuestionsProps={unfinishedQuestionAliases} />
							) : (
								<>
									{Children.map(
										childrenArray,
										(child, index) =>
											isValidElement(child) &&
											cloneElement<DeckedPollInjectedProps>(child, { deckIndex: index })
									)}
								</>
							)}
						</div>
					</DeckCollapseProvider>
				</UniversalVelvetLeftBorder>
			</WebtextQuestion>
		</div>
	);
};

const PollsDeckHeader: VFC<{
	id: string;
	showCollapseControls: boolean;
	pollElementsProps: Array<PollProps | PollResultsAliasProps>;
	onVisibilityChange: DisplayedQuestionElementProps['onVisibilityChange'];
}> = ({ id, showCollapseControls, pollElementsProps, onVisibilityChange }) => {
	const isUniversalVelvet = useIsUniversalVelvet();
	const deckSize = useCollapseSelector((state) => state.collapsedMap.length);
	const atLeastOneCollapsed = useCollapseSelector(selectAtLeastOneCollapsed);
	const collapseAll = useCollapseSelector((state) => state.collapseAll);
	const expandAll = useCollapseSelector((state) => state.expandAll);

	return (
		<div className="deck-header">
			<QuestionType id={id}>
				{isUniversalVelvet && <PollDeckIcon />}
				<span className="deck-title">{deckSize} Poll Questions</span>
			</QuestionType>
			{showCollapseControls && (
				<WebtextButton
					variant="text"
					className="deck-collapse"
					onClick={
						atLeastOneCollapsed
							? () => {
									expandAll().forEach(
										(index) =>
											onVisibilityChange?.({
												familyId: pollElementsProps[index].questionFamilyId,
												scope: 'toggle_all',
												collapsed: false
											})
									);
							  }
							: () => {
									collapseAll().forEach(
										(index) =>
											onVisibilityChange?.({
												familyId: pollElementsProps[index].questionFamilyId,
												scope: 'toggle_all',
												collapsed: true
											})
									);
							  }
					}>
					{atLeastOneCollapsed ? 'Expand' : 'Collapse'} All
				</WebtextButton>
			)}
		</div>
	);
};

export default PollsDeck;

/**
 * ## Question deck containing polls
 * 	- If the user is a student: only the first unanswered item in the deck is expanded. If all items are answered, they are all collapsed
 * 	- For all other users: only the first item in the deck is expanded
 *
 * ## Alias-only deck
 * 	- all the items are expanded
 *
 * @see https://soomo.height.app/T-94166
 */
function getInitialCollapsedMap(
	props: Pick<Props, 'pollElementsProps' | 'instructorView'>
): boolean[] {
	const { instructorView, pollElementsProps } = props;

	const deckSize = pollElementsProps.length;
	let collapsedMap: boolean[];

	const isAllPollResultsAliases = pollElementsProps.every(isPollResultsAliasProps);
	if (isAllPollResultsAliases) {
		collapsedMap = Array(deckSize).fill(false); // all expanded
	} else {
		collapsedMap = Array(deckSize).fill(true); // all collapsed

		const unansweredItemIndex = pollElementsProps.findIndex(
			(pollElement) =>
				!(pollElement as PollProps).answer &&
				!(pollElement as PollResultsAliasProps).targetPollResults?.answer
		);
		const expandedItemIndex = instructorView ? 0 : unansweredItemIndex;
		if (expandedItemIndex !== -1) {
			collapsedMap[expandedItemIndex] = false;
		}
	}

	return collapsedMap;
}

const styles = (theme) => css`
	// WebtextQuestion inner div
	.webtext-question-universal-velvet > div {
		@media (max-width: ${breakpoints.small}) {
			padding-right: 0.25rem;
		}
	}

	.deck-header {
		display: grid;
		grid-template: 'heading collapse';
		align-content: center;
		justify-content: space-between;
		margin-bottom: 28px;

		@media (max-width: ${breakpoints.small}) {
			grid-template:
				'heading heading'
				'collapse collapse';
		}

		[role='heading'] {
			grid-area: heading;

			display: flex;
			align-items: center;
			column-gap: 0.5rem;
			margin-bottom: 0;
			font-size: ${theme.webtextQuestion.questionType.fontSize};
			white-space: nowrap;

			@media (max-width: ${breakpoints.small}) {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		button.deck-collapse {
			grid-area: collapse;
			justify-self: end;

			width: auto;
			padding: 0;
			margin: 0;
			font-size: ${theme.webtextQuestion.questionPrompt.fontSize};
			font-weight: 400;
		}
	}

	.poll-icon {
		margin: 0;
	}

	.questions {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;

		@media (max-width: ${breakpoints.small}) {
			margin-top: 1rem;
		}
	}
`;
