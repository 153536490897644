import { getFeedbackMessage } from '~/components/pageElements/GoReact/helpers/feedback/message';
import { AnswerFeedback, ScoreSettingsConfig } from '~/components/pageElements/GoReact/types';
import { GoReactAnswerProps } from '~/types';

import { getRequirementsMet, getRequirementsStatus } from './requirementsStatus';

export const getAnswerFeedback = (
	scoringConfig: ScoreSettingsConfig,
	answer: GoReactAnswerProps
): AnswerFeedback | null => {
	const requirementsStatus = getRequirementsStatus(scoringConfig, answer);
	if (!requirementsStatus) return null;

	const message = getFeedbackMessage(scoringConfig, requirementsStatus);
	if (!message) return null;

	return {
		requirementsMet: getRequirementsMet(requirementsStatus),
		message
	};
};
