import { css } from '@emotion/react';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import { mixins, breakpoints } from '~/styles/themes';

export const pollResultsStyles = (theme) => {
	return css`
		padding: 30px;
		margin: 30px 0;
		background-color: ${theme.colors.white};
		display: flex;
		flex-wrap: wrap;

		@media (max-width: ${breakpoints.small}) {
			padding: 20px 7px;
			margin: 10px 0;
			display: inline-flex;
			flex-wrap: wrap;
		}

		.sr-only {
			${mixins.webtextHiddenAccessible(theme)}
		}

		svg {
			max-width: 300px;
			min-height: 206px;
			flex-shrink: 0;
			margin: auto;

			@media (max-width: ${breakpoints.small}) {
				margin: 1em auto;
			}
		}

		hr {
			border: thin solid black;
		}

		.total-responses {
			text-align: right;
		}

		.legend {
			margin: auto;
			max-width: 300px;
			min-width: 246px;

			.results-table {
				caption {
					display: block;
					@-moz-document url-prefix() {
						width: 100%;
						display: table-caption;
					}
				}
			}

			.legend-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-size: 0.875em;
				.response-label {
					text-align: left;
					font-weight: normal;
					display: flex;
					margin-right: 32px;
					.color {
						margin-top: 4px;
						margin-right: 8px;
						border-style: solid;
						border-width: 2px;
						flex-shrink: 0;
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	`;
};

export const refreshedPollResultsStyles = (
	theme,
	options: { isSmallView: boolean } = { isSmallView: false }
) => css`
	margin: 0 0 1em;

	svg {
		* {
			font-family: ${theme.fonts.app};
		}

		a {
			color: ${theme.colors.primary};

			// There's a bug in Safari that makes 'a' tag underline appear thicker than rest of the content
			// See https://github.com/soomo/soomo-libs/pull/1647#discussion_r1371913467
			text-decoration-thickness: 2px;
		}

		.xAxis .tick line {
			stroke: ${theme.pollQuestion.tickColor};
		}

		.divider-line {
			stroke: ${theme.pollQuestion.lineColor};

			@media (max-width: ${breakpoints.small}) {
				stroke: ${theme.pollQuestion.tickColor};
			}
		}
	}

	&.refreshed-poll-results {
		/**
		Breaks the chart out of the parent container with padding on the sides
		It helps to prevent overflow and cropping of the labels
		*/
		&-pie {
			margin-left: calc(-1 * var(--tab-panel-padding));
			margin-right: calc(-1 * var(--tab-panel-padding));
		}

		${options.isSmallView &&
		css`
			&-bars {
				margin-left: calc(-1 * var(--tab-panel-padding));
				margin-right: calc(-1 * var(--tab-panel-padding));
				margin-top: 16px;
				overflow-x: auto;

				.refreshed-poll-results-chart-container {
					// Emulates padding on the sides of the chart
					margin-left: var(--tab-panel-padding);
					margin-right: var(--tab-panel-padding);
				}
			}
		`}
	}

	.xAxis {
		font-size: 14px;
	}

	.yAxis {
		transform: translate(-3px); // Moves labels to the left, creates gap between labels and bars
	}

	.poll-percent-label {
		font-size: 12px;
		font-weight: 500;
	}

	.poll-x-axis-tick-text {
		font-size: 14px;
		color: ${theme.colors['evil-gray']};

		@media (max-width: ${breakpoints.small}) {
			font-size: 10px;
		}
	}

	.poll-y-axis-tick {
		outline: none; // Removes default outline on focus
		font-size: 12px;
		font-weight: 400;
		line-height: 1.3;
		overflow: visible; // Shows the text underline

		&-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 100%;
		}

		&-label {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			overflow-wrap: break-word;
			hyphens: auto;

			// Padding is required to display the '...' in Chrome, see https://soomo.height.app/T-86029#ec975898-1ea0-45e3-ae8b-95d12599ddca
			text-align: right;
			padding-right: 1.1em;

			&.your-class {
				.course-number {
					font-size: 9px;
					color: ${theme.colors['evil-gray']};
				}
			}
		}
	}

	.poll-group-label {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		overflow-wrap: break-word;
		hyphens: auto;
		width: fit-content;
		font-size: 12px;

		// "Your class" group includes the class number
		// They need be truncated together to prevent overflow
		// See examples:
		// .group-2-items - https://ibb.co/pxHV7FL (class), https://ibb.co/3CF1M5y (comparison)
		// .group-3-items - https://ibb.co/zh4bpbH (class), https://ibb.co/ypHfP3x (comparison)
		&.your-class {
			&.group-2-items {
				-webkit-line-clamp: 3;
			}
			&.group-3-items {
				-webkit-line-clamp: 5;
			}
			-webkit-line-clamp: 7;
		}

		.group {
			font-weight: 700;
		}

		.course-number {
			color: ${theme.colors['evil-gray']};
		}
	}

	.label-underline {
		text-decoration: underline dotted ${theme.colors.primary};
		text-decoration-thickness: 2px;
	}

	.poll-results-svg {
		width: 100%;
		border-bottom: 2px solid ${theme.pollQuestion.lineColor};

		@media (max-width: ${breakpoints.small}) {
			border-bottom-width: 1px;
			border-bottom-color: ${theme.pollQuestion.tickColor};
		}
	}

	.pie-chart-label-line {
		stroke: ${theme.colors['evil-gray']};
	}

	.pie-chart-label {
		overflow: visible;
		font-size: 14px;
		line-height: 1.21; // Magically 1.21 makes the underline display properly in Chrome, see https://soomolearning.slack.com/archives/G1R0V6JAW/p1693313340755179?thread_ts=1693312331.723349&cid=G1R0V6JAW

		@media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
			font-size: 13px;
		}

		@media (max-width: ${breakpoints.small}) {
			font-size: 12px;
			line-height: 1.15;
		}

		&-text-container {
			width: fit-content;
			text-align: center;
		}

		&-text {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			overflow-wrap: break-word;
			hyphens: auto;

			.visually-hidden {
				${mixins.webtextHiddenAccessible(theme)}
			}
		}
	}

	@media (max-width: ${breakpoints.small}) {
		.poll-results-svg {
			margin-bottom: 0.5em;
		}
	}
`;

export const pollDataStyles = (theme, isClassOnly: boolean) => css`
	margin-top: 1.5em;
	margin-bottom: 1em;

	font-size: 16px;
	font-family: ${theme.fonts.app} !important;

	@media (max-width: ${breakpoints.small}) {
		margin-top: 1em;
	}

	.poll-data-comment {
		margin-left: 1em;

		::before {
			counter-increment: .poll-data-comment;
			content: counter(.poll-data-comment) '.';
		}
	}

	.poll-data-table-overflow {
		overflow-x: auto;
	}

	table {
		width: 100%;

		a {
			color: ${theme.colors.primary};
		}

		// TODO: remove the next lines when core UV _webtext_figure.scss styles get removed
		strong {
			font-family: ${theme.fonts.app} !important;
		}
	}

	table,
	th,
	td {
		border: 1px solid #000;
		border-collapse: collapse;
		// TODO: remove the next lines when core UV _webtext_figure.scss styles get removed
		line-height: 20px !important;
		font-family: ${theme.fonts.app} !important;

		@media (max-width: ${breakpoints.small}) {
			border-color: #595959;
		}
	}

	thead {
		th {
			padding: 6px 8px 8px 8px;
			font-weight: 700;
			font-size: 16px;
			text-align: center !important;
			vertical-align: top !important;

			@media (max-width: ${breakpoints.small}) {
				font-size: 12px;

				&:first-of-type {
					text-align: left !important;
				}
			}

			&:first-of-type:not(.poll-data-header-column-label) {
				text-align: left !important;
				padding-left: 14px;

				@media (max-width: ${breakpoints.small}) {
					padding-left: 10px;
				}
			}
		}
	}

	tbody {
		td,
		th {
			padding: 12px 8px;
			vertical-align: top;
			font-size: 16px;

			@media (max-width: ${breakpoints.small}) {
				padding: 6px 8px;
				vertical-align: middle;
				font-size: 12px;
			}

			&.poll-data-total-row-label {
				width: 166px;
			}
		}

		td {
			text-align: right;
		}

		th {
			text-align: left;
			font-weight: normal;
			overflow-wrap: break-word;
		}

		tr:last-of-type {
			th {
				font-size: 16px;
				font-weight: 700;

				@media (max-width: ${breakpoints.small}) {
					font-size: 12px;
				}
			}

			td {
				font-weight: ${!isClassOnly ? '700' : '400'};
				line-height: 24px;

				@media (max-width: ${breakpoints.small}) {
					font-weight: 400;
				}
			}

			.poll-class-only-class-total {
				font-weight: 700;
			}

			.total-row-percentage {
				font-weight: normal;
			}

			.poll-data-classmates-message {
				font-weight: 400;
				font-size: 14px;
				line-height: 14px;
				color: ${theme.colors['evil-gray']};
				margin-top: 5px;

				@media (max-width: ${breakpoints.small}) {
					font-size: 10px;
				}
			}
		}

		tr {
			&.poll-data-small-total-row {
				th,
				td:not(:last-of-type) {
					font-weight: 700;
				}
			}
		}
	}

	.poll-data-small-choice-header {
		font-family: ${theme.fonts.app};
		font-size: 14px;
		background: #f3f3f3;
		border: 1px solid #595959;
		border-bottom: none;
		text-align: left;
		padding: 6px 8px 8px 10px;
		font-weight: 700;
	}

	.poll-data-header-column-label {
		font-size: 12px;
		font-weight: 400;
		color: ${theme.colors['evil-gray']};
		padding: 3px;

		@media (max-width: ${breakpoints.small}) {
			font-size: 10px;
			padding: 4px 8px;

			&:first-of-type {
				width: 166px;
			}
		}
	}

	.shaded-cell {
		background-color: ${theme.pollQuestion.cellBackgroundColor};
	}

	.poll-data-contextual-info {
		list-style: none;
		margin: 1.5em 0;
		padding-left: 0;

		a {
			color: ${theme.colors.primary};
		}

		li {
			display: grid;
			grid-template-columns: 20px auto;
			font-size: 14px;
			font-weight: 400;
			font-family: ${theme.fonts.app};
			font-style: italic;
			color: ${theme.colors['evil-gray']};
			margin: 0;

			& + li {
				margin-top: 10px;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			margin: 1em 0;
		}
	}

	.course-number {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;

		@media (min-width: ${breakpoints.small}) {
			// Centers the truncated text
			margin-left: auto;
			margin-right: auto;
		}

		// Prevents autosizing from making "Your class" column too wide
		max-width: 140px;

		font-size: 12px;
		font-weight: 400;
		line-height: 1.2;
		color: ${theme.colors['evil-gray']};

		@media (max-width: ${breakpoints.small}) {
			font-size: 10px;
		}
	}
`;

export default (theme) => css`
	--tab-panel-padding: 24px;

	a {
		font-weight: ${theme.webtextPage.a.fontWeight};
	}

	.loading {
		display: flex;
		span {
			margin-left: 0.5em;
		}
	}

	.submissionError {
		margin-top: 1em;
	}

	.status-and-actions {
		margin-top: 2em;
	}

	.poll-data-header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;

		&-data-tab {
			flex-direction: row;

			a {
				margin-left: auto;
				text-decoration: underline;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			&-data-tab {
				flex-direction: column-reverse;

				a {
					margin-left: 0;
				}
			}
		}
	}

	.poll-title {
		line-height: 20px;
		font-family: ${theme.fonts.app};
		font-weight: 700;

		&-large {
			font-size: 18px;

			@media (max-width: ${breakpoints.small}) {
				font-size: 16px;
			}
		}

		&-small {
			margin-top: 20px;
			font-size: 14px;

			@media (max-width: ${breakpoints.small}) {
				margin-top: 16px;
				font-size: 12px;
			}
		}

		.course-number {
			margin-top: 4px;
			font-weight: 400;
			color: ${theme.colors['evil-gray']};

			@media (max-width: ${breakpoints.small}) {
				margin-top: 0;
			}
		}
	}

	.poll-chart-options {
		margin-bottom: 15px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}

	.poll-chart-instructions {
		margin-top: 10px;
		font-size: 16px;
		line-height: 21px;

		@media (max-width: ${breakpoints.small}) {
			margin-top: 8px;
			font-size: 14px;
		}
	}

	.poll-chart-empty-warning {
		display: flex;
		align-items: center;
		gap: 0.6em;
		margin-top: 24px;
		padding: 6px 8px;

		font-size: 14px;
		line-height: 1;
		border-radius: 10px;
		background-color: ${lighten(theme.colors.primary, 0.95)};

		svg {
			fill: ${theme.colors.primary};
		}

		span {
			margin-bottom: -2px; // Due to the text height rendering, it's slightly off center
		}

		@media (max-width: ${breakpoints.small}) {
			margin-top: 20px;
		}
	}

	.poll-chart-instructions,
	.poll-chart-empty-warning {
		grid-column: 1 / -1;
		font-family: ${theme.fonts.app};
		color: #595959;
	}

	.poll-caption-credits-container {
		color: ${theme.colors['evil-gray']};
		font-size: 14px;

		.contrast-toggle-container {
			button.contrast-toggle {
				padding: 0;
				font-size: inherit;
				font-weight: normal;
				text-align: left;

				@media (max-width: ${breakpoints.small}) {
					width: auto;
					margin-bottom: 0;
				}
			}
		}

		.poll-caption-and-credits {
			display: inline;
			font-weight: 400;
			font-family: ${theme.fonts.app};

			a {
				color: ${theme.colors.primary};
			}

			.poll-credits {
				font-style: italic;

				em {
					font-style: normal;
				}
			}
		}
	}

	.poll-tabs {
		margin-bottom: 1rem;

		.MuiTab-root {
			font-family: ${theme.fonts.app};
			font-size: 16px;
			padding: 6px;
			color: #000;
			background: #ffffff;
			// needed to override core styles
			border: 1px solid ${theme.pollQuestion.lineColor} !important;
			width: 98px;
			margin-bottom: -1px;

			&.Mui-selected {
				font-weight: bold;
				// needed to override core styles
				border-bottom: none !important;
			}

			&:not(.Mui-selected) {
				color: #1d2433cc;
				background-color: #e6e6e6;
			}

			@media (max-width: ${breakpoints.small}) {
				font-size: 12px;
				width: 70px;
			}
		}

		.MuiTabsList-root {
			display: flex;
			flex-direction: row;
			gap: 2px;
			border-bottom: 1px solid ${theme.pollQuestion.lineColor};
		}

		.MuiTabPanel-root {
			background: white;
			border: 1px solid ${theme.pollQuestion.lineColor};
			border-top: 0;
			padding: var(--tab-panel-padding);

			@media (max-width: ${breakpoints.small}) {
				--tab-panel-padding: 1.2em;

				&.poll-data-tab {
					--tab-panel-padding: 12px;
					padding-top: 1em;
				}
			}
		}
	}
`;

export const pollLabelTippyStyles = (theme) => css`
	.tippy-box[data-theme~='poll-chart-label'] .tippy-content {
		font-family: ${theme.fonts.app};
		font-size: 12px;
	}
`;
