// The data embedded in the window by the server
import type { PopupQuizLoadData } from '~/components/pageElements/PopupQuiz/types';
import type { NotificationElementType } from '~/components/WebtextNotifications/types';
import type { FamilyId } from '~/types/WebtextManifest';

type ISO8601DateTime = string;

export interface InitialNotebookState {
	userId: number;
	userName: string;
	courseName: string;
	courseId: number;
	dontShowWelcomeScreen: boolean;
	webtextCoverURL: string;
}

// types for AnalyticsController#element_activity_data
export interface ElementsActivityResponse {
	pageId: string;
	elements: {
		[family_id: string]: {
			answers: ElementAnswer[];
		};
	};
}

export interface ElementUnreadNotificationsResponse {
	thingFamilyId: string;
	notifications: Array<NotificationElementType>;
}

export interface RefreshDataResponse {
	notebookSummary: NotebookSummary;
	elementActivity: {
		[pageId: string]: ElementsActivityResponse;
	};
}

export interface ElementAnswer {
	id: string;
	correct: boolean;
	body: string;
	saved_at: string;
	reset_at: string;
	updated_at: string;
}

export interface CommentableAnswer {
	comment: string;
	commented_at: string;
	comment_author_name: string;
	comment_seen_at: string;
}

export type WebtextBuilderData = CommentableAnswer & {
	answer_id: string;
	version: number;
	last_saved_at: string;
	last_autosubmit_at: string | null;
	lms_file_upload_support: boolean;
	sources: any;
	dependent_builder_prompts: any;
	dependent_builders_last_saved_at: string;
	dests: any;
	error: any;
	locked: boolean;
	locked_message: string;
	history: Array<ActivityVersion>;
};

export interface MCQuestionAnswer extends ElementAnswer, CommentableAnswer {}

export interface MCQuestionPoolAnswer extends Omit<ElementAnswer, 'id'> {
	id: number;
	question_family_id: string;
}

export interface PollQuestionAnswer extends ElementAnswer, CommentableAnswer {
	graph: any;
}

export interface BuilderAnswer extends ElementAnswer, CommentableAnswer {}

export interface PopupQuizAnswer extends ElementAnswer {
	data: PopupQuizLoadData;
	saved_at: string;
	reset_at: string;
}

export interface TableRow {
	id: string;
	isPageGroup: boolean;
}

// Return value of notebook_service#notebook_summary
export interface NotebookSummary {
	chapters: string[];
	chapterMap: {
		[familyId: string]: NotebookChapterSummary;
	};
	pageGroupMap: {
		[familyId: string]: NotebookPageGroupSummary;
	};
	pageMap: {
		[familyId: string]: NotebookPageSummary;
	};
	chaptersLabel: string;
	courseSummary: SummaryData;
	tableRows: TableRow[];
	longestRowLength: number;
	userDecisions: UserDecisions;
	dueDates: DueDates;
}

export interface BackgroundDataUpdate {
	notebookSummary: NotebookSummary;
	elementsActivity: {
		[pageId: string]: {
			[family_id: string]: {
				answers: ElementAnswer[];
			};
		};
	};
}

export interface UserDecisions {
	[prompt: string]: string;
}

export interface DueDates {
	[familyId: string]: DueDate;
}

export interface DueDate {
	thing_id: number;
	thing_type: string;
	thing_name: string;
	due: string;
	penalty_period_length: number;
	penalty_factor: number;
}

export interface SummaryData {
	totalTimeSpent: number;
	questionsCompleted: number;
	questionsCorrect: number;
	questionsPossible: number;
	questionsScored: number;
	questionsGraded: number;
	questionsInstructorGradable: number;
	lastActivityAt: string;
	lastSavedAt: string;
	dueAt: string;
	penaltyPeriodEndsAt: string;
	pastDuePenaltyFactor: number;
}

export type NotebookPageGroupSummary = SummaryData & {
	id: string;
	name: string;
	rowLabel: string;
	label: string;
	chapterNumber: string;
	pageGroupNumber: string;
	pages: string[];
	pagesOpened: number;
};

export type NotebookChapterSummary = SummaryData & {
	id: string;
	name: string;
	rowLabel: string;
	label: string;
	chapterNumber: string;
	pageGroupsEnabled: boolean;
	pageGroups: any[];
	pages: string[];
	pagesOpened: number;
};

export type NotebookPageSummary = SummaryData & {
	id: string;
	name: string;
	pageTypeLabel?: string;
	pageNumber?: string;
	number: number;
	pastDue: boolean;
	pageOpened: boolean;
	mcAnswerCount?: number;
	resets?: number;
	resetsRemaining?: number;
	resetsAllowed?: number;
	limitedAttempts?: boolean;
};

export type ActivityVersion = AnswerVersion | CommentVersion | DecisionVersion;

export const answerVersionEvents = ['saved', 'posted', 'unposted'] as const;
export interface AnswerVersion {
	body: string | Record<string, any>;
	created_at: string;
	// the type of event that took place to create the new version
	event: (typeof answerVersionEvents)[number];
	// "whodunnit", the user id of the user that caused the event to happen
	source: number;
}
export const isAnswerVersion = (version: ActivityVersion): version is AnswerVersion =>
	answerVersionEvents.includes((version as AnswerVersion).event);

export const commentVersionEvents = ['added', 'edited', 'seen'] as const;
export interface CommentVersion {
	author_name: string;
	body: string;
	created_at: string;
	seen_at: string | null;
	event: (typeof commentVersionEvents)[number];
}
export const isCommentVersion = (version: ActivityVersion): version is CommentVersion =>
	commentVersionEvents.includes((version as CommentVersion).event);

export const decisionVersionEvents = ['decision_reset'] as const;
export interface DecisionVersion {
	decisions: UserDecisions;
	created_at: string;
	event: (typeof decisionVersionEvents)[number];
	source: number;
}
export const isDecisionVersion = (version: ActivityVersion): version is DecisionVersion =>
	decisionVersionEvents.includes((version as DecisionVersion).event);

export interface StudentGrade {
	current_points: number;
	delivered_points: number | null;
	last_delivery_status: string | null;
	last_delivered_at: ISO8601DateTime | null;
}

export interface GradebookColumn {
	label: string;
	id: string;
	possible_points: number;
	ext: {
		scope_id: number;
		scope_family_id: FamilyId;
		score_aggregator_id: number;
		interactive_type: GradebookInteractiveType;
		filter: string;
		deep_link_family_id: FamilyId;
		due_date: string | null;
	};
}

export enum GradebookInteractiveType {
	'GetTheGist' = 'get-the-gist',
	'PollQuestion' = 'poll-question',
	'OnPageMcQuestion' = 'on-page-mc-question',
	'OnPageSaQuestion' = 'on-page-sa-question',
	'YourTurn' = 'your-turn',
	'WritingTemplate' = 'writing-template',
	'VoiceRecording' = 'voice-recording',
	'GoReact' = 'go-react',
	'PopupQuiz' = 'popup-quiz',
	'Assignment' = 'assignment',
	'AssignmentPage' = 'assignment-page',
	'AssignmentPageExceptPolls' = 'assignment-page-except-polls',
	'AssignmentPageAndTemplates' = 'assignment-page-and-templates'
}
