import cloneDeep from 'lodash-es/cloneDeep';
import { transparentize } from 'polished';

import constants from '../base/constants';
import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

const green = '#979e44';

theme.colors.primary = green;
theme.colors.secondary = '#eaeaea';
theme.colors.accent = green;
theme.colors.link = '#717733';

theme.webtextPage.title.color = theme.colors.primary;
theme.webtextPage.h3.fontFamily = constants.fonts['helvetica-neue'];
theme.webtextPage.h4.fontFamily = constants.fonts['helvetica-neue'];
theme.webtextPage.h5.fontFamily = constants.fonts['helvetica-neue'];

theme.tableOfContents.currentItemColor = (theme) => transparentize(0.3, theme.colors.primary);

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}1A`;

export default theme;
