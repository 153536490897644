import React, { FC, HTMLAttributes } from 'react';

const PollQuestionIcon: FC<HTMLAttributes<SVGElement>> = (props) => (
	<svg className="poll-icon" width={30} height={30} aria-hidden {...props}>
		<g fill="none" fillRule="evenodd">
			<path fill="#F5F5F5" d="M-384-53h1448v419H-384z" />
			<g fill="#5F01DF">
				<path d="M25.38 16.38H13.62V4.62A.62.62 0 0013 4C5.831 4 0 9.831 0 17s5.831 13 13 13 13-5.831 13-13a.62.62 0 00-.62-.62" />
				<path d="M16.636 0A.637.637 0 0016 .636v12.728c0 .35.285.636.636.636h12.728a.637.637 0 00.636-.636C30 5.994 24.006 0 16.636 0" />
			</g>
		</g>
	</svg>
);

export default PollQuestionIcon;
