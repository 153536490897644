import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import { DateTime } from 'luxon';

import WebtextButton from '~/components/WebtextButton';
import { getThemeItem, Theme, ThemeName } from '~/styles/themes';
import { numberToOrdinalWord } from '~/utils/formatting';
import { getAttemptsRemaining, getHasLimitedAttempts } from '~/utils/policy';

import { buttonStyles, pastDueDateStyles } from '../styles';
import WarningIcon from './WarningIcon';

import type { DueDateProps } from '~/types/dueDateProps';
import type { FamilyId } from '~/types/WebtextManifest';

interface Props extends DueDateProps {
	questionPoolFamilyId: FamilyId;
	attemptsAllowed: number;
	resetCount: number;
	isRequestInProgress: boolean;
	disabled?: boolean;
	onClick: () => Promise<void> | void;
	saveButtonLabel?: string;
}

const SaveButton: React.VFC<Props> = ({
	questionPoolFamilyId,
	attemptsAllowed,
	resetCount,
	isRequestInProgress,
	disabled,
	originallyDueAt: originallyDueAtISO8601,
	penaltyDueAt: penaltyDueAtISO8601,
	onClick,
	saveButtonLabel
}) => {
	const [originallyDueAt, penaltyDueAt] = useMemo(
		() => [DateTime.fromISO(originallyDueAtISO8601), DateTime.fromISO(penaltyDueAtISO8601)],
		[originallyDueAtISO8601, penaltyDueAtISO8601]
	);
	const hasDueDate = originallyDueAtISO8601 != null;
	const hasPenaltyDueDate = penaltyDueAtISO8601 != null;
	const finalEndDate =
		hasPenaltyDueDate && penaltyDueAt > originallyDueAt ? penaltyDueAt : originallyDueAt;
	const isPastFinalEndDate = hasDueDate && finalEndDate < DateTime.local();
	const hasLimitedAttempts = getHasLimitedAttempts(attemptsAllowed);
	const attemptsRemaining = getAttemptsRemaining({
		attemptsAllowed,
		resetCount,
		isAnswered: false // if we're showing the Save button, it's because the question hasn't been answered
	});

	const showOnlyAttemptWarning = attemptsAllowed === 1;
	/**
	 * It's possible for attemptsRemaining to be less than 1 *and* for the question to be unanswered
	 * if an instructor resets a student's multiple-choice question after they've exhausted their attempts;
	 * therefore we have to check <= 1, not === 1.
	 * @see https://soomo.height.app/T-98435
	 */
	const showLastAttemptWarning = attemptsRemaining <= 1;

	const warningTextId = `${questionPoolFamilyId}-save-button-warning-text`;

	return (
		<>
			<div css={buttonContainerStyles}>
				<div id={warningTextId} css={warningTextStyles}>
					{isPastFinalEndDate && (
						<span css={pastDueDateStyles}>
							The due date has passed. Correct attempts won’t receive credit.
						</span>
					)}
					{!isPastFinalEndDate && hasLimitedAttempts && (
						<span>
							{(showOnlyAttemptWarning || showLastAttemptWarning) && (
								<WarningIcon css={warningIconStyles} />
							)}
							{showOnlyAttemptWarning && 'This is your only attempt.'}
							{attemptsAllowed > 1 && (
								<>
									{showLastAttemptWarning && 'This is your final attempt.'}
									{attemptsRemaining > 1 &&
										`This is your ${numberToOrdinalWord(
											resetCount + 1
										)} of ${attemptsAllowed} attempts.`}
								</>
							)}
						</span>
					)}
				</div>
				<WebtextButton
					onClick={onClick}
					css={buttonStyles}
					disabled={disabled || isRequestInProgress}
					aria-describedby={warningTextId}>
					{isRequestInProgress ? 'Saving...' : saveButtonLabel ?? 'Save'}
				</WebtextButton>
			</div>
		</>
	);
};

export default SaveButton;

const buttonContainerStyles = (theme: Theme) => css`
	display: grid;
	padding: 2rem 0 0;
	grid-template-columns: 1fr auto;
	justify-items: flex-end;
	align-items: center;
	column-gap: 1rem;

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		border-top: 1px solid #d3d3d3;
	`}
`;

const warningIconStyles = css`
	display: inline-block;
	vertical-align: sub;
	margin-right: 0.25rem;
`;

const warningTextStyles = (theme: Theme) => css`
	color: ${getThemeItem(theme.webtextQuestion.questionPrompt.color, theme)};
`;
