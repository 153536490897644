import React, { FC } from 'react';

import { css, SerializedStyles } from '@emotion/react';
import pluralize from 'pluralize';

import { PollResultsAliasProps } from '~/components/pageElements/PollQuestion/types';
import {
	headingStyles as unfinishedHeadingStyles,
	linkStyles as unfinishedLinkStyles,
	listStyles as unfinishedListStyles
} from '~/components/WritingTemplate/Unfinished/styles';
import { breakpoints, getThemeItem, Theme } from '~/styles/themes';

interface Props {
	missedQuestionsProps: Array<
		Pick<
			PollResultsAliasProps,
			| 'targetPollPageNumber'
			| 'targetPollLink'
			| 'navigateToTargetPoll'
			| 'targetPollResults'
			| 'questionFamilyId'
		>
	>;
}

const UnfinishedPolls: FC<Props> = (props) => {
	const { missedQuestionsProps } = props;
	return (
		<div css={unfinishedPollStyles} className="unfinished-poll">
			<div className="unfinished-poll-instruction">
				To see results, answer the following poll{' '}
				{pluralize('question', missedQuestionsProps.length)}:
			</div>
			<ul>
				{missedQuestionsProps.map((question, index) => {
					const {
						targetPollPageNumber: pageNumber,
						targetPollLink,
						navigateToTargetPoll,
						targetPollResults
					} = question;

					return (
						<li key={`${targetPollResults.questionFamilyId}${index}`}>
							<span className="page-number">Page{pageNumber && ` ${pageNumber}`}: </span>
							<a
								href={targetPollLink}
								className="nav-links" // Prevents Core styles override
								onClick={(e) => {
									e.preventDefault();
									navigateToTargetPoll();
								}}>
								{targetPollResults.body}
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const unfinishedPollStyles = (theme: Theme): SerializedStyles => css`
	// Using the ">" selector to override Core's specificity
	& > .unfinished-poll-instruction {
		${unfinishedPollHeadingStyles(theme)};
	}
	& > ul {
		${unfinishedPollListStyles(theme)};
	}
`;

const unfinishedPollHeadingStyles = (theme: Theme): SerializedStyles => {
	const { colors, fonts } = theme;
	const textColor = getThemeItem(colors.text, theme);
	const textFontFamily = getThemeItem(fonts.app, theme);

	return css`
		${unfinishedHeadingStyles(theme)};

		font-size: 16px;
		font-family: ${textFontFamily};
		color: ${textColor};
		margin-top: 0;
		margin-bottom: 15px;

		@media (max-width: ${breakpoints.small}) {
			font-size: 14px;
		}
	`;
};

const unfinishedPollListStyles = (theme: Theme): SerializedStyles => css`
	${unfinishedListStyles};

	li {
		padding-left: 24px;
		font-size: 16px;
		font-weight: bold;

		// Workaround to make the ellipsis use the color of the link
		color: ${theme.colors.primary};
		.page-number {
			color: ${theme.colors.black};
		}

		@media (max-width: ${breakpoints.small}) {
			font-size: 14px;
		}

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		a {
			${unfinishedLinkStyles(theme)};
		}
	}
`;

export default UnfinishedPolls;
