import React, { ReactElement } from 'react';

import isNull from 'lodash-es/isNull';
import last from 'lodash-es/last';

import { Kinds } from '../../WritingTemplate/types';
import { getPreviousVisibleElement } from '../helpers';
import { separatorStyles } from '../styles';
import { BuilderElementConfig } from '../types';
import BuilderElementContainer from './BuilderElementContainer';
import QuestionFieldset from './QuestionFieldset';
import SelectorContainer from './SelectorContainer';

interface Props {
	elementsArray: BuilderElementConfig[];
	isTable: boolean;
	tableSelectors?: BuilderElementConfig[];
	parentPresentation: string;
	listDepth: number;
	onParseOldOutline: (component: unknown, listDepth?: number) => ReactElement;
	resetScaleImageFailure?: () => void;
}

const BuilderElementsContainer: React.FC<Props> = (props) => {
	const {
		elementsArray,
		isTable,
		tableSelectors,
		parentPresentation,
		listDepth,
		onParseOldOutline,
		resetScaleImageFailure
	} = props;

	if (!elementsArray || elementsArray.length === 0) return null;

	let heldElements: BuilderElementConfig[] = [];

	return (
		<>
			{elementsArray.map((element, elementIdx, elements) => {
				const isInlineView = isTable || parentPresentation === 'inline';
				const separator = createSeparator(element, elements, elementIdx);

				if ((element.kind === Kinds.Text || element.kind === Kinds.ValueOf) && !isTable) {
					/**
					 * Why do we even need this? In a lot of examples (especially old) text and "value of" make a one visual question, but if we break this string only last element (end of the sentence) will be bounded to the input field
					 * In this case I see only bunch of br tags that breaks parts of texts that are not related
					 * example: https://ibb.co/TMMvDhV
					 * "gap" and "hr" features will help to avoid this, because gap component is come to replace these <br>s, but I don't feel this it was replaced everywhere
					 */
					if (element.kind === Kinds.Text && element.value.includes('<br>')) {
						const textElements = renderTextElements(isInlineView, elementIdx, elements);
						/**
						 * After rendering these elements new queue fills with this separator
						 */
						heldElements = [element];
						return textElements;
					}

					heldElements.push(element);
					/**
					 * When we reach the end of the elements block we have to release all held elements
					 * Otherwise last held elements will be lost
					 * We're using `elementIdx + 1` because `renderTextElements` designed to be called on the next element after text
					 * but in this case we render when we reach the end of the block and current element is the last one
					 */
					return (
						<>
							{separator}
							{elementIdx === elements.length - 1 &&
								renderTextElements(isInlineView, elementIdx + 1, elements)}
						</>
					);
				}

				/**
				 * In this place all held text elements will be released and heldElements will be cleared
				 * After that we will render current element
				 * in next couple condition we will make some changes to current element and heldElements
				 * according to element-specific requirements
				 */
				const textElements = renderTextElements(isInlineView, elementIdx, elements);
				heldElements = [];
				const breakElement = createBreak(element, elements, elementIdx);
				const isInlineSelector = (e) => e.kind === Kinds.Select && e.presentation === 'inline';
				const inlineSelectors = elements.filter(isInlineSelector);
				const currentElement = (
					<BuilderElementContainer
						builderElement={element}
						isInlineView={isInlineView}
						inlineSelectorIndex={
							isInlineSelector(element) || tableSelectors != null
								? (tableSelectors || inlineSelectors).findIndex((e) => e === element)
								: null
						}
						priorElement={elements[elementIdx - 1]}
						parentPresentation={parentPresentation}
						isTable={isTable}
						listDepth={listDepth}
						onParseOldOutline={onParseOldOutline}
						resetScaleImageFailure={resetScaleImageFailure}
						multipleInlineSelectors={inlineSelectors?.length > 1 || tableSelectors?.length > 1}
					/>
				);

				/**
				 * Once we reach the fill in area and there are held items, we need to render them in common container
				 */
				if (
					textElements.length &&
					parentPresentation !== 'inline' &&
					element.kind === Kinds.FillIn &&
					!isTable
				) {
					return (
						<QuestionFieldset
							key={`${element?.dest}-qfs`}
							legendText={textElements}
							inputFields={currentElement}
							isOutline={!isNull(listDepth)}
						/>
					);
				}

				/**
				 * When selector follows the text in inline mode, we should make them as a single block
				 */
				if (
					textElements.length &&
					element.kind === Kinds.Select &&
					element?.presentation === 'inline'
				) {
					const lastTextElement = last(textElements);
					const restTextElements = textElements.slice(0, -1);

					const selectorElementWithLegend = (
						<SelectorContainer>
							{lastTextElement}
							{currentElement}
						</SelectorContainer>
					);

					return (
						<>
							{restTextElements}
							{selectorElementWithLegend}
						</>
					);
				}

				return (
					<>
						{textElements}
						{breakElement}
						{currentElement}
					</>
				);
			})}
		</>
	);

	function createSeparator(
		element: BuilderElementConfig,
		elements: BuilderElementConfig[],
		elementIdx: number
	) {
		const previousElement = getPreviousVisibleElement(elements, elementIdx);

		const shouldRenderSeparator = ![
			Kinds.Text,
			Kinds.ValueOf,
			Kinds.Gap,
			Kinds.HorizontalRule
		].includes(previousElement?.kind);
		const isCurrentElementText = [Kinds.Text, Kinds.ValueOf].includes(element.kind);

		if (
			shouldRenderSeparator &&
			parentPresentation !== 'inline' &&
			isCurrentElementText &&
			elementIdx !== 0
		) {
			return <div aria-hidden css={separatorStyles} />;
		}

		return null;
	}

	function createBreak(
		element: BuilderElementConfig,
		elements: BuilderElementConfig[],
		elementIdx: number
	) {
		/**
		 * Picked from original "shouldEnter"
		 * Only text elements affected
		 */
		const shouldRenderBreaker = ![
			Kinds.FillIn,
			Kinds.Select,
			Kinds.ValueOf,
			Kinds.Gap,
			Kinds.HorizontalRule,
			Kinds.Map
		].includes(element.kind);

		const previousElement = getPreviousVisibleElement(elements, elementIdx);
		const isPreviousElementText = previousElement?.kind === Kinds.Text;

		if (shouldRenderBreaker && isPreviousElementText) {
			return <br />;
		}

		return null;
	}

	function renderTextElements(
		isInlineView: boolean,
		elementIdx: number,
		elements: BuilderElementConfig[]
	) {
		const textElements: ReactElement[] = [];

		for (let idx = 0; idx < heldElements.length; idx++) {
			/**
			 * Calculates the builder-level index of the held element
			 * `elementIdx` - index of the current element, which comes after all the held elements
			 */
			const heldElementIdx = elementIdx - (heldElements.length - idx);

			const heldElement = heldElements[idx];
			const breakElement = createBreak(heldElement, elements, heldElementIdx);

			textElements.push(
				breakElement,
				<BuilderElementContainer
					key={`${idx}/${heldElements.toString()}`}
					builderElement={heldElement}
					isInlineView={isInlineView}
					parentPresentation={parentPresentation}
					priorElement={elements[elementIdx - 1]}
					isTable={isTable}
					listDepth={listDepth}
					onParseOldOutline={onParseOldOutline}
				/>
			);
		}

		return textElements;
	}
};

export default BuilderElementsContainer;
