import { css } from '@emotion/react';

import { breakpoints, mixins, ThemeName } from '~/styles/themes';

export default (noBottomMargin?: boolean, wide?: boolean) => (theme) => css`
	clear: both;
	font-size: ${theme.webtextQuestion.fontSize};
	font-family: ${theme.webtextQuestion.fontFamily};
	background: ${theme.webtextQuestion.backgroundColor};
	padding: 2em;
	margin: 0 0 ${noBottomMargin ? '0' : '2em'} 0;
	line-height: 1.6;
	max-width: ${theme.layout.contentColumnWidth};

	${wide &&
	css`
		min-width: calc(800px + 48%);
		margin-left: -24%;

		@media (max-width: ${breakpoints.large}) {
			min-width: unset;
			margin-left: unset;
		}
	`};

	@media (max-width: ${breakpoints.small}) {
		padding: 20px ${wide ? '0' : '18px'};
		margin: 0 0 ${noBottomMargin ? '0' : '10px'} 0;
	}

	.sr-only {
		${mixins.webtextHiddenAccessible(theme)}
	}

	.no-outline:focus {
		outline: none;
	}

	.save-button-container {
		text-align: right;

		@media (max-width: ${breakpoints.small}) {
			width: 100%;
		}
	}

	.question {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		position: relative;

		@media (max-width: ${breakpoints.small}) {
			padding: 0;
		}

		.answer {
			margin: ${theme.webtextQuestion.answerInput.margin};
			padding: 0.5em;
			border: ${theme.webtextQuestion.answerInput.border};
			font-size: ${theme.webtextQuestion.answerInput.fontSize};
			line-height: ${theme.webtextQuestion.answerInput.lineHeight};
			color: ${theme.webtextQuestion.answerInput.color};
			font-family: ${theme.fonts['webtext-question-answer']};

			resize: none;
			height: 5em;

			&.invalid {
				border: ${theme.webtextQuestion.answerInput.invalid.border} !important;
				background-color: ${theme.webtextQuestion.answerInput.invalid.backgroundColor};

				&:focus {
					outline: none !important;
				}
			}
		}

		.status-and-actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width: ${breakpoints.small}) {
				justify-content: space-between;

				.answer-status {
					line-height: 30px;
					flex: 0 0 100%;
					margin-bottom: 1em;
				}

				button {
					margin-right: 0px;

					@media (max-width: ${breakpoints.extraSmall}) {
						flex: 0 0 100%;
					}
				}
			}
		}
	}

	.divider {
		flex: 1;
		margin: 1.75em -1.75em 1.25em -1.75em;

		@media (max-width: ${breakpoints.small}) {
			margin: 1.75em -1.1em 1.25em -1.1em;
		}

		height: ${theme.webtextQuestion.divider.height};
		background-color: ${theme.webtextQuestion.divider.backgroundColor};
	}

	.gutter-bottom {
		margin-bottom: 1em;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetOverrides(theme)}
`;

export const universalVelvetOuterContainer =
	(spacing: number, noBottomMargin?: boolean, wide?: boolean) => (theme) => {
		const fullWidthBackgroundColor = theme.webtextQuestion.backgroundColor;

		return css`
			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				clear: both;
				position: relative;
				background-color: ${fullWidthBackgroundColor};
				padding-top: 36px;
				padding-bottom: 28px;
				margin-bottom: ${noBottomMargin ? '0' : '16px'};

				&::before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: -9999px;
					right: 0;
					z-index: -1;
					border-left: 9999px solid ${fullWidthBackgroundColor};
					box-shadow: 9999px 0 0 ${fullWidthBackgroundColor};
				}

				@media (max-width: ${breakpoints.small}) {
					padding-top: 0;
					padding-bottom: 0;
					margin-bottom: ${noBottomMargin ? '0' : '32px'};

					${wide
						? css`
								padding-left: 0;
						  `
						: css`
								&::after {
									content: '';
									position: absolute;
									top: 0;
									bottom: 0;
									left: ${spacing ? `-${spacing}px` : '0px'};
									background-color: ${theme.colors.primary};
									width: 6px;
								}
						  `}
				}
			`}
		`;
	};

const universalVelvetOverrides = ({ colors }) => css`
	padding: 0;
	max-width: 770px;

	@media (max-width: ${breakpoints.small}) {
		padding-left: 0;
	}

	.answer {
		font-size: 18px;
		background: #ffffff;
		border-radius: 6px;
		margin-bottom: 2em;

		&:focus {
			outline: none;
			border: 1px solid ${colors.primary} !important;
		}
	}

	.divider {
		display: none;
	}

	.question {
		padding: 0;
		margin: 0;
	}

	.question .status-and-actions {
		margin-top: 0 !important;

		@media (max-width: ${breakpoints.small}) {
			button {
				margin: 0;
				flex: 0 0 40%;
				flex-grow: 2;

				&:first-of-type:not(:last-child) {
					margin-right: 1em;
				}
			}
		}

		@media (min-width: ${breakpoints.small}) {
			&.separator {
				padding-top: 2em;
				border-top: thin solid lightgray;
			}
		}
	}
`;
