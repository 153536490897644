import { css } from '@emotion/react';

export default (theme) => {
	const loader = theme.loader;

	return css`
		.MuiCircularProgress-colorPrimary {
			color: ${loader.colors.primary};
		}
	`;
};
