import React, { FC } from 'react';

import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import Alert, { AlertProps } from '@material-ui/lab/Alert';

import { toastBodyStyles, toastSnackbarStyles } from '~/components/Toast/styles';

export type Props = Pick<SnackbarProps, 'open' | 'autoHideDuration'> &
	Pick<AlertProps, 'icon' | 'action' | 'onClose' | 'className' | 'children' | 'aria-live'>;

const Toast: FC<Props> = (props) => {
	const { open = false, autoHideDuration = 6000, className, onClose, ...alertProps } = props;

	return (
		<Snackbar
			open={open}
			css={toastSnackbarStyles}
			className={className}
			autoHideDuration={autoHideDuration}
			onClose={onClose}>
			<Alert variant="filled" severity="info" icon={false} css={toastBodyStyles} {...alertProps} />
		</Snackbar>
	);
};

export default Toast;
