import React, { FC } from 'react';

import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

import { ChartLegend } from '../ChartLegend';
import EnlargeChartWrapper from '../EnlargeChart';
import D3PieChart from './D3PieChart';

import type { Props as ChartFigureProps } from '../Chart';
import type { ChartElement } from '~/types/WebtextManifest';

export type PieChart = Pick<ChartElement, 'data' | 'show_labels' | 'colors'>;

export interface Props extends Omit<ChartFigureProps, 'chart'> {
	chart: PieChart;
}

const PieChart: FC<Props> = (props) => {
	const { chart, monochrome, zoomed } = props;
	const seriesLabels = chart.data[0].slice(1);

	return (
		<div css={styles}>
			<EnlargeChartWrapper zoomed={zoomed} zoomFactor={1.5} maxWidth="700px">
				<D3PieChart chart={chart} monochrome={monochrome} />
			</EnlargeChartWrapper>
			{seriesLabels.length > 1 && (
				<ChartLegend
					className="pie-legend"
					seriesLabels={seriesLabels}
					colors={chart.colors}
					monochrome={monochrome}
					fill
				/>
			)}
		</div>
	);
};

const styles = css`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@media (min-width: ${breakpoints.large}) {
		align-items: flex-start;
	}

	.pie-legend {
		justify-content: center;

		// Ensures legend is below pie chart, even if legend items
		// are small enough to fit next to pie chart.
		@media (max-width: ${breakpoints.large}) {
			flex-basis: ${breakpoints.large};

			.legend-item {
				text-align: center;

				.swatch.color {
					border-radius: 50%;
				}
			}
		}

		@media (min-width: ${breakpoints.large}) {
			min-width: 260px;
			max-width: calc(100% - 400px - 30px);
			flex-direction: column;
			margin: 30px 0 0 30px;
		}
	}

	.zoomer-container {
		flex: 1 0 400px;

		.zoomer {
			margin-left: auto;
			margin-right: auto;

			:not(&.zoomed) {
				max-width: 400px;
			}

			[data-pie-chart] {
				max-width: unset;
			}
		}
	}
`;

export default PieChart;
