import React from 'react';

import styles from './styles';

interface Props {
	value?: string;
}

const Title: React.FC<Props> = ({ value, ...rest }) => {
	return value ? (
		<span {...rest} css={styles}>
			{value}
		</span>
	) : (
		<span {...rest} css={styles} />
	);
};

export default Title;
