import React from 'react';

import { css, withTheme } from '@emotion/react';

import Loader from '~/components/Loader';
import { Theme, ThemeName } from '~/styles/themes';
import constants from '~/styles/themes/base/constants';

import MultiDimensionalResults from './MultiDimensionalResults';
import TotalScoreInterpretation from './TotalScoreInterpretation';
import { PopupQuiz, PopupQuizResults } from './types';

const styles = (theme) => {
	const { popupQuiz } = theme;

	return css`
		color: ${theme.colors.text};

		.loading-indicator-container {
			width: 100%;
			margin-top: 2em;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.top-line-standard {
			font-weight: bold;

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				font-weight: normal;
				font-family: ${constants.fonts['haas-grotesk-bold']};
			`}
		}

		.analysis {
			margin-top: 2ex;

			&:first-of-type {
				margin-top: 0;
			}
		}

		.question-interpretation-heading {
			margin-top: 2ex;
		}

		.question-interpretation-wrapper {
			padding: 0 2ex;

			.question-interpretation {
				margin: 1ex;
			}

			.question-number-interpretation {
				font-weight: ${popupQuiz.interpretation.questionNumber.fontWeight};
				margin-right: 2px;
			}
		}

		.results-footer {
			margin-top: 2ex;
			border-top: 1px solid ${theme.colors['light-gray']};
			padding-top: 2ex;
			font-size: 14px;
		}

		ol {
			list-style: decimal;
			margin: 1.2em 0 30px 30px;
		}

		ol > li {
			font-size: ${popupQuiz.fontSize};
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.analysis {
				font-weight: 500;

				@media (max-width: ${constants.breakpoints.small}) {
					font-weight: normal;
				}
			}

			.results-footer {
				border-top: none;
			}
		`}
	`;
};

interface Props {
	theme: Theme;
	popupQuiz: PopupQuiz;
	results: PopupQuizResults;
	resultsAvailable: boolean;
	resultsMessage: string;
}

class QuizResults extends React.Component<Props> {
	ref: React.RefObject<HTMLDivElement>;

	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	componentDidMount() {
		(this.ref.current.firstChild as HTMLElement).focus();
	}

	render() {
		const { theme, popupQuiz, results, resultsAvailable, resultsMessage } = this.props;
		const resultsMessagePresent = resultsMessage && resultsMessage.length > 0;

		let hideQuestionScoreInterpretations = false;
		switch (popupQuiz.ui.question_score_interpretations) {
			case 'hide_always':
				hideQuestionScoreInterpretations = true;
				break;
			case 'hide_when_fail':
				hideQuestionScoreInterpretations = !results.passed ? true : false;
				break;
			case 'hide_when_pass':
				hideQuestionScoreInterpretations = results.passed ? true : false;
				break;
		}

		const { showTotalScoreIntepretationAfterTotalScoreBar } = popupQuiz.ui.results;

		return (
			<div css={styles(theme)} ref={this.ref}>
				{!resultsAvailable && !resultsMessagePresent && (
					<div className="loading-indicator-container">
						<Loader />
						<span>(Loading results)</span>
					</div>
				)}
				{resultsMessagePresent && (
					<div className="analysis" dangerouslySetInnerHTML={{ __html: resultsMessage }} />
				)}
				{resultsAvailable && !resultsMessage && (
					<>
						{!showTotalScoreIntepretationAfterTotalScoreBar && (
							<TotalScoreInterpretation popupQuiz={popupQuiz} results={results} />
						)}
						{results.scores != null && (
							<MultiDimensionalResults popupQuiz={popupQuiz} results={results} />
						)}
						{results.questionScoreInterpretations.length > 0 &&
							!hideQuestionScoreInterpretations && (
								<>
									<div
										className="question-interpretation-heading"
										dangerouslySetInnerHTML={{
											__html:
												results.questionScoreInterpretationsHeading.length > 0
													? results.questionScoreInterpretationsHeading
													: '&nbsp;'
										}}
									/>
									<div className="question-interpretation-wrapper" style={{ paddingTop: '0' }}>
										{results.questionScoreInterpretations.map((interpretation) => {
											return (
												<div key={interpretation.key} className="question-interpretation">
													<span className="question-number-interpretation">
														{interpretation.key}
													</span>
													<span dangerouslySetInnerHTML={{ __html: interpretation.explanation }} />
												</div>
											);
										})}
									</div>
								</>
							)}
						{results.footer.length > 0 && (
							<div
								className="results-footer"
								dangerouslySetInnerHTML={{ __html: results.footer }}
							/>
						)}
					</>
				)}
			</div>
		);
	}
}

export default withTheme(QuizResults);
