import { css } from '@emotion/react';

import { breakpoints, fonts, ThemeName } from '~/styles/themes';

import { getFullPageBackgroundStyle } from '../WritingTemplate/utils';

const incompleteIcon =
	'<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 352 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';
const completedIcon =
	'<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>';

export const styles = (theme) => {
	const { name, webtextQuestion } = theme;
	return css`
		background: ${webtextQuestion.backgroundColor};
		${getFullPageBackgroundStyle(webtextQuestion.backgroundColor)};
		padding: 32px;
		font-family: ${theme.fonts.app} !important;

		${name === ThemeName.UNIVERSAL_VELVET &&
		css`
			padding: 32px 52px;
		`}

		ul.dependent-builder-list {
			li::before,
			li.completed::before {
				display: inline-block;
				zoom: 125%;
				margin-right: 5px;
				transform: translate(0, 4px);
			}

			li::before {
				content: url('data:image/svg+xml; utf8, ${incompleteIcon}');
			}

			li.completed::before {
				content: url('data:image/svg+xml; utf8, ${completedIcon}');
			}
		}

		ul.incomplete-dependent-builder-list {
			padding-left: 20px !important;
			margin-top: 20px;

			li.first-to-complete {
				font-weight: bold;
			}
		}

		p {
			${phraseStyles};
		}

		a {
			${linkStyles(theme)};
		}

		ul {
			${listStyles};
		}

		h4 {
			${headingStyles(theme)}
		}

		@media (max-width: ${breakpoints.small}) {
			padding: 35px 20px;
		}
	`;
};

export const headingStyles = (theme) => css`
	color: ${theme.colors.primary};
	margin: 30px 0 15px;
	font-weight: 300;
	text-transform: none;
	font-size: 24px;
	font-family: ${theme.fonts['webtext-heading-font-family']};

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		color: ${theme.colors.text};
		font-weight: 400;
		font-family: ${fonts['haas-grotesk']} !important; // Required to override Core's styles
	`};

	@media (max-width: ${breakpoints.small}) {
		font-size: 18px;
	}
`;

export const phraseStyles = css`
	font-size: 18px;
	line-height: 30px;
	margin-bottom: 20px;

	@media (max-width: ${breakpoints.small}) {
		font-size: 16px;
		line-height: 26px;
	}
`;

export const linkStyles = (theme) => css`
	// Workaround for applying the underline to the ellipsis
	// Unfortunately, "text-decoration: underline" doesn't work on it
	text-decoration: none;
	border-bottom: 1px solid ${theme.colors.primary};
	color: ${theme.colors.primary};
`;

export const listStyles = css`
	list-style: none;
	padding: 0;

	li {
		padding-left: 10px;
		margin-bottom: 5px;
		margin-top: 5px;

		font-size: 18px;
		line-height: 1.6;

		@media (max-width: ${breakpoints.small}) {
			font-size: 16px;
		}
	}
`;
