import { css, SerializedStyles } from '@emotion/react';
export default (
	left: number,
	top: number,
	width: number,
	height: number,
	color: string,
	opacity: number
): SerializedStyles => css`
	position: absolute;
	border: 2px dashed ${color};
	left: ${left - 1}px;
	top: ${top - 1}px;
	width: ${width + 1}px;
	height: ${height}px;
	z-index: 2;
	pointer-events: none;
	touch-action: none;
	box-sizing: border-box;

	div {
		background: ${color};
		border: 1px solid ${color};
		width: 100%;
		height: 100%;
		opacity: ${opacity};
		z-index: 1;
	}
`;
