import React, { FC, useMemo } from 'react';

import range from 'lodash-es/range';

import InstanceView from '~/components/Outline/components/InstanceView';
import AddButton from '~/components/Outline/components/SectionView/components';
import { sectionView } from '~/components/Outline/components/SectionView/styles';
import { getAddressDepth } from '~/components/Outline/helpers';
import {
	selectMaxSectionInstancesAllowed,
	selectMinSectionInstancesRequired,
	selectReadonly,
	selectSectionInstancesCount,
	selectTemplate,
	selectVariant,
	useOutlineSelector
} from '~/components/Outline/store';
import {
	getHierarchyItemType,
	OutlineHierarchyItemType,
	OutlineInstanceAddress,
	OutlineVariant
} from '~/components/Outline/types';

interface Props {
	address: OutlineInstanceAddress;
}

const SectionView: FC<Props> = (props) => {
	const { address } = props;

	const template = useOutlineSelector((state) => selectTemplate(state, address));
	const { label } = template;

	const depth = getAddressDepth(address);
	const sectionType = getHierarchyItemType(address);

	// TODO Combine selectors
	const instancesCount = useOutlineSelector((state) => selectSectionInstancesCount(state, address));
	const minInstancesRequired = useOutlineSelector((state) =>
		selectMinSectionInstancesRequired(state, address)
	);
	const maxInstancesAllowed = useOutlineSelector((state) =>
		selectMaxSectionInstancesAllowed(state, address)
	);

	const variant = useOutlineSelector(selectVariant);

	const instancesViews = useMemo(
		() =>
			range(instancesCount).map((instanceIndex) => {
				const key = `${label}_${instanceIndex}`;
				const instanceAddress = [...address, { instanceIndex }];
				return <InstanceView key={key} address={instanceAddress} />;
			}),
		[address, instancesCount, label]
	);

	const readOnly = useOutlineSelector(selectReadonly);
	const showAddButton = variant === OutlineVariant.EDIT && !readOnly;

	const addInstance = useOutlineSelector(({ addInstance }) => addInstance);

	return (
		<div
			css={sectionView({ depth, variant, sectionType })}
			className={`outline-section outline-section-${sectionType}`}>
			{instancesViews}
			{showAddButton && (
				<AddButton
					label={label}
					instancesCount={instancesCount}
					sectionType={sectionType}
					minInstancesRequired={minInstancesRequired}
					maxInstancesAllowed={maxInstancesAllowed}
					onAddition={() => addInstance(address, OutlineHierarchyItemType.Children)}
				/>
			)}
		</div>
	);
};

export default SectionView;
