import React from 'react';

import { GeoMapType } from '~/types';

import { Figure } from '../Figure';
import DescriptionTable from './DescriptionTable';
import GeoMap from './GeoMap';

import type { GeoMapElement } from '~/types/WebtextManifest';

interface Props {
	figure: GeoMapElement;
	getTopology?: (type: GeoMapType) => Promise<Record<string, any>>;
}

const GeoMapFigureWrapper: React.FC<Props> = (props) => {
	const { figure, getTopology } = props;

	return (
		<Figure
			figureOptions={{
				title: figure.title,
				instructions: figure.instructions,
				payload: (
					<>
						<GeoMap figure={figure} getTopology={getTopology} />
						<DescriptionTable figure={figure} />
					</>
				),
				caption: figure.caption,
				credits: figure.credits,
				header_style: 'map',
				figure_number: figure.figure_number
			}}
		/>
	);
};

export default GeoMapFigureWrapper;
