import React, { createContext, FC, useContext, useRef } from 'react';

import { useStoreWithEqualityFn } from 'zustand/traditional';

import {
	createOutlineStore,
	OutlineActions,
	OutlineState,
	OutlineStore,
	OutlineStoreProps
} from '~/components/Outline/store/store';

/**
 * Zustand store with props pattern is used
 * @see {@link https://github.com/pmndrs/zustand/blob/main/docs/guides/initialize-state-with-props.md}
 */
export const OutlineContext = createContext<OutlineStore | null>(null);

export const OutlineProvider: FC<OutlineStoreProps> = ({ children, ...props }) => {
	const storeRef = useRef<OutlineStore>();
	if (!storeRef.current) {
		storeRef.current = createOutlineStore(props);
	}
	return <OutlineContext.Provider value={storeRef.current}>{children}</OutlineContext.Provider>;
};

export const useOutlineStore = (): OutlineStore => {
	const store = useContext(OutlineContext);
	if (!store) throw new Error('Missing OutlineContext.Provider in the tree');

	return store;
};

export const useOutlineSelector = <T,>(
	selector: (state: OutlineState & OutlineActions) => T,
	equalityFn?: (left: T, right: T) => boolean
): T => {
	const store = useOutlineStore();
	return useStoreWithEqualityFn(store, selector, equalityFn);
};
