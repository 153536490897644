import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { Timeline } from '@knight-lab/timelinejs';

import { timelineStyles } from './TimelineStyles';

import type { TimelinePayload } from '~/types';

interface Props {
	data: TimelinePayload['data'];
	options: TimelinePayload['options'];
	onSlideChange: TimelinePayload['onSlideChange'];
}

const WebtextTimeline: React.FC<Props> = (props) => {
	const { data, options, onSlideChange } = props;

	const timelineElementRef = useRef<HTMLDivElement>();
	const timelineInstanceRef = useRef<any>();

	useLayoutEffect(() => {
		// Prevents duplicated Timeline instances on rerenders
		const { current: timelineInstance } = timelineInstanceRef;
		if (timelineInstance) {
			timelineInstance.on('change', ({ unique_id }) => onSlideChange?.(unique_id)); // onSlideChange may change
			return;
		}

		const optionsWithDefault = {
			timenav_mobile_height_percentage: 30,
			...options
		};

		const { current: timelineElement } = timelineElementRef;
		const timeline = new Timeline(timelineElement, data, optionsWithDefault);
		timelineInstanceRef.current = timeline;

		/**
		 * Some elements aren't displayed correctly on init (because of wrong position calculation).
		 * Workaround pt.1: use setTimeout 0 to wait until element is rendered.
		 * Workaround pt.2: use `_updateDisplay` to stop the initial 2s animation that breaks the expected 650 height
		 */
		timeline.on('ready', () => setTimeout(() => timeline._updateDisplay(null, true, 0)));
		timeline.on('change', ({ unique_id }) => onSlideChange?.(unique_id));
	}, [data, options, onSlideChange]);

	// Allows dynamically change the content of the slides and rerender the component
	useEffect(() => {
		const { current: timelineInstance } = timelineInstanceRef;
		timelineInstance?._initData(data);
	}, [data]);

	return (
		<div css={timelineStyles} className="timeline-container">
			<div ref={timelineElementRef} style={{ width: '100%' }} />
		</div>
	);
};

export default WebtextTimeline;
