import React from 'react';

import Modal from '~/components/Modal';
import WebtextButton from '~/components/WebtextButton';

import { paragraphStyles } from './styles';

interface Props {
	open: boolean;
	onClose: () => void;
}

const SecondInterventionModal: React.VFC<Props> = ({ open, onClose }) => {
	return (
		<Modal
			open={open}
			className="speedbump-intervention-2-modal"
			title="Need help answering the question?"
			actions={[
				<WebtextButton key={0} onClick={onClose}>
					Continue
				</WebtextButton>
			]}>
			<p css={paragraphStyles}>
				<strong>Tip:</strong> This chapter includes everything you need to answer the questions.
				Usually you can find the answer in the material just above the question! Before you try
				again, review the information related to the question.
			</p>
		</Modal>
	);
};

export default SecondInterventionModal;
