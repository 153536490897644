import { EditorType } from '../../FillIn/types/enums';
import { IDictionary, Kinds } from '../../WritingTemplate/types';

export const createLabelMap = (prompts: IDictionary): IDictionary =>
	prompts
		? prompts.reduce(
				(acc, prompt) =>
					Array.isArray(prompt)
						? { ...acc, ...onParseArray(prompt) }
						: { ...acc, ...onParseObject(prompt) },
				{}
		  )
		: {};

const onParseArray = (prompt) => prompt.filter(filter).reduce(reducer, {});

const onParseObject = (prompt: IDictionary) => prompt?.items?.filter(filter).reduce(reducer, {});

const isAcceptableFillIn = (element: IDictionary) =>
	element.kind === Kinds.FillIn && element.options?.editor?.type !== EditorType.RichText;

const filter = (element: IDictionary, id: number, array: IDictionary[]) => {
	const nextInput = array[id + 1];

	if (element.kind === Kinds.Text && nextInput && isAcceptableFillIn(nextInput)) {
		return element;
	} else if (isAcceptableFillIn(element)) {
		return element;
	}
};

const reducer = (acc: IDictionary, element: IDictionary, idx: number, arr: IDictionary[]) => {
	const prevElement = arr[idx - 1];

	if (isAcceptableFillIn(element) && prevElement && prevElement.kind === Kinds.Text) {
		const { value } = prevElement;
		const { dest } = element;

		return value && dest ? { ...acc, [dest]: value } : { ...acc };
	}

	return { ...acc };
};
