import { css } from '@emotion/react';
import { darken } from 'polished';

import { getThemeItem, Theme } from '~/styles/themes';

const iconButtonStyles = (theme: Theme) => css`
	color: ${getThemeItem(theme.figure.caption.color, theme)};
	background-color: transparent;
	font-size: 12px;
	font-family: ${getThemeItem(theme.fonts.app, theme)};
	padding: 0;

	display: inline-flex;

	cursor: pointer;

	&:hover {
		color: ${darken(0.05, getThemeItem(theme.figure.caption.color, theme))};
	}

	svg {
		margin-right: 5px;

		/** Override core .webtext-callout div svg styles */
		margin-left: 0 !important;
		margin-bottom: 0 !important;
		position: relative !important;
		font-size: inherit !important;
	}
`;

export const textStyles = (theme: Theme) => css`
	${iconButtonStyles(theme)}

	border: none;
`;

export const outlinedStyles = (theme: Theme) => css`
	${iconButtonStyles(theme)}

	border: 1px solid ${getThemeItem(theme.chart.lineColor, theme)} !important;
	border-radius: 3px;
	padding: 8px;
`;
