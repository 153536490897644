import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, mixins, Theme, ThemeName } from '~/styles/themes';

import { CitationStyle } from './types/enums';

import type { InputType } from './components/InlineInput';

export default (theme) => css`
	* {
		box-sizing: border-box;
	}
	font-size: ${theme.webtextQuestion.fontSize};
	font-family: ${theme.fonts.app} !important;
	background: ${theme.webtextQuestion.backgroundColor};
	max-width: ${theme.layout.contentColumnWidth};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 8px 0;

	ul {
		padding-left: unset;
	}
	${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetOverrides(theme)}
`;

const universalVelvetOverrides = ({ fonts }) => css`
	font-family: ${fonts.app};

	@media (max-width: ${breakpoints.small}) {
		font-size: 12px;
		margin-bottom: 6px;

		svg {
			display: block;
			margin-bottom: 1em !important;
		}
	}
`;

export const hiddenAccessible = (theme) => css`
	${mixins.webtextHiddenAccessible(theme)}
`;

export const placeholderStyle = (theme, className, isTable) => css`
	color: #6e6e6e !important;
	background: inherit !important;
	line-height: 1.5em !important;
	position: absolute;
	user-select: none;
	cursor: text;
	margin: 0 !important;
	pointer-events: none;
	font-size: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '18px' : '16px'};

	@media (max-width: ${breakpoints.small}) {
		font-size: 16px !important;
	}

	@media (max-width: ${breakpoints.extraSmall}) {
		font-size: 15px !important;
	}

	${className === 'rt-placeholder'
		? richTextPlaceholder(theme)
		: regularPlaceholder(theme, isTable)}
`;

const inTablePlaceholder = (theme) => css`
	word-wrap: normal;
	font-weight: normal !important;

	@media (max-width: ${breakpoints.small}) {
		word-break: break-all;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET ? inTablePlaceholderUV() : inTablePlaceholderCommon()}
`;

const regularPlaceholder = (theme, isTable) => css`
	${isTable
		? inTablePlaceholder(theme)
		: theme.name === ThemeName.UNIVERSAL_VELVET
		  ? regularPlaceholderUV()
		  : regularPlaceholderCommon()}
`;

const richTextPlaceholder = (theme) => css`
	${theme.name === ThemeName.UNIVERSAL_VELVET
		? richTextPlaceholderUV()
		: richTextPlaceholderCommon()}
`;

const inTablePlaceholderCommon = () => css`
	top: 0.55em;
	padding: 0 0.82em;
`;

const inTablePlaceholderUV = () => css`
	top: 0.55em;
	padding: 0 0.55em;
`;

const regularPlaceholderCommon = () => css`
	top: 0.55em;
	padding: 0 0.82em;
`;

const regularPlaceholderUV = () => css`
	top: 0.575em;
	padding: 0 0.55em;
`;

const richTextPlaceholderCommon = () => css`
	top: 0.5em;
	padding: 0 0.75em;
`;

const richTextPlaceholderUV = () => css`
	top: 0.5em;
	padding: 0 0.5em;
`;

export const areaStyles = (theme, readOnly?: boolean): SerializedStyles => css`
	border: ${theme.webtextQuestion.answerInput.border};
	font-size: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '18px' : '16px'};
	line-height: ${theme.webtextQuestion.answerInput.lineHeight};
	color: #000;
	font-family: ${theme.fonts.app};
	padding: 0.5em 0.75em;

	${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetTextArea(theme, readOnly)}
`;

const universalVelvetTextArea = (theme, readOnly?: boolean) => css`
	background-color: ${readOnly ? 'inherit' : '#fff'};
	border-radius: 6px;
	padding: 0.5em;

	&:focus {
		outline: none;
		border: 1px solid ${theme.colors.primary};
	}

	@media (max-width: ${breakpoints.small}) {
		font-size: 16px;
	}
`;

export const textAreaStyles = (
	theme: Theme,
	options: { withinTable: boolean; invalid?: boolean; readOnly?: boolean }
): SerializedStyles => {
	const { withinTable, invalid, readOnly } = options;
	return css`
		${areaStyles(theme, readOnly)}
		resize: none;
		overflow: hidden;

		box-sizing: border-box;
		${invalid && areaInvalid(theme)}
		${withinTable && areaWithinTable(theme)}
	`;
};

const areaWithinTable = (theme: Theme): SerializedStyles =>
	theme.name === ThemeName.UNIVERSAL_VELVET
		? css`
				padding: 0.35em;
		  `
		: css`
				padding: 0.35em 0.5em;
		  `;

const areaInvalid = (theme) => css`
	background: #fee6e6;
	border: 3px solid #e70000 !important;
	outline: none;
	${theme.name === ThemeName.UNIVERSAL_VELVET
		? areaInvalidUV()
		: css`
				line-height: 1.1em !important;
		  `}
`;

const areaInvalidUV = () => css`
	border: 1px solid #e70000 !important;

	&:focus {
		outline: none;
		border: 1px solid #e70000 !important;
	}
`;

export const isShowPlaceholder = (theme, isHide, className, isTable) => css`
	${isHide && 'z-index: -1000; color: inherit;'}
	${placeholderStyle(theme, className, isTable)}
`;

export const textAreaWrap = css`
	position: relative;
	min-width: 100%;

	span {
		padding-top: 0.5em;
	}
`;

export const bulletListIconStyle = (theme) => css`
	height: 13px;
	transform: scale(0.95);
	display: flex;

	svg {
		max-width: 100%;
		max-height: 100%;
	}
`;

const invalidStyle = (theme) => css`
	border: none !important;

	& > div {
		background: #fee6e6;
		border: 3px solid #e70000;
	}

	& > div:nth-of-type(2) {
		color: red;
		background: ${theme.webtextQuestion.backgroundColor};
	}

	& > div:last-of-type {
		border-top: ${theme.webtextQuestion.answerInput.border}!important;
	}

	& > div.toolbar {
		box-sizing: border-box;
		border-bottom: none;

		& > button:first-of-type {
			border-radius: 5px 0 0 0;
		}
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET && invalidStyleUVoverride()}
`;

const invalidStyleUVoverride = () => css`
	& > div {
		border: 1px solid #e70000;
		&:focus-within {
			border: 1px solid #e70000;
		}
	}

	& > div:last-of-type {
		border-radius: 0 0 5px 5px;
	}

	& > div.toolbar {
		border-radius: 5px 5px 0 0;
		border-bottom: none;

		& > button:first-of-type {
			border-radius: 5px 0 0 0;
		}
	}
`;

export const storyWrapper = (theme) => css`
	background: ${theme.webtextQuestion.backgroundColor};
	padding: 2em;
	max-width: ${theme.layout.contentColumnWidth};
`;

export const invalidMessage = (theme) => css`
	color: #ad0000;
	width: 100%;
	padding: 1em 0;
`;

export const wrapCitation = (citaitiontype) => css`
	${citaitiontype === CitationStyle.Turabian &&
	css`
		padding-bottom: 10px;
		display: inline-block;
		vertical-align: bottom !important;
		font-size: 13px;

		span > span:first-of-type {
			padding-right: 3px;
		}
	`}
`;

export const citationStyles = (theme) => css`
	max-height: 24px;
	background: #d8d8d8;
	padding: 0.2rem 0.4rem;
	margin: 0 0.1em;
	white-space: nowrap;
	display: inline-block;
	line-height: 1;
	border-radius: 15px;
	color: #000;
	cursor: pointer;
	caret-color: transparent;
	font-weight: normal !important;
	position: relative;
	vertical-align: top !important;
	margin-top: 1px !important;

	&:hover,
	&:focus {
		background-color: #494949;
		color: #fff;
	}

	&:hover .citation-remove {
		color: #000;
		background-color: #fff;
	}

	.citation-text {
		display: inline-block;
		padding-right: 5px;
	}

	.citation-remove {
		width: 18px;
		text-align: center;
		height: 18px;
		margin-left: 3px;
		border-radius: 50%;
		cursor: pointer;
		pointer-events: auto;
		font-weight: 100;
		background-color: #9e9e9e;
		color: #fff;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		top: -1px;
		font-size: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '18px' : '16px'};
	}

	.citation {
		padding-right: 10px;
		min-height: 18px;
		display: flex;
		align-items: center;
	}

	@media (max-width: ${breakpoints.small}) {
		.citation-remove {
			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				padding-top: 2px;
			`}
		}
	}

	@media (max-width: ${breakpoints.extraSmall}) {
		max-width: 95%;

		.citation-text {
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 90%;
			padding-right: 3px;
		}

		.citation-remove {
			position: relative;
			${theme.name !== 'universal_velvet' && 'vertical-align: middle;'}
		}
	}
`;

export const modalStyle = (theme) => css`
	padding: 2em 5em;
	font-family: ${theme.fonts.app};
	display: flex;
	flex-direction: column;

	.additional {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: ${theme.colors.primary}!important;

		div {
			margin: 0 !important;
			max-width: 100px;
			background-color: #fff;
			color: ${theme.colors.primary}!important;

			textarea {
				outline: none;
				border: 1px solid ${theme.colors.primary};
				vertical-align: middle;
			}
		}

		span {
			margin-right: 1em;
		}
	}

	.citation-hint {
		align-self: center;
		margin-bottom: 0 !important;
		color: #5f5f5f;
		text-decoration: none;
		border: none;
		background: none;
		font-size: 1rem;
		padding: 0;
		border-bottom: 1px dotted #5f5f5f;
		cursor: pointer;
	}

	.citation-submit {
		align-self: center;
		margin-top: 3em !important;

		.close-modal {
			position: absolute;
			right: 0px;
			top: 0px;
			background: transparent;
			border: 1px solid transparent;
			color: #fff;
			width: 35px;
			height: 35px;
			cursor: pointer;
		}
	}

	.citaiton-create-part {
		margin: 0.5em 0;
	}

	@media (max-width: ${breakpoints.small}) {
		padding: 1em 1.5em;
	}
`;

export const popupStyle = (theme) => css`
	font-family: ${theme.fonts.app};
	margin: 1em;

	table {
		border-collapse: collapse;

		th,
		td {
			text-align: start;
			padding: 5px;
		}

		tr:nth-child(2n + 2) {
			background: #333;
		}
		tr:nth-child(2n + 3) {
			background: #444;
		}
	}
`;

export const validationMessageStyle = (theme) => css`
	order: -1;
	border: none !important;
	background: ${theme.webtextQuestion.backgroundColor};
`;

export const inlineInputStyle = (
	theme: Theme,
	inputType: InputType,
	isFocus: boolean,
	iw: number,
	readOnly?: boolean,
	isInvalid?: boolean
): SerializedStyles => css`
	* {
		box-sizing: border-box;
	}
	color: rgb(0, 0, 0);
	line-height: 3em;
	border: 1px solid rgb(205, 205, 205);
	font-size: 16px !important;
	font-family: 'HelveticaNeueW01-55Roma', Helvetica, Arial, sans-serif !important;
	background-color: ${!readOnly ? '#fefefe' : 'inherit'};
	display: ${inputType} !important;
	cursor: text;
	caret-color: black;
	word-wrap: break-word;
	padding: 0.5em;
	min-width: ${iw / 2}px;
	max-width: 100%;
	margin: 0.325em 0 !important;
	margin-right: 3px;

	${inputType === 'inline-block' &&
	css`
		line-height: 1.3em;
		height: 2.25em;
		min-height: 37px;
		min-width: 100px;
		vertical-align: middle;
		margin-bottom: 9.5px !important;
	`}

	&[data-placeholder]:empty:before {
		content: attr(data-placeholder);
		color: #6e6e6e;
		display: ${isFocus ? 'none' : 'inline'};
		white-space: pre-wrap;
	}

	&:after {
		content: '  ';
		display: inline-block;
	}

	&:focus {
		outline: none;
	}

	${isInvalid &&
	css`
		background: #fee6e6;
		border: 3px solid #e70000;
		padding: 0.4em;
	`}

	${theme.name === ThemeName.UNIVERSAL_VELVET && inlineInputVelvet(theme, isInvalid, inputType)}
`;

const inlineInputVelvet = (theme: Theme, isInvalid: boolean, inputType: InputType) => css`
	border: 1px solid #cdcdcd;
	border-radius: 6px;
	padding: 0.75em;
	min-height: 45px;
	line-height: 3.5em;

	&:focus {
		border: 1px solid ${theme.colors.primary};
	}

	${isInvalid &&
	css`
		background: #fee6e6;
		border: 1px solid #e70000;
		padding: 0.75em;

		&:focus {
			border: 1px solid #e70000;
		}
	`}

	${inputType === 'inline-block' &&
	css`
		line-height: 1.3em;
		height: 2.5em;
		min-height: 45px;
		min-width: 100px;
	`}
`;
