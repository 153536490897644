import * as React from 'react';

export function Pyramid(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={34.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.933050340393933px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={30.5} fill="currentColor" />
			<path
				style={{
					fill: 'none',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.19978775529545px'
				}}
				d="M36.17 14.56 16.38 48.84h39.57L36.17 14.56z"
			/>
			<path
				style={{
					fill: '#fff'
				}}
				d="M23.24 39.12H49.4l6.21 9.81H16.04l7.2-9.81z"
			/>
			<path
				style={{
					fill: 'none',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.19978775529545px'
				}}
				d="M27.55 28.87H43.6M32.77 29.43l-7.5 13.46M38.28 29.1l-7.5 13.46M43.68 29.1l-7.5 13.46M46.8 33.37l-5.12 9.19"
			/>
		</svg>
	);
}
