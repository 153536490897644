import React, { forwardRef, ButtonHTMLAttributes } from 'react';

import { outlinedStyles, textStyles } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'text' | 'outlined';
}

const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
	const { variant, ...rest } = props;

	if (variant === 'outlined') {
		return <button {...rest} ref={ref} css={outlinedStyles} />;
	}
	return <button {...rest} ref={ref} css={textStyles} />;
});

IconButton.displayName = 'IconButton';

export default IconButton;
