import React, { FC, useEffect, useMemo, useRef } from 'react';

import { ClassNames } from '@emotion/react';
import { extent, max, merge, transpose } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleTime, scaleOrdinal } from 'd3-scale';
import { select } from 'd3-selection';
import { area, line, symbol, symbolCircle } from 'd3-shape';
import { timeMonth, timeYear, timeYears } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import cloneDeep from 'lodash-es/cloneDeep';
import { useResizeObserver } from 'usehooks-ts';

import { breakpoints, colors } from '~/styles/themes';

import ChartDescription from '../ChartDescription';
import {
	getBasis,
	getValueAxisFormat,
	parseDate,
	sanitizeAndScaleValue,
	sanitizeValue,
	scaleValue,
	valueOf
} from '../chartHelpers';
import { a11yShapes } from './shapesUtils';
import { refreshedChartStyles } from './styles';
import { getYAxisMaxLabelWidth } from './utils';

import type { Props as ChartFigureProps } from './Chart';
import type { ChartElement } from '~/types/WebtextManifest';

export type LineChart = Pick<
	ChartElement,
	| 'data'
	| 'x_axis_label'
	| 'x_axis_type'
	| 'x_ticks'
	| 'y_axis_label'
	| 'date_format'
	| 'min_bound'
	| 'max_bound'
	| 'colors'
	| 'fill_line'
	| 'line_stroke_width'
	| 'show_markers'
	| 'round_to'
>;

export interface Props extends Omit<ChartFigureProps, 'chart'> {
	chart: LineChart;
	isHighContrast?: boolean;
}

/**
 * Global dimensions
 */
const minChartWidth = 300;
const minChartHeight = 400;
const axisTitleHeight = 14;
const defaultLineStrokeWidth = 3;
const tickPadding = 5;
const labelPadding = 8;
const axisTitleBottomGap = 3;

const defaultMargins = {
	top: 20,
	bottom: 22,
	left: 0,
	right: 30
};

const getMargins = (x_axis_label: string, y_axis_label: string) => {
	/**
	 * If axis labels present add more margin space for them
	 */
	const margin = {
		...defaultMargins,
		left: y_axis_label?.length ? defaultMargins.left + axisTitleHeight * 2 : defaultMargins.left,
		bottom: x_axis_label?.length
			? defaultMargins.bottom + axisTitleHeight * 2
			: defaultMargins.bottom
	};

	return margin;
};

const getSeriesValues = (categoriesLabels, seriesData, basis) => {
	return categoriesLabels
		.map((label, index) => ({
			date: label,
			value: sanitizeAndScaleValue(seriesData[index], basis)
		}))
		.filter((entry) => !isNaN(entry.value));
};

const LineChart: FC<Props> = (props) => {
	const { chart, isHighContrast } = props;

	const chartData = cloneDeep(chart.data);

	/**
	 * Handle resize events
	 */
	const chartContainerRef = useRef<HTMLDivElement | null>(null);
	const { width: chartContainerWidth = 0 } = useResizeObserver({
		ref: chartContainerRef,
		box: 'border-box'
	});
	const isSmallView = useMemo(
		() => chartContainerWidth < parseFloat(breakpoints.small),
		[chartContainerWidth]
	);

	const seriesLabels = chartData.shift().slice(1);

	const categoriesParser = chart.x_axis_type === 'linear' ? parseInt : parseDate;
	const categoriesLabels = chartData.map((row) => categoriesParser(row[0]));

	const valuesData = chartData.map((row) => row.slice(1));
	const transposedValuesData = transpose(valuesData);

	const valueType = valueOf(valuesData[0][0]).type;
	const maxValue = max(merge(valuesData), (item: string | number) => sanitizeValue(item));

	/**
	 * Calculated dimensions
	 */
	const margin = useMemo(
		() => getMargins(chart.x_axis_label, chart.y_axis_label),
		[chart.x_axis_label, chart.y_axis_label]
	);
	const height = minChartHeight + margin.top + margin.bottom;

	const color = scaleOrdinal().range(chart.colors);

	useEffect(() => {
		const { current: chartContainer } = chartContainerRef;

		/**
		 * Don't try to draw until we can received the size of the window
		 */
		if (!chartContainer || chartContainerWidth === 0) return;

		const width = Math.max(minChartWidth, chartContainerWidth);

		const valuesLowerBound = sanitizeValue(chart.min_bound) || 0;
		const valuesUpperBound = sanitizeValue(chart.max_bound) || maxValue;

		const basis = getBasis(valuesLowerBound, valuesUpperBound, valueType);
		const valuesScaleLowerBound = scaleValue(valuesLowerBound, basis);
		const valuesScaleUpperBound = scaleValue(valuesUpperBound, basis);

		const data = seriesLabels.map((name, index) => ({
			name: name,
			values: getSeriesValues(categoriesLabels, transposedValuesData[index], basis)
		}));

		const dateRange: any = extent(categoriesLabels);

		const chartXTicks = Number(chart.x_ticks) || 1;

		const draw = () => {
			/**
			 * Chart container
			 */
			const svgContainer = select(chartContainer)
				.append('svg')
				.attr('class', 'chart-svg')
				.attr('width', width)
				.attr('height', height)
				.attr('aria-hidden', true);

			const svg: any = svgContainer
				.append('g')
				.attr('transform', `translate(${margin.left}, ${margin.top})`);

			/**
			 * Draw y axis
			 */
			const yScale = scaleLinear()
				.domain([valuesScaleLowerBound, valuesScaleUpperBound])
				.range([height - margin.bottom - margin.top, 0]);

			const yFormat = getValueAxisFormat(chart.round_to, valueType);
			const yAxis = axisLeft(yScale)
				.tickFormat(yFormat)
				.tickSizeInner(-width)
				.tickSizeOuter(0)
				.tickPadding(5);

			svg
				.append('g')
				.call(yAxis)
				.attr('class', 'yAxis')
				.selectAll('text')
				.attr('class', 'chart-value-axis-tick-text')
				.attr('aria-hidden', true);

			const yAxisMaxLabelWidth = getYAxisMaxLabelWidth(svg);
			/**
			 * Update svg's transform attribute according to yAxisMaxLabelWidth
			 */
			svg.attr(
				'transform',
				`translate(${margin.left + yAxisMaxLabelWidth + labelPadding}, ${margin.top})`
			);

			/**
			 * Draw x axis
			 */
			const xScale = ((chart.x_axis_type === 'linear' ? scaleLinear() : scaleTime()) as any)
				.domain(dateRange)
				.range([0, width - margin.left - margin.right - yAxisMaxLabelWidth]);

			let xTicksStep = chartXTicks;
			let xAxisLabelsOverlap = false;

			drawXAxis(svg, xTicksStep, xScale);

			xAxisLabelsOverlap = detectXAxisLabelsOverlap(svg);

			/**
			 * While x axis labels overlap decrease the number of ticks and redraw the axis
			 */
			while (xAxisLabelsOverlap) {
				svg.selectAll('.xAxis').remove();

				xTicksStep = xTicksStep * 2;
				drawXAxis(svg, xTicksStep, xScale);

				xAxisLabelsOverlap = detectXAxisLabelsOverlap(svg);
			}

			/**
			 * Draw the lines
			 */
			const lines: any = svg
				.selectAll('.lines')
				.data(data)
				.enter()
				.append('g')
				.attr('class', 'lines');

			const valueLine = (line() as any)
				.defined((d) => !isNaN(d.value))
				.x((d) => xScale(d.date))
				.y((d) => yScale(d.value));

			lines
				.append('path')
				.attr('class', 'line')
				.attr('d', (d) => valueLine(d.values))
				.attr('fill', 'none')
				.attr('stroke', (d) => (isHighContrast ? colors.brown : color(d.name)))
				.attr('stroke-width', Number(chart.line_stroke_width) || defaultLineStrokeWidth);

			/**
			 * Draw the markers
			 */
			if (chart.show_markers && !isHighContrast) {
				const markers = svg
					.selectAll('.circles')
					.data(data)
					.enter()
					.append('g')
					.attr('fill', 'white')
					.attr('stroke', (d) => (isHighContrast ? colors.brown : color(d.name)))
					.attr('stroke-width', 2);

				markers
					.selectAll('circle')
					.data((d) => d.values)
					.enter()
					.append('path')
					.filter((d) => !isNaN(d.value))
					.attr(
						'd',
						symbol()
							.type(symbolCircle)
							.size(Number(chart.line_stroke_width || defaultLineStrokeWidth) * 3 * 10)
					)
					.attr('transform', (d) => `translate(${xScale(d.date)}, ${yScale(d.value)})`);
			}

			if (isHighContrast) {
				const shapeScale = scaleOrdinal(a11yShapes);

				/**
				 * Add markerType property for each value
				 */
				data.forEach((line, lineIndex) => {
					line.values.forEach((value) => {
						value.markerType = shapeScale(lineIndex);
					});
				});

				const markers = svg.selectAll('.circles').data(data).enter().append('g');

				markers
					.selectAll('circle')
					.data((d) => d.values)
					.enter()
					.filter((d) => !isNaN(d.value))
					.append('g')
					.attr('fill', colors.brown)
					.attr('stroke', colors.brown)
					.html((d) => d.markerType)
					.attr('transform', (d) => `translate(${xScale(d.date)}, ${yScale(d.value)})`);
			}

			/**
			 * Fill area
			 */
			if (chart.fill_line) {
				const lineArea = (area() as any)
					.x((d) => xScale(d.date))
					.y0(height - margin.top - margin.bottom)
					.y1(
						(d) => yScale(d.value) + Number(chart.line_stroke_width || defaultLineStrokeWidth) / 2
					);

				lines
					.append('path')
					.attr('class', 'area')
					.attr('d', (d) => lineArea(d.values))
					.attr('fill', (d) => (isHighContrast ? colors.beige : color(d.name)))
					.attr('fill-opacity', isHighContrast ? '1' : '0.8');
			}

			/**
			 * Draw axis titles
			 */
			if (chart.x_axis_label?.length > 0) {
				svgContainer
					.append('text')
					.attr('class', 'chart-x-axis-title')
					.attr('text-anchor', 'middle')
					.attr('transform', `translate(${width / 2}, ${height - axisTitleBottomGap})`)
					.text(chart.x_axis_label);
			}

			if (chart.y_axis_label?.length > 0) {
				svgContainer
					.append('text')
					.attr('class', 'chart-y-axis-title')
					.attr('text-anchor', 'middle')
					.attr('transform', `translate(${axisTitleHeight}, ${height / 2}) rotate(-90)`)
					.text(chart.y_axis_label);
			}
		};

		const drawXAxis = (svg, xTicksStep, xScale) => {
			let xAxis;

			if (chart.x_axis_type === 'linear') {
				const xTicks = (dateRange[1] - dateRange[0]) / xTicksStep + 1;
				xAxis = axisBottom(xScale).ticks(xTicks).tickSizeOuter(0);
			}

			if (chart.x_axis_type === 'datetime') {
				const multipleYears = timeYears(dateRange[0], dateRange[1], 2).length > 0;
				const xFormat = timeFormat(chart.date_format || (multipleYears ? '%Y' : '%b'));
				const xTicks = multipleYears ? timeYear.every(xTicksStep) : timeMonth.every(xTicksStep);

				xAxis = axisBottom(xScale).ticks(xTicks).tickFormat(xFormat).tickSizeOuter(0);
			}

			svg
				.append('g')
				.attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
				.call(xAxis)
				.attr('class', 'xAxis')
				.selectAll('text')
				.attr('class', 'chart-value-axis-tick-text')
				.attr('aria-hidden', true);
		};

		/**
		 * Remove all SVG elements and tooltip instances before redrawing
		 */
		select(chartContainer).selectAll('*').remove();

		draw();
	}, [
		chartContainerRef,
		chartContainerWidth,
		height,
		categoriesLabels,
		valueType,
		seriesLabels,
		transposedValuesData,
		color,
		maxValue,
		margin.left,
		margin.right,
		margin.top,
		margin.bottom,
		chart.max_bound,
		chart.min_bound,
		chart.x_axis_type,
		chart.date_format,
		chart.x_ticks,
		chart.line_stroke_width,
		chart.fill_line,
		chart.x_axis_label,
		chart.y_axis_label,
		chart.round_to,
		chart.show_markers,
		isHighContrast
	]);

	return (
		<ClassNames>
			{({ cx }) => (
				<figure
					css={(theme) => refreshedChartStyles(theme, { isSmallView })}
					className={cx('refreshed-chart', 'refreshed-line-chart')}>
					<div
						className="refreshed-chart-container"
						style={{ minWidth: minChartWidth }}
						ref={chartContainerRef}
					/>
					<ChartDescription chart={chart as ChartElement} />
				</figure>
			)}
		</ClassNames>
	);
};

export default LineChart;

export const detectXAxisLabelsOverlap = (svg) => {
	const ticks = svg.selectAll('.xAxis .tick text');

	let overlapping = false;
	ticks.each(function (_, i) {
		if (i > 0) {
			const prev = select(ticks.nodes()[i - 1]);
			const curr = select(this);
			const prevBox = prev.node().getBoundingClientRect();
			const currBox = curr.node().getBoundingClientRect();

			if (prevBox.right + tickPadding > currBox.left) {
				overlapping = true;
			}
		}
	});
	return overlapping;
};
