import { MouseEvent, TouchEvent } from 'react';

export const isTouchEvent = (event: unknown): event is TouchEvent =>
	typeof event === 'object' && event !== null && 'type' in event && 'touches' in event;

export const isMouseEvent = (event: unknown): event is MouseEvent =>
	typeof event === 'object' && event !== null && 'type' in event && 'clientX' in event;

export interface ScrollToEventDetail {
	elementId: string;
}
export class ScrollToPageElementCustomEvent extends CustomEvent<ScrollToEventDetail> {
	public static readonly eventName = 'scroll_to_page_element';

	constructor(detail: ScrollToEventDetail) {
		super(ScrollToPageElementCustomEvent.eventName, { detail });
	}
}
