import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';

export const writingTemplateStyles = (theme: Theme, options: { noBottomMargin?: boolean }) => {
	const { webtextWritingTemplate } = theme;
	const { fontFamily } = webtextWritingTemplate || {};

	return css`
		position: relative;
		min-height: 100px;

		${fontFamily &&
		css`
			font-family: ${getThemeItem(fontFamily, theme)} !important;
		`};

		${!options.noBottomMargin &&
		css`
			margin-bottom: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '48px' : '28px'};
		`}

		a {
			font-weight: ${theme.webtextPage.a.fontWeight};
		}
	`;
};

export const wrapperWithContentStyle = (theme: Theme): SerializedStyles => css`
	max-width: ${theme.name === ThemeName.UNIVERSAL_VELVET ? '800px' : '700px'};
	margin: 0 auto;
	display: flex;
	flex-direction: column;

	h2 {
		font-size: 30px;
		margin: 30px 0 15px;
		color: #047da4;
		font-family: 'HelveticaNeueW01-45Ligh', Helvetica, Arial, sans-serif;
		font-weight: 300;
		text-transform: none;
	}

	p {
		margin-bottom: 30px;
		font-size: 19px;
		line-height: 1.6;
	}

	img {
		max-width: 700px;
		padding-bottom: 20px;
		display: block;
	}
`;
/**
 * In this style important is used to override the default margin of the div from core styles
 */
export const dividerStyles = (theme: Theme, units = 0): SerializedStyles => css`
	border: none;
	color: #cccccc;
	background-color: #cccccc;
	height: 1px;
	${!units
		? `margin: 2em 0 ${theme.name !== ThemeName.UNIVERSAL_VELVET ? '1.5em' : ''} !important;`
		: `margin: ${units * 1.6}em 0 !important;`}

	@media (max-width: ${breakpoints.small}) {
		margin: 1.5em 0 !important;
	}
`;

/**
 * In this style important is used to override the default margin of the div from core styles
 */
export const gapStyle = (units: number): SerializedStyles => css`
	display: block !important;
	margin: ${units * 1.6}em 0;
`;
