import { css } from '@emotion/react';

export default (theme, type, fromView?) => css`
	font-family: ${theme?.fonts?.app} !important;
	list-style-type: ${type} !important;
	margin-bottom: 0px !important;

	${fromView &&
	css`
		& > li {
			margin-top: 10px;
			margin-bottom: 0;
		}
	`}

	li > div {
		padding: 5px 0;
	}
	padding-inline-start: 1.5em;
`;
