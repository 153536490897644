import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { css } from '@emotion/react';

import { breakpoints, Theme } from '~/styles/themes';

interface Props {
	numQuestions: number;
	activeIndex: number;
	onPrevious: () => void;
	onNext: () => void;
}

const InstructorViewPoolNavigation: React.VFC<Props> = ({
	activeIndex,
	numQuestions,
	onPrevious,
	onNext
}) => {
	if (numQuestions < 2) return null;

	return (
		<div css={styles}>
			<span className="label">
				<span>Browse items in this pool</span> <wbr />
				<span className="num-questions">
					({activeIndex + 1} of {numQuestions})
				</span>
			</span>
			<button
				aria-label="previous pool question"
				disabled={activeIndex === 0}
				onClick={onPrevious}
				className="bordered-button">
				<FaChevronLeft size={17} />
			</button>
			<button
				aria-label="next pool question"
				disabled={activeIndex === numQuestions - 1}
				onClick={onNext}
				className="bordered-button">
				<FaChevronRight size={17} />
			</button>
			<div className="explanatory-text" css={explanatoryTextStyles}>
				The item assigned to each student for their initial attempt is randomized, and students will
				receive a different item from the pool if they reset. Visit our{' '}
				<a
					href="https://instructorhelp.soomolearning.com/en/articles/8233562"
					target="_blank"
					rel="noreferrer">
					Help Center
				</a>{' '}
				to learn more.
			</div>
		</div>
	);
};

export default InstructorViewPoolNavigation;

export const explanatoryTextStyles = css`
	text-align: end;
	font-size: 14px;
	color: #464646;
	line-height: 18px;
`;

const styles = (theme: Theme) => css`
	max-width: 600px;
	display: grid;
	margin: 1.5rem 0 0 auto;
	padding: 0;
	grid-template-columns: 1fr auto auto;
	justify-items: flex-end;
	align-items: center;

	[data-in-question-deck='true'] & {
		margin-top: 1rem;
	}

	.label {
		font-weight: 500;
		line-height: 1;

		@media (max-width: ${breakpoints.small}) {
			text-align: end;

			span.num-questions {
				white-space: nowrap;
			}
		}
	}

	.explanatory-text {
		padding: 0.5rem 0 0;
		grid-row: 2;
		grid-column: span 3;

		a {
			text-decoration: underline;
		}
	}

	a,
	a:visited {
		color: ${theme.colors.primary};
	}

	button {
		margin: 0;
		padding: 0.25rem;
		line-height: 0;
		color: ${theme.colors.primary};
		background: none;
		border: 1px solid ${theme.colors.primary} !important; // needed to override a11y.js in core
		cursor: pointer;

		&[aria-label^='previous'] {
			margin-left: 0.75rem;
			border-radius: 3px 0 0 3px;
		}

		&[aria-label^='next'] {
			margin-left: -1px;
			border-radius: 0 3px 3px 0;
		}

		&:disabled {
			opacity: 0.25;
			cursor: not-allowed;
		}
	}
`;
