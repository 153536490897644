import cloneDeep from 'lodash-es/cloneDeep';
import { transparentize } from 'polished';

import constants from '../base/constants';
import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

const strayerRed = '#ff562b';
const strayerTeal = '#38c0c5';
const strayerGrayHigh = '#f1f1f2';

const { roboto, oswald } = constants.fonts;

theme.fonts.app = roboto;
theme.fonts.webtext = roboto;
theme.fonts['webtext-question-body'] = roboto;
theme.fonts['webtext-question-answer'] = roboto;
theme.fonts['webtext-question'] = roboto;
theme.fonts['webtext-question-buttons'] = roboto;
theme.fonts['webtext-question-rejoinder-correctness'] = roboto;
theme.fonts['webtext-heading-font-family'] = oswald;
theme.fonts.serif = roboto;
theme.fonts['sans-serif'] = oswald;

theme.colors.primary = strayerRed;
theme.colors.secondary = strayerGrayHigh;
theme.colors.accent = strayerTeal;
theme.colors.link = (theme) => theme.colors.primary;

theme.chapterSplash.title.name.textTransform = 'uppercase';
theme.chapterSplash.separatorColor = (theme) => theme.colors.primary;
theme.chapterSplash.credit.color = '#fcfcfc';

theme.element.title.fontFamily = oswald;
theme.element.title.fontSize = '18px';

theme.webtextPage.title.fontSize = '40px';
theme.webtextPage.title.textTransform = 'uppercase';
theme.webtextPage.title.color = (theme) => theme.colors.primary;
theme.webtextPage.headings.color = (theme) => theme.colors.accent;
theme.webtextPage.headings.textTransform = 'uppercase';

theme.webtextPage.strong.color = '#000';
theme.webtextPage.strong.fontWeight = 700;
theme.webtextPage.a.fontWeight = 700;

theme.global.webtextPageInstruction.backgroundColor = (theme) => theme.colors.primary;
theme.global.webtextPageInstruction.fontFamily = roboto;
theme.global.webtextPageInstruction.fontSize = '19px';
theme.global.webtextPageInstruction.fontWeight = 700;
theme.global.webtextPageInstruction.lineHeight = '1.6em';

theme.global.webtextInvestigationObjective.fontFamily = 'Oswald, Helvetica, Arial, sans-serif';
theme.global.webtextInvestigationObjective.fontSize = '19px';
theme.global.webtextArticleSource.fontFamily = oswald;

theme.webtextPullQuote.heading.fontSize = '19px';
theme.webtextPullQuote.heading.fontFamily = constants.fonts['helvetica-neue-bold'];
theme.webtextPullQuote.heading.fontWeight = '700';

theme.bigQuoteEpigraph.borderColor = '#0e5692';
theme.bigQuoteEpigraph.authorColor = '#3d86c5';
theme.bigQuoteEpigraph.quoteTextFont = constants.fonts['times'];
theme.bigQuoteEpigraph.quoteAuthorFont = roboto;
theme.bigQuoteEpigraph.quoteSourceFont = roboto;

theme.waveformAudioPlayer.colors.progress = (theme) => theme.colors.accent;

theme.webtextQuestion.questionPrompt.fontFamily = theme.fonts.app;
theme.webtextQuestion.questionPrompt.fontSize = '1.2em';

theme.webtextQuestionButtons.fontSize = '19px';
theme.webtextQuestionButtons.fontWeight = constants.fontWeights.bold;

theme.popupQuiz.header.fontSize = '19px';
theme.popupQuiz.header.fontWeight = constants.fontWeights.bold;

theme.webtextWritingTemplate.instructions.fontWeight = constants.fontWeights.bold;
theme.webtextWritingTemplate.instructions.fontSize = '19px';
theme.webtextWritingTemplate.instructions.color = '#fff';
theme.webtextWritingTemplate.instructions.padding = '30px';

theme.artifactLink.title.fontFamily = oswald;
theme.artifactLink.title.textDecorationColor = '#bcbec0';
theme.artifactLink.transcript.fontWeight = 700;

theme.figure = {
	...theme.figure,
	number: {
		...theme.figure.number,
		fontFamily: oswald,
		textTransform: 'uppercase'
	},
	title: {
		...theme.figure.title,
		fontFamily: oswald
	}
};

theme.tableFigure.fontSize = '17px';
theme.tableFigure.headerBackground = strayerTeal;

theme.endOfChapter.message.fontWeight = 700;

theme.notifications.fontFamily = theme.fonts.app;

theme.comments.instructor.header.title.fontSize = '19px';
theme.comments.instructor.header.title.fontWeight = '500';
theme.comments.instructor.header.title.fontFamily = theme.fonts.app;
theme.comments.instructor.header.title.marginBottom = '3px';
theme.comments.instructor.header.timestamp.fontFamily = theme.fonts.app;
theme.comments.instructor.header.reveal.fontSize = '19px';
theme.comments.instructor.header.reveal.fontFamily = theme.fonts.app;
theme.comments.instructor.header.reveal.arrow.top = '0.18em';
theme.comments.instructor.comment.fontSize = '19px';
theme.comments.instructor.comment.fontFamily = theme.fonts.app;

theme.tableOfContents.currentItemColor = (theme) => transparentize(0.25, theme.colors.primary);

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}0D`;

export default theme;
