import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export default (theme) => css`
	.answer-posted {
		font-size: ${theme.shortAnswer.answerPosted.fontSize};
		line-height: ${theme.shortAnswer.answerPosted.lineHeight};
		color: ${theme.shortAnswer.answerPosted.color};
		white-space: pre-wrap;
	}

	.answer-posted.posted {
		margin-bottom: 2.5em;

		@media (max-width: ${breakpoints.small}) {
			margin-bottom: 16px;
		}
	}

	.button-group {
		@media (max-width: ${breakpoints.small}) {
			width: 100%;
		}
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		&.question .answer {
			margin-bottom: 12px;
		}

		.button-group {
			@media (max-width: ${breakpoints.small}) {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
				button {
					flex-basis: 48%;
				}
			}
		}

		.answer-posted.posted {
			font-size: 18px;
			color: #545454;
			letter-spacing: 0;
			line-height: 30px;

			@media (max-width: ${breakpoints.small}) {
				font-size: 15px;
			}
		}

		.answer-status {
			font-size: 16px;
			color: #545454;
			letter-spacing: 0;

			@media (max-width: ${breakpoints.small}) {
				font-size: 12px;
			}
		}

		.divider {
			margin-top: 50px;
		}

		.reference-answer-section {
			padding-top: 28px;
			padding-bottom: 24px;
			background: white;
			margin-left: -50px;
			padding-left: 50px;
			font-size: 18px;
			margin-top: 27px;

			.webtext-question-label {
				color: black;
				font-family: ${theme.fonts['webtext-question-body']};
				font-size: 18px;
			}

			.reference-answer {
				font-family: ${theme.fonts['webtext-question-rejoinder']};
				font-style: italic;
				font-size: 18px;
				color: #4e4e4e;
				letter-spacing: 0;
				line-height: 1.67;

				@media (max-width: ${breakpoints.small}) {
					font-size: 15px;
				}
			}

			@media (max-width: ${breakpoints.small}) {
				margin-left: -25px;
				padding-left: 25px;
			}
		}
	`}
`;
