import * as React from 'react';

export function ScalesOfJustice(): JSX.Element {
	return (
		<svg
			width="1em"
			height="1em"
			version="1.1"
			viewBox="0 0 99 100"
			xmlns="http://www.w3.org/2000/svg">
			<g fill="none">
				<g transform="translate(2 2)">
					<path
						d="m94.426 48.001c0 25.988-21.066 47.055-47.055 47.055-25.987 0-47.054-21.067-47.054-47.055 0-25.987 21.067-47.054 47.054-47.054 25.989 0 47.055 21.067 47.055 47.054z"
						stroke="currentColor"
						strokeWidth="4"
					/>
					<path
						d="m88.974 48.001c0 22.978-18.626 41.604-41.604 41.604-22.976 0-41.602-18.626-41.602-41.604 0-22.977 18.626-41.603 41.602-41.603 22.978 0 41.604 18.626 41.604 41.603"
						fill="currentColor"
					/>
					<path
						d="m67.587 50.82c-4.214 0-7.716-3.111-8.328-7.157h16.657c-0.612 4.046-4.114 7.157-8.329 7.157m-2e-3 -22.636c2.144 3.617 5.453 9.642 6.267 12.946h-12.525c0.769-3.093 3.746-8.724 6.258-12.946m2e-3 -5.352c0.454 0 0.823 0.369 0.823 0.823s-0.369 0.822-0.823 0.822c-0.453 0-0.822-0.368-0.822-0.822s0.369-0.823 0.822-0.823m9.69 18.298h-0.848c-0.827-4.195-5.068-11.581-6.946-14.709 0.881-0.605 1.459-1.62 1.459-2.766 0-1.85-1.504-3.356-3.355-3.356-1.85 0-3.354 1.506-3.354 3.356 0 1.146 0.578 2.161 1.458 2.766-1.877 3.128-6.118 10.514-6.945 14.709h-0.848c-0.7 0-1.267 0.567-1.267 1.267 0 6.041 4.915 10.956 10.957 10.956 6.041 0 10.956-4.915 10.956-10.956 0-0.7-0.567-1.267-1.267-1.267"
						fill="white"
					/>
					<path
						d="m67.587 50.82c-4.214 0-7.716-3.111-8.328-7.157h16.657c-0.612 4.046-4.114 7.157-8.329 7.157zm-2e-3 -22.636c2.144 3.617 5.453 9.642 6.267 12.946h-12.525c0.769-3.093 3.746-8.724 6.258-12.946zm2e-3 -5.352c0.454 0 0.823 0.369 0.823 0.823s-0.369 0.822-0.823 0.822c-0.453 0-0.822-0.368-0.822-0.822s0.369-0.823 0.822-0.823zm9.69 18.298h-0.848c-0.827-4.195-5.068-11.581-6.946-14.709 0.881-0.605 1.459-1.62 1.459-2.766 0-1.85-1.504-3.356-3.355-3.356-1.85 0-3.354 1.506-3.354 3.356 0 1.146 0.578 2.161 1.458 2.766-1.877 3.128-6.118 10.514-6.945 14.709h-0.848c-0.7 0-1.267 0.567-1.267 1.267 0 6.041 4.915 10.956 10.957 10.956 6.041 0 10.956-4.915 10.956-10.956 0-0.7-0.567-1.267-1.267-1.267z"
						stroke="white"
						strokeWidth=".25"
					/>
					<path
						d="m56.763 76.198h-19.879v-2.213h19.879v2.213zm-4.615-4.746h-10.65v-1.949h10.65v1.949zm-6.168-38.543c0.274 0.049 0.555 0.075 0.843 0.075s0.569-0.026 0.843-0.075v34.061h-1.686v-34.061zm-26.313 17.319c0.768-3.094 3.746-8.725 6.258-12.947 2.143 3.617 5.452 9.642 6.266 12.947h-12.524zm6.26 9.689c-4.214 0-7.716-3.11-8.328-7.157h16.657c-0.614 4.047-4.114 7.157-8.329 7.157zm0-27.987c0.454 0 0.823 0.368 0.823 0.822s-0.369 0.822-0.823 0.822c-0.453 0-0.823-0.368-0.823-0.822s0.37-0.822 0.823-0.822zm20.896-6.024c1.253 0 2.273 1.019 2.273 2.273 0 1.253-1.02 2.272-2.273 2.272s-2.272-1.019-2.272-2.272c0-1.254 1.019-2.273 2.272-2.273zm11.206 45.546h-3.347v-3.216c0-0.699-0.568-1.266-1.267-1.266h-3.216v-35.375c0.828-0.819 1.361-1.935 1.423-3.174l5.355-1.152c0.684-0.147 1.119-0.821 0.972-1.505-0.147-0.683-0.821-1.118-1.505-0.972l-5.367 1.156c-0.805-1.529-2.409-2.574-4.254-2.574-2.555 0-4.649 2.003-4.797 4.521l-13.389 2.881c-0.612-0.835-1.598-1.379-2.71-1.379-1.85 0-3.356 1.505-3.356 3.355 0 1.147 0.579 2.161 1.459 2.766-1.877 3.128-6.118 10.515-6.944 14.71h-0.849c-0.699 0-1.266 0.567-1.266 1.266 0 6.041 4.915 10.956 10.956 10.956 6.042 0 10.957-4.915 10.957-10.956 0-0.699-0.568-1.266-1.267-1.266h-0.849c-0.827-4.196-5.067-11.582-6.945-14.71 0.754-0.518 1.287-1.337 1.424-2.282l13.303-2.862c0.234 0.453 0.538 0.865 0.897 1.221v35.376h-3.216c-0.699 0-1.266 0.566-1.266 1.265v3.217h-3.348c-0.699 0-1.267 0.567-1.267 1.267v4.745c0 0.699 0.568 1.266 1.267 1.266h22.412c0.699 0 1.267-0.567 1.267-1.266v-4.746c0-0.699-0.568-1.267-1.267-1.267z"
						fill="white"
					/>
					<path
						d="m56.763 76.198h-19.879v-2.213h19.879v2.213zm-4.615-4.746h-10.65v-1.949h10.65v1.949zm-6.168-38.543c0.274 0.049 0.555 0.075 0.843 0.075s0.569-0.026 0.843-0.075v34.061h-1.686v-34.061zm-26.313 17.319c0.768-3.094 3.746-8.725 6.258-12.947 2.143 3.617 5.452 9.642 6.266 12.947h-12.524zm6.26 9.689c-4.214 0-7.716-3.11-8.328-7.157h16.657c-0.614 4.047-4.114 7.157-8.329 7.157zm0-27.987c0.454 0 0.823 0.368 0.823 0.822s-0.369 0.822-0.823 0.822c-0.453 0-0.823-0.368-0.823-0.822s0.37-0.822 0.823-0.822zm20.896-6.024c1.253 0 2.273 1.019 2.273 2.273 0 1.253-1.02 2.272-2.273 2.272s-2.272-1.019-2.272-2.272c0-1.254 1.019-2.273 2.272-2.273zm11.206 45.546h-3.347v-3.216c0-0.699-0.568-1.266-1.267-1.266h-3.216v-35.375c0.828-0.819 1.361-1.935 1.423-3.174l5.355-1.152c0.684-0.147 1.119-0.821 0.972-1.505-0.147-0.683-0.821-1.118-1.505-0.972l-5.367 1.156c-0.805-1.529-2.409-2.574-4.254-2.574-2.555 0-4.649 2.003-4.797 4.521l-13.389 2.881c-0.612-0.835-1.598-1.379-2.71-1.379-1.85 0-3.356 1.505-3.356 3.355 0 1.147 0.579 2.161 1.459 2.766-1.877 3.128-6.118 10.515-6.944 14.71h-0.849c-0.699 0-1.266 0.567-1.266 1.266 0 6.041 4.915 10.956 10.956 10.956 6.042 0 10.957-4.915 10.957-10.956 0-0.699-0.568-1.266-1.267-1.266h-0.849c-0.827-4.196-5.067-11.582-6.945-14.71 0.754-0.518 1.287-1.337 1.424-2.282l13.303-2.862c0.234 0.453 0.538 0.865 0.897 1.221v35.376h-3.216c-0.699 0-1.266 0.566-1.266 1.265v3.217h-3.348c-0.699 0-1.267 0.567-1.267 1.267v4.745c0 0.699 0.568 1.266 1.267 1.266h22.412c0.699 0 1.267-0.567 1.267-1.266v-4.746c0-0.699-0.568-1.267-1.267-1.267z"
						stroke="white"
						strokeWidth=".25"
					/>
					<path
						d="m60.665 26.524c0.088 0 0.178-9e-3 0.268-0.029l0.922-0.198c0.684-0.148 1.119-0.821 0.972-1.504-0.147-0.685-0.821-1.12-1.505-0.972l-0.923 0.198c-0.683 0.148-1.119 0.821-0.971 1.505 0.128 0.594 0.653 1 1.237 1"
						fill="white"
					/>
					<path
						d="m60.665 26.524c0.088 0 0.178-9e-3 0.268-0.029l0.922-0.198c0.684-0.148 1.119-0.821 0.972-1.504-0.147-0.685-0.821-1.12-1.505-0.972l-0.923 0.198c-0.683 0.148-1.119 0.821-0.971 1.505 0.128 0.594 0.653 1 1.237 1z"
						stroke="white"
						strokeWidth=".25"
					/>
				</g>
			</g>
		</svg>
	);
}
