import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';
import round from 'lodash-es/round';

import { localStorageGet } from '~/utils/localStorage';

import { alphabet, headerColumnWidth, regex, staticErrorReturns } from './constants';
import {
	getCellCoordinates,
	getEndCell,
	getHeightValues,
	getStartCell,
	getStartPosition,
	getWidthValues,
	normalizeRange
} from './selection';
import {
	Cell,
	CellFormatting,
	CellType,
	CellValues,
	EditableRanges,
	FormatType,
	HeaderCell,
	Row,
	CellsRowHeight,
	Sheet,
	Formatting,
	CellRelations
} from './types';
import { StringDictionary } from '../../WritingTemplate/types';
import { transformFinalInputValue } from '../Components/Cell/utils';

export const getCopyCacheKey = (dest: string): string => `${dest}-copy`;
export const spreadsheetUpdateKey = 'last_clipboard_interaction_key';

export const getHeaderRow = (rows: Row[], origin: number): Row => {
	return {
		rowId: 'header',
		locked: true,
		cells: Array.from({ length: Math.max(...rows.map((row) => row.cells.length)) })
			.fill(0)
			.map((_, id) => ({
				type: CellType.Header,
				locked: true,
				value: String.fromCharCode(65 + id + origin)
			}))
	};
};

export const getHeaderColumn = (row: Row, origin: number): (Cell | HeaderCell)[] => {
	return row.locked
		? [{ type: CellType.Header, locked: true, value: '' }, ...row.cells]
		: [{ type: CellType.Header, locked: true, value: row.rowId + origin }, ...row.cells];
};

export const getRangeAddresses = (
	addresses: string
): { result: boolean; message?: string; value?: string[] } => {
	const { cellsRange: cellsRangeRegex } = regex;

	if (!cellsRangeRegex.test(addresses)) {
		return {
			result: false,
			message: 'Wrong coordinates provided'
		};
	}

	const [, startAddress, endAddress] = addresses.match(cellsRangeRegex);
	const { horizontal: startHorizontal, vertical: startVertical } = getCellCoordinates(startAddress);
	const { horizontal: endHorizontal, vertical: endVertical } = getCellCoordinates(endAddress);

	/**
	 * Use +1 to include the range start cell and cover "A1:A1" cases, where bottom-top delta is 0
	 */
	const leftmostCoordinate = Math.min(startHorizontal, endHorizontal);
	const rightmostCoordinate = Math.max(startHorizontal, endHorizontal);
	const horizontalAddresses = Array(rightmostCoordinate - leftmostCoordinate + 1)
		.fill(0)
		.map((_, idx) => String.fromCharCode(idx + leftmostCoordinate));

	const topCoordinate = Math.min(startVertical, endVertical);
	const bottomCoordinate = Math.max(startVertical, endVertical);
	const verticalAddresses = Array(bottomCoordinate - topCoordinate + 1)
		.fill(0)
		.map((_, idx) => idx + topCoordinate);

	const rangeAddresses = horizontalAddresses.flatMap((horizontalAddress) =>
		verticalAddresses.map((verticalAddress) => `${horizontalAddress}${verticalAddress}`)
	);

	return {
		result: true,
		value: rangeAddresses
	};
};

export const convertLetterToDigit = (letter) => ({
	x: alphabet.indexOf(letter.match(/^[A-Z]+/)[0]) + 1,
	y: +letter.match(/[0-9]+$/)[0]
});

export const isCoordinateInRange = (coordinate, range) => {
	if (!range.includes(':')) {
		return coordinate === range;
	} else {
		const [start, end] = range.split(':');
		const convCoord = convertLetterToDigit(coordinate);
		const convStart = convertLetterToDigit(start);
		const convEnd = convertLetterToDigit(end);
		return (
			convCoord.x >= convStart.x &&
			convCoord.y >= convStart.y &&
			convCoord.x <= convEnd.x &&
			convCoord.y <= convEnd.y
		);
	}
};

export const getOverflowForCell = (
	cellAddress: string,
	styling: { [key: string]: string | string[] | any }
): boolean => {
	if (!styling) return false;

	const styles = Object.entries(styling)
		.filter(([range, _]) => isCoordinateInRange(cellAddress, range))
		.map(([_, styles]) => styles);
	return styles.some((style) => style.overflow);
};

export const getStylesForCell = (
	cellAddress: string,
	styling: { [key: string]: string | string[] }
): string => {
	const applyStyleToString = (acc: string, value: string) => `${acc} ${value}`;

	if (styling) {
		const styles = Object.entries(styling)
			.map(([range, styles]) => {
				if (isCoordinateInRange(cellAddress, range)) {
					return styles;
				}
				return null;
			})
			.filter((e) => e);

		if (styles.length === 0 || isEmpty(styles)) return '';

		const styleString = styles
			.map((style) => {
				const stylePart = Object.entries(style)
					.map(([key, value]) => {
						if (Array.isArray(value)) {
							if (key === 'font-style') {
								const temp = value.map((e) => {
									if (e === 'bold') {
										return 'font-weight: 800;' as string;
									} else if (e === 'italic') {
										return 'font-style: italic;' as string;
									} else if (e === 'underline') {
										return 'text-decoration: underline;';
									}

									return '';
								});

								if (temp.length < 2) {
									return temp[0];
								} else {
									return temp.reduce(applyStyleToString);
								}
							}

							return '';
						} else if (key === 'align') {
							if (value === 'right') {
								return `
								div {
									justify-content: flex-end !important;
								}`;
							} else if (value === 'center') {
								return `
								div {
									justify-content: center !important;
								}`;
							} else if (value === 'left') {
								return `
								div {
									justify-content: flex-start !important;
								}`;
							}

							return ``;
						} else if (key === 'width' || key === 'height' || key === 'border') {
							// In case that borders isn't on target we don't want to add it to the style yet
							// This part may be reconsidered in the future
							// And suppport for them will be added then, in other case it'll create visual issues
							// Otherwise they'll be removed in the future
							return ``;
						} else if (key === 'vertical-align') {
							if (value === 'top') {
								return `div {
									align-items: start !important;
								}`;
							} else if (value === 'middle') {
								return `div {
									align-items: center !important;
								}`;
							} else if (value === 'bottom') {
								return `div {
									align-items: end !important;
								}`;
							}
						} else if (key === 'font-size') {
							return `div {
									font-size: ${value}px !important;
								}`;
						}

						return `${key}:${value};`;
					})
					.reduce(applyStyleToString);

				return stylePart;
			})
			.reduce(applyStyleToString);

		return styleString;
	}
};

export const getGlobalWidth = (sheet: Sheet, wrapper: any, isTouchDevice: boolean): string => {
	if (isEmpty(sheet?.styling)) return '';

	const columnsLetters = getColumnLetters(getSheetColsCount(sheet), !!sheet.origin, sheet.origin);
	const stylingColumnsWidths = getWidthFromStyles(sheet, columnsLetters);

	const defaultColumnsWidths = columnsLetters.reduce(
		(letters, column) => ({ ...letters, [column]: 250 }),
		{}
	);
	const columnsWidths = { ...defaultColumnsWidths, ...stylingColumnsWidths };

	if (isEmpty(columnsWidths)) return '';

	/**
	 * Indent that decrease the global width of the SPI when it include more than 3 columns
	 * In case not including it, we'll have fantom scroller.
	 * scroller can't be disabled because we support scrolling inside the SPI, but content basically does not fit sometimes
	 * For mobile devices we always have to have the indent because of the `drag-handle` width
	 */
	const indent = columnsLetters.length <= 2 || isTouchDevice ? 2 : 0;
	const horizontalPadding = 20;

	return Object.entries<string | number>(columnsWidths)
		.map(([columnLetter, columnWidth]) => {
			if (typeof columnWidth !== 'string' || !columnWidth.endsWith('%')) {
				return `.${columnLetter} { width: ${columnWidth}px } td.cellId${columnLetter} { min-width: ${columnWidth}px; div span.placeholder-content { width: ${
					+columnWidth - horizontalPadding
				}px; }  }`;
			}

			let wrapperWidth = wrapper?.offsetWidth;
			if (!wrapperWidth) {
				return `div.cell.${columnLetter} { width: 250px; } td.cellId${columnLetter} { min-width: 250px; div span.placeholder-content { width: 230px; } }`;
			}

			/**
			 * Cells' width should be calculated against the container
			 * with `width = originalWidth - headerColumnWidth`,
			 * when heading columns is present
			 */
			const hideHeaders = sheet?.hideAddressHeaders;
			if (!hideHeaders) {
				wrapperWidth -= headerColumnWidth;
			}

			// 25% -> 0.25
			const columnWidthPercent = parseFloat(columnWidth) / 100;

			const cellWidth = Math.floor(wrapperWidth * columnWidthPercent - indent);
			return `div.cell.${columnLetter} { width: ${cellWidth}px; } td.cellId${columnLetter} { min-width: ${cellWidth}px; div span.placeholder-content { width: ${
				cellWidth - horizontalPadding
			}px; } }`;
		})
		.join('');
};

export const getGlobalHeight = (sheet: Sheet): string => {
	if (!isEmpty(sheet.styling)) {
		const sheetRowNumbers = getRowNumbers(getSheetRowsCount(sheet), !!sheet.origin, sheet.origin);
		const height = getHeightFromStyles(sheet, sheetRowNumbers);
		const fontHeight = getFontSizeAutoHeight(sheet, sheetRowNumbers);

		const merge = { ...fontHeight, ...height } as {
			[key: number]: { height: number; cell?: string };
		};

		if (!isEmpty(merge)) {
			return Object.entries(merge)
				.map(([key, value]) => {
					const { cell, height } = value;

					if (cell && height > 30) {
						return `div.row${key} { height: ${height}px !important; } div.${cell}.row${key} { line-height: 1.3 } `;
					}

					if (height) {
						return `div.row${key} { height: ${height}px !important; }`;
					}

					return '';
				})
				.join('');
		}
	}

	return '';
};

export const getSheetColsCount = (sheet: Sheet): number => {
	const { cells } = sheet;
	return cells.map((cell) => cell.length).sort()[0];
};

const getSheetRowsCount = (sheet: Sheet): number => {
	const { cells } = sheet;
	return cells.length;
};

export const getColumnLetters = (sheetCols: number, isOrigin: boolean, origin: string): string[] =>
	Array.from({ length: sheetCols })
		.fill(0)
		.map((_, idx) =>
			String.fromCharCode(getStartCell([isOrigin ? origin : 'A1']).horizontal + idx)
		);

const getRowNumbers = (sheetRows: number, isOrigin: boolean, origin: string): string[] => {
	return Array.from({ length: sheetRows })
		.fill(0)
		.map((_, idx) => `${getStartCell([isOrigin ? origin : 'A1']).vertical + idx}`);
};

const getFontSizeAutoHeight = (
	sheet: Sheet,
	rowNumbers: string[]
): { [x: string]: { height: number; cell: string } } => {
	if (sheet.styling) {
		return Object.entries(sheet.styling)
			.map(([key, values]) => {
				const isHeightSetup = () => {
					return (
						Object.entries(values)
							.map(([styleKey]) => styleKey === 'font-size')
							.filter((e) => e).length > 0
					);
				};

				if (key.includes(':')) {
					const { result, value } = getRangeAddresses(key);
					if (!result || !isHeightSetup()) {
						return null;
					}

					return value
						.map((k) =>
							rowNumbers.some((c) => k.endsWith(c))
								? {
										[getStartCell([k]).vertical]: {
											height: (values as any)['font-size'] + 14,
											cell: String.fromCharCode(getStartCell([k]).horizontal)
										}
								  }
								: null
						)
						.filter((e) => e)
						.reduce((acc, e) => ({ ...acc, ...e }), {});
				} else {
					if (rowNumbers.some((c) => key.endsWith(c)) && isHeightSetup()) {
						return {
							[getStartCell([key]).vertical]: {
								height: (values as any)['font-size'] + 14,
								cell: String.fromCharCode(getStartCell([key]).horizontal)
							}
						};
					}

					return null;
				}
			})
			.filter((e) => e)
			.reduce((acc, e) => ({ ...acc, ...e }), {});
	}
};

const getHeightFromStyles = (
	sheet: Sheet,
	rowNumbers: string[]
): { [x: string]: { height: number } } => {
	if (sheet.styling) {
		return Object.entries(sheet.styling)
			.map(([key, values]) => {
				const isHeightSetup = () => {
					return (
						Object.entries(values)
							.map(([styleKey]) => styleKey === 'height')
							.filter((e) => e).length > 0
					);
				};

				if (key.includes(':')) {
					const { result, value } = getRangeAddresses(key);
					if (!result || !isHeightSetup()) {
						return null;
					}

					return value
						.map((k) =>
							rowNumbers.some((c) => k.endsWith(c))
								? {
										[getStartCell([k]).vertical]: {
											height: (values as any).height
										}
								  }
								: null
						)
						.filter((e) => e)
						.reduce((acc, e) => ({ ...acc, ...e }), {});
				} else {
					if (rowNumbers.some((c) => key.endsWith(c)) && isHeightSetup()) {
						return {
							[getStartCell([key]).vertical]: {
								height: (values as any).height
							}
						};
					}

					return null;
				}
			})
			.filter((e) => e)
			.reduce((acc, e) => ({ ...acc, ...e }), {});
	}

	return {};
};

export const getWidthFromStyles = (
	sheet: Sheet,
	columnLetters: string[]
): { [columnLetter: string]: any } => {
	if (sheet.styling) {
		return Object.entries(sheet.styling)
			.map(([key, values]) => {
				const isWidthSetup = () => {
					return (
						Object.entries(values)
							.map(([styleKey]) => styleKey === 'width')
							.filter((e) => e).length > 0
					);
				};

				if (key.includes(':')) {
					const { result, value } = getRangeAddresses(key);
					if (!result || !isWidthSetup()) {
						return null;
					}

					return value
						.map((k) =>
							columnLetters.some((c) => k.startsWith(c))
								? { [String.fromCharCode(getStartCell([k]).horizontal)]: (values as any).width }
								: null
						)
						.filter((e) => e)
						.reduce((acc, e) => ({ ...acc, ...e }), {});
				} else {
					if (columnLetters.some((c) => key.startsWith(c)) && isWidthSetup()) {
						return {
							[String.fromCharCode(getStartCell([key]).horizontal)]: (values as any).width
						};
					}

					return null;
				}
			})
			.filter((e) => e)
			.reduce((acc, e) => ({ ...acc, ...e }), {});
	}

	return {};
};

export const getFormattingForCell = (
	cellAddress: string,
	formatting: { [key: string]: CellFormatting } = {}
): CellFormatting =>
	Object.entries(formatting).reduce((acc, format) => {
		const [range, formattingData] = format;
		if (isCoordinateInRange(cellAddress, range)) {
			return { ...acc, ...formattingData };
		}
		return acc;
	}, {});

export const formatValue = (value: number | string, formatting: CellFormatting): string => {
	const { type, accuracy: schemaAccuracy, separate } = formatting;

	const defaultAccuracy = 2;
	const accuracy = schemaAccuracy ?? defaultAccuracy;

	const normalizedValue = normalizeCellValue(value, formatting);

	/**
	 * Prevents showing the -0 value in the cell. Required for .toLocaleString
	 * More details - https://github.com/soomo/soomo-libs/pull/1059#issue-1403050104
	 */
	const formattingValue = normalizedValue === 0 ? Math.abs(+value) : +value;

	const formattingOptions = getNumberFormatOptions();
	const formattedValue = Number(formattingValue).toLocaleString('en-US', formattingOptions);

	return processValueByType(formattedValue);

	function getNumberFormatOptions(): Intl.NumberFormatOptions {
		const options: Intl.NumberFormatOptions = {
			maximumFractionDigits: accuracy,
			useGrouping: [FormatType.Accounting, FormatType.Currency].includes(type) || separate
		};

		switch (type) {
			case FormatType.Percent:
				options.style = 'percent';
				break;
			case FormatType.Accounting:
				options.minimumFractionDigits = accuracy;
				break;
			case FormatType.Currency:
				options.style = 'currency';
				options.currency = 'USD';
				options.minimumFractionDigits = accuracy;
				break;
			default:
				options.style = 'decimal';
		}

		return options;
	}

	function processValueByType(formattedValue: string): string {
		switch (type) {
			case FormatType.Accounting:
				return processAccountingType();
			default:
				return formattedValue;
		}

		/**
		 * In accounting type negative values are expressed with parentheses
		 * -1337 => (1337)
		 */
		function processAccountingType(): string {
			if (!formattedValue.startsWith('-')) return formattedValue;

			const positiveValue = formattedValue.slice(1);
			return `(${positiveValue})`;
		}
	}
};

export const copyToClipboard = (value: string): void => {
	navigator.clipboard.writeText(value);
};

export const getCache = (
	selectedIndexes: string[],
	selectedCell: string,
	cellValues: StringDictionary
): { [key: string]: string } => {
	if (selectedIndexes?.length > 1) {
		return selectedIndexes
			.map((index) => ({ [index]: cellValues[index] }))
			.reduce((acc, e) => ({ ...acc, ...e }), {});
	}

	return { [selectedCell]: cellValues[selectedCell] };
};

export const createCopyString = (
	selectedCell: string,
	selectedCells: string[],
	elements: { [key: string]: string }
): string => {
	if (selectedCells.length > 1) {
		const cellsRows = selectedCells
			.map((e) => {
				const re = regex.cellHandle;
				re.lastIndex = 0;

				return re.exec(e);
			})
			.reduce((acc, el) => {
				if (!el) return acc;

				const [address, , ver] = el;

				if (acc[ver]) {
					return { ...acc, [ver]: [...acc[ver], address] };
				}

				return { ...acc, [ver]: [address] };
			}, {});

		const result = Object.values(cellsRows)
			.map((row: string[]) => {
				const values = row.map((e) => elements[e]);

				return values.join('\t');
			})
			.join('\r\n');

		return result;
	}

	return elements[selectedCell];
};

export const getCopiedText = async (dest: string): Promise<string> => {
	const key = localStorageGet(spreadsheetUpdateKey) || getCopyCacheKey(dest);
	let text = '';

	if (navigator.clipboard?.readText) {
		text = await navigator.clipboard.readText();
	} else if (localStorageGet(key)) {
		const valuesObj = localStorageGet(key);
		const coordsArray = Object.keys(valuesObj);

		if (coordsArray && coordsArray.length > 0) {
			const copyString = createCopyString(first(coordsArray), coordsArray, valuesObj);
			text = copyString;
		}
	} else {
		console.warn('Clipboard and local storage are unavailable, please enable any and try again');
	}

	return text;
};

/**
 * This function recreate object with position for copied range;
 * When user copy something from Spreadsheet or Excel or GSheets
 * In buffer appears data with format value\t value\n(row)
 * We unwrap this value and assign to selected cells in ST
 * At this moment we're doing formula "shifts", for example:
 * if copied from A7 value =A6 and paste to B8 the value become =B7
 * because we shift 1 cell down and 1 cell to right
 */
export const assignTextWithCells = (props: {
	text: string;
	selectedCell: string;
	copyCache: CellValues;
	formatting: Formatting;
}): {
	pasteValues: CellValues;
	pasteValuesWithNoTransform: CellValues;
	relationMap: CellRelations;
} => {
	const { text, selectedCell, copyCache, formatting } = props;

	const re = regex.cellHandle;
	re.lastIndex = 0;

	const [, columnAddress, rowAddress] = re.exec(selectedCell);
	const columnCharCode = columnAddress.charCodeAt(0);

	const pasteValuesWithNoTransform: CellValues = {};
	const relationMap = {};

	const pasteValues = Object.fromEntries(
		text.split('\r\n').flatMap((rowValues, index) =>
			rowValues.split('\t').map((cellValue, idx) => {
				const [previousKey] = getElementByValue(copyCache, cellValue);

				const key = `${String.fromCharCode(columnCharCode + idx)}${index + +rowAddress}`;
				const text = transformFinalInputValue(
					transformFormula(previousKey, key, cellValue),
					getFormattingForCell(key, formatting)
				);

				/**
				 * Mutate object with no transform values
				 * We could repeat the process of transformation, but it's not necessary, since we have the original values here
				 */
				pasteValuesWithNoTransform[key] = cellValue;
				relationMap[key] = previousKey;

				return [key, text];
			})
		)
	);

	return { pasteValues, pasteValuesWithNoTransform, relationMap };
};

/**
 * This function is creating range with values based on cell availability;
 * Basically create new values for all selection address that are editable
 */
export const populateSelectedRangeWithValues = (props: {
	selectedIndexes: string[];
	editableRanges: EditableRanges;
	value: string;
	formulaOrigin: string;
	formatting: Formatting;
}): CellValues => {
	const { selectedIndexes, editableRanges, value, formulaOrigin, formatting } = props;

	const availableCells = selectedIndexes.filter((selection) => editableRanges[selection]);

	/**
	 * We will transform value based on the position in further updates, this update does not include transformations
	 */
	return Object.fromEntries(
		availableCells.map((cellAddress) => [
			cellAddress,
			transformFinalInputValue(
				transformFormula(formulaOrigin, cellAddress, value),
				getFormattingForCell(cellAddress, formatting)
			)
		])
	);
};

export const getExistingEditableCells = (
	elements: { [key: string]: React.MutableRefObject<HTMLTableCellElement> },
	pasteCells: { [key: string]: string },
	editableRanges: EditableRanges
): CellValues =>
	Object.entries(pasteCells)
		.filter(([key]) => elements[key] && editableRanges[key])
		.reduce((acc, [address, value]) => ({ ...acc, [address]: value }), {} as CellValues);

const getElementByValue = (obj: StringDictionary | undefined, text: string) => {
	const res = Object.entries(obj || {}).find(([, value]) => value === text);
	return res ? [res.shift()] : [null];
};

const transformFormula = (exKey: string, key: string, value: string) => {
	if (value?.startsWith('=') && exKey) {
		const { horizontal, vertical } = getShift(exKey, key);
		return value.replace(/([A-Za-z]+)([0-9]+)/gi, (text: string, hor: string, ver: string) => {
			const result = `${String.fromCharCode(
				Math.max(hor.charCodeAt(0) + horizontal, 65)
			)}${Math.max(+ver + vertical, 1)}`;
			return result;
		});
	}

	return value;
};

const getShift = (oldCell: string, newCell: string) => {
	const [, oldHorizontal, oldVertical] = /([A-Za-z]+)([0-9]+)/gi.exec(oldCell);
	const [, newHorizontal, newVertical] = /([A-Za-z]+)([0-9]+)/gi.exec(newCell);

	return {
		horizontal: newHorizontal.charCodeAt(0) - oldHorizontal.charCodeAt(0),
		vertical: +newVertical - +oldVertical
	};
};

export const onHideContext = (e: React.MouseEvent<HTMLTableElement, MouseEvent>): boolean => {
	if ((e?.target as Element).tagName !== 'TEXTAREA') {
		e.preventDefault();
		return false;
	}
};

export const createCoordinatesFromArray = (arr: string[]): string => {
	const coordsRange = normalizeRange(arr);
	const min = getStartCell(coordsRange);
	const max = getEndCell(coordsRange);

	if (min && max) {
		const minString = `${String.fromCharCode(min.horizontal)}${min.vertical}`;
		const maxString = `${String.fromCharCode(max.horizontal)}${max.vertical}`;

		return `${minString}:${maxString}`;
	}

	return '';
};

export const getHighlighters = (val: string): string[] => {
	const highlighters = findMatches(
		/([A-Za-z]{1,2}[0-9]{1,3}:[A-Za-z]{1}[0-9]{1,2})|([A-Za-z]{1,2}[0-9]{1,3})/gi,
		val?.replace(/[$]/gi, '')
	).map(([e]) => e);

	return highlighters;
};

export const getHightLightRanges = (
	highlight: string[],
	elements: {
		[key: string]: React.MutableRefObject<HTMLTableCellElement>;
	},
	withHeader = true
): {
	isDisplay: boolean;
	position: {
		top: number;
		left: number;
		width: number;
		height: number;
	};
}[] => {
	const highlighters = highlight
		.map((hl) => hl.toUpperCase())
		.map((hl) => {
			if (hl.includes(':')) {
				const { result, value } = getRangeAddresses(hl);

				if (result && value.every((e) => elements[e])) {
					return value;
				}

				return null;
			} else {
				return elements[hl] ? [hl] : null;
			}
		})
		.filter((e) => e)
		.map((e) => {
			const startCell = getStartCell(e);
			const endCell = getEndCell(e);

			const startPosition = getStartPosition(
				`${String.fromCharCode(startCell.horizontal)}${startCell.vertical}`,
				elements,
				withHeader
			);

			const width = getWidthValues(
				endCell.horizontal - startCell.horizontal + 1,
				elements,
				startCell.horizontal,
				startCell.vertical - 1
			);

			const height = getHeightValues(
				endCell.vertical - startCell.vertical + 1,
				elements,
				startCell.horizontal,
				startCell.vertical
			);

			return {
				isDisplay: true,
				position: {
					top: startPosition.top,
					left: startPosition.left,
					width,
					height
				}
			};
		});

	return highlighters;
};

export const getIsFormula = (value?: string): boolean =>
	typeof value === 'string' && value.startsWith('=') && value.length > 1;

/**
 * Checks if the passed range definition has the properly defined start and the end
 * @param value - range definition
 * @returns {Boolean} Returns if the range definition is valid
 * @example
 * // returns false
 * getIsRange('A2:')
 * @example
 * // returns true
 * getIsRange('A2:B2')
 */
export const getIsRange = (value?: string): boolean => {
	if (typeof value !== 'string') return false;

	const rangeParts = value.split(':');
	if (rangeParts.length !== 2) return false;

	const trimmedRangeParts = rangeParts.map((part) => part.trim());
	return trimmedRangeParts.every(Boolean);
};

export const formatCurrencyLikeValue = (
	cellValue: string,
	cellFormatting: CellFormatting
): string => {
	const isCurrencyLikeFormatting = [FormatType.Accounting, FormatType.Currency].includes(
		cellFormatting?.type
	);

	if (cellFormatting?.type === FormatType.Percent) return formatValue(cellValue, cellFormatting);
	if (!isCurrencyLikeFormatting) return cellValue;

	// Replace `,` bc SR reads not 25000 but 25.000
	return formatValue(+cellValue, { ...cellFormatting, type: FormatType.Currency })?.replace(
		/,/g,
		''
	);
};

/**
 * Returns the rounded decimal number based on the raw cell input.
 * Handles a special case when the accuracy can be 0 for the percent cells, and the raw value <0
 * @param value - raw input value of the cell
 * @param formatting - cell's formatting rules
 */
export const normalizeCellValue = (value: number | string, formatting: CellFormatting): number => {
	const { type, accuracy } = formatting;

	let normalized = +value;
	if (type === FormatType.Percent) {
		normalized *= 100;
	}
	return round(normalized, accuracy);
};

export const findMatches = (regex: RegExp, str: string, matches = []): RegExpExecArray[] => {
	const res = regex.exec(str);

	/**
	 * Basically we're asking if there are some results and if yes it shouldn't be an empty string otherwise it creates an infinite loop here.
	 */
	if (res && res[0] !== '') {
		matches.push(res);
		return findMatches(regex, str, matches);
	}
	return matches;
};

export const getStaticErrorValuesList = (): string[] =>
	Object.values(staticErrorReturns)
		.map(({ result }) => result)
		.filter(Boolean);

export const createHeightStyle = (rowHeights: CellsRowHeight): string => {
	const heightObj = Object.values(rowHeights).reduce<Record<number, number>>(
		(acc, { rowName: row, height }) => (height < acc[row] ? acc : { ...acc, [row]: height }),
		{}
	);

	return Object.entries(heightObj)
		.map(([rowId, height]) => `div.cell.row${rowId} { min-height: ${height}px !important; }`)
		.join(' ');
};

export const getSelectionFromString = (value: string): string[] => {
	if (value.includes(':')) {
		const range = getRangeAddresses(value);
		return range?.result ? range.value : [];
	}

	return [value];
};
