import * as React from 'react';

export function BulbIcon(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 3136 4343">
			<g fillRule="nonzero" fill="currentColor">
				<path d="M1308 4322c-23-11-64-44-91-73-34-36-69-60-107-76-69-28-163-95-199-142-59-78-67-114-80-352l-13-221-72-70c-134-129-192-242-216-422-17-130-31-159-187-396C81 2173-1 1923 0 1523c1-211 17-319 72-480C237 556 664 171 1180 44c132-32 232-44 385-44 158 0 254 12 400 48 386 97 726 343 943 682 224 349 286 793 172 1233-53 203-128 358-303 623-144 219-160 254-178 386-23 177-79 286-215 416l-72 70-13 220c-7 121-18 236-25 255-33 93-132 188-248 237-43 18-79 43-105 72-23 24-61 57-85 73l-45 28h-220c-203 0-224-2-263-21Zm790-1242c61-59 73-87 86-208 17-154 56-241 212-475 217-326 285-494 310-764 46-500-230-940-707-1129-156-61-241-76-439-76-144 1-185 4-264 24-484 120-814 481-871 951-20 166 8 393 70 561 40 111 97 215 226 414 181 278 207 336 225 494 13 121 25 149 86 208l54 53h958l54-53Z" />
				<path d="M1477 2930c-52-16-126-89-152-149-61-143 29-314 185-349 200-45 375 162 295 349-27 62-105 137-158 152-46 12-127 11-170-3ZM1517 2253c-44-10-80-46-91-89-6-20-33-209-60-421-49-370-51-394-51-620 0-134 5-251 11-273 14-53 71-112 127-134 28-11 73-18 112-18s84 7 112 18c56 22 113 81 127 134 6 22 11 139 11 278 0 234-2 250-54 643-56 414-61 436-109 466-29 19-94 26-135 16Z" />
			</g>
		</svg>
	);
}
