import * as React from 'react';

export function HeadWithHeartBrain(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={33.6}
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36 71a35 35 0 1 1 35-35 35 35 0 0 1-35 35Zm0-67.2a32.1 32.1 0 1 0 32.1 32.1A32.14 32.14 0 0 0 36 3.8Z"
				fill="currentColor"
			/>
			<circle cx={36} cy={36} r={29.7} fill="currentColor" />
			<path
				d="m34.9 35.2.6.3.6-.3c5.3-3.2 5.2-6.3 4.8-7.4a3.3 3.3 0 0 0-3.2-2.5 4.1 4.1 0 0 0-2.2.7 3.41 3.41 0 0 0-2.2-.7 3.3 3.3 0 0 0-3.2 2.5c-.4 1.2-.5 4.2 4.8 7.4Zm-2.7-6.8a1 1 0 0 1 1-.8 1.71 1.71 0 0 1 1.3.7l1 1.6 1-1.6a1.71 1.71 0 0 1 1.3-.7 1.06 1.06 0 0 1 1 .9c.3 1-.5 2.7-3.3 4.6-2.8-2-3.6-3.7-3.3-4.7Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M36.6 53.5v-6.6l8.5 2.1a50.73 50.73 0 0 0 3.4-8.2l4.2-1.4-2.7-6.8a10.87 10.87 0 0 0 .1-1.8c.2-8.3-6-15.7-14.6-15.7a14.55 14.55 0 0 0-14.6 14.6 14.44 14.44 0 0 0 3.1 9 5.88 5.88 0 0 1 1.4 3.7v11h11.2ZM23.1 29.8a12.44 12.44 0 0 1 12.3-12.4c7.2 0 12.5 6.3 12.3 13.4 0 .6-.1 1.2-.1 2.2l2 5.1-3.1 1a33.63 33.63 0 0 1-2.8 7.3L34.2 44v7.2h-6.7v-8.8a7.46 7.46 0 0 0-1.9-5 12.6 12.6 0 0 1-2.5-7.6Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M35.5 39.9a10.1 10.1 0 1 0-10.1-10.1 10.07 10.07 0 0 0 10.1 10.1Zm0-18a7.9 7.9 0 1 1-7.9 7.9 7.92 7.92 0 0 1 7.9-7.9Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
