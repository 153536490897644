import { useContext, useEffect } from 'react';

import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $getRoot, $insertNodes, $setSelection } from 'lexical';

import CitationMetaContext from '../context/CitationMetaContext';
import {
	addCitationSpacerForIOS,
	extendCitationElementsWithAttributes
} from '../helpers/citations.helper';
import { verifyCitationPayload } from '../helpers/formatting.helper';
import { CitationStyle } from './../../../types';

export const useValueInit = (
	value: string,
	withCitations: boolean,
	citationStyle: CitationStyle
): void => {
	const [editor] = useLexicalComposerContext();
	const { inEditorCitations } = useContext(CitationMetaContext);

	/**
	 * This is quite simple hook to initialize editor state with value
	 */
	useEffect(() => {
		editor.update(() => {
			const paragraph = $createParagraphNode();
			const root = $getRoot();
			root.clear();
			root.append(paragraph);
			/**
			 * https://lexical.dev/docs/concepts/serialization#html---lexical
			 */
			const input = withCitations ? verifyCitationPayload(value, citationStyle) : value;
			const parser = new DOMParser();
			const dom = parser.parseFromString(input, 'text/html');
			addCitationSpacerForIOS(dom);
			extendCitationElementsWithAttributes(dom, inEditorCitations);

			const nodes = $generateNodesFromDOM(editor, dom);

			root.select();
			// To avoid some predefined content
			root.clear();
			$insertNodes(nodes);
			$setSelection(null);
		});
		/**
		 * inEditorCitations here is not a dependency, because this hook is called only once or when value, withCitations, citationStyle, editor change.
		 * However inEditorCitations changes over the time, so we need to disable eslint here and remove from deps.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, withCitations, citationStyle, editor]);
};
