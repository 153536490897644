import { ToolConfiguration } from '@soomo/lib/types/index';
import { mergeData, requestAsync } from './';

interface Props {
	courseId: number;
	studentId: number | string | null;
	instructorId: number;
	familyId: string;
	force?: boolean;
}

export default (props: Props) => {
	const { courseId, studentId, instructorId, familyId, force } = props;

	const params: Record<string, number | string> = {
		course_id: courseId,
		user_id: instructorId // Obtains the tool view for the instructor,
	};
	if (studentId) {
		params.filter_user_id = studentId; // Show only student's recordings if provided
	}

	return requestAsync({
		path: `/api/courses/v1/webtext/objects/${familyId}/lti_basic_tool`,
		params,
		transform: (data: { toolConfig: ToolConfiguration }) => {
			const toolConfigId = studentId ? `${studentId}:${familyId}` : familyId;

			const entities = { externalToolsConfigs: {} };
			entities.externalToolsConfigs[toolConfigId] = data.toolConfig;
			return entities;
		},
		update: { externalToolsConfigs: mergeData },
		force
	});
};
