export const getColorNameByHex = (code: string) => {
	const colorToNameConvertor = new HexCodeToColorName();
	const [_, name] = colorToNameConvertor.name(code);

	return name;
};

/*

+-----------------------------------------------------------------+
|     Inspired by Chirag Mehta - http://chir.ag/projects/ntc       |
|-----------------------------------------------------------------|
|               ntc js (Name that Color JavaScript)               |
+-----------------------------------------------------------------+
*/

class HexCodeToColorName {
	constructor() {
		for (let i = 0; i < this.names.length; i++) {
			const color = `#${this.names[i][0]}`;
			const [r, g, b] = this.rgb(color);
			const [h, s, l] = this.hsl(color);
			this.names[i].push(r, g, b, h, s, l);
		}
	}

	private names: any[][] = [
		['FF0000', 'Red'],
		['B10000', 'Bright Red'],
		['C62D42', 'Brick Red'],
		['480404', 'Rustic Red'],
		['FF3F34', 'Red Orange'],
		['FF681F', 'Orange'],
		['FE4C40', 'Sunset Orange'],
		['CC5500', 'Burnt Orange'],
		['FFAE42', 'Yellow Orange'],
		['FFFF00', 'Yellow'],
		['C5E17A', 'Yellow Green'],
		['ADFF2F', 'Green Yellow'],
		['00FF00', 'Green'],
		['98FF98', 'Mint Green'],
		['66FF00', 'Bright Green'],
		['228B22', 'Forest Green'],
		['77DD77', 'Pastel Green'],
		['0000FF', 'Blue'],
		['E0FFFF', 'Baby Blue'],
		['2A52BE', 'Cerulean Blue'],
		['0000C8', 'Dark Blue'],
		['003366', 'Midnight Blue'],
		['000080', 'Navy Blue'],
		['384555', 'Oxford Blue'],
		['4169E1', 'Royal Blue'],
		['76D7EA', 'Sky Blue'],
		['C3DDF9', 'Tropical Blue'],
		['30D5C8', 'Turquoise'],
		['6CDAE7', 'Turquoise Blue'],
		['08E8DE', 'Bright Turquoise'],
		['008080', 'Teal'],
		['044259', 'Teal Blue'],
		['003532', 'Deep Teal'],
		['4F69C6', 'Indigo'],
		['240A40', 'Violet'],
		['803790', 'Vivid Violet'],
		['6456B7', 'Blue Violet'],
		['C71585', 'Red Violet'],
		['F7468A', 'Violet Red'],
		['660099', 'Purple'],
		['FFFFFF', 'White'],
		['808080', 'Gray'],
		['3C4151', 'Bright Gray'],
		['262335', 'Steel Gray'],
		['C3CDE6', 'Periwinkle Gray'],
		['CCCCFF', 'Periwinkle'],
		['964B00', 'Brown'],
		['301F1E', 'Cocoa Brown'],
		['370202', 'Chocolate'],
		['FFDDAF', 'Caramel'],
		['706555', 'Coffee'],
		['612718', 'Espresso'],
		['7B3F00', 'Cinnamon'],
		['782D19', 'Mocha'],
		['FFFDD0', 'Cream'],
		['F5F5DC', 'Beige'],
		['D2B48C', 'Tan'],
		['661010', 'Dark Tan'],
		['000000', 'Black'],
		['00FFFF', 'Cyan / Aqua'],
		['FF00FF', 'Magenta / Fuchsia'],
		['C154C1', 'Fuchsia Pink'],
		['7A58C1', 'Fuchsia Blue'],
		['FFC0CB', 'Pink'],
		['FF69B4', 'Hot Pink'],
		['FF6FFF', 'Blush Pink'],
		['FF7F50', 'Coral'],
		['FF007F', 'Rose'],
		['FFF6F5', 'Rose White'],
		['B57EDC', 'Lavender'],
		['7FFFD4', 'Aquamarine'],
		['FAECCC', 'Champagne'],
		['7FFF00', 'Chartreuse'],
		['DC143C', 'Crimson'],
		['4E0606', 'Mahogany'],
		['800000', 'Maroon'],
		['FF2400', 'Scarlet'],
		['EB9373', 'Apricot'],
		['E96E00', 'Clementine'],
		['DB5079', 'Cranberry'],
		['F28500', 'Tangerine'],
		['FDE910', 'Lemon'],
		['BFFF00', 'Lime'],
		['808000', 'Olive'],
		['B5B35C', 'Olive Green'],
		['FFE5B4', 'Peach'],
		['843179', 'Plum'],
		['FF8C69', 'Salmon'],
		['FFDB58', 'Mustard'],
		['FFBF00', 'Amber'],
		['0047AB', 'Cobalt'],
		['B87333', 'Copper'],
		['FFD700', 'Gold'],
		['C0C0C0', 'Silver'],
		['3F2109', 'Bronze'],
		['D4D7D9', 'Iron'],
		['FFFFF0', 'Ivory'],
		['00A86B', 'Jade'],
		['50C878', 'Emerald'],
		['2F519E', 'Sapphire']
	];

	name(color: string) {
		if (color.length < 3 || color.length > 7) {
			return ['#000000', `Invalid Color: ${color}`, false];
		}

		color = color.toUpperCase();
		if (color.length % 3 == 0) {
			color = `#${color}`;
		}
		if (color.length == 4) {
			color = `#${color.slice(1, 2)}${color.slice(1, 2)}${color.slice(2, 3)}${color.slice(
				2,
				3
			)}${color.slice(3, 4)}${color.slice(3, 4)}`;
		}

		const [r, g, b] = this.rgb(color);
		const [h, s, l] = this.hsl(color);
		let ndf1 = 0;
		let ndf2 = 0;
		let ndf = 0;
		let cl = -1;
		let df = -1;

		for (let i = 0; i < this.names.length; i++) {
			if (color == `#${this.names[i][0]}`) {
				return [`#${this.names[i][0]}`, this.names[i][1], true];
			}

			ndf1 =
				Math.pow(r - this.names[i][2], 2) +
				Math.pow(g - this.names[i][3], 2) +
				Math.pow(b - this.names[i][4], 2);
			ndf2 =
				Math.pow(h - this.names[i][5], 2) +
				Math.pow(s - this.names[i][6], 2) +
				Math.pow(l - this.names[i][7], 2);
			ndf = ndf1 + ndf2 * 2;
			if (df < 0 || df > ndf) {
				df = ndf;
				cl = i;
			}
		}

		return cl < 0
			? ['#000000', `Invalid Color: ${color}`, false]
			: [`#${this.names[cl][0]}`, this.names[cl][1], false];
	}

	private hsl(color: string) {
		const [r, g, b] = [
			parseInt(`0x${color.substring(1, 3)}`) / 255,
			parseInt(`0x${color.substring(3, 5)}`) / 255,
			parseInt(`0x${color.substring(5, 7)}`) / 255
		];
		const min = Math.min(r, Math.min(g, b));
		const max = Math.max(r, Math.max(g, b));
		const delta = max - min;
		const l = (min + max) / 2;

		let s = 0;
		if (l > 0 && l < 1) {
			s = delta / (l < 0.5 ? 2 * l : 2 - 2 * l);
		}

		let h = 0;
		if (delta > 0) {
			if (max == r && max != g) h += (g - b) / delta;
			if (max == g && max != b) h += 2 + (b - r) / delta;
			if (max == b && max != r) h += 4 + (r - g) / delta;
			h /= 6;
		}

		return [h * 255, s * 255, l * 255];
	}

	private rgb = (color: string) => [
		parseInt(`0x${color.substring(1, 3)}`),
		parseInt(`0x${color.substring(3, 5)}`),
		parseInt(`0x${color.substring(5, 7)}`)
	];
}
