import { css } from '@emotion/react';

const tooltipBackground = '#191919';
const citationLabelColor = '#d8d8d8';

const popoverActionButton = css`
	padding: 6px;
	margin: 0;
	color: #fefefe;
	font-size: 1em;
	border: none;
	border-radius: 5px;
	background: none;
	pointer-events: auto;
	cursor: pointer;
`;

export default (theme, { platform }) => {
	const { colors } = theme;

	return css`
		.reference-content {
			font-family: Georgia;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0;
			line-height: 18px;
			padding: 14px 14px;
			word-break: break-word;
			white-space: normal;
		}

		.reference-copy-container {
			text-align: right;
			background: #2d2d2d;
			padding: 0.4em 1.5em;

			${platform === 'web' &&
			css`
				margin: 5px 9px;
			`}

			button {
				${popoverActionButton};
			}
		}

		font-style: normal;
		font-weight: normal;
		text-transform: none;

		font-size: 15px;
		text-shadow: none;
		padding: 0;
		border: 1px solid #666;
		background: ${tooltipBackground};
		background-image: none;

		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;

		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		button {
			border: 1px solid ${colors['light-gray']};
		}

		.reference-copy-link {
			border: 0;
			padding: 0;
			margin: 0;
			cursor: pointer;
			color: ${colors.white};
			vertical-align: top;
		}

		p + p {
			margin-top: 10px;
		}

		.tippy-content {
			padding: 0;

			em {
				font-style: italic;
			}
		}

		.citation-label {
			font-family: Helvetica;
			font-size: 12px;
			text-align: left;
			color: ${citationLabelColor};
			letter-spacing: 2.28px;
			padding: 14px 14px 0 14px;
		}

		.tippy-content {
			.help-text,
			.citation-help {
				font-size: 14px;
				padding: 0 10px;
				text-align: left;
				button {
					background: ${colors.primary};
					color: ${colors.white};
					border-radius: 2px;
				}
				p {
					margin: 1em;
				}
			}
		}
	`;
};

export const TippyBaseTheme = (platform?: string) => css`
	[data-tippy-root] {
		// Required to make popover accessible but not interfere with other inline content
		display: inline-block;
	}

	.tippy-box[data-animation='fade'][data-state='hidden'] {
		opacity: 0;
	}
	[data-tippy-root] {
		max-width: calc(100vw - 10px);
	}
	.tippy-box {
		position: relative;
		background-color: #333;
		color: #fff;
		border-radius: 4px;
		font-size: 14px;
		line-height: 1.4;
		outline: 0;
		transition-property: transform, visibility, opacity;
	}
	.tippy-box[data-placement^='top'] > .tippy-arrow {
		bottom: 0;
	}
	.tippy-box[data-placement^='top'] > .tippy-arrow:before {
		bottom: -7px;
		left: 0;
		border-width: 8px 8px 0;
		border-top-color: initial;
		transform-origin: center top;
	}
	.tippy-box[data-placement^='bottom'] > .tippy-arrow {
		top: 0;
	}
	.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
		top: -7px;
		left: 0;
		border-width: 0 8px 8px;
		border-bottom-color: initial;
		transform-origin: center bottom;
	}
	.tippy-box[data-placement^='left'] > .tippy-arrow {
		right: 0;
	}
	.tippy-box[data-placement^='left'] > .tippy-arrow:before {
		border-width: 8px 0 8px 8px;
		border-left-color: initial;
		right: -7px;
		transform-origin: center left;
	}
	.tippy-box[data-placement^='right'] > .tippy-arrow {
		left: 0;
	}
	.tippy-box[data-placement^='right'] > .tippy-arrow:before {
		left: -7px;
		border-width: 8px 8px 8px 0;
		border-right-color: initial;
		transform-origin: center right;
	}
	.tippy-box[data-inertia][data-state='visible'] {
		transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
	}
	.tippy-arrow {
		width: 16px;
		height: 16px;
		color: #333;
	}
	.tippy-arrow:before {
		content: '';
		position: absolute;
		border-color: transparent;
		border-style: solid;
	}
	.tippy-content {
		position: relative;
		padding: 5px 9px;
		z-index: 1;
	}

	${platform === 'web' &&
	css`
		.tippy-box {
			border-radius: 0;
			border: 1px solid #666;
			background: #191919;
		}
	`}
`;
