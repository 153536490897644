import {
	NumberedItemStyle,
	OutlineChildrenResponseInstance,
	OutlineChildrenResponseSection,
	OutlineConfig,
	OutlineSectionInstancesNumber,
	OutlineTemplate,
	OutlineToggleResponseInstance,
	OutlineToggleResponseSection,
	TemplateLabelType,
	TemplateType
} from '~/components/Outline/types';

/**
 * Set of default properties that should be used if not specified by the editor
 */
export const defaultOutlineConfig: Partial<OutlineConfig> = {
	numberedItemsStyle: [
		NumberedItemStyle.UpperRoman,
		NumberedItemStyle.UpperAlpha,
		NumberedItemStyle.Decimal,
		NumberedItemStyle.LowerAlpha,
		NumberedItemStyle.LowerRoman
	],
	templatesConstraints: {}
};

export const defaultTemplate: Partial<OutlineTemplate> = {
	label_type: TemplateLabelType.Block,
	type: TemplateType.Text,
	numbered: true,
	text_field: {
		min_rows: 1
	},
	output: {
		disable_label: false
	}
};

export const defaultResponseSection: Required<
	OutlineChildrenResponseSection | OutlineToggleResponseSection
> = {
	instances: []
};

export const defaultChildrenResponseInstance: OutlineChildrenResponseInstance = {
	toggle: [],
	children: []
};

export const defaultToggleResponseInstance: OutlineToggleResponseInstance = {};

export const defaultChildrenInstanceNumber: Required<OutlineSectionInstancesNumber> = {
	initial: 1,
	min: 1,
	max: 1
};

export const defaultToggleInstanceNumber: Required<OutlineSectionInstancesNumber> = {
	initial: 0,
	min: 0,
	max: 1
};
