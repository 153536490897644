import { Sheet } from '../Spreadsheet/helpers/types';

export enum ChartType {
	Pie = 'pie'
}

export type SpreadsheetState = { cellValues: { [key: string]: string }; sheet: Sheet };

export type ChartState = {
	type: ChartType;
	name: string;
	data: ChartDataType;
	meta: ChartMetadata;
};

export type PieDataType = {
	label?: string;
	value: number;
	percent: number;
	formattedValue: string;
}[];

export type ChartDataType = PieDataType; // or any other type, when it will be available

export type ChartMetadata = {
	nameRanges: string[][];
	dataRanges: string[][];
};

export enum Tabs {
	Chart,
	Data
}
