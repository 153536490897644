import React, { useMemo } from 'react';

import { shallow } from 'zustand/shallow';

import { usePrevious } from '~/hooks';

import { getCursorId } from '../../helpers/constants';
import { getChildSizes, getStartPosition } from '../../helpers/selection';
import { CellsRefs } from '../../helpers/types';
import { useElementsResize } from '../../hooks/useElementResize';
import { useSpreadsheetSelector } from '../../store/provider';
import styles from './styles';

interface Props {
	cellsRefs: CellsRefs;
	withHeader: boolean;
	totalHeight: number;
	totalWidth: number;
}

const Cursor: React.FC<Props> = ({ cellsRefs, withHeader, totalHeight, totalWidth }) => {
	const { selectedCell, focused, dest, editingCell } = useSpreadsheetSelector(
		(state) => ({
			selectedCell: state.selectedCell,
			focused: state.focused,
			dest: state.dest,
			editingCell: state.editingCell
		}),
		shallow
	);

	const prevSelectedCell = usePrevious(selectedCell);
	const [triggerIdentifier] = useElementsResize(cellsRefs, [selectedCell, prevSelectedCell]);

	const { isDisplay, position } = useMemo(() => {
		if (!selectedCell) {
			return { isDisplay: false };
		}

		const { top, left } = getStartPosition(selectedCell, cellsRefs, withHeader);
		const { width, height } = getChildSizes(selectedCell, cellsRefs);

		return {
			isDisplay: focused,
			position: {
				top,
				left,
				width,
				height
			}
		};
		// `totalHeight` needed for re-calculation of cursor size
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCell, focused, cellsRefs, withHeader, totalHeight, triggerIdentifier, totalWidth]);

	return (
		<div
			id={`${isDisplay ? getCursorId(dest) : 'cursor-hidden'}`}
			aria-hidden
			css={styles(
				position?.top,
				position?.left,
				position?.width,
				position?.height,
				Boolean(editingCell),
				isDisplay
			)}
		/>
	);
};

export default Cursor;
