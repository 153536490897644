import React, { type PropsWithChildren, forwardRef } from 'react';

import { ClassNames } from '@emotion/react';

import { NewPollIcon, PollIcon } from '~/components/icons';
import { default as UniversalVelvetLeftBorder } from '~/components/pageElements/UniversalVelvetLeftBorder';
import { QuestionType, WebtextQuestion } from '~/components/shared/Question';
import { useIsUniversalVelvet } from '~/hooks';

import pollStyles from './styles';
import { type PollProps } from './types';

type Props = PropsWithChildren<
	Pick<PollProps, 'tabsUi' | 'noBottomMargin'> & {
		id?: string;
		setHeadingRef?: (el?: HTMLElement) => void;
		ariaHeading?: string;
		className?: string;
	}
>;

const PollElementWrapper = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const { id, tabsUi, noBottomMargin, ariaHeading, className, setHeadingRef, children } = props;

	const isUniversalVelvet = useIsUniversalVelvet();

	return (
		<ClassNames>
			{({ cx }) => (
				<div id={id} ref={ref} css={pollStyles} className={cx(className, 'poll-question')}>
					<WebtextQuestion noBottomMargin={noBottomMargin}>
						<UniversalVelvetLeftBorder>
							<QuestionType ref={setHeadingRef}>
								{isUniversalVelvet && (tabsUi ? <NewPollIcon /> : <PollIcon />)}
								Poll Question
								<span className="visually-hidden">{ariaHeading}</span>
							</QuestionType>
							{children}
						</UniversalVelvetLeftBorder>
					</WebtextQuestion>
				</div>
			)}
		</ClassNames>
	);
});

PollElementWrapper.displayName = 'PollElementWrapper';

export default PollElementWrapper;
