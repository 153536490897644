import { CreateSingletonProps as TippySingletonProps } from 'tippy.js';

import type { PollAnswer } from '~/types';
import type {
	FamilyId,
	PollChartType,
	PollChoiceOrdering,
	PollColorPalette,
	PollDataType,
	PollGroupBy,
	QuestionChoice
} from '~/types/WebtextManifest';

export interface PollQuestionProps {
	questionFamilyId: string;
	body: string;
	choices: QuestionChoice[];
	answer?: PollAnswer;
	onChoiceSelected?: (choiceFamilyId: FamilyId) => void;
	onChangeSelectedOption?: (familyId: string) => void;
	online?: boolean;
	submitting?: boolean;
	submissionError?: Error;
	mobile?: boolean;
	readOnly?: boolean;
	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
	/**
	 * New Polls Refresh Props
	 */
	tabsUi?: boolean;
}

export interface PollQuestionTargetResultsProps
	extends Omit<
		PollQuestionProps,
		'answer' | 'onChoiceSelected' | 'onChangeSelectedOption' | 'submitting' | 'submissionError'
	> {
	answer?: Pick<PollAnswer, 'graphData' | 'updated_at'>;
}
export interface RefreshPollProps {
	courseNumber: string | undefined;
	instructions?: string;
	caption?: string;
	credits?: string;
	description?: string;
	externalData?: (string[] | number[])[];
	contextualInfo?: string[];
	totalCount?: number[];
	totalNumberOfStudents?: number;
	dataType?: PollDataType;
	dataGroupBy?: PollGroupBy;
	choiceOrdering?: PollChoiceOrdering;
	sourceDatasetIndex?: number;
	colorPalette?: PollColorPalette;
	colorGroupBy?: PollGroupBy;
	chartType?: PollChartType;
	onFileDownload?: (url: string, defaultFileName?: string) => Promise<boolean>;
	onTabChange?: (tabValue: string) => Promise<void>;
	tippyProps?: Partial<TippySingletonProps>;
	expandedViewEnabled?: boolean;
}

export type PollProps = PollQuestionProps & RefreshPollProps;
export type PollResultsProps = PollQuestionTargetResultsProps & RefreshPollProps;

export enum PollResultDisplayStyle {
	ALL = 'all_or_nothing',
	COMPLETED = 'completed'
}

export interface PollResultsAliasProps {
	targetPollPageNumber?: string;

	/**
	 * Optional because instructor view won't have them, since
	 * the poll alias is *always* rendered from instructor view
	 * We need to keep both the link and the navigate function to:
	 * - support opening the link in the new tab
	 * - track the clicks
	 */
	targetPollLink?: string;
	navigateToTargetPoll?: () => void;

	questionFamilyId: FamilyId; // The family ID of the PollResults element itself
	targetPollResults: PollResultsProps;
}

export const isPollResultsAliasProps = (
	elementProps: PollProps | PollResultsAliasProps
): elementProps is PollResultsAliasProps => 'targetPollPageNumber' in elementProps;

export const isPollQuestionProps = (
	elementProps: PollProps | PollResultsAliasProps
): elementProps is PollProps => !isPollResultsAliasProps(elementProps);
