import React, { useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import isNil from 'lodash-es/isNil';

import { GoReactIcon } from '~/components/icons';
import Loader from '~/components/Loader';
import GoReactIframe from '~/components/pageElements/GoReact/GoReactIframe';
import { getAnswerFeedback } from '~/components/pageElements/GoReact/helpers';
import { GoReactApi, GoReactElement } from '~/components/pageElements/GoReact/types';
import { QuestionPrompt, QuestionType, WebtextQuestion } from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import { generatingLoaderStyle } from '~/components/WritingTemplate/ViewMode/styles';
import { useRelativeTimePhrase } from '~/hooks';
import { GoReactAnswer, GoReactAnswerProps, ToolConfiguration } from '~/types';

import styles from './styles';

export interface Props {
	externalTool: GoReactElement;
	toolConfig: ToolConfiguration;
	answer?: GoReactAnswer;
	checkWorkJobStatus?: { checking: boolean; error: boolean };
	api?: GoReactApi;
	showInstructorView?: boolean;
	showInContextView?: boolean;
}

const GoReact: React.FC<Props> = (props) => {
	const {
		externalTool,
		toolConfig,
		answer,
		checkWorkJobStatus,
		api,
		showInstructorView,
		showInContextView
	} = props;
	const { launchURL, launchParams } = toolConfig;
	const {
		family_id: familyId,
		prompt_body: promptBody,
		caption,
		credits,
		transcript_url: transcriptUrl,
		score_config: scoringConfig
	} = externalTool;

	const showCheckWorkSection =
		!showInstructorView && // Instructors don't need to check work
		scoringConfig?.scoreOptions !== 'noPoints'; // We don't need to check work if there are no points (https://bit.ly/no-points-dp)

	const checkedRelativePhrase = useRelativeTimePhrase(answer?.updated_at);

	const answerFeedback = useMemo(
		() => (answer ? getAnswerFeedback(scoringConfig, answer) : null),
		[answer, scoringConfig]
	);

	const gradePercent = getGradePercentage(externalTool, answer);

	const handleCheckWorkClick = async (): Promise<void> => {
		try {
			await api?.requestWorkCheck();
			console.debug(`GoReact - ${familyId}: work check has been requested successfully`);
		} catch (error) {
			console.error(`GoReact - ${familyId}: work check request failed`, error);
		}
	};

	return (
		<WebtextQuestion wide={!showInContextView}>
			<QuestionType>
				<GoReactIcon />
				Video Recorder
			</QuestionType>
			<QuestionPrompt body={promptBody} />
			<div css={styles}>
				<div className="video-recorder-container">
					<GoReactIframe familyId={familyId} launchURL={launchURL} launchParams={launchParams} />
				</div>
				<div className="bottom-container">
					<div>
						<div className="attributions">
							{caption} <span className="credits">{credits}</span>
						</div>
						{transcriptUrl && (
							<div className="transcript-label">
								Download a <a href={transcriptUrl}>text transcript</a> of the speech.
							</div>
						)}
					</div>

					{showCheckWorkSection && (
						<>
							<div
								className="check-work-feedback"
								role="status"
								aria-busy={checkWorkJobStatus?.checking}>
								{checkWorkJobStatus?.error && (
									<span className="failure">
										Work check error. Please wait 10 seconds, then try clicking the Check My Work
										button again.
									</span>
								)}

								{!checkWorkJobStatus?.checking && !checkWorkJobStatus?.error && answerFeedback && (
									<>
										{answerFeedback.requirementsMet ? (
											<span className="success">
												<FaCheckCircle aria-hidden />
												{answerFeedback.message}
											</span>
										) : (
											<span className="failure">{answerFeedback.message}</span>
										)}
									</>
								)}

								{gradePercent !== null && (
									<div className="instructor-grade-container">
										<div className="grade">
											Grade: {gradePercent.toLocaleString(undefined, { style: 'percent' })}
										</div>
										<div className="instruction">
											Click your recording above to check for comments from your instructor.
										</div>
									</div>
								)}
							</div>

							<div className="status-and-button">
								{gradePercent === null && ( // Show the button only if there's no grade
									<WebtextButton
										size="large"
										disabled={checkWorkJobStatus?.checking}
										onClick={handleCheckWorkClick}>
										{checkWorkJobStatus?.checking ? (
											<div css={generatingLoaderStyle}>
												Checking <Loader />
											</div>
										) : (
											'Check My Work'
										)}
									</WebtextButton>
								)}
								{answer?.updated_at && (
									<span className="status-label">Last checked {checkedRelativePhrase}</span>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</WebtextQuestion>
	);
};

export const getGradePercentage = (
	goReactElement: GoReactElement,
	answerProps: GoReactAnswerProps
): number | null => {
	if (goReactElement.score_config.scoreOptions !== 'instructorGrade') return null;
	if (!answerProps || !('instructorPoints' in answerProps)) return null;

	const { points, possiblePoints } = answerProps.instructorPoints;
	return !isNil(points) && possiblePoints ? points / possiblePoints : null;
};

export default GoReact;
