import { isIOS } from 'react-device-detect';

import { IDictionary } from './../../../../WritingTemplate/types';
import {
	CitationExport,
	CitationStyle,
	CitationResponse,
	AvailableCitation,
	CitationMode,
	SerializedCitationNode,
	CitationResponseAttributes
} from './../../../types';

/**
 * Generate uniq id for each citation;
 * Legacy method but we never had problems with it;
 */
export const generateCitationId = (): string => Math.random().toString(36).substr(2, 9);

/**
 * This function will allow us to create options based on source (select 1) and citation style;
 * It based on citation style because citation options can be smth like style: { apa: {//}, sws: {//}}
 * Note: Turabian style does not have this options and skips this step
 */
export const getCitationStyleOptions = (
	availableCitations: AvailableCitation[],
	style: CitationStyle,
	source: string
): IDictionary => {
	switch (style) {
		case CitationStyle.APA: {
			return createOptionsForApa(availableCitations, source);
		}
		case CitationStyle.SWS: {
			return createStyleOptionsForSws(availableCitations, source);
		}
		default:
			return null;
	}
};

const getStyleOptionsForSource = (availableCitations: AvailableCitation[], source: string) =>
	availableCitations.filter(({ 'options-label': value }) => value === source);

/**
 * This function is called from getCitationStyleOptions create uniq styles for APA style.
 */
const createOptionsForApa = (availableCitations: AvailableCitation[], source: string) =>
	getStyleOptionsForSource(availableCitations, source).reduce(
		(acc, { 'supported-styles': supported }) => {
			const { styles } = supported[CitationStyle.APA];
			return { ...acc, ...styles.reduce((acc, val) => ({ ...acc, ...val }), {}) };
		},
		{}
	);

/**
 * Turabian style don't use this values directly, but still will require it to define is extra input presented
 */
const createOptionsForTur = (availableCitations: AvailableCitation[], source: string) =>
	getStyleOptionsForSource(availableCitations, source).reduce(
		(acc, { 'supported-styles': supported }) => {
			return { ...acc, ...supported[CitationStyle.Turabian] };
		},
		{}
	);

/**
 * This function is called from getCitationStyleOptions create uniq styles for SWS style.
 */
const createStyleOptionsForSws = (availableCitations: AvailableCitation[], source: string) =>
	getStyleOptionsForSource(availableCitations, source).reduce(
		(acc, { 'supported-styles': supported }) => {
			const { 'author-name': author, 'page-number': page } = supported[CitationStyle.SWS];
			const result = {
				'sws-citation-name': `(${author}, Source No.)`,
				'sws-citation-reference-number': '(Source No.)'
			};

			const pageSetup = {
				'sws-citation-name-page-number': `(${author}, Source No., p. __)`,
				'sws-page-number': '(Source No., p. __)'
			};
			return page ? { ...acc, ...result, ...pageSetup } : { ...acc, ...result };
		},
		{}
	);

/**
 * Define is extra input presented for particular citation style;
 * Based on source or options and configs
 */
export const getIsExtraInput = (
	style: CitationStyle,
	source: string,
	citationStyleOption: string,
	availableCitations: AvailableCitation[]
): boolean => {
	switch (style) {
		case CitationStyle.APA:
		case CitationStyle.SWS:
			return getIsExtraInputForCitation(source, citationStyleOption, availableCitations, style);
		case CitationStyle.Turabian:
			return getIsExtraInputForTur(source, availableCitations);
		default:
			return false;
	}
};

/**
 * Used to define is there are any extra inputs for SWS and APA are supposed to be there
 */
const getIsExtraInputForCitation = (
	citationSource: string,
	citationStyleOption: string,
	availableCitations: AvailableCitation[],
	citationStyle: CitationStyle
) => {
	const options =
		citationStyle === CitationStyle.APA
			? createOptionsForApa(availableCitations, citationSource)
			: createStyleOptionsForSws(availableCitations, citationSource);
	const option = getSelectedOption(citationStyleOption, options);
	if (!option || !option.length) return false;
	const [key] = option;

	return key.includes('page');
};

/**
 * Used to define is there are any extra inputs for Turabian are supposed to be there
 */
const getIsExtraInputForTur = (citationSource: string, availableCitations: AvailableCitation[]) => {
	if (!citationSource) return false;

	const options = createOptionsForTur(availableCitations, citationSource);

	return Object.entries(options).some(([key, value]: [string, string]) => {
		if (key !== 'note-text' && key !== 'short-note-text') return false;
		if (value.match(/\W+xx/gi)) return true;

		return false;
	});
};

const getSelectedOption = (option: string, options: IDictionary) =>
	Object.entries(options).find(([, value]) => value === option);

/**
 * Crete citation data for response, based on the style
 */
export const makeCitationExportData = (
	style: CitationStyle,
	source: string,
	option: string,
	availableCitations: AvailableCitation[],
	extraInput: string
): CitationExport => {
	switch (style) {
		case CitationStyle.APA:
			return makeCitationExportApa(source, option, extraInput, availableCitations);
		case CitationStyle.SWS:
			return makeCitationExportSws(source, option, extraInput, availableCitations);
		case CitationStyle.Turabian:
			return makeCitationExportTur(source, extraInput, availableCitations);
		default:
			return null;
	}
};

/**
 * Crete APA citation data for response
 */
const makeCitationExportApa = (
	citationSource: string,
	option: string,
	extraInput: string,
	availableCitations: AvailableCitation[]
) => {
	const source = getStyleOptionsForSource(availableCitations, citationSource)[0];
	const { 'options-value': key } = source;

	const options = createOptionsForApa(availableCitations, citationSource);
	const [selectedOptionsKey, selectedOptionsValue] = getSelectedOption(option, options);

	const markerValue = makeMakerApa(selectedOptionsValue, extraInput);

	const attributes = extraInput?.length
		? { style: selectedOptionsKey, 'page-number': extraInput }
		: { style: selectedOptionsKey };

	return {
		key,
		attributes,
		markerValue
	};
};

/**
 * Crete APA marker for response
 */
const makeMakerApa = (selectedOptionsValue: string, extraInput: string): string => {
	/**
	 * Remove all brackets and wrap only around the text
	 */
	if (!extraInput?.length) return `(${selectedOptionsValue.replace(/\(|\)/gi, '')})`;

	/**
	 * Remove brackets and wrap only around text; also replace placeholder with add value;
	 */
	return `(${selectedOptionsValue.replace(/\(|\)/gi, '').replace(/_+/gi, extraInput)})`;
};

/**
 * Crete SWS citation data for response
 */
const makeCitationExportSws = (
	citationSource: string,
	option: string,
	extraInput: string,
	availableCitations: AvailableCitation[]
) => {
	const source = getStyleOptionsForSource(availableCitations, citationSource)[0];
	const { 'options-value': key } = source;

	const options = createStyleOptionsForSws(availableCitations, citationSource);
	const [selectedOptionsKey] = getSelectedOption(option, options);
	const markerValue = makeMarkerSws(key, selectedOptionsKey, availableCitations);

	const attributes = extraInput?.length
		? { style: selectedOptionsKey, 'page-number': extraInput }
		: { style: selectedOptionsKey };

	return { key, attributes, markerValue };
};

/**
 * Crete SWS marker for response
 */
const makeMarkerSws = (
	key: string,
	selectedOptionsKey: string,
	availableCitation: AvailableCitation[]
) => {
	/**
	 * When selected option includes a name, we're showing it in citation marker
	 */
	const isAttrStyleWithName = selectedOptionsKey.includes('name');

	/**
	 * Get the author name from config
	 */
	const getAuthorName = () =>
		availableCitation
			.map(({ 'options-value': key, 'supported-styles': styles }) => ({
				key,
				author: styles[CitationStyle.SWS]['author-name']
			}))
			.find(({ key: k }) => k === key)?.author;

	/**
	 * Otherwise show (#) for SWS;
	 */
	return isAttrStyleWithName ? `(${getAuthorName()}, #)` : '(#)';
};

/**
 * Crete Turabuan citation data for response
 */
const makeCitationExportTur = (
	citationSource: string,
	extraInput: string,
	availableCitations: AvailableCitation[]
) => {
	const source = getStyleOptionsForSource(availableCitations, citationSource)[0];
	const { 'options-value': key } = source;
	/**
	 * Will be managed separately from this scope
	 */
	const markerValue = '0';
	const attributes = extraInput?.length ? { 'page-number': extraInput } : {};

	return {
		key,
		attributes,
		markerValue
	};
};

/**
 * Send error notification when citation can't be created
 */
export const notifyError = (data: IDictionary, mode: CitationMode): void => {
	console.error(`Unable to ${mode} citation with data: `, data);
	if (window.Rollbar) window.Rollbar.error(`Unable to ${mode} citation with data: `, data);
};

/**
 * When we enter in update citation mode, this function used for pre populating values from existing citation
 */
export const preInitCitation = (
	citationStyle: CitationStyle,
	editingCitation: CitationResponse,
	availableCitations: AvailableCitation[]
): {
	source: string;
	option: string;
	extraInput: string;
} => {
	const { key } = editingCitation;
	const citationMeta = availableCitations.find(({ 'options-value': option }) => option === key);

	switch (citationStyle) {
		case CitationStyle.APA:
		case CitationStyle.SWS:
			return makeDefaultsForCitations(
				editingCitation,
				citationMeta,
				availableCitations,
				citationStyle
			);
		case CitationStyle.Turabian:
			return makeDefaultsForTur(editingCitation, citationMeta);
		default:
			return null;
	}
};

/**
 * Create pre-populated data for APA or SWS
 */
const makeDefaultsForCitations = (
	editingCitation: CitationResponse,
	citationMeta: AvailableCitation,
	availableCitations: AvailableCitation[],
	citationStyle: CitationStyle
) => {
	const options =
		citationStyle === CitationStyle.APA
			? createOptionsForApa(availableCitations, citationMeta['options-label'])
			: createStyleOptionsForSws(availableCitations, citationMeta['options-label']);

	const { 'options-label': source } = citationMeta;
	const {
		attributes: { style, 'page-number': extraInput }
	} = editingCitation;

	const option = options[style];

	return { source, option, extraInput };
};

/**
 * Create pre-populated data for Turabian
 */
const makeDefaultsForTur = (editingCitation: CitationResponse, citationMeta: AvailableCitation) => {
	const { 'options-label': source } = citationMeta;
	const {
		attributes: { 'page-number': extraInput }
	} = editingCitation;

	return { source, option: null, extraInput };
};

/**
 * For iOS we need to add a space after the citation marker
 * otherwise cursor will be placed before the marker for citation that exported from the response
 */
export const addCitationSpacerForIOS = (editorDom: Document): void => {
	if (!isIOS) return;

	Array.from(editorDom.getElementsByTagName('mark'))
		.filter((mark) => !mark.nextSibling)
		.forEach((mark) => mark.after(document.createTextNode(' ')));
};

/**
 * Insert citation attributes inside the mark nodes that will be parsed by the editor
 * When citation will be created, we will use these values for filling gaps in CitationNode.
 */
export const extendCitationElementsWithAttributes = (
	editorDom: Document,
	inEditorCitations: CitationResponse[]
): void => {
	if (!inEditorCitations?.length) return;

	Array.from(editorDom.getElementsByTagName('mark')).forEach((mark) => {
		const citation = inEditorCitations.find((c) => c.identifier === mark.id);
		if (!citation) return;
		mark.setAttribute('data-citation', JSON.stringify(citation));
	});
};

/**
 * Check if citation available in this editor instance by checking the options-value and style
 */
export const getCitationAvailable = (
	node: SerializedCitationNode,
	availableCitations: AvailableCitation[],
	citationStyle: CitationStyle
): boolean => {
	const { citationKey, attributes } = node;

	const nodeCitationStyle = getCitationStylesFromAttributes(attributes);
	if (citationStyle !== nodeCitationStyle) return false;
	return availableCitations.some(
		({ 'options-value': key, 'supported-styles': styles }) =>
			key === citationKey && styles[citationStyle]
	);
};

const getCitationStylesFromAttributes = (attributes: CitationResponseAttributes) => {
	if (!attributes.style) return CitationStyle.Turabian;
	if (attributes.style.includes('apa')) return CitationStyle.APA;
	if (attributes.style.includes('sws')) return CitationStyle.SWS;

	return null;
};

export const getInputValid = (htmlString: string): boolean => {
	let isInputValid = true;

	const markFindRegex = /<mark .*?>.*?<\/mark>/gi;
	/**
	 * We could use DOMParser for the string entirely, but this is not something that will be better, expect for code-semantic reasons
	 * In this case replace is targeting only marks, so it's not a big deal
	 * We're checking if the mark has data-citation attribute, if not, we're rejecting this input.
	 */
	htmlString.replace(markFindRegex, (mark: string) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(mark, 'text/html');
		const markElement = doc.getElementsByTagName('mark')[0];
		const citationDataValue = markElement.getAttribute('data-citation');

		/**
		 * If we have marks that does not have attribute data-citation, it means that they are not citations and more likely pasted from output, in this case we have to reject this entirely
		 */
		if (!citationDataValue) {
			isInputValid = false;
			return '';
		}

		return mark;
	});

	return isInputValid;
};
