import React from 'react';

import { css } from '@emotion/react';

import { useIsUniversalVelvet } from '~/hooks';
import { breakpoints, ThemeName } from '~/styles/themes';

import { PopupQuiz, Dimension } from './types';

interface Props {
	popupQuiz: PopupQuiz;
	dimension: Dimension;
	topLineResult: string;
	explanation: string;
	singleDimension: boolean;
}

const DimensionScoreInterpretation: React.FC<Props> = (props) => {
	const { dimension, topLineResult, explanation, singleDimension } = props;
	const isUniversalVelvet = useIsUniversalVelvet();

	return (
		<div css={styles}>
			{!singleDimension &&
				(!isUniversalVelvet ? (
					<div className="dimension-name-and-desc">
						<strong>{dimension.name}:</strong> {dimension.desc}
					</div>
				) : (
					<div className="dimension-name-and-desc">
						<strong>{dimension.name}:</strong>
						<strong className="dimension-desc">{dimension.desc}</strong>
					</div>
				))}

			{topLineResult &&
				(!isUniversalVelvet ? (
					<div
						className="dimension-top-line-result"
						dangerouslySetInnerHTML={{ __html: topLineResult }}
					/>
				) : (
					<span
						className="dimension-top-line-result"
						dangerouslySetInnerHTML={{ __html: topLineResult }}
					/>
				))}

			{explanation &&
				(!isUniversalVelvet ? (
					<div
						className="dimension-explanation"
						dangerouslySetInnerHTML={{ __html: explanation }}
					/>
				) : (
					<span
						className="dimension-explanation"
						dangerouslySetInnerHTML={{ __html: explanation }}
					/>
				))}
		</div>
	);
};

const styles = (theme) => {
	return css`
		.dimension-name-and-desc {
			padding: 0 0 0.3em;

			font-size: 16px;
			line-height: 1.2;
		}

		.dimension-top-line-result {
			width: 90%;
			padding-top: 0.5em;
		}

		.dimension-explanation {
			width: 90%;
			padding-top: 0.8em;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.dimension-name-and-desc {
				font-size: 28px;
				line-height: 1.071;

				.dimension-desc {
					display: block;
					font-size: 18px;
					line-height: 1.667;
				}
			}

			.dimension-top-line-result {
				color: ${theme.colors.primary};
			}

			.dimension-top-line-result,
			.dimension-explanation {
				font-size: 18px;
				line-height: 1.667;
				margin-right: 5px;
			}

			@media (max-width: ${breakpoints.small}) {
				.dimension-name-and-desc {
					font-size: 20px;
					line-height: 1.9;

					.dimension-desc {
						font-size: 15px;
					}
				}

				.dimension-top-line-result,
				.dimension-explanation {
					font-size: 15px;
				}
			}
		`}
	`;
};

export default DimensionScoreInterpretation;
