import React from 'react';

import { css } from '@emotion/react';

import { mixins } from '~/styles/themes';

import type { ChartElement } from '~/types/WebtextManifest';

interface Props {
	chart: ChartElement;
}

const ChartDescription: React.FC<Props> = (props) => {
	const { chart } = props;

	return (
		<div css={descriptionStyles} id={`webtext-chart-${chart.id}`}>
			<div dangerouslySetInnerHTML={{ __html: chart.description }} />
			{chart.tabsUi && <div>Go to the Data tab to explore the details.</div>}
			{!chart.tabsUi && (
				<div>
					<h3>Chart data for {chart.figure_number || chart.title || 'figure'}</h3>
					<table>
						<thead>
							<tr>
								{chart.data[0].map((header, idx) => {
									const id = `${chart.id}-header-${idx + 1}`;
									return (
										<th key={id} id={id}>
											{header}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{chart.data.slice(1, -1).map((row, rowIdx) => (
								<tr key={rowIdx}>
									{row.map((cell, idx) => {
										const headersId = `${chart.id}-header-${idx + 1}`;
										return (
											<td key={headersId} headers={headersId}>
												{cell}
											</td>
										);
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

const descriptionStyles = (theme) => css`
	${mixins.webtextHiddenAccessible(theme)}
`;

export default ChartDescription;
