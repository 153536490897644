import React, { useLayoutEffect } from 'react';

import { BoundaryError } from '~/components/GenericErrorBoundary';
import { BuilderApiEndpointKey } from '~/components/WritingTemplate/WritingTemplate/utils';
import NetError from '~/utils/netErrors';

import { invalidText } from '../EditMode/styles';

interface Props {
	netError?: NetError<BuilderApiEndpointKey>;
}

// Nonfatal means we're just going to render some kind of warning message below the component,
// but if it's fatal, we're letting the error boundary handle it, which is going to tell
// the user to reload
const fatalResponseCodes = [409];
const fatalApiEndpointKeys: Array<BuilderApiEndpointKey> = ['load'];

type EndpointsMessages = {
	[request in BuilderApiEndpointKey]?: {
		[status: number]: string;
	};
};

const defaultErrorMessage = `Your response was unable to be delivered because of a network error.`;

const endpointsMessages: EndpointsMessages = {
	store: {
		409: `It looks like you’ve filled out this template in another browser window or tab. 
					Please reload this page to see those changes here.`
	},
	generateDocument: {
		500: `Something went wrong. Refresh the page and try again. If the error persists, contact Soomo Support.`
	}
};

/**
 * This component triggers the exceptions which will consistently be caught and processed by the ErrorBoundary
 */
const NetworkError: React.FC<Props> = ({ netError }) => {
	const { apiEndpointKey, status } = netError;

	const errorMessage = endpointsMessages[apiEndpointKey]?.[status] || defaultErrorMessage;

	useLayoutEffect(() => {
		if (fatalApiEndpointKeys.includes(apiEndpointKey) || fatalResponseCodes.includes(status)) {
			throw new BoundaryError(errorMessage);
		}
	}, [status, errorMessage, apiEndpointKey]);

	return <div css={invalidText}>{errorMessage}</div>;
};

export default NetworkError;
