import styles from './PollQuestion.scss';
import pageViewStyles from '../PageView.scss';

import React, { Component } from 'react';
import { object, string, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { bindActionCreators } from 'redux';
import last from 'lodash.last';

import { PollQuestion as PollQuestionFromLibs } from '@soomo/lib/components/pageElements/PollQuestion/index';
import { QuestionChoices } from '@soomo/lib/components/shared/Question';

import elementActivityQuery from 'Requests/ElementActivityQuery';
import PollResults from 'Components/PollResults';
import ActivityHeader from 'Components/ActivityHeader';
import * as uiActions from 'Actions/uiActions';
import withLibsThemeProvider from 'Hocs/withLibsThemeProvider';

class PollQuestion extends Component {
	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate() {
		this.fetchData();
	}

	fetchData() {
		const { dispatch, course, question } = this.props;
		dispatch(elementActivityQuery({ courseId: course.id, elementId: question.id }));
	}

	showContextView() {
		const { question } = this.props;
		this.props.uiActions.updateCurrentViewParams({
			showInContext: true,
			scrollTo: `poll_question_${question.id}`
		});
	}

	render() {
		const { question, pollResults, answer, timeZone, course, tabsUi } = this.props;

		if (this.props.inContext) {
			return (
				<PollQuestionFromLibs
					questionFamilyId={question.family_id}
					answer={answer}
					online
					submitting={false}
					onChoiceSelected={() => undefined}
					readOnly
					courseNumber={course.number}
					tabsUi={tabsUi}
					totalNumberOfStudents={course.active_student_enrollment_count}
					{...question}
				/>
			);
		}

		const renderHeader = () => (
			<>
				{answer && <div className={cn(pageViewStyles.Symbol, pageViewStyles.IsCompleteSymbol)} />}
				<div className={styles.ActivitySummary}>
					<span className={pageViewStyles.QuestionType}>Poll Question</span>
				</div>
				<h2
					className={pageViewStyles.QuestionBody}
					dangerouslySetInnerHTML={{ __html: question.body }}
				/>
				{answer && (
					<ActivityHeader timestamp={answer.saved_at} timeZone={timeZone}>
						COMPLETED
					</ActivityHeader>
				)}
			</>
		);

		const renderNoAnswer = () => (
			<QuestionChoices
				questionFamilyId={question.family_id}
				choices={question.choices}
				disabled
				className={styles.QuestionChoiceList}
			/>
		);

		const renderAnswer = () =>
			tabsUi ? (
				<div className={styles.PollInContextResult}>
					To review the poll results, go to{' '}
					<a onClick={this.showContextView.bind(this)}>In-Context View</a>.
				</div>
			) : (
				<div className={styles.PollResults} data-test="poll-results">
					<PollResults poll={question} results={pollResults} width={590} />
				</div>
			);

		return (
			<div className={styles.PollQuestion}>
				{renderHeader()}
				{answer ? renderAnswer() : renderNoAnswer()}
			</div>
		);
	}
}

PollQuestion.propTypes = {
	course: object.isRequired,
	question: object.isRequired,
	pollResults: object.isRequired,
	answer: object,
	timeZone: string.isRequired,
	tabsUi: bool.isRequired,
	inContext: bool.isRequired,
	dispatch: func.isRequired,
	uiActions: object.isRequired
};

export const createPollQuestionState = (state, question, activity) => {
	const pollResults = {};
	const choiceMap = {};
	question.choices.forEach((choice) => {
		pollResults[choice.body] = 0;
		choiceMap[choice.id] = choice.body;
	});

	state.entities.studentIds.forEach(function (userId) {
		const answerId = (state.entities.elementAnswerIds[userId + ':' + question.id] || [])[0];

		if (answerId) {
			const answer = state.entities.answers[answerId];
			const choiceBody = choiceMap[answer.body];
			pollResults[choiceBody]++;
		}
	});

	const page = state.entities.pages[state.ui.currentViewParams.scope];

	const tabsUi =
		state.entities.toc.config.pages.types[page.page_type].elements['Poll']?.settings?.tabs_ui ||
		false;

	let answer = last(activity?.answers);
	if (answer && answer.reset_at) answer = null;
	if (answer && pollResults) {
		answer.completed = true;
		answer.graphData = Object.entries(pollResults).map(([label, data]) => ({ label, data }));
	}

	return {
		course: state.entities.course,
		pollResults: pollResults,
		timeZone: state.entities.course.time_zone,
		answer,
		tabsUi
	};
};

const mapStateToProps = (state, props) =>
	createPollQuestionState(state, props.question, props.activity);

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uiActions: bindActionCreators(uiActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withLibsThemeProvider(PollQuestion));
