class NetError<TEndpointKey = string> extends Error {
	status: number;
	apiEndpointKey?: TEndpointKey;

	constructor(message: string, status: number, apiEndpointKey?: TEndpointKey) {
		super(message);
		this.status = status;
		this.apiEndpointKey = apiEndpointKey;
	}
}

export default NetError;

// https://developer.mozilla.org/en-US/docs/Web/API/DOMException#exception-AbortError
export const isAbortError = (err: unknown): err is DOMException => {
	if (!(err instanceof DOMException)) return false;
	return err.name === 'AbortError' || err.code === DOMException.ABORT_ERR;
};
