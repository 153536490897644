import first from 'lodash-es/first';
import last from 'lodash-es/last';

import { ChartState } from '../../Charts/types';
import { IDictionary, Kinds } from '../../WritingTemplate/types';
import { BuilderElementConfig, ChartNameSource } from '../types';

export const getFromConfig = (dest: string, fromConfig?: IDictionary) => {
	if (!!fromConfig && !!fromConfig[dest]) {
		return fromConfig[dest];
	}

	if (fromConfig && dest && dest.includes('.')) {
		const [path, to] = dest.split('.');

		if (fromConfig[path]) {
			const temp = fromConfig[path];
			const tempTo = temp[to];

			if (tempTo) return tempTo;
		}
	}
	return null;
};

export const basisMap = (basis: number): number => {
	if (!basis) return 25;

	const values = {
		less10: basis * 2,
		to15: basis + 10,
		to50: basis + 15,
		to80: basis * (1 + basis / 100) + 10
	};

	if (basis < 10) return values['less10'];
	if (basis < 15) return values['to15'];
	if (basis < 50) return values['to50'];

	return values['to80'];
};

export const shouldSplitText = (
	element: BuilderElementConfig,
	parentPresentation: string
): boolean => {
	if (element.kind !== Kinds.Text) return false;
	return element.value.endsWith('<br>') && parentPresentation === 'inline';
};

export const getFooterData = (buttons, footer) => {
	const defaultFooterBody =
		'If the button cannot be clicked, make sure you have responded to all prompts and completed all citations in the template.';
	const defaultFooterTitle = `To complete this template, you must click the “${
		buttons?.save ? buttons.save : 'Save'
	}” button.`;
	const footerCopy = footer || {};
	const visible = footerCopy.visible === 'false' ? false : footerCopy.visible;
	return { body: defaultFooterBody, title: defaultFooterTitle, ...footer, visible };
};

export const getNameSource = (name: string): ChartNameSource => {
	if (!name) return null;

	const findCellReferenceRegex = /:[a-z]+[0-9]+$/gi;

	if (findCellReferenceRegex.test(name)) {
		const spreadsheetSource = name.split(/:/);

		return {
			type: 'cell',
			value: first(spreadsheetSource),
			address: last(spreadsheetSource)
		};
	}

	return { type: 'raw', value: name };
};

export const getChartName = (
	chartValue: ChartState,
	nameSource: ChartNameSource,
	getFromUserInputs: (dest: string) => any
): string => {
	if (chartValue?.name) return chartValue.name;
	if (!nameSource) return '';

	const { type, value, address } = nameSource;
	const nameFromState = getFromUserInputs(value);

	if (!nameFromState) return value;
	if (type === 'raw') return nameFromState;

	const cellValue = nameFromState.cellValues[address];

	return cellValue || '';
};

/**
 * Iterates over the interactive parts of the schema's inputs and aggregate them into an array
 * Result example: https://ibb.co/cbtpwgg
 */
export function getInteractivePromptsParts(part: IDictionary): IDictionary[] {
	if (!(part instanceof Object)) return [];

	if (Array.isArray(part)) {
		return part.flatMap((inputPart) => getInteractivePromptsParts(inputPart));
	}

	if (!('kind' in part && 'dest' in part)) {
		return Object.entries(part)
			.filter(([key]) => key !== Kinds.Dest)
			.flatMap(([_, value]) => getInteractivePromptsParts(value));
	}

	return part as IDictionary[];
}

export const getPreviousVisibleElement = (
	elements: BuilderElementConfig[],
	index: number
): BuilderElementConfig => {
	const previousElement = elements[index - 1];
	if (!previousElement) return null;

	if (previousElement.kind === Kinds.Map || previousElement.kind === Kinds.Group) {
		return getPreviousVisibleElement(elements, index - 1);
	}

	return previousElement;
};
