import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaListUl } from 'react-icons/fa';

import {
	INSERT_UNORDERED_LIST_COMMAND,
	REMOVE_LIST_COMMAND,
	$isListNode,
	ListNode
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType } from '@lexical/utils';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';

import { ListType } from '../../../types';
import ToolbarButton from '../components/Button';
import CitationMetaContext from '../context/CitationMetaContext';
import { toolbarStyles } from '../styles';

interface Props {
	allowedLists: ListType[];
	readOnly?: boolean;
	withCitations: boolean;
}

export const ToolbarPlugin: React.FC<Props> = ({ allowedLists, readOnly, withCitations }) => {
	const { openCreateDialog } = useContext(CitationMetaContext);
	const [editor] = useLexicalComposerContext();
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [blockType, setBlockType] = useState('paragraph');

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();

			const element =
				anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);

			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type = parentList ? parentList.getTag() : element.getTag();
					setBlockType(type);
				} else {
					const type = $isHeadingNode(element) ? element.getTag() : element.getType();
					setBlockType(type);
				}
			}

			setIsBold(selection.hasFormat('bold'));
			setIsItalic(selection.hasFormat('italic'));
		}
	}, [editor]);

	useEffect(() => {
		const removeUpdateToolbarListener = editor.registerUpdateListener(({ editorState }) => {
			editorState.read(() => {
				updateToolbar();
			});
		});

		return () => {
			removeUpdateToolbarListener();
		};
	}, [editor, updateToolbar]);

	const isULAllowed = allowedLists?.includes(ListType.UL);
	const isListBlock = blockType === 'ul';

	/**
	 * We have to call editor.focus() here, because of several reasons.
	 * 1. We have to setSelection to null, after setting initial state, otherwise editor will take unexpected focus
	 * 2. If we setSelection to null, button is not going to work until editor will have focus :confused:
	 */
	const handleBoldClick = () => {
		editor.focus();
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
	};

	const handleItalicClick = () => {
		editor.focus();
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
	};

	const formatBulletList = () => {
		editor.focus();

		if (blockType !== 'ul') {
			editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, null);
			return;
		}

		editor.dispatchCommand(REMOVE_LIST_COMMAND, null);
	};

	return (
		<div className="toolbar-container" css={toolbarStyles}>
			<ToolbarButton
				className={`bold${isBold ? ' active' : ''}`}
				onClick={handleBoldClick}
				aria-label="format bold"
				aria-disabled={readOnly}
				disabled={readOnly}
				aria-pressed={isBold}>
				<span aria-hidden>B</span>
			</ToolbarButton>
			<ToolbarButton
				className={`italic${isItalic ? ' active' : ''}`}
				onClick={handleItalicClick}
				aria-label="format italic"
				aria-disabled={readOnly}
				disabled={readOnly}
				aria-pressed={isItalic}>
				<em aria-hidden>I</em>
			</ToolbarButton>
			{isULAllowed && (
				<ToolbarButton
					className={`list-button`}
					onClick={formatBulletList}
					aria-label="bullet list"
					aria-disabled={readOnly}
					disabled={readOnly}
					aria-pressed={isListBlock}>
					<div aria-hidden>
						<FaListUl className={isListBlock ? 'active' : ''} />
					</div>
				</ToolbarButton>
			)}
			{withCitations && (
				<ToolbarButton
					className={`add-citation-button`}
					aria-label="Add citation"
					aria-disabled={readOnly}
					disabled={readOnly}
					onClick={openCreateDialog}
					aria-pressed={false}>
					Add citation
				</ToolbarButton>
			)}
		</div>
	);
};
