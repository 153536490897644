import React, { useEffect, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';

import { Theme } from '~/styles/themes';

import { fromHtmlToRawText, placeCaretAtEnd } from '../../helpers';
import { inlineInputStyle } from '../../styles';

interface Props {
	dest: string;
	placeholder?: string;
	value?: string;
	label: string;
	readOnly: boolean;
	isInvalid: boolean;
	onChange: (value: string) => void;
	onUserUpdate?: () => void;
}

export type InputType = 'inline' | 'inline-block';

const InlineInput: React.FC<Props> = ({
	placeholder,
	value,
	label = 'Your Response',
	readOnly,
	dest,
	isInvalid,
	onChange: handleSave,
	onUserUpdate
}) => {
	const [initial, setInit] = useState<string>(value);
	const [initWidth, setInitWidth] = useState<number>(null);
	const [localValue, setValue] = useState<string>(value || null);
	const [inputType, setInputType] = useState<InputType>('inline');
	const [isFocus, setFocus] = useState(false);
	const inputRef = useRef(null);
	const theme: Theme = useTheme();

	useEffect(() => {
		if (value) {
			onChange(value, true);
		}
	}, [value]);

	useEffect(() => {
		if (inputRef) {
			if (inputRef.current.offsetWidth < 100 && localValue?.length < 1) {
				setInputType('inline-block');
			} else if (
				inputRef.current.offsetWidth > 100 &&
				inputType === 'inline-block' &&
				localValue?.length > 10
			) {
				setInputType('inline');
			}
		}
	}, [initial, localValue]);

	useEffect(() => {
		if (inputRef) {
			setInitWidth(inputRef.current.offsetWidth);
		}
	}, [inputRef]);

	useEffect(() => {
		if (inputRef && /<br\/?>/.test(inputRef.current.innerHTML)) {
			inputRef.current.innerHTML = '';
			insertText(localValue);
		}
	});

	const onChange = (text: string, fromUpdate?: boolean) => {
		handleSave(text.replace('\n', ''));
		setValue(text.replace('\n', ''));

		if (fromUpdate) {
			setInit(text.replace('\n', ''));
		}
	};

	const onPaste = (event: React.ClipboardEvent<HTMLSpanElement>) => {
		event.preventDefault();
		const text = event.clipboardData.getData('text/plain').replace('\n', '');
		insertText(text);
	};

	const handleFocus = () => {
		placeCaretAtEnd(inputRef?.current);
		setFocus(true);
		if (!localValue || localValue?.length < 10) {
			setInputType('inline-block');
		}
	};

	const handleBlur = () => {
		setFocus(false);
		if (!localValue && placeholder) {
			setInputType('inline');
		}
	};

	const insertText = (text: string) => {
		document.execCommand('inserttext', false, text);
	};

	const onPressKey = (event: React.KeyboardEvent<HTMLSpanElement>) => {
		onUserUpdate?.();
		if (event.key === 'Enter') {
			event.preventDefault();
			return;
		}
	};

	return (
		<span
			id={dest}
			css={inlineInputStyle(theme, inputType, isFocus, initWidth, readOnly, isInvalid)}
			className="editable"
			ref={inputRef}
			aria-label={fromHtmlToRawText(label)}
			aria-description={placeholder}
			data-placeholder={placeholder}
			aria-invalid={isInvalid}
			onInput={(e) => onChange(e.currentTarget.textContent)}
			onPaste={(event) => onPaste(event)}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onKeyPress={(e) => onPressKey(e)}
			suppressContentEditableWarning
			contentEditable={!readOnly}>
			{initial}
		</span>
	);
};

export default InlineInput;
