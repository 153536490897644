import AlphaEncoder from 'alphanumeric-encoder';
import { romanize } from 'romans';

import { NumberedItemStyle } from '~/components/Outline/types';

const alphaEncoder = new AlphaEncoder();

export const formatDecimalToNumberedStyle = (decimal: number, style: NumberedItemStyle): string => {
	let formatted: string;

	switch (style) {
		case NumberedItemStyle.Decimal:
			formatted = decimal.toString();
			break;
		case NumberedItemStyle.DecimalLeadingZero:
			formatted = decimal.toString().padStart(2, '0'); // Matches the CSS decimal-leading-zero behavior
			break;
		case NumberedItemStyle.UpperRoman:
			formatted = romanize(decimal);
			break;
		case NumberedItemStyle.LowerRoman:
			formatted = romanize(decimal).toLowerCase();
			break;
		case NumberedItemStyle.UpperAlpha:
			formatted = alphaEncoder.encode(decimal);
			break;
		case NumberedItemStyle.LowerAlpha:
			formatted = alphaEncoder.encode(decimal).toLowerCase();
			break;
	}

	return `${formatted}.`;
};
