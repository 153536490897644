import React, { VFC } from 'react';
import { ImCheckmark, ImCross } from 'react-icons/im';

import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '~/styles/themes';

export interface Props {
	correct: boolean;
	className?: string;
}

const CorrectnessIcon: VFC<Props> = (props) => {
	const { correct, className } = props;
	return (
		<span
			css={(theme) => styles(theme, { correct })}
			className={className}
			role="img"
			aria-label={correct ? 'Correct.' : 'Incorrect.'}>
			{correct ? <ImCheckmark aria-hidden /> : <ImCross aria-hidden />}
		</span>
	);
};

const styles = (theme: Theme, options: { correct: boolean }): SerializedStyles => css`
	color: ${options.correct ? theme.colors.green : theme.colors.red};
`;

export default CorrectnessIcon;
