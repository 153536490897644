import { css } from '@emotion/react';

import { fonts } from '~/styles/themes';

export default (theme) => css`
	.uppy-Dashboard-poweredBy {
		display: none !important;
	}
	.uppy-Informer {
		p {
			border-radius: 8px;
			color: #e70000;
			background: #fee6e6;
			border: 1px solid #e70000;
		}
	}
`;

export const closeButtonStyle = () => css`
	font-family: ${fonts['haas-grotesk-display']} !important;
	position: absolute;
	top: -8px;
	right: -10px;
	padding: 8px;
	border-radius: 50%;
	color: white;
	background-color: black;
	height: 10px;
	width: 10px;

	&::before {
		position: absolute;
		transform: translate(-50%, -50%);
		content: '×';
		font-weight: bold;
		font-size: 17px !important;
		color: #fff;
	}
`;
export const previewStyle = () => css`
	position: relative;
	height: 100%;
	display: inline-block;

	img {
		max-width: 100%;
	}

	svg {
		max-width: 100%;
		height: 110px;
	}
`;

export const pdfPreview = () => css``;
