import cloneDeep from 'lodash-es/cloneDeep';

import constants from '../base/constants';
import baseTheme from '../strayer_velvet';

const theme = cloneDeep(baseTheme);

const strayerPrimary = '#c02027';
const strayerSecondary = '#1d77af';
const strayerGrayHigh = '#f1f1f2';

const { oswald, roboto } = constants.fonts;

theme.colors.primary = strayerSecondary;
theme.colors.secondary = strayerGrayHigh;
theme.colors.accent = strayerPrimary;
theme.colors.link = theme.colors.primary;

theme.bigLink.icon.fill = theme.colors.accent;

theme.webtextWritingTemplate.instructions.padding = '10px 20px';

theme.artifactLink.icon.color = strayerPrimary;
theme.artifactLink.title.fontFamily = oswald;

theme.element.title.fontFamily = oswald;

theme.figure.caption.prefix.fontWeight = constants.fontWeights.bold;
theme.tableFigure.headerBackground = strayerPrimary;

theme.global.webtextArticleSource.fontFamily = oswald;

theme.notifications.fontFamily = theme.fonts.app;
theme.notifications.tab.borderWidthBefore = '21px';
theme.notifications.tab.borderWidthAfter = '91px';
theme.notifications.button.unread.backgroundColor = constants.colors['pure-white'];
theme.notifications.button.unread.dotBorderColor = constants.colors['pure-white'];

theme.comments.instructor.header.title.fontSize = '19px';
theme.comments.instructor.header.title.fontWeight = '500';
theme.comments.instructor.header.title.fontFamily = theme.fonts.app;
theme.comments.instructor.header.title.marginBottom = '3px';
theme.comments.instructor.header.timestamp.fontFamily = theme.fonts.app;
theme.comments.instructor.header.reveal.fontSize = '19px';
theme.comments.instructor.header.reveal.fontFamily = theme.fonts.app;
theme.comments.instructor.comment.fontSize = '19px';
theme.comments.instructor.comment.fontFamily = theme.fonts.app;
theme.comments.instructor.comment.lineHeight = '1.526';

theme.timeline.fontFamily = roboto;
theme.timeline.media.fontFamily = roboto;
theme.timeline.slidenav.fontFamily = roboto;
theme.timeline.date.fontFamily = roboto;
theme.timeline.slider.fontFamily = roboto;
theme.timeline.heading.fontFamily = oswald;

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}1A`;

export default theme;
