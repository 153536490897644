import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

interface IShadowRootContext {
	shadowRoot: ShadowRoot | null;
}

export const ShadowRootContext = React.createContext<IShadowRootContext>({
	shadowRoot: null
});

interface Props {
	className?: string;
	mode: 'open' | 'closed';
}

let emotionCacheKey = 'a';
const getNextEmotionCacheKey = () => {
	const lastIndex = emotionCacheKey.length - 1;
	if (emotionCacheKey.charAt(lastIndex) === 'z') {
		emotionCacheKey = emotionCacheKey + 'a';
	} else {
		emotionCacheKey =
			emotionCacheKey.substring(0, lastIndex) +
			String.fromCharCode(emotionCacheKey.charAt(lastIndex).charCodeAt(0) + 1);
	}
	return emotionCacheKey;
};

/**
 * Renders this component's children in a separate shadow DOM root (to isolate from outside styles and scripts).
 * Also creates a separate Emotion cache provider for the shadow root.
 *
 * Adaptation of https://github.com/apearce/react-shadow-root.
 */
const ShadowDOMRoot: React.FC<Props> = ({ children, className, mode }) => {
	const [attached, setAttached] = useState(false);
	const mountPoint = useRef<HTMLDivElement | null>(null);
	const shadowRoot = useRef<ShadowRoot | null>(null);
	const emotionCache = useRef<EmotionCache | null>(null);

	useEffect(() => {
		if (!attached) {
			shadowRoot.current = mountPoint.current.attachShadow({ mode });
			emotionCache.current = createCache({
				key: `shadow${getNextEmotionCacheKey()}`, // each shadow DOM gets its own private Emotion cache
				container: shadowRoot.current as unknown as HTMLElement
			});
			setAttached(true);
		}
	}, [attached, mode]);

	return (
		<ShadowRootContext.Provider value={{ shadowRoot: shadowRoot.current }}>
			<div className={className} ref={mountPoint} />
			{attached &&
				createPortal(
					<CacheProvider value={emotionCache.current}>{children}</CacheProvider>,
					shadowRoot.current
				)}
		</ShadowRootContext.Provider>
	);
};

export default ShadowDOMRoot;
