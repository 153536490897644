import camelCase from 'lodash-es/camelCase';
import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';

import { IDictionary } from '../types';

export const getCitationDataFromConf = (
	toc: IDictionary,
	schema: IDictionary,
	dests: IDictionary
): IDictionary => {
	if (schema.imports) {
		const keys = Object.keys(schema.imports).filter((key) => !dests[key]);
		const values = keys
			.map((key) => [key, schema.imports[key]])
			.map(([key, value]) => [key, tryToFindInSettings(value, toc)])
			.filter(([key, value]) => !!key && !!value)
			.reduce((acc, [key, value]) => {
				return { ...acc, [key]: value };
			}, {});

		return values;
	}

	return {};
};

const tryToFindInSettings = (value: string, toc: IDictionary) => {
	const [path, to] = value.split(':');

	const userDecisions = checkInUserDecisions(toc);
	const courseDecisions = getCourseDecisions(toc, camelCase(path));

	if (!userDecisions) return;
	if (!courseDecisions) return;
	const { options } = courseDecisions[to];

	if (!options) return;

	const option = Object.values(userDecisions)
		.map((decision) => options.find((option) => Object.values(option).includes(decision)))
		.filter((v) => !!v);

	return first(option);
};

const checkInUserDecisions = (toc: IDictionary) => {
	const { userDecisions } = toc;
	if (!userDecisions) return;

	return {
		...userDecisions
	};
};

const getCourseDecisions = (toc: IDictionary, key): IDictionary => {
	if (toc && !isEmpty(toc)) return toc.config[key];
};
