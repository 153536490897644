import React, { useState } from 'react';

import cn from 'classnames';
import { DateTime } from 'luxon';

import WebtextButton from '~/components/WebtextButton/WebtextButton';
import { useIsUniversalVelvet } from '~/hooks';
import { ResponseBoardPost } from '~/types';

import PostedAnswer from './PostedAnswer';
import styles from './styles';

const COURSE_POST_PAGE_SIZE = 5;

interface Props {
	coursePosts: ResponseBoardPost[];
	showResponses: boolean;
	setShowResponses?: (boolean) => void;
	unpaginated?: boolean;
}

const PostedAnswers: React.FC<Props> = (props: Props) => {
	const { coursePosts, showResponses, setShowResponses, unpaginated } = props;

	const [coursePostsPage, setCoursePostsPage] = useState(1);
	const isUniversalVelvet = useIsUniversalVelvet();
	const endIndex = COURSE_POST_PAGE_SIZE * coursePostsPage;

	const allCoursePosts = [
		...coursePosts.filter((p) => p.isPinned),
		...coursePosts
			.filter((p) => !p.isPinned)
			.sort((a, b) =>
				DateTime.fromISO(a.postedAt) > DateTime.fromISO(b.postedAt)
					? -1
					: DateTime.fromISO(a.postedAt) < DateTime.fromISO(b.postedAt)
					  ? 1
					  : 0
			)
	];

	let paginatedCoursePosts;

	if (!unpaginated) {
		paginatedCoursePosts = allCoursePosts.slice(0, endIndex);
	}

	const hasMore = endIndex - 1 < coursePosts.length;

	const PostedAnswersList = (props: { a11y?: boolean }) => {
		const postList = props.a11y ? allCoursePosts : paginatedCoursePosts || allCoursePosts;
		return (
			<ul
				className={cn('posted-answers-list', { 'sr-only': props.a11y })}
				aria-hidden={!props.a11y}>
				{postList.map((p) => (
					<li key={p.author.name + p.postedAt} className="posted-answers-list-item">
						<PostedAnswer answer={p} />
					</li>
				))}
			</ul>
		);
	};

	return (
		<div css={styles}>
			<PostedAnswersList />
			<PostedAnswersList a11y />
			<div className="posted-answers-footer">
				{!unpaginated && hasMore && coursePosts.length > 5 && (
					<WebtextButton
						tabIndex={-1}
						aria-hidden={true}
						onClick={() => setCoursePostsPage(coursePostsPage + 1)}
						disabled={!hasMore}>
						View More Responses
					</WebtextButton>
				)}
				{setShowResponses && (
					<WebtextButton
						aria-expanded={showResponses}
						className="show-responses"
						variant={isUniversalVelvet ? 'underlined' : 'text'}
						onClick={() => {
							setShowResponses(!showResponses);
							setCoursePostsPage(1);
						}}>
						{showResponses ? `Hide responses` : `Show responses`}
					</WebtextButton>
				)}
			</div>
		</div>
	);
};

export default PostedAnswers;
