import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useDebounceCallback } from 'usehooks-ts';
import { shallow } from 'zustand/shallow';

import { isMouseEvent, isTouchEvent } from '~/types/events';
import { BatchProcessor } from '~/utils/BatchProcessor';

import ElementsContext from '../../context/ElementsContext';
import { getHeaderColumn, getHeaderRow, onHideContext } from '../../helpers';
import { getOriginAddressShift } from '../../helpers/address';
import { firefoxViewHeaderOffset } from '../../helpers/constants';
import { handleClick } from '../../helpers/interactions';
import {
	Cell as RowCell,
	CellsRowHeight,
	CellType,
	ContextMenuParams,
	HeaderCell as RowHeaderCell,
	Row
} from '../../helpers/types';
import { useExpandSelectionArea } from '../../hooks/useExpandSelectionArea';
import { useSpreadsheetSelector } from '../../store/provider';
import Cell from '../Cell';
import HeaderCell from '../HeaderCell';
import { tableRowStyle, tableSheetStyles } from './styles';

interface Props {
	rows: Row[];
	withHeader: boolean;
	spreadsheetStyles: string;
	hideAddressHeaders?: boolean;
}

const Grid: React.FC<Props> = ({
	rows: propsRows,
	withHeader = true,
	spreadsheetStyles,
	hideAddressHeaders
}) => {
	const spreadsheetBodyRef = useRef<HTMLTableElement>(null);

	const {
		dest,
		origin,
		focused,
		editingCell,
		selectedIndexes,
		cellValues,
		selectedCell,
		copyCacheKey,
		editableRanges,
		invalidCell
	} = useSpreadsheetSelector(
		(state) => ({
			dest: state.dest,
			origin: state.origin,
			focused: state.focused,
			editingCell: state.editingCell,
			selectedIndexes: state.selectedIndexes,
			cellValues: state.cellValues,
			selectedCell: state.selectedCell,
			copyCacheKey: state.copyCacheKey,
			editableRanges: state.editableRanges,
			invalidCell: state.invalidCell
		}),
		shallow
	);

	const {
		setFocus,
		setSelectedCells,
		setSelectedCell,
		setEditingCell,
		setEnterWithString,
		updateCellsValues,
		updateContextProps,
		updateAnnouncementSR,
		setActiveAreaId,
		setShowErrorFor,
		updateCellHeight
	} = useSpreadsheetSelector(
		(state) => ({
			setFocus: state.setFocus,
			setSelectedCells: state.setSelectedCells,
			setSelectedCell: state.setSelectedCell,
			setEditingCell: state.setEditingCell,
			setEnterWithString: state.setEnterWithString,
			updateCellsValues: state.updateCellValues,
			updateContextProps: state.updateContextProps,
			updateAnnouncementSR: state.updateAnnouncementSR,
			setActiveAreaId: state.setActiveAreaId,
			setShowErrorFor: state.setShowErrorFor,
			updateCellHeight: state.updateCellHeight
		}),
		shallow
	);

	const { cellsRefs, formatting, spreadsheetWrapperRef } = useContext(ElementsContext);

	const showErrorPanel = useCallback(
		(address: string, message?: string) => setShowErrorFor(address, message),
		[setShowErrorFor]
	);
	const debouncedShowErrorPanel = useDebounceCallback(showErrorPanel);

	const showContextMenu = useCallback(
		(contextMenuProps: ContextMenuParams) => updateContextProps(contextMenuProps),
		[updateContextProps]
	);
	const debouncedShowContextMenu = useDebounceCallback(showContextMenu);

	useEffect(() => {
		/**
		 * Once user leaves edit mode for cell, we're returning focus on the ST canvas
		 * scroll effect on focus creates undesired jumps here.
		 */
		if (!editingCell && focused) spreadsheetBodyRef?.current?.focus({ preventScroll: true });
	}, [editingCell, focused]);

	const cellRowHeightsBatch = useMemo(
		() => new BatchProcessor<CellsRowHeight>((collection) => updateCellHeight?.(collection)),
		[updateCellHeight]
	);

	const tableRows = useMemo(() => {
		const [horShift, verShift] = getOriginAddressShift(origin);

		const rows = withHeader ? [getHeaderRow(propsRows, horShift), ...propsRows] : propsRows;

		return rows.map((row) => {
			const cells = withHeader ? getHeaderColumn(row, verShift) : row.cells;

			return (
				<tr css={tableRowStyle} key={row.rowId}>
					{(cells as (RowCell | RowHeaderCell)[]).map((cell) => {
						const { type } = cell;

						if (type === CellType.Header) {
							/**
							 * Values for header cells were created dynamically, in place with getHeaderRow or getHeaderColumn
							 */
							const { value } = cell as RowHeaderCell;
							return <HeaderCell key={value} value={value} />;
						} else {
							const rowCell = cell as RowCell;
							const { address } = rowCell;

							return (
								<Cell
									key={address}
									cell={rowCell}
									callbacks={{ debouncedShowErrorPanel, debouncedShowContextMenu }}
									cellRowHeightsBatch={cellRowHeightsBatch}
								/>
							);
						}
					})}
				</tr>
			);
		});
	}, [
		propsRows,
		origin,
		withHeader,
		cellRowHeightsBatch,
		debouncedShowErrorPanel,
		debouncedShowContextMenu
	]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTableElement>) => {
		if (editingCell) return;
		handleClick({
			dest,
			origin,
			event,
			cellsRefs,
			setSelectedCells,
			setEditingCell,
			setEnterWithString,
			selectedCell,
			selectedIndexes,
			cellValues,
			formatting,
			updateCellsValues,
			setSelectedCell,
			copyCacheKey,
			editableRanges,
			updateAnnouncementSR,
			setActiveAreaId,
			spreadsheetWrapperRef,
			updateContextPropsWithParams
		});
	};

	const handleMouseUp = (event: React.MouseEvent<HTMLTableElement, MouseEvent | TouchEvent>) => {
		const { current: wrapperElement } = spreadsheetWrapperRef;
		if (!wrapperElement) return;

		if (event.button === 2 && (event.target as Element).tagName !== 'TEXTAREA') {
			const content = document.getElementById('content');
			const diffY = content ? firefoxViewHeaderOffset : 0; // difference because of fixed header on each page

			let mouseX;
			let mouseY;
			if (isMouseEvent(event)) {
				mouseX = event.clientX;
				mouseY = event.clientY - diffY;
			} else if (isTouchEvent(event)) {
				mouseX = event.touches[0].clientX;
				mouseY = event.touches[0].clientY - diffY;
			}
			if (mouseX === undefined || mouseY === undefined) return;
			updateContextPropsWithParams({ mouseX, mouseY, accessBy: 'keyboard' }, wrapperElement);
		}
	};

	const updateContextPropsWithParams = (
		params: ContextMenuParams,
		wrapperElement: HTMLDivElement
	) => {
		if (!params) return updateContextProps(null);

		const { mouseX, mouseY } = params;
		const contextMenuParams = { width: 180, height: 100 }; // this is always static;
		const diffX = 20; //view shifted to 20px?;

		const { left: scopeOffsetX } = wrapperElement.getBoundingClientRect();
		const scopeX = mouseX - scopeOffsetX;
		const outOfBoundsOnX = scopeX + contextMenuParams.width > wrapperElement.clientWidth;

		const normalizedX = outOfBoundsOnX
			? scopeOffsetX + wrapperElement.clientWidth - contextMenuParams.width + diffX
			: mouseX;

		updateContextProps({ mouseX: normalizedX, mouseY: mouseY, accessBy: params.accessBy });
	};

	const handleMouseDown = () => {
		if (focused) return;

		spreadsheetBodyRef.current.focus({ preventScroll: true });
		setFocus(true);
	};

	const handleKeyUp = () => {
		if (!focused && invalidCell && invalidCell !== selectedCell) {
			setSelectedCells([]);
			setSelectedCell(invalidCell);
			setFocus(true);
			return;
		}

		if (!focused && !selectedCell) {
			setSelectedCell(origin);
		}

		setFocus(true);
	};

	const selectableAreasStyles = useExpandSelectionArea();

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<table
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}
			role="application"
			aria-roledescription="Spreadsheet Application"
			ref={spreadsheetBodyRef}
			cellPadding={0}
			cellSpacing={0}
			onKeyUp={handleKeyUp}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onKeyDown={handleKeyDown}
			css={(theme) => [
				spreadsheetStyles,
				selectableAreasStyles,
				tableSheetStyles(theme, { hideAddressHeaders })
			]}
			onContextMenu={onHideContext}>
			<tbody>{tableRows}</tbody>
		</table>
	);
};

export default Grid;
