import React from 'react';

import { css } from '@emotion/react';
import { DateTime } from 'luxon';

import { AnswerStatus, QuestionType } from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import Selector from '~/components/WritingTemplate/Selector';
import { Theme } from '~/styles/themes';

const styles = (theme) => {
	return css`
		padding: 30px;
		margin: 30px 0;
		background: #f7f7f7;
		font-family: ${theme.fonts.app};
		font-size: 16px;
		line-height: 1.6;
		color: ${theme.colors.text};

		.prompt {
			padding: 10px 10px 10px 0;
		}

		.dropdown-select {
			display: inline-block;
			width: 250px;
			height: calc(2.25rem + 2px);
			padding: 0.375rem 0.75rem;
			margin: 0;
			font-size: 1rem;
			line-height: 1.5;
			text-transform: none;
			background-clip: padding-box;
			background-color: #757575;
			background-image: none;
			border-width: 1px;
			border-style: solid;
			border-color: #ced4da;
			border-radius: 0.25rem;
			transition:
				border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;
			box-shadow: none;
			color: #fefefe;
			margin-bottom: 20px;

			&:focus {
				outline: 0;
				border-color: #80bdff;
			}
		}

		.controls {
			display: flex;
			justify-content: space-between;
			text-align: right;
			margin-top: 16px;
		}

		.last-saved-message {
			outline: none;
			padding: 10px 10px 0 0;
			margin-top: 10px;
			text-align: right;
			font-size: 16px;
		}
	`;
};

export interface Decision {
	family_id: string;
	prompt: string;
	options: { key: string; name: string }[];
}

export interface DecisionApi {
	load: (decisionFamilyId: string) => Promise<{ userDecision: string; lastSavedAt: string }>;
	save: (decisionFamilyId: string, userDecision: string) => Promise<void>;
	reset: (decisionFamilyId: string) => Promise<void>;
}

export interface Props {
	theme: Theme;
	decision: Decision;
	api: DecisionApi;
	elementLabel?: string;
	confirmReset?: () => Promise<boolean>;
	resettable?: boolean;
	readOnly?: boolean;
}

interface State {
	loaded: boolean;
	userDecision: string;
	decisionMade: boolean;
	lastSavedAt: string;
	justSaved: boolean;
	justReset: boolean;
	resettingMayDeleteWork: boolean;
}

class DecisionPoint extends React.Component<Props, State> {
	selectRef: React.RefObject<HTMLSelectElement>;
	lastSavedMessageRef: React.RefObject<HTMLSpanElement>;

	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			userDecision: null,
			decisionMade: false,
			lastSavedAt: null,
			justSaved: false,
			justReset: false,
			resettingMayDeleteWork: false
		};

		this.selectRef = React.createRef();
		this.lastSavedMessageRef = React.createRef();
	}

	componentDidMount() {
		this.props.api.load(this.props.decision.family_id).then(({ userDecision, lastSavedAt }) => {
			const decisionMade = userDecision != null;
			this.setState({
				loaded: true,
				userDecision: userDecision || '',
				decisionMade: decisionMade,
				lastSavedAt: lastSavedAt || null,

				/*
				   If a decision has been made when this component mounts, then
				   it is assumed that the student made the decision at some
				   arbitrary time in the past. Therefore, the component will
				   indicate to the student that they may be deleting work.
				   However, if the decision hasn't been made yet (on initial
				   render), then it's unlikely that they will be deleting work
				   if they choose to reset.
				*/
				resettingMayDeleteWork: decisionMade
			});
		});
	}

	componentDidUpdate() {
		if (this.state.justSaved && this.lastSavedMessageRef.current) {
			this.lastSavedMessageRef.current.focus();
			this.setState({ justSaved: false });
		} else if (this.state.justReset && this.selectRef.current) {
			this.selectRef.current.focus();
			this.setState({ justReset: false });
		}
	}

	onDecisionChange = (value) => {
		this.setState({ userDecision: value });
	};

	onSaveDecision = (event) => {
		event.preventDefault();
		this.props.api.save(this.props.decision.family_id, this.state.userDecision).then(() => {
			this.setState({ decisionMade: true, lastSavedAt: DateTime.local().toISO(), justSaved: true });
		});
	};

	onResetDecision = (event) => {
		event.preventDefault();
		if (this.props.confirmReset) {
			this.props.confirmReset().then((confirmed) => {
				if (confirmed) this.onResetDecisionConfirmed();
			});
		} else {
			this.onResetDecisionConfirmed();
		}
	};

	onResetDecisionConfirmed = () => {
		this.props.api.reset(this.props.decision.family_id).then(() => {
			this.setState({ decisionMade: false, lastSavedAt: null, justReset: true });
		});
	};

	render() {
		const { decision, resettable = true, readOnly, elementLabel } = this.props;
		const { loaded, userDecision, decisionMade, lastSavedAt, resettingMayDeleteWork } = this.state;

		if (!loaded) return null;

		return (
			<div css={styles} id={decision.family_id}>
				<QuestionType>{elementLabel || 'Decision Point'}</QuestionType>
				<form>
					<div className="prompt readable">
						<label htmlFor={`select-${decision.family_id}`}>{decision.prompt}</label>
					</div>
					<Selector
						id={`select-${decision.family_id}`}
						onChange={this.onDecisionChange}
						select={userDecision || ''}
						choices={decision.options}
						ariaLabel={decision.prompt}
						readOnly={decisionMade || readOnly}
						ref={this.selectRef}
						placeholder={!userDecision ? 'Make a selection' : undefined}
					/>

					<div className="controls">
						{lastSavedAt != null ? (
							<span className="last-saved-message" tabIndex={-1} ref={this.lastSavedMessageRef}>
								<AnswerStatus
									updatedAt={lastSavedAt}
									posting={false}
									saving={false}
									unposting={false}
									suppressAria
								/>
							</span>
						) : (
							<span />
						)}
						{!decisionMade && (
							<WebtextButton onClick={this.onSaveDecision} disabled={!userDecision || readOnly}>
								Save
							</WebtextButton>
						)}
						{decisionMade && resettable && (
							<WebtextButton onClick={this.onResetDecision} disabled={readOnly}>
								{resettingMayDeleteWork ? `Delete & Reset` : `Reset`}
							</WebtextButton>
						)}
					</div>
				</form>
			</div>
		);
	}
}

export default DecisionPoint;
