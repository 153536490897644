import { useContext } from 'react';

import isEqual from 'lodash-es/isEqual';

import { OutlineInstanceAddress } from '~/components/Outline/types';
import { TemplateValidationContext } from '~/components/WritingTemplate/WritingTemplate/providers/TemplateValidationProvider';
import {
	isOutlineInstanceTarget,
	ValidationStatus
} from '~/components/WritingTemplate/WritingTemplate/types';

/**
 * Retrieves the error message for the instance from the `TemplateValidationProvider`
 */
export const useValidationProviderErrorMessage = (
	dest: string,
	address: OutlineInstanceAddress
): string | undefined => {
	const { validationState } = useContext(TemplateValidationContext);
	if (validationState?.status !== ValidationStatus.Failure) return;

	const { validations } = validationState;
	const destValidation = validations[dest];
	if (!destValidation) return;

	const { target, message } = destValidation;
	if (!isOutlineInstanceTarget(target)) return;

	const { address: targetAddress } = target;
	if (!isEqual(address, targetAddress)) return;

	return message;
};
