import { axios } from '~/utils';

export const builderAnswer = (courseId, chapterId, pageId, builderId) =>
	axios.get(
		`/courses/${courseId}/traditional_book/chapters/${chapterId}/pages/${pageId}/builder_responses/answer?builder_id=${builderId}`
	);

export const submitVoiceRecordingToLMS = (
	courseId,
	chapterId,
	pageId,
	questionFamilyId,
	resourcesToSubmit
) =>
	axios.post(
		`/courses/${courseId}/traditional_book/chapters/${chapterId}/pages/${pageId}/voice_recordings/submit_answer_files_to_lms?question_family_id=${questionFamilyId}`,
		{ resourcesToSubmit: resourcesToSubmit }
	);

export const getVoiceRecordingAnswer = (courseId, chapterId, pageId, questionFamilyId) =>
	axios.get(
		`/courses/${courseId}/traditional_book/chapters/${chapterId}/pages/${pageId}/voice_recordings/answer?question_family_id=${questionFamilyId}`
	);

export const cancelLMSSubmission = (courseId, chapterId, pageId, answerId) =>
	axios.post(
		`/courses/${courseId}/traditional_book/chapters/${chapterId}/pages/${pageId}/user_background_jobs/cancel?answer_id=${answerId}`
	);
