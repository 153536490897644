import { css } from '@emotion/react';

export default (theme) => css`
	background-color: #fff;
	border-collapse: collapse;
	box-sizing: border-box;
	font-size: 14px;
	font-family: 'Helvetica Neue', sans-serif;

	td {
		border: 1px solid #dad4d4;
		min-height: 30px;
	}

	margin: 20px 0;
`;

export const hide = css`
	border: none !important;
`;

export const header = css`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
`;

export const sideHeader = css`
	border-right: none !important;
	height: 100%;
	width: 30px;

	span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const cell = (isAccounting: boolean) => css`
	padding: 0 8px;
	line-height: 1.5;
	min-height: 30px;
	display: flex;
	align-items: center;

	${isAccounting && accounting}
`;

const accounting = css`
	&::before {
		content: '$';
		font-weight: normal !important;
		font-style: normal !important;
		position: absolute;
		left: 5px;
	}

	padding-left: 20px;
`;
