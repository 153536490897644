import React from 'react';
import { FaCheck, FaBan, FaStar } from 'react-icons/fa';

import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import WebtextExampleStyles from './styles';
import { Props as TextProps } from '../../Text';
import { checkForSpecialCase } from '../../utils';

interface Props {
	element: Element;
	textProps: TextProps;
}

export type WebtextExampleDecorationState = 'right' | 'wrong' | 'star' | '';

/**
 * The content provided to this component is wrapped in `webtext-example-wrapper` because of
 * previous handling of this element.
 *
 * See https://github.com/soomo/soomo/blob/a40bd4c0d7ed6137ac6f08d03d9dcf9a5671bd2c/app/assets/javascripts/webtexts/page.coffee#L141
 */
const WebtextExample: React.FC<Props> = (props) => {
	const { element, textProps } = props;

	let iconName: WebtextExampleDecorationState = '';

	if (element.attribs?.class?.match(/(right|wrong|star)/g)) {
		iconName = element.attribs.class.match(
			/(right|wrong|star)/g
		)[0] as WebtextExampleDecorationState;
	}

	const renderIcon = (iconName: string) => {
		if (iconName === 'right') {
			return <FaCheck role="img" aria-label="green, checkmark icon" />;
		} else if (iconName === 'wrong') {
			return <FaBan role="img" aria-label="red, cross-out icon" />;
		} else if (iconName === 'star') {
			return <FaStar role="img" aria-label="star icon" />;
		}
	};

	const content = domToReact([element], {
		replace: (domNode: Element) => checkForSpecialCase(domNode, textProps)
	});

	return (
		<div css={WebtextExampleStyles}>
			{iconName && (
				<div className={`webtext-example-icon-wrapper ${iconName}`}>{renderIcon(iconName)}</div>
			)}
			<div className="webtext-example-wrapper">{content}</div>
		</div>
	);
};

export default WebtextExample;
