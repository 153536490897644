import * as React from 'react';

export function Lightbulb(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<path d="M36 15.87a.85.85 0 0 1-.85-.85V7.85a.85.85 0 0 1 1.7 0V15a.85.85 0 0 1-.85.87ZM23 20.05a.85.85 0 0 1-.65-.3l-4.61-5.49a.87.87 0 0 1 .11-1.21.85.85 0 0 1 1.2.11l4.61 5.49a.85.85 0 0 1-.11 1.2.87.87 0 0 1-.55.2ZM14.94 32.25h-.15L7.73 31A.85.85 0 0 1 8 29.31l7.05 1.24a.86.86 0 0 1-.14 1.7ZM10.41 50.34a.85.85 0 0 1-.41-1.59l6.21-3.58a.85.85 0 0 1 1.17.31.86.86 0 0 1-.38 1.17l-6.2 3.58a.84.84 0 0 1-.39.11ZM49 20.05a.87.87 0 0 1-.55-.2.85.85 0 0 1-.11-1.2L53 13.16a.85.85 0 0 1 1.2-.11.87.87 0 0 1 .11 1.21l-4.61 5.49a.85.85 0 0 1-.7.3ZM57.06 32.25a.86.86 0 0 1-.14-1.7l7-1.24a.85.85 0 1 1 .3 1.68l-7.06 1.25ZM61.59 50.34a.84.84 0 0 1-.43-.11L55 46.65a.86.86 0 0 1-.32-1.17.85.85 0 0 1 1.17-.31L62 48.75a.85.85 0 0 1-.43 1.59ZM38.31 65H33a.85.85 0 0 1-.55-.2l-4-3.36a.83.83 0 0 1-.31-.66v-3.87a.85.85 0 0 1 .85-.85h13.4a.85.85 0 0 1 .85.85v3.89a.85.85 0 0 1-.3.65l-4 3.35a.87.87 0 0 1-.63.2Zm-5-1.71H38l3.49-2.89v-2.63h-11.7v2.61ZM43.08 54.38H28.92a.86.86 0 0 1-.85-.86V50a7.41 7.41 0 0 0-2.5-2.86c-2.66-2-5.35-6.59-5.35-11.69a15.43 15.43 0 0 1 15.65-15.24h.19a15.44 15.44 0 0 1 15.72 15.27c0 5.1-2.69 9.74-5.35 11.69a7.27 7.27 0 0 0-2.5 2.83v3.49a.86.86 0 0 1-.85.89Zm-13.3-1.71h12.44v-2.8a1.18 1.18 0 0 1 0-.25 8.43 8.43 0 0 1 3.16-3.83c2.25-1.65 4.65-5.82 4.65-10.31s-3.43-13.5-14-13.56H36c-10.59.06-14 9.16-14 13.56s2.4 8.66 4.65 10.31a8.43 8.43 0 0 1 3.16 3.83 1.18 1.18 0 0 1 0 .25Z" />
			<path d="M28.94 59.93h13.4v1.71h-13.4zM46.81 36.08a.85.85 0 0 1-.86-.86 11.37 11.37 0 0 0-5-8.33.86.86 0 0 1 .91-1.45c3.56 2.24 5.76 6.82 5.76 9.78a.85.85 0 0 1-.81.86Z" />
		</svg>
	);
}
