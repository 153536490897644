import React, { useEffect, useRef, useState } from 'react';

import { ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isRangeSelection,
	FORMAT_TEXT_COMMAND,
	TextFormatType,
	COMMAND_PRIORITY_LOW,
	$getRoot,
	ParagraphNode,
	$getCharacterOffsets
} from 'lexical';

import { accessibleAnnouncerStyles } from '../styles';

interface Props {
	isListsEnabled: boolean;
}

export const AccessibleAnnouncerPlugin: React.FC<Props> = ({ isListsEnabled }) => {
	const [editor] = useLexicalComposerContext();
	const [announceValue, setAnnounce] = useState('');
	const listNodeIds = useRef<{ [key: string]: string[] }>({});

	const updateAnnounceValue = (text: string) => {
		/**
		 * If previous value is the same to current it will not be announce
		 * We have to add a space at the end in this case
		 */
		setAnnounce((prevAnnounce) => (prevAnnounce === text ? `${text} ` : text));
		/**
		 * We're removing any values exact after announce to make dom clear and do not allow
		 * SR target any of them
		 */
		setTimeout(() => {
			setAnnounce('');
		}, 1000);
	};

	useEffect(() => {
		const getChildNodeIds = (node: ParagraphNode | ListNode): string[] =>
			node.getChildren().map((childNode) => childNode.getKey());
		let removeMergeRegisterLists;

		const removeCommandRegister = editor.registerCommand(
			FORMAT_TEXT_COMMAND,
			(payload: TextFormatType) => {
				const selection = $getSelection();

				if (!$isRangeSelection(selection)) return false;

				const [start, end] = $getCharacterOffsets(selection);
				const isRange = Math.max(start, end) - Math.min(start, end) > 0;

				if (!selection.hasFormat(payload)) {
					updateAnnounceValue(`${payload} applied ${isRange ? 'to selection' : ''}`);
				} else {
					updateAnnounceValue(`${payload} removed ${isRange ? 'from selection' : ''}`);
				}

				return false;
			},
			COMMAND_PRIORITY_LOW
		);

		if (isListsEnabled) {
			removeMergeRegisterLists = mergeRegister(
				editor.registerNodeTransform(ListNode, (node) => {
					const nodeKey = node.getKey();
					const transformedNodes = getChildNodeIds(node);
					const bulletsNumber = transformedNodes.length;

					const updateCurrentListNodes = () =>
						(listNodeIds.current = {
							...listNodeIds.current,
							[nodeKey]: transformedNodes
						});

					if (!Object.keys(listNodeIds.current).includes(nodeKey)) {
						/**
						 * Newly create list
						 */
						updateAnnounceValue(`Create bullet list with ${Math.max(1, bulletsNumber)} item(s)`);
						updateCurrentListNodes();
						return;
					}

					const prevListNodes = listNodeIds.current[nodeKey];

					if (transformedNodes.some((nodeId) => !prevListNodes.includes(nodeId))) {
						/**
						 * New list items inserted
						 */
						updateCurrentListNodes();
						return;
					}
					/**
					 * New nodes list was reduced = bullet removed
					 */
					updateAnnounceValue(`Bullet(s) removed`);
					updateCurrentListNodes();
				}),
				editor.registerNodeTransform(ParagraphNode, () => {
					const rootNodesKeys = $getRoot()
						.getChildren()
						.map((node) => node.getKey());

					const listKeys = Object.keys(listNodeIds.current);

					if (!listKeys.some((key) => !rootNodesKeys.includes(key))) {
						return;
					}
					/**
					 * List was removed at all
					 */
					updateAnnounceValue(`Bullet removed`);
					listKeys
						.filter((key) => !rootNodesKeys.includes(key))
						.forEach((key) => {
							const { [key]: _, ...rest } = listNodeIds.current;
							listNodeIds.current = rest;
						});
				})
			);
		}

		return () => {
			removeCommandRegister();
			removeMergeRegisterLists?.();
		};
	}, [editor, isListsEnabled]);

	return (
		<div aria-live="assertive" role="log" aria-hidden="false" css={accessibleAnnouncerStyles}>
			{announceValue}
		</div>
	);
};
