import { SerializedStyles, css } from '@emotion/react';

import { linkStyles } from '~/components/pageElements/Text/styles';
import { Theme, ThemeName } from '~/styles/themes';

export const paragraphStyles = (theme: Theme): SerializedStyles => css`
	margin: 0;
	line-height: 1.5;
	font-size: 18px !important;

	a {
		${linkStyles(theme)}

		${theme.name !== ThemeName.UNIVERSAL_VELVET &&
		css`
			color: ${theme.colors['pure-white']};
		`}
	}
`;
