import { css, SerializedStyles } from '@emotion/react';

import { tableResponsiveStyles } from '~/components/pageElements/TableFigure/styles';
import { breakpoints, fonts, Theme, ThemeName } from '~/styles/themes';

import { Kinds } from '../WritingTemplate/types';

enum Alignment {
	Left = 'left',
	Right = 'right',
	Center = 'center',
	Top = 'top',
	Bottom = 'bottom',
	Middle = 'middle'
}

const getFlexAlignment = (al?: Alignment) => {
	switch (al) {
		case Alignment.Left:
			return `justify-content: flex-start !important;
			text-align: left;`;
		case Alignment.Right:
			return `justify-content: flex-end !important;
			text-align: right;`;
		case Alignment.Center:
			return `justify-content: center !important;
			text-align: center;`;
		case Alignment.Top:
			return 'align-items: flex-start !important;';
		case Alignment.Bottom:
			return 'align-items: flex-end !important;';
		case Alignment.Middle:
			return 'align-items: center !important;';
		default:
			return null;
	}
};

export const columnWidthStyle = (theme, kind?: string, isOutput?: boolean): SerializedStyles => {
	return css`
		${(kind === Kinds.Text || kind === Kinds.ValueOf) &&
		theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			padding-top: 1em !important;
			padding-bottom: 1em !important;
			padding-left: 0.5em;
		`}

		${isOutput &&
		css`
			padding: 1em;
		`}
	`;
};

export const tableContainerStyle = css`
	overflow: auto;
`;

export const tableStyle = (
	theme: Theme,
	options: { isEqual: boolean; expandNarrowTable: boolean }
): SerializedStyles => css`
	box-sizing: border-box;
	position: relative;

	width: 100%;
	height: 100% !important;

	font-family: ${fonts['haas-grotesk']} !important;

	border-spacing: 5px;
	border-collapse: collapse;

	th {
		text-align: start;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET ? UVoutputBorders() : outputBorders()}

	${tableResponsiveStyles(theme, options)};
`;

const UVoutputBorders = () => css`
	tr {
		border-bottom: 1px solid #cccccc;
	}

	tbody tr:last-of-type {
		border-bottom: none;
	}

	td,
	th {
		border-right: 1px solid #cccccc;
		padding: 1em;

		@media (max-width: ${breakpoints.small}) {
			font-size: 16px !important;
			padding: 0.5em !important;
		}
	}

	td:first-of-type,
	th:first-of-type {
		padding-left: 0;
	}

	td:last-of-type,
	th:last-of-type {
		border-right: none;
		padding-right: 0;
		padding-left: 1em;
	}
`;

const outputBorders = () => css`
	tr {
		border-bottom: 1px solid #cccccc;
	}

	tbody tr:last-of-type {
		border-bottom: none;
	}

	td,
	th {
		border-right: 1px solid #cccccc;
	}

	td,
	th {
		padding: 0.75em;
	}

	td:last-of-type,
	th:last-of-type {
		padding-right: 0;
		padding-left: 0.75em !important;
		border-right: none;
	}

	td:first-of-type,
	th:first-of-type {
		padding-left: 0;
	}
`;

export const rowStyle = (): SerializedStyles => css`
	position: relative;
`;

export const tableHeadStyles = (): SerializedStyles => css`
	${rowStyle()}
	border-top: none;
`;

export const tableCellStyle = (
	theme,
	horAlignment?: Alignment,
	verAlignment?: Alignment,
	isOutput?: boolean
): SerializedStyles => {
	const fontFamily = theme.fonts
		? theme.fonts.app
		: '"HelveticaNeueW01-55Roma",Helvetica,Arial,sans-serif';

	return css`
		height: 100% !important;
		font-weight: normal;
		display: flex;
		font-family: ${fontFamily} !important;
		line-height: 1.5em !important;
		${getFlexAlignment(horAlignment)};
		${getFlexAlignment(verAlignment)};

		@media (max-width: ${breakpoints.small}) {
			font-size: 16px !important;
		}

		@media (max-width: ${breakpoints.extraSmall}) {
			font-size: 15px !important;
		}

		${!verAlignment && !isOutput ? 'align-items: center !important;' : ''}

		span {
			line-height: ${theme.name !== ThemeName.UNIVERSAL_VELVET ? '1.4em' : '1.5em'}!important;
		}
	`;
};
