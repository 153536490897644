import React, { useMemo } from 'react';

import { ClassNames } from '@emotion/react';

interface Props {
	primarySourceTitle: string;
	nestingInstructionClasses: string[];
}

const PrimarySourceWrapper: React.FC<Props> = (props) => {
	const { primarySourceTitle, nestingInstructionClasses, children } = props;

	const ariaLabel = useMemo(() => {
		const isContinuation =
			nestingInstructionClasses.includes('continuation-without-ending') ||
			nestingInstructionClasses.includes('continuation-with-ending');

		return isContinuation ? `Continued: ${primarySourceTitle}` : primarySourceTitle;
	}, [primarySourceTitle, nestingInstructionClasses]);

	return (
		<ClassNames>
			{({ cx }) => (
				<article aria-label={ariaLabel} className={cx('nested-content', nestingInstructionClasses)}>
					{children}
				</article>
			)}
		</ClassNames>
	);
};

export default PrimarySourceWrapper;
