import React from 'react';

import { css } from '@emotion/react';
import cn from 'classnames';

import { breakpoints, ThemeName } from '~/styles/themes';

import DimensionScoreBar from './DimensionScoreBar';
import DimensionScoreInterpretation from './DimensionScoreInterpretation';
import TotalScoreInterpretation from './TotalScoreInterpretation';
import { PopupQuiz, PopupQuizResults } from './types';

interface Props {
	popupQuiz: PopupQuiz;
	results: PopupQuizResults;
}

const MultiDimensionalResults: React.FC<Props> = (props) => {
	const { popupQuiz, results } = props;

	let showSeparator = true;
	let showInterpretations = false;

	if (popupQuiz.ui.results) {
		if (popupQuiz.ui.results.bars) {
			showSeparator = popupQuiz.ui.results.bars.showSeparator !== false;
		}
		showInterpretations = popupQuiz.ui.results.showDimensionInterpretations === true;
	}

	const totalScore = Object.values(results.scores).reduce((sum, score) => sum + score, 0);
	const totalScoreBasis = Object.values(results.maxScores).reduce((sum, score) => sum + score, 0);
	const totalScoreAsPercentage = (totalScore / totalScoreBasis) * 100;

	const showTotalScoreBar = popupQuiz.ui.results.showTotalScoreBar === true;
	const showTotalScoreIntepretationAfterTotalScoreBar =
		popupQuiz.ui.results.showTotalScoreIntepretationAfterTotalScoreBar === true;

	const singleDimension = popupQuiz.dimensions.length === 1;
	const dimensionsWithScores = getSortedDimensionsWithScores(popupQuiz, results);

	return (
		<div css={styles}>
			<ul className={cn('dimension-results-list', { 'single-dimension': singleDimension })}>
				{dimensionsWithScores.map(({ dimension, score, scoreAsPercentage, basis }) => {
					return (
						<li
							className={cn('dimension-results', { 'single-dimension': singleDimension })}
							data-show-separator={showSeparator}
							key={dimension.key}>
							<DimensionScoreBar
								popupQuiz={popupQuiz}
								dimension={dimension}
								score={score}
								scoreAsPercentage={scoreAsPercentage}
								basis={basis}
							/>
						</li>
					);
				})}
			</ul>
			{showTotalScoreBar && (
				<>
					<ul className="dimension-results-list all-dimensions">
						<li className="dimension-results" key={0}>
							<DimensionScoreBar
								popupQuiz={popupQuiz}
								dimension={{
									key: '',
									name: popupQuiz.ui.labels.total_score,
									desc: ''
								}}
								score={totalScore}
								scoreAsPercentage={totalScoreAsPercentage}
								basis={totalScoreBasis}
							/>
						</li>
					</ul>
				</>
			)}
			{showTotalScoreIntepretationAfterTotalScoreBar && (
				<div className="total-score-interpretation">
					<TotalScoreInterpretation popupQuiz={popupQuiz} results={results} />
				</div>
			)}
			{showInterpretations && (
				<ul
					className={cn('dimension-interpretations-list', { 'single-dimension': singleDimension })}>
					{dimensionsWithScores.map(({ dimension, topLineResult, explanation }) => (
						<li className="dimension-interpretation" key={dimension.key}>
							<DimensionScoreInterpretation
								popupQuiz={popupQuiz}
								dimension={dimension}
								topLineResult={topLineResult}
								explanation={explanation}
								singleDimension={singleDimension}
							/>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

const styles = (theme) => {
	const baseStyles = css`
		.dimension-results-list {
			list-style: none;
			margin: 1em 1.4em 0;
			padding: 0;

			@media (min-width: ${breakpoints.small}) {
				width: 80%;
			}

			&.single-dimension {
				margin: 0;
			}

			&.all-dimensions {
				border-top: 1px solid ${theme.colors['off-white']};
				padding: 2em 0 1em;
				margin-top: 2em;
			}
		}

		.dimension-results {
			padding: 0.3em 0;

			font-size: 16px;
			line-height: 1.2;

			&.single-dimension {
				padding: 0;
				margin: 0;
			}
		}

		.dimension-results[data-show-separator='true'] {
			padding: 1em 0;
			border-bottom: 1px solid ${theme.colors['off-white']};

			&:last-of-type {
				@media (min-width: ${breakpoints.small}) {
					border-bottom: 0;
				}
			}
		}

		.total-score-interpretation {
			margin: 1em 0;
		}

		.dimension-interpretations-list {
			list-style: none;
			margin: 1em 1.4em 0;
			padding: 0;

			@media (min-width: ${breakpoints.small}) {
				width: 80%;
			}

			&.single-dimension {
				margin: 0;

				.dimension-interpretation {
					border-bottom: 0;

					&:last-of-type {
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		.dimension-interpretation {
			padding: 1em 0;
			border-bottom: 1px solid ${theme.colors['off-white']};

			font-size: 16px;
			line-height: 1.2;

			&:last-of-type {
				@media (min-width: ${breakpoints.small}) {
					border-bottom: 0;
				}
			}

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				padding: 1em 0 2em 0;
				margin-bottom: 1.2em;

				&:last-of-type {
					border-bottom: 0;

					@media (max-width: ${breakpoints.small}) {
						margin-bottom: 0;
						padding-bottom: 4px;
					}
				}
			`}
		}
	`;

	const styles = [baseStyles];

	if (theme.name === ThemeName.UNIVERSAL_VELVET) {
		const uvStyles = css`
			padding-left: 0;
			margin-left: 0;
			.dimension-results-list {
				margin-left: 0;
				margin-top: 0;
				width: 100%;
				max-width: 560px;
				li.dimension-results {
					margin-bottom: 1em;
				}
			}

			.dimension-interpretations-list {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}
		`;

		styles.push(uvStyles);
	}

	return styles;
};

export const getSortedDimensionsWithScores = (popupQuiz: PopupQuiz, results: PopupQuizResults) => {
	const { dimensions } = popupQuiz;

	let sortOrder = 'score.desc';
	if (popupQuiz.ui && popupQuiz.ui.results && popupQuiz.ui.results.dimensionSortOrder != null) {
		sortOrder = popupQuiz.ui.results.dimensionSortOrder;
	}

	let dimensionsWithScores = dimensions.map((dimension) => {
		const score = results.scores[dimension.key];
		const scoreAsPercentage = results.scoresAsPercentages[dimension.key];
		const basis = results.maxScores[dimension.key];
		const { topLineResult, explanation } =
			results.dimensionScoreInterpretations[dimension.key] || {};

		return {
			dimension,
			score,
			scoreAsPercentage,
			basis,
			topLineResult,
			explanation
		};
	});

	if (sortOrder !== 'default') {
		if (sortOrder === 'score.desc') {
			dimensionsWithScores = dimensionsWithScores.sort((a, b) => {
				// sort by scoreAsPercentage, highest first
				if (a.scoreAsPercentage > b.scoreAsPercentage) return -1;
				if (a.scoreAsPercentage < b.scoreAsPercentage) return 1;

				// then by order of dimensions
				const idxA = dimensions.indexOf(a.dimension);
				const idxB = dimensions.indexOf(b.dimension);
				return idxA < idxB ? -1 : 1;
			});
		} else {
			throw new Error(`Unrecognized dimension sort order: ${sortOrder}`);
		}
	}

	return dimensionsWithScores;
};

export default MultiDimensionalResults;
