import * as React from 'react';

export function WritingToolbox(): JSX.Element {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 72 72"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1747.900000, -1039.800000)" fillRule="nonzero">
					<g>
						<g transform="translate(1748.500000, 1040.400000)">
							<circle fill="#FFFFFF" cx="35.4" cy="35.4" r="34"></circle>
							<path
								d="M35.4,70.8 C15.9,70.8 -1.44328993e-15,54.9 -1.44328993e-15,35.4 C-1.44328993e-15,15.9 15.9,-1.44328993e-15 35.4,-1.44328993e-15 C54.9,-1.44328993e-15 70.8,15.9 70.8,35.4 C70.8,54.9 54.9,70.8 35.4,70.8 Z M35.4,2.8 C17.4,2.8 2.8,17.4 2.8,35.4 C2.8,53.4 17.4,68 35.4,68 C53.4,68 68,53.4 68,35.4 C68,17.4 53.4,2.8 35.4,2.8 Z"
								fill="currentColor"></path>
						</g>
						<circle fill="currentColor" cx="1783.9" cy="1075.8" r="30.1"></circle>
					</g>
					<g transform="translate(1764.189977, 1055.109489)" fill="#FFFFFF">
						<path d="M25.6100227,21.6905107 L19.1100227,15.1905107 L33.3100227,1.09051072 L39.8100227,7.59051072 L25.6100227,21.6905107 Z M21.6100227,15.2905107 L25.5100227,19.1905107 L32.1100227,12.5905107 L28.2100227,8.69051072 L21.6100227,15.2905107 Z M29.5100227,7.39051072 L33.4100227,11.2905107 L37.2100227,7.49051072 L33.2100227,3.69051072 L29.5100227,7.39051072 Z"></path>
						<path d="M2.21002267,38.5905107 L3.91002267,30.4905107 L15.2100227,19.1905107 L21.7100227,25.6905107 L10.3100227,36.9905107 L2.21002267,38.5905107 Z M5.31002267,32.5905107 L4.51002267,36.2905107 L8.21002267,35.4905107 L5.31002267,32.5905107 Z M6.11002267,30.7905107 L10.0100227,34.6905107 L19.1100227,25.5905107 L15.2100227,21.6905107 L6.11002267,30.7905107 Z"></path>
						<path d="M14.5100227,21.0905107 L9.91002267,16.4905107 C7.21002267,17.0905107 4.31002267,16.1905107 2.41002267,14.1905107 C0.110022674,11.8905107 -0.589977326,8.49051072 0.510022674,5.49051072 L1.01002267,4.19051072 L4.81002267,7.99051072 L7.91002267,7.89051072 L8.01002267,4.79051072 L4.21002267,0.990510716 L5.51002267,0.490510716 C8.51002267,-0.609489284 11.9100227,0.190510716 14.2100227,2.39051072 C16.2100227,4.39051072 17.0100227,7.19051072 16.5100227,9.89051072 L21.1100227,14.4905107 L19.7100227,15.8905107 L14.4100227,10.5905107 L14.5100227,10.0905107 C15.1100227,7.89051072 14.5100227,5.39051072 12.8100227,3.79051072 C11.4100227,2.39051072 9.41002267,1.69051072 7.51002267,1.89051072 L9.71002267,4.09051072 L9.51002267,9.59051072 L4.01002267,9.79051072 L1.81002267,7.59051072 C1.61002267,9.59051072 2.21002267,11.4905107 3.71002267,12.9905107 C5.31002267,14.5905107 7.81002267,15.2905107 10.0100227,14.6905107 L10.5100227,14.5905107 L15.8100227,19.8905107 L14.5100227,21.0905107 Z"></path>
						<path d="M32.5100227,39.6905107 C30.7100227,39.6905107 28.9100227,38.9905107 27.5100227,37.5905107 C26.0100227,36.0905107 25.3100227,34.0905107 25.5100227,31.9905107 L19.7100227,26.2905107 L21.0100227,24.9905107 L27.3100227,31.2905107 L27.2100227,31.6905107 C26.9100227,33.3905107 27.5100227,34.9905107 28.7100227,36.1905107 C30.8100227,38.2905107 34.1100227,38.2905107 36.2100227,36.1905107 C37.2100227,35.1905107 37.8100227,33.8905107 37.8100227,32.4905107 C37.8100227,31.0905107 37.2100227,29.7905107 36.2100227,28.7905107 C35.0100227,27.5905107 33.4100227,27.0905107 31.7100227,27.2905107 L31.3100227,27.3905107 L25.0100227,21.0905107 L26.3100227,19.7905107 L32.0100227,25.4905107 C34.1100227,25.2905107 36.1100227,26.0905107 37.6100227,27.4905107 C38.9100227,28.7905107 39.7100227,30.5905107 39.7100227,32.4905107 C39.7100227,34.3905107 39.0100227,36.1905107 37.6100227,37.4905107 C36.1100227,38.9905107 34.3100227,39.6905107 32.5100227,39.6905107 Z"></path>
						<rect
							transform="translate(23.019623, 17.850051) rotate(-44.959485) translate(-23.019623, -17.850051) "
							x="22.1196311"
							y="14.1500853"
							width="1.79998319"
							height="7.39993088"></rect>
						<rect
							transform="translate(17.820823, 23.047591) rotate(-44.959485) translate(-17.820823, -23.047591) "
							x="16.9208311"
							y="19.3476253"
							width="1.79998319"
							height="7.39993088"></rect>
					</g>
				</g>
			</g>
		</svg>
	);
}
