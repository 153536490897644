import React from 'react';

import { css } from '@emotion/react';

import { roundGradeNumerator, roundGradeDenominator } from '~/notebook/utils';
import { formatTime } from '~/utils/formatting';

import type { StudentGrade } from '../types';

interface Props {
	studentGrade: StudentGrade | null;
	possiblePoints: number;
	timeZone: string;
	lmsGradebookPrecision: number;
}

const GradeDeliveryTooltip: React.VFC<Props> = ({
	studentGrade,
	possiblePoints,
	timeZone,
	lmsGradebookPrecision: precision
}) => {
	const wasDelivered = studentGrade?.last_delivered_at != null;
	return (
		<div css={styles}>
			<span className="last-delivered">
				Grade last delivered to LMS:&nbsp;
				{wasDelivered
					? `${roundGradeNumerator(
							studentGrade.delivered_points,
							precision
					  )} / ${roundGradeDenominator(possiblePoints, precision)}`
					: 'Never'}
			</span>
			{wasDelivered && (
				<>
					<br />
					on{' '}
					{formatTime({
						time: studentGrade.last_delivered_at,
						timeZone
					})}
					{studentGrade.current_points !== studentGrade.delivered_points && (
						<>
							<br />
							Updated grade delivery in progress.
						</>
					)}
				</>
			)}
		</div>
	);
};

export default GradeDeliveryTooltip;

const styles = css`
	font-size: 14px;
	line-height: 18px;

	.last-delivered {
		font-weight: 500;
	}
`;
