import React, { useRef, useState } from 'react';
import { TbArrowsDiagonal } from 'react-icons/tb';

import { css, ClassNames } from '@emotion/react';
import zip from 'lodash-es/zip';
import { useToggle } from 'usehooks-ts';

import AlternateViewButton from '~/components/pageElements/ChartFigure/AlternateViewButton';
import { ExpandedViewModal } from '~/components/pageElements/ExpandedViewModal';
import TabsUIWrapper from '~/components/shared/TabsUIWrapper/TabsUIWrapper';
import WebtextButton from '~/components/WebtextButton';
import { breakpoints } from '~/styles/themes';
import { FigureType } from '~/types';

import { Figure } from '../Figure';
import Chart from './Chart';
import { useChartZoom } from './EnlargeChart';
import PatternsSvg from './PatternsSvg';
import RefreshedChart from './RefreshedChart/Chart';
import DataTable from './RefreshedChart/DataTable';
import DownloadCSVLink from './RefreshedChart/DownloadCSVLink';
import { chartDataTabContainerStyles } from './RefreshedChart/styles';
import IconButton from '../IconButton/IconButton';

import type { ChartElement } from '~/types/WebtextManifest';

export interface Props {
	chart: ChartElement;
	monochromeCharts: boolean;
	expandedViewEnabled?: boolean;
}

const ChartFigure: React.FC<Props> = (props) => {
	const { chart, monochromeCharts, expandedViewEnabled } = props;

	const [activeTab, setActiveTab] = useState('chart');
	const [showMoreRowsClicked, setShowMoreRowsClicked] = useState(false);

	const [isHighContrast, toggleHighContrast] = useToggle(false);
	const [isExpandedViewModalOpen, toggleExpandedViewModalOpen] = useToggle(false);

	const figureNumber = chart.figure_number ? chart.figure_number.replace(/Figure\s+/, '') : '';
	const expandedViewTitle = [figureNumber && `Figure ${figureNumber}`, chart.title]
		.filter(Boolean)
		.join(': ');

	/**
	 * @deprecated Used only for the legacy non-tabsUi charts.
	 *             The new ones will use the `ExpandedViewChartModal`
	 */
	const { isZoomed, enlargeCaption } = useChartZoom(chart);

	const expandButtonRef = useRef<HTMLButtonElement | null>(null);
	const figureChartRef = useRef<HTMLDivElement | null>(null);
	const figureRect = figureChartRef.current?.getBoundingClientRect();

	const chartContainer = (
		<ClassNames>
			{({ cx }) => (
				<div
					className={cx(
						'chart-container',
						'webtext-figure-asset',
						'webtext-figure-asset-chart',
						chart.chart_type
					)}>
					<div className="chart-svg" data-chart-id={chart.id}>
						{chart.tabsUi ? (
							<TabsUIWrapper
								chartComponent={
									<RefreshedChart
										ref={figureChartRef}
										chart={chart}
										isHighContrast={isHighContrast}
									/>
								}
								dataComponent={
									<div css={chartDataTabContainerStyles}>
										<DataTable
											data={chart.chart_type === 'pie' ? zip(...chart.data) : chart.data}
											showMoreRowsClicked={showMoreRowsClicked}
											onShowMoreRows={onShowMoreRows}
										/>
										<DownloadCSVLink data={chart.data} onFileDownload={chart.onFileDownload} />
									</div>
								}
								onTabChange={onTabChange}
							/>
						) : (
							<Chart chart={chart} monochrome={monochromeCharts} zoomed={isZoomed} />
						)}
					</div>
				</div>
			)}
		</ClassNames>
	);

	const optionsContainer = (
		<div css={styles.options}>
			<IconButton
				ref={expandButtonRef}
				variant="outlined"
				aria-label="Expand chart"
				onClick={toggleExpandedViewModalOpen}>
				<TbArrowsDiagonal aria-hidden="true" /> Expand chart
			</IconButton>
			{chart.tabsUi && activeTab === 'chart' && (
				<AlternateViewButton
					isHighContrast={isHighContrast}
					toggleHighContrast={toggleHighContrast}
				/>
			)}
		</div>
	);

	const onTabChange = (value: string) => {
		setActiveTab(value);
		if (chart.onTabChange) {
			chart.onTabChange(value);
		}
	};

	const onShowMoreRows = () => setShowMoreRowsClicked(true);

	return (
		<>
			<Figure
				figureOptions={{
					title: chart.title,
					payload: (
						<>
							{chartContainer}
							{expandedViewEnabled && chart.tabsUi && activeTab === 'chart' && optionsContainer}
							<PatternsSvg />
						</>
					),
					captionPrefix: !expandedViewEnabled && chart.tabsUi && activeTab === 'chart' && (
						<WebtextButton variant="text" css={styles.contrastButton} onClick={toggleHighContrast}>
							Show {isHighContrast ? 'default chart view' : 'alternate chart view'}
						</WebtextButton>
					),
					caption: chart.tabsUi ? chart.caption : enlargeCaption,
					credits: chart.credit,
					header_style: 'traditional',
					figure_number: figureNumber,
					figure_type: FigureType.chart
				}}
			/>
			{expandedViewEnabled && isExpandedViewModalOpen && figureRect && (
				<ExpandedViewModal<HTMLDivElement>
					returnFocusRef={expandButtonRef}
					title={expandedViewTitle}
					ariaLabel={'Expanded view of chart' + (expandedViewTitle ? `: ${expandedViewTitle}` : '')}
					onClose={toggleExpandedViewModalOpen}>
					{({ elementRef }) => (
						<div ref={elementRef} style={{ width: figureRect.width, height: figureRect.height }}>
							<RefreshedChart chart={chart} isHighContrast={isHighContrast} />
						</div>
					)}
				</ExpandedViewModal>
			)}
		</>
	);
};

const styles = {
	contrastButton: css`
		padding: 0;
		font-size: inherit;
		font-weight: inherit;
		text-align: left;

		@media (max-width: ${breakpoints.small}) {
			width: auto;
			margin-bottom: 0;
		}
	`,
	options: css`
		margin-bottom: 15px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	`
};

export default ChartFigure;
