import cloneDeep from 'lodash-es/cloneDeep';

import constants from '../base/constants';
import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

const { montserrat, nunito } = constants.fonts;

theme.fonts.app = nunito;
theme.fonts.webtext = nunito;
theme.fonts['webtext-question-body'] = nunito;
theme.fonts['webtext-question-answer'] = nunito;
theme.fonts['webtext-question'] = nunito;
theme.fonts['webtext-question-buttons'] = nunito;
theme.fonts['webtext-question-rejoinder-correctness'] = nunito;
theme.fonts['webtext-heading-font-family'] = montserrat;
theme.fonts.serif = nunito;
theme.fonts['sans-serif'] = nunito;

theme.colors.primary = '#472d89';
theme.colors.secondary = '#f1f1f2';
theme.colors.accent = (theme) => theme.colors.primary;
theme.colors.link = (theme) => theme.colors.primary;

theme.chapterSplash.title.fontWeight = 600;
theme.chapterSplash.title.name.textTransform = 'uppercase';
theme.chapterSplash.separatorColor = (theme) => theme.colors.primary;
theme.chapterSplash.credit.color = '#eeeef0';

theme.webtextPage.title.color = theme.colors.red;
theme.webtextPage.title.textTransform = 'uppercase';
theme.webtextPage.headings.fontWeight = 600;
theme.webtextPage.headings.textTransform = 'uppercase';
theme.webtextPage.strong.color = (theme) => theme.colors.primary;
theme.webtextPage.strong.fontWeight = 600;

theme.global.webtextPageInstruction.fontFamily = nunito;
theme.global.webtextPageInstruction.fontSize = '19px';
theme.global.webtextPageInstruction.fontWeight = 300;

theme.endOfChapter.separators.borderColor = theme.colors.red;
theme.endOfChapter.message.color = theme.colors.red;
theme.endOfChapter.message.fontWeight = 300;

theme.notifications.fontFamily = theme.fonts.app;

theme.comments.instructor.header.title.fontFamily = montserrat;
theme.comments.instructor.header.title.fontWeight = 700;
theme.comments.instructor.header.title.marginBottom = '3px';
theme.comments.instructor.header.timestamp.fontFamily = montserrat;
theme.comments.instructor.comment.fontFamily = montserrat;

theme.webtextQuestion.questionPrompt.fontFamily = nunito;
theme.webtextQuestion.rejoinder.correctness.fontWeight = 600;
theme.webtextQuestion.sectionTitle.fontFamily = nunito;

theme.shortAnswer.referenceAnswer.fontWeight = 300;

theme.tableOfContents.currentItemColor = (theme) => theme.colors.primary;

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}1A`;

export default theme;
