import * as React from 'react';

export function HeadWithGear(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={32.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.762833777925626px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={28.73} fill="currentColor" />
			<path
				d="M26.07 41.11v11.74A1.1 1.1 0 0 0 27.13 54h13.12a1.09 1.09 0 0 0 1.1-1.09v-3.5h5.45a3.27 3.27 0 0 0 3.27-3.27v-5.43h3.28a1.09 1.09 0 0 0 1.09-1.09 1 1 0 0 0-.12-.49l-4.25-8.51a14.2 14.2 0 1 0-24 10.49Zm-1.92-12.78a12 12 0 0 1 23.77 2.55 1 1 0 0 0 .12.49l3.58 7.16H49a1.09 1.09 0 0 0-1.09 1.09v6.55a1.09 1.09 0 0 1-1.09 1.1h-6.58a1.09 1.09 0 0 0-1.09 1.09v3.45H28.22V40.63a1.09 1.09 0 0 0-.36-.81 12 12 0 0 1-3.74-11.49Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M29.11 38.2a1.14 1.14 0 0 0 .83-.11l1.62-.94a6.34 6.34 0 0 0 1 .61v1.86a1.09 1.09 0 0 0 1.08 1.09H38a1.09 1.09 0 0 0 1.1-1.09v-1.86a7.15 7.15 0 0 0 1-.61l1.63.92a1.08 1.08 0 0 0 1.49-.4l2.19-3.74a1.14 1.14 0 0 0 .11-.83 1.08 1.08 0 0 0-.51-.69l-1.59-.92v-1.23l1.59-.92a1.09 1.09 0 0 0 .4-1.49l-2.19-3.79a1.11 1.11 0 0 0-1.49-.4l-1.61.94a7.19 7.19 0 0 0-1-.6v-1.86a1.09 1.09 0 0 0-1.09-1.09h-4.36a1.09 1.09 0 0 0-1.09 1.09V24a8.25 8.25 0 0 0-1 .6l-1.61-.93a1.09 1.09 0 0 0-.83-.1 1 1 0 0 0-.69.5l-2.18 3.79a1.08 1.08 0 0 0 .39 1.49l1.6.92v1.23l-1.6.92a1.08 1.08 0 0 0-.39 1.49l2.18 3.78a1.12 1.12 0 0 0 .69.51Zm1.39-6.32a5 5 0 0 1 0-2 1.1 1.1 0 0 0-.5-1.14L28.69 28l1.09-1.89 1.3.75a1.07 1.07 0 0 0 1.26-.12 5.35 5.35 0 0 1 1.7-1 1.11 1.11 0 0 0 .69-1v-1.5h2.19v1.49a1.09 1.09 0 0 0 .69 1 5.55 5.55 0 0 1 1.7 1 1.1 1.1 0 0 0 1.26.12l1.43-.74L43.05 28l-1.28.74a1.1 1.1 0 0 0-.53 1.15 5.46 5.46 0 0 1 .11 1 5.57 5.57 0 0 1-.11 1 1.1 1.1 0 0 0 .53 1.11l1.28.74L42 35.65l-1.3-.75a1.09 1.09 0 0 0-1.25.11 5.29 5.29 0 0 1-1.72 1A1.11 1.11 0 0 0 37 37v1.5h-2.22V37a1.11 1.11 0 0 0-.69-1 5.1 5.1 0 0 1-1.7-1 1.1 1.1 0 0 0-1.26-.11l-1.3.75-1.09-1.89L30 33a1.08 1.08 0 0 0 .52-1.14Z"
				style={{
					fill: '#fff'
				}}
			/>
			<path
				d="M35.87 34.16a3.29 3.29 0 1 0-3.28-3.29 3.29 3.29 0 0 0 3.28 3.29Zm0-4.37a1.09 1.09 0 1 1-1.09 1.09 1.09 1.09 0 0 1 1.09-1.09Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
