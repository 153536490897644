import React from 'react';

export default function LinkIcon(props) {
	return (
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
			<g transform="translate(0,90) scale(0.1,-0.1)" fill={props.color}>
				<path
					d="M553 884 c-23 -9 -61 -33 -85 -54 l-43 -38 34 -35 33 -35 44 33 c58
44 109 52 167 27 67 -30 102 -95 90 -170 -5 -30 -24 -55 -97 -129 -74 -74 -98
-93 -129 -99 -50 -9 -108 9 -151 46 l-33 29 -33 -34 -34 -35 33 -34 c52 -54
122 -79 201 -74 84 6 114 23 209 113 104 100 136 158 136 250 0 48 -6 81 -19
105 -63 120 -200 177 -323 134z"
				/>
				<path
					d="M278 606 c-69 -25 -206 -155 -249 -236 -30 -58 -33 -167 -5 -220 83
-159 277 -197 408 -80 l43 38 -34 35 -33 35 -44 -33 c-58 -44 -109 -52 -167
-27 -67 30 -102 95 -90 170 5 30 24 55 97 129 74 74 98 93 129 99 50 9 108 -9
151 -46 l33 -29 33 34 34 35 -30 31 c-65 70 -184 98 -276 65z"
				/>
			</g>
		</svg>
	);
}
