import { css } from '@emotion/react';

import { breakpoints, mixins } from '~/styles/themes';

export default (theme) => {
	const { colors } = theme;

	const iconSize = '26px';
	const iconCircleSize = '52px';

	const iconSizeSmallScreen = '20px';
	const iconCircleSizeSmallScreen = '45px';

	return css`
		margin-bottom: 30px;

		.big-idea-icon-container {
			position: absolute;

			.big-idea-icon-circle {
				width: ${iconCircleSize};
				height: ${iconCircleSize};
				font-size: ${iconSize};
				margin-top: 1em;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				color: #fff;
				text-align: center;
				background: ${colors.primary};
			}
		}

		.big-idea {
			background: ${theme.webtextQuestion.backgroundColor};
			padding: 2.25em 3em;
			margin-left: 26px;

			.big-idea-title {
				display: block;
				margin-bottom: 0.75em;
				color: ${colors.primary};
				font-size: ${theme.bigIdea.title.fontSize};
				font-family: ${theme.bigIdea.title.fontFamily};
				letter-spacing: 2.8px;
				text-transform: uppercase;
			}

			.big-idea-body {
				display: block;
				font-size: ${theme.bigIdea.body.fontSize};
				font-family: ${theme.bigIdea.body.fontFamily};
				line-height: 1.25;
			}

			.webtext-source-attribute {
				margin-bottom: 0;
				font-size: ${theme.bigIdea.sourceAttribute.fontSize};
				font-family: ${theme.bigIdea.sourceAttribute.fontFamily};
				letter-spacing: 1.1px;
				text-align: right;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			// Compensates for the icon -1em offset + 1em margin
			margin-top: 2em;

			.big-idea-icon-container {
				.big-idea-icon-circle {
					margin-top: -1em;
					font-size: 1.25em;
					width: ${iconCircleSizeSmallScreen};
					height: ${iconCircleSizeSmallScreen};
					font-size: ${iconSizeSmallScreen};
				}
			}

			.big-idea {
				padding: 1.75em 1.25em;
				margin-left: 23px;

				.big-idea-title {
					font-size: ${theme.bigIdea.title.mobileFontSize};
					letter-spacing: 2.4px;
				}

				.big-idea-body {
					font-size: ${theme.bigIdea.body.mobileFontSize};
				}
			}
		}

		.visually-hidden {
			${mixins.webtextHiddenAccessible(theme)}
		}
	`;
};
