import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export const primarySourceWrapperStyles = (theme) => {
	const primarySourceReaderBackgroundColor = '#f4f4f4';
	const primarySourceReaderPrimaryColor = theme.colors.primary;

	return css`
		position: relative;
		background-color: ${primarySourceReaderBackgroundColor};
		border-left: 6px solid ${primarySourceReaderPrimaryColor};
		padding: 2em;
		margin-left: 2em;
		margin-bottom: 2em;

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			margin-left: 0;
			padding-top: 34px;
			border-left: 0;

			&.beginning-without-ending,
			&.continuation-without-ending {
				margin-bottom: 0;
				padding-bottom: 0;
			}

			&.continuation-without-ending,
			&.continuation-with-ending {
				margin-top: 0;
				padding-top: 0;
			}

			&.beginning-without-ending,
			&.continuation-without-ending {
				margin-bottom: 40px;
			}

			&.continuation-without-ending {
				.webtext-primary-source-separator {
					&:first-of-type {
						margin-top: -11px;
					}

					&:last-of-type {
						margin-top: -9px;
					}
				}
			}

			.webtext-primary-source-separator {
				width: calc(100% + 4em);
				margin-left: -2em;

				@media (max-width: ${breakpoints.small}) {
					width: calc(100% + 32px);
					margin-left: -16px;
				}
			}

			@media (max-width: ${breakpoints.small}) {
				padding-left: 16px;
				padding-right: 16px;
				padding-bottom: 1em;

				&.beginning-without-ending,
				&.continuation-without-ending {
					margin-bottom: 3em;
					padding-bottom: 0;
				}

				&.continuation-without-ending,
				&.continuation-with-ending {
					margin-top: 0;
					padding-top: 0;
				}

				&.continuation-without-ending {
					.webtext-primary-source-separator {
						&:first-of-type {
							margin-top: -9px;
						}

						&:last-of-type {
							margin-top: -8px;
						}
					}
				}

				&.continuation-with-ending {
					.webtext-primary-source-separator {
						margin-top: -9px;
					}
				}
			}
		`}
	`;
};

export default (theme) => {
	const primarySourceReaderPrimaryColor = theme.colors.primary;

	return css`
		.webtext-primary-source {
			${primarySourceWrapperStyles(theme)}
		}

		.webtext-primary-source-attributes {
			padding-bottom: 2em;
			margin-bottom: 2em;
			border-bottom: 1px solid ${primarySourceReaderPrimaryColor};

			@media only screen and (min-width: 1400px) {
				position: absolute;
				right: 700px;
				width: 180px;
				border-bottom: 0;
				text-align: right;
			}

			@media only screen and (min-width: 1550px) {
				width: 280px;
			}
		}

		.webtext-primary-source-content {
			&[data-continued-below]::after,
			&[data-continued-from-above]::before {
				display: block;
				text-transform: uppercase;
				color: ${primarySourceReaderPrimaryColor};
				font-size: 0.8em;
			}

			&[data-continued-below]::after {
				content: 'Continued below';
				font-family: ${theme.fonts.app};
				margin: 3em 0 0;
			}

			&[data-continued-from-above]::before {
				margin: 3em 0 2em;
				font-family: ${theme.fonts.app};
				font-size: 0.8em;
				content: 'Continued from above';

				@media only screen and (min-width: 1400px) {
					margin-top: 0 !important;
				}
			}

			blockquote {
				border-color: transparent;
				font-style: inherit;
			}
		}

		.webtext-source-attribute {
			margin: 0;
			font-size: 14px;
			letter-spacing: 0.1em;
			line-height: 1.2em;
			font-family: ${theme.fonts.app};

			&::before {
				content: attr(data-label);
				font-size: 13px;
				color: ${primarySourceReaderPrimaryColor};
				display: inline-block;
				text-transform: uppercase;
				margin-right: 0.5em;
			}

			&.webtext-source-title {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 1em;

				&::before {
					display: block;
					font-size: 13px;
					letter-spacing: 0.1em;
					margin-bottom: 0.25em;
					margin-right: 0;
				}
			}

			&.webtext-source-link {
				a {
					color: #000;
				}

				&::before {
					font-family: 'SSStandard';
					content: '🔗';
				}
			}
		}

		// Other themes

		${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetStyles(theme)}
	`;
};

export const universalVelvetStyles = (theme) => {
	return css`
		.webtext-primary-source-meta {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.webtext-source-attribute-description {
				font-family: ${theme.fonts.app};
				font-size: 16px;
				line-height: 1.2em;
				color: #000000;
				max-width: 45%;
				margin-top: 1.7em;
				margin-bottom: 0;
				padding-bottom: 2em;

				&[data-label]::before {
					content: attr(data-label) ': ';
					font-family: ${theme.primarySource.sourceAttribute.fontFamily};
					font-weight: ${theme.primarySource.sourceAttribute.fontWeight};
					font-size: 15px;
					color: #000000;
					text-transform: none;
				}
			}
		}

		.webtext-primary-source-attributes {
			flex-grow: 1;
			position: initial;
			width: auto;
			text-align: left;
			margin-bottom: 0;
			border: 0;

			.webtext-source-attribute {
				margin: 0;
				line-height: 1.2em;
				font-family: ${theme.fonts.app};
				letter-spacing: 0;
				font-size: 16px;
				color: #000000;
				margin-bottom: 0.5em;
				margin-right: 1em;

				&::before {
					content: attr(data-label) ':';
					font-family: ${theme.primarySource.sourceAttribute.fontFamily};
					font-weight: ${theme.primarySource.sourceAttribute.fontWeight};
					font-size: 15px;
					color: #000000;
					text-transform: none;
				}

				&.webtext-source-title {
					&::before {
						content: attr(data-label);
						letter-spacing: normal;
						color: ${theme.colors.primary};
					}

					margin-bottom: 12px;
				}
			}
		}

		.webtext-primary-source-content {
			font-family: ${theme.fonts.app};
			background-color: #fff;
			padding: 2em;
			padding-bottom: 1.5em;

			.webtext-source-attribute.webtext-source-title {
				font-family: ${theme.primarySource.sourceTitle.fontFamily};
				text-transform: none;
				font-size: 24px;
				letter-spacing: normal;
			}

			p {
				font-size: 20px;
				font-weight: 400;
				color: #000000;
				letter-spacing: 0;
				line-height: 34px;
				font-family: ${theme.primarySource.sourceContent.fontFamily};

				a {
					font-weight: bold;
					text-decoration-thickness: 1px;
				}
			}

			li {
				font-family: ${theme.primarySource.sourceContent.fontFamily};
			}
		}

		.webtext-figure {
			border: 0;
			margin-bottom: 0;
		}

		.webtext-primary-source-separator {
			position: absolute;
			left: 0;
			display: flex;
			align-items: center;
			text-align: center;
			color: ${theme.primarySource.separator.color};
			font-size: 16px;
			font-family: ${theme.primarySource.separator.fontFamily};
			font-style: italic;
			margin-top: -10px;
			z-index: 1;

			&::before,
			&::after {
				content: '';
				flex: 1;
				border-bottom: ${theme.primarySource.separator.borderBottom};
			}

			&:not(:empty)::before {
				margin-right: 0.4em;
			}

			&:not(:empty)::after {
				margin-left: 0.4em;
			}
		}

		.webtext-primary-source-credit {
			font-family: ${theme.primarySource.sourceCredit.fontFamily};
			font-size: ${theme.primarySource.sourceCredit.fontSize};
			color: ${theme.primarySource.sourceCredit.color};
			margin-top: 2em;

			em {
				font-style: normal;
				font-family: ${theme.primarySource.sourceCredit.em.fontFamily};
			}
		}

		+ div[data-react-class='InstructorResponseBoardQuestion'],
		+ div[data-react-class='ResponseBoardQuestion'],
		+ div[data-react-class='InstructorPollQuestion'],
		+ div[data-react-class='PollQuestion'],
		+ div[data-react-class='InstructorShortAnswerQuestion'],
		+ div[data-react-class='ShortAnswerQuestion'],
		+ div[data-react-class='MultipleChoiceQuestion'] {
			margin-top: 95px;

			@media (max-width: ${breakpoints.small}) {
				margin-top: 32px;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			.webtext-primary-source-meta {
				flex-wrap: wrap;

				.webtext-source-attribute-description {
					max-width: unset;
					margin-bottom: 1em;
					margin-top: 0.5em;
				}
			}

			.webtext-primary-source-attributes {
				position: initial;
				width: auto;
				text-align: left;
				font-size: 15px;
				padding-bottom: 0;
				margin-bottom: 16px;

				.webtext-source-attribute {
					font-size: 15px;
					margin-bottom: 10px;
					margin-right: 0;
				}
			}

			.webtext-primary-source-content {
				padding: 1.5em;

				.webtext-source-attribute.webtext-source-title {
					font-size: 18px;
					line-height: 23px;
				}

				p {
					font-size: 15px;
					line-height: 26px;
				}
			}

			.webtext-primary-source-separator {
				font-size: 14px;
				margin-top: -8px;
			}
		}
	`;
};
