import React from 'react';

import { css } from '@emotion/react';

const styles = (theme) => css`
	background-color: ${theme.colors.secondary};
	z-index: 2;

	.container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.loader {
		text-align: center;
		margin: 0 auto;
		opacity: 1;
		padding: 35px 5%;
		height: 128px;
		font-family: Helvetica;

		.signal {
			border: 3px solid ${theme.colors.black};
			border-radius: 30px;
			height: 20px;
			width: 20px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
			opacity: 0;
			animation: pulsate 1.5s ease-out;
			animation-iteration-count: infinite;
		}
	}

	@keyframes pulsate {
		0% {
			transform: scale(0.1);
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		100% {
			transform: scale(1.2);
			opacity: 0;
		}
	}
`;

const Loading = () => {
	return (
		<div css={styles}>
			<div className="container">
				<div className="loader">
					<div className="signal"></div>
					<div style={{ overflow: 'auto' }}>(loading)</div>
				</div>
			</div>
		</div>
	);
};

export default Loading;
