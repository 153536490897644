import { type ComponentType, type FC, type ReactElement, useEffect, useMemo } from 'react';

import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { type Klass, type LexicalNode } from 'lexical';

import { CitationNode } from '~/components/WritingTemplate/FillIn/components/LexicalRTE/nodes/CitationNode';
import defaultTheme from '~/components/WritingTemplate/FillIn/components/LexicalRTE/theme';
import { type EditorSpecificOptions } from '~/components/WritingTemplate/FillIn/types';

function withLexicalComposer<T>(Component: ComponentType<T>) {
	return function LexicalComposerWrapper(
		props: T & { dest: string; editorOptions: EditorSpecificOptions; withCitations?: boolean }
	): ReactElement {
		const { dest, withCitations, editorOptions } = props;

		/**
		 * Required for initialConfig, we can catch the error manually, but
		 * `Lexical will try to recover gracefully without losing user data`.
		 * https://lexical.dev/docs/getting-started/react
		 */
		const onError = (error: Error) => {
			console.error('Lexical RTE error', error);
			if (window.Rollbar) window.Rollbar.error('Lexical RTE error', error);
		};

		/**
		 * Set available nodes based on configs
		 * It might be list and/or citation and/or something in the future.
		 */
		const availableNodes = useMemo<Klass<LexicalNode>[]>(() => {
			const nodes = new Set<Klass<LexicalNode>>();

			if (withCitations) {
				/**
				 * Add citation node only inside editor with citations
				 */
				nodes.add(CitationNode);
			}

			if (editorOptions.allowedLists?.length) {
				/**
				 * Required for using lists
				 */
				nodes.add(ListNode);
				nodes.add(ListItemNode);
			}

			return Array.from(nodes);
		}, [editorOptions.allowedLists?.length, withCitations]);

		return (
			<LexicalComposer
				initialConfig={{
					theme: defaultTheme,
					namespace: `lexicalRTE-${dest}`,
					onError,
					nodes: availableNodes
				}}>
				<EditableSetter editable={!editorOptions.readOnly} />
				<Component {...(props as any)}></Component>
			</LexicalComposer>
		);
	};
}

/**
 * Required for setting the "Read Only" mode for the editor
 * Solves the caveat of the `initialConfig` that get applied only once and further changes have no effect
 */
const EditableSetter: FC<{ editable: boolean }> = ({ editable }) => {
	const [editor] = useLexicalComposerContext();
	useEffect(() => editor.setEditable(editable), [editable, editor]);
	return null;
};

export default withLexicalComposer;
