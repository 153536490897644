import React from 'react';

import first from 'lodash-es/first';

import { localStorageGet, localStorageSet } from '~/utils/localStorage';

import {
	copyToClipboard,
	createCopyString,
	getCache,
	spreadsheetUpdateKey,
	getCopiedText,
	assignTextWithCells,
	populateSelectedRangeWithValues,
	getExistingEditableCells
} from '.';
import { getActiveAreaId, getCursorId, getRangeSelectionId } from './constants';
import { expandSelection, shiftPosition } from './selection';
import {
	KeyCodes,
	CellsRefs,
	CellValues,
	Dir,
	Result,
	EditableRanges,
	Formatting,
	ContextMenuParams
} from './types';

export const handleClick = (props: {
	dest: string;
	origin: string;
	event: React.KeyboardEvent<HTMLTableElement>;
	cellsRefs: CellsRefs;
	selectedCell: string;
	selectedIndexes: string[];
	cellValues: CellValues;
	editableRanges: EditableRanges;
	copyCacheKey: string;
	formatting: Formatting;
	setSelectedCell: (address: string, options?: { saveSelectedIndexes: boolean }) => void;
	setEditingCell: (address: string) => void;
	setSelectedCells: (selected: string[]) => void;
	setEnterWithString: (value: string) => void;
	updateCellsValues: (cellValues: CellValues) => void;
	updateAnnouncementSR: (value: string) => void;
	setActiveAreaId: (id: string) => void;
	spreadsheetWrapperRef: React.MutableRefObject<HTMLDivElement>;
	updateContextPropsWithParams: (params: ContextMenuParams, wrapperElement: HTMLDivElement) => void;
}): void => {
	const {
		event,
		dest,
		origin,
		setSelectedCells,
		setSelectedCell,
		cellsRefs,
		selectedCell,
		setEditingCell,
		setEnterWithString,
		updateCellsValues,
		selectedIndexes,
		cellValues,
		copyCacheKey,
		editableRanges,
		updateAnnouncementSR,
		formatting,
		setActiveAreaId,
		spreadsheetWrapperRef,
		updateContextPropsWithParams
	} = props;

	if (event.key === KeyCodes.Tab || isReload(event)) {
		return;
	}

	event.preventDefault();

	if ((event.ctrlKey || event.metaKey) && event.key.toUpperCase() === KeyCodes.A) {
		const allCells = getAllCells(cellsRefs);
		setSelectedCells(allCells);
		return;
	}

	if (
		(event.ctrlKey || event.metaKey) &&
		[KeyCodes.X, KeyCodes.V, KeyCodes.C].includes(event.key.toUpperCase() as KeyCodes)
	) {
		if (event.key.toUpperCase() === KeyCodes.C) {
			const values = callClipboardInScope(selectedCell, selectedIndexes, cellValues);
			copyRange(copyCacheKey, values);
			return;
		}

		if (event.key.toUpperCase() === KeyCodes.X) {
			const values = callClipboardInScope(selectedCell, selectedIndexes, cellValues);
			copyRange(copyCacheKey, values);
			clearSelectedRange({
				selectedCell,
				selectedIndexes,
				updateCellsValues,
				editableRanges
			});
			return;
		}

		if (event.key.toUpperCase() === KeyCodes.V) {
			pasteRange({
				dest,
				selectedCell,
				selectedIndexes,
				editableRanges,
				cellsRefs,
				updateCellsValues,
				setSelectedCells,
				copyCacheKey,
				formatting
			});
			return;
		}

		return;
	}

	if (
		(event.key === KeyCodes.FTwo || (event.ctrlKey && event.key.toUpperCase() === KeyCodes.U)) &&
		editableRanges[selectedCell]
	) {
		setEditingCell(selectedCell);
		setActiveAreaId(getActiveAreaId(dest));

		return;
	}

	if (isEditableKeyClick(event) && editableRanges[selectedCell]) {
		setEnterWithString(event.key);
		setEditingCell(selectedCell);
		setActiveAreaId(getActiveAreaId(dest));

		return;
	}

	const isDeleteAvailable =
		selectedIndexes.length > 0
			? selectedIndexes.some((selectedCellAddress) => editableRanges[selectedCellAddress])
			: editableRanges[selectedCell];

	if ((event.key === KeyCodes.Delete || event.key === KeyCodes.Backspace) && isDeleteAvailable) {
		const range = selectedIndexes.length > 0 ? selectedIndexes : [selectedCell];
		const values = Object.fromEntries(
			range.filter((address) => editableRanges[address]).map((address) => [address, ''])
		);
		updateCellsValues(values);
		return;
	}

	const onMove = (dir: Dir) => {
		const increased = shiftPosition(dir, selectedCell, cellsRefs);
		const { result, key } = increased;

		if (result === Result.Accept && key) {
			setSelectedCell(key);
		} else {
			updateAnnouncementSR(increased.message);
		}
	};

	const onSelect = (dir: Dir) => {
		const expanded = expandSelection(dir, selectedIndexes, selectedCell, cellsRefs);

		const { result, keys } = expanded;

		if (result === Result.Accept && keys) {
			setSelectedCells(keys);
		} else {
			updateAnnouncementSR('No more cells');
		}
	};

	if (
		(event.key === KeyCodes.ArrowUp && !event.shiftKey) ||
		(event.key === KeyCodes.Enter && event.shiftKey)
	) {
		onMove(Dir.Up);
	} else if (
		(event.key === KeyCodes.ArrowDown && !event.shiftKey) ||
		event.key === KeyCodes.Enter
	) {
		onMove(Dir.Down);
	}

	if (!event.shiftKey) {
		if (event.key === KeyCodes.ArrowLeft) {
			onMove(Dir.Left);
		} else if (event.key === KeyCodes.ArrowRight) {
			onMove(Dir.Right);
		}
	} else if (event.shiftKey) {
		if (event.key === KeyCodes.ArrowLeft) {
			onSelect(Dir.Left);
		} else if (event.key === KeyCodes.ArrowRight) {
			onSelect(Dir.Right);
		} else if (event.key === KeyCodes.ArrowUp) {
			onSelect(Dir.Up);
		} else if (event.key === KeyCodes.ArrowDown) {
			onSelect(Dir.Down);
		}
	}

	/**
	 * Action for moving to the left top corner
	 */
	if (event.key === KeyCodes.Home && (event.ctrlKey || event.metaKey)) {
		setSelectedCell(origin);
	}

	if (event.key === KeyCodes.FTen && event.shiftKey) {
		const { current: wrapperElement } = spreadsheetWrapperRef;
		if (!wrapperElement) return;

		return updateContextPropsWithParams(
			getElementCenter(
				wrapperElement.querySelector(
					selectedIndexes?.length <= 1 ? `#${getCursorId(dest)}` : `#${getRangeSelectionId(dest)}`
				)
			),
			wrapperElement
		);
	}
};

const getElementCenter = (element: Element): ContextMenuParams => {
	if (!element) return;

	const { top, left, width, height } = element.getBoundingClientRect();
	const diffY = 40; // need only inside the webtexts, there is some global shift that makes all absolute elements move down
	return {
		mouseY: top + height / 2 - diffY,
		mouseX: left + width / 2,
		accessBy: 'keyboard'
	};
};

const isReload = (e: React.KeyboardEvent<HTMLTableElement>) =>
	e.key === KeyCodes.FFive || (e.ctrlKey && e.key.toLowerCase() === 'r');

const getAllCells = (cellsRefs: CellsRefs) => Object.keys(cellsRefs);

const isEditableKeyClick = (e: React.KeyboardEvent<HTMLTableElement>) => {
	/**
	 * Firefox has different codes for those key inputs;
	 * reference https://github.com/ccampbell/mousetrap/pull/215
	 * There is no sense to list all available inputs for any other
	 * That's why we use keyCodes, except these
	 */
	const firefoxSupportKeys = ['=', '-', '+', '_', ';', ':'];

	return (
		(e.keyCode >= 65 && e.keyCode <= 90) || // Regular keys
		(e.keyCode >= 96 && e.keyCode <= 105) || // Num pad number keys
		(e.keyCode >= 48 && e.keyCode <= 57) || // Number key keys
		(e.keyCode >= 106 && e.keyCode <= 111) || // Num pad keys not numbers
		(e.keyCode >= 186 && e.keyCode <= 222) || // Other keys
		firefoxSupportKeys.includes(e.key)
	);
};

export const callClipboardInScope = (
	selectedCell: string,
	selectedIndexes: string[],
	cellValues: CellValues
): CellValues => {
	copyToClipboard(createCopyString(selectedCell, selectedIndexes, cellValues));
	return getCache(selectedIndexes, selectedCell, cellValues);
};

export const getCopyingValues = (
	selectedCell: string,
	selectedIndexes: string[],
	cellValues: CellValues
): {
	clipboardValue: string;
	storageValue: CellValues;
} => {
	const clipboardValue = createCopyString(selectedCell, selectedIndexes, cellValues);
	const storageValue = getCache(selectedIndexes, selectedCell, cellValues);

	return {
		clipboardValue,
		storageValue
	};
};

export const copyRange = (copyCacheKey: string, values: CellValues): void => {
	localStorageSet(copyCacheKey, values);
	localStorageSet(spreadsheetUpdateKey, copyCacheKey);
};

export const clearSelectedRange = (props: {
	selectedCell: string;
	selectedIndexes: string[];
	editableRanges: EditableRanges;
	updateCellsValues: (values: CellValues) => void;
}): void => {
	const { selectedCell, selectedIndexes, editableRanges, updateCellsValues } = props;

	const selection = selectedIndexes.length > 1 ? selectedIndexes : [selectedCell];
	const availableCells = selection.filter((cell) => editableRanges[cell]);
	const updatedCellValues = Object.fromEntries(availableCells.map((address) => [address, '']));
	updateCellsValues(updatedCellValues);
};

export const pasteRange = async (props: {
	dest: string;
	selectedCell: string;
	copyCacheKey: string;
	selectedIndexes: string[];
	editableRanges: EditableRanges;
	cellsRefs: CellsRefs;
	formatting: Formatting;
	updateCellsValues: (values: CellValues) => void;
	setSelectedCells: (selection: string[]) => void;
	clipboardValue?: string;
	storageValue?: CellValues;
}): Promise<string[]> => {
	const {
		dest,
		selectedCell,
		copyCacheKey,
		selectedIndexes,
		editableRanges,
		updateCellsValues,
		cellsRefs,
		setSelectedCells,
		formatting,
		clipboardValue,
		storageValue
	} = props;

	const text = clipboardValue ? clipboardValue : await getCopiedText(dest);
	const copyCache = storageValue ? storageValue : localStorageGet(copyCacheKey);

	const { pasteValues, pasteValuesWithNoTransform, relationMap } = assignTextWithCells({
		text,
		selectedCell,
		copyCache,
		formatting
	});

	/**
	 * We have to paste cell and populate values on all range in case when it selected
	 */
	const isCellToRange = Object.values(pasteValues).length === 1 && selectedIndexes.length > 1;

	if (isCellToRange) {
		const formulaOrigin = first(Object.values(relationMap));
		const pastedValue = first(Object.values(pasteValuesWithNoTransform));

		const rangeValues = populateSelectedRangeWithValues({
			selectedIndexes,
			editableRanges,
			value: pastedValue,
			formulaOrigin,
			formatting
		});

		/**
		 * This is not required, but help to avoid redundant onUserUpdate calls
		 * - Insert range only in the range exist
		 */
		if (Object.values(rangeValues).length) {
			updateCellsValues(rangeValues);
		}

		return;
	}

	/**
	 * Otherwise we have range in buffer and have to create range inside ST and replace values
	 */
	const availableCells = getExistingEditableCells(cellsRefs, pasteValues, editableRanges);

	/**
	 * This is not required, but help to avoid redundant onUserUpdate calls
	 * - Insert range only in the range exist
	 */
	if (Object.values(availableCells).length) {
		updateCellsValues(availableCells);
	}

	/**
	 * Re-select range with new values
	 */
	const replacedRangeIndexes = Object.entries(pasteValues)
		.filter(([key]) => cellsRefs[key])
		.map(([key]) => key);

	setSelectedCells(replacedRangeIndexes);

	return replacedRangeIndexes;
};
