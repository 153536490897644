import { TippyProps } from '@tippyjs/react';
import defaultsDeep from 'lodash-es/defaultsDeep';
import {
	CreateSingleton,
	createSingleton,
	CreateSingletonProps,
	Instance as TippyInstance
} from 'tippy.js';

import {
	hideOnEscPlugin,
	unlinkifyContentPlugin
} from '~/components/pageElements/Text/components/WebtextTippy/utils';
import { PollChartType, PollColorPalette } from '~/types/WebtextManifest';
import { includesMarkup } from '~/utils/parsing';

export const uvPollColors: PollColorPalette = [
	'#10878B',
	'#6F0540',
	'#AF1FA0',
	'#5F01DF',
	'#EB6D00',
	'#1F94F2',
	'#4F611D',
	'#9A829E',
	'#63A11B',
	'#091B6F'
];

export const instructorViewLoadingText = 'Retrieving responses from the class.';
export const studentViewLoadingText = 'Retrieving responses from your peers.';

export const getPollQuestionId = (familyId: string): string => `poll_question_${familyId}`;

export const getPollPromptBodyId = (familyId: string): string =>
	`${getPollQuestionId(familyId)}_body`;

export const getPollQuestionContentId = (familyId: string): string =>
	`${getPollQuestionId(familyId)}_content`;

export const getContextualInfoId = (datasetIndex: number): string =>
	`contextual-info-${datasetIndex}`;

/**
 * The set of contextual icons is defined is specified in T-86031
 * There will be no more than 4 datasets in a poll
 */
export const getContextualInfoIcon = (datasetIndex: number): string | undefined =>
	['*', '**', '†', '††'][datasetIndex];

const getFirstSentence = (chartType: PollChartType, isClassOnly: boolean) => {
	switch (chartType) {
		case 'bar':
			return isClassOnly
				? 'Bar chart of your class’s responses.'
				: 'Bar chart of your class’s responses compared to other data sets.';
		case 'pie':
			return 'Pie chart of your class’s responses.';
		default:
			return '';
	}
};

export const lastDescriptionSentence = 'Go to the Data tab to explore the details.';

export const getLongDescription = (
	description: string,
	chartType: PollChartType,
	isClassOnly = true
): string => {
	const firstSentence = getFirstSentence(chartType, isClassOnly);

	// If description includes HTML markup, display only the first sentence in aria-label.
	// The description itself and the last sentence will be displayed in the <figcaption>.
	if (includesMarkup(description)) return firstSentence;

	return `${firstSentence} ${description ? `${description} ` : ''}${lastDescriptionSentence}`;
};

export const getFigureCaption = (description: string): string => {
	return `${description ? `${description} ` : ''}${lastDescriptionSentence}`;
};

export const createTooltipsSingleton = (
	tooltips: Array<TippyInstance>,
	tippyProps: Partial<CreateSingletonProps> = {}
) =>
	createSingleton(tooltips, {
		placement: 'bottom-start',
		maxWidth: 185,
		theme: 'poll-chart-label',
		interactiveDebounce: 15,
		interactive: true,
		appendTo: document.body, // The `body` parent is needed to place the tooltip outside the chart and prevent any overlapping
		zIndex: 9, // Places tooltip right under the page header
		moveTransition: 'transform 0.2s ease-out',
		allowHTML: true,
		plugins: [hideOnEscPlugin, unlinkifyContentPlugin],
		...tippyProps
	});
