import * as React from 'react';

export function ChatBubbles(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 99 100">
			<g fill="none">
				<path
					d="M96.426 50.001c0 25.988-21.066 47.055-47.055 47.055-25.987 0-47.054-21.067-47.054-47.055 0-25.987 21.067-47.054 47.054-47.054 25.989 0 47.055 21.067 47.055 47.054z"
					stroke="currentColor"
					strokeWidth={4}
				/>
				<path
					d="M90.975 50.001c0 22.978-18.626 41.604-41.604 41.604-22.976 0-41.602-18.626-41.602-41.604 0-22.977 18.626-41.603 41.602-41.603 22.978 0 41.604 18.626 41.604 41.603"
					fill="currentColor"
				/>
				<path
					d="M35.867 54.478a1.38 1.38 0 0 0-1.043.472l-5.561 6.424v-5.528c0-.763-.616-1.379-1.378-1.379h-2.803a2.325 2.325 0 0 1-2.32-2.32V32a2.324 2.324 0 0 1 2.32-2.32h34.126a2.324 2.324 0 0 1 2.32 2.32v20.158a2.324 2.324 0 0 1-2.32 2.32H35.867zm28.408-2.32V32a5.075 5.075 0 0 0-5.067-5.067H25.082A5.076 5.076 0 0 0 20.014 32v20.158a5.076 5.076 0 0 0 5.068 5.067h1.446v6.076c0 1.121.908 2.03 2.04 2.03.583 0 1.144-.258 1.536-.707l6.402-7.411H59.22c2.78.012 5.055-2.263 5.055-5.055z"
					fill="#fff"
				/>
				<path
					d="M64.825 72.932a2 2 0 0 0 2.242.572 2.01 2.01 0 0 0 1.322-1.906v-6.076h1.447a5.076 5.076 0 0 0 5.067-5.068V40.297a5.076 5.076 0 0 0-5.067-5.067c-.763 0-1.379.617-1.379 1.368 0 .762.616 1.379 1.379 1.379a2.324 2.324 0 0 1 2.32 2.32v20.157a2.325 2.325 0 0 1-2.32 2.322h-2.814c-.762 0-1.379.616-1.379 1.379v5.526l-5.56-6.424a1.364 1.364 0 0 0-1.043-.471H38.412c-.763 0-1.379.617-1.379 1.379 0 .763.616 1.379 1.379 1.379h20l6.413 7.388zM43.782 42.562a1.637 1.637 0 1 1-3.274 0 1.637 1.637 0 0 1 3.274 0M49.264 42.562a1.636 1.636 0 1 1-3.273.001 1.636 1.636 0 0 1 3.273-.001M38.3 42.562a1.636 1.636 0 1 1-3.273.001 1.636 1.636 0 0 1 3.273-.001"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
