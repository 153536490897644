import { css } from '@emotion/react';

/**
 * Extracted from CastLabs provided .css file
 */

export const CastLabsStyles = () => {
	return css`
		.clpp-container {
			display: block;
			overflow: hidden;
			box-sizing: border-box;
			background-color: transparent;
			position: relative;
			padding: 0;
			padding-top: 0px;
			font-size: 10px;
			line-height: 1;
			font-weight: 400;
			font-style: normal;
			font-family: Arial, Helvetica, sans-serif;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			width: 100%;
			height: 100%;
			max-width: 800px;
		}

		.clpp-hidden {
			display: none;
		}

		.clpp-fill {
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		#sample_title {
			padding: 5px;
		}

		.clpp-ima > div {
			position: absolute;
			top: 0;
			left: 0;
		}
	`;
};
