import cloneDeep from 'lodash-es/cloneDeep';

import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

// Primaries (from design guidelines)
const primaryColors = {
	'sei-blue': '#0e5692', // SEI Blue
	'dark-sei-blue': '#00193f', // Darker
	'bowline-blue': '#00bed8', // Bowline Blue
	'dark-bowline': '#004a5d', // Darker
	'reef-red': '#ba2025', // Reef Red
	'dark-port': '#430000' // Darker
};

// Grays (from design guidelines)
const grayColors = {
	black: '#000000',
	'black-80p': '#58595b',
	'black-50p': '#939598',
	'black-17p': '#d8d9db',
	'black-5p': '#f1f1f2'
};

// Accents (from design guidelines)
// const accentColors = {
// 	sandbank:   '#eed0be',
// 	seafoam:    '#7ac29b',
// 	yellowtail: '#fad859',
// 	toro:       '#e63d5a'
// }

theme.colors.primary = '#0e5692';
theme.colors.secondary = '#f9f9f9';
theme.colors.link = (theme) => theme.colors.primary;

theme.waveformAudioPlayer.colors.background = grayColors['black-17p'];
theme.waveformAudioPlayer.colors.wave = (theme) => theme.colors.primary;
theme.waveformAudioPlayer.colors.progress = primaryColors['bowline-blue'];

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}1A`;

export default theme;
