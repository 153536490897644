import React, { forwardRef } from 'react';

import { css, ClassNames } from '@emotion/react';

import { rejoinderRootStyles } from '~/components/pageElements/MultipleChoiceQuestion/styles';
import { Theme, breakpoints, getThemeItem, ThemeName } from '~/styles/themes';

import type { MCQuestionCorrectChoice } from '~/types';

type HTMLString = string;

interface Props {
	rejoinder: HTMLString;
	correct: boolean;
	correctChoice?: MCQuestionCorrectChoice;
	className?: string;
}

const Rejoinder = forwardRef<HTMLElement, Props>((props, ref) => {
	const { rejoinder, correct, correctChoice, className } = props;

	return (
		<ClassNames>
			{({ cx }) => (
				<div css={styles} className={cx('rejoinder-container', className)}>
					<span ref={ref} tabIndex={-1} />
					<div className="rejoinder-text">
						<span className="correctness" data-correct={correct}>
							{correct ? 'Correct. ' : 'Incorrect. '}
						</span>
						<span dangerouslySetInnerHTML={{ __html: rejoinder }} />
						{!correct && correctChoice && (
							<div css={correctChoiceStyles}>
								<div className="correct-choice-body">
									<b>Correct Answer:</b>{' '}
									<span dangerouslySetInnerHTML={{ __html: correctChoice.body }} />
								</div>
								<div
									className="correct-choice-rejoinder"
									dangerouslySetInnerHTML={{ __html: correctChoice.rejoinder }}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</ClassNames>
	);
});
Rejoinder.displayName = 'Rejoinder';

export default Rejoinder;

const styles = (theme: Theme) => css`
	${rejoinderRootStyles(theme)}; // classic MCQ rejoinder styles

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		padding: 1rem 2rem 1rem 0;

		[data-in-question-deck='false'] & {
			// if standalone, counteract left padding from UniversalVelvetLeftBorder
			margin-left: -50px;
			padding-left: 50px;
		}

		[data-in-question-deck='true'] & {
			padding-left: 1.5rem;
		}
	`};

	.correctness {
		font-weight: ${theme.webtextQuestion.rejoinder.correctness.fontWeight};
		font-family: ${getThemeItem(theme.webtextQuestion.rejoinder.correctness.fontFamily, theme)};

		&[data-correct='true'] {
			color: ${getThemeItem(theme.webtextQuestion.rejoinder.correct.color, theme)};
		}

		&[data-correct='false'] {
			color: ${getThemeItem(theme.webtextQuestion.rejoinder.incorrect.color, theme)};
		}
	}

	.rejoinder-text {
		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			font-size: 18px;
			line-height: 30px;
			font-style: italic;
			color: #4e4e4e;

			& em,
			& i {
				font-style: normal;
			}

			a {
				color: ${getThemeItem(theme.colors.link, theme)};
				text-decoration: underline;
			}

			@media (max-width: ${breakpoints.small}) {
				font-size: 15px;
				line-height: 23px;
			}
		`}
	}
`;

const correctChoiceStyles = css`
	.correct-choice-body,
	.correct-choice-rejoinder {
		margin-top: 1rem;
	}

	b {
		font-weight: 500;
	}
`;
