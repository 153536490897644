import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';

import { ClassNames } from '@emotion/react';
import last from 'lodash-es/last';
import { useResizeObserver } from 'usehooks-ts';
import { shallow } from 'zustand/shallow';

import { EditModeStateContext } from '~/components/WritingTemplate/EditMode/providers/EditModeStateProvider';
import CellPlaceholder from '~/components/WritingTemplate/Spreadsheet/Components/CellPlaceholder';
import { useDoubleTap } from '~/hooks';
import { BatchProcessor } from '~/utils/BatchProcessor';

import ElementsContext from '../../context/ElementsContext';
import { getIsFormula } from '../../helpers';
import {
	getActiveAreaId,
	selectableContainerId,
	separateInputAreaButtonAccept,
	separateInputAreaButtonDiscard,
	separateInputAreaId
} from '../../helpers/constants';
import {
	getCellCoordinates,
	getEndCell,
	getStartCell,
	normalizeRange,
	shiftPosition
} from '../../helpers/selection';
import {
	CellsRowHeight,
	ContextMenuParams,
	Dir,
	FormatType,
	KeyCodes,
	Result,
	Cell as RowCell
} from '../../helpers/types';
import useOuterClick from '../../hooks/useClickOutside';
import { useHover } from '../../hooks/useHover';
import { useSpreadsheetSelector } from '../../store/provider';
import {
	selectCellValue,
	selectEditableCell,
	selectEditingCell,
	selectIsCellInsideSelection
} from '../../store/selectors';
import CellInput from '../CellInput';
import { ValidateType, ValueError, getValueError } from './blur-errors.helper';
import { cellElementStyle, replaceElementStyle, style as tableItemStyle } from './styles';
import {
	getAvailableNeighbors,
	getCellsRowHeight,
	getFormattedValue,
	getIsWidthOverflow,
	getWidthFromNeighbors,
	showFormatting,
	transformFinalInputValue
} from './utils';

interface Props {
	cell: RowCell;
	callbacks: {
		debouncedShowErrorPanel: (address: string, message?: string) => void;
		debouncedShowContextMenu: (props: ContextMenuParams) => void;
	};
	cellRowHeightsBatch: BatchProcessor<CellsRowHeight>;
}

const Cell: React.FC<Props> = (props) => {
	const {
		cell,
		callbacks: { debouncedShowErrorPanel, debouncedShowContextMenu },
		cellRowHeightsBatch
	} = props;

	const { address, formatting, isOverflow, importedStyle, placeholder } = cell;

	const [cellWhenInvalidWidth, setCellInvalidWidth] = useState<number>(null);
	const [isReplaceLongNumber, setIsReplaceNumber] = useState(false);

	const {
		cellValue,
		isEditable,
		isEditing,
		isSelected,
		isInvalid,
		formulaInputMethod,
		isCellInLongSelection,
		editableRanges,
		cellValues,
		isSPFocused,
		setFocused
	} = useSpreadsheetSelector(
		(state) => ({
			cellValue: selectCellValue(state, address),
			isEditable: selectEditableCell(state, address),
			isEditing: selectEditingCell(state, address),
			isSelected: state.selectedCell === address,
			isInvalid: state.invalidCell === address,
			formulaInputMethod: state.formulaInputMethod,
			isCellInLongSelection: selectIsCellInsideSelection(state, address),
			editableRanges: state.editableRanges,
			cellValues: state.cellValues,
			isSPFocused: state.focused,
			setFocused: state.setFocus
		}),
		shallow
	);

	const {
		setEditing,
		updateCellValue,
		setSelectedCell,
		createSelectionWith,
		pushFormulaValue,
		updateRuntimeEditableErrors,
		updateAnnouncementSR,
		dest,
		inputValue,
		setInputValue,
		setActiveAreaId,
		selectedCells,
		setSelectedCells,
		updateContextProps,
		updateOnBlurErrorMessages
	} = useSpreadsheetSelector(
		(state) => ({
			setEditing: state.setEditingCell,
			updateCellValue: state.updateCellValue,
			setSelectedCell: state.setSelectedCell,
			createSelectionWith: state.createSelectionWith,
			pushFormulaValue: state.pushFormulaSelectedValue,
			updateRuntimeEditableErrors: state.updateRuntimeEditableErrors,
			updateAnnouncementSR: state.updateAnnouncementSR,
			dest: state.dest,
			invalidCell: state.invalidCell,
			setActiveAreaId: state.setActiveAreaId,
			selectedCells: state.selectedIndexes,
			setSelectedCells: state.setSelectedCells,
			updateContextProps: state.updateContextProps,
			inputValue: state.activeInputValue,
			setInputValue: state.updateActiveInputValue,
			updateOnBlurErrorMessages: state.updateOnBlurErrorMessage
		}),
		shallow
	);

	const cellContainerRefTemp = useRef<HTMLTableCellElement | null>(null);
	const { width: cellColumnWidth = 0, height: rowInitialHeight = 0 } = useResizeObserver({
		ref: cellContainerRefTemp,
		box: 'border-box'
	});

	const cellContainerRef = useOuterClick<HTMLTableCellElement>(
		(clickEvent) => {
			if (isEditing && formulaInputMethod !== 'selection') closeEditAndSave();

			if (
				isEditing &&
				formulaInputMethod === 'selection' &&
				(clickEvent.target as HTMLElement).closest('.mouse-selection-area') == null
			)
				closeEditAndSave();
		},
		[
			getActiveAreaId(dest),
			separateInputAreaId,
			separateInputAreaButtonAccept,
			separateInputAreaButtonDiscard
		],
		cellContainerRefTemp
	);

	const isHovered = useHover(cellContainerRef.current);

	const cellElementRef = useRef<HTMLDivElement>(null);
	const cellOverflowElementRef = useRef<HTMLDivElement>();

	const { addReference, cellsRefs, windowSize, blurErrorsRules, resetCachedSelection } =
		useContext(ElementsContext);

	const editModeState = useContext(EditModeStateContext);
	const spreadsheetProps = editModeState?.spreadsheetProps;

	const cellError: ValueError = useMemo(() => {
		const { attributes, value } = cellValue;
		const cellBlurErrorsRules = blurErrorsRules[address];

		const valueAsString = String(value);

		return getRuntimeError();

		function getValueBasedError(input: string, validateType: ValidateType) {
			/**
			 * There is no blur error all error fully controlled by formula
			 */
			if (!cellBlurErrorsRules) {
				return;
			}

			/**
			 * Get errors based on entered value
			 */
			const inputValue =
				formatting.type === FormatType.Percent && validateType === ValidateType.Value
					? input.replace('%', '')
					: input;

			return getValueError({ value: inputValue, validateType, cellBlurErrorsRules });
		}

		function getRuntimeError(): ValueError {
			if (attributes?.error) {
				spreadsheetProps?.spiRuntimeErrorCallback?.(address, attributes.error, cellValues[address]);
				return { type: 'formula', message: attributes.error };
			}

			/**
			 * Rare case when formula does not include attribute and represented as a string;
			 * It happens when you paste a formula and cellValue already written to the store, but evaluatedCellValues does not contain result yet.
			 * In this case formula represents sting value and run validation process.
			 */
			const isFormulaWithoutAttributes = getIsFormula(valueAsString) && !attributes;
			if (!valueAsString || !cellBlurErrorsRules || isFormulaWithoutAttributes) return null;

			if (!attributes) return getValueBasedError(valueAsString, ValidateType.Value);

			return getValueBasedError(valueAsString, ValidateType.Formula);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cellValue.value, blurErrorsRules, address, formatting.type]);

	useEffect(() => {
		if (cellContainerRef.current) {
			addReference(address, cellContainerRef);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address, cellContainerRef]);

	useEffect(() => {
		/**
		 * Disable error on these circumstances
		 */
		if (!cellError || isEditing || !isHovered) {
			debouncedShowErrorPanel(null);
			return;
		}
		debouncedShowErrorPanel(address, cellError.type === 'accept-only' ? cellError.message : null);
		return;
	}, [isHovered, address, isEditing, cellError, debouncedShowErrorPanel]);

	/**
	 * used as a separate useEffect, because listening to isSelected during the clearing error state brings unexpected results
	 * When a cell becomes unselected and it does not have an error it clears the error display state for the selected cell
	 * This useEffect only triggers an error in case of an error in a cell and the cell becomes selected, we need it inside a cell because of the custom message that lives only inside a cell
	 * however clearing on deselect is an SPI responsibility and ErrorMessage.tsx:96
	 */
	useEffect(() => {
		if (isSelected && cellError && isSPFocused)
			debouncedShowErrorPanel(address, cellError.type === 'accept-only' ? cellError.message : null);
	}, [isSelected, cellError, address, debouncedShowErrorPanel, isSPFocused]);

	useEffect(() => {
		if (!isSelected || isEditing || !isSPFocused) return;

		cellContainerRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'center'
		});
	}, [isSelected, isEditing, cellContainerRef, isSPFocused]);

	const closeEditAndSave = () => {
		setEditing(null);
		updateCellValue(address, transformFinalInputValue(inputValue, formatting));
	};

	const formattedValue = useMemo(() => {
		const { value, attributes } = cellValue;

		if (attributes && !attributes.success) return String(value);
		if (cellError) return '#ERROR!';

		return getFormattedValue(value, formatting ?? {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cellValue.value, formatting, cellError]);

	useEffect(() => {
		if (isOverflow) return;

		const cellHeight = getCellsRowHeight({
			cellElement: cellElementRef.current,
			cellContainer: cellContainerRef.current,
			address,
			formattedValue: String(formattedValue)
		});

		cellRowHeightsBatch.add(cellHeight);
		if (address === last(Object.keys(cellsRefs))) cellRowHeightsBatch.apply();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isOverflow,
		formattedValue,
		cellContainerRef,
		isEditing,
		windowSize,
		address,
		cellColumnWidth,
		cellsRefs
	]);

	const cellClassNames = useMemo(() => {
		const { horizontal, vertical } = getStartCell([address]);
		return `cellId${String.fromCharCode(horizontal)} rowId${vertical}`;
	}, [address]);

	const columnLetter = useMemo(() => {
		const { horizontal, vertical } = getStartCell([address]);
		return `${String.fromCharCode(horizontal)} row${vertical}`;
	}, [address]);

	/**
	 * Store of all available neighbors for cell, already calculated with not editable -> editable restriction
	 */
	const availableNeighbors = useMemo(() => {
		if (!isOverflow) {
			return [];
		}

		return getAvailableNeighbors({
			address,
			cellsRefs,
			editableRanges,
			cellValues,
			neighbors: []
		});
	}, [address, cellsRefs, editableRanges, isOverflow, cellValues]);

	const isOverflowing: { enabled: boolean; width?: number } = useMemo(() => {
		setIsReplaceNumber(false);
		const isWidthOverflow = getIsWidthOverflow(
			cellElementRef,
			cellContainerRef,
			String(formattedValue)
		);

		/**
		 * Long numbers will be shown as #####
		 */
		if (!availableNeighbors.length && isWidthOverflow && !Number.isNaN(+cellValue.value)) {
			setIsReplaceNumber(true);
			return { enabled: false };
		}

		/**
		 * Falsy values, any of these is not match the overflow requirements
		 */
		if (!availableNeighbors.length || cellError) return { enabled: false };
		if (!isWidthOverflow) return { enabled: false };

		const width = getWidthFromNeighbors({
			neighbors: availableNeighbors,
			width: cellElementRef.current.offsetWidth,
			elements: cellsRefs,
			cellElementRef,
			cellContainerRef,
			evaluatedValue: String(formattedValue)
		});

		return { enabled: true, width };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		availableNeighbors,
		formattedValue,
		cellsRefs,
		cellError,
		cellContainerRef,
		cellValue.value,
		/**
		 * Window size is used here, because when window became smaller we need to re-calculate width
		 */
		windowSize
	]);

	/**
	 * Using the `blockTapEvents` to handle just a single tap event type at a time
	 * When the double tap was registered - we shouldn't handle the single tap on the cells.
	 * As it'll lead to an unwanted appearance of the context menu.
	 *
	 * note: this feature was added to handle unstable iOS taps processing. It cannot register _just_ the double tap, it captures each tap that leads to showing the context menu on the cell that's being edited
	 */
	const blockTapEvents = useRef(false);

	useEffect(() => {
		if (!isEditing) blockTapEvents.current = false;
	}, [isEditing]);

	useEffect(() => {
		updateRuntimeEditableErrors(address, Boolean(cellError));
		if (cellError != null && spreadsheetProps != null && cellError.violatedOnBlurRule != null) {
			spreadsheetProps.spiOnBlurErrorCallback?.(address, cellError, cellValues[address]);
		}

		updateOnBlurErrorMessages({
			[address]: cellError?.type === 'accept-only' ? cellError.message : null
		});
	}, [
		cellError,
		updateRuntimeEditableErrors,
		spreadsheetProps,
		cellValues,
		address,
		updateOnBlurErrorMessages
	]);

	/**
	 * Worth looking this https://github.com/soomo/soomo-libs/pull/1685#discussion_r1395870098
	 */
	const handleDoubleClick = useCallback(() => {
		/**
		 * For mobile app only, make cell explicitly selected and SPI focused
		 * iOS mobile app doesn't do this before making cell editing, and this make special input area behave unexpectedly
		 */
		if (spreadsheetProps?.mobile) {
			setFocused(true);
			setSelectedCell(address);
		}

		if (isEditable) {
			setActiveAreaId(getActiveAreaId(dest));
			setEditing(address);
			cellContainerRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center'
			});
			blockTapEvents.current = true;
		}
	}, [
		address,
		dest,
		isEditable,
		setActiveAreaId,
		setEditing,
		cellContainerRef,
		setSelectedCell,
		setFocused,
		spreadsheetProps?.mobile
	]);

	const handleDoubleTap = useDoubleTap(handleDoubleClick);

	const cellViewElement = useMemo(() => {
		const placeholderElement = <CellPlaceholder content={placeholder} show={!formattedValue} />;
		return (
			<>
				<div
					data-key={`cell-${address}`}
					ref={cellElementRef}
					className={`cell ${columnLetter}`}
					onDoubleClick={handleDoubleClick}
					data-placeholder-alignment={!formattedValue}
					css={(theme) =>
						cellElementStyle(
							theme,
							isOverflowing.enabled,
							cellOverflowElementRef.current?.offsetHeight
						)
					}
					{...handleDoubleTap}>
					{placeholderElement}
					<div className="selectable" data-key={address} id={isSelected && selectableContainerId} />
					{formattedValue}
				</div>
				{isOverflowing.enabled && (
					<div
						aria-hidden
						className="replacer"
						ref={cellOverflowElementRef}
						css={replaceElementStyle(isOverflowing.width, isInvalid)}>
						{formattedValue}
					</div>
				)}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		address,
		columnLetter,
		formattedValue,
		isEditable,
		placeholder,
		isInvalid,
		handleDoubleTap,
		isOverflowing.width,
		isOverflowing.enabled
	]);

	const cellInputElement = useMemo(
		() => (
			<CellInput
				address={address}
				className={columnLetter}
				inputValue={inputValue}
				handleChange={setInputValue}
				closeEditAndSave={closeEditAndSave}
				initialContainerHeight={rowInitialHeight}
			/>
		),
		[formattedValue, address, columnLetter, inputValue, rowInitialHeight]
	);

	const cellElement = useMemo(() => {
		if (!isEditing) return cellViewElement;

		return cellInputElement;
	}, [cellViewElement, cellInputElement, isEditing]);

	const cellFormatting = useMemo(() => {
		// Removes the () added by the accounting formatting
		const sanitizedValue =
			typeof formattedValue === 'string' ? +formattedValue?.replace(/[(),]/g, '') : NaN;

		return showFormatting(isEditing, `${cellValue.value}`) &&
			!cellError &&
			!Number.isNaN(sanitizedValue)
			? formatting
			: {};
	}, [formattedValue, isEditing, cellError, formatting, cellValue.value]);

	/**
	 * We have cases when we have to show error with value and value should be left aligned
	 * We rely on the ' ' on the start of value, because formula inject it space when generate error
	 */
	const isLeftAlignedError = useMemo(() => {
		/**
		 * Without this line it always crashes with error
		 * https://ibb.co/7rrz5nG
		 * I believe it happens when we reference on error and then error disappears, but not sure why (I wasn't able to track this).
		 */
		if (typeof formattedValue !== 'string') return;
		return cellError && formattedValue?.startsWith(' ');
	}, [cellError, formattedValue]);

	const cellPositioning = useMemo<{ isLastColumn: boolean; isLastRow: boolean }>(() => {
		/**
		 * In the table container cell borders are different, that depends on the cell position.
		 * Regular cell should have wider border on the right and bottom (3px).
		 * Edge cells should have regular borders instead (2px).
		 * Here we're going to spice up displaying with some attributes and define how it will look like inside styles.
		 */

		const availableCells = Object.keys(cellsRefs);

		/**
		 * Taking cell coordinates and last cell coordinate, then compare.
		 */
		const cellCoordinates = getCellCoordinates(address);
		const lastCellCoordinates = getEndCell(availableCells);

		return {
			isLastColumn: cellCoordinates.horizontal === lastCellCoordinates?.horizontal,
			isLastRow: cellCoordinates.vertical === lastCellCoordinates?.vertical
		};
	}, [cellsRefs, address]);

	const getCellInvalidWidth = useCallback(() => {
		/**
		 * When cell is not editable we're not really care about this bc nobody will switch mode.
		 */
		if (!isEditable || !cellContainerRef?.current) return;

		if (isInvalid && !isEditing) {
			/**
			 * Calculate new cell width value when it's invalid and not is editing.
			 * That value will replace current width in editing cell mode
			 * (for example width 204px, when you start editing width reduce to 200, bc of borders hideout
			 * Why this happening? Styles move from td(container) to textarea, because textArea can increase and overflow td in height or/and width.)
			 * Otherwise, we will have jumps when you switch from view cell mode to editing mode.
			 */

			const {
				current: { offsetWidth }
			} = cellContainerRef;

			/**
			 * We re-define this only because width can change during the work.
			 */
			setCellInvalidWidth(offsetWidth);

			return offsetWidth;
		}

		return cellWhenInvalidWidth;
	}, [isInvalid, isEditable, isEditing, cellContainerRef, cellWhenInvalidWidth]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
		if (event.key === KeyCodes.Escape) {
			event.preventDefault();
			setEditing(null);
			return;
		}

		if (event.key === KeyCodes.Tab && event.shiftKey) {
			selectNextCell(event, shiftPosition(Dir.Left, address, cellsRefs));
			return;
		}

		if (event.key === KeyCodes.Tab) {
			selectNextCell(event, shiftPosition(Dir.Right, address, cellsRefs));
			return;
		}
	};

	function selectNextCell(
		event: React.KeyboardEvent<HTMLTableCellElement>,
		nextCell: { result: Result; key: string; message?: string }
	) {
		event.preventDefault();

		if (nextCell.result === Result.Accept) {
			setSelectedCell(nextCell.key);
			closeEditAndSave();
			return;
		}

		setSelectedCell(address);
		/**
		 * Announce special value, that warn about that we don't have any next cell
		 */
		updateAnnouncementSR('No more cells');
		closeEditAndSave();
	}

	const handleMouseDown = (event: React.MouseEvent<HTMLTableCellElement>) => {
		/**
		 * Selected the cell during editing another cell
		 */
		if (formulaInputMethod === 'selection' && !isEditing) {
			return event.shiftKey
				? createSelectionWith(normalizeRange, address)
				: pushFormulaValue(address);
		}

		if (isCellInLongSelection && event.button === 2) return;

		if (!event.shiftKey) {
			if (isSelected) return;
			setSelectedCell(address);
			return;
		}

		createSelectionWith(normalizeRange, address);
	};

	const [touchStartPosition, setTouchStartPosition] = useState<{ x: number; y: number }>(null);
	const contextMenuTimerRef = useRef<ReturnType<typeof setTimeout>>(null);
	const onTouchEndCallbackRef = useRef<() => void | null>(null);
	const handleTouch = (e: React.TouchEvent<HTMLTableDataCellElement>) => {
		const touch = e.touches[0];
		if (!touch || !isSPFocused) return;
		const { clientX, clientY } = touch;
		setTouchStartPosition({ x: clientX, y: clientY });

		/**
		 * In case if user make a short-time touch we call the context menu if cell was previously selected or change selection
		 * when touch take more than the 1s callback will change and call the context menu for the whole selection range
		 * Otherwise, if click is out of selection, it will make clicked cell is `selected` and call the context menu
		 */
		onTouchEndCallbackRef.current = function () {
			updateContextProps(null);
			if (selectedCells.length > 1) {
				setSelectedCells([address]);
				resetCachedSelection?.([address]);
				return;
			}

			if (isSelected && !blockTapEvents.current) {
				updateContextProps({ mouseX: clientX, mouseY: clientY, accessBy: 'touch' });
			}
		};

		contextMenuTimerRef.current = setTimeout(() => {
			onTouchEndCallbackRef.current = function () {
				if (selectedCells.includes(address) && selectedCells.length > 1) {
					updateContextProps({ mouseX: clientX, mouseY: clientY, accessBy: 'touch' });
					return;
				}

				setSelectedCell(address);
				debouncedShowContextMenu({ mouseX: clientX, mouseY: clientY, accessBy: 'touch' });
			};
		}, 300);
	};

	const clearTouchCallback = () => {
		clearTimeout(contextMenuTimerRef.current);
		onTouchEndCallbackRef.current = null;
	};

	const handleTouchMove = (e: React.TouchEvent<HTMLTableDataCellElement>) => {
		/**
		 * On touch move reset everything
		 * iOS counts slight move differently, minimal movement will reset operation, added some buffer fo not resetting
		 * In case touchStartPosition is not set, that means user "moving" SPI, without actually focusing it
		 */
		if (isIOS && touchStartPosition) {
			const movedTouch = e.touches[0];
			if (!movedTouch) return;

			const nonScrollRange = 20;
			const { clientX: movedX, clientY: movedY } = movedTouch;
			const { x: startX, y: startY } = touchStartPosition;
			if (
				Math.abs(movedX - startX) <= nonScrollRange &&
				Math.abs(movedY - startY) <= nonScrollRange
			)
				return;
		}

		clearTouchCallback();
	};

	const handleTouchEnd = () => {
		/**
		 * In case of the SPI was not focused and click was made just do nothing and clear callback if it was set previously
		 */
		if (!isSPFocused) {
			/**
			 * In case when the SPI isn't focused, the `onMouseDown` is just not firing! However, `onTouch` event works as expected
			 * This doesn't happen when the SPI is focused! Both events are registered then.
			 * For iOS specifically we're calling the `setSelectedCell` when the touch event ends. In other cases `handleClick` will do the job 👌🏻
			 */
			if (isIOS) {
				setSelectedCell(address);
			}

			clearTouchCallback();
			return;
		}

		const onTouchEndCallback = onTouchEndCallbackRef.current;
		/**
		 * When cell is editing we don't need to invoke here anything even if callback defined
		 */
		if (onTouchEndCallback && !isEditing) {
			setFocused(true);
			setTimeout(() => {
				onTouchEndCallback();
			}, 200);
		}
	};

	return (
		<ClassNames>
			{({ cx }) => (
				<td
					style={rowInitialHeight && isEditing ? { height: `${rowInitialHeight}px` } : null}
					className={cx(cellClassNames, 'cellContainer')}
					data-key={address}
					aria-hidden={!isEditing}
					ref={cellContainerRef}
					onTouchStart={handleTouch}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					onKeyDown={handleKeyDown}
					onMouseDown={handleMouseDown}
					css={() =>
						tableItemStyle({
							isEditable,
							cellFormatting,
							importedStyle,
							withError: Boolean(cellError && !isEditing),
							isLeftAlignedError,
							isInvalid,
							isSelected,
							isEditing,
							isReplaceLongNumber,
							cellPositioning,
							cellOriginWidth: getCellInvalidWidth(),
							isOverflowEnabled: isOverflow,
							isSPFocused
						})
					}>
					{cellElement}
				</td>
			)}
		</ClassNames>
	);
};

export default Cell;
