import type React from 'react';

import BigQuoteEpigraphStyles from './BigQuoteEpigraphStyles';

export interface Props {
	text: string;
	author: string;
	citation: string;
}

const BigQuoteEpigraph: React.FC<Props> = (props) => (
	<div css={BigQuoteEpigraphStyles}>
		<div className="page-element big-quote-epigraph">
			<div className="backdrop">
				<div className="quoteCard">
					<div className="icon not-annotatable">“</div>
					<div className="text" dangerouslySetInnerHTML={{ __html: props.text }} />
					<div className="source">
						<div className="author" dangerouslySetInnerHTML={{ __html: props.author }} />
						<div className="citation" dangerouslySetInnerHTML={{ __html: props.citation }} />
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default BigQuoteEpigraph;
