import { SerializedStyles, css } from '@emotion/react';

import { mixins, breakpoints, ThemeName, Theme } from '~/styles/themes';

export default (theme: Theme): SerializedStyles => {
	return css`
		.posted-answers-list {
			margin: 0;
			padding: 0;
			list-style: none;
			.posted-answers-list-item {
				padding: 0;
			}
		}

		.posted-answers-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.75em;

			&.show-responses {
				margin-bottom: 2em;
			}

			@media (max-width: ${breakpoints.small}) {
				button {
					margin-bottom: 0px;
					text-align: right;
				}
			}
		}

		.posted-answers-footer {
			&::before {
				content: 'SPACER';
				margin: 1px auto 1px 1px;
				visibility: hidden;
			}

			display: flex;
			justify-content: center;

			@media (max-width: ${breakpoints.small}) {
				display: inherit;
				button {
					width: 100%;
				}
			}

			button {
				margin-left: auto;
			}
		}

		.sr-only {
			${mixins.webtextHiddenAccessible(theme)}
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.only-mobile {
				@media (min-width: ${breakpoints.small}) {
					display: none;
				}
			}

			.response-board-responses {
				margin-top: 33px;

				.divider {
					background: none;
					border-bottom: 1px dashed #979797;
					margin-bottom: 33px;
				}

				.all-responses {
					font-family: "NHaasGroteskTXW01", Helvetica, Arial, sans-serif;
					font-size: 18px;
					line-height: 30px;
					font-weight: bold;
					color: #000000;
				}

				.posted-answers-container {
					padding: 15px;
					background: #fefefe;
					border-radius: 8px;
					margin-bottom: 20px;
				}

				.posted-answers-footer {
					@media (max-width: ${breakpoints.small}) {
						.show-responses {
							width: initial;
							display: block;
							margin: auto;
						}
					}
				}
			}

			@media (max-width: ${breakpoints.small}) {
				.posted-answers-header {
					display: block;
					span {
						display: block;
					}
					button {
						width: initial;
						margin-left: 0;
					}
				}
		`}
	`;
};
