import React, { FC } from 'react';

import { css } from '@emotion/react';

import { CorrectnessIcon } from '~/notebook/components';
import { MCQuestionPoolAnswer } from '~/notebook/types';
import { Theme } from '~/styles/themes';

import { expandablePanelStyles } from '../../ExpandablePanel/ExpandablePanel';
import ActivityHeader, { Props as ActivityHeaderProps } from '../ActivityHeader';

import type { MCQuestionPoolItem } from '~/types/WebtextManifest';

export interface Props extends Pick<ActivityHeaderProps, 'timeZone'> {
	question: MCQuestionPoolItem;
	answer: MCQuestionPoolAnswer;
}

const MultipleChoiceQuestionPoolHistoryItem: FC<Props> = (props) => {
	const { question, answer, timeZone } = props;

	const choice = question.choices.find((ch) => ch.family_id === answer.body);
	return (
		<div css={expandablePanelStyles.historyItem}>
			<ActivityHeader timestamp={answer.saved_at} timeZone={timeZone}>
				<div
					css={mcpqHistoryItemStyles.questionBody}
					dangerouslySetInnerHTML={{ __html: question.body }}
				/>
				<div css={mcHistoryItemStyles.status}>
					<CorrectnessIcon css={mcHistoryItemStyles.icon} correct={answer.correct} />
					<span dangerouslySetInnerHTML={{ __html: choice.body }} />
				</div>
			</ActivityHeader>
		</div>
	);
};

// TODO Create reusable MC Question History Item
const mcHistoryItemStyles = {
	status: (theme: Theme) => css`
		display: flex;
		align-items: center;
		column-gap: 8px;
		color: ${theme.colors.black};
		line-height: 1.4;
	`,
	icon: css`
		position: relative;
		top: 2px;
	`
};

const mcpqHistoryItemStyles = {
	questionBody: (theme: Theme) => css`
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: bold;
		color: ${theme.colors.black};
	`
};

export default MultipleChoiceQuestionPoolHistoryItem;
