import { getIsTouchScreenDevice } from '~/utils';

import { IDictionary } from '../../WritingTemplate/types';
import { Graph } from '../Graph';
import { SpreadsheetState } from '../store/store';
import { getCellAddress } from './address';
import { getCellValue } from './compute';
import { checkIsAddress } from './formula/utils';
import { getCopyCacheKey, getIsRange, getRangeAddresses } from './index';
import { CellValues, EditableRanges, Sheet, EvaluatedCells } from './types';

type PartialSpreadsheetState = Omit<SpreadsheetState, 'cellValues' | 'evaluatedFormulasValues'>;

export const createInitialState = (props: {
	dest: string;
	sheet: Sheet;
	readOnly: boolean;
	isolated: boolean;
}): PartialSpreadsheetState => {
	const { dest, sheet, readOnly, isolated } = props;

	/**
	 * On first initialize focus is always false;
	 */
	const focused = false;

	/**
	 * Initialize empty graph in this step
	 */
	const graph = new Graph();

	/**
	 * There are no selection on first initialize
	 */
	const selectedIndexes: string[] = [];
	const selectedCell = null;

	/**
	 * Formula input method always 'input' for initialize and change only during typing into a cell
	 */
	const formulaInputMethod = 'input';

	/**
	 * Formula selected value is always empty for initialize and change only during selecting with formulaInputMethod = selection
	 */
	const formulaSelectedValue = '';

	/**
	 * Origin is a value defined in schema, it creates shift for the whole list of spreadsheet
	 * default: A1
	 */
	const origin = selectOrigin(sheet);

	/**
	 * Editable is read-only structure from sheet config, it allows you to change cell value in a process of work.
	 */
	const editableRanges = mapEditableRanges(sheet, readOnly, isolated);

	/**
	 * On first initialize there are no editingCell;
	 */
	const editingCell = null;

	/**
	 * This value may appear when user start typing directly on the spreadsheet instead of enter edit mode
	 */
	const enterWithString = null;

	/**
	 * We need to control row height according to the biggest cell value
	 * because instead they will collapse
	 */
	const cellsRowHeight = {};

	/**
	 * Errors that appears during formula (or formula chain) exec, by default it's empty, once error will be shown
	 * it will be tagged in this object
	 */
	const runtimeEditableErrors = {};

	/**
	 * When user hovering cell with error we're showing action window with explanation message
	 */
	const displayErrorFor = { address: null, message: null };

	/**
	 * When user enter edit mode and inside the cell stored formula, we're highlighting this formula ranges and cells
	 * in special way on the sheet.
	 */
	const cellHighlights = [];

	/**
	 * This key is used to store and get (additional metadata) data from local storage during copying
	 */
	const copyCacheKey = getCopyCacheKey(dest);

	/**
	 * Define where show custom right-click context
	 */
	const contextMenuProps = null;

	/**
	 * There are no invalid range|cell on first load
	 */
	const invalidRange = [];
	const invalidCell = null;

	/**
	 * SR announcement is empty on first initialize
	 */
	const announcementSR = '';
	const isTouchScreen = getIsTouchScreenDevice();

	/**
	 * SPI on touch devices update;
	 * runtime input defined as empty string on first initialize because it exist only on cell edit and used for bounding inputs value
	 * isTouchScreen defined as null on first initialize because it takes from the hook, which is not available here
	 */
	const activeInputValue = '';
	const activeAreaId = '';
	const touchMode = null;

	return {
		dest,
		graph,
		origin,
		focused,
		cellHighlights,
		selectedCell,
		editingCell,
		announcementSR,
		invalidRange,
		invalidCell,
		editableRanges,
		enterWithString,
		selectedIndexes,
		copyCacheKey,
		displayErrorFor,
		contextMenuProps,
		formulaInputMethod,
		formulaSelectedValue,
		cellsRowHeight,
		runtimeEditableErrors,
		touchMode,
		activeInputValue,
		isTouchScreen,
		activeAreaId
	};
};

/**
 * Map over the sheet['editable-ranges'] and find valid address ranges and addresses,
 * write them in flag object like { ... A1: true, B1: true ...}
 */
const mapEditableRanges = (sheet: Sheet, readOnly: boolean, isolated: boolean): EditableRanges => {
	if (readOnly || isolated) return {};

	const definedEditableRanges = selectEditable(sheet);

	return Object.fromEntries(
		definedEditableRanges
			.flatMap((range) => {
				/**
				 * Checking whether it's a range or individual cell value
				 */
				if (getIsRange(range)) {
					return getRangeAddresses(range).value || [];
				}

				const isAddress = checkIsAddress(range);
				if (!isAddress) return [];

				return range;
			})
			.map((address) => [address, true])
	);
};

const selectOrigin = (sheet: Sheet): string => sheet.origin ?? 'A1';
const selectEditable = (sheet: Sheet): string[] => sheet['editable-ranges'] ?? [];

/**
 * Map over sheet.cells and stored values to make the cellValues initial state
 * this happens once when spreadsheet initialize
 */
export const initializeCellValues = (props: {
	sheet: Sheet;
	graph: Graph;
	origin: string;
	inputs: IDictionary;
	isolated: boolean;
	editableCoordinates: EditableRanges;
	storedValues: CellValues;
}): CellValues => {
	const { sheet, origin, inputs, graph, isolated, editableCoordinates, storedValues } = props;
	const { cells: rows } = sheet;

	if (!rows) throw new Error('Spreadsheet cells are not provided.');

	const cellValues: CellValues = {};
	const evaluatedSpreadsheetSourcesCache = {};

	const updateEvaluatedSourcesCache = (source: string, evaluatedSources: EvaluatedCells) =>
		(evaluatedSpreadsheetSourcesCache[source] ??= evaluatedSources);
	/**
	 * Map over cells defined in schema and fill cellValues, also fill a graph
	 */
	rows.forEach((row, rowIndex) => {
		row.forEach((cell, columnIndex) => {
			const address = getCellAddress(rowIndex, columnIndex, origin);
			/**
			 * In rare cases this function can return number, we're casting all values to string for avoiding it
			 */
			const cellValue = String(
				getCellValue({
					cellConfig: cell,
					address,
					inputs,
					isolated,
					editableCoordinates,
					storedValues,
					evaluatedSpreadsheetSourcesCache,
					updateEvaluatedSourcesCache
				})
			);
			cellValues[address] = cellValue;
			graph.addVertex(address, cellValue);
		});
	});

	return cellValues;
};
