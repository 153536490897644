import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export default (theme) =>
	theme.name === ThemeName.UNIVERSAL_VELVET
		? universalVelvetStylesEmphasized(theme)
		: baseVelvetStyles(theme);

const baseVelvetStyles = (theme) => css`
	margin: -1px 0 30px 40px !important;
	padding: 0 20px 3px 20px !important;
	border-left: ${theme.webtextBlockquote.borderLeft};

	em {
		color: inherit;
		font-style: normal;
	}

	strong {
		color: inherit;
		font-weight: bold;
	}

	cite {
		font-size: 16px;
		display: block;
		text-align: right;
		margin-top: 15px;
		padding-right: 10px;
	}

	@media (max-width: ${breakpoints.small}) {
		font-size: 15px !important;
		line-height: 26px;
		letter-spacing: 0.22px;
	}
`;

const universalVelvetStylesEmphasized = (theme) => css`
	margin: -1px 0 30px 40px !important;
	border-left: 4px solid #d7d7d7;
	padding: 0 20px 3px 20px !important;

	@media (max-width: ${breakpoints.small}) {
		margin-bottom: 0 !important;
	}

	> p:last-child {
		margin-bottom: 0;
	}

	em {
		color: inherit;
		font-style: normal;
	}

	strong {
		color: inherit;
		font-weight: bold;
	}

	cite {
		font-size: 16px;
		display: block;
		text-align: right;
		margin-top: 15px;
		margin-bottom: 15px;
		padding-right: 10px;
	}

	// Blockquote Icon
	svg:first-of-type {
		color: ${theme.colors.primary};
		margin-left: -2.2em;
		position: absolute;
		font-size: 37px !important;

		@media (max-width: ${breakpoints.small}) {
			margin-left: -2.2em;
			font-size: 30px !important;
			position: absolute;
		}
	}
`;
