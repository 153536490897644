import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';

import WebtextButton from '~/components/WebtextButton';

import { ChartState, Tabs as TabsEnum } from '../../types';
import ChartSelection from '../ChartSelection';
import DataTable from '../DataTable';
import TabPanel from '../TabPanel';
import { chartContainer, resetButton as resetButtonStyles, tabButton, tabControls } from './styles';

interface Props {
	chartState: ChartState;
	isAllowReset: boolean;
	resetChart: () => void;
	isNewlyCreated: boolean;
}

const ChartTabs: React.FC<Props> = ({ chartState, resetChart, isAllowReset, isNewlyCreated }) => {
	const [currentTab, setCurrentTab] = useState(0);

	const handleTabChange = (_: React.SyntheticEvent, newTabValue: number) => {
		setCurrentTab(newTabValue);
	};

	const a11yProps = (index: number) => ({
		id: `tab-${index}`,
		'aria-controls': `tabpanel-${index}`
	});

	const getIsTabSelected = (tabIdx: number) => currentTab === tabIdx;

	return (
		<Box css={chartContainer}>
			<Box css={tabControls}>
				<Tabs
					value={currentTab}
					selectionFollowsFocus
					onChange={handleTabChange}
					TabIndicatorProps={{
						style: {
							display: 'none'
						}
					}}>
					<Tab
						label={TabsEnum[TabsEnum.Chart]}
						css={tabButton(getIsTabSelected(TabsEnum.Chart))}
						{...a11yProps(TabsEnum.Chart)}
						data-ignore="1"
					/>
					<Tab
						label={TabsEnum[TabsEnum.Data]}
						css={tabButton(getIsTabSelected(TabsEnum.Data))}
						{...a11yProps(TabsEnum.Data)}
						data-ignore="1"
					/>
				</Tabs>
				{isAllowReset && (
					<WebtextButton
						data-ignore="1"
						css={resetButtonStyles}
						variant="text"
						onClick={resetChart}>
						Reset Chart
					</WebtextButton>
				)}
			</Box>
			<TabPanel value={currentTab} index={0}>
				<ChartSelection {...chartState} isNewlyCreated={isNewlyCreated} />
			</TabPanel>
			<TabPanel value={currentTab} index={1}>
				<DataTable chartState={chartState} />
			</TabPanel>
		</Box>
	);
};

export default ChartTabs;
