import React, { FC } from 'react';

import PollResultsView from '~/components/pageElements/PollQuestion/PollResultsView';
import { PollResultsAliasProps } from '~/components/pageElements/PollQuestion/types';
import UnfinishedPolls from '~/components/pageElements/PollResultsAlias/UnfinishedPolls';

interface Props extends PollResultsAliasProps {
	instructorView?: boolean;
}

const PollResultsAliasView: FC<Props> = (props) => {
	const { instructorView, targetPollResults } = props;
	return !instructorView && !targetPollResults.answer ? (
		<UnfinishedPolls missedQuestionsProps={[props]} />
	) : (
		<PollResultsView {...props.targetPollResults} />
	);
};

export default PollResultsAliasView;
