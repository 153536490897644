import React from 'react';

import WTPhrase from '../WTPhrase';
import { styles } from './styles';

interface Props {
	message: string;
	elementLabel?: string;
	builderFamilyId: string;
}

const Unfinished: React.FC<Props> = ({ message, elementLabel, builderFamilyId }) => (
	<div css={styles}>
		<WTPhrase label={elementLabel} builderFamilyId={builderFamilyId} />
		<div dangerouslySetInnerHTML={{ __html: message }}></div>
	</div>
);

export default Unfinished;
