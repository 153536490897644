import { css } from '@emotion/react';

import { breakpoints, ThemeName } from '~/styles/themes';

export default ({ webtextWritingTemplate, colors, name }) => css`
	color: #5f6f7c;
	margin-top: 0 !important;
	margin-bottom: 1em;
	font-size: 12px;
	display: flex;
	align-items: center;

	svg {
		display: none;
	}

	${name === ThemeName.UNIVERSAL_VELVET && unviversalVelvetHeading(webtextWritingTemplate, colors)}
`;

const unviversalVelvetHeading = (webtextWritingTemplate, colors) => css`
	letter-spacing: 2.8px;
	color: ${colors.primary} !important;
	font-size: 14px;
	margin-bottom: 28px !important;

	span {
		padding-top: 4px;
	}

	svg {
		display: unset;
		width: ${webtextWritingTemplate?.icon.width} !important;
		height: ${webtextWritingTemplate?.icon.height} !important;
		margin-right: 13px !important;
	}

	@media (max-width: ${breakpoints.small}) {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-bottom: 6px !important;
		font-size: 12px;
		font-weight: 400;

		span {
			margin-left: 3px;
		}

		svg {
			margin-bottom: 10px;
		}
	}
`;
