import React from 'react';

import { default as voiceRecordingMediaElementStyles } from './voiceRecordingMediaElementStyles';
import MediaElementAudioPlayer from '../MediaElementAudioPlayer/MediaElementAudioPlayer';
interface Props {
	recordingUrl: string;
	transcriptUrl: string;
}

export const CompletedVoiceRecording = (props) => {
	return (
		<div css={voiceRecordingMediaElementStyles}>
			<MediaElementAudioPlayer
				src={props.recordingUrl}
				onPlay={() => {
					return null;
				}}
			/>
		</div>
	);
};

export default CompletedVoiceRecording;
