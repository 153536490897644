import React, { FC, HTMLAttributes } from 'react';

const PollDeckIcon: FC<HTMLAttributes<SVGElement>> = (props) => (
	<svg
		className="poll-deck-icon"
		width={32}
		height={32}
		viewBox="0 0 448 448"
		aria-hidden
		{...props}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g fill="#5F01DF" fillRule="nonzero">
				<path d="M310,112 L26,112 C11.6405965,112 -1.75851975e-15,123.640597 0,138 L0,422 C1.75851975e-15,436.359403 11.6405965,448 26,448 L310,448 C324.359403,448 336,436.359403 336,422 L336,138 C336,123.640597 324.359403,112 310,112 Z M120,256 L120,376 L72,376 L72,256 L120,256 Z M144,184 L192,184 L192,376 L144,376 L144,184 Z M264,304 L264,376 L216,376 L216,304 L264,304 Z M427,0 C438.59798,-2.13051432e-15 448,9.40202025 448,21 L448,315 C448,326.59798 438.59798,336 427,336 L336,336 L336,291 L403,291 L403,45 L157,45 L157,112 L112,112 L112,21 C112,9.40202025 121.40202,2.13051432e-15 133,0 L427,0 Z"></path>
			</g>
		</g>
	</svg>
);
export default PollDeckIcon;
