import React, { useMemo } from 'react';

import { getKeyColumnName } from './helpers';
import { descriptionTableStyles } from './styles';

import type { GeoMapElement } from '~/types/WebtextManifest';

interface Props {
	figure: GeoMapElement;
}

const DescriptionTable: React.FC<Props> = (props) => {
	const { figure } = props;

	const keyColumnName = getKeyColumnName(figure.geomap_type);
	const items = useMemo(
		() =>
			Object.keys(figure.data).filter((item) => figure.data[item].some((value) => value !== '')),
		[figure.data]
	);
	return (
		<div css={descriptionTableStyles}>
			<div className="webtext-figure-description" id={`webtext-figure-description-${figure.id}`}>
				<div dangerouslySetInnerHTML={{ __html: figure.description }} />

				<table
					cellPadding="0"
					cellSpacing="0"
					width="100%"
					className="webtext-figure-asset webtext-figure-asset-table">
					<thead>
						<tr>
							<th scope="column">{keyColumnName}</th>
							{figure.labels.map((label, index) => (
								<th scope="column" key={index}>
									{label}
								</th>
							))}
						</tr>
					</thead>

					<tbody>
						{items.map((item) => {
							return (
								<tr key={item}>
									<th scope="row">{item}</th>
									{figure.data[item].map((columnValue, index) => {
										if (figure.labels[index] === 'Group') {
											const groupId = columnValue;
											const groupValue =
												figure.colors.find((group) => group.id === groupId)?.name || '';
											return <td key={`group-${item}`}>{groupValue}</td>;
										} else {
											return <td key={item}>{columnValue}</td>;
										}
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default DescriptionTable;
