import React from 'react';

import { onShowHorizontalRule } from '~/components/WritingTemplate/WritingTemplate/components/utils';

import { lockStyles } from '../../styles';

interface Props {
	text: string;
}

const LockMessage: React.FC<Props> = ({ text }) => (
	<>
		{onShowHorizontalRule()}
		<div css={lockStyles} dangerouslySetInnerHTML={{ __html: text }}></div>
	</>
);

export default LockMessage;
