import { FormulaResult } from '../helpers/types';
import { SpreadsheetState } from './store';

export const selectCellValue = (
	state: SpreadsheetState,
	address: string
): {
	value: string | number;
	attributes?: {
		error: string;
		success: boolean;
	};
} => {
	const { cellValues, evaluatedFormulasValues } = state;

	if (evaluatedFormulasValues[address]) {
		const { result, error, success } = evaluatedFormulasValues[address];
		return { value: result, attributes: { error, success } };
	}

	return { value: cellValues[address], attributes: null };
};

export const selectCellInputValue = (state: SpreadsheetState, address: string): string =>
	state.cellValues[address];

export const selectEditableCell = (state: SpreadsheetState, address: string): boolean =>
	state.editableRanges[address] ?? false;

export const selectEditableSelectedCell = (state: SpreadsheetState): boolean =>
	state.editableRanges[state.selectedCell] || false;

export const selectSelectedCellValue = (state: SpreadsheetState): string =>
	state.cellValues[state.selectedCell];

export const selectEditingCell = (state: SpreadsheetState, address: string): boolean =>
	state.editingCell === address;

export const selectIsCellInsideSelection = (state: SpreadsheetState, address: string): boolean =>
	state.selectedIndexes.includes(address);

export const selectCellEvaluatedValue = (state: SpreadsheetState, address: string): FormulaResult =>
	state.evaluatedFormulasValues[address] ?? null;

export const selectIsAnyRuntimeError = (state: SpreadsheetState): boolean =>
	Object.values(state.runtimeEditableErrors).some(Boolean);
