import React from 'react';

import { useIsUniversalVelvet } from '~/hooks';

import ScoreBar from './ScoreBar';
import { PopupQuiz, Dimension } from './types';

interface Props {
	popupQuiz: PopupQuiz;
	dimension: Dimension;
	score: number;
	scoreAsPercentage: number;
	basis: number;
}

const DimensionScoreBar: React.FC<Props> = (props) => {
	const { popupQuiz, dimension, score, scoreAsPercentage, basis } = props;
	const barOptions = (popupQuiz.ui && popupQuiz.ui.results && popupQuiz.ui.results.bars) || {};
	const isUniversalVelvet = useIsUniversalVelvet();

	const label =
		barOptions.showDescription !== false ? (
			<>
				<strong>{dimension.name}:</strong> {dimension.desc}
			</>
		) : isUniversalVelvet ? (
			<strong>{dimension.name}:</strong>
		) : (
			<>{dimension.name}:</>
		);

	return (
		<ScoreBar
			label={label}
			score={score}
			scoreAsPercentage={scoreAsPercentage}
			basis={basis}
			barOptions={barOptions}
		/>
	);
};

export default DimensionScoreBar;
