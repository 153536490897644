export const localStorageGet = (key: string, defaultValue?: any) => {
	let item;
	try {
		item = window.localStorage.getItem(key);
	} catch {
		console.warn('Local Storage unavailable!');
		return defaultValue;
	}
	if (item != null) {
		return JSON.parse(item);
	} else {
		return defaultValue;
	}
};

export const localStorageSet = (key: string, value: any) => {
	const valueToStore = value instanceof Function ? value(localStorageGet(key)) : value;
	if (valueToStore != null) {
		const stringifiedValue = JSON.stringify(valueToStore);
		try {
			window.localStorage.setItem(key, stringifiedValue);
		} catch {
			console.warn('Local Storage unavailable!');
		}
	}
};
