import * as React from 'react';

export function GearWithPlayButton(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<path d="M62.33 38.79h-4.86a.76.76 0 0 0-.75.64 21.05 21.05 0 0 1-3.66 8.77.76.76 0 0 0 .08 1l3.49 3.49-4 4-3.5-3.5a.76.76 0 0 0-1-.09 21.06 21.06 0 0 1-8.77 3.63.76.76 0 0 0-.64.75v4.91h-5.51v-4.97a.76.76 0 0 0-.64-.75A21.06 21.06 0 0 1 23.8 53a.76.76 0 0 0-1 .09l-3.5 3.5-4-4 3.49-3.49a.76.76 0 0 0 .08-1 21.05 21.05 0 0 1-3.66-8.77.76.76 0 0 0-.75-.64H9.67v-5.48h4.84a.76.76 0 0 0 .75-.64 21.17 21.17 0 0 1 3.63-8.83.75.75 0 0 0-.08-1l-3.44-3.44 4-4 3.43 3.43a.75.75 0 0 0 1 .08 21.09 21.09 0 0 1 8.84-3.67.76.76 0 0 0 .64-.75V9.67h5.58v4.79a.76.76 0 0 0 .64.75 21.09 21.09 0 0 1 8.84 3.67.75.75 0 0 0 1-.08l3.43-3.43 4 4-3.44 3.44a.75.75 0 0 0-.08 1 21.17 21.17 0 0 1 3.63 8.83.76.76 0 0 0 .75.64h4.84v5.58Zm.76-7.1h-5a22.65 22.65 0 0 0-3.42-8.3l3.54-3.54a.75.75 0 0 0 0-1.07l-5-5a.75.75 0 0 0-1.07 0l-3.53 3.52a22.68 22.68 0 0 0-8.31-3.45V8.91a.76.76 0 0 0-.76-.76h-7.1a.77.77 0 0 0-.77.76v4.91a22.71 22.71 0 0 0-8.3 3.45l-3.53-3.52a.75.75 0 0 0-1.07 0l-5 5a.75.75 0 0 0 0 1.07l3.54 3.54a22.48 22.48 0 0 0-3.42 8.3h-5a.76.76 0 0 0-.76.76v7.1a.77.77 0 0 0 .76.77h5a22.43 22.43 0 0 0 3.45 8.24l-3.59 3.59a.75.75 0 0 0 0 1.07l5 5a.75.75 0 0 0 1.07 0l3.6-3.6A22.62 22.62 0 0 0 31.65 58v5a.77.77 0 0 0 .77.76h7.1a.76.76 0 0 0 .76-.76v-5a22.6 22.6 0 0 0 8.24-3.41l3.6 3.6a.75.75 0 0 0 1.07 0l5-5a.75.75 0 0 0 0-1.07l-3.59-3.59a22.43 22.43 0 0 0 3.45-8.24h5a.77.77 0 0 0 .76-.77v-7.1a.76.76 0 0 0-.76-.76Z" />
			<path d="M31.81 40.74v-9.48L41.29 36l-9.48 4.74Zm11.52-5.42-11.94-6a.75.75 0 0 0-.74 0 .78.78 0 0 0-.36.65V42a.78.78 0 0 0 .36.65.8.8 0 0 0 .4.11.77.77 0 0 0 .34-.08l11.94-6a.76.76 0 0 0 0-1.36Z" />
			<path d="M36.09 51.71A15.71 15.71 0 1 1 51.8 36a15.73 15.73 0 0 1-15.71 15.71Zm0-29.71a14 14 0 1 0 14 14 14 14 0 0 0-14-14Z" />
		</svg>
	);
}
