import React, { FC } from 'react';

import { css, ClassNames } from '@emotion/react';

interface Props {
	zoomed: boolean;
	zoomFactor?: number;
	maxWidth?: string;
	maxHeight?: string;
}

const EnlargeChartWrapper: FC<Props> = ({ zoomed, children, ...zoomerProps }) => (
	<ClassNames>
		{({ cx }) => (
			// Creates overflow container to not interfere with the outer parent container
			<div css={styles(zoomerProps)} className="zoomer-container">
				<div className={cx('zoomer', zoomed && 'zoomed')}>{children}</div>
			</div>
		)}
	</ClassNames>
);

const styles = ({ zoomFactor = 2.2, maxWidth = '1200px', maxHeight }: Omit<Props, 'zoomed'>) => css`
	overflow: auto;

	.zoomer {
		width: 100%;
		height: 100%;

		&.zoomed {
			--target-width: calc(100% * ${zoomFactor});
			--target-height: calc(100% * ${zoomFactor});

			// Using min() instead of max-width / max-height to prevent jumping animation on unzooming
			width: ${maxWidth ? `min(${maxWidth}, var(--target-width))` : `var(--target-width)`};
			height: ${maxHeight ? `min(${maxHeight}, var(--target-height))` : `var(--target-height)`};
		}
	}
`;

export default EnlargeChartWrapper;
