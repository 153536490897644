import React, { FC, useMemo } from 'react';
import { BsArrowsExpand } from 'react-icons/bs';

import WebtextButton from '~/components/WebtextButton';

import { chartDataTableStyles, showMoreButtonStyles } from './styles';

interface Props {
	data: (string | number)[][];
	showMoreRowsClicked: boolean;
	onShowMoreRows: () => void;
}

const ROWS_LIMIT = 15;
const SHOW_ROWS_LIMIT = 5;

const DataTable: FC<Props> = ({ data, showMoreRowsClicked, onShowMoreRows }) => {
	const [headerData, ...rowsData] = data;

	const formatDataValue = (value) => {
		const numericPart = value.replace(/[^0-9.-]/g, '');
		const number = parseFloat(numericPart);

		if (isNaN(number)) return value;

		// Format the number with commas: 10000.00 -> 10,000.00
		const formattedNumber = new Intl.NumberFormat('en-US').format(number);

		// Get non-numeric values - $ and %
		const prefix = value.match(/^[^\d.-]*/)[0];
		const suffix = value.match(/[^\d.-]*$/)[0];

		return prefix + formattedNumber + suffix;
	};

	const header = useMemo(
		() => (
			<tr>
				{headerData.map((value, index) => (
					<th scope="col" key={`header-${index}`}>
						{value}
					</th>
				))}
			</tr>
		),
		[headerData]
	);

	const renderRow = (row: (string | number)[], rowIndex: number) => {
		const [rowLabel, ...rowData] = row;
		return (
			<tr key={`${rowLabel}-${rowIndex}`}>
				<th scope="row">{rowLabel}</th>
				{rowData.map((value, columnIndex) => (
					<td key={`${columnIndex}-${value}-data`}>{formatDataValue(value)}</td>
				))}
			</tr>
		);
	};

	const renderShowMoreRow = () => (
		<tr key="show-more-rows">
			<th scope="row" colSpan={rowsData[0].length} className="show-more-rows">
				<div className="show-more-rows-container">
					<WebtextButton variant="text" css={showMoreButtonStyles} onClick={onShowMoreRows}>
						Show {rowsData.length - SHOW_ROWS_LIMIT * 2} remaining rows <BsArrowsExpand />
					</WebtextButton>
				</div>
			</th>
		</tr>
	);

	const renderRows = () => {
		if (rowsData.length >= ROWS_LIMIT && !showMoreRowsClicked) {
			const firstRows = rowsData
				.slice(0, SHOW_ROWS_LIMIT)
				.map((row, index) => renderRow(row, index));
			const lastRows = rowsData.slice(-SHOW_ROWS_LIMIT).map((row, index) => renderRow(row, index));

			return [...firstRows, renderShowMoreRow(), ...lastRows];
		}

		return rowsData.map((row, index) => renderRow(row, index));
	};

	return (
		<div className="chart-data-table" css={chartDataTableStyles}>
			<table>
				<thead>{header}</thead>
				<tbody>{renderRows()}</tbody>
			</table>
		</div>
	);
};

export default DataTable;
