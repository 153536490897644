import { useHotkeys } from 'react-hotkeys-hook';

import { useOutlineSelector } from '~/components/Outline/store';
import { getOS } from '~/utils';

const os = getOS();
export const isAppleOS = os === 'macos' || os === 'ios';
export const { hotkey: outlineUndoHotkey, human: outlineUndoHumanHotkey } = isAppleOS
	? {
			hotkey: 'control+z',
			human: 'Control Z'
	  }
	: {
			hotkey: 'ctrl+alt+z',
			human: 'Ctrl Alt Z'
	  };

export const useOutlineActionsHotkeys = (): void => {
	const undoUserAction = useOutlineSelector(({ undoUserAction }) => undoUserAction);
	useHotkeys(outlineUndoHotkey, undoUserAction, {
		enableOnFormTags: ['textarea'] // Register the events on the focused textarea
	});
};
