import { css } from '@emotion/react';
import { darken } from 'polished';

import constants from './constants';
import { type Theme, type Mixins } from './types';
import { getThemeItem } from './utils';

const mixins: Mixins = {
	elementTitle: function (theme: Theme) {
		const title = theme.element.title;
		return `
			font-size: ${title.fontSize} !important;
			font-family: ${title.fontFamily} !important;
			${title.fontWeight !== false ? `font-weight: ${title.fontWeight} !important;` : ''}
			${title.textTransform !== false ? `text-transform: ${title.textTransform} !important;` : ''}
			color: ${title.color} !important;
			margin: ${title.margin} !important;
		`;
	},
	webtextQuestionButtons: function (theme: Theme) {
		const {
			webtextQuestionButtons: {
				fontFamily: buttonsFontFamily,
				fontSize: buttonsFontSize,
				fontWeight: buttonsFontWeight
			}
		} = theme;

		return css`
			background: ${theme.colors.primary};
			border-radius: 2px;
			padding: 0.55em 1em;
			border: 0;
			margin-right: 10px;
			color: ${constants.colors.white};
			font-family: ${getThemeItem(buttonsFontFamily, theme)};
			font-size: ${getThemeItem(buttonsFontSize, theme)};
			${buttonsFontWeight ? `font-weight: ${buttonsFontWeight};` : ''}
			border: 1px solid transparent;
			outline: 0;

			@media (max-width: ${constants.breakpoints.small}) {
				width: 100%;
				margin-bottom: 15px;
			}

			&:hover {
				cursor: pointer;
				background: ${darken(0.05, theme.colors.primary)};
			}

			&:focus {
				${this.webtextAccessibleFocused(theme)}
				background: ${darken(0.1, theme.colors.primary)};
			}

			&[disabled],
			&.snoozed {
				opacity: 0.5;
				cursor: default;

				&:hover {
					cursor: default;
					background: ${theme.colors.primary};
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		`;
	},
	webtextAccessibleFocused: function (theme: Theme) {
		const {
			ally: { borderColor: allyBorderColor, highlightColor: allyHighlightColor }
		} = theme;

		return css`
			border: 1px solid ${getThemeItem(allyBorderColor, theme)} !important;
			box-shadow:
				0 0 4px 1px ${getThemeItem(allyHighlightColor, theme)},
				inset 0 0 4px 1px ${getThemeItem(allyHighlightColor, theme)};
			outline: none;
		`;
	},
	webtextHiddenAccessible: function () {
		return css`
			position: absolute !important;
			bottom: 0;
			left: 0;
			clip: rect(1px 1px 1px 1px);
			padding: 0 !important;
			border: 0 !important;
			height: 1px !important;
			width: 1px !important;
			overflow: hidden;
		`;
	},
	placeholderAlignmentAttribute: function () {
		return css`
			&[data-placeholder-alignment='true'] {
				align-items: center !important;
				justify-content: flex-start !important;
				white-space: normal;
				height: auto; // Overrides the height fixed set on the element and make the ph fit
			}
		`;
	}
};

export default mixins;
