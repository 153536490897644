import sha256 from 'tiny-sha256';

/**
 * Returns a shuffled copy of a list for the given seed value.
 *
 * Will return the same list as Core's `Soomo::Shuffler.shuffle` for the same seed value.
 *
 * **Will throw an error** if list is 64 or more items in length.
 *
 * @param list - the list to shuffle
 * @param seed - the seed value
 * @returns - a shuffled copy of `list`
 * @throws if `list` is 64 or more items in length
 */
export default function shuffle<T>(list: T[], seed: string | number): T[] {
	if (list.length >= 64) {
		throw new Error('Shuffling 64 or more items is not supported');
	}
	const digest = sha256(typeof seed === 'string' ? seed : `${seed}`) as string;
	const randomBytes = Array.from(digest).map((hexDigit) => parseInt(hexDigit, 16));
	const shuffledList = [...list];
	let choiceCount = list.length;
	while (choiceCount > 1) {
		const randomInt = randomBytes[choiceCount] % choiceCount;
		choiceCount -= 1;
		[shuffledList[choiceCount], shuffledList[randomInt]] = [
			shuffledList[randomInt],
			shuffledList[choiceCount]
		];
	}
	return shuffledList;
}
