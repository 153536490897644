import React from 'react';

const st0 = {
	fillOpacity: 0,
	stroke: '#ffffff',
	strokeWidth: 1.5,
	strokeMiterlimit: 10
};

const st1 = {
	stroke: '#ffffff',
	fill: 'white',
	strokeWidth: 0
};

export const Play = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="25px"
		height="25px"
		aria-hidden>
		<path style={st1} d="M4 2v20l18-10z" />
	</svg>
);

export const Pause = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="25px"
		height="25px"
		aria-hidden>
		<path style={st1} d="M3,3v18h7v-18zM13,3v18h7v-18z" />
	</svg>
);

export const SkipBackward5s = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 36 36"
		width="25px"
		height="25px"
		aria-hidden>
		<path style={st0} d="M18.1 5.3c8 0 14.4 6.5 14.4 14.4S26 34.1 18.1 34.1 3.7 27.7 3.7 19.8" />
		<polygon style={st1} points="18.9 9.6 13 5.6 18.9 1.5 " />
		<polygon style={st1} points="14.2 9.6 8.3 5.6 14.2 1.5 " />
		<path
			style={st1}
			d="M14.1 15.4l-0.5 2.8 0 0c0.2-0.2 0.5-0.4 0.8-0.5 0.3-0.1 0.7-0.2 1-0.2 0.4 0 0.8 0.1 1.2 0.2s0.7 0.3 1 0.6c0.3 0.3 0.5 0.6 0.7 1s0.2 0.9 0.2 1.4c0 0.4-0.1 0.8-0.2 1.2 -0.1 0.4-0.3 0.7-0.6 1 -0.3 0.3-0.6 0.5-1 0.7 -0.4 0.2-0.9 0.3-1.5 0.3 -0.4 0-0.8-0.1-1.2-0.2 -0.4-0.1-0.7-0.3-1-0.5 -0.3-0.2-0.5-0.5-0.7-0.9 -0.2-0.3-0.2-0.7-0.3-1.2h1.1c0 0.2 0.1 0.5 0.2 0.7 0.1 0.2 0.2 0.4 0.4 0.6 0.2 0.2 0.4 0.3 0.6 0.4 0.2 0.1 0.5 0.1 0.8 0.1 0.3 0 0.5 0 0.8-0.1 0.2-0.1 0.5-0.2 0.6-0.4 0.2-0.2 0.3-0.4 0.4-0.7 0.1-0.3 0.2-0.6 0.2-1 0-0.3-0.1-0.6-0.2-0.9 -0.1-0.3-0.2-0.5-0.4-0.7s-0.4-0.3-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.2 -0.4 0-0.7 0.1-1 0.2 -0.3 0.2-0.6 0.4-0.8 0.7l-1-0.1 0.9-5H18v1H14.1z"
		/>
		<path
			style={st1}
			d="M20.6 22.2c0.1 0.2 0.2 0.3 0.4 0.4 0.2 0.1 0.4 0.2 0.6 0.2 0.2 0 0.4 0.1 0.7 0.1 0.2 0 0.4 0 0.6 0 0.2 0 0.4-0.1 0.5-0.1 0.2-0.1 0.3-0.2 0.4-0.3 0.1-0.1 0.2-0.3 0.2-0.5 0-0.3-0.1-0.5-0.3-0.7 -0.2-0.2-0.5-0.3-0.8-0.4 -0.3-0.1-0.7-0.2-1.1-0.3 -0.4-0.1-0.8-0.2-1.1-0.3 -0.3-0.1-0.6-0.3-0.8-0.6 -0.2-0.3-0.3-0.6-0.3-1.1 0-0.4 0.1-0.7 0.2-0.9 0.2-0.3 0.4-0.5 0.6-0.6 0.2-0.2 0.5-0.3 0.8-0.4 0.3-0.1 0.6-0.1 0.9-0.1 0.4 0 0.8 0 1.1 0.1s0.6 0.2 0.9 0.4c0.3 0.2 0.5 0.4 0.6 0.7 0.2 0.3 0.2 0.6 0.3 1.1h-1.1c0-0.2-0.1-0.4-0.2-0.6 -0.1-0.1-0.2-0.3-0.4-0.4 -0.2-0.1-0.3-0.2-0.5-0.2 -0.2 0-0.4-0.1-0.6-0.1 -0.2 0-0.3 0-0.5 0 -0.2 0-0.3 0.1-0.5 0.1 -0.1 0.1-0.3 0.2-0.4 0.3 -0.1 0.1-0.1 0.3-0.1 0.4 0 0.2 0.1 0.4 0.2 0.5 0.1 0.1 0.3 0.2 0.5 0.3 0.2 0.1 0.5 0.2 0.7 0.2s0.5 0.1 0.8 0.2c0.3 0.1 0.6 0.1 0.8 0.2s0.5 0.2 0.7 0.4c0.2 0.1 0.4 0.3 0.5 0.6 0.1 0.2 0.2 0.5 0.2 0.8 0 0.4-0.1 0.8-0.3 1.1 -0.2 0.3-0.4 0.5-0.7 0.7s-0.6 0.3-1 0.4 -0.7 0.1-1.1 0.1c-0.4 0-0.8 0-1.1-0.1 -0.4-0.1-0.7-0.2-0.9-0.4 -0.3-0.2-0.5-0.4-0.6-0.7 -0.2-0.3-0.3-0.7-0.3-1.1h1.1C20.4 21.8 20.4 22 20.6 22.2z"
		/>
	</svg>
);

export const SkipForward5s = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 36 36"
		width="25px"
		height="25px"
		aria-hidden>
		<path style={st0} d="M32.5 19.8c0 7.9-6.5 14.3-14.4 14.3S3.7 27.6 3.7 19.7 10.1 5.3 18.1 5.3" />
		<polygon style={st1} points="17.3 1.5 23.2 5.6 17.3 9.6 " />
		<polygon style={st1} points="22 1.5 27.9 5.6 22 9.6 " />
		<path
			style={st1}
			d="M14.1 15.4l-0.5 2.8 0 0c0.2-0.2 0.5-0.4 0.8-0.5s0.7-0.2 1-0.2c0.4 0 0.8 0.1 1.2 0.2s0.7 0.3 1 0.6c0.3 0.3 0.5 0.6 0.7 1s0.2 0.9 0.2 1.4c0 0.4-0.1 0.8-0.2 1.2 -0.1 0.4-0.3 0.7-0.6 1s-0.6 0.5-1 0.7 -0.9 0.3-1.5 0.3c-0.4 0-0.8-0.1-1.2-0.2s-0.7-0.3-1-0.5 -0.5-0.5-0.7-0.9c-0.2-0.3-0.2-0.7-0.3-1.2h1.1c0 0.2 0.1 0.5 0.2 0.7 0.1 0.2 0.2 0.4 0.4 0.6s0.4 0.3 0.6 0.4c0.2 0.1 0.5 0.1 0.8 0.1s0.5 0 0.8-0.1c0.2-0.1 0.5-0.2 0.6-0.4 0.2-0.2 0.3-0.4 0.4-0.7 0.1-0.3 0.2-0.6 0.2-1 0-0.3-0.1-0.6-0.2-0.9s-0.2-0.5-0.4-0.7 -0.4-0.3-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.2 -0.4 0-0.7 0.1-1 0.2s-0.6 0.4-0.8 0.7l-1-0.1 0.9-5h5v1h-3.9V15.4z"
		/>
		<path
			style={st1}
			d="M20.6 22.2c0.1 0.2 0.2 0.3 0.4 0.4 0.2 0.1 0.4 0.2 0.6 0.2s0.4 0.1 0.7 0.1c0.2 0 0.4 0 0.6 0s0.4-0.1 0.5-0.1c0.2-0.1 0.3-0.2 0.4-0.3 0.1-0.1 0.2-0.3 0.2-0.5 0-0.3-0.1-0.5-0.3-0.7 -0.2-0.2-0.5-0.3-0.8-0.4s-0.7-0.2-1.1-0.3c-0.4-0.1-0.8-0.2-1.1-0.3 -0.3-0.1-0.6-0.3-0.8-0.6 -0.2-0.3-0.3-0.6-0.3-1.1 0-0.4 0.1-0.7 0.2-0.9 0.2-0.3 0.4-0.5 0.6-0.6 0.2-0.2 0.5-0.3 0.8-0.4s0.6-0.1 0.9-0.1c0.4 0 0.8 0 1.1 0.1s0.6 0.2 0.9 0.4c0.3 0.2 0.5 0.4 0.6 0.7 0.2 0.3 0.2 0.6 0.3 1.1h-1.1c0-0.2-0.1-0.4-0.2-0.6s-0.2-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.2s-0.4-0.1-0.6-0.1 -0.3 0-0.5 0 -0.3 0.1-0.5 0.1c-0.1 0.1-0.3 0.2-0.4 0.3 -0.1 0.1-0.1 0.3-0.1 0.4 0 0.2 0.1 0.4 0.2 0.5s0.3 0.2 0.5 0.3c0.2 0.1 0.5 0.2 0.7 0.2s0.5 0.1 0.8 0.2 0.6 0.1 0.8 0.2c0.3 0.1 0.5 0.2 0.7 0.4 0.2 0.1 0.4 0.3 0.5 0.6 0.1 0.2 0.2 0.5 0.2 0.8 0 0.4-0.1 0.8-0.3 1.1 -0.2 0.3-0.4 0.5-0.7 0.7s-0.6 0.3-1 0.4 -0.7 0.1-1.1 0.1 -0.8 0-1.1-0.1c-0.4-0.1-0.7-0.2-0.9-0.4s-0.5-0.4-0.6-0.7 -0.3-0.7-0.3-1.1h1.1C20.4 21.8 20.5 22 20.6 22.2z"
		/>
	</svg>
);
