import type React from 'react';

import {
	type BasePageElement,
	type CoursePolicy,
	type DecisionElement,
	type FigureElement,
	type Manifest,
	type MCQuestionElement,
	type Page,
	type PollQuestionElement,
	type ResponseBoardElement,
	type SAQuestionElement,
	type TextElement,
	type WritingTemplateElement
} from '~/types/WebtextManifest';

import {
	DecisionPoint,
	Figure,
	MultipleChoiceQuestion,
	Nesting,
	PollQuestion,
	ResponseBoardQuestion,
	ShortAnswerQuestion,
	Text,
	WritingTemplate
} from '../pageElements';
import { mockDecisionApi } from './mocks';
import { Unsupported } from './Unsupported';
import { convertPageElementType } from './utils';
import { type WaveformAudioPlayerRef } from '../pageElements/WaveformAudioPlayer';

type WebtextPageElementProps = {
	element: BasePageElement;
	page: Page;
	manifest: Manifest;
	policyManifest: CoursePolicy;
	playMediaSnippet?: (familyId: string, start: string, end: string) => void;
	audioFigureRef?: WaveformAudioPlayerRef;
	nestingInstructionClasses?: string[];
	nestingInstructionElements?: React.ReactElement[];
	containerType?: string;
	mock?: boolean;
};

const HIDDEN_ELEMENT_TYPES = ['NG::Soomo::MC::Choice', 'NG::Traditional::Page'];

const WebtextPageElement: React.FC<WebtextPageElementProps> = (props) => {
	const {
		page,
		manifest,
		playMediaSnippet,
		nestingInstructionClasses,
		nestingInstructionElements,
		containerType,
		mock
	} = props;
	const { type, name, family_id } = props.element;

	switch (convertPageElementType(type)) {
		case 'text': {
			return (
				<Text
					className="webtext-text"
					element={props.element as TextElement}
					onCitationClicked={(clicked, footnote) => {
						footnote.scrollIntoView();
					}}
					online
					playMediaSnippet={playMediaSnippet}
					containerType={containerType}
				/>
			);
		}
		case 'figure': {
			return (
				<Figure
					figureOptions={props.element as FigureElement}
					elementContext={{
						courseId: 1,
						pageFamilyId: page.family_id
					}}
					audioFigureProps={{
						waveformAudioPlayerRef: props.audioFigureRef
					}}
					mock={mock}
				/>
			);
		}
		case 'decision': {
			const { course_decisions } = manifest.toc.config;
			const element = props.element as DecisionElement;
			return (
				<DecisionPoint
					decision={{
						family_id,
						prompt: element.prompt,
						...course_decisions[name]
					}}
					api={mockDecisionApi()}
					confirmReset={() => Promise.resolve(confirm('Are you sure?'))}
				/>
			);
		}

		/*
		case 'popup_quiz': {
			const element = props.element as PopupQuizElement;
			const { instructions, mode, disable_review, introduction } = element;

			const questions = element.questions
				.map<PopupQuizQuestionElement>((q) => pageMap[q])
				.reduce<PopupQuizQuestion[]>(
					(acc, q, index) => [
						...acc,
						{
							family_id: q.family_id,
							number: index + 1,
							prompt: q.prompt,
							choices: q.choices
								.map<PopupQuizChoiceElement>((c) => pageMap[c])
								.reduce<PopupQuizChoice[]>(
									(acc, c) => [...acc, { family_id: c.family_id, prompt: c.prompt }],
									[]
								)
						}
					],
					[]
				);

			return (
				<PopupQuiz
					popupQuiz={{
						family_id,
						name,
						introduction,
						instructions,
						disable_review,
						mode
					}}
					coursePolicy={policyManifest}
					api={mockPopupQuizApi(questions)}
				/>
			);
		}
		*/

		case 'sa_question': {
			const element = props.element as SAQuestionElement;

			return (
				<ShortAnswerQuestion
					userId="page-user-id"
					questionId="page-sa-question-id"
					body={element.body}
					answer={{}}
					referenceAnswer={element.reference_answer}
					online
					validationMessage=""
					readOnly
					noBottomMargin
				/>
			);
		}

		case 'response_board': {
			const element = props.element as ResponseBoardElement;

			return (
				<ResponseBoardQuestion
					userId="page-user-id"
					questionId="page-rb-question-id"
					body={element.body}
					userPost={{
						body: 'Some read only body text that someone wrote at some point.',
						postedAt: new Date().toISOString(),
						author: {
							name: 'Read Only'
						},
						isPinned: false,
						pinnedBy: 'Soomo'
					}}
					coursePosts={[]}
					online
					sharingAcknowledged
					showResponses={false}
					setShowResponses={() => null}
					validationMessage=""
					editable
					autoSave
					readOnly
					noBottomMargin
				/>
			);
		}

		case 'poll_question': {
			// Doesn't require maintenance - https://github.com/soomo/soomo-libs/pull/1488#discussion_r1285827261
			const element = props.element as PollQuestionElement;
			return (
				<PollQuestion
					courseNumber=""
					questionFamilyId={element.family_id}
					body={element.body}
					choices={element.choices}
					online
					submitting={false}
					submissionError={null}
					noBottomMargin
				/>
			);
		}

		case 'mc_question': {
			const element = props.element as MCQuestionElement;

			return (
				<MultipleChoiceQuestion
					questionFamilyId={element.family_id}
					body={element.body}
					choices={element.choices}
					onSubmit={(_fId, _sC) => null}
					noBottomMargin
				/>
			);
		}

		case 'interactive_template': {
			const element = props.element as WritingTemplateElement;
			const api = {
				storeTemplate: () => Promise.resolve({}),
				loadTemplate: async () => {
					const destsForThisElement = element.config.dests;
					const destsForThisAndUpstreamElements = {
						...(element.config.sources || {}),
						...destsForThisElement
					};

					return {
						dests: Object.keys(destsForThisAndUpstreamElements).length
							? destsForThisAndUpstreamElements
							: null,
						draftTime: new Date(),
						view: 'edit'
					};
				},
				signedImageParameters: () => Promise.resolve({}),
				generateDocument: () => Promise.resolve({}),
				getImageUrl: async () => '',
				generatedDocumentReady: () => Promise.resolve()
			};
			return (
				<WritingTemplate
					schema={JSON.parse(JSON.stringify(element.config))}
					toc={manifest.toc}
					builderId={element.family_id}
					api={api}
					generatingDocument={false}
					setGeneratingDocument={() => Promise.resolve()}
					noBottomMargin
				/>
			);
		}

		case 'nesting_instruction': {
			return (
				<Nesting
					familyId={props.element.family_id}
					key={`${props.element.family_id}-nesting`}
					nestingInstructionClasses={nestingInstructionClasses}
					nestingInstructionElements={nestingInstructionElements}
				/>
			);
		}

		default: {
			if (HIDDEN_ELEMENT_TYPES.indexOf(type) > -1) {
				return null;
			}

			return <Unsupported type={type} />;
		}
	}
};

export default WebtextPageElement;
