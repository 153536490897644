import { css, SerializedStyles } from '@emotion/react';
import { createStyles, makeStyles } from '@material-ui/core';

import { breakpoints, mixins } from '~/styles/themes';

export const useStyles = makeStyles({
	scrollPaper: {
		alignItems: 'baseline',
		padding: '5%',
		position: 'relative'
	}
});

export const useHelperDialogStyles = makeStyles(() =>
	createStyles({
		arrow: {
			color: '#333'
		},
		tooltip: {
			margin: 0,
			backgroundColor: '#333',
			maxWidth: 700,
			fontSize: 'inherit'
		}
	})
);

export const styles = (theme): SerializedStyles => css`
	font-family: ${theme.fonts.app};
	padding: 2em 5em;
	display: flex;
	flex-direction: column;

	.pick-answer-container {
		margin: 0.5em 0;
	}

	.button-wrapper {
		align-self: center;
		margin-top: 3em !important;
	}

	.extra-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: ${theme.colors.primary}!important;

		div {
			margin: 0 !important;
			max-width: 100px;
			background-color: #fff;
			color: ${theme.colors.primary}!important;

			textarea {
				outline: none;
				border: 1px solid ${theme.colors.primary};
				vertical-align: middle;
			}
		}

		span {
			margin-right: 1em;
		}
	}

	.citation-hint {
		align-self: center;
		margin-bottom: 0 !important;
		color: #5f5f5f;
		text-decoration: none;
		border: none;
		background: none;
		font-size: 1rem;
		padding: 0;
		border-bottom: 1px dotted #5f5f5f;
		cursor: pointer;
	}

	.close-dialog {
		position: absolute;
		right: 0px;
		top: 0px;
		background: transparent;
		border: 1px solid transparent;
		color: #fff;
		width: 35px;
		height: 35px;
		cursor: pointer;
	}

	@media (max-width: ${breakpoints.small}) {
		padding: 1em 1.5em;
	}
`;

export const citationHelpStyles = (theme): SerializedStyles => css`
	font-family: ${theme.fonts.app};
	margin: 1em;

	table {
		border-collapse: collapse;

		th,
		td {
			text-align: start;
			padding: 5px;
		}

		tr:nth-child(2n + 2) {
			background: #333;
		}
		tr:nth-child(2n + 3) {
			background: #444;
		}
	}
`;

export const hiddenAccessible = (theme) => css`
	${mixins.webtextHiddenAccessible(theme)}
`;
