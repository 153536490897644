import React, { useEffect } from 'react';

import { QuestionPrompt } from '~/components/shared/Question';

import { useInstructorViewPoolNavigation } from '../hooks/useInstructorViewPoolNavigation';
import { promptStyles } from '../styles';
import InstructorViewBelowTheFold from './InstructorViewBelowTheFold';

import type { DisplayedQuestionElementProps, InstructorViewElementProps } from '../types';

type Props = InstructorViewElementProps &
	DisplayedQuestionElementProps & {
		promptId: string;
	};

const StandaloneInstructorView: React.VFC<Props> = (props) => {
	const { questionPool, promptId, onInitialLoadComplete, onStudyStackLinkClick } = props;
	const { activePoolIndex, onPrevious, onNext } = useInstructorViewPoolNavigation();
	const activeQuestion = questionPool.questions[activePoolIndex];

	useEffect(() => {
		onInitialLoadComplete?.({ familyId: activeQuestion.family_id, collapsed: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<QuestionPrompt id={promptId} body={activeQuestion.body} css={promptStyles} />
			<InstructorViewBelowTheFold
				questionPool={questionPool}
				activePoolIndex={activePoolIndex}
				onPrevious={onPrevious}
				onNext={onNext}
				onStudyStackLinkClick={onStudyStackLinkClick}
			/>
		</>
	);
};

export default StandaloneInstructorView;
