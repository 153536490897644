import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import cn from 'classnames';
import { getUserName } from '@soomo/lib/notebook/utils/index';
import { NoMatchingStudentsSelector, StudentsSelector } from './StudentsSelector';
import popoverStyles from '../SelectorPanel/PopoverSelector.scss';
import StudentsFilterListHeader from './StudentsFilterListHeader';
import styles from './StudentList.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const StudentList = ({
	students,
	currentStudentId,
	studentsFilters,
	filteringEnabled,
	noMatchingStudents,
	showOverallClassPerformance,
	onSelect,
	onOverallClassPerformanceSelect,
	onClose
}) => {
	const matchingStudents = students.filter((student) => student.matches);

	return (
		<div className={popoverStyles.PopoverMenu}>
			<IconButton
				aria-label="Hide Table of Contents"
				size="large"
				onClick={onClose}
				className={popoverStyles.CloseButton}>
				<CloseIcon role="presentation" focusable={false} size={20} />
			</IconButton>
			<List>
				{filteringEnabled ? (
					<StudentsFilterListHeader
						studentsFilters={studentsFilters}
						noMatchingStudents={noMatchingStudents}
					/>
				) : (
					// Compensates for the close button
					<div className={styles.StudentListItemPlaceholder} />
				)}

				{showOverallClassPerformance && (
					<ListItem
						key="overall-class-performance"
						classes={{
							root: styles.StudentListItem
						}}>
						<Button
							role="link"
							onClick={() => onOverallClassPerformanceSelect()}
							className={styles.StudentLabel}>
							<span
								className={cn(styles.StudentName, {
									[styles.CurrentStudentName]: currentStudentId === null,
									[styles.OverallClassPerformance]: filteringEnabled
								})}>
								Overall Class Performance
							</span>
						</Button>
					</ListItem>
				)}

				{matchingStudents.map((student) => {
					const isCurrentStudent = student.id === currentStudentId;
					return (
						<ListItem
							key={student.id}
							classes={{
								root: styles.StudentListItem
							}}>
							<Button
								role="link"
								aria-current={isCurrentStudent && 'location'}
								onClick={(event) => onSelect(event, student.id)}
								classes={{
									root: styles.StudentLabel
								}}>
								{filteringEnabled && (
									<span
										className={cn(styles.CommentIcon, {
											'ss-compose': student.status.alreadyCommented
										})}
									/>
								)}
								<span
									className={cn(styles.StudentName, {
										[styles.CurrentStudentName]: isCurrentStudent
									})}>
									{getUserName(student)}
								</span>
							</Button>
						</ListItem>
					);
				})}
			</List>
		</div>
	);
};

StudentList.propTypes = {
	students: PropTypes.array,
	currentStudentId: PropTypes.number,
	studentsFilters: PropTypes.array,
	filteringEnabled: PropTypes.bool,
	noMatchingStudents: PropTypes.bool,
	onSelect: PropTypes.func,
	onClose: PropTypes.func
};

const mapStateToProps = (state) => ({
	students: StudentsSelector(state),
	currentStudentId: state.ui.currentViewParams.student,
	studentsFilters: state.students.filters,
	noMatchingStudents: NoMatchingStudentsSelector(state),
	showOverallClassPerformance: state.entities.featureFlags.showOverallClassPerformance
});

export default connect(mapStateToProps)(StudentList);
