import React from 'react';

const annotateTime = (str, showMillis) => {
	if (showMillis) {
		if (str.indexOf('.') === -1) {
			return str;
		} else {
			const splits = str.split('.');
			return (
				<>
					{splits[0]}.<span>{splits[1]}</span>
				</>
			);
		}
	} else {
		return '-' + str;
	}
};

const formatTime = (time, showMillis) => {
	const date = new Date(null);
	date.setMilliseconds(time);

	let start, pick;
	if (time < 60 * 60 * 1000) {
		start = 14;
		pick = 7;
	} else {
		start = 11;
		pick = 10;
	}

	if (!showMillis) {
		pick = pick - 2;
	}

	return annotateTime(date.toISOString().substr(start, pick), showMillis);
};

export function formatTimeElapsed(timeElapsed) {
	return formatTime(timeElapsed, true);
}

export function formatTimeRemaining(timeRemaining) {
	return formatTime(timeRemaining, false);
}
