import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, mixins, Theme } from '~/styles/themes';

export const actionToast = (theme: Theme): SerializedStyles => css`
	&.MuiSnackbar-root .MuiAlert-root {
		@media (min-width: ${breakpoints.small}) {
			white-space: nowrap;
			min-width: 480px;
		}
	}
`;
