import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	HiChevronLeft as PreviousQuestionIcon,
	HiChevronRight as NextQuestionIcon
} from 'react-icons/hi';
import {
	IoCheckmarkSharp as QuestionCorrectIcon,
	IoCloseSharp as QuestionIncorrectIcon
} from 'react-icons/io5';
import { RiCheckboxMultipleFill as QuestionDeckIcon } from 'react-icons/ri';

import { ClassNames } from '@emotion/react';

import { WebtextButton } from '~/components';
import { UniversalVelvetLeftBorder, MultipleChoiceQuestion } from '~/components/pageElements';
import { WebtextQuestion, QuestionType } from '~/components/shared/Question';
import { useIsUniversalVelvet, useAccessibilityFocus } from '~/hooks';
import { MCQuestionAnswer, MCQuestionCorrectChoice } from '~/types';
import { numberToOrdinalWord, numberToWord } from '~/utils/formatting';

import { MultipleChoiceQuestionRef } from '../MultipleChoiceQuestion/MultipleChoiceQuestion';
import { DisplayedQuestionElementProps } from '../MultipleChoiceQuestionPool/types';
import NextQuestionReminderTooltip from './NextQuestionReminderTooltip';
import styles from './styles';

import type { MCQuestionElement } from '~/types/WebtextManifest';

const REQUIRED_REJOINDER_INTERSECTION_RATIO = 1.0;
/**
 * Grows/shrinks the intersection area. Top/right/bottom/left order, like `margin`.
 * We grow the intersection area by 200px at the bottom (so that the rejoinder must be fully visible *and* there's an
 * additional 200px below it). We *shrink* the intersection area by -50px on the left, because there's a
 * `margin-left: -50px` rule on `.rejoinder`. If we don't account for that, then `.rejoinder` will never intersect
 * on mobile because it's slightly off screen relative to the scrolling element. (See T-60154.)
 */
const REQUIRED_INTERSECTION_ROOT_MARGIN = '0px 0px -200px 50px';

const questionStatus = (answer: MCQuestionAnswer): 'incomplete' | 'correct' | 'incorrect' => {
	if (!answer) {
		return 'incomplete';
	} else if (answer.correct) {
		return 'correct';
	}
	return 'incorrect';
};

type Props = {
	questions: MCQuestionElement[];
	correctChoices?: { [questionFamilyId: string]: MCQuestionCorrectChoice };
	answers: { [questionFamilyId: string]: MCQuestionAnswer };
	onChangeSelectedChoice: (questionFamilyId: string, choiceFamilyId: string) => void;
	onSubmit: (questionFamilyId: string, choiceFamilyId: string) => void | Promise<void>;
	showInstructorView?: boolean;
	readOnly?: boolean;
	seed?: number;

	/** Whether to show the "You're not done yet!" Next Question reminder for this question deck. Defaults to false. */
	nextQuestionReminderEnabled?: boolean;

	/**
	 * The element containing this question deck that is scrolling.
	 *
	 * Most of the time, this will be `document.documentElement`, but if e.g. `<body>` is set to `overflow: hidden`;
	 * and a child element is scrolling instead, then `document.onscroll` and `window.onscroll` will not fire.
	 *
	 * In that case, pass the child element performing the scrolling to this prop.
	 *
	 * Has no effect unless `nextQuestionReminderEnabled` is true.
	 *
	 * Defaults to `document.documentElement`. */
	scrollContainerElement?: HTMLElement;

	/**
	 * @deprecated This property no longer has any effect.
	 *
	 * If `nextQuestionReminderEnabled` is true, "normal" is now always used;
	 * otherwise, "updated" is always used. */
	buttonStyle?: 'updated' | 'normal';

	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
	/**
	 * Whether to shuffle question choices or not. In Core, we shuffle ahead of time on the server, so in that case
	 * we don't want to shuffle twice and should set this to false.
	 *
	 * Defaults to true.
	 */
	shuffleChoices?: boolean;
} & DisplayedQuestionElementProps;

const QuestionDeck: React.VFC<Props> = (props) => {
	const {
		questions,
		correctChoices,
		answers,
		onChangeSelectedChoice,
		onSubmit,
		showInstructorView,
		readOnly,
		seed,
		nextQuestionReminderEnabled,
		scrollContainerElement,
		shuffleChoices = true,
		onInitialLoadComplete,
		onVisibilityChange
	} = props;
	const [hasScrolledAway, setHasScrolledAway] = useState(false);
	const [dummyRef, setFocusToDummy] = useAccessibilityFocus();
	const isUniversalVelvet = useIsUniversalVelvet();
	const [containerTopPositionWhenMCQAnswered, setContainerTopPositionWhenMCQAnswered] = useState<
		number | null
	>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const mcqRefs = useRef<MultipleChoiceQuestionRef[]>(Array(questions.length));
	const [wasRejoinderFullyVisible, setWasRejoinderFullyVisible] = useState(false);
	const answersArray = questions.map((mcq) => answers[mcq.family_id]);
	const rejoinderIntersectionObserver = useRef<IntersectionObserver | null>(null);

	const [activeIndex, _setActiveIndex] = useState(0);
	const setActiveIndexAndNotifyVisibility = useCallback(
		(setAction, scope: 'toggle_item' | 'try_again') => {
			const newIndex = typeof setAction === 'function' ? setAction(activeIndex) : setAction;
			if (activeIndex !== newIndex) {
				_setActiveIndex(newIndex);
				onVisibilityChange?.({
					familyId: questions[newIndex].family_id,
					scope: scope,
					collapsed: false
				});
			}
		},
		[activeIndex, onVisibilityChange, questions]
	);

	// call this function to reset all of flags that control the next question reminder's visibility.
	const resetNextQuestionReminderFlags = useCallback(() => {
		setHasScrolledAway(false);
		setWasRejoinderFullyVisible(false);
		setContainerTopPositionWhenMCQAnswered(null);
	}, []);

	// reset flags when active question changes
	useEffect(() => {
		resetNextQuestionReminderFlags();
	}, [activeIndex, resetNextQuestionReminderFlags]);

	// a change to the seed implies that a reset was performed,
	// so jump back to the start of the question deck after a reset
	useEffect(() => {
		_setActiveIndex(0);
		resetNextQuestionReminderFlags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seed]);

	// create an IntersectionObserver only (a) when component mounts and (b) when scrollContainerElement changes.
	// otherwise we'll make a new IntersectionObserver on every render.
	useEffect(() => {
		rejoinderIntersectionObserver.current = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (!wasRejoinderFullyVisible && entry.isIntersecting) {
						setWasRejoinderFullyVisible(true);
						rejoinderIntersectionObserver.current.disconnect();
					}
				});
			},
			{
				root: scrollContainerElement ?? document.documentElement,
				threshold: REQUIRED_REJOINDER_INTERSECTION_RATIO,
				rootMargin: REQUIRED_INTERSECTION_ROOT_MARGIN
			}
		);
		// disconnect the now-stale observer in the cleanup function so that it can be garbage collected.
		return () => rejoinderIntersectionObserver.current.disconnect();
		// don't react to wasRejoinderFullyVisible changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollContainerElement]);

	// Upon load:
	// - if the question deck reminder is NOT enabled, jump to the first *unanswered* question, if there is one.
	// - if the question deck reminder IS enabled, find the first answered question before an unanswered question,
	//   jump to that question, and immediately trigger the next question reminder.
	useEffect(() => {
		let index = 0;
		for (let i = 0; i < questions.length; i++) {
			if (!nextQuestionReminderEnabled && !answers[questions[i].family_id]) {
				index = i;
				break;
			} else if (
				nextQuestionReminderEnabled &&
				!answers[questions[i].family_id] &&
				i > 0 &&
				answers[questions[i - 1].family_id] != null
			) {
				index = i - 1;
				setHasScrolledAway(true);
				setWasRejoinderFullyVisible(true);
				break;
			}
		}
		_setActiveIndex(index); // Don't onNotifyVisibility on initial load. That's for user-action-driven changes
		onInitialLoadComplete?.({ familyId: questions[index].family_id, collapsed: false });
		// we don't want to jump past a question the moment `answers` updates, so we underspecify the deps array
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// track when the user has scrolled down from the position they were at when they answered the current question.
	useEffect(() => {
		if (nextQuestionReminderEnabled && containerTopPositionWhenMCQAnswered != null) {
			const listener = () => {
				if (
					containerRef.current?.getBoundingClientRect().top < containerTopPositionWhenMCQAnswered
				) {
					setHasScrolledAway(true);
					(scrollContainerElement ?? document).removeEventListener('scroll', listener);
				}
			};
			(scrollContainerElement ?? document).addEventListener('scroll', listener);
			return () => {
				(scrollContainerElement ?? document).removeEventListener('scroll', listener);
			};
		}
	}, [containerTopPositionWhenMCQAnswered, nextQuestionReminderEnabled, scrollContainerElement]);

	// track if the rejoinder is fully visible
	useEffect(() => {
		if (nextQuestionReminderEnabled && answersArray[activeIndex] != null) {
			rejoinderIntersectionObserver.current.observe(
				mcqRefs.current[activeIndex].rejoinderContainer
			);
			return () => rejoinderIntersectionObserver.current.disconnect();
		}
	}, [activeIndex, answersArray, nextQuestionReminderEnabled, scrollContainerElement]);

	const handlePreviousQuestion = () => {
		setFocusToDummy().then(() =>
			setActiveIndexAndNotifyVisibility((old) => old - 1, 'toggle_item')
		);
	};

	const handleNextQuestion = () => {
		setFocusToDummy().then(() =>
			setActiveIndexAndNotifyVisibility((old) => old + 1, 'toggle_item')
		);
	};

	const handleSubmit = (questionFamilyId: string, choiceFamilyId: string) => {
		setContainerTopPositionWhenMCQAnswered(containerRef.current?.getBoundingClientRect().top);
		return onSubmit(questionFamilyId, choiceFamilyId);
	};

	const handleJumpToQuestion = (newActiveIndex: number) => {
		setFocusToDummy().then(() => setActiveIndexAndNotifyVisibility(newActiveIndex, 'toggle_item'));
	};

	const numAttempted = answersArray.filter((answer) => Boolean(answer)).length;
	const numCorrect = answersArray.filter((answer) => answer?.correct).length;
	const numIncorrect = answersArray.filter((answer) => answer && !answer.correct).length;
	const nextQuestionButtonStyle = nextQuestionReminderEnabled ? 'normal' : 'updated';
	const isNextQuestionButtonVisible =
		answersArray[activeIndex] != null && activeIndex < questions.length - 1;
	const allQuestionsAnswered = numAttempted === questions.length;
	const shouldShowNextQuestionReminder =
		nextQuestionReminderEnabled &&
		hasScrolledAway &&
		wasRejoinderFullyVisible &&
		isNextQuestionButtonVisible &&
		!allQuestionsAnswered;

	const renderNextQuestionButton = () => {
		if (nextQuestionButtonStyle === 'updated') {
			return (
				<button
					className="updated-next-question-button"
					onClick={handleNextQuestion}
					data-testid="nextQuestionBottomButton">
					<span className="button-label">NEXT QUESTION</span>
					<NextQuestionIcon aria-hidden="true" className="right-icon" />
				</button>
			);
		}

		// if nextQuestionButtonStyle === 'normal',
		// then nextQuestionReminderEnabled === true
		const normalStyleButton = (
			<WebtextButton
				className="next-question-bottom-button"
				onClick={handleNextQuestion}
				data-testid="nextQuestionBottomButton">
				Next Question
			</WebtextButton>
		);

		return shouldShowNextQuestionReminder ? (
			<NextQuestionReminderTooltip
				open={hasScrolledAway}
				title={`You’re not done yet! Click the “Next Question” button to start the ${numberToOrdinalWord(
					activeIndex + 1 + 1 // +1 because we're zero-indexed, then +1 for next index
				)} question in this set of ${numberToWord(questions.length)}.`}>
				{normalStyleButton}
			</NextQuestionReminderTooltip>
		) : (
			normalStyleButton
		);
	};

	return (
		<ClassNames>
			{({ cx }) => (
				<div ref={containerRef} css={styles(nextQuestionButtonStyle)}>
					<WebtextQuestion
						noBottomMargin={props.noBottomMargin}
						className={cx(
							'question-deck',
							showInstructorView ? 'instructor-view' : 'student-view'
						)}>
						<UniversalVelvetLeftBorder>
							<QuestionType>
								{isUniversalVelvet && (
									<QuestionDeckIcon className="question-deck-icon" aria-hidden="true" />
								)}
								<span className="question-deck-heading">
									{questions.length} Multiple-Choice Questions
								</span>
							</QuestionType>
							<div
								className={cx(
									'mc-question-panels',
									answersArray[activeIndex]
										? 'active-question-answered'
										: 'active-question-unanswered'
								)}>
								<span className="visually-hidden" tabIndex={-1} ref={dummyRef}>
									&nbsp;
								</span>
								{questions.map((mcq, i) => (
									<div
										key={mcq.family_id}
										className={'mc-question-panel'}
										hidden={i !== activeIndex}
										data-testid="question-panel">
										<span className="visually-hidden">Question {i + 1}</span>
										<MultipleChoiceQuestion
											questionFamilyId={mcq.family_id}
											body={mcq.body}
											choices={mcq.choices}
											correctChoice={(correctChoices || {})[mcq.family_id]}
											answer={answersArray[i]}
											onSubmit={handleSubmit}
											onChangeSelectedChoice={onChangeSelectedChoice}
											seed={seed}
											showInstructorView={showInstructorView}
											readOnly={readOnly}
											isInQuestionDeck
											ref={(mcq) => (mcqRefs.current[i] = mcq)}
											shuffleChoices={shuffleChoices}
										/>
									</div>
								))}
								<div className="previous-next-question-buttons">
									<button
										className="previous-question-button"
										aria-label="Previous question"
										disabled={activeIndex === 0}
										onClick={handlePreviousQuestion}>
										<PreviousQuestionIcon aria-hidden="true" />
									</button>
									<button
										className="next-question-button"
										aria-label="Next question"
										disabled={activeIndex === questions.length - 1}
										onClick={handleNextQuestion}>
										<NextQuestionIcon aria-hidden="true" />
									</button>
								</div>
								<div
									className={cx(
										'status-and-next-question-button',
										isNextQuestionButtonVisible && 'has-next-question-button'
									)}>
									<div className="question-status-section">
										<span className="answer-status-text">
											{`${numAttempted} of ${questions.length} attempted` +
												(numAttempted > 0 ? ` | ${numCorrect} correct` : '') +
												(numAttempted > 0 && numIncorrect > 0
													? ` | ${numIncorrect} incorrect`
													: '')}
										</span>
										<ol className="question-buttons">
											{questions.map((mcq, i) => {
												const status = questionStatus(answersArray[i]);
												return (
													<li key={mcq.family_id}>
														<button
															aria-label={`Question ${i + 1}: ${status}`}
															className={cx(i === activeIndex ? 'active' : 'inactive')}
															onClick={() => handleJumpToQuestion(i)}>
															{status === 'correct' && (
																<QuestionCorrectIcon
																	className="checkmark-icon"
																	data-testid="checkmark"
																	aria-hidden="true"
																/>
															)}
															{status === 'incorrect' && (
																<QuestionIncorrectIcon data-testid="cross" aria-hidden="true" />
															)}
														</button>
													</li>
												);
											})}
										</ol>
									</div>
									<div
										className={cx(
											'next-question-button-container',
											shouldShowNextQuestionReminder && 'reminder-visible'
										)}>
										{isNextQuestionButtonVisible && renderNextQuestionButton()}
									</div>
								</div>
							</div>
						</UniversalVelvetLeftBorder>
						<div className="backdrop" hidden={!shouldShowNextQuestionReminder} />
					</WebtextQuestion>
					<hr className="visually-hidden" />
				</div>
			)}
		</ClassNames>
	);
};
export default QuestionDeck;
