import React from 'react';

import { css } from '@emotion/react';
import cn from 'classnames';

import { ActivityHeader } from '~/notebook/components';
import { elementStyles } from '~/notebook/styles';
import { ElementAnswer, PopupQuizAnswer } from '~/notebook/types';
import { unlinkify } from '~/utils';

import { getSortedDimensionsWithScores } from './MultiDimensionalResults';
import { PopupQuiz, PopupQuizLoadDataStarted, PopupQuizQuestion, PopupQuizResults } from './types';

const mdTableStyles = css`
	margin-bottom: 1rem;
	td:first-of-type {
		padding: 0 10px 0 25px;
	}
`;

interface Props {
	popupQuiz: PopupQuiz;
	questions: PopupQuizQuestion[];
	answers: PopupQuizAnswer[];
}

const NotebookView: React.FunctionComponent<Props> = (props) => {
	const { popupQuiz, questions, answers } = props;
	const resultsOptions = (popupQuiz.ui && popupQuiz.ui.results) || {};
	const { showTotalScoreIntepretationAfterTotalScoreBar } = resultsOptions;
	const notebookOptions = (popupQuiz.ui && popupQuiz.ui.notebook) || {};
	const { showBasis } = notebookOptions;
	const showScores = notebookOptions.showScores != null ? notebookOptions.showScores : true;

	const latestAnswer = answers[answers.length - 1];
	const data = latestAnswer && latestAnswer.reset_at == null ? latestAnswer.data : {};
	const results = data as PopupQuizLoadDataStarted;

	const answerCount = results.result ? Object.keys(results.result).length : 0;
	const questionCount = questions.length;

	const started = answerCount > 0;
	const completed = results.completed;

	let questionInterpretations = null;
	if (completed) {
		if (results.questionScoreInterpretations.length > 0) {
			questionInterpretations = results.questionScoreInterpretations.map(function (interpretation) {
				return (
					<p key={interpretation.key}>
						<b>{interpretation.key}</b>
						&nbsp;
						<span dangerouslySetInnerHTML={{ __html: interpretation.explanation }} />
					</p>
				);
			});
		} else if (results.scores != null && popupQuiz.dimensions.length > 1 && showScores) {
			questionInterpretations = (
				<table css={mdTableStyles}>
					<tbody>
						{getSortedDimensionsWithScores(popupQuiz, results as PopupQuizResults).map(function ({
							dimension,
							score,
							basis
						}) {
							const scoreText = showBasis ? `${score} out of ${basis}` : score;
							return (
								<tr key={dimension.key}>
									<td>{dimension.name}</td>
									<td>{scoreText}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			);
		}
	}

	let topLevelScoreInterpretation = null;
	if (
		popupQuiz.dimensions &&
		popupQuiz.dimensions.length === 1 &&
		results.dimensionScoreInterpretations
	) {
		const interpretation = results.dimensionScoreInterpretations[popupQuiz.dimensions[0].key];
		topLevelScoreInterpretation = (
			<>
				<p
					className="comfortable"
					dangerouslySetInnerHTML={{ __html: interpretation.topLineResult }}
				/>
				<p
					className="comfortable"
					dangerouslySetInnerHTML={{ __html: interpretation.explanation }}
				/>
			</>
		);
	} else {
		topLevelScoreInterpretation = (
			<>
				<p className="comfortable" dangerouslySetInnerHTML={{ __html: results.topLineResult }} />
				<p className="comfortable" dangerouslySetInnerHTML={{ __html: results.explanation }} />
			</>
		);
	}

	return (
		<div css={elementStyles}>
			<div className="element-content">
				<div className={cn('element-response', completed && 'completed')}>
					{completed && (
						<>
							{!showTotalScoreIntepretationAfterTotalScoreBar && topLevelScoreInterpretation}
							{questionInterpretations != null && (
								<>
									<p
										className="question-interpretation-heading comfortable"
										dangerouslySetInnerHTML={{
											__html:
												results.questionScoreInterpretationsHeading.length > 0
													? results.questionScoreInterpretationsHeading
													: ''
										}}
									/>
									<div className="question-interpretations-container">
										{questionInterpretations}
									</div>
								</>
							)}
							{showTotalScoreIntepretationAfterTotalScoreBar && topLevelScoreInterpretation}
						</>
					)}
					{!started && <ActivityHeader css={activityHeaderStyles}>Not yet started</ActivityHeader>}
					{started && !completed && (
						<div className="incomplete">
							<p>
								<i>
									Completed {answerCount} of {questionCount} question{questionCount !== 1 && 's'}.
								</i>
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const transformAnswerForNotebookView = (answer: ElementAnswer): PopupQuizAnswer => {
	const data = answer.body ? JSON.parse(answer.body) : {};

	if (data.explanation) {
		data.explanation = unlinkify(data.explanation);
	}
	return { ...answer, data };
};

// Override ActivityHeader styles as we use it inside the NotebookView, not as a separate block
const activityHeaderStyles = css`
	margin-top: 14px;

	.activity-header-status {
		margin-left: 0;
		text-transform: uppercase;
	}
`;

export default NotebookView;
