import React, { CSSProperties, FC } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import { getThemeItem, Theme } from '~/styles/themes';

interface Props {
	content: string;
	show?: boolean;
	styles?: CSSProperties;
}

const CellPlaceholder: FC<Props> = ({ content, show, styles }) =>
	show && (
		// Wrapper prevents inheritance of the "text-decoration"
		<div style={styles}>
			<span css={placeholderContentStyles} className="placeholder-content">
				{content}
			</span>
		</div>
	);

const placeholderContentStyles = (theme: Theme): SerializedStyles => {
	const placeholderColor = getThemeItem(theme.colors.placeholderGray, theme);
	return css`
		// Prevents inheritance of the "text-decoration" https://stackoverflow.com/a/17347691/10963661
		display: inline-block;
		color: ${placeholderColor};
		font-weight: 400;
		font-style: normal;
	`;
};

export default CellPlaceholder;
