import React, { cloneElement, FC, MouseEvent, ReactElement, useEffect, useRef } from 'react';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { shallow } from 'zustand/shallow';

import {
	menu,
	menuButton,
	menuPopper
} from '~/components/Outline/components/ActionMenu/components/PopperMenu/styles';
import { removeTrailingColon } from '~/components/Outline/helpers';
import {
	selectOpenedMenuId,
	selectResponseValue,
	selectTemplateLabelNumber,
	selectTemplateLabelText,
	useOutlineSelector
} from '~/components/Outline/store';
import { OutlineInstanceAddress } from '~/components/Outline/types';
import { usePrevious } from '~/hooks';

interface Props {
	address: OutlineInstanceAddress;
	buttonId: string;
	items: Array<ReactElement>;
}

/**
 * Implements Popper + Menu adaptation - https://mui.com/material-ui/react-menu/#menulist-composition
 * Usual `Menu` component cannot be used because underlying `Popover` component getting position incorrectly on iOS (https://soomo.height.app/T-63175)
 */
const PopperMenu: FC<Props> = (props) => {
	const { address, buttonId, items } = props;
	const menuId = `${buttonId}_menu`;

	const anchorRef = useRef<HTMLButtonElement>(null);

	const openedMenuId = useOutlineSelector(selectOpenedMenuId);
	const open = openedMenuId === menuId;
	const prevOpen = usePrevious(open);

	const instanceAriaLabel = useOutlineSelector((state) => {
		const number = selectTemplateLabelNumber(state, address);
		const text = selectTemplateLabelText(state, address);
		const label = removeTrailingColon(`${number} ${text}`);
		return `, for ${label}`;
	});
	const instanceAriaResponseValue = useOutlineSelector((state) => {
		const responseValue = selectResponseValue(state, address);
		return responseValue?.length > 0 ? `, ${responseValue}` : '';
	});
	const menuAriaLabel = `Action menu${instanceAriaLabel}${instanceAriaResponseValue}`;

	const { openMenu, closeAllMenus } = useOutlineSelector(
		(state) => ({
			openMenu: state.openMenu,
			closeAllMenus: state.closeAllMenus
		}),
		shallow
	);

	const handleToggle = () => (open ? closeAllMenus() : openMenu(menuId));

	const handleClose = (event: MouseEvent<Document | HTMLLIElement>) => {
		if (anchorRef.current?.contains(event.target as HTMLElement)) return;
		closeAllMenus();
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		switch (event.key) {
			case 'Tab':
				event.preventDefault();
				closeAllMenus();
				break;
			case 'Escape':
				closeAllMenus();
				break;
		}
	}

	// return focus to the button when we transitioned from !open -> open
	useEffect(() => {
		if (prevOpen && !open && !openedMenuId) {
			anchorRef.current?.focus({ preventScroll: true });
		}
	}, [open, openedMenuId, prevOpen]);

	const itemsWithMenuCloseHandler = items.map((item) =>
		cloneElement(item, {
			onClick: (e: MouseEvent<HTMLLIElement>) => {
				item.props?.onClick?.(e);
				handleClose(e);
			}
		})
	);

	if (itemsWithMenuCloseHandler.length === 0) return null;
	return (
		<div css={menu}>
			<Button
				ref={anchorRef}
				id={buttonId}
				css={(theme) => menuButton(theme, { menuOpened: open })}
				className="ally-ignore" // Don't draw the Core's button outline
				aria-label={menuAriaLabel}
				aria-controls={open ? menuId : undefined}
				aria-expanded={open}
				aria-haspopup="true"
				onClick={handleToggle}
				disableRipple>
				...
			</Button>
			<Popper
				css={menuPopper}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement="bottom-start"
				modifiers={{
					flip: {
						enabled: true,
						padding: 30,
						flipVariationsByContent: true
					},
					preventOverflow: {
						enabled: false // Sticks popper to the anchor on scroll
					},
					hide: {
						enabled: false
					}
				}}
				disablePortal>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<MenuList
							id={menuId}
							autoFocusItem={open}
							aria-labelledby={buttonId}
							onKeyDown={handleListKeyDown}>
							{itemsWithMenuCloseHandler}
						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</div>
	);
};

export default PopperMenu;
