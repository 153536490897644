import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { shallow } from 'zustand/shallow';

import { usePreviousRender } from '~/hooks';

import { getCaretExceptSpaceLeft, isNextAccept } from '../Components/Cell/utils';
import { getHighlighters } from '../helpers';
import { getActiveAreaId } from '../helpers/constants';
import { checkFormula } from '../helpers/formula/utils';
import { useSpreadsheetSelector } from '../store/provider';

function useInteractiveInputElement(
	inputElement: HTMLTextAreaElement,
	handleChange: (value: string) => void
): {
	caretPosition: number;
	setCaretPosition: (value: number) => void;
	isFormulaMode: boolean;
} {
	const [caretPosition, setCaretPosition] = useState(0);
	const [isFormulaMode, setFormulaMode] = useState(false);

	const {
		formulaSelectedValue,
		updateFormulaInputMethod,
		activeInputValue,
		formulaInputMethod,
		updateCellHighlights,
		activeAreaId,
		dest
	} = useSpreadsheetSelector(
		(state) => ({
			formulaSelectedValue: state.formulaSelectedValue,
			activeInputValue: state.activeInputValue,
			formulaInputMethod: state.formulaInputMethod,
			updateCellHighlights: state.updateCellHighlights,
			updateFormulaInputMethod: state.updateFormulaInputMethod,
			activeAreaId: state.activeAreaId,
			dest: state.dest
		}),
		shallow
	);

	const isAreaActive = useMemo(() => {
		if (!inputElement) return activeAreaId === getActiveAreaId(dest);
		return inputElement?.id === activeAreaId;
	}, [activeAreaId, inputElement, dest]);

	useEffect(() => {
		if (!formulaSelectedValue?.length || !isAreaActive || !inputElement) return;

		const caretPlus = caretPosition + formulaSelectedValue.length;
		inputElement.selectionStart = caretPlus;
		inputElement.selectionEnd = caretPlus;
		inputElement?.focus({ preventScroll: true });
	}, [activeInputValue, formulaSelectedValue, caretPosition, isAreaActive, inputElement]);

	useEffect(() => {
		if (!isAreaActive) return;

		/**
		 * Set caret position tracking
		 */
		if (formulaInputMethod !== 'selection') {
			setCaretPosition(inputElement?.selectionStart ?? 0);
		}

		/**
		 * Enable formula mode
		 */
		if (activeInputValue?.startsWith('=')) {
			setFormulaMode(true);
			updateCellHighlights(getHighlighters(activeInputValue));
			return;
		}

		/**
		 * Disable formula mode
		 */
		setFormulaMode(false);
		updateCellHighlights([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeInputValue, formulaInputMethod, isAreaActive]);

	const setSelectionCatch = () => {
		/**
		 * List of symbols which precede enabling catching mode
		 * note: :(column) is enabling in Excel even for following ranges
		 */
		const formulaCatchSymbols = ['=', '+', '-', '*', '/', '(', ':'];

		/**
		 * Get previous symbol
		 */
		const beforeCaret = getCaretExceptSpaceLeft(activeInputValue, caretPosition);

		const shouldCatchInlineExpression =
			formulaCatchSymbols.includes(beforeCaret) && isNextAccept(activeInputValue, caretPosition);

		if (shouldCatchInlineExpression) {
			updateFormulaInputMethod('selection');
			return;
		}

		/**
		 * list of symbols that enable catching mode inside a formula range
		 */
		const formulaInsideFormulaCatchSymbols = ['(', ';', ','];
		const formulas = checkFormula(activeInputValue);
		const formulaRanges = formulas.map((e) => [e.index, e.index + e[0].length]);

		/**
		 * We're checking is caret currently placed in a range of formula
		 */
		const isInsideFormula = formulaRanges.some(
			([formulaStarts, formulaEnds]) =>
				caretPosition >= formulaStarts && caretPosition <= formulaEnds
		);

		const isEnableCatchingForNamedFormula =
			formulaRanges.length > 0 &&
			/**
			 * slice(1) removes "=" from acceptable list, because inside formulas it should be ignored
			 */
			[...formulaInsideFormulaCatchSymbols, ...formulaCatchSymbols.slice(1)].includes(
				beforeCaret
			) &&
			isNextAccept(activeInputValue, caretPosition, [')']);

		if (isEnableCatchingForNamedFormula) {
			updateFormulaInputMethod('selection');
			return;
		}

		const isFormulaNextAcceptable =
			activeInputValue[caretPosition] === ')' ||
			/**
			 * ( symbol is not acceptable here, only ; and ,
			 */
			formulaInsideFormulaCatchSymbols.slice(1).includes(activeInputValue[caretPosition]) ||
			!activeInputValue[caretPosition];

		if (isInsideFormula && isFormulaNextAcceptable) {
			updateFormulaInputMethod('selection');
			return;
		}

		updateFormulaInputMethod('input');
	};

	const previouslySelectedValue = usePreviousRender(formulaSelectedValue);

	useLayoutEffect(() => {
		/**
		 * Replace values inside a formula with selection.
		 */
		if (formulaInputMethod !== 'selection' || !isAreaActive) return;
		if (!formulaSelectedValue) return;

		const sliceStart = activeInputValue.slice(0, caretPosition);
		const sliceEnd = activeInputValue.slice(caretPosition);

		if (!previouslySelectedValue) {
			handleChange(`${sliceStart}${formulaSelectedValue}${sliceEnd}`);
		} else {
			const sliceEndModified = sliceEnd.replace(previouslySelectedValue, formulaSelectedValue);
			handleChange(`${sliceStart}${sliceEndModified}`);
		}

		/**
		 * Setting timeout here is a workaround needed only for FF. Because FF is peculiar with focus selection:
		 * - It loses selection on each odd click, which is not happening anywhere else
		 * - We're setting focus on each tempFormulaValue update, because empty value means click on the same cell that was previous
		 * - If we not do this, click in formula mode on the same cell twice, will not have effect
		 */
		setTimeout(() => inputElement?.focus({ preventScroll: true }));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formulaSelectedValue, isAreaActive]);

	useEffect(() => {
		if (!isAreaActive) return;

		/**
		 * By default there cen't be selection mode w/o formula
		 */
		if (!isFormulaMode) {
			updateFormulaInputMethod('input');
			return;
		}

		setSelectionCatch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [caretPosition, isFormulaMode, isAreaActive]);

	return {
		caretPosition,
		setCaretPosition,
		isFormulaMode
	};
}

export default useInteractiveInputElement;
