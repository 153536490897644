import { ValidationOptions } from '../types';

export const fromHtmlToRawText = (str: string): string => str.replace(/(<([^>]+)>)/gi, '');

export const getWordOptimum = (validation: ValidationOptions): number =>
	validation?.['word-count']?.optimum;

export const getMinimalEditorHeight = (count: number): number => count * 2 + 40;

export const editorId = (builderFamilyId: string, dest: string): string =>
	`${builderFamilyId}_${dest}_editor`;

export const placeholderId = (builderFamilyId: string, dest: string, rt = true): string =>
	`${builderFamilyId}_${dest}_${rt ? 'rt' : 'area'}_placeholder`;

export const placeCaretAtEnd = (el): void => {
	if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
		const range = document.createRange();
		range.selectNodeContents(el);
		range.collapse(false);
		const sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	}
};
