import { css, SerializedStyles } from '@emotion/react';

export default (theme): SerializedStyles => css`
	position: relative;
	overflow: hidden;

	* {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		user-select: none;
	}
`;
