import { useTheme } from '@emotion/react';

import { Theme, ThemeName } from '~/styles/themes';

/**
 * Only to be used in the context of a theme provider
 */
export const useIsUniversalVelvet = () => {
	const theme = useTheme();
	const isUniversalVelvet = theme && theme.name === ThemeName.UNIVERSAL_VELVET;
	return isUniversalVelvet || false;
};
