export const getCustomEventElement = (): HTMLDivElement => {
	if (!window._customEventTargetElement) {
		window._customEventTargetElement = document.createElement('div');
	}
	return window._customEventTargetElement;
};

export function emitCustomEvent<T>(event: CustomEvent<T>): void;
export function emitCustomEvent<T>(eventName: string, data?: T): void;
export function emitCustomEvent<T>(...args: [CustomEvent<T>] | [string, T?]): void {
	const element = getCustomEventElement();
	let event: CustomEvent<T>;
	if (args[0] instanceof CustomEvent) {
		event = args[0];
	} else {
		const [eventName, data] = args;
		event = new CustomEvent<T>(eventName, { detail: data });
	}
	element.dispatchEvent(event);
}
