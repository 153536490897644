import React, { createContext, FC, useEffect, useMemo } from 'react';

import isEmpty from 'lodash-es/isEmpty';
import { useImmerReducer } from 'use-immer';

import {
	HistoricalValidationState,
	ValidationAction,
	ValidationActionType,
	ValidationItemStatus,
	ValidationItemType,
	ResponseValidationResults,
	ValidationState,
	ValidationStatus
} from '../types';

/**
 * Set a global state of the validation
 * The validation flow:
 *
 * ┌─────────────────────┐  validation/run  ┌─────────────────────┐
 * │"Submit" button click├─────────────────►│ validation status   │
 * └─────────────────────┘                  │ changed to "Pending"│
 *                                          └─────────┬───────────┘
 *                                                    │
 *   ┌─────────────────────────────┐                  │
 *   │ components w/ client-side   │                  │
 *   │ validation updates state of │◄─────────────────┘
 *   │ their prompts               │
 *   └────┬──────────────────────┬─┘
 *        │                      │
 *        │                      │        All prompts successfully validated -
 *        │After at least one    │              validation/success
 *        │prompt rejected -     │              ┌─────────────────────┐
 *        │validation/rejected   │              │ validation status   │
 *        │                      └─────────────►│ changed to "success"│     Response doesn't contain
 *        ▼                                     │                     │      validation_results
 *    ┌────────────────────┐                    │ Submission continue,├──────────────┐
 *    │validation status   │                    │ storing request is  │              ▼
 *    │changed to "failure"│                    │ sent to the server  │   ┌─────────────────────────┐
 *    │                    │                    └────────┬────────────┘   │ Switch to the "ViewMode"│
 *    │Submission stopped  │                             │                └─────────────────────────┘
 *    │                    │                             │
 *    │Error is displayed  │         Response contains   │
 *    └────────────────────┘         validation_results  │
 *                                                       ▼
 *                                               ┌─────────────────────┐
 *                                               │ validation status   │
 *                                               │ changed to "failure"│
 *                                               │                     │
 *                                               │ Error is displayed  │
 *                                               └─────────────────────┘
 */
const validationReducer = (
	state: HistoricalValidationState,
	{ type, value, trigger }: ValidationAction
): HistoricalValidationState => {
	switch (type) {
		/**
		 * Validation run will receive expected spreadsheet (or possibly any inner front-end) validations
		 * When this value is empty, trigger a callback and continue process, like they are not even exist
		 * Otherwise each PI will be validated and when only all of them will be `Success` we'll call this function
		 */
		case ValidationActionType.Run: {
			if (isEmpty(value)) {
				state.previous = state.current;

				/**
				 * Noting to validate here, continue
				 */
				state.current = {
					status: ValidationStatus.Success,
					validations: {},
					trigger
				};
			} else {
				/**
				 * There are some PI that contains expected in-place validations
				 */
				state.current = {
					status: ValidationStatus.ClientPending,
					validations: value,
					trigger
				};
			}
			break;
		}
		case ValidationActionType.RunOnServer:
			state.previous = state.current;
			state.current = {
				...state.current,
				status: ValidationStatus.ServerPending,
				trigger: 'submit'
			};
			break;
		/**
		 * Update status for individual PI
		 */
		case ValidationActionType.Update: {
			Object.entries(value).forEach(([dest, item]) => (state.current.validations[dest] = item));
			break;
		}
		case ValidationActionType.Success: {
			state.previous = state.current;

			/**
			 * This action will be called when all inner validations return success
			 */
			state.current = {
				status: ValidationStatus.Success,
				validations: {},
				trigger: state.current.trigger
			};
			break;
		}
		case ValidationActionType.Reject: {
			state.previous = state.current;

			/**
			 * If we'll got any failure for inner validation or receive validation from back-end,
			 * this will trigger display in component where message expected
			 */
			state.current = {
				status: ValidationStatus.Failure,
				validations: value || state.current.validations || {},
				trigger: state.current.trigger
			};
			break;
		}
		case ValidationActionType.Reset: {
			state.previous = state.current;

			state.current = {
				status: ValidationStatus.Uninitialized,
				validations: {}
			};
			break;
		}
		default:
			return state;
	}
};

interface ValidationsContext {
	validationState: ValidationState;
	previousValidationState: ValidationState;
	dispatchValidation: (action: ValidationAction) => void;
}

export const TemplateValidationContext = createContext<ValidationsContext>(
	{} as ValidationsContext
);

interface Props {
	responseValidationResults?: ResponseValidationResults;
}

const ValidationsProvider: FC<Props> = (props) => {
	const { children, responseValidationResults } = props;

	const initialValidationState = useMemo<HistoricalValidationState>(() => {
		const validationState = {
			validations: {},
			status: ValidationStatus.Uninitialized
		};
		return {
			previous: validationState,
			current: validationState
		};
	}, []);

	/**
	 * TODO Create root reducer, where a generic dispatch will be used allover the Builder code
	 */
	const [historicalValidationState, dispatchValidation] = useImmerReducer(
		validationReducer,
		initialValidationState
	);
	const { validationState, previousValidationState } = useMemo(
		() => ({
			validationState: historicalValidationState.current,
			previousValidationState: historicalValidationState.previous
		}),
		[historicalValidationState]
	);

	useEffect(() => {
		const { validations, status } = validationState;

		if (status !== ValidationStatus.ClientPending) return;

		/**
		 * In case when all pending validation succeed, push success state and continue store flow;
		 */
		const validationValues = Object.values(validations);
		if (validationValues.every(({ status }) => status === ValidationItemStatus.Success)) {
			dispatchValidation({ type: ValidationActionType.Success });
			return;
		}

		/**
		 * Otherwise, send signal to show validations in template
		 */
		if (validationValues.some(({ status }) => status === ValidationItemStatus.Failure)) {
			dispatchValidation({ type: ValidationActionType.Reject });
		}
	}, [validationState]);

	useEffect(() => {
		if (isEmpty(responseValidationResults)) return;

		const isErrorMessagePresent = Object.values(responseValidationResults).some(Boolean);
		if (!isErrorMessagePresent) {
			return dispatchValidation({ type: ValidationActionType.Success });
		}

		const validationValues = Object.entries(responseValidationResults)
			.map(([dest, value]) => ({
				[dest]: {
					...value,
					type: 'received' as ValidationItemType,
					status: ValidationItemStatus.Failure
				}
			}))
			.reduce((acc, value) => ({ ...acc, ...value }), {});

		dispatchValidation({ type: ValidationActionType.Reject, value: validationValues });
	}, [responseValidationResults]);

	/**
	 * Required optimization
	 * https://hswolff.com/blog/how-to-usecontext-with-usereducer/#:~:text=Performance%20Concerns
	 */
	const validationContextValue = useMemo(
		() => ({ validationState, previousValidationState, dispatchValidation }),
		[validationState, previousValidationState]
	);

	return (
		<TemplateValidationContext.Provider value={validationContextValue}>
			{children}
		</TemplateValidationContext.Provider>
	);
};

export default ValidationsProvider;
