import React, { useState } from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import { css } from '@emotion/react';
import cn from 'classnames';

import { useAudioFigures } from '~/hooks';
import { breakpoints } from '~/styles/themes';

import { Spacer } from '../pageElements';
import WebtextPageElement from './WebtextPageElement';

import type {
	BasePageElement,
	CoursePolicy,
	FigureElement,
	Manifest,
	NestingInstruction
} from '~/types/WebtextManifest';

interface Props {
	policyManifest: CoursePolicy;
	webtext: Manifest;
	focusPage?: string;
	mockElements?: string[];
}

const shouldUseMockElement = (e: BasePageElement, mockElements?: string[]) => {
	if (mockElements) {
		if (e.type === 'figure') {
			const figure = e as FigureElement;
			if (
				mockElements.includes('figure:video:youtube') &&
				figure.figure_type === 'video' &&
				figure.payload.includes('youtube')
			) {
				return true;
			}
		}
	}

	return false;
};

const Page: React.FC<Props> = (props) => {
	const { policyManifest, webtext, mockElements } = props;

	const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const { audioRefs, playMediaSnippet } = useAudioFigures();

	const currentChapter = webtext.chapters[webtext.toc.chapter_ids[currentChapterIndex]];
	const currentPage = webtext.pages[currentChapter.page_ids[currentPageIndex]];

	const hasNextPage = currentPageIndex + 1 < currentChapter.page_ids.length;
	const hasNextChapter = currentChapterIndex + 1 < webtext.toc.chapter_ids.length;

	const hasPrevPage = currentPageIndex - 1 > -1;
	const hasPrevChapter = currentChapterIndex - 1 > -1;

	let nestingInstructionClasses: string[] | null = null;
	let nestingInstructionElements: React.ReactElement[] = [];
	let clearNestingInfo = false;

	return (
		<div css={styles}>
			<h4>{`${currentChapterIndex} - ${currentPageIndex}`}</h4>

			{(hasPrevPage || hasPrevChapter) && (
				<button
					title="Back"
					className="fixed-page-nav-btn fixed-page-nav-btn-prev"
					onClick={() => {
						if (hasPrevPage) {
							setCurrentPageIndex(currentPageIndex - 1);
						} else if (hasPrevChapter) {
							setCurrentChapterIndex(currentChapterIndex - 1);
							setCurrentPageIndex(0);
						}

						window.scrollTo(0, 0);
					}}>
					<MdKeyboardArrowLeft color="#666" size="2em" />
				</button>
			)}

			{(hasNextPage || hasNextChapter) && (
				<button
					title="Next"
					className="fixed-page-nav-btn  fixed-page-nav-btn-next"
					onClick={() => {
						if (hasNextPage) {
							setCurrentPageIndex(currentPageIndex + 1);
						} else if (hasNextChapter) {
							setCurrentChapterIndex(currentChapterIndex + 1);
							setCurrentPageIndex(0);
						}
						window.scrollTo(0, 0);
					}}>
					<MdKeyboardArrowRight color="#666" size="2em" />
				</button>
			)}

			<div className="webtext-content">
				{currentPage.element_ids.map((elementFamilyId) => {
					/**
					 * Clear nesting info
					 */
					if (clearNestingInfo) {
						nestingInstructionClasses = null;
						nestingInstructionElements = [];
						clearNestingInfo = false;
					}

					const e = webtext.elements[elementFamilyId];

					if (e.type === 'figure' && (e as FigureElement).figure_type === 'audio') {
						audioRefs.current[e.family_id] = React.createRef();
					}

					if ((e as NestingInstruction)?.direction === 'in') {
						nestingInstructionClasses = (e as NestingInstruction).container_classes;
						return;
					} else if ((e as NestingInstruction)?.direction === 'out') {
						clearNestingInfo = true;
					} else {
						/**
						 * If classes are set this is going to be rendered elsewhere
						 */
						if (nestingInstructionClasses) {
							nestingInstructionElements.push(
								<WebtextPageElement
									key={e.family_id}
									element={e}
									page={currentPage}
									manifest={webtext}
									policyManifest={policyManifest}
									playMediaSnippet={playMediaSnippet}
									containerType="nesting"
									mock={shouldUseMockElement(e, mockElements)}
									{...(e.type === 'figure' && (e as FigureElement).figure_type === 'audio'
										? { audioFigureRef: audioRefs.current[e.family_id] }
										: {})}
								/>
							);
							return;
						}
					}

					return [
						<WebtextPageElement
							key={e.family_id}
							element={e}
							page={currentPage}
							manifest={webtext}
							policyManifest={policyManifest}
							playMediaSnippet={playMediaSnippet}
							nestingInstructionClasses={nestingInstructionClasses}
							nestingInstructionElements={nestingInstructionElements}
							mock={shouldUseMockElement(e, mockElements)}
							{...(e.type === 'figure' && (e as FigureElement).figure_type === 'audio'
								? { audioFigureRef: audioRefs.current[e.family_id] }
								: {})}
						/>,
						<Spacer key={`spacer-${currentPage.family_id}-${e.family_id}`} />
					];
				})}
			</div>
		</div>
	);
};

export const WebtextContent: React.FC<{ contrast?: boolean }> = (props) => (
	<div css={styles}>
		<div className={cn('webtext-content', { contrast: props.contrast })}>{props.children}</div>
	</div>
);

const styles = (theme) => css`
	.webtext-content {
		max-width: ${theme.layout.contentColumnWidth};

		&.contrast {
			border-left: 1px solid hotpink;
			background: rgba(0, 128, 128, 0.1);
		}

		@media (max-width: ${breakpoints.small}) {
			margin-left: 0;
		}
	}

	.fixed-page-nav-btn {
		position: fixed;
		top: 50%;
		height: 70px;
		width: 40px;
		margin-top: -35px;
		cursor: pointer;
		color: transparent;
		z-index: 50;
		transform: translateZ(0);
	}

	.fixed-page-nav-btn-next {
		right: 0px;
	}

	.fixed-page-nav-btn-prev {
		left: 0px;
	}

	.webtext-figure-asset-image {
		width: 100%;
	}

	/**
	 * Spacing
	 * 
	 * We are going to handle spacing with the <Spacer /> component.  So we should remove
	 * margin-bottom from the last child in any <Text> component.
	 */
	.webtext-text > :last-child {
		margin-bottom: 0 !important;
	}
`;

export default Page;
