import React, { useMemo } from 'react';

import { css } from '@emotion/react';
import { DateTime } from 'luxon';

import WebtextButton from '~/components/WebtextButton';
import { breakpoints, getThemeItem, Theme, ThemeName } from '~/styles/themes';
import { getAttemptsRemaining, getHasLimitedAttempts } from '~/utils/policy';

import { buttonStyles, pastDueDateStyles } from '../styles';

import type { DueDateProps } from '~/types/dueDateProps';
import type { FamilyId } from '~/types/WebtextManifest';

interface Props extends DueDateProps {
	questionPoolFamilyId: FamilyId;
	attemptsAllowed: number;
	resetCount: number;
	isRequestInProgress: boolean;
	disabled?: boolean;
	correct: boolean;
	poolSize: number;
	className?: string;
	onReset: () => Promise<void> | void;
	resetButtonLabel?: string;
	isStudyStack?: boolean;
	onStudyStackLinkClick?: () => void;
}

const ResetButton: React.VFC<Props> = ({
	questionPoolFamilyId,
	onReset,
	isRequestInProgress,
	correct,
	attemptsAllowed,
	resetCount,
	poolSize,
	originallyDueAt: originallyDueAtISO8601,
	penaltyDueAt: penaltyDueAtISO8601,
	className,
	disabled,
	resetButtonLabel,
	isStudyStack,
	onStudyStackLinkClick
}) => {
	const hasLimitedAttempts = getHasLimitedAttempts(attemptsAllowed);
	const attemptsRemaining = getAttemptsRemaining({
		attemptsAllowed,
		resetCount,
		isAnswered: true
	});
	const [originallyDueAt, penaltyDueAt] = useMemo(
		() => [DateTime.fromISO(originallyDueAtISO8601), DateTime.fromISO(penaltyDueAtISO8601)],
		[originallyDueAtISO8601, penaltyDueAtISO8601]
	);

	if (correct && !isStudyStack) {
		return onStudyStackLinkClick ? (
			<div
				css={[notResettableStyles, correctStudyStackLinkStyles]}
				data-has-study-stack-link="true">
				<StudyStackLink onStudyStackLinkClick={onStudyStackLinkClick} />
			</div>
		) : null;
	}

	if (attemptsRemaining <= 0) {
		return (
			<div
				css={notResettableStyles}
				className={className}
				data-has-study-stack-link={!!onStudyStackLinkClick}>
				0 attempts remaining
				{onStudyStackLinkClick && (
					<>
						. <StudyStackLink onStudyStackLinkClick={onStudyStackLinkClick} />
					</>
				)}
			</div>
		);
	}

	const hasDueDate = originallyDueAtISO8601 != null;
	const hasPenaltyDueDate = penaltyDueAtISO8601 != null;
	const finalEndDate =
		hasPenaltyDueDate && penaltyDueAt > originallyDueAt ? penaltyDueAt : originallyDueAt;
	const isPastFinalEndDate = hasDueDate && finalEndDate < DateTime.local();
	const dueDatePassedWarningId = `${questionPoolFamilyId}-reset-button-due-date-passed-warning`;
	const shouldShowDueDatePassedWarning = isPastFinalEndDate && !correct;

	return (
		<div css={styles} className={className}>
			<div className="reminder-text">
				{shouldShowDueDatePassedWarning && (
					<span css={pastDueDateStyles} id={dueDatePassedWarningId}>
						The due date has passed. Correct attempts won’t receive credit.
					</span>
				)}
				{!isPastFinalEndDate && poolSize > 1 && (
					<span>
						Click the Try Again button to check your understanding with another version of this
						question.
					</span>
				)}
			</div>
			<WebtextButton
				aria-describedby={shouldShowDueDatePassedWarning ? dueDatePassedWarningId : undefined}
				data-has-limited-attempts={hasLimitedAttempts}
				onClick={onReset}
				css={(theme) => [buttonStyles, tryAgainButtonStyles(theme)]}
				disabled={disabled || isRequestInProgress}>
				<span>{isRequestInProgress ? 'Resetting...' : resetButtonLabel ?? 'Try Again'}</span>
				{hasLimitedAttempts && (
					<small>
						{attemptsRemaining} {attemptsRemaining === 1 ? 'attempt' : 'attempts'} remaining
					</small>
				)}
			</WebtextButton>
		</div>
	);
};

export default ResetButton;

const styles = css`
	display: grid;
	padding: 1rem 0 0;
	grid-template-columns: 1fr auto;
	align-items: center;
	column-gap: 2rem;
	font-size: 14px;
	line-height: 18px;
	color: #545454;

	@media (max-width: ${breakpoints.small}) {
		grid-template-columns: unset;
		grid-template-rows: auto auto;
		row-gap: 1rem;
	}
`;

const notResettableStyles = css`
	padding: 1rem 0 0;
	text-align: end;
	color: #545454;

	&[data-has-study-stack-link='true'] {
		text-align: start;
	}
`;

const correctStudyStackLinkStyles = css`
	padding-top: 1rem;
`;

const tryAgainButtonStyles = (theme: Theme) => css`
	grid-column: 2;
	display: flex;
	flex-direction: column;
	row-gap: 0;

	@media (max-width: ${breakpoints.small}) {
		grid-column: unset;
		row-gap: 4px;
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET &&
	css`
		&[data-has-limited-attempts='true'] {
			&:not(:disabled) {
				padding-top: 12px;
				padding-bottom: 12px;
			}

			&:disabled {
				padding-top: 11px;
				padding-bottom: 11px;
			}
		}

		@media (max-width: ${breakpoints.small}) {
			display: flex;
			row-gap: 0;
		}
	`}

	small {
		font-weight: normal;
		white-space: nowrap;

		@media (max-width: ${breakpoints.small}) {
			white-space: unset;
		}
	}
`;

const StudyStackLink: React.VFC<{ onStudyStackLinkClick: () => void }> = ({
	onStudyStackLinkClick
}) => {
	const handleStudyStackLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		onStudyStackLinkClick();
	};

	return (
		<span css={studyStackLinkStyles}>
			Check your understanding of this concept and more with{' '}
			<a href="#" onClick={handleStudyStackLinkClick}>
				Study Stack
			</a>
			.
		</span>
	);
};

const studyStackLinkStyles = (theme: Theme) => css`
	a {
		color: ${getThemeItem(theme.colors.link, theme)};
		font-weight: 500;
		text-decoration: underline;
	}
`;
