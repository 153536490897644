import React from 'react';
import round from 'lodash.round';

import { CorrectnessIcon } from '@soomo/lib/notebook/components/index';
import { MCQuestionElement } from '@soomo/lib/types/WebtextManifest';

import BaseTable from './BaseTable';

import pageViewStyles from '../PageView.scss';
import styles from '../AggregatedPageView.scss';
import InfoItem from './InfoItem';

export interface MCQuestionAggregated {
	attemptedAtLeastOnce?: number;
	avgNumberOfAttempts?: number;
	avgInitialAttemptScore?: number;
	avgLatestAttemptScore?: number;
	aggregatedChoices: Record<string, { initialAttempt: number; latestAttempt: number }>;
}

interface Props {
	element: MCQuestionElement;
	isPageActivityLoaded: boolean;
	aggregatedElement?: MCQuestionAggregated;
	totalStudentsCount?: number;
	isPoolItem?: boolean;
	className?: string;
}

const MCQuestion: React.FC<Props> = (props) => {
	const {
		element,
		aggregatedElement,
		totalStudentsCount,
		isPageActivityLoaded,
		isPoolItem = false,
		className
	} = props;

	const {
		attemptedAtLeastOnce = 0,
		avgNumberOfAttempts = 0,
		avgInitialAttemptScore = 0,
		avgLatestAttemptScore = 0,
		aggregatedChoices = {}
	} = aggregatedElement || {};

	const headerRow = ['Response', 'Initial attempt', 'Latest attempt'];
	const dataRows = element.choices.map((choice) => {
		const response = choice.is_correct ? (
			<>
				<CorrectnessIcon correct={true} />
				<span dangerouslySetInnerHTML={{ __html: choice.body }} style={{ marginLeft: '10px' }} />
			</>
		) : (
			<span dangerouslySetInnerHTML={{ __html: choice.body }} />
		);

		return [
			response,
			aggregatedChoices[choice.family_id]?.initialAttempt || 0,
			aggregatedChoices[choice.family_id]?.latestAttempt || 0
		];
	});

	return (
		<div className={className}>
			{!isPoolItem && (
				<div className={pageViewStyles.ActivitySummary}>
					<span className={pageViewStyles.QuestionType}>Multiple-Choice Question</span>
				</div>
			)}
			<h2
				className={pageViewStyles.QuestionBody}
				dangerouslySetInnerHTML={{ __html: element.body }}
			/>

			<BaseTable header={headerRow} rows={dataRows} isDataLoaded={isPageActivityLoaded} />

			{!isPoolItem && (
				<div className={styles.AggregatedQuestionInfo}>
					<InfoItem
						label="Attempted at least once:"
						data={
							isPageActivityLoaded
								? `${attemptedAtLeastOnce}/${totalStudentsCount} students`
								: undefined
						}
					/>

					<InfoItem
						label="Average number of attempts:"
						data={isPageActivityLoaded ? `${round(avgNumberOfAttempts, 2)}` : undefined}
					/>

					<InfoItem
						label="Average initial attempt score:"
						data={isPageActivityLoaded ? `${Math.floor(avgInitialAttemptScore * 100)}%` : undefined}
					/>

					<InfoItem
						label="Average latest attempt score:"
						data={isPageActivityLoaded ? `${Math.floor(avgLatestAttemptScore * 100)}%` : undefined}
					/>
				</div>
			)}
		</div>
	);
};

export default MCQuestion;
