import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import { useCustomEventListener, useResettableTimeout } from '~/hooks';

const ARIA_LIVE_REGION_CLEAR_DELAY = 5000;

export const MakePoliteAnnouncementEventType = 'AriaLiveAnnouncer/makePoliteAnnouncement';
export const MakeAssertiveAnnouncementEventType = 'AriaLiveAnnouncer/makeAssertiveAnnouncement';

const domParser = new DOMParser();
/**
 * Strips any HTML from a given string and returns just its text content.
 */
function stripHtml(text: string) {
	return domParser.parseFromString(text, 'text/html').body.textContent;
}

/**
 * A component that listens to custom events and performs a polite or assertive
 * aria-live announcement. Intended to be used with the `useAriaLiveAnnouncer` hook.
 *
 * Heavily inspired by https://github.com/AlmeroSteyn/react-aria-live
 * (see also https://almerosteyn.com/2017/09/aria-live-regions-in-react).
 */
const AriaLiveAnnouncer: React.VFC = () => {
	// we use two aria-live regions for each announcement type and flip-flop between them,
	// so as to ensure that multiple announcements in quick succession work correctly
	const firstPoliteRegionRef = useRef<HTMLDivElement>(null);
	const secondPoliteRegionRef = useRef<HTMLDivElement>(null);
	const firstAssertiveRegionRef = useRef<HTMLDivElement>(null);
	const secondAssertiveRegionRef = useRef<HTMLDivElement>(null);
	const isUsingFirstPolite = useRef(false);
	const isUsingFirstAssertive = useRef(false);

	// we will clear the aria-live regions after some time so that screen reader users
	// reading the end of the page don't inadvertently see a stale announcement;
	// it's important that we use a resettable timeout because we don't want to clear a live region
	// while a message inside of it is being announced
	const { resetTimeout } = useResettableTimeout(() => {
		firstPoliteRegionRef.current.innerText = '';
		secondPoliteRegionRef.current.innerText = '';
		firstAssertiveRegionRef.current.innerText = '';
		secondAssertiveRegionRef.current.innerText = '';
	}, ARIA_LIVE_REGION_CLEAR_DELAY);

	useCustomEventListener<string>(MakePoliteAnnouncementEventType, (message) => {
		const div = isUsingFirstPolite.current
			? firstPoliteRegionRef.current
			: secondPoliteRegionRef.current;

		// e.g. MultipleChoiceQuestion makes announcements after saving a MC question
		// with the rejoinder content, which has some HTML in it.
		// We should remove this so that the escaped HTML isn't announced.
		div.innerText = stripHtml(message);
		isUsingFirstPolite.current = !isUsingFirstPolite.current;
		resetTimeout();
	});

	useCustomEventListener<string>(MakeAssertiveAnnouncementEventType, (message) => {
		const div = isUsingFirstAssertive.current
			? firstAssertiveRegionRef.current
			: secondAssertiveRegionRef.current;

		div.innerText = stripHtml(message);
		isUsingFirstAssertive.current = !isUsingFirstAssertive.current;
		resetTimeout();
	});

	return createPortal(
		<>
			<div className="visually-hidden" aria-live="polite" ref={firstPoliteRegionRef} />
			<div className="visually-hidden" aria-live="polite" ref={secondPoliteRegionRef} />
			<div className="visually-hidden" aria-live="assertive" ref={firstAssertiveRegionRef} />
			<div className="visually-hidden" aria-live="assertive" ref={secondAssertiveRegionRef} />
		</>,
		document.body
	);
};
export default AriaLiveAnnouncer;
