import React from 'react';

import { css, withTheme } from '@emotion/react';
import maintainDisabled from 'ally.js/maintain/disabled';
import maintainHidden from 'ally.js/maintain/hidden';

import { WebtextQuestion } from '~/components/shared/Question';
import { mixins, Theme, breakpoints, ThemeName } from '~/styles/themes';
import { AllyOptions } from '~/types';
import { Policy } from '~/types/WebtextManifest';

import InlineQuizView from './InlineQuizView';
import QuizInstructionsView from './QuizInstructionsView';
import QuizModal from './QuizModal';
import QuizQuestionView from './QuizQuestionView';
import QuizResultsView from './QuizResultsView';
import {
	PopupQuiz as PopupQuizType,
	PopupQuizQuestion,
	PopupQuizAnswers,
	PopupQuizResults
} from './types';

const styles = (theme) => {
	const { popupQuiz } = theme;

	return css`
		font-family: ${popupQuiz.fontFamily};
		max-width: ${theme.layout.contentColumnWidth};

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 15px 0;
			color: ${popupQuiz.colors.primary};
			font-family: ${popupQuiz.heading.fontFamily};
			font-weight: ${popupQuiz.heading.fontWeight};
			text-transform: none;
		}

		.element-title {
			${mixins.elementTitle(theme)}
		}

		.element-body {
			background-color: #f7f7f7;
			font-size: 16px;
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET &&
		css`
			.popup-quiz-divider {
				height: ${popupQuiz.divider.height};
				background-color: ${popupQuiz.divider.backgroundColor};
				border: none;
				margin: 24px 0 0 -12px;

				@media (max-width: ${breakpoints.small}) {
					&.popup-quiz-divider-md {
						display: none;
					}
				}

				@media (min-width: ${breakpoints.small}) {
					&.popup-quiz-divider-sm {
						display: none;
					}
				}
			}
		`}
	`;
};

interface AllyHandle {
	disengage: () => void;
}

type OnConfirmationFunction = () => void;

interface WarningDialogParameters {
	content: string;
	onCancel?: () => null;
	onConfirm: OnConfirmationFunction;
}

export interface Props {
	popupQuiz: PopupQuizType;
	policy: Policy;
	ally: AllyOptions;
	theme: Theme;
	renderWarningDialog?: (params: WarningDialogParameters) => null;
	unlocked: boolean;
	lockedMessage: string;
	questions: PopupQuizQuestion[];
	questionCount: number;
	draftAnswers: PopupQuizAnswers;
	savedAnswers: PopupQuizAnswers;
	savedAnswerCount: number;
	resetCount: number;
	completed: boolean;
	resultsAvailable: boolean;
	results: PopupQuizResults;
	loaded: boolean;
	needsSave: boolean;
	saving: boolean;
	reviewing: boolean;
	draftAnswer: (questionFamilyId: string, choiceFamilyId: string) => void;
	resetDraftAnswer: () => void;
	saveAnswers: () => Promise<unknown>;
	reset: () => Promise<unknown>;
	readOnly?: boolean;
	online: boolean;
	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
}

interface State {
	quizOpen: boolean;
	quizCurrentQuestionIndex: number;
	quizViewName: string;
	resizeCount: number;
	setFocus: boolean;
}

// Represents the element that flows inline with the rest
// of the webtext content. The `Quiz` component will "popup"
// as a modal of sorts.
class PopupQuiz extends React.Component<Props, State> {
	questionCount: number;
	quizModalRef: React.RefObject<HTMLDivElement>;
	hiddenHandle: AllyHandle;
	disabledHandle: AllyHandle;
	resizing = false;

	state = {
		answers: {},
		quizOpen: false,
		quizCurrentQuestionIndex: 0,
		quizCurrentChoiceId: null,
		quizViewName: 'questions',
		resizeCount: 0,
		setFocus: false
	};

	constructor(props) {
		super(props);

		this.quizModalRef = React.createRef();
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('keyup', this.handleKeyup);

		document.addEventListener('WEBTEXT_DIALOG_OPEN', this.releaseFocusTrap);
		document.addEventListener('WEBTEXT_DIALOG_CLOSE', () => {
			if (this.state.quizOpen) {
				this.trapFocus();
			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKeyup);
		window.removeEventListener('resize', this.handleResize);

		document.removeEventListener('WEBTEXT_DIALOG_OPEN', this.releaseFocusTrap);
		document.removeEventListener('WEBTEXT_DIALOG_CLOSE', this.trapFocus);

		this.releaseFocusTrap();
	}

	handleResize = () => {
		if (this.resizing) return;
		this.resizing = true;

		setTimeout(() => {
			this.setState({ resizeCount: this.state.resizeCount + 1 }, () => {
				this.resizing = false;
			});
		}, 100);
	};

	handleKeyup = (event) => {
		if (
			(event.key === 'Escape' || event.key === 'Esc') &&
			this.state.quizOpen &&
			!this.props.saving
		) {
			this.closeQuiz();
		} else if (event.key === 'Enter' && this.state.quizViewName === 'questions') {
			if (!this.props.saving && this.props.needsSave) {
				this.onSaveAndContinue();
			}
		}
	};

	// This will trap focus in such a way that other elements may not be focusable,
	// such as new dialogs e.g., "you're signed out!" or the Intercom widget.
	trapFocus = () => {
		const filter = (
			this.props.ally
				? Array.from(document.querySelectorAll(this.props.ally.maintainAvailableFilter))
				: []
		).concat([this.quizModalRef.current as HTMLDivElement]);

		if (!this.hiddenHandle && !this.props.ally?.disableFocusTrapping) {
			this.hiddenHandle = maintainHidden({ filter });
		}

		if (!this.disabledHandle && !this.props.ally?.disableFocusTrapping) {
			this.disabledHandle = maintainDisabled({ filter });
		}
	};

	releaseFocusTrap = () => {
		if (this.hiddenHandle) {
			this.hiddenHandle.disengage();
			this.hiddenHandle = null;
		}
		if (this.disabledHandle) {
			this.disabledHandle.disengage();
			this.disabledHandle = null;
		}
	};

	openQuiz = () => {
		let quizViewName = 'questions';

		// show instructions if no questions answered yet
		if (this.props.savedAnswerCount === 0) {
			const { instructions } = this.props.popupQuiz;
			if (instructions && instructions.length) quizViewName = 'instructions';
		}

		this.onFirstAnswerableQuestion();
		this.setState({ quizOpen: true, quizViewName }, this.trapFocus);
	};

	closeQuiz = () => {
		this.releaseFocusTrap();
		this.setState({ quizOpen: false });
		this.props.resetDraftAnswer();
	};

	showPrevQuestion = () => {
		this.setState({
			setFocus: true,
			quizCurrentQuestionIndex: Math.max(this.state.quizCurrentQuestionIndex - 1, 0)
		});
	};

	showNextQuestion = () => {
		this.setState({
			setFocus: true,
			quizCurrentQuestionIndex: Math.min(
				this.state.quizCurrentQuestionIndex + 1,
				this.props.questionCount - 1
			)
		});
	};

	onChoiceSelected = (questionFamilyId, choiceFamilyId) => {
		this.props.draftAnswer(questionFamilyId, choiceFamilyId);
	};

	onFirstAnswerableQuestion = () => {
		this.setState({
			quizCurrentQuestionIndex: Math.min(this.props.savedAnswerCount, this.props.questionCount - 1)
		});
	};

	onPrevQuestion = () => {
		this.props.needsSave
			? this.props.saveAnswers().then(this.showPrevQuestion)
			: this.showPrevQuestion();
	};

	onNextQuestion = () => {
		this.props.needsSave
			? this.props.saveAnswers().then(this.showNextQuestion)
			: this.showNextQuestion();
	};

	onSaveAndContinue = () => {
		const { saving, savedAnswerCount, questionCount } = this.props;
		if (saving) return; // can happen if user double-clicks

		const completed = savedAnswerCount === questionCount;
		const onLastQuestion = this.state.quizCurrentQuestionIndex === questionCount - 1;

		this.props.saveAnswers().then(() => {
			if (completed) {
				// reviewing questions; don't navigate away
			} else if (onLastQuestion) {
				this.setState({ quizOpen: true, quizViewName: 'results' }, this.trapFocus);
			} else {
				this.setState({
					setFocus: true,
					quizCurrentQuestionIndex: Math.min(
						this.state.quizCurrentQuestionIndex + 1,
						questionCount - 1
					)
				});
			}
		});
	};

	onFocusSet = () => {
		this.setState({ setFocus: false });
	};

	onQuizShowQuestions = () => {
		this.setState(
			{ quizOpen: true, quizViewName: 'questions', quizCurrentQuestionIndex: 0 },
			this.trapFocus
		);
	};

	onQuizShowResults = () => {
		if (this.props.saving) return; // can happen if user double-clicks

		this.props.saveAnswers().then(() => {
			this.setState(
				{
					quizOpen: true,
					quizViewName: 'results'
				},
				this.trapFocus
			);
		});
	};

	modalResultsMessage = () => {
		const { popupQuiz, results } = this.props;

		return popupQuiz.ui.messages.modal_results_message
			? popupQuiz.ui.messages.modal_results_message
			: results.message
			  ? results.message
			  : 'You’re done! Close this dialog to see your results in the webtext.';
	};

	resetDisabled = () => {
		const attemptsAllowed = this.props.policy.attempts_allowed;
		if (attemptsAllowed < 0) return false;

		return this.props.resetCount >= attemptsAllowed - 1;
	};

	resetVisible = () => {
		const { resettable } = this.props.results;
		if (!resettable) return false;

		if (this.props.popupQuiz.ui.flags.resetHiddenWhenDisabled) {
			return !this.resetDisabled();
		} else {
			return true;
		}
	};

	onQuizReset = () => {
		const { policy, renderWarningDialog, reset } = this.props;

		if (!policy.reset_confirmation || !renderWarningDialog) {
			reset().then(this.onQuizResetSuccess);
			return true;
		}

		renderWarningDialog({
			content: policy.reset_confirmation,
			onConfirm: () => {
				reset().then(this.onQuizResetSuccess);
			}
		});

		return true;
	};

	onQuizResetSuccess = () => {
		this.setState({
			quizCurrentQuestionIndex: 0,
			quizViewName: 'questions'
		});
	};

	modalTitle = () => {
		if (this.state.quizViewName === 'results') {
			return this.props.popupQuiz.ui.messages.results_header_message || 'Your Results';
		} else {
			const message =
				this.props.popupQuiz.ui.messages.instructions_header_message ||
				'Answer the following <strong>{{totalQuestionsCount()}}</strong> questions to see your results.';
			return message.replace(/\{\{totalQuestionsCount\(\)\}\}/gm, '' + this.props.questionCount);
		}
	};

	renderInlineQuizView(children: React.ReactNode) {
		const { name: themeName } = this.props.theme;

		if (themeName === 'universal_velvet') {
			return (
				<WebtextQuestion noBottomMargin={this.props.noBottomMargin}>{children}</WebtextQuestion>
			);
		}

		return children;
	}

	render() {
		const { theme, popupQuiz, online, loaded, unlocked, readOnly } = this.props;
		const {
			questions,
			questionCount,
			draftAnswers,
			savedAnswers,
			savedAnswerCount,
			resultsAvailable,
			results,
			saving,
			reviewing
		} = this.props;
		const { quizViewName } = this.state;

		return (
			<div css={styles(theme)} id={popupQuiz.family_id} data-family-id={popupQuiz.family_id}>
				{theme.name !== 'universal_velvet' && popupQuiz.ui.name?.visible !== false && (
					<div className="element-title" role="heading" aria-level={3}>
						{popupQuiz.name}
					</div>
				)}

				{this.renderInlineQuizView(
					<div className="element-body">
						<InlineQuizView
							popupQuiz={popupQuiz}
							unlocked={unlocked}
							lockedMessage={this.props.lockedMessage}
							questionCount={questionCount}
							savedAnswerCount={savedAnswerCount}
							resetCount={this.props.resetCount}
							resultsAvailable={resultsAvailable}
							results={results}
							loaded={loaded}
							quizOpen={this.state.quizOpen}
							resetVisible={this.resetVisible()}
							resetDisabled={this.resetDisabled()}
							openQuiz={this.openQuiz}
							onQuizShowQuestions={this.onQuizShowQuestions}
							onQuizReset={this.onQuizReset}
							readOnly={readOnly}
							online={online}
						/>
					</div>
				)}

				{loaded && unlocked && !readOnly && (
					<QuizModal
						online={online}
						open={this.state.quizOpen}
						onClose={this.closeQuiz}
						title={theme.name === ThemeName.UNIVERSAL_VELVET ? popupQuiz.name : this.modalTitle()}
						saving={saving}
						ref={this.quizModalRef}>
						{quizViewName === 'instructions' && (
							<QuizInstructionsView
								popupQuiz={popupQuiz}
								questionCount={questionCount}
								onStart={this.onQuizShowQuestions}
							/>
						)}
						{quizViewName === 'questions' && (
							<QuizQuestionView
								popupQuiz={popupQuiz}
								questionCount={questionCount}
								currentQuestion={questions[this.state.quizCurrentQuestionIndex]}
								savedAnswerCount={savedAnswerCount}
								draftAnswers={draftAnswers}
								savedAnswers={savedAnswers}
								saving={saving}
								reviewing={reviewing}
								onChoiceSelected={this.onChoiceSelected}
								onFirstAnswerableQuestion={this.onFirstAnswerableQuestion}
								onPrevQuestion={this.onPrevQuestion}
								onNextQuestion={this.onNextQuestion}
								onSaveAndContinue={this.onSaveAndContinue}
								onShowResults={this.onQuizShowResults}
								setFocus={this.state.setFocus}
								onFocusSet={this.onFocusSet}
							/>
						)}
						{quizViewName === 'results' && (
							<QuizResultsView
								popupQuiz={popupQuiz}
								results={results}
								resultsAvailable={resultsAvailable}
								resultsMessage={this.modalResultsMessage()}
								onFinished={this.closeQuiz}
							/>
						)}
					</QuizModal>
				)}
			</div>
		);
	}
}

export default withTheme(PopupQuiz);
