import * as React from 'react';

export function BusinessConnections(): JSX.Element {
	return (
		<svg
			id="add1f23b-99a8-4f25-b9d4-9ace658fdb0f"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 72 72">
			<g>
				<circle cx="36" cy="36" r="33.6" fill="#fff" />
				<path
					d="M36,71A35,35,0,1,1,71,36,35,35,0,0,1,36,71ZM36,3.8A32.1,32.1,0,1,0,68.1,35.9,32.14,32.14,0,0,0,36,3.8Z"
					fill="currentColor"
				/>
				<circle cx="36" cy="36" r="29.7" fill="currentColor" />
				<path
					d="M48.8,42.9c-.9-1.8-4-4.3-7-6.8a46.42,46.42,0,0,1-4.3-3.7.92.92,0,0,0-1-.2c-.4.2-.9.3-1.3.5A2.66,2.66,0,0,1,34,33a1,1,0,0,0-.7.4c-1.3,2-2.7,1.8-3.6,1.5a.68.68,0,0,1-.4-.3c-.2-.8.8-2.7,2-4,3-3,4.5-3.8,7.8-2.3a77.89,77.89,0,0,0,7.5,3,1,1,0,0,0,1.2-.5h0a.93.93,0,0,0-.6-1.2h0s-3.7-1.3-7.3-2.9c-4.2-1.9-6.6-.7-9.9,2.7-1.3,1.3-3,3.8-2.5,5.7a2.48,2.48,0,0,0,1.6,1.7,4.57,4.57,0,0,0,5.4-1.9,7.37,7.37,0,0,0,1.4-.4l.6-.3c1.1,1,2.5,2.2,4,3.4,2.7,2.2,5.8,4.7,6.5,6.1a1.08,1.08,0,0,1-.3,1.4c-.4.3-.9.4-1.1.2a1,1,0,0,0-1.3.2,1.45,1.45,0,0,0-.2.7c.1.6-.5.9-.7,1a1.35,1.35,0,0,1-1.4,0,1,1,0,0,0-.9-.2.87.87,0,0,0-.6.8,1.77,1.77,0,0,1-1.1,1.4.91.91,0,0,1-1.1-.2.78.78,0,0,0-.8-.2,1.34,1.34,0,0,0-.7.6c0,.1-.1.3-1,.3a4.23,4.23,0,0,1-2.3-.8c-.5-.3-4.7-3.5-8.2-6.4-.5-.4-1.3-1.3-2.1-2.1s-1.3-1.3-1.6-1.6a.92.92,0,0,0-1.2,1.4l1.5,1.5,2.2,2.2c3.4,2.9,7.6,6,8.4,6.5a6.81,6.81,0,0,0,3.3,1.1,2.77,2.77,0,0,0,2.1-.6,3.75,3.75,0,0,0,2.1,0,3.7,3.7,0,0,0,1.9-1.8,4.45,4.45,0,0,0,2.3-.3,2.94,2.94,0,0,0,1.5-1.6,3.14,3.14,0,0,0,2.1-.7A2.79,2.79,0,0,0,48.8,42.9Z"
					fill="#fff"
				/>
				<path
					d="M32.3,27.6H24a.9.9,0,0,0-.9.9h0a.9.9,0,0,0,.9.9h8.3a.9.9,0,0,0,.9-.9h0a.9.9,0,0,0-.9-.9Z"
					fill="#fff"
				/>
				<path
					d="M51.5,40.9a1.06,1.06,0,0,0-1.3-.3h0l-2.7,1.8a1.06,1.06,0,0,0-.3,1.3h0a1.23,1.23,0,0,0,.8.4,4.33,4.33,0,0,0,.5-.1l2.7-1.8a.86.86,0,0,0,.3-1.3Z"
					fill="#fff"
				/>
				<path
					d="M45.8,45.4c-.7-.5-3.6-3.6-5.4-5.5a.92.92,0,1,0-1.3,1.3h0c.5.5,4.7,4.9,5.6,5.6a1.42,1.42,0,0,0,.6.2.91.91,0,0,0,.7-.3A1,1,0,0,0,45.8,45.4Z"
					fill="#fff"
				/>
				<path
					d="M42.1,47.2a63.46,63.46,0,0,1-4.5-4.5.85.85,0,0,0-1.3,0,1,1,0,0,0-.1,1.3s3.4,3.6,4.7,4.7a1.42,1.42,0,0,0,.6.2.91.91,0,0,0,.7-.3,1,1,0,0,0-.1-1.4Z"
					fill="#fff"
				/>
				<path
					d="M38.4,49.1c-1.3-1.1-4-4-4.5-4.5a.93.93,0,0,0-1.3.1.78.78,0,0,0,0,1.1,51.79,51.79,0,0,0,4.7,4.7.93.93,0,0,0,1.3-.1A1,1,0,0,0,38.4,49.1Z"
					fill="#fff"
				/>
				<path
					d="M24.6,26c-1.6-1.5-7.9-2-9.8-2.1a1.85,1.85,0,0,0-.7.2.91.91,0,0,0-.3.7V41.4a.9.9,0,0,0,.9.9h5.5a1,1,0,0,0,.9-.6c.1-.4,3.3-10.2,3.7-15A.66.66,0,0,0,24.6,26Zm-5,14.5H15.7V25.8c3,.2,6.2.7,7.3,1.3A92.06,92.06,0,0,1,19.6,40.5Z"
					fill="#fff"
				/>
				<path
					d="M57.2,25.8a30,30,0,0,0-11.4,1.9.88.88,0,0,0-.5,1.2.1.1,0,0,0,.1.1c1.1,1.8,4.7,12,5.4,14.5a1,1,0,0,0,.9.7h5.5a.9.9,0,0,0,.9-.9h0V26.7a.9.9,0,0,0-.9-.9Zm-.9,16.6H52.4c-.9-2.9-3.5-10.3-4.9-13.4a28.91,28.91,0,0,1,8.8-1.4V42.4Z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
