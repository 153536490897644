import { css } from '@emotion/react';

export const element = css`
	width: 100%;
	max-width: 750px;
	min-height: 400px;
	margin-top: 20px;
	font-family: 'HelveticaNeueW01-55Roma', Helvetica, Arial, sans-serif !important;
`;

export const chartPlaceholder = css`
	${element};
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e0e0e0;
	text-align: center;
`;

export const chartGenerateButton = css`
	float: right;
	margin-bottom: 25px;

	@media screen and (max-width: 400px) {
		margin-top: 15px;
	}
`;
