import React, { FC } from 'react';
import { MdAccessibilityNew } from 'react-icons/md';

import IconButton from '../IconButton/IconButton';

export interface Props {
	isHighContrast: boolean;
	toggleHighContrast: () => void;
}

const AlternateViewButton: FC<Props> = ({ isHighContrast, toggleHighContrast }) => (
	<IconButton variant="outlined" className="contrast-toggle" onClick={toggleHighContrast}>
		<MdAccessibilityNew aria-hidden="true" />
		{`Show ${isHighContrast ? 'default view' : 'alternate view'}`}
	</IconButton>
);

export default AlternateViewButton;
