// Borrowed heavily from
// https://medium.com/@karenmarkosyan/how-to-manage-promises-into-dynamic-queue-with-vanilla-javascript-9d0d1f8d4df5

import { isAbortError } from '~/utils/netErrors';

interface ExecutionQueueItem<T = unknown> {
	promise: (abortController: AbortController) => Promise<T>;
	abortController;
	resolve: (value: T) => void;
	reject: (err?: unknown | IgnoreWorkingItemError) => void;
}

export default class SerialExecutionQueue {
	private _queue: Array<ExecutionQueueItem> = [];
	private _workingAbortController: AbortController | null = null;
	private _ignoreWorkingItem = false;

	get working(): boolean {
		return !!this._workingAbortController;
	}

	enqueue<T = void>(promise: ExecutionQueueItem<T>['promise']): Promise<T> {
		return new Promise((resolve, reject) => {
			const abortController = new AbortController();
			this._queue.push({ promise, abortController, resolve, reject });
			this.dequeue();
		});
	}

	dequeue(): boolean {
		if (this.working) return false;

		const item = this._queue.shift();
		if (!item) return false;

		const { promise, abortController, resolve, reject } = item;
		this._workingAbortController = abortController;

		promise(abortController)
			.then((value) => {
				this._workingAbortController = null;

				if (this._ignoreWorkingItem) {
					reject(new IgnoreWorkingItemError());
				} else {
					resolve(value);
				}

				this.dequeue();
			})
			.catch((err) => {
				this._workingAbortController = null;

				// Continue queue execution if the prev promise was aborted
				if (isAbortError(err)) {
					this.dequeue();
				}

				reject(err);
			})
			.finally(() => {
				this._ignoreWorkingItem = false;
			});
	}

	ignoreWorkingItem(): void {
		if (!this.working) return;
		this._ignoreWorkingItem = true;
	}

	ignoreAllPendingItems(): void {
		this.ignoreWorkingItem();
		this._queue = []; // Nothing to execute further
	}

	abortWorkingItem(): void {
		if (!this.working) return;
		this._workingAbortController.abort();
	}

	abortAllPendingItems(): void {
		this.abortWorkingItem();
		this._queue = []; // Nothing to execute further
	}
}

export class IgnoreWorkingItemError extends Error {
	constructor() {
		super('Working item results are being ignored');
		this.name = 'IgnoreWorkingItemError';
	}
}

export const isIgnoreWorkingItemError = (err: unknown): err is IgnoreWorkingItemError =>
	err instanceof IgnoreWorkingItemError ||
	(err as IgnoreWorkingItemError)?.name === 'IgnoreWorkingItemError';
