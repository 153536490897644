import React, { FC, useEffect, useRef, useMemo } from 'react';

import { css, SerializedStyles } from '@emotion/react';

import { serializeAddress } from '~/components/Outline/helpers';
import { OutlineInstanceAddress } from '~/components/Outline/types';
import { groupInvalidMessage } from '~/components/WritingTemplate/EditMode/styles';

interface Props {
	id?: string;
	message: string;
	className?: string;
}

const ValidationMessage: FC<Props> = (props) => {
	const { id, message, className } = props;

	const messageElementRef = useRef(null);

	const hasLink = useMemo(() => {
		if (!message) return null;

		const findLinkTagRegex = /<a.*?href="(.*?)".*?>/gi;
		return findLinkTagRegex.test(message);
	}, [message]);

	// Focuses on the validation message after the failed submission
	useEffect(() => {
		if (document?.activeElement?.tagName === 'BUTTON') {
			const { current: messageElement } = messageElementRef;
			messageElement?.focus();
		}
	}, [message]);

	return (
		<div css={styles({ hasLink })} className={className}>
			{message && (
				<div
					id={id}
					ref={messageElementRef}
					css={groupInvalidMessage}
					tabIndex={0}
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}
		</div>
	);
};

export const getValidationMessageId = (address: OutlineInstanceAddress): string =>
	`${serializeAddress(address)}_validation_message`;

const styles = (options: { hasLink: boolean }): SerializedStyles => css`
	${options.hasLink &&
	css`
		div:first-of-type {
			min-width: unset;

			* {
				color: #e41b0c !important;
			}

			a {
				font-weight: bold;
				text-decoration: underline;
			}
		}
	`}
`;

export default ValidationMessage;
