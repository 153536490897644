import React from 'react';

import MultipleChoiceQuestionHeading from '~/components/pageElements/MultipleChoiceQuestion/MultipleChoiceQuestionHeading';
import UniversalVelvetLeftBorder from '~/components/pageElements/UniversalVelvetLeftBorder';
import { WebtextQuestion } from '~/components/shared/Question';

import StandaloneInstructorView from './components/StandaloneInstructorView';
import StandaloneStudentView from './components/StandaloneStudentView';
import { getMCQuestionPoolId, getMCQuestionPoolPromptBodyId } from './utils';

import type {
	StudentViewElementProps,
	InstructorViewElementProps,
	DisplayedQuestionElementProps
} from './types';

type Props = (StudentViewElementProps | InstructorViewElementProps) & DisplayedQuestionElementProps;

const StandaloneMCQuestionPool: React.VFC<Props> = (props) => {
	const containerId = getMCQuestionPoolId(props.questionPool.family_id);
	const promptId = getMCQuestionPoolPromptBodyId(props.questionPool.family_id);

	return (
		// we set `id` for scroll-to behavior
		<WebtextQuestion
			id={containerId}
			data-in-question-deck="false"
			data-testid="standalone-mc-question-pool">
			<UniversalVelvetLeftBorder>
				<MultipleChoiceQuestionHeading />
				<div role="group" aria-labelledby={promptId}>
					{props.showInstructorView === true ? (
						<StandaloneInstructorView {...props} promptId={promptId} />
					) : (
						<StandaloneStudentView {...props} promptId={promptId} />
					)}
				</div>
			</UniversalVelvetLeftBorder>
		</WebtextQuestion>
	);
};

export default StandaloneMCQuestionPool;
