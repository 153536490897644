import React from 'react';

import WebtextButton from '~/components/WebtextButton';
import { useIsUniversalVelvet } from '~/hooks';

import PopupQuizControls, { RightControls } from './PopupQuizControls';
import PopupQuizView from './PopupQuizView';
import QuizResults from './QuizResults';
import { PopupQuiz, PopupQuizResults } from './types';

interface Props {
	popupQuiz: PopupQuiz;
	results: PopupQuizResults;
	resultsAvailable: boolean;
	resultsMessage?: string;
	onFinished: (event: React.MouseEvent) => void;
}

const QuizResultsView: React.FunctionComponent<Props> = (props) => {
	const { popupQuiz, onFinished } = props;
	const { results, resultsAvailable, resultsMessage } = props;

	const isUniversalVelvet = useIsUniversalVelvet();

	return (
		<>
			<PopupQuizView>
				<QuizResults
					popupQuiz={popupQuiz}
					results={results}
					resultsAvailable={resultsAvailable}
					resultsMessage={resultsMessage}
				/>
			</PopupQuizView>

			{isUniversalVelvet && <hr aria-hidden className="popup-quiz-divider" />}

			<PopupQuizControls insideModal>
				<RightControls>
					<WebtextButton onClick={onFinished}>
						{popupQuiz.ui.buttons.return_to_my_webtext_button || 'Close & View Results'}
					</WebtextButton>
				</RightControls>
			</PopupQuizControls>
		</>
	);
};

export default QuizResultsView;
