import { useContext, useEffect, useState } from 'react';

import { ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, ParagraphNode } from 'lexical';

import { CitationStyle } from '../../../types';
import CitationMetaContext from '../context/CitationMetaContext';
import { $isCitationNode, CitationNode } from '../nodes/CitationNode';

export default function useSerialTurabianNumber(identifier: string): string {
	const [editor] = useLexicalComposerContext();
	const { citationStyle, inEditorCitations } = useContext(CitationMetaContext);

	const [citationValue, setCitationValue] = useState<string>(null);

	useEffect(() => {
		if (!editor.hasNodes([CitationNode])) {
			throw new Error('CitationsPlugin: CitationNode not registered on editor');
		}

		/**
		 * use hook only for Turabian style
		 */
		if (citationStyle !== CitationStyle.Turabian || !editor._editable) return;

		editor.update(() => {
			const root = $getRoot();

			/**
			 * Select all citation nodes from the editor
			 */
			const citationNodes = root
				.getChildren<ParagraphNode | ListNode>()
				.flatMap((node) => node?.getChildren())
				.filter((node) => $isCitationNode(node)) as CitationNode[];

			/**
			 * Calculate the value of the citation
			 */
			const citationValue = citationNodes
				.map((citation, idx) => ({
					[citation.getIdentifier()]: `${idx + 1}`
				}))
				.find((citation) => citation[identifier]);

			if (!citationValue) return;

			/**
			 * Update the value of the citation
			 */
			setCitationValue(citationValue[identifier]);
		});
	}, [identifier, editor, citationStyle, inEditorCitations]);

	return citationValue;
}
