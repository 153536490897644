import * as React from 'react';

export function PapersWithPencil(): JSX.Element {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 72">
			<circle
				cx={36}
				cy={36}
				r={32.5}
				style={{
					fill: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '2.762833777925626px'
				}}
				stroke="currentColor"
			/>
			<circle cx={36} cy={36} r={28.73} fill="currentColor" />
			<path
				d="M45.28 46.11h-.12L41 45.26a.56.56 0 0 1-.29-.16l-9.24-9.25a1.77 1.77 0 0 1 0-2.49l1.68-1.68a1.76 1.76 0 0 1 2.49 0l9.24 9.24a.63.63 0 0 1 .16.3l.84 4.2a.59.59 0 0 1-.16.52.66.66 0 0 1-.44.17Zm-3.92-2 3.18.64-.64-3.18-9.12-9.07a.61.61 0 0 0-.86 0l-1.68 1.68a.62.62 0 0 0 0 .87Z"
				style={{
					fill: '#fff',
					stroke: '#fff',
					strokeMiterlimit: 10,
					strokeWidth: '.6907084444814064px'
				}}
			/>
			<path
				d="M45.62 18.59H23.18a1 1 0 0 0-1 1v28.58a1 1 0 0 0 1 1h3.06v3.2a1 1 0 0 0 1 1h22.48a1 1 0 0 0 1-1V28.62a3.06 3.06 0 0 0-.89-2.17l-2.91-2.88a1.93 1.93 0 0 0-.3-.27v-3.69a1 1 0 0 0-1-1ZM24.21 47.15V20.63h20.4v2.07H27.28a1 1 0 0 0-1 1v23.51Zm4.07 4.22V24.71h14.29v5.07a1 1 0 0 0 1 1h5.13v20.59Zm20.11-23.49a1.06 1.06 0 0 1 .3.73v.17h-4.08v-4.07h.18a1 1 0 0 1 .69.3Z"
				style={{
					fill: '#fff'
				}}
			/>
		</svg>
	);
}
