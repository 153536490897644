import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { css, ClassNames } from '@emotion/react';
import TextareaAutosize, { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize';
import { useResizeObserver } from 'usehooks-ts';

import InputPlaceholder from '~/components/InputPlaceholder';
import { getIsPlaceholderSampleText } from '~/components/InputPlaceholder/InputPlaceholder';
import { getThemeItem, Theme } from '~/styles/themes';
import { generateID } from '~/utils';

export type TextareaHandles = Pick<
	HTMLTextAreaElement,
	'focus' | 'setSelectionRange' | 'selectionStart' | 'selectionEnd' | 'selectionDirection'
>;

/**
 * Manually exclude the `ref` from the `TextareaAutosizeProps`
 * to prevent `forwardRef` from mangling the `Props` type with the `Pick<...>`
 * It breaks the consumption of the component and makes optional props required 🤷🏻‍
 * @see https://soomolearning.slack.com/archives/G1R0V6JAW/p1714493591495669?thread_ts=1714490902.963149&cid=G1R0V6JAW
 */
interface Props extends Omit<TextareaAutosizeProps, 'ref'> {
	containerClassName?: string;
	textareaClassName?: string;
	minimalEditorHeight?: number;
	showSampleTextPlaceholder?: boolean;
}

const AutosizeTextarea = forwardRef<TextareaHandles, Props>((props, ref) => {
	const {
		containerClassName,
		minimalEditorHeight = 0,
		placeholder,
		showSampleTextPlaceholder = true,
		'aria-describedby': ariaDescribedby,
		textareaClassName,
		style,
		...restProps
	} = props;

	const { current: textareaId } = useRef(props.id || generateID());

	/**
	 * When the placeholder is a sample text, we need to render it as a separate element
	 * and the textarea won't have the `placeholder` attribute
	 * @see {getIsPlaceholderSampleText}
	 */
	const placeholderId = `${textareaId}_placeholder`;
	const isPlaceholderSampleText =
		showSampleTextPlaceholder && getIsPlaceholderSampleText(placeholder);

	/**
	 * The minimal height of the editor is based on the height of the provided placeholder (if present)
	 * Also, on resize, we need to update the min-height of the editor to match the placeholder
	 */
	const placeholderRef = useRef<HTMLDivElement | null>(null);
	const { height: placeholderHeight = 0 } = useResizeObserver({
		ref: placeholderRef,
		box: 'border-box'
	});

	const textareaAutosizeRef = useRef<HTMLTextAreaElement | null>(null);
	useImperativeHandle(ref, () => {
		const { current: textareaAutosize } = textareaAutosizeRef;

		return {
			focus: (...args) => textareaAutosize?.focus(...args),
			setSelectionRange: (...args) => textareaAutosize?.setSelectionRange(...args),
			selectionStart: textareaAutosize?.selectionStart,
			selectionEnd: textareaAutosize?.selectionEnd,
			selectionDirection: textareaAutosize?.selectionDirection
		};
	});

	return (
		<div css={autosizeContainerStyles} className={containerClassName}>
			{isPlaceholderSampleText && !props.value && (
				<InputPlaceholder
					id={placeholderId}
					className={textareaClassName} // Uses the same styles and input are text
					css={placeholderStyles}
					placeholder={placeholder}
				/>
			)}

			<ClassNames>
				{({ cx }) => (
					<TextareaAutosize
						{...restProps}
						ref={textareaAutosizeRef}
						id={textareaId}
						style={{
							...style,
							minHeight: style?.minHeight || `${Math.max(placeholderHeight, minimalEditorHeight)}px`
						}}
						css={textareaStyles}
						className={cx(
							textareaClassName,
							'bordered-textarea' // Avoid redundant Core's border style override
						)}
						aria-describedby={cx(ariaDescribedby, {
							[placeholderId]: isPlaceholderSampleText && !!placeholder
						})}
						placeholder={isPlaceholderSampleText ? undefined : placeholder}
					/>
				)}
			</ClassNames>

			{isPlaceholderSampleText && (
				// Supportive placeholder to measure height of the placeholder
				<InputPlaceholder
					ref={placeholderRef}
					className={textareaClassName}
					css={placeholderStyles}
					placeholder={placeholder}
					invisible
				/>
			)}
		</div>
	);
});

const autosizeContainerStyles = css`
	position: relative;
	overflow: hidden;
`;

const textareaStyles = css`
	width: 100%;
`;

const placeholderStyles = (theme: Theme) => {
	const {
		colors: { placeholderGray }
	} = theme;
	const placeholderColor = getThemeItem(placeholderGray, theme);

	// Inherit all the textarea styles, except the background and border
	return css`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		color: ${placeholderColor} !important;
		background-color: transparent !important;
		border: none !important;
		text-overflow: ellipsis;
		user-select: none;
		pointer-events: none;

		z-index: 1;
	`;
};

AutosizeTextarea.displayName = 'OutlineAutosizeTextarea';

export default AutosizeTextarea;
