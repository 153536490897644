import React, { useMemo } from 'react';

import { createRangeDimensions } from '../../helpers/compute';
import { CellsRefs } from '../../helpers/types';
import { useSpreadsheetSelector } from '../../store/provider';
import styles from '../RangeSelection/styles';

interface Props {
	hideAddressHeaders: boolean;
	cellsRefs: CellsRefs;
	withHeader: boolean;
	totalHeight: number;
	totalWidth: number;
}

const InvalidRangeSelection: React.FC<Props> = ({
	hideAddressHeaders,
	cellsRefs,
	withHeader,
	totalHeight,
	totalWidth
}) => {
	const invalidRange = useSpreadsheetSelector((state) => state.invalidRange);

	const { isDisplay, position, highlightMode, compensateHeader } = useMemo(() => {
		if (!invalidRange || !invalidRange.length) return { isDisplay: false };

		const { top, left, width, height } = createRangeDimensions(invalidRange, cellsRefs, withHeader);

		return {
			isDisplay: true,
			compensateHeader: !!hideAddressHeaders,
			highlightMode: 'invalid',
			position: {
				top,
				left,
				width,
				height
			}
		};
		// `totalHeight` needed for re-calculation of cursor size
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invalidRange, hideAddressHeaders, totalHeight, withHeader, cellsRefs, totalWidth]);

	return isDisplay ? (
		<div
			aria-hidden
			css={styles({
				left: position.left,
				top: position.top,
				width: position.width,
				height: position.height,
				highlightMode,
				compensateHeader
			})}
		/>
	) : null;
};

export default InvalidRangeSelection;
