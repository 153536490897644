import React, { FC, PropsWithChildren } from 'react';

import {
	ActivityHeader as LibsActivityHeader,
	ActivityHeaderProps
} from '@soomo/lib/notebook/components/index';

import styles from './ActivityHeader.scss';

const ActivityHeader: FC<PropsWithChildren<ActivityHeaderProps>> = (props) => (
	<LibsActivityHeader {...props} className={styles.ActivityHeader} />
);

export default ActivityHeader;
