import React from 'react';
import { CSVLink } from 'react-csv';

import { css } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

interface Props {
	data: (string | number)[][];
	onFileDownload?: (url: string, defaultFileName?: string) => Promise<boolean>;
}

const DownloadCSVLink: React.FC<Props> = (props) => {
	const { data, onFileDownload } = props;

	const getFileName = () => {
		const date = new Date();
		const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		const formattedTime = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

		return `chart_data_${formattedDate}-${formattedTime}.csv`;
	};

	const handleLinkClick = (event) => {
		/**
		 * When the `onFileDownload` is not provided - return `true` to trigger the default browser behavior
		 * Otherwise, store the file with `onFileDownload` callback, and ignore the default browser's behavior with returning the `false`
		 */
		if (!onFileDownload) return true;

		const { href: url } = event.target as HTMLAnchorElement;
		const fileName = getFileName();
		return onFileDownload(url, fileName);
	};

	return (
		<CSVLink
			data={data}
			filename={getFileName()}
			css={downloadLinkStyles}
			className="download-csv-link"
			onClick={handleLinkClick}>
			Download the data (CSV)
		</CSVLink>
	);
};

/**
 * When the text-decoration: underline is set, the brackets are not underlined
 */
const downloadLinkStyles = (theme) => css`
	color: ${theme.colors.primary};
	text-underline-offset: 4px;
	font-weight: ${theme.webtextPage.a.fontWeight};

	@media (max-width: ${breakpoints.small}) {
		font-size: 14px;
	}
`;

export default DownloadCSVLink;
