import React from 'react';

import { jsx, useTheme } from '@emotion/react';
import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { ThemeName } from '~/styles/themes';

import { WebtextBlockquoteStyles } from '.';
import { Quote } from '../../icons';
import { Props as TextProps } from '../../Text';
import { checkForSpecialTextContent } from '../../utils';

interface Props {
	element: Element;
	textProps: TextProps;
	showQuotationIcon?: boolean;
}

const WebtextBlockquote: React.FC<Props> = (props) => {
	const { element, textProps, showQuotationIcon } = props;
	const theme = useTheme();

	const content = domToReact(element.children, {
		replace: (domNode: Element) => checkForSpecialTextContent(domNode, textProps)
	});

	return jsx(element.tagName, {
		className: element.attribs.class,
		css: WebtextBlockquoteStyles(theme),
		children:
			theme.name === ThemeName.UNIVERSAL_VELVET && showQuotationIcon
				? [<Quote key="icon" />, content]
				: content
	});
};

export default WebtextBlockquote;
