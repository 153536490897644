import React, { FC, useContext, useEffect, useRef } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { HiX } from 'react-icons/hi';

import { css, SerializedStyles, useTheme } from '@emotion/react';
import { shallow } from 'zustand/shallow';

import { EditModeStateContext } from '~/components/WritingTemplate/EditMode/providers/EditModeStateProvider';
import { getThemeItem, Theme } from '~/styles/themes';

import {
	separateInputAreaButtonAccept,
	separateInputAreaButtonDiscard,
	separateInputAreaId
} from '../../helpers/constants';
import { shiftCursorInsideSelection } from '../../helpers/selection';
import { CellsRefs, Dir, Formatting, KeyCodes, Result } from '../../helpers/types';
import useInteractiveInputElement from '../../hooks/useInteractiveInputElement';
import { useSpreadsheetSelector } from '../../store/provider';
import { selectEditableSelectedCell, selectSelectedCellValue } from '../../store/selectors';
import { transformFinalInputValue } from '../Cell/utils';

interface Props {
	formatting: Formatting;
	cellsRefs: CellsRefs;
	leftParentIndent: number;
}

const InputArea: FC<Props> = ({ formatting, cellsRefs, leftParentIndent }) => {
	const {
		isSelectedCellEditable,
		editingCell,
		focused,
		selectedCellValue,
		inputValue,
		selectedCell,
		setEditingCell,
		setInputValue,
		updateCellValue,
		setActiveAreaId,
		selectedCells,
		setSelectedCell,
		updateAnnouncementSR
	} = useSpreadsheetSelector(
		(state) => ({
			isSelectedCellEditable: selectEditableSelectedCell(state),
			editingCell: state.editingCell,
			focused: state.focused,
			selectedCellValue: selectSelectedCellValue(state),
			inputValue: state.activeInputValue,
			setInputValue: state.updateActiveInputValue,
			selectedCell: state.selectedCell,
			setEditingCell: state.setEditingCell,
			updateCellValue: state.updateCellValue,
			setActiveAreaId: state.setActiveAreaId,
			selectedCells: state.selectedIndexes,
			setSelectedCell: state.setSelectedCell,
			updateAnnouncementSR: state.updateAnnouncementSR
		}),
		shallow
	);

	const inputRef = useRef<HTMLTextAreaElement>(null);
	const showInputArea = isSelectedCellEditable && focused;

	const displayValue = !editingCell ? selectedCellValue : inputValue;
	const { setCaretPosition } = useInteractiveInputElement(inputRef.current, setInputValue);

	const editModeState = useContext(EditModeStateContext);

	const handleClick = () => {
		if (!editingCell) setEditingCell(selectedCell);
		setCaretPosition(inputRef.current?.selectionStart ?? 0);
		setActiveAreaId(separateInputAreaId);
	};

	const handleChange = (event) => {
		setInputValue(event.target.value);
		setCaretPosition(inputRef.current?.selectionStart);
	};

	const handleClickDiscard = () => setEditingCell(null);

	const handleClickAccept = () => {
		setEditingCell(null);
		updateCellValue(editingCell, transformFinalInputValue(inputValue, formatting));
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === KeyCodes.Enter) {
			event.preventDefault();
			const nextCell = shiftCursorInsideSelection({
				dir: Dir.Down,
				selectedCells,
				selectedCell,
				elements: cellsRefs
			});

			const saveSelectedIndexes = selectedCells.length > 1;

			if (nextCell.result === Result.Accept) {
				setSelectedCell(nextCell.key, { saveSelectedIndexes });
				handleClickAccept();
				return;
			}

			setSelectedCell(selectedCell, { saveSelectedIndexes });
			updateAnnouncementSR('No more cells');
			handleClickAccept();
		}
	};

	const theme = useTheme();
	const inputContainerRef = useRef<HTMLDivElement>(null);

	/**
	 * The input area should be placed on the bottom of the visual area. When keyboard folds/unfolds area size changes
	 * For these need we're using the events of the visual viewport and when keyboard unfolds shift the area to the place above the keyboard and vice versa
	 * heightCompensation is a static value of visual part of the input that must be visible (should be changed in case of modifying sizes of the area)
	 */
	useEffect(() => {
		const visualViewport = window.visualViewport;
		if (!visualViewport) return;

		const heightCompensation = editModeState?.spreadsheetProps?.mobile ? 0 : 40;
		const fixPosition = () =>
			(inputContainerRef.current.style.top = `${
				visualViewport.height + (visualViewport.offsetTop || 0) - heightCompensation
			}px`);

		window.visualViewport.addEventListener('scroll', fixPosition);
		window.visualViewport.addEventListener('resize', fixPosition);
		fixPosition();

		return () => {
			window.visualViewport.removeEventListener('scroll', fixPosition);
			window.visualViewport.removeEventListener('resize', fixPosition);
		};
	}, [inputContainerRef, editModeState?.spreadsheetProps?.mobile]);

	const placeholderText = !editingCell && 'Tap here to edit';

	return (
		<div
			ref={inputContainerRef}
			css={containerStyles(theme, {
				isVisible: showInputArea,
				mobile: editModeState?.spreadsheetProps?.mobile
			})}
			style={{ transform: `translate(-${leftParentIndent}px, -100%)` }}>
			<div css={buttonRowStyles({ isVisible: !!editingCell })}>
				<button
					id={separateInputAreaButtonDiscard}
					className="button-discard"
					aria-label="Discard the changes"
					onClick={handleClickDiscard}>
					<HiX size={14} aria-hidden />
				</button>
				<button
					id={separateInputAreaButtonAccept}
					className="button-accept"
					aria-label="Accept the changes"
					onClick={handleClickAccept}>
					<BsCheck2 size={18} aria-hidden />
				</button>
			</div>
			<div className="area-container">
				<textarea
					id={separateInputAreaId}
					placeholder={placeholderText}
					ref={inputRef}
					name="Cell input area"
					value={displayValue}
					onClick={handleClick}
					onKeyPress={handleKeyPress}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
};

export default InputArea;

const containerStyles = (
	theme: Theme,
	options: { isVisible: boolean; mobile: boolean }
): SerializedStyles => css`
	display: ${options.isVisible ? 'flex' : 'none'};
	width: 100%;
	position: fixed;
	z-index: 1000;
	pointer-events: none;
	flex-direction: column;
	align-items: center;

	.area-container {
		width: 100%;
		padding-top: 5px;
		padding-bottom: ${options.mobile ? 20 : 8}px;
		background: #dad4d4;
		display: flex;
		align-items: center;
		flex-direction: column;

		textarea {
			width: 95%;
			resize: none;
			border: 2px solid dodgerblue !important;
			vertical-align: unset !important;
			border-radius: 5px;
			font-size: 16px;
			box-sizing: border-box;
			padding: 8px 10px;
			height: 35px;
			overflow: hidden;
			pointer-events: auto;
			/**override the mobile app style */
			font: initial;
			font-family: ${theme.fonts['helvetica-neue']} !important;

			&::placeholder {
				color: ${getThemeItem(theme.colors.placeholderGray, theme)};
			}
		}
	}
`;

const buttonRowStyles = (options: { isVisible: boolean }): SerializedStyles => css`
	display: ${options.isVisible ? 'flex' : 'none'};
	justify-content: flex-end;
	width: 95%;

	button {
		opacity: 0.95;
		outline: none;
		border: none;
		margin: 5px;
		height: 25px;
		color: white;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: auto;
		/** set explicitly to reset code override */
		padding: 0 !important;
		font-size: 11px !important;

		&.button-discard {
			background: #e26135;
			width: 25px;
			border-radius: 50%;
		}

		&.button-accept {
			background: #3e963e;
			border-radius: 15px;
			width: 55px;
		}

		&:focus {
			outline: 0;
		}

		svg {
			pointer-events: none;
		}
	}
`;
