import first from 'lodash-es/first';
import round from 'lodash-es/round';

import { regex } from '../Spreadsheet/helpers/constants';
import { ChartConfig } from './../EditMode/types';
import { getPieData } from './helpers/pieChartHelper';
import { ChartDataType, ChartMetadata, ChartState, ChartType, SpreadsheetState } from './types';

export const getPercentage = (total: number, value: number): number =>
	// round to 2 here are reasons https://github.com/soomo/soomo-libs/pull/1033#discussion_r972918786
	total === 0 ? total : round((value / total) * 100, 2);

export const splitRangeIntoRows = (selection: string[]): { [key: string]: string[] } =>
	// This method split raw range like [A1, B1, ...] into more excel structure like [[A1, B1], [A2, B2], ...]
	// basically creates rows from raw selection
	selection
		.map((e) => {
			const cellHandleRegex = regex.cellHandle;
			cellHandleRegex.lastIndex = 0;

			return cellHandleRegex.exec(e);
		})
		.reduce((acc, el) => {
			if (!el) return acc;

			const [address, , vertical] = el;

			if (acc[vertical]) {
				return { ...acc, [vertical]: [...acc[vertical], address] };
			}

			return { ...acc, [vertical]: [address] };
		}, {});

export const getChartData = (
	config: ChartConfig,
	chartSource: SpreadsheetState,
	selection: string[]
): { name: string; data: ChartDataType; meta: ChartMetadata } => {
	switch (config?.type) {
		case ChartType.Pie:
			return getPieData(chartSource, selection, config);
		default:
			return null;
	}
};

export const switchRowViewIntoColumns = (rows: string[][]): string[][] => {
	const length = first(rows)?.length;

	if (!length) return [];

	/**
	 *
	 * @param matrix
	 * @returns matrix
	 *
	 * This is a similar to transpose to right matrix functions
	 * basically when we have an array like [[A1, B1], [A2, B2]] turns it to [[A1, A2], [B1, B2]]
	 */

	function transpose(matrix) {
		return Object.keys(matrix[0]).map((column) => matrix.map((row) => row[column]));
	}

	return transpose(rows);
};

export const getIsChartData = (chartState: ChartState): boolean => chartState?.data?.length > 0;

export const getSourceNotSelected = (
	selection: { [dest: string]: string[] },
	source: string
): boolean => selection[source]?.length <= 0;
