import React, { useCallback, useEffect, useState } from 'react';

import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { Dashboard, useUppy } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import DocumentsPreview from '~/components/WritingTemplate/DocumentPreview';
import { generateID } from '~/utils';

import style, { closeButtonStyle, previewStyle } from './styles';

import type { IApi } from '../WritingTemplate/WritingTemplate';

export interface IRestrictions {
	maxFileSize: number;
	maxNumberOfFiles: number;
	minNumberOfFiles: number;
	allowedFileTypes: string[];
}

interface Props {
	documentName?: string;
	getDocumentUrl?: IApi['getImageUrl'];
	sourceBuilderId?: string;
	endpoint: string;
	onLoadPicture?: (url: string) => void;
	getAwsParams?: (name: string) => Promise<any>;
	restrictions?: IRestrictions;
	fileRestrictionError?: string;
}

const allowedImageFileTypes = [
	'image/png',
	'image/jpeg',
	'image/pjpeg',
	'image/bmp',
	'image/tiff',
	'image/gif',
	'image/svg+xml'
];

const DocumentsLoader: React.FC<Props> = ({
	documentName,
	getDocumentUrl,
	sourceBuilderId,
	endpoint,
	restrictions,
	fileRestrictionError,
	onLoadPicture,
	getAwsParams
}) => {
	const [isPreview, setIsPreview] = useState(!!documentName);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsPreview(!!documentName);
	}, [documentName]);

	const onLoadProgress = useCallback(() => setIsLoading(true), []);
	const onLoadFinish = useCallback(() => setIsLoading(false), []);

	const uppy = useUppy(() => {
		return Uppy({
			autoProceed: true,
			restrictions: {
				minFileSize: 1,
				maxFileSize: restrictions?.maxFileSize || 10000000,
				maxNumberOfFiles: 1,
				allowedFileTypes: restrictions?.allowedFileTypes || allowedImageFileTypes
			},
			locale: {
				strings: {
					dropHereOr: 'Drop here or %{browse}',
					browse: 'browse',
					youCanOnlyUploadFileTypes:
						fileRestrictionError ||
						'This file is in the wrong format. Please try again with a different file type.',
					inferiorSize: 'The file you uploaded is empty. Please choose another file.'
				}
			}
		}).use(AwsS3, {
			companionUrl: endpoint,
			limit: 1,
			getUploadParameters: async (file) => {
				let name;
				const parts = file.name.split('.');
				if (parts.length > 1) {
					name = generateID() + '.' + parts[parts.length - 1];
				} else {
					name = generateID();
				}
				const params = await getAwsParams(name);
				return params;
			}
		});
	});

	// see https://github.com/transloadit/uppy/issues/3863
	uppy.off('complete', null).on('complete', (result) => {
		if (result.successful[0]) {
			const metaData = result.successful[0].meta as { key: string; name: string };
			const parts = metaData.key.split('/');
			const name = parts[parts.length - 2] + '/' + parts[parts.length - 1];
			onLoadPicture(name);
			uppy.reset();
		}
	});

	// see https://github.com/transloadit/uppy/issues/3863
	uppy.off('upload', null).on('upload', () => {
		onLoadProgress();
	});

	const renderComponent = () => {
		if (isPreview) {
			return (
				<div css={previewStyle}>
					<DocumentsPreview
						documentName={documentName}
						getDocumentUrl={getDocumentUrl}
						sourceBuilderId={sourceBuilderId}
						onLoadProgress={onLoadProgress}
						onLoadFinish={onLoadFinish}
					/>
					{!isLoading ? (
						<button
							data-ignore="1"
							css={closeButtonStyle}
							onClick={() => {
								onLoadPicture('');
								setIsPreview(false);
							}}
						/>
					) : null}
				</div>
			);
		} else if (isLoading) {
			return <span>Loading preview...</span>;
		} else {
			return <Dashboard uppy={uppy} />;
		}
	};

	return <div css={style}>{renderComponent()}</div>;
};

export default DocumentsLoader;
