import { css } from '@emotion/react';

import { underlinedStyles } from '~/components/WebtextButton';
import { breakpoints, mixins, ThemeName } from '~/styles/themes';
import constants from '~/styles/themes/base/constants';

export default (nextQuestionButtonStyle: 'normal' | 'updated') => (theme) => css`
	.visually-hidden {
		${mixins.webtextHiddenAccessible(theme)}
	}

	.question-deck-heading {
		white-space: nowrap;
	}

	.question-deck-icon {
		width: 25px;
		height: 25px;
	}

	.mc-question-panels {
		position: relative;

		.previous-next-question-buttons {
			position: absolute;
			border-radius: 3px;
			line-height: 0;

			top: -24px;
			right: 0;

			${theme.name === ThemeName.UNIVERSAL_VELVET &&
			css`
				top: -55px;

				@media (max-width: ${breakpoints.small}) {
					top: -69px;
					right: -18px;
				}
			`}

			.previous-question-button,
			.next-question-button {
				padding: 0;
				width: 29px;
				height: 29px;
				border: 1px solid ${theme.colors.primary} !important;
				cursor: pointer;

				svg {
					fill: ${theme.colors.primary};
					stroke: ${theme.colors.primary};
					width: 100%;
					height: 100%;
				}

				&[disabled] {
					opacity: 0.5;
					cursor: default;
				}
			}

			.previous-question-button {
				border-radius: 3px 0 0 3px;
				margin-right: -1px;
			}

			.next-question-button {
				border-radius: 0 3px 3px 0;
			}
		}

		.webtext-question-universal-velvet {
			padding: 0;

			&::before,
			&::after {
				content: none;
			}

			@media (max-width: ${breakpoints.small}) {
				margin: 0;
			}
		}

		.multiple-choice-question-component {
			margin: 0;
			padding: 0;

			.save-button-container {
				z-index: 1;
			}
		}

		.status-and-next-question-button {
			display: flex;
			align-items: ${nextQuestionButtonStyle === 'updated' ? 'flex-start' : 'flex-end'};
			width: 100%;

			@media (max-width: ${breakpoints.small}) {
				flex-direction: column-reverse;

				&.has-next-question-button {
					row-gap: 24px;
				}
			}

			.next-question-bottom-button {
				margin: 0;
				white-space: nowrap;
				${theme.name !== 'universal_velvet' &&
				css`
					height: max-content;
				`}
			}

			.question-status-section {
				width: 100%;
				flex-grow: 1;

				.answer-status-text {
					margin-bottom: 6px;
					color: #545454;
					font-size: 14px;

					@media (max-width: ${breakpoints.small}) {
						font-size: 12px;
					}
				}

				ol.question-buttons {
					list-style: none;
					margin: 6px 0 0;
					padding: 0;
					display: grid;
					grid-template-columns: repeat(auto-fill, 20px);
					grid-column-gap: 10px;
					grid-row-gap: 20px;

					@media (max-width: ${breakpoints.small}) {
						margin-top: 8px;
						grid-template-columns: repeat(auto-fill, 24px);
						grid-column-gap: 14px;
					}

					li {
						margin: 0;
						padding: 0;
						line-height: 0;

						button {
							padding: 0;
							width: 20px;
							height: 20px;
							border: 2px solid ${constants.colors.black} !important;
							border-radius: 2px;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;

							svg {
								fill: ${constants.colors.black};
								stroke: ${constants.colors.black};
								width: 20px;
								height: 20px;

								&.checkmark-icon path {
									stroke: ${constants.colors.black};
									stroke-width: 44px;
								}
							}

							@media (max-width: ${breakpoints.small}) {
								width: 24px;
								height: 24px;

								svg {
									width: 24px;
									height: 24px;
								}
							}
						}

						button.inactive:not(:hover) {
							opacity: 0.4;
						}
					}
				}
			}
		}
	}

	.question-deck.student-view {
		${theme.name !== 'universal_velvet' &&
		css`
			/* .webtext-question-universal-velvet (from WebtextQuestion) provides the relative anchor for UV,
			but we need to set a relative anchor manually for non-UV themes so that the Next Question reminder backdrop
			correctly renders over the QD element */
			position: relative;
		`}

		.mc-question-panels.active-question-unanswered .status-and-next-question-button {
			position: absolute;
			bottom: 0;

			@media (max-width: ${breakpoints.small}) {
				position: initial;

				${theme.name === ThemeName.UNIVERSAL_VELVET &&
				css`
					margin-top: 19px;
				`}
			}
		}

		.mc-question-panels.active-question-answered .status-and-next-question-button {
			margin-top: 12px;
		}

		/* https://css-tricks.com/full-browser-width-bars/ */
		.backdrop {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.3);
			z-index: 10;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: -100vw;
				right: 0;
				border-left: 100vw solid rgba(0, 0, 0, 0.3);
				box-shadow: 100vw 0 0 rgba(0, 0, 0, 0.3);
			}
		}

		.next-question-button-container {
			position: relative;

			@media (max-width: ${breakpoints.small}) {
				width: 100%;
			}

			&.reminder-visible {
				position: absolute;
				right: 0;
				z-index: 100;
				background-color: ${theme.webtextQuestion.backgroundColor};
				border-radius: 10px;

				--halo-size: 7px;
				padding: var(--halo-size);
				margin-right: calc(-1 * var(--halo-size));
				margin-bottom: calc(-1 * var(--halo-size));

				@media (max-width: ${breakpoints.small}) {
					/* the next question button needs to take up space in the layout for small viewports,
					   so we override position: absolute; from the desktop layout */
					position: static;
				}

				${theme.name !== 'universal_velvet' &&
				css`
					border-radius: 3px;
				`}
			}
		}
	}

	.question-deck.instructor-view {
		.status-and-next-question-button {
			margin-top: 6px;
		}
	}

	.updated-next-question-button {
		${iconButtonStyles(theme)}
	}
`;

const iconButtonStyles = (theme) => css`
	background-color: transparent;
	border: none;
	cursor: pointer;
	white-space: nowrap;

	position: relative;
	display: flex;
	align-items: flex-end;
	letter-spacing: 1.4px;

	@media (max-width: ${breakpoints.small}) {
		padding-left: 0;
		align-items: center;
	}

	.right-icon {
		fill: ${theme.colors.primary};
		stroke: ${theme.colors.primary};
		margin-left: 0.3em;
		border: 1px solid ${theme.colors.primary};
		border-radius: 0 4px 4px 0;
		font-size: 1.75em;
		height: 28px;
		min-width: 28px;

		${theme.name !== 'universal_velvet' &&
		css`
			margin-left: 0.4em;
		`}

		@media (max-width: ${breakpoints.small}) {
			margin-bottom: 0.3em;
		}
	}

	.button-label {
		${underlinedStyles(theme)}
		height: unset;
		margin-right: 0 !important;

		${theme.name !== 'universal_velvet' &&
		css`
			color: ${theme.colors.primary};
		`}

		${theme.name === 'strayer_velvet_2' &&
		css`
			color: black;
			font-weight: 500;
			font-size: 16px;
		`}

		@media (max-width: ${breakpoints.small}) {
			margin-left: 0;
			margin-top: 0.6em;
			margin-bottom: 1.5em;
		}
	}
`;
