import React from 'react';

import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { withGutterIconHeader } from '~/components/pageElements/Text';

import { Props as TextProps } from '../Text';
import { checkForSpecialTextContent } from '../utils';

interface Props {
	element?: Element;
	textProps: TextProps;
}

const Header: React.FC<Props> = (props) => {
	const { element, textProps } = props;
	const { family_id } = textProps.element;
	const iconKey = `${family_id}-${element.startIndex}-icon`;

	return domToReact([element], {
		replace: (element: Element) => {
			const { attribs } = element;
			const content = domToReact(element.children, {
				replace: (domNode: Element) => checkForSpecialTextContent(domNode, textProps)
			});
			if (attribs) {
				if (attribs.class.includes('webtext-callout-gutter')) {
					return withGutterIconHeader({ element, content, key: iconKey });
				} else if (attribs.class.includes('webtext-callout-heading')) {
					return withGutterIconHeader({
						element,
						content,
						key: iconKey,
						iconStylesOverrides: { borderRadius: '0px', fontSize: '36px' }
					});
				}
			}
		}
	}) as JSX.Element;
};

export default Header;
