import React, { FC, useMemo } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import isEmpty from 'lodash-es/isEmpty';
import { shallow } from 'zustand/shallow';

import PopperMenu from '~/components/Outline/components/ActionMenu/components/PopperMenu/PopperMenu';
import ToggleItem from '~/components/Outline/components/ActionMenu/components/ToggleItem';
import { getAddressInstanceIndex } from '~/components/Outline/helpers';
import {
	selectHierarchySection,
	selectMenuButtonId,
	selectMinSectionInstancesRequired,
	selectSectionInstancesCount,
	useOutlineSelector
} from '~/components/Outline/store';
import {
	isChildrenSection,
	OutlineChildrenSection,
	OutlineInstanceAddress
} from '~/components/Outline/types';

interface ActionMenuContainerProps {
	address: OutlineInstanceAddress;
}

const ActionMenu: FC<ActionMenuContainerProps> = (props) => {
	const { address } = props;

	const instanceIndex = getAddressInstanceIndex(address);
	const menuButtonId = useOutlineSelector((state) => selectMenuButtonId(state, address));

	const section = useOutlineSelector((state) =>
		selectHierarchySection<OutlineChildrenSection>(state, address)
	);
	const { toggle } = section;

	const instancesCount = useOutlineSelector((state) => selectSectionInstancesCount(state, address));
	const minInstancesRequired = useOutlineSelector((state) =>
		selectMinSectionInstancesRequired(state, address)
	);

	const { moveInstanceUp, moveInstanceDown, deleteInstance } = useOutlineSelector(
		(state) => ({
			moveInstanceUp: state.moveInstanceUp,
			moveInstanceDown: state.moveInstanceDown,
			deleteInstance: state.deleteInstance
		}),
		shallow
	);

	const showToggle = !isEmpty(toggle);
	const toggleItems = useMemo(() => {
		if (!showToggle) return [];

		return toggle.map(({ template }, toggleIndex) => {
			const key = `${template}_${toggleIndex}`;
			const toggleSectionAddress = address.concat({ toggleIndex });
			return (
				<ToggleItem
					key={key}
					autoFocus={toggleIndex === 0} // Workaround which fixes the focus behavior for the custom items in the `Menu` https://soomo.height.app/T-62964
					toggleSectionAddress={toggleSectionAddress}
				/>
			);
		});
	}, [address, showToggle, toggle]);

	const showMoveUp = instanceIndex > 0 && instancesCount > 1;
	const moveUpItem = showMoveUp ? (
		<MenuItem key="move-up" disableRipple onClick={() => moveInstanceUp(address)}>
			Move this item up
		</MenuItem>
	) : null;

	const showMoveDown = instanceIndex < instancesCount - 1 && instancesCount > 1;
	const moveDownItem = showMoveDown ? (
		<MenuItem key="move-down" disableRipple onClick={() => moveInstanceDown(address)}>
			Move this item down
		</MenuItem>
	) : null;

	const showInstanceDelete =
		instancesCount > minInstancesRequired && isChildrenSection(section, address);
	const instanceDeleteItem = showInstanceDelete ? (
		<MenuItem key="delete-instance" disableRipple onClick={() => deleteInstance(address)}>
			Delete this item
		</MenuItem>
	) : null;

	const items = [toggleItems, moveUpItem, moveDownItem, instanceDeleteItem].flat().filter(Boolean);
	return <PopperMenu address={address} buttonId={menuButtonId} items={items} />;
};

export default ActionMenu;
