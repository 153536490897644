import { useLayoutEffect } from 'react';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';

/**
 * Custom implementation of the `OnChangePlugin` from `lexical`
 *
 * See https://github.com/soomo/soomo-libs/pull/1208
 */
export function CustomOnChangePlugin({
	onChange,
	ignoreAutoSave = false
}: {
	onChange: (editorState: EditorState, editor: LexicalEditor, ignoreAutoSave) => void;
	ignoreAutoSave?: boolean;
}): null {
	const [editor] = useLexicalComposerContext();

	useLayoutEffect(() => {
		if (onChange) {
			return editor.registerUpdateListener(
				({ editorState, prevEditorState, dirtyElements, dirtyLeaves }) => {
					/**
					 * Inherited from the original `OnChangePlugin`
					 * When onChange is triggered, custom elements unmount and re-mount
					 * This makes selection drop from the elements (e.g. citations)
					 */
					if ((dirtyElements.size === 0 && dirtyLeaves.size === 0) || prevEditorState.isEmpty()) {
						return;
					}

					prevEditorState.read(() => {
						const root = $getRoot();
						ignoreAutoSave = !root.getAllTextNodes().length;
					});

					onChange(editorState, editor, ignoreAutoSave);
				}
			);
		}
	}, [editor, onChange]);

	return null;
}
