import EditTextField from './EditTextField';
import ReviseTextField from './ReviseTextField';
import ViewTextField from './ViewTextField';

const TextField = {
	Edit: EditTextField,
	View: ViewTextField,
	Revise: ReviseTextField
};

export default TextField;
