export interface IkWidget {
	embed: (targetId: string, settings: KalturaSettings) => boolean | null;
}

export type KalturaSettings = {
	targetId?: string;
	wid: string;
	uiconf_id?: string;
	entry_id: string;
	flashvars: object;
	params: object;
	cache_st?: string;
	readyCallback?: () => void;
};

export interface KalturaPlayerElement extends HTMLElement {
	kBind: (
		eventName: KalturaPlayerEvents,
		callback: (data: string | number | KalturaMediaPlayState, id?: string) => void
	) => void;
	evaluate: (property: string) => string | number;
	sendNotification: (notificationName: KalturaNotificationName, notificationData?: any) => void;
}

export type KalturaMediaPlayState =
	| 'uninitialized'
	| 'loading'
	| 'ready'
	| 'playing'
	| 'paused'
	| 'buffering'
	| 'playbackError';

export enum KalturaPlayerEvents {
	MEDIA_READY = 'mediaReady',
	FIRST_PLAY = 'firstPlay',
	PLAYER_PLAY_END = 'playerPlayEnd',
	PLAYER_PLAYED = 'playerPlayed',
	PLAYER_PAUSED = 'playerPaused',
	BUFFER_START_EVENT = 'bufferStartEvent',
	MEDIA_ERROR = 'mediaError'
}

// List - http://player.kaltura.com/docs/api#doPause
export enum KalturaNotificationName {
	DO_PAUSE = 'doPause',
	DO_PLAY = 'doPlay',
	DO_STOP = 'doStop',
	DO_SEEK = 'doSeek',
	CHANGE_VOLUME = 'changeVolume',
	DO_SWITCH = 'doSwitch',
	CHANGE_PREFERRED_BITRATE = 'changePreferredBitrate',
	DO_REPLAY = 'doReplay',
	ALERT = 'alert',
	SHOW_UI_ELEMENT = 'showUiElement',
	REMOVE_ALERTS = 'removeAlerts',
	ENABLE_GUI = 'enableGui',
	CANCEL_ALERTS = 'cancelAlerts',
	LIVE_ENTRY = 'liveEntry',
	SHOW_CLOSED_CAPTIONS = 'showClosedCaptions',
	HIDE_CLOSED_CAPTIONS = 'hideClosedCaptions'
}
