import { GeoMapType } from '~/types';

import type { GeoMapElement } from '~/types/WebtextManifest';

const replaceKeyColumnName = (activeFeatureName: string, figure: GeoMapElement): string => {
	switch (figure.geomap_type) {
		case GeoMapType.US: {
			return figure.tooltip.replace(/\[State\]/i, activeFeatureName);
		}

		case GeoMapType.World:
			return figure.tooltip.replace(/\[Country\]/i, activeFeatureName);

		default:
			console.warn(`Unrecognized GeoMap type = '${figure.geomap_type}'`);
			break;
	}
};
/**
 * Basic tooltip template looks like: '{{State}} &mdash; Group: <strong>{{Group}}</strong>'
 * This method replaces the labels in brackets with the column values from the data table.
 * In case of the Group column, we take the corresponding value from the Map Groups table.
 *
 * Example result: 'Arizona &mdash; Group: <strong>Low crime</strong>'
 */
export const getTooltip = (activeFeatureName: string, figure: GeoMapElement): string => {
	let replacedTooltip = replaceKeyColumnName(activeFeatureName, figure);

	const regExp = /\[([^\]]+)\]/g;
	const matches = replacedTooltip.match(regExp);

	matches &&
		matches.forEach((match) => {
			const labelFromTooltip = match.slice(1, match.length - 1);
			const labelIndex = figure.labels.findIndex(
				(label) => label.toLowerCase() === labelFromTooltip.toLowerCase()
			);

			const regex = new RegExp(`\\${match}`);
			const columnValue = figure.data[activeFeatureName][labelIndex] as string;

			if (labelFromTooltip.toLowerCase() === 'group') {
				// find Group name that responds to the given group id
				const groupName = figure.colors.find((group) => group.id === columnValue)?.name || '';
				replacedTooltip = replacedTooltip.replace(regex, groupName);
			} else if (labelIndex !== -1) {
				replacedTooltip = replacedTooltip.replace(regex, columnValue);
			}
		});

	return replacedTooltip;
};

export const getKeyColumnName = (mapType: GeoMapType): string => {
	switch (mapType) {
		case GeoMapType.US:
			return 'State';

		case GeoMapType.World:
			return 'Country';

		default:
			console.warn(`Unrecognized GeoMap type = '${mapType}'`);
			break;
	}
};

export const getNeighborDirection = (key: string): string => {
	switch (key) {
		case 'ArrowLeft':
		case 'Left':
			return 'l';

		case 'ArrowUp':
		case 'Up':
			return 'u';

		case 'ArrowRight':
		case 'Right':
			return 'r';

		case 'ArrowDown':
		case 'Down':
			return 'd';

		default:
			break;
	}
};
