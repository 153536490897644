import { css, SerializedStyles } from '@emotion/react';
import { lighten } from 'polished';

import { breakpoints, getThemeItem, Theme } from '~/styles/themes';

export const toastSnackbarStyles = css`
	&.MuiSnackbar-anchorOriginBottomCenter {
		// Use the whole screen width on smaller screens
		@media (max-width: ${breakpoints.small}) {
			left: 8px;
			right: 8px;
			bottom: 8px;
			transform: unset;
		}
	}
`;

export const toastBodyStyles = (theme: Theme): SerializedStyles => {
	const {
		fonts: { app },
		toast: { backgroundColor, buttonBackgroundColor }
	} = theme;

	const appFont = getThemeItem(app);
	const toastBgColor = getThemeItem(backgroundColor);
	const toastButtonBgColor = getThemeItem(buttonBackgroundColor);

	return css`
		&.MuiAlert-root {
			align-items: center;
			min-width: 250px;
			padding-top: 4px;
			padding-bottom: 4px;
			border-radius: 8px;
			font-family: ${appFont};
			font-size: 0.93rem;

			@media (max-width: ${breakpoints.small}) {
				min-width: 100%;
			}
		}

		&.MuiAlert-filledInfo {
			background-color: ${toastBgColor};
		}

		.MuiAlert-action {
			gap: 8px;

			// Respect the actions wrapper
			> div,
			span {
				display: flex;
				gap: 8px;
			}

			.MuiButton-root {
				color: inherit;
				min-width: 55px;
				padding: 0;
				text-decoration: none;
				text-transform: none;
				font-family: inherit;
				background-color: ${toastButtonBgColor};
				font-size: 0.9rem;

				&.Mui-focusVisible,
				&:active {
					background-color: ${lighten(0.15, toastButtonBgColor)};
				}
			}
		}
	`;
};
