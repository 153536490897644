import React from 'react';

import { ValidationParams } from '~/components/WritingTemplate/FillIn/types';

import { ValueErrorType } from './../Components/Cell/blur-errors.helper';

// Main components types

export type Sheet = any; //temporary !!type this one!

export enum CellType {
	Common = 'Common',
	Header = 'Header'
}

export type CellsRefs = { [address: string]: React.MutableRefObject<HTMLTableCellElement> };

export type CellFormatting = {
	accuracy?: number;
	type?: FormatType;
	separate?: boolean;
};

export enum FormatType {
	Accounting = 'accounting',
	Currency = 'currency',
	Percent = 'percent',
	Number = 'number',
	General = 'general'
}

export type Cell = {
	type: CellType;
	address: string;
	importedStyle: string;
	formatting: CellFormatting;
	isOverflow: boolean;
	placeholder?: string; // What is shown as the purely instructional placeholder. Visual + SR
	placeholderAnnouncement?: string; // Helps to contextualize the placeholder if it is set. Only SR
};

export type HeaderCell = {
	type: CellType;
	locked: boolean;
	value: string;
};

export type Row = {
	locked?: boolean;
	rowId: string;
	cells: Cell[] | HeaderCell[];
};

export type RegionHeaders = { headers: string[] };

export type RawRegion = [string[], RegionHeaders];

export type RawRegions = RawRegion[];

// Types for keyboard handle

export enum KeyCodes {
	ArrowUp = 'ArrowUp',
	ArrowDown = 'ArrowDown',
	ArrowRight = 'ArrowRight',
	ArrowLeft = 'ArrowLeft',
	Tab = 'Tab',
	Enter = 'Enter',
	FTwo = 'F2',
	FFive = 'F5',
	FTen = 'F10',
	Escape = 'Escape',
	Home = 'Home',
	Delete = 'Delete',
	Backspace = 'Backspace',
	X = 'X',
	C = 'C',
	V = 'V',
	A = 'A',
	U = 'U',
	Shift = 'Shift',
	Space = ' '
}

export enum Dir {
	Down = 'down',
	Up = 'up',
	Left = 'left',
	Right = 'right'
}

export enum Result {
	Accept = 'accept',
	Decline = 'decline'
}

// Selection types

export interface CursorSelection {
	isDisplay: boolean;
	position?: {
		top: number;
		left: number;
		width: number;
		height: number;
	};
}

export type Formatting = {
	[address: string]: {
		type: FormatType;
		separate?: boolean;
		accuracy?: number;
	};
};

export interface Validations {
	[address: string]: ValidationRule;
}

export type ValidationRule = CellNumberValidations | CellFormulaValidations | CellTextValidations;

export type RangeValidationRule = ContainsRangeValidation | IntersectsRangeValidation;

interface RangeValidation {
	messages: [];
	negated?: boolean;
}

export interface ContainsRangeValidation extends RangeValidation {
	'contains-range': string;
}

export interface IntersectsRangeValidation extends RangeValidation {
	'intersects-range': string;
}

interface CellValidations {
	'value-type': ValidationTypes;
	messages?: string[];
}

export interface CellNumberValidations extends CellValidations {
	'value-type': ValidationTypes.Number;
	equal?: string;
	less?: string;
	greater?: string;
}

export interface CellFormulaValidations extends CellValidations {
	'value-type': ValidationTypes.Formula;
	strict?: boolean;
	formula: {
		'one-of': string[];
		equal?: string;
	};
	result: Omit<CellNumberValidations, 'value-type'>;
}

export interface CellTextValidations extends CellValidations {
	'value-type': ValidationTypes.Text;
	'word-count': Omit<ValidationParams, 'optimum'>;
}

export enum ValidationTypes {
	Number = 'number',
	Formula = 'formula',
	Text = 'text'
}

export interface OnBlurErrors {
	[address: string]: CellOnBlurErrors;
}

export interface CellOnBlurErrors {
	'accept-only': AcceptOnlyTypes;
}

export enum AcceptOnlyTypes {
	Number = 'number',
	Numeric = 'numeric'
}

type CellValidationItem = {
	[address: string]: ValidationRule;
};

type RangeValidationItem = {
	selection: RangeValidationRule;
};

export type ValidationRules = CellValidationItem[] | RangeValidationItem[];

export interface EvaluatedCells {
	/**
	 * Simple object with [address]: formulaResult signature;
	 * We don't care about anything else here, used only for formula value caching
	 */
	[address: string]: FormulaResult;
}

export type CellErrorSetter = (message?: string, type?: ValueErrorType) => void;

type FormulaFunction = (
	formulaArguments: (string | number)[],
	cellValues: CellValues,
	evaluated: EvaluatedCells,
	isAllowRecursion: boolean
) => number | FormulaResult; // this will be or for formula types

export interface AvailableFormulas {
	[name: string]: {
		formula: FormulaFunction;
		required: {
			min?: number;
			max?: number;
		};
	};
}

export interface FormulaResult {
	success: boolean;
	error: string; // for additional field with error
	result: string | number; // cell value
}

export interface CellValues {
	[address: string]: string;
}

export type CellRelations = CellValues;

export interface EditableRanges {
	[address: string]: boolean;
}

/**
 * Formula input method selection is allow user to selecting cells with mouse/keyboard
 * input type allows only type text directly in area
 * In this case the name for selection more correctly is combined, but generally selection with typing is the feature itself
 * so this seems to be appropriate.
 */
export type FormulaInputMethod = 'selection' | 'input';

export interface CellsRowHeight {
	[address: string]: {
		rowName: string;
		height: number;
	};
}

/**
 * For non-touch devices this value is always null
 * On touch screen we should keep the container scrollable and selectable at the same time
 * so we need to know what mode is active now
 *
 * When mode scroll is active (default) we can scroll the container and do what we want
 * When mode select is active we can select cells using the selection, but scrolling is disabled at this moment
 */
export type TouchMode = 'scroll' | 'select' | null;

export interface Box {
	top: number;
	left: number;
	width: number;
	height: number;
}

export enum TreeArgumentType {
	Formula = 'function',
	BinaryExpression = 'binary-expression'
}

export enum ArgumentType {
	Cell = 'cell',
	CellRange = 'cell-range',
	Number = 'number',
	Text = 'text'
}

export type FormulaArguments = Array<Tree | RangeArgument | Argument | TextArgument>;

export interface Argument {
	type: ArgumentType.Cell;
	refType: 'relative' | 'mixed';
	// aka address
	key: string;
}

export interface TextArgument {
	type: ArgumentType.Text;
	value: string;
}

export interface RangeArgument {
	left: Argument;
	right: Argument;
	type: ArgumentType.CellRange;
}

export interface ExpressionArgument {
	type: TreeArgumentType.BinaryExpression;
	operator: '+' | '-' | '*' | '/' | ',';
	left: Tree;
	right: Tree;
}

export interface Tree {
	type: TreeArgumentType | ArgumentType;
	name?: string;
	arguments?: FormulaArguments;
	left?: Tree;
	right?: Tree;
}

export type DragHandlePosition = 'top' | 'bottom';

export interface SelectionState {
	selection: Set<string>;
	leftMostTopMostCellAddress: string | null;
	rightMostBottomMostCellAddress: string | null;
	activeHandle: DragHandlePosition | null;
	disableResetting?: boolean;
	anchorCellAddress?: string;
	isResetSelection?: boolean;
}

export interface ContextMenuParams {
	mouseX: number;
	mouseY: number;
	accessBy: 'keyboard' | 'touch';
}
