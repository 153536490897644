import * as React from 'react';

const WarningIcon: React.VFC<React.SVGAttributes<SVGElement>> = (props) => (
	<svg
		width="18px"
		height="17px"
		viewBox="0 0 18 17"
		version="1.1"
		aria-label="Warning: "
		{...props}>
		<title>warning</title>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Artboard" transform="translate(-915, -591)">
				<g id="warning" transform="translate(916, 592)">
					<path
						d="M7.99995094,-0.5 C8.31533477,-0.5 8.63046328,-0.416830898 8.90736008,-0.251822825 C9.16615863,-0.0975998463 9.39239232,0.12781734 9.55328799,0.425950009 L16.2888571,12.9029801 C16.5836859,13.4492013 16.5689254,14.1098171 16.2499635,14.6423825 C15.9314754,15.1741568 15.3562792,15.5 14.7351601,15.5 L1.26215223,15.4999995 C0.641509704,15.4991153 0.0671634477,15.1728277 -0.250671009,14.6412015 C-0.568963815,14.1088086 -0.58345262,13.4487689 -0.288848135,12.9029647 L6.44637033,0.425852124 C6.60739927,0.127719321 6.833692,-0.0976576638 7.09251268,-0.251852782 C7.36945834,-0.416846043 7.68459683,-0.5 7.99995094,-0.5 Z"
						id="Path"
						stroke="#000000"
						fill="#000000"></path>
					<path
						d="M15.8488604,13.1404731 L9.11330541,0.66346912 C8.63589237,-0.221156373 7.3641083,-0.221156373 6.88630005,0.66346912 L0.151140272,13.1404731 C-0.0597225026,13.5311317 -0.0493405128,14.0035648 0.178481366,14.3846314 C0.406303244,14.7656979 0.818066396,14.9993664 1.26286451,15 L14.7351601,15 C15.1803106,15 15.5927112,14.7666668 15.821012,14.3854767 C16.0493129,14.0042865 16.0598895,13.531441 15.8488604,13.1404731 Z M8.00000034,13.0683311 C7.56346401,13.0683311 7.2095814,12.7153362 7.2095814,12.2798949 C7.2095814,11.8444535 7.56346401,11.4914586 8.00000034,11.4914586 C8.43653666,11.4914586 8.79041928,11.8444535 8.79041928,12.2798949 C8.79041928,12.7153362 8.43653666,13.0683311 8.00000034,13.0683311 L8.00000034,13.0683311 Z M8.85839531,5.13863338 L8.63154507,9.94809461 C8.63154507,10.2964477 8.34843898,10.5788436 7.99920992,10.5788436 C7.64998086,10.5788436 7.36687477,10.2964477 7.36687477,9.94809461 L7.14002453,5.14060447 C7.12972358,4.90849305 7.21438313,4.68216367 7.3745912,4.51351237 C7.53479928,4.34486107 7.75681265,4.24835538 7.98972489,4.24612341 L7.99802429,4.24612341 C8.2325543,4.24600293 8.45694033,4.34152045 8.61911888,4.51051348 C8.78129742,4.67950651 8.86717093,4.90728514 8.85681447,5.14099869 L8.85839531,5.13863338 Z"
						id="Shape"
						fill="#EA9409"
						fillRule="nonzero"></path>
				</g>
			</g>
		</g>
	</svg>
);

export default WarningIcon;
