import React, { useEffect, useState } from 'react';

import { css, ClassNames } from '@emotion/react';
import Snackbar from '@material-ui/core/Snackbar';
import isEmpty from 'lodash-es/isEmpty';
import { useIsMounted } from 'usehooks-ts';

import { RBIcon } from '~/components/icons';
import { Offline } from '~/components/Offline';
import { UniversalVelvetLeftBorder } from '~/components/pageElements';
import {
	AnswerStatus,
	QuestionApi,
	QuestionPrompt,
	QuestionType,
	SectionTitle,
	ValidationStatus,
	WebtextQuestion
} from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import {
	SetAnswerDraftArgs,
	useAccessibilityFocus,
	useAutoSave,
	useIsUniversalVelvet,
	useTabSync
} from '~/hooks';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { ResponseBoardPost } from '~/types';

import PostedAnswer from './PostedAnswer';
import PostedAnswers from './PostedAnswers';
import styles from './styles';

/**
 * If `editable` is passed true than all new features (auto-save, tab-sync, editability) will
 * be enabled
 */
export interface RBQProps {
	userId: string;
	questionId: string;
	body: string;
	userPost: ResponseBoardPost;
	coursePosts: ResponseBoardPost[];
	api?: QuestionApi;
	saving?: boolean;
	posting?: boolean;
	unposting?: boolean;
	online: boolean;
	mobile?: boolean;
	showUnpostModal?: () => void;
	showSharingWarningModal?: (answerDraft: string) => void;
	sharingAcknowledged: boolean;
	showResponses: boolean;
	setShowResponses: (boolean) => void;
	// Validation
	hasValidation?: boolean;
	validationMessage: string;
	// Auto-Save / Flexible answer draft handling
	answerDraft?: string;
	setAnswerDraft?: (args: SetAnswerDraftArgs) => void;
	autoSave?: boolean;
	autoSaveDelay?: number;
	// Editability
	editable: boolean;
	// Tab-Sync
	tabSync?: boolean;
	setTabHasFocus?: (value: boolean) => void;
	readOnly?: boolean;
	postFailed?: boolean;
	updatedAt?: string;
	/**
	 * Temporary
	 */
	noBottomMargin?: boolean;
}

const ResponseBoardQuestion: React.FC<RBQProps> = (props: RBQProps) => {
	const {
		questionId,
		body,
		userPost,
		coursePosts,
		api,
		saving,
		posting,
		unposting,
		online,
		mobile,
		showUnpostModal,
		showSharingWarningModal,
		showResponses,
		setShowResponses,
		hasValidation,
		validationMessage,
		autoSave,
		autoSaveDelay,
		editable,
		tabSync,
		setTabHasFocus,
		readOnly,
		postFailed,
		updatedAt
	} = props;

	const [snackbarVisible, setSnackbarVisible] = useState(false);
	const hideSnackbar = () => setSnackbarVisible(false);
	const [localAnswerDraft, setLocalAnswerDraft] = useState(userPost.body);
	const [userActed, setUserActed] = useState(false);
	const [headingRef, setFocusToHeading] = useAccessibilityFocus();
	const [textareaRef, setFocusToTextarea] = useAccessibilityFocus();
	const [allResponsesHeadingRef, setFocusToAllResponsesHeading] = useAccessibilityFocus();
	const isUniversalVelvet = useIsUniversalVelvet();
	const isMounted = useIsMounted();

	const promptId = `response_board_question_body_${questionId}`;

	const answerDraft = () => {
		const draft = props.setAnswerDraft ? props.answerDraft : localAnswerDraft;
		return draft || '';
	};

	const setAnswerDraft = (args: SetAnswerDraftArgs) => {
		if (props.setAnswerDraft) {
			return props.setAnswerDraft(args);
		} else {
			return setLocalAnswerDraft(args.value);
		}
	};

	const onChange = (value) => {
		setAnswerDraft({ value, sync: true, save: true, saveDelay: autoSaveDelay || 5000 });
	};

	const onBlur = () => {
		setAnswerDraft({ value: answerDraft(), save: true, saveDelay: 1000 });
		if (tabSync) {
			setTabHasFocus(false);
		}
	};

	const isPosted = Boolean(userPost.postedAt);
	// dirty if they aren't equal and one of them has some kind of value
	const isDirty =
		answerDraft() !== userPost.body && !(isEmpty(answerDraft()) && isEmpty(userPost.body));
	useUnsavedChangesWarning(isDirty);

	const shouldHideResponses = !online && isPosted;
	const postingAllowed = !hasValidation || online;
	const isPostable =
		!posting && answerDraft() && answerDraft().length > 0 && !isPosted && postingAllowed;

	const save = () => {
		api.onSave(answerDraft()).then(() => setFocusToTextarea());
	};

	const post = () => {
		/**
		 * The consumer of this component determines posting / sharing warning modal logic.
		 */
		setUserActed(true);
		showSharingWarningModal(answerDraft());
	};

	const unpost = () => {
		setUserActed(true);
		showUnpostModal();
	};

	const renderButtons = () => {
		if (!isPosted) {
			if (autoSave) {
				return (
					<WebtextButton
						onClick={() => post()}
						disabled={readOnly || !isPostable || !postingAllowed}>
						Post
					</WebtextButton>
				);
			} else {
				return (
					<>
						<WebtextButton
							onClick={() => save()}
							secondary={isUniversalVelvet}
							disabled={readOnly || saving || answerDraft() === userPost.body}>
							Save Draft
						</WebtextButton>
						<WebtextButton
							onClick={() => post()}
							disabled={
								readOnly || posting || (!updatedAt && !userPost.lastSavedAt) || !postingAllowed
							}>
							Post
						</WebtextButton>
					</>
				);
			}
		}

		if (editable) {
			return (
				<WebtextButton
					key={`saq-${questionId}-unpost`}
					disabled={readOnly || unposting}
					onClick={() => unpost()}>
					Unpost &amp; Edit
				</WebtextButton>
			);
		}
	};

	const renderResponses = () => {
		if (!shouldHideResponses && isPosted) {
			if (mobile) {
				return (
					<WebtextButton
						className="show-responses-button"
						onClick={() => setShowResponses(!showResponses)}
						css={css`
							margin-top: 1em;
						`}>
						Show responses
					</WebtextButton>
				);
			}
			return (
				<ClassNames>
					{({ cx }) => (
						<div className="response-board-responses">
							<div className="divider" />
							<div className={cx('posted-answers-header', { 'show-responses': showResponses })}>
								<SectionTitle
									role="heading"
									ariaLevel={3}
									className="all-responses"
									tabIndex={-1}
									ref={allResponsesHeadingRef}>
									All Responses
								</SectionTitle>
								<WebtextButton
									aria-expanded={showResponses}
									variant={isUniversalVelvet ? 'underlined' : 'text'}
									onClick={() => setShowResponses(!showResponses)}>
									{showResponses ? `Hide responses` : `Show responses`}
								</WebtextButton>
							</div>
							{showResponses && (
								<PostedAnswers
									showResponses={showResponses}
									setShowResponses={setShowResponses}
									coursePosts={coursePosts}
								/>
							)}
						</div>
					)}
				</ClassNames>
			);
		}
	};

	useEffect(() => {
		setSnackbarVisible(postFailed);
	}, [postFailed]);

	/**
	 * After posting set user acted so we can focus header properly
	 */
	useEffect(() => {
		if (isMounted() && userActed && !posting && userPost.isPosted) {
			setFocusToHeading();
		}
	}, [isMounted, posting, setFocusToHeading, userActed, userPost.isPosted]);

	/**
	 * After unposting set user acted so we can focus textarea properly
	 */
	useEffect(() => {
		if (isMounted && userActed && !unposting && !userPost.isPosted) {
			setFocusToTextarea();
		}
	}, [isMounted, setFocusToTextarea, unposting, userActed, userPost.isPosted]);

	/**
	 * After hiding responses list, focus on "All responses" heading
	 */
	useEffect(() => {
		if (userActed && !showResponses) {
			setFocusToAllResponsesHeading();
		}
	}, [setFocusToAllResponsesHeading, showResponses, userActed]);

	return (
		<ClassNames>
			{({ cx }) => (
				<>
					<WebtextQuestion css={styles} noBottomMargin={props.noBottomMargin}>
						<UniversalVelvetLeftBorder>
							<QuestionType ref={headingRef}>
								{isUniversalVelvet && <RBIcon />}
								Response Board
							</QuestionType>
							<QuestionPrompt body={body} id={promptId} />
							<div className="question">
								{!isPosted && (
									<textarea
										ref={textareaRef}
										key="answer-input"
										aria-labelledby={promptId}
										className={cx(
											'answer',
											{ invalid: !!validationMessage },
											{ 'changed-input': isDirty }
										)}
										value={answerDraft()}
										disabled={readOnly || isPosted || posting}
										onChange={(e) => onChange(e.target.value)}
										onFocus={() => {
											if (tabSync) {
												setTabHasFocus(true);
											}
										}}
										onBlur={onBlur}
										rows={4}
										cols={60}
										spellCheck={true}
										placeholder={
											isUniversalVelvet ? 'Share your thoughts with your peers...' : null
										}
									/>
								)}

								<div className="user-post">
									{isPosted ? <PostedAnswer answer={userPost} hideAttribution /> : null}
								</div>
								<ValidationStatus>{validationMessage}</ValidationStatus>
								<div className={cx('status-and-actions', { posted: isPosted })}>
									<AnswerStatus
										postedAt={userPost.postedAt}
										updatedAt={updatedAt || userPost.lastSavedAt}
										saving={saving}
										posting={posting}
										unposting={unposting}
										offline={!online}
										suppressAria
									/>
									{renderButtons()}
								</div>
								{!postingAllowed && !isPosted && (
									<Offline
										mobile={mobile}
										standalone={false}
										elementName="Posting"
										interactionVerb="post"
									/>
								)}
								{shouldHideResponses && (
									<Offline
										mobile={mobile}
										standalone={false}
										elementName="All Responses Panel"
										interactionVerb="view"
									/>
								)}
							</div>
							{!shouldHideResponses && isPosted && renderResponses()}
						</UniversalVelvetLeftBorder>
					</WebtextQuestion>
					<Snackbar
						open={snackbarVisible}
						autoHideDuration={6000}
						onClose={hideSnackbar}
						message="An error occurred while sending your answer. Please try again."
						action={<button onClick={hideSnackbar}>close</button>}
					/>
				</>
			)}
		</ClassNames>
	);
};

export const ResponseBoardQuestionSynced: React.FC<RBQProps> = (props) => {
	const { tabSync, autoSave, posting } = props;

	const [tabHasFocus, setTabHasFocus] = useState(false);

	/**
	 * Temporary solution to fix the overriding of the local answer with the props.answer
	 * https://soomo.height.app/T-40308
	 */
	const [answerDraftLastSavedAt, setAnswerDraftLastSavedAt] = useState<number>(-1);

	const [answerDraft, setAutoSaveAnswerDraft] = useAutoSave({
		value: props.userPost.body,
		onSave: props.api.onSave,
		posting
	});

	const setAnswerDraft = (draft) => {
		setAnswerDraftLastSavedAt(Date.now());

		props.setAnswerDraft?.(draft);
		setAutoSaveAnswerDraft(draft);
	};

	// If we got a new answer from elsewhere (e.g., a newer web answer overwrites a stale mobile answer),
	// we want the draft to reflect the newer answer.
	useEffect(() => {
		const isPropsAnswerLater =
			new Date(props.userPost.lastSavedAt).getTime() > answerDraftLastSavedAt;

		if (props.userPost.body !== answerDraft && isPropsAnswerLater) {
			setAnswerDraft({ value: props.userPost.body, save: false, saveDelay: 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.userPost.body]);

	/**
	 * todo: This should probably get refactored into one setState call that happens regardless
	 * of whether tabSync or autoSave are turned on. By setting the state /first/ and then calling
	 * side effects we will reduce bugs that can arise in different combinations of autoSave: on/off and tabSync: on/off
	 */
	const [tabSyncAnswerDraft, setTabSyncAnswerDraft] = useState<SetAnswerDraftArgs | null>(null);
	const { questionState } = useTabSync({
		props: props,
		syncedKeys: ['userPost', 'coursePosts', 'saving', 'posting', 'unposting', 'validationMessage'],
		answerDraft: autoSave ? answerDraft : tabSyncAnswerDraft?.value,
		setAnswerDraft,
		tabHasFocus
	});

	let finalProps = { ...props, setTabHasFocus };

	if (autoSave) {
		finalProps = { ...finalProps, answerDraft, setAnswerDraft };
	}

	if (tabSync) {
		finalProps = {
			...finalProps,
			...questionState
		};

		if (!autoSave) {
			finalProps.setAnswerDraft = setTabSyncAnswerDraft;
		}
	}

	return <ResponseBoardQuestion {...finalProps} />;
};

export default ResponseBoardQuestion;
