import React from 'react';

import { groupInvalidMessage } from '~/components/WritingTemplate/EditMode/styles';

interface Props {
	dest: string;
	message: string;
}

export const ValidationMessagesPlugin: React.FC<Props> = ({ dest, message }) => (
	<>
		{message && (
			<div className="validation-message" id={`rt-validation-message-${dest}`}>
				<span css={groupInvalidMessage}>{message}</span>
			</div>
		)}
	</>
);
