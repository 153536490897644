import React, { useMemo } from 'react';

import { CellsRefs } from '../../helpers/types';
import { useElementsResize } from '../../hooks/useElementResize';
import Cursor from '../Cursor';
import FormulaHighlight from '../FormulaHighlight';
import InvalidRangeSelection from '../InvalidRangeSelection';
import RangeSelection from '../RangeSelection';

interface Props {
	cellsRefs: CellsRefs;
	withHeader: boolean;
	hideAddressHeaders: boolean;
	leaveSelectionVisible: boolean;
}

const Selection: React.FC<Props> = ({
	cellsRefs,
	withHeader,
	leaveSelectionVisible,
	hideAddressHeaders
}) => {
	const spreadsheetIndexes = useMemo(() => Object.keys(cellsRefs), [cellsRefs]);
	const [, totalHeight, totalWidth] = useElementsResize(cellsRefs, spreadsheetIndexes);

	return (
		<>
			<Cursor
				cellsRefs={cellsRefs}
				withHeader={withHeader}
				totalHeight={totalHeight}
				totalWidth={totalWidth}
			/>
			<RangeSelection
				leaveSelectionVisible={leaveSelectionVisible}
				hideAddressHeaders={hideAddressHeaders}
				cellsRefs={cellsRefs}
				withHeader={withHeader}
				totalHeight={totalHeight}
				totalWidth={totalWidth}
			/>
			<InvalidRangeSelection
				hideAddressHeaders={hideAddressHeaders}
				cellsRefs={cellsRefs}
				withHeader={withHeader}
				totalHeight={totalHeight}
				totalWidth={totalWidth}
			/>
			<FormulaHighlight withHeader={withHeader} totalHeight={totalHeight} totalWidth={totalWidth} />
		</>
	);
};

export default Selection;
