export enum EditorType {
	RichText = 'richtext',
	TextArea = 'textarea'
}

export enum ListType {
	UL = 'ul'
}

export enum RemoveMode {
	Auto = 'auto',
	Manual = 'manual'
}

export enum EditorKind {
	WithCitation = 'fill-in-with-citations',
	SourceWithCitation = 'source-list-ref'
}

export enum StyleType {
	Bold = 'BOLD',
	Italic = 'ITALIC',
	Bullet = 'unordered-list-item',
	Atomic = 'atomic'
}

export const KeyCodes = {
	I: 73,
	B: 66,
	A: 65,
	C: 67,
	X: 88,
	V: 86,
	Backspace: 8,
	Space: 32,
	Enter: 13,
	Delete: 46,
	ArrowLeft: 37,
	ArrowRight: 39,
	ArrowUp: 38,
	ArrowDown: 40,
	EnterString: 'Enter',
	DeleteString: 'Delete',
	BackspaceString: 'Backspace',
	ArrowLeftString: 'ArrowLeft',
	ArrowRightString: 'ArrowRight',
	ArrowUpString: 'ArrowUp',
	ArrowDownString: 'ArrowDown',
	SpaceString: ' ',
	CString: 'c',
	XString: 'x'
};

export enum Command {
	Bold = 'BOLD',
	Italic = 'ITALIC',
	Bullet = 'unordered-list-item',
	RemoveBullet = 'remove-bullet',
	RemoveSpecBullet = 'remove-special-bullet',
	RemoveCitation = 'remove-citation',
	DeleteCitation = 'delete-citation',
	EditPrevCitation = 'edit-prev-citation',
	EditNextCitation = 'edit-next-citation',
	ManualMove = 'manual-move',
	SplitSpecialList = 'split-special-list',
	HandleEndlineClick = 'handle-endline-click',
	IgnoreInput = 'ingnore-input',
	FocusCitationForward = 'focus-citation-forward',
	FocusCitationBack = 'focus-citation-back'
}

export enum Action {
	RemoveCitation = 'remove-citation',
	CreateCitationModal = 'create-citation-modal',
	EditCitation = 'edit-citation',
	CloseCitationModal = 'cancel-citation-modal',
	SaveEditorValue = 'save-editor-value',
	CreateCitation = 'create-citation',
	UpdateCitation = 'update-citation',
	FocusCitation = 'focus-citation',
	UnfocusBack = 'citation-focus-back',
	UnfocusForward = 'citation-focus-forward',
	ToggleInlineStyle = 'apply-inline-style',
	ToggleBulletlist = 'toggle-bullet-list',
	InsertBulletShortCut = 'insert-bullet-shortcut',
	BulletInteractionRemove = 'user-bulet-remove',
	SplitByKey = 'split-by-key',
	MoveSelection = 'move-selection',
	UnfocusOn = 'unfocus-on',
	ManualSplit = 'manual-split',
	UnfocusCitation = 'unfocus-citation'
}

export enum ClassName {
	citation = 'citation-wrap',
	afterElement = 'citation-after',
	afterElementEnline = 'citation-after-endline',
	beforeElement = 'citation-before',
	betweenElement = 'citation-between',
	unstyled = 'unstyled',
	InListCitation = 'in-list',
	InListNearCitation = 'citation-list-item-complex',
	InListSimple = 'citation-list-item-simple',
	ListDivider = 'atomic-list-divider',
	CitationAround = 'citation-around',
	SimpleListItem = 'simple-list-item',
	ComplexListItem = 'complex-list-item',
	SpecListUl = 'spec-list-ul',
	ListUl = 'list-ul',
	OutputListDivider = 'list-divider'
}

export enum CitationStyle {
	Sws = 'sws',
	Apa = 'apa',
	Turabian = 'turabian'
}

export enum Status {
	Handled = 'handled',
	NotHandled = 'not-handled'
}

export enum StyleStatusAction {
	SelectedStyled = 'selected&styled',
	Selected = 'selected&!styled',
	Styled = '!selected&styled',
	Noone = '!selected&!styled'
}

export enum ModalActions {
	Complete = 'complete',
	Incomplete = 'incomplete',
	SwitchOnAdditional = 'switchOnAdditional',
	SwitchOffAdditional = 'switchOffAdditional',
	SwitchOffAndComplete = 'switchOffAndComplete',
	EraseReducer = 'falseReducer'
}

export enum ContentType {
	Citation = 'CITATION',
	Unstyled = 'unstyled',
	CodeBlock = 'code-block',
	OrderedListItem = 'ordered-list-item',
	ListItem = 'unordered-list-item'
}

export enum EntityMutability {
	Immutable = 'IMMUTABLE',
	Mutable = 'MUTABLE',
	Segment = 'SEGMENT'
}

export enum EditorChangeType {
	SplitBlock = 'split-block',
	ChangeBlockType = 'change-block-type',
	DeleteCharacter = 'delete-character',
	ApplyEntity = 'apply-entity',
	InsertFragment = 'insert-fragment',
	RemoveRange = 'remove-range'
}

export enum StaticWidth {
	DefaultMultipler = 7.7,
	UniversalVelvetMultipler = 8.15,
	MinWidth = 75,
	WideCharsWidthMultipler = 1.15,
	SmallCharsWidthMultipler = 0.75,
	CapitalCharsWidthMultipler = 1.25
}
