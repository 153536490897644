import React, { useState, useEffect, useCallback, useMemo } from 'react';

import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

import WebtextButton from '~/components/WebtextButton';

import { ChartConfig } from '../EditMode/types';
import ChartTabs from './Components/ChartTabs';
import { chartGenerateButton, chartPlaceholder } from './styles';
import { ChartState, SpreadsheetState } from './types';
import { getChartData, getIsChartData, getSourceNotSelected } from './utils';

interface Props {
	dest?: string;
	source?: string;
	value: ChartState;
	chartName?: string;
	config: ChartConfig;
	output?: boolean;
	chartSource: SpreadsheetState | ChartState;
	selection?: { [key: string]: string[] };
	onChange?: (data: ChartState) => void;
	onReset?: () => void;
	setReferenceSelection?: (selection: string[], source: string) => void;
}

const ChartContainer: React.FC<Props> = (props) => {
	const {
		dest,
		source,
		config,
		chartName,
		selection,
		output,
		value,
		chartSource,
		onChange,
		onReset,
		setReferenceSelection
	} = props;

	const [chartState, setChartState] = useState<ChartState>({} as ChartState);
	const [isNewlyCreated, setNewlyCreated] = useState(false);

	const getConfigValid = useCallback(() => {
		if (output) {
			return { state: false, message: '' };
		}
		if (!dest || !source) {
			return { state: true, message: 'Unable to save or load Chart.' };
		}
		if (!config) {
			return { state: false, message: '' };
		}

		const { useReference, type } = config;

		if (!type) {
			return { state: true, message: 'Chart type is not provided.' };
		}

		if (useReference && !(source in selection)) {
			return { state: true, message: 'Referenced spreadsheet is not exist.' };
		}
	}, [dest, source, config, selection, output]);

	const handleGenerateChart = useCallback(
		(isNew = true, historicalSelection?: string[]) => {
			const { type } = config; // In case when we'll support multiple chart types, this should be moved in state
			const {
				name,
				data,
				meta: metadata
			} = getChartData(
				config,
				chartSource as SpreadsheetState,
				historicalSelection || selection[source]
			);

			// When name is selected it's more prior
			const chartStateName = name || chartName || 'ChartTitle';

			/**
			 * When we do not have a name from selection, but want to use name from config and have a cellReference with this name inside a .xlsx
			 * put this cell into a metadata. Otherwise do nothing.
			 */
			const meta =
				!name && chartName && config.nameReference
					? { ...metadata, nameRanges: [[config.nameReference]] }
					: metadata;

			setNewlyCreated(isNew);
			return { type, data, name: chartStateName, meta };
		},
		[chartName, chartSource, config, selection, source]
	);

	const handleGenerateChartClick = useCallback(() => {
		const chartState = handleGenerateChart();
		setChartState(chartState);
		onChange?.(chartState);
	}, [handleGenerateChart, onChange]);

	const handleResetChart = useCallback(() => {
		onReset?.();
		setChartState({} as ChartState);
	}, [onReset]);

	useEffect(() => {
		/**
		 * More likely this means chant was never touched or it was reset; However, this is not appropriate condition for ViewMode
		 */
		if ((isEmpty(value) && !output) || !chartSource) return;

		/**
		 * When chart is referencing another chart we will always take sourced value, because chart itself is never able to update
		 * The chart state will be updated if it differs from source, but it will be used only in places where this state is pulled
		 */
		if (!('cellValues' in chartSource)) {
			setChartState(chartSource);
			handleChange(chartSource);
			return;
		}

		/**
		 * Whenever builder's chart state is not empty, we need to know whether something changes
		 * For that purpose we need to build the "historicalSelection" which is technically the reference to values from SPI
		 * After that instead of applying old "value" we build "initialChartState" which may differ from the old value, because of the references change
		 * In case of differences, we re-call the onChange, which will write new builder's chart state.
		 */
		const historicalSelection = [...value.meta.dataRanges, ...value.meta.nameRanges].flat();
		const initialChartState = handleGenerateChart(false, historicalSelection);
		setChartState(initialChartState);
		handleChange(initialChartState);

		/**
		 * This will be called in case of something changed in a source; Otherwise we don't need it
		 */
		function handleChange(chartState: ChartState) {
			if (!isEqual(value, chartState)) onChange?.(chartState);
		}
	}, [handleGenerateChart, onChange, chartSource, value, output]);

	useEffect(() => {
		if (config?.useReference && chartState?.meta)
			setReferenceSelection?.(
				[...chartState.meta.dataRanges, ...chartState.meta.nameRanges].flat(),
				source
			);
	}, [chartState, source, config?.useReference, setReferenceSelection]);

	const chartTabSetElement = useMemo(() => {
		const isInvalid = getConfigValid();

		if (isInvalid?.state) {
			return <div css={chartPlaceholder}>{isInvalid.message}</div>;
		}

		if (!chartState?.data?.length) {
			return (
				<div css={chartPlaceholder}>
					Your chart will appear here once you select data and click &ldquo;Generate Chart.&rdquo;
				</div>
			);
		}

		return (
			<ChartTabs
				chartState={chartState}
				resetChart={handleResetChart}
				/**
				 * in output view or when chart is an import, reset button should not being shown
				 */
				isAllowReset={!output && !!config?.useReference}
				isNewlyCreated={isNewlyCreated}
			/>
		);
	}, [chartState, config?.useReference, getConfigValid, handleResetChart, isNewlyCreated, output]);

	const chartGenerateButtonElement = useMemo(() => {
		const isInvalid = getConfigValid();
		const isChartReference = chartSource && !('cellValues' in chartSource);
		const isChartData = getIsChartData(chartState);

		if (isChartReference || output || isInvalid?.state || isChartData) return;

		return (
			<WebtextButton
				data-ignore="1"
				css={chartGenerateButton}
				onClick={handleGenerateChartClick}
				disabled={getSourceNotSelected(selection, source)}>
				Generate Chart
			</WebtextButton>
		);
	}, [
		chartSource,
		chartState,
		getConfigValid,
		output,
		selection,
		source,
		handleGenerateChartClick
	]);

	return (
		<div id={`chart-for#${source || 'independent'}`}>
			{chartGenerateButtonElement}
			{chartTabSetElement}
		</div>
	);
};

export default ChartContainer;
