import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';

import { css } from '@emotion/react';

import {
	calculateCellContent,
	emptyCellContent
} from '~/components/pageElements/PollQuestion/RefreshedPoll/ResultsDataTable/ComparisonTable/utils';
import { breakpoints } from '~/styles/themes';
import { PollClassData } from '~/types/WebtextManifest';
import { trimTags } from '~/utils/parsing';

import { usePollDataState } from './hooks';

import type { PollProps } from './types';

interface Props
	extends Pick<
		PollProps,
		| 'choices'
		| 'externalData'
		| 'totalCount'
		| 'dataType'
		| 'choiceOrdering'
		| 'onFileDownload'
		| 'sourceDatasetIndex'
	> {
	classData: PollClassData;
}

const DownloadCSVLink: React.FC<Props> = (props) => {
	const {
		classData,
		choices,
		dataType,
		externalData,
		totalCount,
		choiceOrdering,
		sourceDatasetIndex,
		onFileDownload
	} = props;

	const roundValuePrecision = 10;

	const { classTotal, orderedClassData, shapedData, totalPercentageSums } = usePollDataState({
		choices,
		shapedDataMode: 'number',
		classData,
		externalData,
		choiceOrdering,
		sourceDatasetIndex,
		dataType,
		totalCount,
		roundValuePrecision
	});

	const csvData = useMemo(() => {
		if (!orderedClassData) return null;

		const getDatasetLabels = (dataset?: string) =>
			dataset ? [`${dataset} Responses`, `${dataset} Percentage`] : ['Responses', 'Percentage'];

		let headerRow = ['Choice'];
		if (externalData) {
			const externalDatasets = externalData[0] as string[];
			const classLabels = getDatasetLabels('Class');
			const externalDatasetLabels = externalDatasets.flatMap(getDatasetLabels);
			headerRow = [...headerRow, ...classLabels, ...externalDatasetLabels];
		} else {
			headerRow = [...headerRow, ...getDatasetLabels()];
		}

		const shapedDataWithoutDatasets = shapedData.slice(1) as number[][];
		const rows = shapedDataWithoutDatasets.map((row, rowIndex) => {
			const choiceLabel = trimTags(orderedClassData[rowIndex].label);
			const datasetColumns = row.flatMap((colValue, colIdx) => {
				const { numberCellContent, percentageCellContent } = calculateCellContent({
					colIdx,
					colValue,
					dataType,
					totalCount,
					classTotal,
					roundValuePrecision
				});
				return [numberCellContent, percentageCellContent];
			});
			return [choiceLabel, ...datasetColumns];
		});

		const totalRowLabel = 'Total Responses';
		const classLabels = [classTotal, `${totalPercentageSums[0]}%`];
		const externalDatasetLabels =
			totalCount?.flatMap((total, idx) => [
				total ? Number(total).toLocaleString('en-US') : emptyCellContent,
				`${totalPercentageSums[idx + 1]}%`
			]) || [];
		const totalRow = [totalRowLabel, ...classLabels, ...externalDatasetLabels];

		return [headerRow, ...rows, totalRow];
	}, [
		classTotal,
		dataType,
		externalData,
		orderedClassData,
		shapedData,
		totalCount,
		totalPercentageSums
	]);

	const getFileName = () => {
		const date = new Date();
		const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		const formattedTime = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

		return `poll_data_${formattedDate}-${formattedTime}.csv`;
	};

	const handleLinkClick = (event) => {
		/**
		 * When the `onFileDownload` is not provided - return `true` to trigger the default browser behavior
		 * Otherwise, store the file with `onFileDownload` callback, and ignore the default browser's behavior with returning the `false`
		 */
		if (!onFileDownload) return true;

		const { href: url } = event.target as HTMLAnchorElement;
		const fileName = getFileName();
		return onFileDownload(url, fileName);
	};

	return orderedClassData ? (
		<CSVLink
			data={csvData}
			filename={getFileName()}
			css={downloadLinkStyles}
			onClick={handleLinkClick}>
			Download the data (CSV)
		</CSVLink>
	) : null;
};

/**
 * When the text-decoration: underline is set, the brackets are not underlined
 */
const downloadLinkStyles = (theme) => css`
	color: ${theme.colors.primary};
	text-underline-offset: 4px;

	@media (max-width: ${breakpoints.small}) {
		font-size: 14px;
	}
`;

export default DownloadCSVLink;
