import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import indefinite from 'indefinite';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import ModalDialog from 'Components/ModalDialog';
import { Api, Course, User } from 'Types/index';
import { formatReportTime } from 'Utils/format';

import styles from './GenerateReportModal.scss';

export type ReportType = 'mc_performance' | 'analytics' | 'gradebook';

interface Props {
	open: boolean;
	onClose: () => void;
	reportType: ReportType;
	showToast: () => void;
	api: Api;
	course: Course;
	user: User;
	timeZone: string;
}

const ReportTimeEstimates: Record<ReportType, number> = {
	gradebook: 1,
	mc_performance: 1,
	analytics: 15
};

const HelpCenterURLs: Record<ReportType, string> = {
	gradebook:
		'https://instructorhelp.soomolearning.com/en/articles/9425530-how-do-i-use-a-gradebook-points-report',
	mc_performance: 'https://instructorhelp.soomolearning.com/en/articles/9174743',
	analytics: 'https://instructorhelp.soomolearning.com/en/articles/9161528'
};

const ENDPOINT = '/api/reports/v1/requests';

const GenerateReportModal: React.FC<Props> = (props) => {
	const { open, onClose, reportType, showToast, api, course, user, timeZone } = props;

	const [isLoading, setIsLoading] = useState(true);
	const [reportInProgress, setReportInProgress] = useState(null);

	const enrollmentsNumber = course.active_student_enrollment_count;

	useEffect(() => {
		// check whether the report type is already being generated for the course
		const protocol = window.Playwright ? 'http' : 'https';
		const url = `${protocol}://${api.host}${ENDPOINT}?course_id=${course.id}&report_type=${reportType}&report_status=queued,running&jwt=${api.jwt}`;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				if (data.length !== 0) setReportInProgress(data[0]);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				throw new Error(error.message);
			});
	}, [api.host, api.jwt, course.id, reportType]);

	const getReportName = () => {
		switch (reportType) {
			case 'gradebook':
				return 'Gradebook Points';
			case 'mc_performance':
				return 'MC Question Response Summary';

			case 'analytics':
				return 'Student Activity Detail';

			default:
				break;
		}
	};

	const getModalTitle = () => {
		return reportInProgress
			? 'Your report is processing.'
			: `Would you like to generate ${indefinite(getReportName())} report for your course webtext?`;
	};

	const getTimeEstimate = () => {
		const seconds = enrollmentsNumber * ReportTimeEstimates[reportType];
		const minutes = seconds / 60;

		if (minutes <= 30) return 'several minutes';
		else if (minutes <= 75) return 'an hour';
		else if (minutes <= 135) return 'two hours';
		else return 'a few hours';
	};

	const getModalBody = () => {
		if (reportInProgress) {
			return (
				<>
					<p>
						The {getReportName()} report began processing at{' '}
						{formatReportTime({ time: reportInProgress.created_at, timeZone })}.
					</p>
					<p>
						It may take {getTimeEstimate()} to process this report. When it’s ready, we will send a
						download link to {user.email}.
					</p>
					<p>
						If you think there has been an error or if you have any questions, please contact us at{' '}
						<a href={`mailto:support@soomolearning.com`} target="_blank" rel="noreferrer">
							support@soomolearning.com
						</a>{' '}
						or click the Soomo Messenger button.
					</p>
				</>
			);
		}

		return (
			<>
				<p>
					{reportType === 'gradebook' &&
						`This report provides the webtext points earned for each student in your course. Points are 
						awarded based on the grading profile for your webtext and match the points delivered to the LMS 
						gradebook if you have an LMS integration. `}
					{reportType === 'mc_performance' &&
						`This report provides aggregated response data for each multiple-choice question 
						 in the webtext and enables you to see how well students are understanding the 
						 content. `}
					{reportType === 'analytics' &&
						`This report provides webtext activity data at the book, chapter, and page level 
						 for each student in your course. Types of data include active time in the webtext, 
						 question completion, multiple-choice question attempts, and more. `}
					See our{' '}
					<a href={HelpCenterURLs[reportType]} target="_blank" rel="noreferrer">
						Help Center
					</a>{' '}
					for more information.
				</p>
				<p>
					It may take {getTimeEstimate()} to process this report. When it’s ready, we will send a
					download link to {user.email}.
				</p>
				<p>
					Need help? Ask{' '}
					<a href={`mailto:support@soomolearning.com`} target="_blank" rel="noreferrer">
						support@soomolearning.com
					</a>{' '}
					or click the Soomo Messenger button.
				</p>
			</>
		);
	};

	const onGenerateReportClick = () => {
		// check whether the report type is already being generated for the course
		const protocol = window.Playwright ? 'http' : 'https';
		const url = `${protocol}://${api.host}${ENDPOINT}?course_id=${course.id}&report_type=${reportType}&jwt=${api.jwt}`;
		fetch(url, { method: 'POST' })
			.then(() => showToast())
			.catch((error) => {
				console.error(error);
				throw new Error(error.message);
			});
	};

	const actions = reportInProgress
		? [
				<Button key="ok-button" onClick={onClose} classes={{ root: styles.Button }}>
					<span className={styles.Label}>OK</span>
				</Button>
		  ]
		: [
				<Button key="close-button" onClick={onClose} classes={{ root: styles.Button }}>
					<span className={`${styles.Label} ${styles.CancelButtonLabel}`}>Cancel</span>
				</Button>,
				<Button
					key="generate-button"
					disabled={isLoading || reportInProgress}
					onClick={() => {
						onGenerateReportClick();
						onClose();
					}}
					classes={{ root: styles.Button }}>
					<span className={styles.Label}>Generate Report</span>
				</Button>
		  ];

	return (
		<ModalDialog
			open={open}
			onClose={onClose}
			title={isLoading ? '' : getModalTitle()}
			actions={isLoading ? [] : actions}
			className={styles.GenerateReportModal}>
			{isLoading && (
				<div className={styles.LoadingModal}>
					<CircularProgress color="inherit" size={32} />
					<div className={styles.LoadingModalLabel}>Checking report queue...</div>
				</div>
			)}
			{!isLoading && <div>{getModalBody()}</div>}
		</ModalDialog>
	);
};

const mapStateToProps = (state) => ({
	api: state.data.api,
	course: state.entities.course,
	user: state.entities.currentUser,
	timeZone: state.entities.course.time_zone
});

export default connect(mapStateToProps)(GenerateReportModal);
