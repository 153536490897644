import React, { useState } from 'react';

import { ClassNames } from '@emotion/react';

import { errorMessage } from '~/components/GenericErrorBoundary';
import { Indeterminate } from '~/components/Loaders';
import { Offline } from '~/components/Offline';
import { AnswerStatus, QuestionChoices } from '~/components/shared/Question';
import WebtextButton from '~/components/WebtextButton';
import { type FocusFunction } from '~/hooks';
import { useUnsavedChangesWarning } from '~/hooks/useUnsavedChangesWarning';
import { type FamilyId } from '~/types/WebtextManifest';

import PollResultsView from './PollResultsView';
import { type PollProps } from './types';
import { studentViewLoadingText } from './utils';

interface Props extends PollProps {
	setUserActed?: (_: boolean) => void;
	setFocusToHeading?: FocusFunction;
}

const PollQuestionView: React.FC<Props> = (props) => {
	const {
		questionFamilyId,
		choices,
		answer,
		onChoiceSelected,
		onChangeSelectedOption,
		online,
		mobile,
		submissionError,
		submitting,
		readOnly,
		/**
		 * Student view Props
		 */
		setUserActed,
		setFocusToHeading
	} = props;

	const [selectedOption, setSelectedOption] = useState(null);

	const completedAnswer = answer && answer.completed;
	const completedAnswerWithoutData = completedAnswer && !answer.graphData;

	// Shows the loaders with the text next to it at the bottom of a question
	const isLoading = online && !submissionError && (submitting || completedAnswerWithoutData);
	const ableToSubmit = (!answer || !answer.completed) && !submitting && !isLoading;

	const isDirty = selectedOption && selectedOption !== answer?.body;
	useUnsavedChangesWarning(isDirty);

	const selectChoice = () => {
		setUserActed?.(true);
		setFocusToHeading?.().then(() => onChoiceSelected(selectedOption));
	};

	const changeSelectedChoice = (choiceFamilyId: FamilyId) => {
		setSelectedOption(choiceFamilyId);
		onChangeSelectedOption?.(questionFamilyId);
	};

	return (
		<ClassNames>
			{({ cx }) => (
				<div className="question">
					{!answer?.graphData ? (
						<div className="poll-question-form">
							<QuestionChoices
								questionFamilyId={questionFamilyId}
								choices={choices}
								disabled={readOnly || !ableToSubmit}
								selectedChoiceFamilyId={completedAnswer ? answer.body : selectedOption}
								onChangeSelectedChoice={changeSelectedChoice}
							/>
							{submissionError &&
								errorMessage('An error occurred while submitting your answer.', 'submissionError')}
							<div className={cx('status-and-actions', { separator: !completedAnswer })}>
								<div className="save-button-container">
									{completedAnswer && !online && <span>Response saved.</span>}
									{isLoading && (
										<span className="loading">
											<Indeterminate size={20} aria-hidden />
											<span>{studentViewLoadingText}</span>
										</span>
									)}
									{ableToSubmit && !submitting && (
										<WebtextButton
											type="submit"
											value="Save"
											onClick={selectChoice}
											disabled={readOnly || (!selectedOption && (!answer || !!answer.completed))}>
											Save
										</WebtextButton>
									)}
								</div>
							</div>
						</div>
					) : (
						<div>
							<PollResultsView {...props} />
							{answer.updated_at && (
								<div className="save-button-container">
									<AnswerStatus
										updatedAt={answer.updated_at}
										posting={false}
										saving={false}
										unposting={false}
										suppressAria
									/>
								</div>
							)}
						</div>
					)}

					{!online && ableToSubmit && (
						<Offline
							mobile={mobile}
							standalone={false}
							elementName="Results"
							contentDescription="After you respond, come back when you’re online to see your classmates’ answers."
						/>
					)}
					{!online && (completedAnswerWithoutData || submitting) && (
						<Offline
							mobile={mobile}
							standalone={false}
							elementName="Results"
							contentDescription="Once you’re back online, you’ll see your classmates’ responses here."
						/>
					)}
				</div>
			)}
		</ClassNames>
	);
};

export default PollQuestionView;
